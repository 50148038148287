import _ from "lodash";

import { defined } from "../../../core/defined";
import { dateStringComparatorAsc } from "../../../core/time";
import { DataPoint, ForecastDataPoint } from "../datasets/DataPoint";
import { ColorKey } from "./core/definitions";

export type DateDistanceGetter = (a: string, b: string) => number;
export type LinePoint = {
  // Applicable to survey data only
  lowBase: boolean;
  point: DataPoint;
};

export type PointChain = LinePoint[];

export interface DataPointWithLowBaseInfo {
  point: DataPoint;
  lowBase: boolean;
}

export interface ColorableTimeSeriesLine extends ColorKey {
  line: TimeSeriesLine;
  forecastLine: ForecastLine;
}

export class ForecastLine {
  private _points: ForecastDataPoint[];

  constructor(points: ForecastDataPoint[]) {
    this._points = points.slice().sort((left, right) => {
      return dateStringComparatorAsc(left.domainRaw(), right.domainRaw());
    });
  }

  points(): ForecastDataPoint[] {
    return this._points;
  }

  uncertaintyOutline(): DataPoint[] {
    const highPoints = this._points.map((point) => {
      return {
        ...point,
        value: () => point.high,
      };
    });
    const lowPoints = this._points
      .map((point) => {
        return {
          ...point,
          value: () => point.low,
        };
      })
      .reverse(); // Reverse to draw a closed polygon

    return [...highPoints, ...lowPoints];
  }
}

export class TimeSeriesLine {
  /**
   * @param _pointChains chains of adjacent points.
   * Multiple chains means there are gaps in this data series.
   */
  private constructor(private _pointChains: PointChain[]) {}

  get hasGaps(): boolean {
    return this._pointChains.length > 1;
  }

  get hasLowBase(): boolean {
    return defined(
      this._pointChains.find((chain) =>
        defined(chain.find((point) => point.lowBase))
      )
    );
  }

  /**
   * Get data as a single line, regardless of gaps in series
   */
  singleLine(): LinePoint[] {
    return _.flatMap(this._pointChains, (chain) => chain);
  }

  /**
   * @returns chains of adjacent points. Chains are separated by gaps.
   */
  pointChains(): PointChain[] {
    return this._pointChains;
  }

  points(): DataPoint[] {
    return _.flatMap(this._pointChains, (chain) =>
      chain.map((point) => point.point)
    );
  }

  static fromRows(
    pointsUnsorted: DataPointWithLowBaseInfo[],
    dateDimensionDistance: DateDistanceGetter
  ): TimeSeriesLine {
    const pointChains: PointChain[] = [];

    const points = pointsUnsorted
      .slice()
      .sort((left, right) =>
        dateStringComparatorAsc(left.point.domainRaw(), right.point.domainRaw())
      );

    let currentChain: LinePoint[] = [];
    for (let i = 0; i < points.length; i++) {
      const current = points[i];
      currentChain.push(current);

      const next = points[i + 1] as DataPointWithLowBaseInfo | undefined;
      if (
        defined(next) &&
        dateDimensionDistance(
          current.point.domainRaw() as string,
          next.point.domainRaw()
        ) > 1
      ) {
        pointChains.push(currentChain);
        currentChain = [];
      }
    }

    if (currentChain.length > 0) {
      pointChains.push(currentChain);
    }
    return new TimeSeriesLine(pointChains);
  }
}
