import { useCallback, useEffect, useState } from "react";

import { LoadingResult } from "../../../../../lib/application/loading/LoadingResult";
import {
  DataGroup,
  getMicroDatasetWithCache,
} from "../../../../../lib/application/requests/datasets/micro";
import {
  MicroGeoSelections,
  MicroOutputTab,
  MicroSettings,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { MicroDataset } from "../../../../../lib/application/stats/datasets/MicroDataset";
import { defined } from "../../../../../lib/core/defined";
import {
  FilterMeasureMicro,
  PrimaryMeasureSelectionMicroFull,
} from "../../../../../lib/domain/measure/definitions";
import { MicroResultsType } from "../../../../../lib/domain/micro/definitions";
import { makeDesoRegsoToPropertiesLookup } from "../../../../../lib/domain/micro/desoLookup";
import { HttpError, HttpResult } from "../../../../../lib/infra/HttpResult";
import { logger } from "../../../../../lib/infra/logging";
import { MicroGeoTree } from "../../../../../lib/application/stats/shared/MicroGeoTree";

/** @deprecated */
function selectedAreasToDataGroups(selection: MicroGeoSelections): {
  groups: DataGroup[];
  singleUnitsOnly: boolean;
} {
  const anonymousGeocodes: string[] = [];
  const dataGroups: DataGroup[] = [];
  let singleDesoGroupsOnly = false;

  if (selection.type === "deso") {
    for (const area of selection.selected) {
      if (area.type === "deso") {
        anonymousGeocodes.push(area.props.deso);
      } else if (area.type === "user-defined") {
        dataGroups.push({
          label: area.groupName,
          geocodes: area.props.map((p) => p.deso),
          group_id: area.groupId,
        });
      }
    }
  } else if (selection.type === "regso") {
    for (const area of selection.selected) {
      if (area.type === "regso") {
        anonymousGeocodes.push(area.props.regso);
      } else if (area.type === "user-defined") {
        dataGroups.push({
          label: area.groupName,
          geocodes: area.props.map((p) => p.regso),
          group_id: area.groupId,
        });
      }
    }
  }

  if (anonymousGeocodes.length > 0) {
    dataGroups.push(
      ...anonymousGeocodes.map((deso, i) => ({
        label: "Anonymous-" + i,
        geocodes: [deso],
        group_id: "anonymous-" + i,
      }))
    );
    singleDesoGroupsOnly = true;
  }

  return { groups: dataGroups, singleUnitsOnly: singleDesoGroupsOnly };
}

export function useFetchPostalCodeDataset(
  selectedAreas: MicroGeoSelections | undefined,
  filterMeasure: FilterMeasureMicro[] | undefined,
  settings: MicroSettings,
  showDraftData: boolean,
  microGeoTree: MicroGeoTree | undefined,
  measureSelection?: PrimaryMeasureSelectionMicroFull
): () => Promise<HttpResult<MicroDataset | undefined>> {
  const callback: () => Promise<HttpResult<MicroDataset | undefined>> =
    useCallback(() => {
      const noSelectedAreas =
        !defined(selectedAreas) || selectedAreas.selected.length === 0;
      if (
        !defined(measureSelection) ||
        noSelectedAreas ||
        !measureSelection.measure.geoTypes.includes(selectedAreas.type)
      ) {
        return Promise.resolve(HttpResult.fromOk(undefined));
      }

      const { groups, singleUnitsOnly } =
        selectedAreasToDataGroups(selectedAreas);
      const {
        measure,
        selectedDimensions,
        timeSelection,
        computedMeasureVariablesConfig: selectedComputedVariables,
      } = measureSelection;
      // Each dimension must have one selected value
      if (
        Object.values(selectedDimensions).some(
          (d) => !defined(d) || d.length === 0
        )
      ) {
        return Promise.resolve(HttpResult.fromOk(undefined));
      }

      const comparisonType: MicroResultsType = singleUnitsOnly
        ? selectedAreas.type === "deso"
          ? "compare-units-deso"
          : "compare-units-regso"
        : "compare-groups";

      if (!defined(timeSelection)) {
        throw new Error(
          "timeSelection must be defined when fetching micro data"
        );
      }

      const variableKeys = Object.keys(measure.computed?.variables ?? {});
      if (
        measure.computed?.type === "count_within_distance" &&
        !variableKeys.every((k) => defined(selectedComputedVariables?.[k]))
      ) {
        return Promise.resolve(HttpResult.fromOk(undefined));
      }

      const desoToProperties = makeDesoRegsoToPropertiesLookup(selectedAreas);

      return getMicroDatasetWithCache(
        measure.id,
        comparisonType,
        measureSelection,
        filterMeasure,
        selectedAreas,
        settings,
        desoToProperties,
        timeSelection[0],
        timeSelection[1],
        measure.computed?.type,
        selectedComputedVariables,
        selectedDimensions,
        groups,
        microGeoTree,
        showDraftData,
        true
      );
    }, [
      filterMeasure,
      measureSelection,
      microGeoTree,
      selectedAreas,
      settings,
      showDraftData,
    ]);

  return callback;
}

export function useMicroResults(
  view: MicroOutputTab | undefined,
  selectedAreas: MicroGeoSelections | undefined,
  filterMeasures: FilterMeasureMicro[] | undefined,
  settings: MicroSettings,
  isEditingCard: boolean,
  showDraftData: boolean,
  microGeoTree: MicroGeoTree | undefined,
  measureSelection?: PrimaryMeasureSelectionMicroFull
): [
  LoadingResult<HttpError, MicroDataset>,
  () => Promise<MicroDataset | undefined>
] {
  const [loadingStatus, setLoadingStatus] = useState<
    LoadingResult<HttpError, MicroDataset>
  >(LoadingResult.notStarted());

  const noSelectedAreas =
    !defined(selectedAreas) || selectedAreas.selected.length === 0;

  const load = useCallback(
    (measureSelection?: PrimaryMeasureSelectionMicroFull) => {
      if (
        !defined(measureSelection) ||
        noSelectedAreas ||
        !measureSelection.measure.geoTypes.includes(selectedAreas.type)
      ) {
        setLoadingStatus(LoadingResult.notStarted());
        return Promise.resolve(undefined);
      }

      const { groups, singleUnitsOnly } =
        selectedAreasToDataGroups(selectedAreas);

      const {
        measure,
        selectedDimensions,
        timeSelection,
        computedMeasureVariablesConfig: selectedComputedVariables,
      } = measureSelection;
      // Each dimension must have one selected value
      if (
        Object.values(selectedDimensions).some(
          (d) => !defined(d) || d.length === 0
        )
      ) {
        return Promise.resolve(undefined);
      }

      const comparisonType: MicroResultsType = singleUnitsOnly
        ? selectedAreas.type === "deso"
          ? "compare-units-deso"
          : "compare-units-regso"
        : "compare-groups";

      if (!defined(timeSelection)) {
        throw new Error(
          "timeSelection must be defined when fetching micro data"
        );
      }

      const variableKeys = Object.keys(measure.computed?.variables ?? {});
      if (
        measure.computed?.type === "count_within_distance" &&
        !variableKeys.every((k) => defined(selectedComputedVariables?.[k]))
      ) {
        return Promise.resolve(undefined);
      }

      const desoToProperties = makeDesoRegsoToPropertiesLookup(selectedAreas);
      if (loadingStatus.isNotStarted()) {
        setLoadingStatus(LoadingResult.inProgress());
      }

      return getMicroDatasetWithCache(
        measure.id,
        comparisonType,
        measureSelection,
        filterMeasures,
        selectedAreas,
        settings,
        desoToProperties,
        timeSelection[0],
        timeSelection[1],
        measure.computed?.type,
        selectedComputedVariables,
        selectedDimensions,
        groups,
        microGeoTree,
        showDraftData,
        false
      )
        .then((r) => {
          return r.match({
            ok: (dataset) => {
              setLoadingStatus(LoadingResult.from(dataset));
              return dataset;
            },
            err: (err) => {
              logger.error(err);
              setLoadingStatus(LoadingResult.err(err));
              return undefined;
            },
          });
        })
        .catch((e) => {
          logger.error(e);
          setLoadingStatus(LoadingResult.err(e));
          return undefined;
        });
    },
    // We disregard loadingStatus here because to avoid reload loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterMeasures, selectedAreas, settings, showDraftData, microGeoTree]
  );

  const reload = useCallback(() => {
    return load(measureSelection);
  }, [load, measureSelection]);

  // Unset results when not showing a results tab.
  useEffect(() => {
    if (view === "map-select") {
      setLoadingStatus(LoadingResult.notStarted());
    }
  }, [view]);

  useEffect(() => {
    const resultViews: MicroOutputTab[] = ["chart", "table", "map-view"];
    if ((defined(view) && resultViews.includes(view)) || !isEditingCard) {
      load(measureSelection);
    }
  }, [load, measureSelection, isEditingCard, view]);

  return [loadingStatus, reload];
}
