import { defined } from "../../../../lib/core/defined";

export const resolutionOptions: string[] = [
  "days-1",
  "weeks-1",
  "months-1",
  "months-3",
  "months-6",
  "years-1",
  "years-2",
  "years-4",
  "years-5",
];

type CommonFieldProperties<K> = {
  key: K;
  label?: string;
  optional?: boolean;
  placeholder?: string;
};

export type InputField<K> = {
  type: "input" | "textarea";
} & CommonFieldProperties<K>;

export type SelectField<K> = {
  type: "select";
  options: string[];
} & CommonFieldProperties<K>;

export type MultiSelectField<K> = {
  type: "multiselect";
  options: string[];
  default?: string[];
} & CommonFieldProperties<K>;

/**
 * Specifies a breakdown column name and its label.
 */
export type BreakdownField<K> = {
  type: "breakdowns";
  optional?: boolean;
  key: K;
  label: {
    label: string;
    placeholder?: string;
  };
  csvColumn: {
    label: string;
    placeholder?: string;
  };
};

export type MetadataInputField<K> =
  | InputField<K>
  | ({
      type: "date";
    } & CommonFieldProperties<K>)
  | SelectField<K>
  | BreakdownField<K>
  | MultiSelectField<K>;

export function isMultiselectField<T>(
  field: MetadataInputField<T>
): field is MultiSelectField<T> {
  return field.type === "multiselect";
}

export function metadataEntryLabel<K extends string>(
  entry: MetadataInputField<K>
): string {
  return defined(entry.label) ? `${entry.label} (${entry.key})` : entry.key;
}
