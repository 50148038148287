import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { useAuth0 } from "@auth0/auth0-react";

import { Toolbar } from "./Toolbar";
import {
  useAddMicroCardCallback,
  useAddPythonCardCallback,
  useAddStatsCardCallback,
  useAddTextCardCallback,
  useGetAllCardsCallback,
} from "../../../lib/application/state/actions/cardCallbacks";
import { getAlertRegistrations } from "../../../lib/application/requests/common_requests";
import {
  makeDataCardState,
  makeMicroCard,
  makePythonCardState,
  makeTextCardCK,
} from "../../../lib/application/state/stats/document-core/create";
import {
  reportMetaStateQuery,
  reportTitleQuery,
} from "../../../lib/application/state/stats/document-meta/queries";
import { getText } from "../../../lib/application/strings";
import { defined } from "../../../lib/core/defined";
import { Milliseconds } from "../../../lib/core/time";
import { newCopyDraftPath, newCopySavedPath } from "../../../lib/paths";
import {
  MainContent,
  PageContentContainer,
} from "../../../components/MainContent";
import {
  getBrowserTitle,
  defaultBrowserTitle,
} from "../../../lib/application/browser/title";
import {
  CategoriesContext,
  AppMessagesContext,
  SharingInfoContext,
  ShowDraftDataContext,
  SaveDocumentContext,
  GeographiesContext,
  DocumentIdContext,
  UserInfoContext,
  TutorialsContext,
} from "../../../lib/application/contexts";
import { DataCardsContainer } from "./DataCardsContainer";
import { config } from "../../../config";
import { docCardsListQuery } from "../../../lib/application/state/stats/document-core/docCardsListState";
import { SharingInfo } from "../../../lib/application/files/SharingInfo";
import { useLoadDocumentAndSharingInfo } from "../../../lib/application/state/actions/load/useLoadDocumentAndSharingInfo";
import { eitherDisplayer } from "../../../components/wrappers/either";
import {
  DefaultLoading,
  NotReadyHttpErrFullPage,
} from "../../../components/Loading";
// TODO#580
// import { useStateTransition } from "../../../lib/application/hooks/useStateTransition";
// import { useDocumentEditMode } from "../../../lib/application/state/stats/useEditMode";
import {
  DocumentMetadataReloader,
  useDocumentMetadataLoader,
} from "../../../lib/application/state/stats/useMetadata";
import { PromiseController } from "../../../lib/application/loading/load_status";
import { HttpResult } from "../../../lib/infra/HttpResult";
import { DocumentMetadata } from "../../../lib/application/state/stats/document-meta/DocumentMetadata";
import { GeographiesSerializable } from "../../../lib/domain/geography";
import { getClaimUserId } from "../../../lib/application/auth/claims";
import { logger } from "../../../lib/infra/logging";
import { AlertRegistrations } from "../../../lib/domain/alerts";
import { AlertBox } from "../../../components/AlertBox";

import { useDocSaveProgress } from "../../../lib/application/state/actions/useDocSaveProgress";
import { useSaveDocument } from "../../../lib/application/state/actions/useSaveDocument";
import { setScrollToCard } from "../../../lib/application/stats/document_position";
import { classNames } from "../../../lib/core/classNames";
import {
  useCardEditMode,
  useDocumentEditMode,
} from "../../../lib/application/state/stats/useEditMode";
import { createColorSchemeContainerWithPalette } from "../../../lib/application/state/stats/document-style/operations";
import { StatsThirdPartyDocPreview } from "./StatsThirdPartyDocPreview";
import { saveTracker } from "../../../lib/application/SaveTracker";
import { Button } from "../../../components/Button";
import { reportMetaStateToPersistent } from "../../../lib/application/state/stats/document-meta/definitions";
import { useAppMessages } from "../../../lib/application/hooks/useAppMessages";

import "./Stats.scss";

export function StatsDocument(props: { documentId: number }) {
  const documentId = props.documentId;
  const reportTitle = useRecoilValue(reportTitleQuery);

  const appMessagesHandler = useContext(AppMessagesContext);
  const geographies = useContext(GeographiesContext);
  const [sharingInfo, reloadSharingInfo] = useLoadDocumentAndSharingInfo(
    documentId,
    appMessagesHandler
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [saveProgress, setSaveProgress] = useDocSaveProgress();

  const { reloadDocumentMetadata, documentMetadata } =
    useDocumentMetadataLoader(documentId);

  const handleSaveDoc = useSaveDocument(documentId, reloadDocumentMetadata);

  useEffect(() => {
    if (defined(reportTitle)) {
      document.title = getBrowserTitle(reportTitle);
      return () => {
        document.title = defaultBrowserTitle();
      };
    }
  }, [reportTitle]);

  const addMaxNumCardsError = useCallback(() => {
    appMessagesHandler?.add("warning", getText("max-num-cards-exceeded"), {
      durationMs: Milliseconds.second * 10,
    });
  }, [appMessagesHandler]);

  return documentMetadata.match({
    notReady: (status) => {
      return <NotReadyHttpErrFullPage left={status} />;
    },
    ready: (metadata) => {
      if (!defined(geographies)) {
        return <DefaultLoading></DefaultLoading>;
      }
      return (
        <SaveDocumentContext.Provider value={handleSaveDoc}>
          <DocumentIdContext.Provider value={documentId}>
            <StatsDocumentInnerLoader
              geographies={geographies}
              reloadSharingInfo={reloadSharingInfo}
              reloadDocumentMetadata={reloadDocumentMetadata}
              addMaxNumCardsError={addMaxNumCardsError}
              documentMetadata={metadata}
              item={sharingInfo}
              documentId={documentId}
            ></StatsDocumentInnerLoader>
          </DocumentIdContext.Provider>
        </SaveDocumentContext.Provider>
      );
    },
  });
}

const StatsDocumentInnerLoader = eitherDisplayer(
  NotReadyHttpErrFullPage,
  StatsDocumentInner
);

function StatsDocumentInner(props: {
  item: SharingInfo;
  geographies: GeographiesSerializable;
  reloadSharingInfo?: () => Promise<HttpResult<unknown>>;
  documentId: number;
  documentMetadata: DocumentMetadata;
  reloadDocumentMetadata: DocumentMetadataReloader;
  addMaxNumCardsError: () => void;
}) {
  const {
    addMaxNumCardsError,
    // documentMetadata,
    reloadDocumentMetadata,
    documentId,
    item: sharingInfo,
  } = props;

  const { user } = useAuth0();
  const userId = defined(user) ? getClaimUserId(user) : undefined;
  const metaState = useRecoilValue(reportMetaStateQuery);

  const [savingFailure, setSavingFailure] = useState<boolean>(false);
  const [savingPostponed, setSavingPostponed] = useState<boolean>(false);

  const cardsList = useRecoilValue(docCardsListQuery);
  const addMicroCard = useAddMicroCardCallback();
  const addTextCard = useAddTextCardCallback();

  const categories = useContext(CategoriesContext);
  const userInfo = useContext(UserInfoContext);

  const adminShowDraftData = useContext(ShowDraftDataContext);
  const [alertRegistrations, setAlertRegistrations] =
    useState<AlertRegistrations | null>(null);
  const { createTutorialsHandler, tutorialsHandler } =
    useContext(TutorialsContext);

  const handleSaveDocument = useContext(SaveDocumentContext);
  const getAllCards = useGetAllCardsCallback(cardsList);
  const appMessages = useAppMessages();

  const { isEditingDocument } = useDocumentEditMode(
    sharingInfo,
    props.documentMetadata
  );

  // TODO#580 -- reinstate notification when document has been updated
  // const documentEditMode = useDocumentEditMode(
  //   sharingInfo,
  //   props.documentMetadata
  // );
  // useStateTransition(documentMetadata, (previous, current) => {
  //   // a) If the current user is editing, we can be sure that the document is up-to-date
  //   if (documentEditMode.isEditingDocument) {
  //     return;
  //   }
  //   // b) If current and previous versions don't match, prompt user to reload
  //   if (!defined(previous) || !defined(current)) {
  //     return;
  //   }

  //   if (previous.version < current.version) {
  //     appMessagesHandler?.add("warning", getText("document-outdated"), {
  //       action: {
  //         text: "Ladda om",
  //         onClick: () => window.location.reload(),
  //       },
  //       durationMs: Milliseconds.second * 10,
  //     });
  //   }
  // });
  const loadAlertRegistrations = useCallback(() => {
    if (defined(userId)) {
      getAlertRegistrations(userId).then((result) => {
        try {
          const registrations = result.unwrap();
          setAlertRegistrations(registrations);
        } catch (e) {
          logger.error(e);
        }
      });
    }
  }, [userId]);

  useEffect(() => {
    if (defined(tutorialsHandler)) {
      return;
    }

    createTutorialsHandler?.();
  }, [tutorialsHandler, createTutorialsHandler]);

  useEffect(() => {
    if (!defined(tutorialsHandler)) {
      return;
    }
    if (sharingInfo.canEdit() && isEditingDocument) {
      tutorialsHandler.register("stats_card_intro");
    } else {
      tutorialsHandler.unregister("stats_card_intro");
    }
  }, [isEditingDocument, sharingInfo, tutorialsHandler]);

  useEffect(loadAlertRegistrations, [loadAlertRegistrations]);

  useEffect(() => {
    /**
     * If the user is an editor, we need to regularly reload document metadata to check
     * if the document is locked.
     */
    if (sharingInfo.canEdit()) {
      const promiseController = new PromiseController();
      const interval = setInterval(() => {
        reloadDocumentMetadata(promiseController);
      }, Milliseconds.second * 10);

      return () => {
        promiseController.stop();
        clearInterval(interval);
      };
    }
  }, [documentId, reloadDocumentMetadata, sharingInfo]);

  useEffect(() => {
    const handle = setInterval(() => {
      const badTimeElapsed = saveTracker.timeInFailedSaveState();
      if (!defined(badTimeElapsed)) {
        setSavingFailure(false);
        return;
      }

      if (badTimeElapsed > config.saveFailureNoticeDelayMs) {
        setSavingFailure(true);
      }
    }, Milliseconds.second * 5);
    return () => clearInterval(handle);
  }, []);

  useEffect(() => {
    const handle = setInterval(() => {
      const badTimeElapsed = saveTracker.timeInPostponedState();
      if (!defined(badTimeElapsed)) {
        setSavingPostponed(false);
        return;
      }

      if (badTimeElapsed > Milliseconds.second * 20) {
        setSavingPostponed(true);
      }
    }, Milliseconds.second * 5);

    return () => clearInterval(handle);
  }, []);

  const handleAddTextCardCK = useCallback(() => {
    if (cardsList.length >= config.maxNumDocCards) {
      addMaxNumCardsError();
      return;
    }
    const newCard = makeTextCardCK();
    setScrollToCard(newCard.id);
    addTextCard(newCard, cardsList.length);
  }, [cardsList.length, addTextCard, addMaxNumCardsError]);

  const addDataCard = useAddStatsCardCallback();
  const addPythonCard = useAddPythonCardCallback();

  const handleAddDataCard = useCallback(() => {
    if (cardsList.length >= config.maxNumDocCards) {
      addMaxNumCardsError();
      return;
    }
    const dataCard = makeDataCardState([], undefined);
    setScrollToCard(dataCard.id);
    addDataCard(
      dataCard,
      cardsList.length,
      createColorSchemeContainerWithPalette(userInfo?.defaultColorPalette())
    );
  }, [cardsList.length, addDataCard, userInfo, addMaxNumCardsError]);

  const handleAddPythonCard = useCallback(() => {
    if (cardsList.length >= config.maxNumDocCards) {
      addMaxNumCardsError();
      return;
    }
    const newCard = makePythonCardState();
    setScrollToCard(newCard.id);
    addPythonCard(newCard, cardsList.length);
  }, [addMaxNumCardsError, addPythonCard, cardsList.length]);

  const handleAddMicroCard = useCallback(() => {
    if (cardsList.length >= config.maxNumDocCards) {
      addMaxNumCardsError();
      return;
    }
    const newCard = makeMicroCard();
    setScrollToCard(newCard.id);
    addMicroCard(
      newCard,
      cardsList.length,
      createColorSchemeContainerWithPalette(userInfo?.defaultColorPalette())
    );
  }, [cardsList.length, addMicroCard, userInfo, addMaxNumCardsError]);

  const sharing = useMemo(
    () => ({
      info: sharingInfo,
      reload: props.reloadSharingInfo,
    }),
    [props.reloadSharingInfo, sharingInfo]
  );

  const handleSaveAs = useCallback(
    (name: string) => {
      window.open(newCopySavedPath(documentId, name), "_blank");
    },
    [documentId]
  );

  const [isSaving, setIsSaving] = useState(false);
  const handleAttemptSave = useCallback(() => {
    if (!defined(handleSaveDocument)) {
      return;
    }
    if (!defined(metaState)) {
      logger.error("Meta state not defined in handleAttemptSave");
      appMessages?.add("error", getText("cant-save-doc-unknown-error"));
      return;
    }

    setSavingPostponed(false);
    setIsSaving(true);
    handleSaveDocument(
      getAllCards(),
      reportMetaStateToPersistent(metaState)
    ).finally(() => setIsSaving(false));
  }, [appMessages, getAllCards, handleSaveDocument, metaState]);

  return (
    <SharingInfoContext.Provider value={sharing}>
      <div className="toolbars">
        <Toolbar
          userId={userId}
          reloadAlertRegistrations={loadAlertRegistrations}
          alertRegistrations={alertRegistrations}
          handleSaveAs={handleSaveAs}
          handleAddPythonCard={handleAddPythonCard}
          handleAddDataCard={handleAddDataCard}
          handleAddNewTextCardCK={handleAddTextCardCK}
          handleAddMicroCard={handleAddMicroCard}
          categories={categories}
        ></Toolbar>
      </div>

      {savingFailure && (
        <PageContentContainer>
          <AlertBox intent="danger" className="margin-y-md margin-right-md">
            <div className="margin-x-sm">
              <h3>Det gick inte att spara dokumentet!</h3>
              <p>
                Ladda om dokumentet eller kontakta support om felet kvarstår.
              </p>
            </div>
          </AlertBox>
        </PageContentContainer>
      )}

      {!savingFailure && savingPostponed && (
        <PageContentContainer>
          <AlertBox intent="warning" className="margin-y-md margin-right-md">
            <div className="margin-x-sm">
              <h3>Ändringar ej sparade</h3>
              <div className="flex-row flex-align-items-center">
                <Button
                  disabled={isSaving}
                  onClick={handleAttemptSave}
                  title="Försök spara nu"
                ></Button>
                {isSaving && <DefaultLoading label="" />}
              </div>
            </div>
          </AlertBox>
        </PageContentContainer>
      )}

      <div
        className={classNames(
          "page-scrollable",
          metaState?.noFramesMode && !isEditingDocument ? "no-frames" : ""
        )}
      >
        <MainContent>
          <>
            {adminShowDraftData && (
              <AlertBox intent="warning" className="margin-y-md">
                <p>
                  Utkastdata visas.{" "}
                  <strong>Inga ändringar du gör i dokumentet sparas</strong>.
                </p>
              </AlertBox>
            )}
          </>

          {metaState?.thirdPartySharingDoc && !isEditingDocument ? (
            <StatsThirdPartyDocPreview />
          ) : (
            <DataCardsContainer
              sharingInfo={sharingInfo}
              documentId={props.documentMetadata.id}
              categories={categories}
              addMaxNumCardsError={() => addMaxNumCardsError()}
            ></DataCardsContainer>
          )}
        </MainContent>
      </div>
    </SharingInfoContext.Provider>
  );
}
