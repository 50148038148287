import { useCallback, useContext } from "react";
import { statsApiV2 } from "../../application/requests/statsApiV2";
import { defined } from "../../core/defined";
import { logger } from "../../infra/logging";
import { AppMessagesContext } from "../../application/contexts";
import { HttpResult, displayHttpErrorInternal } from "../../infra/HttpResult";
import { DimensionV2Dto } from "../../domain/measure/definitions";
import {
  editMicroDimension,
  editStatsDimensionV2,
  editSurveyDimensionV2,
} from "../../application/requests/admin/measure_management/edit";
import { getMicroDimensions } from "../../application/requests/datasets/micro";

export function useLoadStatsDimensions(
  setDimensions: (dims: DimensionV2Dto[]) => void,
  measureId: number | undefined,
  showDraftData: boolean
) {
  const appMessagesHandler = useContext(AppMessagesContext);

  return useCallback(() => {
    if (!defined(measureId)) {
      return;
    }
    statsApiV2.getDimensions(measureId, showDraftData, false).then((res) => {
      res.match({
        ok: (dimensions) => {
          setDimensions(dimensions ?? []);
        },
        err: (err) => {
          logger.error(err);
          appMessagesHandler?.add(
            "error",
            "Kunde inte hamta dimensioner" + displayHttpErrorInternal(err)
          );
        },
      });
    });
  }, [appMessagesHandler, measureId, setDimensions, showDraftData]);
}

export function useLoadMicroDimensions(
  setDimensions: (dims: DimensionV2Dto[]) => void,
  measureId: number | undefined,
  showDraftData: boolean
) {
  const appMessagesHandler = useContext(AppMessagesContext);
  return useCallback(() => {
    if (!defined(measureId)) {
      return;
    }
    getMicroDimensions(measureId, showDraftData).then((res) => {
      res.match({
        ok: (dimensions) => {
          setDimensions(dimensions ?? []);
        },
        err: (err) => {
          logger.error(err);
          appMessagesHandler?.add(
            "error",
            "Kunde inte hamta dimensioner" + displayHttpErrorInternal(err)
          );
        },
      });
    });
  }, [appMessagesHandler, measureId, setDimensions, showDraftData]);
}

export function useSaveDimensionsOrder(loadDimensions: () => void) {
  const appMessagesHandler = useContext(AppMessagesContext);

  return useCallback(
    (dims: DimensionV2Dto[]): Promise<HttpResult<void>> => {
      return Promise.all(
        dims.map((dFull) => {
          const d = { ...dFull };
          switch (d.type) {
            case "stats_breakdown":
              return editStatsDimensionV2(d.dimension_id, d);
            case "survey_background":
            case "survey_subquestion":
            case "survey_value":
              return editSurveyDimensionV2(d.dimension_id, d);
            case "mikro_breakdown":
              return editMicroDimension(d.dimension_id, d);
          }
          throw new Error("Invalid dimension type");
        })
      ).then((results) => {
        const errs = results
          .map((r) => r.match({ err: (e) => e, ok: () => undefined }))
          .filter(defined);
        if (errs.length > 0) {
          appMessagesHandler?.add(
            "error",
            "Kunde inte spara ordning på dimensioner"
          );
          return Promise.resolve(HttpResult.fromErr(errs[0]));
        } else {
          appMessagesHandler?.add("success", "Ordning på dimensioner sparad");
          loadDimensions();
          return Promise.resolve(HttpResult.fromOk(undefined));
        }
      });
    },
    [appMessagesHandler, loadDimensions]
  );
}
