import * as _ from "lodash";

import { defined } from "../../../core/defined";
import { BoundingBox } from "../../../domain/cartography/BoundingBox";
import { LatLngRaw } from "../../../domain/cartography/types";
import { GeoType } from "../../../domain/geography";
import { StatsDataset } from "./StatsDataset";
import { Dimension, NO_VALUE_MARKER } from "../shared/core/definitions";
import { RowBaseInterface } from "../shared/row";
import { GeoSelections } from "../../state/stats/document-core/core";
import { shortenMunicipalityLabel } from "../../../domain/names";
import {
  BackgroundMapData,
  DrawableGeodata,
  SingleMapData,
  StylingInfo,
  SvgPathOptions,
} from "../map/types";
import { getColorScaleInfo } from "../map/scales";
import { NarrowedDataset } from "./NarrowedDataset";
import { logger } from "../../../infra/logging";
import { RowValueRegularRaw } from "../../../infra/api_responses/dataset";
import { ChartDataUnknown } from "../shared/chart_data/ChartData";
import { config } from "../../../../config";
import { DataOutputSettings } from "../../state/stats/document-core/DataOutputSettings";

export const MISSING_VALUE_PATTERN_ID = "diagonalHatch";
export const MISSING_VALUE_FILL_VALUE = `url('#${MISSING_VALUE_PATTERN_ID}')`;

export const MAP_BACKGROUND_FILL_COLOR = "#eee";
const mapStrokeColor = "grey";

export type PolygonGetter = (
  geoType: GeoType,
  geoname: string
) => GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> | undefined;

export interface MapStatsData {
  numMaps: number;
  geoType: GeoType;
  valueFormatter: (value: number) => string;
  data: DrawableGeodata | undefined;
  numMapsLimitReached: boolean;
}

export function createMapDataset(
  originalDataset: StatsDataset,
  geoSelections: GeoSelections,
  lookupPolygon: PolygonGetter,
  settings?: DataOutputSettings
): MapStatsData {
  const mapDatasetCollection = originalDataset.convertForMapChart(
    config.statsMapChartsLimit
  );
  const singleGeoTypeUnchecked = _.uniq(
    mapDatasetCollection.sets
      .map((d) => d.dataset.singleSelectedGeoType)
      .filter(defined)
  );
  if (singleGeoTypeUnchecked.length > 1) {
    throw new Error("Must select a single geo type");
  }
  const singleGeoType = singleGeoTypeUnchecked[0];
  const selectedGeonames =
    geoSelections[singleGeoType]?.map((s) => s.label) ?? [];

  const fixatedPolygonLookup = (geoname: string) =>
    lookupPolygon(singleGeoType, geoname);

  const chartData = mapDatasetCollection.sets.map((d) => d.dataset.chartData());

  return {
    numMaps: mapDatasetCollection.sets.length,
    numMapsLimitReached: mapDatasetCollection.limitReached,
    geoType: singleGeoType,
    valueFormatter: getFormatter(originalDataset, chartData[0]),
    data: getData(
      originalDataset,
      mapDatasetCollection.sets,
      chartData,
      fixatedPolygonLookup,
      selectedGeonames,
      settings
    ),
  };
}

function getFormatter(
  originalDataset: StatsDataset,
  chartData: ChartDataUnknown
): (value: number) => string {
  if (originalDataset.primaryValueType === "category") {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (value: any) => value;
  }

  const formatter = chartData.dimensionFormatter;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (value: any) => formatter?.(Dimension.value, value) ?? value;
}

function getData(
  originalDataset: StatsDataset,
  mapDatasets: NarrowedDataset[],
  chartData: ChartDataUnknown[],
  polygonLookup: (
    geoname: string
  ) => GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties> | undefined,
  selectedGeonames: string[],
  settings?: DataOutputSettings
): DrawableGeodata | undefined {
  if (chartData.length === 0) {
    return;
  }

  const defaultPairs: RegionValuePair[] = selectedGeonames.map(
    // Municipality labels are always shortened. This might need to be reconsidered.
    // TODO#283
    (geoname) => [shortenMunicipalityLabel(geoname), NO_VALUE_MARKER]
  );

  function getStyleInfo(): StylingInfo {
    const scaleInfo = getColorScaleInfo(originalDataset, chartData, settings);
    return {
      scaleInfo,
      featureToStyle: (f: GeoJSON.Feature | undefined): SvgPathOptions => {
        if (!defined(f)) {
          return {};
        }
        const value = f.properties?.[Dimension.value];
        return {
          fillColor:
            value === NO_VALUE_MARKER
              ? MISSING_VALUE_FILL_VALUE
              : scaleInfo.valueToColor(value),
          color: mapStrokeColor,
          weight: 1,
          fillOpacity: 1,
        };
      },
    };
  }

  const maps = chartData.map((data, i) =>
    buildSingleMapData(
      buildRegionValuePairs(defaultPairs, data.rows),
      mapDatasets[i].dataset.key,
      polygonLookup,
      mapDatasets[i].labelTexts
    )
  );
  return { style: getStyleInfo(), data: maps };
}

function buildRegionValuePairs(
  defaults: RegionValuePair[],
  rows: RowBaseInterface<RowValueRegularRaw>[]
): RegionValuePair[] {
  const d1RegionValueDict = _.fromPairs(defaults);
  for (const row of rows) {
    const value = row.range();
    const region = row.dimension(Dimension.region);
    d1RegionValueDict[region] = value;
  }
  return _.toPairs(d1RegionValueDict);
}

export function buildBackgroundMap(
  geoType: GeoType,
  collection: GeoJSON.GeoJSON
): BackgroundMapData {
  if (collection.type !== "FeatureCollection") {
    throw new Error("Could not find background base country shape");
  }

  return {
    key: `se-background-${geoType}`,
    featuresCollection: collection,
    style: {
      fillColor: MAP_BACKGROUND_FILL_COLOR,
      fillOpacity: 1,
      opacity: 1,
      color: mapStrokeColor,
      weight: 0.2,
    },
  };
}

type RegionValuePair = [region: string, value: number | typeof NO_VALUE_MARKER];

function buildSingleMapData(
  regionValuePairs: RegionValuePair[],
  datasetKey: string,
  lookupFeature: (geoname: string) => ReturnType<PolygonGetter>,
  labelTexts: string[]
): SingleMapData {
  let hasMissingData = false;
  const features: GeoJSON.Feature[] = [];

  for (const pair of regionValuePairs) {
    const region = pair[0];
    const value = pair[1];
    if (value === NO_VALUE_MARKER) {
      hasMissingData = true;
    }

    const feature = lookupFeature(region);
    if (defined(feature)) {
      features.push({
        ...feature,
        id: region + "," + value,
        properties: {
          ...feature.properties,
          name: region,
          [Dimension.value]: value,
        },
      });
    }
  }

  const anyPosition = getAnyPosition(features[0]);
  const boundingBox = BoundingBox.fromCorners(anyPosition, anyPosition);

  for (const feature of features) {
    const geometry = feature.geometry;
    if (geometry.type === "Polygon") {
      for (const ring of geometry.coordinates) {
        for (const point of ring) {
          const latLng: LatLngRaw = geoJsonPositionToLatLngRaw(point);
          boundingBox.extendMut(latLng);
        }
      }
    } else if (geometry.type === "MultiPolygon") {
      for (const polygon of geometry.coordinates) {
        for (const ring of polygon) {
          for (const point of ring) {
            const latLng: LatLngRaw = geoJsonPositionToLatLngRaw(point);
            boundingBox.extendMut(latLng);
          }
        }
      }
    } else {
      logger.error("Unexpected geometry type: " + geometry.type);
    }
  }

  return {
    labelTexts,
    key: datasetKey + "_" + labelTexts.join(", "),
    hasMissingData,
    boundingBox,
    featureCollection: { type: "FeatureCollection", features },
  };
}

function getAnyPosition(feature: GeoJSON.Feature): LatLngRaw {
  const geometry = feature.geometry;
  const position =
    geometry.type === "Polygon"
      ? geometry.coordinates[0][0]
      : geometry.type === "MultiPolygon"
      ? geometry.coordinates[0][0][0]
      : undefined;
  if (!defined(position)) {
    throw new Error("Could not find any position in feature");
  }
  return geoJsonPositionToLatLngRaw(position);
}

function geoJsonPositionToLatLngRaw(position: GeoJSON.Position): LatLngRaw {
  return [position[1], position[0]];
}
