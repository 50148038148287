export function assertDefined<T>(
  arg: T | undefined,
  label?: string
): asserts arg is T {
  const info = label !== undefined ? " | " + label : "";
  if (arg === undefined) {
    throw new Error("assertDefined given undefined value" + info);
  }
}

export function assertNever(arg: never): never {
  throw new Error("assertNever should never be reached");
}
