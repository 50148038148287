import { DEFAULT_AXIS_COLOR } from "../../../../lib/application/stats/shared/core/colors/colors";
import { MainChartDimensions } from "../../../../lib/application/stats/shared/core/definitions";

interface Props {
  dims: MainChartDimensions;
  verticalAxisOffset?: number;
  customStrokeColor?: string | null;
  children?: JSX.Element;
}
export function AxisLineLeft(props: Props) {
  const { dims, children, customStrokeColor } = props;
  if (customStrokeColor === null) {
    return null;
  }
  return (
    <g transform={`translate(${dims.marginLeft}, ${dims.marginTop})`}>
      <line
        x1={0 + (props.verticalAxisOffset ?? 0)}
        y1="0"
        x2={0 + (props.verticalAxisOffset ?? 0)}
        y2={dims.boundedHeight}
        stroke={customStrokeColor ?? DEFAULT_AXIS_COLOR}
        strokeWidth="1"
        fill="none"
        shapeRendering="crispEdges"
      />
      {children}
    </g>
  );
}
