import {
  isMicroMeasureRegularDto,
  measureToMeasureSpecMicro,
} from "../../application/state/stats/document-core/core-micro";
import { defined } from "../../core/defined";
import { MicroMeasureDto } from "../../infra/api_responses/micro_dataset";
import { GeoTypeMicro } from "../geography";
import { DimensionV2Dto, MeasureSpecMicro } from "../measure/definitions";
import { TimeResolution } from "../time";

// Duplicated here to avoid incorrect import dependency
type MicroSubjectPath = (string | undefined)[];

export class MicroMeasure {
  private _resolution: TimeResolution;
  constructor(
    private _measure: MicroMeasureDto,
    private _dimensions: DimensionV2Dto[] | null
  ) {
    this._resolution = TimeResolution.deserialize(this._measure.resolution);
  }

  get id(): number {
    return this._measure.mikro_id;
  }

  get resolution(): TimeResolution {
    return this._resolution;
  }

  isPrimary(): boolean {
    return (
      this._measure.value_type === "integer" ||
      this._measure.value_type === "decimal"
    );
  }

  isComputed(): boolean {
    return isMicroMeasureRegularDto(this._measure)
      ? defined(this._measure.computed_measurement_type)
      : false;
  }

  measureSpec(): MeasureSpecMicro {
    const measure = this._measure;
    const dimensions = this._dimensions;
    return measureToMeasureSpecMicro(measure, dimensions ?? []);
  }

  dimensionsDto(): DimensionV2Dto[] | null {
    return this._dimensions;
  }

  subjectPath(): MicroSubjectPath {
    return [this._measure.area, this._measure.subarea, this._measure.subject];
  }

  dto(): MicroMeasureDto {
    return this._measure;
  }

  displayDimensionByColumn(column: string): string | undefined {
    return this._dimensions?.find((d) => d.data_column === column)?.label;
  }

  geotypeSupported(geotype: GeoTypeMicro): boolean {
    return (
      isMicroMeasureRegularDto(this._measure) &&
      this._measure.geo_types.includes(geotype)
    );
  }

  displayDimensionValue(
    dimensionId: string,
    value: string
  ): string | undefined {
    return this._dimensions
      ?.find((d) => d.dimension_id.toString() === dimensionId)
      ?.values?.find((v) => v.label === value)?.label;
  }
}
