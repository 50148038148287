import { IconProps } from "@blueprintjs/core";
import { chain, flatten } from "lodash";
import { useRecoilValue, useRecoilState, RecoilRoot } from "recoil";
import { Pivot, PivotItem, ThemeProvider } from "@fluentui/react";
import React, { useCallback, useContext, useMemo, useEffect } from "react";
import useResizeObserver from "use-resize-observer";
import { PivotPlaceholder } from "../data_card/PivotPlaceholder";
import { TableTab } from "../card_general/TableTab";
import { MapTab } from "../data_card/output_tabs/MapTab";
import { ChartTab } from "../card_general/ChartTab";
import { InfoTab } from "../card_general/InfoTab";
import {
  DefaultLoading,
  DefaultLoadingStretchedToFit,
} from "../../../../../components/Loading";
import {
  DataOutputView,
  GeoSelections,
} from "../../../../../lib/application/state/stats/document-core/core";
import {
  supportedGeographiesQuery,
  primarySelectionQuery,
  selectedCardViewQuery,
  singleDataCardQuery,
  currentlyValidGeoSelection,
  getGeoSelections,
} from "../../../../../lib/application/state/stats/document-core/queries/dataCard";
import { classNames } from "../../../../../lib/core/classNames";
import { defined } from "../../../../../lib/core/defined";
import { GeographiesSerializable } from "../../../../../lib/domain/geography";
import { AlertBox } from "../../../../../components/AlertBox";
import { useLoadEmbeddedDoc } from "../../../../../lib/application/state/actions/load/useLoadEmbeddedDoc";
import { docCardsListState } from "../../../../../lib/application/state/stats/document-core/atoms";
import { fluentUITheme } from "../../../../../lib/application/theme";
import { EmbeddedCardErrorBoundary } from "../../../../../components/errors/EmbeddedCardErrorBoundary";
import { useDatasetError } from "../../../../../lib/application/state/stats/useDataset";
import { MeasureSelectionComponentRegular } from "../data_card/selections/MeasureSelectionComponentRegular";
import { MeasureSelection } from "../../../../../lib/domain/selections/definitions";
import { TimeSelect } from "../card_general/TimeSelect";
import { Button } from "../../../../../components/Button";
import { useToggle } from "../../../../../lib/application/hooks/useToggle";
import { DataCardGeoModal } from "../card_general/DataCardGeoModal";
import { truncate } from "../../../../../lib/core/truncate";

import "./DataCardEmbed.scss";
import { EmbedSettings } from "../../../../../lib/application/embedded_cards";
import { HttpErrorNotice } from "../../../../../components/errors/HttpErrorNotice";
import {
  CardUpdateCountContext,
  GeographiesContext,
  ShowDraftDataContext,
} from "../../../../../lib/application/contexts";
import { Progress } from "../../../../../lib/core/progress";
import {
  useActivateLockToLatestTime,
  useChangeTime,
  useUnsetLockToLatestTime,
} from "../../../../../lib/application/state/actions/selections/useChangeTime";
import { usePrimarySelectionChanger } from "../../../../../lib/application/state/actions/selections/shared/usePrimarySelectionChanger";
import { useChangeDataOutputSettings } from "../../../../../lib/application/state/actions/selections/useChangeDataOutputSettings";
import { cardColors } from "../../../../../lib/application/state/stats/document-core/queries/shared";
import { wait } from "../../../../../lib/core/wait";
import { cardWithUpdatedDataState } from "../../../../../lib/application/state/stats/cardToDataStateStats";
import { useSaveCard } from "../../../../../lib/application/state/actions/useSaveDocument";
import { logger } from "../../../../../lib/infra/logging";
import { SurveyStringDataset } from "../../../../../lib/application/stats/datasets/SurveyStringDataset";
import { DataOutputToolbar } from "../card_general/DataOutputToolbar";
import { useExtendedAppearanceSettings } from "../../../../../lib/application/state/actions/useExtendedAppearanceSettings";

const pivots: Array<{
  key: DataOutputView;
  title: string;
  icon: IconProps["icon"];
  component?: JSX.Element;
}> = [
  {
    key: "diagram",
    title: "Diagram",
    icon: "grouped-bar-chart",
  },
  { key: "table", title: "Tabell", icon: "th" },
  { key: "map", title: "Karta", icon: "map" },
  { key: "info", title: "Info", icon: "info-sign" },
];

export function EmbedWrapper(props: { embedKey: string }) {
  return (
    <EmbeddedCardErrorBoundary>
      <RecoilRoot>
        <ThemeProvider className="theme-provider-root" theme={fluentUITheme}>
          <EmbedLoader embedKey={props.embedKey}></EmbedLoader>
        </ThemeProvider>
      </RecoilRoot>
    </EmbeddedCardErrorBoundary>
  );
}

function EmbedLoader(props: { embedKey: string }) {
  const status = useLoadEmbeddedDoc(props.embedKey);
  const docLoadErr = useMemo(() => status.getErr(), [status]);
  const cardList = useRecoilValue(docCardsListState);
  const geographies = useContext(GeographiesContext);
  const embedSettings = useMemo(() => {
    return status.match({
      ready: (doc) => doc.embedSettings,
      notReady: () => undefined,
    });
  }, [status]);

  if (defined(docLoadErr)) {
    return <HttpErrorNotice error={docLoadErr} />;
  }
  if (!status.isReady()) {
    return <DefaultLoadingStretchedToFit />;
  }
  if (!defined(geographies) || !defined(embedSettings)) {
    return <DefaultLoadingStretchedToFit />;
  }
  if (cardList.length !== 1) {
    return (
      <HttpErrorNotice error={{ code: "unknown-error" }}></HttpErrorNotice>
    );
  }
  return (
    <DataCardEmbed
      embedSettings={embedSettings}
      geographies={geographies}
      embedKey={props.embedKey}
      cardStateId={cardList[0].id}
    ></DataCardEmbed>
  );
}

export function DataCardEmbed(props: {
  embedSettings: EmbedSettings;
  cardStateId: string;
  geographies: GeographiesSerializable;
  embedKey: string;
}) {
  const { ref, width, height } = useResizeObserver();
  useEffect(() => {
    if (!defined(width) || !defined(height)) {
      return;
    }
    window.parent.postMessage(
      {
        type: "infostat-resize",
        width: width + 1,
        height: height + 1,
        key: props.embedKey,
      },
      "*"
    );
  }, [height, width, props.embedKey]);

  const isEditing = true;
  const geographies = props.geographies;
  const embedSettings = props.embedSettings;
  const queryProps = { cardStateId: props.cardStateId };

  const primarySelection = useRecoilValue(primarySelectionQuery(queryProps));
  const handleChangePrimarySelection = usePrimarySelectionChanger(
    props.cardStateId,
    primarySelection,
    geographies
  );
  const [settings, setSettings] = useChangeDataOutputSettings(
    props.cardStateId
  );
  const appearanceSettings = useExtendedAppearanceSettings();
  const supportedGeographies = useRecoilValue(
    supportedGeographiesQuery(queryProps)
  );
  const [card, setCard] = useRecoilState(
    singleDataCardQuery({ cardStateId: props.cardStateId })
  );
  const saveCard = useSaveCard();
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const [geoModalOpen, toggleGeoModalOpen] = useToggle(false);
  const measureSelection = primarySelection?.measureSelection;
  const geoSelections = useMemo(
    () => getGeoSelections(card, geographies),
    [card, geographies]
  );
  const geoSelectionString: string = listSelections(geoSelections);
  const selectedKey = useRecoilValue(selectedCardViewQuery(queryProps));

  const handleUpdateTime = useChangeTime(card);
  const handleUnsetLockToLatestTime = useUnsetLockToLatestTime(
    card,
    geographies,
    adminShowDraftData
  );
  const handleActivateLockToLatestTime = useActivateLockToLatestTime(
    card,
    geographies,
    adminShowDraftData
  );

  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const handleLinkClick = useCallback(
    async (item?: PivotItem) => {
      if (!defined(item)) {
        return;
      }

      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const updatedCard = {
        ...card,
        data: {
          ...card.data,
          selectedView: item.props.itemKey as any,
        },
      };

      setCard(updatedCard);
      await wait(0);

      const cardWithData = await cardWithUpdatedDataState(
        shouldAbort,
        updatedCard,
        geographies,
        adminShowDraftData,
        appearanceSettings,
        cardColors(card)
      );
      if (shouldAbort() || !defined(cardWithData)) {
        return;
      }
      setCard(cardWithData);
      saveCard?.(cardWithData);
    },
    [
      adminShowDraftData,
      card,
      appearanceSettings,
      geographies,
      getCurrentCount,
      incrementUpdateCount,
      saveCard,
      setCard,
    ]
  );

  const handleSetMeasureSelection = useCallback(
    (m: MeasureSelection) => {
      const prev = primarySelection;
      if (!defined(prev)) {
        return;
      }
      handleChangePrimarySelection({ ...prev, measureSelection: m });
    },
    [handleChangePrimarySelection, primarySelection]
  );

  const onlyCountrySupported =
    supportedGeographies.length === 1 && supportedGeographies[0] === "country";

  const unsupportedTabs: DataOutputView[] = chain([
    defined(primarySelection) &&
    primarySelection.measureSelection?.measure.value_type === "category"
      ? ("diagram" as const)
      : undefined,
    onlyCountrySupported ? ("map" as const) : undefined,
    ...(embedSettings.hideTabs ?? []),
  ])
    .filter(defined)
    .uniq()
    .value();

  const supportedTabs = pivots.filter((p) => !unsupportedTabs.includes(p.key));
  const selectedPivot = unsupportedTabs.includes(selectedKey)
    ? supportedTabs[0]
    : pivots.find((p) => p.key === selectedKey);

  if (
    card.initState === Progress.NotStarted ||
    card.initState === Progress.InProgress
  ) {
    return <DefaultLoadingStretchedToFit />;
  }

  if (!defined(measureSelection)) {
    logger.error("No measure selection defined");
    return null;
  }
  if (
    measureSelection.valueType === "survey" ||
    measureSelection.valueType === "survey_string"
  ) {
    logger.error(
      "Survey measures not supported in embedded cards. Current valueType: " +
        measureSelection.valueType
    );
    return null;
  }

  const showGeo = !onlyCountrySupported && embedSettings.showGeoSelector;
  const noControls =
    !embedSettings.showBreakdowns && !showGeo && !embedSettings.showTimeline;

  return (
    <div
      className={"embedded-card-container embed-" + props.embedKey}
      ref={ref}
    >
      {embedSettings.showBreakdowns && (
        <MeasureSelectionComponentRegular
          hideMeasure
          isGroupingSelection={false}
          measureSelection={measureSelection}
          setMeasureSelection={handleSetMeasureSelection}
        ></MeasureSelectionComponentRegular>
      )}
      {showGeo && (
        <div className="simple-geo-display">
          <p>
            <strong>Valda geografiska områden</strong>: {geoSelectionString}
          </p>{" "}
          <Button small title="Ändra" onClick={toggleGeoModalOpen}></Button>
        </div>
      )}
      {embedSettings.showTimeline && (
        <TimeSelect
          setLockToLatestTime={handleActivateLockToLatestTime}
          updateCardStateWithTimeRange={handleUpdateTime}
          unsetLockToLatestTime={handleUnsetLockToLatestTime}
          appMode="embedded"
          cardStateId={props.cardStateId}
        ></TimeSelect>
      )}
      {geoModalOpen && (
        <DataCardGeoModal
          cardStateId={props.cardStateId}
          geographies={geographies}
          supportedGeographies={supportedGeographies}
          primarySelection={primarySelection}
          handleClose={toggleGeoModalOpen}
        ></DataCardGeoModal>
      )}

      <div
        className={classNames(
          isEditing ? "editing" : "read-only",
          noControls ? "no-controls" : "",
          "stats-geo-and-output-container"
        )}
      >
        <div className="stats-output-viewer">
          {isEditing && (
            <div className={classNames("menu-container", "tools-surface")}>
              {supportedTabs.length > 1 && (
                <Pivot
                  className="pivot-menu"
                  selectedKey={selectedKey}
                  getTabId={(itemKey) => itemKey}
                  onLinkClick={handleLinkClick}
                >
                  {supportedTabs.map((p) => (
                    <PivotItem
                      key={p.title}
                      itemKey={p.key}
                      headerText={p.title}
                    ></PivotItem>
                  ))}
                </Pivot>
              )}
              <DataOutputToolbar
                cardRaw={card}
                isEditing={true}
                isThirdPartyDoc={false}
                settings={settings}
                setSettings={setSettings}
                showToolbar={embedSettings.showToolbar}
                showToolbarDownload={embedSettings.showToolbarDownload}
                showToolbarSettings={embedSettings.showToolbarSettings}
              />
            </div>
          )}
          <div className={classNames("tab-content-with-toolbar")}>
            <React.Suspense
              key={queryProps.cardStateId}
              fallback={<DefaultLoading></DefaultLoading>}
            >
              <TabContent
                selectedPivot={selectedPivot}
                cardStateId={props.cardStateId}
                geographies={geographies}
              ></TabContent>
            </React.Suspense>
          </div>
        </div>
      </div>
    </div>
  );
}

function TabContent(props: {
  cardStateId: string;
  geographies: GeographiesSerializable;
  selectedPivot?: (typeof pivots)[number];
}) {
  const { selectedPivot, cardStateId, geographies } = props;
  const datasetError = useDatasetError(cardStateId);
  const [settings, setSettings] = useChangeDataOutputSettings(cardStateId);
  const currentCard = useRecoilValue(singleDataCardQuery({ cardStateId }));
  const geoSelections = useMemo(
    () => currentlyValidGeoSelection(currentCard, geographies),
    [currentCard, geographies]
  );
  const loadedData = currentCard.data.loadedData;

  if (!defined(currentCard) || !defined(loadedData)) {
    return <PivotPlaceholder icon={selectedPivot?.icon}></PivotPlaceholder>;
  }
  if (
    selectedPivot !== undefined &&
    !["table", "diagram", "info", "map"].includes(selectedPivot.key)
  ) {
    return <PivotPlaceholder icon={selectedPivot.icon}></PivotPlaceholder>;
  }

  if (loadedData?.progress?.type === Progress.InProgress) {
    return <DefaultLoading></DefaultLoading>;
  }
  if (defined(datasetError)) {
    return (
      <AlertBox className="margin-x-sm margin-y-md" intent="warning">
        <span>{datasetError.displayMessage()}</span>
      </AlertBox>
    );
  }
  const dataset = loadedData.dataset;

  if (dataset instanceof SurveyStringDataset) {
    throw new Error("SurveyStringDataset not supported in embedded cards");
  }

  return (
    <>
      {selectedPivot?.key === "table" && (
        <TableTab
          settings={settings}
          setSettings={setSettings}
          tableDataState={loadedData.tableDataState}
        ></TableTab>
      )}
      {selectedPivot?.key === "map" && (
        <MapTab
          isReloadingData={false} // FIXME
          geoSelections={geoSelections}
          dataset={dataset}
          cardId={currentCard.id}
          settings={settings}
        ></MapTab>
      )}
      {selectedPivot?.key === "diagram" && (
        <ChartTab
          cardId={currentCard.id}
          chartDataState={loadedData.chartDataState}
        ></ChartTab>
      )}
      {selectedPivot?.key === "info" && (
        <InfoTab dataset={dataset} cardId={currentCard.id}></InfoTab>
      )}
    </>
  );
}

function listSelections(selection: GeoSelections): string {
  const maxLen = 30;
  const entries = flatten(Object.values(selection));
  if (entries.length > 3) {
    return (
      entries
        .slice(0, 3)
        .map((e) => e.label)
        .join(", ")
        .slice(0, maxLen) + "..."
    );
  }
  return truncate(entries.map((item) => item.label).join(", "), maxLen);
}
