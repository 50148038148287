export function calcPermutations<T>(items: T[]): T[][] {
  if (items.length <= 1) {
    return [items];
  }

  let result: T[][] = [];
  for (const permutation of calcPermutations(items.slice(1))) {
    const combos: T[][] = [];
    for (let i = 0; i < items.length; i++) {
      combos.push(
        permutation.slice(0, i).concat([items[0]]).concat(permutation.slice(i))
      );
    }

    result.push(...combos);
  }
  return result;
}
