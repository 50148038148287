import { useState, useCallback } from "react";
import { MicroMapDownloadContext, MicroMapDownloadFunc } from "../contexts";
import { voidFunc } from "../../core/voidFunc";

export function MicroMapDownloadEventProvider(props: {
  children: JSX.Element;
}) {
  const [eventListener, setEventListener] =
    useState<MicroMapDownloadFunc>(voidFunc);

  const emitEvent = useCallback(() => {
    eventListener();
  }, [eventListener]);

  const listenEvent = useCallback((callback: MicroMapDownloadFunc) => {
    setEventListener(() => callback);
  }, []);

  return (
    <MicroMapDownloadContext.Provider value={{ emitEvent, listenEvent }}>
      {props.children}
    </MicroMapDownloadContext.Provider>
  );
}
