import { useCallback } from "react";

import { NewMetadataInput } from "../../../../../lib/domain-admin/metadata";
import { statsEntriesV2 } from "./shared";
import { StepFooter } from "./StepFooter";
import { CompleteData1, CompleteData2 } from "./types";
import { MetadataFieldView } from "./MetadataFieldView";
import { useMetadataInputs } from "./useMetadataInputs";

export function Step2Meta(props: {
  data: CompleteData1;
  handlePrevious: (data: CompleteData1) => void;
  handleNext: (data: CompleteData2) => void;
}) {
  const step2Data = props.data.step2;

  const {
    textInputs,
    selectInputs,
    updateInput,
    collectData,
    breakdownParents,
    setBreakdownParents,
    breakdowns,
    setBreakdowns,
    updateSelect,
    multiselectInputs,
    updateMultiselect,
    allValid,
  } = useMetadataInputs<keyof NewMetadataInput>(statsEntriesV2, step2Data);

  const handlePrevious = useCallback(() => {
    props.handlePrevious({
      ...props.data,
      step2: { ...props.data.step2, ...collectData() },
    });
  }, [collectData, props]);

  return (
    <div>
      <h2>Stats</h2>
      <section>
        {statsEntriesV2.map((field) => {
          return (
            <MetadataFieldView
              key={field.key}
              field={field}
              textInputs={textInputs}
              breakdownParents={breakdownParents}
              updateBreakdownParents={setBreakdownParents}
              breakdowns={breakdowns}
              updateBreakdowns={setBreakdowns}
              selectInputs={selectInputs}
              updateInput={updateInput}
              updateSelect={updateSelect}
              multiselectInputs={multiselectInputs}
              updateMultiselect={updateMultiselect}
            ></MetadataFieldView>
          );
        })}
      </section>

      <StepFooter
        handlePrevious={handlePrevious}
        handleNext={
          allValid
            ? () => {
                props.handleNext({
                  ...props.data,
                  step2: collectData(),
                });
              }
            : undefined
        }
      ></StepFooter>
    </div>
  );
}
