import mapboxgl from "mapbox-gl";
import { useCallback, useEffect, useMemo } from "react";

import { ToggleStateAndActions } from "../../../../../lib/application/hooks/useToggle";
import { defined } from "../../../../../lib/core/defined";

const eventNames = ["fullscreenchange", "webkitfullscreenchange"];

export function useFullscreen(
  toggleState: ToggleStateAndActions,
  map?: mapboxgl.Map
) {
  const container = map?.getContainer().closest(".fullscreen-container");

  const [isFullscreen, toggleFullscreen] = toggleState;

  useEffect(() => {
    function fullscreenListener() {
      toggleFullscreen();
    }

    const changeEvent = eventNames.find((name) =>
      defined("on" + name in document)
    );
    if (!defined(changeEvent)) {
      return;
    }
    document.addEventListener(changeEvent, fullscreenListener);
    return () => {
      document.removeEventListener(changeEvent, fullscreenListener);
    };
  }, [toggleFullscreen]);

  const requestFunc: ((opts?: FullscreenOptions) => Promise<void>) | undefined =
    useMemo(
      () =>
        container?.requestFullscreen?.bind(container) ??
        (container as any)?.webkitRequestFullscreen?.bind(container),
      [container]
    );
  const requestCancelFunc: () => Promise<void> | undefined = useMemo(
    () =>
      window.document?.exitFullscreen?.bind(window.document) ??
      (window.document as any).webkitCancelfullscreen?.bind(window.document),
    []
  );

  const fullscreenSupported =
    defined(requestFunc) &&
    (window.document.fullscreenEnabled ||
      (window.document as any).webkitFullscreenEnabled);

  const handleFullscreen = useCallback(() => {
    if (isFullscreen) {
      return requestCancelFunc?.();
    }
    const res = requestFunc?.();
    if (res instanceof Promise) {
      res.then(() => {
        // Fullscreen promise done
      });
    }
    return res;
  }, [isFullscreen, requestCancelFunc, requestFunc]);

  return { isFullscreen, fullscreenSupported, handleFullscreen };
}
