import { SvgTextAnchor } from "../../../lib/application/stats/shared/core/definitions";
import { TextStyle } from "../../../lib/application/stats/shared/core/TextStyle";
import {
  PositionedTextLine,
  TextRowWithOffset,
} from "../../../lib/application/stats/shared/core/text_containers";
import { translate } from "../../../lib/application/stats/svg";
import { defined } from "../../../lib/core/defined";
import { Position2D } from "../../../lib/core/space/position";
import { DEFAULT_CHART_TEXT_COLOR } from "../../../lib/application/stats/shared/core/colors/colors";

interface Props {
  row: TextRowWithOffset;
  fontColor?: string | null;
}
export function RenderOffsetTextRow(props: Props) {
  const row = props.row;
  return (
    <text
      dominantBaseline=""
      key={row.text + "-" + row.offsetY}
      transform={translate(0, row.offsetY + row.rowHeightWithPadding * 0.9)}
      fill={props.fontColor ?? DEFAULT_CHART_TEXT_COLOR}
      {...row.textStyle.svgFontAttrs()}
    >
      {row.text}
    </text>
  );
}

/**
 *
 * Renders a PositionedTextLine as SVG text.
 *
 * Rotations don't make sense for all text alignments. This component supports rotations for
 * mid-point alignment only at the moment.
 *
 * Rotation is applied around the text's bottom midpoint. See graphics.
 *
 *                          xxxxx
 *    CCW rotation         xxxx
 *                          x  xx
 *                          x    xx
 *                                xx
 * ┌──────────────────────────┐     x
 * │                          │     x
 * │        SOME TEXT         │     x
 * │                          │     x
 * │                          │    xx
 * └────────────xx────────────┘   xx
 *               ▲              xxx
 *               │            xxx
 *               │        xxxx
 *               │
 *
 *
 *      ┌─────────┐
 *      │         │
 *      │    T    │
 *      │    X    │
 *      │    E    │
 *      │    T    │
 *      │        xx ◄─────────
 *      │         │
 *      │    E    │
 *      │    M    │
 *      │    O    │
 *      │    S    │
 *      │         │
 *      └─────────┘
 *
 * For CW rotation, the principle is the same, but the end result will be like below.
 * The original text bottom midpoint is marked xx.
 *
 *      ┌─────────┐
 *      │         │
 *      │    S    │
 *      │    O    │
 *      │    M    │
 *      │    E    │
 *     xx         |
 *      │         │
 *      │    T    │
 *      │    E    │
 *      │    X    │
 *      │    T    │
 *      │         │
 *      └─────────┘
 *
 */
export function RenderPositionedTextLine(props: {
  line: PositionedTextLine;
  fontColor?: string | null;
  textStyle: TextStyle;
  anchor?: SvgTextAnchor;
  transform?: string;
  className?: string;
  dominantBaseline?: React.SVGAttributes<SVGTextElement>["dominantBaseline"];
  lengthAdjust?: React.SVGAttributes<SVGTextElement>["lengthAdjust"];
}) {
  const line = props.line;
  return (
    <text
      className={props.className}
      style={props.textStyle.svgFontAttrs()}
      transform={
        defined(line.rotation)
          ? getRotationTransform(
              line.rotation,
              line.position,
              line.lineHeightWithPadding
            )
          : undefined
      }
      fill={props.fontColor ?? DEFAULT_CHART_TEXT_COLOR}
      textAnchor={props.anchor ?? "end"}
      lengthAdjust={props.lengthAdjust}
      dominantBaseline={props.dominantBaseline}
      x={adjustXPosByPadding(props.line)}
      dy={getRotationAdjustment(line.rotation)}
      y={props.line.position.y}
    >
      {props.line.text}
    </text>
  );
}

/**
 * For CCW 90 rotated texts, make a correction to account for the fact that text boxes are positioned
 * relative to text points rather than a box.
 */
function getRotationAdjustment(rotation?: number): string | undefined {
  if (!defined(rotation)) {
    return;
  }

  if (rotation === -90) {
    return "0.4em";
  }
}

function getRotationTransform(
  rotation: number,
  midpointPosition: Position2D,
  lineHeight: number
): string {
  const { x, y } = midpointPosition;
  if (rotation <= 0) {
    return `translate(0 0) rotate(${rotation} ${x} ${y + lineHeight})`;
  }
  return `rotate(${rotation},${x},${y})`;
}

function adjustXPosByPadding(line: PositionedTextLine): number {
  switch (line.anchor) {
    case "end":
      return line.position.x - line.anchorPadding;
    case "left":
      return line.position.x + line.anchorPadding;
    case "middle":
      return line.position.x;
  }
}
