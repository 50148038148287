import { classNames } from "../lib/core/classNames";
import "./Divider.scss";

export function VerticalDivider() {
  return <div className="vertical-divider"></div>;
}

export function HorizontalDivider(props: {
  bottomMargin?: "none";
  invisible?: boolean;
}) {
  return (
    <div
      className={classNames(
        "horizontal-divider",
        props.bottomMargin === "none" ? "skip-bottom-margin" : "",
        props.invisible ? "invisible" : ""
      )}
    ></div>
  );
}
