import { logger } from "../../infra/logging";

const redirectPathKey = "redirect-to-path-after-auth";

/**
 * Set the location path to restore after Auth redirect
 */
export function setAuthRedirectRestorePathIfNotExists() {
  try {
    localStorage.setItem(redirectPathKey, window.location.pathname);
  } catch (e) {
    logger.error(e);
  }
}

/**
 * Read path, delete the stored path, and return
 */
export function extractPathToRestoreAfterAuthRedirect(): string | null {
  try {
    const path = localStorage.getItem(redirectPathKey);
    localStorage.removeItem(redirectPathKey);
    return path;
  } catch (e) {
    logger.error(e);
    return null;
  }
}
