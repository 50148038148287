import * as d3coll from "d3-collection";
import { chain } from "lodash";

import { defined } from "../../../../core/defined";
import { extent } from "../../../../core/numeric/extent";
import { LabelsByDimension } from "../../datasets/DatasetGeneric";
import { makeBandScalesAndLabelLayersVerticalBars } from "../bar_chart/bar_chart_common";
import {
  calculateNumberOfBars,
  getStandardChartInnerWidth,
} from "../charts_common";
import { ChartType, Orientation } from "../core/definitions";
import { getLegendDimensionBarChart } from "../dimensions";
import { ChartDataUnknown } from "./ChartData";
import { ColorSchemeContainer } from "../../../state/stats/document-style/definitions";
import { ColorConfig } from "../core/colors/colorSchemes";
import {
  colorSchemesEqual,
  copyColorSchemeContainer,
  makeFullColorScheme,
} from "../../../state/stats/document-style/operations";
import { DataOutputSettings } from "../../../state/stats/document-core/DataOutputSettings";

/**
 * Get the range of values of rows using the rangeAccessor.
 * If there are no values and defaultRange is specified, return it, otherwise throw.
 */
export function getRange<T>(
  rows: T[],
  rangeAccessor: (row: T) => number,
  defaultRange?: [number, number]
): [number, number] {
  const [rangeMin, rangeMax] = extent(
    chain(rows).map(rangeAccessor).filter(defined).value()
  );

  if (
    !defined(rangeMin) ||
    !defined(rangeMax) ||
    (rangeMin === 0 && rangeMax === 0)
  ) {
    if (defined(defaultRange)) {
      return defaultRange;
    }
    throw new Error("Range values not defined and no default given!");
  }
  return [rangeMin, rangeMax];
}

export function groupRows<T>(
  rows: T[],
  groupAccessor: (arg: T) => string
): { key: string; values: T[] }[] {
  const groups: { key: string; values: T[] }[] = d3coll
    .nest<T>()
    .key(groupAccessor)
    .entries(rows);
  return groups;
}

export function determineChartType<T extends ChartDataUnknown>(
  chartData: T,
  vizAreaWidth: number,
  settings: DataOutputSettings
) {
  const labelsByDimension = chartData.labelsByDimension;
  const uniqueDates = chartData.uniqueDates;

  // If we have more than three unique dates, we want a line chart
  if (defined(uniqueDates) && uniqueDates.length > 2) {
    return ChartType.line;
  }

  const chartInnerWidth = getStandardChartInnerWidth(
    vizAreaWidth,
    window.innerHeight
  );

  const legendDimension = getLegendDimensionBarChart(chartData.dataDimensions);
  const numBars = calculateNumberOfBars(labelsByDimension);
  const numLegendColors = defined(legendDimension)
    ? chartData.numLabels(legendDimension)
    : 0;

  if (numLegendColors >= 5 || numBars > 10) {
    return ChartType.barHorizontal;
  }

  if (
    !canFitLabelsVertical(chartData, chartInnerWidth, settings.chart.labelSize)
  ) {
    return ChartType.barHorizontal;
  }

  return ChartType.barVertical;
}

function canFitLabelsVertical<T extends ChartDataUnknown>(
  chartData: T,
  chartInnerWidth: number,
  baseLabelSize: number
): boolean {
  const { nonPrimaryLabelsByDimension, legendDimension } =
    chartData.prepareDimensionsAndLabelsBarChart(Orientation.vertical);

  const { layeredLabels } = makeBandScalesAndLabelLayersVerticalBars(
    nonPrimaryLabelsByDimension,
    legendDimension,
    chartData.dimensionFormatter,
    baseLabelSize,
    chartInnerWidth
  );
  return layeredLabels.successfulFit;
}

export function labelSpecsToText(labels?: LabelsByDimension[0]): string[] {
  return labels?.[1] ?? [];
}

export function getUpdatedColorScheme(
  existingColors: ColorSchemeContainer,
  labels: string[],
  colorConfig: ColorConfig,
  liftedDimensions: { [key: string]: string } | undefined
): ColorSchemeContainer {
  // TODO: checking lifted dimensions/prev lifted dimensions to determine
  // colors to keep won't when multiple dimensions were lifted
  const existingColorsCopy = copyColorSchemeContainer(existingColors);
  const existingColorKeysAndValues = Object.entries(
    existingColors.colorScheme
  ).filter(([, value]) => defined(value));
  const numExistingColors = existingColorKeysAndValues.length;

  const isSpecialCaseManyToOne = numExistingColors > 1 && labels.length === 1;
  const isSpecialCaseOneToMany = numExistingColors === 1 && labels.length > 1;

  if (isSpecialCaseManyToOne) {
    let existingColorKeyAndValue: [string, string] | undefined = undefined;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_dim, dimValue] of Object.entries(liftedDimensions ?? {})) {
      const found = existingColorKeysAndValues.find((x) => x[0] === dimValue);
      if (defined(found)) {
        existingColorKeyAndValue = found;
        break;
      }
    }
    if (defined(existingColorKeyAndValue)) {
      deleteKeysExcept(existingColorsCopy.colorScheme, labels);
      existingColorsCopy.colorScheme[""] = existingColorKeyAndValue[1];
      return existingColorsCopy;
    }
  } else if (isSpecialCaseOneToMany) {
    let existingColorKeyAndValue: [string, string] | undefined = undefined;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [_dim, dimValue] of Object.entries(liftedDimensions ?? {})) {
      const found = existingColorKeysAndValues.find((x) => x[0] === dimValue);
      if (defined(found)) {
        existingColorKeyAndValue = found;
        break;
      }
    }
    if (defined(existingColorKeyAndValue)) {
      deleteKeysExcept(existingColorsCopy.colorScheme, labels);
      existingColorsCopy.colorScheme[""] = existingColorKeyAndValue[1];
      return existingColorsCopy;
    }
  }

  deleteKeysExcept(existingColorsCopy.colorScheme, labels);

  const scheme = makeFullColorScheme(
    existingColorsCopy,
    labels,
    colorConfig.defaultToSingleColor,
    colorConfig.colorableDimensionLabel
  );
  return colorSchemesEqual(existingColors.colorScheme, scheme)
    ? existingColors
    : { ...existingColors, colorScheme: scheme };
}

function deleteKeysExcept(dict: { [key: string]: string }, excepted: string[]) {
  for (const key of Object.keys(dict)) {
    if (!excepted.includes(key)) {
      delete dict[key];
    }
  }
}
