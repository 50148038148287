import { LineChartDataV2 } from "../../../lib/application/state/stats/document-core/_core-shared";
import { defined } from "../../../lib/core/defined";
import { ChartPointPositions, ExtendedPositionInfo } from "./types";

export function getExtendedPositionInfo(
  position: ChartPointPositions,
  chartData: LineChartDataV2
): ExtendedPositionInfo {
  const domainValueRaw = defined(position)
    ? chartData.closestDomainValue(position.chartAreaPosition.x)
    : undefined;
  const domainValue = defined(domainValueRaw)
    ? new Date(domainValueRaw)
    : undefined;
  return {
    position,
    domainValueRaw,
    domainValue,
  };
}
