import { flatMap } from "lodash";
import { defined } from "../../../core/defined";
import { logger } from "../../../infra/logging";
import {
  DesoProperties,
  RegsoProperties,
  desoToId,
  regsoToId,
} from "../../state/stats/document-core/core-micro";
import { Array as ArrayRT, Record, Static, String as StringRT } from "runtypes";

export const MicroGeoTreeRawRT = ArrayRT(
  Record({
    lan: StringRT,
    label: StringRT,
    children: ArrayRT(
      Record({
        kommun: StringRT,
        label: StringRT,
        children_deso: ArrayRT(
          Record({
            deso: StringRT,
            deso_label: StringRT,
            regso: StringRT,
            regso_label: StringRT,
          })
        ),
        children_regso: ArrayRT(
          Record({
            regso: StringRT,
            regso_label: StringRT,
          })
        ),
      })
    ),
  })
);

export type MicroGeoTreeRaw = Static<typeof MicroGeoTreeRawRT>;

export class MicroGeoTree {
  private _municipalityToDeso: { [key: string]: DesoProperties[] };
  private _municipalityToRegso: { [key: string]: RegsoProperties[] };
  private _regionToMunicipalities: { [key: string]: string[] };
  private _desoRegsoToMunicipality: { [key: string]: string };
  private _desoRegsoToRegion: { [key: string]: string };
  private _desoLabelToDeso: { [key: string]: DesoProperties };
  private _regsoLabelToRegso: { [key: string]: RegsoProperties };

  constructor(private _tree: MicroGeoTreeRaw) {
    _tree.sort((a, b) => a.label.localeCompare(b.label));

    const municipalityToDeso: { [key: string]: DesoProperties[] } = {};
    const municipalityToRegso: { [key: string]: RegsoProperties[] } = {};
    const regionToMunicipalities: { [key: string]: string[] } = {};
    const desoRegsoToRegion: { [key: string]: string } = {};
    const desoRegsoToMunicipality: { [key: string]: string } = {};

    const desoLabelToDeso: { [key: string]: DesoProperties } = {};
    const regsoLabelToRegso: { [key: string]: RegsoProperties } = {};

    for (const region of _tree) {
      region.children.sort((a, b) => a.label.localeCompare(b.label));

      const municipalities: string[] = [];
      for (const municipality of region.children) {
        municipalityToDeso[municipality.kommun] =
          municipality.children_deso.map((d) => ({
            ...d,
            id: desoToId(d.deso),
          }));

        municipalityToRegso[municipality.kommun] =
          municipality.children_regso.map((r) => ({
            ...r,
            id: regsoToId(r.regso),
          }));

        municipalities.push(municipality.kommun);

        for (const deso of municipality.children_deso) {
          desoRegsoToRegion[deso.deso] = region.label;
          desoRegsoToMunicipality[deso.deso] = municipality.label;
          desoLabelToDeso[deso.deso_label] = {
            ...deso,
            id: desoToId(deso.deso),
          };
        }

        for (const regso of municipality.children_regso) {
          desoRegsoToRegion[regso.regso] = region.label;
          desoRegsoToMunicipality[regso.regso] = municipality.label;
          regsoLabelToRegso[regso.regso_label] = {
            ...regso,
            id: regsoToId(regso.regso),
          };
        }
      }
      regionToMunicipalities[region.lan] = municipalities;
    }

    this._desoLabelToDeso = desoLabelToDeso;
    this._regsoLabelToRegso = regsoLabelToRegso;
    this._desoRegsoToRegion = desoRegsoToRegion;
    this._desoRegsoToMunicipality = desoRegsoToMunicipality;
    this._municipalityToDeso = municipalityToDeso;
    this._municipalityToRegso = municipalityToRegso;
    this._regionToMunicipalities = regionToMunicipalities;
  }

  tree() {
    return this._tree;
  }

  allDesoInRegion(region: string): DesoProperties[] {
    const municipalities = this._regionToMunicipalities[region];
    if (!defined(municipalities)) {
      logger.warn("No municipalities found");
      return [];
    }
    return flatMap(municipalities, (m) => this._municipalityToDeso[m]);
  }

  desoLabelToDeso(desoLabel: string): string {
    return this._desoLabelToDeso[desoLabel]?.deso;
  }

  regsoLabelToRegso(regsoLabel: string): string {
    return this._regsoLabelToRegso[regsoLabel]?.regso;
  }

  regsoLongLat(regsoLabel: string): [number, number] | undefined {
    const lng = this._regsoLabelToRegso[regsoLabel]?.lng;
    const lat = this._regsoLabelToRegso[regsoLabel]?.lat;
    if (!defined(lng) || !defined(lat)) {
      return undefined;
    }
    return [lng, lat];
  }

  desoLongLat(desoLabel: string): [number, number] | undefined {
    const lng = this._desoLabelToDeso[desoLabel]?.lng;
    const lat = this._desoLabelToDeso[desoLabel]?.lat;
    if (!defined(lng) || !defined(lat)) {
      return undefined;
    }
    return [lng, lat];
  }

  desoRegsoToRegion(desoRegso: string): string | undefined {
    return this._desoRegsoToRegion[desoRegso];
  }

  desoRegsoToMunicipality(desoRegso: string): string | undefined {
    return this._desoRegsoToMunicipality[desoRegso];
  }

  allRegsoInRegion(region: string): RegsoProperties[] {
    const municipalities = this._regionToMunicipalities[region];
    if (!defined(municipalities)) {
      logger.warn("No municipalities found");
      return [];
    }
    return flatMap(municipalities, (m) => this._municipalityToRegso[m]);
  }

  allDesoInMunicipality(municipality: string): DesoProperties[] {
    return this._municipalityToDeso[municipality] ?? [];
  }
  allRegsoInMunicipality(municipality: string): RegsoProperties[] {
    return this._municipalityToRegso[municipality] ?? [];
  }
}
