import { Link } from "@fluentui/react/lib/Link";
import {
  DetailsList,
  Selection,
  IColumn,
  IDragDropEvents,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import { getTheme, mergeStyles } from "@fluentui/react/lib/Styling";
import { TooltipHost } from "@fluentui/react";
import { useCallback, useContext, useMemo, useState } from "react";

import "./FileList.scss";
import { FluentIcon } from "../../../../components/Icons";
import { defined } from "../../../../lib/core/defined";
import {
  FileNodeInfo,
  ChildNodeInfo,
} from "../../../../lib/application/files/tree";
import { config } from "../../../../config";
import { InfoBox } from "../../../../components/InfoBox";
import { Button } from "../../../../components/Button";
import { SimpleInputModal } from "../../../../components/SimpleInputModal";
import { validateNonEmptyString } from "../../../../lib/application/validation/string";
import { classNames } from "../../../../lib/core/classNames";
import { ShareDialogLoader } from "../../sharing/ShareDialog";
import { FileListMenu } from "./FileListMenu";
import { FileListMoveDialog } from "./FileListMoveDialog";
import {
  FileFeatures,
  NewNodeHandler,
  sharingColumnSpec,
} from "./shared/features";
import {
  FavoriteIcon,
  FolderOrLibrary,
  HelpIcon,
  SampleLibraryIcon,
  SharedIcon,
} from "./shared/FileListCommon";
import { FilePathNavigation } from "./shared/FilePathWithNavigation";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../components/Modal";
import {
  ButtonsFooter,
  ButtonsFooterLeft,
  ButtonsFooterRight,
} from "../../../../components/ButtonContainers";
import { createDocumentAlert } from "../../../../lib/application/requests/admin/common_requests_admin";
import { AppMessagesContext } from "../../../../lib/application/contexts";

const theme = getTheme();
const dragEnterClass = mergeStyles({
  backgroundColor: theme.palette.neutralLight,
});

type ListItem = ChildNodeInfo;

interface Props {
  node: FileNodeInfo;
  navigationRootName: string;
  enableSharingColumn?: boolean;
  enableSelection: boolean;
  handleNew?: NewNodeHandler;
  highlightFolder?: boolean;
  handleOpenFolder: (nodeId: number) => void;
  handleOpenFile: (nodeId: number) => void;
  handleUpdateNodeName: (nodeId: number, name: string) => void;
  handleNavigateToRoot: () => void;
  handleDelete: (item: ChildNodeInfo) => void;
  handleMove: (target: FileNodeInfo, items: ChildNodeInfo[]) => void;
  fileFeatures: FileFeatures;
}
export function FileListWithPath(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selection, setSelection] = useState(new Selection());
  const [draggedItems, setDraggedItems] = useState<
    ChildNodeInfo[] | undefined
  >();
  const [moveDialogItem, setMoveDialogItem] = useState<ChildNodeInfo>();
  const [renameDialogItem, setRenameDialogItem] = useState<ChildNodeInfo>();
  const [sharingDialogItem, setSharingDialogItem] = useState<ChildNodeInfo>();
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);
  const [scheduleNoticesDialogItem, setScheduleNoticesDialogItem] =
    useState<ChildNodeInfo>();

  const { node, handleDelete, fileFeatures } = props;
  const favoriteActions = fileFeatures.favorites;
  const appMessages = useContext(AppMessagesContext);

  const setCurrentFolderNodeId = useCallback(
    (nodeId?: number) => {
      if (!defined(nodeId)) {
        return props.handleNavigateToRoot();
      }
      selection.setItems(selection.getItems(), true);
      props.handleOpenFolder(nodeId);
    },
    [props, selection]
  );

  const handleScheduleNotices = useCallback(
    (nodeId: number) => {
      createDocumentAlert(nodeId).then((res) => {
        res.match({
          ok: () => {
            setScheduleNoticesDialogItem(undefined);
            appMessages?.add("success", "Utskick schemalagt");
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Misslyckades med att schemalägga utskick: " + err.code
            );
          },
        });
      });
    },
    [appMessages]
  );

  const columns: IColumn[] = useMemo(() => {
    const cols: IColumn[] = [
      {
        key: "type-col",
        name: "Filtyp",
        fieldName: "type",
        isIconOnly: true,
        minWidth: 20,
        maxWidth: 34,
        onRender: (item: ChildNodeInfo) => (
          <>
            {item.type === "document" && (
              <div className="node-icons">
                <TooltipHost content="Dokument">
                  <FluentIcon name="document" size="sm"></FluentIcon>
                </TooltipHost>
                {item.belongsToHelpLibrary && fileFeatures.useHelpIcon && (
                  <TooltipHost content="Del av hjälpbibliotek">
                    <HelpIcon></HelpIcon>
                  </TooltipHost>
                )}
                {item.belongsToSampleLibrary &&
                  fileFeatures.useSampleLibIcon && (
                    <TooltipHost content="Del av exempelbibliotek">
                      <SampleLibraryIcon></SampleLibraryIcon>
                    </TooltipHost>
                  )}
              </div>
            )}
            {item.type === "folder" && (
              <div className="node-icons">
                <FolderOrLibrary
                  fileFeatures={fileFeatures}
                  item={item}
                ></FolderOrLibrary>
              </div>
            )}
          </>
        ),
      },
      {
        key: "name-col",
        name: "Namn",
        fieldName: "name",
        minWidth: 210,
        maxWidth: 550,
        isRowHeader: true,
        isResizable: true,
        className: "name-column",
        onRender: (item: ChildNodeInfo) => {
          switch (item.type) {
            case "folder":
              return (
                <div className="node-title-col">
                  <Link className="grow" data-selection-invoke={true}>
                    {item.title}
                  </Link>
                  {fileFeatures.showSharedStatus && (
                    <SharedIcon item={item}></SharedIcon>
                  )}
                  {defined(favoriteActions) && (
                    <FavoriteIcon
                      optimisticStatus={favoriteActions.optimistic[item.id]}
                      item={item}
                      removeFavorite={favoriteActions.remove}
                      addFavorite={favoriteActions.add}
                    ></FavoriteIcon>
                  )}
                </div>
              );
            case "document":
              return (
                <div className="node-title-col">
                  <Link className="grow" data-selection-invoke={true}>
                    {item.title}
                  </Link>
                  {fileFeatures.showSharedStatus && (
                    <SharedIcon item={item}></SharedIcon>
                  )}
                  {defined(favoriteActions) && (
                    <FavoriteIcon
                      optimisticStatus={favoriteActions.optimistic[item.id]}
                      item={item}
                      removeFavorite={favoriteActions.remove}
                      addFavorite={favoriteActions.add}
                    ></FavoriteIcon>
                  )}
                </div>
              );
          }
        },
        data: "string",
        isPadded: true,
      },
    ];

    if (props.enableSharingColumn) {
      cols.push(sharingColumnSpec);
    }

    cols.push(
      {
        key: "date-col",
        name: "Senaste ändring",
        fieldName: "dateModified",
        minWidth: 70,
        maxWidth: 140,
        isResizable: false,
        // onColumnClick: this._onColumnClick,
        data: "number",
        onRender: (item: ChildNodeInfo) => {
          return <span>{item.lastModified.toLocaleString(config.locale)}</span>;
        },
        isPadded: true,
      },
      {
        key: "menu-col",
        name: "File Type",
        fieldName: "type",
        isIconOnly: true,
        minWidth: 16,
        maxWidth: 16,
        // onColumnClick: this._onColumnClick,
        onRender: (item: ChildNodeInfo) =>
          props.fileFeatures.hideAll ? (
            <></>
          ) : (
            <FileListMenu
              parentNode={props.node}
              menuFeatures={props.fileFeatures}
              item={item}
              key={item.id}
              onDelete={handleDelete}
              handleOpenMoveDialog={() => setMoveDialogItem(item)}
              handleOpenRenameDialog={() => setRenameDialogItem(item)}
              handleOpenSharingDialog={() => setSharingDialogItem(item)}
              handleOpenScheduleNoticesDialog={() =>
                setScheduleNoticesDialogItem(item)
              }
            ></FileListMenu>
          ),
      }
    );
    return cols;
  }, [
    favoriteActions,
    fileFeatures,
    handleDelete,
    props.enableSharingColumn,
    props.fileFeatures,
    props.node,
  ]);

  const _getDragDropEvents: () => IDragDropEvents = () => {
    return {
      canDrop: (dropContext) => {
        const dropTarget = dropContext?.data as ListItem;
        return dropTarget.type === "folder";
      },
      canDrag: () => {
        return true;
      },
      onDragEnter: () => {
        // return string is the css classes that will be added to the entering element.
        return dragEnterClass;
      },
      onDragLeave: () => {
        return;
      },
      onDrop: (target: ChildNodeInfo | undefined) => {
        if (defined(target) && defined(draggedItems)) {
          props.handleMove(target, draggedItems);
          selection.setItems(selection.getItems(), true);
        }
      },
      onDragStart: (
        item: ChildNodeInfo | undefined,
        index,
        selectedItems: ChildNodeInfo[] | undefined
      ) => {
        if (defined(selectedItems)) {
          setDraggedItems(selectedItems);
        } else if (defined(item)) {
          setDraggedItems([item]);
        } else {
          setDraggedItems(undefined);
        }
      },
      onDragEnd: () => {
        setDraggedItems(undefined);
      },
    };
  };

  const _onItemInvoked = (item: ListItem): void => {
    if (item.type === "folder") {
      props.handleOpenFolder(item.id);
    } else {
      props.handleOpenFile(item.id);
    }
    selection.setItems(selection.getItems(), true);
  };

  const _dragDropEvents = _getDragDropEvents();
  const newNodeHandler = props.handleNew?.(node);

  return (
    <div>
      <div className="menu-container">
        <div>
          <Button
            title="Nytt dokument"
            disabled={!defined(newNodeHandler)}
            onClick={() => newNodeHandler?.file()}
            icon="document"
          ></Button>
          <Button
            title="Ny mapp"
            disabled={!defined(newNodeHandler)}
            onClick={() => setCreateFolderModalOpen(true)}
            icon="folder-close"
            className="color-folder"
          ></Button>
        </div>
      </div>
      {node.type === "folder" && defined(node.path) && (
        <FilePathNavigation
          rootName={props.navigationRootName}
          path={node.path}
          handleNavigate={setCurrentFolderNodeId}
          handleBackToRoot={() => setCurrentFolderNodeId(undefined)}
        ></FilePathNavigation>
      )}
      {defined(scheduleNoticesDialogItem) && (
        <FluentModal
          onClose={() => setScheduleNoticesDialogItem(undefined)}
          isOpen={true}
          title="Schemalägg utskick"
        >
          <FluentModalBody>
            <p>
              Är du säker på att du vill schemalägga epostutskick till alla
              användare som bevakar följande dokument?
            </p>
            <p>
              <strong>{scheduleNoticesDialogItem.title}</strong>
            </p>
          </FluentModalBody>
          <FluentModalFooter>
            <ButtonsFooter>
              <ButtonsFooterLeft>
                <Button
                  title="Avbryt"
                  onClick={() => setScheduleNoticesDialogItem(undefined)}
                ></Button>
              </ButtonsFooterLeft>
              <ButtonsFooterRight>
                <Button
                  title="Schemalägg"
                  intent="primary"
                  onClick={() =>
                    scheduleNoticesDialogItem.type === "document"
                      ? handleScheduleNotices(scheduleNoticesDialogItem.id)
                      : alert(
                          "Ogiltig dokumenttyp: " +
                            scheduleNoticesDialogItem.type
                        )
                  }
                ></Button>
              </ButtonsFooterRight>
            </ButtonsFooter>
          </FluentModalFooter>
        </FluentModal>
      )}
      {createFolderModalOpen && (
        <SimpleInputModal
          title="Ny mapp"
          onDismiss={() => setCreateFolderModalOpen(false)}
          onSaveInput={(input) => {
            setCreateFolderModalOpen(false);
            newNodeHandler?.folder(input);
          }}
          input={{
            initialValue: "Mapp",
            label: "Namn",
            maxLength: config.docTitleMaxLength,
            validate: validateNonEmptyString,
          }}
          autoSelectText={true}
        ></SimpleInputModal>
      )}
      {defined(moveDialogItem) && defined(fileFeatures.enableMove) && (
        <FileListMoveDialog
          nodesMode={fileFeatures.enableMove.mode}
          fileFeatures={fileFeatures}
          fileRootName={props.navigationRootName}
          item={moveDialogItem}
          onMove={(item: ChildNodeInfo, target: FileNodeInfo) => {
            props.handleMove(target, [item]);
            setMoveDialogItem(undefined);
          }}
          onClose={() => setMoveDialogItem(undefined)}
        ></FileListMoveDialog>
      )}
      {defined(renameDialogItem) && (
        <SimpleInputModal
          title="Ändra namn"
          input={{
            initialValue: renameDialogItem.title,
            validate: validateNonEmptyString,
            label: "Namn",
            maxLength: config.docTitleMaxLength,
          }}
          onDismiss={() => setRenameDialogItem(undefined)}
          onSaveInput={(value: string) => {
            props.handleUpdateNodeName(renameDialogItem.id, value);
            setRenameDialogItem(undefined);
          }}
          autoSelectText={true}
        ></SimpleInputModal>
      )}
      {defined(sharingDialogItem) && (
        <ShareDialogLoader
          nodeId={sharingDialogItem.id}
          nodeTitle={sharingDialogItem.title}
          nodeType={sharingDialogItem.type}
          onClose={() => setSharingDialogItem(undefined)}
        ></ShareDialogLoader>
      )}
      <div
        className={classNames(
          "list-content-main",
          props.highlightFolder ? "highlight-folder" : ""
        )}
      >
        {!defined(node.children) || node.children.length === 0 ? (
          <InfoBox
            className="margin-top-lg"
            sections={[
              {
                header: "Tomt",
                text: "Här finns inga dokument eller mappar än.",
              },
            ]}
          ></InfoBox>
        ) : (
          <MarqueeSelection selection={selection}>
            <DetailsList
              onShouldVirtualize={() => false}
              className="infostat-filelist"
              setKey="items"
              items={
                defined(fileFeatures.listSortAlphabetical)
                  ? node.children
                      .slice()
                      .sort((left, right) =>
                        left.title.localeCompare(right.title, config.locale)
                      )
                  : node.children
              }
              columns={columns}
              selectionMode={
                props.enableSelection
                  ? SelectionMode.multiple
                  : SelectionMode.none
              }
              selection={selection}
              onItemInvoked={_onItemInvoked}
              dragDropEvents={_dragDropEvents}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="select row"
            />
          </MarqueeSelection>
        )}
      </div>
    </div>
  );
}
