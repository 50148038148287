import * as _ from "lodash";

import {
  degreesToRadians,
  radiansToDegrees,
} from "../../../../../core/math/angles";
import {
  Dimension,
  MAX_LABEL_NUM_CHARS_ROTATION_MODE,
  MAX_LABEL_ROTATION_ANGLE_DEG,
} from "../../core/definitions";

import { TextStyle } from "../../core/TextStyle";
import { MultilineText } from "../../core/text_containers";
import { TextSplittingMode } from "../../core/text_splitting";
import { BandScalesByDimension } from "../bandScales";
import {
  LabelLayer,
  LabelLayerWithRenderInfo,
  LayeredLabels,
  RenderInfoPublic,
  SplittingMethodInternal,
  TextContainer,
} from "./definitions";
import { defined } from "../../../../../core/defined";
import { uniqBy } from "lodash";
import { logger } from "../../../../../infra/logging";

const LABEL_MARGIN_FACTOR = 0.8;

interface LabelLayerWithRenderInfoInternal<LabelType>
  extends LabelLayerWithRenderInfo<LabelType> {
  renderInfo: RenderInfoInternal<LabelType>;
}

/**
 */
export class LayeredLabelsVerticalBars<LabelType = string>
  implements LayeredLabels<LabelType>
{
  private _extendedLayers: LabelLayerWithRenderInfoInternal<LabelType>[];
  private _totalLayerWidthHorizontal: number;
  private _totalLayerHeight: number;
  private _successfulFit: boolean;
  /**
   * @param labelLayers layers of labels, ordered from outer to inner
   */
  private constructor(
    successfulFit: boolean,
    extendedLayers: LabelLayerWithRenderInfoInternal<LabelType>[]
  ) {
    this._successfulFit = successfulFit;
    this._extendedLayers = extendedLayers;
    this._totalLayerWidthHorizontal =
      _.max(extendedLayers.map((l) => l.renderInfo.width)) ?? 0;
    this._totalLayerHeight =
      _.sum(extendedLayers.map((l) => l.renderInfo.height)) ?? 0;
  }

  static fromLabelLayers(
    labelLayers: LabelLayer[],
    textStyle: TextStyle,
    bandscales: BandScalesByDimension,
    options?: {
      labelAnchorPadding?: number;
    }
  ) {
    const outerScale: BandScalesByDimension[0]["scale"] | undefined =
      bandscales[0]?.scale;
    const step = outerScale.step();

    const extendedLayers: LabelLayerWithRenderInfoInternal<string>[] =
      labelLayers.map((layer) => {
        const isTopLayer = labelLayers.indexOf(layer) === 0;
        return {
          layer,
          renderInfo: new RenderInfoInternal(
            layer.formattedLabels.map((label) => {
              const singleLabelMaxWidth = step * LABEL_MARGIN_FACTOR;
              return {
                label,
                container: new MultilineText(label, textStyle, {
                  desiredMaxWidth: singleLabelMaxWidth,
                  anchorPadding: options?.labelAnchorPadding,
                  boxPaddingTop: isTopLayer ? undefined : 7,
                  anchor: "middle",
                }),
              };
            }),
            {
              mode: TextSplittingMode.breakOnWhitespace,
              retry: true,
            }
          ),
        };
      });

    const successfulFit = fitLabelsMutable(extendedLayers, bandscales);
    return new LayeredLabelsVerticalBars(successfulFit, extendedLayers);
  }

  static fromTreeData(
    // One layer per hierarchy
    dimensionLayers: readonly string[],
    labelPaths: string[][],
    pathWithoutDimension: (path: string[], dimension: string) => string[],
    legendDimension: string | undefined,
    textStyle: TextStyle,
    getLabelMaxWidth: (path: string[]) => number,
    formatLabelPath: (path: string[]) => string,
    options?: {
      labelAnchorPadding?: number;
    }
  ): LayeredLabelsVerticalBars<string[]> {
    const extendedLayers: LabelLayerWithRenderInfoInternal<string[]>[] = [];

    for (let i = 0; i < dimensionLayers.length; i++) {
      // The legend dimension does not give rise to label layer.
      // However, it is still needed for the label paths.
      const dimGroup = dimensionLayers[i];
      if (defined(legendDimension) && dimGroup === legendDimension) {
        continue;
      }

      const plainDims = [dimGroup];
      const lastDim = plainDims[plainDims.length - 1];
      // TODO: can be simiplified now
      const desiredPathLength = dimensionLayers
        .slice(0, i + 1)
        .reduce((acc, curr) => acc + 1, 0);
      const currentPaths = labelPaths.filter(
        (path) => path.length === desiredPathLength
      );
      // Ensure labels are not unnecessarily duplicated.
      // If the path excluding legend is the same, the label is the same and will be in the same position.
      const labels = defined(legendDimension)
        ? uniqBy(currentPaths, (path) => {
            const key = pathWithoutDimension(path, legendDimension).join(",");
            return key;
          })
        : currentPaths;

      const isTopLayer = i === 0;
      const formattedLabels = labels.map(formatLabelPath);
      // If there is only one label, it is not necessary to create a layer.
      // That label will be visible in the chart headers as a lifted value anyway.
      if (isTopLayer && formattedLabels.length === 1) {
        continue;
      }

      extendedLayers.push({
        layer: {
          dimension: lastDim as Dimension,
          labels,
          formattedLabels,
        },
        renderInfo: new RenderInfoInternal<string[]>(
          labels.map((labelPath) => {
            const formattedLabel = formatLabelPath(labelPath);
            return {
              label: labelPath,
              container: new MultilineText(formattedLabel, textStyle, {
                desiredMaxWidth:
                  getLabelMaxWidth(labelPath) * LABEL_MARGIN_FACTOR,
                anchorPadding: options?.labelAnchorPadding,
                boxPaddingTop: isTopLayer ? undefined : 7,
                anchor: "middle",
              }),
            };
          }),
          {
            mode: TextSplittingMode.breakOnWhitespace,
            retry: true,
          }
        ),
      });
    }

    const successfulFit = fitLabelsMutableHierarchical(extendedLayers);
    return new LayeredLabelsVerticalBars(successfulFit, extendedLayers);
  }

  get successfulFit(): boolean {
    return this._successfulFit;
  }

  get totalWidth() {
    return this._totalLayerWidthHorizontal;
  }

  get totalHeight(): number {
    return this._totalLayerHeight;
  }

  get numLayers(): number {
    return this._extendedLayers.length;
  }

  innermostLabelMaxHeight(): number {
    const textContainers =
      this._extendedLayers[this._extendedLayers.length - 1].renderInfo
        .textContainers;

    return (
      _.maxBy(textContainers, (label) => label.container.height)?.container
        .height ?? 0
    );
  }

  layers(): LabelLayer<LabelType>[] {
    return this._extendedLayers.map((l) => l.layer);
  }

  extendedLayers(): readonly LabelLayerWithRenderInfo<LabelType>[] {
    return this._extendedLayers;
  }
}

/**
 * Render information used by LayeredLabels class only
 */
class RenderInfoInternal<LabelType> implements RenderInfoPublic<LabelType> {
  constructor(
    private _textContainers: TextContainer<LabelType>[],
    private _splittingMethod: SplittingMethodInternal
  ) {}
  get textContainers(): TextContainer<LabelType>[] {
    return this._textContainers;
  }

  get method(): SplittingMethodInternal {
    return this._splittingMethod;
  }

  set method(method: SplittingMethodInternal) {
    this._splittingMethod = method;
  }

  get width(): number {
    return _.sum(this._textContainers.map((t) => t.container.maxWidth)) ?? 0;
  }

  get height(): number {
    return _.max(this._textContainers.map((t) => t.container.height)) ?? 0;
  }

  /**
   * @returns whether any update has been made
   */
  refineSplitNaive(desiredWidth: number): boolean {
    let allUnchanged = true;
    for (const label of this._textContainers) {
      const maxWidthBefore = label.container.maxWidth;
      const maxWidthAfter = label.container.refineSplitNaive(desiredWidth);
      if (maxWidthAfter < maxWidthBefore) {
        allUnchanged = false;
      }
    }
    return allUnchanged;
  }

  /**
   * @returns whether any update has been made
   */
  refineSplitNaiveHierarch(): boolean {
    let allUnchanged = true;
    for (const label of this._textContainers) {
      const maxWidthBefore = label.container.maxWidth;
      const maxWidthAfter = label.container.refineSplitNaive(
        label.container.desiredMaxWidth
      );
      if (maxWidthAfter < maxWidthBefore) {
        allUnchanged = false;
      }
    }
    return allUnchanged;
  }

  /**
   * Resets label to a single line and rotates in order
   * to fill the given horizontal width.
   * Does not rotate more than MAX_LABEL_ROTATION_ANGLE_DEG.
   */
  resetAndRotateLabels(desiredWidth: number): void {
    const labelMaxWidth =
      _.max(
        this._textContainers.map(
          (container) => container.container.originalWidth
        )
      ) ?? 0;

    // hyp = original text width
    // adj = desired text width
    // cos(angle) = adj / hyp
    // angle = cos_inv(adj / hyp)

    const maxAngleRadians = degreesToRadians(MAX_LABEL_ROTATION_ANGLE_DEG);
    const angleRadians = Math.acos(desiredWidth / labelMaxWidth);
    const angleDegrees = radiansToDegrees(
      Math.min(maxAngleRadians, angleRadians)
    );
    for (const label of this._textContainers) {
      label.container.resetAndRotate(-angleDegrees);
    }
  }

  /**
   * Resets label to a single line and rotates in order
   * to fill the given horizontal width.
   * Does not rotate more than MAX_LABEL_ROTATION_ANGLE_DEG.
   */
  resetAndRotateLabelsHierarch(): void {
    const maxAngleRadians = degreesToRadians(MAX_LABEL_ROTATION_ANGLE_DEG);

    const angleRadians =
      _.max(
        this._textContainers.map((container) => {
          const labelWidth = container.container.originalWidth;
          const labelDesiredWidth = container.container.desiredMaxWidth;
          const angleRadians = Math.acos(labelDesiredWidth / labelWidth);
          return angleRadians;
        })
      ) ?? 0;

    // hyp = original text width
    // adj = desired text width
    // cos(angle) = adj / hyp
    // angle = cos_inv(adj / hyp)

    const angleDegrees = radiansToDegrees(
      Math.min(maxAngleRadians, angleRadians)
    );
    for (const label of this._textContainers) {
      label.container.resetAndRotate(-angleDegrees);
    }
  }

  refineSplitForce(desiredWidth: number) {
    for (const label of this._textContainers) {
      label.container.refineSplitForce(desiredWidth);
      this._splittingMethod.retry = false;
    }
  }
}

/**
 * Ensure text containers fit into specified totalMaxWidth
 * Relies on iterative algorithm to successively shrink labels.
 * @returns true if successfully fitted, else false
 */
function fitLabelsMutableHierarchical(
  extendedLayers: LabelLayerWithRenderInfoInternal<string[]>[]
): boolean {
  let numIterations = 0;
  let rotationUsed = false;

  function labelExceedsSpace(
    extLayer: LabelLayerWithRenderInfoInternal<string[]>
  ): boolean {
    const textContainers = extLayer.renderInfo.textContainers;
    return textContainers.some(
      (t) => t.container.desiredMaxWidth < t.container.maxWidth
    );
  }

  for (const extLayer of extendedLayers) {
    // Approximation of how much space is available for each label.
    // Rotated labels will end at the midpoint of each bar, so if we consider the whole
    // horizontal space per bar usable, the first label will point out too much
    // outside the chart area.
    const method = extLayer.renderInfo.method;

    while (labelExceedsSpace(extLayer) && numIterations < 10) {
      numIterations += 1;

      if (method.mode === TextSplittingMode.breakOnWhitespace && method.retry) {
        const allUnchanged = extLayer.renderInfo.refineSplitNaiveHierarch();
        if (allUnchanged) {
          extLayer.renderInfo.method.retry = false;
        }
        if (allUnchanged && numIterations > 9) {
          return false;
        }
      } else if (
        method.mode === TextSplittingMode.breakOnWhitespace &&
        !method.retry
      ) {
        const canUseRotation =
          extLayer.layer.labels.every(
            (label) => label.length <= MAX_LABEL_NUM_CHARS_ROTATION_MODE
          ) && !rotationUsed;
        if (!canUseRotation) {
          return false;
        } else {
          extLayer.renderInfo.resetAndRotateLabelsHierarch();
          rotationUsed = true;
        }
        break;
      } else {
        // No other modes used for vertical bars
        break;
      }
    }
  }

  return true;
}

/**
 * Ensure text containers fit into specified totalMaxWidth
 * Relies on iterative algorithm to successively shrink labels.
 * @returns true if successfully fitted, else false
 */
function fitLabelsMutable(
  extendedLayers: LabelLayerWithRenderInfoInternal<string>[],
  bandscales: BandScalesByDimension
): boolean {
  let numIterations = 0;
  let rotationUsed = false;

  for (const layer of extendedLayers) {
    // Approximation of how much space is available for each label.
    // Rotated labels will end at the midpoint of each bar, so if we consider the whole
    // horizontal space per bar usable, the first label will point out too much
    // outside the chart area.
    const bandscale = bandscales[extendedLayers.indexOf(layer)]?.scale;
    const availableWidthSplitting = bandscale.step() * LABEL_MARGIN_FACTOR;
    const availableWidthRotation = availableWidthSplitting;
    const method = layer.renderInfo.method;

    while (
      maxLabelWidth(layer) > availableWidthSplitting &&
      numIterations < 10
    ) {
      numIterations += 1;

      if (method.mode === TextSplittingMode.breakOnWhitespace && method.retry) {
        const allUnchanged = layer.renderInfo.refineSplitNaive(
          availableWidthSplitting
        );
        if (allUnchanged) {
          layer.renderInfo.method.retry = false;
        }
        if (allUnchanged && numIterations > 9) {
          return false;
        }
      } else if (
        method.mode === TextSplittingMode.breakOnWhitespace &&
        !method.retry
      ) {
        const canUseRotation =
          layer.layer.labels.every(
            (label) => label.length <= MAX_LABEL_NUM_CHARS_ROTATION_MODE
          ) && !rotationUsed;
        if (!canUseRotation) {
          return false;
        } else {
          layer.renderInfo.resetAndRotateLabels(availableWidthRotation);
          rotationUsed = true;
        }
        break;
      } else {
        // No other modes used for vertical bars
        logger.warn("Invalid splitting mode used for vertical bars", method);
        break;
      }
    }
  }

  return true;
}

function maxLabelWidth(layer: LabelLayerWithRenderInfo): number {
  return (
    _.max(layer.renderInfo.textContainers.map((t) => t.container.maxWidth)) ?? 0
  );
}
