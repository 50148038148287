import { useMemo, useRef } from "react";
import { createBarChartVerticalDataAndColors } from "../../../../lib/application/stats/chart_containers/createBarChartVerticalData";
import { CustomThemeSpec } from "../../../../lib/application/stats/shared/core/colors/colorSchemes";
import { StatsDataset } from "../../../../lib/application/stats/datasets/StatsDataset";
import { GEOCODE_SWEDEN } from "../../../../lib/domain/geography";
import { dataOutputSettingsDefault } from "../../../../lib/application/state/stats/document-core/create";
import { applyCustomOutputSettingsOnCompleteSettings } from "../../../../lib/application/state/stats/default-settings/apply";
import { defined } from "../../../../lib/core/defined";
import { BarChartVerticalInner } from "../../../../components/charts/BarChartVerticalV2";
import { PreviewModeSetting } from "./themes";
import { createLineChartDataAndColors } from "../../../../lib/application/stats/chart_containers/createLineChartData";
import { LineChartInner } from "../../../../components/charts/LineChartV2";
import {
  sampleDataBarChart,
  sampleDataLineChart,
  sampleMeasureSelectionBarChart,
  sampleMeasureSelectionLineChart,
} from "./preview_data";

export function SampleDataPreview(props: {
  theme: CustomThemeSpec;
  previewMode: PreviewModeSetting;
}) {
  return (
    <>
      {props.previewMode === "bars" && (
        <SampleBarChart theme={props.theme}></SampleBarChart>
      )}
      {props.previewMode === "lines" && (
        <SampleLineChart theme={props.theme}></SampleLineChart>
      )}
    </>
  );
}

function SampleBarChart(props: { theme: CustomThemeSpec }) {
  const svgContainerRef1 = useRef<SVGSVGElement>(null);

  const availableWidth = 800;
  const availableHeight = 750;

  const barChartData = useMemo(() => {
    const settings = dataOutputSettingsDefault();
    const customOutputSettings = props.theme.customOutputSettings;
    const dataset = new StatsDataset(
      sampleDataBarChart,
      [sampleMeasureSelectionBarChart],
      ["country"],
      [GEOCODE_SWEDEN],
      undefined,
      defined(customOutputSettings)
        ? applyCustomOutputSettingsOnCompleteSettings(
            settings,
            customOutputSettings
          )
        : settings
    );

    const chartData = dataset.chartData();
    const barData = createBarChartVerticalDataAndColors(
      chartData,
      availableWidth,
      availableHeight,
      props.theme
    );
    return barData;
  }, [props.theme]);

  return (
    <BarChartVerticalInner
      cardId="mock1"
      dataContainer={barChartData}
      svgContainerRef={svgContainerRef1}
      previewBackgroundColor={props.theme.customBgColor ?? undefined}
    ></BarChartVerticalInner>
  );
}

function SampleLineChart(props: { theme: CustomThemeSpec }) {
  const svgContainerRef1 = useRef<SVGSVGElement>(null);

  const availableWidth = 800;
  const availableHeight = 750;

  const lineChartData = useMemo(() => {
    const settings = dataOutputSettingsDefault();
    const customOutputSettings = props.theme.customOutputSettings;
    const dataset = new StatsDataset(
      sampleDataLineChart,
      [sampleMeasureSelectionLineChart],
      ["country"],
      [GEOCODE_SWEDEN],
      undefined,
      defined(customOutputSettings)
        ? applyCustomOutputSettingsOnCompleteSettings(
            settings,
            customOutputSettings
          )
        : settings
    );

    const chartData = dataset.chartData();
    return createLineChartDataAndColors(
      chartData,
      availableWidth,
      availableHeight,
      props.theme
    );
  }, [props.theme]);

  return (
    <LineChartInner
      cardId="mock1"
      chartData={lineChartData}
      svgContainerRef={svgContainerRef1}
      previewBackgroundColor={props.theme.customBgColor ?? undefined}
    />
  );
}
