import { Dropdown } from "@fluentui/react";
import { sortBy } from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";

import { DefaultLoading } from "../../../../../components/Loading";
import { AppMessagesContext } from "../../../../../lib/application/contexts";
import { getOrganizationsWithCache } from "../../../../../lib/application/requests/admin/common_requests_admin";
import { defined } from "../../../../../lib/core/defined";
import { OrganizationResponse } from "../../../../../lib/infra/api_responses/admin/organizations";
import { StepFooter } from "./StepFooter";
import { DataStep3, CompleteData2, CompleteData3 } from "./types";

function getExistingOrgLock(data?: DataStep3): string | undefined {
  if (!defined(data)) {
    return;
  }
  return data.owner_org.id;
}

export function Step3LockMode(props: {
  data: CompleteData2;
  handlePrevious: (data: CompleteData2) => void;
  handleNext: (data: CompleteData3) => void;
}) {
  const step3 = props.data.step3;
  const [selectedOrg, setSelectedOrg] = useState<string | undefined>(
    getExistingOrgLock(step3)
  );
  const [selectedOrgsErrorMessage, setSelectedOrgsErrorMessage] = useState<
    string | undefined
  >();
  const [organizations, setOrganizations] = useState<OrganizationResponse>();
  const appMessages = useContext(AppMessagesContext);

  useEffect(() => {
    getOrganizationsWithCache().then((res) =>
      setOrganizations(sortBy(res, "name"))
    );
  }, []);

  const validate: () => boolean = useCallback(() => {
    let noErrors = true;
    if (!defined(selectedOrg)) {
      setSelectedOrgsErrorMessage("Du måste välja en organisation");
      noErrors = false;
    } else {
      setSelectedOrgsErrorMessage(undefined);
    }
    return noErrors;
  }, [selectedOrg]);

  useEffect(() => {
    validate();
  }, [validate]);

  const allValid = defined(selectedOrg);

  const handleNext = useCallback(() => {
    if (!validate()) {
      return;
    }
    if (!allValid) {
      return;
    }

    const orgInfo = organizations?.find((o) => o.id === selectedOrg);
    if (!defined(orgInfo)) {
      appMessages?.add(
        "error",
        "Organisationsinfo hittades inte (id: " + selectedOrg + ")"
      );
      return;
    }
    props.handleNext({
      ...props.data,
      step3: { owner_org: orgInfo },
    });
  }, [allValid, appMessages, organizations, props, selectedOrg, validate]);

  if (!defined(organizations)) {
    return <DefaultLoading></DefaultLoading>;
  }

  return (
    <div>
      <h2>Ägarorganisation</h2>

      <section>
        <Dropdown
          required
          label="Ägarorganisation"
          errorMessage={selectedOrgsErrorMessage}
          onChange={(_, option) => {
            if (!defined(option)) {
              return;
            }
            const key = option.key as string;
            setSelectedOrg(key);
            validate();
          }}
          selectedKey={selectedOrg}
          options={organizations.map((org) => {
            return { key: org.id, text: org.name };
          })}
        ></Dropdown>
      </section>

      <StepFooter
        handlePrevious={() => props.handlePrevious(props.data)}
        handleNext={handleNext}
      ></StepFooter>
    </div>
  );
}
