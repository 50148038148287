import { atom, atomFamily } from "recoil";

import { DocumentThumbnailsState, SingleCardThumbnail } from "./definitions";

export const documentThumbnailsState = atom<DocumentThumbnailsState>({
  key: "documentThumbnailsState",
  default: { thumbnails: [] as SingleCardThumbnail[], version: 0 },
});

/**
 * CardId -> thumbnail
 */
export const docThumbnailsAtomFamily = atomFamily<
  SingleCardThumbnail | undefined,
  string
>({
  key: "docThumbnailsAtomFamily",
  default: undefined,
});
