import { useState, useCallback, useContext, useMemo } from "react";
import { Card } from "../../../../components/Card";
import { CustomThemeSpec } from "../../../../lib/application/stats/shared/core/colors/colorSchemes";
import { defined } from "../../../../lib/core/defined";
import {
  AppMessagesContext,
  OrgPreferencesUpdateContext,
  UserInfoContext,
} from "../../../../lib/application/contexts";
import { voidFunc } from "../../../../lib/core/voidFunc";
import { CustomThemesSection, ManageThemes } from "../_shared/ManageThemes";
import { logger } from "../../../../lib/infra/logging";

export function OrgAppearance() {
  const userInfo = useContext(UserInfoContext);
  const [isUpdating, setIsUpdating] = useState(false);

  const handleUpdateOrg = useContext(OrgPreferencesUpdateContext);
  const appMessages = useContext(AppMessagesContext);

  const handleUpdateOrgThemes = useCallback(
    (themes: CustomThemeSpec[]) => {
      if (!defined(handleUpdateOrg)) {
        return;
      }

      setIsUpdating(true);
      return handleUpdateOrg({ orgDefinedThemes: themes })
        .then((res) => {
          res.match({
            ok: voidFunc,
            err: (err) => {
              logger.error(err);
              appMessages?.add("error", "Kunde inte spara ändringarna");
            },
          });
          return res;
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [appMessages, handleUpdateOrg]
  );

  const handleSetDefault = useCallback(
    (id?: string) => {
      if (!defined(handleUpdateOrg)) {
        return;
      }
      setIsUpdating(true);
      return handleUpdateOrg({ defaultThemeId: id })
        .then((res) => {
          res.match({
            ok: voidFunc,
            err: (err) => {
              logger.error(err);
              appMessages?.add("error", "Kunde inte spara ändringarna");
            },
          });
          return res;
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [appMessages, handleUpdateOrg]
  );

  const customThemesSection: CustomThemesSection | undefined = useMemo(() => {
    const orgThemes =
      userInfo?.extendedAppearanceSettings().colorPalettesSpecial.orgThemes;
    if (!defined(orgThemes)) {
      return undefined;
    }

    return {
      name: "Din organisations teman",
      themes: orgThemes,
    };
  }, [userInfo]);

  return (
    <Card id="org-appearance-settings">
      <h2>Förvalt tema för din organisation</h2>
      <p>
        Det förvalda temat används för nya diagram som användare i
        organisationen skapar. Användare kan också skapa och välja andra
        förvalda scheman.
      </p>
      <ManageThemes
        handleUpdateCustomThemes={handleUpdateOrgThemes}
        handleSetDefaultTheme={handleSetDefault}
        customThemesSectionEditable={customThemesSection}
        isUpdating={isUpdating}
        defaultThemeId={
          userInfo?.extendedAppearanceSettings().defaultOrgThemeId
        }
      />
    </Card>
  );
}
