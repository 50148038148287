import { config } from "../../../config";
import {
  MyOrganizationDto,
  MyOrganizationDtoRT,
  UserInfoDto,
  UserInfoDtoRT,
} from "../../infra/api_responses/account";
import {
  FolderNodeDto,
  FolderNodesArrayDtoRT,
} from "../../infra/api_responses/file_area";
import { HttpResult } from "../../infra/HttpResult";
import {
  authedFileDownload,
  authedRequest,
  decodedAuthedRequest,
} from "./shared";

export function getAccountInfo(): Promise<HttpResult<UserInfoDto>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "users/me",
    undefined,
    "GET",
    UserInfoDtoRT
  );
}

export function setUserTutorialSeen(
  tutorialKey: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `users/me/seentutorials/`,
    { tutorial: tutorialKey },
    "POST"
  );
}

export function updateUserPreferences(
  properties: Partial<UserInfoDto["preferences"]>
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    "users/me/preferences",
    properties,
    "PATCH"
  );
}

export function updateOrgPreferences(
  properties: Partial<UserInfoDto["organization_preferences"]>
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    "users/me/organization/preferences",
    properties,
    "PATCH"
  );
}

export function getMyOrganization(): Promise<HttpResult<MyOrganizationDto>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "users/me/organization",
    undefined,
    "GET",
    MyOrganizationDtoRT
  );
}

export function moveFile(
  id: string,
  parentId: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `users/me/organization/files/${encodeURIComponent(id)}`,
    { parent_id: parentId },
    "PATCH"
  );
}

export function moveFolder(
  id: string,
  parentId: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `users/me/organization/folders/${encodeURIComponent(id)}`,
    { parent_id: parentId },
    "PATCH"
  );
}

export function renameFile(
  fileId: string,
  newName: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `users/me/organization/files/${encodeURIComponent(fileId)}`,
    { name: newName },
    "PATCH"
  );
}

export function setFileStatus(
  fileId: string,
  status: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `users/me/organization/files/${encodeURIComponent(fileId)}`,
    { status },
    "PATCH"
  );
}

export function renameFolder(
  folderId: string,
  newName: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `users/me/organization/folders/${encodeURIComponent(folderId)}`,
    { name: newName },
    "PATCH"
  );
}

export function createFolder(
  parentId: string,
  folderName: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    "users/me/organization/folders",
    {
      parent_id: parentId,
      folder_name: folderName,
    },
    "POST"
  );
}

export function listFolderContents(
  parentId: string
): Promise<HttpResult<FolderNodeDto[] | null>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `users/me/organization/folders/${parentId}`,
    undefined,
    "GET",
    FolderNodesArrayDtoRT
  );
}

export function deleteFolder(folderId: string): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `users/me/organization/folders/${encodeURIComponent(folderId)}`,
    undefined,
    "DELETE"
  );
}

export function getFile(fileId: string) {
  return authedFileDownload(
    config.apis.statsV2,
    `users/me/organization/files/${encodeURIComponent(fileId)}`,
    undefined,
    "GET"
  );
}

export function deleteFile(fileId: string): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `users/me/organization/files/${encodeURIComponent(fileId)}`,
    undefined,
    "DELETE"
  );
}
