import { RuntypeBase } from "runtypes/lib/runtype";

import { Api, auth0AuthorizationParams, config } from "../../../config";
import { defined } from "../../core/defined";
import { HttpResult } from "../../infra/HttpResult";
import { logger } from "../../infra/logging";
import {
  handleErrorStatus,
  handleRawHttpResponseXHR,
  rawRequest,
  rawRequestEmbedded,
  RequestMethod,
} from "../../infra/request";
import { pathJoin } from "../../paths";
import { decodeWithLogging } from "../checks/decode";
import { authState } from "../state/AuthState";

/**
 * Basic request that uses either embed key authentication (for public embedded cards)
 * or the logged in user's token.
 *
 * Embed key auth is used whenever such a key exists.
 */
export async function authedRequest<T>(
  api: Api,
  path: string,
  data: any,
  method: RequestMethod
): Promise<HttpResult<T>> {
  const embedKey = authState.embedKey;
  if (defined(embedKey)) {
    return rawRequestEmbedded(
      embedKey,
      api,
      pathJoin("embed", path),
      data,
      method
    );
  }

  const token = await authState.silentTokenGetterOrThrow()({
    authorizationParams: auth0AuthorizationParams,
  });
  return rawRequest<T>(api, token, path, data, method);
}

export async function publicEmbedRequest<T>(
  api: Api,
  path: string,
  data: any,
  method: RequestMethod
): Promise<HttpResult<T>> {
  return rawRequest<T>(api, "", path, data, method);
}

export async function authedFileDownload(
  api: Api,
  path: string,
  data: any,
  method: RequestMethod
): Promise<HttpResult<Response>> {
  const token = await authState.silentTokenGetterOrThrow()({
    authorizationParams: auth0AuthorizationParams,
  });

  const body = defined(data) ? JSON.stringify(data) : null;
  const headers: { [key: string]: string } = {
    Authorization: `Bearer ${token}`,
  };
  if (defined(body)) {
    headers["Content-Type"] = "application/json";
  }

  return fetch(pathJoin(api.url, path), {
    headers,
    method,
    body,
  }).then(async (res) => {
    const status = res.status;
    if (status >= 400 && status < 600) {
      const errInfo = await handleErrorStatus(res);
      if (defined(errInfo)) {
        return errInfo as any;
      }
    }
    if (res.ok) {
      return HttpResult.fromOk(res);
    }

    return HttpResult.fromErr({ code: "unknown-error" });
  });
}

export async function authedFileFormUpload<T>(
  api: Api,
  path: string,
  form: FormData,
  eventHandlers: {
    onUploadProgress: (percentDone: number) => void;
    onUploadDone: () => void;
    onUploadError: () => void;
  }
): Promise<HttpResult<T>> {
  const token = await authState.silentTokenGetterOrThrow()({
    authorizationParams: auth0AuthorizationParams,
  });

  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();

    // Upload event handlers
    xhr.upload.addEventListener("progress", (e) => {
      if (!e.lengthComputable) {
        logger.warn("upload progress event has no computable length");
        return;
      }
      const percentDone = Math.round((e.loaded / e.total) * 100);
      eventHandlers.onUploadProgress(percentDone);
    });
    xhr.upload.addEventListener("load", () => {
      eventHandlers.onUploadDone();
    });
    xhr.upload.addEventListener("error", (e) => {
      logger.error(e);
      eventHandlers.onUploadError();
    });

    // Handle request response / done
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        // Always resolve, never reject, since we're returning a Result
        resolve(handleRawHttpResponseXHR(xhr));
      }
    };

    xhr.open("POST", pathJoin(api.url, path));
    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    xhr.setRequestHeader("Accept", "application/json");

    xhr.send(form);
  });
}

export async function decodedAuthedRequest<T>(
  api: Api,
  path: string,
  params: any,
  method: RequestMethod,
  decoder: RuntypeBase<T>
): Promise<HttpResult<T>> {
  const res = await authedRequest<unknown>(api, path, params, method);
  return res.match({
    ok: (responseData) => {
      try {
        return HttpResult.fromOk(decodeWithLogging(responseData, decoder));
      } catch (e) {
        return HttpResult.fromErr({
          code: "unknown-error",
          info: {
            message: "Decoding error: " + (e as any)?.toString(),
            type: "errorMessage",
          },
        });
      }
    },
    err: (err) => {
      return HttpResult.fromErr(err);
    },
  });
}

export class CrudMaker<ID extends string | number> {
  constructor(private _baseRoute: string) {}
  create<T, U, R extends RuntypeBase<U> = RuntypeBase<U>>(
    decoder: R
  ): (
    data: T
  ) => R extends RuntypeBase<infer Res>
    ? Promise<HttpResult<Res>>
    : Promise<HttpResult<void>>;
  create<T>(): (data: T) => Promise<HttpResult<void>>;
  create<T, U = void>(
    decoder?: RuntypeBase<U>
  ): (data: T) => Promise<HttpResult<void | U>> {
    return (data: T) =>
      authedRequest<U>(config.apis.statsV1, this._baseRoute, data, "POST").then(
        (res) => {
          if (decoder !== undefined) {
            return res.map((inner) => decodeWithLogging(inner, decoder));
          }
          return res;
        }
      );
  }

  /**
   * Read and parse the result
   * @param decoder
   * @returns
   */
  readParse<T>(
    parse: (input: unknown) => T
  ): (id: ID) => Promise<HttpResult<T>> {
    return (id: ID) =>
      authedRequest(
        config.apis.statsV1,
        `${this._baseRoute}/${id}`,
        undefined,
        "GET"
      ).then((res) => res.map((inner) => parse(inner)));
  }

  /**
   * Read and decode the result
   */
  read<T>(decoder: RuntypeBase<T>): (id: ID) => Promise<HttpResult<T>> {
    return (id: ID) =>
      decodedAuthedRequest(
        config.apis.statsV1,
        `${this._baseRoute}/${id}`,
        undefined,
        "GET",
        decoder
      );
  }

  listParse<T>(parse: (input: unknown) => T): () => Promise<HttpResult<T>> {
    return () =>
      authedRequest(
        config.apis.statsV1,
        this._baseRoute,
        undefined,
        "GET"
      ).then((res) => res.map((inner) => parse(inner)));
  }

  list<T>(decoder: RuntypeBase<T>): () => Promise<HttpResult<T>> {
    return () =>
      decodedAuthedRequest(
        config.apis.statsV1,
        this._baseRoute,
        undefined,
        "GET",
        decoder
      );
  }

  delete(): (id: ID) => Promise<HttpResult<void>> {
    return (id: ID) =>
      authedRequest(
        config.apis.statsV1,
        `${this._baseRoute}/${id}`,
        undefined,
        "DELETE"
      );
  }

  update<T>(): (id: ID, data: T) => Promise<HttpResult<void>> {
    return (id: ID, data: T) =>
      authedRequest(
        config.apis.statsV1,
        `${this._baseRoute}/${id}`,
        data,
        "PUT"
      );
  }

  patch<T>(): (id: ID, data: Partial<T>) => Promise<HttpResult<void>> {
    return (id: ID, data: Partial<T>) =>
      authedRequest(
        config.apis.statsV1,
        `${this._baseRoute}/${id}`,
        data,
        "PATCH"
      );
  }
}
