import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { GeographiesSerializable } from "../../../../domain/geography";
import { makeDataSelectionGrouping } from "../../stats/document-core/core";
import { groupingSelectionQuery } from "../../stats/document-core/queries/dataCard";
import { useGroupingSelectionChanger } from "./shared/useGroupingSelectionChanger";

export function useChangeGroupingSelection(
  cardId: string,
  geographies: GeographiesSerializable,
  adminShowDraftData: boolean
) {
  const groupingSelection = useRecoilValue(
    groupingSelectionQuery({ cardStateId: cardId })
  );

  const handleChangeGroupingSelection = useGroupingSelectionChanger(
    cardId,
    groupingSelection,
    geographies
  );

  /**
   * Change the subject path, keeping some settings from previous data selection
   */
  const handleChangePrimaryPath = useCallback(
    (path: string[]) => {
      handleChangeGroupingSelection(makeDataSelectionGrouping(path));
    },
    [handleChangeGroupingSelection]
  );

  return { handleChangeGroupingSelection, handleChangePrimaryPath };
}
