import { Checkbox, Dropdown } from "@fluentui/react";
import { useCallback, useMemo, useState } from "react";

import {
  FluentModalBody,
  FluentModalFooter,
  FluentModal,
} from "../../../../../components/Modal";
import { DocCardTextCK } from "../../../../../lib/application/state/stats/document-core/core";
import { defined } from "../../../../../lib/core/defined";
import { Button } from "../../../../../components/Button";

interface Props {
  cardId: string;
  onClose: () => void;
  card: DocCardTextCK;
  setCard: (card: DocCardTextCK) => void;
}

export function AdjustTextMarginDialog(props: Props) {
  const { onClose, card, setCard } = props;

  const [currentMarginTop, setCurrentMarginTop] = useState(
    card.customMarginTop
  );
  const [currentMarginRight, setCurrentMarginRight] = useState(
    card.customMarginRight
  );
  const [currentMarginBottom, setCurrentMarginBottom] = useState(
    card.customMarginBottom
  );
  const [currentMarginLeft, setCurrentMarginLeft] = useState(
    card.customMarginLeft
  );

  const handleSave = useCallback(() => {
    setCard({
      ...card,
      customMarginTop: currentMarginTop,
      customMarginRight: currentMarginRight,
      customMarginBottom: currentMarginBottom,
      customMarginLeft: currentMarginLeft,
    });
    onClose();
  }, [
    card,
    currentMarginTop,
    currentMarginRight,
    currentMarginBottom,
    currentMarginLeft,
    onClose,
    setCard,
  ]);

  const allMarginsZero = useMemo(() => {
    const properties = [
      currentMarginBottom,
      currentMarginLeft,
      currentMarginRight,
      currentMarginTop,
    ];

    return properties.every((p) => p === 0);
  }, [
    currentMarginBottom,
    currentMarginLeft,
    currentMarginRight,
    currentMarginTop,
  ]);

  const handleNoMarginChange = useCallback((checked: boolean) => {
    if (checked) {
      setCurrentMarginTop(0);
      setCurrentMarginRight(0);
      setCurrentMarginBottom(0);
      setCurrentMarginLeft(0);
    } else {
      setCurrentMarginBottom(undefined);
      setCurrentMarginLeft(undefined);
      setCurrentMarginRight(undefined);
      setCurrentMarginTop(undefined);
    }
  }, []);

  return (
    <FluentModal
      title="Anpassa marginaler"
      isOpen={true}
      onClose={onClose}
      width="md"
    >
      <FluentModalBody>
        <div id="adjust-text-margin-dialog">
          <section className="settings-grid">
            <Checkbox
              label="Inga marginaler"
              checked={allMarginsZero}
              onChange={(_, checked) => handleNoMarginChange(!!checked)}
            />
            <NumberDropdown
              label="Över"
              value={currentMarginTop}
              onChange={(val) => {
                setCurrentMarginTop(val);
              }}
              disabled={allMarginsZero}
              values={marginSizes}
            />
            <NumberDropdown
              label="Under"
              value={currentMarginBottom}
              onChange={(val) => {
                setCurrentMarginBottom(val);
              }}
              disabled={allMarginsZero}
              values={marginSizes}
            />
            <NumberDropdown
              label="Höger"
              value={currentMarginRight}
              onChange={(val) => {
                setCurrentMarginRight(val);
              }}
              disabled={allMarginsZero}
              values={marginSizes}
            />
            <NumberDropdown
              label="Vänster"
              value={currentMarginLeft}
              onChange={(val) => {
                setCurrentMarginLeft(val);
              }}
              disabled={allMarginsZero}
              values={marginSizes}
            />
          </section>
        </div>
      </FluentModalBody>
      <FluentModalFooter>
        <Button title="Avbryt" onClick={onClose} />
        <Button intent="primary" title="Spara" onClick={handleSave} />
      </FluentModalFooter>
    </FluentModal>
  );
}

const marginSizes = [0, 4, 8, 14, 22, 36, 72, 144];

function NumberDropdown(props: {
  disabled?: boolean;
  label: string;
  value: number | null | undefined;
  onChange: (value?: number) => void;
  values: number[];
}) {
  return (
    <Dropdown
      disabled={props.disabled}
      label={props.label}
      selectedKey={props.value?.toString() ?? "undef"}
      options={[
        { key: "undef", text: "Auto" },
        ...props.values.map((v) => ({
          key: v.toString(),
          text: v.toString(),
        })),
      ]}
      onChange={(e, item) => {
        if (!defined(item)) {
          return;
        }
        if (item.key === "undef") {
          props.onChange(undefined);
        } else {
          props.onChange(parseInt(item.key as string));
        }
      }}
    ></Dropdown>
  );
}
