import { Dictionary, Record, Static, String as StringRT } from "runtypes";
import { DimensionType } from "../domain/measure/definitions";
import { DimensionValueMinimal } from "./survey_dimensions";

export interface NewExternalMeasureMetadata {
  [key: string]: any;
  dimensions: {
    data_column: string;
    label: string;
    type: DimensionType;
    values: Omit<DimensionValueMinimal<string>, "id">[];
  }[];
}

export interface CreateExternalMetadata {
  measurements: NewExternalMeasureMetadata[];
}

export const ExternalMetadataResponseRT = Record({
  validation_errors: Dictionary(Dictionary(StringRT)),
});

export type ExternalMetadataResponse = Static<
  typeof ExternalMetadataResponseRT
>;
