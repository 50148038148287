import * as _ from "lodash";
import { last } from "lodash";
import {
  Array as ArrayRT,
  Literal,
  Static,
  Union,
  String as StringRT,
  Record,
  Partial,
} from "runtypes";

import {
  GeoSelections,
  SingleGeoTypeSelection,
} from "../application/state/stats/document-core/core";
import { defined } from "../core/defined";
import { shortenMunicipalityLabel } from "./names";

export const GEONAME_SWEDEN = "Sverige";
export const GEOCODE_SWEDEN = "se";
export const GEOCODE_MUNICIPALITY_STHLM = "se0180";
export const GEOCODE_NUTS3_STHLM = "se110";

export const REGION_ITEM_SWEDEN: RegionResponseItem = {
  type: "country",
  geocode: GEOCODE_SWEDEN,
  label: GEONAME_SWEDEN,
} as const;

export const GeoTypeRT = Union(
  Literal("country"),
  Literal("nuts1"),
  Literal("nuts2"),
  Literal("nuts3"),
  Literal("municipal")
);

export const GeoTypeMicroRT = Union(Literal("deso"), Literal("regso"));
export type GeoTypeMicro = Static<typeof GeoTypeMicroRT>;
export const geoTypesMicro: GeoTypeMicro[] = ["deso", "regso"];

export function geocodesToSelections(
  codes: string[],
  geographies: GeographiesSerializable
): GeoSelections {
  const selections: GeoSelections = emptyGeoSelections();
  for (const code of codes) {
    const item = geographies.geocodeToItem(code);
    if (defined(item)) {
      selections[item.type].push(item);
    }
  }
  return selections;
}

export function geoSelectionsToCodes(selections: GeoSelections): string[] {
  return _.chain(Object.values(selections))
    .flatten()
    .map((item) => item.geocode)
    .value();
}

export function numSelectedGeos(selections: GeoSelections): number {
  return geoSelectionsToCodes(selections).length;
}

export function narrowSelectionsToDeepestType(
  selections: GeoSelections
): SingleGeoTypeSelection | undefined {
  const deepestType = allGeoTypes
    .slice()
    .reverse()
    .find((geoType) => selections[geoType]?.length > 0);
  if (!defined(deepestType)) {
    return undefined;
  }
  return { type: deepestType, items: selections[deepestType] };
}

/**
 * Geotypes, ordered from high-level to low-level
 */
export const allGeoTypes: readonly GeoType[] = GeoTypeRT.alternatives.map(
  (c) => c.value
);
export type GeoType = Static<typeof GeoTypeRT>;

export function greatestResolutionGeotype(
  geoTypes: readonly GeoType[]
): GeoType {
  if (geoTypes.length === 0) {
    throw new Error("Received empty list");
  }
  return geoTypes.reduce((prev: GeoType, current: GeoType) =>
    geoTypes.indexOf(prev) > geoTypes.indexOf(current) ? prev : current
  );
}

export function nextGeoResolution(current: GeoType): GeoType | undefined {
  return allGeoTypes[allGeoTypes.indexOf(current) + 1];
}

export function higherGeoResolutions(current: GeoType): GeoType[] {
  return allGeoTypes.slice(allGeoTypes.indexOf(current) + 1);
}

/**
 * Returns the highest resolution, ie closest municipal level.
 */
export function geoResolutionMin(a: GeoType, b: GeoType): GeoType {
  return allGeoTypes.indexOf(a) < allGeoTypes.indexOf(b) ? a : b;
}

/**
 * Returns the lowest resolution, ie closest to country level.
 */
export function geoResolutionMax(a: GeoType, b: GeoType): GeoType {
  return allGeoTypes.indexOf(a) > allGeoTypes.indexOf(b) ? a : b;
}

export function emptyGeoSelections(): GeoSelections {
  return { country: [], municipal: [], nuts1: [], nuts2: [], nuts3: [] };
}

export function displayGeographyPlural(g: GeoType): string {
  switch (g) {
    case "nuts1":
      return "landsändar";
    case "nuts2":
      return "riksområden";
    case "municipal":
    case "nuts3":
      return displayGeography(g) + "er";
    case "country":
      return "länder";
  }
}

export function displayGeography(g: GeoType): string {
  switch (g) {
    case "municipal":
      return "kommun";
    case "nuts1":
      return "landsände";
    case "nuts2":
      return "riksområde";
    case "nuts3":
      return "region";
    case "country":
      return "land";
  }
}

export interface GeoTree extends GeoItem {
  type: GeoType;
  label: string;
  geocode: string;
  children: GeoTree[];
}

export const GeoItemRT = Record({
  geocode: StringRT,
  label: StringRT,
  type: GeoTypeRT,
});
export type GeoItem = Static<typeof GeoItemRT>;

export const RegionResponseItemRT = GeoItemRT.And(
  Partial({
    nuts1: StringRT,
    nuts2: StringRT,
    nuts3: StringRT,
  })
);
export const RegionsResponseRT = ArrayRT(RegionResponseItemRT);
export type RegionResponseItem = Static<typeof RegionResponseItemRT>;

export type RegionsResponse = Static<typeof RegionsResponseRT>;

export interface GeographiesSerializable {
  labelToGeocode: (label: string, geoType: GeoType) => string | undefined;
  geocodeToItem: (geocode: string) => RegionResponseItem | undefined;
  tree: GeoTree;
  itemsList: RegionResponseItem[];
}

export const geographiesDummy: GeographiesSerializable = {
  labelToGeocode: () => undefined,
  geocodeToItem: () => undefined,
  tree: {
    type: "country",
    geocode: "se",
    label: "Sverige",
    children: [],
  } as GeoTree,
  itemsList: [],
} as GeographiesSerializable;

export class Geographies {
  private _tree: GeoTree;

  private constructor(private _data: RegionsResponse) {
    this._tree = makeTree(_data);
  }

  public list(geoTypes: GeoType[]): RegionResponseItem[] {
    return listify(this._data, geoTypes);
  }

  public tree(): GeoTree {
    return this._tree;
  }

  /**
   * Traverses the tree and returns subtrees from a given maximum level (GeoType),
   */
  public treesFromLevel(highestLevel: GeoType): GeoTree[] {
    if (this._tree.type === highestLevel) {
      return [this._tree];
    }
    return subtreesAtLevel(this._tree, highestLevel);
  }

  static serializable(data: RegionsResponse): GeographiesSerializable {
    const labelLookup: { [key: string]: RegionResponseItem } = {};
    const geocodeLookup: { [key: string]: RegionResponseItem } = {};
    for (const item of data) {
      const itemLabel =
        item.type === "municipal"
          ? shortenMunicipalityLabel(item.label)
          : item.label;
      const key = geocodeLookupKey(itemLabel, item.type);
      labelLookup[key] = item;
      geocodeLookup[item.geocode] = item;
    }

    return {
      labelToGeocode: (label: string, geoType: GeoType) => {
        const key = geocodeLookupKey(label, geoType);
        return labelLookup[key]?.geocode;
      },
      geocodeToItem: (geocode: string) => geocodeLookup[geocode],
      tree: makeTree(data),
      itemsList: listify(data, allGeoTypes),
    };
  }
}

export function treesFromLevel(
  geographies: GeographiesSerializable,
  highestLevel: GeoType
): GeoTree[] {
  const tree = geographies.tree;
  if (tree.type === highestLevel) {
    return [tree];
  }
  return subtreesAtLevel(tree, highestLevel);
}

export function listWithGeoTypes(
  geographies: GeographiesSerializable,
  includeGeoTypes: readonly GeoType[]
) {
  return listify(geographies.itemsList, includeGeoTypes);
}

function listify(data: RegionsResponse, includeGeoTypes: readonly GeoType[]) {
  return data.filter((item) => includeGeoTypes.includes(item.type));
}

function makeTree(data: RegionsResponse) {
  const root = data.find((item) => item.geocode === "se");
  if (!defined(root)) {
    throw new Error("Could not build geography tree");
  }
  return traverseGeography(root.geocode, root.label, root.type, data);
}

function subtreesAtLevel(tree: GeoTree, level: GeoType): GeoTree[] {
  if (tree.type === level) {
    return [tree];
  }
  const trees: GeoTree[] = [];
  for (const subtree of tree.children) {
    trees.push(...subtreesAtLevel(subtree, level));
  }
  return trees;
}

function traverseGeography(
  rootGeoCode: string,
  label: string,
  level: GeoType,
  data: RegionsResponse
): GeoTree {
  const isChild = (item: RegionResponseItem) => {
    const isOneLevelDown =
      allGeoTypes.indexOf(level) === allGeoTypes.indexOf(item.type) - 1;
    if (!isOneLevelDown) {
      return false;
    }
    switch (level) {
      case "country":
        return item.type === "nuts1";
      case "municipal":
        return false;
      default:
        return item[level] === rootGeoCode;
    }
  };
  const children =
    level === last(allGeoTypes) ? [] : data.filter((item) => isChild(item));

  return {
    geocode: rootGeoCode,
    label,
    type: level,
    children: children.map((c) =>
      traverseGeography(c.geocode, c.label, c.type, data)
    ),
  };
}

/** Generate a key used in dictionary */
function geocodeLookupKey(label: string, geoType: GeoType): string {
  return label + "_" + geoType;
}
