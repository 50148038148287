import { useEffect, useMemo } from "react";
import React from "react";

import { PivotPlaceholder } from "../data_card/PivotPlaceholder";
import { SVG_CHART_MAX_WIDTH } from "../../../../../lib/application/stats/shared/charts_common";
import { ChartType } from "../../../../../lib/application/stats/shared/core/definitions";
import { defined } from "../../../../../lib/core/defined";
import { BarChartVerticalContainerV2 } from "../../../../../components/charts/BarChartVerticalV2";
import { BarChartHorizontalContainerV2 } from "../../../../../components/charts/BarChartHorizontalV2";
import { LineChartContainer } from "../../../../../components/charts/LineChartV2";
import { ChartDataState } from "../../../../../lib/application/state/stats/document-core/_core-shared";
import { useRecalculateChart } from "../../../../../lib/application/state/actions/useRecalculateChart";
import { useElementSize } from "../../../../../lib/application/hooks/useElementSize";

export interface ChartTabProps {
  cardId: string;
  chartDataState?: ChartDataState;
}

export const ChartTab = React.memo(ChartTabInner);
function ChartTabInner(props: ChartTabProps) {
  const { chartDataState } = props;

  const customBgStyle = useMemo(() => {
    const customBgColor =
      chartDataState?.loadedChartData?.colorSchemeContainer.customBgColor;
    return defined(customBgColor) ? { backgroundColor: customBgColor } : {};
  }, [chartDataState?.loadedChartData?.colorSchemeContainer.customBgColor]);

  const loadedData = chartDataState?.loadedChartData;

  const recalculateChart = useRecalculateChart(props.cardId);

  const { ref, width } = useElementSize();

  useEffect(() => {
    if (!defined(loadedData)) {
      return;
    }
    if (!defined(width)) {
      return;
    }
    const availableChartWidth = Math.min(SVG_CHART_MAX_WIDTH, width - 1); // FIXME
    let timeoutHandle: NodeJS.Timeout | null = null;
    // Recaulculate chart if the available width is notably bigger than the rendered chart width,
    // or if the available width is smaller than current rendered width
    if (
      // loadedData.renderWidth < availableChartWidth * 0.9 ||
      loadedData.renderWidth < availableChartWidth ||
      loadedData.renderWidth > availableChartWidth
    ) {
      timeoutHandle = setTimeout(() => {
        recalculateChart(availableChartWidth);
      }, 100);
    }
    return () => {
      if (defined(timeoutHandle)) {
        clearTimeout(timeoutHandle);
      }
    };
  }, [loadedData, recalculateChart, width]);

  if (!defined(loadedData)) {
    return <PivotPlaceholder icon={"grouped-bar-chart"}></PivotPlaceholder>;
  }

  switch (loadedData.chartType) {
    case ChartType.barHorizontal:
      return (
        <div
          ref={ref}
          className="dataset-chart tab-content"
          style={customBgStyle}
        >
          <BarChartHorizontalContainerV2
            cardId={props.cardId}
            dataContainer={loadedData}
          ></BarChartHorizontalContainerV2>
        </div>
      );
    case ChartType.barVertical:
      return (
        <div
          ref={ref}
          className="dataset-chart tab-content"
          style={customBgStyle}
        >
          <BarChartVerticalContainerV2
            cardId={props.cardId}
            dataContainer={loadedData}
          ></BarChartVerticalContainerV2>
        </div>
      );
    case ChartType.line:
      return (
        <div
          ref={ref}
          className="dataset-chart tab-content"
          style={customBgStyle}
        >
          <LineChartContainer
            cardId={props.cardId}
            chartData={loadedData}
          ></LineChartContainer>
        </div>
      );
  }
}
