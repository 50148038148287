import { fromPairs } from "lodash";

export interface Dictionary<T> {
  [key: string]: T;
}

export function keepSingleValuePerKey<U, T extends U[] | undefined>(
  dict: Dictionary<T>
): Dictionary<T> {
  const entries = Object.entries(dict);
  if (
    entries.every(([key, values]) => values === undefined || values.length <= 1)
  ) {
    // In order to preserve referential equality, we return the original
    // when no change is necessary.
    return dict;
  }

  return fromPairs<T>(
    entries.map<[string, T]>(
      ([key, values]) => [key, values?.slice(0, 1) ?? []] as [string, T]
    )
  );
}
