import _ from "lodash";
import { Dropdown, IDropdownProps } from "@fluentui/react";

import { dropdownStyles, dropdownStylesCondensed } from "./style";
import { SELECT_ALL_LABEL } from "../../../../../lib/application/menus";
import { defined } from "../../../../../lib/core/defined";

export function StyledDropdown(props: IDropdownProps) {
  return (
    <Dropdown
      onRenderTitle={(selectedOptions) => {
        const title = !defined(selectedOptions)
          ? "[Ingen vald]"
          : _.chain(selectedOptions)
              .map((option) => option.text)
              .filter((label) => label !== SELECT_ALL_LABEL)
              .join(", ")
              .value();
        return <span title={title}>{title}</span>;
      }}
      styles={dropdownStyles}
      {...props}
    ></Dropdown>
  );
}

export function StyledDropdownCondensed(props: IDropdownProps) {
  return (
    <Dropdown
      onRenderTitle={(selectedOptions) => {
        const title = !defined(selectedOptions)
          ? "[Ingen vald]"
          : _.chain(selectedOptions)
              .map((option) => option.text)
              .filter((label) => label !== SELECT_ALL_LABEL)
              .join(", ")
              .value();
        return <span title={title}>{title}</span>;
      }}
      styles={dropdownStylesCondensed}
      {...props}
    ></Dropdown>
  );
}
