import {
  AdminMetadataMicroDto,
  AdminMetadataOrAlias,
} from "../../../domain-admin/metadata";
import { AdminMeasureListDto } from "../../../infra/api_responses/admin/document_versions";
import {
  LicenseTypesResponse,
  OrganizationDetailsV2Dto,
} from "../../../infra/api_responses/admin/organizations";
import { DocumentDto } from "../../../infra/api_responses/document";
import { EmbeddedDocumentDto } from "../../../infra/api_responses/document_embed";
import { HttpResult } from "../../../infra/HttpResult";
import { LatestSettingsFormat } from "../../state/stats/default-settings/common";
import { WorkspaceLatest } from "../../state/stats/workspace/shared";
import { getParentOrgInfo } from "../third_party_org_admin";
import { adminGetAIAnalysis } from "./ai_analysis";
import {
  saveMetadataPatches,
  listMeasuresMetadata,
  MetadataPatch,
  saveMeasureDefaultSettings,
  listMeasuresMetadataMicro,
  saveMetadataPatchesMicro,
  MetadataPatchMicro,
} from "./common_requests_admin";
import {
  getDocument,
  getEmbeddedDocument,
  listDocumentsByVersion,
  listDocumentVersions,
  listEmbeddedDocumentsByVersion,
  listEmbeddedDocumentVersions,
  updateDocument,
  updateEmbeddedDocument,
} from "./document_management";
import {
  createAutoImportSpec,
  deleteAutoImportSpec,
  getAutoImportLogs,
  getAutoImportSpec,
  listAutoImportGroups,
  listAutoImportSchedules,
  listAutoImportSpecs,
  patchAutoImportSpec,
  runAutoImportScript,
} from "./import_management";
import {
  deleteMeasureMicro,
  deleteMeasureStats,
  deleteMeasureSurvey,
  deleteMemberOrgsMeasure,
} from "./measure_management/delete";
import {
  listSettingsVersions,
  listMeasuresBySettingsVersion,
} from "./measure_management/settings";
import {
  addOrganizationInvites as addOrganizationInvite,
  addUserLicense,
  createOrganizationV2,
  deleteOrganizationInvite,
  deleteOrganizationLicenseV2,
  deleteUserFromOrganization,
  editOrganizationLicenseV2,
  getOrganizationV2,
  listLicenseTypes,
  listOrganizationsV2,
  updateOrganizationV2,
  removeUserLicense,
  sendOrganizationInviteEmailToUser,
  addUserRole,
  removeUserRole,
  createThirdPartyOrg,
  addThirdPartyOrgMembers,
  updateThirdPartyOrgMember,
  removeThirdPartyOrgMember,
  removeThirdPartyOrg,
  createThirdPartyDocsFromTemplate,
  sendThemeToOrg,
  sendThemeToUser,
  patchThirdPartyOrg,
} from "./organization_management";

export type CustomDataListGetter<T, U = undefined> = (
  limit: number,
  offset: number,
  colFilters: { [key: string]: string },
  orderBy: { column: string; sort_order: "asc" | "desc" } | undefined,
  options?: U
) => Promise<HttpResult<CustomDataListResponse<T>>>;

export interface CustomDataListResponse<T> {
  rows: T[] | null;
  total_count: number;
}

export interface AdminApi {
  listDocumentVersions: () => Promise<HttpResult<number[]>>;
  listDocumentsByVersion: (
    version: number
  ) => Promise<HttpResult<{ id: number; title: string }[] | null>>;
  listSettingsVersions: () => Promise<HttpResult<number[] | null>>;
  listMeasuresBySettingsVersion: {
    (version: number): Promise<HttpResult<AdminMeasureListDto>>;
  };
  getDocument: (documentId: number) => Promise<HttpResult<DocumentDto>>;
  updateDocument: (
    documentId: number,
    workspace: WorkspaceLatest
  ) => Promise<HttpResult<void>>;
  listEmbeddedDocumentVersions: () => Promise<HttpResult<number[]>>;
  listEmbeddedDocumentsByVersion: (
    version: number
  ) => Promise<HttpResult<{ key: string; title: string }[] | null>>;
  getEmbeddedDocument: (
    key: string
  ) => Promise<HttpResult<EmbeddedDocumentDto>>;
  updateEmbeddedDocument: (
    key: string,
    workspace: WorkspaceLatest
  ) => Promise<HttpResult<void>>;
  getAIAnalysis(tableData: string): Promise<HttpResult<{ result: string }>>;
  deleteMeasureStats: (measureId: number) => Promise<HttpResult<unknown>>;
  deleteMeasureMicro: (measureId: number) => Promise<HttpResult<unknown>>;
  deleteMeasureSurvey: (measureId: number) => Promise<HttpResult<unknown>>;
  deleteMemberOrgsMeasure: (measureId: number) => Promise<HttpResult<unknown>>;
  saveMeasureDefaultSettings: (
    measureId: number,
    settings: LatestSettingsFormat
  ) => Promise<HttpResult<unknown>>;
  listMeasuresMetadata: CustomDataListGetter<AdminMetadataOrAlias>;
  listMeasuresMetadataMicro: CustomDataListGetter<AdminMetadataMicroDto>;
  saveMetadataPatches: (
    patchList: MetadataPatch[]
  ) => Promise<HttpResult<unknown>>;
  saveMetadataPatchesMicro: (
    patchList: MetadataPatchMicro[]
  ) => Promise<HttpResult<unknown>>;
  listLicenseTypes: () => Promise<HttpResult<LicenseTypesResponse>>;
  addOrganizationV2: typeof createOrganizationV2;
  deleteUserFromOrganization: typeof deleteUserFromOrganization;
  getOrganizationV2: (
    id: string
  ) => Promise<HttpResult<OrganizationDetailsV2Dto>>;
  editOrganizationLicenseV2: typeof editOrganizationLicenseV2;
  deleteOrganizationLicenseV2: typeof deleteOrganizationLicenseV2;
  addOrganizationInvite: typeof addOrganizationInvite;
  deleteOrganizationInvite: typeof deleteOrganizationInvite;
  listOrganizationsV2: typeof listOrganizationsV2;
  addUserLicense: typeof addUserLicense;
  removeUserLicense: typeof removeUserLicense;
  addUserRole: typeof addUserRole;
  removeUserRole: typeof removeUserRole;
  updateOrganizationV2: typeof updateOrganizationV2;
  sendOrganizationInviteEmailToUser: typeof sendOrganizationInviteEmailToUser;
  createThirdPartyDocsFromTemplate: typeof createThirdPartyDocsFromTemplate;
  createThirdPartyOrg: typeof createThirdPartyOrg;
  removeThirdPartyOrg: typeof removeThirdPartyOrg;
  getParentOrgInfo: typeof getParentOrgInfo;
  addThirdPartyOrgMembers: typeof addThirdPartyOrgMembers;
  removeThirdPartyOrgMember: typeof removeThirdPartyOrgMember;
  updateThirdPartyOrgMember: typeof updateThirdPartyOrgMember;
  patchThirdPartyOrg: typeof patchThirdPartyOrg;
  sendThemeToOrg: typeof sendThemeToOrg;
  sendThemeToUser: typeof sendThemeToUser;
  getAutoImportSpec: typeof getAutoImportSpec;
  listAutoImportSpecs: typeof listAutoImportSpecs;
  createAutoImportSpec: typeof createAutoImportSpec;
  listAutoImportGroups: typeof listAutoImportGroups;
  listAutoImportSchedules: typeof listAutoImportSchedules;
  patchAutoImportSpec: typeof patchAutoImportSpec;
  getAutoImportLogs: typeof getAutoImportLogs;
  runAutoImportScript: typeof runAutoImportScript;
  deleteAutoImportSpec: typeof deleteAutoImportSpec;
}

export const adminApi: AdminApi = {
  listMeasuresBySettingsVersion: listMeasuresBySettingsVersion,
  listSettingsVersions: listSettingsVersions,
  getDocument: getDocument,
  updateDocument: updateDocument,
  listDocumentVersions: listDocumentVersions,
  listDocumentsByVersion: listDocumentsByVersion,
  deleteMeasureStats: deleteMeasureStats,
  deleteMeasureMicro: deleteMeasureMicro,
  deleteMeasureSurvey: deleteMeasureSurvey,
  deleteMemberOrgsMeasure: deleteMemberOrgsMeasure,
  saveMeasureDefaultSettings: saveMeasureDefaultSettings,
  getEmbeddedDocument,
  updateEmbeddedDocument,
  listEmbeddedDocumentVersions: listEmbeddedDocumentVersions,
  listEmbeddedDocumentsByVersion: listEmbeddedDocumentsByVersion,
  getAIAnalysis: adminGetAIAnalysis,
  listMeasuresMetadata: listMeasuresMetadata,
  listMeasuresMetadataMicro: listMeasuresMetadataMicro,
  saveMetadataPatches: saveMetadataPatches,
  saveMetadataPatchesMicro: saveMetadataPatchesMicro,
  deleteUserFromOrganization: deleteUserFromOrganization,
  addUserLicense: addUserLicense,
  removeUserLicense: removeUserLicense,
  addUserRole: addUserRole,
  removeUserRole: removeUserRole,
  listOrganizationsV2: listOrganizationsV2,
  addOrganizationInvite: addOrganizationInvite,
  deleteOrganizationInvite: deleteOrganizationInvite,
  deleteOrganizationLicenseV2: deleteOrganizationLicenseV2,
  listLicenseTypes: listLicenseTypes,
  addOrganizationV2: createOrganizationV2,
  editOrganizationLicenseV2: editOrganizationLicenseV2,
  getOrganizationV2: getOrganizationV2,
  updateOrganizationV2: updateOrganizationV2,
  sendOrganizationInviteEmailToUser: sendOrganizationInviteEmailToUser,
  createThirdPartyDocsFromTemplate: createThirdPartyDocsFromTemplate,
  createThirdPartyOrg: createThirdPartyOrg,
  removeThirdPartyOrg: removeThirdPartyOrg,
  getParentOrgInfo: getParentOrgInfo,
  addThirdPartyOrgMembers: addThirdPartyOrgMembers,
  removeThirdPartyOrgMember: removeThirdPartyOrgMember,
  updateThirdPartyOrgMember: updateThirdPartyOrgMember,
  patchThirdPartyOrg: patchThirdPartyOrg,
  sendThemeToOrg: sendThemeToOrg,
  sendThemeToUser: sendThemeToUser,
  getAutoImportSpec: getAutoImportSpec,
  listAutoImportSpecs: listAutoImportSpecs,
  createAutoImportSpec: createAutoImportSpec,
  listAutoImportGroups: listAutoImportGroups,
  listAutoImportSchedules: listAutoImportSchedules,
  patchAutoImportSpec: patchAutoImportSpec,
  getAutoImportLogs: getAutoImportLogs,
  runAutoImportScript: runAutoImportScript,
  deleteAutoImportSpec: deleteAutoImportSpec,
};
