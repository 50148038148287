import {
  Record,
  String as StringRT,
  Number as NumberRT,
  Boolean as BooleanRT,
  Unknown,
  Static,
  Optional,
} from "runtypes";
import { CustomBrandingDtoRT } from "./admin/organizations";

export const DocumentDtoRT = Record({
  id: NumberRT,
  title: StringRT,
  draft: Optional(BooleanRT),
  workspace: Unknown,
});
export type DocumentDto = Static<typeof DocumentDtoRT>;

export const ThirdPartyDocDtoRT = Record({
  third_party_doc_id: StringRT,
  title: StringRT,
  created_at: StringRT,
  content: Unknown,
  custom_branding: Optional(CustomBrandingDtoRT),
});
export type ThirdPartyDocDto = Static<typeof ThirdPartyDocDtoRT>;

export const CreateSecureDeliveryDocResultRT = Record({
  third_party_doc_id: StringRT,
});
