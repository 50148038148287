export function propertiesEqual<T extends object, K extends keyof T>(
  left: T,
  right: T,
  keys: K[]
): boolean {
  for (const key of keys) {
    if (left[key] !== right[key]) {
      return false;
    }
  }
  return true;
}
