import { assertNever } from "../core/assert";
import { defined } from "../core/defined";
import { Either } from "../core/func/monad";
import { ResultType } from "../core/Result";

export type RequestErrorInfo =
  | {
      type: "data";
      data: unknown;
    }
  | {
      type: "errorCode";
      error: string;
      message?: string;
    }
  | {
      type: "errorMessage";
      message: string;
    };

export type HttpError = (
  | { code: "4xx" }
  | { code: "conflict" }
  | { code: "unauthorized" }
  | { code: "not-found" }
  | { code: "bad-client-input" }
  | { code: "internal-server-error" }
  | { code: "unknown-error" }
  | { code: "fetch-error" }
) & {
  info?: RequestErrorInfo;
};

export class HttpResult<T> implements Either<HttpError, T> {
  // export class HttpResult<T> extends Result<T, HttpError> {
  constructor(
    private _resRaw: ResultType<T, HttpError>,
    private _md5?: string
  ) {}

  /**
   * @deprecated
   * use fold instead
   */
  switch(): ResultType<T, HttpError> {
    return this._resRaw;
  }

  match<LeftOut, RightOut>(matcher: {
    ok: (arg: T) => LeftOut;
    err: (arg: HttpError) => RightOut;
  }): LeftOut | RightOut {
    const resRaw = this._resRaw;
    if (resRaw.type === "err") {
      return matcher.err(resRaw.error);
    }
    return matcher.ok(resRaw.data);
  }

  unwrap(): T {
    if (this._resRaw.type === "err") {
      throw new Error("Called unwrap on err!");
    }
    return this._resRaw.data;
  }

  fold<LeftOut, RightOut>(
    onLeft: (arg: HttpError) => LeftOut,
    onRight: (arg: T) => RightOut
  ): LeftOut | RightOut {
    const resRaw = this._resRaw;
    if (resRaw.type === "err") {
      return onLeft(resRaw.error);
    }
    return onRight(resRaw.data);
  }

  ok(): T | undefined {
    return this._resRaw.type === "ok" ? this._resRaw.data : undefined;
  }

  map<U>(mapFunc: (input: T) => U): HttpResult<U> {
    const resRaw = this._resRaw;
    if (resRaw.type === "err") {
      return this as any;
    }
    const mapRes = mapFunc(resRaw.data);
    if (mapRes instanceof HttpResult) {
      return mapRes as any;
    }
    return HttpResult.fromOk(mapRes, this._md5) as any;
  }

  /**
   * MD5 checksum for the response content, if any.
   */
  md5checksum(): string | undefined {
    return this._md5;
  }

  static fromErr<T = unknown>(error: HttpError) {
    return new HttpResult<T>({ type: "err", error });
  }

  static fromOk<T>(data: T, md5?: string) {
    return new HttpResult<T>({ type: "ok", data }, md5);
  }
}

export function displayHttpErrorInternal(err: HttpError): string {
  return `${err.code}: ${getMessage(err.info) ?? "no info"}`;
}

function getMessage(info?: RequestErrorInfo): string | undefined {
  if (!defined(info)) {
    return;
  }
  switch (info.type) {
    case "data":
      const validationErrors = (info as any).validation_errors;
      if (defined(validationErrors)) {
        return (
          "\nvalidation error(s): " +
          Object.entries(validationErrors)
            .map(
              ([key, valueObj]) => key + ": " + JSON.stringify(valueObj as any)
            )
            .join("\n")
        );
      }
      return "unknown error";
    case "errorCode":
      return [info.error, info.message].filter(defined).join(" / ");
    case "errorMessage":
      return info.message;
    default:
      assertNever(info);
  }
}
