import { defined } from "./defined";

interface Entry<T> {
  storedTime: number;
  item: T;
}
export class SimpleCache<T> {
  private _dict: { [key: string]: Entry<T> };
  private _keys: string[];

  constructor(private _maxItems: number, private _maxAgeMs?: number) {
    this._dict = {};
    this._keys = [];
  }

  private _outdated(entry: Entry<T>): boolean {
    if (this._maxAgeMs === undefined) {
      return false;
    }

    return (
      this._maxAgeMs !== undefined &&
      Date.now() - entry.storedTime > this._maxAgeMs
    );
  }

  private get _numKeys(): number {
    return this._keys.length;
  }

  private _pruneOldSingle(): void {
    // If using maxAgeMs, we check items by age
    if (defined(this._maxAgeMs)) {
      for (let i = 0; i < this._keys.length; i++) {
        const key = this._keys[i];
        const entry = this._dict[key];
        if (entry === undefined) {
          throw new Error(
            "Invalid invariant: key in keys must be in dict as well!"
          );
        }
        if (this._outdated(entry)) {
          delete this._dict[key];
          this._keys.splice(i, 1);
          return;
        }
      }

      return;
    }

    const key = this._keys.shift();
    if (key === undefined) {
      throw new Error(
        "Invalid invariant: _pruneOld must not be called when cache is empty!"
      );
    }
    delete this._dict[key];
  }

  clear(): void {
    for (const key of Object.keys(this._dict)) {
      delete this._dict[key];
    }
    this._keys = [];
  }

  remove(key: string): void {
    delete this._dict[key];
    const index = this._keys.indexOf(key);
    if (index === -1) {
      throw new Error("Invalid invariant: key must be in keys!");
    }
    this._keys.splice(index, 1);
  }

  set(key: string, value: T): void {
    if (this._numKeys >= this._maxItems) {
      this._pruneOldSingle();
    }
    this._dict[key] = { storedTime: Date.now(), item: value };
    this._keys.push(key);
  }

  get(key: string): T | undefined {
    const entry = this._dict[key];
    if (entry === undefined) {
      return undefined;
    }
    if (this._outdated(entry)) {
      this.remove(key);
      return undefined;
    }
    return entry.item;
  }
}
