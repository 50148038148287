import { useCallback, useMemo } from "react";
import { sortBy } from "lodash";

import { MeasureSelectionSetterRegular } from "../../../../../../lib/application/requests/common_requests";
import { useSetBreakdownsMulti } from "../../../../../../lib/application/state/actions/selections/shared/useSetBreakdowns";
import { defined } from "../../../../../../lib/core/defined";
import { MeasureSelectionRegular } from "../../../../../../lib/domain/selections/definitions";
import { MeasureDropdown } from "./MeasureDropdown";

import { BreakdownSelectMultiV2, BreakdownSelectSingleV2 } from "./shared";
import { DimensionV2Dto } from "../../../../../../lib/domain/measure/definitions";
import { makeBreakdownsSoundMut } from "../../../../../../lib/domain/measure/breakdowns";
import { logger } from "../../../../../../lib/infra/logging";
import { sortDimensionSpecsByParentChild } from "../../../../../../lib/application/stats/shared/dimensions";

type MeasureSelectionProps = {
  isGroupingSelection: boolean;
  measureSelection: MeasureSelectionRegular;
  selectSingle?: boolean;
  setMeasureSelection: MeasureSelectionSetterRegular;
  hideMeasure?: boolean;
};

/** For each dimension, if that dimension has duplicates, contains a count of the number of occurences of a given label.
 * If no duplicates, the dimension will have no entries at all.
 */
interface DimensionValueCounts {
  [key: string]:
    | {
        [key: string]: number;
      }
    | undefined;
}

export function MeasureSelectionComponentRegular(props: MeasureSelectionProps) {
  const { measureSelection, setMeasureSelection } = props;

  const setBreakdownsMulti = useSetBreakdownsMulti(
    measureSelection,
    setMeasureSelection
  );

  const dimensions = useMemo(() => {
    const rawDims = measureSelection.measure.dimensions;
    return sortDimensionSpecsByParentChild(
      sortBy(rawDims, (d) => d.sort_order)
    );
  }, [measureSelection.measure.dimensions]);
  const dimValueCounts = useMemo(() => {
    const counts: DimensionValueCounts = {};
    for (const d of dimensions) {
      const currentCounts: { [key: string]: number } = {};
      let hasDuplicates = false;
      for (const v of d.values ?? []) {
        const nextCount = (currentCounts[v.label] ?? 0) + 1;
        currentCounts[v.label] = nextCount;
        if (nextCount > 1) {
          hasDuplicates = true;
        }
      }

      if (hasDuplicates) {
        counts[d.data_column] = currentCounts;
      }
    }
    return counts;
  }, [dimensions]);

  const setBreakdownsSingle = useCallback(
    (dataColumn: string, valueId?: number) => {
      const breakdowns = measureSelection.breakdowns;
      const dims = measureSelection.measure.dimensions;
      const updatedDim = dims.find((d) => d.data_column === dataColumn);
      if (!defined(updatedDim)) {
        logger.error(
          "Dimension not found when updating breakdowns: " + dataColumn
        );
        return;
      }
      const updatedBreakdownSelection = !defined(valueId) ? [] : [valueId];
      const updatedBreakdowns = makeBreakdownsSoundMut(
        {
          ...breakdowns,
          [dataColumn]: updatedBreakdownSelection,
        },
        updatedDim,
        dims
      );

      const updatedSelection = {
        ...measureSelection,
        breakdowns: updatedBreakdowns,
      };
      setMeasureSelection(updatedSelection);
    },
    [measureSelection, setMeasureSelection]
  );

  const clearBreakdownSelection = useCallback(
    (dataColumn: string) => {
      const dims = measureSelection.measure.dimensions;
      const updatedDim = dims.find((d) => d.data_column === dataColumn);
      if (!defined(updatedDim)) {
        logger.error(
          "Dimension not found when updating breakdowns: " + dataColumn
        );
        return;
      }
      const updatedBreakdowns = makeBreakdownsSoundMut(
        {
          ...measureSelection.breakdowns,
          [dataColumn]: [],
        },
        updatedDim,
        dims
      );
      setMeasureSelection({
        ...measureSelection,
        breakdowns: updatedBreakdowns,
      });
    },
    [measureSelection, setMeasureSelection]
  );

  return (
    <>
      {!props.hideMeasure && (
        <>
          <MeasureDropdown
            setMeasureSelection={(m) => {
              if (m.valueType === "survey" || m.valueType === "survey_string") {
                // this component handles only regular stats measures
                return;
              }
              props.setMeasureSelection(m);
            }}
            measureSelection={measureSelection}
          ></MeasureDropdown>
          <div className="clear-row"></div>
        </>
      )}
      <div className="breakdowns-container">
        {dimensions
          .filter((d) => defined(d.values) && d.values.length > 1)
          .map((d) => (
            <Dimension
              selectSingle={props.selectSingle}
              key={d.dimension_id}
              dimensionValueCounts={dimValueCounts}
              isGroupingSelection={props.isGroupingSelection}
              current={d}
              dimensions={dimensions}
              measureSelection={measureSelection}
              setBreakdownsMulti={setBreakdownsMulti}
              setBreakdownsSingle={setBreakdownsSingle}
              clearBreakdownSelection={clearBreakdownSelection}
            />
          ))}
      </div>
    </>
  );
}

function Dimension(props: {
  isGroupingSelection: boolean;
  current: DimensionV2Dto;
  dimensionValueCounts: DimensionValueCounts;
  clearBreakdownSelection: (dataColumn: string) => void;
  measureSelection: MeasureSelectionRegular;
  dimensions: DimensionV2Dto[];
  selectSingle?: boolean;
  setBreakdownsSingle: (breakdownDimension: string, valueId?: number) => void;
  setBreakdownsMulti: (
    breakdownDimension: string,
    valueIds: number[],
    selected: boolean
  ) => void;
}) {
  const {
    clearBreakdownSelection,
    current,
    dimensions,
    measureSelection,
    setBreakdownsMulti,
    setBreakdownsSingle,
  } = props;
  const d = current;
  const parentDimension = useMemo(() => {
    return defined(d.parent_id)
      ? dimensions.find((p) => p.dimension_id === d.parent_id)
      : undefined;
  }, [d, dimensions]);
  const parentDimensionSelectedValues = useMemo(() => {
    if (!defined(parentDimension)) {
      return;
    }
    return (
      parentDimension.values?.filter((v) =>
        measureSelection.breakdowns[parentDimension?.data_column]?.includes(
          v.id
        )
      ) ?? []
    );
  }, [parentDimension, measureSelection.breakdowns]);

  const lookupPath = useCallback(
    (parentValueId: number) => {
      if (!defined(parentDimension)) {
        return;
      }

      function findParentDimension(dimensionId: number) {
        return dimensions.find((d) => d.dimension_id === dimensionId);
      }

      function findParentValue(dimension: DimensionV2Dto, valueId: number) {
        return dimension.values?.find((v) => v.id === valueId);
      }

      function findRootDimension(
        dimension: DimensionV2Dto
      ): DimensionV2Dto | undefined {
        let current = dimension;
        while (defined(current.parent_id)) {
          const parent = findParentDimension(current.parent_id);
          if (!defined(parent)) {
            break;
          }
          current = parent;
        }
        return current;
      }

      const path: [label: string, hasDuplicate: boolean][] = [];
      let parentDim = dimensions.find(
        (d) => d.dimension_id === current.parent_id
      );
      if (!defined(parentDim)) {
        logger.warn("Parent dimension not found");
        return;
      }
      const rootDim = findRootDimension(parentDim);
      if (!defined(rootDim)) {
        logger.warn("Root dimension not found");
        return;
      }

      let currentParentValueId: number | undefined = parentValueId;
      while (defined(parentDim) && defined(currentParentValueId)) {
        const parentValue = findParentValue(parentDim, currentParentValueId);
        if (!defined(parentValue)) {
          break;
        }
        path.push([
          parentValue.label,
          (props.dimensionValueCounts[parentDim.data_column]?.[
            parentValue.label
          ] ?? 0) > 1 ?? false,
        ]);
        if (!defined(parentDim.parent_id)) {
          break;
        }
        parentDim = findParentDimension(parentDim.parent_id);
        currentParentValueId = parentValue.parent_id ?? undefined;
      }

      return path.reverse().find((p) => !p[1])?.[0];
    },
    [current.parent_id, dimensions, parentDimension, props.dimensionValueCounts]
  );

  const parentDimensionSelectedValueIds = useMemo(() => {
    return parentDimensionSelectedValues?.map((v) => v.id);
  }, [parentDimensionSelectedValues]);

  if (props.isGroupingSelection || props.selectSingle) {
    return (
      <BreakdownSelectSingleV2
        key={d.dimension_id}
        dimension={d}
        parentDimensionSelectedValueId={
          defined(parentDimension)
            ? parentDimensionSelectedValues?.[0]?.id ?? null
            : undefined
        }
        selectedValueId={measureSelection.breakdowns[d.data_column]?.[0]}
        setSelectedKey={setBreakdownsSingle}
      ></BreakdownSelectSingleV2>
    );
  }
  return (
    <BreakdownSelectMultiV2
      className="measure-regular-breakdown-multi"
      key={d.label}
      lookupPath={lookupPath}
      noneSelectedError="Välj minst ett alternativ"
      dimension={d}
      parentDimensionSelectedValueIds={parentDimensionSelectedValueIds}
      selectedBreakdowns={measureSelection.breakdowns}
      clearBreakdownValues={clearBreakdownSelection}
      setBreakdowns={setBreakdownsMulti}
    ></BreakdownSelectMultiV2>
  );
}
