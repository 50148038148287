import { useCallback, useContext, useMemo } from "react";
import { useRecoilState } from "recoil";

import { defined } from "../../core/defined";
import { cardQuery } from "../state/stats/document-core/queries/card";
import { cardWithUpdatedDataState } from "../state/stats/cardToDataStateStats";
import {
  CardUpdateCountContext,
  GeographiesContext,
  ShowDraftDataContext,
} from "../contexts";
import { ColorSchemeContainer } from "../state/stats/document-style/definitions";
import { loadAndStoreDataMicro } from "../state/stats/cardToDataStateMicro";
import { fetchGeoTreeWithCache } from "../../../views/stats/docs/cards/micro/useGeoTree";
import { cardColors } from "../state/stats/document-core/queries/shared";
import { useSaveCard } from "../state/actions/useSaveDocument";
import { assertNever } from "../../core/assert";
import { useExtendedAppearanceSettings } from "../state/actions/useExtendedAppearanceSettings";
import { CustomThemeSpecApplied } from "../stats/shared/core/colors/colorSchemes";
import { DocCardMicro, DocCardStats } from "../state/stats/document-core/core";
import { CustomDataOutputSettings } from "../../infra/api_responses/account";
import { cloneDeep } from "lodash";
import { dataOutputSettingsDefault } from "../state/stats/document-core/create";
import { DataOutputSettings } from "../state/stats/document-core/DataOutputSettings";

export function useColorScheme(cardId: string) {
  const [card, setCard] = useRecoilState(cardQuery(cardId));
  if (card.type === "textCardSimple" || card.type === "textCardCK") {
    throw new Error("Cannot use color scheme on text card");
  }

  const geographies = useContext(GeographiesContext);
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const handleSaveCard = useSaveCard();
  const appearanceSettings = useExtendedAppearanceSettings();

  const setColorScheme = useCallback(
    async (
      colorScheme: ColorSchemeContainer,
      theme?: CustomThemeSpecApplied
    ) => {
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      switch (card.type) {
        case "microCard": {
          const geoTree = await fetchGeoTreeWithCache();
          if (shouldAbort()) {
            return;
          }
          const updatedCard: DocCardMicro = defined(theme)
            ? {
                ...card,
                data: {
                  ...card.data,
                  settings: {
                    ...card.data.settings,
                    dataOutputSettings: applyCustomOutputSettingsOrDefaults(
                      card.data.settings.dataOutputSettings,
                      theme.customOutputSettings
                    ),
                  },
                },
              }
            : card;

          loadAndStoreDataMicro(
            updatedCard,
            setCard,
            shouldAbort,
            geoTree,
            adminShowDraftData,
            appearanceSettings,
            colorScheme,
            handleSaveCard
          );
          break;
        }
        case "dataCard": {
          if (shouldAbort() || !defined(geographies)) {
            return;
          }
          const cardWithUpdatedSettings: DocCardStats = defined(theme)
            ? {
                ...card,
                data: {
                  ...card.data,
                  settings: applyCustomOutputSettingsOrDefaults(
                    card.data.settings,
                    theme.customOutputSettings
                  ),
                },
              }
            : card;

          const updatedCard = await cardWithUpdatedDataState(
            shouldAbort,
            cardWithUpdatedSettings,
            geographies,
            adminShowDraftData,
            appearanceSettings,
            colorScheme
          );
          if (!defined(updatedCard)) {
            return;
          }
          setCard(updatedCard);
          handleSaveCard?.(updatedCard);
        }
      }
    },
    [
      adminShowDraftData,
      card,
      appearanceSettings,
      geographies,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      setCard,
    ]
  );

  const colorScheme = useMemo(() => {
    switch (card.type) {
      case "error":
      case "microCardImage":
      case "pythonCard":
        return;
      case "dataCard":
      case "microCard":
        return cardColors(card);
      default:
        assertNever(card);
    }
  }, [card]);

  return [colorScheme, setColorScheme] as const;
}

function applyCustomOutputSettingsOrDefaults(
  currentSettings: DataOutputSettings,
  customSettings: CustomDataOutputSettings = {}
): DataOutputSettings {
  const settingsCopy = cloneDeep(currentSettings);
  const {
    chartLabelsSize,
    chartValueLabelsSize,
    customMainHeaderSize,
    customSubHeaderLargeSize,
    customSubHeaderSmallSize,
    customUnitSize,
    fixedNumDecimals,
    hideChartTitleSection,
    forceLabelsOutsideBars,
    showFatLines,
    showLabels,
    showLineCircles,
    showSurveyValueFraction,
    customSourceTextSize,
    hideLegendDimensionLabels,
    gridLinesXStyle,
    gridLinesYStyle,
    showTicksXAxis,
    showTicksYAxis,
    showYAxisLabels,
    showXAxis,
    showYAxis,
  } = customSettings;

  const defaultSettings = dataOutputSettingsDefault();

  settingsCopy.gridLinesXStyle = defined(gridLinesXStyle)
    ? gridLinesXStyle
    : defaultSettings.gridLinesXStyle;
  settingsCopy.gridLinesYStyle = defined(gridLinesYStyle)
    ? gridLinesYStyle
    : defaultSettings.gridLinesYStyle;

  settingsCopy.forceLabelsOutsideBars = defined(forceLabelsOutsideBars)
    ? forceLabelsOutsideBars
    : defaultSettings.forceLabelsOutsideBars;
  settingsCopy.chart.labelSize =
    chartLabelsSize ?? defaultSettings.chart.labelSize;
  settingsCopy.chart.valueLabelSize =
    chartValueLabelsSize ?? defaultSettings.chart.valueLabelSize;
  settingsCopy.customMainHeaderSize = defined(customMainHeaderSize)
    ? customMainHeaderSize
    : defaultSettings.customMainHeaderSize;
  settingsCopy.customSubHeaderLargeSize = defined(customSubHeaderLargeSize)
    ? customSubHeaderLargeSize
    : defaultSettings.customSubHeaderLargeSize;
  settingsCopy.customSubHeaderSmallSize = defined(customSubHeaderSmallSize)
    ? customSubHeaderSmallSize
    : defaultSettings.customSubHeaderSmallSize;
  settingsCopy.customUnitSize = defined(customUnitSize)
    ? customUnitSize
    : defaultSettings.customUnitSize;
  settingsCopy.fixedNumDecimals = defined(fixedNumDecimals)
    ? fixedNumDecimals
    : defaultSettings.fixedNumDecimals;
  settingsCopy.customSourceTextSize = defined(customSourceTextSize)
    ? customSourceTextSize
    : defaultSettings.customSourceTextSize;
  settingsCopy.hideChartTitleSection = defined(hideChartTitleSection)
    ? hideChartTitleSection
    : defaultSettings.hideChartTitleSection;
  settingsCopy.showFatLines = defined(showFatLines)
    ? showFatLines
    : defaultSettings.showFatLines;
  settingsCopy.showLabels = defined(showLabels)
    ? showLabels
    : defaultSettings.showLabels;
  settingsCopy.showLineCircles = defined(showLineCircles)
    ? showLineCircles
    : defaultSettings.showLineCircles;
  settingsCopy.showSurveyValueFraction = defined(showSurveyValueFraction)
    ? showSurveyValueFraction
    : defaultSettings.showSurveyValueFraction;
  settingsCopy.hideLegendDimensionLabels = defined(hideLegendDimensionLabels)
    ? hideLegendDimensionLabels
    : defaultSettings.hideLegendDimensionLabels;
  settingsCopy.showTicksXAxis = defined(showTicksXAxis)
    ? showTicksXAxis
    : defaultSettings.showTicksXAxis;
  settingsCopy.showTicksYAxis = defined(showTicksYAxis)
    ? showTicksYAxis
    : defaultSettings.showTicksYAxis;
  settingsCopy.showXAxis = defined(showXAxis)
    ? showXAxis
    : defaultSettings.showXAxis;
  settingsCopy.showYAxis = defined(showYAxis)
    ? showYAxis
    : defaultSettings.showYAxis;
  settingsCopy.showYAxisLabels = defined(showYAxisLabels)
    ? showYAxisLabels
    : defaultSettings.showYAxisLabels;

  return settingsCopy;
}
