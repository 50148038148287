import { RecoilRoot, useRecoilValue } from "recoil";
import { cardQuery } from "../../../../lib/application/state/stats/document-core/queries/card";
import { DataOutput } from "./data_card/DataOutput";
import { useContext, useEffect } from "react";
import {
  GeographiesContext,
  MicroGeoTreeContext,
  SaveDocumentContext,
} from "../../../../lib/application/contexts";
import { defined } from "../../../../lib/core/defined";
import { MicroOutputContainer } from "./micro/MicroCard";
import { getSharingInfoNonDocument } from "../../../../lib/application/files/SharingInfo";
import { voidFunc } from "../../../../lib/core/voidFunc";
import {
  useAddMicroCardCallback,
  useAddStatsCardCallback,
  useGetAllCardsCallback,
} from "../../../../lib/application/state/actions/cardCallbacks";
import {
  DocCardMicro,
  DocCardState,
  DocCardStats,
} from "../../../../lib/application/state/stats/document-core/core";
import { GeographiesSerializable } from "../../../../lib/domain/geography";
import { docCardsListQuery } from "../../../../lib/application/state/stats/document-core/docCardsListState";
import { logger } from "../../../../lib/infra/logging";
import { useExtendedAppearanceSettings } from "../../../../lib/application/state/actions/useExtendedAppearanceSettings";
import { createColorSchemeContainerWithPalette } from "../../../../lib/application/state/stats/document-style/operations";
import { useGeoTree } from "./micro/useGeoTree";
import { useApplyChangeStats } from "../../../../lib/application/state/stats/useApplyChangeStats";

export function OutputPreview(props: { cardId: string }) {
  const card = useRecoilValue(cardQuery(props.cardId));
  return (
    <SaveDocumentContext.Provider value={undefined}>
      <OutputPreviewInner card={card} />
    </SaveDocumentContext.Provider>
  );
}

export function OutputPreviewInner(props: {
  card: DocCardState;
  applyChanges?: boolean;
}) {
  const card = props.card;
  const geographies = useContext(GeographiesContext);
  if (card.type === "dataCard") {
    if (!defined(geographies)) {
      return null;
    }
    return (
      <div style={{ maxWidth: "100%", overflow: "auto" }}>
        <div style={{ minWidth: 400 }}>
          <RecoilRoot>
            <SaveDocumentContext.Provider value={undefined}>
              <OutputPreviewInnerStats
                card={card}
                geographies={geographies}
                applyChanges={props.applyChanges}
              />
            </SaveDocumentContext.Provider>
          </RecoilRoot>
        </div>
      </div>
    );
  } else if (card.type === "microCard") {
    return (
      <div style={{ maxWidth: "100%", overflow: "auto" }}>
        <div style={{ minWidth: 400 }}>
          <RecoilRoot>
            <SaveDocumentContext.Provider value={undefined}>
              <OutputPreviewInnerMicro card={card} />
            </SaveDocumentContext.Provider>
          </RecoilRoot>
        </div>
      </div>
    );
  }

  return null;
}

function OutputPreviewInnerMicro(props: { card: DocCardMicro }) {
  const addMicroCard = useAddMicroCardCallback();
  const cardsList = useRecoilValue(docCardsListQuery);
  const appearanceSettings = useExtendedAppearanceSettings();

  const getCards = useGetAllCardsCallback(cardsList);
  const geoTree = useGeoTree();

  useEffect(() => {
    addMicroCard(
      props.card,
      0,
      createColorSchemeContainerWithPalette(appearanceSettings.defaultTheme)
    );
  }, [addMicroCard, appearanceSettings.defaultTheme, props.card]);

  const card = getCards()?.[0];
  if (!defined(card)) {
    return null;
  }
  if (!defined(geoTree)) {
    return null;
  }
  if (card.type !== "microCard") {
    logger.error("Expected micro card");
    return null;
  }

  return (
    <MicroGeoTreeContext.Provider value={geoTree}>
      <MicroOutputContainer
        isEditingCard={false}
        microOutputDisabled={false}
        sharingInfo={getSharingInfoNonDocument()}
        card={card}
        setCard={voidFunc}
      ></MicroOutputContainer>
    </MicroGeoTreeContext.Provider>
  );
}

function OutputPreviewInnerStats(props: {
  card: DocCardStats;
  geographies: GeographiesSerializable;
  applyChanges?: boolean;
}) {
  const addCard = useAddStatsCardCallback();
  const cardsList = useRecoilValue(docCardsListQuery);
  const appearanceSettings = useExtendedAppearanceSettings();

  const handleApplyChange = useApplyChangeStats(props.card.id);
  useEffect(() => {
    if (props.applyChanges) {
      handleApplyChange(props.card);
    }
  }, [handleApplyChange, props.applyChanges, props.card]);

  useEffect(() => {
    addCard(
      props.card,
      0,
      createColorSchemeContainerWithPalette(appearanceSettings.defaultTheme)
    );
  }, [addCard, appearanceSettings.defaultTheme, props.card]);

  const cardId = cardsList[0]?.id;
  if (!defined(cardId)) {
    return null;
  }

  return (
    <div className="data-card-content">
      <DataOutput
        cardStateId={cardId}
        geographies={props.geographies}
        isEditing={false}
      />
    </div>
  );
}
