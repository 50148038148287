import { useContext, useEffect } from "react";
import { AppMessagesContext } from "../../lib/application/contexts";
import { Milliseconds } from "../../lib/core/time";
import { HttpError } from "../../lib/infra/HttpResult";
import { AlertBox, AlertIntent } from "../AlertBox";
import { nonEmptyString } from "../../lib/core/nonEmptyString";
import { defined } from "../../lib/core/defined";

const httpErrors: { [K in HttpError["code"]]: string } = {
  "fetch-error": "Kunde inte få kontakt med servern.",
  unauthorized: "Du verkar inte ha behörighet för att visa det här.",
  "internal-server-error":
    "Okänt fel. Försök igen eller kontakta support om problemet kvarstår.",
  "unknown-error":
    "Okänt fel. Försök igen eller kontakta support om problemet kvarstår.",
  "bad-client-input":
    "Ett oväntat fel i kommunikation med servern uppstod. Försök igen senare eller kontakta support om problemet kvarstår.",
  "not-found":
    "Det du efterfrågade kunde inte hittas. Försök igen eller kontakta support om problemet kvarstår.",
  "4xx": "Okänt fel. Försök igen eller kontakta support om problemet kvarstår.",
  conflict:
    "Resursen finns redan eller har ändrats. Försök igen senare eller kontakta support om problemet kvarstår.",
};

export function displayHttpError(error: HttpError): string {
  const info = error.info;
  if (defined(info)) {
    if (info.type === "errorMessage") {
      return info.message;
    } else if (info.type === "errorCode") {
      return ((httpErrors as any)[info.error as any] as string) ?? info.error;
    }
  }

  const message = httpErrors[error.code];
  if (nonEmptyString(message)) {
    return message;
  }
  return httpErrors["unknown-error"];
}

/**
 * Shows a small error notice as part of a page
 */
export function HttpErrorViewEitherSmallNotice(props: { left: HttpError }) {
  return <HttpErrorNotice error={props.left}></HttpErrorNotice>;
}

export function HttpErrorViewEitherNotification(props: { left: HttpError }) {
  const appMessages = useContext(AppMessagesContext);
  const errorCode = props.left.code;
  useEffect(() => {
    appMessages?.add("warning", httpErrors[errorCode], {
      durationMs: Milliseconds.second * 20,
    });
  }, [appMessages, errorCode]);

  return null;
}

interface Props {
  error: HttpError;
}
/**
 * A small error notice that centers on the page and shows:
 * - title of the error
 * - short explanation
 */
export function HttpErrorNotice(props: Props): JSX.Element {
  const error = props.error;
  switch (error.code) {
    case "unauthorized":
      return <Unauthorized></Unauthorized>;
    case "not-found":
      return <NotFound></NotFound>;
    case "bad-client-input":
      return <BadClientInput></BadClientInput>;
    case "4xx":
    case "internal-server-error":
    case "unknown-error":
      return <UnexpectedError></UnexpectedError>;
    case "fetch-error":
      return <FetchError></FetchError>;
    case "conflict":
      return <Conflict></Conflict>;
  }
}

function Conflict() {
  return (
    <SimpleNotice
      title="Konflikt"
      message={httpErrors.conflict}
      intent="warning"
    />
  );
}

function Unauthorized() {
  return (
    <SimpleNotice
      title="Behörighet saknas"
      message={httpErrors.unauthorized}
      intent="warning"
    />
  );
}

function NotFound() {
  return (
    <SimpleNotice
      title="Kunde inte hitta det du efterfrågade"
      message={httpErrors["not-found"]}
      intent="warning"
    />
  );
}
function BadClientInput() {
  return (
    <SimpleNotice
      title="Oväntat fel"
      message={httpErrors["unknown-error"]}
      intent="warning"
    />
  );
}

function UnexpectedError() {
  return (
    <SimpleNotice
      title="Oväntat fel"
      message={httpErrors["unknown-error"]}
      intent="warning"
    />
  );
}

function FetchError() {
  return (
    <SimpleNotice
      title="Ett oväntat fel uppstod"
      message={httpErrors["fetch-error"]}
      intent="warning"
    />
  );
}

export function SimpleNotice(props: {
  title: string;
  message: string;
  intent: AlertIntent;
}) {
  return (
    <AlertBox intent={props.intent}>
      <h3>{props.title}</h3>
      <p>{props.message}</p>
    </AlertBox>
  );
}
