import { flatMap, sample, uniq } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { allEqual } from "../../../../core/allEqual";
import { defined } from "../../../../core/defined";
import { standardColors } from "../../../stats/shared/core/colors/colors";
import {
  colorPalettes,
  CustomPaletteSpecApplied,
  CustomThemeSpecApplied,
  getSpecialColorScheme,
} from "../../../stats/shared/core/colors/colorSchemes";
import { ColorScheme, useSingleColorSymbol } from "../document-core/core";
import {
  ColorSchemeContainer,
  GroupStyleGeoMicro,
  StyleContainerGeoMicro,
} from "./definitions";

export function createColorSchemeContainerWithPalette(
  customPalette?: CustomPaletteSpecApplied | CustomThemeSpecApplied
): ColorSchemeContainer {
  const copy = defined(customPalette) ? { ...customPalette } : undefined;
  if (defined(copy) && defined(copy.customOutputSettings)) {
    delete copy.customOutputSettings;
  }
  const container: ColorSchemeContainer = {
    colorScheme: {},
    paletteName: "standard",
    embeddedPalette: customPalette,
    id: uuidv4(),
  };
  if (defined(customPalette)) {
    container.useDefaultSpecialColors = customPalette.useDefaultSpecialColors;
    container.customAxesColor = customPalette.customAxesColor;
    container.customBgColor = customPalette.customBgColor;
    container.customGridLinesColor = customPalette.customGridLinesColor;
    container.customGridLinesXColor = customPalette.customGridLinesXColor;
    container.customGridLinesYColor = customPalette.customGridLinesYColor;
    container.customHeadersColor = customPalette.customHeadersColor;
    container.customLabelsColor = customPalette.customLabelsColor;
  }
  return container;
}

export function defaultStyleContainerGeoMicro(): StyleContainerGeoMicro {
  return { styles: [], id: uuidv4() };
}

export function makeSingleColorScheme(
  labels: string[],
  color: string
): ColorScheme {
  const colorScheme: ColorScheme = {
    [useSingleColorSymbol]: true,
  };
  for (const label of labels) {
    colorScheme[label] = color;
  }
  return colorScheme;
}

export function copyStyleContainerGeoMicro(
  container: StyleContainerGeoMicro
): StyleContainerGeoMicro {
  return { ...container, id: uuidv4() };
}

export function copyColorSchemeContainer(
  scheme: ColorSchemeContainer
): ColorSchemeContainer {
  return { ...scheme, colorScheme: { ...scheme.colorScheme }, id: uuidv4() };
}

/** If the color scheme is single-color, get that color */
export function getSingleColor(
  scheme: ColorSchemeContainer
): string | undefined {
  return scheme.colorScheme[useSingleColorSymbol]
    ? Object.values(scheme.colorScheme)[0]
    : undefined;
}

/**
 * Fill in colors for all labels that do not already have a color
 *
 * A single color is used for the color scheme if all of the following are true:
 * - defaultToSingleColor is true
 * - colorDimension is NOT a special color dimension (e.g. "kön")
 * - the current color scheme does not already have colors assigned
 *
 */
export function makeFullColorScheme(
  schemeContainer: ColorSchemeContainer,
  labelsToColorize: string[],
  defaultToSingleColor: boolean,
  colorDimension?: string,
  lastUsedSingleColor?: string
): ColorScheme {
  // For backwards compatibility, default to standard palette if there is
  // no embedded palette
  const palette =
    schemeContainer.embeddedPalette?.colors ??
    (schemeContainer.paletteName === "blackWhite"
      ? colorPalettes.blackWhite
      : colorPalettes.standard);
  const currentScheme = schemeContainer.colorScheme;
  const currentColors = uniq(Object.values(currentScheme));

  // Never use special colors if useDefaultSpecialColors is explicitly false
  const specialColors =
    defined(colorDimension) && schemeContainer.useDefaultSpecialColors !== false
      ? getSpecialColorScheme(colorDimension)
      : undefined;

  // All labels to colorize already have unique colors
  // (and we're not defaulting to single color)
  if (
    !defaultToSingleColor &&
    currentColors.length === labelsToColorize.length &&
    labelsToColorize.every((label) => defined(currentScheme[label]))
  ) {
    return currentScheme;
  }

  // Single color, auto
  if (
    defaultToSingleColor &&
    !defined(specialColors) &&
    !schemeContainer.isUserDefined
  ) {
    return makeSingleColorScheme(labelsToColorize, palette[0]);
  }

  // Single color, user-defined
  if (
    schemeContainer.isUserDefined &&
    schemeContainer.colorScheme[useSingleColorSymbol]
  ) {
    const activeColor =
      lastUsedSingleColor ?? Object.values(schemeContainer.colorScheme)[0];
    if (defined(activeColor)) {
      return makeSingleColorScheme(labelsToColorize, activeColor);
    }
  }

  // Is currently single-color, but should swap to multi-color
  const isSingleColor = allEqual(currentColors) && currentColors.length > 0;
  if (
    !schemeContainer.isUserDefined &&
    (currentScheme[useSingleColorSymbol] || isSingleColor) &&
    (!defaultToSingleColor || defined(specialColors))
  ) {
    return fillColorsMultiPalette(
      currentScheme,
      palette,
      palette,
      labelsToColorize,
      specialColors
    );
  }

  // Multiple colors
  const unusedColors = palette.filter(
    (color) => !currentColors.includes(color)
  );

  return fillColorsMultiPalette(
    currentScheme,
    palette,
    unusedColors,
    labelsToColorize.filter((label) => !defined(currentScheme[label])),
    specialColors
  );
}

export function colorSchemesEqual(
  left: ColorScheme,
  right: ColorScheme
): boolean {
  const entriesLeft = Object.entries(left);
  const entriesRight = Object.entries(right);
  return (
    entriesLeft.length === entriesRight.length &&
    entriesLeft.every(([key, value]) => value === right[key])
  );
}

export function fillColorsMultiPalette(
  currentScheme: ColorScheme,
  palette: readonly string[],
  unusedColorsReadonly: readonly string[],
  labels: readonly string[],
  specialColors: ColorScheme | undefined
) {
  const unusedColors = unusedColorsReadonly.slice();
  const resultingScheme: ColorScheme = {
    ...currentScheme,
    [useSingleColorSymbol]: false,
  };
  let paletteCounter = 0;
  for (const label of labels) {
    if (defined(specialColors) && defined(specialColors[label])) {
      resultingScheme[label] = specialColors[label];
      continue;
    }
    const firstUnusedColor = unusedColors.shift();
    if (defined(firstUnusedColor)) {
      resultingScheme[label] = firstUnusedColor;
    } else {
      resultingScheme[label] = palette[paletteCounter];
      paletteCounter = (paletteCounter + 1) % palette.length;
    }
  }

  return resultingScheme;
}

export function getNextFillColorGeoMicroV2(
  styles: GroupStyleGeoMicro[]
): string {
  const usedColors = flatMap(styles, (c) => [c.fill, c.border]).filter(defined);
  const unusedColor = standardColors.find((c) => !usedColors.includes(c));
  if (defined(unusedColor)) {
    return unusedColor;
  }
  return sample(standardColors)!; // standardColors is never empty
}

export function getNextBorderColorGeoMicroV2(
  styles: GroupStyleGeoMicro[]
): string {
  const usedColors = flatMap(styles, (c) => [c.fill, c.border]).filter(defined);
  const unusedColor = standardColors.find((c) => !usedColors.includes(c));
  if (defined(unusedColor)) {
    return unusedColor;
  }
  return sample(standardColors)!; // standardColors is never empty
}
