import { classNames } from "../lib/core/classNames";
import "./ButtonContainers.scss";

/**
 * Use with @ButtonsFooterLeft and @ButtonsFooterRight
 */
export function ButtonsFooter(props: {
  children: JSX.Element | JSX.Element[];
  className?: string;
}) {
  return (
    <div className={classNames("buttons-footer", props.className)}>
      {props.children}
    </div>
  );
}

export function ButtonsFooterLeft(props: { children: JSX.Element }) {
  return <div className="left">{props.children}</div>;
}

export function ButtonsFooterRight(props: { children: JSX.Element }) {
  return <div className="right">{props.children}</div>;
}
