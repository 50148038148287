import { useState } from "react";
import { classNames } from "../lib/core/classNames";

import "./FoldoutPanel.scss";
import { FluentIcon, IconSize } from "./Icons";

export function FoldoutPanel(props: {
  title: string | JSX.Element;
  size?: IconSize;
  children: JSX.Element | JSX.Element[];
  className?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FoldoutPanelControlled
      className={props.className}
      title={props.title}
      size={props.size}
      isOpen={isOpen}
      toggleOpen={() => setIsOpen(!isOpen)}
    >
      {props.children}
    </FoldoutPanelControlled>
  );
}

export function FoldoutPanelControlled(props: {
  isOpen: boolean;
  size?: IconSize;
  className?: string;
  toggleOpen: () => void;
  title: string | JSX.Element;
  children: JSX.Element | JSX.Element[];
}) {
  const { isOpen } = props;

  const size = props.size ?? "md";
  return (
    <div
      className={classNames(
        "foldout-panel",
        size,
        props.className,
        isOpen ? "is-open" : ""
      )}
    >
      <div className={classNames("header", size)} onClick={props.toggleOpen}>
        <span className={classNames("toggle-icon", size)}>
          <FluentIcon
            size={size}
            name={isOpen ? "chevron-down" : "chevron-right"}
          ></FluentIcon>
        </span>
        {props.title}
      </div>
      {isOpen && <div className="content">{props.children}</div>}
    </div>
  );
}
