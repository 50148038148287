import { config } from "../../config";
import { defined } from "../core/defined";
import { logger } from "../infra/logging";

export type AppMessageType = "warning" | "info" | "error" | "success";

let appMessageCounter = 0;

type AppMessageOptions = {
  durationMs?: number;
  action?: { text: string; onClick: () => void };
};

export function makeAppMessage(
  type: AppMessageType,
  text: string,
  options?: AppMessageOptions
): AppMessage {
  const durationMs = options?.durationMs;
  return {
    id: appMessageCounter++,
    type,
    text,
    expiryTime: defined(durationMs)
      ? Date.now() + durationMs
      : Date.now() + config.defaultAppNoticeDurationMs,
    action: options?.action,
  };
}

export interface AppMessage {
  id: number;
  type: AppMessageType;
  text: string;
  expiryTime?: number;
  action?: AppMessageOptions["action"];
}

export class AppMessagesHandler {
  private _add: undefined | ((message: AppMessage) => void);

  init(add: (message: AppMessage) => void) {
    if (defined(this._add)) {
      logger.error("init already called.");
      return;
    }
    this._add = add;
  }

  add(type: AppMessageType, text: string, options?: AppMessageOptions) {
    if (!defined(this._add)) {
      logger.error("add called before init.");
      return;
    }
    this._add(makeAppMessage(type, text, options));
  }
}

export const globalAppMessagesHandler = new AppMessagesHandler();
