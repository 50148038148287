import { config } from "../../../../../config";
import { HttpResult } from "../../../../infra/HttpResult";
import { authedRequest } from "../../shared";

interface AliasDto {
  area: string;
  subarea: string;
  subject: string;
  measure: string;
}

export function createAlias(
  measureId: number,
  body: AliasDto
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `admin/measures/${measureId}/alias`,
    body,
    "POST"
  );
}

export function deleteAlias(
  measureId: number,
  aliasId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `admin/measures/${measureId}/alias/${aliasId}`,
    null,
    "DELETE"
  );
}

export function updateAlias(
  measureId: number,
  aliasId: number,
  body: AliasDto
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `admin/measures/${measureId}/alias/${aliasId}`,
    body,
    "PUT"
  );
}
