import { assertNever } from "./assert";

export type Comparator<T> = (left: T, right: T) => 0 | -1 | 1;
export type StringComparator = Comparator<string>;

export enum SortOrder {
  ascending = "ascending",
  descending = "descending",
}

export function sortOrderEnum(
  sortOrder: "ascending" | "descending"
): SortOrder {
  switch (sortOrder) {
    case "ascending":
      return SortOrder.ascending;
    case "descending":
      return SortOrder.descending;
  }
  assertNever(sortOrder);
}

export function invertComparator<T>(comparator: Comparator<T>): Comparator<T> {
  return (left: T, right: T) => comparator(right, left);
}

export function defaultStringComparatorAsc(
  left: string,
  right: string
): ReturnType<StringComparator> {
  return simpleComparatorAsc(left, right);
}

export function defaultNumberComparatorAsc(
  left: number,
  right: number
): ReturnType<StringComparator> {
  return simpleComparatorAsc(left, right);
}

export function simpleComparatorAsc<T extends number | string>(
  left: T,
  right: T
) {
  if (left < right) {
    return -1;
  } else if (left > right) {
    return 1;
  }
  return 0;
}

export const simpleComparatorDesc = invertComparator(simpleComparatorAsc);

class Reordering {
  constructor(private _indices: number[]) {}

  apply<T>(arr: T[]): T[] {
    if (arr.length !== this._indices.length) {
      throw new Error("Mismatching arr and reordering indices");
    }

    return this._indices.map((index) => arr[index]);
  }
}

export function findReordering<T>(
  arr: T[],
  comparator: Comparator<T>
): Reordering {
  const elements = arr.map((item, index) => ({ originalIndex: index, item }));
  elements.sort((left, right) => comparator(left.item, right.item));

  return new Reordering(elements.map((e) => e.originalIndex));
}
