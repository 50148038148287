import {
  Record,
  Boolean as BooleanRT,
  Number as NumberRT,
  String as StringRT,
  Array as ArrayRT,
  Dictionary,
  Static,
  Optional,
  Union,
  Literal,
  Null,
  Tuple,
} from "runtypes";

import {
  CommonHeaderDataDtoRT,
  DataValueTypeSurveyRT,
  DataValueTypeSurveyStringRT,
  RowsCollection,
} from "./dataset";

const SurveyQuestionTypeRT = Union(
  Literal("singlechoice"),
  Literal("multichoice")
);
const SurveyHeaderCommonRT = Record({
  subject: StringRT,
  measure: StringRT,
  unit_label: StringRT,
  descr_long: StringRT,
  source: StringRT,
  dimensions: ArrayRT(StringRT),
  dubious_base_dimensions: Optional(ArrayRT(StringRT)),
  base_counts_totals: Optional(Dictionary(NumberRT, StringRT)),
  base_counts_overview: Optional(Dictionary(NumberRT, StringRT)),
  base_counts_detailed: Optional(Dictionary(NumberRT, StringRT)),
});

const SurveyHeaderRT = SurveyHeaderCommonRT.And(
  Record({
    lifted: Optional(Dictionary(StringRT, StringRT)),
    value_type: DataValueTypeSurveyRT,
    ext_source: Optional(StringRT),
    descr_short: Optional(StringRT),

    ext_descr: Optional(StringRT),
    ext_descr_long: Optional(StringRT),

    survey_info: Record({
      question: StringRT,
      filter_info: Optional(StringRT),
      weight_info: Optional(StringRT),
      method_info_public: Optional(StringRT),
      target_group_info_public: Optional(StringRT),
      interview_period: Tuple(StringRT, StringRT),
    }),
  })
);

const SurveyStringHeaderRT = SurveyHeaderCommonRT.And(
  Record({
    lifted: Optional(Dictionary(StringRT.Or(NumberRT), StringRT)),
    value_type: DataValueTypeSurveyStringRT,
    ext_source: Optional(StringRT),
    descr_short: Optional(StringRT),

    ext_descr: Optional(StringRT),
    ext_descr_long: Optional(StringRT),

    survey_info: Record({
      question: StringRT,
      filter_info: Optional(StringRT),
      weight_info: Optional(StringRT),
      method_info_public: Optional(StringRT),
      target_group_info_public: Optional(StringRT),
      interview_period: Tuple(StringRT, StringRT),
    }),
  })
);

const SurveyRowValueRT = StringRT.Or(NumberRT).Or(Null).Or(BooleanRT);

export const SurveyDatasetDtoRT = Record({
  groupHeader: Optional(CommonHeaderDataDtoRT),
  header: SurveyHeaderRT,
  rows: Optional(ArrayRT(Dictionary(SurveyRowValueRT, StringRT))),
  ref_rows: Optional(ArrayRT(Dictionary(SurveyRowValueRT, StringRT))),
});

export const SurveyStringDatasetDtoRT = Record({
  groupHeader: Optional(CommonHeaderDataDtoRT),
  header: SurveyStringHeaderRT,
  rows: Optional(
    ArrayRT(Dictionary(StringRT.Or(NumberRT).Or(BooleanRT), StringRT))
  ),
});

export type SurveyRowValue = Static<typeof SurveyRowValueRT>;
export type SurveyQuestionType = Static<typeof SurveyQuestionTypeRT>;
export type SurveyHeader = Static<typeof SurveyHeaderRT>;

export type SurveyRowValueRaw = string | number | boolean | null;
export type SurveyRowsRaw = RowsCollection<SurveyRowValueRaw>;
export type SurveyDatasetDto = Static<typeof SurveyDatasetDtoRT>;
export type SurveyStringDatasetDto = Static<typeof SurveyStringDatasetDtoRT>;
export type SurveyRowRaw = SurveyRowsRaw[number];
