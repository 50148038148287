import { useContext, useEffect, useState } from "react";
import { useCallback } from "react";

import { NotReadyHttpErrNotice } from "../../../../components/Loading";
import { eitherDisplayer } from "../../../../components/wrappers/either";
import { AppMessagesContext } from "../../../../lib/application/contexts";
import {
  ChildNodeInfo,
  FileNodeInfo,
} from "../../../../lib/application/files/tree";
import { useStateTransition } from "../../../../lib/application/hooks/useStateTransition";

import {
  deleteNode,
  updateNode,
  patchNode,
} from "../../../../lib/application/requests/docs/documents";
import { getText } from "../../../../lib/application/strings";
import { defined } from "../../../../lib/core/defined";
import { Milliseconds } from "../../../../lib/core/time";
import { truncate } from "../../../../lib/core/truncate";
import { logger } from "../../../../lib/infra/logging";
import { openDocument } from "../../../../lib/paths";
import { FileListWithPath } from "./FileList";
import { FileFeatures, NewNodeHandler } from "./shared/features";

interface PropsCommon {
  fileFeatures: FileFeatures;
  /**
   * The name of the root folder for this tree, for display in breadcrumbs
   */
  navigationRootName: string;
  /**
   * If true, items can be selected
   */
  enableSelection: boolean;
  /**
   * Open temporarily  & highlight a node
   */
  openFolderId?: number;
  enableSharingColumn?: boolean;
  handleNew?: NewNodeHandler;
  handleOpenFolder: (nodeId: number | undefined) => Promise<void>;
  loadFiles: () => Promise<void>;
}

export const FileTreeOrLoading = eitherDisplayer(
  NotReadyHttpErrNotice,
  FileTree
);

interface Props extends PropsCommon {
  item: FileNodeInfo;
}

export function FileTree(props: Props) {
  const { item, handleOpenFolder, loadFiles, openFolderId } = props;

  const appMessagesHandler = useContext(AppMessagesContext);
  const [highlightFolder, setHighlightFolder] = useState(false);

  useEffect(() => {
    if (!defined(props.openFolderId)) {
      const timeoutHandle = setTimeout(() => {
        setHighlightFolder(false);
      }, Milliseconds.second * 0.2);
      return () => {
        clearTimeout(timeoutHandle);
      };
    }
  }, [props.openFolderId]);

  const handleUpdateFolderOpen = useCallback(
    (prev?: number, current?: number) => {
      if (defined(current)) {
        handleOpenFolder(current).then(() => {
          setHighlightFolder(true);
        });
      }
    },
    [handleOpenFolder]
  );
  useStateTransition(openFolderId, handleUpdateFolderOpen);

  const handleOpenFile = useCallback((nodeId: number) => {
    openDocument(nodeId);
  }, []);

  const handleMove = useCallback(
    (target: FileNodeInfo, items: ChildNodeInfo[]) => {
      Promise.all(
        items.map((item) =>
          updateNode(item.id, {
            title: item.title,
            parent_folder: target.type === "root" ? null : target.id,
          })
        )
      ).then((res) => {
        const err = res.find((r) => r.switch().type === "err");
        if (defined(err)) {
          logger.error(err);
          appMessagesHandler?.add("error", "Misslyckades med flyttningen", {
            durationMs: Milliseconds.second * 5,
          });
        }

        loadFiles();
      });
    },
    [appMessagesHandler, loadFiles]
  );

  const handleUpdateNodeName = useCallback(
    (nodeId: number, newName: string) => {
      patchNode(nodeId, { title: newName })
        .then((res) => {
          res.match({
            ok: loadFiles,
            err: (err) => {
              logger.error(err);
              appMessagesHandler?.add(
                "error",
                "Kunde inte ändra namn. Försök igen senare."
              );
            },
          });
        })
        .catch(logger.error);
    },
    [appMessagesHandler, loadFiles]
  );

  const handleDelete = useCallback(
    (item: ChildNodeInfo) => {
      const name = truncate(item.title, 30);
      const message =
        item.type === "folder"
          ? `Vill du ta bort mappen "${name}" och alla filer och mappar i den?`
          : `Vill du ta bort filen "${name}"?`;
      if (window.confirm(message)) {
        deleteNode(item.id).then((res) => {
          const result = res.switch();
          if (result.type === "err") {
            const message = getText(
              item.type === "folder"
                ? "failed-to-delete-folder"
                : "failed-to-delete-file"
            );
            appMessagesHandler?.add("error", message, {
              durationMs: Milliseconds.second * 5,
            });
            logger.error("Delete node: " + result.error.code);
          }
          loadFiles();
        });
      }
    },
    [loadFiles, appMessagesHandler]
  );

  return (
    <div className="infostat-filetree">
      <div className="file-tree">
        <FileListWithPath
          handleNew={props.handleNew}
          enableSharingColumn={props.enableSharingColumn}
          highlightFolder={highlightFolder}
          fileFeatures={props.fileFeatures}
          navigationRootName={props.navigationRootName}
          enableSelection={props.enableSelection}
          node={item}
          handleUpdateNodeName={handleUpdateNodeName}
          handleDelete={handleDelete}
          handleOpenFile={handleOpenFile}
          handleOpenFolder={handleOpenFolder}
          handleNavigateToRoot={() => handleOpenFolder(undefined)}
          handleMove={handleMove}
        ></FileListWithPath>
      </div>
    </div>
  );
}
