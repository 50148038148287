import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";
import {
  FluentModalBody,
  FluentModalFooter,
  FluentModalTall,
} from "../../../../../components/Modal";
import { OutputPreview } from "../OutputPreview";

import { Button } from "../../../../../components/Button";
import { AdjustFontSizesSection } from "../../../settings/_shared/themes";
import { DEFAULT_FONT_SIZE_LABELS } from "../../../../../lib/application/stats/shared/core/TextStyle";

import "./AdjustFontSizesDialog.scss";
import { CustomDataOutputSettings } from "../../../../../lib/infra/api_responses/account";
import { DataOutputSettings } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";

function settingsToCustomOutputSettings(
  settings: DataOutputSettings
): CustomDataOutputSettings {
  return {
    customMainHeaderSize: settings.customMainHeaderSize ?? undefined,
    customSubHeaderLargeSize: settings.customSubHeaderLargeSize ?? undefined,
    customSubHeaderSmallSize: settings.customSubHeaderSmallSize ?? undefined,
    customUnitSize: settings.customUnitSize ?? undefined,
    customSourceTextSize: settings.customSourceTextSize ?? undefined,
    chartLabelsSize: settings.chart.labelSize,
    chartValueLabelsSize: settings.chart.valueLabelSize,
    fixedNumDecimals: settings.fixedNumDecimals ?? undefined,
    hideChartTitleSection: settings.hideChartTitleSection ?? undefined,
    showFatLines: settings.showFatLines ?? undefined,
    showLabels: settings.showLabels ?? undefined,
    showLineCircles: settings.showLineCircles ?? undefined,
    showSurveyValueFraction: settings.showSurveyValueFraction ?? undefined,
  };
}

export function AdjustFontSizesDialog(props: {
  cardId: string;
  onClose: () => void;
  settings: DataOutputSettings;
  setSettings: (settings: DataOutputSettings) => void;
}) {
  return (
    <FluentModalTall
      title="Anpassa textstorlekar"
      isOpen
      onClose={props.onClose}
      width="md"
    >
      <FluentModalBody>
        <div id="adjust-font-sizes-dialog">
          <section id="dropdowns">
            <AdjustFontSizesSection
              settings={settingsToCustomOutputSettings(props.settings)}
              setCustomMainHeaderSize={(size) => {
                props.setSettings({
                  ...props.settings,
                  customMainHeaderSize: size ?? null,
                });
              }}
              setCustomSubHeaderLargeSize={(size) => {
                props.setSettings({
                  ...props.settings,
                  customSubHeaderLargeSize: size ?? null,
                });
              }}
              setCustomSubHeaderSmallSize={(size) => {
                props.setSettings({
                  ...props.settings,
                  customSubHeaderSmallSize: size ?? null,
                });
              }}
              setCustomUnitSize={(size) => {
                props.setSettings({
                  ...props.settings,
                  customUnitSize: size ?? null,
                });
              }}
              setCustomLabelSize={(size) => {
                props.setSettings({
                  ...props.settings,
                  chart: {
                    ...props.settings.chart,
                    labelSize: size ?? DEFAULT_FONT_SIZE_LABELS,
                  },
                });
              }}
              setCustomValueLabelSize={(size) => {
                props.setSettings({
                  ...props.settings,
                  chart: {
                    ...props.settings.chart,
                    valueLabelSize: size ?? DEFAULT_FONT_SIZE_LABELS,
                  },
                });
              }}
              setCustomSourceTextSize={(size) => {
                props.setSettings({
                  ...props.settings,
                  customSourceTextSize: size ?? null,
                });
              }}
            />
          </section>
          <div className="preview">
            <h2>Förhandsvisning</h2>
            <OutputPreview cardId={props.cardId}></OutputPreview>
          </div>
        </div>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterRight>
            <Button title="Stäng" onClick={props.onClose}></Button>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModalTall>
  );
}
