import { MicroSubjectPath } from "../application/state/stats/document-core/core-micro";
import { defined } from "../core/defined";

export interface Categories {
  [area: string]: { [subarea: string]: string[] };
}

export type CategoryPath = { area: string; subarea: string; subject: string };
export type CategoryLevel = keyof CategoryPath;

export const levelNames: { [key in keyof CategoryPath]: string } = {
  area: "Område",
  subarea: "Delområde",
  subject: "Ämne",
};

export function defaultSubject(
  path: MicroSubjectPath,
  categories: Categories
): [string, string, string] | undefined {
  const [area, subarea, subject] = path;

  if ([area, subarea, subject].every(defined)) {
    return [area, subarea, subject] as [string, string, string];
  }
  if (!defined(area)) {
    return undefined;
  }

  const subareas = Object.keys(categories[area]).sort();
  if (!defined(subarea)) {
    const defaultSubarea = subareas[0];
    const defaultSubjects = categories[area][defaultSubarea]?.sort();
    if (!defined(defaultSubjects) || defaultSubjects.length === 0) {
      return;
    }
    return [area, defaultSubarea, defaultSubjects[0]];
  } else {
    const defaultSubjects = categories[area][subarea]?.sort();
    if (!defined(defaultSubjects) || defaultSubjects.length === 0) {
      return;
    }
    return [area, subarea, defaultSubjects[0]];
  }
}

export function prepareCategoryParts(
  path: (string | undefined)[],
  categories: Categories
) {
  const [area, subarea] = path;
  const areas = Object.keys(categories);
  const subareas =
    defined(area) && defined(categories[area])
      ? Object.keys(categories[area])
      : undefined;
  const subjects =
    defined(area) && defined(subarea) && defined(categories[area])
      ? categories[area][subarea]
      : undefined;
  return { areas, subareas, subjects };
}
