export function withoutUndefinedProperties<T extends Record<any, any>>(
  obj: T
): T {
  const copy: T = { ...obj };
  for (const key of Object.keys(copy)) {
    if (copy[key] === undefined) {
      delete copy[key];
    }
  }
  return copy;
}

export const omitUndefined = withoutUndefinedProperties;
