import {
  Array as ArrayRT,
  Boolean as BooleanRT,
  Number as NumberRT,
  String as StringRT,
  Literal,
  Record,
  Static,
  Null,
  Union,
  Tuple,
  Optional,
} from "runtypes";

import { DataValueTypeAnyRT } from "./dataset";

const CommonFieldsRT = Record({
  area: StringRT,
  subarea: StringRT,
  hit_field: Optional(StringRT),
  hit_indices: Optional(Tuple(NumberRT, NumberRT)),
  hit_string: Optional(StringRT),
  full_hit: Optional(BooleanRT),
  search_score: NumberRT,
});

const SearchResultSubareaRT = Record({
  hit_type: Literal("subarea"),
}).And(CommonFieldsRT);

const SearchResulSubjectRT = Record({
  hit_type: Literal("subject"),
  subject: StringRT,
}).And(CommonFieldsRT);

export const SearchResultMeasureRT = CommonFieldsRT.And(
  Record({
    hit_type: Literal("measure"),
    data_id: NumberRT,
    subject: StringRT,
    measure: StringRT,
    descr_long: StringRT,
    source: StringRT,
    value_type: DataValueTypeAnyRT,
    resolution: StringRT,
    geo_types: ArrayRT(StringRT),
    dimension_value_label: Optional(StringRT),
    dimension_value_id: Optional(NumberRT),
    dimension_data_column: Optional(StringRT),
    dimension_label: Optional(StringRT),
  })
);

export const SearchResultItemV2RT = Union(
  SearchResulSubjectRT,
  SearchResultSubareaRT,
  SearchResultMeasureRT
);
export const SearchResultsV2RT = ArrayRT(SearchResultItemV2RT).Or(Null);

export type SearchResultMeasure = Static<typeof SearchResultMeasureRT>;
export type SearchResultItemV2 = Static<typeof SearchResultItemV2RT>;
export type SearchResultsV2 = Static<typeof SearchResultsV2RT>;
