import { logger } from "../infra/logging";
import { Either } from "./func/monad";

export type ResultType<T, E> =
  | {
      type: "err";
      error: E;
    }
  | {
      type: "ok";
      data: T;
    };

export class Result<T, E> implements Either<E, T> {
  protected constructor(private _resRaw: ResultType<T, E>) {}

  /**
   * @deprecated
   * use fold instead
   */
  switch(): ResultType<T, E> {
    return this._resRaw;
  }

  match<LeftOut, RightOut>(matcher: {
    ok: (arg: T) => LeftOut;
    err: (arg: E) => RightOut;
  }): LeftOut | RightOut {
    const resRaw = this._resRaw;
    if (resRaw.type === "err") {
      return matcher.err(resRaw.error);
    }
    return matcher.ok(resRaw.data);
  }

  fold<LeftOut, RightOut>(
    onLeft: (arg: E) => LeftOut,
    onRight: (arg: T) => RightOut
  ): LeftOut | RightOut {
    const resRaw = this._resRaw;
    if (resRaw.type === "err") {
      return onLeft(resRaw.error);
    }
    return onRight(resRaw.data);
  }

  /**
   * Returns the wrapped data, or throws an error if the Result is of type err.
   */
  unwrap(): T {
    if (this._resRaw.type === "err") {
      logger.error(this._resRaw.error);
      throw new Error("Called unwrap on err!");
    }
    return this._resRaw.data;
  }

  ok(): T | undefined {
    return this._resRaw.type === "ok" ? this._resRaw.data : undefined;
  }

  map<U>(mapFunc: (input: T) => U): Result<U, E> {
    const resRaw = this._resRaw;
    if (resRaw.type === "err") {
      return this as any;
    }
    const mapRes = mapFunc(resRaw.data);
    if (mapRes instanceof Result) {
      return mapRes;
    }
    return Result.fromOk(mapRes) as any;
  }

  static fromErr<E, T = unknown>(error: E) {
    return new Result<T, E>({ type: "err", error });
  }

  static fromOk<T, E = unknown>(data: T) {
    return new Result<T, E>({ type: "ok", data });
  }
}
