import {
  CommitDto,
  RevertDto,
} from "../../../../../lib/application/requests/admin/measure_management/shared";

export function renderCommitResult(commitDto: CommitDto): JSX.Element {
  return (
    <>
      <h3>Info om gjorda ändringar</h3>
      <table>
        <tbody>
          <tr>
            <td>Rader borttagna, antal</td>
            <td>{commitDto.rows_deleted}</td>
          </tr>
          <tr>
            <td>Rader publicerade, antal</td>
            <td>{commitDto.rows_published}</td>
          </tr>
          <tr>
            <td>Dimensionsvärden tillagda, antal</td>
            <td>{commitDto.dimension_values_published}</td>
          </tr>
          <tr>
            <td>Ej använda dimensionsvärden borttagna, antal</td>
            <td>{commitDto.unused_dimension_values_deleted}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export function renderRevertResult(revertResult: RevertDto): JSX.Element {
  return (
    <>
      <h3>Info om slängda ändringar</h3>
      <table>
        <tbody>
          <tr>
            <td>Nya, ej publicerade, rader som slängts, antal</td>
            <td>{revertResult.rows_deleted}</td>
          </tr>
          <tr>
            <td>Rader ej längre markerade för borttagning, antal</td>
            <td>{revertResult.rows_reverted}</td>
          </tr>
          <tr>
            <td>Ej använda dimensionsvärden borttagna, antal</td>
            <td>{revertResult.unused_dimension_values_deleted}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
