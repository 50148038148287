import { useCallback, useContext, useMemo, useState } from "react";
import { IconProps } from "@blueprintjs/core";
import { Pivot, PivotItem } from "@fluentui/react";
import { useRecoilState, useRecoilValue } from "recoil";
import { chain, flatten } from "lodash";

import { MeasureSelection } from "../../../../../lib/domain/selections/definitions";
import { defined } from "../../../../../lib/core/defined";
import { cardColors } from "../../../../../lib/application/state/stats/document-core/queries/shared";
import { cardWithUpdatedDataState } from "../../../../../lib/application/state/stats/cardToDataStateStats";
import { wait } from "../../../../../lib/core/wait";
import {
  CardBgColorContext,
  CardUpdateCountContext,
  InfostatDataAdminModeContext,
  ShowDraftDataContext,
} from "../../../../../lib/application/contexts";
import { useExtendedAppearanceSettings } from "../../../../../lib/application/state/actions/useExtendedAppearanceSettings";
import { useChangeDataOutputSettings } from "../../../../../lib/application/state/actions/selections/useChangeDataOutputSettings";
import {
  currentlyValidGeoSelection,
  getGeoSelections,
  primarySelectionQuery,
  selectedCardViewQuery,
  singleDataCardQuery,
  supportedGeographiesQuery,
} from "../../../../../lib/application/state/stats/document-core/queries/dataCard";
import { usePrimarySelectionChanger } from "../../../../../lib/application/state/actions/selections/shared/usePrimarySelectionChanger";
import { useToggle } from "../../../../../lib/application/hooks/useToggle";
import {
  useActivateLockToLatestTime,
  useChangeTime,
  useUnsetLockToLatestTime,
} from "../../../../../lib/application/state/actions/selections/useChangeTime";
import {
  DataOutputView,
  GeoSelections,
} from "../../../../../lib/application/state/stats/document-core/core";
import { MeasureSelectionComponentRegular } from "./selections/MeasureSelectionComponentRegular";
import {
  DefaultLoading,
  DefaultLoadingStretchedToFit,
} from "../../../../../components/Loading";
import { Progress } from "../../../../../lib/core/progress";
import { logger } from "../../../../../lib/infra/logging";
import { Button } from "../../../../../components/Button";
import { classNames } from "../../../../../lib/core/classNames";
import { GeographiesSerializable } from "../../../../../lib/domain/geography";
import { useDatasetError } from "../../../../../lib/application/state/stats/useDataset";
import { PivotPlaceholder } from "./PivotPlaceholder";
import { AlertBox } from "../../../../../components/AlertBox";
import { SurveyStringDataset } from "../../../../../lib/application/stats/datasets/SurveyStringDataset";
import { TableTab } from "../card_general/TableTab";
import { MapTab } from "./output_tabs/MapTab";
import { ChartTab } from "../card_general/ChartTab";
import { InfoTab } from "../card_general/InfoTab";
import { TimeSelect } from "../card_general/TimeSelect";
import { DataOutputToolbar } from "../card_general/DataOutputToolbar";
import { truncate } from "../../../../../lib/core/truncate";
import { DataCardGeoModal } from "../card_general/DataCardGeoModal";

import { CardContainer } from "../CardContainer";
import {
  TabbedCardTabsContainer,
  TabbedCardTab,
  TabbedCardsContentContainer,
  TabbedCardMain,
} from "../../../../../components/Card";
import {
  PageBreakBefore,
  PageBreakAfter,
} from "../../../../../components/print/page_breaks";
import { TabContainer } from "../card_tabs/CardTab";
import { ThirdPartyDocDataCardTabView } from "../card_tabs/DataCardTab";
import { voidFunc } from "../../../../../lib/core/voidFunc";
import { ISharingInfo } from "../../../../../lib/application/files/SharingInfo";
import {
  measureSelectionIsRegular,
  measureSelectionIsSurvey,
} from "../../../../../lib/domain/measure";
import { measureSelectionIsSurveyString } from "../../../../../lib/domain/measure";
import { MeasureSelectionComponentSurvey } from "./selections/MeasureSelectionComponentSurvey";
import { TableTabSurveyString } from "../card_general/TableTabSurveyString";

import "./DataCardThirdPartyDoc.scss";

const pivots: Array<{
  key: DataOutputView;
  title: string;
  icon: IconProps["icon"];
  component?: JSX.Element;
}> = [
  {
    key: "diagram",
    title: "Diagram",
    icon: "grouped-bar-chart",
  },
  { key: "table", title: "Tabell", icon: "th" },
  { key: "map", title: "Karta", icon: "map" },
  { key: "info", title: "Info", icon: "info-sign" },
];

const adminTabName = "admin_meta";

/**
 * Special view of data cards for use in
 * - third-party documents
 * - view mode for cards, when doc is in editing mode
 * - view mode for cards, when doc is in view mode
 */
export function DataCardThirdPartyDoc(props: {
  cardId: string;
  geographies: GeographiesSerializable;
  sharingInfo: ISharingInfo;
}) {
  const sharingInfo = props.sharingInfo;
  const card = useRecoilValue(
    singleDataCardQuery({ cardStateId: props.cardId })
  );
  const dataAdminMode = useContext(InfostatDataAdminModeContext);

  const cardTabs = useMemo(() => {
    const cardTabsHolder = ["data"];

    if (dataAdminMode) {
      cardTabsHolder.push(adminTabName);
    }
    return cardTabsHolder;
  }, [dataAdminMode]);

  const [currentTabUnchecked, setCurrentTab] = useState(cardTabs[0]);

  const currentTab = cardTabs.includes(currentTabUnchecked)
    ? currentTabUnchecked
    : cardTabs[0];

  const onSelectTab = (name: string) => setCurrentTab(name);

  return (
    <>
      <CardContainer
        className={defined(card.bgColor) ? "custom-bg" : ""}
        isRemovingCard={false}
        cardId={card.id}
        removeBrokenCard={voidFunc}
        sharingInfo={sharingInfo}
      >
        <PageBreakBefore breakSetting={card.pageBreak}></PageBreakBefore>
        <TabbedCardTabsContainer currentTab={currentTab}>
          <TabbedCardTab
            key="primary-tab"
            containerKey={
              card.id +
              card.data.dataSelections
                .map((d) => d.measureSelection?.measure.data_id ?? "")
                .join(",")
            }
            onSelect={onSelectTab}
            name={cardTabs[0]}
          >
            <ThirdPartyDocDataCardTabView
              sharingInfo={sharingInfo}
              containerKey={
                card.id +
                card.data.dataSelections
                  .map((d) => d.measureSelection?.measure.data_id ?? "")
                  .join(",")
              }
              cardId={card.id}
            />
          </TabbedCardTab>
          {defined(cardTabs[1]) ? (
            <TabbedCardTab
              key="metadata-tab"
              onSelect={onSelectTab}
              name={cardTabs[1]}
            >
              <TabContainer>
                <></>
                <p>Metadata</p>
              </TabContainer>
            </TabbedCardTab>
          ) : (
            <div></div>
          )}
        </TabbedCardTabsContainer>
        <TabbedCardsContentContainer currentTab={currentTab}>
          <></>
          <TabbedCardMain name={cardTabs[0]}>
            <DataCardThirdPartyDocContent
              cardId={card.id}
              geographies={props.geographies}
            />
          </TabbedCardMain>
        </TabbedCardsContentContainer>
        <PageBreakAfter breakSetting={card.pageBreak}></PageBreakAfter>
      </CardContainer>
    </>
  );
}

export function DataCardThirdPartyDocContent(props: {
  cardId: string;
  geographies: GeographiesSerializable;
}) {
  const isEditing = true;
  const geographies = props.geographies;
  const queryProps = { cardStateId: props.cardId };

  const primarySelection = useRecoilValue(primarySelectionQuery(queryProps));
  const handleChangePrimarySelection = usePrimarySelectionChanger(
    props.cardId,
    primarySelection,
    geographies
  );
  const [settings, setSettings] = useChangeDataOutputSettings(props.cardId);
  const appearanceSettings = useExtendedAppearanceSettings();
  const supportedGeographies = useRecoilValue(
    supportedGeographiesQuery(queryProps)
  );
  const [card, setCard] = useRecoilState(
    singleDataCardQuery({ cardStateId: props.cardId })
  );
  const thirdPartyDataCardSettings = card.data.thirdPartyDataCardSettings;
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const [geoModalOpen, toggleGeoModalOpen] = useToggle(false);
  const measureSelection = primarySelection?.measureSelection;
  const geoSelections = useMemo(
    () => getGeoSelections(card, geographies),
    [card, geographies]
  );
  const geoSelectionString: string = listSelections(geoSelections);
  const selectedKey = useRecoilValue(selectedCardViewQuery(queryProps));

  const handleUpdateTime = useChangeTime(card);
  const handleUnsetLockToLatestTime = useUnsetLockToLatestTime(
    card,
    geographies,
    adminShowDraftData
  );
  const handleActivateLockToLatestTime = useActivateLockToLatestTime(
    card,
    geographies,
    adminShowDraftData
  );

  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const handleLinkClick = useCallback(
    async (item?: PivotItem) => {
      if (!defined(item)) {
        return;
      }

      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const updatedCard = {
        ...card,
        data: {
          ...card.data,
          selectedView: item.props.itemKey as any,
        },
      };

      setCard(updatedCard);
      await wait(0);

      const cardWithData = await cardWithUpdatedDataState(
        shouldAbort,
        updatedCard,
        geographies,
        adminShowDraftData,
        appearanceSettings,
        cardColors(card)
      );
      if (shouldAbort() || !defined(cardWithData)) {
        return;
      }
      setCard(cardWithData);
    },
    [
      adminShowDraftData,
      card,
      appearanceSettings,
      geographies,
      getCurrentCount,
      incrementUpdateCount,
      setCard,
    ]
  );

  const handleSetMeasureSelection = useCallback(
    (m: MeasureSelection) => {
      const prev = primarySelection;
      if (!defined(prev)) {
        return;
      }
      handleChangePrimarySelection({ ...prev, measureSelection: m });
    },
    [handleChangePrimarySelection, primarySelection]
  );

  const onlyCountrySupported =
    supportedGeographies.length === 1 && supportedGeographies[0] === "country";

  const unsupportedTabs: DataOutputView[] = useMemo(() => {
    return chain([
      defined(primarySelection) &&
      primarySelection.measureSelection?.measure.value_type === "category"
        ? ("diagram" as const)
        : undefined,
      onlyCountrySupported ? ("map" as const) : undefined,
      ...(thirdPartyDataCardSettings?.hideTabs ?? []),
      ...(primarySelection?.measureSelection?.measure.value_type ===
      "survey_string"
        ? ["diagram" as const, "map" as const]
        : []),
    ])
      .filter(defined)
      .uniq()
      .value();
  }, [primarySelection, onlyCountrySupported, thirdPartyDataCardSettings]);

  const bgColor = useContext(CardBgColorContext);

  const supportedTabs = pivots.filter((p) => !unsupportedTabs.includes(p.key));
  const selectedPivot = unsupportedTabs.includes(selectedKey)
    ? supportedTabs[0]
    : pivots.find((p) => p.key === selectedKey);

  if (
    card.initState === Progress.NotStarted ||
    card.initState === Progress.InProgress
  ) {
    return <DefaultLoadingStretchedToFit />;
  }

  if (!defined(measureSelection)) {
    logger.error("No measure selection defined");
    return null;
  }

  const showGeo =
    !onlyCountrySupported && thirdPartyDataCardSettings?.showGeoSelector;
  const noControls =
    !thirdPartyDataCardSettings?.showBreakdowns &&
    !showGeo &&
    !thirdPartyDataCardSettings?.showTimeline;

  const isSurvey =
    measureSelectionIsSurvey(measureSelection) ||
    measureSelectionIsSurveyString(measureSelection);

  return (
    <div
      className="third-party-doc-card-container"
      style={defined(bgColor) ? { backgroundColor: bgColor } : {}}
    >
      {thirdPartyDataCardSettings?.showBreakdowns && (
        <>
          {measureSelectionIsRegular(measureSelection) && (
            <MeasureSelectionComponentRegular
              hideMeasure
              isGroupingSelection={false}
              measureSelection={measureSelection}
              setMeasureSelection={handleSetMeasureSelection}
            ></MeasureSelectionComponentRegular>
          )}
          {isSurvey && (
            <MeasureSelectionComponentSurvey
              hideMeasure
              isGroupingSelection={false}
              measureSelection={measureSelection}
              setMeasureSelection={handleSetMeasureSelection}
            ></MeasureSelectionComponentSurvey>
          )}
        </>
      )}
      {showGeo && !isSurvey && (
        <div className="simple-geo-display">
          <p>
            <strong>Valda geografiska områden</strong>: {geoSelectionString}
          </p>{" "}
          <Button small title="Ändra" onClick={toggleGeoModalOpen}></Button>
        </div>
      )}
      {thirdPartyDataCardSettings?.showTimeline && (
        <TimeSelect
          setLockToLatestTime={handleActivateLockToLatestTime}
          updateCardStateWithTimeRange={handleUpdateTime}
          unsetLockToLatestTime={handleUnsetLockToLatestTime}
          appMode="embedded"
          cardStateId={props.cardId}
        ></TimeSelect>
      )}
      {geoModalOpen && !isSurvey && (
        <DataCardGeoModal
          cardStateId={props.cardId}
          geographies={props.geographies}
          supportedGeographies={supportedGeographies}
          primarySelection={primarySelection}
          handleClose={toggleGeoModalOpen}
        />
      )}

      <div
        className={classNames(
          isEditing ? "editing" : "read-only",
          noControls ? "no-controls" : "",
          "stats-geo-and-output-container"
        )}
      >
        <div className="stats-output-viewer">
          {isEditing && (
            <div className={classNames("menu-container", "tools-surface")}>
              {supportedTabs.length > 1 && (
                <Pivot
                  className="pivot-menu"
                  selectedKey={selectedKey}
                  getTabId={(itemKey) => itemKey}
                  onLinkClick={handleLinkClick}
                >
                  {supportedTabs.map((p) => (
                    <PivotItem
                      key={p.title}
                      itemKey={p.key}
                      headerText={p.title}
                    ></PivotItem>
                  ))}
                </Pivot>
              )}
              <DataOutputToolbar
                cardRaw={card}
                isEditing={true}
                isThirdPartyDoc={true}
                settings={settings}
                setSettings={setSettings}
                showToolbar={thirdPartyDataCardSettings?.showToolbar}
                showToolbarDownload={
                  thirdPartyDataCardSettings?.showToolbarDownload
                }
                showToolbarSettings={
                  thirdPartyDataCardSettings?.showToolbarSettings
                }
              />
            </div>
          )}
          <div className={classNames("tab-content-with-toolbar")}>
            <TabContent
              selectedPivot={selectedPivot}
              cardStateId={props.cardId}
              geographies={geographies}
            ></TabContent>
          </div>
        </div>
      </div>
    </div>
  );
}

function TabContent(props: {
  cardStateId: string;
  geographies: GeographiesSerializable;
  selectedPivot?: (typeof pivots)[number];
}) {
  const { selectedPivot, cardStateId, geographies } = props;
  const datasetError = useDatasetError(cardStateId);
  const [settings, setSettings] = useChangeDataOutputSettings(cardStateId);
  const currentCard = useRecoilValue(singleDataCardQuery({ cardStateId }));
  const geoSelections = useMemo(
    () => currentlyValidGeoSelection(currentCard, geographies),
    [currentCard, geographies]
  );
  const loadedData = currentCard.data.loadedData;

  if (!defined(currentCard) || !defined(loadedData)) {
    return <PivotPlaceholder icon={selectedPivot?.icon}></PivotPlaceholder>;
  }
  if (
    selectedPivot !== undefined &&
    !["table", "diagram", "info", "map"].includes(selectedPivot.key)
  ) {
    return <PivotPlaceholder icon={selectedPivot.icon}></PivotPlaceholder>;
  }

  if (loadedData?.progress?.type === Progress.InProgress) {
    return <DefaultLoading></DefaultLoading>;
  }
  if (defined(datasetError)) {
    return (
      <AlertBox className="margin-x-sm margin-y-md" intent="warning">
        <span>{datasetError.displayMessage()}</span>
      </AlertBox>
    );
  }
  const dataset = loadedData.dataset;

  if (dataset instanceof SurveyStringDataset) {
    return (
      <>
        {selectedPivot?.key === "table" && (
          <TableTabSurveyString dataset={dataset} cardId={currentCard.id} />
        )}
        {selectedPivot?.key === "info" && (
          <InfoTab dataset={dataset} cardId={currentCard.id}></InfoTab>
        )}
      </>
    );
  }

  return (
    <>
      {selectedPivot?.key === "table" && (
        <TableTab
          settings={settings}
          setSettings={setSettings}
          tableDataState={loadedData.tableDataState}
        ></TableTab>
      )}
      {selectedPivot?.key === "map" && (
        <MapTab
          isReloadingData={false}
          geoSelections={geoSelections}
          dataset={dataset}
          cardId={currentCard.id}
          settings={settings}
        ></MapTab>
      )}
      {selectedPivot?.key === "diagram" && (
        <ChartTab
          cardId={currentCard.id}
          chartDataState={loadedData.chartDataState}
        ></ChartTab>
      )}
      {selectedPivot?.key === "info" && (
        <InfoTab dataset={dataset} cardId={currentCard.id}></InfoTab>
      )}
    </>
  );
}

function listSelections(selection: GeoSelections): string {
  const maxLen = 30;
  const entries = flatten(Object.values(selection));
  if (entries.length > 3) {
    return (
      entries
        .slice(0, 3)
        .map((e) => e.label)
        .join(", ")
        .slice(0, maxLen) + "..."
    );
  }
  return truncate(entries.map((item) => item.label).join(", "), maxLen);
}
