import { useCallback, useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSetRecoilState } from "recoil";

import { defined } from "../../../../core/defined";
import { FilterMeasureMicro } from "../../../../domain/measure/definitions";
import {
  ComputedMeasurementType,
  MicroMeasureDto,
} from "../../../../infra/api_responses/micro_dataset";
import { logger } from "../../../../infra/logging";
import { getMicroDimensionsWithCache } from "../../../requests/datasets/micro";
import { replaceAtIndexImmut } from "../../generic";
import {
  findMeasureMicro,
  measureToMeasureSpecMicro,
} from "../../stats/document-core/core-micro";
import { singleMicroCardQuery } from "../../stats/document-core/queries/microCard";
import { getDefaultSelectedDimensions } from "../../../../domain/measure";
import { useReadMicroCardState } from "./updates";
import { loadAndStoreDataMicro } from "../../stats/cardToDataStateMicro";
import { cardColors } from "../../stats/document-core/queries/shared";
import { useGeoTree } from "../../../../../views/stats/docs/cards/micro/useGeoTree";
import { useSaveCard } from "../useSaveDocument";
import { CardUpdateCountContext } from "../../../contexts";
import { useExtendedAppearanceSettings } from "../useExtendedAppearanceSettings";

type CallbackType = (
  filterMeasureIndex: number,
  id: number,
  computedMeasurementType: ComputedMeasurementType | undefined,
  availableMeasures: MicroMeasureDto[]
) => void;

export function useChangeFilterMeasure(
  cardId: string,
  adminShowDraftData: boolean
): [CallbackType, boolean] {
  const setCard = useSetRecoilState(
    singleMicroCardQuery({ cardStateId: cardId })
  );
  const [changePending, setChangePending] = useState(false);

  const geoTree = useGeoTree();
  const handleSaveCard = useSaveCard();
  const appearanceSettings = useExtendedAppearanceSettings();
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const readCard = useReadMicroCardState(cardId);

  const handleSetSelectedMeasure = useCallback(
    async (
      filterMeasureIndex: number,
      id: number,
      computedMeasurementType: ComputedMeasurementType | undefined,
      availableMeasures: MicroMeasureDto[]
    ) => {
      setChangePending(true);
      try {
        const selectedMeasure = findMeasureMicro(
          availableMeasures,
          id,
          computedMeasurementType
        );
        if (!defined(selectedMeasure)) {
          return;
        }

        const currentUpdate = incrementUpdateCount();
        const shouldAbort = () => getCurrentCount() > currentUpdate;

        const dimensions = (
          await getMicroDimensionsWithCache(
            selectedMeasure.mikro_id,
            adminShowDraftData
          )
        ).match({
          ok: (data) => {
            return data ?? [];
          },
          err: (err) => {
            logger.error(err);
            return undefined;
          },
        });

        if (!defined(dimensions)) {
          logger.error(
            "dimensions object not populated (nor empty array) - this means an error occurred"
          );
          return;
        }
        if (!defined(geoTree)) {
          logger.error("geoTree not populated - this means an error occurred");
          return;
        }
        if (shouldAbort()) {
          return;
        }

        const selectedDimensions = getDefaultSelectedDimensions(dimensions);
        const prev = readCard();
        const prevFilterMeasure = prev.data.filterMeasures[filterMeasureIndex];

        const filterMeasure: FilterMeasureMicro = {
          id: uuidv4(),
          subjectPath: prevFilterMeasure.subjectPath,
          measureSelection: {
            measure: measureToMeasureSpecMicro(selectedMeasure, dimensions),
            selectedDimensions,
          },
        };
        const updatedCard = {
          ...prev,
          data: {
            ...prev.data,
            filterMeasures: replaceAtIndexImmut(
              prev.data.filterMeasures,
              filterMeasureIndex,
              filterMeasure
            ),
          },
        };
        setCard(updatedCard);
        loadAndStoreDataMicro(
          updatedCard,
          setCard,
          shouldAbort,
          geoTree,
          adminShowDraftData,
          appearanceSettings,
          cardColors(updatedCard),
          handleSaveCard
        );
      } finally {
        setChangePending(false);
      }
    },
    [
      adminShowDraftData,
      appearanceSettings,
      geoTree,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      readCard,
      setCard,
    ]
  );

  return [handleSetSelectedMeasure, changePending];
}
