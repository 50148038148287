/**
 * A representation of a single data point in dataset.
 */
export interface DataPoint {
  isUserDefined: boolean;

  _dimension(dimension: string): unknown;

  colorKey?(): string;

  /** Label for this data point. Must be unique in dataset. */
  label(): string;

  /** Raw domain value */
  domainRaw(): string;

  /** Numeric value  */
  value(): number | undefined;

  /** Textual representation of the value. Can be a marker for missing values. */
  display(): string;
}

export interface ForecastDataPoint extends DataPoint {
  high: number;
  low: number;
}

export interface DrawableDataPoint extends DataPoint {
  value(): number;
}

export function isDrawableDataPoint(
  point: DataPoint
): point is DrawableDataPoint {
  return point.value() !== undefined;
}
