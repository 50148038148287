import {
  TextField,
  IDropdownOption,
  Dropdown,
  Checkbox,
} from "@fluentui/react";
import { useCallback, useState, useContext, useMemo } from "react";

import { Button } from "../../../../../components/Button";
import {
  InfostatDatePickerControlled,
  InfostatTimeInput,
} from "../../../../../components/input/DatePicker";
import { AppMessagesContext } from "../../../../../lib/application/contexts";
import { classNames } from "../../../../../lib/core/classNames";
import { defined } from "../../../../../lib/core/defined";
import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import { Milliseconds } from "../../../../../lib/core/time";
import { OrganizationResponse } from "../../../../../lib/infra/api_responses/admin/organizations";
import { HttpResult } from "../../../../../lib/infra/HttpResult";
import { logger } from "../../../../../lib/infra/logging";
import { truncate } from "../../../../../lib/core/truncate";
import { MultiDropdownWithSelectAll } from "../../../../../components/input/MultiDropdown";

import "./shared.scss";

export const ORG_ACCESS_FIELD_LABEL = "Organisationer med behörighet";

export type FieldType =
  | "int-input"
  | "input"
  | "textarea"
  | "date"
  | "checkbox"
  | "time"
  | "time-multi"
  | "select-single"
  | "select-multi";

export type DataFieldDef<T> =
  | {
      key: keyof T;
      label?: string;
      editable: boolean;
      deletable?: boolean;
      type: Exclude<FieldType, "select-single" | "select-multi">;
    }
  | {
      key: keyof T;
      label?: string;
      editable: boolean;
      deletable?: boolean;
      type: "select-single";
      options: IDropdownOption[];
    }
  | {
      key: keyof T;
      label?: string;
      editable: boolean;
      deletable?: boolean;
      type: "select-multi";
      options: IDropdownOption[];
    };

export function DataFields<T>(props: {
  fields: DataFieldDef<T>[];
  data: T;
  handleSave: (
    key: keyof T,
    value: string | number | boolean | null | string[] | undefined
  ) => Promise<HttpResult<unknown>>;
}) {
  const { fields, data, handleSave } = props;

  return (
    <div className="data-fields-with-editing">
      {fields.map((field) => {
        const fieldValue = data[field.key];
        const commonProps = {
          fieldType: field.type,
          key: field.key as string,
          field: field.key,
          deletable: field.deletable,
        };
        const fieldLabel = field.label ?? (field.key as string);

        const currentValue = fieldValue;
        switch (field.type) {
          case "int-input":
            if (!(typeof currentValue === "number" || !defined(currentValue))) {
              throw new Error("Unexpected field value type!");
            }
            if (!field.editable) {
              return (
                <ReadonlyField
                  {...commonProps}
                  currentValue={currentValue?.toString() ?? ""}
                  label={fieldLabel}
                ></ReadonlyField>
              );
            }
            return (
              <EditableFieldInput<T>
                {...commonProps}
                label={field.label}
                currentValue={currentValue?.toString() ?? ""}
                handleSave={(value) =>
                  handleSave(
                    field.key,
                    defined(value) ? parseInt(value) : undefined
                  )
                }
              ></EditableFieldInput>
            );
          case "input":
            if (!(typeof currentValue === "string" || !defined(currentValue))) {
              throw new Error("Unexpected field value type!");
            }
            if (!field.editable) {
              return (
                <ReadonlyField
                  {...commonProps}
                  fieldType="input"
                  currentValue={currentValue ?? ""}
                  label={fieldLabel}
                ></ReadonlyField>
              );
            }
            return (
              <EditableFieldInput
                {...commonProps}
                currentValue={currentValue ?? ""}
                handleSave={(value) => handleSave(field.key, value)}
              ></EditableFieldInput>
            );
          case "select-single":
            if (!(typeof currentValue === "string" || !defined(currentValue))) {
              throw new Error(
                "Unexpected field value type, expected string: " + currentValue
              );
            }
            if (!field.editable) {
              return (
                <ReadonlyField
                  {...commonProps}
                  currentValue={currentValue ?? ""}
                  label={fieldLabel}
                ></ReadonlyField>
              );
            }
            return (
              <SelectFieldSingle
                {...commonProps}
                label={fieldLabel}
                options={field.options ?? []}
                currentValue={(currentValue ?? "") as string}
                handleSave={(value) => handleSave(field.key, value)}
              ></SelectFieldSingle>
            );

          case "select-multi":
            if (!(Array.isArray(currentValue) || !defined(currentValue))) {
              throw new Error(
                "Unexpected field value type, expected array: " + currentValue
              );
            }
            if (!field.editable) {
              const displayValue = Array.isArray(currentValue)
                ? field.options
                    .filter((o) => currentValue.includes(o.key))
                    .map((o) => o.text)
                    .join(", ")
                : currentValue ?? "";
              return (
                <ReadonlyField
                  {...commonProps}
                  currentValue={displayValue}
                  label={fieldLabel}
                ></ReadonlyField>
              );
            }
            return (
              <SelectFieldMulti
                {...commonProps}
                label={fieldLabel}
                options={field.options ?? []}
                currentValue={currentValue ?? []}
                handleSave={(value) => handleSave(field.key, value)}
              ></SelectFieldMulti>
            );
          case "textarea":
            if (!(typeof currentValue === "string" || !defined(currentValue))) {
              throw new Error("Unexpected field value type!");
            }
            if (!field.editable) {
              return (
                <ReadonlyField
                  {...commonProps}
                  currentValue={currentValue ?? ""}
                  label={fieldLabel}
                ></ReadonlyField>
              );
            }
            return (
              <EditableFieldTextarea<T>
                {...commonProps}
                currentValue={currentValue ?? ""}
                handleSave={(value) => handleSave(field.key, value)}
              ></EditableFieldTextarea>
            );
          case "date":
            if (!(typeof currentValue === "string" || !defined(currentValue))) {
              throw new Error("Unexpected field value type!");
            }
            if (!field.editable) {
              return (
                <ReadonlyField
                  {...commonProps}
                  currentValue={currentValue ?? ""}
                  label={fieldLabel}
                ></ReadonlyField>
              );
            }
            return (
              <EditableFieldDate<T>
                {...commonProps}
                currentValue={currentValue}
                handleSave={(value) => handleSave(field.key, value)}
              ></EditableFieldDate>
            );

          case "time":
            if (!(typeof currentValue === "string" || !defined(currentValue))) {
              throw new Error("Unexpected field value type!");
            }
            if (!field.editable) {
              return (
                <ReadonlyField
                  {...commonProps}
                  currentValue={currentValue ?? ""}
                  label={fieldLabel}
                ></ReadonlyField>
              );
            }
            return (
              <EditableTimeField
                {...commonProps}
                label={fieldLabel}
                deletable={field.deletable ?? false}
                currentValue={currentValue}
                handleSave={(value) => handleSave(field.key, value)}
              ></EditableTimeField>
            );
          case "time-multi":
            if (!(typeof currentValue === "string" || !defined(currentValue))) {
              throw new Error(
                "Unexpected field value type, expected string, was: " +
                  currentValue
              );
            }
            if (!field.editable) {
              return (
                <ReadonlyField
                  {...commonProps}
                  currentValue={currentValue ?? ""}
                  label={fieldLabel}
                ></ReadonlyField>
              );
            }
            return (
              <EditableTimeMultiField
                {...commonProps}
                label={fieldLabel}
                deletable={field.deletable ?? false}
                currentValue={currentValue}
                handleSave={(value) => handleSave(field.key, value)}
              />
            );
          case "checkbox":
            if (!(typeof currentValue === "boolean")) {
              throw new Error("Unexpected field value type!");
            }
            if (!field.editable) {
              <Checkbox
                label={fieldLabel}
                disabled={true}
                checked={currentValue}
              ></Checkbox>;
            }
            return (
              <EditableFieldCheckbox<T>
                {...commonProps}
                label={fieldLabel}
                currentValue={currentValue}
                handleSave={(value) => handleSave(field.key, value)}
              ></EditableFieldCheckbox>
            );
          default:
            logger.error("Unexpected field type: ", field);
        }
      })}
    </div>
  );
}

export function EditableFieldCheckbox<T>(props: {
  currentValue: boolean;
  field: keyof T;
  label: string;
  deletable?: boolean;
  fieldType: FieldType;
  handleSave: (value: boolean) => Promise<HttpResult<unknown>>;
}) {
  const {
    value,
    setValue,
    editing,
    setEditing,
    setSaving,
    saving,
    handleCancel,
    handleDelete,
    handleSave,
  } = useFieldState(props.currentValue, props.handleSave);

  const onChange = useCallback(
    (
      ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ): void => {
      setValue(!!checked);
    },
    [setValue]
  );

  return (
    <div className={classNames("editable-field section", props.fieldType)}>
      <Checkbox
        label={props.label}
        disabled={!editing}
        checked={value}
        onChange={onChange}
      ></Checkbox>
      <FieldButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleDelete={props.deletable ? handleDelete : undefined}
        disableDelete={props.currentValue === null}
        disableSave={props.currentValue === value}
        editing={editing}
        setEditing={setEditing}
        saving={saving}
        setSaving={setSaving}
      ></FieldButtons>
    </div>
  );
}

export function EditableFieldDate<T>(props: {
  currentValue: string | null | undefined;
  field: keyof T;
  deletable?: boolean;
  fieldType: FieldType;
  handleSave: (
    value: string | undefined | null
  ) => Promise<HttpResult<unknown>>;
}) {
  const {
    value,
    setValue,
    editing,
    setEditing,
    setSaving,
    saving,
    handleCancel,
    handleDelete,
    handleSave,
  } = useFieldState(props.currentValue, props.handleSave);

  const inputProps = {
    label: props.field as string,
    disabled: !editing,
    value: value,
    onChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.currentTarget.value);
    },
  };

  return (
    <div className={classNames("editable-field section", props.fieldType)}>
      <DateField
        {...inputProps}
        onChange={(v: string) => setValue(v)}
      ></DateField>
      <FieldButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleDelete={props.deletable ? handleDelete : undefined}
        disableDelete={props.currentValue === null}
        disableSave={props.currentValue === value}
        editing={editing}
        setEditing={setEditing}
        saving={saving}
        setSaving={setSaving}
      ></FieldButtons>
    </div>
  );
}

export function EditableFieldTextarea<T>(props: {
  currentValue: string;
  field: keyof T;
  deletable?: boolean;
  fieldType: FieldType;
  handleSave: (value: string | null) => Promise<HttpResult<unknown>>;
}) {
  const {
    value,
    setValue,
    editing,
    setEditing,
    setSaving,
    saving,
    handleCancel,
    handleDelete,
    handleSave,
  } = useFieldState(props.currentValue ?? "", props.handleSave);

  const inputProps = {
    label: props.field as string,
    disabled: !editing,
    value: value,
    onChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.currentTarget.value);
    },
  };

  return (
    <div className={classNames("editable-field section", props.fieldType)}>
      <TextField
        multiline
        {...inputProps}
        value={inputProps.value ?? ""}
      ></TextField>
      <FieldButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleDelete={props.deletable ? handleDelete : undefined}
        disableDelete={props.currentValue === null}
        disableSave={props.currentValue === value}
        editing={editing}
        setEditing={setEditing}
        saving={saving}
        setSaving={setSaving}
      ></FieldButtons>
    </div>
  );
}

export function EditableFieldInput<T>(props: {
  label?: string;
  currentValue: string;
  field: keyof T;
  deletable?: boolean;
  fieldType: FieldType;
  handleSave: (value: string | null) => Promise<HttpResult<unknown>>;
}) {
  const {
    value,
    setValue,
    editing,
    setEditing,
    setSaving,
    saving,
    handleCancel,
    handleDelete,
    handleSave,
  } = useFieldState(props.currentValue ?? "", props.handleSave);

  const inputProps = {
    label: props.label ?? (props.field as string),
    disabled: !editing,
    value: value,
    onChange: (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.currentTarget.value);
    },
  };

  return (
    <div className={classNames("editable-field section", props.fieldType)}>
      <TextField {...inputProps} value={inputProps.value ?? ""}></TextField>
      <FieldButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleDelete={props.deletable ? handleDelete : undefined}
        disableDelete={props.currentValue === null}
        disableSave={props.currentValue === value}
        editing={editing}
        setEditing={setEditing}
        saving={saving}
        setSaving={setSaving}
      ></FieldButtons>
    </div>
  );
}

export function FieldButtons(props: {
  handleSave: () => void;
  disableDelete: boolean;
  saving: boolean;
  disableSave: boolean;
  setSaving: (saving: boolean) => void;
  editing: boolean;
  setEditing: (editing: boolean) => void;
  handleDelete?: () => void;
  handleCancel: () => void;
}) {
  const {
    editing,
    setEditing,
    saving,
    handleSave,
    handleCancel,
    disableDelete,
    disableSave,
    handleDelete,
  } = props;
  return (
    <>
      {editing ? (
        <>
          <Button
            onClick={handleCancel}
            disabled={saving}
            title="Avbryt"
          ></Button>
          {defined(handleDelete) && (
            <Button
              intent="danger"
              onClick={handleDelete}
              disabled={saving || disableDelete}
              title={saving ? "Sparar..." : "Ta bort värde"}
            ></Button>
          )}
          <Button
            intent="primary"
            onClick={handleSave}
            disabled={saving || disableSave}
            title={saving ? "Sparar..." : "Spara"}
          ></Button>
        </>
      ) : (
        <Button
          onClick={() => setEditing(true)}
          intent="primary"
          title="Redigera"
        ></Button>
      )}
    </>
  );
}

export function useFieldState<T>(
  currentValue: T,
  handleSaveRemote: (input: T) => Promise<HttpResult<unknown>>,
  validate?: (input: T) => string | undefined
) {
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState<T>(currentValue);

  const appMessagesHandler = useContext(AppMessagesContext);
  const handleDelete = useCallback(() => {
    handleSaveRemote(null as any).then((result) => {
      result.match({
        ok: () => {
          setValue(null as any);
          setEditing(false);
        },
        err: (e) => {
          appMessagesHandler?.add("error", "Misslyckades med att spara!", {
            durationMs: Milliseconds.second * 5,
          });
        },
      });
    });
  }, [appMessagesHandler, handleSaveRemote]);

  const handleSave = useCallback(() => {
    const validationError = validate?.(value);
    if (nonEmptyString(validationError)) {
      appMessagesHandler?.add("error", validationError);
      return;
    }
    setSaving(true);
    handleSaveRemote(value)
      .then((result) => {
        result.match({
          ok: () => {
            setValue(value);
            setEditing(false);
          },
          err: (e) => {
            appMessagesHandler?.add("error", "Misslyckades med att spara!", {
              durationMs: Milliseconds.second * 5,
            });
          },
        });
      })
      .finally(() => setSaving(false));
  }, [appMessagesHandler, handleSaveRemote, validate, value]);

  const handleCancel = useCallback(() => {
    setEditing(false);
    setValue(currentValue);
  }, [currentValue]);

  return {
    value,
    setValue,
    editing,
    setEditing,
    saving,
    setSaving,
    handleDelete,
    handleSave,
    handleCancel,
  };
}

export function ReadonlyField(props: {
  label: string;
  currentValue: string;
  fieldType: FieldType;
  options?: IDropdownOption[];
}) {
  const inputProps = {
    disabled: true,
    label: props.label,
    value: props.currentValue,
    className: "section",
    readOnly: true,
  };
  switch (props.fieldType) {
    case "select-single":
    case "select-multi":
    case "int-input":
    case "input":
    case "time":
      return <TextField {...inputProps}></TextField>;
    case "time-multi":
      return <TextField {...inputProps}></TextField>;
    case "textarea":
      return <TextField multiline {...inputProps}></TextField>;
    case "date":
      return <TextField {...inputProps}></TextField>;
    case "checkbox":
      throw new Error("Not implemented for Checkbox");
  }
}

export function SelectFieldSingle(props: {
  label: string;
  currentValue: string;
  options?: IDropdownOption[];
  handleSave: (value: string) => Promise<HttpResult<unknown>>;
}) {
  const {
    value,
    setValue,
    editing,
    setEditing,
    setSaving,
    saving,
    handleCancel,
    handleSave,
  } = useFieldState(props.currentValue ?? "", props.handleSave);
  return (
    <div className={classNames("editable-field section", "select-single")}>
      <Dropdown
        disabled={!editing}
        options={props.options ?? []}
        label={props.label}
        selectedKey={value}
        onChange={(e, option) => {
          if (!defined(option)) {
            return;
          }
          setValue(option.key as string);
        }}
      ></Dropdown>
      <FieldButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleDelete={undefined}
        disableDelete={props.currentValue === null}
        disableSave={props.currentValue === value}
        editing={editing}
        setEditing={setEditing}
        saving={saving}
        setSaving={setSaving}
      ></FieldButtons>
    </div>
  );
}

export function SelectFieldMulti(props: {
  label: string;
  currentValue: string[] | null;
  options?: IDropdownOption[];
  handleSave: (value: string[] | null) => Promise<HttpResult<unknown>>;
}) {
  const {
    value,
    setValue,
    editing,
    setEditing,
    setSaving,
    saving,
    handleCancel,
    handleSave,
    handleDelete,
  } = useFieldState<string[] | null>(
    props.currentValue ?? [],
    props.handleSave
  );
  const selectedKeysSorted = useMemo(() => {
    const options = props.options;
    if (!defined(options)) {
      return value ?? [];
    }

    if (value === null) {
      return [];
    }
    return options
      .filter((o) => value.includes(o.key as string))
      .map((o) => o.key as string);
  }, [props.options, value]);

  return (
    <div className={classNames("editable-field section", "select-multi")}>
      <MultiDropdownWithSelectAll
        disabled={!editing}
        options={props.options ?? []}
        selectedKeys={selectedKeysSorted}
        label={props.label}
        onRenderTitle={(options) => {
          if (!defined(options) || options.length === 0) {
            return <span></span>;
          }

          const fullText = options.map((option) => option.text).join(", ");
          return <span>{truncate(fullText, 30)}</span>;
        }}
        onChange={(selectedKeys) => {
          setValue(selectedKeys);
        }}
      ></MultiDropdownWithSelectAll>
      <FieldButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleDelete={handleDelete}
        disableDelete={props.currentValue === null}
        disableSave={props.currentValue === value}
        editing={editing}
        setEditing={setEditing}
        saving={saving}
        setSaving={setSaving}
      ></FieldButtons>
    </div>
  );
}

export function EditableTimeMultiField(props: {
  deletable: boolean;
  currentValue: string | null | undefined;
  label: string;
  handleSave: (input: string) => Promise<HttpResult<unknown>>;
}) {
  const validateTimeEntries = useCallback((input: string) => {
    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    const times = input.split(/[\s,]+/);
    for (const time of times) {
      if (!timeRegex.test(time)) {
        return `Felaktigt tidsformat (${time}). Måste matcha HH:MM`;
      }
    }
    return undefined;
  }, []);

  const {
    value,
    setValue,
    editing,
    setEditing,
    setSaving,
    saving,
    handleCancel,
    handleDelete,
    handleSave,
  } = useFieldState(
    props.currentValue ?? "",
    props.handleSave,
    validateTimeEntries
  );

  return (
    <div className={classNames("editable-field section", "time-multi")}>
      <TextField
        disabled={!editing}
        value={value ?? ""}
        label={props.label}
        onChange={(e, newValue) => {
          if (!defined(newValue)) {
            setValue("");
            return;
          }
          setValue(newValue);
        }}
      ></TextField>
      <FieldButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleDelete={props.deletable ? handleDelete : undefined}
        disableDelete={props.currentValue === null}
        disableSave={props.currentValue === value}
        editing={editing}
        setEditing={setEditing}
        saving={saving}
        setSaving={setSaving}
      ></FieldButtons>
    </div>
  );
}

export function EditableTimeField(props: {
  deletable: boolean;
  currentValue: string | null | undefined;
  label: string;
  handleSave: (input: string) => Promise<HttpResult<unknown>>;
}) {
  const validate = useCallback((input: string) => {
    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (!timeRegex.test(input)) {
      return "Felaktigt tidsformat. Måste matcha HH:MM";
    }
    return undefined;
  }, []);

  const {
    value,
    setValue,
    editing,
    setEditing,
    setSaving,
    saving,
    handleCancel,
    handleDelete,
    handleSave,
  } = useFieldState(props.currentValue ?? "", props.handleSave, validate);

  return (
    <div className={classNames("editable-field section", "time")}>
      <InfostatTimeInput
        disabled={!editing}
        value={value ?? ""}
        label={props.label}
        onChange={(time) => {
          if (!defined(time)) {
            setValue("");
            return;
          }

          setValue(time ?? "");
        }}
      ></InfostatTimeInput>
      <FieldButtons
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleDelete={props.deletable ? handleDelete : undefined}
        disableDelete={props.currentValue === null}
        disableSave={props.currentValue === value}
        editing={editing}
        setEditing={setEditing}
        saving={saving}
        setSaving={setSaving}
      ></FieldButtons>
    </div>
  );
}

export function DateField(props: {
  disabled: boolean;
  value: string | null | undefined;
  label: string;
  onChange: (input: string) => void;
}) {
  const value =
    defined(props.value) && nonEmptyString(props.value)
      ? new Date(props.value)
      : undefined;
  const disabled = props.disabled;
  return (
    <InfostatDatePickerControlled
      disabled={disabled}
      value={value}
      onChange={(date) => {
        const dateString = date?.toLocaleDateString();
        props.onChange(dateString ?? "");
      }}
      label={props.label}
    ></InfostatDatePickerControlled>
  );
}

export function OrgAccessEditor(props: {
  organizations: OrganizationResponse;
  handleCancel: () => void;
  handleSave: (orgIds: string[]) => Promise<HttpResult<unknown>>;
  selected: string[];
}) {
  const { value, setValue, saving, handleSave } = useFieldState(
    props.selected,
    props.handleSave
  );
  return (
    <div className="editable-field org-access">
      <Dropdown
        label={ORG_ACCESS_FIELD_LABEL}
        multiSelect
        selectedKeys={value}
        options={props.organizations.map((org) => ({
          key: org.id,
          text: org.name,
        }))}
        onChange={(e, option) => {
          if (!defined(option)) {
            return;
          }
          if (option.selected) {
            setValue([...value, option.key as string]);
          } else {
            setValue(value.filter((id) => id !== (option.key as string)));
          }
        }}
      ></Dropdown>
      <Button
        onClick={props.handleCancel}
        disabled={saving}
        title="Avbryt"
      ></Button>
      <Button
        intent="primary"
        onClick={handleSave}
        disabled={saving}
        title={saving ? "Sparar..." : "Spara"}
      ></Button>
    </div>
  );
}
