import ReactQuill from "react-quill";
import { DeltaStatic } from "quill";
import "react-quill/dist/quill.snow.css";

import { classNames } from "../lib/core/classNames";
import { QuillDocState } from "../lib/application/state/stats/document-core/core";

interface Props {
  initialValue?: QuillDocState;
  className?: string;
  quillRef: React.RefObject<ReactQuill>;
  onChange: (contents: QuillDocState) => void;
}
export function Quill(props: Props) {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline"],
      [{ font: [] }, { color: [] }, { background: [] }, { align: [] }],
      [
        { list: "bullet" },
        { list: "ordered" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  return (
    <ReactQuill
      ref={props.quillRef}
      className={classNames("quill-container", props.className)}
      modules={modules}
      theme="snow"
      placeholder="Din text här..."
      defaultValue={props.initialValue as DeltaStatic}
      onChange={(content, delta, source, editor) => {
        props.onChange(editor.getContents());
      }}
    ></ReactQuill>
  );
}

export function QuillReadonly(props: {
  quillRef: React.RefObject<ReactQuill>;
  content?: QuillDocState;
  className?: string;
}) {
  return (
    <ReactQuill
      ref={props.quillRef}
      className={classNames("quill-container read-only", props.className)}
      modules={{ toolbar: [] }}
      readOnly={true}
      placeholder="Din text här..."
      value={props.content as DeltaStatic}
    ></ReactQuill>
  );
}
