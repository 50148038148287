export enum Progress {
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Error = "Error",
  Success = "Success",
}

export type ExtendedProgress<T, U = never> =
  | {
      type: Progress.NotStarted;
      info: U;
    }
  | {
      type: Progress.InProgress;
    }
  | {
      type: Progress.Error;
      error: T;
    }
  | {
      type: Progress.Success;
    };
