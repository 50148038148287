import * as _ from "lodash";
import {
  RANGE_TICKS_PADDING_PIXELS,
  RANGE_TICKS_SIZE_PIXELS,
} from "../charts_common";

import {
  calculateTextHeight,
  calculateTextWidth,
} from "./text_containers/measure";
import { TextStyle } from "./TextStyle";
import { LABEL_FONTS } from "./definitions";

export class TicksStyle {
  constructor(
    private _textStyle: TextStyle,
    /**
     * Size of tick mark
     */
    private _tickSize: number,
    /**
     * Padding between tick mark and text
     */
    private _labelPadding: number
  ) {}

  get textStyle(): TextStyle {
    return this._textStyle;
  }

  get tickSize(): number {
    return this._tickSize;
  }

  get labelPadding(): number {
    return this._labelPadding;
  }

  /**
   * Length of tick mark + padding
   */
  get totalSize(): number {
    return this._tickSize + this._labelPadding;
  }
}

export function defaultTicksStyle(labelSize?: number): TicksStyle {
  return new TicksStyle(
    new TextStyle(labelSize ?? 12, { fontFamily: LABEL_FONTS }),
    RANGE_TICKS_SIZE_PIXELS,
    RANGE_TICKS_PADDING_PIXELS
  );
}

export interface TickLabel {
  text: string;
  offset: number;
}

export interface TickLabelWithRawValue extends TickLabel {
  value: number;
}

export class XTicksContainer {
  private _totalHeight: number;
  private _maxLineHeight: number;
  constructor(private _labels: TickLabel[], private _ticksStyle: TicksStyle) {
    const maxHeight =
      _.max(
        this._labels.map((l) =>
          calculateTextHeight(l.text, _ticksStyle.textStyle)
        )
      ) ?? 0;
    this._maxLineHeight = maxHeight;
    this._totalHeight =
      _ticksStyle.tickSize + _ticksStyle.labelPadding + maxHeight;
  }

  get totalHeight(): number {
    return this._totalHeight;
  }

  get textStyle(): TextStyle {
    return this._ticksStyle.textStyle;
  }

  get ticks(): Array<{
    text: string;
    xOffset: number;
    textOffsetY: number;
    tickSize: number;
  }> {
    return this._labels.map((l) => ({
      text: l.text,
      xOffset: l.offset,
      textOffsetY: this._ticksStyle.tickSize + this._ticksStyle.labelPadding,
      tickSize: this._ticksStyle.tickSize,
    }));
  }
}

export class YTicksContainer {
  private _totalWidth: number;
  constructor(private _labels: TickLabel[], private _ticksStyle: TicksStyle) {
    const maxWidth =
      _.max(
        _labels.map((l) => calculateTextWidth(l.text, _ticksStyle.textStyle))
      ) ?? 0;

    this._totalWidth =
      maxWidth + _ticksStyle.tickSize + _ticksStyle.labelPadding;
  }

  get totalWidth(): number {
    return this._totalWidth;
  }

  get textStyle(): TextStyle {
    return this._ticksStyle.textStyle;
  }

  get ticks(): Array<{
    text: string;
    yOffset: number;
    labelPadding: number;
    tickSize: number;
  }> {
    return this._labels.map((l) => ({
      text: l.text,
      yOffset: l.offset,
      tickSize: this._ticksStyle.tickSize,
      labelPadding: this._ticksStyle.labelPadding,
    }));
  }
}
