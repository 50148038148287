import * as _ from "lodash";
import { geoPath, geoMercator, geoClipRectangle, geoBounds } from "d3-geo";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { getRelativeEventPointerPosition } from "../../../lib/application/browser/pointer";
import {
  DrawableGeodata,
  BackgroundMapData,
  SingleMapData,
  StylingInfo,
  ScaleInfo,
  displayNumericalRange,
} from "../../../lib/application/stats/map/types";
import {
  Dimension,
  GOLDEN_RATIO,
  MainChartDimensions,
} from "../../../lib/application/stats/shared/core/definitions";
import {
  getDefaultTextStyle,
  getDefaultTextStyleChartValueLabel,
  TextStyle,
} from "../../../lib/application/stats/shared/core/TextStyle";
import {
  MultilineText,
  TextRow,
  TextRowSet,
} from "../../../lib/application/stats/shared/core/text_containers";
import { calculateTextWidth } from "../../../lib/application/stats/shared/core/text_containers/measure";
import {
  translate,
  translatePosition,
} from "../../../lib/application/stats/svg";
import { defined } from "../../../lib/core/defined";
import {
  Position2D,
  Position2DOrigin,
  subtractPosition2D,
} from "../../../lib/core/space/position";
import { SvgWrapper } from "./SvgWrapper";
import { ChartTitle } from "./ChartTitle";
import { DataDescriptionRegular } from "../../../lib/application/stats/shared/DataDescription";
import { calculcateTitleRows } from "../../../lib/application/stats/shared/texts";
import { ChartSource } from "./ChartSource";
import { MISSING_VALUE_FILL_VALUE } from "../../../lib/application/stats/datasets/MapDataset";
import { SimpleCache } from "../../../lib/core/SimpleCache";
import { useUpdateSvgThumbnail } from "../../../lib/application/hooks/thumbnails";
import { PrintDummy } from "../../print/PrintDummy";
import { logger } from "../../../lib/infra/logging";

import "./MapMultiChart.scss";
import { DataOutputSettings } from "../../../lib/application/state/stats/document-core/DataOutputSettings";

const USABLE_AREA_PADDING = 20;
const MAP_INNER_PADDING_X = USABLE_AREA_PADDING * 2;
const MAP_INNER_PADDING_Y = USABLE_AREA_PADDING * 2;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ValueFormatter = (value: any) => string;
type ColorType =
  | {
      type: "code";
      code: string;
    }
  | {
      type: "missing";
    };

type ScaleSpec = {
  color: ColorType;
  text: TextRow;
}[];

const SVG_SIDE_PADDING = 10;
const LEGEND_COLOR_BOX_SIDE = 20;
const LEGEND_COLOR_BOX_SPACE_WITH_PADDING = LEGEND_COLOR_BOX_SIDE + 10;
const LEGEND_MARGIN_LEFT = 20;

interface Props {
  cardId: string;
  settings: DataOutputSettings;
  source: TextRowSet;
  dataDescription: DataDescriptionRegular;
  drawableGeoData: DrawableGeodata;
  valueFormatter: ValueFormatter;
  background: BackgroundMapData;
  maxSvgWidth: number;
}
export function MapMultiChart(props: Props) {
  const { drawableGeoData, background, maxSvgWidth, dataDescription } = props;

  const svgContainerRef = useRef<null | SVGSVGElement>(null);
  const handleUpdate = useUpdateSvgThumbnail(svgContainerRef, props.cardId);

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate, props.drawableGeoData]);

  const geoCache = useRef<null | SimpleCache<string | null>>();
  useEffect(() => {
    geoCache.current = new SimpleCache(300);
    return () => {
      geoCache.current?.clear();
    };
  });

  const bboxedCollection = useMemo(
    () => featuresToExtendedBboxCollection(drawableGeoData),
    [drawableGeoData]
  );

  const numCharts = drawableGeoData.data.length;
  const { chartWidth, chartHeight, numChartsPerRow } =
    calcSingleChartDimensions(
      maxSvgWidth,
      geoBounds(bboxedCollection),
      numCharts,
      props.settings.mapChart.mapSize
    );

  const numChartsFirstRow = Math.min(numCharts, numChartsPerRow);
  const hasMissingData = drawableGeoData.data.some((d) => d.hasMissingData);

  const scaleSpec = getScaleSpec(
    drawableGeoData.style.scaleInfo,
    hasMissingData
  );
  const { maxHeight: maxLabelHeight, sets: labelSets } = getMapLabelSets(
    drawableGeoData,
    chartWidth
  );
  const marginRight = calcMarginRight(scaleSpec);
  const marginLeft = SVG_SIDE_PADDING;
  const titleMarginRight = 10;
  const maxTitleWidth =
    Math.min(
      numChartsFirstRow * (chartWidth + USABLE_AREA_PADDING) +
        marginRight -
        marginLeft,
      maxSvgWidth
    ) - titleMarginRight;
  const titleRows = calculcateTitleRows(
    dataDescription,
    maxTitleWidth,
    props.settings
  );
  const dimensions = calcMapMultiDimensions(
    marginRight,
    marginLeft,
    scaleSpec,
    titleRows,
    props.source,
    maxLabelHeight,
    numCharts,
    numChartsPerRow,
    chartWidth,
    chartHeight
  );

  const pathGenerator = useMemo(() => {
    const projection = geoMercator()
      .fitExtent(
        [
          [USABLE_AREA_PADDING, USABLE_AREA_PADDING],
          [chartWidth - USABLE_AREA_PADDING, chartHeight - USABLE_AREA_PADDING],
        ],
        bboxedCollection
      )
      .postclip(geoClipRectangle(0, 0, chartWidth, chartHeight));
    const pathGenerator = geoPath().projection(projection);
    return pathGenerator;
  }, [chartWidth, chartHeight, bboxedCollection]);

  const cachedPathGenerator = useCallback(
    (featureId: string, path: any) => {
      const cache = geoCache.current;
      if (defined(cache)) {
        const cachedValue = cache.get(featureId);
        if (defined(cachedValue)) {
          return cachedValue;
        }
      }
      const generatedValue = pathGenerator(path);
      cache?.set(featureId, generatedValue);
      return generatedValue;
    },
    [pathGenerator]
  );

  const commonProps = {
    valueFormatter: props.valueFormatter,
    containerRef: svgContainerRef,
    mapWidth: chartWidth,
    mapHeight: chartHeight,
    background: background,
    pathGen: pathGenerator,
    pathGenerator: cachedPathGenerator,
    style: drawableGeoData.style,
  };

  return (
    <div className="map-multi-chart svg-chart-container">
      <SvgWrapper
        className="stats-maps-svg-wrapper"
        width={dimensions.fullWidth}
        height={dimensions.fullHeight}
        viewBox={`0 0 ${dimensions.fullWidth} ${dimensions.fullHeight}`}
        containerRef={svgContainerRef}
        cardId={props.cardId}
      >
        <g transform={translate(dimensions.marginLeft, 0)}>
          <ChartTitle
            shiftOneLineDown={false}
            position={Position2DOrigin}
            titleRowSet={titleRows}
          />
          <Legend
            scaleSpec={scaleSpec}
            position={dimensions.legendPosition}
          ></Legend>
          {
            <>
              {drawableGeoData.data.map((singleMapData, index) => {
                const rowIndex = Math.floor(index / numChartsPerRow);
                const colIndex = index % numChartsPerRow;
                const mapPosition: Position2D = {
                  x: colIndex * (chartWidth + MAP_INNER_PADDING_X),
                  y:
                    dimensions.marginTop +
                    (chartHeight + MAP_INNER_PADDING_Y) * rowIndex +
                    (rowIndex + 1) * maxLabelHeight,
                };
                return (
                  <SingleMap
                    {...commonProps}
                    settings={props.settings}
                    labelSet={labelSets[index]}
                    key={singleMapData.key}
                    mapPosition={mapPosition}
                    singleMapData={singleMapData}
                  ></SingleMap>
                );
              })}
            </>
          }
          <ChartSource
            position={{
              x: 0,
              y:
                dimensions.fullHeight -
                dimensions.marginBottom +
                (props.source.first?.paddingTop ?? 0),
            }}
            source={props.source}
          ></ChartSource>
        </g>
      </SvgWrapper>
      <PrintDummy></PrintDummy>
    </div>
  );
}

interface SingleMapProps {
  containerRef: React.MutableRefObject<SVGSVGElement | null>;
  valueFormatter: ValueFormatter;
  settings: DataOutputSettings;
  labelSet: TextRowSet;
  /** Map position relative to SVG */
  mapPosition: Position2D;
  mapWidth: number;
  mapHeight: number;
  background: BackgroundMapData;
  singleMapData: SingleMapData;
  style: StylingInfo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pathGen: (path: any) => string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pathGenerator: (featureId: string, path: any) => string | null;
}

function SingleMap(props: SingleMapProps) {
  const {
    background,
    pathGenerator,
    singleMapData,
    style,
    labelSet,
    settings,
  } = props;
  const featureToStyle = style.featureToStyle;

  const [hoverState, setHoverState] = useState<{
    position: Position2D;
    name: string;
    value: string;
    boxAnchorX?: "right";
  }>();

  return (
    <g transform={translatePosition(props.mapPosition)}>
      <g transform={translate(0, -labelSet.totalHeight)}>
        {labelSet.offsetRows.map((row) => {
          return (
            <text
              key={row.text}
              transform={translate(0, row.offsetY + row.paddingTop)}
              {...row.textStyle.svgFontAttrs()}
            >
              {row.text}
            </text>
          );
        })}
      </g>
      <path
        d={
          pathGenerator(background.key, background.featuresCollection) ??
          undefined
        }
        fill={background.style.fillColor}
        stroke={background.style.fillColor}
        strokeWidth={background.style.weight}
      />

      {singleMapData.featureCollection.features.map((f) => {
        const featureStyle = featureToStyle(f);
        const name = f.properties?.["name"] ?? "";
        return (
          <path
            key={f.id}
            d={pathGenerator(f.id as string, f) ?? undefined}
            fill={featureStyle.fillColor}
            stroke={
              settings.mapChart.showAdministrativeBorders
                ? featureStyle.color
                : undefined
            }
            onMouseEnter={(event) => {
              const svgArea = props.containerRef?.current;
              if (!defined(svgArea)) {
                return;
              }
              const positionRelativeToSvg = getRelativeEventPointerPosition(
                svgArea,
                event
              );
              const position = subtractPosition2D(
                positionRelativeToSvg,
                props.mapPosition
              );
              let adjustedPosition = { ...position };
              const boxAnchorX =
                position.x > props.mapWidth / 2 ? "right" : undefined;

              setHoverState({
                position: adjustedPosition,
                name: name,
                value: props.valueFormatter(
                  f.properties?.[Dimension.value] ?? ""
                ),
                boxAnchorX,
              });
            }}
            onMouseOut={() => setHoverState(undefined)}
            {...(defined(hoverState) && hoverState.name === name
              ? { fillOpacity: 0.8, strokeWidth: 2 }
              : {
                  fillOpacity: featureStyle.fillOpacity,
                  strokeWidth: featureStyle.weight,
                })}
          ></path>
        );
      })}
      {defined(hoverState) && (
        <TextBox key={hoverState.position.x + ""} {...hoverState}></TextBox>
      )}
    </g>
  );
}

function getScaleSpec(
  scaleInfo: ScaleInfo,
  hasMissingData: boolean
): ScaleSpec {
  const grades: { color: ColorType; text: string }[] =
    scaleInfo.type === "sequential"
      ? scaleInfo.ranges.map((r) => {
          if (r.min === r.max) {
            return {
              color: { type: "code", code: r.color },
              text: displayNumericalRange(r),
            };
          }
          return {
            color: { type: "code", code: r.color },
            text: displayNumericalRange(r),
          };
        })
      : scaleInfo.categories.map((c) => ({
          color: { type: "code", code: c.color },
          text: c.label,
        }));

  if (hasMissingData) {
    grades.push({ color: { type: "missing" }, text: "Data saknas" });
  }

  return grades.map((grade) => ({
    color: grade.color,
    text: new TextRow(grade.text, getDefaultTextStyleChartValueLabel(), 10),
  }));
}

function Legend(props: { scaleSpec: ScaleSpec; position: Position2D }) {
  let yOffset = 0;
  return (
    <g transform={translatePosition(props.position)}>
      {props.scaleSpec.map((s) => {
        const currentOffset = yOffset;
        yOffset += s.text.totalHeight;
        const commonRectProps = {
          width: LEGEND_COLOR_BOX_SIDE,
          height: LEGEND_COLOR_BOX_SIDE,
          stroke: "grey",
        };
        return (
          <g
            key={
              s.text.text +
              "_" +
              (s.color.type === "code" ? s.color.code : s.color.type)
            }
            transform={translate(0, currentOffset)}
          >
            {s.color.type === "code" ? (
              <rect {...commonRectProps} fill={s.color.code}></rect>
            ) : (
              <rect {...commonRectProps} fill={MISSING_VALUE_FILL_VALUE}></rect>
            )}
            <text
              dx={LEGEND_COLOR_BOX_SPACE_WITH_PADDING}
              dy="1.2em"
              {...s.text.textStyle.svgFontAttrs()}
            >
              {s.text.text}
            </text>
          </g>
        );
      })}
    </g>
  );
}

interface TextBoxProps {
  position: Position2D;
  name: string;
  value: string;
  boxAnchorX?: "right";
}

function TextBox(props: TextBoxProps) {
  const { name, value, position } = props;
  const headerStyle = new TextStyle(12);
  const valueStyle = new TextStyle(12);
  const nameWidth = calculateTextWidth(name, headerStyle);
  const valueWidth = calculateTextWidth(value, valueStyle);
  const padding = 10;
  const textWidth = Math.max(nameWidth, valueWidth);
  const boxWidth = textWidth + padding * 2;

  const nameRow = new TextRow(name, headerStyle);
  const valueRow = new TextRow(value, valueStyle);
  const textRows = new TextRowSet([nameRow, valueRow], 5);

  const adjustedPosition =
    props.boxAnchorX === "right"
      ? subtractPosition2D(position, { x: boxWidth + 10, y: 0 })
      : position;
  return (
    <g transform={translatePosition(adjustedPosition)}>
      <rect
        width={boxWidth}
        height={textRows.totalHeight}
        fill="white"
        opacity="0.5"
      ></rect>
      {textRows.offsetRows.map((row) => (
        <text
          key={row.text}
          textAnchor="end"
          transform={translate(
            textWidth + padding,
            row.offsetY + row.paddingTop + row.rowHeightWithPadding
          )}
          {...row.textStyle.svgFontAttrs()}
        >
          {row.text}
        </text>
      ))}
    </g>
  );
}

function calcMarginRight(scaleSpec: ScaleSpec): number {
  const scaleWidth =
    Math.max(...scaleSpec.map((s) => s.text.width)) +
    LEGEND_COLOR_BOX_SPACE_WITH_PADDING;
  return Math.ceil(scaleWidth) + LEGEND_MARGIN_LEFT + SVG_SIDE_PADDING;
}

function calcMapMultiDimensions(
  marginRight: number,
  marginLeft: number,
  scaleSpec: ScaleSpec,
  titleRows: TextRowSet,
  sourceRows: TextRowSet,
  maxLabelHeight: number,
  numCharts: number,
  numChartsPerRow: number,
  chartWidth: number,
  chartHeight: number
): MainChartDimensions & { legendPosition: Position2D } {
  const maxNumChartsPerRow = Math.min(numCharts, numChartsPerRow);
  const numChartRows = Math.ceil(numCharts / maxNumChartsPerRow);

  const scaleHeight = _.sum(scaleSpec.map((s) => s.text.totalHeight)) ?? 0;

  const marginTop = titleRows.totalHeight + 20;
  const marginBottom = sourceRows.totalHeight;
  const chartHeightWithLabelSet = chartHeight + maxLabelHeight;

  const boundedHeight = Math.max(
    scaleHeight,
    chartHeightWithLabelSet * numChartRows +
      (numChartRows - 1) * MAP_INNER_PADDING_Y
  );
  const boundedWidth =
    chartWidth * maxNumChartsPerRow +
    (maxNumChartsPerRow - 1) * MAP_INNER_PADDING_X;

  const legendPosition = {
    x: boundedWidth + LEGEND_MARGIN_LEFT,
    y:
      marginTop +
      maxLabelHeight +
      (scaleHeight > chartHeight ? 0 : chartHeight / 2 - scaleHeight / 2),
  };

  return {
    legendPosition,
    boundedHeight,
    fullHeight: boundedHeight + marginTop + marginBottom,
    boundedWidth,
    fullWidth: marginLeft + boundedWidth + marginRight,
    marginBottom,
    marginLeft,
    marginTop,
    marginRight,
  };
}

function featuresToExtendedBboxCollection(
  geoData: DrawableGeodata
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): GeoJSON.FeatureCollection<any, any> {
  const f: GeoJSON.Feature[] = _.flatMap(geoData.data, (d) => {
    return d.featureCollection.features.map((f) => {
      return {
        id: f.id,
        properties: { ...f.properties },
        type: "Feature",
        geometry: { ...f.geometry },
      } as GeoJSON.Feature;
    });
  });

  const res = {
    type: "FeatureCollection",
    features: f,
  } as const;
  return res;
}

function getMapLabelSets(
  geodata: DrawableGeodata,
  maxLabelWidth: number
): { maxHeight: number; sets: TextRowSet[] } {
  const mapData: SingleMapData[] = geodata.data;
  const sets = mapData.map((data) => {
    const multilineTexts = data.labelTexts.map(
      (t, labelIndex) =>
        new MultilineText(t, getDefaultTextStyle(), {
          desiredMaxWidth: maxLabelWidth,
          boxPaddingTop: labelIndex === 0 ? 10 : 0,
        })
    );
    return TextRowSet.fromMixedText(multilineTexts);
  });
  const maxHeight = Math.max(...sets.map((s) => s.totalHeight));
  return { maxHeight, sets };
}

function calcSingleChartDimensions(
  maxSvgWidth: number,
  bbox: [[number, number], [number, number]],
  numCharts: number,
  chartSize?: string
) {
  let numChartsPerRow = 1;
  let chartWidth: number;
  let chartHeight: number;
  switch (chartSize) {
    case "small":
      chartWidth = Math.floor(maxSvgWidth / 4);
      chartHeight = GOLDEN_RATIO * chartWidth;
      numChartsPerRow = 2;
      break;
    case "medium":
      chartWidth = Math.floor(maxSvgWidth / 2.5);
      chartHeight = GOLDEN_RATIO * chartWidth;
      numChartsPerRow = 1;
      break;
    case "large":
      chartWidth = Math.floor(maxSvgWidth / 2);
      chartHeight = GOLDEN_RATIO * chartWidth;
      numChartsPerRow = 1;
      break;
    default:
      if (defined(chartSize)) {
        logger.error(
          "Unknown map chart size: " + chartSize + ". Using small size."
        );
      }
      chartWidth = Math.floor(maxSvgWidth / 4);
      chartHeight = GOLDEN_RATIO * chartWidth;
      numChartsPerRow = 2;
  }

  const [sw, ne] = bbox;
  const [west, south] = sw;
  const [east, north] = ne;
  if (east - west > north - south) {
    return {
      chartWidth: chartHeight,
      chartHeight: chartWidth,
      numChartsPerRow,
    };
  }
  return { chartWidth, chartHeight, numChartsPerRow };
}
