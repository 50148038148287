import { Checkbox, Dropdown, TextField } from "@fluentui/react";
import { sortBy } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { AlertBox } from "../../../../../components/AlertBox";
import {
  Button,
  ButtonWithSideSpinner,
} from "../../../../../components/Button";
import {
  ButtonsFooter,
  ButtonsFooterLeft,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";
import { DefaultLoading } from "../../../../../components/Loading";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import { EmbedSettings } from "../../../../../lib/application/embedded_cards";
import { useToggle } from "../../../../../lib/application/hooks/useToggle";
import {
  getMeasureOrAliasMetadata,
  getOrganizationsWithCache,
} from "../../../../../lib/application/requests/admin/common_requests_admin";
import { createEmbeddedDocument } from "../../../../../lib/application/requests/admin/embed";
import { useGetAllCardsCallback } from "../../../../../lib/application/state/actions/cardCallbacks";
import { DataOutputView } from "../../../../../lib/application/state/stats/document-core/core";
import { docCardsListQuery } from "../../../../../lib/application/state/stats/document-core/docCardsListState";
import { stateToWorkspaceLatest } from "../../../../../lib/application/state/stats/workspace/shared";
import { validateNonEmptyString } from "../../../../../lib/application/validation/string";
import { defined } from "../../../../../lib/core/defined";
import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import { CreateCardEmbedResultDto } from "../../../../../lib/infra/api_responses/document_embed";
import { logger } from "../../../../../lib/infra/logging";
import { DataCardEmbed } from "../embedded_card/DataCardEmbed";
import { GeographiesContext } from "../../../../../lib/application/contexts";
import { copyToClipboard } from "../../../../../lib/application/browser/copyToClipboard";

import "./CreateCardEmbedModal.scss";
import { adminApi } from "../../../../../lib/application/requests/admin/admin_api";
import { statsApiV2 } from "../../../../../lib/application/requests/statsApiV2";
import { FoldoutPanel } from "../../../../../components/FoldoutPanel";

interface Organization {
  id: string;
  name: string;
}

function validateDomainWithoutProtocol(domain: string) {
  return domain.startsWith("http://") || domain.startsWith("https://")
    ? "Ange domänen utan http:// eller https://"
    : undefined;
}

export function CreateCardEmbedModal(props: {
  cardId: string;
  onClose: () => void;
}) {
  const [domain, setDomain] = useState("");
  const [domainError, setDomainError] = useState<string | undefined>(undefined);
  const [embedLabel, setEmbedLabel] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<string | undefined>();
  const [createEmbedResult, setCreateEmbedResult] =
    useState<CreateCardEmbedResultDto>();
  const [copied, setCopied] = useState(false);

  const [showGeo, toggleShowGeo] = useToggle(false);
  const [showTimeline, toggleShowTimeline] = useToggle(false);
  const [showBreakdowns, toggleShowBreakdowns] = useToggle(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showToolbar, toggleShowToolbar, setShowToolbar] = useToggle(false);
  const [
    showToolbarSettings,
    toggleShowToolbarSettings,
    setShowToolbarSettings,
  ] = useToggle(false);
  const [
    showToolbarDownload,
    toggleShowToolbarDownload,
    setShowToolbarDownload,
  ] = useToggle(false);

  const [hideChart, toggleHideChart] = useToggle(false);
  const [hideTable, toggleHideTable] = useToggle(false);
  const [hideMap, toggleHideMap] = useToggle(false);
  const [hideInfo, toggleHideInfo] = useToggle(false);

  const handleToggleShowToolbar = useCallback(() => {
    if (showToolbar) {
      setShowToolbarSettings(false);
      setShowToolbarDownload(false);
    }
    setShowToolbar(!showToolbar);
  }, [
    setShowToolbar,
    setShowToolbarDownload,
    setShowToolbarSettings,
    showToolbar,
  ]);

  const embedSettings: EmbedSettings = useMemo(() => {
    const hiddenViews: DataOutputView[] = [
      hideChart ? ("diagram" as const) : undefined,
      hideTable ? ("table" as const) : undefined,
      hideMap ? ("map" as const) : undefined,
      hideInfo ? ("info" as const) : undefined,
    ].filter(defined);
    return {
      showBreakdowns,
      showGeoSelector: showGeo,
      showTimeline,
      showToolbar,
      showToolbarSettings,
      showToolbarDownload,
      hideTabs: hiddenViews,
    };
  }, [
    hideChart,
    hideInfo,
    hideMap,
    hideTable,
    showBreakdowns,
    showGeo,
    showTimeline,
    showToolbar,
    showToolbarDownload,
    showToolbarSettings,
  ]);

  const [organizations, setOrganizations] = useState<Organization[]>();

  const cardsList = useRecoilValue(docCardsListQuery);
  const getAllCardsCallback = useGetAllCardsCallback(cardsList);
  const geographies = useContext(GeographiesContext);

  useEffect(() => {
    getOrganizationsWithCache().then((orgs) =>
      setOrganizations(sortBy(orgs, (o) => o.name))
    );
  }, []);

  const parsedDomains = useMemo(() => {
    return domain
      .split(/[\s,]+/)
      .filter((d) => d.trim())
      .filter(nonEmptyString);
  }, [domain]);

  const validateDomain = useCallback(() => {
    const err = validateNonEmptyString(domain);
    if (defined(err)) {
      return setDomainError(err);
    }

    const protocolError = parsedDomains
      .map(validateDomainWithoutProtocol)
      .find(defined);
    if (defined(protocolError)) {
      setDomainError(protocolError);
    }
    return protocolError;
  }, [domain, parsedDomains]);

  const validateOrg = useCallback(() => {
    if (!defined(selectedOrg)) {
      return "Välj en organisation";
    }
  }, [selectedOrg]);

  const validateEmbedLabel = useCallback(() => {
    return validateNonEmptyString(embedLabel);
  }, [embedLabel]);

  const validate = useCallback(() => {
    const err = validateDomain() || validateOrg() || validateEmbedLabel();
    if (defined(err)) {
      logger.warn("Validation error", err);
      return false;
    }
    return true;
  }, [validateDomain, validateEmbedLabel, validateOrg]);

  const handleSubmit = useCallback(() => {
    if (!validate()) {
      return;
    }

    const currentCard = getAllCardsCallback().find(
      (c) => c.id === props.cardId
    );
    if (!defined(currentCard)) {
      return logger.error("Could not find card with id", props.cardId);
    }
    if (currentCard.type !== "dataCard") {
      return logger.error("Card is not a data card", currentCard);
    }
    if (!defined(selectedOrg)) {
      return logger.error("No organization selected");
    }
    const dataId =
      currentCard.data.dataSelections[0].measureSelection?.measure.data_id;
    if (!defined(dataId)) {
      return logger.error("Could not find data id for card", currentCard);
    }
    getMeasureOrAliasMetadata(dataId).then((metadata) => {
      if (metadata.value_type === "alias") {
        throw new Error("unexpected value_type === alias");
      }
      const allowedOrgs = metadata.organization_access_restrictions;
      if (
        allowedOrgs !== null &&
        allowedOrgs.every((org) => selectedOrg !== org)
      ) {
        return window.alert(
          "Vald organisation har inte tillgång till denna data."
        );
      }

      const workspace = stateToWorkspaceLatest([currentCard], {
        editModeOn: false,
      });

      setIsLoading(true);
      return createEmbeddedDocument(
        selectedOrg,
        embedLabel,
        dataId,
        parsedDomains,
        embedSettings,
        workspace
      )
        .then((res) => {
          res.match({
            ok: (r) => {
              setCreateEmbedResult(r);
              setCopied(false);
            },
            err: (e) => {
              logger.error("Failed to create embed", e);
              alert("Något gick fel: " + e);
            },
          });
        })
        .finally(() => setIsLoading(false));
    });
  }, [
    embedLabel,
    embedSettings,
    getAllCardsCallback,
    parsedDomains,
    props.cardId,
    selectedOrg,
    validate,
  ]);

  if (!defined(organizations) || !defined(geographies)) {
    return <DefaultLoading></DefaultLoading>;
  }

  const hasResult = defined(createEmbedResult);
  const commonElementProps = {
    disabled: hasResult,
    className: "element",
  };

  const embedCode = `<iframe class="iframe-${createEmbedResult?.key}"
  style="border: 1px solid lightgray; width: 100%;" height=700 title="Statistik från Infostat"
  src="${window.location.origin}/stats/embed/${createEmbedResult?.key}"></iframe>`;

  return (
    <FluentModal
      width="md"
      containerClassName="create-card-embed-modal"
      title="Skapa inbäddningsbart kort"
      isOpen={true}
      onClose={props.onClose}
    >
      <FluentModalBody>
        <section>
          <Dropdown
            id="select-org-for-embed"
            disabled={hasResult}
            required
            label="Organisation"
            className="element"
            options={organizations.map((o) => ({ key: o.id, text: o.name }))}
            selectedKey={selectedOrg}
            onChange={(event, option) => {
              if (defined(option)) {
                setSelectedOrg(option.key as string);
              }
            }}
          ></Dropdown>
          <TextField
            id="publication-domains-for-embed"
            disabled={hasResult}
            autoFocus
            required
            className="element"
            label="Domäner för publicering (separera med blanksteg eller komma)"
            placeholder="1.example.com 2.example.com"
            value={domain}
            onChange={(event) => {
              setDomain(event.currentTarget.value);
            }}
            errorMessage={domainError}
          ></TextField>
          <TextField
            id="label-for-embed"
            disabled={hasResult}
            required
            label="Etikett (för intern användning, visas ej)"
            placeholder="Arbetkostnader, Företaget AB"
            className="element"
            value={embedLabel}
            onChange={(event) => {
              setEmbedLabel(event.currentTarget.value);
            }}
          ></TextField>
        </section>

        <section>
          <Checkbox
            {...commonElementProps}
            checked={showGeo}
            label="Visa geoväljare"
            onChange={toggleShowGeo}
          ></Checkbox>

          <Checkbox
            {...commonElementProps}
            checked={showTimeline}
            label="Visa tidslinje"
            onChange={toggleShowTimeline}
          ></Checkbox>
          <Checkbox
            {...commonElementProps}
            checked={showBreakdowns}
            label="Visa nedbrytningar"
            onChange={toggleShowBreakdowns}
          ></Checkbox>
        </section>

        <section>
          <Checkbox
            {...commonElementProps}
            checked={showToolbar}
            label="Visa verktygsfält"
            onChange={handleToggleShowToolbar}
          ></Checkbox>
          <div className="indent">
            <Checkbox
              {...commonElementProps}
              disabled={!showToolbar}
              checked={showToolbarSettings}
              label="Visa verktygsfältets inställningar"
              onChange={toggleShowToolbarSettings}
            ></Checkbox>
            <Checkbox
              {...commonElementProps}
              disabled={!showToolbar}
              checked={showToolbarDownload}
              label="Visa ladda ner"
              onChange={toggleShowToolbarDownload}
            ></Checkbox>
          </div>
        </section>

        <Checkbox
          {...commonElementProps}
          checked={hideChart}
          label="Dölj diagram"
          onChange={toggleHideChart}
        ></Checkbox>
        <Checkbox
          {...commonElementProps}
          checked={hideTable}
          label="Dölj tabell"
          onChange={toggleHideTable}
        ></Checkbox>
        <Checkbox
          {...commonElementProps}
          checked={hideMap}
          label="Dölj karta"
          onChange={toggleHideMap}
        ></Checkbox>
        <Checkbox
          {...commonElementProps}
          checked={hideInfo}
          label="Dölj info"
          onChange={toggleHideInfo}
        ></Checkbox>

        <>
          {hasResult && (
            <AlertBox intent="success" className="element">
              <p>
                Skapade inbäddningsbart kort. Använd koden nedan för att bädda
                in.{" "}
                <button
                  onClick={() => {
                    setCopied(true);
                    copyToClipboard(embedCode);
                  }}
                >
                  Kopiera
                </button>
                {copied && <span> Kopierat!</span>}
              </p>

              <code>
                <pre id="iframe-code-snippet">{embedCode}</pre>
              </code>

              <FoldoutPanel title="Autojustera höjd på inbäddningen">
                <p>
                  För att få automatisk anpassning av diagramhöjd så behöver
                  ägaren av webbsidan där inbäddning ska göras lägga in ett
                  stycke JavaScript. Detta skall endast läggas in en gång per
                  sida, oavsett hur många diagram som bäddas in. Bäst är att
                  göra detta sist i head-taggen.
                </p>
                <code>
                  <pre>
                    {`<script>
  window.addEventListener("message", function (event) {
    if (event.data.type === "infostat-resize") {
      var elements = document.getElementsByClassName("iframe-" + event.data.key)
      if (elements.length === 0) {
        return;
      }
      var iframeElement = elements[0];
      iframeElement.height = event.data.height;
      iframeElement.scrolling = "no";
    }
  });
</script>`}
                  </pre>
                </code>
              </FoldoutPanel>
            </AlertBox>
          )}
        </>

        <>
          {!hasResult && (
            <DataCardEmbed
              cardStateId={props.cardId}
              geographies={geographies}
              embedSettings={embedSettings}
              embedKey={""}
            ></DataCardEmbed>
          )}
        </>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterLeft>
            <Button onClick={props.onClose} title="Avbryt"></Button>
          </ButtonsFooterLeft>
          <ButtonsFooterRight>
            <>
              <ButtonWithSideSpinner
                id="create-embed-button"
                disabled={hasResult}
                loading={isLoading}
                intent="primary"
                title="Skapa"
                onClick={handleSubmit}
              ></ButtonWithSideSpinner>
              {hasResult && (
                <Button title="Klar" onClick={props.onClose}></Button>
              )}
            </>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModal>
  );
}
