import { useEffect, useState } from "react";

import { config } from "../../../../../config";
import { defined } from "../../../../../lib/core/defined";
import {
  MicroGeoTree,
  MicroGeoTreeRawRT,
} from "../../../../../lib/application/stats/shared/MicroGeoTree";

let cachedGeoTree: MicroGeoTree | undefined;

export function useGeoTree() {
  const [geoTree, setGeoTree] = useState<MicroGeoTree | undefined>(
    cachedGeoTree
  );

  useEffect(() => {
    if (defined(geoTree)) {
      return;
    }
    if (defined(cachedGeoTree)) {
      if (!defined(geoTree)) {
        setGeoTree(cachedGeoTree);
      }
      return;
    }
    fetchGeoTreeWithCache().then((geoTree) => {
      setGeoTree(geoTree);
    });
  }, [geoTree]);

  return geoTree;
}

export function fetchGeoTreeWithCache(): Promise<MicroGeoTree> {
  if (defined(cachedGeoTree)) {
    return Promise.resolve(cachedGeoTree);
  }

  return fetch(config.geoFiles.microTree).then((res) => {
    return res.json().then((data) => {
      const checkedData = MicroGeoTreeRawRT.check(data);
      cachedGeoTree = new MicroGeoTree(checkedData);
      return cachedGeoTree;
    });
  });
}
