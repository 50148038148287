import { decodeWithLogging } from "../../checks/decode";
import { SharingInfo } from "../../files/SharingInfo";
import { FileNodeInfo, ChildNodeInfo } from "../../files/tree";
import { NodeChildFullDto, NodesDto, NodesDtoRT, SharingInfoDto } from "./dto";

export function parseNodesRootDto(dto: NodesDto): FileNodeInfo {
  if (dto.type === "root") {
    return {
      type: "root",
      children: dto.children?.map(parseNodesChildDto),
    };
  } else {
    return parseNodesChildDto(dto);
  }
}

export function parseNodesChildDto(dto: NodeChildFullDto): ChildNodeInfo {
  return {
    id: dto.id,
    type: dto.type,
    title: dto.title,
    path: dto.path?.map((p) => ({
      nodeId: p.node_id,
      title: p.title,
    })),
    roles: dto.roles,

    isShared: dto.is_shared,
    isFavorite: dto.is_favorite,
    hasShortcut: dto.has_shortcut,
    belongsToHelpLibrary:
      dto.belongs_to_help_library ?? dto.belongs_to_help_lib ?? false,
    belongsToSampleLibrary: dto.belongs_to_sample_library ?? false,

    ownership: dto.ownership,
    lastModified: new Date(dto.last_update),
    children: dto.children?.map((child) => parseNodesChildDto(child)),
  };
}

export function dtoToDomain(dto: unknown): FileNodeInfo {
  return parseNodesRootDto(decodeWithLogging(dto, NodesDtoRT));
}

export function parseNodeSharingInfo(dto: SharingInfoDto) {
  return new SharingInfo(dto);
}
