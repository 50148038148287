import { MainChartDimensions } from "../../../lib/application/stats/shared/core/definitions";

interface Props {
  dims: MainChartDimensions;
  x1: number;
  x2: number;
}
export function BackgroundBandVertical(props: Props) {
  return (
    <rect
      x={props.x1}
      width={props.x2 - props.x1}
      y={0}
      height={props.dims.boundedHeight}
      fill="rgba(200,200,200,0.3)"
    ></rect>
  );
}
