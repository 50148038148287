import mapbox from "mapbox-gl";
import { flatMap } from "lodash";

import { logger } from "../../../../../lib/infra/logging";
import {
  MicroGeoSelections,
  MicroMapSettings,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { GeoTypeMicro } from "../../../../../lib/domain/geography";
import { assertNever } from "../../../../../lib/core/assert";
import { defined } from "../../../../../lib/core/defined";

export const desoSourceLayer = "deso_extended";
export const desoSource = "deso";
export const desoLayerId = "deso-layer";
export const desoBorderLayerId = "deso-border-layer";

export const desoRegsoLabelsLayerId = "deso-regso-labels";
export const desoRegsoLabelsSourceId = "deso-regso-labels-source";

export const regsoSourceLayer = "regso_without_water";
export const regsoSource = "regso";
export const regsoLayerId = "regso-layer";
export const regsoBorderLayerId = "regso-border-layer";

export const SELECTION_DEFAULT_COLOR = "#34abeb";
export const SELECTION_FILL_OPACITY = 0.5;
export const VIZ_COLOR_FILL_OPACITY_DEFAULT = 0.8;

export type ClearGeos = (areas: MicroGeoSelections) => void;

export type HandleUpdateSetting = <T extends keyof MicroMapSettings>(
  key: T,
  value: MicroMapSettings[T]
) => void;

export function mapHideDesoLayer(mapboxMap?: mapbox.Map) {
  mapboxMap?.setLayoutProperty(desoLayerId, "visibility", "none");
  mapboxMap?.setLayoutProperty(desoBorderLayerId, "visibility", "none");
}

export function mapShowDesoLayer(mapboxMap?: mapbox.Map) {
  mapboxMap?.setLayoutProperty(desoLayerId, "visibility", "visible");
  mapboxMap?.setLayoutProperty(desoBorderLayerId, "visibility", "visible");
}

export function mapHideRegsoLayer(mapboxMap?: mapbox.Map) {
  mapboxMap?.setLayoutProperty(regsoLayerId, "visibility", "none");
  mapboxMap?.setLayoutProperty(regsoBorderLayerId, "visibility", "none");
}

export function mapShowRegsoLayer(mapboxMap?: mapbox.Map) {
  mapboxMap?.setLayoutProperty(regsoLayerId, "visibility", "visible");
  mapboxMap?.setLayoutProperty(regsoBorderLayerId, "visibility", "visible");
}

export function mapSetGeoLayerActive(
  map: mapbox.Map | undefined,
  geotype: GeoTypeMicro
) {
  if (!defined(map)) {
    return;
  }
  if (geotype === "deso") {
    mapShowDesoLayer(map);
    mapHideRegsoLayer(map);
  } else {
    mapShowRegsoLayer(map);
    mapHideDesoLayer(map);
  }
}

export function getGeoBorderLayerId(geotype: GeoTypeMicro): string {
  switch (geotype) {
    case "deso":
      return desoBorderLayerId;
    case "regso":
      return regsoBorderLayerId;
  }
  assertNever(geotype);
}

export function getGeoLayerId(geotype: GeoTypeMicro): string {
  switch (geotype) {
    case "deso":
      return desoLayerId;
    case "regso":
      return regsoLayerId;
  }
  assertNever(geotype);
}

function getFeatureIdentifier(id: number | string, geotype: GeoTypeMicro) {
  switch (geotype) {
    case "deso":
      return { id, source: desoSource, sourceLayer: desoSourceLayer };
    case "regso":
      return { id, source: regsoSource, sourceLayer: regsoSourceLayer };
  }
  assertNever(geotype);
}

export function mapSetFeatureSelected(
  map: mapbox.Map,
  geoType: GeoTypeMicro,
  id: number | string
) {
  try {
    map.setFeatureState(getFeatureIdentifier(id, geoType), {
      selected: true,
      color: SELECTION_DEFAULT_COLOR,
      borderColor: undefined,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export function mapSetDesoFeatureColor(
  map: mapbox.Map,
  id: number | string,
  color: string,
  borderColor?: string
) {
  mapSetFeatureColor("deso", map, id, color, borderColor);
}

export function mapSetFeatureColor(
  geotype: GeoTypeMicro,
  map: mapbox.Map,
  id: number | string,
  color: string,
  borderColor?: string
) {
  try {
    switch (geotype) {
      case "deso":
        map.setFeatureState(
          { id, source: desoSource, sourceLayer: desoSourceLayer },
          { selected: true, color, borderColor }
        );
        break;
      case "regso":
        map.setFeatureState(
          { id, source: regsoSource, sourceLayer: regsoSourceLayer },
          { selected: true, color, borderColor }
        );
        break;
      default:
        assertNever(geotype);
    }
  } catch (e) {
    logger.warn("Failed to set feature state", e);
  }
}

export function removeAllFeatureState(
  map: mapbox.Map,
  selections: MicroGeoSelections
) {
  if (selections.type === "deso") {
    const items = flatMap(selections.selected, (item) => item.props);
    for (const item of items) {
      mapRemoveFeatureState("deso", map, item.id);
    }
  } else if (selections.type === "regso") {
    const items = flatMap(selections.selected, (item) => item.props);
    for (const item of items) {
      mapRemoveFeatureState("regso", map, item.id);
    }
  }
}

export function mapRemoveFeatureState(
  geotype: GeoTypeMicro,
  map: mapbox.Map,
  id: number | string
) {
  switch (geotype) {
    case "deso":
      return mapRemoveDesoFeatureState(map, id);
    case "regso":
      return mapRemoveRegsoFeatureState(map, id);
  }
  assertNever(geotype);
}

export function mapRemoveDesoFeatureState(
  map: mapbox.Map,
  id: number | string
) {
  try {
    map.removeFeatureState({
      id,
      source: desoSource,
      sourceLayer: desoSourceLayer,
    });
  } catch (e) {
    logger.warn("Failed to clear feature state", e);
  }
}

export function mapRemoveRegsoFeatureState(
  map: mapbox.Map,
  id: number | string
) {
  try {
    map.removeFeatureState({
      id,
      source: regsoSource,
      sourceLayer: regsoSourceLayer,
    });
  } catch (e) {
    logger.warn("Failed to clear feature state", e);
  }
}

export function mapSetLayerOpacity(
  geotype: GeoTypeMicro,
  map: mapbox.Map,
  opacity: number
) {
  try {
    if (geotype === "deso") {
      return map.setPaintProperty(desoLayerId, "fill-opacity", opacity);
    } else if (geotype === "regso") {
      return map.setPaintProperty(regsoLayerId, "fill-opacity", opacity);
    }
    assertNever(geotype);
  } catch (e) {
    logger.warn("Failed to set paint property", e);
  }
}

export function areaTypesMixed(areas: MicroGeoSelections) {
  if (areas.selected.length === 0) {
    return false;
  }
  const firstType = areas.selected[0].type;
  for (const area of areas.selected.slice(1)) {
    if (area.type !== firstType) {
      return true;
    }
  }
  return false;
}

const defaultColors = ["red", "green", "blue", "yellow", "orange"];
export class ColorBag {
  private _colors: string[];
  constructor() {
    this._colors = defaultColors.slice();
  }

  pick(): string {
    if (this._colors.length === 0) {
      this._colors = defaultColors.slice();
      return this._colors.shift()!;
    }
    return this._colors.shift()!;
  }
}
