import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpErrorPage } from "../../../components/errors/HttpErrorView";
import { DefaultLoading } from "../../../components/Loading";
import {
  createDocument,
  patchNode,
} from "../../../lib/application/requests/docs/documents";
import { useLoadSingleStaticMeasure } from "../../../lib/application/state/actions/load/useLoadSingleStaticMeasure";
import { stateToWorkspaceLatest } from "../../../lib/application/state/stats/workspace/shared";
import { defined } from "../../../lib/core/defined";
import { voidFunc } from "../../../lib/core/voidFunc";
import { GeographiesSerializable } from "../../../lib/domain/geography";
import { HttpError } from "../../../lib/infra/HttpResult";
import { documentPath } from "../../../lib/paths";

interface Props {
  name: string;
  measureId: number;
  geographies: GeographiesSerializable;
}
/**
 * Component that handles creation of a new document from a single measure
 */
export function StatsSingleMeasureNewDoc(props: Props) {
  const [cardResource] = useLoadSingleStaticMeasure(
    props.measureId,
    props.geographies
  );
  const [httpErr, setHttpErr] = useState<HttpError | undefined>(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    cardResource.match({
      ready: (cardRes) => {
        const workspace = stateToWorkspaceLatest([cardRes], {
          editModeOn: true,
          defaultTitle: props.name,
        });
        createDocument(props.name, workspace).then((res) => {
          res.match({
            ok: (docId) => {
              patchNode(docId, { title: props.name, draft: false }).then(
                (res) => {
                  res.match({
                    ok: () => {
                      navigate(documentPath(docId));
                    },
                    err: (err) => {
                      setHttpErr(err);
                    },
                  });
                }
              );
            },
            err: (err) => {
              setHttpErr(err);
            },
          });
        });
      },
      notReady: voidFunc,
    });
  }, [cardResource, navigate, props.name]);

  if (defined(httpErr)) {
    <HttpErrorPage error={httpErr}></HttpErrorPage>;
  }

  if (cardResource.isInProgress()) {
    return <DefaultLoading></DefaultLoading>;
  }

  return null;
}
