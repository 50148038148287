import { Button } from "../../../components/Button";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../components/Modal";

export function SubscriptionInfoDialog(props: { onClose: () => void }) {
  return (
    <div>
      <FluentModal title="Bevakning" isOpen={true} onClose={props.onClose}>
        <FluentModalBody>
          <p>
            Genom att bevaka ett <strong>dokument</strong> får du en notis via
            epost varje gång dokumentet ändras.
          </p>
          <p>
            Vid bevakning av <strong>mått</strong> får du motsvarande notis
            varje gång måttet uppdateras.
          </p>
          <p>
            Notiser skickas en gång per dag, tidigast dagen efter händelsen som
            notisen gäller.
          </p>
        </FluentModalBody>
        <FluentModalFooter>
          <Button onClick={() => props.onClose()} title="Stäng"></Button>
        </FluentModalFooter>
      </FluentModal>
    </div>
  );
}
