import { merge } from "lodash";

import { defined } from "../../../../../core/defined";
import { ColorScheme } from "../../../../state/stats/document-core/core";

export class ConditionalColorScheme {
  constructor(
    private _significantLabels: ColorScheme,
    private _insignificantLabels: ColorScheme
  ) {}

  matchesAtLeast2Significant(labels: string[]): boolean {
    return (
      labels.filter((label) => defined(this._significantLabels[label]))
        .length >= 2
    );
  }

  build(): ColorScheme {
    return merge({}, this._significantLabels, this._insignificantLabels);
  }
}
