import { TextField } from "@fluentui/react";
import { useState, useCallback } from "react";
import { Button } from "../../../../../../components/Button";

import { FluentIcon } from "../../../../../../components/Icons";
import {
  FilterSet,
  MicroDataFilter,
} from "../../../../../../lib/application/state/stats/document-core/core-micro";
import { assertNever } from "../../../../../../lib/core/assert";
import { classNames } from "../../../../../../lib/core/classNames";
import { defined } from "../../../../../../lib/core/defined";
import { formatNumberForLocale } from "../../../../../../lib/core/numeric/formatNum";
import { parseSwedishNumber } from "../../../../../../lib/core/numeric/parseNum";
import { StyledDropdownCondensed } from "../../card_general/StyledDropdown";

type FilterInput = "number" | "none" | "int" | "number-pair";
const filterOptions: {
  label: string;
  key: MicroDataFilter["type"];
  input?: FilterInput;
  incompatibleWith?: MicroDataFilter["type"][];
  placeholder?: string;
}[] = [
  {
    key: "gte",
    label: "Minst",
    input: "number",
    placeholder: "Värde",
  },
  {
    key: "lte",
    label: "Högst",
    input: "number",
    placeholder: "Värde",
  },
  { key: "interval", label: "Intervall", input: "number-pair" },
  {
    key: "aboveAvg",
    label: "Över medel",
    input: "none",
    incompatibleWith: ["belowAvg"],
  },
  {
    key: "belowAvg",
    label: "Under medel",
    input: "none",
    incompatibleWith: ["aboveAvg"],
  },
  {
    key: "topX",
    label: "X antal högsta",
    input: "int",
    placeholder: "Antal",
    incompatibleWith: [
      "bottomX",
      "topXPercent",
      "bottomXPercent",
      "aboveAvg",
      "belowAvg",
    ],
  },
  {
    key: "bottomX",
    label: "X antal lägsta",
    input: "int",
    placeholder: "Antal",
    incompatibleWith: [
      "topX",
      "topXPercent",
      "bottomXPercent",
      "aboveAvg",
      "belowAvg",
    ],
  },
  {
    key: "topXPercent",
    label: "X% högsta",
    input: "int",
    placeholder: "Procent",
    incompatibleWith: ["bottomXPercent", "bottomX", "belowAvg", "aboveAvg"],
  },
  {
    key: "bottomXPercent",
    label: "X% lägsta",
    input: "int",
    placeholder: "Procent",
    incompatibleWith: ["topXPercent", "topX", "belowAvg", "aboveAvg"],
  },
  {
    key: "intervalPercent",
    label: "Intervall i procent",
    input: "number-pair",
  },
];

export function FilterSelector(props: {
  readOnly?: boolean;
  filterSet?: FilterSet;
  isEditingFilter: boolean;
  handleRemoveFilter?: () => void;
  toggleIsEditingFilter: () => void;
  handleSetFilter: (filter: MicroDataFilter) => void;
}) {
  const { filterSet, isEditingFilter, toggleIsEditingFilter } = props;
  const [selectedFilterType, setSelectedFilterType] = useState<
    MicroDataFilter["type"] | undefined
  >(filterSet?.filters[0]?.type);
  const currentFilter = filterOptions.find((f) => f.key === selectedFilterType);

  const handleSetFilter = useCallback(
    (filter: MicroDataFilter) => {
      props.handleSetFilter(filter);
      toggleIsEditingFilter();
    },
    [props, toggleIsEditingFilter]
  );
  const showFilterEditor = isEditingFilter || !defined(filterSet);
  return (
    <div className="filter-selector">
      <div
        className={classNames(
          "filter-container",
          showFilterEditor ? "" : "invisible"
        )}
      >
        <StyledDropdownCondensed
          className="item"
          label="Filter"
          selectedKey={selectedFilterType}
          options={filterOptions.map((f) => ({
            key: f.key,
            text: f.label,
          }))}
          onChange={(_, option) => {
            if (!defined(option)) {
              return;
            }
            setSelectedFilterType(option.key as MicroDataFilter["type"]);
          }}
          dropdownWidth="auto"
        ></StyledDropdownCondensed>
        {defined(currentFilter) && (
          <CreateFilter
            handleSetFilter={handleSetFilter}
            savedFilterSet={filterSet}
            filterType={currentFilter.key}
          ></CreateFilter>
        )}
      </div>
      {!showFilterEditor && (
        <div className="active-filter">
          <DisplayFilter filterSet={filterSet}></DisplayFilter>
          {!props.readOnly && (
            <div className="edit-container">
              {/* TextField is only used here to ensure the height of the container stays consistent with other fields */}
              <TextField className="invisible" readOnly value="D"></TextField>
              <FluentIcon
                size="sm"
                name="edit"
                onClick={toggleIsEditingFilter}
              ></FluentIcon>
              {defined(props.handleRemoveFilter) && (
                <FluentIcon
                  size="sm"
                  name="cross"
                  onClick={props.handleRemoveFilter}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function CreateFilter(props: {
  filterType: MicroDataFilter["type"];
  savedFilterSet?: FilterSet;
  handleSetFilter: (filter: MicroDataFilter) => void;
}) {
  const savedFilter = props.savedFilterSet?.filters[0];
  switch (props.filterType) {
    case "aboveAvg":
      return (
        <AverageFilter
          filterType={props.filterType}
          handleSetFilter={props.handleSetFilter}
        ></AverageFilter>
      );
    case "belowAvg":
      return (
        <AverageFilter
          filterType={props.filterType}
          handleSetFilter={props.handleSetFilter}
        ></AverageFilter>
      );
    case "interval":
      return (
        <IntervalFilter
          filterType={props.filterType}
          handleSetFilter={props.handleSetFilter}
          savedValues={
            savedFilter?.type === "interval"
              ? [savedFilter.gte, savedFilter.lte]
              : undefined
          }
        ></IntervalFilter>
      );
    case "intervalPercent":
      return (
        <IntervalFilter
          filterType={props.filterType}
          handleSetFilter={props.handleSetFilter}
          savedValues={
            savedFilter?.type === "intervalPercent"
              ? [savedFilter.gte, savedFilter.lte]
              : undefined
          }
        ></IntervalFilter>
      );
    case "gte":
      return (
        <FloatFilter
          filterType={props.filterType}
          savedValue={
            savedFilter?.type === "gte" ? savedFilter.value : undefined
          }
          handleSetFilter={props.handleSetFilter}
        ></FloatFilter>
      );
    case "lte":
      return (
        <FloatFilter
          filterType={props.filterType}
          savedValue={
            savedFilter?.type === "lte" ? savedFilter.value : undefined
          }
          handleSetFilter={props.handleSetFilter}
        ></FloatFilter>
      );
    case "bottomXPercent":
      return (
        <FloatFilter
          filterType={props.filterType}
          savedValue={
            savedFilter?.type === "bottomXPercent"
              ? savedFilter.value
              : undefined
          }
          handleSetFilter={props.handleSetFilter}
        ></FloatFilter>
      );
    case "topXPercent":
      return (
        <FloatFilter
          filterType={props.filterType}
          savedValue={
            savedFilter?.type === "topXPercent" ? savedFilter.value : undefined
          }
          handleSetFilter={props.handleSetFilter}
        ></FloatFilter>
      );
    case "bottomX":
      return (
        <IntFilter
          filterType={props.filterType}
          savedValue={
            savedFilter?.type === "bottomX" ? savedFilter.value : undefined
          }
          handleSetFilter={props.handleSetFilter}
        ></IntFilter>
      );
    case "topX":
      return (
        <IntFilter
          filterType={props.filterType}
          savedValue={
            savedFilter?.type === "topX" ? savedFilter.value : undefined
          }
          handleSetFilter={props.handleSetFilter}
        ></IntFilter>
      );
  }
  assertNever(props.filterType);
}

function AverageFilter(props: {
  filterType: MicroDataFilter["type"];
  handleSetFilter: (filter: MicroDataFilter) => void;
}) {
  const handleSubmit = useCallback(() => {
    props.handleSetFilter({ type: props.filterType } as MicroDataFilter);
  }, [props]);

  return (
    <>
      <Button title="OK" onClick={handleSubmit}></Button>
    </>
  );
}

function FloatFilter(props: {
  savedValue?: number;
  label?: string;
  filterType: MicroDataFilter["type"];
  handleSetFilter: (filter: MicroDataFilter) => void;
}) {
  const [input, setInput] = useState<string>(
    props.savedValue?.toString() ?? ""
  );

  const [errorMessage, setErrorMessage] = useState<string>();

  const handleSubmit = useCallback(() => {
    const valueResult = parseSwedishNumber(input.trim());
    valueResult.match({
      ok: (v) => {
        setErrorMessage(undefined);
        props.handleSetFilter({
          type: props.filterType,
          value: v,
        } as MicroDataFilter);
      },
      err: (e) => {
        setErrorMessage("Fyll i ett tal");
      },
    });
  }, [input, props]);

  return (
    <>
      <TextField
        invalid={defined(errorMessage)}
        className="number-input"
        placeholder={props.label}
        value={input}
        onChange={(e) => {
          setInput(e.currentTarget.value);
        }}
      ></TextField>
      <Button title="OK" onClick={handleSubmit}></Button>
    </>
  );
}

function IntervalFilter(props: {
  savedValues?: [number, number];
  label?: string;
  filterType: MicroDataFilter["type"];
  handleSetFilter: (filter: MicroDataFilter) => void;
}) {
  const [inputLower, setInputLower] = useState<string>(
    props.savedValues?.[0].toString() ?? ""
  );
  const [inputUpper, setInputUpper] = useState<string>(
    props.savedValues?.[1].toString() ?? ""
  );

  const [errorMessageUpper, setErrorMessageUpper] = useState<string>();
  const [errorMessageLower, setErrorMessageLower] = useState<string>();

  const handleSubmit = useCallback(() => {
    const okLower = parseSwedishNumber(inputLower.trim()).match({
      ok: (v) => {
        setErrorMessageLower(undefined);
        return v;
      },
      err: (e) => {
        setErrorMessageLower("Fyll i ett tal");
      },
    });
    const okUpper = parseSwedishNumber(inputUpper.trim()).match({
      ok: (v) => {
        setErrorMessageUpper(undefined);
        return v;
      },
      err: (e) => {
        setErrorMessageUpper("Fyll i ett tal");
      },
    });
    if (defined(okLower) && defined(okUpper)) {
      if (okLower > okUpper) {
        setErrorMessageLower("Minsta värde måste vara lägre än högsta värde");
        return;
      }

      props.handleSetFilter({
        type: props.filterType,
        gte: okLower,
        lte: okUpper,
      } as MicroDataFilter);
    }
  }, [inputLower, inputUpper, props]);

  return (
    <>
      <TextField
        invalid={defined(errorMessageLower)}
        className="number-input"
        placeholder={"Minst"}
        value={inputLower}
        onChange={(e) => {
          setInputLower(e.currentTarget.value);
        }}
      ></TextField>
      <TextField
        invalid={defined(errorMessageUpper)}
        className="number-input"
        placeholder={"Högst"}
        value={inputUpper}
        onChange={(e) => {
          setInputUpper(e.currentTarget.value);
        }}
      ></TextField>
      <Button title="OK" onClick={handleSubmit}></Button>
    </>
  );
}
function IntFilter(props: {
  savedValue?: number;
  label?: string;
  filterType: MicroDataFilter["type"];
  handleSetFilter: (filter: MicroDataFilter) => void;
}) {
  const [input, setInput] = useState<string>(
    props.savedValue?.toString() ?? ""
  );

  const [errorMessage, setErrorMessage] = useState<string>();

  const handleSubmit = useCallback(() => {
    const valueResult = parseSwedishNumber(input.trim());
    valueResult.match({
      ok: (v) => {
        setErrorMessage(undefined);
        props.handleSetFilter({
          type: props.filterType,
          value: v,
        } as MicroDataFilter);
      },
      err: (e) => {
        setErrorMessage("Fyll i ett tal");
      },
    });
  }, [input, props]);

  return (
    <>
      <TextField
        invalid={defined(errorMessage)}
        className="number-input"
        placeholder={props.label}
        value={input}
        onChange={(e) => {
          setInput(e.currentTarget.value);
        }}
      ></TextField>
      <Button title="OK" onClick={handleSubmit}></Button>
    </>
  );
}

function DisplayFilter(props: { filterSet: FilterSet }) {
  return (
    <TextField
      className="displayed-filter"
      label="Filter"
      readOnly
      value={filterText(props.filterSet)}
    ></TextField>
  );
}

function filterText(filterSet: FilterSet): string {
  const filter = filterSet.filters[0];
  switch (filter.type) {
    case "aboveAvg":
      return "Över medel";
    case "belowAvg":
      return "Under medel";
    case "gte":
      return `Minst ${formatNumberForLocale(filter.value)}`;
    case "lte":
      return `Högst ${formatNumberForLocale(filter.value)}`;
    case "bottomX":
      return `Lägsta ${filter.value} värdena`;
    case "topX":
      return `Högsta ${filter.value} värdena`;
    case "bottomXPercent":
      return `Lägsta ${filter.value}% av värdena`;
    case "topXPercent":
      return `Högsta ${filter.value}% av värdena`;
    case "interval":
      return `Från ${filter.gte} till ${filter.lte}`;
    case "intervalPercent":
      return `Från ${filter.gte}% till ${filter.lte}%`;
  }
  assertNever(filter);
}
