import * as React from "react";
import { useState } from "react";
import { ISharingInfo } from "../../../../../lib/application/files/SharingInfo";
import { useCardEditMode } from "../../../../../lib/application/state/stats/useEditMode";

import { TabContainer, TitleActionsContainer, TitleContainer } from "./CardTab";
import { TitleActionsContextMenu } from "./TitleActionsContextMenu";
import { CardBackgroundColorDialog } from "../card_general/CardBackgroundColorDialog";
import { useCardBgColor } from "../../../../../lib/application/state/stats/useCardBgColor";
import { useToggle } from "../../../../../lib/application/hooks/useToggle";

interface Props {
  cardId: string;
  handleRemoveCard: () => void;
  handleDuplicateCard: () => void;
  handleSetCardBgColor: (color?: string) => void;
  handleTextCardMargins?: () => void;
  sharingInfo: ISharingInfo;
}
export function TextCardTab(props: Props) {
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [setBgColorOpen, toggleSetBgColorOpen] = useToggle(false);

  const {
    hasEditDocumentAccess,
    isEditingCard: isEditing,
    setCardEditing,
    isEditingDocument,
  } = useCardEditMode(props.cardId, props.sharingInfo);

  const contextMenuContainerRef = React.useRef(null);
  const currentBgColor = useCardBgColor(props.cardId);

  if (!isEditingDocument) {
    return <></>;
  }

  return (
    <TabContainer containerKey={props.cardId}>
      <div className="card-label">
        <TitleContainer
          isEditing={isEditing}
          cardId={props.cardId}
        ></TitleContainer>
        {setBgColorOpen && (
          <CardBackgroundColorDialog
            isOpen
            onClose={toggleSetBgColorOpen}
            onColorChange={props.handleSetCardBgColor}
            initialColor={currentBgColor ?? "#ffffff"}
          />
        )}
      </div>

      <TitleActionsContainer
        setEditing={setCardEditing}
        isEditing={isEditing}
        hasEditDocumentAccess={hasEditDocumentAccess}
        setShowContextMenu={setShowContextMenu}
        showContextMenu={showContextMenu}
        contextMenuContainerRef={contextMenuContainerRef}
      ></TitleActionsContainer>
      <TitleActionsContextMenu
        cardId={props.cardId}
        hasEditDocumentAccess={hasEditDocumentAccess}
        handleEditBgColor={toggleSetBgColorOpen}
        handleDuplicateCard={() => props.handleDuplicateCard()}
        handleRemoveCard={() => props.handleRemoveCard()}
        handleTextCardMargins={props.handleTextCardMargins}
        targetRef={contextMenuContainerRef}
        hidden={!showContextMenu}
        onDismiss={() => setShowContextMenu(false)}
      ></TitleActionsContextMenu>
    </TabContainer>
  );
}
