import { MISSING_VALUE_PATTERN_ID } from "../../../lib/application/stats/datasets/MapDataset";
import { SVGNamespace } from "../../../lib/application/stats/shared/core/definitions";
import { classNames } from "../../../lib/core/classNames";
import { defined } from "../../../lib/core/defined";
import { logger } from "../../../lib/infra/logging";

export function svgChartId(cardId: string) {
  return "svg-chart-card-" + cardId;
}
interface Props {
  cardId: string;
  width?: number;
  height?: number;
  viewBox?: string;
  containerRef?: React.Ref<SVGSVGElement>;
  previewBackgroundColor?: string;
  preserveAspectRatio?: string;
  className?: string;
  children: any;
}

export function SvgWrapper({
  width,
  height,
  viewBox,
  children,
  containerRef,
  previewBackgroundColor,
  className,
  cardId,
}: Props) {
  if (!defined(viewBox) && (!defined(width) || !defined(height))) {
    logger.error(
      "SvgWrapper: width and height must be defined if viewBox is not"
    );
  }

  return (
    <svg
      xmlns={SVGNamespace}
      version="1.1"
      ref={containerRef}
      id={svgChartId(cardId)}
      viewBox={viewBox}
      width={width}
      height={height}
      fontSize="12px"
      className={classNames("text-xs", className)}
    >
      <defs>
        <pattern
          id={MISSING_VALUE_PATTERN_ID}
          patternUnits="userSpaceOnUse"
          width="4"
          height="4"
        >
          <rect x="0" y="0" width="4" height="4" fill="white" />
          <path
            d="M-1,1 l2,-2
           M0,4 l4,-4
           M3,5 l2,-2"
            stroke="red"
            strokeWidth="0.5"
          />
        </pattern>
      </defs>
      <filter id="text-label-shadow">
        <feDropShadow
          dx="1"
          dy="1"
          stdDeviation="0.1"
          floodColor="black"
        ></feDropShadow>
      </filter>
      <filter id="text-label-shadow-white">
        <feDropShadow
          dx="1"
          dy="1"
          stdDeviation="0.1"
          floodColor="white"
        ></feDropShadow>
      </filter>
      {defined(previewBackgroundColor) && (
        <rect width="100%" height="100%" fill={previewBackgroundColor} />
      )}
      {children}
    </svg>
  );
}
