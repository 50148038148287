import {
  MeasureAdminLogEntry,
  TodosMeasuresForMemberOrgsDto,
  TodosMicroDto,
  TodosStatsDto,
} from "../../infra/api_responses/admin/todos";

export interface DraftDataTodo {
  dataId: number;
  label: string;
  hasDraftData: boolean;
  hasDraftDimensions: boolean;
  isDraftMeasure: boolean;
  isCustomerData?: boolean;
  memberOrgMeasurementOwnerOrg?: string;
  memberOrgMeasurementParentId?: number;
  log: MeasureAdminLogEntry[];
}

export class DraftDataTodoMicro implements DraftDataTodo {
  constructor(private _dto: TodosMicroDto["drafts"][number]) {}

  get dataId(): number {
    return this._dto.mikro_id;
  }

  get label(): string {
    return this._dto.label;
  }

  get hasDraftData(): boolean {
    return this._dto.has_draft_data;
  }

  get hasDraftDimensions(): boolean {
    return this._dto.has_draft_dimensions;
  }

  get isDraftMeasure(): boolean {
    return this._dto.is_draft_measure;
  }

  get log(): MeasureAdminLogEntry[] {
    return this._dto.log;
  }
}

export class DraftDataTodoStats implements DraftDataTodo {
  constructor(private _dto: TodosStatsDto["drafts"][number]) {}

  get dataId(): number {
    return this._dto.data_id;
  }

  get label(): string {
    return this._dto.label;
  }

  get hasDraftData(): boolean {
    return this._dto.has_draft_data;
  }

  get hasDraftDimensions(): boolean {
    return this._dto.has_draft_dimensions;
  }

  get isDraftMeasure(): boolean {
    return this._dto.is_draft_measure;
  }

  get isCustomerData(): boolean | undefined {
    return this._dto.is_customer_data;
  }

  get log(): MeasureAdminLogEntry[] {
    return this._dto.log;
  }
}

export class DraftDataTodoMemberOrgsMeasure implements DraftDataTodo {
  constructor(
    private _dto: NonNullable<TodosMeasuresForMemberOrgsDto["drafts"]>[number]
  ) {}

  get dataId(): number {
    return this._dto.data_id;
  }

  get label(): string {
    return this._dto.label;
  }

  get hasDraftData(): boolean {
    return this._dto.has_draft_data;
  }

  get hasDraftDimensions(): boolean {
    return this._dto.has_draft_dimensions;
  }

  get isDraftMeasure(): boolean {
    return this._dto.is_draft_measure;
  }

  get isCustomerData(): boolean | undefined {
    return true;
  }

  get memberOrgMeasurementOwnerOrg(): string {
    return this._dto.member_org_measurement_owner_org;
  }

  get memberOrgMeasurementParentId(): number | undefined {
    return this._dto.member_org_measurement_parent_id ?? undefined;
  }

  get log(): MeasureAdminLogEntry[] {
    return this._dto.log;
  }
}
