import { IconMessageReport } from "@tabler/icons-react";

import {
  DataLoadError,
  DataLoadNotStartedInfo,
} from "../../../../../lib/application/state/stats/document-core/_core-shared";
import { assertNever } from "../../../../../lib/core/assert";

import "./data_load_views.scss";
import { AlertBox } from "../../../../../components/AlertBox";

export function DataLoadNotStartedView(props: {
  info: DataLoadNotStartedInfo;
}) {
  const incomplete = props.info.incomplete;
  const header = getDataLoadNotStartedHeader(props.info);
  switch (incomplete.type) {
    case "empty-card":
      return (
        <InfoContainer
          header={header}
          message="Välj ett mått i ämnesmenyn eller använd sökfunktionen för att hitta statistik"
        />
      );
    case "geo":
      return (
        <InfoContainer
          header={header}
          message="Kryssa i ett område för att se resultat"
        />
      );
    case "breakdown":
      return (
        <InfoContainer
          header={header}
          message={"Välj minst ett alternativ för att visa resultat."}
        />
      );
    case "missing-variable-input":
      return (
        <InfoContainer
          header={header}
          message="Fyll i ett värde för att visa resultat."
        />
      );
    case "incomplete-grouping-subject-path":
      return (
        <InfoContainer
          header={header}
          message="Välj ett område, delområde och ämne för att visa resultat."
        />
      );
  }
  assertNever(incomplete);
}

function getDataLoadNotStartedHeader(info: DataLoadNotStartedInfo): string {
  const incomplete = info.incomplete;
  switch (incomplete.type) {
    case "empty-card":
      return "Välj mått";
    case "geo":
      return "Inget geografiskt område valt";
    case "breakdown":
      return `Kryssa i alternativ i menyn "${incomplete.dimension.toLocaleLowerCase()}"`;
    case "missing-variable-input":
      return `Fyll i värde för "${incomplete.variable.toLocaleLowerCase()}"`;
    case "incomplete-grouping-subject-path":
      return "Välj område, delområde och ämne";
  }
  assertNever(incomplete);
}

function InfoContainer(props: { header: string; message: string }) {
  return (
    <div className="data-load-info">
      <div className="message-container">
        <IconMessageReport size={40} />
        <h2>{props.header}</h2>
        <p>{props.message}</p>
      </div>
    </div>
  );
}

export function DataLoadErrorView(props: { error: DataLoadError }) {
  const header = getHeader(props.error);
  const message = getDataLoadErrorMessage(props.error);
  return <InfoContainer header={header} message={message} />;
}

export function DataLoadErrorMessage(props: {
  error: DataLoadError;
  className?: string;
}) {
  return (
    <AlertBox className={props.className} intent="danger">
      <span>{getDataLoadErrorMessage(props.error)}</span>
    </AlertBox>
  );
}

export function DataloadNotStartedMessage(props: {
  info: DataLoadNotStartedInfo;
  className?: string;
}) {
  return (
    <AlertBox className={props.className} intent="warning">
      <span>{getDataLoadNotStartedHeader(props.info)}</span>
    </AlertBox>
  );
}

function getHeader(error: DataLoadError): string {
  switch (error) {
    case "bad-client-input":
      return "Programfel";
    case "no-data":
      return "Data saknas";
    case "unauthorized":
      return "Obehörig användare";
    case "unknown-error":
      return "Okänt fel";
  }
}

export function getDataLoadErrorMessage(error: DataLoadError): string {
  switch (error) {
    case "no-data":
      return "Det saknas data för dina val.";
    case "unauthorized":
      return "Du har inte behörighet att se den här statistiken.";
    case "unknown-error":
    case "bad-client-input":
      return "Försök igen senare eller kontakta support.";
  }
}
