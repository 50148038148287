import { useCallback, useContext } from "react";
import { useSetRecoilState } from "recoil";

import { DocCardMicro } from "./document-core/core";
import { loadAndStoreDataMicro } from "./cardToDataStateMicro";
import {
  CardUpdateCountContext,
  MicroGeoTreeContext,
  ShowDraftDataContext,
} from "../../contexts";
import { useSaveCard } from "../actions/useSaveDocument";
import { cardQuery } from "./document-core/queries/card";
import { defined } from "../../../core/defined";
import { logger } from "../../../infra/logging";
import { cardColors } from "./document-core/queries/shared";
import { useExtendedAppearanceSettings } from "../actions/useExtendedAppearanceSettings";

/** Apply a change to a measure */
export function useApplyChangeMicro(cardId: string) {
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const handleSaveCard = useSaveCard();
  const setCard = useSetRecoilState(cardQuery(cardId));
  const geoTree = useContext(MicroGeoTreeContext);
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const appearanceSettings = useExtendedAppearanceSettings();

  const handleApply = useCallback(
    (updatedCard: DocCardMicro) => {
      if (!defined(geoTree)) {
        logger.error("Attempted to update card, but no geoTree available");
        return Promise.reject();
      }

      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      return loadAndStoreDataMicro(
        updatedCard,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedCard),
        handleSaveCard
      );
    },
    [
      adminShowDraftData,
      appearanceSettings,
      geoTree,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      setCard,
    ]
  );

  return handleApply;
}
