import { useCallback } from "react";

import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import { EditableInputRange } from "../../../../../components/input/EditableInput";
import { Button } from "../../../../../components/Button";
import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";
import { useChangeDataOutputSettings } from "../../../../../lib/application/state/actions/selections/useChangeDataOutputSettings";
import { defined } from "../../../../../lib/core/defined";
import { validateRange } from "../../../../../lib/application/validation/range";

export function ManualYAxisDialog(props: {
  cardId: string;
  onClose: () => void;
}) {
  const [settings, setSettings] = useChangeDataOutputSettings(props.cardId);

  const handleSaveSettings = useCallback(
    (range: [number, number]) => {
      setSettings({
        ...settings,
        startFromZero: range[0] !== 0 ? false : settings.startFromZero,
        customYAxisRange: range,
      });
    },
    [setSettings, settings]
  );

  const handleClear = useCallback(() => {
    setSettings({
      ...settings,
      customYAxisRange: null,
    });
  }, [setSettings, settings]);

  return (
    <FluentModal width="md" isOpen onClose={props.onClose} title="Ange y-skala">
      <FluentModalBody>
        <div id="manual-y-axis-dialog">
          <p>Ange min- och maxvärden för y-axeln.</p>
          <EditableInputRange
            initialValue={settings.customYAxisRange}
            handleClear={
              defined(settings.customYAxisRange) ? handleClear : undefined
            }
            validateRange={validateRange}
            handleSave={(value) => {
              handleSaveSettings(value);
              return Promise.resolve();
            }}
          />
        </div>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterRight>
            <Button title="Stäng" onClick={props.onClose}></Button>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModal>
  );
}
