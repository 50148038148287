/* eslint-disable no-console */
import { defined } from "../../core/defined";
import { Timer } from "../../core/Timer";
import { logger } from "../../infra/logging";

const xmlNs = "http://www.w3.org/2000/xmlns/";
const xhtmlNs = "http://www.w3.org/1999/xhtml";
const svgNs = "http://www.w3.org/2000/svg";
const doctype =
  '<?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd" [<!ENTITY nbsp "&#160;">]>';
const urlRegex = /url\(["']?(.+?)["']?\)/;

const isElement = (obj: any) =>
  obj instanceof HTMLElement || obj instanceof SVGElement;
const assertDomNode = (el: any) => {
  if (!isElement(el))
    throw new Error(`an HTMLElement or SVGElement is required; got ${el}`);
};
const assertDomNodePromise = (el: any) =>
  new Promise((resolve, reject) => {
    if (isElement(el)) resolve(el);
    else
      reject(new Error(`an HTMLElement or SVGElement is required; got ${el}`));
  });
const isExternal = (url: string) =>
  url &&
  url.lastIndexOf("http", 0) === 0 &&
  url.lastIndexOf(window.location.host) === -1;

const arrayBufferToBase64 = (buffer: Iterable<number>) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  for (let i = 0; i < bytes.byteLength; i++)
    binary += String.fromCharCode(bytes[i]);
  return window.btoa(binary);
};

const getDimension = (el: any, clone: any, dim: any) => {
  const v =
    (el.viewBox && el.viewBox.baseVal && el.viewBox.baseVal[dim]) ||
    (clone.getAttribute(dim) !== null &&
      !clone.getAttribute(dim).match(/%$/) &&
      parseInt(clone.getAttribute(dim))) ||
    el.getBoundingClientRect()[dim] ||
    parseInt(clone.style[dim]) ||
    parseInt(window.getComputedStyle(el).getPropertyValue(dim));
  return typeof v === "undefined" || v === null || isNaN(parseFloat(v)) ? 0 : v;
};

const getDimensions = (
  el: SVGGraphicsElement,
  clone: any,
  width: number | undefined,
  height: number | undefined
) => {
  if (el.tagName === "svg")
    return {
      width: width || getDimension(el, clone, "width"),
      height: height || getDimension(el, clone, "height"),
    };
  else if (el.getBBox) {
    const { x, y, width, height } = el.getBBox();
    return {
      width: x + width,
      height: y + height,
    };
  }
};

const reEncode = (data: any) =>
  decodeURIComponent(
    encodeURIComponent(data).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      const c = String.fromCharCode(`0x${p1}` as any);
      return c === "%" ? "%25" : c;
    })
  );

const uriToBlob = (uri: string) => {
  const byteString = window.atob(uri.split(",")[1]);
  const mimeString = uri.split(",")[0].split(":")[1].split(";")[0];
  const buffer = new ArrayBuffer(byteString.length);
  const intArray = new Uint8Array(buffer);
  for (let i = 0; i < byteString.length; i++) {
    intArray[i] = byteString.charCodeAt(i);
  }
  return new Blob([buffer], { type: mimeString });
};

const query = (el: HTMLElement, selector: string) => {
  if (!selector) return;
  try {
    return (
      el.querySelector(selector) ||
      (el.parentNode && el.parentNode.querySelector(selector))
    );
  } catch (err) {
    console.warn(`Invalid CSS selector "${selector}"`, err);
  }
};

const inlineImages = (el: HTMLElement) =>
  Promise.all(
    Array.from(el.querySelectorAll("image")).map((image) => {
      let href =
        image.getAttributeNS("http://www.w3.org/1999/xlink", "href") ||
        image.getAttribute("href");
      if (href === null) {
        return Promise.resolve(null);
      }
      if (isExternal(href)) {
        href +=
          (href.indexOf("?") === -1 ? "?" : "&") + "t=" + new Date().valueOf();
      }
      return new Promise((resolve, reject) => {
        const canvas = document.createElement("canvas");
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = href!;
        img.onerror = () => reject(new Error(`Could not load ${href}`));
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;
          canvas.getContext("2d")?.drawImage(img, 0, 0);
          image.setAttributeNS(
            "http://www.w3.org/1999/xlink",
            "href",
            canvas.toDataURL("image/png")
          );
          resolve(true);
        };
      });
    })
  );

export type SvgFontSetting = { url: string; format: string; text: string };

const cachedFonts: { [key: string]: string } = {};
const inlineFonts = (fonts: SvgFontSetting[]) => {
  const timer = new Timer();
  return Promise.all(
    fonts.map(
      (font) =>
        new Promise((resolve, reject) => {
          const cachedFontUri = cachedFonts[font.url];
          if (cachedFontUri !== undefined) {
            return resolve(cachedFontUri);
          }

          const req = new XMLHttpRequest();

          req.addEventListener("load", () => {
            // TODO: it may also be worth it to wait until fonts are fully loaded before
            // attempting to rasterize them. (e.g. use https://developer.mozilla.org/en-US/docs/Web/API/FontFaceSet)
            const fontInBase64 = arrayBufferToBase64(req.response);
            const fontUri =
              font.text.replace(
                urlRegex,
                `url("data:${font.format};base64,${fontInBase64}")`
              ) + "\n";
            cachedFonts[font.url] = fontUri;
            resolve(fontUri);
          });
          req.addEventListener("error", (e) => {
            console.warn(`Failed to load font from: ${font.url}`, e);
            delete cachedFonts[font.url];
            reject(null);
          });
          req.addEventListener("abort", (e) => {
            console.warn(`Aborted loading font from: ${font.url}`, e);
            reject(null);
          });
          req.open("GET", font.url);
          req.responseType = "arraybuffer";
          req.send();
        })
    )
  ).then((fontCss) => {
    logger.debug("Time elapsed for loading fonts: " + timer.elapsedMs() + "ms");
    return fontCss.join("");
  });
};

type RuleSet = {
  rules?: CSSRuleList;
  href?: string | null;
};

let cachedRules: RuleSet[] = [];
function styleSheetRules(): RuleSet[] {
  if (cachedRules) return cachedRules;
  const rules = Array.from(document.styleSheets).map((sheet) => {
    return { rules: sheet.cssRules, href: sheet.href } as RuleSet;
  });
  cachedRules = rules;
  return rules;
}

const inlineCss = (el: any, options: any) => {
  const { selectorRemap, modifyStyle, modifyCss, fonts, excludeUnusedCss } =
    options || {};
  const generateCss =
    modifyCss ||
    ((selector: any, properties: any) => {
      const sel = selectorRemap ? selectorRemap(selector) : selector;
      const props = modifyStyle ? modifyStyle(properties) : properties;
      return `${sel}{${props}}\n`;
    });
  const css: string[] = [];

  const fontList = fonts || [];

  styleSheetRules().forEach(({ rules }) => {
    if (!rules) return;
    Array.from(rules).forEach((rule: any) => {
      if (typeof rule.style != "undefined") {
        if (query(el, rule.selectorText)) {
          css.push(generateCss(rule.selectorText, rule.style.cssText));
        } else if (!excludeUnusedCss) {
          css.push(rule.cssText);
        }
      }
    });
  });

  return inlineFonts(fontList).then((fontCss) => css.join("\n") + fontCss);
};

export function prepareSvg(el: any, options: any) {
  assertDomNode(el);
  const {
    left = 0,
    top = 0,
    width: w,
    height: h,
    scale = 1,
    responsive = false,
    excludeCss = false,
  } = options || {};

  return inlineImages(el).then(() => {
    let clone = el.cloneNode(true);
    clone.style.backgroundColor =
      (options || {}).backgroundColor || el.style.backgroundColor;
    const { width, height } = getDimensions(el, clone, w, h)!;

    if (el.tagName !== "svg") {
      if (el.getBBox) {
        if (clone.getAttribute("transform") != null) {
          clone.setAttribute(
            "transform",
            clone.getAttribute("transform").replace(/translate\(.*?\)/, "")
          );
        }
        const svg = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "svg"
        );
        svg.appendChild(clone);
        clone = svg;
      } else {
        // eslint-disable-next-line no-console
        console.error("Attempted to render non-SVG element", el);
        return;
      }
    }

    clone.setAttribute("version", "1.1");
    clone.setAttribute("viewBox", [left, top, width, height].join(" "));
    if (!clone.getAttribute("xmlns"))
      clone.setAttributeNS(xmlNs, "xmlns", svgNs);
    if (!clone.getAttribute("xmlns:xlink"))
      clone.setAttributeNS(
        xmlNs,
        "xmlns:xlink",
        "http://www.w3.org/1999/xlink"
      );

    if (responsive) {
      clone.removeAttribute("width");
      clone.removeAttribute("height");
      clone.setAttribute("preserveAspectRatio", "xMinYMin meet");
    } else {
      clone.setAttribute("width", width * scale);
      clone.setAttribute("height", height * scale);
    }

    Array.from(clone.querySelectorAll("foreignObject > *")).forEach(
      (foreignObject) => {
        (foreignObject as any).setAttributeNS(
          xmlNs,
          "xmlns",
          (foreignObject as any).tagName === "svg" ? svgNs : xhtmlNs
        );
      }
    );

    if (excludeCss) {
      const outer = document.createElement("div");
      outer.appendChild(clone);
      const src = outer.innerHTML;
      return { src, width, height };
    } else {
      return inlineCss(el, options).then((css) => {
        const style = document.createElement("style");
        style.setAttribute("type", "text/css");
        style.innerHTML = `<![CDATA[\n${css}\n]]>`;

        const defs = document.createElement("defs");
        defs.appendChild(style);
        clone.insertBefore(defs, clone.firstChild);

        const outer = document.createElement("div");
        outer.appendChild(clone);
        const src = outer.innerHTML.replace(
          /NS\d+:href/gi,
          'xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href'
        );

        return { src, width, height };
      });
    }
  });
}

export function svgAsDataUri(el: any, options: any) {
  assertDomNode(el);
  return prepareSvg(el, options).then((res) => {
    if (!res) {
      return;
    }
    const { src } = res;
    return `data:image/svg+xml;base64,${window.btoa(reEncode(doctype + src))}`;
  });
}

export const svgAsPngUri = (
  el: any,
  options: any
): Promise<string | undefined> => {
  assertDomNode(el);
  const {
    encoderType = "image/png",
    encoderOptions = 1,
    canvg,
  } = options || {};

  const convertToPng = ({
    src,
    width,
    height,
  }: {
    src: HTMLImageElement;
    width: number;
    height: number;
  }) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context === null) {
      throw new Error("Could not get canvas 2d context");
    }
    const pixelRatio = window.devicePixelRatio || 1;

    canvas.width = width * pixelRatio;
    canvas.height = height * pixelRatio;
    canvas.style.width = `${canvas.width}px`;
    canvas.style.height = `${canvas.height}px`;
    context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

    if (canvg) {
      canvg(canvas, src);
    } else {
      context.drawImage(src, 0, 0);
    }

    return Promise.resolve(canvas.toDataURL(encoderType, encoderOptions));
  };

  if (canvg) {
    return prepareSvg(el, options).then((res) => {
      if (!defined(res)) {
        throw new Error("Could not prepare SVG");
      }
      return convertToPng(res as any);
    });
  } else {
    return svgAsDataUri(el, options).then((uri) => {
      if (!defined(uri)) {
        throw new Error("Could not get data URI");
      }
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () =>
          resolve(
            convertToPng({
              src: image,
              width: image.width,
              height: image.height,
            })
          );
        image.onerror = () => {
          reject(
            `There was an error loading the data URI as an image on the following SVG\n${window.atob(
              uri.slice(26)
            )}Open the following link to see browser's diagnosis\n${uri}`
          );
        };
        image.src = uri;
      });
    });
  }
};

function download(name: string, uri: string, options: any) {
  if ((navigator as any).msSaveOrOpenBlob) {
    (navigator as any).msSaveOrOpenBlob(uriToBlob(uri), name);
  } else {
    const saveLink = document.createElement("a");
    if ("download" in saveLink) {
      saveLink.download = name;
      saveLink.style.display = "none";
      document.body.appendChild(saveLink);
      try {
        const blob = uriToBlob(uri);
        const url = URL.createObjectURL(blob);
        saveLink.href = url;
        saveLink.onclick = () =>
          requestAnimationFrame(() => URL.revokeObjectURL(url));
      } catch (e) {
        console.error(e);
        console.warn(
          "Error while getting object URL. Falling back to string URL."
        );
        saveLink.href = uri;
      }
      saveLink.click();
      document.body.removeChild(saveLink);
    } else if (options && options.popup) {
      options.popup.document.title = name;
      options.popup.location.replace(uri);
    }
  }
}

export const saveSvg = (el: HTMLElement, name: string, options: any) => {
  return assertDomNodePromise(el)
    .then((el) => svgAsDataUri(el, options || {}))
    .then((uri) => {
      if (!defined(uri)) {
        throw new Error("Could not get data URI");
      }
      return download(name, uri, {});
    });
};

export const saveSvgAsPng = (el: Element, name: string, options: any) => {
  return assertDomNodePromise(el)
    .then((el) => svgAsPngUri(el, options || {}))
    .then((uri) => {
      if (!defined(uri)) {
        throw new Error("Could not get data URI");
      }
      download(name, uri, {});
    });
};
