import { useCallback, useContext } from "react";
import { ShowDraftDataContext } from "../../../../../../lib/application/contexts";
import { useStateTransition } from "../../../../../../lib/application/hooks/useStateTransition";
import { statsApiV2 } from "../../../../../../lib/application/requests/statsApiV2";
import { defined } from "../../../../../../lib/core/defined";
import {
  defaultMeasureSelectionPrimary,
  setMeasureAvailableDatesMut,
} from "../../../../../../lib/domain/measure";
import { MeasureThinNonCustomer } from "../../../../../../lib/domain/measure/definitions";
import { makeFullSurveyMeasure } from "../../../../../../lib/domain/measure/operations";
import { MeasureSelection } from "../../../../../../lib/domain/selections/definitions";
import { StyledDropdown } from "../../card_general/StyledDropdown";
import {
  MeasureSelectionContainer,
  measureSelectionToAvailableOptions,
} from "./shared";

interface Props {
  setMeasureSelection: (measureSelection: MeasureSelection) => void;
  measureSelection: MeasureSelection;
}
/**
 *
 * Shared component for measure selection, regardless of measure type
 * @param props
 */
export function MeasureDropdown(props: Props) {
  const { setMeasureSelection, measureSelection } = props;
  const measure = measureSelection.measure;

  const availableNonRetired = measureSelection.available.filter(
    (a) => !a.retired
  );
  const adminShowDraftData = useContext(ShowDraftDataContext);

  const setSelectedMeasure = useCallback(
    (selectedMeasure: MeasureThinNonCustomer, adminShowDraftData: boolean) => {
      if (selectedMeasure.value_type === "survey") {
        return statsApiV2
          .getDimensions(selectedMeasure.data_id, adminShowDraftData, true)
          .then((dimensionsRes) => {
            const dimensions = dimensionsRes.unwrap();
            const fullMeasure = makeFullSurveyMeasure(
              selectedMeasure,
              dimensions
            );
            setMeasureAvailableDatesMut(
              defaultMeasureSelectionPrimary(
                fullMeasure,
                measureSelection.available
              ),
              adminShowDraftData
            ).then(setMeasureSelection);
          });
      }
      return statsApiV2
        .getDimensions(selectedMeasure.data_id, adminShowDraftData, true)
        .then((res) => {
          const dimensions = res.unwrap();
          const defaultSelection = defaultMeasureSelectionPrimary(
            { ...selectedMeasure, dimensions },
            measureSelection.available
          );
          return setMeasureAvailableDatesMut(
            defaultSelection,
            adminShowDraftData
          ).then(setMeasureSelection);
        });
    },
    [measureSelection.available, setMeasureSelection]
  );

  useStateTransition(adminShowDraftData, (prev, current) => {
    if (defined(prev) && defined(current) && prev !== current) {
      const selectedMeasure = measureSelection.measure;
      setSelectedMeasure(selectedMeasure, adminShowDraftData);
    }
  });

  return (
    <MeasureSelectionContainer measureId={measure.data_id}>
      <StyledDropdown
        disabled={availableNonRetired.length <= 1}
        className="item"
        selectedKey={measureSelection.measure.data_id}
        onChange={(event, item) => {
          const selectedMeasure = measureSelection.available.find(
            (m) => m.data_id === item?.key
          );
          if (!defined(selectedMeasure)) {
            return;
          }
          setSelectedMeasure(selectedMeasure, adminShowDraftData);
        }}
        label="Mått"
        dropdownWidth="auto"
        options={measureSelectionToAvailableOptions(measureSelection)}
      />
    </MeasureSelectionContainer>
  );
}
