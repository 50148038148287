/**
 * Produce all combinations of items from different arrays
 */
export function combinations<T>(itemArrays: T[][]) {
  let items: T[][] = [];

  for (const layer of itemArrays) {
    items = combine(items, layer);
  }
  return items;
}

function combine<T>(a: T[][], b: T[]): T[][] {
  if (a.length === 0) {
    return b.map((item) => [item]);
  }
  const result: T[][] = [];
  for (const item of a) {
    if (b.length === 0) {
      result.push(item);
    }
    for (const itemB of b) {
      const combination = item.concat([itemB]);
      result.push(combination);
    }
  }
  return result;
}
