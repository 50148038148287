import { useState } from "react";

import { FluentIcon } from "../../../../../../components/Icons";
import {
  shortenMunicipalityLabel,
  shortenRegionLabel,
} from "../../../../../../lib/domain/names";

import "./GeoQuickSelect.scss";
import { MicroGeoTree } from "../../../../../../lib/application/stats/shared/MicroGeoTree";

export function GeoQuickSelect(props: {
  geographies: MicroGeoTree;
  handleSelectAllInRegion: (region: string) => void;
  handleSelectAllInMunicipality: (municipality: string) => void;
}) {
  const geographies = props.geographies;
  const [openRegion, setOpenRegion] = useState<string>();

  return (
    <div className="micro-geo-quick-select">
      {geographies.tree().map((entry) => {
        return (
          <div className="geo-level" key={entry.lan}>
            <div className="region">
              <div
                className="text-wrapper"
                onClick={() => {
                  setOpenRegion(
                    openRegion === entry.lan ? undefined : entry.lan
                  );
                }}
              >
                <FluentIcon size="sm" name="chevron-right"></FluentIcon>
                <div className="label">{shortenRegionLabel(entry.label)}</div>
              </div>
              <FluentIcon
                title="Lägg till områden"
                onClick={() => props.handleSelectAllInRegion(entry.lan)}
                className="icon-add"
                size="md"
                name="insert"
              ></FluentIcon>
            </div>
            <div className="municipalities">
              {openRegion === entry.lan &&
                entry.children.map((m) => {
                  return (
                    <div key={m.label} className="municipality">
                      <FluentIcon
                        size="sm"
                        name="insert"
                        invisible
                      ></FluentIcon>
                      <FluentIcon
                        className="icon-add"
                        size="sm"
                        name="insert"
                        onClick={() =>
                          props.handleSelectAllInMunicipality(m.kommun)
                        }
                      ></FluentIcon>
                      <span>{shortenMunicipalityLabel(m.label)}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
