import { Dropdown, IDropdownProps, TextField } from "@fluentui/react";
import { useCallback, useContext, useEffect, useState } from "react";

import { Button } from "../../../../components/Button";
import {
  ButtonsFooter,
  ButtonsFooterLeft,
  ButtonsFooterRight,
} from "../../../../components/ButtonContainers";
import {
  CategoriesContext,
  ShowDraftDataContext,
} from "../../../../lib/application/contexts";
import { getMeasuresWithCache } from "../../../../lib/application/requests/common_requests";
import { classNames } from "../../../../lib/core/classNames";
import { defined } from "../../../../lib/core/defined";
import { prepareCategoryParts } from "../../../../lib/domain/categories";
import { measureIsNotRetired } from "../../../../lib/domain/measure";
import { MeasureThin } from "../../../../lib/domain/measure/definitions";
import { SearchMain } from "../../../stats/docs/SearchMain";

import "./SimpleSubjectSelect.scss";

/**
 * Subject selection component alloweing for both controlled and uncontrolled behavior.
 * @param props
 * @returns
 */
export function SimpleSubjectSelect(props: {
  area?: string;
  subarea?: string;
  subject?: string;
  defaultPath?: string[];
  className?: string;
  onSelect?: (path: string[]) => Promise<void>;
  onSubmit?: (path: string[]) => Promise<void>;
  submitText?: string;
  onCancel?: () => void;
  enableSearch?: boolean;
}) {
  const [subjectPathLocal, setSubjectPathLocal] = useState<
    (string | undefined)[]
  >(props.defaultPath ?? []);
  const area = props.area ?? subjectPathLocal[0];
  const subarea = props.subarea ?? subjectPathLocal[1];
  const subject = props.subject ?? subjectPathLocal[2];

  const [isSaving, setIsSaving] = useState(false);
  const { onCancel, onSubmit, onSelect, submitText } = props;
  if (!defined(onSubmit) && !defined(onSelect)) {
    throw new Error("either onSubmit or onSelect must be defined");
  }

  const handleUpdateSelection = useCallback(
    (path: string[]) => {
      setSubjectPathLocal(path);
      if (defined(onSelect)) {
        onSelect(path);
      }
    },
    [onSelect]
  );

  const handleSubmit = useCallback(() => {
    setIsSaving(true);
    if (!defined(area) || !defined(subarea) || !defined(subject)) {
      throw new Error("area, subarea and subject must be defined");
    }
    onSubmit?.([area, subarea, subject]).then(() => {
      setIsSaving(false);
    });
  }, [area, onSubmit, subarea, subject]);

  return (
    <div className={classNames("simple-subject-select", props.className)}>
      <section>
        {props.enableSearch && (
          <SearchMain
            autofocus={true}
            onSelectPath={(path) => handleUpdateSelection(path)}
            onSelectMeasure={(measureId, path) => {
              handleUpdateSelection(path);
            }}
          ></SearchMain>
        )}

        <SubjectPathSelection
          area={area}
          subarea={subarea}
          subject={subject}
          handleUpdateSelection={handleUpdateSelection}
        />
      </section>

      {defined(area) && defined(subarea) && defined(subject) && (
        <ExistingMeasures path={[area, subarea, subject]}></ExistingMeasures>
      )}

      <ButtonsFooter>
        <ButtonsFooterLeft>
          <>
            {defined(onCancel) && (
              <Button title="Avbryt" onClick={props.onCancel}></Button>
            )}
          </>
        </ButtonsFooterLeft>
        <ButtonsFooterRight>
          <>
            {defined(onSubmit) && (
              <Button
                intent="primary"
                disabled={
                  !defined(area) ||
                  !defined(subarea) ||
                  !defined(subject) ||
                  isSaving
                }
                title={submitText ?? "Spara"}
                onClick={handleSubmit}
              ></Button>
            )}
          </>
        </ButtonsFooterRight>
      </ButtonsFooter>
    </div>
  );
}

export function SubjectPathSelection(props: {
  area?: string;
  subarea?: string;
  subject?: string;
  handleUpdateSelection: (path: string[]) => void;
}) {
  const { area, subarea, subject, handleUpdateSelection } = props;
  const subjectPath = [area, subarea, subject];
  const categories = useContext(CategoriesContext);
  const { areas, subareas, subjects } = prepareCategoryParts(
    subjectPath,
    categories
  );
  return (
    <div className="subject-path-selection">
      <SelectOrCreate
        onSelect={(key: string) => {
          const subjectPath = [key];
          handleUpdateSelection(subjectPath);
        }}
        dropdownProps={{
          dropdownWidth: "auto",
          label: "Område",
          options: areas.map((area) => ({ key: area, text: area })),
          selectedKey: area ?? null,
        }}
      ></SelectOrCreate>

      <SelectOrCreate
        onSelect={(key: string) => {
          const path = [area, key as string] as string[];
          handleUpdateSelection(path);
        }}
        dropdownProps={{
          disabled: !defined(area),
          label: "Delområde",
          options: subareas?.map((area) => ({ key: area, text: area })) ?? [],
          selectedKey: subarea ?? null,
        }}
      ></SelectOrCreate>

      <SelectOrCreate
        onSelect={(key: string) => {
          const path = [area, subarea, key] as string[];
          handleUpdateSelection(path);
        }}
        dropdownProps={{
          disabled: !defined(area) || !defined(subarea),
          label: "Ämne",
          options: subjects?.map((area) => ({ key: area, text: area })) ?? [],
          selectedKey: subject ?? null,
        }}
      ></SelectOrCreate>
    </div>
  );
}

function SelectOrCreate(props: {
  onSelect: (key: string) => void;
  dropdownProps: IDropdownProps;
}) {
  const { disabled, label, options, selectedKey } = props.dropdownProps;
  const [create, setCreate] = useState(false);
  return (
    <div className="select-or-create">
      {create || options.length === 0 ? (
        <>
          <TextField
            disabled={disabled}
            label={label}
            onChange={(e) => {
              if (defined(e.currentTarget.value)) {
                props.onSelect(e.currentTarget.value);
              }
            }}
            suffix={"Ny kategori"}
          ></TextField>
          {options.length > 0 && (
            <>
              <span className="or-indicator">eller</span>
              <Button
                title="Välj existerande"
                onClick={() => setCreate(!create)}
              ></Button>
            </>
          )}
        </>
      ) : (
        <>
          <Dropdown
            dropdownWidth="auto"
            disabled={disabled}
            label={label}
            options={options}
            selectedKey={selectedKey}
            onChange={(e, item) => {
              if (defined(item)) {
                props.onSelect(item.key as string);
              }
            }}
          ></Dropdown>
          <span className="or-indicator">eller</span>
          <Button
            title="Skapa nytt"
            onClick={() => setCreate(!create)}
          ></Button>
        </>
      )}
    </div>
  );
}

function ExistingMeasures(props: { path: string[] }) {
  const [measures, setMeasures] = useState<MeasureThin[]>([]);
  const showDraftData = useContext(ShowDraftDataContext);
  useEffect(() => {
    getMeasuresWithCache(props.path, false, false, showDraftData).then(
      (res) => {
        setMeasures(res);
      }
    );
  }, [props.path, showDraftData]);

  if (measures.length === 0) {
    return null;
  }

  return (
    <section className="existing-measures">
      <h3>Nuvarande mått under valt ämne</h3>
      <ul>
        {measures.filter(measureIsNotRetired).map((measure) => {
          return (
            <li key={measure.data_id}>
              {measure.measure}{" "}
              <span className="text-subdued">
                ({measure.data_id}, {measure.label})
              </span>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
