import _ from "lodash";
import { useCallback, useMemo } from "react";

import { MeasureSelectionSetter } from "../../../../../../lib/application/requests/common_requests";
import { useSetBreakdownsMulti } from "../../../../../../lib/application/state/actions/selections/shared/useSetBreakdowns";
import { defined } from "../../../../../../lib/core/defined";
import {
  MeasureSelectionSurvey,
  MeasureSelectionSurveyString,
  SURVEY_MEASURE_FILTER_NONE_SELECTED,
} from "../../../../../../lib/domain/measure/definitions";
import { MeasureDropdown } from "./MeasureDropdown";
import { BreakdownSelectMultiV2, SELECT_AT_LEAST_1_OPTION } from "./shared";

type MeasureSelectionProps = {
  hideMeasure?: boolean;
  isGroupingSelection: boolean;
  measureSelection: MeasureSelectionSurvey | MeasureSelectionSurveyString;
  setMeasureSelection: MeasureSelectionSetter;
  adminPreviewMode?: boolean;
  dataframeMode?: boolean;
};

const SURVEY_FILTERS_LABEL = "Filter";

export function MeasureSelectionComponentSurvey(props: MeasureSelectionProps) {
  const { measureSelection, setMeasureSelection } = props;
  const setBreakdownsMulti = useSetBreakdownsMulti(
    measureSelection,
    setMeasureSelection
  );

  const measure = measureSelection.measure;
  const dimensions = measure.dimensions;

  const valueDimension = useMemo(
    () => dimensions.find((d) => d.type === "survey_value"),
    [dimensions]
  );

  const surveyFilters = useMemo(
    () => dimensions.filter((d) => d.type === "survey_background"),
    [dimensions]
  );
  const selectedSurveyFilters = surveyFilters.filter((f) => {
    return defined(measureSelection.breakdowns[f.data_column]);
  });

  const clearBreakdownSelection = useCallback(
    (label: string) => {
      const updatedBreakdowns = { ...measureSelection.breakdowns, [label]: [] };
      setMeasureSelection({
        ...measureSelection,
        breakdowns: updatedBreakdowns,
      });
    },
    [measureSelection, setMeasureSelection]
  );

  const setSelectedSurveyFilters = useCallback(
    (filter_data_columns: string[]) => {
      const newBreakdowns = _.fromPairs(
        filter_data_columns.map((v) => [v, []])
      );

      const breakdowns = measureSelection.breakdowns;
      const updatedBreakdowns = {
        ...newBreakdowns,
        ...breakdowns,
      };

      const updatedSelection = {
        ...measureSelection,
        breakdowns: updatedBreakdowns,
      };
      setMeasureSelection(updatedSelection);
    },
    [measureSelection, setMeasureSelection]
  );

  const unselectSurveyFilters = useCallback(
    (dataColumns: string[]) => {
      const breakdowns = { ...measureSelection.breakdowns };
      for (const code of dataColumns) {
        delete breakdowns[code];
      }
      setMeasureSelection({ ...measureSelection, breakdowns });
    },
    [measureSelection, setMeasureSelection]
  );

  const clearSurveyFilters = useCallback(() => {
    unselectSurveyFilters(surveyFilters.map((f) => f.data_column));
  }, [surveyFilters, unselectSurveyFilters]);

  const FILTERS_MOCK_COLUMN = "filters-mock-column";
  const surveyFiltersSelection = useMemo(() => {
    const selected: { [key: string]: number[] } = { [FILTERS_MOCK_COLUMN]: [] };
    for (const f of surveyFilters) {
      if (defined(measureSelection.breakdowns[f.data_column])) {
        selected[FILTERS_MOCK_COLUMN].push(f.dimension_id);
      }
    }

    return selected;
  }, [measureSelection.breakdowns, surveyFilters]);

  if (props.dataframeMode) {
    return (
      <MeasureDropdown
        setMeasureSelection={props.setMeasureSelection}
        measureSelection={measureSelection}
      ></MeasureDropdown>
    );
  }

  return (
    <>
      {props.adminPreviewMode === true || props.hideMeasure === true ? (
        ""
      ) : (
        <MeasureDropdown
          setMeasureSelection={props.setMeasureSelection}
          measureSelection={measureSelection}
        ></MeasureDropdown>
      )}
      <div className="clear-row"></div>
      <div className="breakdowns-container">
        {dimensions
          .filter((d) => d.type === "survey_subquestion")
          .map((subq) => {
            return (
              <BreakdownSelectMultiV2
                key={subq.dimension_id}
                noneSelectedError={SELECT_AT_LEAST_1_OPTION}
                dimension={subq}
                selectedBreakdowns={measureSelection.breakdowns}
                clearBreakdownValues={clearBreakdownSelection}
                setBreakdowns={(_, keys, selected) => {
                  setBreakdownsMulti(subq.data_column, keys, selected);
                }}
              ></BreakdownSelectMultiV2>
            );
          })}

        {measureSelection.valueType !== "survey_string" &&
          measureSelection.measure.survey_question_type !== "multichoice" &&
          defined(valueDimension) && (
            <BreakdownSelectMultiV2
              key={valueDimension.dimension_id}
              noneSelectedError={SELECT_AT_LEAST_1_OPTION}
              dimension={valueDimension}
              selectedBreakdowns={measureSelection.breakdowns}
              clearBreakdownValues={clearBreakdownSelection}
              setBreakdowns={(_, keys, selected) => {
                setBreakdownsMulti(valueDimension.data_column, keys, selected);
              }}
            ></BreakdownSelectMultiV2>
          )}

        <BreakdownSelectMultiV2
          key="filters"
          noneSelectedLabel="Inget valt"
          dimension={{
            data_column: FILTERS_MOCK_COLUMN,
            label: SURVEY_FILTERS_LABEL,
            dimension_id: -1,
            sort_order: 0,
            type: "survey_background",
            values: surveyFilters.map((f) => {
              return {
                id: f.dimension_id,
                label: f.label,
                sort_order: f.sort_order,
              };
            }),
          }}
          clearBreakdownValues={clearSurveyFilters}
          selectedBreakdowns={surveyFiltersSelection}
          setBreakdowns={(data_column, keys, selected) => {
            const prevSelected = surveyFiltersSelection[
              FILTERS_MOCK_COLUMN
            ].map((key) => surveyFilters.find((f) => f.dimension_id === key))
              .filter(defined)
              .map((d) => d.data_column);
            const dataColumns = keys
              .map((key) => surveyFilters.find((f) => f.dimension_id === key))
              .filter(defined)
              .map((d) => d.data_column);

            if (selected) {
              setSelectedSurveyFilters(
                _.uniq([...prevSelected, ...dataColumns])
              );
            } else {
              unselectSurveyFilters(dataColumns);
            }
          }}
        ></BreakdownSelectMultiV2>

        {selectedSurveyFilters.map((f) => {
          return (
            <BreakdownSelectMultiV2
              dimension={f}
              key={f.dimension_id}
              noneSelectedLabel={SURVEY_MEASURE_FILTER_NONE_SELECTED}
              selectedBreakdowns={measureSelection.breakdowns}
              clearBreakdownValues={clearBreakdownSelection}
              setBreakdowns={(label, keys, selected) => {
                setBreakdownsMulti(f.data_column, keys, selected);
              }}
            ></BreakdownSelectMultiV2>
          );
        })}
      </div>
    </>
  );
}
