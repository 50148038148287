export class Timer {
  private _startTime: number;
  constructor() {
    this._startTime = Date.now();
  }

  elapsedMs(): number {
    return Date.now() - this._startTime;
  }
}
