import { SimpleCache } from "../../../../../core/SimpleCache";
import { defined } from "../../../../../core/defined";
import { Box2D } from "../../../../../core/space/box";
import { TextStyle } from "../TextStyle";
import { SVGNamespace } from "../definitions";

export const MAX_LABEL_WIDTH = 170;
// Used only in testing
export const APPROX_CHAR_WIDTH_TESTING = 8.5;
export const APPROX_CHAR_HEIGHT_TESTING = 17.5;

const measurementsCache = new SimpleCache<Box2D>(400);

export function measureText(
  text: string,
  style: TextStyle,
  rotation?: number
): Box2D {
  const key = text + style.serialize() + (rotation ?? 0);
  const cachedValue = measurementsCache.get(key);
  if (defined(cachedValue)) {
    return cachedValue;
  }
  const containerId = "size-measurements";
  const element = document.getElementById(containerId);
  if (!defined(element)) {
    // When testing, use predefined char measurements
    if (process.env.NODE_ENV === "test") {
      return {
        height: APPROX_CHAR_HEIGHT_TESTING,
        width: APPROX_CHAR_WIDTH_TESTING * text.length,
      };
    }
    throw new Error("Could not find measurement root");
  }

  const svg = document.createElementNS(SVGNamespace, "svg");
  const textElement = document.createElementNS(SVGNamespace, "text");
  if (defined(rotation)) {
    textElement.setAttribute("transform", `rotate(${rotation})`);
  }
  const attributes = [
    "font-size: " + style.fontSizeAttr(),
    "font-weight: " + style.fontWeightAttr(),
    "font-family: " + style.fontFamilyAttr(),
    "font-style: " + style.fontStyleAttr(),
  ];
  textElement.setAttribute("style", attributes.join("; "));
  const textNode = document.createTextNode(text);
  textElement.appendChild(textNode);
  svg.appendChild(textElement);
  element.appendChild(svg);

  const boundingRect = textElement.getBoundingClientRect();

  element.removeChild(svg);
  const result = { height: boundingRect.height, width: boundingRect.width };
  measurementsCache.set(key, result);
  return result;
}

export function calculateTextWidth(text: string, textStyle: TextStyle): number {
  return measureText(text, textStyle).width;
}

export function calculateTextHeight(
  text: string,
  textStyle: TextStyle,
  rotation?: number
): number {
  return measureText(text, textStyle, rotation).height;
}
