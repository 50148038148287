import { IconProps } from "@blueprintjs/core";
import { TextField } from "@fluentui/react";
import { useCallback } from "react";
import { AlertBox } from "../../../../components/AlertBox";
import { Button, ButtonWithSideSpinner } from "../../../../components/Button";
import { H3Icon } from "../../../../components/Headings";
import { FluentIcon } from "../../../../components/Icons";
import { copyToClipboard } from "../../../../lib/application/browser/copyToClipboard";
import { SharingInfoReloader } from "../../../../lib/application/contexts";
import { shareCodeToLink } from "../../../../lib/application/files/sharing";
import {
  highestRole,
  ISharingInfo,
  LinkShare,
  OrgShare,
  SingleShareBase,
  UserShare,
} from "../../../../lib/application/files/SharingInfo";
import {
  displayNodeTypeDefinite,
  NodeTypeRegular,
} from "../../../../lib/application/files/tree";
import { useHttpRequestWithStatus } from "../../../../lib/application/hooks/useRequestWithStatus";
import { LoadingResult } from "../../../../lib/application/loading/LoadingResult";
import {
  adminDeleteHelpLibraryRootNode,
  adminDeleteSampleLibraryRootNode,
} from "../../../../lib/application/requests/admin/libraries";
import {
  deleteLink,
  deleteSharing,
} from "../../../../lib/application/requests/docs/documents";
import { RoleDto } from "../../../../lib/application/requests/docs/dto";
import { capitalize } from "../../../../lib/core/capitalize";
import { classNames } from "../../../../lib/core/classNames";
import { defined } from "../../../../lib/core/defined";
import { voidFunc } from "../../../../lib/core/voidFunc";
import { HttpResult } from "../../../../lib/infra/HttpResult";

interface RemoveHandler<T extends number | string> {
  handleRemove: (id: T) => Promise<HttpResult<void>>;
}

interface Props {
  sharingInfo: ISharingInfo;
  reloadSharingInfo?: SharingInfoReloader;
  nodeId: number;
  nodeType: NodeTypeRegular;
}

export function ManageAccessPivot(props: Props) {
  const { sharingInfo, nodeType, reloadSharingInfo, nodeId } = props;
  const shares = sharingInfo.shares();

  const handleRemoveShare = useCallback(
    (shareId: number) => {
      return deleteSharing(shareId).then((res) => {
        res.match({ ok: () => reloadSharingInfo?.(), err: voidFunc });
        return res;
      });
    },
    [reloadSharingInfo]
  );

  const handleRemoveSharedLink = useCallback(
    (linkId: string) => {
      return deleteLink(linkId).then((res) => {
        res.match({ ok: () => reloadSharingInfo?.(), err: voidFunc });
        return res;
      });
    },
    [reloadSharingInfo]
  );

  const handleRemoveHelpLib = useCallback(() => {
    return adminDeleteHelpLibraryRootNode(nodeId).then((res) => {
      res.match({ ok: () => reloadSharingInfo?.(), err: voidFunc });
      return res;
    });
  }, [nodeId, reloadSharingInfo]);

  const handleRemoveSampleLib = useCallback(() => {
    return adminDeleteSampleLibraryRootNode(nodeId).then((res) => {
      res.match({ ok: () => reloadSharingInfo?.(), err: voidFunc });
      return res;
    });
  }, [nodeId, reloadSharingInfo]);

  const implicitLinkRole = highestRole(
    shares.links.filter((l) => !l.explicit).map((l) => l.role)
  );

  return (
    <div className="manage-access-pivot">
      {!sharingInfo.isShared() && (
        <AlertBox intent="primary">
          <H3Icon icon={"lock"} text="Ingen annan har åtkomst."></H3Icon>
        </AlertBox>
      )}
      {!sharingInfo.isOwner() && (
        <>
          <H3Icon icon="user" text="Ägare"></H3Icon>
          <p>{sharingInfo.owner}</p>
        </>
      )}

      {defined(shares.organization) && (
        <OrgAccess
          hasAuthorizationToRemove={sharingInfo.isOwner()}
          nodeType={nodeType}
          share={shares.organization}
          handleRemove={handleRemoveShare}
        ></OrgAccess>
      )}
      {defined(shares.helpLibrary) && (
        <LibraryAccess
          hasAuthorizationToRemove={sharingInfo.isOwner()}
          handleRemove={handleRemoveHelpLib}
          nodeType={nodeType}
          share={shares.helpLibrary}
          libraryName="Hjälpbibliotek"
        ></LibraryAccess>
      )}
      {defined(shares.sampleLibrary) && (
        <LibraryAccess
          hasAuthorizationToRemove={sharingInfo.isOwner()}
          handleRemove={handleRemoveSampleLib}
          nodeType={nodeType}
          share={shares.sampleLibrary}
          libraryName="Exempelbibliotek"
        ></LibraryAccess>
      )}
      {shares.links.length > 0 && (
        <div className="section">
          <H3Icon icon="link" text="Delade länkar" />
          <p>
            Alla användare som fått en länk kan komma åt{" "}
            {displayNodeTypeDefinite(nodeType)}. Om du tar bort delningen dras
            åtkomst för dessa användare tillbaka.
          </p>
          {defined(implicitLinkRole) && (
            <AlertBox>
              <div className="alert-with-icon">
                <RoleIndicatorIcon
                  share={{ role: implicitLinkRole }}
                ></RoleIndicatorIcon>
                <span>
                  {capitalize(displayNodeTypeDefinite(nodeType))} delas
                  automatiskt eftersom mappen{" "}
                  {nodeType === "document" ? "det" : "den"} ligger i är delad
                  via länk.
                </span>
              </div>
            </AlertBox>
          )}
          {shares.links
            .filter((link) => link.explicit)
            .map((linkShare) => (
              <LinkShareLine
                key={linkShare.code}
                share={linkShare}
                handleRemove={handleRemoveSharedLink}
              ></LinkShareLine>
            ))}
        </div>
      )}
      {shares.users.length > 0 && (
        <div className="section">
          <H3Icon icon="user" text="Användare med direkt åtkomst" />
          <p>
            Dessa användare har direkt åtkomst till{" "}
            {displayNodeTypeDefinite(nodeType)}.
          </p>
          {shares.users.map((userShare) => (
            <UserAccessLine
              key={userShare.sharingId}
              share={userShare}
              handleRemove={handleRemoveShare}
            ></UserAccessLine>
          ))}
        </div>
      )}
    </div>
  );
}

function LibraryAccess(
  props: {
    libraryName: string;
    share: SingleShareBase;
    nodeType: NodeTypeRegular;
    hasAuthorizationToRemove: boolean;
  } & RemoveHandler<number>
) {
  const [status, handleRemove] = useHttpRequestWithStatus(props.handleRemove);
  return (
    <section>
      <h3>{props.libraryName}</h3>
      <p>
        {capitalize(displayNodeTypeDefinite(props.nodeType))} är delad med{" "}
        {props.libraryName.toLowerCase()}et.
      </p>
      <div className="sharing-line">
        <ButtonRemoveSharing
          disabled={!props.hasAuthorizationToRemove}
          id={props.share.sharingId}
          handleRemove={handleRemove}
          status={status}
        ></ButtonRemoveSharing>
      </div>
    </section>
  );
}

function OrgAccess(
  props: {
    share: OrgShare;
    nodeType: NodeTypeRegular;
    hasAuthorizationToRemove: boolean;
  } & RemoveHandler<number>
) {
  const { nodeType } = props;
  const [status, handleRemove] = useHttpRequestWithStatus(props.handleRemove);

  return (
    <div className="section">
      <H3Icon icon="office" text="Delning med din organisation" />
      <p>Alla medlemmar i din organisation har åtkomst.</p>
      {props.share.explicit ? (
        <div className="sharing-line">
          <RoleIndicator className="grow" share={props.share}></RoleIndicator>
          <ButtonRemoveSharing
            disabled={!props.hasAuthorizationToRemove}
            id={props.share.sharingId}
            handleRemove={handleRemove}
            status={status}
          ></ButtonRemoveSharing>
        </div>
      ) : (
        <AlertBox>
          <div className="alert-with-icon">
            <RoleIndicatorIcon
              share={{ role: props.share.role }}
            ></RoleIndicatorIcon>
            <span>
              Delas automatiskt med din organisation för att mappen som{" "}
              {displayNodeTypeDefinite(nodeType)} ligger i är delad.
            </span>
          </div>
        </AlertBox>
      )}
      {status.isErr() && (
        <AlertBox intent="danger">
          <span>Något gick fel när delningen skulle tas bort.</span>
        </AlertBox>
      )}
    </div>
  );
}

function ButtonRemoveSharing<T extends string | number>(
  props: {
    id: T;
    status: LoadingResult<any, any>;
    disabled?: boolean;
  } & RemoveHandler<T>
) {
  const { status, handleRemove } = props;

  return (
    <ButtonWithSideSpinner
      hoverTitle={
        props.disabled
          ? "Du har inte behörighet att ta bort denna delning"
          : undefined
      }
      disabled={props.disabled}
      loading={status.isInProgress()}
      onClick={() => handleRemove(props.id)}
      title="Ta bort"
    ></ButtonWithSideSpinner>
  );
}

function LinkShareLine(
  props: {
    share: LinkShare;
  } & RemoveHandler<string>
) {
  const { share } = props;
  const completeLink = shareCodeToLink(share.code);
  const [status, handleRemove] = useHttpRequestWithStatus(props.handleRemove);
  return (
    <div className="sharing-line-container">
      <div className="sharing-line">
        <RoleIndicator share={props.share}></RoleIndicator>
        <TextField className="grow" readOnly value={completeLink}></TextField>
        <Button
          title="Kopiera"
          onClick={() => copyToClipboard(completeLink)}
        ></Button>
        <ButtonRemoveSharing
          status={status}
          id={share.code}
          handleRemove={handleRemove}
        ></ButtonRemoveSharing>
      </div>
      {status.isErr() && (
        <AlertBox intent="danger">
          <span>Något gick fel när delningen skulle tas bort.</span>
        </AlertBox>
      )}
    </div>
  );
}

function UserAccessLine(
  props: {
    share: UserShare;
  } & RemoveHandler<number>
) {
  const [status, handleRemove] = useHttpRequestWithStatus(props.handleRemove);
  return (
    <div className="sharing-line-container">
      <div className="sharing-line">
        <RoleIndicator share={props.share}></RoleIndicator>
        <span className="grow">{props.share.userEmail}</span>
        <ButtonRemoveSharing
          id={props.share.sharingId}
          handleRemove={handleRemove}
          status={status}
        ></ButtonRemoveSharing>
      </div>
      {status.isErr() && (
        <AlertBox intent="danger">
          <span>Något gick fel när delningen skulle tas bort.</span>
        </AlertBox>
      )}
    </div>
  );
}

function RoleIndicatorIcon<T extends { role: RoleDto }>(props: {
  share: T;
  className?: string;
}) {
  const { share } = props;
  const roleInfo = getRoleInfo(share.role);
  return (
    <div className={classNames("role-indicator", props.className)}>
      <FluentIcon className="icon" name={roleInfo.icon} size="sm" />
    </div>
  );
}

function RoleIndicator<T extends { role: RoleDto }>(props: {
  share: T;
  className?: string;
}) {
  const { share } = props;
  const roleInfo = getRoleInfo(share.role);
  return (
    <div className={classNames("role-indicator", props.className)}>
      <FluentIcon className="icon" name={roleInfo.icon} size="sm" />
      <em>{roleInfo.description}</em>
    </div>
  );
}

function getRoleInfo(role: RoleDto): {
  description: string;
  icon: IconProps["icon"];
} {
  switch (role) {
    case "owner":
      return {
        description: "Ägare",
        icon: "office",
      };
    case "editor":
      return {
        description: "Kan redigera",
        icon: "edit",
      };
    case "viewer":
      return {
        description: "Kan visa",
        icon: "eye-open",
      };
  }
}
