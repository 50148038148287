import { defined } from "../../core/defined";
import { Either } from "../../core/func/monad";
import { Progress } from "../../core/progress";
import { voidFunc } from "../../core/voidFunc";
import { HttpError, HttpResult } from "../../infra/HttpResult";
import { NotReady, NotReadyOrReloading } from "./load_status";

export class ReloadingResultWithData<E, T>
  implements Either<NotReadyOrReloading<E, T>, T>
{
  private constructor(
    private readonly _raw:
      | { type: "not-ready"; data: NotReadyOrReloading<E, T> }
      | { type: "ready"; data: T }
  ) {}

  fold<LeftOut, RightOut>(
    onLeft: (arg: NotReadyOrReloading<E, T>) => LeftOut,
    onRight: (arg: T) => RightOut
  ) {
    if (this._raw.type === "not-ready") {
      return onLeft(this._raw.data);
    }
    return onRight(this._raw.data);
  }

  match<LeftOut, RightOut>(matcher: {
    notReady: (arg: NotReadyOrReloading<E, T>) => LeftOut;
    ready: (arg: T) => RightOut;
  }): LeftOut | RightOut {
    if (this._raw.type === "not-ready") {
      return matcher.notReady(this._raw.data);
    }
    return matcher.ready(this._raw.data);
  }

  map<U>(f: (input: T) => U): Either<NotReady<E>, U> {
    if (this._raw.type === "not-ready") {
      const data = this._raw.data;
      if (data.type === Progress.InProgress && defined(data.oldData)) {
        return new ReloadingResultWithData<E, U>({
          type: "not-ready",
          data: {
            type: Progress.InProgress,
            oldData: f(data.oldData),
          },
        });
      }
      return new ReloadingResultWithData<E, U>({
        type: "not-ready",
        data: { type: Progress.InProgress },
      });
    }
    return new ReloadingResultWithData<E, U>({
      type: "ready",
      data: f(this._raw.data),
    });
  }

  toReloading(): ReloadingResultWithData<E, T> {
    const raw = this._raw;
    return new ReloadingResultWithData<E, T>({
      type: "not-ready",
      data: {
        type: Progress.InProgress,
        oldData: raw.type === "ready" ? raw.data : undefined,
      },
    });
  }

  isInProgress(): boolean {
    return (
      this._raw.type === "not-ready" &&
      this._raw.data.type === Progress.InProgress
    );
  }

  isReady(): boolean {
    return this._raw.type === "ready";
  }

  isErr(): boolean {
    return (
      this._raw.type === "not-ready" && this._raw.data.type === Progress.Error
    );
  }

  getErr(): E | undefined {
    return this._raw.type === "not-ready" &&
      this._raw.data.type === Progress.Error
      ? this._raw.data.err
      : undefined;
  }

  getLastAvailableData(): T | undefined {
    if (
      this._raw.type === "not-ready" &&
      this._raw.data.type === Progress.InProgress
    ) {
      return this._raw.data.oldData;
    }
    return this._raw.type === "ready" ? this._raw.data : undefined;
  }

  isNotStarted(): boolean {
    return (
      this._raw.type === "not-ready" &&
      this._raw.data.type === Progress.NotStarted
    );
  }

  static from<T>(data: T): ReloadingResultWithData<any, T> {
    return new ReloadingResultWithData({
      type: "ready",
      data,
    });
  }

  static fromHttpResult<T>(
    data: HttpResult<T>
  ): ReloadingResultWithData<HttpError, T> {
    return data.match({
      err: (err) => ReloadingResultWithData.err(err),
      ok: (ok) => ReloadingResultWithData.from(ok),
    });
  }

  static notStarted<E>(): ReloadingResultWithData<E, any> {
    return new ReloadingResultWithData({
      type: "not-ready",
      data: { type: Progress.NotStarted },
    });
  }

  static inProgress<E>(): ReloadingResultWithData<E, any> {
    return new ReloadingResultWithData({
      type: "not-ready",
      data: { type: Progress.InProgress },
    });
  }

  static err<E>(err: E): ReloadingResultWithData<E, any> {
    return new ReloadingResultWithData({
      type: "not-ready",
      data: { type: Progress.Error, err },
    });
  }

  static anyNotReady<E>(
    ...results: ReloadingResultWithData<E, any>[]
  ): ReloadingResultWithData<E, any> | undefined {
    for (const result of results) {
      const notReady = result.match({
        notReady: () => true,
        ready: voidFunc,
      });
      if (notReady) {
        return result;
      }
    }
  }

  static anyErr<E>(
    ...results: ReloadingResultWithData<E, any>[]
  ): ReloadingResultWithData<E, any> | undefined {
    for (const result of results) {
      const err = result.match({
        notReady: (data) => data.type === Progress.Error,
        ready: voidFunc,
      });
      if (err) {
        return result;
      }
    }
  }

  static matchBothReady<E, T>(
    left: ReloadingResultWithData<E, T>,
    right: ReloadingResultWithData<E, T>,
    onMatched: (left: T, right: T) => void
  ): void {
    left.match({
      notReady: voidFunc,
      ready: (leftData) => {
        right.match({
          notReady: voidFunc,
          ready: (rightData) => {
            onMatched(leftData, rightData);
          },
        });
      },
    });
  }
}
