import {
  IDropdownStyleProps,
  IDropdownStyles,
  IStyleFunctionOrObject,
  ITextFieldStyleProps,
  ITextFieldStyles,
} from "@fluentui/react";

// STYLE
export const dropdownStyles: IStyleFunctionOrObject<
  IDropdownStyleProps,
  IDropdownStyles
> = {
  label: { fontSize: "12px" },
  dropdownOptionText: { overflow: "visible", whiteSpace: "normal" },
  dropdownItem: { height: "auto" },
};
export const dropdownStylesCondensed: IStyleFunctionOrObject<
  IDropdownStyleProps,
  IDropdownStyles
> = {
  label: { fontSize: "12px" },
  //   dropdown: { fontSize: "12px" },
  //   dropdownItem: { fontSize: "12px" },
};

export const textFieldStylesCondensed: IStyleFunctionOrObject<
  ITextFieldStyleProps,
  ITextFieldStyles
> = {
  subComponentStyles: {
    label: { fontSize: "3px", backgroundColor: "blue" },
  },
};
