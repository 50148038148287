import { IconProps } from "@blueprintjs/core";
import {
  ContextualMenu,
  DirectionalHint,
  IContextualMenuItem,
} from "@fluentui/react";
import { useRef, useState } from "react";

import { FluentIcon, IconSize } from "./Icons";

interface Props {
  icon: IconProps["icon"];
  items: IContextualMenuItem[];
  directionalHint?: DirectionalHint;
  size?: IconSize;
}
export function IconContextMenu(props: Props) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <FluentIcon
        onClick={() => setOpen(!open)}
        name={props.icon}
        size={props.size ?? "sm"}
      ></FluentIcon>
      <ContextualMenu
        directionalHint={props.directionalHint}
        items={props.items}
        hidden={!open}
        target={ref}
        onDismiss={() => setOpen(false)}
      />
    </div>
  );
}
