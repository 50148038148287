import { Result } from "../Result";

export type NumParseError = "unknown-parse-error";

const unicodeMinus = "−";

export function parseSwedishNumber(
  input: string
): Result<number, NumParseError> {
  const res = parseFloat(
    input.trim().replace(/,/g, ".").replace(unicodeMinus, "-")
  );
  if (!isNaN(res)) {
    return Result.fromOk(res);
  }
  return Result.fromErr("unknown-parse-error");
}

export function parseFloatOr(input: any, defaultValue: number): number {
  try {
    return parseFloat(input);
  } catch (e) {
    return defaultValue;
  }
}
