import { defined } from "../../core/defined";
import {
  DimensionV2Dto,
  DimensionValueV2Dto,
  SelectedDimensionsV2,
} from "./definitions";

export function makeBreakdownsSoundMut(
  breakdowns: SelectedDimensionsV2,
  updatedDim: DimensionV2Dto,
  dims: DimensionV2Dto[]
): SelectedDimensionsV2 {
  const breakdownSelection = { ...breakdowns };
  let parentDim = updatedDim;
  let childDim = dims.find((d) => d.parent_id === updatedDim?.dimension_id);
  while (childDim !== undefined) {
    const parentSelectedIds = defined(parentDim)
      ? breakdownSelection[parentDim.data_column] ?? null
      : undefined;
    const applicableValues =
      getApplicableDimensionValues(childDim, parentSelectedIds).map(
        (v) => v.id
      ) ?? [];
    const filteredValues = breakdownSelection[childDim.data_column]?.filter(
      (id) => applicableValues.includes(id)
    );
    breakdownSelection[childDim.data_column] =
      defined(filteredValues) && filteredValues.length > 0
        ? filteredValues
        : defined(applicableValues[0])
        ? [applicableValues[0]]
        : [];

    parentDim = childDim;
    const currentChildId = childDim.dimension_id;
    childDim = undefined;
    for (const d of dims) {
      if (d.parent_id === currentChildId) {
        childDim = d;
        break;
      }
    }
  }

  return breakdownSelection;
}

export function getApplicableDimensionValues(
  dimension: DimensionV2Dto,
  parentDimensionSelectedValueIds?: null | number[]
): DimensionValueV2Dto[] {
  if (parentDimensionSelectedValueIds === null) {
    return [];
  }
  if (!defined(parentDimensionSelectedValueIds)) {
    return dimension.values ?? [];
  }

  const lookup = new Set(parentDimensionSelectedValueIds);
  return (
    dimension.values?.filter((v) => {
      return defined(v.parent_id) && lookup.has(v.parent_id);
    }) ?? []
  );
}
