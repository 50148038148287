import React, {
  useContext,
  useCallback,
  useMemo,
  InputHTMLAttributes,
} from "react";
import { useRecoilState } from "recoil";
import { TextField } from "@fluentui/react";

import { FluentIcon } from "../../../../../../components/Icons";
import {
  ShowDraftDataContext,
  CardUpdateCountContext,
} from "../../../../../../lib/application/contexts";
import { useToggle } from "../../../../../../lib/application/hooks/useToggle";
import { useReadMicroCardState } from "../../../../../../lib/application/state/actions/micro/updates";
import { useExtendedAppearanceSettings } from "../../../../../../lib/application/state/actions/useExtendedAppearanceSettings";
import { useSaveCard } from "../../../../../../lib/application/state/actions/useSaveDocument";
import { MicroDataFilter } from "../../../../../../lib/application/state/stats/document-core/core-micro";
import { singleMicroCardQuery } from "../../../../../../lib/application/state/stats/document-core/queries/microCard";
import { defined } from "../../../../../../lib/core/defined";
import { GeoTypeMicro } from "../../../../../../lib/domain/geography";
import {
  DimensionV2Dto,
  FilterMeasureMicro,
  SelectedDimensionsV2,
} from "../../../../../../lib/domain/measure/definitions";
import {
  ComputedMeasurementType,
  MicroMeasureDto,
} from "../../../../../../lib/infra/api_responses/micro_dataset";
import { useGeoTree } from "../useGeoTree";
import {
  SelectedMicroMeasures,
  renderMicroMeasureTypeIcon,
  useVariablesStateFilterSelection,
} from "./_shared";
import { useFilterCallbacks } from "../../../../../../lib/application/state/actions/micro/filter_hooks";
import { FilterSelector } from "./Filters";
import { DimensionSelectionOuter } from "./DimensionSelectionOuter";
import { VariablesConfig } from "./VariablesConfig";
import { voidFunc } from "../../../../../../lib/core/voidFunc";
import { DocCardMicro } from "../../../../../../lib/application/state/stats/document-core/core";
import { ThirdPartyMicroCardSettings } from "../../../../../../lib/application/third_party_docs";
import { classNames } from "../../../../../../lib/core/classNames";
import { StyledDropdownCondensed } from "../../card_general/StyledDropdown";
import { dropdownStyles } from "../../../../docs/cards/card_general/style";
import { useLoadableHttpResource } from "../../../../../../lib/application/hooks/useLoadableResource";
import { getMicroDimensionsWithCache } from "../../../../../../lib/application/requests/datasets/micro";
import { NotReadyHttpErrNotice } from "../../../../../../components/Loading";
import { sortBy } from "lodash";

interface Props {
  cardId: string;
  mainMeasureGeoType?: GeoTypeMicro;
  filterMeasures: FilterMeasureMicro[];
  changePending: boolean;
  thirdPartySettings?: ThirdPartyMicroCardSettings;
}
export function FilterMeasuresThirdPartyDoc(props: Props) {
  const { cardId, filterMeasures, changePending } = props;
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: cardId })
  );
  const [filterMeasuresOpen, toggleFilterMeasuresOpen] = useToggle(true);
  const geoTree = useGeoTree();
  const appearanceSettings = useExtendedAppearanceSettings();
  const handleSaveCard = useSaveCard();
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const readCard = useReadMicroCardState(cardId);

  const {
    handleSetFilter,
    handleClearBreakdowns,
    handleRemoveFilter,
    handleSetSingleBreakdownValue,
  } = useFilterCallbacks(cardId, filterMeasures);

  const numActiveFilters = filterMeasures.filter((f) => {
    const filters = f.filterSet?.filters;
    return defined(filters) && filters.length > 0;
  }).length;

  const selectedFilterMeasures: SelectedMicroMeasures = useMemo(() => {
    return filterMeasures
      .map((f) => {
        const measureId = f.measureSelection?.measure.id;
        if (!defined(measureId)) {
          return;
        }
        return [measureId, f.measureSelection?.measure.computed?.type] as [
          number,
          ComputedMeasurementType
        ];
      })
      .filter(defined);
  }, [filterMeasures]);

  if (numActiveFilters === 0) {
    return null;
  }

  return (
    <div className="filter-measures-container third-party-filter-measures-container">
      <strong>Områdesfilter</strong>
      <div className="filter-measures-list">
        {filterMeasures.map((filterMeasure, index) => (
          <React.Fragment key={filterMeasure.id}>
            {index !== 0 && (
              <div className="filter-divider">
                <FluentIcon name="arrow-down" size="sm" />
              </div>
            )}
            <SingleFilterMeasure
              thirdPartySettings={props.thirdPartySettings}
              selectedFilterMeasures={selectedFilterMeasures}
              index={index}
              card={card}
              handleClearBreakdowns={handleClearBreakdowns}
              handleRemove={handleRemoveFilter}
              handleSetFilter={handleSetFilter}
              handleSetSingleBreakdownValue={handleSetSingleBreakdownValue}
              filterMeasure={filterMeasure}
              changePending={changePending}
            ></SingleFilterMeasure>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

function SingleFilterMeasure(props: {
  thirdPartySettings?: ThirdPartyMicroCardSettings;
  handleSetFilter: (
    filterMeasureIndex: number,
    filter: MicroDataFilter
  ) => void;
  index: number;
  card: DocCardMicro;
  handleRemove: (filterMeasureIndex: number) => void;
  handleSetSingleBreakdownValue: (
    filterMeasureIndex: number,
    dataColumn: string,
    key?: number
  ) => void;
  handleClearBreakdowns: (
    filterMeasureIndex: number,
    dataColumn: string
  ) => void;
  selectedFilterMeasures: SelectedMicroMeasures;
  //   categories: Categories;
  filterMeasure: FilterMeasureMicro;
  changePending: boolean;
}) {
  const { index, filterMeasure, changePending, handleSetSingleBreakdownValue } =
    props;

  const measureSelection = filterMeasure.measureSelection;
  const selectedMeasureId = measureSelection?.measure.id;

  const [isEditingFilter, toggleIsEditingFilter] = useToggle(
    defined(filterMeasure.filterSet) ? false : true
  );

  const {
    isEditingVariables,
    toggleIsEditingVariables,
    handleSetVariablesConfig,
  } = useVariablesStateFilterSelection(props.card.id, index, () => {
    // Default to true only when a variable input is required but currently missing
    return (
      defined(measureSelection) &&
      measureSelection.measure?.computed?.type === "count_within_distance" &&
      !defined(measureSelection.computedMeasureVariablesConfig)
    );
  });

  const handleSetSingleBreakdownKey = useCallback(
    (dataColumn: string, key?: number) => {
      return handleSetSingleBreakdownValue(index, dataColumn, key);
    },
    [handleSetSingleBreakdownValue, index]
  );

  const handleSetFilter = useCallback(
    (filter: MicroDataFilter) => {
      return props.handleSetFilter(index, filter);
    },
    [index, props]
  );

  const handleClearBreakdowns = useCallback(
    (dataColumn: string) => {
      return props.handleClearBreakdowns(index, dataColumn);
    },
    [index, props]
  );

  const onRenderTitle = useCallback(
    (singleOption?: InputHTMLAttributes<HTMLInputElement>) => {
      const option = props.filterMeasure.measureSelection?.measure;
      if (!defined(option)) {
        return <span>--</span>;
      }

      return (
        <div className="micro-measure-selection-entry-with-icon padding-x-sm">
          <div className="icon">
            {renderMicroMeasureTypeIcon({
              value_type: option.valueType,
              computed_measurement_type: option.computed?.type,
            })}
          </div>
          <div className="text" title={option.measure}>
            {option.measure}
          </div>
        </div>
      );
    },
    [props.filterMeasure.measureSelection?.measure]
  );

  if (!defined(selectedMeasureId)) {
    return null;
  }

  return (
    <div className="single-filter-container">
      <div className="single-filter-measure">
        <div className="dimension-selection-and-filter">
          <TextField
            label="Mått"
            className="third-party-doc-filter-measure margin-right-md"
            onRenderInput={onRenderTitle}
            readOnly
            value=""
          />
          <SelectedBreakdowns
            changePending={changePending}
            measureId={selectedMeasureId}
            selectedBreakdowns={measureSelection?.selectedDimensions ?? {}}
          />
          {defined(measureSelection) &&
            defined(measureSelection.measure.computed) &&
            defined(measureSelection.measure.computed.variables) && (
              <VariablesConfig
                readOnly
                isEditingVariables={isEditingVariables}
                toggleIsEditingVariables={toggleIsEditingVariables}
                handleSetConfig={handleSetVariablesConfig}
                config={measureSelection.computedMeasureVariablesConfig}
                variablesSpec={measureSelection.measure.computed.variables}
              ></VariablesConfig>
            )}
          <FilterSelector
            readOnly={!props.thirdPartySettings?.allowEditFilterParams}
            isEditingFilter={isEditingFilter}
            toggleIsEditingFilter={toggleIsEditingFilter}
            handleSetFilter={handleSetFilter}
            filterSet={filterMeasure.filterSet}
          ></FilterSelector>
        </div>
      </div>
    </div>
  );
}

function SelectedBreakdowns(props: {
  changePending: boolean;
  measureId: number;
  selectedBreakdowns: SelectedDimensionsV2;
}) {
  const selectedMeasureId = props.measureId;
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const loader = useCallback(() => {
    if (!defined(selectedMeasureId)) {
      throw new Error("MeasureId is not defined");
    }
    return getMicroDimensionsWithCache(selectedMeasureId, adminShowDraftData);
  }, [adminShowDraftData, selectedMeasureId]);

  const [dims] = useLoadableHttpResource(loader, [loader]);
  return dims.match({
    notReady: (status) => <NotReadyHttpErrNotice left={status} />,
    ready: (data) => {
      return (
        <>
          {data?.map((spec) => (
            <SingleBreakdown
              key={spec.data_column}
              selectedBreakdowns={props.selectedBreakdowns}
              dim={spec}
            ></SingleBreakdown>
          )) ?? []}
        </>
      );
    },
  });
}

function SingleBreakdown(props: {
  dim: DimensionV2Dto;
  selectedBreakdowns: SelectedDimensionsV2;
}) {
  const { dim, selectedBreakdowns } = props;
  const selectedIds = selectedBreakdowns[dim.data_column];
  const values = useMemo(() => {
    if (!defined(selectedIds)) {
      return null;
    }

    const filteredValues = dim.values?.filter((v) =>
      selectedIds.includes(v.id)
    );
    if (!defined(filteredValues)) {
      return null;
    }

    return sortBy(filteredValues, (f) => f.sort_order);
  }, [dim.values, selectedIds]);

  if (!defined(values)) {
    return null;
  }

  const fullString = values.map((v) => v.label).join(", ");
  return (
    <TextField
      className="margin-right-md third-party-doc-filter-breakdown"
      title={fullString}
      label={dim.label}
      readOnly
      value={fullString}
    ></TextField>
  );
}
