import { fromPairs } from "lodash";

import { SelectedDimensionsV2 } from "../measure/definitions";

/**
 * In map view, only one breakdown combination can be displayed. Discard all additional breakdowns.
 */
export function getMicroMapSelectedDimensions(
  allSelected: SelectedDimensionsV2
): SelectedDimensionsV2 {
  return fromPairs(
    Object.entries(allSelected).map(([key, value]) => [key, value?.slice(0, 1)])
  ) as SelectedDimensionsV2;
}
