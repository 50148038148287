import { Checkbox, Dropdown, TextField } from "@fluentui/react";

import { Button } from "../../../../components/Button";
import { InfostatDatePickerControlled } from "../../../../components/input/DatePicker";
import { config } from "../../../../config";
import { assertNever } from "../../../../lib/core/assert";
import { defined } from "../../../../lib/core/defined";
import { Dictionary } from "../../../../lib/core/dictionary";
import { nonEmptyString } from "../../../../lib/core/nonEmptyString";
import {
  metadataEntryLabel,
  MetadataInputField,
  MultiSelectField,
  SelectField,
} from "./base";
import { sortBy } from "lodash";

interface Props<FieldKeys> {
  textInputs: Dictionary<any>;
  field: MetadataInputField<FieldKeys>;
  updateInput: (key: FieldKeys, value: string) => void;
  selectInputs: Record<string | number, string | undefined>;
  updateSelect: (field: SelectField<FieldKeys>, key: string | number) => void;
  multiselectInputs: Record<string | number, string[]>;
  updateMultiselect: (
    field: MultiSelectField<FieldKeys>,
    values: string[]
  ) => void;
  breakdownParents: Record<string, string | undefined>;
  updateBreakdownParents: (
    breakdowns: Record<string, string | undefined>
  ) => void;
  breakdowns: Record<string, string>;
  updateBreakdowns: (breakdowns: Record<string, string>) => void;
  useNoBreakdowns: boolean;
  setUseNoBreakdowns: (useNoBreakdowns: boolean) => void;
}

export function MetadataFieldView<FieldKeys extends string>(
  props: Props<FieldKeys>
): JSX.Element {
  const {
    textInputs,
    field,
    updateInput,
    selectInputs,
    updateSelect,
    multiselectInputs,
    updateMultiselect,
    useNoBreakdowns,
    setUseNoBreakdowns,
  } = props;

  const fieldValue = textInputs[field.key];
  const label = metadataEntryLabel(field);
  switch (field.type) {
    case "input":
      return (
        <TextField
          id={"field-" + field.key}
          key={field.key}
          required={!field.optional}
          label={label}
          placeholder={
            defined(field.placeholder) ? "ex: " + field.placeholder : undefined
          }
          value={fieldValue}
          onChange={(e) => updateInput(field.key, e.currentTarget.value)}
        ></TextField>
      );

    case "textarea":
      return (
        <TextField
          id={"field-" + field.key}
          key={field.key}
          multiline
          required={!field.optional}
          label={label}
          placeholder={
            defined(field.placeholder) ? "ex: " + field.placeholder : undefined
          }
          value={fieldValue}
          onChange={(e) => updateInput(field.key, e.currentTarget.value)}
        ></TextField>
      );
    case "select":
      return (
        <Dropdown
          id={"field-" + field.key}
          key={field.key}
          label={label}
          onChange={(e, data) => {
            const key = data?.key;
            if (!defined(key)) {
              return;
            }
            updateSelect(field, key);
          }}
          required={!field.optional}
          selectedKey={selectInputs[field.key]}
          options={field.options.map((val) => {
            return { key: val, text: val };
          })}
        ></Dropdown>
      );
    case "multiselect":
      const currentKeys = multiselectInputs[field.key];
      return (
        <Dropdown
          id={"field-" + field.key}
          key={field.key}
          label={label}
          multiSelect
          onChange={(e, data) => {
            const key = data?.key as string | undefined;
            if (!defined(key)) {
              return;
            }
            if (currentKeys.includes(key)) {
              updateMultiselect(
                field,
                currentKeys.filter((k) => k !== key)
              );
            } else {
              updateMultiselect(field, [...currentKeys, key]);
            }
          }}
          required={!field.optional}
          selectedKeys={currentKeys}
          options={field.options.map((val) => {
            return { key: val, text: val };
          })}
        ></Dropdown>
      );
    case "breakdowns":
      const breakdownEntries = sortBy(
        Object.entries(props.breakdowns),
        ([k]) => k
      );
      return (
        <div key={field.key}>
          <h3 className="margin-top-md">Breakdowns</h3>
          <Checkbox
            id="use-no-breakdowns"
            checked={useNoBreakdowns}
            onChange={(e, checked) => {
              if (!defined(checked)) {
                return;
              }
              setUseNoBreakdowns(checked);
            }}
            label="Måttet har inga breakdowns"
          ></Checkbox>
          {breakdownEntries.map(([key, value], i) => {
            const canBeChildBreakdown =
              i > 0 &&
              (i === 1 ||
                props.breakdownParents[breakdownEntries[i - 1][0]] !==
                  undefined);
            return (
              <BreakdownField
                key={key}
                isChildBreakdown={props.breakdownParents[key] !== undefined}
                setChildBreakdown={
                  !canBeChildBreakdown
                    ? undefined
                    : (isChild) =>
                        props.updateBreakdownParents({
                          ...props.breakdownParents,
                          [key]: isChild
                            ? breakdownEntries[i - 1][0]
                            : undefined,
                        })
                }
                breakdowns={props.breakdowns}
                updateBreakdowns={props.updateBreakdowns}
                csvField={key}
                fieldInputLabel={field.csvColumn.label}
                name={value}
                nameInputLabel={field.label.label}
              />
            );
          })}
          <div>
            <Button
              id="add-breakdown"
              className="margin-top-md margin-bottom-md"
              disabled={
                breakdownEntries.length >= config.micro.maxNumBreakdowns ||
                useNoBreakdowns
              }
              title="Lägg till breakdown"
              onClick={() => {
                props.updateBreakdowns({
                  ...props.breakdowns,
                  ["breakdown" + (breakdownEntries.length + 1)]: "",
                });
              }}
            ></Button>{" "}
          </div>
        </div>
      );
    case "date":
      const dateValueRaw = textInputs[field.key];
      const dateValue =
        defined(dateValueRaw) && nonEmptyString(dateValueRaw)
          ? new Date(dateValueRaw)
          : undefined;
      return (
        <InfostatDatePickerControlled
          key={field.key}
          disabled={false}
          value={dateValue}
          onChange={(date) => {
            const dateString = date?.toLocaleDateString();
            updateInput(field.key, dateString ?? "");
          }}
          label={label}
        ></InfostatDatePickerControlled>
      );
    default:
      assertNever(field);
  }
}

/**
 * When a field is first created, its key is an empty string. When a field is saved, its key is set
 * to the csv field name, and after that editing is no longer allowed.
 */
function BreakdownField(props: {
  breakdowns: Record<string, string>;
  updateBreakdowns: (breakdowns: Record<string, string>) => void;
  isChildBreakdown: boolean;
  setChildBreakdown?: (isChildBreakdown: boolean) => void;
  fieldInputLabel: string;
  nameInputLabel: string;
  csvField: string;
  name: string;
}) {
  const setChildBreakdown = props.setChildBreakdown;
  return (
    <div className="breakdown-field">
      <TextField
        readOnly
        label={props.fieldInputLabel}
        value={props.csvField}
        disabled
      ></TextField>
      <TextField
        label={props.nameInputLabel}
        value={props.name}
        required
        onChange={(ev) => {
          props.updateBreakdowns({
            ...props.breakdowns,
            [props.csvField]: ev.currentTarget.value,
          });
        }}
      ></TextField>
      {defined(setChildBreakdown) && (
        <Checkbox
          label="Är underkategori till ovan breakdown"
          checked={props.isChildBreakdown}
          onChange={(e, checked) => {
            if (!defined(checked)) {
              return;
            }
            setChildBreakdown(checked);
          }}
        />
      )}
      <Button
        title="Ta bort"
        intent="danger"
        onClick={() => {
          const breakdownsCopy = { ...props.breakdowns };
          delete breakdownsCopy[props.csvField];
          props.updateBreakdowns(breakdownsCopy);
        }}
      ></Button>
    </div>
  );
}
