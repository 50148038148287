import { DataSourceInfo } from "../../../lib/application/stats/datasets/DatasetGeneric";
import { buildSourceTextParts } from "../../../lib/application/stats/shared/texts";
import { defined } from "../../../lib/core/defined";

interface Props {
  mainSource: DataSourceInfo;
  groupingSource?: DataSourceInfo;
  customSourceText?: string | null;
}
export function DataSourceSection(props: Props) {
  if (defined(props.customSourceText)) {
    return <p className="source">{props.customSourceText}</p>;
  }

  const { mainSource, groupingSource } = props;

  const { primary, secondary, multipleSources } = buildSourceTextParts(
    { source: mainSource.source, externalSource: mainSource.externalSource },
    defined(groupingSource) && defined(groupingSource.source)
      ? {
          source: groupingSource.source,
          externalSource: groupingSource.externalSource,
        }
      : undefined
  );
  const sourcesLabel = multipleSources ? "Källor:" : "Källa:";
  return (
    <>
      {defined(secondary) ? (
        <p className="source">
          {sourcesLabel} {primary}; {secondary}
        </p>
      ) : (
        <p className="source">
          {sourcesLabel} {primary}
        </p>
      )}
    </>
  );
}
