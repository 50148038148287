import { User } from "@auth0/auth0-react";
import { DetailsList, IColumn, SelectionMode } from "@fluentui/react";
import { useCallback, useContext } from "react";

import { AlertBox } from "../../../../components/AlertBox";
import { Card } from "../../../../components/Card";
import { FluentIcon } from "../../../../components/Icons";
import { NotReadyHttpErrNotice } from "../../../../components/Loading";
import { eitherDisplayer } from "../../../../components/wrappers/either";
import { config } from "../../../../config";
import { getClaimUserId } from "../../../../lib/application/auth/claims";
import { AppMessagesContext } from "../../../../lib/application/contexts";
import { useLoadableHttpResource } from "../../../../lib/application/hooks/useLoadableResource";
import {
  deleteAlertRegistration,
  getAlertRegistrations,
} from "../../../../lib/application/requests/common_requests";
import { getText } from "../../../../lib/application/strings";
import { decapitalize } from "../../../../lib/core/capitalize";
import {
  AlertRegistrations,
  SingleAlertRegistration,
  SingleAlertRegistrationDocument,
  SingleAlertRegistrationMeasure,
} from "../../../../lib/domain/alerts";
import { logger } from "../../../../lib/infra/logging";

interface Props {
  user: User;
}
export function AlertsContainer(props: Props) {
  const { user } = props;
  const userId = getClaimUserId(user);

  const registrationsGetter = useCallback(() => {
    return getAlertRegistrations(userId);
  }, [userId]);
  const [registrationsLoadable, reloadRegistrations] =
    useLoadableHttpResource(registrationsGetter);

  return (
    <AlertsOrLoading
      item={registrationsLoadable}
      userId={userId}
      reloadRegistrations={reloadRegistrations}
    ></AlertsOrLoading>
  );
}

const AlertsOrLoading = eitherDisplayer(NotReadyHttpErrNotice, Alerts);

export function Alerts(props: {
  item: AlertRegistrations;
  reloadRegistrations: () => void;
  userId: string;
}) {
  const { reloadRegistrations, userId } = props;
  const alertRegistrations = props.item;
  const measureAlerts = alertRegistrations.listMeasure();
  const documentAlerts = alertRegistrations.listDocument();

  const appMessagesHandler = useContext(AppMessagesContext);
  const handleDeleteRegistration = useCallback(
    (item: SingleAlertRegistration) => {
      deleteAlertRegistration(userId, item.id)
        .then(() => {
          reloadRegistrations();
          appMessagesHandler?.add(
            "success",
            getText("alerts-now-unregistered")
          );
        })
        .catch((err) => {
          logger.error(err);
          appMessagesHandler?.add(
            "error",
            getText("alerts-could-not-unregister")
          );
        });
    },
    [appMessagesHandler, reloadRegistrations, userId]
  );

  const columnsMeasure: IColumn[] = [
    {
      key: "measure-description",
      name: "Mått",
      minWidth: 100,
      className: "measure-description",
      onRender: (item: SingleAlertRegistrationMeasure) => {
        const text = `${item.measureDescription} (${decapitalize(
          item.measure
        )})`;
        return <span title={text}>{text}</span>;
      },
    },
    {
      key: "area",
      name: "Område",
      minWidth: 150,
      onRender: (item: SingleAlertRegistrationMeasure) => (
        <span title={item.area}>{item.area}</span>
      ),
    },
    {
      key: "subarea",
      name: "Delområde",
      minWidth: 100,
      onRender: (item: SingleAlertRegistrationMeasure) => (
        <span title={item.subarea}>{item.subarea}</span>
      ),
    },
    {
      key: "subject",
      name: "Ämne",
      minWidth: 100,
      onRender: (item: SingleAlertRegistrationMeasure) => (
        <span title={item.subject}>{item.subject}</span>
      ),
    },
    {
      key: "button",
      name: "",
      className: "button-column",
      minWidth: 30,
      onRender: (item: SingleAlertRegistrationMeasure) => {
        return (
          <FluentIcon
            onClick={() => handleDeleteRegistration(item)}
            name="cross"
            size="sm"
          ></FluentIcon>
        );
      },
    },
  ];

  const columnsDocument: IColumn[] = [
    {
      key: "document-name",
      name: "Namn",
      minWidth: 100,
      className: "document-name",
      onRender: (item: SingleAlertRegistrationDocument) => {
        const text = item.documentTitle;
        return <span title={text}>{text}</span>;
      },
    },
    {
      key: "last-updated",
      name: "Senast uppdaterat",
      minWidth: 150,
      onRender: (item: SingleAlertRegistrationDocument) => (
        <span>{item.lastUpdated.toLocaleString(config.locale)}</span>
      ),
    },
    {
      key: "button",
      name: "",
      className: "button-column",
      minWidth: 30,
      onRender: (item: SingleAlertRegistrationDocument) => {
        return (
          <FluentIcon
            onClick={() => handleDeleteRegistration(item)}
            name="cross"
            size="sm"
          ></FluentIcon>
        );
      },
    },
  ];

  return (
    <Card id="alerts-settings">
      <h2>Bevakningar</h2>
      <p>
        När bevakade mått uppdateras får du en notis om det via epost. Utskick
        görs som mest en gång per dag.
      </p>
      <>
        {measureAlerts.length === 0 && documentAlerts.length === 0 && (
          <AlertBox>
            <span>Du har för närvarande inga bevakningar av mått.</span>
          </AlertBox>
        )}

        {measureAlerts.length > 0 && (
          <DetailsList
            selectionMode={SelectionMode.none}
            items={measureAlerts}
            columns={columnsMeasure}
          ></DetailsList>
        )}

        {documentAlerts.length > 0 && (
          <DetailsList
            selectionMode={SelectionMode.none}
            items={documentAlerts}
            columns={columnsDocument}
          ></DetailsList>
        )}
      </>
    </Card>
  );
}
