import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useContext,
} from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { FolderNodeDto } from "../../../../lib/infra/api_responses/file_area";
import {
  createFolder,
  deleteFile,
  deleteFolder,
  getFile,
  listFolderContents,
  moveFile,
  moveFolder,
  renameFile,
  renameFolder,
  setFileStatus,
} from "../../../../lib/application/requests/account";
import { useAppMessages } from "../../../../lib/application/hooks/useAppMessages";
import { displayHttpError } from "../../../../components/errors/HttpErrorNotice";
import { Card } from "../../../../components/Card";
import { defined } from "../../../../lib/core/defined";
import { classNames } from "../../../../lib/core/classNames";
import { TextButton } from "../../../../components/buttons/TextButton";
import { formatDateTimeSE } from "../../../../lib/core/time";
import { AlertBox } from "../../../../components/AlertBox";
import { downloadBlob } from "../../../../lib/application/browser/downloadBlob";
import { Button } from "../../../../components/Button";
import { authedFileFormUpload } from "../../../../lib/application/requests/shared";
import { config } from "../../../../config";
import { Progress } from "../../../../lib/core/progress";
import { ProgressIndicator } from "@fluentui/react";
import { HttpError } from "../../../../lib/infra/HttpResult";
import { UserInfoContext } from "../../../../lib/application/contexts";

import "./FileManager.scss";
import { fileAreaStatusOptions } from "../../../../lib/domain/file_area";
import { Permission } from "../../../../lib/application/auth/UserInfo";

export function FileManager(props: { orgId: string }) {
  const [currentFolderID, setCurrentFolderID] = useState<string | null>(null);
  const [currentFolder, setCurrentFolder] = useState<FolderNodeDto | null>(
    null
  );
  const [currentPath, setCurrentPath] = useState<
    Array<{ id: string | null; name: string }>
  >([{ id: null, name: "Hem" }]);
  const [currentDirContents, setCurrentDirContents] = useState<FolderNodeDto[]>(
    []
  );
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedItem, setSelectedItem] = useState<FolderNodeDto | null>(null);
  const appMessages = useAppMessages();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isDraggingOver, setIsDraggingOver] = useState(false); // For drag and drop upload

  const fetchFolderContents = useCallback(async () => {
    try {
      const result = await listFolderContents(currentFolderID ?? "root");
      result.match({
        ok: (contents) => {
          setCurrentDirContents(contents ?? []);
          if (currentPath.length === 1) {
            return;
          }
          const parentParentId = currentPath[currentPath.length - 2].id;
          return listFolderContents(parentParentId ?? "root").then((res) => {
            res.match({
              ok: (parentContents) => {
                const parentItem = parentContents?.find(
                  (c) => c.id === currentFolderID
                );
                if (defined(parentItem)) {
                  setCurrentFolder(parentItem);
                }
              },
              err: (err) => {
                appMessages?.add("error", displayHttpError(err));
              },
            });
          });
        },
        err: (err) => {
          appMessages?.add("error", displayHttpError(err));
        },
      });
    } catch (error) {
      appMessages?.add("error", "Kunde inte hämta mappens innehåll.");
    }
  }, [appMessages, currentFolderID, currentPath]);

  useEffect(() => {
    fetchFolderContents();
  }, [currentFolderID, fetchFolderContents]);

  useEffect(() => {
    // Reset selection when directory contents change
    setSelectedIndex(-1);
  }, [currentDirContents]);

  useEffect(() => {
    // Update selected item based on selected index
    if (selectedIndex >= 0 && selectedIndex < currentDirContents.length) {
      setSelectedItem(currentDirContents[selectedIndex]);
    } else {
      setSelectedItem(null);
    }
  }, [selectedIndex, currentDirContents]);

  const navigateToFolder = useCallback(
    (folder: FolderNodeDto) => {
      setCurrentFolderID(folder.id);
      setCurrentPath([...currentPath, { id: folder.id, name: folder.name }]);
      setSelectedIndex(-1);
      setSelectedItem(null);
    },
    [currentPath]
  );

  const navigateToPathIndex = (index: number) => {
    const newPath = currentPath.slice(0, index + 1);
    setCurrentPath(newPath);
    setCurrentFolderID(newPath[newPath.length - 1].id);
    setSelectedIndex(-1);
    setSelectedItem(null);
  };

  const [editingStatusItemId, setEditingStatusItemId] = useState<string | null>(
    null
  );
  const [editingStatusValue, setEditingStatusValue] = useState<string>("");

  const startEditingStatus = useCallback((item: FolderNodeDto) => {
    setEditingStatusItemId(item.id);
    setEditingStatusValue(item.status || "uploaded"); // Default to 'uploaded' if status is undefined
  }, []);

  const saveStatus = useCallback(
    (item: FolderNodeDto) => {
      setFileStatus(item.id, editingStatusValue).then((res) => {
        res.match({
          ok: () => {
            fetchFolderContents();
            setEditingStatusItemId(null);
            setEditingStatusValue("");
          },
          err: (err) => {
            appMessages?.add("error", displayHttpError(err));
          },
        });
      });
    },
    [appMessages, fetchFolderContents, editingStatusValue]
  );

  const handleDownloadFile = useCallback(
    async (file: FolderNodeDto) => {
      try {
        const result = await getFile(file.id);
        result.match({
          err: (err) => {
            appMessages?.add("error", displayHttpError(err));
          },
          ok: (response) => {
            response.blob().then((b) => {
              const filename = response.headers
                .get("Content-Disposition")
                ?.split("filename=")[1]
                ?.slice(1, -1);
              downloadBlob(b, filename ?? "download");
            });
          },
        });
      } catch (error) {
        appMessages?.add("error", "Kunde inte hämta filen.");
      }
    },
    [appMessages]
  );

  const handleItemDoubleClick = useCallback(
    (item: FolderNodeDto) => {
      if (item.type === "dir") {
        navigateToFolder(item);
      } else {
        handleDownloadFile(item);
      }
    },
    [handleDownloadFile, navigateToFolder]
  );

  const handleDelete = useCallback(async () => {
    if (!selectedItem) return;
    if (!window.confirm("Är du säker på att du vill ta bort objektet?")) {
      return;
    }
    const isFolder = selectedItem.type === "dir";

    try {
      if (isFolder) {
        const result = await deleteFolder(selectedItem.id);
        result.match({
          ok: () => {
            fetchFolderContents();
            setSelectedIndex(-1);
            setSelectedItem(null);
          },
          err: (err) => {
            appMessages?.add("error", displayHttpError(err));
          },
        });
      } else {
        const result = await deleteFile(selectedItem.id);
        result.match({
          ok: () => {
            fetchFolderContents();
            setSelectedIndex(-1);
            setSelectedItem(null);
          },
          err: (err) => {
            appMessages?.add("error", displayHttpError(err));
          },
        });
      }
    } catch (error) {
      appMessages?.add("error", "Kunde inte ta bort objektet.");
    }
  }, [appMessages, fetchFolderContents, selectedItem]);

  const handleRename = async () => {
    if (!selectedItem) return;
    const newName = prompt("Enter new name:", selectedItem.name);
    if (!newName || newName === selectedItem.name) return;

    switch (selectedItem.type) {
      case "dir":
        return renameFolder(selectedItem.id, newName).then((res) => {
          res.match({
            ok: () => {
              fetchFolderContents();
            },
            err: (err) => {
              appMessages?.add("error", displayHttpError(err));
            },
          });
        });
      case "file":
        return renameFile(selectedItem.id, newName).then((res) => {
          res.match({
            ok: () => {
              fetchFolderContents();
            },
            err: (err) => {
              appMessages?.add("error", displayHttpError(err));
            },
          });
        });
    }
  };

  const [uploadProgressPercent, setUploadProgressPercent] = useState(0);
  const [requestProgress, setRequestProgress] = useState<Progress>(
    Progress.NotStarted
  );
  const [requestError, setRequestError] = useState<HttpError>();
  const handleUploadProgress = useCallback((progress: number) => {
    setUploadProgressPercent(progress);
  }, []);

  const handleUploadDone = useCallback(() => {
    fetchFolderContents();
  }, [fetchFolderContents]);

  const handleUploadError = useCallback(() => {
    appMessages?.add("error", "Filuppladdning kunde inte slutföras");
    setRequestProgress(Progress.Error);
  }, [appMessages]);

  const handleFileUpload = useCallback(
    async (
      event:
        | React.ChangeEvent<HTMLInputElement>
        | { target: { files: FileList } }
    ) => {
      const files = event.target.files;
      if (!defined(files)) {
        return;
      }
      if (!defined(currentFolderID)) {
        return;
      }
      const form = new FormData();
      form.append("parent_id", currentFolderID);
      for (const file of files) {
        form.append("file", file);
      }

      authedFileFormUpload(
        config.apis.statsV2,
        "users/me/organization/files",
        form,
        {
          onUploadProgress: handleUploadProgress,
          onUploadDone: handleUploadDone,
          onUploadError: handleUploadError,
        }
      ).then((res) => {
        res.match({
          ok: () => {
            appMessages?.add("success", "Filen/filerna har laddats upp.");
            setRequestProgress(Progress.NotStarted);
            fetchFolderContents();
          },
          err: (err) => {
            appMessages?.add("error", displayHttpError(err));
            setRequestProgress(Progress.Error);
            setRequestError(err);
          },
        });
      });
    },
    [
      appMessages,
      currentFolderID,
      fetchFolderContents,
      handleUploadDone,
      handleUploadError,
      handleUploadProgress,
    ]
  );

  // Handle drag and drop file upload
  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDraggingOver(true);
    },
    []
  );

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDraggingOver(false);
    },
    []
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDraggingOver(false);

      const files = event.dataTransfer.files;
      if (files && files.length > 0) {
        // Reuse the handleFileUpload logic
        const dataTransferEvent = {
          target: {
            files: files,
          },
        } as React.ChangeEvent<HTMLInputElement>;
        handleFileUpload(dataTransferEvent);
      }
    },
    [handleFileUpload]
  );

  // Handle drag and drop move within file manager
  const handleDragStartItem = (
    event: React.DragEvent<HTMLTableRowElement>,
    item: FolderNodeDto
  ) => {
    event.dataTransfer.setData("application/json", JSON.stringify(item));
    event.dataTransfer.effectAllowed = "move";
  };

  const handleDragOverItem = (
    event: React.DragEvent<HTMLTableRowElement>,
    item: FolderNodeDto
  ) => {
    event.preventDefault();
    event.stopPropagation();
    if (item.type === "dir") {
      event.dataTransfer.dropEffect = "move";
    } else {
      event.dataTransfer.dropEffect = "none";
    }
  };

  const handleDropItem = async (
    event: React.DragEvent<HTMLTableRowElement>,
    targetItem: FolderNodeDto
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (targetItem.type !== "dir") {
      return;
    }

    const data = event.dataTransfer.getData("application/json");
    if (data) {
      const draggedItem: FolderNodeDto = JSON.parse(data);
      if (draggedItem.id === targetItem.id) {
        // Cannot move item into itself
        return;
      }

      // Move the item
      try {
        const result =
          draggedItem.type === "file"
            ? await moveFile(draggedItem.id, targetItem.id)
            : await moveFolder(draggedItem.id, targetItem.id);
        result.match({
          ok: () => {
            appMessages?.add("success", "Objektet har flyttats.");
            fetchFolderContents();
          },
          err: (err) => {
            appMessages?.add("error", displayHttpError(err));
          },
        });
      } catch (error) {
        appMessages?.add("error", "Kunde inte flytta objektet.");
      }
    }
  };

  // Handle folder creation
  const handleCreateFolder = async () => {
    const folderName = prompt("Enter folder name:");
    if (!folderName) return;
    if (!defined(currentFolderID)) {
      appMessages?.add("error", "Kunde inte skapa mappen.");
      return;
    }
    try {
      const result = await createFolder(currentFolderID, folderName);
      result.match({
        ok: () => {
          fetchFolderContents();
        },
        err: (err) => {
          appMessages?.add("error", displayHttpError(err));
        },
      });
    } catch (error) {
      appMessages?.add("error", "Kunde inte skapa mappen.");
    }
  };

  const isRootFolder = !defined(currentFolderID);

  const handleButtonClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const userInfo = useContext(UserInfoContext);
  const userIsAdmin = useMemo(
    () => userInfo?.hasPermission(Permission.InternalManageOrgFiles),
    [userInfo]
  );

  const currentFolderOwnedByOther = useMemo(() => {
    const owner = currentFolder?.owner;
    if (userIsAdmin) {
      return false;
    }
    return owner !== "customer";
  }, [currentFolder?.owner, userIsAdmin]);
  const currentFolderIsCustomerFolder = useMemo(
    () => currentFolder?.owner === "customer",
    [currentFolder?.owner]
  );

  // Keyboard navigation handler
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setSelectedIndex((prevIndex) => {
          const newIndex = prevIndex + 1;
          if (newIndex >= currentDirContents.length) {
            return prevIndex; // Do not move beyond the end
          }
          return newIndex;
        });
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setSelectedIndex((prevIndex) => {
          const newIndex = prevIndex - 1;
          if (newIndex < 0) {
            return prevIndex; // Do not move before the start
          }
          return newIndex;
        });
      } else if (event.key === "Enter") {
        event.preventDefault();
        if (selectedItem) {
          handleItemDoubleClick(selectedItem);
        }
      } else if (event.key === "Delete") {
        event.preventDefault();
        handleDelete();
      }
    },
    [
      currentDirContents.length,
      selectedItem,
      handleDelete,
      handleItemDoubleClick,
    ]
  );

  useEffect(() => {
    // Focus the container when the component mounts
    containerRef.current?.focus();
  }, []);

  const dropdownOptions: IDropdownOption[] = useMemo(
    () =>
      fileAreaStatusOptions.map((opt) => ({
        key: opt.value,
        text: opt.label,
      })),
    []
  );

  return (
    <Card id="file-area-manager">
      <div
        ref={containerRef}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onClick={() => containerRef.current?.focus()}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={isDraggingOver ? "drag-over" : ""}
      >
        <div style={{ marginBottom: "10px" }}>
          <strong></strong>{" "}
          {currentPath.map((segment, index) => (
            <span key={segment.id || "root"}>
              {index > 0 && " / "}
              {index === currentPath.length - 1 ? (
                <strong>{segment.name}</strong>
              ) : (
                <TextButton
                  title={segment.name}
                  onClick={() => navigateToPathIndex(index)}
                />
              )}
            </span>
          ))}
        </div>

        <div className="actions-panel">
          <Button
            title="Ny mapp"
            small
            disabled={isRootFolder || currentFolderOwnedByOther}
            onClick={handleCreateFolder}
          ></Button>
          <Button
            title="Ta bort"
            small
            disabled={
              !defined(selectedItem) ||
              isRootFolder ||
              currentFolderOwnedByOther
            }
            onClick={handleDelete}
          ></Button>
          <Button
            title="Byt namn"
            small
            disabled={
              !defined(selectedItem) ||
              isRootFolder ||
              currentFolderOwnedByOther
            }
            onClick={handleRename}
          ></Button>

          <Button
            disabled={isRootFolder || currentFolderOwnedByOther}
            title="Ladda upp filer"
            small
            onClick={handleButtonClick}
          ></Button>
          <input
            type="file"
            multiple
            onChange={handleFileUpload}
            ref={fileInputRef}
            style={{ display: "none" }}
          />
        </div>

        {requestProgress !== Progress.NotStarted && (
          <div className="upload-info margin-top-md margin-bottom-md">
            {requestProgress === Progress.InProgress && (
              <div>
                <span>Laddar upp... ({uploadProgressPercent}%)</span>
                <ProgressIndicator
                  percentComplete={(uploadProgressPercent ?? 0) / 100}
                />
              </div>
            )}
            {requestProgress === Progress.Error && (
              <AlertBox intent="danger">
                <>
                  <span>Uppladdning misslyckades!</span>
                  {defined(requestError) && (
                    <div>{displayHttpError(requestError)}</div>
                  )}
                </>
              </AlertBox>
            )}
          </div>
        )}

        {currentDirContents.length === 0 ? (
          <AlertBox>
            <span>Tom mapp</span>
          </AlertBox>
        ) : (
          <ul className="node-list">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Namn</th>
                  {currentFolderIsCustomerFolder && <th>Status</th>}
                  <th>Senast ändrad</th>
                </tr>
              </thead>
              <tbody>
                {currentDirContents.map((item, index) => (
                  <tr
                    key={item.id}
                    onClick={() => {
                      setSelectedIndex(index);
                    }}
                    onDoubleClick={() => handleItemDoubleClick(item)}
                    className={classNames(
                      selectedIndex === index ? "selected" : "",
                      item.status ?? "uploaded"
                    )}
                    draggable
                    onDragStart={(e) => handleDragStartItem(e, item)}
                    onDragOver={(e) => handleDragOverItem(e, item)}
                    onDrop={(e) => handleDropItem(e, item)}
                  >
                    <td>{item.type === "dir" ? "📁" : "📄"}</td>
                    <td>{item.name}</td>

                    {currentFolderIsCustomerFolder && (
                      <>
                        {item.type === "file" ? (
                          <td className="file-status-cell">
                            {editingStatusItemId === item.id ? (
                              <>
                                <Dropdown
                                  dropdownWidth="auto"
                                  selectedKey={editingStatusValue}
                                  onChange={(e, option) => {
                                    if (option) {
                                      setEditingStatusValue(
                                        option.key as string
                                      );
                                    }
                                  }}
                                  options={dropdownOptions}
                                />
                                <Button
                                  title="Avbryt"
                                  small
                                  onClick={() => setEditingStatusItemId(null)}
                                />
                                <Button
                                  intent="primary"
                                  title="Spara"
                                  small
                                  onClick={() => saveStatus(item)}
                                />
                              </>
                            ) : (
                              <>
                                <span className="status-text">
                                  {getStatusLabel(item.status || "uploaded")}
                                </span>
                                {userIsAdmin && (
                                  <Button
                                    small
                                    title="Redigera"
                                    className="status-edit-button"
                                    onClick={() => startEditingStatus(item)}
                                  />
                                )}
                              </>
                            )}
                          </td>
                        ) : (
                          <td></td> // Empty cell for folders
                        )}
                      </>
                    )}

                    <td>
                      {item.updated_at ? formatDateTime(item.updated_at) : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ul>
        )}
      </div>
    </Card>
  );
}

function formatDateTime(s: string): string {
  return formatDateTimeSE(new Date(s));
}

function getStatusLabel(status: string): string {
  const option = fileAreaStatusOptions.find((opt) => opt.value === status);
  return option ? option.label : status;
}
