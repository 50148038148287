import * as d3shape from "d3-shape";

import { ExtendedProgress, Progress } from "../../../../core/progress";
import { DataCellValue } from "../../../stats/datasets/DataCellValue";
import { DataPoint } from "../../../stats/datasets/DataPoint";
import {
  AllBarsSpec,
  LegendLabel,
} from "../../../stats/shared/bar_chart/bar_chart_common";
import { ColorConfig } from "../../../stats/shared/core/colors/colorSchemes";
import {
  ChartDimensions,
  ChartType,
  LabelAreas,
  LegendPositioningBottom,
  LegendPositioningSide,
} from "../../../stats/shared/core/definitions";
import {
  TextRowSet,
  TextContainerPositioned,
} from "../../../stats/shared/core/text_containers";
import {
  XTicksContainer,
  YTicksContainer,
} from "../../../stats/shared/core/ticks";
import { ColorSchemeContainer } from "../document-style/definitions";
import { ColorableTimeSeriesLine } from "../../../stats/shared/TimeSeriesLine";
import {
  DesoResultData,
  MicroResultsType,
  RegsoResultData,
} from "../../../../domain/micro/definitions";
import {
  DimensionAndStringValue,
  DrawableGroupV2,
} from "../../../stats/datasets/MicroDatasetGeo";
import { MapStatsData } from "../../../stats/datasets/MapDataset";
import { HttpError } from "../../../../infra/HttpResult";
import { DataDescription } from "../../../stats/shared/DataDescription";
import { DataSourceInfo } from "../../../stats/datasets/DatasetGeneric";
import { DataOutputSettings } from "./DataOutputSettings";
import { TableSpec } from "../../../stats/datasets/table/table_base/table_base_definitions";

// FIXME: imports structure is bad, imports come from seemingly outer layers

export type DataLoadError =
  | "no-data"
  | "unauthorized"
  | "unknown-error"
  | "bad-client-input";

export type SelectionsIncompleteSpec =
  | { type: "empty-card" }
  | { type: "geo" }
  | { type: "incomplete-grouping-subject-path" }
  | { type: "breakdown"; dimension: string }
  | { type: "missing-variable-input"; variable: string };
export type DataLoadNotStartedInfo = {
  type: "selections-incomplete";
  incomplete: SelectionsIncompleteSpec;
};

export function httpErrToDataLoadErr(httpError: HttpError): DataLoadError {
  switch (httpError.code) {
    case "unknown-error":
    case "conflict":
    case "internal-server-error":
    case "fetch-error":
    case "4xx":
      return "unknown-error";
    case "bad-client-input":
      return "bad-client-input";
    case "not-found":
      return "no-data";
    case "unauthorized":
      return "unauthorized";
  }
}

export interface ChartDataState {
  loadedChartData?: ChartDataContainerV2;
  loadProgress: DataLoadProgress;
}

export interface TableDataState {
  loadedTableData?: TableSpec;
  loadProgress?: DataLoadProgress;
}
export interface TableSurveyStringDataState {
  loadedData?: SurveyStringTableData;
  loadProgress?: DataLoadProgress;
}
export interface StatsMapsState {
  loadedMapsData?: MapStatsData;
  loadProgress?: DataLoadProgress;
}

export type ChartDataContainerV2 =
  | ChartDataContainerV2Line
  | ChartDataContainerV2BarVertical
  | ChartDataContainerV2BarHorizontal;

export interface MicroMapReferenceValue {
  label: string;
  value: string;
}

export interface MicroMapData {
  numSelectedAreas: number;
  significantValuesRounder: (n: number) => string;
  formatterSingleValue: (n: number) => string;
  forcedDimensionValues: DimensionAndStringValue[];
  resultsType: MicroResultsType;
  desoResults?: DesoResultData[];
  regsoResults?: RegsoResultData[];
  title: string;
  subtitle?: string;
  unitLabel: string;
  referenceValues: MicroMapReferenceValue[];
}

export interface MicroMapState {
  loadedMicroMapDataProgress?: DataLoadProgress;
  loadedMicroMapData?: MicroMapData;

  loadedPolygonsProgress?: Progress;
  loadedPolygons?: DrawableGroupV2[];

  loadedPointsProgress?: Progress;
  loadedPoints?: DrawableGroupV2[];

  loadedLinesProgress?: Progress;
  loadedLines?: DrawableGroupV2[];
}

//
// Specific interfaces
//

export interface ChartDataContainerV2BarVertical {
  chartType: ChartType.barVertical;
  renderWidth: number;
  data: BarChartVerticalDataV2;
  colorSchemeContainer: ColorSchemeContainer;
}

export interface ChartDataContainerV2BarHorizontal {
  chartType: ChartType.barHorizontal;
  renderWidth: number;
  data: BarChartHorizontalDataV2;
  colorSchemeContainer: ColorSchemeContainer;
}

export interface ChartDataContainerV2Line {
  chartType: ChartType.line;
  renderWidth: number;
  data: LineChartDataV2;
  colorSchemeContainer: ColorSchemeContainer;
}

export interface BaseChartData {
  title: TextRowSet;
  colorConfig: ColorConfig;
  source: TextRowSet;
  settings: DataOutputSettings;
  liftedDimensions: { [key: string]: string };
  legendDimension: string | undefined;
}

export interface LineChartDataV2 extends BaseChartData {
  d3lineGen: d3shape.Line<DataPoint>;
  chartDimensions: ChartDimensions;
  legend: LegendPositioningSide | LegendPositioningBottom | undefined;
  title: TextRowSet;
  sortedGroupingDimensions: string[];
  formatDomain: (row: Date) => string;
  formattedRange: (row: DataPoint) => string;
  labelAreas: LabelAreas;
  legendLabels?: LegendLabel[];
  lines: ColorableTimeSeriesLine[];
  hasColorDimension: boolean;
  rangeTicks: YTicksContainer;
  domainTicks: XTicksContainer;
  scaledDomain: (dataPoint: DataPoint) => number;
  scaledRange: (dataPoint: DataPoint) => number;
  scaleDomainValue: (domainValue: Date) => number;
  closestDomainValue: (domainScaled: number) => string | undefined;
  dataPointsByDomainValue: (
    domainValue: string,
    options?: { adaptiveFormatting?: boolean }
  ) => DataPoint[];
  relativeChanges: (
    from: string,
    to: string
  ) => {
    label: string;
    change: DataCellValue<number>;
  }[];
}

export interface BarChartHorizontalDataV2 extends BaseChartData {
  axisOffset: number;
  barsSpec: AllBarsSpec;
  chartDimensions: ChartDimensions;
  formatRange: (value: number) => string;
  labelAreas: LabelAreas;
  labels: TextContainerPositioned[];
  legendDimensionLabel?: string;
  legendSide?: LegendPositioningSide;
  legendStandard?: LegendPositioningBottom;
  rangeTicksHorizontalAxis: XTicksContainer;
  useTopTicksAxis: boolean;
}

export interface BarChartVerticalDataV2 extends BaseChartData {
  axisOffset: number;
  barsSpec: AllBarsSpec;
  chartDimensions: ChartDimensions;
  formatRange: (value: number) => string;
  labelAreas: LabelAreas;
  labels: TextContainerPositioned[];
  legendSide?: LegendPositioningSide;
  legendStandard?: LegendPositioningBottom;
  rangeTicksTopDown: YTicksContainer;
}

export interface SurveyStringCol {
  key: string;
  label: string;
  format?: (arg: any) => string;
}

export interface SurveyStringTableData {
  name?: string;
  tableDescription: DataDescription;
  sourceInfo: DataSourceInfo;
  cols: SurveyStringCol[];
  rows: { [key: string]: string | number }[];
}

// State containers

export type DataLoadProgress = ExtendedProgress<
  DataLoadError,
  DataLoadNotStartedInfo
>;
export type DataCardLoadedData<Dataset> = {
  progress?: DataLoadProgress;
  dataset?: Dataset;
  chartDataState?: ChartDataState;
  tableDataState?: TableDataState;
  tableSurveyStringDataState?: TableSurveyStringDataState;
  statsMapsState?: StatsMapsState;
};
