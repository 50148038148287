import React, { useCallback, useContext, useMemo } from "react";
import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { DefaultLoading } from "../../../components/Loading";
import { getSharingInfoMicroPreview } from "../../../lib/application/files/SharingInfo";
import { useLoadableHttpResource } from "../../../lib/application/hooks/useLoadableResource";
import {
  getMicroAvailableDates,
  getMicroCategoriesWithCache,
  getMicroDimensionsWithCache,
  getSingleMicroMeasure,
} from "../../../lib/application/requests/datasets/micro";
import {
  useAddMicroCardCallback,
  useGetAllCardsCallback,
} from "../../../lib/application/state/actions/cardCallbacks";
import { useChangeGeotype } from "../../../lib/application/state/actions/micro/useChangeGeotype";
import { makeMicroCard } from "../../../lib/application/state/stats/document-core/create";
import { docCardsListQuery } from "../../../lib/application/state/stats/document-core/docCardsListState";
import { singleMicroCardQuery } from "../../../lib/application/state/stats/document-core/queries/microCard";
import { reportEditModeOnQuery } from "../../../lib/application/state/stats/document-meta/queries";
import { defined } from "../../../lib/core/defined";
import { voidFunc } from "../../../lib/core/voidFunc";
import { Categories } from "../../../lib/domain/categories";
import { GeographiesSerializable } from "../../../lib/domain/geography";
import {
  getDefaultSelectedDimensions,
  getSubjectPathMicro,
} from "../../../lib/domain/measure";
import { dataValueTypesMicroGeo } from "../../../lib/infra/api_responses/dataset";
import { ComputedMeasurementType } from "../../../lib/infra/api_responses/micro_dataset";
import { logger } from "../../../lib/infra/logging";
import { MeasureSelectionMicro } from "../../stats/docs/cards/micro/components/MeasureSelectionMicro";
import { MicroOutputContainer } from "../../stats/docs/cards/micro/MicroCard";
import { SelectedDimensionsV2 } from "../../../lib/domain/measure/definitions";
import {
  MicroGeoSelections,
  makeMicroDataGeoSelection,
  makePrimaryMicroDataSelection,
} from "../../../lib/application/state/stats/document-core/core-micro";
import { MicroMeasure } from "../../../lib/domain/micro/MicroMeasure";
import {
  GeographiesContext,
  MicroGeoTreeContext,
} from "../../../lib/application/contexts";
import { createColorSchemeContainerWithPalette } from "../../../lib/application/state/stats/document-style/operations";
import { useGeoTree } from "../../stats/docs/cards/micro/useGeoTree";

export function DataPreviewMicro(props: {
  measureId: number;
  showDraftData: boolean;
  onGeoSelectionsUpdated?: (geoSelections: MicroGeoSelections) => void;
  geoSelectionsToReplicate?: MicroGeoSelections;
}) {
  const addMicroCard = useAddMicroCardCallback();

  const geographies = useContext(GeographiesContext);
  const geoTree = useGeoTree();

  useEffect(() => {
    if (!defined(geographies)) {
      return;
    }
    addMicroCard(makeMicroCard(), 0, createColorSchemeContainerWithPalette());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geographies, props.measureId]);

  const cardsList = useRecoilValue(docCardsListQuery);
  const getCards = useGetAllCardsCallback(cardsList);
  const card = getCards().find((c) => c.type === "microCard");

  const loadCategoriesPrimary = useCallback(
    () => getMicroCategoriesWithCache(props.showDraftData, [], []),
    [props.showDraftData]
  );
  const [categoriesPrimaryResult] = useLoadableHttpResource(
    loadCategoriesPrimary,
    [loadCategoriesPrimary]
  );

  const categoriesPrimary = categoriesPrimaryResult.match({
    ready: (c) => c,
    notReady: () => undefined,
  });

  if (
    !defined(geographies) ||
    !defined(geoTree) ||
    !defined(card) ||
    !defined(categoriesPrimary)
  ) {
    return null;
  }

  if (card.type !== "microCard") {
    logger.error("Expected microCard, got", card.type);
    return null;
  }

  return (
    <React.Suspense fallback={<DefaultLoading></DefaultLoading>}>
      <MicroGeoTreeContext.Provider value={geoTree}>
        <PreviewMicroInner
          measureId={props.measureId}
          cardId={card.id}
          categoriesPrimary={categoriesPrimary}
          geographies={geographies}
          adminShowDraftData={props.showDraftData}
          onGeoSelectionsUpdated={props.onGeoSelectionsUpdated}
          geoSelectionsToReplicate={props.geoSelectionsToReplicate}
        ></PreviewMicroInner>
      </MicroGeoTreeContext.Provider>
    </React.Suspense>
  );
}

function PreviewMicroInner(props: {
  measureId: number;
  cardId: string;
  geographies: GeographiesSerializable;
  adminShowDraftData: boolean;
  categoriesPrimary: Categories;
  onGeoSelectionsUpdated?: (geoSelections: MicroGeoSelections) => void;
  geoSelectionsToReplicate?: MicroGeoSelections;
}) {
  const { cardId, adminShowDraftData, categoriesPrimary } = props;
  const [loaded, setLoaded] = React.useState(false);

  const queryProps = { cardStateId: cardId };
  const [card, setCard] = useRecoilState(singleMicroCardQuery(queryProps));
  const [editModeOn, setDocEditing] = useRecoilState(reportEditModeOnQuery);
  const geoSelections = card.data.geoSelections;

  useEffect(() => {
    if (!defined(props.onGeoSelectionsUpdated)) {
      return;
    }
    if (!defined(geoSelections)) {
      return;
    }
    props.onGeoSelectionsUpdated(geoSelections);
  }, [geoSelections, props]);

  useEffect(() => {
    if (!defined(props.geoSelectionsToReplicate)) {
      return;
    }
    setCard((c) => {
      return {
        ...c,
        data: { ...c.data, geoSelections: props.geoSelectionsToReplicate },
      };
    });
  }, [props.geoSelectionsToReplicate, setCard]);

  const handleChangeGeotype = useChangeGeotype(cardId);

  useEffect(() => {
    if (!defined(card)) {
      return;
    }
    if (!editModeOn) {
      setDocEditing(true);
    } else {
    }
    if (
      editModeOn &&
      defined(card.data.dataSelections) &&
      card.data.dataSelections[0].measure?.id === props.measureId
    ) {
      setLoaded(true);
    }
  }, [card, editModeOn, props.measureId, setDocEditing]);

  useEffect(() => {
    // We never preview computed measures, so supplying undefined below is fine.
    getSingleMicroMeasure(props.measureId, undefined).then(async (res) => {
      const m = res.unwrap();
      const dims = (
        await getMicroDimensionsWithCache(props.measureId, adminShowDraftData)
      ).unwrap();
      const selectedDimensions: SelectedDimensionsV2 =
        getDefaultSelectedDimensions(dims);

      const availableDates = (
        await getMicroAvailableDates(
          props.measureId,
          selectedDimensions,
          adminShowDraftData
        )
      ).unwrap();

      const subjectPath = getSubjectPathMicro(m);

      const microMeasure = new MicroMeasure(m, dims);
      const dataSelection = microMeasure.isPrimary()
        ? makePrimaryMicroDataSelection(
            subjectPath,
            m,
            dims,
            availableDates ?? [],
            selectedDimensions,
            undefined
          )
        : makeMicroDataGeoSelection(
            subjectPath,
            m,
            dims,
            availableDates ?? [],
            selectedDimensions
          );

      setCard((baseCard) => ({
        ...baseCard,
        data: {
          ...baseCard.data,
          geoSelections: {
            type: microMeasure.geotypeSupported("deso") ? "deso" : "regso",
            selected: [],
          },
          dataSelections: [dataSelection],
        },
      }));
    });
  }, [adminShowDraftData, props.measureId, setCard]);

  const microOutputDisabled = !defined(card.data.dataSelections);
  const mockSharingInfo = useMemo(() => getSharingInfoMicroPreview(), []);

  if (!loaded) {
    return null;
  }

  return (
    <div className="document-card-container infostat-micro editing edit-mode-enabled">
      {card.data.dataSelections?.slice(0, 1)?.map((selection, index) => {
        const canBePrimary = true;
        const otherSelectedMeasures =
          card.data.dataSelections
            ?.filter((s, i) => i !== index)
            .map((s) => {
              const id = s.measure?.id;
              if (!defined(id)) {
                return;
              }
              return [id, s.measure?.computed?.type] as [
                number,
                ComputedMeasurementType
              ];
            })
            .filter(defined) || [];
        return (
          <MeasureSelectionMicro
            autofill
            otherSelectedMeasures={otherSelectedMeasures}
            acceptMeasureValueTypes={canBePrimary ? [] : dataValueTypesMicroGeo}
            key={selection.id}
            selection={selection}
            handleRemoveMeasure={voidFunc}
            handleChangeGeotype={handleChangeGeotype}
            categories={categoriesPrimary}
            handleChangeMeasure={voidFunc}
            handleChangePath={voidFunc}
            changePending={true}
            cardId={cardId}
          ></MeasureSelectionMicro>
        );
      })}
      <MicroOutputContainer
        isEditingCard={true}
        microOutputDisabled={microOutputDisabled}
        card={card}
        setCard={setCard}
        sharingInfo={mockSharingInfo}
      ></MicroOutputContainer>
    </div>
  );
}
