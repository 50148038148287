import {
  Checkbox,
  ChoiceGroup,
  ComboBox,
  Dropdown,
  IChoiceGroupOption,
  IComboBoxOption,
  IDropdownOption,
  TextField,
} from "@fluentui/react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AlertBox } from "../../../../../components/AlertBox";
import { Button } from "../../../../../components/Button";
import { Card } from "../../../../../components/Card";
import { FileDrop } from "../../../../../components/FileDrop";
import {
  AppMessagesContext,
  CategoriesContext,
} from "../../../../../lib/application/contexts";
import { replaceAtIndexImmut } from "../../../../../lib/application/state/generic";
import { classNames } from "../../../../../lib/core/classNames";
import { defined } from "../../../../../lib/core/defined";
import { truncate } from "../../../../../lib/core/truncate";

import {
  DataCellValue,
  MetadataField,
} from "../../../../../lib/application_admin/measure_creator/survey/types";
import { isEqual, sortBy } from "lodash";
import { assertNever } from "../../../../../lib/core/assert";
import {
  DataLine,
  SURVEY_METADATA_FIELDS,
  FIELD_AREA,
  FIELD_LABEL,
  FIELD_ORG_ACCESS,
  FIELD_QUESTION_TYPE,
  FIELD_RESOLUTION,
  FIELD_SUBAREA,
  FIELD_SUBJECT,
  questionTypes,
  subjectPathFields,
  answerLabelField,
} from "../../../../../lib/application_admin/measure_creator/survey/metadata_definition";
import { DimensionValueMinimal as DimensionValueMinimalGeneric } from "../../../../../lib/domain-admin/survey_dimensions";
import { DEFAULT_ANSWER_LABEL_SURVEY, IS_PROD } from "../../../../../config";
import {
  Categories,
  prepareCategoryParts,
} from "../../../../../lib/domain/categories";
import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import {
  getOrganizationsWithCache,
  submitMetadataSurvey,
} from "../../../../../lib/application/requests/admin/common_requests_admin";
import { DefaultLoading } from "../../../../../components/Loading";
import { validResolutions } from "../../../../../lib/domain/time";
import {
  ExternalMetadataResponseRT,
  NewExternalMeasureMetadata,
} from "../../../../../lib/domain-admin/external_metadata";

import "./CreateSurveyMeasure.scss";
import {
  metadataFromCsv,
  MetadataTable,
} from "../../../../../lib/application_admin/measure_creator/survey/metadataFromCsv";
import { logger } from "../../../../../lib/infra/logging";
import { FluentModal, FluentModalBody } from "../../../../../components/Modal";
import { EditableDimension } from "../../shared/DimensionsEditorV2";
import { DimensionV2Dto } from "../../../../../lib/domain/measure/definitions";
import { HttpResult } from "../../../../../lib/infra/HttpResult";

type DimensionValueMinimal = DimensionValueMinimalGeneric<number>;

type EditedMultiField = {
  line: number;
  values: DimensionValueMinimal[] | null;
  field: MetadataField;
};

type EditedTextField = {
  line: number;
  field: MetadataField;
};

interface Organization {
  id: string;
  name: string;
}

interface CellSelection {
  field: MetadataField;
  lineStart: number;
  lineEnd: number;
}
interface CellPointer {
  field: MetadataField;
  line: number;
}

interface SelectionState {
  selectStart: CellPointer;
  lastHover: CellPointer;
}

export function CreateSurveyMeasure(props: { isCustomerSurvey: boolean }) {
  const [file, setFile] = useState<File>();
  const [parsedFile, setParsedFile] = useState<MetadataTable>();
  const [parseErrors, setParseErrors] = useState<string[]>();

  const appMessages = useContext(AppMessagesContext);

  const handleUploadFile = useCallback(
    (f: File) => {
      setFile(f);
      f.text()
        .then((contents) => {
          const parseResult = metadataFromCsv(contents);
          parseResult.match({
            ok: setParsedFile,
            err: setParseErrors,
          });
        })
        .catch((e) => {
          appMessages?.add("error", "Kunde inte ladda filen: " + e.message);
        });
    },
    [appMessages]
  );

  const handleReset = useCallback(() => {
    setParsedFile(undefined);
    setFile(undefined);
    setParseErrors(undefined);
  }, []);

  return (
    <Card id="create-survey-measure">
      <>
        {!IS_PROD && (
          <AlertBox intent="danger">
            <span>
              Du är ej i produktionsmiljön. Om du fortsätter att skapa ett mått
              kommer det endast vara synligt i utvecklingsmiljön. Kunder kommer
              inte kunna se måttet.
            </span>
          </AlertBox>
        )}
      </>
      <h2>Nytt surveymått {props.isCustomerSurvey ? "(kundmått)" : ""}</h2>
      <>
        {defined(parseErrors) && (
          <AlertBox intent="danger">
            <div>
              {parseErrors.map((e) => (
                <p key={e}>{e}</p>
              ))}
            </div>
          </AlertBox>
        )}
        {!defined(file) && (
          <FileDrop
            multi={false}
            accept=".csv"
            onSubmit={handleUploadFile}
          ></FileDrop>
        )}
        {defined(parsedFile) && (
          <TableAndInput
            isCustomerSurvey={props.isCustomerSurvey}
            handleReset={handleReset}
            parsedCsv={parsedFile}
          ></TableAndInput>
        )}
      </>
    </Card>
  );
}

const optionKeyLock = "lock";
const optionKeyPublic = "public";
const optionKeys = [optionKeyLock, optionKeyPublic] as const;
type OptionKeys = (typeof optionKeys)[number];

function TableAndInput(props: {
  isCustomerSurvey: boolean;
  parsedCsv: MetadataTable;
  handleReset: () => void;
}) {
  const header = props.parsedCsv.header;
  const [data, setData] = useState<DataLine[]>(
    props.parsedCsv.dataLines.map((l) => {
      return new DataLine(
        l.map((csvValue) => {
          if (Array.isArray(csvValue)) {
            return csvValue.map((v, i) => {
              return { id: i, label: v, sort_order: i, sort_mode: "fixed_top" };
            });
          }
          return csvValue;
        })
      );
    })
  );
  const [selection, setSelection] = useState<CellSelection | undefined>(
    undefined
  );
  const [useDefaultBackgroundFilters, setUseDefaultBackgroundFilters] =
    useState(!props.isCustomerSurvey);
  const [copy, setCopy] = useState<CellPointer>();
  const [selectionState, setSelectionState] = useState<SelectionState>();
  const [compactMode, setCompactMode] = useState(true);
  const [editedMultiField, setEditedMultiField] = useState<EditedMultiField>();
  const [editedTextField, setEditedTextField] = useState<EditedTextField>();
  const [organizations, setOrganizations] = useState<Organization[]>();
  const [allInputValid, setAllInputValid] = useState(true);

  const [selectedLockMode, setSelectedLockMode] =
    useState<OptionKeys>(optionKeyLock);
  const [selectedOrgs, setSelectedOrgs] = useState<string[] | undefined>();
  const [selectedOrgsErrorMessage, setSelectedOrgsErrorMessage] = useState<
    string | undefined
  >();
  const [makeSurveyStringMeasure, setMakeSurveyStringMeasure] = useState(false);

  const categories = useContext(CategoriesContext);
  useEffect(() => {
    getOrganizationsWithCache().then((orgs) =>
      setOrganizations(sortBy(orgs, (o) => o.name))
    );
  }, []);

  const appMessages = useContext(AppMessagesContext);

  const validate = useCallback(() => {
    let allLinesValid = true;
    for (const line of data) {
      const lineErrors: string[] = [];
      SURVEY_METADATA_FIELDS.forEach((f, i) => {
        const res = validateField(f, line, line.fieldValue(f));
        if (defined(res)) {
          lineErrors.push(res);
        }
      });
      allLinesValid = allLinesValid && lineErrors.length === 0;
      if (lineErrors.length > 0) {
        appMessages?.add("error", lineErrors.join("; "));
      }
    }
    if (selectedLockMode === optionKeyLock) {
      if (!defined(selectedOrgs) || selectedOrgs.length === 0) {
        setSelectedOrgsErrorMessage("Du måste välja minst en organisation");
        allLinesValid = false;
      } else {
        setSelectedOrgsErrorMessage(undefined);
      }
    }
    return allLinesValid;
  }, [appMessages, data, selectedLockMode, selectedOrgs]);

  useEffect(() => {
    const allValid = validate();
    setAllInputValid(allValid);
  }, [validate]);

  const hiddenFieldIndexes: number[] = useMemo(() => {
    const shouldShow = header.map(() => false);
    for (const line of data) {
      for (let i = 0; i < SURVEY_METADATA_FIELDS.length; i++) {
        try {
          const f = header[i].field;
          // We only hide background columns, so if it's not a background column, always show it
          if (!f.startsWith("background") && !f.startsWith("breakdown")) {
            shouldShow[i] = true;
            continue;
          }

          if (defined(line.fieldValue(header[i]))) {
            shouldShow[i] = true;
          }
        } catch (e) {
          logger.error("Could not find a field for csv column", e);
        }
      }
    }

    // Values columns are shown only if their twin column is shown
    header.forEach((h, i) => {
      const f = header[i].field;
      if (f.endsWith("_values")) {
        shouldShow[i] = shouldShow[i - 1];
      }
    });

    // Map to array index
    return shouldShow
      .map((shouldShow, i) => (shouldShow ? -1 : i))
      .filter((i) => i >= 0);
  }, [data, header]);

  const handleSubmit = useCallback(() => {
    const simpleFieldIndexes = SURVEY_METADATA_FIELDS.map((f, i) =>
      f.type === "string" ? i : -1
    ).filter((i) => i >= 0);
    const dimensionIndexes = SURVEY_METADATA_FIELDS.map((f, i) =>
      f.type === "multi-string" ? i : -1
    ).filter((i) => i >= 0);

    const labelFieldIndexes: number[] = [];
    SURVEY_METADATA_FIELDS.forEach((f, i) => {
      const labelField = f.labelField;
      if (defined(labelField)) {
        labelFieldIndexes.push(
          SURVEY_METADATA_FIELDS.findIndex((ext) => ext.field === labelField)
        );
      }
    });

    const allValid = validate();
    if (!allValid) {
      return;
    }

    const finalMetadata = data.map((line) => {
      const metadata: NewExternalMeasureMetadata = { dimensions: [] };

      for (const i of simpleFieldIndexes) {
        if (labelFieldIndexes.includes(i)) {
          continue;
        }

        const field = SURVEY_METADATA_FIELDS[i];
        if (field.field === FIELD_ORG_ACCESS) {
          metadata[field.dataColumn] = [line.fieldValue(field)];
        } else if (field.dataColumn.startsWith("background")) {
          const label = line.fieldValue(field);
          if (!defined(label)) {
            continue;
          }
          if (typeof label !== "string") {
            throw new Error("Background label is not a string");
          }
          metadata.dimensions.push({
            data_column: field.dataColumn,
            label: label,
            type: "survey_background",
            values: [],
          });
        } else if (field.dataColumn.startsWith("subquestion")) {
          const label = line.fieldValue(field);
          if (!defined(label)) {
            continue;
          }
          if (typeof label !== "string") {
            throw new Error("Background label is not a string");
          }
          metadata.dimensions.push({
            data_column: field.dataColumn,
            label: label,
            type: "survey_subquestion",
            values: [],
          });
        } else if (field.dataColumn === "answer_value") {
          const label = line.fieldValue(
            SURVEY_METADATA_FIELDS.find((f) => f.field === "answer_label")!
          );
          if (!defined(label)) {
            throw new Error("Answer label is undefined");
          }
          if (typeof label !== "string") {
            throw new Error("Answer label is not a string");
          }
          const values = line.fieldValue(field);
          metadata.dimensions.push({
            data_column: field.dataColumn,
            label: label,
            type: "survey_subquestion",
            values: values as DimensionValueMinimal[],
          });
        } else {
          const currentValue = line.fieldValue(field);
          if (!defined(currentValue) && field.requiredWhen?.type === "always") {
            throw new Error("Required field is undefined: " + field.field);
          }

          if (defined(currentValue)) {
            metadata[field.dataColumn] = currentValue;
          }
        }
      }

      for (const i of dimensionIndexes) {
        const field = SURVEY_METADATA_FIELDS[i];
        const dataColumn = field.dataColumn;
        const requiredWhen = field.requiredWhen;
        const values = line.fieldValue(field);
        if (!defined(values)) {
          continue;
        }

        let label;
        if (defined(requiredWhen) && requiredWhen.type === "fieldUsed") {
          const labelField = header.find(
            (headerField) => headerField.field === requiredWhen.field
          );
          if (!defined(labelField)) {
            throw new Error("labelField not defined");
          }

          label = line.fieldValue(labelField);
        } else if (field.dataColumn === "value") {
          const labelField = answerLabelField;
          const fieldValue = line.fieldValue(labelField);
          label = fieldValue ?? DEFAULT_ANSWER_LABEL_SURVEY;
        } else {
          label = field.label;
        }
        if (!defined(label)) {
          throw new Error("label is undefined");
        }
        if (!defined(field.dimensionType)) {
          throw new Error(
            "field.dimensionType is not defined " + field.dimensionType
          );
        }
        if (!Array.isArray(values)) {
          throw new Error("values is not array");
        }
        metadata.dimensions.push({
          data_column: dataColumn,
          label: label as string,
          type: field.dimensionType,
          values: values.map((v) => ({ ...v, label: v.label.trim() })),
        });
      }

      if (makeSurveyStringMeasure) {
        metadata["value_type"] = "survey_string";
      }

      return metadata;
    });

    if (
      selectedLockMode === "lock" &&
      (!defined(selectedOrgs) || selectedOrgs.length === 0)
    ) {
      throw new Error("selectedOrgs is undefined");
    }

    submitMetadataSurvey(
      { measurements: finalMetadata },
      selectedLockMode === "public"
        ? { restricted: false }
        : { restricted: true, organizations: selectedOrgs! },
      useDefaultBackgroundFilters,
      props.isCustomerSurvey
    ).then((res) => {
      res.match({
        ok: (ok) => {
          appMessages?.add("success", "Metadata skapad");
          props.handleReset();
        },
        err: (err) => {
          const info = err.info;
          if (!defined(info)) {
            return appMessages?.add(
              "error",
              "Misslyckades med att skapa metadata"
            );
          }

          if (info.type === "errorCode") {
            return appMessages?.add(
              "error",
              `Misslyckades med att skapa metadata: ${info.error}, ${info.message}`
            );
          } else if (info.type === "errorMessage") {
            return appMessages?.add(
              "error",
              `Misslyckades med att skapa metadata: ${info.message}`
            );
          }

          try {
            const errorData = ExternalMetadataResponseRT.check(info.data);
            const errors = Object.entries(errorData.validation_errors);
            if (errors.length === 0) {
              appMessages?.add("success", "Metadata skapad");
              props.handleReset();
              return;
            } else if (errors.length !== finalMetadata.length) {
              appMessages?.add(
                "warning",
                "Några mått skapade, fel fanns för de som återstår"
              );
              setData(
                data.filter((l) => {
                  const label = l.fieldValue(
                    SURVEY_METADATA_FIELDS.find((f) => f.field === FIELD_LABEL)!
                  );
                  return !errors.some(([key]) => key === label);
                })
              );
            } else {
              appMessages?.add("warning", "Fel vid skapande av mått");
            }
            for (const [label, errorSet] of errors) {
              appMessages?.add(
                "error",
                `${label}: ${Object.entries(errorSet)
                  .map(([key, err]) => `${key}: ${err}`)
                  .join("; ")})`
              );
            }
          } catch (e) {
            appMessages?.add(
              "error",
              "Misslyckades med att tolka felinformation"
            );
          }
        },
      });
    });
  }, [
    appMessages,
    data,
    header,
    makeSurveyStringMeasure,
    props,
    selectedLockMode,
    selectedOrgs,
    useDefaultBackgroundFilters,
    validate,
  ]);

  const handleUpdateField = useCallback(
    (field: MetadataField, line: number, value: DataCellValue) => {
      setData((data) => {
        const newData = [...data];
        newData[line] = newData[line].replaceFieldImmut(field, value);
        return newData;
      });
    },
    []
  );

  const allValuesEqColumns = useMemo(() => {
    const columns: DataCellValue[][] = header.map(() => []);
    for (const line of data) {
      line.cells().forEach((cell, i) => columns[i].push(cell));
    }
    return columns.map(
      (cells) =>
        cells[0] !== null && cells.every((cell) => dataCellEq(cell, cells[0]))
    );
  }, [data, header]);

  const handleCopy = useCallback(() => {
    if (defined(selection)) {
      setCopy({ field: selection.field, line: selection.lineStart });
    }
  }, [selection]);

  const handlePaste = useCallback(() => {
    if (!defined(copy) || !defined(selection)) {
      return;
    }

    const dataCopy: DataLine[] = [];
    const cellToPaste = data[copy.line].fieldValue(copy.field);

    data.forEach((line, i) => {
      if (rangeContains(selection.lineStart, selection.lineEnd, i)) {
        dataCopy.push(line.replaceFieldImmut(selection.field, cellToPaste));
      } else {
        dataCopy.push(line);
      }
    });
    setData(dataCopy);
  }, [copy, data, selection]);

  const handleEditMultiValueField = useCallback(
    (line: number, field: MetadataField) => {
      const currentValue = data[line].fieldValue(field);
      if (typeof currentValue === "string") {
        throw new Error("Invalid value found for multi-value field");
      }
      setEditedMultiField({ line, values: currentValue, field });
    },
    [data]
  );

  const handleSaveMultiValueField = useCallback(
    (dimension: DimensionV2Dto, editedField: EditedMultiField) => {
      const values = dimension.values;
      const lineIndex = editedField.line;
      const updatedLine = data[lineIndex].replaceFieldImmut(
        editedField.field,
        values
      );
      setData(replaceAtIndexImmut(data, lineIndex, updatedLine));
    },
    [data]
  );

  const editedMultiFieldValues = useMemo(() => {
    if (!defined(editedMultiField)) {
      return [];
    }
    const value = data[editedMultiField.line].fieldValue(
      editedMultiField.field
    );
    if (!Array.isArray(value)) {
      throw new Error("Unexpected type of multi field!");
    }
    return value;
  }, [data, editedMultiField]);

  const editedMultiFieldLabel = useMemo(() => {
    if (!defined(editedMultiField)) {
      return;
    }

    const requiredWhen = editedMultiField.field.requiredWhen;
    if (!defined(requiredWhen) || requiredWhen.type !== "fieldUsed") {
      return;
    }
    const field = requiredWhen.field;
    const dataLine = data[editedMultiField.line];
    const metadataField = SURVEY_METADATA_FIELDS.find((f) => f.field === field);
    if (!defined(metadataField)) {
      throw new Error("Could not find metadataField for " + field);
    }
    const value = dataLine.fieldValue(metadataField);
    if (typeof value !== "string") {
      return;
    }
    return value;
  }, [data, editedMultiField]);

  const originalEditableDimension = useMemo(() => {
    if (!defined(editedMultiField)) {
      return;
    }
    return {
      data_column: editedMultiField.field.dataColumn,
      label: `${editedMultiFieldLabel} (${editedMultiField.field.field})`,
      dimension_id: -1,
      sort_order: -1,
      type: editedMultiField.field.dimensionType!,
      values: editedMultiFieldValues ?? [],
    };
  }, [editedMultiField, editedMultiFieldLabel, editedMultiFieldValues]);
  const handleCloneDimension = useCallback((v: DimensionV2Dto) => {
    return {
      ...v,
      values: v.values?.map((v) => ({ ...v })) ?? [],
    };
  }, []);

  const handleSaveDimension = useCallback(
    (d: DimensionV2Dto) => {
      if (!defined(editedMultiField)) {
        return Promise.resolve(HttpResult.fromOk(undefined));
      }
      handleSaveMultiValueField(d, editedMultiField);
      return Promise.resolve(HttpResult.fromOk(undefined));
    },
    [editedMultiField, handleSaveMultiValueField]
  );

  if (!defined(organizations)) {
    return <DefaultLoading></DefaultLoading>;
  }

  const options: IChoiceGroupOption[] = [
    { key: optionKeyLock, text: "Lås till en eller flera organisationer" },
    {
      key: optionKeyPublic,
      text: "Gör publik för alla Infostat-användare",
      disabled: props.isCustomerSurvey,
    },
  ];

  return (
    <>
      {defined(editedMultiField) && defined(originalEditableDimension) && (
        <FluentModal
          onClose={() => setEditedMultiField(undefined)}
          title="Redigera värden"
          isOpen={true}
        >
          <FluentModalBody>
            <EditableDimension
              disableEditLabel
              enableDelete={true}
              clone={handleCloneDimension}
              handleSave={handleSaveDimension}
              originalDimension={originalEditableDimension}
            ></EditableDimension>
          </FluentModalBody>
        </FluentModal>
      )}

      <div className="table-wrapper">
        <section>
          {hiddenFieldIndexes.length > 0 && (
            <p>
              Ej använda kolumner:{" "}
              {hiddenFieldIndexes
                .filter((i) => header[i].type !== "multi-string")
                .map((i) => header[i].label)
                .join(", ")}
            </p>
          )}
          <div>
            <Checkbox
              checked={compactMode}
              onChange={(e, c) => setCompactMode(c ?? false)}
              label="Kompakt visning"
            ></Checkbox>
          </div>
        </section>

        <section>
          <section>
            <ChoiceGroup
              selectedKey={selectedLockMode}
              options={options}
              onChange={(e, option) => {
                const key = option?.key;
                if (defined(key)) {
                  setSelectedLockMode(key as any);
                }
              }}
              label="Lås mått till organisation?"
              required={true}
            />
          </section>
          {selectedLockMode === optionKeyLock && (
            <section>
              <Dropdown
                style={{ maxWidth: "400px" }}
                required
                errorMessage={selectedOrgsErrorMessage}
                multiSelect
                onChange={(_, option) => {
                  if (!defined(option)) {
                    return;
                  }
                  const key = option.key as string;
                  if (selectedOrgs?.includes(key)) {
                    setSelectedOrgs(selectedOrgs.filter((o) => o !== key));
                  } else {
                    setSelectedOrgs(
                      defined(selectedOrgs) ? [...selectedOrgs, key] : [key]
                    );
                  }
                }}
                selectedKeys={selectedOrgs}
                options={organizations.map((org) => {
                  return { key: org.id, text: org.name };
                })}
              ></Dropdown>
            </section>
          )}
        </section>
        <section>
          <Checkbox
            disabled
            checked={useDefaultBackgroundFilters}
            onChange={(e, c) => setUseDefaultBackgroundFilters(c ?? false)}
            label="Lägg automatiskt till de vanliga bakgrundsfiltren"
          ></Checkbox>
        </section>
        <section>
          <Checkbox
            checked={makeSurveyStringMeasure}
            onChange={(e, c) => setMakeSurveyStringMeasure(c ?? false)}
            label="Gör till fritextmått"
          ></Checkbox>
        </section>

        <section>
          <Button
            small
            icon="duplicate"
            title="Kopiera"
            disabled={
              !defined(selection) || selection.lineEnd !== selection.lineStart
            }
            onClick={handleCopy}
          ></Button>
          <Button
            small
            icon="clipboard"
            title="Klistra in"
            onClick={handlePaste}
            disabled={
              !defined(copy) || copy.field.type !== selection?.field.type
            }
          ></Button>
        </section>

        <section className="table-output">
          <table className={classNames(compactMode ? "compact" : "")}>
            <thead>
              <tr>
                {header.map((h, i) => (
                  <th
                    key={h.field}
                    className={classNames(
                      hiddenFieldIndexes.includes(i) ? "hidden" : "",
                      allValuesEqColumns[i] ? "all-values-eq" : ""
                    )}
                  >
                    {h.displayLabel ?? h.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, ri) => (
                <TableRow
                  categories={categories}
                  organizations={organizations}
                  row={row}
                  rowIndex={ri}
                  selection={selection}
                  setSelection={setSelection}
                  selectionState={selectionState}
                  setSelectionState={setSelectionState}
                  allValuesEqColumns={allValuesEqColumns}
                  header={header}
                  handleEditMultiValueField={handleEditMultiValueField}
                  compactMode={compactMode}
                  key={ri}
                  editedTextField={editedTextField}
                  setEditedTextField={setEditedTextField}
                  hiddenFieldIndexes={hiddenFieldIndexes}
                  handleUpdateField={handleUpdateField}
                ></TableRow>
              ))}
            </tbody>
          </table>
        </section>
        <div>
          {!allInputValid && (
            <AlertBox intent="warning">
              <p>Fyll i alla uppgifter innan du laddar upp.</p>
            </AlertBox>
          )}
          <Button
            intent="primary"
            title="Ladda upp"
            disabled={!allInputValid}
            onClick={handleSubmit}
          ></Button>
        </div>
      </div>
    </>
  );
}

function TableRow(props: {
  organizations: Organization[];
  categories: Categories;
  row: DataLine;
  rowIndex: number;
  selection: CellSelection | undefined;
  setSelection: (s?: CellSelection) => void;
  selectionState: SelectionState | undefined;
  setSelectionState: (s?: SelectionState) => void;
  compactMode: boolean;
  allValuesEqColumns: boolean[];
  hiddenFieldIndexes: number[];
  header: readonly MetadataField[];
  editedTextField: EditedTextField | undefined;
  setEditedTextField: (s?: EditedTextField) => void;
  handleEditMultiValueField: (rowIndex: number, f: MetadataField) => void;
  handleUpdateField: (
    f: MetadataField,
    rowIndex: number,
    value: DataCellValue
  ) => void;
}) {
  const {
    row,
    rowIndex: ri,
    selection,
    setSelection,
    setSelectionState,
    selectionState,
    allValuesEqColumns,
    hiddenFieldIndexes,
    header,
    compactMode,
    setEditedTextField,
    editedTextField,
    handleEditMultiValueField,
    categories,
  } = props;

  const areaField = SURVEY_METADATA_FIELDS.find((f) => f.field === FIELD_AREA);
  const subareaField = SURVEY_METADATA_FIELDS.find(
    (f) => f.field === FIELD_SUBAREA
  )!;
  const subjectField = SURVEY_METADATA_FIELDS.find(
    (f) => f.field === FIELD_SUBJECT
  )!;
  if (!defined(areaField) || !defined(subareaField) || !defined(subjectField)) {
    throw new Error("Missing area, subarea or subject field");
  }

  const area = row.fieldValue(areaField) as string;
  const subarea = row.fieldValue(subareaField) as string;
  const subject = row.fieldValue(subjectField) as string;
  const { areas, subareas, subjects } = prepareCategoryParts(
    [area, subarea, subject],
    categories
  );

  const onMouseDown = useCallback(
    (f: MetadataField, line: number) => {
      if (
        defined(editedTextField) &&
        (editedTextField.field.field !== f.field ||
          editedTextField.line !== line)
      ) {
        setEditedTextField(undefined);
      }
      setSelectionState({
        selectStart: { field: f, line },
        lastHover: { field: f, line },
      });
    },
    [editedTextField, setEditedTextField, setSelectionState]
  );

  const onMouseMove = useCallback(
    (f: MetadataField, line: number) => {
      if (
        defined(selectionState) &&
        selectionState.selectStart.field.field === f.field
      ) {
        setSelection(undefined);
        setSelectionState({
          ...selectionState,
          lastHover: { field: f, line },
        });
      }
    },
    [selectionState, setSelection, setSelectionState]
  );

  const onMouseUp = useCallback(
    (f: MetadataField, line: number) => {
      if (
        defined(selectionState) &&
        selectionState.selectStart.field.field === f.field
      ) {
        if (selectionState.selectStart.line === line) {
          // Same cell as where selection started => select this cell, or if already selected, activate cell editing
          if (
            defined(selection) &&
            selection.field.field === f.field &&
            selection.lineStart === selection.lineEnd &&
            selection.lineStart === line
          ) {
            if (f.type === "multi-string") {
              handleEditMultiValueField(line, f);
            } else {
              setEditedTextField({
                field: f,
                line,
              });
            }
          }
        }

        setSelection({
          field: selectionState.selectStart.field,
          lineStart: selectionState.selectStart.line,
          lineEnd: line,
        });
      }
      setSelectionState(undefined);
    },
    [
      handleEditMultiValueField,
      selection,
      selectionState,
      setEditedTextField,
      setSelection,
      setSelectionState,
    ]
  );

  return (
    <tr key={ri}>
      {row
        .cells()
        .map((cell, ci) => ({ cell, ci }))
        .filter(({ ci }) => !hiddenFieldIndexes.includes(ci))
        .map(({ cell, ci }) => {
          const f = header[ci];
          const { disabled, valid } = fieldAttributes(f, cell, row);
          const requiredStringEmpty =
            typeof cell === "string" && !nonEmptyString(cell);

          const isEditing =
            editedTextField?.line === ri &&
            editedTextField.field.field === f.field;
          const tdProps = {
            onMouseDown: () => onMouseDown(f, ri),
            onMouseMove: () => onMouseMove(f, ri),
            onMouseUp: () => onMouseUp(f, ri),
            key: f.field,
            className: classNames(
              allValuesEqColumns[ci] ? "all-values-eq" : "",
              f.type === "multi-string" ? "multi-value" : "",
              requiredStringEmpty ? "invalid" : "",
              !valid ? "invalid" : "",
              disabled ? "disabled" : "",
              (defined(selection) &&
                selection.field.field === f.field &&
                rangeContains(selection.lineStart, selection.lineEnd, ri)) ||
                (defined(selectionState) &&
                  selectionState.selectStart.field.field === f.field &&
                  rangeContains(
                    selectionState.selectStart.line,
                    selectionState.lastHover.line,
                    ri
                  ))
                ? "selected"
                : "",
              isEditing ? "editing" : ""
            ),
          };
          if (subjectPathFields.includes(f.field)) {
            const isNewCategory =
              typeof cell === "string" &&
              nonEmptyString(cell) &&
              ((f.field === FIELD_AREA && !areas.includes(cell)) ||
                (f.field === FIELD_SUBAREA && !subareas?.includes(cell)) ||
                (f.field === FIELD_SUBJECT && !subjects?.includes(cell)));
            return (
              <td
                {...tdProps}
                className={classNames(
                  tdProps.className,
                  isNewCategory ? "new-category" : ""
                )}
              >
                <DisplayFreeFormSelectCell
                  handleChangeTextValue={(value) => {
                    const subareaField = SURVEY_METADATA_FIELDS.find(
                      (f) => f.field === FIELD_SUBAREA
                    );
                    const subjectField = SURVEY_METADATA_FIELDS.find(
                      (f) => f.field === FIELD_SUBJECT
                    );
                    props.handleUpdateField(f, ri, value);
                    if (f.field === FIELD_AREA) {
                      props.handleUpdateField(subareaField!, ri, "");
                      props.handleUpdateField(subjectField!, ri, "");
                    } else if (f.field === FIELD_SUBAREA) {
                      props.handleUpdateField(subjectField!, ri, "");
                    }
                  }}
                  f={f}
                  options={
                    f.field === FIELD_AREA
                      ? areas
                      : f.field === FIELD_SUBAREA
                      ? subareas
                      : subjects
                  }
                  cell={cell as string | null}
                ></DisplayFreeFormSelectCell>
              </td>
            );
          } else if (f.field === FIELD_QUESTION_TYPE) {
            return (
              <td
                {...tdProps}
                className={classNames(
                  tdProps.className,
                  questionTypes.includes(cell as string) ? "" : "invalid"
                )}
              >
                <DisplaySelectCell
                  handleChangeTextValue={(value) => {
                    props.handleUpdateField(f, ri, value);
                  }}
                  f={f}
                  options={questionTypes.map((t) => ({ key: t, text: t }))}
                  cell={cell as string | null}
                ></DisplaySelectCell>
              </td>
            );
          } else if (f.field === FIELD_ORG_ACCESS) {
            return (
              <td
                {...tdProps}
                className={classNames(
                  tdProps.className,
                  questionTypes.includes(cell as string) ? "" : "invalid"
                )}
              >
                <DisplaySelectCell
                  handleChangeTextValue={(value) => {
                    props.handleUpdateField(f, ri, value);
                  }}
                  f={f}
                  options={props.organizations.map((o) => ({
                    key: o.id,
                    text: o.name,
                  }))}
                  cell={cell as string | null}
                ></DisplaySelectCell>
              </td>
            );
          } else if (f.field === FIELD_RESOLUTION) {
            return (
              <td
                {...tdProps}
                className={classNames(
                  tdProps.className,
                  (validResolutions as readonly string[]).includes(
                    cell as string
                  )
                    ? ""
                    : "invalid"
                )}
              >
                <DisplaySelectCell
                  handleChangeTextValue={(value) => {
                    props.handleUpdateField(f, ri, value);
                  }}
                  f={f}
                  options={validResolutions.map((r) => ({ key: r, text: r }))}
                  cell={cell as string | null}
                ></DisplaySelectCell>
              </td>
            );
          }
          return (
            <td {...tdProps}>
              <DisplayCell
                editedTextField={isEditing ? editedTextField : undefined}
                handleChangeTextValue={(value) =>
                  props.handleUpdateField(f, ri, value)
                }
                f={f}
                compactMode={compactMode}
                cell={cell}
              ></DisplayCell>
            </td>
          );
        })}
    </tr>
  );
}

function rangeContains(
  rangeStart: number,
  rangeEndInclusive: number,
  index: number
) {
  if (rangeStart > rangeEndInclusive) {
    return rangeEndInclusive <= index && rangeStart >= index;
  }
  return rangeStart <= index && rangeEndInclusive >= index;
}

function fieldAttributes(
  f: MetadataField,
  cell: DataCellValue,
  row: DataLine
): { valid: boolean; disabled: boolean } {
  const req = f.requiredWhen;
  const valid = validateField(f, row, cell) === undefined;
  if (!defined(req)) {
    return { valid, disabled: false };
  }
  if (req.type === "always") {
    return { valid, disabled: false };
  } else if (req.type === "fieldUsed") {
    const twinField = SURVEY_METADATA_FIELDS.find((m) => m.field === req.field);
    if (!defined(twinField)) {
      throw new Error("Invalid field used in requiredWhen: " + req.field);
    }
    const twinCell = row.fieldValue(twinField);
    return {
      valid,
      disabled: !defined(twinCell),
    };
  }
  assertNever(req);
}

function DisplaySelectCell(props: {
  f: MetadataField;
  options: IDropdownOption[];
  cell: string | null;
  handleChangeTextValue: (value: string) => void;
}) {
  const cell = props.cell;

  return (
    <Dropdown
      className="select-cell"
      options={props.options}
      selectedKey={cell}
      onChange={(e, option) => {
        if (defined(option)) {
          props.handleChangeTextValue(option.key as string);
        }
      }}
    ></Dropdown>
  );
}

function DisplayFreeFormSelectCell(props: {
  f: MetadataField;
  options: string[] | undefined;
  cell: string | null;
  handleChangeTextValue: (value: string) => void;
}) {
  const cell = props.cell;
  const options: IComboBoxOption[] = useMemo(() => {
    const baseOptions =
      props.options?.map<IComboBoxOption>((s) => ({ key: s, text: s })) ?? [];
    if (!props.options?.includes(cell as string)) {
      baseOptions.push({ key: cell as string, text: cell as string });
    }
    return baseOptions;
  }, [cell, props.options]);

  return (
    <ComboBox
      className="select-cell"
      styles={{
        optionsContainerWrapper: {
          border: "none",
        },
        container: { height: "16px", border: "none" },
        input: { border: "none", outline: "none" },
      }}
      allowFreeform={true}
      options={options}
      selectedKey={cell}
      onChange={(e, option, index, value) => {
        if (defined(option)) {
          props.handleChangeTextValue(option.key as string);
        } else if (defined(value)) {
          props.handleChangeTextValue(value as string);
        }
      }}
    ></ComboBox>
  );
}

function DisplayCell(props: {
  f: MetadataField;
  editedTextField: EditedTextField | undefined;
  compactMode: boolean;
  cell: string | DimensionValueMinimal[] | null;
  handleChangeTextValue: (value: string) => void;
}) {
  const cell = props.cell;
  const f = props.f;
  const compactMode = props.compactMode;
  const maxLength = f.truncatedLength;
  const editedTextField = props.editedTextField;

  if (f.type === "string") {
    if (!(typeof cell === "string" || cell === null)) {
      throw new Error("Invalid cell content, expected string | null: " + cell);
    }

    if (defined(editedTextField)) {
      return (
        <TextField
          value={cell ?? ""}
          onChange={(e) => {
            props.handleChangeTextValue(e.currentTarget.value);
          }}
        ></TextField>
      );
    }
    return (
      <span title={cell ?? "[tomt]"}>
        {compactMode && defined(maxLength)
          ? truncate(cell ?? "", maxLength)
          : cell ?? ""}
      </span>
    );
  }

  if (cell === null) {
    return <span></span>;
  }

  if (!Array.isArray(cell)) {
    throw new Error("Invalid cell content, expected array: " + cell);
  }

  const joinedValues = cell.map((v) => v.label).join(", ");
  return (
    <>
      <span title={joinedValues}>
        {compactMode && defined(maxLength)
          ? truncate(joinedValues, maxLength)
          : joinedValues}
      </span>
    </>
  );
}

function dataCellEq(left: DataCellValue, right: DataCellValue): boolean {
  if (left === null || typeof left === "string") {
    return left === right;
  }

  // Left is not any of these types, so right should not be either
  if (right === null || typeof right === "string") {
    return false;
  }

  if (left.length !== right.length) {
    return false;
  }
  return left.every((l, i) => {
    return isEqual(l, right[i]);
  });
}

function validateField(
  field: MetadataField,
  dataLine: DataLine,
  cell: DataCellValue
): string | undefined {
  const requiredWhen = field.requiredWhen;
  if (defined(requiredWhen)) {
    if (requiredWhen.type === "always" && !defined(cell)) {
      return `Fältet ${field.displayLabel ?? field.label} är obligatoriskt`;
    }
    if (requiredWhen.type === "fieldUsed") {
      const twinField = SURVEY_METADATA_FIELDS.find(
        (f) => f.field === requiredWhen.field
      );
      if (!defined(twinField)) {
        throw new Error("Invalid field used in requiredWhen: " + field.field);
      }
      if (defined(dataLine.fieldValue(twinField)) && !defined(cell)) {
        return `Fältet ${field.field} är obligatoriskt om ${twinField.field} används`;
      }
    }

    if (defined(cell) && defined(field.labelField)) {
      const labelField = SURVEY_METADATA_FIELDS.find(
        (f) => f.field === field.labelField
      );
      if (!defined(labelField)) {
        throw new Error(
          "Invalid label field in requiredWhen: " + field.labelField
        );
      }

      if (!defined(dataLine.fieldValue(labelField))) {
        return `Fältet ${labelField.field} är obligatoriskt om ${field.field} används`;
      }
    }
  }

  return undefined;
}
