import { useCallback } from "react";
import {
  editMicroDimension,
  getUpdateConflictsMikro as getUpdateConflictsMicro,
} from "../../../../../lib/application/requests/admin/measure_management/edit";
import {
  commitPendingChangesMicro,
  revertPendingChangesMicro,
} from "../../../../../lib/application/requests/admin/measure_management/micro/import";
import { getMicroDimensionsWithCache } from "../../../../../lib/application/requests/datasets/micro";
import { DimensionV2Dto } from "../../../../../lib/domain/measure/definitions";
import { MeasureConfirmation } from "../_shared/MeasureConfirmation";
import { DraftDataTodo } from "../../../../../lib/application_admin/todos/draft_data_todos";
import {
  renderCommitResult,
  renderRevertResult,
} from "../_shared/renderCommitRevert";

interface Props {
  id: number;
  todos?: DraftDataTodo[];
  reloadTodos: () => void;
}

export function MicroMeasureConfirmation(props: Props) {
  const getDims = useCallback(
    (id: number) => getMicroDimensionsWithCache(props.id, true),
    [props.id]
  );

  const getConflicts = useCallback(async (id: number) => {
    const dims = await getUpdateConflictsMicro(id);
    return dims;
  }, []);

  const editDimension = useCallback(
    (dimensionId: number, dimension: DimensionV2Dto) => {
      if (dimension.type !== "mikro_breakdown") {
        throw new Error("Invalid dimension type");
      }
      return editMicroDimension(dimensionId, dimension);
    },
    []
  );
  return (
    <MeasureConfirmation
      {...props}
      type="micro"
      getConflicts={getConflicts}
      getDimensions={getDims}
      editDimension={editDimension}
      revertPendingChanges={revertPendingChangesMicro}
      commitPendingChanges={commitPendingChangesMicro}
      renderCommitDto={renderCommitResult}
      renderRevertDto={renderRevertResult}
    ></MeasureConfirmation>
  );
}
