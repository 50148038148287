import { useCallback, useContext } from "react";
import { useRecoilValue } from "recoil";

import { GeographiesContext } from "../../contexts";
import { DocCardStats } from "../stats/document-core/core";
import { defined } from "../../../core/defined";
import { dateToSimpleString } from "../../../domain/measure";
import { cardQuery } from "../stats/document-core/queries/card";
import { useApplyChangeStats } from "../stats/useApplyChangeStats";

export function useDeactivateForecast(cardId: string) {
  const card = useRecoilValue(cardQuery(cardId));
  const handleApplyChange = useApplyChangeStats(cardId);

  return useCallback(() => {
    if (card.type !== "dataCard") {
      return;
    }

    const updatedCard: DocCardStats = {
      ...card,
      data: {
        ...card.data,
        settings: {
          ...card.data.settings,
          forecast: {
            show: false,
          },
        },
      },
    };

    handleApplyChange(updatedCard);
  }, [card, handleApplyChange]);
}

export function useSetForecast(
  card: DocCardStats,
  lastAvailableTimePoint: string
) {
  const geographies = useContext(GeographiesContext);
  const handleApplyChange = useApplyChangeStats(card.id);

  return useCallback(
    (newUntilDate: Date, showUncertainty: boolean) => {
      if (!defined(geographies)) {
        return;
      }

      const newUntil = dateToSimpleString(newUntilDate);
      const timeSelection = card.data.timeSelection;
      if (!defined(timeSelection)) {
        throw new Error("Time selection is not defined");
      }
      const updatedCard: DocCardStats = {
        ...card,
        data: {
          ...card.data,
          timeSelection: [timeSelection[0], lastAvailableTimePoint],
          settings: {
            ...card.data.settings,
            forecast: {
              show: true,
              until: newUntil,
              showUncertaintyInterval: showUncertainty,
            },
          },
        },
      };

      handleApplyChange(updatedCard);
    },
    [card, geographies, handleApplyChange, lastAvailableTimePoint]
  );
}
