import { useCallback, useContext, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { throttle } from "lodash";

import { config } from "../../../config";
import { defined } from "../../core/defined";
import { saveHtmlAsDataUri, getSvgAsSvgDataUri } from "../exports/svg";
import { useSetThumbnail } from "../state/stats/useThumbnailsState";
import { StatsDataset } from "../stats/datasets/StatsDataset";
import { SurveyDataset } from "../stats/datasets/SurveyDataset";
import { cardEditModeQuery } from "../state/stats/document-core/queries/card";
import { reportEditModeOnQuery } from "../state/stats/document-meta/queries";
import { SurveyStringDataset } from "../stats/datasets/SurveyStringDataset";
import { useCardEditMode } from "../state/stats/useEditMode";
import { SharingInfoContext } from "../contexts";
import { getSharingInfoNonDocument } from "../files/SharingInfo";

export function useUpdateSvgThumbnail(
  ref: React.MutableRefObject<SVGSVGElement | null>,
  cardId: string
) {
  const updateThumbnail = useSetThumbnail(cardId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledUpdateThumbnail = useCallback(
    throttle(updateThumbnail, 2000, { leading: false, trailing: true }),
    [updateThumbnail]
  );

  const sharingContext = useContext(SharingInfoContext);
  const { hasEditDocumentAccess } = useCardEditMode(
    cardId,
    sharingContext?.info ?? getSharingInfoNonDocument()
  );

  return useCallback(() => {
    if (config.appMode === "embedded") {
      return;
    }
    if (!hasEditDocumentAccess) {
      return;
    }
    const element = ref.current;
    if (defined(element)) {
      throttledUpdateThumbnail(() => {
        return getSvgAsSvgDataUri(element);
      });
    }
  }, [hasEditDocumentAccess, ref, throttledUpdateThumbnail]);
}

export function useUpdateInfoTabThumbnail(
  ref: React.MutableRefObject<HTMLElement | null>,
  cardId: string,
  dataset: StatsDataset | SurveyDataset | SurveyStringDataset | undefined
) {
  const updateThumbnail = useSetThumbnail(cardId);

  const documentEditModeOn = useRecoilValue(reportEditModeOnQuery);
  const editCardOn = useRecoilValue(cardEditModeQuery({ cardStateId: cardId }));

  useEffect(() => {
    if (config.appMode === "embedded") {
      return;
    }
    if (!(documentEditModeOn && editCardOn)) {
      return;
    }

    const element = ref.current;
    if (defined(element)) {
      updateThumbnail(() => {
        return saveHtmlAsDataUri(element);
      });
    }
  }, [ref, updateThumbnail, dataset, documentEditModeOn, editCardOn]);
}

export function useUpdateHtmlThumbnail(
  ref: React.MutableRefObject<HTMLElement | null>,
  cardId: string
) {
  const updateThumbnail = useSetThumbnail(cardId);

  const documentEditModeOn = useRecoilValue(reportEditModeOnQuery);
  const editCardOn = useRecoilValue(cardEditModeQuery({ cardStateId: cardId }));

  useEffect(() => {
    if (config.appMode === "embedded") {
      return;
    }
    if (!(documentEditModeOn && editCardOn)) {
      return;
    }

    const element = ref.current;
    if (defined(element)) {
      updateThumbnail(() => {
        return saveHtmlAsDataUri(element);
      });
    }
  });
}
