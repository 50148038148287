import { eq } from "lodash";

import { defined } from "../../../../core/defined";
import { Timestamp } from "../../../../core/Timestamp";
import { DocumentMetadataDto } from "../../../requests/docs/dto";

/**
 * Isolated metadata for a document
 */
export class DocumentMetadata {
  private _lock: { byYou: boolean; until: Timestamp } | undefined;

  private constructor(private _dto: DocumentMetadataDto) {
    this._lock = defined(_dto.locked)
      ? {
          byYou: _dto.locked.by_you,
          until: Timestamp.fromUnixTimeSeconds(_dto.locked.until),
        }
      : undefined;
  }

  get id(): number {
    return this._dto.id;
  }

  get version(): number {
    return this._dto.version;
  }

  get title(): string {
    return this._dto.title;
  }

  toDto(): DocumentMetadataDto {
    return this._dto;
  }

  lockedByOther(): boolean {
    const lock = this._lock;
    return defined(lock) && !lock.byYou && !lock.until.isPast();
  }

  eq(other: DocumentMetadata): boolean {
    return eq(this._dto, other._dto);
  }

  static fromDto(dto: DocumentMetadataDto): DocumentMetadata {
    return new DocumentMetadata(dto);
  }
}
