import {
  DefaultValue,
  GetRecoilValue,
  selectorFamily,
  SetRecoilState,
} from "recoil";
import { defined } from "../../../../../core/defined";

import { docCardAtomFamily } from "../atoms";
import { CardIdParams, DocCardMicro } from "../core";
import {
  isMicroSelectionLine,
  isMicroSelectionPoint,
  isMicroSelectionPolygon,
  MicroGeoSelections,
  microSelectionPrimary,
} from "../core-micro";
import { getCardOrThrow } from "./card";
import { MeasureSelectionGeoMicroFull } from "../../../../../domain/measure/definitions";
import { chain } from "lodash";
import { isMeasureSelectionGeoMicroFull } from "../core-micro";
import { DataSelectionMicro } from "../core-micro";

function getMicroCardOrThrow(get: GetRecoilValue, id: string): DocCardMicro {
  const card = getCardOrThrow(get, id);
  if (card.type !== "microCard") {
    throw new Error("Found card, but was not of type microCard");
  }
  return card;
}

export function setMicroCardorThrow(
  set: SetRecoilState,
  id: string,
  updater: (card: DocCardMicro) => DocCardMicro
) {
  updateCardOrThrow(set, id, (card) => {
    if (card.type !== "microCard") {
      throw new Error("Found card, but was not of type microCard");
    }
    return updater(card);
  });
}

export const singleMicroCardQuery = selectorFamily<DocCardMicro, CardIdParams>({
  key: "singleMicroCardQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getMicroCardOrThrow(get, params.cardStateId);
      const primarySelection = microSelectionPrimary(card);

      const isComputed = defined(primarySelection?.measure.computed);
      const geoMeasuresOnly = card.data.dataSelections?.every(
        (d) => d.type === "geo-micro"
      );
      const actualCard: DocCardMicro = {
        ...card,
        data: {
          ...card.data,
          geoSelections: geoMeasuresOnly ? undefined : card.data.geoSelections,
          settings: {
            ...card.data.settings,
            map: {
              ...card.data.settings.map,
              localZRange: isComputed
                ? true
                : card.data.settings.map.localZRange ?? false,
            },
          },
        },
      };

      return actualCard;
    },
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("Cannot set default value");
      }
      updateCardOrThrow(set, params.cardStateId, () => newValue);
    },
});

export const microCardMultiSelectQuery = selectorFamily<boolean, CardIdParams>({
  key: "microCardMultiSelectQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getMicroCardOrThrow(get, params.cardStateId);
      const primarySelection = microSelectionPrimary(card);
      return (
        (defined(primarySelection?.measure.computed) ||
          primarySelection?.multiSelectEnabled) ??
        false
      );
    },
});

export const microCardSelectionsQuery = selectorFamily<
  DataSelectionMicro[] | undefined,
  CardIdParams
>({
  key: "getMicroCardSelectionsQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getMicroCardOrThrow(get, params.cardStateId);
      return card.data.dataSelections;
    },
});

export const microCardGeoSelectionsQuery = selectorFamily<
  MicroGeoSelections | undefined,
  CardIdParams
>({
  key: "microCardGeoSelectionsQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getMicroCardOrThrow(get, params.cardStateId);
      return card.data.geoSelections;
    },
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("Cannot set default value");
      }
      updateCardOrThrow(set, params.cardStateId, (card) => {
        return {
          ...card,
          data: {
            ...card.data,
            geoSelections: defined(newValue) ? { ...newValue } : undefined,
          },
        };
      });
    },
});

export const microGeoPointSelectionsQuery = selectorFamily<
  MeasureSelectionGeoMicroFull[],
  CardIdParams
>({
  key: "microGeoPointSelectionsQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const selections = get(microCardSelectionsQuery(params));
      return (
        chain(selections)
          .filter(isMeasureSelectionGeoMicroFull)
          .filter(isMicroSelectionPoint)
          .filter(hasAtLeastOneBreakdownSelection)
          .value() ?? []
      );
    },
});

export const microGeoPolygonSelectionsQuery = selectorFamily<
  MeasureSelectionGeoMicroFull[],
  CardIdParams
>({
  key: "microGeoPolygonSelectionsQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const selections = get(microCardSelectionsQuery(params));
      return (
        chain(selections)
          .filter(isMeasureSelectionGeoMicroFull)
          .filter(isMicroSelectionPolygon)
          .filter(hasAtLeastOneBreakdownSelection)
          .value() ?? []
      );
    },
});

export const microGeoLineSelectionsQuery = selectorFamily<
  MeasureSelectionGeoMicroFull[],
  CardIdParams
>({
  key: "microGeoLineSelectionsQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const selections = get(microCardSelectionsQuery(params));
      return (
        chain(selections)
          .filter(isMeasureSelectionGeoMicroFull)
          .filter(isMicroSelectionLine)
          .filter(hasAtLeastOneBreakdownSelection)
          .value() ?? []
      );
    },
});

function hasAtLeastOneBreakdownSelection(
  selection: MeasureSelectionGeoMicroFull
): boolean {
  return (
    Object.keys(selection.selectedDimensions).length === 0 ||
    Object.values(selection.selectedDimensions).every(
      (d) => defined(d) && d.length > 0
    )
  );
}

function updateCardOrThrow(
  set: SetRecoilState,
  id: string,
  updater: (card: DocCardMicro) => DocCardMicro
) {
  set(docCardAtomFamily(id), (prev) => {
    if (!defined(prev)) {
      throw new Error("Could not find card to update");
    }
    if (prev.type !== "microCard") {
      throw new Error("Found card, but was not of type microCard");
    }
    return updater(prev);
  });
}
