import { AdminMetadata } from "../../../../lib/domain-admin/metadata";

export type MeasureWithPath = {
  path: string[];
  measure: string;
  canonicalMeasure: AdminMetadata;
  measureId: number;
};

export function displayMeasure(measureWithPath: {
  path: string[];
  measure: { measure: string };
}) {
  return `${measureWithPath.path.join(" > ")} > ${
    measureWithPath.measure.measure
  }`;
}
