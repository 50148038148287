import ExcelJS from "exceljs";
import { mimeTypeUtf8 } from "../../core/mime";
import { DataDescription } from "../stats/shared/DataDescription";
import { defined } from "../../core/defined";
import { buildSourceTextParts } from "../stats/shared/texts";
import { DataSourceInfo } from "../stats/datasets/DatasetGeneric";
import { borderStyleThin } from "./_shared_excel";

export async function simpleTableExcel(
  filename: string,
  dict: { [key: string]: (string | number)[] },
  dataDescription: DataDescription | undefined,
  sourceInfo: DataSourceInfo | undefined
) {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Infostat");

  // Metadata, if available
  if (defined(dataDescription)) {
    sheet.addRow([dataDescription.header]);
    sheet.getCell(sheet.lastRow!.number, 1).font = { bold: true, size: 12 };
    dataDescription.primaryMeasureSubheaders.forEach((header) => {
      sheet.addRow([header]);
    });

    for (const subquestion of dataDescription.single_subquestions) {
      sheet.addRow([]);
      sheet.addRow([subquestion]);
      sheet.getCell(sheet.lastRow!.number, 1).font = { bold: true };
    }
    sheet.addRow([]);
  }

  // Data
  const keys = Object.keys(dict);
  const values = Object.values(dict);
  sheet.addRow(keys);
  for (let i = 0; i < keys.length; i++) {
    sheet.getCell(sheet.lastRow!.number, 1 + i).font = { bold: true };
    sheet.getCell(sheet.lastRow!.number, 1 + i).border = {
      bottom: borderStyleThin,
    };
  }

  const maxNumValues = Math.max(...values.map((v) => v.length));
  for (let i = 0; i < maxNumValues; i++) {
    const rowValues = keys.map((_, j) => values[j][i]);
    sheet.addRow(rowValues);
  }

  // Source info, if available
  if (defined(sourceInfo)) {
    sheet.addRow([]);
    const { primary, multipleSources } = buildSourceTextParts({
      source: sourceInfo.source,
      externalSource: sourceInfo.externalSource,
    });
    const sourcesLabel = multipleSources ? "Källor" : "Källa";

    sheet.addRow([`${sourcesLabel}: ${primary}`]);
    const sourceRowNumber = sheet.lastRow?.number;
    if (!defined(sourceRowNumber)) {
      throw new Error("No sourceRowNumber");
    }
    sheet.mergeCells(sourceRowNumber, 1, sourceRowNumber, 10);
    sheet.addRow(["Framställt av Infostat"]);
  }

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: mimeTypeUtf8("xlsx"),
  });
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Clean up
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);
}
