import {
  Dictionary,
  Record,
  String as StringRT,
  Number as NumberRT,
  Static,
} from "runtypes";
import { config } from "../../../../../config";
import { HttpResult } from "../../../../infra/HttpResult";
import { authedRequest } from "../../shared";

interface MetadataFields {}
interface SubjectPath {
  area: string;
  subarea: string;
  subject: string;
}
export type RestrictionMode =
  | {
      restricted: true;
      organizations: string[];
    }
  | {
      restricted: false;
    };

export function createStatsMeasureV2(
  fields: MetadataFields,
  subjectPath: SubjectPath,
  restrictionMode: RestrictionMode,
  dimensions: { data_column: string; label: string }[]
): Promise<HttpResult<CreatedResult>> {
  return authedRequest(
    config.apis.importV2,
    `admin/stats/create`,
    {
      measurements: [
        {
          ...fields,
          ...subjectPath,
          restriction_mode: restrictionMode,
          dimensions,
        },
      ],
    },
    "POST"
  );
}

export function createMemberOrgsMeasure(
  fields: MetadataFields,
  subjectPath: SubjectPath,
  ownerOrgId: string,
  dimensions: { data_column: string; label: string }[]
): Promise<HttpResult<CreatedResult>> {
  return authedRequest(
    config.apis.importV2,
    `admin/member_orgs_measures/create`,
    {
      ...fields,
      ...subjectPath,
      owner_org: ownerOrgId,
      dimensions,
    },
    "POST"
  );
}

const CreateResultRT = Record({
  created: Dictionary(NumberRT, StringRT),
});
export type CreatedResult = Static<typeof CreateResultRT>;

export function createMicroMeasure(
  fields: MetadataFields,
  subjectPath: SubjectPath,
  restrictionMode: RestrictionMode,
  dimensions: { data_column: string; label: string }[]
): Promise<HttpResult<CreatedResult>> {
  return authedRequest(
    config.apis.statsV1,
    `admin/mikro`,
    {
      measurements: [
        {
          ...fields,
          ...subjectPath,
          restriction_mode: restrictionMode,
          dimensions,
        },
      ],
    },
    "POST"
  ).then((res) => {
    return res.map((data) => CreateResultRT.check(data));
  });
}
