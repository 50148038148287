import * as _ from "lodash";
import { DEFAULT_GRID_LINE_COLOR } from "../../../lib/application/stats/shared/core/colors/colors";

import { MainChartDimensions } from "../../../lib/application/stats/shared/core/definitions";
import { XTicksContainer } from "../../../lib/application/stats/shared/core/ticks";
import { defined } from "../../../lib/core/defined";

interface Props {
  dims: MainChartDimensions;
  ticksContainer: XTicksContainer;
  excludeX?: number;
  strokeColor?: string | null;
  strokeDashArray?: string;
}
export function LinesVertical(props: Props) {
  const { dims, strokeDashArray, ticksContainer, excludeX, strokeColor } =
    props;
  const ticks = ticksContainer.ticks;
  if (strokeColor === null) {
    return null;
  }
  return (
    <g>
      {_.chain(ticks)
        .filter((t) => (defined(excludeX) ? t.xOffset !== excludeX : true))
        .map((t) => (
          <line
            className="chart-guide-line"
            key={t.xOffset}
            y1="0"
            y2={dims.boundedHeight}
            x1={t.xOffset}
            x2={t.xOffset}
            stroke={strokeColor ?? DEFAULT_GRID_LINE_COLOR}
            strokeWidth="1"
            shapeRendering="crispEdges"
            strokeDasharray={strokeDashArray}
          />
        ))
        .value()}
    </g>
  );
}

export function FocusLineVertical(props: {
  dims: MainChartDimensions;
  xOffset: number;
}) {
  return (
    <g>
      <line
        strokeDasharray={3}
        y1={-10} // Extend outside chart area to make
        y2={props.dims.boundedHeight}
        x1={props.xOffset}
        x2={props.xOffset}
        stroke="#dddddd"
        strokeWidth={props.xOffset === 0 ? "3" : "1"}
        shapeRendering="crispEdges"
      ></line>
    </g>
  );
}
