import { TextField } from "@fluentui/react";
import { useState } from "react";
import { defined } from "../lib/core/defined";

import { Button } from "./Button";
import { FluentModal, FluentModalBody, FluentModalFooter } from "./Modal";

interface Props {
  title: string;
  description?: string;
  onDismiss: () => void;
  onSaveInput: (input: string) => void;
  input: {
    initialValue: string;
    label: string;
    maxLength: number;
    /**
     * validation function must return an error message if input is invalid,
     * else undefined
     */
    validate: (input: string) => undefined | string;
  };
  multiline?: boolean;
  actions?: {
    saveLabel?: string;
    cancelLabel?: string;
  };
  autoSelectText: boolean;
}

/**
 * Modal window that allows the user to edit & submit a string
 * @param props
 * @returns
 */
export function SimpleInputModal(props: Props) {
  const { input, actions, description } = props;
  const [value, setValue] = useState(input.initialValue);

  const saveLabel = actions?.saveLabel ?? "Spara";
  const cancelLabel = actions?.cancelLabel ?? "Avbryt";

  const validationError = input.validate(value);
  const isValid = !defined(validationError);

  return (
    <FluentModal
      title={props.title}
      isOpen={true}
      onClose={() => props.onDismiss()}
    >
      <FluentModalBody>
        <>{defined(description) && <p>{description}</p>}</>
        <TextField
          autoFocus
          multiline={props.multiline ?? false}
          label={input.label}
          maxLength={input.maxLength}
          onChange={(event) => {
            setValue(event.currentTarget.value);
          }}
          styles={props.multiline ? { fieldGroup: { height: 200 } } : undefined}
          onKeyDown={(event) => {
            if (props.multiline) {
              // If key is Enter and Control is pressed, submit the form
              if (event.key === "Enter" && event.ctrlKey && isValid) {
                props.onSaveInput(value);
              }
              return;
            }
            if (event.key === "Enter" && isValid) {
              props.onSaveInput(value);
            }
          }}
          onFocus={(event) => {
            if (props.autoSelectText) {
              event.currentTarget.select();
            }
          }}
          className="report-title-input"
          errorMessage={validationError}
          value={value}
        ></TextField>
      </FluentModalBody>
      <FluentModalFooter>
        <Button
          title={cancelLabel}
          onClick={() => {
            props.onDismiss();
          }}
        ></Button>
        <Button
          title={saveLabel}
          intent="primary"
          disabled={!isValid}
          onClick={() => {
            props.onSaveInput(value);
          }}
        ></Button>
      </FluentModalFooter>
    </FluentModal>
  );
}
