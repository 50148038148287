import { DefaultButton, TooltipHost } from "@fluentui/react";
import { useState } from "react";

export function HelpIcon(props: { tooltipText: string }) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <>
      <TooltipHost content={showTooltip ? props.tooltipText : undefined}>
        <DefaultButton
          className="info-button"
          onClick={() => setShowTooltip(!showTooltip)}
          styles={{ root: { border: "none", minWidth: "16px", padding: 0 } }}
          iconProps={{ iconName: "Info" }}
        />
      </TooltipHost>
    </>
  );
}
