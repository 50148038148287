import { SharingError } from "../../lib/application/state/sharing";
import { HttpErrorPage } from "./HttpErrorView";
import { SimpleErrorPage } from "./SimpleErrorPage";

export function SharingErrorView(props: { error: SharingError }) {
  const error = props.error;
  if (error.code === "decodeError") {
    return (
      <SimpleErrorPage
        title="Fel vid avkodning av data"
        text="Ett fel på vår sida har uppstått! Vi ser över det så snart som möjligt."
        includeBackButton
        includeLogoutButton={false}
      ></SimpleErrorPage>
    );
  }

  return <HttpErrorPage error={error.error}></HttpErrorPage>;
}
