import { useCallback, useContext } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { dataSettingsQuery } from "../../stats/document-core/queries/generalDataCard";
import { cardWithUpdatedDataOutputSettings } from "../../stats/cardToDataStateStats";
import { defined } from "../../../../core/defined";
import { useSaveCard } from "../useSaveDocument";
import { cardQuery } from "../../stats/document-core/queries/card";
import { cardWithUpdatedDataOutputSettingsMicro } from "../../stats/cardToDataStateMicro";
import { GeographiesContext } from "../../../contexts";
import { useExtendedAppearanceSettings } from "../useExtendedAppearanceSettings";
import { DataOutputSettings } from "../../stats/document-core/DataOutputSettings";

export function useChangeDataOutputSettings(cardId: string) {
  const settings = useRecoilValue(dataSettingsQuery({ cardStateId: cardId }));
  const [card, setCard] = useRecoilState(cardQuery(cardId));

  const geographies = useContext(GeographiesContext);
  const saveCard = useSaveCard();
  const appearanceSettings = useExtendedAppearanceSettings();

  const handleChangeSettings = useCallback(
    (newSettings: DataOutputSettings) => {
      if (card.type === "dataCard") {
        if (!defined(geographies)) {
          return;
        }
        cardWithUpdatedDataOutputSettings(
          card,
          geographies,
          appearanceSettings,
          newSettings
        ).then((updatedCard) => {
          if (!defined(updatedCard)) {
            return;
          }
          setCard(updatedCard);
          saveCard?.(updatedCard);
        });
      } else if (card.type === "microCard") {
        cardWithUpdatedDataOutputSettingsMicro(
          card,
          appearanceSettings,
          newSettings
        ).then((updatedCard) => {
          if (!defined(updatedCard)) {
            return;
          }
          setCard(updatedCard);
          saveCard?.(updatedCard);
        });
      }
    },
    [card, appearanceSettings, geographies, saveCard, setCard]
  );

  return [settings, handleChangeSettings] as const;
}
