import {
  Array as ArrayRT,
  Optional,
  Record,
  Static,
  String as StringRT,
  Number as NumberRT,
  Union,
  Literal,
  Null,
} from "runtypes";

export const CreateDataLinksResultDtoRT = Record({
  link: StringRT,
});

export const DataLinkDtoRT = Record({
  id: NumberRT,
  link: StringRT,
  description: Optional(StringRT),
  created_at: StringRT,
  created_by: StringRT,
  last_accessed: Optional(StringRT).Or(Null),
  type: Optional(
    Union(
      Literal("dataframe"),
      Literal("stats"),
      Literal("survey"),
      Literal("mikro")
    )
  ),
});
export type DataLinkDto = Static<typeof DataLinkDtoRT>;

export const DataLinksListDtoRT = Record({
  links: ArrayRT(DataLinkDtoRT).Or(Null),
});
