import { classNames } from "../lib/core/classNames";
import { defined } from "../lib/core/defined";
import "./Table.scss";

type ColumnType = "text" | "numeric" | "icon";

export type TableData<T, Cells> = Array<{
  id: T;
  cells: Cells;
  icon?: JSX.Element;
  rowClassName?: string;
}>;

export type TableColumns<T, Cells> = Array<{
  name: string;
  key?: string;
  type?: ColumnType;
  classNames?: string;
  onColClick?: (id: T, cells: Cells) => void;
}>;

interface Props<T, Cells> {
  data: TableData<T, Cells>;
  columns: TableColumns<T, Cells>;
  hideHeader?: boolean;
  onRowClick?: (id: T, cells: Cells) => void;
  /** Avoid flex-growing the whole table to fill available space */
  noGrow?: boolean;
  slim?: boolean;
  containerClassName?: string;
}
export function Table<
  T extends string | number = string | number,
  Cells extends (string | JSX.Element)[] = string[]
>(props: Props<T, Cells>) {
  const clickable = props.onRowClick !== undefined;

  return (
    <div
      className={classNames(
        props.containerClassName,
        "infostat-table-container"
      )}
    >
      <table
        className={classNames(
          "infostat-table",
          props.slim ? "slim" : "",
          clickable ? "clickable-rows" : undefined,
          props.noGrow ? "no-grow" : undefined
        )}
      >
        <thead>
          {!props.hideHeader && (
            <tr className="header">
              {props.columns.map((c) => (
                <th key={c.key ?? c.name} className={c.type}>
                  {c.name}
                </th>
              ))}
            </tr>
          )}
        </thead>
        <tbody>
          {props.data.map((d) => {
            const rowClick = props.onRowClick;
            return (
              <tr
                onClick={
                  defined(rowClick) ? () => rowClick(d.id, d.cells) : undefined
                }
                key={d.id.toString()}
                id={d.id + ""}
                className={classNames("data-row", d.rowClassName)}
              >
                {d.cells.map((item, cellIndex) => {
                  const column = props.columns[cellIndex];
                  const colClick = column?.onColClick;
                  return (
                    <td
                      key={cellIndex}
                      className={classNames(
                        defined(colClick) ? "clickable" : undefined,
                        column?.classNames
                      )}
                      onClick={
                        defined(colClick)
                          ? () => colClick(d.id, d.cells)
                          : undefined
                      }
                    >
                      {cellIndex === 0 && defined(d.icon) && d.icon}
                      <span>{item}</span>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
