const maxPeriodsForecast: { [key: string]: number } = {
  "days-1": 365 * 2,
  "weeks-1": 52 * 10,
  "months-1": 12 * 20,
  "months-2": 6 * 20,
  "months-3": 4 * 20,
  "months-6": 2 * 20,
  "years-1": 40,
  "years-2": 20,
  "years-4": 20,
  "years-5": 20,
};

export function getForecastMaxPeriods(resolution: string): number {
  return maxPeriodsForecast[resolution] ?? 50;
}
