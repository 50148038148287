// licenses with hardcoded ids
const stats = 2;
const statsRead = 8;
const python = 10;
const surveyRead = 7;
const survey = 1;
const infomikro = 3;
const infomikroZipExport = 4;
const infomikroAddressExport = 5;
const aiTools = 6;
const infomikroRead = 9;

export const testLicenseIds = {
  stats,
  statsRead,
  python,
  surveyRead,
  survey,
  infomikro,
  infomikroZipExport,
  infomikroAddressExport,
  aiTools,
  infomikroRead,
};

// Map from license id to dependencies
const dependencies: {
  [licenseId: number]: null | { condition: "and" | "or"; deps: number[] };
} = {
  [stats]: null,
  [statsRead]: null,
  [survey]: null,
  [surveyRead]: null,
  [infomikro]: { condition: "or", deps: [statsRead, stats] },
  [infomikroAddressExport]: { condition: "or", deps: [infomikro] },
  [infomikroZipExport]: { condition: "or", deps: [infomikro] },
  [python]: { condition: "or", deps: [stats] },
  [infomikroRead]: { condition: "or", deps: [statsRead, stats] },
  [aiTools]: { condition: "or", deps: [stats] },
};

interface LicenseIdDto {
  license_id: number;
}

/** Check whether a given license is active and valid (wrt deps), given a complete list of licenses */
export function licenseAvailable(
  wanted: LicenseIdDto,
  currentLicenses: LicenseIdDto[] | null
): boolean {
  const depsSpec = dependencies[wanted.license_id];

  if (currentLicenses === null || currentLicenses.length === 0) {
    return false;
  }

  if (depsSpec === null) {
    return currentLicenses.some((l) => l.license_id === wanted.license_id);
  }
  if (depsSpec.condition === "or") {
    return depsSpec.deps.some((dep) => {
      return (
        currentLicenses.some((l) => l.license_id === dep) &&
        licenseAvailable({ license_id: dep }, currentLicenses)
      );
    });
  }
  return depsSpec.deps.every(
    (dep) =>
      currentLicenses.some((l) => l.license_id === dep) &&
      licenseAvailable({ license_id: dep }, currentLicenses)
  );
}

/** All deps of a license are fulfilled */
export function licenseDepsFulfilled(
  wanted: LicenseIdDto,
  currentLicenses: LicenseIdDto[] | null
): boolean {
  return licenseAvailable(wanted, currentLicenses?.concat(wanted) ?? null);
}

export function filterApplicableLicensesIds(
  licenses: number[],
  existingLicenses: number[]
): number[] {
  return licenses.filter((l) =>
    licenseDepsFulfilled(
      { license_id: l },
      licenses
        .map((l) => ({ license_id: l }))
        .concat(existingLicenses.map((l) => ({ license_id: l })))
    )
  );
}
