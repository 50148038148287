import React from "react";
import { AlertBox } from "../AlertBox";

interface Props {
  children?: React.ReactNode;
}
interface State {
  hasError: boolean;
}
export class EmbeddedCardErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <AlertBox intent="warning">
          <p>Ett oväntat fel uppstod.</p>
        </AlertBox>
      );
    }

    return this.props.children;
  }
}
