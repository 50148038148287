import { logger } from "../../infra/logging";

export function decodeWithLogging<T>(
  obj: unknown,
  decoder: { check: (obj: unknown) => T }
): T {
  try {
    return decoder.check(obj);
  } catch (e) {
    logger.error("Decoding failure", e);
    logger.error((e as any)?.details);
    throw e;
  }
}
