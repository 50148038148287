import { Progress } from "../../core/progress";

export type NotReady<Err> =
  | {
      type: Progress.InProgress;
    }
  | { type: Progress.NotStarted }
  | { type: Progress.Error; err: Err };

export type NotReadyOrReloading<Err, Data> =
  | {
      type: Progress.InProgress;
      oldData?: Data;
    }
  | { type: Progress.NotStarted }
  | { type: Progress.Error; err: Err };

/**
 * Helper class used for indicating whether promise chains should stop processing
 */
export class PromiseController {
  _stopped: boolean = false;

  stop(): void {
    this._stopped = true;
  }

  get stopped(): boolean {
    return this._stopped;
  }
}
