import { MessageBar, MessageBarType, Separator } from "@fluentui/react";
import { useCallback, useContext } from "react";
import { SetterOrUpdater, useRecoilValue } from "recoil";

import { Button } from "../../../../../components/Button";
import {
  AppMessagesContext,
  ShowDraftDataContext,
} from "../../../../../lib/application/contexts";
import { useStateTransition } from "../../../../../lib/application/hooks/useStateTransition";
import { statsApi } from "../../../../../lib/application/requests/statsApi";
import { useUpdateSelectionOnToggleDraftMode } from "../../../../../lib/application/state/actions/selections/shared/useUpdateSelectionOnToggleDraftMode";
import { useActivateGroupingMode } from "../../../../../lib/application/state/actions/selections/useActivateGroupingMode";

import { useChangeGroupingSelection } from "../../../../../lib/application/state/actions/selections/useChangeGroupingSelection";
import { useCreateSelectionFromSearchHit } from "../../../../../lib/application/state/actions/selections/useCreateSelectionFromSearchHit";
import { useDeactivateGroupingMode } from "../../../../../lib/application/state/actions/selections/useDeactivateGroupingMode";
import {
  DocCardStats,
  makeDataSelection,
} from "../../../../../lib/application/state/stats/document-core/core";
import { groupingTimeMismatch } from "../../../../../lib/application/state/stats/document-core/queries/dataCard";
import { defined } from "../../../../../lib/core/defined";
import { GeographiesSerializable } from "../../../../../lib/domain/geography";
import {
  DataSelection,
  DataSelectionGrouping,
} from "../../../../../lib/domain/selections/definitions";
import { TimeResolution } from "../../../../../lib/domain/time";
import { logger } from "../../../../../lib/infra/logging";
import { SearchGroupingMeasure } from "../../SearchGroupingMeasure";
import {
  AdvancedOptionsSection,
  SingleDataSelection,
} from "./selections/DataCardSelection";
import { RetiredMeasureWarning } from "./selections/RetiredMeasureWarning";
import { MeasureSelectionGrouping } from "../../../../../lib/domain/measure/definitions";

export function GroupingSection(props: {
  setGroupingSelection: SetterOrUpdater<DataSelection | undefined>;
  maxResolutionGrouping: TimeResolution;
  cardStateId: string;
  card: DocCardStats;
  geographies: GeographiesSerializable;
}) {
  const { card, cardStateId, maxResolutionGrouping, geographies } = props;
  const groupingSelection = card.data.groupingSelection;
  const primarySelection = card.data.dataSelections[0];

  const appMessages = useContext(AppMessagesContext);
  const adminShowDraftData = useContext(ShowDraftDataContext);

  const { handleChangeGroupingSelection, handleChangePrimaryPath } =
    useChangeGroupingSelection(card.id, geographies, adminShowDraftData);

  const updateSelectionOnToggleDraftMode =
    useUpdateSelectionOnToggleDraftMode<DataSelectionGrouping>(
      handleChangeGroupingSelection,
      true,
      false
    );

  const handleCreateSelectionFromHit =
    useCreateSelectionFromSearchHit<MeasureSelectionGrouping>(
      adminShowDraftData
    );
  const handleSelectMeasureResult = useCallback(
    async (
      measureId: number,
      subjectPath: string[],
      dimensionDataColumn?: string,
      dimensionValueId?: number
    ) => {
      const result = await handleCreateSelectionFromHit(
        measureId,
        subjectPath,
        dimensionDataColumn,
        dimensionValueId
      );
      if (!defined(groupingSelection)) {
        return handleChangeGroupingSelection({
          ...makeDataSelection(result.subjectPath, result.measureSelection),
          measureSelection: result.measureSelection,
        });
      }
      return handleChangeGroupingSelection({
        ...groupingSelection,
        ...result,
      });
    },
    [
      groupingSelection,
      handleChangeGroupingSelection,
      handleCreateSelectionFromHit,
    ]
  );

  const handleActivateGrouping = useActivateGroupingMode(card.id);
  const handleDeactivateGrouping = useDeactivateGroupingMode(card.id);

  const deactivateGrouping = useCallback(() => {
    if (!defined(primarySelection)) {
      return;
    }

    statsApi
      .getAvailableMeasures(
        primarySelection.subjectPath,
        false,
        false,
        adminShowDraftData,
        TimeResolution.maximal()
      )
      .then((measures) => {
        const measureSelection = primarySelection.measureSelection;
        if (!defined(primarySelection) || !defined(measureSelection)) {
          return;
        }
        handleDeactivateGrouping(primarySelection, measures);
      })
      .catch((err) => {
        appMessages?.add(
          "error",
          "Misslyckades med att avsluta sambandsanalys"
        );
        logger.error(err);
      });
  }, [
    adminShowDraftData,
    appMessages,
    handleDeactivateGrouping,
    primarySelection,
  ]);

  const switchToGroupingMode = useCallback(() => {
    if (defined(groupingSelection)) {
      return;
    }
    if (!defined(primarySelection)) {
      return;
    }

    statsApi
      .getAvailableMeasures(
        primarySelection.subjectPath,
        true,
        false,
        adminShowDraftData,
        TimeResolution.maximal()
      )
      .then((measures) => {
        const measureSelection = primarySelection.measureSelection;
        if (!defined(primarySelection) || !defined(measureSelection)) {
          return;
        }
        handleActivateGrouping(primarySelection, measures);
      })
      .catch((err) => {
        appMessages?.add(
          "error",
          "Misslyckades med att aktivera sambandsanalys"
        );
        logger.error(err);
      });
  }, [
    adminShowDraftData,
    appMessages,
    groupingSelection,
    handleActivateGrouping,
    primarySelection,
  ]);

  // When toggling draft mode, we need to ensure selections are updated so that
  // draft measures are included/excluded
  useStateTransition(adminShowDraftData, (prev, next) => {
    if (defined(prev) && defined(next) && prev !== next) {
      if (!defined(groupingSelection)) {
        return;
      }
      updateSelectionOnToggleDraftMode(groupingSelection, next);
    }
  });

  const isGroupingTimeMismatch = useRecoilValue(
    groupingTimeMismatch({ cardStateId: card.id })
  );

  if (!defined(groupingSelection)) {
    return (
      <div>
        <div className="settings-container content-padding">
          <AdvancedOptionsSection
            cardStateId={cardStateId}
            switchToGroupingMode={switchToGroupingMode}
            primaryDataSelection={card.data.dataSelections[0]}
          ></AdvancedOptionsSection>
        </div>
      </div>
    );
  }

  return (
    <>
      <>
        <div className="grouping-header-line content-padding">
          <h3 className="header">Analysera samband med ...</h3>
          <SearchGroupingMeasure
            className="grouping-search"
            maxTimeResolution={maxResolutionGrouping}
            onSelectPath={handleChangePrimaryPath}
            onSelectMeasure={handleSelectMeasureResult}
          ></SearchGroupingMeasure>
          <Button
            intent="primary"
            title="Ta bort"
            onClick={deactivateGrouping}
          ></Button>
        </div>
        <div className="content-padding">
          {groupingSelection.measureSelection?.measure.retired && (
            <RetiredMeasureWarning></RetiredMeasureWarning>
          )}
          <SingleDataSelection
            groupingSelectionMode={true}
            isGroupingSelection={true}
            dataSelection={groupingSelection}
            maxCategoriesResolution={maxResolutionGrouping}
            setDataSelection={
              handleChangeGroupingSelection as (
                selection: DataSelection
              ) => void
            }
            autofill={false}
          ></SingleDataSelection>
        </div>
      </>
      <Separator></Separator>
      {isGroupingTimeMismatch && (
        <div className="card-error-bar">
          <MessageBar messageBarType={MessageBarType.error}>
            Sambandsmåttet finns endast för senare tidpunkter än huvudmåttet.
          </MessageBar>
        </div>
      )}
    </>
  );
}
