export function radiansToDegrees(radians: number): number {
  // deg = 360 * (rad / 2pi)
  // (2pi * deg) / 360 = rad
  return 180 * (radians / Math.PI);
}

export function degreesToRadians(degrees: number): number {
  // deg = 360 * (rad / 2pi)
  // (2pi * deg) / 360 = rad
  return (Math.PI * degrees) / 180;
}
