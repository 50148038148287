import { DetailsList, IColumn } from "@fluentui/react/lib/DetailsList";
import { useCallback, useMemo } from "react";
import { ChildNodeInfo } from "../../../../lib/application/files/tree";
import { FileFeatures } from "./shared/features";
import { Link } from "@fluentui/react/lib/Link";
import { FluentIcon } from "../../../../components/Icons";
import { defined } from "../../../../lib/core/defined";
import { InfoBox } from "../../../../components/InfoBox";
import { config } from "../../../../config";
import {
  deleteNode,
  DraftsListingDto,
} from "../../../../lib/application/requests/docs/documents";
import { IContextualMenuItem } from "@fluentui/react";
import { IconContextMenu } from "../../../../components/IconContextMenu";
import { truncate } from "../../../../lib/core/truncate";
import { getText } from "../../../../lib/application/strings";
import { useAppMessages } from "../../../../lib/application/hooks/useAppMessages";
import { Milliseconds } from "../../../../lib/core/time";
import { logger } from "../../../../lib/infra/logging";

interface Props {
  drafts?: DraftsListingDto;
  handleOpenFile: (id: number) => void;
  fileFeatures: FileFeatures;
  reloadFiles: () => Promise<void>;
}

type ItemType = NonNullable<DraftsListingDto>[0];

export function DraftsFileListFlat(props: Props) {
  const { drafts, handleOpenFile, reloadFiles } = props;
  const appMessagesHandler = useAppMessages();

  const handleDelete = useCallback(
    (item: ItemType) => {
      const name = truncate(item.title, 30);
      if (window.confirm(`Vill du ta bort filen "${name}"?`)) {
        deleteNode(item.id).then((res) => {
          res.match({
            err: (err) => {
              logger.error("Delete node: " + err.code);
              const message = getText("failed-to-delete-file");
              appMessagesHandler?.add("error", message, {
                durationMs: Milliseconds.second * 5,
              });
            },
            ok: () => {
              reloadFiles();
            },
          });
        });
      }
    },
    [appMessagesHandler, reloadFiles]
  );

  const columns: IColumn[] = [
    {
      key: "type-col",
      name: "Filtyp",
      fieldName: "type",
      isIconOnly: true,
      minWidth: 20,
      maxWidth: 34,
      onRender: () => <FluentIcon name="document" size="sm"></FluentIcon>,
    },
    {
      key: "name-col",
      name: "Namn",
      fieldName: "title",
      minWidth: 210,
      maxWidth: 550,
      isRowHeader: true,
      isResizable: true,
      className: "name-column",
      onRender: (item: ItemType) => (
        <div>
          <Link data-selection-invoke={true}>{item.title}</Link>
        </div>
      ),
    },
    {
      key: "date-col",
      name: "Senaste ändring",
      fieldName: "dateModified",
      minWidth: 70,
      maxWidth: 140,
      isResizable: false,
      data: "number",
      onRender: (item: ItemType) => {
        return (
          <span>
            {new Date(item.last_updated).toLocaleString(config.locale)}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: "menu-col",
      name: "File Type",
      fieldName: "type",
      isIconOnly: true,
      minWidth: 16,
      maxWidth: 16,
      onRender: (item: ItemType) =>
        props.fileFeatures.hideAll ? (
          <></>
        ) : (
          <FileListMenu
            item={item}
            key={item.id}
            onDelete={handleDelete}
          ></FileListMenu>
        ),
    },
  ];

  const onItemInvoked = useCallback(
    (item: ChildNodeInfo) => {
      handleOpenFile(item.id);
    },
    [handleOpenFile]
  );

  if (!defined(drafts) || drafts?.length === 0) {
    return (
      <InfoBox
        className="margin-top-lg"
        sections={[
          {
            header: "Tomt",
            text: "Här finns inga dokument än.",
          },
        ]}
      ></InfoBox>
    );
  }

  return (
    <div className="infostat-filelist-flat">
      <DetailsList
        items={drafts}
        columns={columns}
        onItemInvoked={onItemInvoked}
      />
    </div>
  );
}

export function FileListMenu(props: {
  item: ItemType;
  onDelete: (item: ItemType) => void;
}) {
  const { item, onDelete } = props;

  const menuItems: IContextualMenuItem[] = useMemo(() => {
    const items: IContextualMenuItem[] = [
      {
        key: "remove",
        name: "Ta bort",
        iconProps: { iconName: "trash" },
        onClick: () => onDelete?.(item),
      },
    ];
    return items;
  }, [onDelete, item]);

  if (menuItems.length === 0) {
    return <></>;
  }

  return <IconContextMenu icon="more" items={menuItems} />;
}
