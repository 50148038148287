import {
  addOrganizationInvites,
  addUserLicense,
  addUserRole,
  deleteOrganizationInvite,
  deleteUserFromOrganization,
  getMyOrganization,
  removeUserLicense,
  removeUserRole,
  sendOrganizationInviteEmailToUser,
} from "./requests";

export interface OrgAdminApi {
  addOrganizationInvite: typeof addOrganizationInvites;
  deleteUserFromOrganization: typeof deleteUserFromOrganization;
  deleteOrganizationInvite: typeof deleteOrganizationInvite;
  sendOrganizationInviteEmailToUser: typeof sendOrganizationInviteEmailToUser;
  addUserLicense: typeof addUserLicense;
  addUserRole: typeof addUserRole;
  removeUserRole: typeof removeUserRole;
  removeUserLicense: typeof removeUserLicense;
  getMyOrganization: typeof getMyOrganization;
}

export const orgAdminApi = {
  getMyOrganization: getMyOrganization,
  addOrganizationInvite: addOrganizationInvites,
  deleteUserFromOrganization: deleteUserFromOrganization,
  deleteOrganizationInvite: deleteOrganizationInvite,
  sendOrganizationInviteEmailToUser: sendOrganizationInviteEmailToUser,
  addUserLicense: addUserLicense,
  addUserRole: addUserRole,
  removeUserRole: removeUserRole,
  removeUserLicense: removeUserLicense,
};
