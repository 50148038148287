import * as _ from "lodash";

import { GeoSelections } from "../../application/state/stats/document-core/core";
import {
  MeasureSelection,
  MeasureSelectionGrouping,
} from "../measure/definitions";

export type {
  MeasureSelection,
  MeasureSelectionRegular,
  MeasureSelectionSurvey,
} from "../measure/definitions";

// Data selections

export interface DataSelectionWithoutId {
  subjectPath: string[];
  measureSelection?: MeasureSelection;
}

interface GroupingDataSelectionWithoutId {
  subjectPath: string[];
  measureSelection?: MeasureSelectionGrouping;
}

export interface DataSelection extends DataSelectionWithoutId {
  id: string;
}

export interface DataSelectionGrouping extends GroupingDataSelectionWithoutId {
  id: string;
}

export interface DataSelectionGeneric<T> {
  id: string;
  subjectPath: string[];
  measureSelection?: T;
}

/**
 * Calculate the total number of geographic areas in selection
 */
export function calcNumGeoSelections(selections: GeoSelections): number {
  return _.sum(Object.values(selections).map((list) => list.length));
}
