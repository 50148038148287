import { INavLinkGroup, Nav } from "@fluentui/react";
import { useCallback, useContext, useMemo } from "react";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";

import {
  AppMessagesContext,
  UserInfoContext,
} from "../../../lib/application/contexts";
import { usePolledResource } from "../../../lib/application/hooks/useLoadableResource";
import {
  fetchTodosMeasuresForMemberOrgs,
  fetchTodosMicro,
  fetchTodosStats,
  fetchTodosSurvey,
} from "../../../lib/application/requests/admin/measure_management/micro/todos";
import {
  DraftDataTodoMemberOrgsMeasure,
  DraftDataTodoMicro,
  DraftDataTodoStats,
} from "../../../lib/application_admin/todos/draft_data_todos";
import { Milliseconds } from "../../../lib/core/time";
import {
  handleSingleMeasureForMemberOrgsPath,
  handleSingleMicroMeasurePath,
  handleSingleStatsMeasurePath,
  handleSingleSurveyMeasurePath,
  lastPathSegment,
  pathInfo,
} from "../../../lib/paths";
import { ManageAliases } from "./aliases/ManageAliases";
import { MicroConfirmationWrapper } from "./handle_data/micro/DataAdminMicro";
import { StatsConfirmationWrapper } from "./handle_data/stats/DataAdminStats";
import { SurveyConfirmationWrapper } from "./handle_data/survey/DataAdminSurvey";
import { ManageMigrations } from "./migrations/ManageMigrations";
import { SelectMeasureType } from "./new_measure/CreateMeasure";

import "./DataAdminHome.scss";
import { CreateMicroMeasure } from "./new_measure/micro_measure/CreateMicroMeasure";
import { CreateStatsMeasure } from "./new_measure/stats_measure/CreateStatsMeasure";
import { CreateSurveyMeasure } from "./new_measure/survey/CreateSurveyMeasure";
import { TodoOverview } from "./handle_data/_shared/TodoOverview";
import { config } from "../../../config";
import { adminApi } from "../../../lib/application/requests/admin/admin_api";
import {
  HttpResult,
  displayHttpErrorInternal,
} from "../../../lib/infra/HttpResult";
import {
  MeasureManagementType,
  useSendNotices,
  useUpdateLastUpdated,
  useUpdateNextUpdate,
} from "../../../lib/application_admin/hooks/measure_management";
import { commitPendingChangesStats } from "../../../lib/application/requests/admin/measure_management/import";
import { commitPendingChangesSurvey } from "../../../lib/application/requests/admin/measure_management/survey/import";
import { commitPendingChangesMicro } from "../../../lib/application/requests/admin/measure_management/micro/import";
import { CreateMembergOrgsMeasure } from "./new_measure/member_orgs_measure/CreateMemberOrgsMeasure";
import { MemberOrgsMeasureConfirmationWrapper } from "./handle_data/measure_for_member_orgs/DataAdminMeasureForMemberOrgs";
import { commitPendingChangesMemberOrgsMeasure } from "../../../lib/application/requests/admin/measure_management/member_orgs_measures/import";

const COMMIT_FUNCS = {
  stats: commitPendingChangesStats,
  survey: commitPendingChangesSurvey,
  micro: commitPendingChangesMicro,
  memberOrgsMeasure: commitPendingChangesMemberOrgsMeasure,
};

function getLinks(navigate: ReturnType<typeof useNavigate>) {
  const subPaths: [string, string][] = [
    ["Nytt mått", pathInfo.dataAdminNewMeasure.pathTemplate],
    ["Hantera statsdata", pathInfo.dataAdminHandleDataStats.pathTemplate],
    ["Hantera mikrodata", pathInfo.dataAdminHandleDataMicro.pathTemplate],
    ["Hantera surveydata", pathInfo.dataAdminHandleDataSurvey.pathTemplate],
    [
      "Hantera medlemsmåttdata",
      pathInfo.dataAdminHandleDataMemberOrgsMeasures.pathTemplate,
    ],
    ["Migrering (IT)", pathInfo.dataAdminMigrations.pathTemplate],
    ["Hantera alias", pathInfo.dataAdminAliases.pathTemplate],
  ];

  const navLinks: INavLinkGroup = {
    links: subPaths.map((p) => {
      return {
        name: p[0],
        url: p[1],
        onClick: (e) => {
          e?.preventDefault();
          navigate(p[1]);
        },
        forceAnchor: true,
      };
    }),
  };
  return navLinks;
}

export function DataAdminHome() {
  const navigate = useNavigate();
  const appMessagesHandler = useContext(AppMessagesContext);
  const userInfo = useContext(UserInfoContext);
  if (!userInfo?.internalCanAccessAdminPanel()) {
    throw new Error("Must be authorized user!");
  }

  const [todosMicroResource, reloadTodosMicro] = usePolledResource(
    fetchTodosMicro,
    Milliseconds.second * 60
  );
  const [todosSurveyResource, reloadTodosSurvey] = usePolledResource(
    fetchTodosSurvey,
    Milliseconds.second * 60
  );
  const [todosStatsResource, reloadTodosStats] = usePolledResource(
    fetchTodosStats,
    Milliseconds.second * 60
  );
  const [todosMeasuresForMemberOrgsResource, reloadTodosMeasuresForMemberOrgs] =
    usePolledResource(
      fetchTodosMeasuresForMemberOrgs,
      Milliseconds.second * 60
    );

  const todosMicro = useMemo(() => {
    return todosMicroResource.getLastAvailableData()?.drafts.map((t) => {
      return new DraftDataTodoMicro(t);
    });
  }, [todosMicroResource]);
  const todosSurvey = useMemo(() => {
    return todosSurveyResource.getLastAvailableData()?.drafts.map((t) => {
      return new DraftDataTodoStats(t);
    });
  }, [todosSurveyResource]);
  const todosStats = useMemo(() => {
    const lastAvail = todosStatsResource.getLastAvailableData();
    return lastAvail?.drafts?.map((t) => {
      return new DraftDataTodoStats(t);
    });
  }, [todosStatsResource]);
  const todosMeasuresForMemberOrgs = useMemo(() => {
    return (
      todosMeasuresForMemberOrgsResource
        .getLastAvailableData()
        ?.drafts?.map((t) => {
          return new DraftDataTodoMemberOrgsMeasure(t);
        }) ?? []
    );
  }, [todosMeasuresForMemberOrgsResource]);

  const isLoadingTodosStats = todosStatsResource.isInProgress();
  const isLoadingTodosMicro = todosMicroResource.isInProgress();
  const isLoadingTodosSurvey = todosSurveyResource.isInProgress();
  const isLoadingTodosMeasuresForMemberOrgs =
    todosMeasuresForMemberOrgsResource.isInProgress();

  const handleUpdateLastUpdated = useUpdateLastUpdated(appMessagesHandler);
  const handleUpdateNextUpdate = useUpdateNextUpdate(appMessagesHandler);
  const handleSendNotices = useSendNotices(undefined, appMessagesHandler);

  const handleQuickConfirm = useCallback(
    (type: MeasureManagementType, dataId: number) => {
      return COMMIT_FUNCS[type](dataId).then((res) =>
        res.match({
          ok: () => {
            return Promise.race([
              handleUpdateLastUpdated(type, dataId),
              handleUpdateNextUpdate(type, dataId),
              // We never send notices for member orgs measures
              type !== "memberOrgsMeasure"
                ? handleSendNotices(dataId)
                : Promise.resolve(HttpResult.fromOk(undefined)),
            ]).catch((err) => {
              appMessagesHandler?.add(
                "error",
                "Har uppdaterat mått, men kunde inte uppdatera datum/göra utskick: " +
                  displayHttpErrorInternal(err)
              );
              return HttpResult.fromOk(undefined);
            });
          },
          err: (err) => {
            appMessagesHandler?.add(
              "error",
              "Kunde inte bekräfta: " + displayHttpErrorInternal(err)
            );
            return HttpResult.fromOk(undefined);
          },
        })
      );
    },
    [
      appMessagesHandler,
      handleSendNotices,
      handleUpdateLastUpdated,
      handleUpdateNextUpdate,
    ]
  );

  const handleQuickConfirmStats = useCallback(
    (dataId: number) => handleQuickConfirm("stats", dataId),
    [handleQuickConfirm]
  );

  const handleQuickConfirmSurvey = useCallback(
    (dataId: number) => handleQuickConfirm("survey", dataId),
    [handleQuickConfirm]
  );

  const handleQuickConfirmMicro = useCallback(
    (dataId: number) => handleQuickConfirm("micro", dataId),
    [handleQuickConfirm]
  );

  const handleRemoveMeasure = useCallback(
    (
      dataId: number,
      removeFunc: (id: number) => Promise<HttpResult<unknown>>
    ) => {
      return removeFunc(dataId).then((res) => {
        res.match({
          ok: () => appMessagesHandler?.add("success", "Mått borttaget"),
          err: (err) =>
            appMessagesHandler?.add("error", displayHttpErrorInternal(err)),
        });
        return res;
      });
    },
    [appMessagesHandler]
  );

  const handleRemoveMeasureForMemberOrgs = useCallback(
    (dataId: number) => {
      return handleRemoveMeasure(dataId, adminApi.deleteMemberOrgsMeasure);
    },
    [handleRemoveMeasure]
  );

  const handleRemoveMeasureMicro = useCallback(
    (dataId: number) => {
      return handleRemoveMeasure(dataId, adminApi.deleteMeasureMicro);
    },
    [handleRemoveMeasure]
  );
  const handleRemoveMeasureStats = useCallback(
    (dataId: number) => {
      return handleRemoveMeasure(dataId, adminApi.deleteMeasureStats);
    },
    [handleRemoveMeasure]
  );

  const handleRemoveMeasureSurvey = useCallback(
    (dataId: number) => {
      return handleRemoveMeasure(dataId, adminApi.deleteMeasureSurvey);
    },
    [handleRemoveMeasure]
  );

  const navLinks = getLinks(navigate);

  return (
    <div id="data-admin">
      <Nav className="nav" groups={[navLinks]} />
      <div>
        <div className="data-admin-view">
          <Routes>
            <Route
              path="new/*"
              element={
                <div id="create-measure">
                  <Outlet />
                </div>
              }
            >
              <Route
                path="external"
                element={
                  <CreateSurveyMeasure
                    isCustomerSurvey={true}
                  ></CreateSurveyMeasure>
                }
              ></Route>
              <Route
                path="survey"
                element={
                  <CreateSurveyMeasure
                    isCustomerSurvey={false}
                  ></CreateSurveyMeasure>
                }
              ></Route>
              <Route path="micro" element={<CreateMicroMeasure />}></Route>
              <Route path="stats-v2" element={<CreateStatsMeasure />}></Route>
              <Route
                path={lastPathSegment(
                  pathInfo.dataAdminNewMeasureMemberOrg.pathTemplate
                )}
                element={<CreateMembergOrgsMeasure />}
              ></Route>
              <Route path="*" element={<SelectMeasureType />}></Route>
            </Route>

            <Route path="handle-data">
              <Route
                path="stats"
                element={
                  <TodoOverview
                    isStats
                    isMultiUpload={true}
                    isLoadingTodos={isLoadingTodosStats}
                    handleQuickConfirm={handleQuickConfirmStats}
                    handleRemoveMeasure={handleRemoveMeasureStats}
                    measureAdminPath={(id: number) =>
                      handleSingleStatsMeasurePath(id)
                    }
                    header="Ladda upp statsdata"
                    uploadApi={config.apis.importV2}
                    csvUploadPath={"admin/stats/uploadcsv"}
                    reloadTodos={reloadTodosStats}
                    todos={todosStats}
                  />
                }
              ></Route>
              <Route
                path="stats/confirm/:id"
                element={
                  <StatsConfirmationWrapper
                    idParam="id"
                    todos={todosStats}
                    reloadTodos={reloadTodosStats}
                  ></StatsConfirmationWrapper>
                }
              ></Route>

              <Route
                path="member-orgs-measures"
                element={
                  <TodoOverview
                    isMemberOrgs={true}
                    isMultiUpload={false}
                    isLoadingTodos={isLoadingTodosMeasuresForMemberOrgs}
                    handleRemoveMeasure={handleRemoveMeasureForMemberOrgs}
                    measureAdminPath={(id: number) =>
                      handleSingleMeasureForMemberOrgsPath(id)
                    }
                    header="Ladda upp data för mått för medlemsorganisationer"
                    uploadApi={config.apis.importV2}
                    csvUploadPath={"admin/member_orgs_measures/uploadcsv"}
                    reloadTodos={reloadTodosMeasuresForMemberOrgs}
                    todos={todosMeasuresForMemberOrgs}
                  />
                }
              />
              <Route
                path="member-orgs-measures/confirm/:id"
                element={
                  <MemberOrgsMeasureConfirmationWrapper
                    idParam="id"
                    todos={todosMeasuresForMemberOrgs}
                    reloadTodos={reloadTodosMeasuresForMemberOrgs}
                  />
                }
              ></Route>

              <Route
                path="survey"
                element={
                  <TodoOverview
                    isMultiUpload={true}
                    isLoadingTodos={isLoadingTodosSurvey}
                    handleQuickConfirm={handleQuickConfirmSurvey}
                    handleRemoveMeasure={handleRemoveMeasureSurvey}
                    measureAdminPath={(id: number) =>
                      handleSingleSurveyMeasurePath(id)
                    }
                    header="Ladda upp surveydata"
                    uploadApi={config.apis.importV2}
                    csvUploadPath={"admin/survey/uploadcsv"}
                    reloadTodos={reloadTodosSurvey}
                    todos={todosSurvey}
                  />
                }
              ></Route>
              <Route
                path="survey/confirm/:id"
                element={
                  <SurveyConfirmationWrapper
                    idParam="id"
                    todos={todosSurvey}
                    reloadTodos={reloadTodosSurvey}
                  />
                }
              ></Route>

              <Route
                path="micro"
                element={
                  <TodoOverview
                    isMultiUpload={false}
                    isLoadingTodos={isLoadingTodosMicro}
                    handleQuickConfirm={handleQuickConfirmMicro}
                    handleRemoveMeasure={handleRemoveMeasureMicro}
                    measureAdminPath={(id: number) =>
                      handleSingleMicroMeasurePath(id)
                    }
                    header="Ladda upp mikrodata"
                    uploadApi={config.apis.importV1}
                    csvUploadPath={"admin/mikro/uploadcsv"}
                    reloadTodos={reloadTodosMicro}
                    todos={todosMicro}
                  />
                }
              ></Route>
              <Route
                path="micro/confirm/:id"
                element={
                  <MicroConfirmationWrapper
                    idParam="id"
                    todos={todosMicro}
                    reloadTodosMicro={reloadTodosMicro}
                  ></MicroConfirmationWrapper>
                }
              ></Route>
            </Route>

            <Route
              path="aliases"
              element={<ManageAliases></ManageAliases>}
            ></Route>
            <Route
              path="migrations"
              element={<ManageMigrations></ManageMigrations>}
            ></Route>

            <Route path="*" element={<Navigate to="new" />}></Route>
          </Routes>
        </div>
      </div>
    </div>
  );
}
