import { Dropdown } from "@fluentui/react";
import { useCallback, useContext, useEffect, useState } from "react";

import { Button } from "../../../../components/Button";
import { ShowDraftDataContext } from "../../../../lib/application/contexts";
import { getMeasuresWithCache } from "../../../../lib/application/requests/common_requests";
import { defined } from "../../../../lib/core/defined";
import { DataSelection } from "../../../../lib/domain/selections/definitions";
import { SingleDataSelection } from "../../../stats/docs/cards/data_card/selections/DataCardSelection";
import { SearchMain } from "../../../stats/docs/SearchMain";
import { MeasureThin } from "../../../../lib/domain/measure/definitions";
import { MeasureWithPath } from "./MeasureWithPath";
import { getMeasureMetadata } from "../../../../lib/application/requests/admin/common_requests_admin";
import { AdminMetadata } from "../../../../lib/domain-admin/metadata";

export function SimpleMeasureSelect(props: {
  actionText: string;
  onSelect: (measure: MeasureWithPath) => Promise<void>;
}) {
  const [dataSelection, setDataSelection] = useState<DataSelection>({
    id: "1",
    subjectPath: [],
  });

  const showDraftData = useContext(ShowDraftDataContext);
  const [measures, setMeasures] = useState<MeasureThin[]>();
  const [selectedCanonicalMeasure, setSelectedCanonicalMeasure] =
    useState<AdminMetadata>();
  const [isCopying, setIsCopying] = useState(false);

  const handleSetDataSelection = useCallback(
    (
      dataSelection: DataSelection,
      selectedCanonicalMeasure?: AdminMetadata
    ) => {
      setSelectedCanonicalMeasure(selectedCanonicalMeasure);
      setDataSelection(dataSelection);
    },
    []
  );

  const handleCopy = useCallback(() => {
    if (!defined(selectedCanonicalMeasure)) {
      return;
    }
    const dataId = selectedCanonicalMeasure.data_id;
    const currentMeasure = measures?.find((m) => m.data_id === dataId);
    if (!defined(currentMeasure)) {
      return;
    }

    setIsCopying(true);

    props
      .onSelect({
        measureId: dataId,
        path: dataSelection.subjectPath,
        measure: currentMeasure.measure,
        canonicalMeasure: selectedCanonicalMeasure,
      })
      .finally(() => {
        setIsCopying(false);
      });
  }, [dataSelection.subjectPath, measures, props, selectedCanonicalMeasure]);

  useEffect(() => {
    if (dataSelection.subjectPath.length === 3) {
      getMeasuresWithCache(
        dataSelection.subjectPath,
        false,
        false,
        showDraftData
      ).then((measures) => {
        setMeasures(measures);
        if (!defined(selectedCanonicalMeasure)) {
          const measureId = measures[0].data_id;
          return getMeasureMetadata(measureId).then(
            setSelectedCanonicalMeasure
          );
        }
      });
    }
  }, [dataSelection.subjectPath, selectedCanonicalMeasure, showDraftData]);

  return (
    <div>
      <section>
        <SearchMain
          autofocus={true}
          onSelectPath={(path) =>
            handleSetDataSelection({ ...dataSelection, subjectPath: path })
          }
          onSelectMeasure={async (measureId, subjectPath) => {
            const measure = await getMeasureMetadata(measureId);
            handleSetDataSelection(
              {
                ...dataSelection,
                subjectPath,
                measureSelection: undefined,
              },
              measure
            );
          }}
        ></SearchMain>
        <SingleDataSelection
          disabled={isCopying}
          dataSelection={dataSelection}
          setDataSelection={handleSetDataSelection}
          autofill={true}
          groupingSelectionMode={false}
          isGroupingSelection={false}
        ></SingleDataSelection>
        {defined(measures) && (
          <Dropdown
            disabled={isCopying}
            selectedKey={selectedCanonicalMeasure?.data_id}
            onChange={(event, item) => {
              const nextSelected = measures.find(
                (m) => m.data_id === item?.key
              );
              if (!defined(nextSelected)) {
                return;
              }
              getMeasureMetadata(nextSelected.data_id).then(
                setSelectedCanonicalMeasure
              );
            }}
            label="Mått"
            dropdownWidth="auto"
            options={measures.map((m) => ({ key: m.data_id, text: m.measure }))}
          ></Dropdown>
        )}
      </section>
      <div>
        <Button
          intent="primary"
          disabled={!defined(selectedCanonicalMeasure) || isCopying}
          title={props.actionText}
          onClick={handleCopy}
        ></Button>
      </div>
    </div>
  );
}
