import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

import { MapMultiChart } from "../../../../../../components/charts/shared/MapMultiChart";
import { InfoBox } from "../../../../../../components/InfoBox";
import { DefaultLoading } from "../../../../../../components/Loading";
import { useElementSize } from "../../../../../../lib/application/hooks/useElementSize";
import {
  getGeoSeLau2,
  getGeoSeNuts3,
  getGeoSeNuts2,
  getGeoSeNuts1,
  getGeoSeCountry,
} from "../../../../../../lib/application/requests/common_requests";
import { GeoSelections } from "../../../../../../lib/application/state/stats/document-core/core";
import { StatsDataset } from "../../../../../../lib/application/stats/datasets/StatsDataset";
import { buildBackgroundMap } from "../../../../../../lib/application/stats/datasets/MapDataset";
import { SurveyDataset } from "../../../../../../lib/application/stats/datasets/SurveyDataset";
import { DEFAULT_TEXT_SIZE_CHART_LABELS } from "../../../../../../lib/application/stats/shared/core/TextStyle";
import { defined } from "../../../../../../lib/core/defined";
import { GeoType } from "../../../../../../lib/domain/geography";

import { PivotPlaceholder } from "../PivotPlaceholder";
import { TextRowSet } from "../../../../../../lib/application/stats/shared/core/text_containers";
import { singleDataCardQuery } from "../../../../../../lib/application/state/stats/document-core/queries/dataCard";
import { DataOutputSettings } from "../../../../../../lib/application/state/stats/document-core/DataOutputSettings";

const NUM_MAP_CHARTS_LIMIT = 20;

interface Props {
  isReloadingData: boolean;
  cardId: string;
  dataset?: StatsDataset | SurveyDataset;
  geoSelections: GeoSelections;
  settings: DataOutputSettings;
}

export function MapTab(props: Props) {
  const { dataset, settings } = props;
  const { ref, width } = useElementSize();

  if (!defined(dataset) || dataset instanceof SurveyDataset) {
    return <PivotPlaceholder icon={"grouped-bar-chart"}></PivotPlaceholder>;
  }

  if (props.isReloadingData) {
    return <DefaultLoading />;
  }

  return (
    <>
      <div className="map-tab tab-content" ref={ref}>
        {defined(width) && (
          <MapTabInner
            cardId={props.cardId}
            settings={settings}
            dataset={dataset}
            width={width}
            geoSelections={props.geoSelections}
          ></MapTabInner>
        )}
      </div>
    </>
  );
}

type GeographicSpec = {
  [geoType in GeoType]: GeoJSON.GeoJSON;
};

function MapTabInner(props: {
  cardId: string;
  dataset: StatsDataset;
  width: number;
  geoSelections: GeoSelections;
  settings: DataOutputSettings;
}) {
  const { dataset, width, geoSelections, settings } = props;
  const [geoJson, setGeoJson] = useState<GeographicSpec>();

  const chartData = useMemo(() => dataset.chartData(), [dataset]);

  const sourceText = useMemo(() => {
    return chartData.source(
      width,
      settings.mapChart.labelSize ?? DEFAULT_TEXT_SIZE_CHART_LABELS
    );
  }, [chartData, settings.mapChart.labelSize, width]);

  useEffect(() => {
    Promise.all([
      getGeoSeLau2(),
      getGeoSeNuts3(),
      getGeoSeNuts2(),
      getGeoSeNuts1(),
      getGeoSeCountry(),
    ]).then(([municipal, nuts3, nuts2, nuts1, country]) =>
      setGeoJson({ municipal, nuts3, nuts2, nuts1, country })
    );
  }, []);

  if (
    Object.entries(geoSelections).filter((selection) => selection[1].length > 0)
      .length > 1
  ) {
    return <p>Kartor stöds bara när en typ av geografiskt område är valt.</p>;
  }

  if (!chartData.hasDrawableRegionDimension()) {
    return (
      <p>Stöd för kartor saknas för detta mått med dessa inställningar.</p>
    );
  }

  if (!defined(geoJson)) {
    return <DefaultLoading></DefaultLoading>;
  }

  return (
    <MapChartWithContent
      cardId={props.cardId}
      sourceText={sourceText}
      geoJson={geoJson}
      width={width}
      settings={settings}
      dataset={dataset}
    ></MapChartWithContent>
  );
}

function MapChartWithContent(props: {
  cardId: string;
  dataset: StatsDataset;
  sourceText: TextRowSet;
  width: number;
  geoJson: GeographicSpec;
  settings: DataOutputSettings;
}) {
  const { cardId, dataset, sourceText, geoJson, width, settings } = props;
  const card = useRecoilValue(singleDataCardQuery({ cardStateId: cardId }));

  const mapData = useMemo(() => {
    return card.data.loadedData?.statsMapsState?.loadedMapsData;
  }, [card.data.loadedData?.statsMapsState?.loadedMapsData]);

  const geoType = mapData?.geoType;
  const background = useMemo(() => {
    if (!defined(geoJson) || !defined(geoType)) {
      return undefined;
    }
    return buildBackgroundMap(geoType, geoJson.country);
  }, [geoJson, geoType]);

  const dataDescription = useMemo(() => {
    return dataset.dataDescription();
  }, [dataset]);

  if (mapData?.numMaps === 0) {
    return <p>Välj minst ett alternativ för att visa kartdiagram.</p>;
  }

  if (!defined(background)) {
    return <DefaultLoading></DefaultLoading>;
  }
  if (!defined(mapData) || !defined(mapData.data)) {
    return (
      <p>
        Kartor stöds inte för nuvarande val. Olika regionsnivåer kan inte
        jämföras på samma gång.
      </p>
    );
  }

  return (
    <>
      {mapData.numMapsLimitReached && (
        <InfoBox
          sections={[
            {
              text: `Vissa kartor visas inte eftersom det totala antalet kartor är begränsat till ${NUM_MAP_CHARTS_LIMIT}. Välj färre tidsperioder eller andra alternativ för att välja i detalj vilka kartor du vill visa.`,
            },
          ]}
        ></InfoBox>
      )}
      <MapMultiChart
        cardId={cardId}
        settings={settings}
        dataDescription={dataDescription}
        valueFormatter={mapData.valueFormatter}
        background={background}
        maxSvgWidth={width}
        drawableGeoData={mapData.data}
        source={sourceText}
      ></MapMultiChart>
    </>
  );
}
