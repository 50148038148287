import {
  Array as ArrayRT,
  Record,
  String as StringRT,
  Number as NumberRT,
} from "runtypes";
import { config } from "../../../config";
import { decodedAuthedRequest } from "./shared";

const PropertSearchResultPartRT = Record({
  type: StringRT,
  id: StringRT,
  relevance: NumberRT,
  label: StringRT,
  municipality: StringRT,
  latitude: NumberRT,
  longitude: NumberRT,
});
const PropertySearchResultsRT = ArrayRT(PropertSearchResultPartRT);

export function propertySearch(searchTerm: string) {
  return searchInner(searchTerm, ["property"]);
}

export function desoRegsoSearch(searchTerm: string) {
  return searchInner(searchTerm, ["deso", "regso"]);
}

function searchInner(searchTerm: string, types: string[]) {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `mikro/mapsearch?term=${searchTerm}&types=${types.join(",")}`,
    undefined,
    "GET",
    PropertySearchResultsRT
  ).then((res) => {
    return res.map((data) => {
      return data.map((item) => {
        return {
          type: "Feature",
          id: `poi.${item.id}`,
          place_type: ["place"],
          relevance: item.relevance,
          place_name: item.label,
          text: item.label,
          center: [item.longitude, item.latitude],
          geometry: {
            type: "Point",
            coordinates: [item.longitude, item.latitude],
          },
          properties: {},
        };
      });
    });
  });
}
