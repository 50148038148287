import {
  DefaultValue,
  GetRecoilValue,
  selectorFamily,
  SetRecoilState,
} from "recoil";
import * as _ from "lodash";

import { defined } from "../../../../../core/defined";
import {
  calcNumGeoSelections,
  DataSelection,
  DataSelectionGrouping,
} from "../../../../../domain/selections/definitions";
import { defaultGeographySelection } from "../../../../../domain/measure";
import {
  CardIdParams,
  GeoSelections,
  DataOutputView,
  DocCardStats,
  InnerDataCardState,
} from "../core";
import {
  emptyGeoSelections,
  GeographiesSerializable,
  GeoType,
  narrowSelectionsToDeepestType,
  REGION_ITEM_SWEDEN,
} from "../../../../../domain/geography";
import { updateDataCardInnerImmut } from "../updates";
import { getCardOrThrow, updateCardOrThrow } from "./card";
import { last } from "../../../../../core/last";
import { utcTimeStringToDate } from "../../../../../core/time";
import { getStandardMeasureSelections } from "./shared";

export function setDataCardOrThrow(
  set: SetRecoilState,
  id: string,
  updater: (card: DocCardStats) => DocCardStats
) {
  updateCardOrThrow(set, id, (card) => {
    if (card.type !== "dataCard") {
      throw new Error("Expected data card");
    }
    return updater(card);
  });
}

export function setDataCardInnerField<T extends keyof DocCardStats["data"]>(
  set: SetRecoilState,
  id: string,
  field: T,
  updater: (card: DocCardStats["data"][T]) => DocCardStats["data"][T]
) {
  setDataCardOrThrow(set, id, (card) => {
    return {
      ...card,
      data: updateDataCardInnerImmut(card.data, field, updater),
    };
  });
}

export const singleDataCardQuery = selectorFamily<DocCardStats, CardIdParams>({
  key: "singleDataCardQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      return getDataCardOrThrow(get, params.cardStateId);
    },
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("Cannot set default value");
      }
      setDataCardOrThrow(set, params.cardStateId, () => newValue);
    },
});

export const groupingSelectionQuery = selectorFamily<
  DataSelection | undefined,
  CardIdParams
>({
  key: "groupingSelectionQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      return getDataCardOrThrow(get, params.cardStateId).data.groupingSelection;
    },
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      setDataCardInnerField(
        set,
        params.cardStateId,
        "groupingSelection",
        () => newValue as DataSelectionGrouping
      );
    },
});

export const groupingTimeMismatch = selectorFamily<boolean, CardIdParams>({
  key: "groupingTimeMismatch",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const data = getDataCardOrThrow(get, params.cardStateId).data;
      const primaryDatesRaw =
        data.dataSelections[0].measureSelection?.availableDates;
      const groupingDatesRaw =
        data.groupingSelection?.measureSelection?.availableDates;
      const primaryLastRaw = last(primaryDatesRaw ?? []);
      const groupingFirstRaw = groupingDatesRaw?.[0];

      if (defined(primaryLastRaw) && defined(groupingFirstRaw)) {
        const primaryLast = utcTimeStringToDate(primaryLastRaw);
        const groupingFirst = utcTimeStringToDate(groupingFirstRaw);
        // If the grouping time range in its entirety is more recent than the primary time range,
        // we have a mismatch and cannot produce a grouping dataset.
        return groupingFirst.getTime() > primaryLast.getTime();
      }

      return false;
    },
});

export const primarySelectionQuery = selectorFamily<
  DataSelection | undefined,
  CardIdParams
>({
  key: "primarySelectionQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      return getDataCardOrThrow(get, params.cardStateId).data.dataSelections[0];
    },
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("Default value not supported");
      }
      if (newValue === undefined) {
        throw new Error("Primary selection cannot be undefined");
      }
      setDataCardInnerField(set, params.cardStateId, "dataSelections", () => {
        return [newValue];
      });
    },
});

export const isGroupingQuery = selectorFamily<boolean, CardIdParams>({
  key: "isGroupingQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      return defined(
        getDataCardOrThrow(get, params.cardStateId).data.groupingSelection
      );
    },
});

export const selectedCardViewQuery = selectorFamily<
  DataOutputView,
  CardIdParams
>({
  key: "selectedCardView",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const cardState = getDataCardOrThrow(get, params.cardStateId);

      const primarySelection = get(primarySelectionQuery(params));
      const selectedView = cardState.data.selectedView;
      const chartNotSupported =
        defined(primarySelection) &&
        primarySelection.measureSelection?.measure.value_type === "category";
      const selectedUnchecked = selectedView ?? "diagram";
      if (selectedUnchecked === "diagram" && chartNotSupported) {
        return "table";
      } else {
        return selectedUnchecked;
      }
    },
  set:
    (params) =>
    ({ set }, newValue) => {
      setDataCardInnerField(set, params.cardStateId, "selectedView", () => {
        return newValue instanceof DefaultValue
          ? undefined
          : (newValue as DataOutputView);
      });
    },
});

export const supportedGeographiesQuery = selectorFamily<
  GeoType[],
  CardIdParams
>({
  key: "supportedGeographiesQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) =>
      getSupportedGeographies(getDataCardOrThrow(get, params.cardStateId)),
});

/**
 * If in map mode, narrow to a single geo type
 */
export function currentlyValidGeoSelection(
  card: DocCardStats,
  geographies: GeographiesSerializable
) {
  const uncheckedSelections = getGeoSelections(card, geographies);
  return cardToValidGeoSelections(card, uncheckedSelections);
}

export const geoExpansionsQuery = selectorFamily<string[], CardIdParams>({
  key: "geoExpansionsQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      return getDataCardOrThrow(get, params.cardStateId).data.geoExpansions;
    },
  set:
    (params) =>
    ({ set }, newValue) => {
      setDataCardInnerField(
        set,
        params.cardStateId,
        "geoExpansions",
        () => newValue as string[]
      );
    },
});

export const primaryResolutionQuery = selectorFamily<
  string | undefined,
  CardIdParams
>({
  key: "resolutionQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const cardState = getDataCardOrThrow(get, params.cardStateId);
      return cardState.data.dataSelections[0]?.measureSelection?.measure
        .resolution;
    },
});

/**
 * Determine whether a card should inherit geo selections.
 *
 * If the current card has only one data selection,
 * then we are now changing the measure.
 * After the measure changes, geo selections are inherited
 * until a new geo selection is made.
 */
export function shouldInheritGeoSelectionsOnMeasureChange(
  prevState: InnerDataCardState
): boolean {
  return prevState.dataSelections.length === 1;
}

function getDataCardOrThrow(get: GetRecoilValue, id: string): DocCardStats {
  const card = getCardOrThrow(get, id);
  if (card.type !== "dataCard") {
    throw new Error("Found card, but was not of type dataCard");
  }
  return card;
}

function getSupportedGeographies(cardState: DocCardStats): GeoType[] {
  const measureSelections = getStandardMeasureSelections(cardState);
  if (defined(cardState.data.groupingSelection)) {
    return ["municipal"];
  }

  return _.intersection(...measureSelections.map((m) => m.measure.geo_types));
}

export function getGeoSelections(
  cardState: DocCardStats,
  geographies: GeographiesSerializable
): GeoSelections {
  const measures = getStandardMeasureSelections(cardState).map(
    (m) => m.measure
  );
  const savedSelections = cardState.data.geoSelections;
  const supportedGeoTypes = getSupportedGeographies(cardState);
  if (supportedGeoTypes.length === 1 && supportedGeoTypes[0] === "country") {
    return {
      country: [REGION_ITEM_SWEDEN],
      municipal: [],
      nuts1: [],
      nuts2: [],
      nuts3: [],
    };
  }
  if (defined(savedSelections)) {
    const validSelections = _.pick(savedSelections, supportedGeoTypes);
    const numValidSelections = calcNumGeoSelections(validSelections);
    if (
      numValidSelections > 0 ||
      cardState.data.geoSelectionsInherited === false
    ) {
      return validSelections;
    }
  }

  return defaultGeographySelection(measures, geographies);
}

export function cardToValidGeoSelections(
  card: DocCardStats,
  uncheckedSelections: GeoSelections
): GeoSelections {
  if (card.data.selectedView === "map") {
    const result = narrowSelectionsToDeepestType(uncheckedSelections);
    if (!defined(result)) {
      return emptyGeoSelections();
    }
    return { ...emptyGeoSelections(), [result.type]: result.items };
  }
  return uncheckedSelections;
}
