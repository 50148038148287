import mapbox from "mapbox-gl";
import { useCallback, useEffect } from "react";
import {
  MicroMapSettings,
  MicroMapView,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { MicroDataset } from "../../../../../lib/application/stats/datasets/MicroDataset";

import { defined } from "../../../../../lib/core/defined";
import { InfostatBoundingBox } from "../../../../../lib/domain/cartography/types";
import { SelectionPanelControl } from "./components/SelectionPanel";
import { ColorBag } from "./shared";
import { GeoState } from "./useGeoState";
import { useGeoPanelActions } from "./useGeoPanelActions";
import { useAddRemoveControl } from "./useAddRemoveControl";
import { MicroGeoTree } from "../../../../../lib/application/stats/shared/MicroGeoTree";

export function useSelectionControl(
  view: MicroMapView,
  map: mapbox.Map | undefined,
  settings: MicroMapSettings,
  isLoading: boolean,
  isEditingCard: boolean,
  microDataset: MicroDataset | undefined,
  colorBag: ColorBag,
  setLocationBounds: (box: InfostatBoundingBox) => void,
  expandSelectionPanel: boolean,
  toggleExpandSelectionPanel: () => void,
  expandQuickSelectPanel: boolean,
  toggleExpandQuickSelectPanel: () => void,
  geoState: GeoState,
  geoTree: MicroGeoTree | undefined,
  geoLayerIds: string[]
) {
  const {
    handleSelectUserDefinedRegion,
    handleDeselectSingleDeso,
    handleDeselectGroup,
    handleClearGeos,
    handleCreateRegion,
    handleDeleteLocalRegion,
    handleDeleteSavedRegion,
    handleSaveRegion,
    handleEditUserDefinedRegionName,
    handleSelectAllInMunicipality,
    handleSelectAllInRegion,
  } = useGeoPanelActions(
    view,
    map,
    settings,
    geoState,
    microDataset,
    colorBag,
    setLocationBounds,
    geoTree,
    geoLayerIds
  );

  const makeControl = useCallback(() => {
    return new SelectionPanelControl(
      handleClearGeos,
      handleDeselectSingleDeso,
      handleDeselectGroup
    );
  }, [handleClearGeos, handleDeselectGroup, handleDeselectSingleDeso]);

  const {
    add,
    remove,
    control: selectionControl,
  } = useAddRemoveControl(map, makeControl, "top-left");

  useEffect(() => {
    if (!defined(selectionControl)) {
      return;
    }
    selectionControl.render(
      view,
      isLoading,
      isEditingCard,
      settings,
      expandSelectionPanel,
      toggleExpandSelectionPanel,
      expandQuickSelectPanel,
      toggleExpandQuickSelectPanel,
      geoState,
      geoTree,
      handleSaveRegion,
      handleEditUserDefinedRegionName,
      handleDeleteLocalRegion,
      handleDeleteSavedRegion,
      handleCreateRegion,
      handleSelectUserDefinedRegion,
      handleSelectAllInMunicipality,
      handleSelectAllInRegion
    );
  }, [
    selectionControl,
    handleSelectUserDefinedRegion,
    expandSelectionPanel,
    toggleExpandSelectionPanel,
    isLoading,
    settings,
    geoState,
    handleCreateRegion,
    isEditingCard,
    handleSaveRegion,
    handleEditUserDefinedRegionName,
    handleDeleteLocalRegion,
    handleDeleteSavedRegion,
    expandQuickSelectPanel,
    toggleExpandQuickSelectPanel,
    geoTree,
    handleSelectAllInMunicipality,
    handleSelectAllInRegion,
    view,
  ]);

  return [selectionControl, add, remove] as const;
}
