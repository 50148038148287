import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  IChoiceGroupOption,
  TextField,
} from "@fluentui/react";
import { useState, useCallback, useMemo, useContext, useEffect } from "react";

import { AlertBox } from "../../../../components/AlertBox";
import { Button, ButtonWithSideSpinner } from "../../../../components/Button";
import { copyToClipboard } from "../../../../lib/application/browser/copyToClipboard";
import {
  GeographiesContext,
  OrganizationBrandingContext,
  SaveDocumentContext,
  SharingInfoReloader,
  UserInfoContext,
} from "../../../../lib/application/contexts";
import { shareCodeToLink } from "../../../../lib/application/files/sharing";
import { ISharingInfo } from "../../../../lib/application/files/SharingInfo";
import { NodeTypeRegular } from "../../../../lib/application/files/tree";
import { useHttpRequestWithStatus } from "../../../../lib/application/hooks/useRequestWithStatus";
import {
  createLink,
  createSecureDeliveryDoc,
  shareNode,
} from "../../../../lib/application/requests/docs/documents";
import { RoleDto } from "../../../../lib/application/requests/docs/dto";
import { defined } from "../../../../lib/core/defined";
import { voidFunc } from "../../../../lib/core/voidFunc";
import { createPackagedDocument } from "../../../../lib/application/requests/packaged_docs";
import { config, packagedDocLink } from "../../../../config";
import { logger } from "../../../../lib/infra/logging";
import { useGetAllCardsCallback } from "../../../../lib/application/state/actions/cardCallbacks";
import { docCardsListQuery } from "../../../../lib/application/state/stats/document-core/docCardsListState";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  isTemplateCard,
  stateToThirdPartyDocTemplate,
  stateToWorkspaceLatest,
} from "../../../../lib/application/state/stats/workspace/shared";
import { makePackagedDocument } from "../../../../lib/application/state/stats/packaged-doc/pack";
import { reportMetaStateQuery } from "../../../../lib/application/state/stats/document-meta/queries";
import { ProgressBar } from "../../../../components/ProgressBar";
import { PROGRESS_BAR_GREEN } from "../../../../components/ProgressBar";
import { PROGRESS_BAR_BACKGROUND_GREEN } from "../../../../components/ProgressBar";
import { displayHttpError } from "../../../../components/errors/HttpErrorNotice";
import { IconCoffee } from "@tabler/icons-react";
import { FoldoutPanelControlled } from "../../../../components/FoldoutPanel";
import {
  ReportMetaState,
  reportMetaStateToPersistent,
} from "../../../../lib/application/state/stats/document-meta/definitions";
import { useAppMessages } from "../../../../lib/application/hooks/useAppMessages";
import { adminApi } from "../../../../lib/application/requests/admin/admin_api";
import { getNonMemberOrgMeasureDatasetIds } from "../../../../lib/application/state/stats/document-core/dataset_ids";
import {
  secureDeliveryLinkFromCode,
  thirdPartyLinkFromCode,
} from "../../../../lib/paths";
import { Progress } from "../../../../lib/core/progress";
import {
  InstantiateMemberOrgDocsResultDto,
  OrganizationDetailsV2Dto,
} from "../../../../lib/infra/api_responses/admin/organizations";
import { Table } from "../../../../components/Table";
import { displayHttpErrorInternal } from "../../../../lib/infra/HttpResult";
import { nonEmptyString } from "../../../../lib/core/nonEmptyString";
import { useEmailsInputState } from "../../../../lib/application/hooks/useEmailsInputState";
import { CustomBranding } from "../../../../components/NavBar";
import { OrgBranding } from "./_Branding";

type ThirdPartyOrg = NonNullable<
  OrganizationDetailsV2Dto["third_party_orgs"]
>[0];

const orgKey = "org";
const anyoneWithLinkKey = "anyoneWithLink";
const publicPackagedDocKey = "publicPackagedDoc";
const shareWithLinkOption = {
  key: anyoneWithLinkKey,
  text: "Vilken Infostat-användare som helst med länk",
};
const shareWithOrgOption = { key: orgKey, text: "Din organisation" };
const shareWithPublicPackagedDocOption = {
  key: publicPackagedDocKey,
  text: "Publikt, via statiskt dokument",
};
const editorShareOptions: IChoiceGroupOption[] = [
  // Not implemented yet
  // { key: peopleInOrgKey, text: "Vissa personer i din organisation" },
  shareWithLinkOption,
  {
    ...shareWithOrgOption,
    disabled: true,
    title: "Endast ägare kan dela med organisationen",
  },
];

const ownerShareOptions: IChoiceGroupOption[] = [
  shareWithLinkOption,
  shareWithOrgOption,
];

const viewerOnlyRoleOptions: IChoiceGroupOption[] = [
  { key: "viewer", text: "Visa" },
];
const viewerEditorRoleOptions: IChoiceGroupOption[] = [
  ...viewerOnlyRoleOptions,
  { key: "editor", text: "Redigera" },
];

interface Props {
  sharingInfo: ISharingInfo;
  nodeId: number;
  reloadSharingInfo?: SharingInfoReloader;
  nodeType: NodeTypeRegular;
  handleShowSharing: () => void;
}

export function SharePivot(props: Props) {
  const { sharingInfo, nodeId, nodeType } = props;
  const [selectedSharingType, setSelectedSharingType] =
    useState(anyoneWithLinkKey);

  const userInfo = useContext(UserInfoContext);
  const handleSaveDoc = useContext(SaveDocumentContext);
  const cardsList = useRecoilValue(docCardsListQuery);
  const getAllCards = useGetAllCardsCallback(cardsList);
  const [metaState, setMetaState] = useRecoilState(reportMetaStateQuery);

  const isThirdPartyDoc = metaState?.thirdPartySharingDoc ?? false;
  const [thirdPartyShareOpen, setThirdPartyShareOpen] = useState(
    (userInfo?.hasThirdPartyShareAccess() && isThirdPartyDoc) ?? false
  );

  const handleToggleThirdPartyDoc = useCallback(() => {
    if (!defined(userInfo)) {
      return;
    }
    if (!defined(metaState)) {
      return;
    }
    const next = !isThirdPartyDoc;
    setMetaState({
      ...metaState,
      thirdPartySharingDoc: next,
    });
    handleSaveDoc?.(getAllCards(), {
      ...reportMetaStateToPersistent(metaState),
      thirdPartySharingDoc: next,
    });
  }, [
    getAllCards,
    handleSaveDoc,
    isThirdPartyDoc,
    metaState,
    setMetaState,
    userInfo,
  ]);

  const sharingOptions = useMemo(() => {
    if (!defined(userInfo)) {
      return;
    }
    const base = (
      sharingInfo.isOwner() ? ownerShareOptions : editorShareOptions
    ).slice();
    if (nodeType === "document" && !isThirdPartyDoc) {
      base.push(shareWithPublicPackagedDocOption);
    }
    return base;
  }, [isThirdPartyDoc, nodeType, sharingInfo, userInfo]);

  return (
    <div className="share-pivot">
      {!sharingInfo.isShared() &&
        defined(userInfo) &&
        userInfo.hasThirdPartyShareAccess() && (
          <AlertBox>
            <FoldoutPanelControlled
              isOpen={thirdPartyShareOpen}
              toggleOpen={() => setThirdPartyShareOpen(!thirdPartyShareOpen)}
              title="Dela med användare som inte har tillgång Infostat?"
            >
              <ThirdPartyShareSection
                docId={nodeId}
                isThirdPartyDoc={isThirdPartyDoc}
                handleToggleThirdPartyDoc={handleToggleThirdPartyDoc}
              />
            </FoldoutPanelControlled>
          </AlertBox>
        )}
      {sharingInfo.isShared() && (
        <AlertBox intent="primary">
          <div className="flex-row-space-between flex-align-items-center">
            <span>{getAlreadySharedText(nodeType)}</span>
            <DefaultButton
              text="Visa delningar"
              onClick={() => props.handleShowSharing()}
            ></DefaultButton>
          </div>
        </AlertBox>
      )}
      {!isThirdPartyDoc && (
        <>
          <ChoiceGroup
            label="Vem vill du dela med?"
            className="section"
            selectedKey={selectedSharingType}
            onChange={(ev, option) =>
              defined(option) ? setSelectedSharingType(option.key) : undefined
            }
            options={sharingOptions}
          ></ChoiceGroup>
          {selectedSharingType === orgKey && (
            <OrgShare
              nodeType={nodeType}
              nodeId={nodeId}
              sharingInfo={sharingInfo}
              reloadSharingInfo={props.reloadSharingInfo}
            ></OrgShare>
          )}
          {selectedSharingType === anyoneWithLinkKey && (
            <AnyoneLinkShare
              nodeType={nodeType}
              nodeId={nodeId}
              reloadSharingInfo={props.reloadSharingInfo}
            ></AnyoneLinkShare>
          )}
          {selectedSharingType === publicPackagedDocKey && (
            <PublicPackagedDocShare
              nodeType={nodeType}
              nodeId={nodeId}
            ></PublicPackagedDocShare>
          )}
        </>
      )}
      <div className="section"></div>
    </div>
  );
}

function getAlreadySharedText(nodeType: NodeTypeRegular) {
  switch (nodeType) {
    case "folder":
      return "Mappen är delad.";
    case "document":
      return "Dokumentet är delat.";
  }
}

function PublicPackagedDocShare(props: {
  nodeId: number;
  nodeType: NodeTypeRegular;
}) {
  const [link, setLink] = useState<string | undefined>();
  const [status, requestLink] = useHttpRequestWithStatus(
    createPackagedDocument
  );
  const [progress, setProgress] = useState<number | null>(null);
  const [docName, setDocName] = useState<string>("");
  const [vanityPath, setVanityPath] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const organizationBranding = useContext(OrganizationBrandingContext);
  const geographies = useContext(GeographiesContext);
  const metaState = useRecoilValue(reportMetaStateQuery);
  const cardsList = useRecoilValue(docCardsListQuery);
  const getAllCards = useGetAllCardsCallback(cardsList);
  const [brandingChangePending, setBrandingChangePending] = useState(false);
  const [branding, setBranding] = useState<CustomBranding | undefined>();
  const [brandingPanelOpen, setBrandingPanelOpen] = useState(false);

  const handleCreate = useCallback(async () => {
    const releaseId = config.releaseId;
    if (!defined(releaseId)) {
      return logger.error("releaseId is not defined");
    }
    if (!defined(geographies)) {
      return logger.error("geographies is not defined");
    }
    if (nonEmptyString(vanityPath)) {
      if (!/^[a-z0-9-_]+$/.test(vanityPath)) {
        setError(
          "Anpassad URL får endast innehålla a-z, 0-9, bindestreck och understreck"
        );
        return;
      }
      if (vanityPath.length > 50) {
        setError("Anpassad URL får vara max 50 tecken lång");
        return;
      }
    }
    setProgress(0);
    // Filter out python cards from share docs.
    // These are for producing other cards only.
    const allCards = getAllCards().filter((card) => card.type !== "pythonCard");
    const workspace = stateToWorkspaceLatest(allCards, {
      editModeOn: false,
      noFramesMode: metaState?.noFramesMode ?? false,
      thirdPartySharingDoc: metaState?.thirdPartySharingDoc,
    });
    try {
      const docNameTrimmed = docName.trim();
      const packageData = await makePackagedDocument(
        docNameTrimmed,
        geographies,
        allCards,
        branding ?? organizationBranding,
        (current, total) => setProgress((current / total) * 100)
      );
      requestLink(
        releaseId,
        docNameTrimmed,
        workspace,
        packageData,
        nonEmptyString(vanityPath) ? vanityPath : undefined
      ).then((res) => {
        res.match({
          ok: (data) => setLink(packagedDocLink(data.packaged_document_id)),
          err: (err) => {
            if (err.code === "conflict") {
              setError(
                "Det finns redan en sådan länk med samma namn. Välj en annan länk eller lämna fältet tomt."
              );
              return;
            }
            logger.error("Failed to create packaged document", err);
            setError(
              "Misslyckades med att skapa dokumentet: " + displayHttpError(err)
            );
          },
        });
      });
    } catch (e) {
      logger.error("Failed to create packaged document", e);
      setProgress(null);
      setError(
        "Misslyckades med att skapa delat dokument. Försök igen senare."
      );
    }
  }, [
    branding,
    docName,
    geographies,
    getAllCards,
    metaState?.noFramesMode,
    metaState?.thirdPartySharingDoc,
    organizationBranding,
    requestLink,
    vanityPath,
  ]);

  return (
    <div>
      <h3>Dela publikt</h3>
      <p>
        Klicka nedan för att skapa en publik sida. Du får en länk som kan delas
        med vem som helst utan kräva inloggning.
      </p>
      <TextField
        className="margin-bottom-sm"
        label="Titel på dokument"
        value={docName}
        onChange={(ev, newValue) => setDocName(newValue ?? "")}
      ></TextField>
      <TextField
        className="margin-bottom-sm"
        label="Anpassad URL (valfritt)"
        value={vanityPath}
        onChange={(ev, newValue) => setVanityPath(newValue ?? "")}
      />
      <>
        {progress !== null && !defined(link) && (
          <section>
            <div className="flex-row flex-align-items-center margin-bottom-xs">
              Genererar sida... Det här kan ta en liten stund.{" "}
              <IconCoffee size={18} className="margin-left-sm" />
            </div>
            <ProgressBar
              sections={[
                { value: progress, color: PROGRESS_BAR_GREEN },
                { value: 100 - progress, color: PROGRESS_BAR_BACKGROUND_GREEN },
              ]}
            ></ProgressBar>
          </section>
        )}
      </>
      <section>
        <FoldoutPanelControlled
          isOpen={brandingPanelOpen}
          toggleOpen={() => setBrandingPanelOpen(!brandingPanelOpen)}
          title={"Anpassa branding"}
        >
          <OrgBranding
            changePending={brandingChangePending}
            currentBranding={branding}
            handleSetBranding={(b) => {
              setBranding(b);
              setBrandingPanelOpen(false);
            }}
            originalDocID={props.nodeId}
            removeCurrentBranding={() => setBranding(undefined)}
            setChangePending={setBrandingChangePending}
          />
        </FoldoutPanelControlled>
      </section>

      <div className="section">
        <ButtonWithSideSpinner
          title="Skapa"
          disabled={docName.length === 0 || defined(link) || brandingPanelOpen}
          loading={
            (progress !== null && Math.round(progress) !== 100) ||
            status.isInProgress()
          }
          intent="primary"
          onClick={() => {
            handleCreate();
          }}
        />
      </div>
      {defined(error) && (
        <AlertBox intent="danger">
          <span>{error}</span>
        </AlertBox>
      )}
      {defined(link) && <ShareLinkCreatedView link={link} />}
    </div>
  );
}

function AnyoneLinkShare(props: {
  nodeId: number;
  nodeType: NodeTypeRegular;
  reloadSharingInfo: SharingInfoReloader | undefined;
}) {
  const { nodeId } = props;
  const state = useState<RoleDto>("viewer");
  const [status, requestLink] = useHttpRequestWithStatus(createLink);
  const [link, setLink] = useState<string | undefined>();
  return (
    <div>
      <h3>Dela med Infostat-användare via länk</h3>
      <div className="section">
        <RoleSelection
          label="Tillåt mottagare att"
          options={viewerEditorRoleOptions}
          state={state}
        ></RoleSelection>
      </div>
      <div className="section">
        <ButtonWithSideSpinner
          title="Hämta länk"
          intent="primary"
          loading={status.isInProgress()}
          onClick={() =>
            requestLink({ node_id: nodeId, role: state[0] }).then((res) => {
              res.match({
                ok: (code) => {
                  props.reloadSharingInfo?.();
                  setLink(shareCodeToLink(code));
                },
                err: voidFunc,
              });
            })
          }
        />
      </div>
      {defined(link) && <ShareLinkCreatedView link={link} />}
      {status.isErr() && (
        <AlertBox intent="danger">
          <span>Misslyckades med att skapa länk. Försök igen senare.</span>
        </AlertBox>
      )}
    </div>
  );
}

function OrgShare(props: {
  nodeId: number;
  nodeType: NodeTypeRegular;
  sharingInfo: ISharingInfo;
  reloadSharingInfo: SharingInfoReloader | undefined;
}) {
  const { reloadSharingInfo, nodeType } = props;
  const state = useState<RoleDto>("viewer");
  const [status, shareNodeWrapped] = useHttpRequestWithStatus(shareNode);

  const handleShare = useCallback(() => {
    shareNodeWrapped(props.nodeId, "org", state[0]).then((res) => {
      res.match({
        ok: () => reloadSharingInfo?.(),
        err: voidFunc,
      });
    });
  }, [props.nodeId, reloadSharingInfo, shareNodeWrapped, state]);

  const alreadyShared = props.sharingInfo.isSharedWithUserOrganization();
  const sharingAllowed = props.sharingInfo.isOwner();

  return (
    <div>
      <h3>Dela med din organisation</h3>
      {alreadyShared && (
        <AlertBox intent="primary">
          <span>
            {nodeType === "document"
              ? "Dokumentet är delat med din organisation."
              : "Mappen är delad med din organisation."}
          </span>
        </AlertBox>
      )}
      <AlertBox intent="warning">
        <span>
          När du ska dela ett dokument med din organisation måste dokumentet
          ligga i en mapp som är delad med organisationen.
        </span>
      </AlertBox>
      <div className="section">
        <RoleSelection
          label="Tillåt medlemmar att"
          options={viewerEditorRoleOptions}
          state={state}
        ></RoleSelection>
      </div>
      <ButtonWithSideSpinner
        loading={status.isInProgress()}
        disabled={status.isReady() || alreadyShared || !sharingAllowed}
        onClick={handleShare}
        intent="primary"
        title="Dela"
      ></ButtonWithSideSpinner>
    </div>
  );
}

function RoleSelection(props: {
  label: string;
  options: IChoiceGroupOption[];
  state: [RoleDto, (role: RoleDto) => void];
}) {
  const [selected, setSelected] = props.state;
  return (
    <ChoiceGroup
      label={props.label}
      selectedKey={selected}
      onChange={(ev, option) =>
        defined(option) ? setSelected(option.key as any) : undefined
      }
      options={props.options}
    ></ChoiceGroup>
  );
}
function ThirdPartyShareSection(props: {
  docId: number;
  isThirdPartyDoc: boolean;
  handleToggleThirdPartyDoc: () => void;
}) {
  const { isThirdPartyDoc, handleToggleThirdPartyDoc } = props;
  const [createdLink, setCreatedLink] = useState<string | undefined>(undefined);
  const [memberOrgsEmailSubject, setMemberOrgsEmailSubject] = useState(
    config.emails.memberOrgsEmailSubjectDefault
  );
  const [memberOrgsEmailBody, setMemberOrgsEmailBody] = useState(
    config.emails.memberOrgsEmailBodyDefault
  );

  const [externalDeliveryEmailSubject, setExternalDeliveryEmailSubject] =
    useState(config.emails.externalDeliveryEmailSubjectDefault);
  const [externalDeliveryEmailBody, setExternalDeliveryEmailBody] = useState(
    config.emails.externalDeliveryEmailBodyDefault
  );

  const [metaState, setMetaState] = useRecoilState(reportMetaStateQuery);
  const userInfo = useContext(UserInfoContext);
  const [currentOrg, setCurrentOrg] = useState<
    OrganizationDetailsV2Dto | undefined
  >(undefined);
  const cardsList = useRecoilValue(docCardsListQuery);
  const getAllCards = useGetAllCardsCallback(cardsList);
  const [progress, setProgress] = useState<Progress>(Progress.NotStarted);
  const [memberOrgsDocsResult, setMemberOrgsDocsResult] = useState<
    InstantiateMemberOrgDocsResultDto | undefined
  >();

  const [failedMemberOrgs, setFailedMemberOrgs] = useState<ThirdPartyOrg[]>([]);
  const [secureDeliverySendEmails, setSecureDeliverySendEmails] =
    useState(false);

  const [secureDeliveryName, setSecureDeliveryName] = useState("");

  const {
    emailsRaw: recipientEmailsRaw,
    setEmailsRaw: setRecipientEmailsRaw,
    emailValidationMessage,
    currentEmails,
  } = useEmailsInputState();

  const hasMemberOrgMeasures = useMemo(() => {
    return getAllCards().some(
      (card) => card.type === "dataCard" && isTemplateCard(card)
    );
  }, [getAllCards]);

  const choiceGroupOptions: IChoiceGroupOption[] = useMemo(() => {
    return [
      {
        disabled: hasMemberOrgMeasures,
        key: "third-party",
        text: "Skapa dokument läsbart för valda mottagare",
      },
      userInfo?.hasMemberOrgsShareAccess()
        ? {
            disabled: !hasMemberOrgMeasures,
            key: "member-orgs",
            text: "Skapa dokument för medlemsorganisationer utifrån detta dokument som mall",
          }
        : undefined,
    ].filter(defined);
  }, [hasMemberOrgMeasures, userInfo]);

  const [selectedOption, setSelectedOption] = useState<
    "third-party" | "member-orgs"
  >(
    userInfo?.hasMemberOrgsShareAccess() && hasMemberOrgMeasures
      ? "member-orgs"
      : "third-party"
  );

  const [memberOrgRecipientOption, setMemberOrgRecipientOption] = useState<
    "all" | "selected"
  >("selected");
  const [memberOrgsSendEmails, setMemberOrgsSendEmails] = useState(false);
  const [selectedMemberOrgs, setSelectedMemberOrgs] = useState<string[]>([]);

  const handleSaveDoc = useContext(SaveDocumentContext);
  const appMessages = useAppMessages();

  const [brandingChangePending, setBrandingChangePending] = useState(false);
  const [branding, setBranding] = useState<CustomBranding | undefined>(
    metaState?.customBranding
  );
  const [brandingPanelOpen, setBrandingPanelOpen] = useState(false);

  const canMakeSecureDeliveryDoc = useMemo(() => {
    return (
      isThirdPartyDoc &&
      currentEmails.length > 0 &&
      !nonEmptyString(emailValidationMessage) &&
      nonEmptyString(secureDeliveryName)
    );
  }, [
    currentEmails.length,
    emailValidationMessage,
    isThirdPartyDoc,
    secureDeliveryName,
  ]);

  const canUseAsTemplate = useMemo(() => {
    if (!isThirdPartyDoc) {
      return false;
    }

    if (selectedOption === "member-orgs" && !hasMemberOrgMeasures) {
      return false;
    }
    return isThirdPartyDoc;
  }, [hasMemberOrgMeasures, isThirdPartyDoc, selectedOption]);

  useEffect(() => {
    if (!isThirdPartyDoc) {
      return;
    }
    if (selectedOption !== "member-orgs") {
      return;
    }

    const cards = getAllCards();
    if (!defined(metaState)) {
      return;
    }

    const { placeholderInfo } = stateToThirdPartyDocTemplate(cards, metaState);
    adminApi
      .getParentOrgInfo(
        placeholderInfo.map((p) => p?.parent_measure_id).filter(defined)
      )
      .then((res) => {
        res.match({
          ok: (org) => {
            setCurrentOrg(org);
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Kunde inte hämta din organisation: " + displayHttpError(err)
            );
          },
        });
      });
  }, [
    appMessages,
    getAllCards,
    isThirdPartyDoc,
    metaState,
    selectedOption,
    userInfo,
  ]);

  const handleInstantiateDocsFromTemplate = useCallback(() => {
    if (!canUseAsTemplate) {
      logger.error("Cannot use as template");
      return;
    }

    if (memberOrgsSendEmails && memberOrgsEmailSubject.trim().length === 0) {
      appMessages?.add("error", "Ämne för e-post saknas");
      return;
    }

    const accessIds = getNonMemberOrgMeasureDatasetIds(getAllCards());

    const cards = getAllCards();
    if (!defined(metaState)) {
      return;
    }
    const { placeholderInfo, state, workspaceVersion } =
      stateToThirdPartyDocTemplate(cards, metaState);

    let recipients: string[];
    switch (memberOrgRecipientOption) {
      case "all":
        recipients =
          currentOrg?.third_party_orgs?.map((t) => t.third_party_org_id) ?? [];
        if (recipients.length === 0) {
          appMessages?.add(
            "error",
            "Inga mottagarorganisationer valda/hittade"
          );
          return;
        }
        break;
      case "selected":
        recipients = selectedMemberOrgs;
        break;
    }

    setProgress(Progress.InProgress);
    adminApi
      .createThirdPartyDocsFromTemplate(
        { state, workspaceVersion },
        placeholderInfo,
        metaState.id,
        accessIds.statsIds,
        accessIds.microIds,
        memberOrgsSendEmails,
        memberOrgsEmailSubject,
        memberOrgsEmailBody.length > 0 ? memberOrgsEmailBody : undefined,
        recipients
      )
      .then((res) => {
        res.match({
          ok: (info) => {
            if (!defined(currentOrg)) {
              throw new Error("No current org");
            }

            const failedOrgs = getFailedOrgs(
              info,
              currentOrg,
              selectedMemberOrgs,
              memberOrgRecipientOption
            );
            const numSuccessful = info?.length ?? 0;
            appMessages?.add(
              failedOrgs.length > 0 ? "warning" : "success",
              `Dokument skapade för ${numSuccessful} (${failedOrgs.length} misslyckades) medlemsorganisationer`
            );
            setProgress(Progress.Success);
            setFailedMemberOrgs(failedOrgs);
            setMemberOrgsDocsResult(info ?? []);
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Kunde inte dela dokumentet: " + displayHttpErrorInternal(err)
            );
            setProgress(Progress.Error);
          },
        });
      });
  }, [
    appMessages,
    canUseAsTemplate,
    currentOrg,
    getAllCards,
    memberOrgRecipientOption,
    memberOrgsEmailBody,
    memberOrgsEmailSubject,
    memberOrgsSendEmails,
    metaState,
    selectedMemberOrgs,
  ]);

  const handleCreateSecureDeliveryDoc = useCallback(() => {
    if (!canMakeSecureDeliveryDoc) {
      return;
    }

    if (defined(emailValidationMessage) && emailValidationMessage.length > 0) {
      return;
    }

    // Update metaState with branding before creating the doc
    if (metaState) {
      const newMetaState = {
        ...metaState,
        customBranding: branding,
      };
      setMetaState(newMetaState);
      // Save the document with new metaState
      handleSaveDoc?.(getAllCards(), reportMetaStateToPersistent(newMetaState));
    }

    const accessIds = getNonMemberOrgMeasureDatasetIds(getAllCards());
    createSecureDeliveryDoc(
      props.docId,
      currentEmails,
      secureDeliverySendEmails,
      accessIds,
      secureDeliveryName,
      externalDeliveryEmailSubject,
      externalDeliveryEmailBody.length > 0
        ? externalDeliveryEmailBody
        : undefined
    ).then((res) => {
      res.match({
        ok: (info) => {
          setCreatedLink(secureDeliveryLinkFromCode(info.third_party_doc_id));
        },
        err: (err) => {
          appMessages?.add("error", "Kunde inte dela dokumentet");
        },
      });
    });
  }, [
    appMessages,
    branding,
    canMakeSecureDeliveryDoc,
    currentEmails,
    emailValidationMessage,
    externalDeliveryEmailBody,
    externalDeliveryEmailSubject,
    getAllCards,
    handleSaveDoc,
    metaState,
    props.docId,
    secureDeliveryName,
    secureDeliverySendEmails,
    setMetaState,
  ]);

  return (
    <div>
      <p>
        Klicka på knappen nedan och gå sedan tillbaka till dokumentet för att
        göra eventuella inställningar för vad mottagaren ska kunna göra med
        dokumentet.
      </p>

      <section>
        <Checkbox
          checked={isThirdPartyDoc}
          label="Gör dokument delbart externt"
          onChange={handleToggleThirdPartyDoc}
        />
      </section>
      {isThirdPartyDoc && (
        <>
          <section>
            <ChoiceGroup
              selectedKey={selectedOption}
              onChange={(ev, option) => {
                if (!defined(option)) {
                  return;
                }
                setSelectedOption(option.key as any);
              }}
              options={choiceGroupOptions}
            />

            {selectedOption === "third-party" && (
              <>
                {hasMemberOrgMeasures ? (
                  <AlertBox intent="warning" className="margin-top-md">
                    <span>
                      Det finns mått som är kopplade till en
                      paraplyorganisation. Dessa kan _endast_ användas vid
                      skapande av dokument för medlemsorganisationer.
                    </span>
                  </AlertBox>
                ) : (
                  <section>
                    <div className="margin-top-lg">
                      <p>
                        Skapa dokument läsbart för valda mottagare. Inloggning
                        krävs för att se innehållet.
                      </p>
                    </div>
                    <section>
                      <TextField
                        className=""
                        multiline
                        errorMessage={emailValidationMessage}
                        value={recipientEmailsRaw}
                        placeholder="E-postadresser"
                        onChange={(e) =>
                          setRecipientEmailsRaw(e.currentTarget.value)
                        }
                      />
                    </section>

                    <Checkbox
                      className="margin-bottom-md"
                      label="Skicka e-post till alla mottagare"
                      disabled={
                        progress === Progress.InProgress ||
                        progress === Progress.Success
                      }
                      checked={secureDeliverySendEmails}
                      onChange={(ev, checked) => {
                        if (!defined(checked)) {
                          return;
                        }
                        setSecureDeliverySendEmails(checked);
                      }}
                    />

                    {secureDeliverySendEmails && (
                      <section>
                        <TextField
                          label="Ämne"
                          value={externalDeliveryEmailSubject}
                          onChange={(e) =>
                            setExternalDeliveryEmailSubject(
                              e.currentTarget.value
                            )
                          }
                        ></TextField>
                        <TextField
                          label="Meddelande"
                          multiline
                          value={externalDeliveryEmailBody}
                          onChange={(e) =>
                            setExternalDeliveryEmailBody(e.currentTarget.value)
                          }
                        ></TextField>
                      </section>
                    )}

                    <TextField
                      label="Beskrivning (visas ej för mottagare)"
                      multiline
                      required
                      value={secureDeliveryName}
                      onChange={(e) =>
                        setSecureDeliveryName(e.currentTarget.value)
                      }
                    ></TextField>

                    <section>
                      <FoldoutPanelControlled
                        isOpen={brandingPanelOpen}
                        toggleOpen={() =>
                          setBrandingPanelOpen(!brandingPanelOpen)
                        }
                        title={"Anpassa branding"}
                      >
                        <OrgBranding
                          changePending={brandingChangePending}
                          currentBranding={branding}
                          handleSetBranding={(b) => {
                            setBranding(b);
                            setBrandingPanelOpen(false);
                            // Update the metaState with new branding
                            if (metaState) {
                              const newMetaState: ReportMetaState = {
                                ...metaState,
                                customBranding: {
                                  backgroundColor: b.backgroundColor,
                                  foregroundColor: b.foregroundColor,
                                  logoBigUrl: b.logoBigUrl,
                                  logoSmallUrl: b.logoSmallUrl,
                                },
                              };
                              setMetaState(newMetaState);
                              // Save the document with new metaState
                              handleSaveDoc?.(
                                getAllCards(),
                                reportMetaStateToPersistent(newMetaState)
                              );
                            }
                          }}
                          originalDocID={props.docId}
                          removeCurrentBranding={() => {
                            setBranding(undefined);
                            // Update the metaState to remove custom branding
                            if (metaState) {
                              const newMetaState = {
                                ...metaState,
                                customBranding: undefined,
                              };
                              setMetaState(newMetaState);
                              // Save the document with new metaState
                              handleSaveDoc?.(
                                getAllCards(),
                                reportMetaStateToPersistent(newMetaState)
                              );
                            }
                          }}
                          setChangePending={setBrandingChangePending}
                        />
                      </FoldoutPanelControlled>
                    </section>

                    <div className="margin-top-sm">
                      <Button
                        intent="primary"
                        title="Dela"
                        disabled={!canMakeSecureDeliveryDoc}
                        onClick={handleCreateSecureDeliveryDoc}
                      />
                    </div>
                  </section>
                )}
                {defined(createdLink) && (
                  <ShareLinkCreatedView link={createdLink} />
                )}
              </>
            )}

            {selectedOption === "member-orgs" && (
              <div>
                {!hasMemberOrgMeasures && (
                  <AlertBox intent="warning" className="margin-top-md">
                    <span>
                      Det finns inga mått som är kopplade till en
                      paraplyorganisation. Minst ett sådant måste finnas för att
                      skapa dokument för medlemsorganisationer.
                    </span>
                  </AlertBox>
                )}
                <p>
                  Skapa dokument för medlemsorganisationer med detta dokument
                  som mall. Mått med medlemsorganisationer kommer begränsas så
                  att varje medlemsorganisation endast får tillgång till sin
                  egen data.
                </p>

                <ChoiceGroup
                  selectedKey={memberOrgRecipientOption}
                  onChange={(ev, option) => {
                    if (!defined(option)) {
                      return;
                    }
                    setMemberOrgRecipientOption(option.key as any);
                    if (option.key === "all") {
                      setSelectedMemberOrgs([]);
                    }
                  }}
                  disabled={
                    progress === Progress.InProgress ||
                    progress === Progress.Success
                  }
                  options={[
                    {
                      key: "all",
                      text: `Skapa för alla medlemsorganisationer (${
                        currentOrg?.third_party_orgs?.length ?? 0
                      })`,
                    },
                    {
                      key: "selected",
                      text: `Skapa för utvalda medlemsorganisationer (${selectedMemberOrgs.length} valda)`,
                    },
                  ]}
                />

                <section className="member-org-recipients">
                  {memberOrgRecipientOption === "selected" &&
                    defined(currentOrg) && (
                      <>
                        <Checkbox
                          label="Välj alla"
                          checked={
                            selectedMemberOrgs.length ===
                            currentOrg.third_party_orgs?.length
                          }
                          onChange={(e, checked) => {
                            if (checked) {
                              setSelectedMemberOrgs(
                                currentOrg.third_party_orgs?.map(
                                  (org) => org.third_party_org_id
                                ) ?? []
                              );
                            } else {
                              setSelectedMemberOrgs([]);
                            }
                          }}
                        />

                        {currentOrg.third_party_orgs?.map((org) => (
                          <Checkbox
                            key={org.third_party_org_id}
                            label={org.name}
                            checked={selectedMemberOrgs.includes(
                              org.third_party_org_id
                            )}
                            onChange={(e, checked) => {
                              if (checked) {
                                setSelectedMemberOrgs([
                                  ...selectedMemberOrgs,
                                  org.third_party_org_id,
                                ]);
                              } else {
                                setSelectedMemberOrgs(
                                  selectedMemberOrgs.filter(
                                    (id) => id !== org.third_party_org_id
                                  )
                                );
                              }
                            }}
                          />
                        ))}
                      </>
                    )}
                </section>

                <section className="margin-top-md">
                  <Checkbox
                    label="Skicka e-post till alla mottagare"
                    checked={memberOrgsSendEmails}
                    onChange={(ev, checked) => {
                      if (!defined(checked)) {
                        return;
                      }
                      setMemberOrgsSendEmails(checked);
                    }}
                  ></Checkbox>
                  {memberOrgsSendEmails && (
                    <>
                      <TextField
                        label="Ämne"
                        value={memberOrgsEmailSubject}
                        onChange={(ev, newValue) =>
                          setMemberOrgsEmailSubject(newValue ?? "")
                        }
                        required
                      ></TextField>
                      <TextField
                        label="Meddelande"
                        multiline
                        value={memberOrgsEmailBody}
                        onChange={(ev, newValue) =>
                          setMemberOrgsEmailBody(newValue ?? "")
                        }
                      ></TextField>
                    </>
                  )}
                </section>

                {progress !== Progress.Success && (
                  <div>
                    <ButtonWithSideSpinner
                      loading={progress === Progress.InProgress}
                      intent="primary"
                      title="Skapa"
                      disabled={!canUseAsTemplate}
                      onClick={handleInstantiateDocsFromTemplate}
                    />
                  </div>
                )}
                {defined(memberOrgsDocsResult) && (
                  <>
                    <section>
                      <h3>Skapade dokument</h3>
                      <Table
                        containerClassName="bg-white"
                        columns={[
                          {
                            key: "org",
                            name: "Medlemsorganisation",
                          },
                          {
                            key: "doc",
                            name: "Dokumentlänk",
                          },
                        ]}
                        data={memberOrgsDocsResult.map((doc) => {
                          const link = thirdPartyLinkFromCode(doc.document_id);
                          return {
                            id: doc.document_id,
                            cells: [doc.member_org_name, link],
                          };
                        })}
                      />
                    </section>
                    {defined(currentOrg) && (
                      <FailedDocsTable failedOrgs={failedMemberOrgs} />
                    )}
                  </>
                )}
              </div>
            )}
          </section>
        </>
      )}
    </div>
  );
}

function getFailedOrgs(
  docsCreated: InstantiateMemberOrgDocsResultDto,
  org: OrganizationDetailsV2Dto,
  selectedMemberOrgs: string[],
  selectMode: "all" | "selected" | "none"
) {
  switch (selectMode) {
    case "all":
      return (
        org.third_party_orgs?.filter((o) => {
          return !docsCreated?.some((doc) => doc.member_org_name === o.name);
        }) ?? []
      );
    case "selected":
      return selectedMemberOrgs
        .map((id) =>
          org.third_party_orgs?.find((o) => o.third_party_org_id === id)
        )
        .filter(defined)
        .filter((o) => {
          return !docsCreated?.some((doc) => doc.member_org_name === o.name);
        });
    case "none":
      return [];
  }
}

function FailedDocsTable(props: { failedOrgs: ThirdPartyOrg[] }) {
  if (props.failedOrgs.length === 0) {
    return null;
  }

  return (
    <section>
      <AlertBox intent="danger">
        <>
          <h3>Misslyckade dokument</h3>
          <p>
            Kontrollera att alla mått i dokumentet har uppdaterats efter att
            nedan medlemsorganisationer lagts till. Detta behövs även om ingen
            ny data tillkommit.
          </p>
          <Table
            containerClassName="bg-white"
            columns={[
              {
                key: "org",
                name: "Medlemsorganisation",
              },
              {
                key: "id",
                name: "ID",
              },
              { key: "members", name: "Medlemmar" },
            ]}
            data={props.failedOrgs.map((org) => {
              return {
                id: org.name,
                cells: [
                  org.name,
                  org.third_party_org_id,
                  org.addresses?.map((a) => a.email).join(", ") ?? "",
                ],
              };
            })}
          />
        </>
      </AlertBox>
    </section>
  );
}

function ShareLinkCreatedView(props: { link: string }) {
  const link = props.link;
  return (
    <>
      <AlertBox intent="success">
        <h3>Länk skapad</h3>
        <p>
          Du kan nu dela länken med den du vill ska få tillgång till det här
          innehållet.
        </p>
        <div>
          <TextField value={link} readOnly></TextField>
        </div>
        <div className="margin-top-sm">
          <Button
            intent="primary"
            onClick={() => copyToClipboard(link)}
            title="Kopiera"
          ></Button>
        </div>
      </AlertBox>
    </>
  );
}
