import { Null } from "runtypes";
import { config } from "../../../../config";
import {
  DimensionsResponseV2RT,
  DimensionV2Dto,
} from "../../../domain/measure/definitions";
import { HttpResult } from "../../../infra/HttpResult";
import { decodedAuthedRequest } from "../shared";
import { SimpleCache } from "../../../core/SimpleCache";

const cache = new SimpleCache<DimensionV2Dto[]>(50);

/**
 * Stats v2 -- will replace v1 above
 */
export function getMeasureDimensionsV2(
  measureId: number,
  adminShowDraftData: boolean,
  useCache: boolean
): Promise<HttpResult<DimensionV2Dto[]>> {
  const cacheKey = `${measureId}-${adminShowDraftData}`;
  if (useCache) {
    const cached = cache.get(cacheKey);
    if (cached) {
      return Promise.resolve(HttpResult.fromOk(cached));
    }
  }

  return decodedAuthedRequest(
    config.apis.statsV2,
    `measures/${measureId}/dimensions${
      adminShowDraftData ? "?see_draft_data=true" : ""
    }`,
    undefined,
    "GET",
    DimensionsResponseV2RT.Or(Null)
  ).then((res) => {
    return res.map((data) => {
      const resultData = data ?? [];
      cache.set(cacheKey, resultData);
      return resultData;
    });
  });
}
