import { SpinnerSize } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { DelayedSpinner } from "../../../components/DelayedSpinner";
import { HttpErrorPage } from "../../../components/errors/HttpErrorView";
import { MainContent } from "../../../components/MainContent";
import {
  createDocument,
  getDocument,
  patchNode,
} from "../../../lib/application/requests/docs/documents";
import { decodeLatestWorkspace } from "../../../lib/application/state/stats/workspace/rebuild_state";
import { getText } from "../../../lib/application/strings";
import { defined } from "../../../lib/core/defined";
import { HttpError } from "../../../lib/infra/HttpResult";
import { logger } from "../../../lib/infra/logging";
import { documentPath } from "../../../lib/paths";

interface Props {
  fromCopyId: number;
  draft: boolean;
  newTitle?: string;
}

// TODO: Error handling
export function StatsNewCopy(props: Props) {
  const { fromCopyId, newTitle } = props;
  const [httpError, setHttpError] = useState<HttpError | undefined>(undefined);
  const navigate = useNavigate();

  const [loadError, setLoadError] = useState<HttpError>();

  useEffect(() => {
    getDocument(fromCopyId).then((document) => {
      const docRaw = document.switch();
      if (docRaw.type === "err") {
        return setLoadError(docRaw.error);
      }
      const workspace = decodeLatestWorkspace(docRaw.data);
      const workspaceInEditMode = {
        ...workspace,
        state: {
          ...workspace.state,
          cards: workspace.state.cards.map((card) => {
            return {
              ...card,
              isEditing: true,
            };
          }),
          reportMeta: {
            ...workspace.state.reportMeta,
            editModeOn: true,
          },
        },
      };
      const title = newTitle ?? getText("default-document-name");
      createDocument(title, workspaceInEditMode)
        .then((doc) => {
          const res = doc.switch();
          if (res.type !== "ok") {
            setHttpError(res.error);
            return;
          }

          if (props.draft) {
            navigate(documentPath(res.data));
          } else {
            patchNode(res.data, { title, draft: false }).then((patchRes) => {
              const patchResSwitch = patchRes.switch();
              if (patchResSwitch.type === "err") {
                setHttpError(patchResSwitch.error);
                return;
              }
              navigate(documentPath(res.data));
            });
          }
        })
        .catch((err) => {
          logger.error(err);
        });
    });
  }, [fromCopyId, navigate, newTitle, props.draft]);

  if (defined(loadError)) {
    return <HttpErrorPage error={loadError}></HttpErrorPage>;
  }

  return (
    <MainContent className="Stats padding-lg">
      {defined(httpError) ? (
        <HttpErrorPage error={httpError}></HttpErrorPage>
      ) : (
        <DelayedSpinner
          delayShowMs={500}
          size={SpinnerSize.large}
        ></DelayedSpinner>
      )}
    </MainContent>
  );
}
