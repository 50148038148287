import { defined } from "../../../core/defined";
import { Progress } from "../../../core/progress";
import { GeoType, GeographiesSerializable } from "../../../domain/geography";
import { logger } from "../../../infra/logging";
import {
  createBarChartHorizontalDataAndColors,
  createBarChartHorizontalDataWithExistingColors,
} from "../../stats/chart_containers/createBarChartHorizontalData";
import {
  createBarChartVerticalDataAndColors,
  createBarChartVerticalDataWithExistingColors,
} from "../../stats/chart_containers/createBarChartVerticalData";
import {
  createLineChartDataAndColors,
  createLineChartDataWithExistingColors,
} from "../../stats/chart_containers/createLineChartData";
import { StatsDataset } from "../../stats/datasets/StatsDataset";
import { SurveyDataset } from "../../stats/datasets/SurveyDataset";
import { ChartDataUnknown } from "../../stats/shared/chart_data/ChartData";
import { ChartType } from "../../stats/shared/core/definitions";
import {
  DatasetError,
  DocCardStats,
  GeoSelections,
} from "./document-core/core";
import {
  ChartDataContainerV2,
  ChartDataState,
  DataLoadProgress,
} from "./document-core/_core-shared";
import { ColorSchemeContainer } from "./document-style/definitions";
import { fetchDatasetForCard } from "./useDataset";
import { createMapDataset } from "../../stats/datasets/MapDataset";
import { getAllGeoJson } from "../../requests/common_requests";
import { cardColors } from "./document-core/queries/shared";
import { getCardOutputWidth } from "./_shared";
import { docCardSelectionValid } from "./document-core/card_info";
import { SurveyStringDataset } from "../../stats/datasets/SurveyStringDataset";
import { wait } from "../../../core/wait";
import {
  CustomThemeSpecApplied,
  ExtendedAppearanceSettings,
} from "../../stats/shared/core/colors/colorSchemes";
import { DataOutputSettings } from "./document-core/DataOutputSettings";

export async function loadAndStoreDataStats(
  setCard: (card: DocCardStats) => void,
  shouldAbort: () => boolean,
  card: DocCardStats,
  geographies: GeographiesSerializable,
  adminShowDraftData: boolean,
  appearanceSettings: ExtendedAppearanceSettings,
  colors: ColorSchemeContainer | undefined,
  handleSaveCard?: (card: DocCardStats) => void
) {
  const cardWithProgress = cardWithDataLoadingProgress(card, {
    type: Progress.InProgress,
  });
  // If we are running a slow update, show progress indicator.
  // Currently only done for updates where forecasts are involved.
  if (card.data.settings.forecast.show) {
    setCard(cardWithProgress);
    // Avoid blocking main thread for too long
    await wait(1);
  }

  const cardWithData = await cardWithUpdatedDataState(
    shouldAbort,
    cardWithProgress,
    geographies,
    adminShowDraftData,
    appearanceSettings,
    colors
  );
  if (shouldAbort()) {
    return;
  }
  if (!defined(cardWithData)) {
    return;
  }

  setCard(cardWithData);
  if (defined(handleSaveCard)) {
    handleSaveCard(cardWithData);
  }
}

export async function cardWithUpdatedDataState(
  shouldAbort: () => boolean,
  card: DocCardStats,
  geographies: GeographiesSerializable,
  adminShowDraftData: boolean,
  appearanceSettings: ExtendedAppearanceSettings,
  colors?: ColorSchemeContainer
): Promise<DocCardStats | undefined> {
  const selectionsIncompleteSpec = docCardSelectionValid(card, geographies);
  if (defined(selectionsIncompleteSpec)) {
    return {
      ...card,
      data: {
        ...card.data,
        loadedData: {
          ...card.data.loadedData,
          progress: {
            type: Progress.NotStarted,
            info: {
              type: "selections-incomplete",
              incomplete: selectionsIncompleteSpec,
            },
          },
        },
      },
    };
  }

  const datasetRes = await fetchDatasetForCard(
    card,
    geographies,
    adminShowDraftData,
    false
  );

  if (shouldAbort()) {
    return;
  }

  if (!defined(datasetRes) || datasetRes instanceof DatasetError) {
    logger.warn("Failed to fetch stats/survey dataset for card", card);
    return;
  }
  if (datasetRes.type === "err") {
    return {
      ...card,
      data: {
        ...card.data,
        loadedData: {
          ...card.data.loadedData,
          progress: { type: Progress.Error, error: datasetRes.error },
        },
      },
    };
  }

  const outputWidth = getCardOutputWidth(card);
  return cardWithUpdatedDataStateInner(
    card,
    datasetRes.data,
    geographies,
    outputWidth,
    appearanceSettings,
    colors
  );
}

/** Update cardOld with given settings */
export async function cardWithUpdatedDataOutputSettings(
  cardOld: DocCardStats,
  geographies: GeographiesSerializable,
  appearanceSettings: ExtendedAppearanceSettings,
  settings: DataOutputSettings
) {
  const card: DocCardStats = {
    ...cardOld,
    data: { ...cardOld.data, settings },
  };
  const datasetOld = card.data.loadedData?.dataset;

  if (!defined(datasetOld)) {
    logger.warn("No dataset for card");
    return;
  }
  const dataset = datasetOld.copyWithSettings({
    ...settings,
    showReferenceLines:
      settings.showReferenceLines &&
      (!(datasetOld instanceof SurveyDataset) ||
        (datasetOld instanceof SurveyDataset &&
          datasetOld.canUseReferenceValues())),
  });

  const outputWidth = getCardOutputWidth(card);
  return cardWithUpdatedDataStateInner(
    card,
    dataset,
    geographies,
    outputWidth,
    appearanceSettings,
    cardColors(card),
    settings
  );
}

export async function cardWithUpdatedDataStateInner(
  card: DocCardStats,
  dataset: StatsDataset | SurveyDataset | SurveyStringDataset,
  geographies: GeographiesSerializable,
  outputWidth: number,
  appearanceSettings: ExtendedAppearanceSettings,
  colors?: ColorSchemeContainer,
  settings?: DataOutputSettings
): Promise<DocCardStats | undefined> {
  const cardCopy: DocCardStats = { ...card, data: { ...card.data } };
  let selectedView = cardCopy.data.selectedView;
  const isChartable = dataset.isChartable();
  if (!defined(selectedView)) {
    if (isChartable) {
      cardCopy.data.selectedView = "diagram";
    } else {
      cardCopy.data.selectedView = "table";
    }
  } else {
    if (!isChartable && selectedView === "diagram") {
      cardCopy.data.selectedView = "table";
    }
  }

  if (dataset instanceof SurveyStringDataset) {
    switch (cardCopy.data.selectedView) {
      case "table":
      case "info": // When on the info tab, we still need to update stored data in order for the view to change
        return updateTableSurveyString(cardCopy, dataset);
    }
    throw new Error(
      "Unexpected view for survey string dataset: " + cardCopy.data.selectedView
    );
  }

  switch (cardCopy.data.selectedView) {
    case "diagram":
      return updateChart(
        cardCopy,
        dataset,
        outputWidth,
        appearanceSettings,
        colors
      );
    case "table":
    case "info": // When on the info tab, we still need to update stored data in order for the view to change
      return updateTable(cardCopy, dataset);
    case "map":
      if (dataset instanceof SurveyDataset) {
        // When we have a survey dataset but are on the map tab, nothing will be shown since
        // maps are not supported for survey. However, we need to create a valid doc card state
        // or else documents that contain cards like this will fail to load.
        return updateTable(cardCopy, dataset);
      }
      return updateMap(cardCopy, dataset, geographies);
  }

  return;
}

/**
 * Create ChartDataState.
 * If no color scheme container is supplied, a new one will be created.
 */
export async function chartDataToDataState(
  chartData: ChartDataUnknown,
  prevLoadedChartData: ChartDataContainerV2 | undefined,
  availableWidth: number,
  defaultCustomColors: CustomThemeSpecApplied,
  colorSchemeContainer?: ColorSchemeContainer
): Promise<ChartDataState | undefined> {
  const vizAreaWidth = availableWidth;
  const windowHeight = window.innerHeight;

  switch (chartData.chartType(vizAreaWidth)) {
    case ChartType.line:
      return {
        loadProgress: { type: Progress.Success },
        loadedChartData: !defined(colorSchemeContainer)
          ? createLineChartDataAndColors(
              chartData,
              vizAreaWidth,
              windowHeight,
              defaultCustomColors
            )
          : createLineChartDataWithExistingColors(
              chartData,
              prevLoadedChartData,
              vizAreaWidth,
              windowHeight,
              colorSchemeContainer
            ),
      };
    case ChartType.barVertical:
      return {
        loadProgress: { type: Progress.Success },
        loadedChartData: !defined(colorSchemeContainer)
          ? createBarChartVerticalDataAndColors(
              chartData,
              vizAreaWidth,
              windowHeight,
              defaultCustomColors
            )
          : createBarChartVerticalDataWithExistingColors(
              chartData,
              vizAreaWidth,
              windowHeight,
              colorSchemeContainer
            ),
      };
    case ChartType.barHorizontal:
      return {
        loadProgress: { type: Progress.Success },
        loadedChartData: !defined(colorSchemeContainer)
          ? createBarChartHorizontalDataAndColors(
              chartData,
              vizAreaWidth,
              defaultCustomColors
            )
          : createBarChartHorizontalDataWithExistingColors(
              chartData,
              vizAreaWidth,
              colorSchemeContainer
            ),
      };
  }
}

async function updateChart(
  card: DocCardStats,
  dataset: StatsDataset | SurveyDataset,
  outputMaxWidth: number,
  appearanceSettings: ExtendedAppearanceSettings,
  colors?: ColorSchemeContainer
): Promise<DocCardStats> {
  const chartData = dataset.chartData();
  const chartDataState = await chartDataToDataState(
    chartData,
    card.data.loadedData?.chartDataState?.loadedChartData,
    outputMaxWidth,
    appearanceSettings.defaultTheme,
    colors
  );

  return {
    ...card,
    data: {
      ...card.data,
      loadedData: {
        ...card.data.loadedData,
        progress: { type: Progress.Success },
        dataset: dataset,
        chartDataState,
      },
    },
  };
}

async function updateTableSurveyString(
  card: DocCardStats,
  dataset: SurveyStringDataset
): Promise<DocCardStats> {
  return {
    ...card,
    data: {
      ...card.data,
      loadedData: {
        ...card.data.loadedData,
        progress: { type: Progress.Success },
        dataset: dataset,
        tableSurveyStringDataState: {
          loadProgress: { type: Progress.Success },
          loadedData: dataset.tableSurveyString(),
        },
      },
    },
  };
}

async function updateTable(
  card: DocCardStats,
  dataset: StatsDataset | SurveyDataset
): Promise<DocCardStats> {
  const tableSpec = dataset.table();
  return {
    ...card,
    data: {
      ...card.data,
      loadedData: {
        ...card.data.loadedData,
        progress: { type: Progress.Success },
        dataset: dataset,
        tableDataState: {
          loadProgress: { type: Progress.Success },
          loadedTableData: tableSpec,
        },
      },
    },
  };
}

export async function handleCreateMapDataset(
  dataset: StatsDataset,
  geoSelections: GeoSelections,
  geographies: GeographiesSerializable,
  settings?: DataOutputSettings
) {
  const geoJson = await getAllGeoJson();

  const polygonLookup = (geoType: GeoType, geolabel: string) => {
    const haystack = geoJson[geoType];
    const geocode = geographies.labelToGeocode(geolabel, geoType);

    if (haystack?.type === "FeatureCollection") {
      const feature = haystack.features.find(
        (f) => f.properties?.["geocode"] === geocode
      );
      return feature;
    } else {
      throw new Error("Unexpected GeoJson: " + haystack?.type);
    }
  };

  return createMapDataset(dataset, geoSelections, polygonLookup, settings);
}

async function updateMap(
  card: DocCardStats,
  dataset: StatsDataset,
  geographies: GeographiesSerializable
): Promise<DocCardStats | undefined> {
  const geoSelections = card.data.geoSelections;
  if (!defined(geoSelections)) {
    return;
  }

  const mapData = await handleCreateMapDataset(
    dataset,
    geoSelections,
    geographies,
    card.data.settings
  );
  return {
    ...card,
    data: {
      ...card.data,
      loadedData: {
        ...card.data.loadedData,
        progress: { type: Progress.Success },
        dataset: dataset,
        statsMapsState: {
          loadProgress: { type: Progress.Success },
          loadedMapsData: mapData,
        },
      },
    },
  };
}

function cardWithDataLoadingProgress(
  card: DocCardStats,
  progress: DataLoadProgress
): DocCardStats {
  const cardWithProgress: DocCardStats = {
    ...card,
    data: {
      ...card.data,
      loadedData: {
        ...card.data.loadedData,
        progress: progress,
      },
    },
  };
  switch (card.data.selectedView) {
    case "diagram":
      cardWithProgress.data.loadedData = {
        ...cardWithProgress.data.loadedData,
        chartDataState: {
          ...cardWithProgress.data.loadedData?.chartDataState,
          loadProgress: progress,
        },
      };
      break;
    case "table":
    case "info":
      cardWithProgress.data.loadedData = {
        ...cardWithProgress.data.loadedData,
        tableDataState: {
          ...cardWithProgress.data.loadedData?.tableDataState,
          loadProgress: progress,
        },
      };
      break;
    case "map":
      cardWithProgress.data.loadedData = {
        ...cardWithProgress.data.loadedData,
        statsMapsState: {
          ...cardWithProgress.data.loadedData?.statsMapsState,
          loadProgress: progress,
        },
      };
      break;
  }

  return cardWithProgress;
}
