import React, { SVGAttributes } from "react";
import {
  BarSpec,
  BarSpecLowBase,
  BarSpecLowInvalidChoice,
  BarSpecRegular,
} from "../../../lib/application/stats/shared/bar_chart/bar_chart_common";
import {
  getDefaultTextStyleChartValueLabel,
  LOw_BASE_FONT_COLOR,
} from "../../../lib/application/stats/shared/core/TextStyle";
import { defined } from "../../../lib/core/defined";
import { SURVEY_LOW_BASE_LABEL } from "../../../lib/domain/measure/definitions";
import { SvgDropShadowText } from "./text/SvgDropShadowText";

interface InvalidOrLowBase {
  bar: { key: string };
  label: {
    elementProps: {
      textAnchor: string;
      x: number;
      y: number;
    };
  };
}

export interface BarAndLabelProps {
  focused?: boolean;
  onMouseEnter?: React.MouseEventHandler<SVGElement>;
  onMouseLeave?: React.MouseEventHandler<SVGElement>;
  bar: BarSpec;
  fontColor?: string | null;
  renderSettingsInvalidChoice: (
    bar: BarSpecLowInvalidChoice
  ) => InvalidOrLowBase;
  renderSettingsLowBase: (bar: BarSpecLowBase) => InvalidOrLowBase;
  renderSettingsRegular: (bar: BarSpecRegular) => {
    bar: {
      key: string;
      className: string;
      x: number;
      y: number;
      width: number;
      height: number;
      fill: string;
    };
    label?: {
      text: string;
      elementProps: {
        dominantBaseline?: SVGAttributes<any>["dominantBaseline"];
        textAnchor: string;
        x: number;
        y: number;
      };
      shadow: boolean;
    };
  };
}

/**
 * Renders a bar chart bar, or if the given bar spec indicates this value represents
 * a missing value, a label is rendered in the appropriate position for the bar that
 * would otherwise have been rendered
 */
export function BarWithoutLabel(props: BarAndLabelProps): JSX.Element {
  const { bar, renderSettingsRegular, renderSettingsLowBase } = props;
  if (bar.type === "regular") {
    const settings = renderSettingsRegular(bar);
    return (
      <rect
        opacity={props.focused ? 0.8 : undefined}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        {...settings.bar}
        shapeRendering="crispEdges"
      ></rect>
    );
  } else if (bar.type === "low_base") {
    const settings = renderSettingsLowBase(bar);
    return (
      <text
        {...getDefaultTextStyleChartValueLabel({
          desiredSize: 9,
        }).svgFontAttrs()}
        {...settings.label.elementProps}
        dominantBaseline="mathematical"
        fill={LOw_BASE_FONT_COLOR}
      >
        {SURVEY_LOW_BASE_LABEL}
      </text>
    );
  } else if (bar.type === "invalid_choice") {
    // For bar charts we currently mark data points that are invalid choices,
    // but there's a chance we will need to do so when it comes to survey versions
    // with different possible answers.
    //     const settings = renderSettingsInvalidChoice(bar);
    return <React.Fragment></React.Fragment>;
  } else {
    throw new Error("Invalid bar type");
  }
}

/**
 * Renders a label for a given bar
 */
export function BarLabel(props: BarAndLabelProps): JSX.Element {
  const { bar, renderSettingsRegular } = props;
  if (bar.type === "regular") {
    const settings = renderSettingsRegular(bar);
    return (
      <React.Fragment>
        {defined(settings.label) &&
          (settings.label.shadow ? (
            <SvgDropShadowText
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              fill="white"
              {...settings.label.elementProps}
            >
              {settings.label.text}
            </SvgDropShadowText>
          ) : (
            <text
              onMouseEnter={props.onMouseEnter}
              onMouseLeave={props.onMouseLeave}
              {...settings.label.elementProps}
            >
              {settings.label.text}
            </text>
          ))}
      </React.Fragment>
    );
  } else if (bar.type === "low_base") {
    return <React.Fragment></React.Fragment>;
  } else if (bar.type === "invalid_choice") {
    return <React.Fragment></React.Fragment>;
  } else {
    throw new Error("Invalid bar type");
  }
}
