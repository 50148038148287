import { flatten } from "lodash";
import { useContext } from "react";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

import { Button } from "../../../components/Button";
import {
  GeographiesContext,
  ShowDraftDataContext,
} from "../../../lib/application/contexts";
import { useToggle } from "../../../lib/application/hooks/useToggle";
import { useLoadStatsMeasureAsNewCardSingleDoc } from "../../../lib/application/state/actions/load/measure";
import { useChangePrimarySelection } from "../../../lib/application/state/actions/selections/useChangePrimarySelection";
import { GeoSelections } from "../../../lib/application/state/stats/document-core/core";
import { docCardsListQuery } from "../../../lib/application/state/stats/document-core/docCardsListState";
import {
  getGeoSelections,
  singleDataCardQuery,
  supportedGeographiesQuery,
} from "../../../lib/application/state/stats/document-core/queries/dataCard";
import { defined } from "../../../lib/core/defined";
import { truncate } from "../../../lib/core/truncate";
import { GeographiesSerializable } from "../../../lib/domain/geography";
import {
  measureSelectionIsRegular,
  measureSelectionIsSurvey,
  measureSelectionIsSurveyString,
} from "../../../lib/domain/measure";
import { MeasureSelection } from "../../../lib/domain/selections/definitions";
import { TimeSelect } from "../../stats/docs/cards/card_general/TimeSelect";
import { DataOutput } from "../../stats/docs/cards/data_card/DataOutput";
import { MeasureSelectionComponentRegular } from "../../stats/docs/cards/data_card/selections/MeasureSelectionComponentRegular";
import { MeasureSelectionComponentSurvey } from "../../stats/docs/cards/data_card/selections/MeasureSelectionComponentSurvey";
import { DataCardGeoModal } from "../../stats/docs/cards/card_general/DataCardGeoModal";
import {
  useActivateLockToLatestTime,
  useChangeTime,
  useUnsetLockToLatestTime,
} from "../../../lib/application/state/actions/selections/useChangeTime";

/** Data preview for stats/survey */
export function DataPreviewWithLoading(props: {
  measureId: number;
  showDraftData: boolean;
}) {
  const loadMeasure = useLoadStatsMeasureAsNewCardSingleDoc(
    props.showDraftData
  );
  const geographies = useContext(GeographiesContext);
  const cardsList = useRecoilValue(docCardsListQuery);

  useEffect(() => {
    if (!defined(geographies)) {
      return;
    }
    loadMeasure(props.measureId, geographies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geographies, props.measureId]);

  const card = cardsList[0];
  if (!defined(geographies) || !defined(card)) {
    return null;
  }

  return (
    <PreviewStatsInner
      cardId={card.id}
      geographies={geographies}
    ></PreviewStatsInner>
  );
}

function PreviewStatsInner(props: {
  cardId: string;
  geographies: GeographiesSerializable;
}) {
  const { cardId, geographies } = props;
  const card = useRecoilValue(singleDataCardQuery({ cardStateId: cardId }));
  const dataSelection =
    card.type === "dataCard" ? card.data.dataSelections[0] : undefined;
  const [geoModalOpen, toggleGeoModalOpen] = useToggle(false);
  const adminShowDraftData = useContext(ShowDraftDataContext);

  const { handleChangePrimarySelection } = useChangePrimarySelection(
    card.id,
    geographies
  );

  const handleChangeTime = useChangeTime(card);
  const handleUnsetLockToLatestTime = useUnsetLockToLatestTime(
    card,
    geographies,
    adminShowDraftData
  );
  const handleActivateLockToLatestTime = useActivateLockToLatestTime(
    card,
    geographies,
    adminShowDraftData
  );
  const queryProps = { cardStateId: card.id };
  const supportedGeographies = useRecoilValue(
    supportedGeographiesQuery(queryProps)
  );
  const geoSelections = getGeoSelections(card, geographies);
  const geoSelectionString: string = listSelections(geoSelections);
  const onlyCountrySupported =
    supportedGeographies.length === 1 && supportedGeographies[0] === "country";
  const showGeo = !onlyCountrySupported;
  const measureSelection = dataSelection?.measureSelection;
  return (
    // Reuse the same styles as for embedded cards
    <div className="embedded-card-container">
      {measureSelection && measureSelectionIsRegular(measureSelection) && (
        <MeasureSelectionComponentRegular
          hideMeasure
          isGroupingSelection={false}
          measureSelection={measureSelection}
          setMeasureSelection={(measureSelection: MeasureSelection) => {
            handleChangePrimarySelection({
              ...dataSelection,
              measureSelection,
            });
          }}
        ></MeasureSelectionComponentRegular>
      )}
      {measureSelection &&
        (measureSelectionIsSurvey(measureSelection) ||
          measureSelectionIsSurveyString(measureSelection)) && (
          <MeasureSelectionComponentSurvey
            adminPreviewMode={true}
            isGroupingSelection={false}
            measureSelection={measureSelection}
            setMeasureSelection={(measureSelection) =>
              handleChangePrimarySelection({
                ...dataSelection,
                measureSelection,
              })
            }
          ></MeasureSelectionComponentSurvey>
        )}
      <TimeSelect
        setLockToLatestTime={handleActivateLockToLatestTime}
        appMode="embedded"
        cardStateId={card.id}
        unsetLockToLatestTime={handleUnsetLockToLatestTime}
        updateCardStateWithTimeRange={handleChangeTime}
      ></TimeSelect>

      {showGeo && (
        <div className="simple-geo-display">
          <p>
            <strong>Valda geografiska områden</strong>: {geoSelectionString}
          </p>{" "}
          <Button small title="Ändra" onClick={toggleGeoModalOpen}></Button>
        </div>
      )}

      {geoModalOpen && (
        <DataCardGeoModal
          cardStateId={card.id}
          geographies={geographies}
          supportedGeographies={supportedGeographies}
          primarySelection={dataSelection}
          handleClose={toggleGeoModalOpen}
        ></DataCardGeoModal>
      )}
      <DataOutput
        cardStateId={card.id}
        geographies={geographies}
        isEditing={false}
      ></DataOutput>
    </div>
  );
}

function listSelections(selection: GeoSelections): string {
  const maxLen = 30;
  const entries = flatten(Object.values(selection));
  if (entries.length > 3) {
    return (
      entries
        .slice(0, 3)
        .map((e) => e.label)
        .join(", ")
        .slice(0, maxLen) + "..."
    );
  }
  return truncate(entries.map((item) => item.label).join(", "), maxLen);
}
