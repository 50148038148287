import { Record, String as StringRT } from "runtypes";
import { config } from "../../../../config";

import { decodedAuthedRequest } from "../shared";

export function adminGetAIAnalysis(
  tableData: string,
) {
  return decodedAuthedRequest(
    config.apis.statsV1,
    "/admin/ai/summary",
    { table_data: tableData },
    "POST",
    Record({ result: StringRT })
  );
}
