import { svgAsDataUri } from "save-svg-as-png";
import * as htmlToImage from "html-to-image";

import { saveSvgAsPng, svgAsPngUri } from "./save_to_svg_lib";
import { mimeTypeUtf8 } from "../../core/mime";
import { logger } from "../../infra/logging";
import { isSafari } from "../browser/detection";
import { Timer } from "../../core/Timer";
import html2canvas from "html2canvas";
import { downloadBlob } from "../browser/downloadBlob";
import { defined } from "../../core/defined";

export function saveSvg(svgElement: HTMLElement, filename: string) {
  const svgData = svgElement.outerHTML;
  const xmlPreface = '<?xml version="1.0" standalone="no"?>\n';
  const svgBlob = new Blob([xmlPreface, svgData], {
    type: mimeTypeUtf8("svg"),
  });
  downloadBlob(svgBlob, filename);
}

const svgSaveOptions = {
  encoderOptions: 1,
  scale: 3,
  fonts: [
    {
      url: "/fonts/CircularXXWeb-Medium.woff2",
      format: "font/woff",
      text: `@font-face {
                  font-family: "Circular-Medium";
                  src: url("/fonts/CircularXXWeb-Medium.woff2") format("woff2");
                }`,
    },
    {
      url: "/fonts/CircularXXWeb-Medium.woff2",
      format: "font/woff",
      text: `@font-face {
                  font-family: "Circular-Regular";
                  src: url("/fonts/CircularXXWeb-Medium.woff2") format("woff2");
                  font-weight: bold;
                }`,
    },
    {
      url: "/fonts/CircularXXWeb-Regular.woff2",
      format: "font/woff",
      text: `@font-face {
                  font-family: "Circular-Regular";
                  src: url("/fonts/CircularXXWeb-Regular.woff2") format("woff2");
                  font-weight: normal;
                }`,
    },
  ],
};

export function getSvgAsSvgDataUri(svgElement: Element): Promise<string> {
  return svgAsDataUri(svgElement, svgSaveOptions);
}
export function getSvgAsPngDataUri(
  svgElement: Element,
  backgroundColor?: string
): Promise<string | undefined> {
  const optionsWithColor: any = { ...svgSaveOptions, scale: 2 };
  if (defined(backgroundColor)) {
    optionsWithColor.backgroundColor = backgroundColor;
  }
  return svgAsPngUri(svgElement, optionsWithColor);
}

export function htmlToPngUri(element: HTMLElement): Promise<string> {
  return html2canvas(element).then((canvas) => {
    return canvas.toDataURL("image/png", 1.0);
  });
}

export function saveHtmlToPng(element: HTMLElement, filename: string) {
  return html2canvas(element).then((canvas) => {
    const dataUri = canvas.toDataURL("image/png", 1.0);
    const downloadLink = document.createElement("a");
    downloadLink.href = dataUri;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up
    document.body.removeChild(downloadLink);
  });
}

export async function saveHtmlAsDataUri(
  htmlElement: HTMLElement
): Promise<string> {
  return htmlToImage.toSvg(htmlElement, {
    width: Math.min(window.innerWidth * 0.8, htmlElement.clientWidth),
    // For now, just avoids trying to fetch MS web fonts that are not available.
    // Does not actually use our specified fonts.
    fontEmbedCSS: svgSaveOptions.fonts.map((f) => f.text).join("\n"),
  });
}

export function saveAsPng(
  element: Element | null,
  filename: string,
  backgroundColor?: string
) {
  if (element === null) {
    const message = "attempted to saveAsPng with null element as target";
    logger.error(message);
    return Promise.reject(message);
  }

  const optionsWithColor = { ...svgSaveOptions, backgroundColor };

  if (isSafari()) {
    const timer = new Timer();
    return svgAsPngUri(element, optionsWithColor).then(() => {
      logger.debug("Safari svg step 1, elapsed ms: " + timer.elapsedMs());
      return saveSvgAsPng(element, filename, optionsWithColor).then(() => {
        logger.debug("Safari svg step 2, elapsed ms: " + timer.elapsedMs());
      });
    });
  }

  return saveSvgAsPng(element, filename, {
    ...optionsWithColor,
    backgroundColor,
  });
}
