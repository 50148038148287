import { useCallback } from "react";
import {
  GeoType,
  REGION_ITEM_SWEDEN,
  emptyGeoSelections,
} from "../../../../domain/geography";
import { GeoSelections } from "../../stats/document-core/core";

export function useResetGeoSelection(
  supportedGeographies: GeoType[],
  setSelections: (nextSelection: GeoSelections) => void,
  setExpansions: (expansions: string[]) => void
) {
  const handleReset = useCallback(() => {
    setExpansions([]);
    if (supportedGeographies.includes("country")) {
      setSelections({
        ...emptyGeoSelections(),
        country: [REGION_ITEM_SWEDEN],
      });
    } else {
      setSelections(emptyGeoSelections());
    }
  }, [setExpansions, setSelections, supportedGeographies]);

  return handleReset;
}
