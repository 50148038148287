import { defined } from "../../../../../core/defined";
import {
  Comparator,
  invertComparator,
  SortOrder,
} from "../../../../../core/order";
import { TableColumn, TableRow } from "./table_base_definitions";

export interface DataTableSortSetting {
  columnIndex: number;
  order: SortOrder;
}

export type ColumnRow = {
  dimension: string;
  columns: TableColumn[];
};

export function applySortOrder(
  unsortedRows: readonly TableRow[],
  row: ColumnRow,
  sortSetting: DataTableSortSetting
): readonly TableRow[] {
  const columnIndex = sortSetting.columnIndex;
  const comparatorAsc = row.columns[columnIndex]?.comparatorAscending;
  if (!defined(comparatorAsc)) {
    return unsortedRows;
  }

  const comparator =
    sortSetting.order === SortOrder.ascending
      ? comparatorAsc
      : invertComparator(comparatorAsc);

  const unsortableRows: TableRow[] = [];
  const sortableRows: { sortBy: string; row: TableRow }[] = [];
  for (const row of unsortedRows) {
    const cell = row.values[columnIndex];
    if (!defined(cell)) {
      unsortableRows.push(row);
      continue;
    }
    cell.match({
      ok: (ok) => sortableRows.push({ sortBy: ok.value, row }),
      nan: () => unsortableRows.push(row),
    });
  }

  return sortableRows
    .sort((left, right) => comparator(left.sortBy, right.sortBy))
    .map((r) => r.row)
    .concat(unsortableRows);
}

export function applySortOrderPrimary(
  unsortedRows: readonly TableRow[],
  sortOrder: SortOrder,
  comparatorAscending: Comparator<string>
): readonly TableRow[] {
  return unsortedRows.slice().sort((left, right) => {
    const cmp = comparatorAscending(left.label, right.label);
    return sortOrder === SortOrder.ascending ? cmp : -cmp;
  });
}
