import React from "react";

import { Either } from "../../lib/core/func/monad";

type RemoveItemField<Type> = {
  [Property in keyof Type as Exclude<Property, "item">]: Type[Property];
};

/**
 * Create a wrapper component that will render the left or right component depending on the result of the Either.
 */
export function eitherDisplayer<
  Left,
  Right,
  OriginalProps extends { item: Right }
>(
  LeftComponent: React.FunctionComponent<{ left: Left }>,
  RightComponent: React.FunctionComponent<OriginalProps>
) {
  type PropsWithEither = RemoveItemField<OriginalProps> & {
    item: Either<Left, Right>;
  };

  return (
    props: PropsWithEither
  ): ReturnType<typeof LeftComponent> | ReturnType<typeof RightComponent> => {
    return props.item.fold(
      (left) => <LeftComponent left={left} />,
      (right) => <RightComponent {...(props as OriginalProps)} item={right} />
    );
  };
}
