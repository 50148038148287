export function replaceInArrayImmut<T>(
  originalArray: T[],
  predicate: (item: T, index: number) => boolean,
  updater: (old: T) => T
): T[] {
  const copy = originalArray.slice(); // Work on copy rather than mutating original
  const index = copy.findIndex(predicate);
  if (index < 0) {
    return originalArray;
  }

  const newItem = updater(copy[index]);
  copy.splice(index, 1, newItem);

  return copy;
}

export function replacePropertyImmut<O, K extends keyof O>(
  obj: O,
  property: K,
  updater: (old: O[K]) => O[K]
): O {
  return { ...obj, [property]: updater(obj[property]) };
}

/**
 * Immutably calculate a new list, where item at index is replaced.
 */
export function replaceAtIndexImmut<T>(list: T[], index: number, item: T): T[] {
  const copy = list.slice();
  copy.splice(index, 1, item);
  return copy;
}

/**
 * Create a new array from the given one, but with item inserted at the specified index.
 */
export function insertIntoArrayImmut<T>(
  list: T[],
  index: number,
  item: T
): T[] {
  const copy = list.slice();
  copy.splice(index, 0, item);
  return copy;
}

/**
 * Flip a boolean value
 */
export function toggleStatePropertyImmut<
  S extends { [key: string]: unknown },
  SB extends {
    [k in keyof S as S[k] extends boolean ? k : never]: S[k];
  },
  P extends keyof SB
>(state: S, property: P): S {
  return { ...state, [property]: !state[property as any] };
}
