import * as _ from "lodash";

import { defined } from "../../../core/defined";
import {
  StatsDatasetResponseDto,
  DataValueTypeAny,
  RowValueRegularRaw,
} from "../../../infra/api_responses/dataset";
import { nonEmptyString } from "../../../core/nonEmptyString";
import { Dimension } from "../shared/core/definitions";
import { getDateFormatter, TimeResolution } from "../../../domain/time";
import {
  getSourceInfoGroupingHeader,
  getSourceInfoMainHeader,
  processLongDescription,
} from "../shared/charts_common";
import { dateRangeFromRawUtc } from "../../../domain/measure";
import { GeoType } from "../../../domain/geography";
import {
  DataDescriptionRegular,
  getApplicableDimensionHeader,
} from "../shared/DataDescription";
import {
  dateStringComparatorAsc,
  utcTimeStringToDate,
} from "../../../core/time";
import { datasetToTable } from "./table/table_regular";
import { DatasetInput, MainHeaderData } from "./headers";
import {
  RowBaseInterface,
  RowForecast,
  RowRawRegular,
  RowRegular,
} from "../shared/row";
import { ChartDataRegular } from "../shared/chart_data/ChartDataRegular";
import {
  DatasetGeneric,
  DatasetOutputType,
  DataSourceInfo,
  DetailedDimensionInfo,
  LabelsByDimension,
  LabelsWithIdsByDimension,
  ValuesByDimensionAndLabel,
} from "./DatasetGeneric";
import { dataSettingsEqual } from "../../state/stats/document-core/eq";
import { NarrowedDataset } from "./NarrowedDataset";
import { DEFAULT_NUM_SIGNIFICANT_FIGURES, maxNumDecimals } from "../format";
import {
  DateRangeRaw,
  DimensionValueV2Dto,
  MeasureSelection,
  MeasureSelectionRegular,
} from "../../../domain/measure/definitions";
import { getHierarchicalDimensionGroups } from "../shared/dimensions";
import { chain, clone, fromPairs, range, uniq } from "lodash";
import { displaySingleRegion, makeDatasetKey } from "./shared";
import {
  DimColumnAndValues,
  getAllValidCombinations,
} from "./table/table_base/table_dimensions";
import { ChartData } from "../shared/chart_data/ChartData";
import { PackagedDatasetDto } from "../../state/stats/packaged-doc/types";
import { last } from "../../../core/last";
import { shortenMunicipalityLabel } from "../../../domain/names";
import { DataOutputSettings } from "../../state/stats/document-core/DataOutputSettings";
import { TableSpec } from "./table/table_base/table_base_definitions";

/**
 * The set of data corresponding to the server response given for a single data request
 * for measure(s) data with given settings
 */
export class StatsDataset
  implements
    DatasetGeneric<RowValueRegularRaw, RowBaseInterface<RowValueRegularRaw>>
{
  private _key: string;
  private _timeResolution: TimeResolution;
  private _regionTimeString: string | undefined;
  private _dateFormatter: (input: string) => string;
  private _dateDistanceGetter: (first: string, last: string) => number;
  private _actualTimespan: DateRangeRaw;
  private _datasetInput: DatasetInput<RowRegular, MainHeaderData>;
  private _copyWithSettings: (s: DataOutputSettings) => StatsDataset;
  private _primaryMeasureSelection: MeasureSelection;
  private _numDecimals: number | undefined;
  private _labelsByDimension: LabelsByDimension;
  private _supportsDecimalModes: boolean;
  private _processedDataDto: StatsDatasetResponseDto;
  private _valuesByDimensionAndLabel: ValuesByDimensionAndLabel;
  private _valuesByDimensionAndLabelUnfiltered:
    | ValuesByDimensionAndLabel
    | undefined;

  constructor(
    private _dataDto: StatsDatasetResponseDto,
    private _measureSelections: MeasureSelection[],
    private _includedGeoTypes: GeoType[],
    private _selectedGeocodes: string[],
    private _singleSelectedGeoLabel: string | undefined,
    private _settings: DataOutputSettings
  ) {
    const primaryMeasureSelection = this._measureSelections[0] as
      | MeasureSelection
      | undefined;
    if (!defined(primaryMeasureSelection)) {
      throw new Error("No measure selection, cannot create ChartData");
    }
    const processedData = processDataWithSettings(
      _dataDto,
      primaryMeasureSelection,
      _settings,
      true
    );
    const processedDataDto = processedData.dto;
    // Processes data again without applying filters only if there actually are filters
    const valuesByDimensionAndLabelUnfiltered =
      _settings.hiddenBreakdownCombinations.length > 0
        ? processDataWithSettings(
            _dataDto,
            primaryMeasureSelection,
            _settings,
            false
          ).valuesByDimensionAndLabel
        : undefined;
    const valuesByDimensionAndLabel = processedData.valuesByDimensionAndLabel;

    const mainHeaderData = new MainHeaderData(processedDataDto.header);

    const liftedDate = processedDataDto.header.lifted?.[Dimension.date];
    const actualDates = defined(liftedDate)
      ? [liftedDate]
      : _.chain(processedDataDto.rows)
          .map((r) => r[Dimension.date])
          .uniq()
          .sort(dateStringComparatorAsc)
          .value();
    const actualStart = actualDates[0];
    const actualEnd = _.last(actualDates) ?? actualStart;
    const actualTimespan: DateRangeRaw = [actualStart, actualEnd];
    const supportsDecimalModes =
      processedDataDto.header.value_type === "decimal";

    const key = makeDatasetKey(
      primaryMeasureSelection,
      this._measureSelections[1] as MeasureSelectionRegular | undefined,
      actualTimespan,
      _includedGeoTypes,
      _selectedGeocodes
    );

    const resolution = TimeResolution.deserialize(
      primaryMeasureSelection.measure.resolution
    );

    const numDecimalsOriginalMeasure = supportsDecimalModes
      ? _settings.fixedNumDecimals !== null
        ? _settings.fixedNumDecimals
        : maxNumDecimals(
            _dataDto.rows
              ?.map((r) => {
                const raw = r[Dimension.value];
                if (!defined(raw)) {
                  return;
                }
                return parseFloat(raw);
              })
              .filter(defined) ?? [],
            DEFAULT_NUM_SIGNIFICANT_FIGURES
          )
      : undefined;

    const dateResolutionFormatter = getDateFormatter(resolution);
    const formatDate = (input: string) =>
      dateResolutionFormatter(new Date(input));
    const singleTimePeriod =
      actualTimespan[0] === actualTimespan[1]
        ? formatDate(actualTimespan[0])
        : undefined;
    const singleRegion = displaySingleRegion(
      _singleSelectedGeoLabel,
      processedDataDto.header.lifted
    );

    const dateDistanceGetter = (first: string, last: string) => {
      const range = dateRangeFromRawUtc([first, last]);
      return resolution.distance(range[0], range[1]);
    };

    const makeRow = (r: RowRawRegular) => {
      let numDecimals = numDecimalsOriginalMeasure;

      return new RowRegular(r, mainHeaderData, {
        numDecimals,
        shortenMunicipalityLabels: true,
      });
    };
    const makeRowForecast = (r: RowRawRegular) => {
      return new RowForecast(r, mainHeaderData, {
        numDecimals: numDecimalsOriginalMeasure,
        shortenMunicipalityLabels: true,
      });
    };
    const rowsFinal: RowRegular[] = processedDataDto.rows?.map(makeRow) ?? [];
    const forecastRows: RowForecast[] =
      processedDataDto.forecastRows?.map(makeRowForecast) ?? [];

    const datasetInput = DatasetInput.fromRegularDto(
      processedDataDto,
      rowsFinal,
      forecastRows,
      [],
      numDecimalsOriginalMeasure
    );

    if (this._measureSelections.length > 2) {
      throw new Error("More than 2 measure selections not supported");
    }
    const regionTimeString = [singleRegion, singleTimePeriod]
      .filter(defined)
      .join(", ");

    this._copyWithSettings = (s: DataOutputSettings): StatsDataset => {
      if (dataSettingsEqual(s, this._settings)) {
        return this;
      }
      return new StatsDataset(
        _dataDto,
        this._measureSelections,
        this._includedGeoTypes,
        this._selectedGeocodes,
        this._singleSelectedGeoLabel,
        s
      );
    };

    this._processedDataDto = processedDataDto;
    this._supportsDecimalModes = supportsDecimalModes;
    this._labelsByDimension = Object.entries(valuesByDimensionAndLabel).map(
      ([dimension, values]) => {
        return [dimension, Object.keys(values)];
      }
    );
    this._valuesByDimensionAndLabel = valuesByDimensionAndLabel;
    this._valuesByDimensionAndLabelUnfiltered =
      valuesByDimensionAndLabelUnfiltered;
    this._numDecimals = numDecimalsOriginalMeasure;
    this._primaryMeasureSelection = primaryMeasureSelection;
    this._datasetInput = datasetInput;
    this._actualTimespan = actualTimespan;
    this._dateDistanceGetter = dateDistanceGetter;
    this._dateFormatter = formatDate;
    this._regionTimeString = regionTimeString;
    this._timeResolution = resolution;
    this._key = key;
  }

  /** Expose rows for testing purposes */
  _rowsForTest() {
    return this._datasetInput.rows;
  }

  private _dimensionToLabel(
    description: DataDescriptionRegular,
    dimension: Dimension
  ): string | undefined {
    if (dimension === Dimension.date) {
      return "År";
    }
    return description.dimensionToLabel(dimension);
  }

  private _dataDescription(): DataDescriptionRegular {
    let fcastRange: [string, string] | null = null;

    const forecastDates =
      this._datasetInput.forecastRows?.map(
        (r) => r.dimension(Dimension.date) as string
      ) ?? [];
    const min = _.minBy(forecastDates, (d) => utcTimeStringToDate(d).getTime());
    const max = _.maxBy(forecastDates, (d) => utcTimeStringToDate(d).getTime());
    if (defined(min) && defined(max)) {
      fcastRange = [min, max].map(this._dateFormatter) as [string, string];
    } else {
      fcastRange = null;
    }

    const excelExportSubheader =
      fcastRange === null
        ? undefined
        : `Med trendframskrivning för perioden ${fcastRange[0]}-${fcastRange[1]}`;

    const datasetInput = this._datasetInput;
    return new DataDescriptionRegular(
      { header: datasetInput.header, groupHeader: datasetInput.groupHeader },
      nonEmptyString(this._regionTimeString)
        ? this._regionTimeString
        : undefined,
      this._measureSelections,
      this._settings,
      { excelExportSubheader }
    );
  }

  private _nonPrimaryDataDimensions(): string[] {
    return this._datasetInput.dimensionsSpec.variable.filter(
      (d) => d !== Dimension.value
    );
  }

  private _eligibleDimensionsComputedVariable(): string[] {
    const spec = this._datasetInput.dimensionsSpec;
    const hierarchicalDimensionGroups = getHierarchicalDimensionGroups(
      this._dataDto.header.dimensions,
      this._primaryMeasureSelection.measure.dimensions
    );
    const dims = spec.variable
      .concat(Object.keys(spec.liftedDimensions ?? {}))
      .filter(
        (d) => ![Dimension.value, Dimension.date].includes(d as Dimension)
      )
      .filter((d) => {
        // Exclude tree dimensions unless it's the last one -- we boil down all tree dimensions to a single one
        return hierarchicalDimensionGroups.every(
          (group) => !group.includes(d) || d === last(group)
        );
      });

    return dims;
  }

  get key(): string {
    return this._key;
  }

  get outputType(): DatasetOutputType {
    return this._datasetInput.header.valueType === "category"
      ? "text"
      : "numeric";
  }

  get supportsDecimalModes(): boolean {
    return this._supportsDecimalModes;
  }

  get canMakeComputedValue(): boolean {
    return this.outputType === "numeric";
  }

  nonPrimaryDataDimensions(): string[] {
    return this._nonPrimaryDataDimensions();
  }

  labelsByDimension(): LabelsByDimension {
    return this._labelsByDimension;
  }

  labelsWithIdsByDimension(): LabelsWithIdsByDimension {
    return Object.entries(
      this._valuesByDimensionAndLabelUnfiltered ??
        this._valuesByDimensionAndLabel
    ).map(([dimension, values]) => {
      return [
        dimension,
        Object.entries(values).map(([label, value]) => {
          return {
            text: label,
            id: value?.id,
          };
        }),
      ];
    });
  }

  eligibleDimensionsComputedVariable(): string[] {
    return this._eligibleDimensionsComputedVariable();
  }

  canChangeDimensionOrder(): boolean {
    return this._nonPrimaryDataDimensions().length > 1;
  }

  copyWithSettings(settings: DataOutputSettings): StatsDataset {
    return this._copyWithSettings(settings);
  }

  convertForMapChart(mapChartsLimit: number): {
    limitReached: boolean;
    sets: NarrowedDataset[];
  } {
    const current = this._datasetInput;
    const dimensionsToFlatten = current.dimensionsSpec.variable.filter(
      (d) => ![Dimension.value, Dimension.region].includes(d as Dimension)
    );

    const description = this._dataDescription();
    const dimSpecs = this._primaryMeasureSelection.measure.dimensions;
    const dimensionsAndValues: DimColumnAndValues[] = _.chain(
      dimensionsToFlatten
    )
      .map((dim) => {
        const dimSpec = dimSpecs.find((d) => d.data_column === dim);
        const labels = Object.keys(
          _.groupBy(
            current.rows.filter((r) => defined(r.dimension(dim))),
            (r) => r.dimension(dim)
          )
        );

        return !defined(dimSpec)
          ? labels.map((l) => [dim, l] as [string, string])
          : labels.map((label) => {
              const found = dimSpec.values?.find((v) => v.label === label);
              if (!defined(found)) {
                // If the value is a computed value, it won't be in the dimension spec
                return [dim, label] as [string, string];
              }
              return [dim, found] as [string, DimensionValueV2Dto];
            });
      })
      .value();

    const allCombinations: { dimension: string; value: string }[][] =
      dimensionsAndValues.length > 0
        ? getAllValidCombinations(dimensionsAndValues).map((combo) =>
            combo.map(([dimColumn, value]) => ({
              dimension: dimColumn,
              value: typeof value === "string" ? value : value.label,
            }))
          )
        : [
            Object.entries(current.dimensionsSpec.liftedDimensions ?? {}).map(
              ([dim, value]) => ({ value, dimension: dim })
            ),
          ];
    const showOnlyDateExtremes =
      this._settings.mapChart.showOnlyFirstAndLastDate;
    const validDateCombinations = allCombinations.filter((combo) => {
      if (!showOnlyDateExtremes) {
        return true;
      }

      // If using setting to show only first and last date,
      // filter out everything that doesn't match first/last date here
      const dateEntry = combo.find((c) => c.dimension === Dimension.date);
      if (defined(dateEntry)) {
        return this._actualTimespan.includes(dateEntry.value);
      }
      // No date dimension
      return true;
    });

    const limitedCombinations = validDateCombinations.slice(0, mapChartsLimit);
    // Sort map charts descending from upper left with respect to dates
    if (current.dimensionsSpec.variable.includes(Dimension.date)) {
      limitedCombinations.sort((left, right) => {
        const leftDate = left.find(
          (l) => l.dimension === Dimension.date
        )?.value;
        const rightDate = right.find(
          (l) => l.dimension === Dimension.date
        )?.value;
        if (defined(leftDate) && defined(rightDate)) {
          return new Date(leftDate).getTime() - new Date(rightDate).getTime();
        }
        return 0;
      });
    }

    // For each combination of "breakdown values" (including date and region),
    // create a dataset where only rows matching those values are included.
    // Also modify the header so lifted dimensions and variable dimensions
    // match the created dataset.
    return {
      limitReached: limitedCombinations.length < validDateCombinations.length,
      sets: limitedCombinations.map((combination) => {
        const datasetInput = this._datasetInput;
        const d = this._processedDataDto;
        const liftedRegion = d.header.lifted?.[Dimension.region];
        const originalLiftedDimensions = d.header.lifted;
        const originalVariableDimensions = d.header.dimensions;

        const modifiedLiftedDimensions = {
          ...originalLiftedDimensions,
          ..._.fromPairs(combination.map((c) => [c.dimension, c.value])),
        };
        const modifiedVariableDimensions = originalVariableDimensions.filter(
          (original) => !combination.find((c) => c.dimension === original)
        );
        const modifiedHeader: typeof d = {
          ...d,
          header: {
            ...d.header,
            lifted: modifiedLiftedDimensions,
            dimensions: modifiedVariableDimensions,
          },
        };
        const dataset = new StatsDataset(
          {
            ...modifiedHeader,
            rows: _.chain(datasetInput.rows)
              .filter((r) =>
                combination.every(
                  (v) =>
                    defined(originalLiftedDimensions?.[v.dimension]) ||
                    r.dimension(v.dimension) === v.value
                )
              )
              .map((r) => {
                const rawRow = fromPairs(
                  modifiedVariableDimensions
                    .map((d) => [d, r.dimension(d)])
                    .concat(Object.entries(modifiedLiftedDimensions))
                );
                return defined(liftedRegion)
                  ? { ...rawRow, [Dimension.region]: liftedRegion }
                  : rawRow;
              })
              .value(),
          },
          this._measureSelections,
          this._includedGeoTypes,
          this._selectedGeocodes,
          this._singleSelectedGeoLabel,
          this._settings
        );
        return {
          labelTexts:
            // If we have only a single combination of dimensions, it means there's
            // only a single map to show, and thus no labels per map are needed.
            allCombinations.length === 1
              ? [""]
              : combination.map((c) => {
                  const value =
                    c.dimension === Dimension.date
                      ? this._dateFormatter(c.value)
                      : c.value;
                  // If we have a single variable dimension, we don't need to show the dimension name.
                  if (combination.length === 1) {
                    return value;
                  }
                  return `${this._dimensionToLabel(
                    description,
                    c.dimension as Dimension
                  )}: ${value}`;
                }),
          dataset,
        };
      }),
    };
  }

  timeSelection(): DateRangeRaw {
    return this._actualTimespan;
  }

  timeSelectionFormatted(): [string, string] {
    const selection = this._actualTimespan;
    return [
      this._dateFormatter(selection[0]),
      this._dateFormatter(selection[1]),
    ];
  }

  /**
   * Returns the category values of this category-type Dataset.
   * Throws if value type is not category
   */
  categories(): string[] {
    if (this.primaryValueType !== "category") {
      throw new Error("Attempting to get categories of non-category dataset");
    }
    return _.chain(this._datasetInput.rows)
      .map((r) => r.dimension(Dimension.value))
      .uniq()
      .value();
  }

  get singleSelectedGeoType(): GeoType | undefined {
    return this._includedGeoTypes.length === 1
      ? this._includedGeoTypes[0]
      : undefined;
  }

  chartData(): ChartData<
    RowValueRegularRaw,
    RowBaseInterface<RowValueRegularRaw>
  > {
    const data = this._datasetInput as DatasetInput<
      RowBaseInterface<RowValueRegularRaw>,
      MainHeaderData
    >;

    return ChartDataRegular.fromStatsDataset<
      RowBaseInterface<RowValueRegularRaw>
    >(
      data,
      this._labelsByDimension,
      this._dateFormatter,
      this._dateDistanceGetter,
      this._measureSelections,
      this._dataDescription(),
      this._settings
    );
  }

  dataDescription(): DataDescriptionRegular {
    return this._dataDescription();
  }

  get aggregationNote(): string | undefined {
    return this._datasetInput.header.aggregationNote;
  }

  internalLabel(): string | undefined {
    return this._primaryMeasureSelection.measure.label;
  }

  infostatPublicComment(): string[] {
    return processLongDescription(
      this._primaryMeasureSelection.measure.public_comment
    );
  }

  get sourceInfo(): DataSourceInfo {
    return getSourceInfoMainHeader(this._datasetInput.header);
  }

  get groupingSourceInfo(): DataSourceInfo | undefined {
    const groupingHeader = this._datasetInput.groupHeader;
    if (!defined(groupingHeader)) {
      return undefined;
    }
    return getSourceInfoGroupingHeader(groupingHeader);
  }

  get primaryValueType(): DataValueTypeAny {
    return this._datasetInput.header.valueType;
  }

  isChartable(): boolean {
    const rawData = this._datasetInput;
    if (rawData.header.valueType === "category") {
      return false;
    }
    return rawData.rows.length > 0;
  }

  packDatasetForPublication(): PackagedDatasetDto {
    return {
      type: "stats",
      data: this._dataDto,
      settings: this._settings,
      measureSelections: this._measureSelections,
      includedGeoTypes: this._includedGeoTypes,
      selectedGeocodes: this._selectedGeocodes,
      singleSelectedGeoLabel: this._singleSelectedGeoLabel,
    };
  }

  table(): TableSpec {
    const datasetInput = this._datasetInput;
    const dataDescription = this._dataDescription();

    const getDimensionHeader = (dimension: string) => {
      return getApplicableDimensionHeader(
        dimension,
        this._settings,
        dataDescription
      );
    };

    const primarySelection = this._measureSelections[0];
    return datasetToTable(
      datasetInput,
      dataDescription,
      datasetInput.dimensionsSpec.variable.slice(1),
      this._dateFormatter,
      primarySelection.measure.dimensions,
      getDimensionHeader,
      this._valuesByDimensionAndLabel,
      this._settings
    );
  }
}

function makeLabel(parts: string[]): string {
  if (parts.length <= 1) {
    return parts[0];
  }
  return `${parts[0]} (${parts.slice(1).join(", ")})`;
}

function processDataWithSettings(
  dataRaw: StatsDatasetResponseDto,
  primaryMeasureSelection: MeasureSelection,
  settings: DataOutputSettings,
  applyFilters: boolean
): {
  dto: StatsDatasetResponseDto;
  valuesByDimensionAndLabel: ValuesByDimensionAndLabel;
} {
  const data = { ...dataRaw };
  const currentRows = data.rows?.slice() ?? [];

  // Sort order by dimension and label
  const valuesByDimensionAndLabel: ValuesByDimensionAndLabel = {};

  const selectedDimValuesAll: DetailedDimensionInfo[] = Object.keys(
    data.header.lifted ?? {}
  )
    .concat(data.header.dimensions)
    .map((d) => {
      const dimInfo = primaryMeasureSelection.measure.dimensions.find(
        (md) => md.data_column === d
      );
      if (!defined(dimInfo)) {
        return undefined;
      }
      const breakdown = primaryMeasureSelection.breakdowns[d];
      if (!defined(breakdown)) {
        return undefined;
      }
      return {
        dataColumn: d,
        dimensionId: dimInfo.dimension_id,
        parentId: dimInfo.parent_id ?? undefined,
        values: dimInfo.values?.filter((v) => breakdown.includes(v.id)) ?? [],
      };
    })
    .filter(defined);

  // ---- START tree data processing
  const hierarchicalDimensionGroups = getHierarchicalDimensionGroups(
    data.header.dimensions,
    primaryMeasureSelection.measure.dimensions
  );
  for (const treeGroup of hierarchicalDimensionGroups) {
    processTreeDataMut(
      treeGroup,
      data,
      currentRows,
      selectedDimValuesAll,
      valuesByDimensionAndLabel
    );
  }
  // ---- END tree data processing

  const filteredRows =
    settings.hiddenBreakdownCombinations.length > 0 && applyFilters
      ? currentRows.filter(
          (r) =>
            // If any of the hidden combinations match, filter out the row
            !settings.hiddenBreakdownCombinations.some((combo) =>
              // All values in the combination must match
              combo.every(
                (part) => readRowValue(r, part.dimension) === part.value
              )
            )
        )
      : currentRows;

  // Adjust variable/lifted dimensions accordingly

  const dimToValues: { [key: string]: Set<string | number> } = {};
  const originalDataDimensions = [
    ...data.header.dimensions,
    ...Object.keys(data.header.lifted ?? {}),
  ].filter((d) => d !== Dimension.value);

  for (const r of filteredRows) {
    for (const dim of originalDataDimensions) {
      if (!dimToValues[dim]) {
        dimToValues[dim] = new Set<string | number>();
      }
      if (defined(r[dim])) {
        dimToValues[dim].add(readRowValue(r, dim) as string | number);
      }
    }
  }

  const header = {
    ...data.header,
    lifted: { ...data.header.lifted },
    dimensions: [...data.header.dimensions],
  };

  for (const d of originalDataDimensions) {
    const values = dimToValues[d];
    if (!defined(values)) {
      delete header.lifted[d];
      header.dimensions = header.dimensions.filter((dim) => dim !== d);
      continue;
    }

    if (values.size === 1) {
      const value = values.values().next().value;
      header.lifted[d] = value as string;
      header.dimensions = header.dimensions.filter((dim) => dim !== d);
    } else if (values.size > 1) {
      delete header.lifted[d];
      header.dimensions = _.uniq([...header.dimensions, d]);
    }
  }

  const regularDataDims = header.dimensions.filter(
    (dim) =>
      dim !== Dimension.value &&
      !hierarchicalDimensionGroups.some((g) => g.includes(dim))
  );

  for (const d of regularDataDims) {
    valuesByDimensionAndLabel[d] = {};
    for (const label of Array.from(dimToValues[d])) {
      valuesByDimensionAndLabel[d][label.toString()] ??= selectedDimValuesAll
        .find((v) => v.dataColumn === d)
        ?.values.find((v) => v.label === label.toString());
    }
  }

  return {
    dto: { ...data, rows: filteredRows, header },
    valuesByDimensionAndLabel,
  };
}

function readRowValue(
  row: NonNullable<StatsDatasetResponseDto["rows"]>[number],
  dim: string
) {
  if (dim === Dimension.region) {
    return shortenMunicipalityLabel(row[dim]);
  }
  return row[dim];
}

function findDimValue(
  chains: DimensionValueV2Dto[][],
  parts: string[]
): DimensionValueV2Dto | undefined {
  for (const chain of chains) {
    if (chain.map((v) => v.label).join() === parts.join()) {
      return last(chain);
    }
  }
  return undefined;
}

function processTreeDataMut(
  treeGroup: readonly string[],
  dataMut: StatsDatasetResponseDto,
  currentRowsMut: RowRawRegular[],
  selectedDimValuesAllMut: DetailedDimensionInfo[],
  valuesByDimensionAndLabelMut: ValuesByDimensionAndLabel
) {
  // If we have a tree  group of something like breakdown1, breakdown2, breakdown3
  // then we completely remove all traces of all but breakdown3
  const excludedDims = treeGroup.slice(0, -1);
  const includedDim = treeGroup[treeGroup.length - 1];

  dataMut.header = {
    ...dataMut.header,
    dimensions: chain(dataMut.header.dimensions)
      // .concat(selectedDimValuesAllMut.map((d) => d.dim))
      // .uniq()
      .filter((dim) => !excludedDims.includes(dim))
      .value(),
  };
  const labelChains: { [rowIndex: number]: string[] } = {};
  for (let i = 0; i < currentRowsMut.length; i++) {
    const rowCopy = clone(currentRowsMut[i]);
    labelChains[i] = treeGroup.map(
      (dim) => rowCopy[dim] ?? dataMut.header.lifted?.[dim]
    );
    for (const dim of excludedDims) {
      delete rowCopy[dim];
    }
    currentRowsMut[i] = rowCopy;
  }

  // Sort order for values in this tree group
  // Filter out all values that are not currently selected in order
  // so that we can match current labels against currently selected values
  const selectedDimValues: DetailedDimensionInfo[] = treeGroup
    .map((d) =>
      selectedDimValuesAllMut.find(
        (selectedDim) => selectedDim.dataColumn === d
      )
    )
    .filter(defined);
  let chains: DimensionValueV2Dto[][] = [];
  for (const dimInfo of selectedDimValues) {
    if (chains.length === 0) {
      chains.push(...dimInfo.values.map((v) => [v]));
      continue;
    }

    const extendedChains = [];
    for (let i = 0; i < chains.length; i++) {
      const chain = chains[i];
      for (const value of dimInfo.values) {
        if (last(chain)?.id === value.parent_id) {
          extendedChains.push([...chain, value]);
        }
      }
    }
    chains = extendedChains;
  }

  valuesByDimensionAndLabelMut[includedDim] = {};

  // Deduplicate values, one dimension at a time, using label parts according to indexDedupOrder
  // until all values are unique

  const indexDedupOrder = [
    treeGroup.length - 1,
    ...range(0, treeGroup.length - 1).reverse(),
  ];
  for (let i = 1; i < indexDedupOrder.length + 1; i++) {
    const usedIndexes = indexDedupOrder.slice(0, i);
    const groups = _.groupBy(
      Object.entries(labelChains),
      ([rowIndex, value]) => {
        return makeLabel(usedIndexes.map((valueIndex) => value[valueIndex]));
      }
    );
    for (const [key, items] of Object.entries(groups)) {
      // a) If all items in this group are identical, we can safely use the key
      // as the label for all of them
      if (uniq(items.map((item) => item[1].join())).length === 1) {
        for (const [rowIndex, parts] of items) {
          const dimValue = findDimValue(chains, parts);
          currentRowsMut[parseInt(rowIndex)][includedDim] = key;
          valuesByDimensionAndLabelMut[includedDim][key] = dimValue;
          delete labelChains[parseInt(rowIndex)];
        }
      }

      // b) If not, do nothing. We will continue processing next iteration.
    }
  }
}
