import { config } from "../../config";
import { documentPath, pathJoin } from "../paths";

export enum DocLink {
  SharingHelp,
}

const docIdsProd: { [key in DocLink]: number } = {
  [DocLink.SharingHelp]: 1248,
};

const docIdsDev: { [key in DocLink]: number } = {
  [DocLink.SharingHelp]: -1,
};

function getDocId(link: DocLink): number | undefined {
  if (config.appEnv === "prod") {
    return docIdsProd[link];
  } else if (config.appEnv === "dev" || config.appEnv === "local") {
    return docIdsDev[link];
  }
}

export const DocLinksHandler = {
  getLinkPath: (link: DocLink) => {
    const documentId = getDocId(link) ?? -1; // -1 is a placeholder for invalid docIds, results in Document not found page
    return pathJoin("https://", config.sharingDomain, documentPath(documentId));
  },
};
