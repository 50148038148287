import { useCallback, useContext, useMemo, useState } from "react";

import { useToggle } from "../../../../../lib/application/hooks/useToggle";
import { useReadMicroCardState } from "../../../../../lib/application/state/actions/micro/updates";
import {
  CustomRegion,
  MicroGeoSelections,
  StoredRegions,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { useRecoilState } from "recoil";
import { singleMicroCardQuery } from "../../../../../lib/application/state/stats/document-core/queries/microCard";
import { defined } from "../../../../../lib/core/defined";
import { useSaveCard } from "../../../../../lib/application/state/actions/useSaveDocument";
import { loadAndStoreDataMicro } from "../../../../../lib/application/state/stats/cardToDataStateMicro";
import {
  CardUpdateCountContext,
  ShowDraftDataContext,
} from "../../../../../lib/application/contexts";
import { cardColors } from "../../../../../lib/application/state/stats/document-core/queries/shared";
import { useGeoTree } from "./useGeoTree";
import { useExtendedAppearanceSettings } from "../../../../../lib/application/state/actions/useExtendedAppearanceSettings";

export function useGeoState(cardId: string) {
  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: cardId })
  );
  const selectedAreas = useMemo(
    () => card.data.geoSelections,
    [card.data.geoSelections]
  );
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const handleSaveCard = useSaveCard();
  const geoTree = useGeoTree();
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const readMicroCard = useReadMicroCardState(cardId);
  const appearanceSettings = useExtendedAppearanceSettings();

  const setSelectedAreas = useCallback(
    async (
      updater: (s: MicroGeoSelections | undefined) => MicroGeoSelections
    ) => {
      const currentCardState = readMicroCard();
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const updatedGeos = updater(currentCardState.data.geoSelections);
      const updatedCard = {
        ...currentCardState,
        data: { ...currentCardState.data, geoSelections: updatedGeos },
      };
      if (!defined(geoTree)) {
        return;
      }

      loadAndStoreDataMicro(
        updatedCard,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedCard),
        handleSaveCard
      );
    },
    [
      adminShowDraftData,
      appearanceSettings,
      geoTree,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      readMicroCard,
      setCard,
    ]
  );

  const [userDefinedRegions, setUserDefinedRegions] = useState<CustomRegion[]>(
    []
  );

  const storedRegions: StoredRegions = useMemo(() => {
    return {
      numTotalAvailable: 0,
      infostatPredefined: [],
      userDefined: [],
    };
  }, []);

  const userDefinedRegionsToggle = useToggle(false);
  const storedRegionsToggle = useToggle(false);

  const addUserDefinedRegion = useCallback(
    (region: CustomRegion) => {
      setUserDefinedRegions([...userDefinedRegions, region]);
    },
    [userDefinedRegions]
  );

  const removeUserDefinedRegion = useCallback(
    (regionId: string) => {
      setUserDefinedRegions(
        userDefinedRegions.filter((r) => r.id !== regionId)
      );
      if (
        selectedAreas?.selected.some(
          (area) => area.type === "user-defined" && area.groupId === regionId
        )
      ) {
        setSelectedAreas((prev) => {
          if (!defined(prev)) {
            throw new Error("Expected previous state");
          }
          if (prev.type === "deso") {
            return {
              type: prev.type,
              selected: prev.selected.filter(
                (area) =>
                  area.type !== "user-defined" || area.groupId !== regionId
              ),
            } as MicroGeoSelections;
          } else if (prev.type === "regso") {
            return {
              type: prev.type,
              selected: prev.selected.filter(
                (area) =>
                  area.type !== "user-defined" || area.groupId !== regionId
              ),
            } as MicroGeoSelections;
          }
          return prev;
        });
      }
    },
    [selectedAreas, setSelectedAreas, userDefinedRegions]
  );

  const handleEditUserDefinedRegionName = useCallback(
    (regionId: string, name: string) => {
      setUserDefinedRegions(
        userDefinedRegions.map((r) => {
          return r.id === regionId ? { ...r, name } : r;
        })
      );
      setSelectedAreas((prev) => {
        if (!defined(prev)) {
          throw new Error("Expected previous state");
        }
        if (prev.type === "deso") {
          return {
            type: "deso",
            selected: prev.selected.map((area) =>
              area.type === "deso"
                ? area
                : area.groupId === regionId
                ? { ...area, groupName: name }
                : area
            ),
          };
        } else if (prev.type === "regso") {
          return {
            type: "regso",
            selected: prev.selected.map((area) =>
              area.type === "regso"
                ? area
                : area.groupId === regionId
                ? { ...area, groupName: name }
                : area
            ),
          };
        }
        return prev;
      });
    },
    [setSelectedAreas, userDefinedRegions]
  );

  return useMemo(
    () => ({
      selectedAreas,
      setSelectedAreas,
      storedRegions,
      storedRegionsToggle,
      userDefinedRegions,
      addUserDefinedRegion,
      removeUserDefinedRegion,
      handleEditUserDefinedRegionName,
      userDefinedRegionsToggle,
    }),
    [
      addUserDefinedRegion,
      handleEditUserDefinedRegionName,
      removeUserDefinedRegion,
      selectedAreas,
      setSelectedAreas,
      storedRegions,
      storedRegionsToggle,
      userDefinedRegions,
      userDefinedRegionsToggle,
    ]
  );
}

export type GeoState = ReturnType<typeof useGeoState>;
