import _ from "lodash";

import { defined } from "../../../../core/defined";
import { MicroReferenceType } from "../../../state/stats/document-core/core";
import { getFormatter, getRounder, maxNumDecimals } from "../../format";
import { getSourceInfoMicro } from "../../shared/charts_common";
import { DataDescription } from "../../shared/DataDescription";
import { anyDimensionLabelComparatorAsc } from "../../shared/dimensions";
import { RowMicroReference } from "../../shared/row";
import { DataCellValue } from "../DataCellValue";
import { DatasetInputMicro } from "../headers";
import { TableMicroSettings } from "./TableMicroSettings";
import {
  getComputedValueOutputSettings,
  getDimensionFormatter,
  getSummaryRows,
  getSuperColumnRows,
  getValueComparatorAscending,
  joinDimensions,
  LabelAndDimension,
} from "./table_base/table_base";
import { DimensionV2Dto } from "../../../../domain/measure/definitions";
import { setupDimensionsAndVariants } from "./table_base/table_dimensions";
import { calcRows } from "./table_base/calc_rows";
import { chartMicroSettingsLabels } from "../../shared/micro_labels";
import { MicroGeoTree } from "../../shared/MicroGeoTree";
import { AggregationMethod } from "../../../../infra/api_responses/dataset";
import { ValuesByDimensionAndLabel } from "../DatasetGeneric";
import {
  DataOutputSettings,
  DataTableMicroSettings,
} from "../../../state/stats/document-core/DataOutputSettings";
import {
  SummaryTableRow,
  SuperColumnsRow,
  TableRow,
  TableSpec,
} from "./table_base/table_base_definitions";
import { applySortOrder, applySortOrderPrimary } from "./table_base/sort";
import { SortOrder, sortOrderEnum } from "../../../../core/order";

const EMPTY_ARRAY: any[] = [];

export function microDatasetToTable(
  dataset: DatasetInputMicro,
  dataDescription: DataDescription,
  microGeoTree: MicroGeoTree | undefined,
  /**
   * All the dimensions that specify the data but not the value (range)
   */
  nonValueDataDimensions: string[],
  dateFormatter: (input: string) => string,
  dimensions: DimensionV2Dto[],
  getDimensionHeader: (dimension: string) => string | undefined,
  valuesByDimensionAndLabel: ValuesByDimensionAndLabel,
  settings: DataOutputSettings
): TableSpec {
  const microSettings = new TableMicroSettings(settings.tableMicro);
  const computedValueOutputSettings = getComputedValueOutputSettings(
    dataset.header.valueType,
    settings
  );

  const header = dataset.header;
  const rows = dataset.rows;
  const isSingleValue = nonValueDataDimensions.length === 0;
  const dimensionFormatter = getDimensionFormatter(dateFormatter);

  const { rowDimension, rowKeys, nonPrimaryDimensions, variantCombinations } =
    setupDimensionsAndVariants(
      rows,
      valuesByDimensionAndLabel,
      dimensionFormatter,
      dimensions,
      nonValueDataDimensions,
      settings,
      microSettings
    );

  const primaryDimensionHeader = defined(rowDimension)
    ? getDimensionHeader(rowDimension)
    : undefined;
  const primaryComparatorAscending = defined(rowDimension)
    ? anyDimensionLabelComparatorAsc(rowDimension)
    : undefined;

  function rowKey(row: { dimension: (dim: string) => string }) {
    return joinDimensions(nonPrimaryDimensions.map((d) => row.dimension(d)));
  }

  const groupedRows = _.groupBy(rows, rowKey);
  const groupedReferenceRows = _.groupBy<RowMicroReference>(
    dataset.microReferenceRows ?? [],
    rowKey
  );

  const valueType = header.valueType;
  const comparatorAscending = getValueComparatorAscending(valueType);
  const numDecimals =
    valueType === "decimal"
      ? settings.fixedNumDecimals !== null
        ? settings.fixedNumDecimals
        : maxNumDecimals(rows.map((r) => r.range()))
      : undefined;
  const valueFormatter = getFormatter(valueType, numDecimals);
  const valueRounder = getRounder(valueType, numDecimals);
  const commonColumnFields = {
    type: valueType,
    format: valueFormatter,
    comparatorAscending,
    round: valueRounder,
  };

  const columnRows: SuperColumnsRow[] = getSuperColumnRows(
    variantCombinations,
    dimensionFormatter
  );
  const singleGeoCode = rows[0]?.singleGeocode();

  const tableRows: TableRow[] = microSettings.showReferenceValuesOnly()
    ? EMPTY_ARRAY
    : calcRows(
        rowKeys,
        rowDimension,
        isSingleValue,
        rows,
        groupedRows,
        header.liftedDimensions,
        variantCombinations,
        dimensionFormatter,
        dateFormatter,
        () => false, // TODO: Forecasts not supported for micro
        computedValueOutputSettings
      );

  const hasLowBase = false;
  const lastColumnRow = _.last(columnRows);
  const primaryDimensionInfo = defined(rowDimension)
    ? {
        dimension: rowDimension,
        header: primaryDimensionHeader,
        comparatorAscending: primaryComparatorAscending,
      }
    : undefined;

  const columnSort = settings.table.columnSort;
  const sortSetting =
    columnSort?.type === "secondary"
      ? {
          columnIndex: columnSort.columnIndex,
          order: sortOrderEnum(columnSort.order),
        }
      : undefined;
  const columnRow = defined(lastColumnRow)
    ? {
        dimension: lastColumnRow.dimension,
        columns: lastColumnRow.columns.map((column) => ({
          ...column,
          ...commonColumnFields,
        })),
      }
    : {
        dimension: "",
        columns: [
          // If no regular columns, we only have a single column of values. Use the unit as header.
          {
            text: dataDescription.unitHeader ?? "--",
            key: "single-col-key",
            ...commonColumnFields,
          },
        ],
      };
  const primaryComparator = primaryDimensionInfo?.comparatorAscending;
  const primarySortOrder: SortOrder | undefined =
    columnSort?.type === "primary"
      ? sortOrderEnum(columnSort.order)
      : undefined;
  const sortedRows = defined(sortSetting)
    ? applySortOrder(tableRows, columnRow, sortSetting)
    : defined(primarySortOrder) && defined(primaryComparator)
    ? applySortOrderPrimary(tableRows, primarySortOrder, primaryComparator)
    : tableRows;
  return {
    primaryDimensionInfo,
    allDimensions: dataset.dimensionsSpec.variable,
    dimensionsString: `rows: ${rows.length}, cols: ${columnRows.length}`,
    hasLowBaseValue: hasLowBase,
    valueType,
    tableDescription: dataDescription,
    sourceInfo: getSourceInfoMicro(header),
    customSourceText: settings.customSourceText,
    header: {
      superColumnRows: columnRows.slice(0, columnRows.length - 1),
      columnRow,
    },
    rows: sortedRows,
    summaryRows: getSummaryRows(
      settings.table,
      tableRows,
      valueRounder,
      valueFormatter
    ),
    referenceRows: getReferenceRowsMicro(
      settings.tableMicro,
      groupedReferenceRows,
      dataset.microReferenceRows,
      variantCombinations,
      microGeoTree,
      singleGeoCode,
      valueRounder,
      valueFormatter,
      dataset.aggregationMethodGeo
    ),
  };
}

export function getReferenceRowsMicro(
  settings: DataTableMicroSettings,
  groupedReferenceRows: _.Dictionary<RowMicroReference[]>,
  referenceRows: RowMicroReference[] | undefined,
  variantCombinations: LabelAndDimension[][],
  microGeoTree: MicroGeoTree | undefined,
  singleGeocode: string | undefined,
  valueRounder: ((value: string) => number) | undefined,
  valueFormatter: (value: string) => string,
  aggMethodGeo: AggregationMethod
): SummaryTableRow[] | undefined {
  if (!defined(referenceRows) || referenceRows.length === 0) {
    return;
  }

  const formatters = {
    format: (inputNumber: number) => valueFormatter(inputNumber.toString()),
    roundForExport: !defined(valueRounder)
      ? undefined
      : (inputNumber: number) => valueRounder(inputNumber.toString()),
  };

  const getValues = (
    rows: RowMicroReference[],
    getValue: (r: RowMicroReference) => number | undefined
  ) => {
    return variantCombinations.length === 0
      ? [renderMicroReferenceRow(rows[0], getValue)]
      : variantCombinations.map<DataCellValue<number>>((combo) => {
          const key = joinDimensions(combo.map((c) => c.label));
          const found = groupedReferenceRows[key]?.[0];
          if (defined(found)) {
            return renderMicroReferenceRow(found, getValue);
          }
          return DataCellValue.nan("no_value");
        });
  };

  const getLabel = (refType: MicroReferenceType) =>
    chartMicroSettingsLabels(
      refType,
      singleGeocode,
      microGeoTree,
      aggMethodGeo
    );

  const rows: SummaryTableRow[] = [];
  if (settings.showCountryReference) {
    rows.push({
      ...formatters,
      label: getLabel("showCountryReference"),
      values: getValues(referenceRows, (r) => r.country()),
    });
  }
  if (settings.showNuts3Reference) {
    rows.push({
      ...formatters,
      label: getLabel("showNuts3Reference"),
      values: getValues(referenceRows, (r) => r.nuts3()),
    });
  }
  if (settings.showMunicipalityReference) {
    rows.push({
      ...formatters,
      label: getLabel("showMunicipalityReference"),
      values: getValues(referenceRows, (r) => r.municipality()),
    });
  }
  if (settings.showSelectedAreasAverage) {
    rows.push({
      ...formatters,
      label: getLabel("showSelectedAreasAverage"),
      values: getValues(referenceRows, (r) => r.selectedAreasAverage()),
    });
  }

  return rows;
}

function renderMicroReferenceRow(
  found: RowMicroReference | undefined,
  getValue: (r: RowMicroReference) => number | undefined
): DataCellValue<number> {
  if (!defined(found)) {
    return DataCellValue.nan("no_value");
  }
  const value = getValue(found);
  if (defined(value)) {
    return DataCellValue.ok(value);
  }

  return DataCellValue.nan("no_value");
}
