import * as _ from "lodash";

import { defined } from "../../../core/defined";
import { MeasureSelectionRegular } from "../../../domain/selections/definitions";
import { nonEmptyString } from "../../../core/nonEmptyString";
import { Dimension } from "../shared/core/definitions";
import { getDateFormatter, TimeResolution } from "../../../domain/time";
import {
  defaultDimensionLabelComparatorAsc,
  getHierarchicalDimensionGroups,
  groupingSortSpec,
  surveyDataDimensions,
  surveyDataDimensionsSorted,
  surveyDataDimensionsWithoutRange,
  surveyDataStandardDimensions,
} from "../shared/dimensions";
import {
  getSourceInfoGroupingHeader,
  getSourceInfoSurveyHeader,
  processLongDescription,
} from "../shared/charts_common";
import {
  dateRangeFromRawUtc,
  getMeasureSelectionResponseDimensionsSurvey,
  SortSpec,
} from "../../../domain/measure";
import { GeoType } from "../../../domain/geography";
import {
  DataDescriptionSurvey,
  getApplicableDimensionHeader,
} from "../shared/DataDescription";
import { dateStringComparatorAsc } from "../../../core/time";
import {
  DatasetHeaderSurvey,
  DatasetInput,
  DimensionsSpec,
  GroupHeaderData,
  MainHeaderData,
} from "./headers";
import { RowRawSurvey, SurveyRow } from "../shared/row";
import { ChartDataSurvey } from "../shared/chart_data/ChartDataSurvey";
import {
  DatasetGeneric,
  DatasetOutputType,
  DataSourceInfo,
  LabelsByDimension,
  LabelsWithIdsByDimension,
} from "./DatasetGeneric";
import {
  SurveyDatasetDto,
  SurveyRowRaw,
  SurveyRowValueRaw,
} from "../../../infra/api_responses/survey_dataset";
import { dataSettingsEqual } from "../../state/stats/document-core/eq";
import {
  BREAKDOWN_ALL_LABEL,
  DateRangeRaw,
  MeasureSelectionSurvey,
} from "../../../domain/measure/definitions";
import { dimensionsToSortSpecs, displaySingleRegion } from "./shared";
import { surveyDatasetToTable } from "./table/table_survey";
import { ChartDataUnknown } from "../shared/chart_data/ChartData";
import { PackagedDatasetDto } from "../../state/stats/packaged-doc/types";
import { last } from "../../../core/last";
import { DataOutputSettings } from "../../state/stats/document-core/DataOutputSettings";
import { TableSpec } from "./table/table_base/table_base_definitions";

/**
 * The set of data corresponding to the server response given for a single data request
 * for measure(s) data with given settings
 */
export class SurveyDataset
  implements DatasetGeneric<SurveyRowValueRaw, SurveyRow>
{
  private _key: string;
  private _regionTimeString: string | undefined;
  private _dateFormatter: (input: string) => string;
  private _dateDistanceGetter: (first: string, last: string) => number;
  private _actualTimespan: DateRangeRaw;
  private _datasetInput: DatasetInput<SurveyRow, DatasetHeaderSurvey>;
  private _dataDescription: () => DataDescriptionSurvey;
  private _header: DatasetHeaderSurvey;
  private _dimensions: DimensionsSpec;
  private _dubiousBaseDimensions: string[];
  private _baseCountsTotals: { [key: string]: number };
  private _baseCountsOverview: { [key: string]: number };
  private _baseCountsDetailed: { [key: string]: number };
  private _copyWithSetting: (s: DataOutputSettings) => SurveyDataset;
  private _labelsByDimension: LabelsByDimension;
  private _labelsWithIdsByDimension: LabelsWithIdsByDimension;

  constructor(
    private _dataDto: SurveyDatasetDto,
    private _primaryMeasureSelection: MeasureSelectionSurvey,
    private _groupingMeasureSelection: MeasureSelectionRegular | undefined,
    private _settings: DataOutputSettings
  ) {
    const forceIntegerRounding = !_settings.showSurveyValueFraction;

    const processedDataDto = processDataWithSettings(_dataDto, _settings, true);
    const processedDataDtoWithoutFilters =
      _settings.hiddenBreakdownCombinations.length > 0
        ? processDataWithSettings(_dataDto, _settings, false)
        : undefined;
    const mainHeaderData = new MainHeaderData(processedDataDto.header);
    // If reference lines are used, it means we are actually using non-standard dimensions
    // even if they are marked as lifted

    const dimensions = determineDimensions(
      processedDataDto.header.lifted,
      processedDataDto.header.dimensions,
      _settings.showReferenceLines
    );

    const variableBreakdownsSorted = surveyDataDimensionsSorted(
      dimensions.variable
    ).filter((d) => d !== Dimension.percentage && d !== Dimension.date);

    const rowFiller: (r: SurveyRowRaw) => SurveyRowRaw = (r) => {
      if (!_settings.showReferenceLines) {
        return r;
      }

      const copyRow = { ...r };
      for (const d of variableBreakdownsSorted) {
        copyRow[d] ??= _dataDto.header.lifted?.[d] as any;
      }
      return copyRow;
    };

    const rowFillerReference: (r: SurveyRowRaw) => SurveyRowRaw = (r) => {
      const copyRow = { ...r };
      for (const d of variableBreakdownsSorted) {
        copyRow[d] ??= BREAKDOWN_ALL_LABEL;
      }
      return copyRow;
    };

    const makeRowReference = (r: RowRawSurvey): SurveyRow =>
      new SurveyRow(rowFillerReference(r), mainHeaderData, {
        forceIntegerRounding,
      });

    const rowsFinal =
      processedDataDto.rows?.map(
        (r) =>
          new SurveyRow(rowFiller(r), mainHeaderData, {
            forceIntegerRounding,
          })
      ) ?? [];
    const rowsWithoutFilters = processedDataDtoWithoutFilters?.rows?.map(
      (r) =>
        new SurveyRow(rowFiller(r), mainHeaderData, {
          forceIntegerRounding,
        })
    );
    if (_settings.showReferenceLines) {
      const refRows = (processedDataDto.ref_rows ?? []).filter((r) => {
        if (_settings.hiddenBreakdownCombinations.length === 0) {
          return r;
        }
        return rowIsVisible(r, _settings.hiddenBreakdownCombinations);
      });
      rowsFinal.push(...(refRows.map(makeRowReference) ?? []));
      rowsWithoutFilters?.push(
        ...((processedDataDtoWithoutFilters?.ref_rows ?? []).map(
          makeRowReference
        ) ?? [])
      );
    }

    const datasetHeaderSurvey = new DatasetHeaderSurvey(
      processedDataDto.header
    );
    const datasetInput = DatasetInput.fromSurvey(
      rowsFinal,
      datasetHeaderSurvey,
      _dataDto.groupHeader,
      dimensions
    );

    const liftedDate = dimensions.liftedDimensions?.[Dimension.date];
    const actualDates = defined(liftedDate)
      ? [liftedDate]
      : _.chain(_dataDto.rows)
          .map((r) => r[Dimension.date] as string)
          .uniq()
          .sort(dateStringComparatorAsc)
          .value();
    const actualStart = actualDates[0];
    const actualEnd = _.last(actualDates) ?? actualStart;
    const actualTimespan: DateRangeRaw = [actualStart, actualEnd];

    const key = makeKey(_primaryMeasureSelection, actualTimespan, [], []);

    const primaryMeasureSelection =
      _primaryMeasureSelection as MeasureSelectionSurvey;
    const resolution = TimeResolution.deserialize(
      primaryMeasureSelection.measure.resolution
    );
    const dateFormatter = (input: string) =>
      getDateFormatter(resolution)(new Date(input));
    const singleTimePeriod =
      actualTimespan[0] === actualTimespan[1]
        ? dateFormatter(actualTimespan[0])
        : undefined;
    const singleRegion = displaySingleRegion(
      undefined,
      processedDataDto.header.lifted
    );

    const dateDistanceGetter = (first: string, last: string) => {
      const range = dateRangeFromRawUtc([first, last]);
      return resolution.distance(range[0], range[1]);
    };

    const surveyDimsExceptRange = surveyDataDimensionsWithoutRange(
      dimensions.variable.concat(Object.keys(dimensions.liftedDimensions ?? {}))
    );
    const labelsByDimension: LabelsByDimension = surveyDimsExceptRange.map(
      (dimension) => {
        return [
          dimension,
          Object.keys(
            _.groupBy(
              rowsFinal,
              (r) =>
                r.dimension(dimension) ??
                dimensions.liftedDimensions?.[dimension]
            )
          ).sort(defaultDimensionLabelComparatorAsc(dimension)), // FIXME: sort order
        ];
      }
    );

    const labelsWithIdsByDimension: LabelsWithIdsByDimension =
      surveyDimsExceptRange.map((dimension) => {
        const dimInfo = _primaryMeasureSelection.measure.dimensions.find(
          (d) => d.data_column === dimension
        );
        return [
          dimension,
          Object.keys(
            _.groupBy(
              rowsWithoutFilters ?? rowsFinal,
              (r) =>
                r.dimension(dimension) ??
                dimensions.liftedDimensions?.[dimension]
            )
          )
            .sort(defaultDimensionLabelComparatorAsc(dimension))
            .map((label) => {
              return {
                text: label,
                id: dimInfo?.values?.find((v) => v.label === label)?.id,
              };
            }),
        ];
      });

    const regionTimeString = [singleRegion, singleTimePeriod]
      .filter(defined)
      .join(", ");

    this._dataDescription = (): DataDescriptionSurvey => {
      return new DataDescriptionSurvey(
        processedDataDto,
        nonEmptyString(this._regionTimeString)
          ? this._regionTimeString
          : undefined,
        this._primaryMeasureSelection,
        this._groupingMeasureSelection,
        this._settings
      );
    };

    this._copyWithSetting = (s: DataOutputSettings): SurveyDataset => {
      if (dataSettingsEqual(s, this._settings)) {
        return this;
      }
      return new SurveyDataset(
        _dataDto,
        this._primaryMeasureSelection,
        this._groupingMeasureSelection,
        s
      );
    };

    this._labelsByDimension = labelsByDimension;
    this._labelsWithIdsByDimension = labelsWithIdsByDimension;
    this._dimensions = dimensions;
    this._header = datasetHeaderSurvey;

    this._datasetInput = datasetInput;
    this._actualTimespan = actualTimespan;
    this._dateDistanceGetter = dateDistanceGetter;
    this._dateFormatter = dateFormatter;
    this._regionTimeString = regionTimeString;
    this._key = key;
    this._dubiousBaseDimensions = _dataDto.header.dubious_base_dimensions ?? [];
    this._baseCountsTotals = _dataDto.header.base_counts_totals ?? {};
    this._baseCountsOverview = _dataDto.header.base_counts_overview ?? {};
    this._baseCountsDetailed = _dataDto.header.base_counts_detailed ?? {};
  }

  private _nonPrimaryDataDimensions(): string[] {
    return surveyDataDimensions(
      this._datasetInput.dimensionsSpec.variable
    ).filter((d) => d !== Dimension.percentage);
  }

  private _eligibleDimensionsComputedVariable(): string[] {
    const hierarchicalDimensionGroups = getHierarchicalDimensionGroups(
      this._dataDto.header.dimensions,
      this._primaryMeasureSelection.measure.dimensions
    );
    const spec = this._datasetInput.dimensionsSpec;
    return surveyDataDimensionsSorted(spec.variable)
      .concat(Object.keys(spec.liftedDimensions ?? {}))
      .filter(
        (d) => ![Dimension.percentage, Dimension.date].includes(d as Dimension)
      )
      .filter((d) => {
        // Exclude tree dimensions unless it's the last one -- we boil down all tree dimensions to a single one
        return hierarchicalDimensionGroups.every(
          (group) => !group.includes(d) || d === last(group)
        );
      });
  }

  get surveyInfo() {
    return this._header.surveyInfo;
  }

  get key(): string {
    return this._key;
  }

  get sourceInfo(): DataSourceInfo {
    return getSourceInfoSurveyHeader(this._header.main);
  }

  get groupingSourceInfo(): DataSourceInfo | undefined {
    const groupingHeader = this._dataDto.groupHeader;
    if (!defined(groupingHeader)) {
      return undefined;
    }
    return getSourceInfoGroupingHeader(new GroupHeaderData(groupingHeader));
  }

  get groupingMunicipalities() {
    return this._dataDto.groupHeader;
  }

  get outputType(): DatasetOutputType {
    return "numeric";
  }

  get supportsDecimalModes(): boolean {
    return false;
  }

  get canMakeComputedValue(): boolean {
    return this.outputType === "numeric";
  }

  nonPrimaryDataDimensions(): string[] {
    return this._nonPrimaryDataDimensions();
  }

  labelsByDimension(): LabelsByDimension {
    return this._labelsByDimension;
  }

  labelsWithIdsByDimension(): LabelsWithIdsByDimension {
    return this._labelsWithIdsByDimension;
  }

  eligibleDimensionsComputedVariable(): string[] {
    return this._eligibleDimensionsComputedVariable();
  }

  canChangeDimensionOrder(): boolean {
    return this._nonPrimaryDataDimensions().length > 1;
  }

  copyWithSettings(settings: DataOutputSettings): SurveyDataset {
    return this._copyWithSetting(settings);
  }

  isChartable(): boolean {
    return this._datasetInput.rows.length > 0;
  }

  get dubiousBaseDimensions(): string[] {
    return this._dubiousBaseDimensions;
  }

  get baseCountsTotals(): { [key: string]: number } {
    return this._baseCountsTotals;
  }

  get baseCountsOverview(): { [key: string]: number } {
    return this._baseCountsOverview;
  }

  get baseCountsDetailed(): { [key: string]: number } {
    return this._baseCountsDetailed;
  }

  /**
   * Number of dimensions that correspond to a survey filter.
   * Does not count lifted dimensions.
   */
  private _numUsedFilterDimensions(): number {
    const measure = this._primaryMeasureSelection.measure;
    const dims = measure.dimensions
      .filter((d) => d.type === "survey_background")
      .map((d) => d.data_column);

    return dims.filter((d) => {
      return (
        this._header.main.dimensions.some((f) => f === d) ||
        defined(this._header.liftedDimensions?.[d])
      );
    }).length;
  }

  /**
   * Reference values can be shown only when using at most a single filter dimension.
   * With multiple filters, charts will become messy.
   */
  canUseReferenceValues(): boolean {
    return this._numUsedFilterDimensions() < 2;
  }

  /**
   * Actual timespan of data
   */
  actualTimespan(): DateRangeRaw {
    return this._actualTimespan;
  }

  actualTimespanFormatted(): [string, string] {
    const selection = this._actualTimespan;
    return [
      this._dateFormatter(selection[0]),
      this._dateFormatter(selection[1]),
    ];
  }

  /** Not used for survey */
  get singleSelectedGeoType(): GeoType | undefined {
    return undefined;
  }

  private _makeSortSpecs() {
    const primaryMeasureSelection = this._primaryMeasureSelection;
    const sortSpecs: Record<string, SortSpec> = dimensionsToSortSpecs(
      primaryMeasureSelection.measure.dimensions
    );
    return {
      ...sortSpecs,
      [Dimension.grouping]: groupingSortSpec,
    };
  }

  internalLabel() {
    return this._primaryMeasureSelection.measure.label;
  }

  infostatPublicComment(): string[] {
    return processLongDescription(
      this._primaryMeasureSelection.measure.public_comment
    );
  }

  chartData(): ChartDataUnknown {
    const settings: DataOutputSettings =
      this.canUseReferenceValues() === false
        ? { ...this._settings, showReferenceLines: false }
        : this._settings;
    return ChartDataSurvey.fromSurveyDataset(
      this._datasetInput,
      this._labelsByDimension,
      this._dateFormatter,
      this._dateDistanceGetter,
      [this._primaryMeasureSelection],
      this._dataDescription(),
      this._makeSortSpecs(),
      settings
    );
  }

  dataDescription(): DataDescriptionSurvey {
    return this._dataDescription();
  }

  get validResponses(): { label: string; values: string[] }[] {
    const primaryMeasureSelection = this._primaryMeasureSelection;
    const responseDimensions = getMeasureSelectionResponseDimensionsSurvey(
      primaryMeasureSelection
    );

    return responseDimensions.map((d) => {
      return {
        label: d.label,
        values: d.values?.map((v) => v.label) ?? [],
      };
    });
  }

  get surveySubquestions(): { label: string; values: string[] }[] | undefined {
    const primaryMeasureSelection = this._primaryMeasureSelection;
    if (
      primaryMeasureSelection.valueType === "survey" &&
      primaryMeasureSelection.measure.survey_question_type === "multichoice"
    ) {
      return undefined;
    }

    if (primaryMeasureSelection.valueType === "survey") {
      return primaryMeasureSelection.measure.dimensions
        .filter((d) => d.type === "survey_subquestion")
        .map((d) => ({
          label: d.label,
          values: d.values?.map((v) => v.label) ?? [],
        }));
    }

    return undefined;
  }

  get questionType() {
    return this._primaryMeasureSelection.measure.survey_question_type;
  }

  packDatasetForPublication(): PackagedDatasetDto {
    return {
      type: "survey",
      data: this._dataDto,
      settings: this._settings,
      primaryMeasureSelection: this._primaryMeasureSelection,
      groupingMeasureSelection: this._groupingMeasureSelection,
    };
  }

  table(): TableSpec {
    const dataDescription = this.dataDescription();
    const settings: DataOutputSettings =
      this.canUseReferenceValues() === false
        ? { ...this._settings, showReferenceLines: false }
        : this._settings;

    const getDimensionHeader = (dimension: string) =>
      getApplicableDimensionHeader(dimension, this._settings, dataDescription);
    const measure = this._primaryMeasureSelection.measure;
    const isBackground = (dim: string) => {
      return measure.dimensions.some(
        (d) => d.data_column === dim && d.type === "survey_background"
      );
    };

    const breakdownSortSpecs = this._makeSortSpecs();
    return surveyDatasetToTable(
      this._datasetInput,
      dataDescription,
      surveyDataDimensionsSorted(this._dimensions.variable).filter(
        (d) => d !== Dimension.percentage
      ),
      this._dateFormatter,
      breakdownSortSpecs,
      settings.showReferenceLines,
      settings.showSurveyValueFraction,
      getDimensionHeader,
      this._settings,
      isBackground
    );
  }
}

function makeKey(
  _primaryMeasureSelection: MeasureSelectionSurvey,
  actualTimespan: DateRangeRaw,
  _includedGeoTypes: ("country" | "nuts1" | "nuts2" | "nuts3" | "municipal")[],
  _selectedGeocodes: string[]
) {
  return (
    _primaryMeasureSelection.measure.data_id +
    Object.values(_primaryMeasureSelection.breakdowns).join(",") +
    actualTimespan.join(",") +
    _includedGeoTypes.join(",") +
    "," +
    _selectedGeocodes.join("_")
  );
}

function determineDimensions(
  liftedDimensionsRaw: Record<string, string> | undefined,
  dimensionsRaw: string[],
  showReferenceValues: boolean
): DimensionsSpec {
  const liftedDimensions = liftedDimensionsRaw;
  if (!showReferenceValues || !defined(liftedDimensions)) {
    return {
      liftedDimensions,
      variable: dimensionsRaw,
    };
  }
  const unliftedDimensions = Object.keys(liftedDimensions).filter(
    (d) => !surveyDataStandardDimensions.includes(d as Dimension)
  );
  return {
    liftedDimensions: _.omit(liftedDimensions, unliftedDimensions),
    variable: _.uniq(dimensionsRaw.concat(unliftedDimensions)),
  };
}

function processDataWithSettings(
  data: SurveyDatasetDto,
  settings: DataOutputSettings,
  applyFilters: boolean
): SurveyDatasetDto {
  const rows = data.rows?.slice() ?? [];
  const filteredRows =
    settings.hiddenBreakdownCombinations.length > 0 && applyFilters
      ? rows.filter((r) =>
          rowIsVisible(r, settings.hiddenBreakdownCombinations)
        )
      : rows;

  // Adjust variable/lifted dimensions accordingly

  const dimToValues: { [key: string]: Set<string | number> } = {};
  const originalDataDimensions = surveyDataDimensions([
    ...data.header.dimensions,
    ...Object.keys(data.header.lifted ?? {}),
  ]).filter((d) => d !== Dimension.percentage);

  for (const r of filteredRows) {
    for (const dim of originalDataDimensions) {
      if (!dimToValues[dim]) {
        dimToValues[dim] = new Set<string | number>();
      }
      if (defined(r[dim])) {
        dimToValues[dim].add(r[dim] as string | number);
      }
    }
  }

  const header = {
    ...data.header,
    lifted: { ...data.header.lifted },
    dimensions: [...data.header.dimensions],
  };

  for (const d of originalDataDimensions) {
    const values = dimToValues[d];
    if (!defined(values)) {
      delete header.lifted[d];
      header.dimensions = header.dimensions.filter((dim) => dim !== d);
      continue;
    }

    if (values.size === 1) {
      const value = values.values().next().value;
      header.lifted[d] = value as string;
      header.dimensions = header.dimensions.filter((dim) => dim !== d);
    } else if (values.size > 1) {
      delete header.lifted[d];
      header.dimensions = _.uniq([...header.dimensions, d]);
    }
  }

  return { ...data, rows: filteredRows, header };
}

function rowIsVisible(
  row: { [key: string]: SurveyRowValueRaw },
  hiddenBreakdownCombinations: {
    dimension: string;
    value: string;
  }[][]
) {
  // If any of the hidden combinations match, filter out the row
  return !hiddenBreakdownCombinations.some((combo) =>
    // All values in the combination must match
    combo.every((part) => row[part.dimension] === part.value)
  );
}
