import { DataOutputSettings } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";
import { DataDescription } from "../../../../../lib/application/stats/shared/DataDescription";
import { defined } from "../../../../../lib/core/defined";

interface Props {
  dataDescription: DataDescription;
  settings: DataOutputSettings;
}
export function DataHeader(props: Props) {
  const hideHeaders = props.settings.hideChartTitleSection;
  const description = props.dataDescription;
  const customSubtitles = props.settings.customSubtitles;
  const customUnitText = props.settings.customUnitText;
  const useCustomSubheaders =
    defined(customSubtitles) && customSubtitles.length > 0;
  if (hideHeaders) {
    return (
      <div className="data-header">
        {description.unitHeader && (
          <p className="unit">{description.unitHeader}</p>
        )}
      </div>
    );
  }
  const {
    customMainHeaderSize,
    customSubHeaderSmallSize,
    customSubHeaderLargeSize,
    customUnitSize,
  } = props.settings;

  const singleSubquestions = description.single_subquestions;
  const unitText = customUnitText ?? description.unitHeader;
  return (
    <div className="data-header">
      <h2 style={{ fontSize: customMainHeaderSize ?? undefined }}>
        {description.header}
      </h2>
      {useCustomSubheaders ? (
        customSubtitles?.map((text) => (
          <h4
            style={{ fontSize: customSubHeaderSmallSize ?? undefined }}
            key={text}
            className="subheader"
          >
            {text}
          </h4>
        ))
      ) : (
        <>
          {description.primaryMeasureSubheaders.map((text) => (
            <h4
              style={{ fontSize: customSubHeaderSmallSize ?? undefined }}
              key={text}
              className="subheader"
            >
              {text}
            </h4>
          ))}
          {description.groupingHeader && (
            <div className="header-section">
              {description.groupingHeader.map((header) => (
                <h4
                  style={{ fontSize: customSubHeaderSmallSize ?? undefined }}
                  key={header}
                  className="subheader grouping"
                >
                  {header}
                </h4>
              ))}
            </div>
          )}
          {description.regionAndTimeHeader && (
            <h4
              style={{ fontSize: customSubHeaderSmallSize ?? undefined }}
              className="region-and-time"
            >
              {description.regionAndTimeHeader}
            </h4>
          )}

          {singleSubquestions.length > 0 && (
            <div
              style={{ fontSize: customSubHeaderSmallSize ?? undefined }}
              className="margin-top-sm"
            >
              {singleSubquestions.map((subquestion) => (
                <h3>{subquestion}</h3>
              ))}
            </div>
          )}
          {description.surveyResponseSingleChoice && (
            <h4
              style={{ fontSize: customSubHeaderLargeSize ?? undefined }}
              className="subheader"
            >
              {description.surveyResponseSingleChoice}
            </h4>
          )}
        </>
      )}

      {unitText && (
        <p style={{ fontSize: customUnitSize ?? undefined }} className="unit">
          {unitText}
        </p>
      )}
    </div>
  );
}
