import React, { useState, useEffect, ReactNode } from "react";
import ReactDOM from "react-dom";

interface IframeContentProps {
  children: ReactNode;
  title: string;
}

export const IframeContentPreview: React.FC<IframeContentProps> = ({
  children,
  title,
}) => {
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (iframeRef) {
      const iframeDoc = iframeRef.contentDocument;
      if (iframeDoc) {
        // Create a clean HTML structure
        iframeDoc.open();
        iframeDoc.write("<html><head></head><body></body></html>");
        iframeDoc.close();

        // Copy all stylesheets from the parent document to the iframe
        Array.from(document.styleSheets).forEach((styleSheet) => {
          if (styleSheet.href) {
            const link = iframeDoc.createElement("link");
            link.rel = "stylesheet";
            link.href = styleSheet.href;
            iframeDoc.head.appendChild(link);
          } else if (styleSheet.cssRules && styleSheet.cssRules.length > 0) {
            const style = iframeDoc.createElement("style");
            Array.from(styleSheet.cssRules).forEach((rule) => {
              style.appendChild(iframeDoc.createTextNode(rule.cssText));
            });
            iframeDoc.head.appendChild(style);
          }
        });
      }
    }
  }, [iframeRef]);

  const mountNode = iframeRef?.contentWindow?.document?.body;

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <iframe
        title={title}
        ref={setIframeRef}
        style={{ border: "none", width: "100%", height: "100%" }}
      >
        {mountNode && ReactDOM.createPortal(children, mountNode)}
      </iframe>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "transparent",
          pointerEvents: "auto",
        }}
        onClick={(e) => e.preventDefault()}
      />
    </div>
  );
};
