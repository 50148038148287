import { config } from "../../../config";
import { omitUndefined } from "../../core/object";
import { HttpResult } from "../../infra/HttpResult";
import {
  CreatePackagedDocResult,
  CreatePackagedDocResultRT,
} from "../../infra/api_responses/packaged_docs";
import { PackagedDocumentDto } from "../state/stats/packaged-doc/types";
import { WorkspaceLatest } from "../state/stats/workspace/shared";
import { decodedAuthedRequest } from "./shared";

export function createPackagedDocument(
  releaseId: string,
  title: string,
  workspace: WorkspaceLatest,
  packagedDocumentData: PackagedDocumentDto,
  vanityPath?: string
): Promise<HttpResult<CreatePackagedDocResult>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `packageddocument`,
    omitUndefined({
      release_id: releaseId,
      title: title,
      document: { title, workspace },
      data: packagedDocumentData,
      vanity_path: vanityPath,
    }),
    "POST",
    CreatePackagedDocResultRT
  );
}
