import {
  AlertRegistrationsDto,
  MeasureAlertStatusDto,
  SingleAlertRegistrationDto,
} from "../infra/api_responses/alerts";

export class AlertRegistrations {
  private _registrations: SingleAlertRegistration[];
  constructor(private _dto: AlertRegistrationsDto) {
    this._registrations = _dto?.map(dtoToDomain) ?? [];
  }

  measureRegistration(measureId: number): SingleAlertRegistration | undefined {
    return this._registrations.find(
      (r) => r.type === "measure" && r.measureId === measureId
    );
  }

  documentRegistration(
    documentId: number
  ): SingleAlertRegistrationDocument | undefined {
    return this._registrations
      .filter(isDocument)
      .find((r) => r.documentId === documentId);
  }

  listMeasure(): SingleAlertRegistrationMeasure[] {
    return this._registrations.filter(isMeasure);
  }

  listDocument(): SingleAlertRegistrationDocument[] {
    return this._registrations.filter(isDocument);
  }

  list(): SingleAlertRegistration[] {
    return this._dto?.map(dtoToDomain) ?? [];
  }
}

export type SingleAlertRegistrationMeasure = {
  type: "measure";
  id: number;
  measureId: number;
  area: string;
  subarea: string;
  subject: string;
  measure: string;
  measureDescription: string;
};

export type SingleAlertRegistrationDocument = {
  type: "document";
  id: number;
  documentId: number;
  documentTitle: string;
  lastUpdated: Date;
};

export type SingleAlertRegistration =
  | SingleAlertRegistrationMeasure
  | SingleAlertRegistrationDocument;

function isMeasure(
  union: SingleAlertRegistration
): union is SingleAlertRegistrationMeasure {
  return union.type === "measure";
}

function isDocument(
  union: SingleAlertRegistration
): union is SingleAlertRegistrationDocument {
  return union.type === "document";
}

function dtoToDomain(dto: SingleAlertRegistrationDto): SingleAlertRegistration {
  if (dto.type === "measure") {
    return {
      type: dto.type,
      id: dto.alert_registration_id,
      measureId: dto.data_id,
      area: dto.area,
      subarea: dto.subarea,
      subject: dto.subject,
      measure: dto.measure,
      measureDescription: dto.descr_long,
    };
  }
  return {
    type: dto.type,
    id: dto.alert_registration_id,
    documentId: dto.node_id,
    documentTitle: dto.title,
    lastUpdated: new Date(dto.last_updated),
  };
}

export class MeasureAlertStatus {
  constructor(private dto: MeasureAlertStatusDto) {}

  get dataId(): number {
    return this.dto.data_id;
  }

  get isScheduled(): boolean {
    return this.dto.scheduled;
  }
}
