import { chain } from "lodash";
import {
  Array as ArrayRT,
  String as StringRT,
  Number as NumberRT,
} from "runtypes";

import { defined } from "../../../core/defined";
import { utcTimeStringToDate } from "../../../core/time";
import { isBreakdownTotal } from "../../../domain/measure";
import { PrimaryMeasureSelectionMicroFull } from "../../../domain/measure/definitions";
import {
  DesoResultData,
  MicroResultsType,
  RegsoResultData,
} from "../../../domain/micro/definitions";
import { DesoRegsoLookup } from "../../../domain/micro/desoLookup";
import { TimeResolution, getDateFormatter } from "../../../domain/time";
import { MicroDatasetDto } from "../../../infra/api_responses/micro_dataset";
import { integerFormatter, numSignificantFiguresRounder } from "../format";
import { computedMeasureSubDescription } from "../shared/DataDescription";
import { MicroGeoTree } from "../shared/MicroGeoTree";
import { Dimension } from "../shared/core/definitions";
import {
  MICRO_DIMENSION_GEOCODES,
  MICRO_DIMENSION_Z_VALUE,
} from "../shared/dimensions";
import { DimensionAndStringValue } from "./MicroDatasetGeo";
import { DimensionsSpec } from "./headers";
import { MicroMapReferenceValue } from "../../state/stats/document-core/_core-shared";

export function createMicroMapData(
  datasetDto: MicroDatasetDto,
  _dimensionsSpec: DimensionsSpec,
  comparisonType: MicroResultsType,
  measureSelection: PrimaryMeasureSelectionMicroFull,
  desoRegsoLookup: DesoRegsoLookup,
  microGeoTree: MicroGeoTree | undefined
) {
  // If using multi-breakdown selection mode, we need to ensure only one
  // value per breakdown is used when preparing data for the map,
  // since only one value per region can be displayed.
  const forcedDimensionValues = _dimensionsSpec.variable
    .map((dimension) => {
      if (dimension === Dimension.value) {
        return;
      }

      const values = chain(datasetDto.rows)
        .groupBy((r) => r[dimension])
        .keys()
        .value();
      if (values.length < 2) {
        return;
      }
      return { dimension, value: values[0] };
    })
    .filter(defined);

  const rows = datasetDto.rows.filter((r) => {
    if (r[Dimension.userDefined] === true) {
      return false;
    }
    return forcedDimensionValues.every(
      (dimValue) => r[dimValue.dimension] === dimValue.value
    );
  });

  let desoResults: DesoResultData[] | undefined = undefined;
  let regsoResults: RegsoResultData[] | undefined = undefined;
  if (comparisonType === "compare-groups") {
    // TODO: support groups
  } else if (comparisonType === "compare-units-deso") {
    const desoData: DesoResultData[] = rows.map((item) => {
      const desos = ArrayRT(StringRT).check(item[MICRO_DIMENSION_GEOCODES]);
      if (desos.length !== 1) {
        throw new Error("Expected exactly one deso");
      }
      const deso = desos[0];
      const desoProps = desoRegsoLookup.lookupDeso(deso);
      return {
        zValue: NumberRT.check(item[MICRO_DIMENSION_Z_VALUE]),
        deso,
        desoLabel: desoProps.deso_label,
        id: desoProps.id,
        value: NumberRT.check(item[Dimension.value]),
      };
    });
    desoResults = desoData;
  } else if (comparisonType === "compare-units-regso") {
    const regsoData: RegsoResultData[] = rows.map((item) => {
      const regsos = ArrayRT(StringRT).check(item[MICRO_DIMENSION_GEOCODES]);
      if (regsos.length !== 1) {
        throw new Error("Expected exactly one deso");
      }
      const regso = regsos[0];
      const regsoProps = desoRegsoLookup.lookupRegso(regso);
      return {
        zValue: NumberRT.check(item[MICRO_DIMENSION_Z_VALUE]),
        regso,
        regsoLabel: regsoProps.regso_label,
        id: regsoProps.id,
        value: NumberRT.check(item[Dimension.value]),
      };
    });
    regsoResults = regsoData;
  }

  // Handle reference values

  const matchingRefRows =
    forcedDimensionValues.length > 0
      ? datasetDto.reference_rows?.filter((r) => {
          return forcedDimensionValues.every(
            (dimValue) => r[dimValue.dimension] === dimValue.value
          );
        }) ?? []
      : datasetDto.reference_rows ?? [];

  const referenceValues: MicroMapReferenceValue[] = [];
  const sfRounder = (n: number) =>
    n === 0 ? "0" : numSignificantFiguresRounder(3)(n);
  const formatterSingleValue =
    datasetDto.header.value_type === "integer"
      ? (n: number) => integerFormatter(n?.toString())
      : sfRounder;
  const refRow = matchingRefRows[0];
  if (defined(refRow)) {
    const singleArea = rows.length === 1 ? rowToGeocode(rows[0]) : undefined;
    const singleAreaLabel = defined(singleArea)
      ? lookupLabel(singleArea, desoRegsoLookup)
      : undefined;

    const specs: Array<[string, string, (n: number) => string]> = [
      [
        "value_selected_areas_average",
        `I ${defined(singleAreaLabel) ? singleAreaLabel : "valda områden"}`,
        sfRounder,
      ],
    ];

    if (measureSelection.measure.aggMethodGeo !== "sum") {
      // Get arbitrary deso value to use for lookuup
      const arbitraryGeocode = rowToGeocode(rows[0]);

      specs.push(
        ["value_country", "I riket", formatterSingleValue],
        [
          "value_region",
          `I ${
            defined(microGeoTree) && defined(arbitraryGeocode)
              ? microGeoTree.desoRegsoToRegion(arbitraryGeocode)
              : "regionen"
          }`,
          formatterSingleValue,
        ],
        [
          "value_municipality",
          `I ${
            defined(microGeoTree) && defined(arbitraryGeocode)
              ? microGeoTree.desoRegsoToMunicipality(arbitraryGeocode)
              : "kommunen"
          }`,
          formatterSingleValue,
        ]
      );
    }
    for (const [property, label, rounder] of specs) {
      const value = refRow[property];
      if (defined(value) && typeof value === "number") {
        referenceValues.push({
          label,
          value: rounder(value),
        });
      }
    }
  }

  const resultsType = comparisonType;

  return {
    numSelectedAreas: rows.length,
    significantValuesRounder: sfRounder,
    formatterSingleValue: formatterSingleValue,
    forcedDimensionValues: forcedDimensionValues,
    resultsType: resultsType,
    desoResults,
    regsoResults,
    title: datasetDto.header.descr_long,
    subtitle: getSubtitle(measureSelection, forcedDimensionValues, datasetDto),
    unitLabel: datasetDto.header.unit_label,
    referenceValues: referenceValues,
  };
}

function getSubtitle(
  _measureSelection: PrimaryMeasureSelectionMicroFull,
  _forcedDimensionValues: DimensionAndStringValue[],
  _datasetDto: MicroDatasetDto
) {
  const computedType = _measureSelection.measure.computed?.type;
  if (defined(computedType)) {
    return computedMeasureSubDescription(
      _measureSelection.measure,
      _measureSelection.computedMeasureVariablesConfig
    );
  }

  if (_forcedDimensionValues.length > 0) {
    return formatValues(_forcedDimensionValues, _measureSelection);
  }

  const dimValues = Object.entries(_datasetDto.header.lifted ?? {}).map(
    ([key, value]) => ({ dimension: key, value } as DimensionAndStringValue)
  );
  return formatValues(dimValues, _measureSelection);
}

function formatValues(
  dimValues: DimensionAndStringValue[],
  measureSelection: PrimaryMeasureSelectionMicroFull
): string {
  const time = TimeResolution.deserialize(
    measureSelection.measure.timeResolution
  );
  const dimensions = measureSelection.measure.dimensions;
  const dateFormatter = getDateFormatter(time);

  function getDimLabel(breakdown: string) {
    return dimensions?.find((dim) => dim.data_column === breakdown)?.label;
  }

  let values: string[] = dimValues
    .map((d) => {
      const key = d.dimension;
      const value = d.value;
      if (key === Dimension.date || isBreakdownTotal(value)) {
        return;
      }
      return `${getDimLabel(key) ?? "--"}: ${value}`;
    })
    .filter(defined);
  const dateValue = dimValues.find(
    (d) => d.dimension === Dimension.date
  )?.value;
  if (defined(dateValue)) {
    values.push(dateFormatter(utcTimeStringToDate(dateValue)));
  }

  return values.join(" | ");
}

function rowToGeocode(row: { [key: string]: unknown }): string | undefined {
  const geocodes = row[MICRO_DIMENSION_GEOCODES];
  const unchecked = Array.isArray(geocodes) ? geocodes[0] : undefined;
  const geocodeChecked = typeof unchecked === "string" ? unchecked : undefined;
  return geocodeChecked;
}

function lookupLabel(
  areaCode: string,
  desoRegsoLookup: DesoRegsoLookup
): string | undefined {
  return (
    desoRegsoLookup.lookupDeso(areaCode)?.deso_label ??
    desoRegsoLookup.lookupRegso(areaCode)?.regso_label
  );
}
