import { IconProps } from "@blueprintjs/core";
import * as _ from "lodash";
import { useRecoilValue, useRecoilState } from "recoil";
import { Pivot, PivotItem } from "@fluentui/react";
import { useCallback, useContext, useMemo } from "react";

import { Geo } from "./GeoSelect";
import { PivotPlaceholder } from "./PivotPlaceholder";
import { TableTab } from "../card_general/TableTab";
import { MapTab } from "./output_tabs/MapTab";
import { ChartTab } from "../card_general/ChartTab";
import { InfoTab } from "../card_general/InfoTab";
import { CustomBugsnagErrorBoundary } from "../../../../../components/ErrorBoundary";
import { DefaultLoading } from "../../../../../components/Loading";
import { DataOutputView } from "../../../../../lib/application/state/stats/document-core/core";
import {
  geoExpansionsQuery,
  supportedGeographiesQuery,
  primarySelectionQuery,
  isGroupingQuery,
  selectedCardViewQuery,
  singleDataCardQuery,
  currentlyValidGeoSelection,
} from "../../../../../lib/application/state/stats/document-core/queries/dataCard";
import { classNames } from "../../../../../lib/core/classNames";
import { defined } from "../../../../../lib/core/defined";
import {
  allGeoTypes,
  GeographiesSerializable,
} from "../../../../../lib/domain/geography";
import { AlertBox } from "../../../../../components/AlertBox";
import { useDatasetError } from "../../../../../lib/application/state/stats/useDataset";
import {
  CardUpdateCountContext,
  ShowDraftDataContext,
} from "../../../../../lib/application/contexts";
import { useGeoSelection } from "../../../../../lib/application/state/actions/selections/useChangeGeoSelection";
import { Progress } from "../../../../../lib/core/progress";
import { useChangeDataOutputSettings } from "../../../../../lib/application/state/actions/selections/useChangeDataOutputSettings";
import { cardWithUpdatedDataState } from "../../../../../lib/application/state/stats/cardToDataStateStats";
import { cardColors } from "../../../../../lib/application/state/stats/document-core/queries/shared";
import { wait } from "../../../../../lib/core/wait";
import { useSaveCard } from "../../../../../lib/application/state/actions/useSaveDocument";
import { useResetGeoSelection } from "../../../../../lib/application/state/actions/selections/useResetGeoSelection";
import {
  DataLoadErrorView,
  DataLoadNotStartedView,
} from "../card_general/data_load_views";
import { SurveyStringDataset } from "../../../../../lib/application/stats/datasets/SurveyStringDataset";
import { TableTabSurveyString } from "../card_general/TableTabSurveyString";
import { DataOutputToolbar } from "../card_general/DataOutputToolbar";
import { useExtendedAppearanceSettings } from "../../../../../lib/application/state/actions/useExtendedAppearanceSettings";

const pivots: Array<{
  key: DataOutputView;
  title: string;
  icon: IconProps["icon"];
  component?: JSX.Element;
}> = [
  {
    key: "diagram",
    title: "Diagram",
    icon: "grouped-bar-chart",
  },
  { key: "table", title: "Tabell", icon: "th" },
  { key: "map", title: "Karta", icon: "map" },
  { key: "info", title: "Info", icon: "info-sign" },
];

interface Props {
  isEditing: boolean;
  cardStateId: string;
  geographies: GeographiesSerializable;
}

export function DataOutput(props: Props) {
  const { isEditing, geographies } = props;
  const queryProps = { cardStateId: props.cardStateId };
  const [expansions, setExpansions] = useRecoilState(
    geoExpansionsQuery(queryProps)
  );
  const supportedGeographies = useRecoilValue(
    supportedGeographiesQuery(queryProps)
  );
  const appearanceSettings = useExtendedAppearanceSettings();
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const [selections, setSelections] = useGeoSelection(
    props.cardStateId,
    geographies,
    adminShowDraftData
  );
  const resetGeoSelections = useResetGeoSelection(
    supportedGeographies,
    setSelections,
    setExpansions
  );
  const saveCard = useSaveCard();
  const primarySelection = useRecoilValue(primarySelectionQuery(queryProps));
  const isGrouping = useRecoilValue(isGroupingQuery(queryProps));
  const selectedKey = useRecoilValue(selectedCardViewQuery(queryProps));

  const [card, setCard] = useRecoilState(
    singleDataCardQuery({ cardStateId: props.cardStateId })
  );

  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const handleLinkClick = useCallback(
    async (item?: PivotItem) => {
      if (!defined(item)) {
        return;
      }

      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const updatedCard = {
        ...card,
        data: {
          ...card.data,
          selectedView: item.props.itemKey as any,
        },
      };

      setCard(updatedCard);
      await wait(0);

      const cardWithData = await cardWithUpdatedDataState(
        shouldAbort,
        updatedCard,
        geographies,
        adminShowDraftData,
        appearanceSettings,
        cardColors(card)
      );
      if (shouldAbort() || !defined(cardWithData)) {
        return;
      }
      setCard(cardWithData);
      saveCard?.(cardWithData);
    },
    [
      adminShowDraftData,
      card,
      appearanceSettings,
      geographies,
      getCurrentCount,
      incrementUpdateCount,
      saveCard,
      setCard,
    ]
  );

  let unsupportedTabs: DataOutputView[] = [];
  const [settings, setSettings] = useChangeDataOutputSettings(
    props.cardStateId
  );

  const primaryValueType = primarySelection?.measureSelection?.valueType;
  const allTabs: DataOutputView[] = ["diagram", "table", "map", "info"];
  switch (primaryValueType) {
    case undefined:
      unsupportedTabs = allTabs;
      break;
    case "survey":
    case "decimal":
    case "integer":
      unsupportedTabs = isGrouping ? ["map"] : [];
      break;
    case "survey_string":
      unsupportedTabs = ["diagram", "map"];
      break;
    case "category":
      unsupportedTabs = ["diagram"];
      break;
  }

  const supportedTabs = pivots.filter((p) => !unsupportedTabs.includes(p.key));
  const selectedPivot = unsupportedTabs.includes(selectedKey)
    ? supportedTabs[0]
    : pivots.find((p) => p.key === selectedKey);

  const onlyCountrySupported =
    supportedGeographies.length === 1 && supportedGeographies[0] === "country";

  const handleReset = useCallback(() => {
    resetGeoSelections();
  }, [resetGeoSelections]);

  const handleExpand = useCallback(
    (geocode: string, expanded: boolean) => {
      if (expanded) {
        setExpansions(expansions.concat(geocode));
      } else {
        setExpansions(expansions.filter((g) => g !== geocode));
      }
    },
    [expansions, setExpansions]
  );

  const currentSelection = useMemo(
    () =>
      _.chain(selections)
        .values()
        .flatten()
        .map((item) => item.geocode)
        .value(),
    [selections]
  );
  const handleSelect = useCallback(
    (
      geoItems: {
        type: "country" | "nuts1" | "nuts2" | "nuts3" | "municipal";
        label: string;
        geocode: string;
      }[],
      selected: boolean
    ): void => {
      const selectionsCopy = { ...selections };
      const newlySelected = _.groupBy(geoItems, (item) => item.type);
      for (const key of allGeoTypes) {
        if (selected) {
          selectionsCopy[key] = _.unionBy(
            selectionsCopy[key],
            newlySelected[key],
            (item) => item.geocode
          );
        } else {
          selectionsCopy[key] = _.differenceBy(
            selectionsCopy[key],
            newlySelected[key],
            (item) => item.geocode
          );
        }
      }
      setSelections(selectionsCopy);
    },
    [selections, setSelections]
  );

  return (
    <div
      className={classNames(
        props.isEditing ? "editing" : "read-only",
        "stats-geo-and-output-container"
      )}
    >
      {props.isEditing && (
        <div className="data-geo">
          <Geo
            isGroupingMode={isGrouping}
            supportedGeographies={supportedGeographies}
            disabled={
              primarySelection?.measureSelection?.valueType === "survey" ||
              onlyCountrySupported ||
              isGrouping
            }
            geographies={geographies}
            selection={currentSelection}
            expansions={expansions}
            onReset={handleReset}
            onExpand={handleExpand}
            onSelect={handleSelect}
          ></Geo>
        </div>
      )}
      <div className="stats-output-viewer">
        {isEditing && (
          <div className={classNames("menu-container", "tools-surface")}>
            <Pivot
              className="pivot-menu"
              selectedKey={selectedKey}
              onLinkClick={handleLinkClick}
            >
              {supportedTabs.map((p) => (
                <PivotItem
                  // Show/hide icons in pivot by uncommenting/commenting this line
                  // itemIcon={p.icon as string}
                  key={p.title}
                  itemKey={p.key}
                  headerText={p.title}
                ></PivotItem>
              ))}
            </Pivot>
            <DataOutputToolbar
              cardRaw={card}
              isThirdPartyDoc={false}
              isEditing={isEditing}
              settings={settings}
              setSettings={setSettings}
              showToolbar={true}
              showToolbarDownload={true}
              showToolbarSettings={true}
            />
          </div>
        )}
        <div className="tab-content-with-toolbar">
          <CustomBugsnagErrorBoundary>
            <TabContent
              selectedPivot={selectedPivot}
              geographies={geographies}
              cardStateId={props.cardStateId}
            ></TabContent>
          </CustomBugsnagErrorBoundary>
        </div>
      </div>
    </div>
  );
}

function TabContent(props: {
  cardStateId: string;
  geographies: GeographiesSerializable;
  selectedPivot?: (typeof pivots)[number];
}) {
  const { selectedPivot, cardStateId, geographies } = props;
  const datasetError = useDatasetError(cardStateId);
  const [settings, setSettings] = useChangeDataOutputSettings(cardStateId);
  const currentCard = useRecoilValue(singleDataCardQuery({ cardStateId }));
  const geoSelections = useMemo(
    () => currentlyValidGeoSelection(currentCard, geographies),
    [currentCard, geographies]
  );
  const loadedData = currentCard.data.loadedData;

  if (!defined(currentCard)) {
    return <PivotPlaceholder icon={selectedPivot?.icon}></PivotPlaceholder>;
  }
  if (
    selectedPivot !== undefined &&
    !["table", "diagram", "info", "map"].includes(selectedPivot.key)
  ) {
    return <PivotPlaceholder icon={selectedPivot.icon}></PivotPlaceholder>;
  }

  if (loadedData?.progress?.type === Progress.InProgress) {
    return <DefaultLoading delayMs={0}></DefaultLoading>;
  }

  if (defined(datasetError)) {
    return (
      <AlertBox className="margin-x-sm margin-y-md" intent="warning">
        <span>{datasetError.displayMessage()}</span>
      </AlertBox>
    );
  }
  const loadProgress = currentCard.data.loadedData?.progress;
  if (loadProgress?.type === Progress.NotStarted) {
    return (
      <DataLoadNotStartedView info={loadProgress.info}></DataLoadNotStartedView>
    );
  }
  if (!defined(loadProgress)) {
    return (
      <DataLoadNotStartedView
        info={{
          type: "selections-incomplete",
          incomplete: { type: "empty-card" },
        }}
      ></DataLoadNotStartedView>
    );
  }

  if (!defined(loadedData)) {
    return <DataLoadErrorView error={"no-data"}></DataLoadErrorView>;
  }
  if (
    selectedPivot?.key !== "info" &&
    loadedData.progress?.type === Progress.Error
  ) {
    return (
      <DataLoadErrorView error={loadedData.progress.error}></DataLoadErrorView>
    );
  }

  const dataset = loadedData.dataset;

  if (dataset instanceof SurveyStringDataset) {
    return (
      <>
        {selectedPivot?.key === "table" && (
          <TableTabSurveyString dataset={dataset} cardId={currentCard.id} />
        )}
        {selectedPivot?.key === "info" && (
          <InfoTab dataset={dataset} cardId={currentCard.id}></InfoTab>
        )}
      </>
    );
  }

  return (
    <>
      {selectedPivot?.key === "table" && (
        <TableTab
          settings={settings}
          setSettings={setSettings}
          tableDataState={loadedData.tableDataState}
        ></TableTab>
      )}
      {selectedPivot?.key === "map" && (
        <MapTab
          isReloadingData={false} // TODO
          geoSelections={geoSelections}
          dataset={dataset}
          cardId={currentCard.id}
          settings={settings}
        ></MapTab>
      )}
      {selectedPivot?.key === "diagram" && (
        <ChartTab
          cardId={currentCard.id}
          chartDataState={loadedData.chartDataState}
        ></ChartTab>
      )}
      {selectedPivot?.key === "info" && (
        <InfoTab dataset={dataset} cardId={currentCard.id}></InfoTab>
      )}
    </>
  );
}
