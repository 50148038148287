import { useCallback } from "react";
import {
  editStatsDimensionV2,
  getUpdateConflictsStats,
} from "../../../../../lib/application/requests/admin/measure_management/edit";
import {
  commitPendingChangesStats,
  revertPendingChangesStats,
} from "../../../../../lib/application/requests/admin/measure_management/import";
import { getMeasureDimensionsV2 } from "../../../../../lib/application/requests/datasets/dimensions";
import { DimensionV2Dto } from "../../../../../lib/domain/measure/definitions";
import { MeasureConfirmation } from "../_shared/MeasureConfirmation";
import { DraftDataTodo } from "../../../../../lib/application_admin/todos/draft_data_todos";
import {
  renderCommitResult,
  renderRevertResult,
} from "../_shared/renderCommitRevert";

interface Props {
  id: number;
  todos?: DraftDataTodo[];
  reloadTodos: () => void;
}

export function StatsMeasureConfirmation(props: Props) {
  const getDims = useCallback(async (id: number) => {
    const dims = await getMeasureDimensionsV2(id, true, false);
    return dims;
  }, []);

  const getConflicts = useCallback(async (id: number) => {
    const dims = await getUpdateConflictsStats(id);
    return dims;
  }, []);

  const editDimension = useCallback(
    (dimensionId: number, dimension: DimensionV2Dto) => {
      if (dimension.type !== "stats_breakdown") {
        throw new Error("Invalid dimension type");
      }
      return editStatsDimensionV2(dimensionId, dimension);
    },
    []
  );

  return (
    <MeasureConfirmation
      {...props}
      type="stats"
      getConflicts={getConflicts}
      getDimensions={getDims}
      editDimension={editDimension}
      revertPendingChanges={revertPendingChangesStats}
      commitPendingChanges={commitPendingChangesStats}
      renderCommitDto={renderCommitResult}
      renderRevertDto={renderRevertResult}
    ></MeasureConfirmation>
  );
}
