import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export function useIncrementer(initialValue: number): [number, () => void] {
  const [value, setValue] = useState(initialValue);
  const increment = useCallback(() => {
    setValue((prev) => prev + 1);
  }, []);

  const handles = useMemo(() => {
    return [value, increment] as [number, () => void];
  }, [increment, value]);

  return handles;
}

/**
 * Incrementer hook that works in callbacks, avoiding stale value reads due to
 * captured values in closures
 */
export function useWrappedIncrementer(initialValue: number = 0) {
  const valueRef = useRef<number>(initialValue);

  const increment = useCallback(() => {
    const next = valueRef.current + 1;
    valueRef.current = next;
    return next;
  }, []);

  const getCurrentValue = useCallback(() => {
    return valueRef.current;
  }, []);

  return { increment, getCurrentValue };
}
