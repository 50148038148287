import { useCallback, useContext } from "react";
import { GeographiesSerializable } from "../../../../../domain/geography";
import { DocCardStats } from "../../../stats/document-core/core";
import { defined } from "../../../../../core/defined";
import { useSetRecoilState } from "recoil";
import { cardQuery } from "../../../stats/document-core/queries/card";
import { cardWithUpdatedDataState } from "../../../stats/cardToDataStateStats";
import { ColorSchemeContainer } from "../../../stats/document-style/definitions";
import { CardUpdateCountContext } from "../../../../contexts";
import { useExtendedAppearanceSettings } from "../../useExtendedAppearanceSettings";

/**
 * Fetch data for a given card and store in card state.
 */
export function useHandleUpdateCardDataState(
  cardId: string,
  geographies: GeographiesSerializable,
  adminShowDraftData: boolean
) {
  const setCard = useSetRecoilState(cardQuery(cardId));
  const appearanceSettings = useExtendedAppearanceSettings();
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const handleUpdate = useCallback(
    async (card: DocCardStats, existingColorScheme?: ColorSchemeContainer) => {
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const cardState = await cardWithUpdatedDataState(
        shouldAbort,
        card,
        geographies,
        adminShowDraftData,
        appearanceSettings,
        existingColorScheme
      );
      if (shouldAbort() || !defined(cardState)) {
        return;
      }
      setCard(cardState);
      return cardState;
    },
    [
      adminShowDraftData,
      appearanceSettings,
      geographies,
      getCurrentCount,
      incrementUpdateCount,
      setCard,
    ]
  );

  return handleUpdate;
}
