import { useRecoilValue } from "recoil";
import { cardQuery } from "./document-core/queries/card";
import { useMemo } from "react";
import { assertNever } from "../../../core/assert";

export function useCardBgColor(cardId: string): string | undefined {
  const card = useRecoilValue(cardQuery(cardId));

  const bgColor = useMemo(() => {
    switch (card.type) {
      case "error":
      case "microCardImage":
      case "pythonCard":
      case "textCardSimple":
        return undefined;
      case "dataCard":
      case "microCard":
      case "textCardCK":
        return card.bgColor;
    }
    assertNever(card);
  }, [card]);

  return bgColor;
}
