import Bugsnag from "@bugsnag/js";
import React from "react";

import { config } from "../config";
import { ExternalLink } from "./ExternalLink";
import "./ErrorBoundary.scss";
import { defined } from "../lib/core/defined";
import { IconButton } from "@fluentui/react";

const reactPlugin = Bugsnag.getPlugin("react");
export const BugsnagErrorBoundary =
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  reactPlugin!.createErrorBoundary(React);

interface Props {
  onDismiss?: () => void;
}

export function ErrorBoundaryFallback(props: Props) {
  const dismiss = props.onDismiss;
  return (
    <div className="error-boundary-container">
      <div className="header-block">
        <h2>Ett oväntat fel inträffade.</h2>
        {defined(dismiss) && (
          <IconButton
            onClick={() => dismiss()}
            iconProps={{ iconName: "cross" }}
          ></IconButton>
        )}
      </div>
      <p>
        Felet har rapporterats till administratören.{" "}
        <ExternalLink
          link={config.infostatSeLinks.contact}
          text="Se här"
        ></ExternalLink>{" "}
        för kontaktuppgifter.
      </p>
    </div>
  );
}

export function CustomBugsnagErrorBoundary(props: {
  onDismiss?: () => void;
  children: JSX.Element;
}) {
  return (
    <BugsnagErrorBoundary
      FallbackComponent={() => (
        <ErrorBoundaryFallback
          onDismiss={props.onDismiss}
        ></ErrorBoundaryFallback>
      )}
    >
      {props.children}
    </BugsnagErrorBoundary>
  );
}
