import { config } from "../../config";

const strings = {
  "max-num-cards-exceeded": `Du har redan skapat max antal datakort (${config.maxNumDocCards}). Ta bort ett och försök igen.`,
  "failed-to-duplicate-card": `Misslyckades med att kopiera kortet`,
  "default-document-name": "Nytt dokument",
  "document-outdated":
    "En ny version av dokumentet finns tillgänglig. Ladda om sidan för att se den nya versionen.",
  "cant-save-doc-unknown-error": "Kunde inte spara. Okänt fel.",
  "cant-save-doc-may-be-deleted":
    "Kunde inte spara. Dokumentet kunde inte hittas.",
  "doc-not-found": "Dokumentet hittades inte",
  "page-not-found": "Hittade inte sidan",
  "validation-input-empty": "Fältet får inte vara tomt",
  "validation-range-min-greater-than-max":
    "Minimum måste vara mindre än maximum",
  "failed-to-delete-file": "Misslyckades med att ta bort filen",
  "failed-to-delete-folder": "Misslyckades med att ta bort mappen",
  "confirm-create-file-in-shared-folder":
    "Är du säker på att du vill skapa en fil i den här delade mappen?",
  "confirm-create-folder-in-shared-folder":
    "Är du säker på att du vill skapa en mapp i den här delade mappen?",
  "alerts-now-registered": "Du bevakar nu detta mått",
  "alerts-now-unregistered": "Du har nu slutat bevaka detta mått",
  "alerts-could-not-register": "Kunde inte lägga till bevakning just nu",
  "alerts-could-not-unregister": "Kunde inte avsluta bevakning just nu",
  "micro-mixed-area-selection":
    "Du har valt en blandning av egna områden och individuella lokalområden. Välj endast en typ av områden för att kunna visa resultat.",
  "micro-too-many-features-selected": `Du har valt för många områden. Välj max ${config.micro.maxNumFeaturesSelected} områden.`,
  "micro-confirm-change-measure-remove-incompatible-filters":
    "Du är på väg att välja ett mått på en annan geografisk nivå. Vissa filter kommer att tas bort.",
  "micro-confirm-remove-filters":
    "I flervalsläge kan inte filter användas. Vill du fortsätta och ta bort alla filter?",
  "micro-address-export-max-regions-exceeded": `För många områden valda. Välj max ${config.micro.maxNumAreasForAddressExport} områden.`,
  "link-vanity-path-too-long": `Länk får inte vara längre än ${config.vanityPathMaxLength} tecken`,
  "invalid-chars-in-link-vanity-path":
    "Länk får endast innehålla bokstäver, siffror, bindestreck och understreck",
};

export function getText(key: keyof typeof strings): string {
  return strings[key];
}
