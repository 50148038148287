import { Pivot, PivotItem } from "@fluentui/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { useRecoilState, useRecoilValue } from "recoil";
import "mapbox-gl/dist/mapbox-gl.css";

import "./MicroCard.scss";
import { defined } from "../../../../../lib/core/defined";
import { CardContainer } from "../CardContainer";
import { ISharingInfo } from "../../../../../lib/application/files/SharingInfo";
import { singleMicroCardQuery } from "../../../../../lib/application/state/stats/document-core/queries/microCard";
import {
  PageBreakAfter,
  PageBreakBefore,
} from "../../../../../components/print/page_breaks";
import {
  TabbedCardMain,
  TabbedCardsContentContainer,
  TabbedCardTab,
  TabbedCardTabsContainer,
} from "../../../../../components/Card";
import { MicroCardTab } from "../card_tabs/MicroCardTab";
import { useCardEditMode } from "../../../../../lib/application/state/stats/useEditMode";
import { DocCardMicro } from "../../../../../lib/application/state/stats/document-core/core";
import { MeasureSelectionMicro } from "./components/MeasureSelectionMicro";
import {
  MicroOutputTab,
  microSelectionPrimary,
  microSelectionsNonPrimary,
  MicroSubjectPath,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { classNames } from "../../../../../lib/core/classNames";
import { MicroMap } from "./components/MicroMap";
import { ChartTab } from "../card_general/ChartTab";
import { useFetchPostalCodeDataset } from "./useMicroResults";
import { TableTab } from "../card_general/TableTab";
import {
  CardUpdateCountContext,
  CategoriesContextMicroPrimaryContext,
  CategoriesContextMicroSecondaryContext,
  InfostatDataAdminModeContext,
  MicroGeoTreeContext,
  ShowDraftDataContext,
} from "../../../../../lib/application/contexts";
import { useChangeSubjectPath } from "../../../../../lib/application/state/actions/micro/useChangeSubjectPath";
import { Categories } from "../../../../../lib/domain/categories";
import { TabContainer } from "../card_tabs/CardTab";
import { useChangeMeasure } from "../../../../../lib/application/state/actions/micro/useChangeMeasure";
import { MetadataAdminMicro } from "../admin/MetadataAdminMicro";
import { useChangeGeotype } from "../../../../../lib/application/state/actions/micro/useChangeGeotype";
import { Button } from "../../../../../components/Button";
import { voidFunc } from "../../../../../lib/core/voidFunc";
import { dataValueTypesMicroGeo } from "../../../../../lib/infra/api_responses/dataset";
import { ensureCompatibleSelectedTab } from "../../../../../lib/application/state/actions/micro/shared";
import { useGeoTree } from "./useGeoTree";
import { MicroInfoTab } from "./components/MicroInfoTab";
import { ComputedMeasurementType } from "../../../../../lib/infra/api_responses/micro_dataset";
import {
  cardWithUpdatedDataOutputSettingsMicro,
  loadAndStoreDataMicro,
} from "../../../../../lib/application/state/stats/cardToDataStateMicro";
import { useSaveCard } from "../../../../../lib/application/state/actions/useSaveDocument";
import { useWrappedIncrementer } from "../../../../../lib/application/hooks/useIncrementer";
import { cardColors } from "../../../../../lib/application/state/stats/document-core/queries/shared";
import { Progress } from "../../../../../lib/core/progress";
import {
  DataLoadErrorView,
  DataLoadNotStartedView,
} from "../card_general/data_load_views";
import { validateMicroCardSelection } from "../../../../../lib/application/state/stats/document-core/card_info";
import { DataOutputToolbar } from "../card_general/DataOutputToolbar";
import { useChangeDataOutputSettings } from "../../../../../lib/application/state/actions/selections/useChangeDataOutputSettings";
import { MicroMapDownloadEventProvider } from "../../../../../lib/application/events/MicroMapDownloadEventProvider";
import { useElementSize } from "../../../../../lib/application/hooks/useElementSize";
import { useExtendedAppearanceSettings } from "../../../../../lib/application/state/actions/useExtendedAppearanceSettings";
import { reportMetaStateQuery } from "../../../../../lib/application/state/stats/document-meta/queries";
import { ThirdPartMicroCardSettings } from "./ThirdPartyMicroCardSettings";
import { MicroCardThirdPartyDoc } from "./MicroCardThirdPartyDoc";
import { DataOutputSettings } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";

const pivots: Array<{
  key: MicroOutputTab;
  title: string;
  component?: JSX.Element;
}> = [
  { key: "map-select", title: "Välj områden" },
  { key: "map-view", title: "Karta" },
  {
    key: "chart",
    title: "Diagram",
  },
  { key: "table", title: "Tabell" },
  { key: "info", title: "Info" },
];

interface Props {
  cardId: string;
  tempRenderInvisible: boolean;
  onRenderDone: () => void;
  removeCard: (cardId: string) => void;
  onDuplicateCard: (cardId: string) => void;
  setCardBgColor: (id: string, color?: string) => void;
  sharingInfo: ISharingInfo;
}

export function MicroCard(props: Props) {
  const updateCounter = useWrappedIncrementer();

  const categoriesPrimary = useContext(CategoriesContextMicroPrimaryContext);
  const categoriesSecondary = useContext(
    CategoriesContextMicroSecondaryContext
  );
  const geoTree = useGeoTree();
  const stateMeta = useRecoilValue(reportMetaStateQuery);
  const { isEditingCard: isEditing } = useCardEditMode(
    props.cardId,
    props.sharingInfo
  );
  if (!defined(categoriesPrimary) || !defined(categoriesSecondary)) {
    throw new Error("Categories not defined");
  }
  if (!defined(geoTree)) {
    return null;
  }

  if (stateMeta?.thirdPartySharingDoc && !isEditing) {
    return (
      <CardUpdateCountContext.Provider value={updateCounter}>
        {/* FIXME: support for bg color? */}
        <MicroCardThirdPartyDoc
          tempRenderInvisible={props.tempRenderInvisible}
          onRenderDone={props.onRenderDone}
          sharingInfo={props.sharingInfo}
          cardId={props.cardId}
        />
      </CardUpdateCountContext.Provider>
    );
  }

  return (
    <CardUpdateCountContext.Provider value={updateCounter}>
      <MicroGeoTreeContext.Provider value={geoTree}>
        <MicroCardInner
          {...props}
          categoriesPrimary={categoriesPrimary}
          categoriesSecondary={categoriesSecondary}
        />
      </MicroGeoTreeContext.Provider>
    </CardUpdateCountContext.Provider>
  );
}

const mainTabName = "micro-tab";
const metadataTabName = "micro-metadata-tab";

const EMPTY_ARRAY: any[] = [];

export function MicroCardInner(
  props: Props & {
    categoriesPrimary: Categories;
    categoriesSecondary: Categories;
  }
) {
  const {
    cardId,
    removeCard,
    sharingInfo,
    onRenderDone,
    categoriesPrimary,
    categoriesSecondary,
    setCardBgColor,
  } = props;
  const { hasEditDocumentAccess } = useCardEditMode(cardId, sharingInfo);
  const dataAdminMode = useContext(InfostatDataAdminModeContext);
  const metaState = useRecoilValue(reportMetaStateQuery);
  const { ref: cardContentRef, height } = useElementSize();

  const [addNewMeasure, setAddNewMeasure] = useState(false);
  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: props.cardId })
  );

  const handleSaveCard = useSaveCard();
  const adminShowDraftData = useContext(ShowDraftDataContext);

  const cardTabs = useMemo(() => {
    const tabsHolder = [mainTabName];
    if (dataAdminMode) {
      tabsHolder.push(metadataTabName);
    }
    return tabsHolder;
  }, [dataAdminMode]);

  const [handleChangeSubjectPathPrimary, changeSubjectPathPendingPrimary] =
    useChangeSubjectPath(
      cardId,
      true,
      categoriesPrimary,
      adminShowDraftData,
      EMPTY_ARRAY,
      EMPTY_ARRAY
    ); // No geo types specified means no restriction on geo type
  const [handleChangeSubjectPathSecondary, changeSubjectPathPendingSecondary] =
    useChangeSubjectPath(
      cardId,
      true,
      categoriesSecondary,
      adminShowDraftData,
      EMPTY_ARRAY,
      dataValueTypesMicroGeo
    );
  const [handleChangeMeasure, changeMeasurePending] = useChangeMeasure(
    cardId,
    adminShowDraftData,
    EMPTY_ARRAY
  );

  const handleChangeGeotype = useChangeGeotype(cardId);

  const changePending =
    changeSubjectPathPendingPrimary ||
    changeSubjectPathPendingSecondary ||
    changeMeasurePending;
  const [currentTabUnchecked, setCurrentTab] = useState(cardTabs[0]);
  const onSelectTab = (name: string) => setCurrentTab(name);
  const currentTab = dataAdminMode ? currentTabUnchecked : mainTabName;

  const microOutputDisabled =
    !defined(card.data.dataSelections) || changePending;
  const { isEditingCard } = useCardEditMode(cardId, sharingInfo);

  const primarySelection = microSelectionPrimary(card);

  useEffect(() => {
    if (height === 0) {
      return;
    }

    const handle = setTimeout(() => {
      onRenderDone();
    }, 300);

    return () => {
      return clearTimeout(handle);
    };
  }, [height, onRenderDone]);

  const [isRemovingCard, setIsRemovingCard] = useState(false);
  const removeCardWithDelay = useCallback(() => {
    setIsRemovingCard(true);
    setTimeout(() => {
      removeCard(cardId);
    }, 200);
  }, [cardId, removeCard]);

  const handleAddMeasureSelection = useCallback(
    (selectionId: string | undefined, path: MicroSubjectPath) => {
      setAddNewMeasure(false);
      if (!defined(primarySelection)) {
        return handleChangeSubjectPathPrimary(undefined, path);
      }
      return handleChangeSubjectPathSecondary(undefined, path);
    },
    [
      handleChangeSubjectPathPrimary,
      handleChangeSubjectPathSecondary,
      primarySelection,
    ]
  );

  const handleRemoveMeasure = useCallback(
    (selectionId: string) => {
      const dataSelections = card.data.dataSelections;
      const selectionToRemove = dataSelections?.find(
        (s) => s.id === selectionId
      );
      if (!defined(selectionToRemove)) {
        return card;
      }

      const removedSelection = dataSelections?.find(
        (s) => s.id === selectionId
      );
      const updatedDataSelections = defined(dataSelections)
        ? dataSelections.filter((s) => s.id !== selectionId)
        : undefined;
      const updatedGeoSelections =
        selectionToRemove.type === "primary"
          ? undefined
          : card.data.geoSelections;
      const updatedCard: DocCardMicro = {
        ...card,
        data: {
          ...card.data,
          selectedTab: ensureCompatibleSelectedTab(
            card.data.selectedTab,
            updatedDataSelections,
            updatedGeoSelections
          ),
          loadedData:
            removedSelection?.type === "primary"
              ? {
                  ...card.data.loadedData,
                  microMapState: undefined,
                  primaryDataset: undefined,
                  primaryProgress: undefined,
                  chartDataState: undefined,
                }
              : card.data.loadedData,
          geoSelections: updatedGeoSelections,
          dataSelections: updatedDataSelections,
        },
      };

      setCard(updatedCard);
      handleSaveCard?.(updatedCard);
    },
    [card, handleSaveCard, setCard]
  );

  const handleSetCardBgColor = useCallback(
    (color?: string) => {
      setCardBgColor(card.id, color);
    },
    [card.id, setCardBgColor]
  );

  return (
    <CardContainer
      isRemovingCard={isRemovingCard}
      cardId={props.cardId}
      removeBrokenCard={props.removeCard}
      disableScrollIntoView={props.tempRenderInvisible}
      className={classNames(
        "infostat-micro",
        props.tempRenderInvisible ? "invisible-micro-card" : "",
        defined(card.bgColor) ? "custom-bg" : ""
      )}
      sharingInfo={props.sharingInfo}
    >
      <PageBreakBefore breakSetting={card.pageBreak}></PageBreakBefore>
      <TabbedCardTabsContainer currentTab={currentTab}>
        {hasEditDocumentAccess ? (
          <React.Fragment key="doc-access">
            <TabbedCardTab
              key="primary"
              bgColor={card.bgColor}
              selected={currentTab === mainTabName}
              name={mainTabName}
              onSelect={onSelectTab}
            >
              <MicroCardTab
                sharingInfo={props.sharingInfo}
                cardId={card.id}
                handleSetCardBgColor={handleSetCardBgColor}
                handleDuplicateCard={() => props.onDuplicateCard(cardId)}
                handleRemoveCard={removeCardWithDelay}
              ></MicroCardTab>
            </TabbedCardTab>
            {dataAdminMode && (
              <TabbedCardTab
                key="metadata"
                selected={currentTab === metadataTabName}
                name={metadataTabName}
                onSelect={onSelectTab}
              >
                <TabContainer>
                  <></>
                  <p>Metadata</p>
                </TabContainer>
              </TabbedCardTab>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment key="no-doc-access"></React.Fragment>
        )}
      </TabbedCardTabsContainer>
      <TabbedCardsContentContainer currentTab={currentTab}>
        <TabbedCardMain name={metadataTabName}>
          <AdminMicroContainer card={card}></AdminMicroContainer>
        </TabbedCardMain>
        <TabbedCardMain name={mainTabName}>
          <div
            ref={cardContentRef}
            className={classNames(
              isEditingCard ? "editing" : "read-only",
              "micro-card-content"
            )}
            style={
              !isEditingCard && defined(card.bgColor)
                ? { backgroundColor: card.bgColor }
                : {}
            }
          >
            {metaState?.thirdPartySharingDoc && isEditingCard && (
              <div className="content-padding padding-top-md">
                <ThirdPartMicroCardSettings card={card} />
              </div>
            )}
            {isEditingCard &&
              card.data.dataSelections?.map((selection, index) => {
                const canBePrimary =
                  !defined(primarySelection) || selection.type === "primary";
                const otherSelectedMeasures =
                  card.data.dataSelections
                    ?.filter((s, i) => i !== index)
                    .map((s) => {
                      const id = s.measure?.id;
                      if (!defined(id)) {
                        return;
                      }
                      return [id, s.measure?.computed?.type] as [
                        number,
                        ComputedMeasurementType
                      ];
                    })
                    .filter(defined) ?? EMPTY_ARRAY;

                return (
                  <MeasureSelectionMicro
                    autofill
                    otherSelectedMeasures={
                      otherSelectedMeasures.length > 0
                        ? otherSelectedMeasures
                        : EMPTY_ARRAY // Use EMPTY_ARRAY to keep referential equality
                    }
                    acceptMeasureValueTypes={
                      canBePrimary ? EMPTY_ARRAY : dataValueTypesMicroGeo
                    }
                    key={selection.id}
                    selection={selection}
                    handleRemoveMeasure={handleRemoveMeasure}
                    handleChangeGeotype={handleChangeGeotype}
                    categories={
                      canBePrimary ? categoriesPrimary : categoriesSecondary
                    }
                    handleChangeMeasure={handleChangeMeasure}
                    handleChangePath={
                      canBePrimary
                        ? handleChangeSubjectPathPrimary
                        : handleChangeSubjectPathSecondary
                    }
                    changePending={changePending}
                    cardId={cardId}
                  />
                );
              })}
            {isEditingCard &&
              (!defined(card.data.dataSelections) ||
                card.data.dataSelections.length === 0 ||
                addNewMeasure) && (
                <MeasureSelectionMicro
                  autofill
                  otherSelectedMeasures={[]}
                  acceptMeasureValueTypes={
                    defined(primarySelection) ? dataValueTypesMicroGeo : []
                  }
                  handleRemoveMeasure={handleRemoveMeasure}
                  handleCancelCreateNewSelection={
                    (card.data.dataSelections?.length ?? 0) > 0
                      ? () => setAddNewMeasure(false)
                      : undefined
                  }
                  handleChangeGeotype={handleChangeGeotype}
                  categories={
                    defined(primarySelection)
                      ? categoriesSecondary
                      : categoriesPrimary
                  }
                  handleChangeMeasure={voidFunc} // Not used, because as soon as a category is selected this selection will be created
                  // and handled as a regular selection (see above)
                  handleChangePath={handleAddMeasureSelection}
                  changePending={changePending}
                  cardId={cardId}
                />
              )}
            {isEditingCard && (
              <section className="content-padding">
                <Button
                  title="Lägg till skikt"
                  onClick={() => setAddNewMeasure(true)}
                  disabled={addNewMeasure}
                />
              </section>
            )}
            <MicroOutputContainer
              isEditingCard={isEditingCard}
              microOutputDisabled={microOutputDisabled}
              card={card}
              setCard={setCard}
              sharingInfo={props.sharingInfo}
            ></MicroOutputContainer>
          </div>
        </TabbedCardMain>
      </TabbedCardsContentContainer>
      <PageBreakAfter breakSetting={card.pageBreak}></PageBreakAfter>
    </CardContainer>
  );
}

export function MicroOutputContainer(props: {
  isEditingCard: boolean;
  microOutputDisabled: boolean;
  sharingInfo: ISharingInfo;
  card: DocCardMicro;
  setCard: (card: DocCardMicro) => void;
}) {
  const { isEditingCard, microOutputDisabled, card, setCard } = props;
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const [settings, setSettings] = useChangeDataOutputSettings(card.id);

  const geoTree = useContext(MicroGeoTreeContext);
  const showDraftData = useContext(ShowDraftDataContext);
  const appearanceSettings = useExtendedAppearanceSettings();

  const secondarySelections = microSelectionsNonPrimary(card);
  const primarySelection = microSelectionPrimary(card);

  const fetchPostalCodeDataset = useFetchPostalCodeDataset(
    card.data.geoSelections,
    card.data.filterMeasures,
    card.data.settings,
    showDraftData,
    geoTree,
    primarySelection
  );

  const saveCard = useSaveCard();

  const handlePivotClick = useCallback(
    (item?: PivotItem) => {
      if (!defined(item)) {
        return;
      }
      if (!defined(geoTree)) {
        return;
      }
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const updatedBaseCard = {
        ...card,
        data: {
          ...card.data,
          selectedTab: item.props.itemKey as any,
        },
      };

      loadAndStoreDataMicro(
        updatedBaseCard,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedBaseCard),
        saveCard
      );
    },
    [
      adminShowDraftData,
      card,
      appearanceSettings,
      geoTree,
      getCurrentCount,
      incrementUpdateCount,
      saveCard,
      setCard,
    ]
  );

  const noDataSelections =
    !defined(card.data.dataSelections) || card.data.dataSelections.length === 0;
  const selections = card.data.geoSelections;
  const hasGeoSelections =
    defined(selections) && selections.selected.length > 0;

  const supportedPivots = useMemo(() => {
    return pivots.filter((p) => {
      switch (p.key) {
        case "map-select":
          return defined(primarySelection) || noDataSelections;
        case "map-view":
          return (
            (defined(primarySelection) && hasGeoSelections) ||
            secondarySelections.length > 0
          );
        case "chart":
        case "table":
          return defined(primarySelection) && hasGeoSelections;
        case "info":
          return defined(primarySelection) || secondarySelections.length > 0;
      }
    });
  }, [
    hasGeoSelections,
    noDataSelections,
    primarySelection,
    secondarySelections.length,
  ]);

  const currentPivot = supportedPivots.find(
    (p) => p.key === card.data.selectedTab
  );

  return (
    <MicroMapDownloadEventProvider>
      <div
        className={classNames(
          "micro-output-container",
          isEditingCard ? "editing" : "read-only"
        )}
      >
        {microOutputDisabled && (
          <div className="micro-viewer-disabled-overlay"></div>
        )}
        <div
          className={classNames(
            "micro-output-viewer",
            ["map-select", "map-view"].includes(currentPivot?.key ?? "")
              ? "map-output"
              : ""
          )}
        >
          {isEditingCard && (
            <div
              className={classNames(
                "menu-container",
                "menu-container-micro",
                "tools-surface"
              )}
            >
              <Pivot
                className="pivot-menu"
                selectedKey={card.data.selectedTab}
                onLinkClick={handlePivotClick}
                getTabId={(key) => key}
              >
                {supportedPivots.map((p) => (
                  <PivotItem
                    key={p.title}
                    itemKey={p.key}
                    headerText={p.title}
                  ></PivotItem>
                ))}
              </Pivot>
              <DataOutputToolbar
                cardRaw={card}
                isThirdPartyDoc={false}
                isEditing={isEditingCard}
                settings={settings}
                setSettings={setSettings}
                fetchPostalCodeDataset={fetchPostalCodeDataset}
                showToolbar={true}
                showToolbarDownload={true}
                showToolbarSettings={true}
              />
            </div>
          )}
          <div className={classNames("tab-content-with-toolbar")}>
            <TabContent
              cardStateId={card.id}
              selectedPivot={card.data.selectedTab}
              sharingInfo={props.sharingInfo}
            ></TabContent>
          </div>
        </div>
      </div>
    </MicroMapDownloadEventProvider>
  );
}

function AdminMicroContainer(props: { card: DocCardMicro }) {
  const { card } = props;
  const selections = card.data.dataSelections;
  if (!defined(selections) || selections.length === 0) {
    return <h1>Inga mått valda</h1>;
  }
  if (selections.length > 1) {
    return <h1>Flera mått valda -- välj endast 1!</h1>;
  }
  const measureId = selections[0].measure?.id;
  if (!defined(measureId)) {
    return <h1>Inget mått valt</h1>;
  }
  return <MetadataAdminMicro measureId={measureId}></MetadataAdminMicro>;
}

function TabContent(props: {
  cardStateId: string;
  selectedPivot?: MicroOutputTab;
  sharingInfo: ISharingInfo;
}) {
  const { cardStateId, selectedPivot, sharingInfo } = props;

  const [card, setCard] = useRecoilState(singleMicroCardQuery({ cardStateId }));
  const primarySelection = microSelectionPrimary(card);
  const nonPrimarySelections = microSelectionsNonPrimary(card);

  const saveCard = useSaveCard();
  const extendedAppearanceSettings = useExtendedAppearanceSettings();

  const handleUpdateSettings = useCallback(
    async (newSettings: DataOutputSettings) => {
      const updatedCard = await cardWithUpdatedDataOutputSettingsMicro(
        card,
        extendedAppearanceSettings,
        newSettings
      );
      if (!defined(updatedCard)) {
        return;
      }
      setCard(updatedCard);
      saveCard?.(updatedCard);
    },
    [card, extendedAppearanceSettings, saveCard, setCard]
  );

  const loadedData = card.data.loadedData;

  const dataPivots: MicroOutputTab[] = ["chart", "table"];
  if (defined(selectedPivot) && dataPivots.includes(selectedPivot)) {
    if (loadedData?.primaryProgress?.type === Progress.NotStarted) {
      return (
        <DataLoadNotStartedView
          info={loadedData.primaryProgress.info}
        ></DataLoadNotStartedView>
      );
    }
    const validationError = validateMicroCardSelection(card);
    if (defined(validationError)) {
      return (
        <DataLoadNotStartedView
          info={{ type: "selections-incomplete", incomplete: validationError }}
        />
      );
    }
  }

  if (
    defined(selectedPivot) &&
    dataPivots.includes(selectedPivot) &&
    loadedData?.primaryProgress?.type === Progress.Error
  ) {
    return (
      <DataLoadErrorView
        error={loadedData.primaryProgress.error}
      ></DataLoadErrorView>
    );
  }

  return (
    <>
      {(selectedPivot === "map-view" || selectedPivot === "map-select") && (
        <MicroMap
          view={selectedPivot}
          cardId={cardStateId}
          sharingInfo={sharingInfo}
        ></MicroMap>
      )}
      {selectedPivot === "chart" && (
        <ChartTab
          chartDataState={loadedData?.chartDataState}
          cardId={cardStateId}
        ></ChartTab>
      )}
      {selectedPivot === "table" && (
        <TableTab
          tableDataState={loadedData?.tableDataState}
          settings={card.data.settings.dataOutputSettings}
          setSettings={handleUpdateSettings}
        />
      )}
      {selectedPivot === "info" && (
        <MicroInfoTab
          primarySelection={primarySelection}
          nonPrimarySelectionsPartial={nonPrimarySelections}
        ></MicroInfoTab>
      )}
    </>
  );
}
