/**
 * A set of low-level containers for raw data, used in the processing layer
 * but not in the rendering layer (LineChart.tsx, BarChart.tsx, etc.).
 */

import * as _ from "lodash";
import { round } from "lodash";
import { assertNever } from "../../../core/assert";
import { defined } from "../../../core/defined";
import { MicroResultsType } from "../../../domain/micro/definitions";

import { shortenMunicipalityLabel } from "../../../domain/names";
import { SurveyRowStatus } from "../../../domain/survey_dataset";
import {
  DataValueTypeAny,
  DataValueTypeRegular,
  RowValueRegularRaw,
} from "../../../infra/api_responses/dataset";
import {
  SurveyRowValue,
  SurveyRowValueRaw,
} from "../../../infra/api_responses/survey_dataset";
import { Dimension } from "./core/definitions";
import { MICRO_DIMENSION_GROUP_ID } from "./dimensions";
import { logger } from "../../../infra/logging";

type RowRaw<ValueType> = {
  [x: string]: ValueType;
};
export type RowRawRegular = RowRaw<RowValueRegularRaw>;
export type RowRawSurvey = RowRaw<SurveyRowValueRaw>;

export interface RowRequiredInfo {
  domainDimension: string;
  rangeDimension: string;
  valueType: DataValueTypeAny;
}

class RowBase<ValueType> {
  protected _rowRaw: RowRaw<ValueType>;

  constructor(
    rowRaw: RowRaw<ValueType>,
    protected _chartHeader: RowRequiredInfo,
    protected _options?: {
      numDecimals?: number;
      shortenMunicipalityLabels?: boolean;
      /** For Micro rows, optionally translate values, like a DeSO ID to a display name.
       * Given a dimension, return a translation function.
       */
      translateMicroValues?: (
        dimension: string
      ) => undefined | ((label: string) => string | undefined);
      microMode?: MicroResultsType;
    }
  ) {
    if (_options?.shortenMunicipalityLabels === true) {
      this._rowRaw = _.mapValues(rowRaw, (value, key) =>
        key === Dimension.region && typeof value === "string"
          ? shortenMunicipalityLabel(value)
          : value
      ) as RowRaw<ValueType>;
    } else {
      this._rowRaw = rowRaw;
    }
  }

  /**
   *
   * @param sortedDimensions Ordered dimensions used to create a consistent label for this row
   * @param defaultValue [supply for survey data only!] If the row does not have a value for a dimension,
   * use this value instead. Needed to supply labels for reference rows, which do not have values for filter dimensions.
   * @returns
   */
  public makeLabel(sortedDimensions: string[], defaultValue?: string): string {
    return sortedDimensions
      .map((d) => {
        const value = this.dimension(d);
        if (defined(defaultValue)) {
          return !defined(value) || (typeof value === "string" && value === "")
            ? defaultValue
            : value;
        }
        return value;
      })
      .join(", ");
  }

  public domain() {
    return this._rowRaw[this._chartHeader.domainDimension];
  }

  public dimension = (dimension: string) => {
    return this._rowRaw[dimension];
  };

  public get isUserDefined(): boolean {
    return this._rowRaw[Dimension.userDefined] === true;
  }
}

export interface RowBaseInterface<T> extends RowBase<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  range(): any;
  type(): SurveyRowStatus;
}

export function surveyRangeParser(forceIntegerRounding?: boolean) {
  return (value: string | number | null) => {
    if (!defined(value)) {
      return null;
    }
    const unrounded = typeof value === "string" ? parseFloat(value) : value;
    return forceIntegerRounding ? round(unrounded, 0) : unrounded;
  };
}

export class SurveyRow
  extends RowBase<SurveyRowValue>
  implements RowBaseInterface<SurveyRowValue>
{
  constructor(
    rowRaw: RowRaw<SurveyRowValue>,
    chartHeader: RowRequiredInfo,
    protected _options?: {
      numDecimals?: number;
      shortenMunicipalityLabels?: boolean;
      forceIntegerRounding?: boolean;
    }
  ) {
    super(rowRaw, chartHeader, _options);
  }

  public domain(): string {
    return this._rowRaw[this._chartHeader.domainDimension] as string;
  }

  public type(): SurveyRowStatus {
    return this._rowRaw[Dimension.status] as SurveyRowStatus;
  }

  public range(): number | null {
    const value = this._rowRaw[Dimension.percentage];
    if (this._rowRaw[Dimension.userDefined]) {
      return parseFloat(value as string);
    }
    const rangeParser = surveyRangeParser(this._options?.forceIntegerRounding);
    if (typeof value === "boolean") {
      logger.error("SurveyRow.range: unexpected boolean value");
      return null;
    }
    return rangeParser(value);
  }

  public override dimension = (d: string) => {
    if (d === Dimension.percentage) {
      return this.range();
    }
    return this._rowRaw[d];
  };
}

export function rowRegularValueParser(
  valueType: DataValueTypeRegular,
  numDecimals?: number
) {
  return (valueRaw: string | undefined) => {
    if (!defined(valueRaw)) {
      return;
    }
    switch (valueType) {
      case "decimal": {
        const parsed = parseFloat(valueRaw);
        if (defined(numDecimals)) {
          return round(parsed, numDecimals);
        }
        return parsed;
      }
      case "integer":
        return parseInt(valueRaw);
      default:
        return valueRaw;
    }
  };
}

export class RowRegular
  extends RowBase<RowValueRegularRaw>
  implements RowBaseInterface<RowValueRegularRaw>
{
  public domain() {
    return this._rowRaw[this._chartHeader.domainDimension];
  }

  public type(): SurveyRowStatus {
    return "";
  }

  // TODO: bad type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public range(): any {
    const raw = this._rowRaw[this._chartHeader.rangeDimension];
    if (this._rowRaw[Dimension.userDefined]) {
      return parseFloat(raw);
    }

    const rangeParser = rowRegularValueParser(
      this._chartHeader.valueType as DataValueTypeRegular,
      this._options?.numDecimals
    );
    if (typeof raw === "boolean") {
      logger.error("RowRegular.range: unexpected boolean value");
      return;
    }
    return rangeParser(raw);
  }
}

export class RowForecast
  extends RowBase<unknown>
  implements RowBaseInterface<unknown>
{
  public type(): SurveyRowStatus {
    return "";
  }
  public range(): number {
    return this._rowRaw[Dimension.forecastValue] as unknown as number;
  }
  public rangeHigh(): number {
    return this._rowRaw[Dimension.forecastValueHigh] as unknown as number;
  }
  public rangeLow(): number {
    return this._rowRaw[Dimension.forecastValueLow] as unknown as number;
  }

  public asRowRegular(): RowRegular {
    return new RowRegular(
      {
        ...this._rowRaw,
        [Dimension.value]: (
          this._rowRaw[Dimension.forecastValue] as number
        ).toString(),
      },
      this._chartHeader,
      this._options
    );
  }
}

/**
 * Container for micro reference data.
 */
export class RowMicroReference {
  constructor(private _row: { [key: string]: unknown }) {}

  public type(): SurveyRowStatus {
    return "";
  }

  public municipality(): number | undefined {
    return this._row["value_municipality"] as number | undefined;
  }

  public nuts3(): number | undefined {
    return this._row["value_nuts3"] as number | undefined;
  }

  public country(): number | undefined {
    return this._row["value_country"] as number | undefined;
  }

  public selectedAreasAverage(): number | undefined {
    return this._row["value_selected_areas_average"] as number | undefined;
  }

  public dimension = (d: string): string => {
    return this._row[d] as string;
  };
}

export class RowMicro
  extends RowBase<RowValueRegularRaw>
  implements RowBaseInterface<RowValueRegularRaw>
{
  public domain() {
    return this._rowRaw[this._chartHeader.domainDimension];
  }

  public type(): SurveyRowStatus {
    return "";
  }

  public raw(): RowRaw<RowValueRegularRaw> {
    return this._rowRaw;
  }

  // TODO: bad type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public range(): any {
    const raw = this._rowRaw[this._chartHeader.rangeDimension];
    if (this._rowRaw[Dimension.userDefined]) {
      return parseFloat(raw);
    }

    const parser = rowRegularValueParser(
      this._chartHeader.valueType as DataValueTypeRegular,
      this._options?.numDecimals
    );
    if (typeof raw === "boolean") {
      logger.error("RowMicro.range: unexpected boolean value");
      return;
    }
    return parser(raw);
  }

  singleGeocode(): string | undefined {
    return (this._dimensionInner("geocodes" as any)?.value as any)?.[0];
  }

  /**
   * The Region dimension is special for Micro rows. We have three cases:
   * - (a) The region is a single deso code
   *   We must translate the deso code to deso label.
   * - (b) The region is a group ID (user-defined)
   *   We must translate the ID to the user-defined name.
   * - (c) The region is user-defined label (not a true region) given by a computed variable
   *   In this case, we must not translate the label.
   */
  private _dimensionInner = (
    d: string
  ): {
    translate: boolean;
    value: RowValueRegularRaw;
  } => {
    if (d === Dimension.region) {
      // (c) The region dimension is only defined when the row is a computed variable over Dimension.region
      if (defined(this._rowRaw[d])) {
        return { translate: false, value: this._rowRaw[d] };
      }

      const microMode = this._options?.microMode;
      if (!defined(microMode)) {
        throw new Error("Micro mode not defined");
      }
      if (microMode === "compare-groups") {
        const groupId = this._rowRaw[MICRO_DIMENSION_GROUP_ID];
        if (!defined(groupId)) {
          throw new Error("Group id not defined");
        }
        return { translate: true, value: groupId };
      } else if (
        microMode === "compare-units-deso" ||
        microMode === "compare-units-regso"
      ) {
        const geocodes = this._rowRaw["geocodes"] as unknown;
        if (!Array.isArray(geocodes)) {
          throw new Error("Geocodes not an array");
        } else if (geocodes.length !== 1) {
          throw new Error("Geocodes length not 1");
        }
        const geocode = geocodes[0];
        if (typeof geocode !== "string") {
          throw new Error("Geocode not a string");
        }
        return { translate: true, value: geocode };
      } else {
        assertNever(microMode);
      }
    }
    return { translate: false, value: this._rowRaw[d] };
  };

  public override dimension = (d: string) => {
    const valueInfo = this._dimensionInner(d);
    if (!valueInfo.translate) {
      return valueInfo.value;
    }
    const translator = this._options?.translateMicroValues?.(d);
    if (!defined(translator)) {
      return valueInfo.value;
    }
    const value = valueInfo.value;
    if (typeof value === "boolean") {
      logger.error("RowMicro.dimension: unexpected boolean value");
      return value;
    }
    return translator(value) ?? value;
  };

  public override makeLabel(sortedDimensions: string[]): string {
    return sortedDimensions.map((d) => this.dimension(d)).join(", ");
  }
}
