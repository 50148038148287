import { TooltipHost } from "@fluentui/react";

import { FluentIcon } from "../../../../../components/Icons";
import { ChildNodeInfo } from "../../../../../lib/application/files/tree";
import { defined } from "../../../../../lib/core/defined";
import { FileFeatures } from "./features";

const fileIndicatorIconSize = "md";

/** Indicate that this is shared with you rather than owned */
export function SharedIcon(props: { item: ChildNodeInfo }) {
  const item = props.item;
  if (item.isShared) {
    return (
      <FluentIcon
        title="Delad"
        className="color-subtle-dark"
        size={fileIndicatorIconSize}
        name="people"
      ></FluentIcon>
    );
  }
  return <></>;
}

export function FavoriteIcon(props: {
  optimisticStatus: boolean | undefined;
  item: ChildNodeInfo;
  addFavorite: (nodeId: number) => void;
  removeFavorite: (nodeId: number) => void;
}) {
  const { item, addFavorite, removeFavorite, optimisticStatus } = props;
  if ((!defined(optimisticStatus) && item.isFavorite) || optimisticStatus) {
    return (
      <FluentIcon
        title="Avmarkera som favorit"
        className="favorite-icon"
        onClick={() => removeFavorite(item.id)}
        size={fileIndicatorIconSize}
        name="star"
      ></FluentIcon>
    );
  }
  return (
    <FluentIcon
      className="favorite-icon"
      title="Markera som favorit"
      onClick={() => addFavorite(item.id)}
      size={fileIndicatorIconSize}
      name="star-empty"
    ></FluentIcon>
  );
}

export function HelpIcon() {
  return (
    <FluentIcon
      className="color-help-filled"
      name="help"
      size="sm"
    ></FluentIcon>
  );
}

export function SampleLibraryIcon() {
  return <FluentIcon name="git-repo" size="sm"></FluentIcon>;
}

export function FolderOrLibrary(props: {
  item: ChildNodeInfo;
  fileFeatures: FileFeatures;
}) {
  const item = props.item;
  if (item.belongsToSampleLibrary && props.fileFeatures.useSampleLibIcon) {
    return (
      <TooltipHost content="Mapp som tillhör exempelbibliotek">
        <SampleLibraryIcon></SampleLibraryIcon>
      </TooltipHost>
    );
  } else if (item.belongsToHelpLibrary && props.fileFeatures.useHelpIcon) {
    return (
      <TooltipHost content="Mapp som tillhör hjälpbiblioteket">
        <HelpIcon></HelpIcon>
      </TooltipHost>
    );
  }
  return (
    <TooltipHost content="Mapp">
      <FluentIcon
        name="folder-close"
        size="sm"
        className="color-folder"
      ></FluentIcon>
    </TooltipHost>
  );
}
