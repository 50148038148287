import { mapValues } from "lodash";
import { Null, Runtype } from "runtypes";

import { config } from "../../../../config";
import { assertNever } from "../../../core/assert";
import { defined } from "../../../core/defined";
import { SimpleCache } from "../../../core/SimpleCache";
import { voidFunc } from "../../../core/voidFunc";
import { BoundingBox } from "../../../domain/cartography/BoundingBox";
import { Categories } from "../../../domain/categories";
import { GeoTypeMicro } from "../../../domain/geography";
import {
  DateRangeRaw,
  DimensionsResponseV2RT,
  DimensionV2Dto,
  FilterMeasureMicro,
  isActiveFilterMeasureMicro,
  MeasureDates,
  MeasureDatesRT,
  PrimaryMeasureSelectionMicroFull,
  SelectedDimensionsV2,
} from "../../../domain/measure/definitions";
import { MicroResultsType } from "../../../domain/micro/definitions";
import {
  DesoRegsoLookup,
  makeDesoRegsoToPropertiesLookup,
} from "../../../domain/micro/desoLookup";
import { DataValueTypeMicroAll } from "../../../infra/api_responses/dataset";
import {
  ComputedMeasurementType,
  MicroDatasetDto,
  MicroDatasetDtoRT,
  MicroLineDatasetDtoRT,
  MicroMeasureDto,
  MicroMeasureListRT,
  MicroPointDatasetDtoRT,
  MicroPolygonDatasetDtoRT,
  MicroMeasureDtoRT,
} from "../../../infra/api_responses/micro_dataset";
import { HttpResult } from "../../../infra/HttpResult";
import { queryParamsString } from "../../browser/query_params";
import {
  FilterSet,
  MicroDataFilter,
  MicroGeoSelections,
  microSelectionPrimary,
  MicroSettings,
} from "../../state/stats/document-core/core-micro";
import { MicroDataset } from "../../stats/datasets/MicroDataset";
import { authedRequest, decodedAuthedRequest } from "../shared";
import { MicroGeoTree } from "../../stats/shared/MicroGeoTree";
import { DocCardMicro } from "../../state/stats/document-core/core";
import {
  DataLoadError,
  httpErrToDataLoadErr,
} from "../../state/stats/document-core/_core-shared";
import { ResultType } from "../../../core/Result";
import {
  UserDefinedSeriesDto,
  userDefinedSeriesToDto,
} from "./user_defined_series";
import {
  COMPUTATION_INPUT_ROUNDING_SAME_AS_DISPLAY,
  DataOutputSettings,
} from "../../state/stats/document-core/DataOutputSettings";

export function getMicroDimensions(
  measureId: number,
  adminShowDraftData: boolean
): Promise<HttpResult<DimensionV2Dto[] | null>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `mikro/${measureId}/dimensions${
      adminShowDraftData ? "?see_draft_data=true" : ""
    }`,
    undefined,
    "GET",
    Null.Or(DimensionsResponseV2RT)
  );
}

const dimensionsCache = new SimpleCache<HttpResult<DimensionV2Dto[] | null>>(
  50
);
export function getMicroDimensionsWithCache(
  measureId: number,
  adminShowDraftData: boolean
): Promise<HttpResult<DimensionV2Dto[] | null>> {
  const cacheKey = `${measureId}-${adminShowDraftData}`;
  const cached = dimensionsCache.get(cacheKey);
  if (defined(cached)) {
    return Promise.resolve(cached);
  }
  return getMicroDimensions(measureId, adminShowDraftData).then((res) => {
    res.match({
      ok: () => {
        dimensionsCache.set(cacheKey, res);
      },
      err: voidFunc,
    });

    return res;
  });
}

function extractFilterFields(singleFilter: MicroDataFilter): any {
  const fields: { [key: string]: any } = {
    type: singleFilter.type,
  };

  switch (singleFilter.type) {
    case "belowAvg":
    case "aboveAvg":
      return fields;
    case "bottomX":
    case "topX":
    case "topXPercent":
    case "bottomXPercent":
    case "gte":
    case "lte":
      return { ...fields, value: singleFilter.value };
    case "interval":
    case "intervalPercent":
      return { ...fields, gte: singleFilter.gte, lte: singleFilter.lte };
  }
  assertNever(singleFilter);
}

export const getMicroPointDatasetDto = microGeoDatasetGetter(
  MicroPointDatasetDtoRT
);
export const getMicroPolygonDatasetDto = microGeoDatasetGetter(
  MicroPolygonDatasetDtoRT
);
export const getMicroLineDatasetDto = microGeoDatasetGetter(
  MicroLineDatasetDtoRT
);

export type GeoDatasetFetcher<ReturnType> = (
  id: number,
  timeSelection: DateRangeRaw,
  dimensions: SelectedDimensionsV2,
  bounds: BoundingBox,
  restrictToGeocodes: string[] | undefined,
  adminSeeDraftData: boolean
) => Promise<HttpResult<ReturnType>>;

/**
 * Generic function for creating dataset getters with correct types
 */
function microGeoDatasetGetter<ReturnType>(
  decoder: Runtype<ReturnType>
): GeoDatasetFetcher<ReturnType> {
  return (
    id,
    timeSelection,
    dimensions,
    bounds,
    restrictToGeocodes,
    adminSeeDraftData
  ) => {
    return authedRequest(
      config.apis.statsV1,
      `mikro/geometry/query`,
      {
        mikro_id: id,
        date_start: timeSelection[0],
        date_end: timeSelection[1],
        dimensions,
        geocodes: restrictToGeocodes,
        bounding_box: bounds.geoJsonBbox(),
        see_draft_data: adminSeeDraftData,
      },
      "POST"
    ).then((res) => {
      return res.match({
        ok: (data) => {
          if (!defined(data)) {
            return HttpResult.fromErr<ReturnType>({
              code: "not-found",
            });
          }
          return res.map(decoder.check) as HttpResult<ReturnType>;
        },
        err: (err) => {
          return HttpResult.fromErr(err);
        },
      });
    });
  };
}

/**
 * Interface for requesting data from server
 */
export interface DataGroup {
  label: string;
  group_id: string;
  geocodes: string[];
}
export function getMicroDatasetDto(
  id: number,
  dateStart: string,
  dateEnd: string,
  dimensions: SelectedDimensionsV2,
  dataGroups: DataGroup[],
  mainFilterSet: FilterSet | undefined,
  filterMeasures: FilterMeasureMicro[] | undefined,
  adminSeeDraftData: boolean,
  translateGeocodesToPostalCodes: boolean,
  computedMeasurementType: ComputedMeasurementType | undefined,
  computedMeasurementVariables:
    | {
        [key: string]: number;
      }
    | undefined,
  userDefinedSeries: UserDefinedSeriesDto[],
  numDecimalsForComputation: number | undefined
): Promise<HttpResult<MicroDatasetDto>> {
  const filtersDto = filterMeasures
    ?.filter(isActiveFilterMeasureMicro)
    .map((f) => {
      const singleFilter = f.filterSet.filters[0];
      const computedType = f.measureSelection.measure.computed?.type;
      return {
        mikro_id: f.measureSelection.measure.id,
        computed_measurement_type: computedType,
        computed_measurement_variables:
          f.measureSelection.computedMeasureVariablesConfig,
        dimensions: defined(computedType)
          ? f.measureSelection.selectedDimensions
          : mapValues(f.measureSelection.selectedDimensions, (v) => [v?.[0]]),
        ...extractFilterFields(singleFilter),
      };
    });
  return authedRequest(
    config.apis.statsV1,
    `mikro/query`,
    {
      mikro_id: id,
      computed_measurement_type: computedMeasurementType,
      computed_measurement_variables: computedMeasurementVariables,
      date_start: dateStart,
      date_end: dateEnd,
      dimensions,
      data_groups: dataGroups,
      see_draft_data: adminSeeDraftData,
      translate_geocodes_to_postal_codes: translateGeocodesToPostalCodes,
      user_defined_series: userDefinedSeries,
      user_defined_series_num_decimals_for_calculation:
        numDecimalsForComputation,
      filters: {
        main_filters: defined(mainFilterSet)
          ? mainFilterSet.filters.map(extractFilterFields)
          : undefined,
        related_measurement_filters:
          defined(filtersDto) && filtersDto.length > 0 ? filtersDto : undefined,
      },
    },
    "POST"
  ).then((res) => {
    return res.match({
      ok: (data) => {
        if (!defined(data)) {
          return HttpResult.fromErr<MicroDatasetDto>({
            code: "not-found",
          });
        }
        return HttpResult.fromOk(MicroDatasetDtoRT.check(data));
      },
      err: (err) => {
        return HttpResult.fromErr(err);
      },
    });
  });
}

export function getMicroAvailableDates(
  measureId: number,
  selectedDimensions: SelectedDimensionsV2,
  adminSeeDraftData: boolean
): Promise<HttpResult<MeasureDates>> {
  return authedRequest(
    config.apis.statsV1,
    `mikro/${measureId}/dates`,
    { breakdowns: selectedDimensions, see_draft_data: adminSeeDraftData },
    "POST"
  ).then((res) => {
    return res.map(MeasureDatesRT.check).map((dates) => {
      return dates?.map((d) => d.slice(0, 10)) ?? null;
    });
  });
}

export function getSingleMicroMeasure(
  id: number,
  computedMeasurementType: ComputedMeasurementType | undefined
): Promise<HttpResult<MicroMeasureDto>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `mikro/measures/${id}?${
      defined(computedMeasurementType)
        ? "computed_measurement_type=" + computedMeasurementType
        : ""
    }`,
    undefined,
    "GET",
    MicroMeasureDtoRT
  );
}

export function getMicroMeasures(
  subjectPath: string[],
  adminSeeDraftData: boolean,
  acceptGeoTypes: GeoTypeMicro[],
  acceptValueTypes: DataValueTypeMicroAll[]
): Promise<HttpResult<MicroMeasureDto[]>> {
  const [area, subarea, subject] = subjectPath;
  const queryParams = queryParamsString({
    see_draft_data: adminSeeDraftData ? "true" : "false",
    geo_types: acceptGeoTypes.join(","),
    value_types: acceptValueTypes.join(","),
    area,
    subarea,
    subject,
  });
  return decodedAuthedRequest(
    config.apis.statsV1,
    `mikro/measures?${queryParams}`,
    undefined,
    "GET",
    MicroMeasureListRT
  );
}

const measuresListCache = new SimpleCache<MicroMeasureDto[]>(40);
export function getMicroMeasuresWithCache(
  subjectPath: string[],
  adminSeeDraftData: boolean,
  /** Empty means all geotypes accepted */
  acceptGeoTypes: GeoTypeMicro[],
  /** Empty means all value types accepted */
  acceptValueTypes: DataValueTypeMicroAll[]
): Promise<HttpResult<MicroMeasureDto[]>> {
  const cacheKey =
    subjectPath.join("-") +
    adminSeeDraftData.toString() +
    acceptGeoTypes.join("-") +
    acceptValueTypes.join("-");
  const cached = measuresListCache.get(cacheKey);
  if (defined(cached)) {
    return Promise.resolve(HttpResult.fromOk(cached));
  }
  return getMicroMeasures(
    subjectPath,
    adminSeeDraftData,
    acceptGeoTypes,
    acceptValueTypes
  ).then((res) => {
    res.match({
      ok: (data) => {
        measuresListCache.set(cacheKey, data);
      },
      err: voidFunc,
    });
    return res;
  });
}

export function getMicroCategories(
  adminSeeDraftData: boolean,
  acceptGeoTypes: GeoTypeMicro[],
  acceptValueTypes: DataValueTypeMicroAll[]
): Promise<HttpResult<Categories>> {
  const queryParams = queryParamsString({
    see_draft_data: adminSeeDraftData ? "true" : "false",
    geo_types: acceptGeoTypes.join(","),
    value_types: acceptValueTypes.join(","),
  });
  return authedRequest<Categories>(
    config.apis.statsV1,
    `mikro/categories?${queryParams}`,
    undefined,
    "GET"
  );
}

const categoriesCache = new SimpleCache<HttpResult<Categories>>(2);
export function getMicroCategoriesWithCache(
  adminSeeDraftData: boolean,
  acceptGeoTypes: GeoTypeMicro[],
  acceptValueTypes: DataValueTypeMicroAll[]
): Promise<HttpResult<Categories>> {
  const cacheKey =
    "micro-categories_" +
    adminSeeDraftData.toString() +
    "_" +
    acceptGeoTypes.join(",") +
    "_" +
    acceptValueTypes.join(",");
  const cached = categoriesCache.get(cacheKey);
  if (defined(cached)) {
    return Promise.resolve(cached);
  }
  return getMicroCategories(
    adminSeeDraftData,
    acceptGeoTypes,
    acceptValueTypes
  ).then((result) => {
    result.match({
      ok: (value) => {
        categoriesCache.set(cacheKey, result);
      },
      err: voidFunc,
    });

    return result;
  });
}

export type MicroQueryResult = ResultType<MicroDataset, DataLoadError>;
export function getMicroDatasetWithCacheV2(
  card: DocCardMicro,
  microGeoTree: MicroGeoTree | undefined,
  showDraftData: boolean,
  isPostalExportMode: boolean
): Promise<MicroQueryResult> {
  const selectedAreas = card.data.geoSelections;
  if (!defined(selectedAreas)) {
    throw new Error(
      "No selected areas -- should not happen. Selections must be complete when making request."
    );
  }
  const primarySelection = microSelectionPrimary(card);
  if (!defined(primarySelection)) {
    throw new Error("No primary selection -- should not happen");
  }
  const { timeSelection, computedMeasureVariablesConfig, selectedDimensions } =
    primarySelection;
  if (!defined(timeSelection)) {
    throw new Error("No time selection -- should not happen");
  }

  const { groups } = selectedAreasToDataGroups(selectedAreas);

  const comparisonType: MicroResultsType =
    selectedAreas.type === "deso"
      ? "compare-units-deso"
      : "compare-units-regso";

  const desoToProperties = makeDesoRegsoToPropertiesLookup(selectedAreas);

  const measure = primarySelection.measure;
  return getMicroDatasetWithCache(
    measure.id,
    comparisonType,
    primarySelection,
    card.data.filterMeasures,
    selectedAreas,
    card.data.settings,
    desoToProperties,
    timeSelection[0],
    timeSelection[1],
    measure.computed?.type,
    computedMeasureVariablesConfig,
    selectedDimensions,
    groups,
    microGeoTree,
    showDraftData,
    isPostalExportMode
  ).then((res) => {
    return res.match({
      ok: (dataset) => {
        return { type: "ok", data: dataset };
      },
      err: (err) => {
        return { type: "err", error: httpErrToDataLoadErr(err) };
      },
    });
  });
}

export function getNumDecimalsForComputationMicro(
  settings: DataOutputSettings
): number | undefined {
  return settings.computedVariablesV3.length > 0 &&
    settings.computationInputRounding ===
      COMPUTATION_INPUT_ROUNDING_SAME_AS_DISPLAY
    ? settings.fixedNumDecimals ?? undefined
    : undefined;
}

const datasetsCache = new SimpleCache<MicroDataset>(20);
export function getMicroDatasetWithCache(
  id: number,
  comparisonType: MicroResultsType,
  measureSelection: PrimaryMeasureSelectionMicroFull,
  filterMeasures: FilterMeasureMicro[] | undefined,
  geoSelection: MicroGeoSelections | undefined,
  settings: MicroSettings,
  desoRegsoToProperties: DesoRegsoLookup,
  dateStart: string,
  dateEnd: string,
  computedMeasurementType: ComputedMeasurementType | undefined,
  computedMeasurementVariables:
    | {
        [key: string]: number;
      }
    | undefined,
  dimensions: SelectedDimensionsV2,
  dataGroups: DataGroup[],
  microGeoTree: MicroGeoTree | undefined,
  adminSeeDraftData: boolean,
  isPostalExportMode: boolean
): Promise<HttpResult<MicroDataset>> {
  const numDecimalsForComputation = getNumDecimalsForComputationMicro(
    settings.dataOutputSettings
  );

  const args = [
    id,
    dateStart,
    dateEnd,
    dimensions,
    dataGroups,
    measureSelection.filterSet,
    filterMeasures,
    adminSeeDraftData,
    isPostalExportMode,
    computedMeasurementType,
    computedMeasurementVariables,
    userDefinedSeriesToDto(settings.dataOutputSettings.computedVariablesV3),
    numDecimalsForComputation,
  ] as const;
  const cacheKey = JSON.stringify(args);
  const cached = datasetsCache.get(cacheKey);
  if (defined(cached)) {
    return Promise.resolve(
      HttpResult.fromOk(cached.copyWithSettings(settings.dataOutputSettings))
    );
  }
  return getMicroDatasetDto(...args).then((res) =>
    res.map((dto) => {
      const dataset = new MicroDataset(
        measureSelection,
        geoSelection,
        settings,
        dto,
        comparisonType,
        desoRegsoToProperties,
        microGeoTree,
        isPostalExportMode
      );
      datasetsCache.set(cacheKey, dataset);
      return dataset;
    })
  );
}

function selectedAreasToDataGroups(selection: MicroGeoSelections): {
  groups: DataGroup[];
  singleUnitsOnly: boolean;
} {
  const anonymousGeocodes: string[] = [];
  const dataGroups: DataGroup[] = [];
  let singleDesoGroupsOnly = false;

  if (selection.type === "deso") {
    for (const area of selection.selected) {
      if (area.type === "deso") {
        anonymousGeocodes.push(area.props.deso);
      } else if (area.type === "user-defined") {
        dataGroups.push({
          label: area.groupName,
          geocodes: area.props.map((p) => p.deso),
          group_id: area.groupId,
        });
      }
    }
  } else if (selection.type === "regso") {
    for (const area of selection.selected) {
      if (area.type === "regso") {
        anonymousGeocodes.push(area.props.regso);
      } else if (area.type === "user-defined") {
        dataGroups.push({
          label: area.groupName,
          geocodes: area.props.map((p) => p.regso),
          group_id: area.groupId,
        });
      }
    }
  }

  if (anonymousGeocodes.length > 0) {
    dataGroups.push(
      ...anonymousGeocodes.map((deso, i) => ({
        label: "Anonymous-" + i,
        geocodes: [deso],
        group_id: "anonymous-" + i,
      }))
    );
    singleDesoGroupsOnly = true;
  }

  return { groups: dataGroups, singleUnitsOnly: singleDesoGroupsOnly };
}
