export function last<T>(items: T[] | readonly T[]): T | undefined {
  if (items.length === 0) {
    return;
  }
  return items[items.length - 1];
}

/**
 * Returns true if the item is identical (referential identity) to the last item in @arr.
 */
export function isLast<T>(arr: readonly T[], item: T): boolean {
  return arr.indexOf(item) === arr.length - 1;
}
