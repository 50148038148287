/**
 * Covers functionality for both MicroCard and DataCard
 */

import {
  DefaultValue,
  GetRecoilValue,
  selectorFamily,
  SetRecoilState,
} from "recoil";
import { assertNever } from "../../../../../core/assert";
import { defined } from "../../../../../core/defined";
import { DateRangeRaw } from "../../../../../domain/measure/definitions";
import { logger } from "../../../../../infra/logging";
import { replaceInArrayImmut } from "../../../generic";
import {
  CardIdParams,
  DocCardMicro,
  DocCardState,
  DocCardStats,
  getCardTimeSelectionMode,
  TimeSelectionMode,
} from "../core";
import { microSelectionPrimary } from "../core-micro";
import { getCardOrThrow, updateCardOrThrow } from "./card";
import { setDataCardOrThrow } from "./dataCard";
import { setMicroCardorThrow } from "./microCard";
import { availableTimespanDataCard, SelectableTime } from "./shared";
import { DataOutputSettings } from "../DataOutputSettings";

type DataOrMicro = DocCardStats | DocCardMicro;

function isDataOrMicro(card: DocCardState): card is DataOrMicro {
  return card.type === "dataCard" || card.type === "microCard";
}

function getGeneralDataCardOrThrow(
  get: GetRecoilValue,
  id: string
): DataOrMicro {
  const card = getCardOrThrow(get, id);
  if (!isDataOrMicro(card)) {
    throw new Error("Found card, but was not of type (dataCard | microCard)");
  }
  return card;
}

function setGeneralDataCardOrThrow(
  set: SetRecoilState,
  id: string,
  updater: (card: DataOrMicro) => DataOrMicro
) {
  updateCardOrThrow(set, id, (card) => {
    if (!isDataOrMicro(card)) {
      throw new Error("Found card, but was not of type (dataCard | microCard)");
    }
    return updater(card);
  });
}

export const dataSettingsQuery = selectorFamily<
  DataOutputSettings,
  CardIdParams
>({
  key: "dataSettingsQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getGeneralDataCardOrThrow(get, params.cardStateId);
      switch (card.type) {
        case "dataCard":
          return card.data.settings;
        case "microCard":
          return card.data.settings.dataOutputSettings;
        default:
          assertNever(card);
      }
    },
});

export const lockToLatestTimeQuery = selectorFamily<boolean, CardIdParams>({
  key: "lockToLatestTimeQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getGeneralDataCardOrThrow(get, params.cardStateId);
      switch (card.type) {
        case "dataCard":
        case "microCard":
          return card.data.lockToLatestTime ?? false;
        default:
          assertNever(card);
      }
    },
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error(
          "DefaultValue not implemented for lockToLatestTimeQuery"
        );
      }
      setGeneralDataCardOrThrow(set, params.cardStateId, (card) => {
        switch (card.type) {
          case "dataCard":
            return {
              ...card,
              data: {
                ...card.data,
                lockToLatestTime: newValue,
              },
            };
          case "microCard":
            return {
              ...card,
              data: {
                ...card.data,
                lockToLatestTime: newValue,
              },
            };
          default:
            assertNever(card);
        }
      });
    },
});

export const timeResolutionQuery = selectorFamily<
  string | undefined,
  CardIdParams
>({
  key: "resolutionQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getGeneralDataCardOrThrow(get, params.cardStateId);
      if (card.type === "dataCard") {
        return card.data.dataSelections[0]?.measureSelection?.measure
          .resolution;
      } else if (card.type === "microCard") {
        return microSelectionPrimary(card)?.measure?.timeResolution;
      }
      assertNever(card);
    },
});

export const availableTimespanQuery = selectorFamily<
  SelectableTime | undefined,
  CardIdParams
>({
  key: "availableTimespanQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const cardState = getGeneralDataCardOrThrow(get, params.cardStateId);
      if (cardState.type === "dataCard") {
        return availableTimespanDataCard(cardState);
      } else if (cardState.type === "microCard") {
        return availableTimespanMicroCard(cardState);
      }
    },
});

export const availableDatesQuery = selectorFamily<
  string[] | undefined,
  CardIdParams
>({
  key: "availableDatesQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const cardState = getGeneralDataCardOrThrow(get, params.cardStateId);
      if (cardState.type === "dataCard") {
        return cardState.data.dataSelections[0]?.measureSelection
          ?.availableDates;
      } else if (cardState.type === "microCard") {
        return microSelectionPrimary(cardState)?.availableDates;
      }
    },
});

export const timeSelectionQuery = selectorFamily<
  DateRangeRaw | undefined,
  CardIdParams
>({
  key: "timeSelectionQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getGeneralDataCardOrThrow(get, params.cardStateId);
      if (card.type === "dataCard") {
        return card.data.timeSelection;
      } else if (card.type === "microCard") {
        return microSelectionPrimary(card)?.timeSelection;
      }
      assertNever(card);
    },
  set:
    (params) =>
    ({ get, set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("DefaultValue not implemented for timeSelectionQuery");
      }

      const card = getGeneralDataCardOrThrow(get, params.cardStateId);
      if (card.type === "dataCard") {
        return setDataCardOrThrow(set, params.cardStateId, () => {
          const primarySelection = card.data.dataSelections[0];
          const availableDates =
            primarySelection?.measureSelection?.availableDates;
          return {
            ...card,
            data: {
              ...card.data,
              timeSelection: newValue,
              lockToLatestTime:
                defined(availableDates) && defined(newValue)
                  ? timeSelectionIncludesLatestTime(newValue, availableDates)
                  : card.data.lockToLatestTime,
            },
          };
        });
      } else if (card.type === "microCard") {
        return setMicroCardorThrow(set, params.cardStateId, (card) => {
          return microCardUpdateTimeSelection(card, newValue);
        });
      }
      return assertNever(card);
    },
});

export function microCardUpdateTimeSelection(
  card: DocCardMicro,
  newValue: DateRangeRaw | undefined
): DocCardMicro {
  const dataSelection = microSelectionPrimary(card);
  const prevSelections = card.data.dataSelections;
  if (!defined(dataSelection) || !defined(prevSelections)) {
    return card;
  }
  return {
    ...card,
    data: {
      ...card.data,
      lockToLatestTime: defined(newValue)
        ? timeSelectionIncludesLatestTime(
            newValue,
            dataSelection.availableDates
          )
        : card.data.lockToLatestTime,
      dataSelections: replaceInArrayImmut(
        prevSelections,
        (s) => s.id === dataSelection.id,
        () => ({ ...dataSelection, timeSelection: newValue })
      ),
    },
  };
}

export const timeSelectionModeQuery = selectorFamily<
  TimeSelectionMode,
  CardIdParams
>({
  key: "timeSelectionModeQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const cardState = getGeneralDataCardOrThrow(get, params.cardStateId);
      return getCardTimeSelectionMode(cardState);
    },
});

function availableTimespanMicroCard(
  card: DocCardMicro
): SelectableTime | undefined {
  const dataSelections = card.data.dataSelections;
  if (!defined(dataSelections)) {
    return undefined;
  }

  const availableDates =
    microSelectionPrimary(card)?.availableDates ??
    dataSelections[0]?.availableDates;
  if (!defined(availableDates)) {
    logger.error("availableDates not defined -- should not happen");
    return undefined;
  }
  return { type: "time-points", timePoints: availableDates };
}

function timeSelectionIncludesLatestTime(
  timeSelection: DateRangeRaw,
  availableDates: string[]
) {
  return timeSelection[1] === availableDates[availableDates.length - 1];
}
