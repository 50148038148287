import { IconButton } from "@fluentui/react";
import { uniq } from "lodash";
import React, { useCallback, useContext, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { FoldoutPanelControlled } from "../../../../../../components/FoldoutPanel";
import { FluentIcon } from "../../../../../../components/Icons";
import { LineClear } from "../../../../../../components/LineClear";
import { ShowDraftDataContext } from "../../../../../../lib/application/contexts";
import { useLoadableHttpResource } from "../../../../../../lib/application/hooks/useLoadableResource";
import { useToggle } from "../../../../../../lib/application/hooks/useToggle";
import { getMicroCategoriesWithCache } from "../../../../../../lib/application/requests/datasets/micro";
import { useChangeFilterMeasure } from "../../../../../../lib/application/state/actions/micro/useChangeFilterMeasure";
import { useChangeSubjectPathFilterMeasure } from "../../../../../../lib/application/state/actions/micro/useChangeSubjectPathFilterMeasure";
import { replaceInArrayImmut } from "../../../../../../lib/application/state/generic";
import { DocCardMicro } from "../../../../../../lib/application/state/stats/document-core/core";
import {
  MicroDataFilter,
  MicroSubjectPath,
} from "../../../../../../lib/application/state/stats/document-core/core-micro";
import { singleMicroCardQuery } from "../../../../../../lib/application/state/stats/document-core/queries/microCard";
import { defined } from "../../../../../../lib/core/defined";
import { Categories } from "../../../../../../lib/domain/categories";
import {
  GeoTypeMicro,
  geoTypesMicro,
} from "../../../../../../lib/domain/geography";
import { FilterMeasureMicro } from "../../../../../../lib/domain/measure/definitions";
import {
  ComputedMeasurementType,
  MicroMeasureDto,
} from "../../../../../../lib/infra/api_responses/micro_dataset";
import { StyledDropdownCondensed } from "../../card_general/StyledDropdown";
import { useMicroAvailableMeasures } from "../useMicroAvailableMeasures";
import { DimensionSelectionOuter } from "./DimensionSelectionOuter";

import "./FilterMeasures.scss";
import { FilterSelector } from "./Filters";
import { VariablesConfig } from "./VariablesConfig";
import {
  SelectedMicroMeasures,
  useSelectionInputState,
  useVariablesStateFilterSelection,
} from "./_shared";
import { useFilterCallbacks } from "../../../../../../lib/application/state/actions/micro/filter_hooks";

export function FilterMeasures(props: {
  cardId: string;
  mainMeasureGeoType?: GeoTypeMicro;
  filterMeasures: FilterMeasureMicro[];
  changePending: boolean;
}) {
  const { cardId, mainMeasureGeoType, filterMeasures, changePending } = props;
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const geoType = mainMeasureGeoType;

  const loadCategories = useCallback(() => {
    return getMicroCategoriesWithCache(
      adminShowDraftData,
      !defined(geoType) || geoType === "deso" ? geoTypesMicro : [geoType],
      ["decimal", "integer"]
    );
  }, [adminShowDraftData, geoType]);
  const [microCategoriesResult] = useLoadableHttpResource(loadCategories, [
    loadCategories,
  ]);

  const categories = microCategoriesResult.match({
    ready: (categories) => categories,
    notReady: () => undefined,
  });

  if (!defined(categories)) {
    return null;
  }

  return (
    <FilterMeasuresInner
      categories={categories}
      cardId={cardId}
      filterMeasures={filterMeasures}
      changePending={changePending}
    ></FilterMeasuresInner>
  );
}

function FilterMeasuresInner(props: {
  cardId: string;
  categories: Categories;
  filterMeasures: FilterMeasureMicro[];
  changePending: boolean;
}) {
  const { cardId, filterMeasures, changePending, categories } = props;
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const card = useRecoilValue(singleMicroCardQuery({ cardStateId: cardId }));
  const [filterMeasuresOpen, toggleFilterMeasuresOpen] = useToggle(true);

  const {
    handleSetFilter,
    handleClearBreakdowns,
    handleRemoveFilter,
    handleSetSingleBreakdownValue,
  } = useFilterCallbacks(cardId, filterMeasures);

  const [handleChangeSubjectPath, subjectChangePending] =
    useChangeSubjectPathFilterMeasure(
      cardId,
      true,
      categories,
      adminShowDraftData
    );
  const [handleChangeMeasure, measureChangePending] = useChangeFilterMeasure(
    cardId,
    adminShowDraftData
  );

  const numActiveFilters = filterMeasures.filter((f) => {
    const filters = f.filterSet?.filters;
    return defined(filters) && filters.length > 0;
  }).length;

  const selectedFilterMeasures: SelectedMicroMeasures = useMemo(() => {
    return filterMeasures
      .map((f) => {
        const measureId = f.measureSelection?.measure.id;
        if (!defined(measureId)) {
          return;
        }
        return [measureId, f.measureSelection?.measure.computed?.type] as [
          number,
          ComputedMeasurementType
        ];
      })
      .filter(defined);
  }, [filterMeasures]);

  return (
    <div className="filter-measures-container">
      <FoldoutPanelControlled
        isOpen={filterMeasuresOpen}
        toggleOpen={toggleFilterMeasuresOpen}
        title={
          <h3>
            Filtrera områden{" "}
            {numActiveFilters > 0
              ? ` (aktiva filter: ${numActiveFilters})`
              : ""}
          </h3>
        }
      >
        <div className="filter-measures-list">
          {filterMeasures.map((filterMeasure, index) => (
            <React.Fragment key={filterMeasure.id}>
              {index !== 0 && (
                <div className="filter-divider">
                  <FluentIcon name="arrow-down" size="sm" />
                </div>
              )}
              <SingleFilterMeasure
                selectedFilterMeasures={selectedFilterMeasures}
                index={index}
                card={card}
                handleClearBreakdowns={handleClearBreakdowns}
                handleRemove={handleRemoveFilter}
                handleSetFilter={handleSetFilter}
                handleSetSingleBreakdownValue={handleSetSingleBreakdownValue}
                handleChangeMeasure={handleChangeMeasure}
                handleChangeSubjectPath={handleChangeSubjectPath}
                categories={categories}
                filterMeasure={filterMeasure}
                changePending={
                  changePending || subjectChangePending || measureChangePending
                }
              ></SingleFilterMeasure>
            </React.Fragment>
          ))}
        </div>
      </FoldoutPanelControlled>
    </div>
  );
}

function SingleFilterMeasure(props: {
  handleSetFilter: (
    filterMeasureIndex: number,
    filter: MicroDataFilter
  ) => void;
  index: number;
  card: DocCardMicro;
  handleRemove: (filterMeasureIndex: number) => void;
  handleSetSingleBreakdownValue: (
    filterMeasureIndex: number,
    dataColumn: string,
    key?: number
  ) => void;
  handleChangeMeasure: (
    filterMeasureIndex: number,
    measureId: number,
    computedMeasureType: ComputedMeasurementType | undefined,
    availableMeasures: MicroMeasureDto[]
  ) => void;
  handleChangeSubjectPath: (
    filterMeasureIndex: number,
    subjectPath: MicroSubjectPath
  ) => Promise<unknown>;
  handleClearBreakdowns: (
    filterMeasureIndex: number,
    dataColumn: string
  ) => void;
  selectedFilterMeasures: SelectedMicroMeasures;
  categories: Categories;
  filterMeasure: FilterMeasureMicro;
  changePending: boolean;
}) {
  const {
    index,
    card,
    filterMeasure,
    changePending,
    categories,
    handleChangeMeasure,
    handleChangeSubjectPath,
    handleSetSingleBreakdownValue,
  } = props;

  const measureSelection = filterMeasure.measureSelection;
  const selectedMeasureId = measureSelection?.measure.id;

  const setCard = useSetRecoilState(
    singleMicroCardQuery({ cardStateId: card.id })
  );
  const [isEditingFilter, toggleIsEditingFilter] = useToggle(
    defined(filterMeasure.filterSet) ? false : true
  );

  const {
    isEditingVariables,
    toggleIsEditingVariables,
    handleSetVariablesConfig,
  } = useVariablesStateFilterSelection(props.card.id, index, () => {
    // Default to true only when a variable input is required but currently missing
    return (
      defined(measureSelection) &&
      measureSelection.measure?.computed?.type === "count_within_distance" &&
      !defined(measureSelection.computedMeasureVariablesConfig)
    );
  });

  const handleSetSingleBreakdownKey = useCallback(
    (dataColumn: string, key?: number) => {
      return handleSetSingleBreakdownValue(index, dataColumn, key);
    },
    [handleSetSingleBreakdownValue, index]
  );

  const handleSetFilter = useCallback(
    (filter: MicroDataFilter) => {
      return props.handleSetFilter(index, filter);
    },
    [index, props]
  );

  const handleClearBreakdowns = useCallback(
    (dataColumn: string) => {
      return props.handleClearBreakdowns(index, dataColumn);
    },
    [index, props]
  );

  const adminShowDraftData = useContext(ShowDraftDataContext);

  const geoType = card.data.geoSelections?.type;
  const availableMeasures = useMicroAvailableMeasures(
    filterMeasure.subjectPath,
    adminShowDraftData,
    defined(geoType) ? [geoType] : geoTypesMicro,
    ["decimal", "integer"]
  );

  const subjectChangeAdapted = useCallback(
    (selectionId: string | undefined, microSubjectPath: MicroSubjectPath) => {
      handleChangeSubjectPath(index, microSubjectPath);
    },
    [handleChangeSubjectPath, index]
  );
  const measureChangeAdapted = useCallback(
    (
      selectionId: string | undefined,
      measureId: number,
      computedMeasureType?: ComputedMeasurementType
    ) => {
      handleChangeMeasure(
        index,
        measureId,
        computedMeasureType,
        availableMeasures
      );
    },
    [availableMeasures, handleChangeMeasure, index]
  );

  const handleUpdateBreakdowns = useCallback(
    async (labelId: string, keys: number[], selected: boolean) => {
      if (!defined(measureSelection)) {
        throw new Error("measureSelection is not defined");
      }
      setCard((prev) => {
        if (prev.data.filterMeasures.length === 0) {
          throw new Error("Expected dataSelection");
        }

        return {
          ...prev,
          data: {
            ...prev.data,
            filterMeasures: replaceInArrayImmut(
              prev.data.filterMeasures,
              (s) => s.id === filterMeasure.id,
              (prevSel) => {
                const mSel = prevSel.measureSelection;
                if (!defined(mSel)) {
                  return prevSel;
                }
                return {
                  ...prevSel,
                  measureSelection: {
                    ...mSel,
                    selectedDimensions: {
                      ...mSel?.selectedDimensions,
                      [labelId]: selected
                        ? uniq([
                            ...(mSel?.selectedDimensions?.[labelId] ?? []),
                            ...keys,
                          ])
                        : (mSel?.selectedDimensions?.[labelId] ?? []).filter(
                            (k) => !keys.includes(k)
                          ),
                    },
                  },
                };
              }
            ),
          },
        };
      });
    },
    [measureSelection, setCard, filterMeasure.id]
  );

  const mockSelection = useMemo(() => {
    return {
      id: card.data.filterMeasures[index].id,
      subjectPath: card.data.filterMeasures[index].subjectPath ?? [
        undefined,
        undefined,
        undefined,
      ],
      measure: card.data.filterMeasures[index].measureSelection?.measure,
    };
  }, [card.data.filterMeasures, index]);

  const {
    areasInputProps,
    subareasInputProps,
    subjectsInputProps,
    measureInputProps,
  } = useSelectionInputState(
    changePending,
    mockSelection,
    subjectChangeAdapted,
    measureChangeAdapted,
    availableMeasures,
    props.selectedFilterMeasures,
    categories
  );

  return (
    <div className="single-filter-container">
      <div className="single-filter-measure">
        <StyledDropdownCondensed
          className="item area"
          dropdownWidth="auto"
          placeholder="Område"
          {...areasInputProps}
        />
        <StyledDropdownCondensed
          className="item"
          dropdownWidth="auto"
          placeholder="Delområde"
          {...subareasInputProps}
        />
        <StyledDropdownCondensed
          className="item"
          dropdownWidth="auto"
          placeholder="Ämne"
          {...subjectsInputProps}
        />
        <StyledDropdownCondensed
          className="measure-dropdown item"
          placeholder="Mått"
          dropdownWidth="auto"
          {...measureInputProps}
        ></StyledDropdownCondensed>

        <LineClear></LineClear>

        {defined(selectedMeasureId) && (
          <div className="dimension-selection-and-filter">
            <DimensionSelectionOuter
              changePending={changePending}
              multiSelect={defined(measureSelection?.measure.computed)}
              measureId={selectedMeasureId}
              handleSetSingleBreakdownKey={handleSetSingleBreakdownKey}
              selectedBreakdowns={measureSelection?.selectedDimensions ?? {}}
              handleClearBreakdowns={handleClearBreakdowns}
              handleSetBreakdowns={handleUpdateBreakdowns}
            ></DimensionSelectionOuter>
            {defined(measureSelection) &&
              defined(measureSelection.measure.computed) &&
              defined(measureSelection.measure.computed.variables) && (
                <VariablesConfig
                  isEditingVariables={isEditingVariables}
                  toggleIsEditingVariables={toggleIsEditingVariables}
                  handleSetConfig={handleSetVariablesConfig}
                  config={measureSelection.computedMeasureVariablesConfig}
                  variablesSpec={measureSelection.measure.computed.variables}
                ></VariablesConfig>
              )}
            <FilterSelector
              isEditingFilter={isEditingFilter}
              toggleIsEditingFilter={toggleIsEditingFilter}
              handleSetFilter={handleSetFilter}
              filterSet={filterMeasure.filterSet}
            ></FilterSelector>
          </div>
        )}
      </div>
      <IconButton
        iconProps={{ iconName: "cross" }}
        onClick={() => props.handleRemove(index)}
      ></IconButton>
    </div>
  );
}
