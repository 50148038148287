export function reverseString(arg: string): string {
  const result: string[] = [];
  for (let i = 0; i < arg.length; i++) {
    result.push(arg.charAt(i));
  }
  return result.reverse().join("");
}

export function stripAnyPrefixes(arg: string, prefixes: string[]): string {
  for (const prefix of prefixes) {
    if (arg.startsWith(prefix)) {
      return arg.slice(prefix.length);
    }
  }
  return arg;
}
