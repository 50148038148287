import { selectorFamily } from "recoil";
import { defined } from "../../../../core/defined";
import { getCardOrThrow } from "../document-core/queries/card";
import { docThumbnailsAtomFamily } from "./atoms";
import { assertNever } from "../../../../core/assert";

type CardId = string;
export type SingleCardThumbnail = {
  cardId: CardId;
} & (
  | {
      type: "chart";
      imageDataUri: string;
    }
  | { type: "chart-placeholder" }
  | { type: "table" }
  | { type: "python" }
  | { type: "text-ck" }
  | { type: "micro" }
  | { type: "error" }
);

export interface DocumentThumbnailsState {
  thumbnails: SingleCardThumbnail[];
  version: number;
}

export const thumbnailQuery = selectorFamily<
  SingleCardThumbnail | undefined,
  CardId
>({
  key: "thumbnailQuery",
  get:
    (cardId) =>
    ({ get }) => {
      const card = getCardOrThrow(get, cardId);
      if (card.type === "dataCard" && card.data.selectedView === "table") {
        return { cardId: cardId, type: "table" };
      }
      if (card.type === "microCard") {
        return { cardId: cardId, type: "micro" };
      }
      if (card.type === "textCardCK") {
        return { cardId: cardId, type: "text-ck" };
      }
      const thumbnail = get(docThumbnailsAtomFamily(cardId));
      return thumbnail;
    },
  set:
    (cardId) =>
    ({ set }, newValue) => {
      set(docThumbnailsAtomFamily(cardId), newValue);
    },
});

export const allThumbnailsQuery = selectorFamily<
  SingleCardThumbnail[],
  string[]
>({
  key: "allThumbnailsQuery",
  get:
    (cardIds) =>
    ({ get }) => {
      return cardIds
        .map<SingleCardThumbnail | undefined>((cardId) => {
          const thumbnail = get(thumbnailQuery(cardId));
          if (defined(thumbnail)) {
            return thumbnail;
          }

          const card = getCardOrThrow(get, cardId);
          switch (card.type) {
            case "error":
              return { cardId: cardId, type: "error" };
            case "dataCard":
              if (card.data.selectedView === "table") {
                return { cardId: cardId, type: "table" };
              }
              if (card.data.selectedView === "diagram") {
                return { cardId: cardId, type: "chart-placeholder" };
              }
              return { cardId: cardId, type: "chart-placeholder" };
            case "microCard":
              return { cardId: cardId, type: "micro" };
            case "textCardCK":
            case "textCardSimple":
              return { cardId: cardId, type: "text-ck" };
            case "pythonCard":
              return { cardId: cardId, type: "python" };
            case "microCardImage":
              throw new Error(
                "microCardImage should not exist in writable doc"
              );
            default:
              assertNever(card);
          }
        })
        .filter(defined);
    },
});
