import _ from "lodash";

import { combinations } from "../../../../core/combinations";
import { defined } from "../../../../core/defined";
import { Dictionary } from "../../../../core/dictionary";
import { mapOptional } from "../../../../core/func/mapOptional";
import { applySortSpec, SortSpec } from "../../../../domain/measure";
import { BREAKDOWN_ALL_LABEL } from "../../../../domain/measure/definitions";
import { SurveyRowValueRaw } from "../../../../infra/api_responses/survey_dataset";
import { getFormatter, getRounder } from "../../format";
import { getSourceInfoCommonHeader } from "../../shared/charts_common";
import { Dimension } from "../../shared/core/definitions";
import { DataDescription } from "../../shared/DataDescription";
import {
  anyDimensionLabelComparatorAsc,
  defaultDimensionComparator,
  defaultDimensionLabelComparatorAsc,
  fixedNonPrimaryDimensionsValid,
} from "../../shared/dimensions";
import { RowBaseInterface } from "../../shared/row";
import { DataCellValue } from "../DataCellValue";
import { DatasetHeaderSurvey, DatasetInput, MainHeaderData } from "../headers";
import {
  columnRowsWithCustomLabels,
  getComputedValueOutputSettings,
  getDimensionFormatter,
  getSortedPrimaryDimensionKeys,
  getSummaryRows,
  getSuperColumnRows,
  getValueComparatorAscending,
  joinDimensions,
  LabelAndDimension,
  tableRowsWithCustomLabels,
} from "./table_base/table_base";
import { DataOutputSettings } from "../../../state/stats/document-core/DataOutputSettings";
import {
  TableRow,
  ComputedValueOutputSettings,
  CellValueWithOutputSettings,
  TableSpec,
  SuperColumnsRow,
} from "./table_base/table_base_definitions";
import { SortOrder, sortOrderEnum } from "../../../../core/order";
import { applySortOrder, applySortOrderPrimary } from "./table_base/sort";

export function surveyDatasetToTable<
  T extends RowBaseInterface<SurveyRowValueRaw>,
  U extends DatasetHeaderSurvey
>(
  dataset: DatasetInput<T, U>,
  dataDescription: DataDescription,
  /**
   * All the dimensions that specify the data but not the value (range)
   */
  nonValueDataDimensions: string[],
  dateFormatter: (input: string) => string,
  fixedSortSpecs: Dictionary<SortSpec>,
  showReferenceValues: boolean,
  showSurveyValueFraction: boolean,
  getDimensionHeader: (dimension: string) => string | undefined,
  settings: DataOutputSettings,
  isSurveyFilterDimension?: (dim: string) => boolean
): TableSpec {
  const computedValueOutputSettings = getComputedValueOutputSettings(
    dataset.header.valueType,
    settings
  );
  const fixedNonPrimaryDimensions = settings.fixedDimensionOrder ?? undefined;
  const validFixedNonPrimaryDimensions = defined(fixedNonPrimaryDimensions)
    ? fixedNonPrimaryDimensionsValid(
        nonValueDataDimensions,
        fixedNonPrimaryDimensions,
        false
      )
      ? fixedNonPrimaryDimensions
      : undefined
    : undefined;

  function filterDimensionDefault(dimension: string) {
    return isSurveyFilterDimension?.(dimension)
      ? BREAKDOWN_ALL_LABEL
      : undefined;
  }

  const header = dataset.header;
  const rows = dataset.rows;
  const nonValueDimensions = nonValueDataDimensions;
  const isSingleValue = nonValueDataDimensions.length === 0;

  // Group by dimension, subgroup by each dimension's labels.
  // Filter out rows that have no value for a dimension,
  // such as reference rows which have no values for any breakdowns
  const rowsByDimensionAndLabels = _.chain(nonValueDimensions)
    .map((dim) => {
      return [
        dim,
        _.groupBy(
          rows.filter(
            (r) =>
              defined(r.dimension(dim)) ||
              (isSurveyFilterDimension?.(dim) ?? false)
          ),
          (r) => r.dimension(dim) ?? filterDimensionDefault(dim)
        ),
      ];
    })
    .fromPairs()
    .value();

  /**
   * "Row-wise" dimension. This dimension holds the row titles.
   */
  const primaryDimension: string | undefined = defined(
    validFixedNonPrimaryDimensions
  )
    ? validFixedNonPrimaryDimensions[0]
    : (_.chain(nonValueDimensions)
        .maxBy((dim) => Object.keys(rowsByDimensionAndLabels[dim]).length ?? 0)
        .value() as string | undefined);
  const primaryDimensionHeader = defined(primaryDimension)
    ? getDimensionHeader(primaryDimension)
    : undefined;

  const primaryComparatorAscending = defined(primaryDimension)
    ? anyDimensionLabelComparatorAsc(primaryDimension)
    : undefined;

  /**
   * These dimensions, appear in the table headers, in order.
   */
  const nonPrimaryDimensions = defined(validFixedNonPrimaryDimensions)
    ? validFixedNonPrimaryDimensions.slice(1)
    : nonValueDimensions
        .filter((dim) => dim !== primaryDimension)
        .sort(defaultDimensionComparator);

  const nonPrimaryDimensionVariants: string[][] = nonPrimaryDimensions.map(
    (dim) => {
      const sortedLabels = Object.keys(rowsByDimensionAndLabels[dim]).sort(
        defaultDimensionLabelComparatorAsc(dim)
      );

      const sortSpec = fixedSortSpecs[dim];
      return defined(sortSpec)
        ? applySortSpec(sortedLabels, sortSpec).merged()
        : sortedLabels;
    }
  );

  // Build the combinations of dimension variants
  // Example:
  // Kön: Man, Kvinna
  // Ålder: 0-4, 4-9
  // results in combinations
  // [[Man, 0-4], [Man, 4-9], [Kvinna, 0-4], [Kvinna, 4-9]]

  const allVariants = nonPrimaryDimensionVariants.map((variants, i) =>
    variants.map((v) => ({
      label: v,
      dimension: nonPrimaryDimensions[i],
    }))
  );
  const variantCombinations: LabelAndDimension[][] = combinations(
    allVariants
  ).filter((combo) => {
    return !settings.hiddenBreakdownCombinations.some((hiddenCombo) =>
      hiddenCombo.every((d) =>
        combo.some((c) => c.dimension === d.dimension && c.label === d.value)
      )
    );
  });

  function rowKey(row: (typeof rows)[0]) {
    return joinDimensions(
      nonPrimaryDimensions.map((dim) => {
        const value = row.dimension(dim) ?? filterDimensionDefault(dim);
        return typeof value === "string" ? value : value?.toString() ?? "";
      })
    );
  }
  const groupedRows = _.groupBy(rows, rowKey);

  /**
   * Rows are sorted by primary dimension keys -- row labels. Here we make the default sort order.
   * The sort order is given by the sort order specified for the dimension, or if none specified for certain items, by value.
   */
  const primaryDimensionKeys: string[] = getSortedPrimaryDimensionKeys(
    rows,
    (row, dimension: string) =>
      (row.dimension(dimension) as string | number) ??
      filterDimensionDefault(dimension),
    primaryDimension,
    mapOptional((dim) => fixedSortSpecs[dim], primaryDimension),
    variantCombinations
  );

  const dimensionFormatter = getDimensionFormatter(dateFormatter);
  const valueType = header.valueType;

  const comparatorAscending = getValueComparatorAscending(valueType);
  const numDecimals = showSurveyValueFraction ? 1 : 0;
  const valueFormatter = getFormatter(valueType, numDecimals);
  const valueRounder =
    valueType === "category" ? undefined : getRounder(valueType, numDecimals);
  const commonColumnFields = {
    type: valueType,
    format: valueFormatter,
    comparatorAscending,
    round: valueRounder,
  };

  const columnRowsOriginal: SuperColumnsRow[] = getSuperColumnRows(
    variantCombinations,
    dimensionFormatter
  );
  const columnRows = columnRowsWithCustomLabels(columnRowsOriginal, settings);

  const hasLowBase = rows.some((r) => r.type() === "low_base");
  const groupHeader = dataset.groupHeader;

  const tableRowsOriginal: TableRow[] = !defined(primaryDimension)
    ? isSingleValue
      ? [
          getSingleTableRow(
            header,
            rows,
            dimensionFormatter,
            computedValueOutputSettings
          ),
        ]
      : []
    : primaryDimensionKeys.map((point) => {
        const matchesPrimaryDimensionKey = (r: (typeof rows)[0]) =>
          (r.dimension(primaryDimension) ??
            filterDimensionDefault(primaryDimension)) === point;

        return {
          label: dimensionFormatter(primaryDimension, point.toString()),
          className: primaryDimension === Dimension.date ? "date" : undefined,
          values:
            variantCombinations.length === 0
              ? [
                  renderRowValue(
                    rows.find(matchesPrimaryDimensionKey),
                    computedValueOutputSettings
                  ),
                ]
              : variantCombinations.map((combo) => {
                  const key = joinDimensions(combo.map((c) => c.label));
                  const found = groupedRows[key]?.find(
                    matchesPrimaryDimensionKey
                  );
                  if (defined(found)) {
                    return renderRowValue(found, computedValueOutputSettings);
                  }
                  return DataCellValue.nan("no_value");
                }),
        };
      });
  const tableRows = tableRowsWithCustomLabels(
    tableRowsOriginal,
    primaryDimension,
    settings
  );
  const lastColumnRow = _.last(columnRows);
  const primaryDimensionInfo = defined(primaryDimension)
    ? {
        dimension: primaryDimension,
        header: primaryDimensionHeader,
        comparatorAscending: primaryComparatorAscending,
      }
    : undefined;
  const columnSort = settings.table.columnSort;
  const primaryComparator = primaryDimensionInfo?.comparatorAscending;
  const primarySortOrder: SortOrder | undefined =
    columnSort?.type === "primary"
      ? sortOrderEnum(columnSort.order)
      : undefined;

  const columnRow = defined(lastColumnRow)
    ? {
        dimension: lastColumnRow.dimension,
        columns: lastColumnRow.columns.map((column) => ({
          ...column,
          ...commonColumnFields,
        })),
      }
    : {
        dimension: "",
        columns: [
          // If no regular columns, we only have a single column of values. Use the unit as header.
          {
            text: dataDescription.unitHeader ?? "--",
            key: "single-col-key",
            ...commonColumnFields,
          },
        ],
      };

  const sortSetting =
    columnSort?.type === "secondary"
      ? {
          columnIndex: columnSort.columnIndex,
          order: sortOrderEnum(columnSort.order),
        }
      : undefined;

  const sortedRows = defined(sortSetting)
    ? applySortOrder(tableRows, columnRow, sortSetting)
    : defined(primarySortOrder) && defined(primaryComparator)
    ? applySortOrderPrimary(tableRows, primarySortOrder, primaryComparator)
    : tableRows;

  return {
    primaryDimensionInfo,
    allDimensions: dataset.dimensionsSpec.variable,
    dimensionsString: `rows: ${rows.length}, cols: ${columnRows.length}`,
    hasLowBaseValue: hasLowBase,
    valueType,
    tableDescription: dataDescription,
    sourceInfo: getSourceInfoCommonHeader(header),
    customSourceText: settings.customSourceText,
    groupingSourceInfo: defined(groupHeader)
      ? getSourceInfoCommonHeader(groupHeader)
      : undefined,
    header: {
      superColumnRows: columnRows.slice(0, columnRows.length - 1),
      columnRow,
    },
    rows: sortedRows,
    summaryRows:
      valueType !== "category"
        ? getSummaryRows(
            settings.table,
            tableRows,
            valueRounder,
            valueFormatter
          )
        : undefined,
  };
}

function renderRowValue<T>(
  found: RowBaseInterface<T> | undefined,
  computedValueOutputSettings: ComputedValueOutputSettings
): DataCellValue<CellValueWithOutputSettings> {
  if (!defined(found)) {
    return DataCellValue.nan("no_value");
  }
  const statusType = found.type();
  if (statusType === "low_base") {
    return DataCellValue.nan("low_base");
  } else if (statusType === "invalid_choice") {
    return DataCellValue.nan("no_value");
  }
  const value = found.range();
  if (found.isUserDefined) {
    return DataCellValue.ok({
      value: (value ?? "").toString(),
      ...computedValueOutputSettings,
    });
  }
  return DataCellValue.ok({ value: (value ?? "").toString() });
}

function getSingleTableRow<U extends MainHeaderData | DatasetHeaderSurvey, V>(
  headers: U,
  rows: RowBaseInterface<V>[],
  dimensionFormatter: (dimension: string, label: string) => string,
  computedValueOutputSettings: ComputedValueOutputSettings
): TableRow {
  if (rows.length !== 1) {
    throw new Error(
      `Unexpected number of rows (${rows.length}) when make single table row`
    );
  }
  const liftedDate = headers.liftedDimensions?.[Dimension.date];
  return {
    label: defined(liftedDate)
      ? dimensionFormatter(Dimension.date, liftedDate)
      : "",
    labelClassName: "date",
    values: [renderRowValue(rows[0], computedValueOutputSettings)],
  };
}
