import { PartialTheme } from "@fluentui/react";

export const fluentUITheme: PartialTheme = {
  defaultFontStyle: { fontFamily: "Selawik" },
  // Color palette generated using the Fluent UI Theming Designer.
  // Based on our brand color and black from our brand palette, but white is #ffffff due to being
  // used for input backgrounds.
  // https://fluentuipr.z22.web.core.windows.net/heads/master/theming-designer/index.html
  palette: {
    themePrimary: "#5a81a7",
    themeLighterAlt: "#f7f9fb",
    themeLighter: "#dfe8f1",
    themeLight: "#c5d4e4",
    themeTertiary: "#92aec9",
    themeSecondary: "#698db0",
    themeDarkAlt: "#517395",
    themeDark: "#44617e",
    themeDarker: "#32475d",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#bbbfc4",
    neutralSecondary: "#7c8289",
    neutralPrimaryAlt: "#474c52",
    neutralPrimary: "#31353a",
    neutralDark: "#25292d",
    black: "#1c1e21",
    white: "#ffffff",
  },
};
