import { Pivot, PivotItem } from "@fluentui/react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RecoilRoot, useRecoilValue, useSetRecoilState } from "recoil";

import { Card } from "../../../components/Card";
import { MainContent } from "../../../components/MainContent";
import {
  defaultBrowserTitle,
  getBrowserTitle,
} from "../../../lib/application/browser/title";
import { useResetAllCardsCallback } from "../../../lib/application/state/actions/cardCallbacks";
import { docCardsListQuery } from "../../../lib/application/state/stats/document-core/docCardsListState";
import { reportMetaStateQuery } from "../../../lib/application/state/stats/document-meta/queries";
import { FileTreeOrLoading } from "./files/FileTree";

import "./Overview.scss";
import {
  addFavorite,
  createFolder,
  readNode,
  getSampleLibraryRoots,
  listFavorites,
  deleteFavorite,
  listShortcuts,
  listNodes,
  getHelpLibraryRoots,
  listDrafts,
  DraftsListingDto,
} from "../../../lib/application/requests/docs/documents";
import { logger } from "../../../lib/infra/logging";
import {
  AppMessagesContext,
  UserInfoContext,
} from "../../../lib/application/contexts";
import { Milliseconds } from "../../../lib/core/time";
import { newDocPath, openDocument } from "../../../lib/paths";
import { getText } from "../../../lib/application/strings";
import {
  FileTreeNavigation,
  OptimisticFavorites,
  useFileTreeNavigation,
} from "../../../lib/application/hooks/useFileTreeNavigation";
import { defined } from "../../../lib/core/defined";
import { InFocusOrLoading } from "./latest/InFocus";
import {
  adminCreateHelpLibraryRootNode,
  adminCreateLibraryShortcut,
  adminCreateSampleLibraryRootNode,
  adminDeleteHelpLibraryRootNode,
  adminDeleteLibraryShortcut,
  adminDeleteSampleLibraryRootNode,
} from "../../../lib/application/requests/admin/libraries";
import {
  FileNodeInfo,
  NodeTypeRegular,
} from "../../../lib/application/files/tree";
import { HttpResult } from "../../../lib/infra/HttpResult";
import { merge } from "lodash";
import { useStateTransition } from "../../../lib/application/hooks/useStateTransition";
import { minimumAccessMatched } from "../../../lib/application/files/SharingInfo";
import { FileFeatures } from "./files/shared/features";
import { MoveDialogNodesMode } from "./files/FileListMoveDialog";
import { DataSearchAndPreview } from "./DataSearchAndPreview";
import { LatestUpdatedMeasures } from "./latest/LatestUpdatedMeasures";
import { DraftsFileListFlat } from "./files/FileListFlat";

const pivotMyDocs = "my-docs";
const pivotFavorites = "favorites";
const pivotSharedDocs = "shared-docs";
const pivotSampleLib = "sample-lib";
const pivotHelp = "help";
const pivotDrafts = "drafts";

const getNodeOrRoot =
  (rootGetter: () => Promise<HttpResult<FileNodeInfo>>) =>
  (nodeId?: number) => {
    return defined(nodeId) ? readNode(nodeId) : rootGetter();
  };

const inFocusViewNodeInfoGetter = getNodeOrRoot(listShortcuts);
const getNodeInfoFavorites = getNodeOrRoot(listFavorites);
const getNodeInfoHelp = getNodeOrRoot(getHelpLibraryRoots);
const getNodeInfoAll = getNodeOrRoot(listNodes);

const sampleLibraryNodeInfoGetter = getNodeOrRoot(getSampleLibraryRoots);

export function DocumentsOverview(props: { openSharedFolder?: number }) {
  const { openSharedFolder } = props;
  const setReportMetaState = useSetRecoilState(reportMetaStateQuery);
  const cards = useRecoilValue(docCardsListQuery);
  const resetAllCards = useResetAllCardsCallback();
  const userInfo = useContext(UserInfoContext);
  const [selectedPivot, setSelectedPivot] = useState<string>(pivotMyDocs);
  const canManageDocLibrary =
    userInfo?.internalCanManageDocumentLibrary() ?? false;

  // Used for going to a specific library folder
  const [selectedLibFolderId, setSelectedLibFolderId] = useState<
    number | undefined
  >();

  useEffect(() => {
    // listenHomeLoaded(runSearchTutorial);
  }, []);

  const getSharedNodesOnly = useCallback((nodeId?: number) => {
    return getNodeInfoAll(nodeId).then((nodeInfo) => {
      return nodeInfo.map((fileNode) => {
        return {
          ...fileNode,
          children: fileNode.children?.filter(
            (child) =>
              child.ownership.type === "shared" ||
              (child.ownership.type === "owner" && child.isShared)
          ),
        };
      });
    });
  }, []);

  const getOwnedNodesOnly = useCallback((nodeId?: number) => {
    return getNodeInfoAll(nodeId).then((nodeInfo) => {
      return nodeInfo.map((fileNode) => {
        return {
          ...fileNode,
          children: fileNode.children?.filter(
            (child) => child.ownership.type === "owner"
          ),
        };
      });
    });
  }, []);

  const [draftsFilesRes, setDraftsFilesRes] = useState<DraftsListingDto>();

  /** Handlers and variables for the all docs views */
  const sampleLibraryView = useFileTreeNavigation(sampleLibraryNodeInfoGetter);
  const allDocsView = useFileTreeNavigation(getOwnedNodesOnly);
  const sharedDocsView = useFileTreeNavigation(getSharedNodesOnly);
  const favoritesView = useFileTreeNavigation(getNodeInfoFavorites);
  const helpView = useFileTreeNavigation(getNodeInfoHelp);
  const inFocusView = useFileTreeNavigation(inFocusViewNodeInfoGetter);
  const pivots: { [key: string]: FileTreeNavigation } = {
    [pivotMyDocs]: allDocsView,
    [pivotFavorites]: favoritesView,
    [pivotSharedDocs]: sharedDocsView,
    [pivotSampleLib]: sampleLibraryView,
    [pivotHelp]: helpView,
  };

  const openSharedDocsFolder = sharedDocsView.handleOpenFolder;

  const loadDraftsFiles = useCallback(() => {
    return listDrafts().then((res) => {
      res.match({
        ok: (f) => setDraftsFilesRes(f ?? undefined),
        err: (err) => {
          logger.error(err);
        },
      });
    });
  }, []);

  useEffect(() => {
    if (selectedPivot === pivotDrafts) {
      loadDraftsFiles();
    }
  }, [selectedPivot, loadDraftsFiles]);

  /**
   * When switching between views, reset the currently open folder of the view
   * being navigated away from
   */
  useStateTransition(selectedPivot, (previous, current) => {
    if (previous !== current && defined(previous)) {
      pivots[previous]?.handleOpenFolder(undefined);
    }
  });

  useEffect(() => {
    if (defined(openSharedFolder)) {
      setSelectedPivot(pivotSharedDocs);
      openSharedDocsFolder(openSharedFolder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Run once

  const optimisticFavorites = useMemo(
    () =>
      [sampleLibraryView, allDocsView, favoritesView]
        .map((view) => view.optimisticFavorites)
        .reduce(merge),
    [allDocsView, favoritesView, sampleLibraryView]
  );

  useEffect(() => {
    document.title = getBrowserTitle("Mina dokument");
    return () => {
      document.title = defaultBrowserTitle();
    };
  });

  useEffect(() => {
    // Reset report state to ensure old report state is not loaded before the actual report state
    setReportMetaState(undefined);
    resetAllCards(cards.map((card) => card.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appMessagesHandler = useContext(AppMessagesContext);

  const handleCreateFolder = useCallback(
    (title: string, parentId?: number) => {
      createFolder(title, parentId).then((res) =>
        res.match({
          ok: allDocsView.loadFiles,
          err: (err) => {
            logger.error(err);
            appMessagesHandler?.add(
              "error",
              "Misslyckades med att skapa ny mapp",
              {
                durationMs: Milliseconds.second * 5,
              }
            );
          },
        })
      );
    },
    [allDocsView, appMessagesHandler]
  );

  const handleCreateNewDoc = useCallback((parentId?: number) => {
    window.open(newDocPath(getText("default-document-name"), parentId));
  }, []);

  useEffect(() => {
    // Clear selectedLibFolderId after it has been set,
    // since it's used for a temporary effect that may be need
    // multiple times.
    if (defined(selectedLibFolderId)) {
      const timeoutHandle = setTimeout(() => {
        setSelectedLibFolderId(undefined);
      }, Milliseconds.second * 0.5);
      return () => {
        clearTimeout(timeoutHandle);
      };
    }
  }, [selectedLibFolderId]);

  const handleSelectPivot = useCallback((key?: string) => {
    if (defined(key)) {
      setSelectedPivot(key);
    }
  }, []);

  const handleUseShortcut = useCallback((nodeId: number) => {
    setSelectedLibFolderId(nodeId);
    setSelectedPivot(pivotSampleLib);
  }, []);

  const myDocsMenuFeatures = useMemo(
    () =>
      getFileListMenuFeatures({
        isAdmin: canManageDocLibrary,
        useLibFeatures: { reloadLib: sampleLibraryView.loadFiles },
        useHelpFeatures: { reloadHelp: helpView.loadFiles },
        useHelpIcon: true,
        useSampleLibIcon: true,
        showSharedWithYouStatus: true,
        useSharingFeatures: true,
        favorites: {
          use: true,
          optimisticFavorites,
          setOptimisticFavorites: allDocsView.setOptimisticFavorites,
          reloadFavorites: favoritesView.loadFiles,
        },
        reloadAllDocs: allDocsView.loadFiles,
        baseFeatures: {
          enableRemove: true,
          enableMove: { mode: "owned-only" },
          enableRename: true,
        },
      }),
    [
      allDocsView.loadFiles,
      allDocsView.setOptimisticFavorites,
      canManageDocLibrary,
      favoritesView.loadFiles,
      helpView.loadFiles,
      optimisticFavorites,
      sampleLibraryView.loadFiles,
    ]
  );

  const sharedDocsMenuFeatures = useMemo(
    () =>
      getFileListMenuFeatures({
        baseFeatures: {
          enableRemove: true,
          enableMove: { mode: "shared-only" },
          enableRename: true,
        },
        showSharedWithYouStatus: true,
        isAdmin: false,
        useSharingFeatures: true,
        useHelpIcon: true,
        useSampleLibIcon: true,
        favorites: {
          use: true,
          optimisticFavorites,
          setOptimisticFavorites: sharedDocsView.setOptimisticFavorites,
          reloadFavorites: sharedDocsView.loadFiles,
        },
        reloadAllDocs: sharedDocsView.loadFiles,
      }),
    [
      optimisticFavorites,
      sharedDocsView.loadFiles,
      sharedDocsView.setOptimisticFavorites,
    ]
  );

  const favoritesMenuFeatures = useMemo(
    () =>
      getFileListMenuFeatures({
        showSharedWithYouStatus: true,
        isAdmin: false,
        useSharingFeatures: true,
        useHelpIcon: true,
        useSampleLibIcon: true,
        favorites: {
          use: true,
          optimisticFavorites,
          setOptimisticFavorites: favoritesView.setOptimisticFavorites,
          reloadFavorites: favoritesView.loadFiles,
        },
        reloadAllDocs: allDocsView.loadFiles,
      }),
    [
      allDocsView.loadFiles,
      favoritesView.loadFiles,
      favoritesView.setOptimisticFavorites,
      optimisticFavorites,
    ]
  );

  const helpMenuFeatures = useMemo(
    () =>
      getFileListMenuFeatures({
        baseFeatures: { enableRemove: false, enableRename: true },
        isAdmin: true,
        favorites: { use: false },
        useHelpFeatures: { reloadHelp: helpView.loadFiles },
        reloadAllDocs: allDocsView.loadFiles,
      }),
    [allDocsView.loadFiles, helpView.loadFiles]
  );

  const sampleLibMenuFeatures = useMemo(
    () =>
      getFileListMenuFeatures({
        baseFeatures: { enableRemove: false, enableRename: true },
        hideAll: !canManageDocLibrary,
        isAdmin: canManageDocLibrary,
        listSortAlphabetical: true,
        useLibFeatures: { reloadLib: sampleLibraryView.loadFiles },
        favorites: {
          use: true,
          optimisticFavorites,
          setOptimisticFavorites: sampleLibraryView.setOptimisticFavorites,
          reloadFavorites: favoritesView.loadFiles,
        },
        reloadAllDocs: allDocsView.loadFiles,
        reloadFocusShortcuts: inFocusView.loadFiles,
      }),
    [
      allDocsView.loadFiles,
      canManageDocLibrary,
      favoritesView.loadFiles,
      inFocusView.loadFiles,
      optimisticFavorites,
      sampleLibraryView.loadFiles,
      sampleLibraryView.setOptimisticFavorites,
    ]
  );

  const draftsMenuFeatures = useMemo(
    () =>
      getFileListMenuFeatures({
        baseFeatures: {
          enableRemove: false,
          enableRename: false,
        },
        isAdmin: false,
        favorites: {
          use: false,
        },
        reloadAllDocs: loadDraftsFiles,
      }),
    [loadDraftsFiles]
  );

  const handleOpenDocument = useCallback(
    (nodeId: number) => openDocument(nodeId),
    []
  );

  return (
    <MainContent className="reports-view">
      <>
        {(userInfo?.hasStatsListAccess() ||
          userInfo?.hasSurveyListAccess()) && (
          <RecoilRoot>
            <DataSearchAndPreview></DataSearchAndPreview>
          </RecoilRoot>
        )}
      </>

      <div className="latest-updates">
        <InFocusOrLoading
          item={inFocusView.fileItemsRes}
          handleOpenDocument={handleOpenDocument}
          handleUseShortcut={handleUseShortcut}
        ></InFocusOrLoading>

        <RecoilRoot>
          <LatestUpdatedMeasures></LatestUpdatedMeasures>
        </RecoilRoot>
      </div>

      <Card className="reports-view-content">
        <h1>Dokument</h1>
        <Pivot
          className="docs-pivot-container"
          onLinkClick={(item) => handleSelectPivot(item?.props.itemKey)}
          selectedKey={selectedPivot}
        >
          <PivotItem itemKey={pivotMyDocs} headerText="Mina">
            <FileTreeOrLoading
              handleNew={(node) => {
                return {
                  file: () =>
                    handleCreateNewDoc(
                      node.type !== "root" ? node.id : undefined
                    ),
                  folder: (title) =>
                    handleCreateFolder(
                      title,
                      node.type !== "root" ? node.id : undefined
                    ),
                };
              }}
              fileFeatures={myDocsMenuFeatures}
              navigationRootName="Mina"
              enableSelection={true}
              handleOpenFolder={allDocsView.handleOpenFolder}
              item={allDocsView.fileItemsRes}
              loadFiles={allDocsView.loadFiles}
            ></FileTreeOrLoading>
          </PivotItem>
          <PivotItem itemKey={pivotDrafts} headerText="Utkast">
            <DraftsFileListFlat
              drafts={draftsFilesRes}
              reloadFiles={loadDraftsFiles}
              handleOpenFile={handleOpenDocument}
              fileFeatures={draftsMenuFeatures}
            />
          </PivotItem>
          <PivotItem itemKey={pivotFavorites} headerText="Favoriter">
            <FileTreeOrLoading
              enableSharingColumn
              fileFeatures={favoritesMenuFeatures}
              navigationRootName="Favoriter"
              enableSelection={false}
              handleOpenFolder={favoritesView.handleOpenFolder}
              item={favoritesView.fileItemsRes}
              loadFiles={favoritesView.loadFiles}
            ></FileTreeOrLoading>
          </PivotItem>
          <PivotItem itemKey={pivotSharedDocs} headerText="Delat">
            <FileTreeOrLoading
              enableSharingColumn
              fileFeatures={sharedDocsMenuFeatures}
              handleNew={
                sharedDocsView.currentFolderNodeId === undefined
                  ? undefined
                  : (node) => {
                      if (
                        node.type === "root" ||
                        !minimumAccessMatched("editor", node.roles)
                      ) {
                        return;
                      }

                      return {
                        file: () => {
                          if (
                            window.confirm(
                              getText("confirm-create-file-in-shared-folder")
                            )
                          ) {
                            handleCreateNewDoc(node.id);
                          }
                        },
                        folder: (title) => {
                          if (defined(node)) {
                            if (
                              window.confirm(
                                getText(
                                  "confirm-create-folder-in-shared-folder"
                                )
                              )
                            ) {
                              handleCreateFolder(title, node.id);
                            }
                          }
                        },
                      };
                    }
              }
              navigationRootName="Delat"
              enableSelection={false}
              handleOpenFolder={sharedDocsView.handleOpenFolder}
              item={sharedDocsView.fileItemsRes}
              loadFiles={sharedDocsView.loadFiles}
            ></FileTreeOrLoading>
          </PivotItem>
          <PivotItem itemKey={pivotSampleLib} headerText="Bibliotek">
            <SampleLibrary
              fileTreeNavigation={sampleLibraryView}
              openNodeId={selectedLibFolderId}
              menuFeatures={sampleLibMenuFeatures}
            ></SampleLibrary>
          </PivotItem>

          <PivotItem
            itemKey={pivotHelp}
            headerText="Hur gör jag?"
            headerButtonProps={{
              iconProps: {
                iconName: "help",
                className: "infostat-fluent-icon color-help-filled",
              },
            }}
          >
            <FileTreeOrLoading
              fileFeatures={helpMenuFeatures}
              navigationRootName="Hur gör jag?"
              enableSelection={false}
              handleOpenFolder={helpView.handleOpenFolder}
              item={helpView.fileItemsRes}
              loadFiles={helpView.loadFiles}
            ></FileTreeOrLoading>
          </PivotItem>
        </Pivot>
      </Card>
    </MainContent>
  );
}

function SampleLibrary(props: {
  menuFeatures: FileFeatures;
  fileTreeNavigation: FileTreeNavigation;
  openNodeId?: number;
}) {
  const { fileItemsRes, handleOpenFolder } = props.fileTreeNavigation;

  return (
    <FileTreeOrLoading
      openFolderId={props.openNodeId}
      fileFeatures={props.menuFeatures}
      navigationRootName="Bibliotek"
      enableSelection={false}
      handleOpenFolder={handleOpenFolder}
      item={fileItemsRes}
      loadFiles={() => Promise.resolve()}
    ></FileTreeOrLoading>
  );
}

function getFileListMenuFeatures(options: {
  hideAll?: boolean;
  baseFeatures?: {
    enableRemove: boolean;
    enableMove?: { mode: MoveDialogNodesMode };
    enableRename: boolean;
  };
  listSortAlphabetical?: boolean;
  isAdmin: boolean;
  showSharedWithYouStatus?: boolean;
  useHelpIcon?: boolean;
  useSampleLibIcon?: boolean;
  useLibFeatures?: { reloadLib: () => void };
  useHelpFeatures?: { reloadHelp: () => void };
  useSharingFeatures?: boolean;
  favorites:
    | {
        use: true;
        optimisticFavorites: OptimisticFavorites;
        setOptimisticFavorites: (arg: OptimisticFavorites) => void;
        reloadFavorites: () => void;
      }
    | { use: false };
  reloadAllDocs: () => void;
  reloadFocusShortcuts?: () => void;
}): FileFeatures {
  const { isAdmin, useLibFeatures, useHelpFeatures, hideAll } = options;
  if (hideAll) {
    return { hideAll };
  }

  const favorites = options.favorites;
  return {
    ...options.baseFeatures,
    useSharingFeatures: options.useSharingFeatures,
    showSharedStatus: options.showSharedWithYouStatus,
    useHelpIcon: options.useHelpIcon,
    listSortAlphabetical: options.listSortAlphabetical,
    useSampleLibIcon: options.useSampleLibIcon,
    addLibraryRoot:
      isAdmin && defined(useLibFeatures)
        ? (nodeId: number, nodeType: NodeTypeRegular) => {
            const message =
              nodeType === "folder"
                ? "Den här mappen och alla dess undermappar och dokument kommer bli tillgängliga för alla inloggade användare. Vill du fortsätta?"
                : "Det här dokumentet kommer bli tillgängligt för alla inloggade användare. Vill du fortsätta?";
            if (window.confirm(message)) {
              return adminCreateSampleLibraryRootNode({ id: nodeId }).then(
                () => {
                  useLibFeatures.reloadLib();
                  options.reloadAllDocs();
                }
              );
            }
            return Promise.resolve();
          }
        : undefined,
    removeLibraryRoot:
      isAdmin && defined(useLibFeatures)
        ? (nodeId: number) => {
            return adminDeleteSampleLibraryRootNode(nodeId).then(() => {
              options.reloadFocusShortcuts?.();
              options.reloadAllDocs();
              useLibFeatures.reloadLib();
            });
          }
        : undefined,
    addShortcut:
      isAdmin && defined(useLibFeatures)
        ? (nodeId: number) => {
            return adminCreateLibraryShortcut({ id: nodeId }).then(() => {
              options.reloadFocusShortcuts?.();
              useLibFeatures.reloadLib();
            });
          }
        : undefined,
    removeShortcut:
      isAdmin && defined(useLibFeatures)
        ? (nodeId: number) => {
            return adminDeleteLibraryShortcut(nodeId).then(() => {
              options.reloadFocusShortcuts?.();
              useLibFeatures.reloadLib();
            });
          }
        : undefined,
    addHelpRoot:
      isAdmin && defined(useHelpFeatures)
        ? (nodeId: number) => {
            if (
              window.confirm(
                "Den här mappen och alla dess undermappar och dokument kommer bli tillgängliga för alla inloggade användare. Vill du fortsätta?"
              )
            ) {
              return adminCreateHelpLibraryRootNode({ id: nodeId }).then(() => {
                useHelpFeatures.reloadHelp();
                options.reloadAllDocs();
              });
            }
            return Promise.resolve();
          }
        : undefined,
    removeHelpRoot:
      isAdmin && defined(useHelpFeatures)
        ? (nodeId: number) => {
            return adminDeleteHelpLibraryRootNode(nodeId).then(() => {
              options.reloadAllDocs();
              useHelpFeatures.reloadHelp();
            });
          }
        : undefined,
    favorites: favorites.use
      ? {
          optimistic: favorites.optimisticFavorites,
          add: (nodeId) => {
            favorites.setOptimisticFavorites({
              ...favorites.optimisticFavorites,
              [nodeId]: true,
            });
            addFavorite({ id: nodeId }).then(() => {
              favorites.reloadFavorites?.();
            });
          },
          remove: (nodeId) => {
            favorites.setOptimisticFavorites({
              ...favorites.optimisticFavorites,
              [nodeId]: false,
            });
            deleteFavorite(nodeId).then(() => {
              favorites.reloadFavorites?.();
            });
          },
        }
      : undefined,
  };
}
