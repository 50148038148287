type EventType = "success" | "failure" | "postponed";

class SaveTracker {
  private _saves: { time: Date; type: EventType }[] = [];

  recordSaveSucceded() {
    this._saves.push({ time: new Date(), type: "success" });
  }

  recordSaveFailed() {
    this._saves.push({ time: new Date(), type: "failure" });
  }

  /** Postponing a save happens when the document is not yet initialized, and the user makes changes */
  recordSavePostponed() {
    this._saves.push({ time: new Date(), type: "postponed" });
  }

  timeInFailedSaveState(): number | undefined {
    const now = new Date();
    if (this._saves.length === 0) {
      return undefined;
    }
    const lastSave = this._saves[this._saves.length - 1];
    if (lastSave.type === "failure") {
      return now.getTime() - lastSave.time.getTime();
    }
    return undefined;
  }

  timeInPostponedState(): number | undefined {
    const now = new Date();
    if (this._saves.length === 0) {
      return undefined;
    }
    const lastSave = this._saves[this._saves.length - 1];
    if (lastSave.type === "postponed") {
      return now.getTime() - lastSave.time.getTime();
    }
    return undefined;
  }
}

export const saveTracker = new SaveTracker();
