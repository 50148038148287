import * as React from "react";
import { useState } from "react";

import { ISharingInfo } from "../../../../../lib/application/files/SharingInfo";

import {
  TabContainer,
  TitleActionsContainerPythonCard,
  TitleContainer,
} from "./CardTab";
import { TitleActionsContextMenuPythonCard } from "./TitleActionsContextMenu";

interface Props {
  primaryMeasureId?: number;
  sharingInfo: ISharingInfo;
  cardId: string;
  handleRemoveCard: () => void;
  handleDuplicateCard: () => void;
  handleExportViaLink: () => void;
}

export function PythonCardTab(props: Props) {
  const { primaryMeasureId } = props;
  const [showContextMenu, setShowContextMenu] = useState(false);
  const contextMenuContainerRef = React.useRef(null);

  return (
    <TabContainer containerKey={primaryMeasureId ?? props.cardId}>
      <div className="card-label">
        <TitleContainer
          key={props.cardId}
          isEditing={true}
          cardId={props.cardId}
        ></TitleContainer>
      </div>

      <TitleActionsContainerPythonCard
        setShowContextMenu={setShowContextMenu}
        showContextMenu={showContextMenu}
        contextMenuContainerRef={contextMenuContainerRef}
      />

      <TitleActionsContextMenuPythonCard
        cardId={props.cardId}
        handleDuplicateCard={() => props.handleDuplicateCard()}
        handleRemoveCard={() => props.handleRemoveCard()}
        handleExportViaLink={props.handleExportViaLink}
        targetRef={contextMenuContainerRef}
        hidden={!showContextMenu}
        onDismiss={() => setShowContextMenu(false)}
      />
    </TabContainer>
  );
}
