import { TutorialEvents, TutorialsHandler } from "../tutorials_handler";
import { Tutorial } from "../types";

export function statsCardIntro(handler: TutorialsHandler): Tutorial {
  return {
    name: "Intro till statistikkort",
    key: "stats_card_intro",
    seen: false,
    config: {
      overlayOpacity: 0.5,
      progressText: "{{current}} av {{total}}",
      nextBtnText: "Nästa",
      prevBtnText: "Föregående",
      doneBtnText: "Klar",
      showProgress: true,
      popoverClass: "infostat-tutorial-popover",
      onDestroyed: () => {
        handler.onDestroy();
      },
      steps: [
        {
          element: ".new-card",
          popover: {
            title: "Hämta statistik",
            description: "Klicka för att välja typ av kort.",
            showButtons: [],
            onNextClick: () => {
              handler.lastDriver?.moveNext();
            },
          },
          onHighlightStarted: () => {
            handler.lastDriver?.setConfig({
              ...handler.lastDriver.getConfig(),
            });
          },
        },
        {
          element: ".new-card-dropdown",
          popover: {
            title: "Korttyp",
            showButtons: ["next"],
            description:
              "Vi börjar med att välja korttyp. För generell statistik väljer vi ”Stats”.",
          },
          onHighlightStarted: () => {
            handler.lastDriver?.setConfig({
              ...handler.lastDriver.getConfig(),
              disableActiveInteraction: true,
            });
          },
        },
        {
          element: ".new-card-dropdown .ms-ContextualMenu-item",
          popover: {
            title: "Öppna databasen",
            showButtons: [],
            description:
              "Klicka här, på ”Stats”, för att öppna databasen och börja ta fram statistik.",
          },
          onHighlightStarted: () => {
            handler.lastDriver?.setConfig({
              ...handler.lastDriver.getConfig(),
              disableActiveInteraction: false,
            });
          },
        },
        {
          element: ".document-card-container:last-child .data-selection",
          onHighlightStarted: () => {
            handler.lastDriver?.setConfig({
              ...handler.lastDriver.getConfig(),
              disableActiveInteraction: true,
            });
          },
          popover: {
            title: "Välj statistik",
            description:
              "All statistik är indelad i övergripande samhällsområden. Från Arbetsliv till Vård och hälsa.",
            showButtons: ["next"],
            onNextClick: () => {
              handler.emitEvent(
                TutorialEvents.StatsCardAreaDropdownHighlighted
              );
              setTimeout(() => {
                handler.lastDriver?.moveNext();
              }, 1000);
            },
          },
        },
        {
          element: ".document-card-container:last-child .subject-search",
          popover: {
            title: "Sök statistik",
            description:
              "Det snabbaste sättet att hitta statistiken är att söka direkt i sökrutan. Du kan söka med vanlig svenska. Som att ”googla” statistik.",
          },
        },
        {
          element: ".document-card-container:last-child .geo-selector",
          popover: {
            title: "Välj geografi",
            description:
              "När du har valt vilken statistik du vill titta på kan du i många fall välja geografisk nedbrytning. Till exempel län eller kommun.  Du kan skriva kommunnamn i sökrutan eller klicka på plustecknet för att fälla ut alla kommuner som tillhör länet.",
            onNextClick: () => {
              document
                .querySelector(
                  ".document-card-container:last-child .pivot-menu"
                )
                ?.scrollIntoView({ block: "center", behavior: "smooth" });
              setTimeout(() => {
                handler.lastDriver?.moveNext();
              }, 500);
            },
          },
        },
        {
          element: ".document-card-container:last-child .pivot-menu",
          popover: {
            title: "Välj presentation",
            description:
              "Statistiken visas per automatik som diagram. Men du kan också välja att visa statistiken som tabell eller karta.",
          },
        },
        {
          element:
            ".document-card-container:last-child .pivot-menu button[name='Info']",
          popover: {
            title: "Information",
            description:
              "Info ger en kort beskrivning av måttet, som det beskrivs i sin originalkälla. Här finns också eventuella kommentarer som kan vara bra att känna till, samt datum när statistiken senast uppdaterades och länk till originalkälla.",
          },
        },
        {
          element: ".document-card-container:last-child .export-menu",
          popover: {
            title: "Ladda ner statistik",
            description:
              "Du kan ladda ner all statistik som du tar fram genom Infostat. I diagramvy kan du ladda ner som bild, och klickar du på tabell kan du ladda ner antingen som Excel-fil eller som API-länk – då uppdateras måttet, automatiskt.",
          },
        },
        {
          element: ".document-card-container:last-child .social-media-button",
          popover: {
            title: "Sociala medier",
            description:
              "Dela diagram på sociala medier som Facebook, Twitter eller LinkedIn.",
          },
        },
        {
          element: ".document-card-container:last-child .time-select",
          popover: {
            title: "Tidsaxeln",
            description:
              "Med tidsaxeln justerar du för vilken tidsperiod som du vill se statistiken. Vi går inte längre tillbaka än till 1980.",
          },
        },
        {
          element:
            ".document-card-container:last-child .time-select .ms-Checkbox",
          popover: {
            title: "Lås till senaste tidpunkt",
            description:
              "När du låser till senaste tidpunkt kommer statistiken att uppdateras automatiskt när ny statistik finns tillgänglig.",
          },
        },
        {
          element: ".document-card-container:last-child .appearance-menu",
          popover: {
            title: "Utseende",
            description:
              "Här kan du själv ändra färger på diagrammen, välja vad som ska synas och vad som ska döljas, justera storleken på texten och till och med sätta egna rubriker.",
          },
        },
        {
          element: ".document-card-container:last-child .alerts-inactive",
          popover: {
            title: "Prenumerera på måttuppdateringar",
            description:
              "Klicka här för att prenumerera på måttuppdateringar så får du ett mejl varje gång måttet uppdateras.",
          },
        },
        {
          element: ".nav-bar-inner .settings-container",
          popover: {
            title: "Inställningar",
            description:
              "Här hittar du dina inställningar. Bland annat kan du skapa egna färg- och layoutteman för diagram.",
          },
        },
        {
          element: ".nav-main .title-container",
          popover: {
            title: "Namnge dokument",
            description:
              "Allt du gör i analysplattformen sparas automatiskt. Här namnger du ditt dokument, så som du vill att det sparas i ”Mina dokument”.",
          },
        },
        {
          element: "#stats-toolbar .share-button",
          popover: {
            title: "Dela",
            description:
              "Här delar du statistik som du tagit fram. Du delar genom att skapa en länk som du skickar vidare. Om du vill dela med någon som inte har Infostat ska du klicka på ”Publikt, via statiskt dokument”. Då får du en länk som vem som helst kan öppna.",
          },
        },
        {
          element: "#nav-bar .help-action-button",
          popover: {
            title: "Hjälp och guider",
            description: "Här finns hjälp och guider.",
          },
        },
      ],
    },
  };
}
