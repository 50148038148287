import * as _ from "lodash";

const municipalities = [
  ["Botkyrka kommun", "Botkyrka"],
  ["Danderyds kommun", "Danderyd"],
  ["Ekerö kommun", "Ekerö"],
  ["Haninge kommun", "Haninge"],
  ["Huddinge kommun", "Huddinge"],
  ["Järfälla kommun", "Järfälla"],
  ["Lidingö stad", "Lidingö"],
  ["Nacka kommun", "Nacka"],
  ["Norrtälje kommun", "Norrtälje"],
  ["Nykvarns kommun", "Nykvarn"],
  ["Nynäshamns kommun", "Nynäshamn"],
  ["Salems kommun", "Salem"],
  ["Sigtuna kommun", "Sigtuna"],
  ["Sollentuna kommun", "Sollentuna"],
  ["Solna stad", "Solna"],
  ["Stockholms stad", "Stockholm"],
  ["Sundbybergs stad", "Sundbyberg"],
  ["Södertälje kommun", "Södertälje"],
  ["Tyresö kommun", "Tyresö"],
  ["Täby kommun", "Täby"],
  ["Upplands Väsby kommun", "Upplands Väsby"],
  ["Upplands-Bro kommun", "Upplands-Bro"],
  ["Vallentuna kommun", "Vallentuna"],
  ["Vaxholms stad", "Vaxholm"],
  ["Värmdö kommun", "Värmdö"],
  ["Österåkers kommun", "Österåker"],
  ["Enköpings kommun", "Enköping"],
  ["Heby kommun", "Heby"],
  ["Håbo kommun", "Håbo"],
  ["Knivsta kommun", "Knivsta"],
  ["Tierps kommun", "Tierp"],
  ["Uppsala kommun", "Uppsala"],
  ["Älvkarleby kommun", "Älvkarleby"],
  ["Östhammars kommun", "Östhammar"],
  ["Eskilstuna kommun", "Eskilstuna"],
  ["Flens kommun", "Flen"],
  ["Gnesta kommun", "Gnesta"],
  ["Katrineholms kommun", "Katrineholm"],
  ["Nyköpings kommun", "Nyköping"],
  ["Oxelösunds kommun", "Oxelösund"],
  ["Strängnäs kommun", "Strängnäs"],
  ["Trosa kommun", "Trosa"],
  ["Vingåkers kommun", "Vingåker"],
  ["Boxholms kommun", "Boxholm"],
  ["Finspångs kommun", "Finspång"],
  ["Kinda kommun", "Kinda"],
  ["Linköpings kommun", "Linköping"],
  ["Mjölby kommun", "Mjölby"],
  ["Motala kommun", "Motala"],
  ["Norrköpings kommun", "Norrköping"],
  ["Söderköpings kommun", "Söderköping"],
  ["Vadstena kommun", "Vadstena"],
  ["Valdemarsviks kommun", "Valdemarsvik"],
  ["Ydre kommun", "Ydre"],
  ["Åtvidabergs kommun", "Åtvidaberg"],
  ["Ödeshögs kommun", "Ödeshög"],
  ["Aneby kommun", "Aneby"],
  ["Eksjö kommun", "Eksjö"],
  ["Gislaveds kommun", "Gislaved"],
  ["Gnosjö kommun", "Gnosjö"],
  ["Habo kommun", "Habo"],
  ["Jönköpings kommun", "Jönköping"],
  ["Mullsjö kommun", "Mullsjö"],
  ["Nässjö kommun", "Nässjö"],
  ["Sävsjö kommun", "Sävsjö"],
  ["Tranås kommun", "Tranås"],
  ["Vaggeryds kommun", "Vaggeryd"],
  ["Vetlanda kommun", "Vetlanda"],
  ["Värnamo kommun", "Värnamo"],
  ["Alvesta kommun", "Alvesta"],
  ["Lessebo kommun", "Lessebo"],
  ["Ljungby kommun", "Ljungby"],
  ["Markaryds kommun", "Markaryd"],
  ["Tingsryds kommun", "Tingsryd"],
  ["Uppvidinge kommun", "Uppvidinge"],
  ["Växjö kommun", "Växjö"],
  ["Älmhults kommun", "Älmhult"],
  ["Borgholms kommun", "Borgholm"],
  ["Emmaboda kommun", "Emmaboda"],
  ["Hultsfreds kommun", "Hultsfred"],
  ["Högsby kommun", "Högsby"],
  ["Kalmar kommun", "Kalmar"],
  ["Mönsterås kommun", "Mönsterås"],
  ["Mörbylånga kommun", "Mörbylånga"],
  ["Nybro kommun", "Nybro"],
  ["Oskarshamns kommun", "Oskarshamn"],
  ["Torsås kommun", "Torsås"],
  ["Vimmerby kommun", "Vimmerby"],
  ["Västerviks kommun", "Västervik"],
  ["Gotlands kommun", "Gotland"],
  ["Karlshamns kommun", "Karlshamn"],
  ["Karlskrona kommun", "Karlskrona"],
  ["Olofströms kommun", "Olofström"],
  ["Ronneby kommun", "Ronneby"],
  ["Sölvesborgs kommun", "Sölvesborg"],
  ["Bjuvs kommun", "Bjuv"],
  ["Bromölla kommun", "Bromölla"],
  ["Burlövs kommun", "Burlöv"],
  ["Båstads kommun", "Båstad"],
  ["Eslövs kommun", "Eslöv"],
  ["Helsingborgs stad", "Helsingborg"],
  ["Hässleholms kommun", "Hässleholm"],
  ["Höganäs kommun", "Höganäs"],
  ["Hörby kommun", "Hörby"],
  ["Höörs kommun", "Höör"],
  ["Klippans kommun", "Klippan"],
  ["Kristianstads kommun", "Kristianstad"],
  ["Kävlinge kommun", "Kävlinge"],
  ["Landskrona stad", "Landskrona"],
  ["Lomma kommun", "Lomma"],
  ["Lunds kommun", "Lund"],
  ["Malmö stad", "Malmö"],
  ["Osby kommun", "Osby"],
  ["Perstorps kommun", "Perstorp"],
  ["Simrishamns kommun", "Simrishamn"],
  ["Sjöbo kommun", "Sjöbo"],
  ["Skurups kommun", "Skurup"],
  ["Staffanstorps kommun", "Staffanstorp"],
  ["Svalövs kommun", "Svalöv"],
  ["Svedala kommun", "Svedala"],
  ["Tomelilla kommun", "Tomelilla"],
  ["Trelleborgs kommun", "Trelleborg"],
  ["Vellinge kommun", "Vellinge"],
  ["Ystads kommun", "Ystad"],
  ["Åstorps kommun", "Åstorp"],
  ["Ängelholms kommun", "Ängelholm"],
  ["Örkelljunga kommun", "Örkelljunga"],
  ["Östra Göinge kommun", "Östra Göinge"],
  ["Falkenbergs kommun", "Falkenberg"],
  ["Halmstads kommun", "Halmstad"],
  ["Hylte kommun", "Hylte"],
  ["Kungsbacka kommun", "Kungsbacka"],
  ["Laholms kommun", "Laholm"],
  ["Varbergs kommun", "Varberg"],
  ["Ale kommun", "Ale"],
  ["Alingsås kommun", "Alingsås"],
  ["Bengtsfors kommun", "Bengtsfors"],
  ["Bollebygds kommun", "Bollebygd"],
  ["Borås stad", "Borås"],
  ["Dals-Eds kommun", "Dals-Ed"],
  ["Essunga kommun", "Essunga"],
  ["Falköpings kommun", "Falköping"],
  ["Färgelanda kommun", "Färgelanda"],
  ["Grästorps kommun", "Grästorp"],
  ["Gullspångs kommun", "Gullspång"],
  ["Göteborgs stad", "Göteborg"],
  ["Götene kommun", "Götene"],
  ["Herrljunga kommun", "Herrljunga"],
  ["Hjo kommun", "Hjo"],
  ["Härryda kommun", "Härryda"],
  ["Karlsborgs kommun", "Karlsborg"],
  ["Kungälvs kommun", "Kungälv"],
  ["Lerums kommun", "Lerum"],
  ["Lidköpings kommun", "Lidköping"],
  ["Lilla Edets kommun", "Lilla Edet"],
  ["Lysekils kommun", "Lysekil"],
  ["Mariestads kommun", "Mariestad"],
  ["Marks kommun", "Mark"],
  ["Melleruds kommun", "Mellerud"],
  ["Munkedals kommun", "Munkedal"],
  ["Mölndals stad", "Mölndal"],
  ["Orust kommun", "Orust"],
  ["Partille kommun", "Partille"],
  ["Skara kommun", "Skara"],
  ["Skövde kommun", "Skövde"],
  ["Sotenäs kommun", "Sotenäs"],
  ["Stenungsunds kommun", "Stenungsund"],
  ["Strömstads kommun", "Strömstad"],
  ["Svenljunga kommun", "Svenljunga"],
  ["Tanums kommun", "Tanum"],
  ["Tibro kommun", "Tibro"],
  ["Tidaholms kommun", "Tidaholm"],
  ["Tjörns kommun", "Tjörn"],
  ["Tranemo kommun", "Tranemo"],
  ["Trollhättans stad", "Trollhättan"],
  ["Töreboda kommun", "Töreboda"],
  ["Uddevalla kommun", "Uddevalla"],
  ["Ulricehamns kommun", "Ulricehamn"],
  ["Vara kommun", "Vara"],
  ["Vårgårda kommun", "Vårgårda"],
  ["Vänersborgs kommun", "Vänersborg"],
  ["Åmåls kommun", "Åmål"],
  ["Öckerö kommun", "Öckerö"],
  ["Kiruna kommun", "Kiruna"],
  ["Haparanda stad", "Haparanda"],
  ["Bodens kommun", "Boden"],
  ["Piteå kommun", "Piteå"],
  ["Luleå kommun", "Luleå"],
  ["Älvsbyns kommun", "Älvsbyn"],
  ["Gällivare kommun", "Gällivare"],
  ["Pajala kommun", "Pajala"],
  ["Övertorneå kommun", "Övertorneå"],
  ["Kalix kommun", "Kalix"],
  ["Överkalix kommun", "Överkalix"],
  ["Jokkmokks kommun", "Jokkmokk"],
  ["Arjeplogs kommun", "Arjeplog"],
  ["Arvidsjaurs kommun", "Arvidsjaur"],
  ["Skellefteå kommun", "Skellefteå"],
  ["Lycksele kommun", "Lycksele"],
  ["Askersunds kommun", "Askersund"],
  ["Degerfors kommun", "Degerfors"],
  ["Hallsbergs kommun", "Hallsberg"],
  ["Hällefors kommun", "Hällefors"],
  ["Karlskoga kommun", "Karlskoga"],
  ["Kumla kommun", "Kumla"],
  ["Laxå kommun", "Laxå"],
  ["Lekebergs kommun", "Lekeberg"],
  ["Lindesbergs kommun", "Lindesberg"],
  ["Ljusnarsbergs kommun", "Ljusnarsberg"],
  ["Nora kommun", "Nora"],
  ["Örebro kommun", "Örebro"],
  ["Arboga kommun", "Arboga"],
  ["Fagersta kommun", "Fagersta"],
  ["Hallstahammars kommun", "Hallstahammar"],
  ["Kungsörs kommun", "Kungsör"],
  ["Köpings kommun", "Köping"],
  ["Norbergs kommun", "Norberg"],
  ["Sala kommun", "Sala"],
  ["Skinnskattebergs kommun", "Skinnskatteberg"],
  ["Surahammars kommun", "Surahammar"],
  ["Västerås stad", "Västerås"],
  ["Umeå kommun", "Umeå"],
  ["Åsele kommun", "Åsele"],
  ["Vilhelmina kommun", "Vilhelmina"],
  ["Vännäs kommun", "Vännäs"],
  ["Dorotea kommun", "Dorotea"],
  ["Sorsele kommun", "Sorsele"],
  ["Storumans kommun", "Storuman"],
  ["Malå kommun", "Malå"],
  ["Norsjö kommun", "Norsjö"],
  ["Robertsfors kommun", "Robertsfors"],
  ["Vindelns kommun", "Vindeln"],
  ["Bjurholms kommun", "Bjurholm"],
  ["Nordmalings kommun", "Nordmaling"],
  ["Östersunds kommun", "Östersund"],
  ["Härjedalens kommun", "Härjedalen"],
  ["Bergs kommun", "Berg"],
  ["Åre kommun", "Åre"],
  ["Strömsunds kommun", "Strömsund"],
  ["Krokoms kommun", "Krokom"],
  ["Bräcke kommun", "Bräcke"],
  ["Ragunda kommun", "Ragunda"],
  ["Örnsköldsviks kommun", "Örnsköldsvik"],
  ["Sollefteå kommun", "Sollefteå"],
  ["Kramfors kommun", "Kramfors"],
  ["Sundsvalls kommun", "Sundsvall"],
  ["Härnösands kommun", "Härnösand"],
  ["Timrå kommun", "Timrå"],
  ["Ånge kommun", "Ånge"],
  ["Hudiksvalls kommun", "Hudiksvall"],
  ["Bollnäs kommun", "Bollnäs"],
  ["Söderhamns kommun", "Söderhamn"],
  ["Sandvikens kommun", "Sandviken"],
  ["Gävle kommun", "Gävle"],
  ["Ljusdals kommun", "Ljusdal"],
  ["Nordanstigs kommun", "Nordanstig"],
  ["Ovanåkers kommun", "Ovanåker"],
  ["Hofors kommun", "Hofors"],
  ["Ockelbo kommun", "Ockelbo"],
  ["Ludvika kommun", "Ludvika"],
  ["Avesta kommun", "Avesta"],
  ["Hedemora kommun", "Hedemora"],
  ["Säters kommun", "Säter"],
  ["Borlänge kommun", "Borlänge"],
  ["Falu kommun", "Falun"],
  ["Mora kommun", "Mora"],
  ["Smedjebackens kommun", "Smedjebacken"],
  ["Älvdalens kommun", "Älvdalen"],
  ["Orsa kommun", "Orsa"],
  ["Rättviks kommun", "Rättvik"],
  ["Leksands kommun", "Leksand"],
  ["Gagnefs kommun", "Gagnef"],
  ["Malung-Sälens kommun", "Malung-Sälen"],
  ["Vansbro kommun", "Vansbro"],
  ["Säffle kommun", "Säffle"],
  ["Arvika kommun", "Arvika"],
  ["Hagfors kommun", "Hagfors"],
  ["Filipstads kommun", "Filipstad"],
  ["Kristinehamns kommun", "Kristinehamn"],
  ["Karlstads kommun", "Karlstad"],
  ["Sunne kommun", "Sunne"],
  ["Årjängs kommun", "Årjäng"],
  ["Grums kommun", "Grums"],
  ["Forshaga kommun", "Forshaga"],
  ["Munkfors kommun", "Munkfors"],
  ["Hammarö kommun", "Hammarö"],
  ["Storfors kommun", "Storfors"],
  ["Torsby kommun", "Torsby"],
  ["Eda kommun", "Eda"],
  ["Kils kommun", "Kil"],
];

const regions = [
  ["Stockholms län", "Stockholm"],
  ["Uppsala län", "Uppsala"],
  ["Södermanlands län", "Södermanland"],
  ["Östergötlands län", "Östergötland"],
  ["Jönköpings län", "Jönköping"],
  ["Kronobergs län", "Kronoberg"],
  ["Kalmar län", "Kalmar"],
  ["Gotlands län", "Gotland"],
  ["Blekinge län", "Blekinge"],
  ["Skåne län", "Skåne"],
  ["Hallands län", "Halland"],
  ["Västra Götalands län", "Västra Götaland"],
  ["Värmlands län", "Värmland"],
  ["Örebro län", "Örebro"],
  ["Västmanlands län", "Västmanland"],
  ["Dalarnas län", "Dalarna"],
  ["Gävleborgs län", "Gävleborg"],
  ["Västernorrlands län", "Västernorrland"],
  ["Jämtlands län", "Jämtland"],
  ["Västerbottens län", "Västerbotten"],
  ["Norrbottens län", "Norrbotten"],
];

export const municipalitiesDict = _.fromPairs(municipalities);
export const regionsDict = _.fromPairs(regions);

const municipalitiesReverseDict = _.fromPairs(
  municipalities.map(([a, b]) => [b, a])
);
const regionsReverseDict = _.fromPairs(regions.map(([a, b]) => [b, a]));

export function translateGeoLabel(label: string): string | undefined {
  return regionsDict[label] ?? municipalitiesDict[label];
}

export function translateGeoLabelReverse(label: string): string {
  return regionsReverseDict[label] ?? municipalitiesReverseDict[label] ?? label;
}

export function shortenRegionLabel(label: string): string {
  return regionsDict[label] ?? label;
}

export function shortenMunicipalityLabel(label: string): string {
  return municipalitiesDict[label] ?? label;
}
