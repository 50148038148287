import { useContext, useMemo } from "react";

import { UserInfoContext } from "../../contexts";
import { extendedAppearanceSettingsDefault } from "../../stats/shared/core/colors/colorSchemes";

export function useExtendedAppearanceSettings() {
  const userInfo = useContext(UserInfoContext);

  return useMemo(
    () =>
      userInfo?.extendedAppearanceSettings() ??
      extendedAppearanceSettingsDefault(),
    [userInfo]
  );
}
