import { Record, Number as NumberRT, Static } from "runtypes";

import {
  CardColorSchemeLink,
  ColorSchemeContainer,
} from "../document-style/definitions";
import { DocCardState } from "../document-core/core";
import { DataSelectionMinimalRT } from "./v8/types";

export const CURRENT_WORKSPACE_VERSION = 8;

export const WorkspaceWrapperRT = Record({
  workspaceVersion: NumberRT,
  state: Record({}),
});
export type WorkspaceWrapper = Static<typeof WorkspaceWrapperRT>;

export interface RebuildEmbeddedCardResult {
  dataCards: DocCardState[];
  colorSchemes: ColorSchemeContainer[];
  cardColorSchemes: CardColorSchemeLink[];
}

export type DataSelectionMinimal = Static<typeof DataSelectionMinimalRT>;
