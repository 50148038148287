import { ComponentType } from "react";

/**
 * Higher-order component that adds an overlay when the component is loading.
 */
export function withLoadingState<T>(Comp: ComponentType<T>) {
  return (
    hocProps: T & {
      isLoading: boolean;
      containerClass: string;
    }
  ) => (
    <div className={hocProps.containerClass}>
      {hocProps.isLoading && <div className="with-loading-state-overlay"></div>}
      <Comp {...hocProps} />
    </div>
  );
}
