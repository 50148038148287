import { DefaultButton, Pivot, PivotItem } from "@fluentui/react";
import { useCallback } from "react";
import { useState } from "react";

import "./ShareDialog.scss";

import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../components/Modal";
import { ISharingInfo } from "../../../lib/application/files/SharingInfo";
import { defined } from "../../../lib/core/defined";
import { truncate } from "../../../lib/core/truncate";
import { NodeTypeRegular } from "../../../lib/application/files/tree";
import { SharePivot } from "./pivots/SharePivot";
import { ManageAccessPivot } from "./pivots/ManageAccessPivot";
import { useLoadableHttpResource } from "../../../lib/application/hooks/useLoadableResource";
import { getNodeSharingInfo } from "../../../lib/application/requests/docs/documents";
import { HttpResult } from "../../../lib/infra/HttpResult";

interface PropsCommon {
  onClose: () => void;
  nodeId: number;
  nodeType: NodeTypeRegular;
  nodeTitle: string;
}

const sharingPivot = "sharing";
const accessPivot = "manage";

/**
 * Wrapper for ShareDialog that loads SharingInfo for the given node
 * @param props
 * @returns
 */
export function ShareDialogLoader(props: PropsCommon) {
  const getSharingInfo = useCallback(
    () => getNodeSharingInfo(props.nodeId),
    [props.nodeId]
  );
  const [loadingResult, reload] = useLoadableHttpResource(getSharingInfo);
  return loadingResult.match({
    ready: (sharingInfo) => (
      <ShareDialog
        {...props}
        reloadSharingInfo={reload}
        sharingInfo={sharingInfo}
      />
    ),
    notReady: () => null,
  });
}

/**
 * Dialog for sharing a document or folder
 */
export function ShareDialog(
  props: PropsCommon & {
    sharingInfo: ISharingInfo;
    reloadSharingInfo?: () => Promise<HttpResult<unknown>>;
  }
) {
  const { nodeTitle } = props;
  const [selectedPivot, setSelectedPivot] = useState(sharingPivot);

  const handleSelectPivot = useCallback((itemKey?: string) => {
    if (defined(itemKey)) {
      setSelectedPivot(itemKey);
    }
  }, []);

  return (
    <div>
      <FluentModal
        containerClassName="share-document-dialog"
        title={`Delning av "${truncate(nodeTitle, 20)}"`}
        isOpen={true}
        onClose={props.onClose}
      >
        <FluentModalBody>
          <Pivot
            selectedKey={selectedPivot}
            onLinkClick={(pivot) => handleSelectPivot(pivot?.props.itemKey)}
          >
            <PivotItem
              className="pivot-container"
              itemKey={sharingPivot}
              headerText="Dela"
            >
              <SharePivot
                {...props}
                handleShowSharing={() => setSelectedPivot(accessPivot)}
              ></SharePivot>
            </PivotItem>
            <PivotItem
              className="pivot-container"
              itemKey={accessPivot}
              headerText="Hantera åtkomst"
            >
              <ManageAccessPivot {...props}></ManageAccessPivot>
            </PivotItem>
          </Pivot>
        </FluentModalBody>
        <FluentModalFooter>
          <DefaultButton
            onClick={() => props.onClose()}
            text="Stäng"
          ></DefaultButton>
        </FluentModalFooter>
      </FluentModal>
    </div>
  );
}
