import { CrudMaker } from "../shared";

const sampleLib = new CrudMaker<number>("admin/sample_libraries");
export const adminCreateSampleLibraryRootNode =
  sampleLib.create<{ id: number }>();
export const adminDeleteSampleLibraryRootNode = sampleLib.delete();

const helpLib = new CrudMaker<number>("admin/help_libraries");
export const adminCreateHelpLibraryRootNode = helpLib.create<{ id: number }>();
export const adminDeleteHelpLibraryRootNode = helpLib.delete();

const libraryShortcuts = new CrudMaker<number>(
  "admin/sample_libraries/shortcuts"
);
export const adminCreateLibraryShortcut =
  libraryShortcuts.create<{ id: number }>();
export const adminDeleteLibraryShortcut = libraryShortcuts.delete();
