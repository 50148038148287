import { IconButton, IIconProps, Slider, ThemeProvider } from "@fluentui/react";
import mapbox from "mapbox-gl";
import { useCallback } from "react";

import {
  MapSelectTool,
  MicroMapSettings,
  MicroMapView,
} from "../../../../../../lib/application/state/stats/document-core/core-micro";
import { fluentUITheme } from "../../../../../../lib/application/theme";
import { classNames } from "../../../../../../lib/core/classNames";
import { defined } from "../../../../../../lib/core/defined";
import { withLoadingState } from "./withLoadingState";
import "./ToolPanel.scss";
import { createRoot } from "react-dom/client";

export interface ZoomActions {
  handleZoomIn: () => void;
  handleZoomOut: () => void;
}

export class ToolPanelControl {
  private _map?: mapbox.Map;
  private _container?: HTMLDivElement;

  private _root?: ReturnType<typeof createRoot>;

  render = (
    view: MicroMapView,
    isLoading: boolean,
    mixedAreaTypes: boolean,
    settings: MicroMapSettings,
    fullscreenSupported: boolean,
    isFullscreen: boolean,
    handleFullscreen: () => void,
    handleUpdateSetting: <T extends keyof MicroMapSettings>(
      key: T,
      value: MicroMapSettings[T]
    ) => void,
    zoomActions: ZoomActions,
    isEditingCard: boolean
  ) => {
    const container = this._container;
    if (!defined(container)) {
      return;
    }

    if (!this._root) {
      this._root = createRoot(container);
    }

    this._root.render(
      <ToolPanelWithLoadingState
        view={view}
        containerClass="mapboxgl-ctrl"
        isLoading={isLoading}
        zoomActions={zoomActions}
        mixedAreaTypes={mixedAreaTypes}
        settings={settings}
        fullscreenSupported={fullscreenSupported}
        isFullscreen={isFullscreen}
        handleFullscreen={handleFullscreen}
        handleUpdateSetting={handleUpdateSetting}
        isEditingCard={isEditingCard}
      />
    );
  };

  onAdd(map: mapbox.Map) {
    this._map = map;
    const container = document.createElement("div");
    container.className = "mapbox-tool-panel";
    this._container = container;
    return container;
  }

  onRemove() {
    setTimeout(() => {
      this._root?.unmount();
      this._root = undefined;
      this._container = undefined;
    });
  }
}

const ToolPanelWithLoadingState = withLoadingState(ToolPanelInner);

interface ToolPanelProps {
  view: MicroMapView;
  mixedAreaTypes: boolean; // Disables showing results
  settings: MicroMapSettings;
  fullscreenSupported: boolean;
  zoomActions: ZoomActions;
  isFullscreen: boolean;
  handleFullscreen: () => void;
  handleUpdateSetting: <T extends keyof MicroMapSettings>(
    key: T,
    value: MicroMapSettings[T]
  ) => void;
  isEditingCard: boolean;
}

function ToolPanelInner(props: ToolPanelProps) {
  const { handleUpdateSetting, view, settings } = props;

  const handleSelectTool = useCallback(
    (tool: MapSelectTool) => {
      handleUpdateSetting("mapSelectTool", tool);
    },
    [handleUpdateSetting]
  );

  const editSelectionsActive = view === "map-select";
  return (
    <ThemeProvider theme={fluentUITheme}>
      {props.isEditingCard && (
        <div className="micro-tool-panel">
          <div className="tools">
            {editSelectionsActive ? (
              <section>
                <ModeButton
                  tool="click-select"
                  icon="hand"
                  selected={settings.mapSelectTool === "click-select"}
                  handleSelect={handleSelectTool}
                />
                <ModeButton
                  tool="draw-select"
                  icon="clip"
                  selected={settings.mapSelectTool === "draw-select"}
                  handleSelect={handleSelectTool}
                />
              </section>
            ) : (
              <section></section>
            )}

            <section>
              <IconButton
                title="Zooma in"
                iconProps={{ iconName: "zoom-in" }}
                onClick={props.zoomActions.handleZoomIn}
              />
              <IconButton
                title="Zooma ut"
                iconProps={{ iconName: "zoom-out" }}
                onClick={props.zoomActions.handleZoomOut}
              />
              <IconButton
                title="Helskärm"
                disabled={!props.fullscreenSupported}
                className={classNames(props.isFullscreen ? "selected" : "")}
                onClick={props.handleFullscreen}
                iconProps={{
                  iconName: "fullscreen",
                }}
              ></IconButton>
            </section>
          </div>

          {view === "map-view" && (
            <div className="transparency-slider">
              <label className="label-left">Opacitet</label>
              <Slider
                className="slider"
                min={0}
                step={0.1}
                max={1}
                onChange={(value) => {
                  props.handleUpdateSetting("resultsOpacity", value);
                }}
                value={props.settings.resultsOpacity}
                valueFormat={(v) => Math.round(v * 100).toString() + "%"}
              ></Slider>
            </div>
          )}
        </div>
      )}
    </ThemeProvider>
  );
}

function ModeButton(props: {
  tool: MapSelectTool;
  selected: boolean;
  icon: IIconProps["iconName"];
  handleSelect: (tool: MapSelectTool) => void;
}) {
  return (
    <IconButton
      className={classNames(props.selected ? "selected" : "")}
      onClick={() => props.handleSelect(props.tool)}
      iconProps={{ iconName: props.icon }}
    ></IconButton>
  );
}
