import _ from "lodash";

import { defined } from "../../../../core/defined";
import { DimensionV2Dto } from "../../../../domain/measure/definitions";
import { getFormatter, getRounder, maxNumDecimals } from "../../format";
import { getSourceInfoCommonHeader } from "../../shared/charts_common";
import { DataDescription } from "../../shared/DataDescription";
import { anyDimensionLabelComparatorAsc } from "../../shared/dimensions";
import { RowRegular } from "../../shared/row";
import { DatasetInput, MainHeaderData } from "../headers";
import {
  columnRowsWithCustomLabels,
  getComputedValueOutputSettings,
  getDimensionFormatter,
  getSummaryRows,
  getSuperColumnRows,
  getValueComparatorAscending,
  joinDimensions,
  tableRowsWithCustomLabels,
} from "./table_base/table_base";
import { setupDimensionsAndVariants } from "./table_base/table_dimensions";
import { calcRows } from "./table_base/calc_rows";
import { Dimension } from "../../shared/core/definitions";
import { ValuesByDimensionAndLabel } from "../DatasetGeneric";
import { DataOutputSettings } from "../../../state/stats/document-core/DataOutputSettings";
import { SortOrder, sortOrderEnum } from "../../../../core/order";
import {
  SuperColumnsRow,
  TableRow,
  TableSpec,
} from "./table_base/table_base_definitions";
import { applySortOrder, applySortOrderPrimary } from "./table_base/sort";

export function datasetToTable(
  dataset: DatasetInput<RowRegular, MainHeaderData>,
  dataDescription: DataDescription,
  /**
   * All the dimensions that specify the data but not the value (range)
   */
  nonValueDataDimensions: string[],
  dateFormatter: (input: string) => string,
  dimensions: DimensionV2Dto[],
  getDimensionHeader: (dimension: string) => string | undefined,
  valuesByDimensionAndLabel: ValuesByDimensionAndLabel,
  settings: DataOutputSettings
): TableSpec {
  const computedValueOutputSettings = getComputedValueOutputSettings(
    dataset.header.valueType,
    settings
  );
  const header = dataset.header;
  const rows = dataset.rows.concat(
    dataset.forecastRows?.map((r) => r.asRowRegular()) ?? []
  );

  const isSingleValue = nonValueDataDimensions.length === 0;
  const dimensionFormatter = getDimensionFormatter(dateFormatter);
  const isForecastLabel = defined(dataset.forecastRows)
    ? (label: string) => {
        return defined(
          dataset.forecastRows?.find(
            (r) =>
              dateFormatter((r.dimension(Dimension.date) as any) ?? "0") ===
              label
          )
        );
      }
    : () => false;

  const { rowKeys, rowDimension, nonPrimaryDimensions, variantCombinations } =
    setupDimensionsAndVariants(
      rows,
      valuesByDimensionAndLabel,
      dimensionFormatter,
      dimensions,
      nonValueDataDimensions,
      settings,
      undefined
    );

  const primaryDimensionHeader = defined(rowDimension)
    ? getDimensionHeader(rowDimension)
    : undefined;
  const primaryComparatorAscending = defined(rowDimension)
    ? anyDimensionLabelComparatorAsc(rowDimension)
    : undefined;

  function rowKey(row: (typeof rows)[0]) {
    return joinDimensions(nonPrimaryDimensions.map((d) => row.dimension(d)));
  }
  const groupedRows = _.groupBy(rows, rowKey);

  const valueType = header.valueType;
  const comparatorAscending = getValueComparatorAscending(valueType);
  const numDecimals =
    valueType === "decimal"
      ? settings.fixedNumDecimals !== null
        ? settings.fixedNumDecimals
        : maxNumDecimals(rows.map((r) => r.range()))
      : undefined;
  const valueFormatter = getFormatter(valueType, numDecimals);
  const valueRounder =
    valueType === "category" ? undefined : getRounder(valueType, numDecimals);
  const commonColumnFields = {
    type: valueType,
    format: valueFormatter,
    comparatorAscending,
    round: valueRounder,
  };

  const columnRowsOriginal: SuperColumnsRow[] = getSuperColumnRows(
    variantCombinations,
    dimensionFormatter
  );
  const columnRows = columnRowsWithCustomLabels(columnRowsOriginal, settings);

  const tableRowsOriginal: TableRow[] = calcRows(
    rowKeys,
    rowDimension,
    isSingleValue,
    rows,
    groupedRows,
    header.liftedDimensions,
    variantCombinations,
    dimensionFormatter,
    dateFormatter,
    isForecastLabel,
    computedValueOutputSettings
  );
  const tableRows = tableRowsWithCustomLabels(
    tableRowsOriginal,
    rowDimension,
    settings
  );

  const groupHeader = dataset.groupHeader;
  const lastColumnRow = _.last(columnRows);

  const columnRow = defined(lastColumnRow)
    ? {
        dimension: lastColumnRow.dimension,
        columns: lastColumnRow.columns.map((column) => ({
          classNames: isForecastLabel(column.text) ? "forecast" : undefined,
          ...column,
          ...commonColumnFields,
        })),
      }
    : {
        dimension: "",
        columns: [
          // If no regular columns, we only have a single column of values. Use the unit as header.
          {
            text: dataDescription.unitHeader ?? "--",
            key: "single-col-key",
            ...commonColumnFields,
          },
        ],
      };
  const columnSort = settings.table.columnSort;
  const sortSetting =
    columnSort?.type === "secondary"
      ? {
          columnIndex: columnSort.columnIndex,
          order: sortOrderEnum(columnSort.order),
        }
      : undefined;

  const primaryDimensionInfo = defined(rowDimension)
    ? {
        dimension: rowDimension,
        header: primaryDimensionHeader,
        comparatorAscending: primaryComparatorAscending,
      }
    : undefined;
  const primaryComparator = primaryDimensionInfo?.comparatorAscending;
  const primarySortOrder: SortOrder | undefined =
    columnSort?.type === "primary"
      ? sortOrderEnum(columnSort.order)
      : undefined;

  const sortedRows = defined(sortSetting)
    ? applySortOrder(tableRows, columnRow, sortSetting)
    : defined(primarySortOrder) && defined(primaryComparator)
    ? applySortOrderPrimary(tableRows, primarySortOrder, primaryComparator)
    : tableRows;

  return {
    primaryDimensionInfo,
    allDimensions: dataset.dimensionsSpec.variable,
    dimensionsString: `rows: ${rows.length}, cols: ${columnRows.length}`,
    hasLowBaseValue: false,
    valueType,
    tableDescription: dataDescription,
    customSourceText: settings.customSourceText,
    sourceInfo: getSourceInfoCommonHeader(header),
    groupingSourceInfo: defined(groupHeader)
      ? getSourceInfoCommonHeader(groupHeader)
      : undefined,
    header: {
      superColumnRows: columnRows.slice(0, columnRows.length - 1),
      columnRow: columnRow,
    },
    rows: sortedRows,
    summaryRows:
      valueType !== "category"
        ? getSummaryRows(
            settings.table,
            tableRows,
            valueRounder,
            valueFormatter
          )
        : undefined,
  };
}
