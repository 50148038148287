import { Api } from "../../config";
import { defined } from "../core/defined";
import { stripAnyPrefixes } from "../core/string";
import { pathJoin } from "../paths";
import { HttpResult, RequestErrorInfo } from "./HttpResult";
import { logger } from "./logging";

export type RequestMethod = "POST" | "GET" | "PUT" | "DELETE" | "PATCH";

export async function rawRequest<T>(
  api: Api,
  token: string,
  path: string,
  data: any,
  method: RequestMethod
): Promise<HttpResult<T>> {
  return rawRequestCustomAuth(api, `Bearer ${token}`, path, data, method);
}

function getDomain(url: string) {
  const withoutProtocol = stripAnyPrefixes(url, ["https://", "http://"]);
  const parts = withoutProtocol.split("/");
  return parts[0];
}

/**
 * Request for loading public data to embed on third-party sites.
 */
export async function rawRequestEmbedded<T>(
  embedKey: string,
  api: Api,
  path: string,
  data: any,
  method: RequestMethod
): Promise<HttpResult<T>> {
  return rawRequestCustomAuth(
    api,
    `EmbedKeyV1 ${embedKey} ${getDomain(document.referrer)}`,
    path,
    data,
    method
  );
}

export async function rawRequestCustomAuth<T>(
  api: Api,
  authorization: string,
  path: string,
  data: any,
  method: RequestMethod
): Promise<HttpResult<T>> {
  const fullPath = pathJoin(api.url, path);
  try {
    const body = defined(data) ? JSON.stringify(data) : null;
    const headers: { [key: string]: string } = {
      Authorization: authorization,
      Accept: "application/json",
    };
    if (defined(body)) {
      headers["Content-Type"] = "application/json";
    }
    const response = await fetch(fullPath, {
      headers,
      method,
      body,
    });
    return handleRawHttpResponse<T>(response);
  } catch (error) {
    logger.error("Error in request", JSON.stringify(error));
    throw new ProblemDetailsError(
      `[rawRequestCustomAuth] failed to fetch in request (url: ${fullPath}): ${
        (error as any)?.message
      }`,
      (error as any)?.statusCode,
      (error as any)?.title,
      (error as any)?.detail
    );
  }
}

export async function handleErrorStatus<T>(
  response: Response
): Promise<HttpResult<T> | undefined> {
  const contentLength = response.headers.get("Content-Length");
  const hasContent =
    typeof contentLength === "string" && parseInt(contentLength) > 0;

  const status = response.status;
  const info = hasContent ? await extractErrorInfo(response) : undefined;
  if (status === 401) {
    return Promise.resolve(HttpResult.fromErr({ code: "unauthorized", info }));
  } else if (status === 404) {
    return Promise.resolve(HttpResult.fromErr({ code: "not-found", info }));
  } else if (status === 422) {
    return Promise.resolve(
      HttpResult.fromErr({ code: "bad-client-input", info })
    );
  } else if (status === 409) {
    return Promise.resolve(HttpResult.fromErr({ code: "conflict", info }));
  } else if (status < 500) {
    return Promise.resolve(HttpResult.fromErr({ code: "4xx", info }));
  } else if (status >= 500) {
    return Promise.resolve(
      HttpResult.fromErr({ code: "internal-server-error", info })
    );
  }
}

export async function handleRawHttpResponse<T>(
  response: Response
): Promise<HttpResult<T>> {
  const contentLength = response.headers.get("Content-Length");

  const status = response.status;
  if (status >= 400 && status < 600) {
    const errInfo = await handleErrorStatus<T>(response);
    if (defined(errInfo)) {
      return errInfo;
    }
  }

  if (typeof contentLength === "string" && parseInt(contentLength) === 0) {
    return HttpResult.fromOk(undefined as any as T);
  }
  const res = await response.json();

  if (!response.ok) {
    throw new ProblemDetailsError(
      `failed to fetch in request: ${response.status}`,
      response.status,
      res?.title,
      res?.detail
    );
  }

  const md5checksum = response.headers.get("Content-MD5");
  return HttpResult.fromOk(res.data, md5checksum ?? undefined);
}

export function handleRawHttpResponseXHR<T>(
  req: XMLHttpRequest
): HttpResult<T> {
  const contentLength = req.getResponseHeader("Content-Length");
  const hasContent =
    typeof contentLength === "string" && parseInt(contentLength) > 0;

  const status = req.status;
  if (status >= 400 && status < 600) {
    const info = hasContent ? extractErrorInfoXHR(req) : undefined;
    if (status === 401) {
      return HttpResult.fromErr({ code: "unauthorized", info });
    } else if (status === 404) {
      return HttpResult.fromErr({ code: "not-found", info });
    } else if (status === 422) {
      return HttpResult.fromErr({ code: "bad-client-input", info });
    } else if (status < 500) {
      return HttpResult.fromErr({ code: "4xx", info });
    } else if (status >= 500) {
      return HttpResult.fromErr({ code: "internal-server-error", info });
    }
  }

  if (typeof contentLength === "string" && parseInt(contentLength) === 0) {
    return HttpResult.fromOk(undefined as any as T);
  }
  const res = JSON.parse(req.responseText);
  return HttpResult.fromOk(res.data);
}

/**
 * Careful -- this consumes the response body!
 * @param response
 */
function extractErrorInfoXHR(
  req: XMLHttpRequest
): RequestErrorInfo | undefined {
  try {
    const text = req.responseText;
    try {
      const json = JSON.parse(text);
      if (typeof json === "string") {
        return { type: "errorMessage", message: json };
      } else if (typeof json.error === "string") {
        return { type: "errorCode", error: json.error, message: json.message };
      }
      return {
        type: "data",
        data: json,
      };
    } catch (e) {
      return { type: "errorMessage", message: text };
    }
  } catch (e) {
    logger.error("failed to extract error info", e);
    return undefined;
  }
}

/**
 * Careful -- this consumes the response body!
 * @param response
 */
async function extractErrorInfo(
  response: Response
): Promise<RequestErrorInfo | undefined> {
  try {
    const text = await response.text();
    try {
      const json = JSON.parse(text);
      if (typeof json === "string") {
        return { type: "errorMessage", message: json };
      } else if (typeof json.error === "string") {
        return { type: "errorCode", error: json.error, message: json.message };
      }
      return {
        type: "data",
        data: json,
      };
    } catch (e) {
      return { type: "errorMessage", message: text };
    }
  } catch (e) {
    logger.error("failed to extract error info", e);
    return undefined;
  }
}

class ProblemDetailsError extends Error {
  constructor(
    message: string,
    public statusCode: number,
    public title: string,
    public detail: string
  ) {
    super(`${statusCode},${title},${detail}:${message}`);
  }
}
