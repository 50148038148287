import { FluentIcon } from "./Icons";

import "./ExternalLink.scss";

export function ExternalLink(props: { link: string; text: string }) {
  return (
    <span className="external-link-container">
      <a
        className="external-link"
        href={props.link}
        target="_blank"
        rel="noreferrer"
      >
        {props.text}
      </a>
      <FluentIcon size="xs" name="share"></FluentIcon>
    </span>
  );
}
