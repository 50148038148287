import React, { useCallback, useMemo } from "react";
import { SurveyStringDataset } from "../../../../../lib/application/stats/datasets/SurveyStringDataset";
import { defined } from "../../../../../lib/core/defined";
import { PivotPlaceholder } from "../data_card/PivotPlaceholder";
import { useChangeDataOutputSettings } from "../../../../../lib/application/state/actions/selections/useChangeDataOutputSettings";

import { Button } from "../../../../../components/Button";
import { SortIndicator } from "../../../../../components/DataTable";
import { SortOrder } from "../../../../../lib/core/order";
import { DataSourceSection } from "../../../../../components/charts/shared/DataSourceInfo";
import { config } from "../../../../../config";
import { DataHeader } from "./DataHeader";

import "./TableTabSurveyString.scss";

interface Props {
  cardId: string;
  dataset?: SurveyStringDataset;
}

export function TableTabSurveyString(props: Props) {
  const { dataset } = props;
  const table = useMemo(() => dataset?.tableSurveyString(), [dataset]);

  const [settings, updateSettings] = useChangeDataOutputSettings(props.cardId);
  const cutRows = settings.tableSurveyString.sliceHeadAndTailRows ?? true;
  const columnSort = settings.tableSurveyString.columnSort;

  const handleShowHiddenRows = useCallback(() => {
    updateSettings({
      ...settings,
      tableSurveyString: {
        ...settings.tableSurveyString,
        sliceHeadAndTailRows: false,
      },
    });
  }, [settings, updateSettings]);

  const handleToggleSetSort = useCallback(
    (colKey: string) => {
      const currentSort = settings.tableSurveyString.columnSort;
      if (currentSort?.column === colKey && currentSort.ascending === false) {
        // remove sort
        return updateSettings({
          ...settings,
          tableSurveyString: {
            ...settings.tableSurveyString,
            columnSort: undefined,
          },
        });
      }
      if (currentSort?.column === colKey) {
        updateSettings({
          ...settings,
          tableSurveyString: {
            ...settings.tableSurveyString,
            columnSort: {
              ...currentSort,
              ascending: !currentSort.ascending,
            },
          },
        });
      } else {
        updateSettings({
          ...settings,
          tableSurveyString: {
            ...settings.tableSurveyString,
            columnSort: {
              column: colKey,
              ascending: true,
            },
          },
        });
      }
    },
    [settings, updateSettings]
  );

  const [tableRows, middleRows] = useMemo(() => {
    if (!defined(table)) {
      return [[], []];
    }
    const baseRows = table.rows.slice();
    if (defined(columnSort)) {
      baseRows.sort((left, right) => {
        const leftValue = left[columnSort.column];
        const rightValue = right[columnSort.column];
        if (typeof leftValue === "number" && typeof rightValue === "number") {
          return leftValue - rightValue;
        }
        if (typeof leftValue === "string" && typeof rightValue === "string") {
          return leftValue.localeCompare(rightValue);
        }
        return 0;
      });
      if (columnSort.ascending === false) {
        baseRows.reverse();
      }
    }

    const middleRows = cutRows
      ? baseRows.splice(
          config.surveyString.numDefaultRowsTable / 2,
          baseRows.length - config.surveyString.numDefaultRowsTable
        )
      : [];

    return [baseRows, middleRows];
  }, [columnSort, cutRows, table]);

  const usedCols = useMemo(() => {
    return (
      table?.cols.filter(
        (col) =>
          col.key !== "weight" || settings.tableSurveyString.showWeightColumn
      ) ?? []
    );
  }, [settings.tableSurveyString.showWeightColumn, table?.cols]);

  if (!defined(dataset) || !defined(table)) {
    return <PivotPlaceholder icon={"th"}></PivotPlaceholder>;
  }

  return (
    <div className="infostat-survey-string-table tab-content">
      <div className="dataset-table-container"></div>
      <DataHeader
        settings={settings}
        dataDescription={table.tableDescription}
      ></DataHeader>
      <table>
        <thead>
          <tr>
            {usedCols.map((col) => (
              <th key={col.key} onClick={() => handleToggleSetSort(col.key)}>
                <div className="th-container">
                  <span className="header-text">{col.label}</span>
                  <SortIndicator
                    currentOrder={
                      columnSort?.column === col.key
                        ? columnSort.ascending
                          ? SortOrder.ascending
                          : SortOrder.descending
                        : undefined
                    }
                  ></SortIndicator>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRows.flatMap((row, i) => {
            return (
              <React.Fragment key={i}>
                {i === config.surveyString.numDefaultRowsTable / 2 &&
                  middleRows.length > 0 && (
                    <tr key="cut-indicator">
                      <td className="cut-indicator" colSpan={usedCols.length}>
                        <i>{middleRows.length} rader visas ej</i>{" "}
                        <Button
                          small
                          title="Visa"
                          onClick={handleShowHiddenRows}
                        ></Button>
                      </td>
                    </tr>
                  )}
                <tr
                  key={i}
                  className={i === tableRows.length - 1 ? "last" : ""}
                >
                  {usedCols.map((col) => {
                    const value = row[col.key];
                    if (col.key === "weight") {
                      return (
                        <td className="decimal" key={col.key + "_" + i}>
                          {col.format?.(value) ?? value}
                        </td>
                      );
                    }
                    if (col.key === "value") {
                      return (
                        <td key={col.key + "_" + i}>
                          {value
                            .toString()
                            .split("\n")
                            .map((line, j) => {
                              return (
                                <p
                                  key={col.key + "_" + i + "_" + j}
                                  className="survey-string-value-line"
                                >
                                  {line}
                                </p>
                              );
                            })}
                        </td>
                      );
                    }
                    return <td key={col.key + "_" + i}>{value}</td>;
                  })}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <DataSourceSection mainSource={table.sourceInfo}></DataSourceSection>
    </div>
  );
}
