import { config } from "../../../../config";
import {
  CreateCardEmbedResultDto,
  CreateCardEmbedResultDtoRT,
} from "../../../infra/api_responses/document_embed";
import { HttpResult } from "../../../infra/HttpResult";
import { logger } from "../../../infra/logging";
import { EmbedSettings } from "../../embedded_cards";
import { WorkspaceLatest } from "../../state/stats/workspace/shared";
import { authedRequest } from "../shared";

export function createEmbeddedDocument(
  organizationId: string,
  label: string,
  dataId: number,
  allowedOrigins: string[],
  embedSettings: EmbedSettings,
  document: WorkspaceLatest
): Promise<HttpResult<CreateCardEmbedResultDto>> {
  return authedRequest(
    config.apis.statsV1,
    `admin/embedcards`,
    {
      organization_id: organizationId,
      label,
      data_id: dataId,
      allowed_origins: allowedOrigins,
      embed_settings: embedSettings,
      document,
    },
    "POST"
  ).then((response) => {
    return response.match({
      ok: (data) => {
        try {
          const res = CreateCardEmbedResultDtoRT.check(data);
          return HttpResult.fromOk(res);
        } catch (e) {
          logger.error(e);
          return HttpResult.fromErr({ code: "unknown-error" });
        }
      },
      err: (e) => {
        logger.error(e);
        return HttpResult.fromErr(e);
      },
    });
  });
}
