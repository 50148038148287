import * as _ from "lodash";
import { useCallback } from "react";

import { MeasureSelection } from "../../../../../domain/selections/definitions";
import { defined } from "../../../../../core/defined";
import { makeBreakdownsSoundMut } from "../../../../../domain/measure/breakdowns";
import { logger } from "../../../../../infra/logging";
import { isBreakdownTotal } from "../../../../../domain/measure";

export function useSetBreakdownsMulti<T extends MeasureSelection>(
  measureSelection: T,
  setMeasureSelection: (s: T) => void
) {
  return useCallback(
    (breakdownDimension: string, keys: number[], selected: boolean) => {
      const breakdowns = { ...measureSelection.breakdowns };
      const existingBreakdownSelection = breakdowns?.[breakdownDimension] ?? [];
      const updatedBreakdownSelection = selected
        ? _.uniq([...existingBreakdownSelection, ...keys])
        : existingBreakdownSelection.filter((k) => !keys.includes(k));

      const dims = measureSelection.measure.dimensions;
      const updatedDim = dims.find((d) => d.data_column === breakdownDimension);
      if (!defined(updatedDim)) {
        logger.error(
          "Dimension not found when updating breakdowns: " + breakdownDimension
        );
        return;
      }

      // Auto-add "total" child values
      const childDim = dims.find(
        (d) => d.parent_id === updatedDim.dimension_id
      );
      if (selected && defined(childDim)) {
        const childValuesToBeSelected = childDim.values?.filter(
          (v) =>
            defined(v.parent_id) &&
            keys.includes(v.parent_id) &&
            isBreakdownTotal(v.label)
        );
        if (
          defined(childValuesToBeSelected) &&
          childValuesToBeSelected.length > 0
        ) {
          breakdowns[childDim.data_column] = _.uniq([
            ...(breakdowns[childDim.data_column] ?? []),
            ...childValuesToBeSelected.map((v) => v.id),
          ]);
        }
      }

      const updatedBreakdowns = makeBreakdownsSoundMut(
        {
          ...breakdowns,
          [breakdownDimension]: updatedBreakdownSelection,
        },
        updatedDim,
        dims
      );

      const updatedSelection = {
        ...measureSelection,
        breakdowns: updatedBreakdowns,
      };

      setMeasureSelection(updatedSelection);
      return updatedSelection;
    },
    [measureSelection, setMeasureSelection]
  );
}
