import { NewMetadataInputMicro } from "../../../../../lib/domain-admin/metadata";
import { GeoTypeMicro } from "../../../../../lib/domain/geography";
import {
  AggregationMethod,
  DataValueTypeMicroAll,
} from "../../../../../lib/infra/api_responses/dataset";
import { MetadataInputField, resolutionOptions } from "../../shared/base";

const dataValueOptions: DataValueTypeMicroAll[] = [
  "integer",
  "decimal",
  "point",
  "polygon",
  "line",
];
const geocodeOptions: GeoTypeMicro[] = ["deso", "regso"];
const aggMethodOptions: AggregationMethod[] = [
  "sum",
  "avg",
  "avg-weighted",
  "none",
];

export const microEntries: MetadataInputField<keyof NewMetadataInputMicro>[] = [
  { key: "label", type: "input" },
  { key: "descr_long", type: "input" },
  { key: "value_type", type: "select", options: dataValueOptions },
  { key: "measure", type: "input" },
  { key: "unit_label", type: "input" },
  {
    key: "geo_types",
    type: "multiselect",
    options: geocodeOptions,
    default: [geocodeOptions[0]],
  },
  {
    key: "agg_method_geo",
    label: "Geografisk aggregeringsmetod",
    type: "select",
    options: aggMethodOptions,
  },
  {
    key: "resolution",
    label: "Tidsupplösning",
    type: "select",
    options: resolutionOptions,
  },
  { key: "last_update", type: "date", optional: true },
  { key: "next_update", type: "date", optional: true },
  { key: "ext_source", type: "textarea", optional: true },
  { key: "ext_label", type: "input", optional: true },
  { key: "ext_descr", type: "textarea", optional: true },
  { key: "ext_descr_long", type: "textarea", optional: true },
  { key: "source", type: "input", placeholder: "SCB" },
  { key: "source_url", type: "input", optional: true },
  { key: "public_comment", type: "textarea", optional: true },
  {
    key: "breakdowns",
    type: "breakdowns",
    optional: true,
    label: { label: "Breakdownetikett", placeholder: "ex. Kön" },
    csvColumn: { label: "CSV-kolumn", placeholder: "ex. breakdown1" },
  },
];

export function isGeometricData(
  fields: MetadataInputField<keyof NewMetadataInputMicro>[],
  values: Record<any, any>
): boolean {
  return fields.some((f) => {
    const value = values[f.key];
    return (
      f.key === "value_type" &&
      (value === "point" || value === "polygon" || value === "line")
    );
  });
}
