import {
  Boolean as BooleanRT,
  Record,
  Array as ArrayRT,
  Number as NumberRT,
  String as StringRT,
  Static,
  Union,
  Literal,
  Optional,
  Null,
} from "runtypes";

const MicroManagementEventPlainRT = Union(
  Literal("data_processed"),
  Literal("data_revert"),
  Literal("data_commit"),
  Literal("measure_created"),
  Literal("data_processing_started")
);

const LogEntryCommonRT = Record({
  time: StringRT,
  performed_by: StringRT,
});
const PlainEventRT = LogEntryCommonRT.And(
  Record({
    event: MicroManagementEventPlainRT,
  })
);
const ProcessingErrorEventRT = LogEntryCommonRT.And(
  Record({
    event: Literal("data_processing_error"),
    message: StringRT,
  })
);

const MeasureAdminLogEntryRT = Union(PlainEventRT, ProcessingErrorEventRT);
export type MeasureAdminLogEntry = Static<typeof MeasureAdminLogEntryRT>;

export const TodosMicroRT = Record({
  drafts: ArrayRT(
    Record({
      mikro_id: NumberRT,
      label: StringRT,
      is_draft_measure: BooleanRT,
      has_draft_data: BooleanRT,
      has_draft_dimensions: BooleanRT,
      log: ArrayRT(MeasureAdminLogEntryRT),
    })
  ),
});

export const TodosStatsDtoRT = Record({
  drafts: ArrayRT(
    Record({
      data_id: NumberRT,
      label: StringRT,
      is_draft_measure: BooleanRT,
      has_draft_data: BooleanRT,
      has_draft_dimensions: BooleanRT,
      is_customer_data: Optional(BooleanRT),
      log: ArrayRT(MeasureAdminLogEntryRT),
    })
  ),
});

export const TodosMeasuresForMemberOrgsDtoRT = Record({
  drafts: ArrayRT(
    Record({
      data_id: NumberRT,
      label: StringRT,
      is_draft_measure: BooleanRT,
      has_draft_data: BooleanRT,
      has_draft_dimensions: BooleanRT,
      log: ArrayRT(MeasureAdminLogEntryRT),
      member_org_measurement_owner_org: StringRT,
      member_org_measurement_parent_id: NumberRT.Or(Null),
    })
  ).Or(Null),
});

export type TodosMicroDto = Static<typeof TodosMicroRT>;
export type TodosStatsDto = Static<typeof TodosStatsDtoRT>;
export type TodosMeasuresForMemberOrgsDto = Static<
  typeof TodosMeasuresForMemberOrgsDtoRT
>;
