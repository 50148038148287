import {
  isMicroMeasureRegularDto,
  MicroGeoSelections,
} from "../../application/state/stats/document-core/core-micro";
import { defined } from "../../core/defined";
import { MicroMeasureDto } from "../../infra/api_responses/micro_dataset";
import { GeoTypeMicro } from "../geography";
import { FilterMeasureMicro, MeasureSpecMicro } from "../measure/definitions";

/**
 * Compute filters that are compatible with a measure that is being changed to
 */
export function compatibleFilters(
  currentFilterMeasures: FilterMeasureMicro[],
  currentGeoSelections: MicroGeoSelections | undefined,
  nextMeasure: MicroMeasureDto
): FilterMeasureMicro[] {
  if (currentFilterMeasures.length === 0) {
    // There are no filters, so no incompatibility.
    return currentFilterMeasures;
  }

  const prevMeasureType = currentGeoSelections?.type;
  if (!defined(prevMeasureType) || prevMeasureType === "regso") {
    // If we switch from regso to regso, no difference
    // If we switch from regso to deso, it doesn't matter because deso measures can be filtered
    // by deso OR regso
    return currentFilterMeasures;
  }

  if (
    prevMeasureType === "deso" &&
    measureSupportsOnlyGeoType(nextMeasure, "regso")
  ) {
    return currentFilterMeasures.filter((f) => {
      if (!defined(f.measureSelection)) {
        return true;
      }

      const measure = f.measureSelection.measure;
      return !measureSpecMicroSupportsOnlyGeoType(measure, "deso");
    });
  }

  return currentFilterMeasures;
}

function measureSpecMicroSupportsOnlyGeoType(
  measure: MeasureSpecMicro,
  geoType: GeoTypeMicro
): boolean {
  return singleSupportedGeoType(measure.geoTypes, geoType);
}

function measureSupportsOnlyGeoType(
  measure: MicroMeasureDto,
  geoType: GeoTypeMicro
): boolean {
  return (
    isMicroMeasureRegularDto(measure) &&
    singleSupportedGeoType(measure.geo_types, geoType)
  );
}

function singleSupportedGeoType(
  geoTypes: GeoTypeMicro[],
  geoType: GeoTypeMicro
): boolean {
  return geoTypes.length === 1 && geoTypes[0] === geoType;
}
