import { Checkbox, TextField } from "@fluentui/react";
import { useState } from "react";
import { Link } from "react-router-dom";

import { AlertBox } from "../../../../../components/AlertBox";
import { CreatedResult } from "../../../../../lib/application/requests/admin/measure_management/create";
import { defined } from "../../../../../lib/core/defined";
import { HttpResult } from "../../../../../lib/infra/HttpResult";
import { logger } from "../../../../../lib/infra/logging";
import { pathInfo } from "../../../../../lib/paths";
import { metadataEntryLabel } from "../../shared/base";
import { isDummyField } from "../shared";
import { InfostatDataWarning } from "./InfostatDataWarning";
import { microEntries } from "./shared";
import { StepFooter } from "./StepFooter";
import { CompleteData3 } from "./types";

type CreatedItems = {
  [label: string]: number;
};

function CreatedNotice(props: { created: CreatedItems }) {
  return (
    <AlertBox intent="success">
      <span>
        <h2>Skapat!</h2>
        <span>Du kan nu importera data för måttet/måtten.</span>
        <div>
          {Object.entries(props.created).map(([label, id]) => (
            <div key={id}>
              <Link to={pathInfo.dataAdminHandleDataMicro.pathTemplate}>
                Importera data för: {label}
              </Link>
            </div>
          ))}
        </div>
      </span>
    </AlertBox>
  );
}
interface Props {
  data: CompleteData3;
  handlePrevious: (data: CompleteData3) => void;
  handleConfirm: (data: CompleteData3) => Promise<HttpResult<CreatedResult>>;
}
export function Step4ConfirmMicro(props: Props) {
  const { step1, step2, step3 } = props.data;

  const [created, setCreated] = useState<CreatedItems>();

  return (
    <div>
      <InfostatDataWarning></InfostatDataWarning>
      {defined(created) && <CreatedNotice created={created}></CreatedNotice>}
      <section>
        <h2>Kategori</h2>
        {step1.area} {">"} {step1.subarea} {">"} {step1.subject}
      </section>
      <section>
        <h2>Måttdata</h2>

        {Object.entries(step2)
          .filter(([k, value]) => defined(value) && !isDummyField(k))
          .sort(
            (left, right) =>
              microEntries.findIndex((e) => e.key === left[0]) -
              microEntries.findIndex((e) => e.key === right[0])
          )
          .map(([key, value]) => {
            const entry = microEntries.find((entry) => entry.key === key);
            if (key === "breakdownParents") {
              return null;
            }
            if (!defined(entry)) {
              logger.error("Unknown key: " + key);
              return;
            }
            if (key === "breakdowns") {
              return (
                <div key={key} className="margin-top-md margin-bottom-md">
                  <h3>Breakdowns</h3>
                  {Object.keys(value).length === 0 && <em>Inga breakdowns</em>}
                  {Object.entries(value).map(([key, value]) => {
                    return (
                      <div key={key} className="breakdown-field">
                        <TextField
                          label="CSV-kolumn"
                          value={key}
                          readOnly
                        ></TextField>
                        <TextField
                          label="Etikett"
                          value={value as any}
                          readOnly
                        ></TextField>
                        <Checkbox
                          label="Är underkategori till föregående breakdown"
                          checked={step2.breakdownParents?.[key] !== undefined}
                          disabled
                        ></Checkbox>
                      </div>
                    );
                  })}
                </div>
              );
            }
            return (
              <TextField
                key={key}
                value={Array.isArray(value) ? value.join(", ") : value}
                label={metadataEntryLabel(entry)}
                readOnly
              ></TextField>
            );
          })}
      </section>
      <section>
        <h2>Begränsad åtkomst</h2>
        {step3.mode.locked ? (
          <span>
            <strong>Endast följande organisationer har åtkomst</strong>:{" "}
            {step3.mode.orgs.map((o) => o.name).join(", ")}
          </span>
        ) : (
          <AlertBox intent="warning">
            <span>
              <strong>Publikt</strong>: tillgängligt för alla Infostat-användare
            </span>
          </AlertBox>
        )}
      </section>
      {created && <CreatedNotice created={created}></CreatedNotice>}
      <StepFooter
        handlePrevious={
          created ? undefined : () => props.handlePrevious(props.data)
        }
        handleNext={
          created
            ? undefined
            : () =>
                props.handleConfirm(props.data).then((res) => {
                  res.match({
                    ok: (data) => {
                      const createdData = data.created;
                      if (defined(createdData)) {
                        setCreated(createdData);
                      }
                    },
                    err: () => {},
                  });
                })
        }
        nextTitle="Skapa"
      ></StepFooter>
    </div>
  );
}
