import {
  DefaultValue,
  GetRecoilValue,
  selectorFamily,
  SetRecoilState,
} from "recoil";
import {
  CardIdParams,
  DocCardText,
  DocCardTextCK,
  QuillDocState,
} from "../core";
import { docCardAtomFamily } from "../atoms";

export const textCardContentQuery = selectorFamily<
  QuillDocState | undefined,
  CardIdParams
>({
  key: "textCardContentQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getTextCardOrThrow(get, params);
      return card.data;
    },
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("DefaultValue not implemented");
      }
      setTextCardOrThrow(set, params, (prev) => {
        return { ...prev, data: newValue };
      });
    },
});

export const textCardQuery = selectorFamily<DocCardText, CardIdParams>({
  key: "textCardQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) =>
      getTextCardOrThrow(get, params),
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("DefaultValue not implemented");
      }
      setTextCardOrThrow(set, params, () => newValue);
    },
});

export const textCardCKQuery = selectorFamily<DocCardTextCK, CardIdParams>({
  key: "textCardQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) =>
      getTextCardCKOrThrow(get, params),
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("DefaultValue not implemented");
      }
      setTextCardCKOrThrow(set, params, () => newValue);
    },
});

export const textCardCKContentQuery = selectorFamily<string, CardIdParams>({
  key: "textCardCKContentQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getTextCardCKOrThrow(get, params);
      return card.data;
    },
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("DefaultValue not implemented");
      }
      setTextCardCKOrThrow(set, params, (prev) => {
        return { ...prev, data: newValue };
      });
    },
});

function setTextCardOrThrow(
  set: SetRecoilState,
  params: CardIdParams,
  updater: (prev: DocCardText) => DocCardText
) {
  set(docCardAtomFamily(params.cardStateId), (prev) => {
    if (prev?.type !== "textCardSimple") {
      throw new Error("Attempting to replace non-text card!");
    }
    return updater(prev);
  });
}

function setTextCardCKOrThrow(
  set: SetRecoilState,
  params: CardIdParams,
  updater: (prev: DocCardTextCK) => DocCardTextCK
) {
  set(docCardAtomFamily(params.cardStateId), (prev) => {
    if (prev?.type !== "textCardCK") {
      throw new Error("Attempting to replace non-text-CK card!");
    }
    return updater(prev);
  });
}

function getTextCardOrThrow(get: GetRecoilValue, params: CardIdParams) {
  const card = get(docCardAtomFamily(params.cardStateId));
  if (card?.type !== "textCardSimple") {
    throw new Error("Attempted to get text card, none found or wrong type");
  }

  return card;
}

function getTextCardCKOrThrow(get: GetRecoilValue, params: CardIdParams) {
  const card = get(docCardAtomFamily(params.cardStateId));
  if (card?.type !== "textCardCK") {
    throw new Error("Attempted to get text card, none found or wrong type");
  }

  return card;
}
