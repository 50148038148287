import { useEffect } from "react";

export function useNavigationPrompt(doPrompt: boolean) {
  useEffect(() => {
    if (!doPrompt) {
      return;
    }

    const listener = (event: any) => {
      event.preventDefault();
      return "";
    };

    document.addEventListener("beforeunload", listener);
    return () => {
      document.removeEventListener("beforeunload", listener);
    };
  }, [doPrompt]);
}
