import { DEFAULT_FONT_COLOR_TICKS } from "../../../../lib/application/stats/shared/core/colors/colors";
import { MainChartDimensions } from "../../../../lib/application/stats/shared/core/definitions";
import { YTicksContainer } from "../../../../lib/application/stats/shared/core/ticks";

interface Props {
  dims: MainChartDimensions;
  ticksContainer: YTicksContainer;
  fontColor?: string | null;
  customTickStrokeColor?: string | null;
  hideTickLabels?: boolean;
  format?: (x: string) => string;
  drawMarks?: boolean;
}

export function TicksLeft({
  dims,
  fontColor,
  ticksContainer,
  customTickStrokeColor,
  hideTickLabels = false,
  format = (x: any) => x,
  drawMarks = true,
}: Props) {
  const textStyle = ticksContainer.textStyle;
  return (
    <g transform={`translate(${dims.marginLeft}, ${dims.marginTop})`}>
      {ticksContainer.ticks.map((tick) => (
        <g key={tick.text} transform={`translate(0, ${tick.yOffset})`}>
          {drawMarks && !customTickStrokeColor !== null && (
            <line
              x1="0"
              y1="0"
              x2={-tick.tickSize}
              y2="0"
              stroke={customTickStrokeColor ?? "gray"}
              shapeRendering="crispEdges"
            />
          )}
          {!hideTickLabels && (
            <text
              {...textStyle.svgFontAttrs()}
              textAnchor="end"
              transform={`translate(-${tick.tickSize + tick.labelPadding}, 0)`}
              dy="0.3em"
              fill={fontColor ?? DEFAULT_FONT_COLOR_TICKS}
            >
              {format(tick.text)}
            </text>
          )}
        </g>
      ))}
    </g>
  );
}
