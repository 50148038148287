import ExcelJS from "exceljs";

import { borderStyleThin } from "./_shared_excel";
import { mimeTypeUtf8 } from "../../core/mime";
import { defined } from "../../core/defined";

interface ColTable {
  [key: string]: (string | number | null)[];
}

function addTableSheet(
  workbook: ExcelJS.Workbook,
  sheetName: string,
  data: ColTable
): ExcelJS.Worksheet {
  const sheetData = workbook.addWorksheet(sheetName);

  const keys = Object.keys(data);
  const values = Object.values(data);
  sheetData.addRow(keys);
  for (let i = 0; i < keys.length; i++) {
    sheetData.getCell(sheetData.lastRow!.number, 1 + i).font = { bold: true };
    sheetData.getCell(sheetData.lastRow!.number, 1 + i).border = {
      bottom: borderStyleThin,
    };
  }

  const maxNumValues = Math.max(...values.map((v) => v.length));
  for (let i = 0; i < maxNumValues; i++) {
    const rowValues = keys.map((_, j) => values[j][i]);
    sheetData.addRow(rowValues);
  }

  return sheetData;
}

export async function groupingExportToExcel(
  filename: string,
  data: ColTable,
  classBoundaries?: ColTable
) {
  const workbook = new ExcelJS.Workbook();
  addTableSheet(workbook, "Data", data);

  if (defined(classBoundaries)) {
    addTableSheet(workbook, "Klassgränser", classBoundaries);
  }

  // Actual export mechanism

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: mimeTypeUtf8("xlsx"),
  });
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Clean up
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);
}
