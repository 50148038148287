import { useCallback, useEffect, useMemo, useState } from "react";
import { Card } from "../../../../components/Card";
import {
  deleteDataFrameLink,
  deleteDataLink,
  deleteDataLinkMicro,
  getUserDataFrameLinks,
  getUserDataLinks,
  patchDataFrameLinkDescription,
  patchDataLinkDescription,
  patchDataLinkMicroDescription,
} from "../../../../lib/application/requests/data_links";
import { DefaultLoading } from "../../../../components/Loading";
import { Table, TableData } from "../../../../components/Table";
import { utcTimeStringToDate } from "../../../../lib/core/time";
import { displayYearMonthDate } from "../../../../lib/domain/time";
import { useAppMessages } from "../../../../lib/application/hooks/useAppMessages";
import { displayHttpError } from "../../../../components/errors/HttpErrorNotice";
import { logger } from "../../../../lib/infra/logging";
import { DataLinkDto } from "../../../../lib/infra/api_responses/data_links";
import { defined } from "../../../../lib/core/defined";
import { AlertBox } from "../../../../components/AlertBox";
import { sortBy } from "lodash";
import { SimpleInputModal } from "../../../../components/SimpleInputModal";
import { FluentIcon } from "../../../../components/Icons";
import { validateNonEmptyString } from "../../../../lib/application/validation/string";
import {
  displayHttpErrorInternal,
  HttpResult,
} from "../../../../lib/infra/HttpResult";
import { TextButton } from "../../../../components/buttons/TextButton";
import { ShowDataLinkDialog } from "../../docs/cards/card_general/ExportViaLinkDialog";
import {
  dataLinkSupportZeroFeatures,
  getDataLinkFeatureSupport,
} from "../../../../lib/domain/data_links";

export function DataLinks() {
  const [dataLinks, setDataLinks] = useState<DataLinkDto[] | null>(null);
  const [dataFrameLinks, setDataFrameLinks] = useState<DataLinkDto[] | null>(
    null
  );
  const [dataLinksErr, setDataLinksErr] = useState<string | null>(null);
  const [dataFrameLinksErr, setDataFrameLinksErr] = useState<string | null>(
    null
  );

  const [editingDescriptionForLink, setEditingDescriptionForLink] = useState<
    string | null
  >(null);

  const appMessages = useAppMessages();

  useEffect(() => {
    getUserDataLinks().then((res) => {
      res.match({
        err: (err) => {
          setDataLinksErr("Kunde inte hämta länkar: " + displayHttpError(err));
          logger.error("Failed to load data links", err);
        },
        ok: (data) => {
          setDataLinks(data.links ?? []);
        },
      });
    });

    getUserDataFrameLinks().then((res) => {
      res.match({
        err: (err) => {
          setDataFrameLinksErr(
            "Kunde inte hämta länkar: " + displayHttpError(err)
          );
          logger.error("Failed to load data links", err);
        },
        ok: (data) => {
          setDataFrameLinks(data.links ?? []);
        },
      });
    });
  }, [appMessages]);

  const handleDelete = useCallback(
    (deleteFunc: (id: number) => Promise<HttpResult<unknown>>, id: number) => {
      deleteFunc(id).then((res) => {
        res.match({
          ok: () => {
            setDataLinks((prev) => prev?.filter((l) => l.id !== id) ?? []);
            setDataFrameLinks((prev) => prev?.filter((l) => l.id !== id) ?? []);
          },
          err: (err) => {
            setDataLinksErr(
              "Kunde inte ta bort länk: " + displayHttpError(err)
            );
            logger.error("Failed to delete data link", err);
          },
        });
      });
    },
    []
  );

  const handleDeleteDataLink = useCallback(
    (link: DataLinkDto) => {
      if (!window.confirm("Vill du ta bort denna länk?")) {
        return;
      }
      switch (link.type) {
        case "dataframe":
          handleDelete(deleteDataFrameLink, link.id);
          break;
        case "mikro":
          handleDelete(deleteDataLinkMicro, link.id);
          break;
        case "stats":
        case "survey":
          handleDelete(deleteDataLink, link.id);
          break;
        default:
          appMessages?.add("error", "Okänd länktyp: " + link.type);
          logger.error("Unknown link type", link.type, "link: ", link.id);
          break;
      }
    },
    [appMessages, handleDelete]
  );

  const descriptionSaver = useCallback(
    (
      saveFunc: (
        id: number,
        description: string
      ) => Promise<HttpResult<unknown>>,
      id: number,
      description: string
    ) => {
      return saveFunc(id, description).then((res) => {
        return res.match({
          ok: () => {
            setDataLinks(
              (prev) =>
                prev?.map((l) => (l.id === id ? { ...l, description } : l)) ??
                []
            );
            setDataFrameLinks(
              (prev) =>
                prev?.map((l) => (l.id === id ? { ...l, description } : l)) ??
                []
            );
            setEditingDescriptionForLink(null);
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Kunde inte spara beskrivning: " + displayHttpError(err)
            );
            logger.error(
              "Failed to save data link description",
              displayHttpErrorInternal(err)
            );
          },
        });
      });
    },
    [appMessages]
  );

  const handleSaveLinkDescription = useCallback(
    (link: DataLinkDto, description: string) => {
      switch (link.type) {
        case "dataframe":
          return descriptionSaver(
            patchDataFrameLinkDescription,
            link.id,
            description
          );
        case "mikro":
          return descriptionSaver(
            patchDataLinkMicroDescription,
            link.id,
            description
          );
        case "stats":
        case "survey":
          return descriptionSaver(
            patchDataLinkDescription,
            link.id,
            description
          );
        default:
          return appMessages?.add("error", "Unknown link type: " + link.type);
      }
    },
    [appMessages, descriptionSaver]
  );

  const allLinks = useMemo(() => {
    return (dataLinks ?? []).concat(dataFrameLinks ?? []);
  }, [dataFrameLinks, dataLinks]);

  const [showLink, setShowLink] = useState<DataLinkDto | null>(null);

  const data: TableData<string, (JSX.Element | string)[]> = useMemo(() => {
    if (allLinks.length === 0) {
      return [];
    }

    return sortBy(allLinks, (l) => -new Date(l.created_at).getTime()).map(
      (l) => {
        const createdAt = utcTimeStringToDate(l.created_at);
        const lastAccessed = defined(l.last_accessed)
          ? utcTimeStringToDate(l.last_accessed)
          : null;
        return {
          id: l.link,
          cells: [
            <>
              {l.description}{" "}
              <FluentIcon
                name="edit"
                size={"sm"}
                onClick={() => setEditingDescriptionForLink(l.link)}
              />
            </>,
            <>
              ...
              <TextButton
                className="monospace"
                title={l.link.slice(-10)}
                onClick={() => setShowLink(l)}
              />
            </>,
            displayYearMonthDate(createdAt),
            defined(lastAccessed) ? displayYearMonthDate(lastAccessed) : "-",
            <FluentIcon
              name="trash"
              size="sm"
              onClick={() => handleDeleteDataLink(l)}
            />,
          ],
        };
      }
    );
  }, [allLinks, handleDeleteDataLink]);

  const linkToEdit = allLinks.find((l) => l.link === editingDescriptionForLink);

  if (defined(dataLinksErr)) {
    return (
      <AlertBox intent="warning">
        <>{dataLinksErr}</>
      </AlertBox>
    );
  }
  if (defined(dataFrameLinksErr)) {
    return (
      <AlertBox intent="warning">
        <>{dataFrameLinksErr}</>
      </AlertBox>
    );
  }

  if (!defined(dataLinks) || !defined(dataFrameLinks)) {
    return <DefaultLoading />;
  }

  return (
    <Card id="data-links-settings">
      <h2>Datalänkar</h2>
      <>
        {defined(showLink) && (
          <ShowDataLinkDialog
            link={showLink}
            handleClose={() => setShowLink(null)}
            featureSupport={
              defined(showLink.type)
                ? getDataLinkFeatureSupport(showLink.type)
                : dataLinkSupportZeroFeatures()
            }
          />
        )}
        {defined(linkToEdit) && (
          <SimpleInputModal
            title="Redigera beskrivning"
            input={{
              initialValue: linkToEdit.description ?? "",
              label: "Beskrivning",
              maxLength: 255,
              validate: validateNonEmptyString,
            }}
            autoSelectText
            onDismiss={() => setEditingDescriptionForLink(null)}
            onSaveInput={(description) => {
              handleSaveLinkDescription(linkToEdit, description);
            }}
          />
        )}
      </>
      <>
        {allLinks.length === 0 ? (
          <AlertBox>
            <span>Du har inga datalänkar.</span>
          </AlertBox>
        ) : (
          <Table
            columns={[
              { key: "description", name: "Beskrivning", type: "text" },
              { key: "link", name: "Länk", type: "text" },
              { key: "created_at", name: "Skapad", type: "text" },
              { key: "last_accessed", name: "Senast använd", type: "text" },
              { key: "delete", name: "" },
            ]}
            data={data}
          />
        )}
      </>
    </Card>
  );
}
