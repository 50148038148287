import React from "react";
import useResizeObserver from "use-resize-observer";

export function useElementSize() {
  const { ref, width, height } = useResizeObserver();
  return React.useMemo(
    () => ({
      ref,
      width,
      height,
    }),
    [ref, width, height]
  );
}
