import { MainChartDimensions } from "../../../lib/application/stats/shared/core/definitions";

interface Props {
  dims: MainChartDimensions;
  children?: any;
  chartAreaRef?: React.Ref<SVGGElement>;
}

export default function Bounds(props: Props) {
  const { dims, children, chartAreaRef } = props;
  return (
    <g
      ref={chartAreaRef}
      transform={`translate(${dims.marginLeft}, ${dims.marginTop})`}
    >
      {children}
    </g>
  );
}
