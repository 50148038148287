import { Checkbox, TextField } from "@fluentui/react";
import { useState } from "react";
import { Link } from "react-router-dom";

import { AlertBox } from "../../../../../components/AlertBox";
import { defined } from "../../../../../lib/core/defined";
import { HttpResult } from "../../../../../lib/infra/HttpResult";
import { pathInfo } from "../../../../../lib/paths";
import { metadataEntryLabel } from "../../shared/base";
import { isDummyField } from "../shared";
import { statsEntriesV2 } from "./shared";
import { StepFooter } from "./StepFooter";
import { CompleteData3 } from "./types";

function CreatedNotice() {
  return (
    <AlertBox intent="success">
      <span>
        <h2>Mått skapat!</h2>
        <span>
          Du kan nu importera data för måttet{" "}
          <Link
            to={pathInfo.dataAdminHandleDataMemberOrgsMeasures.pathTemplate}
          >
            {" "}
            här
          </Link>
          .
        </span>
      </span>
    </AlertBox>
  );
}

interface Props {
  data: CompleteData3;
  handlePrevious: (data: CompleteData3) => void;
  handleConfirm: (data: CompleteData3) => Promise<HttpResult<unknown>>;
}
export function Step4ConfirmMemberOrgsMeasure(props: Props) {
  const { step1, step2, step3 } = props.data;
  const [isCreated, setIsCreated] = useState(false);

  return (
    <div>
      {isCreated && <CreatedNotice></CreatedNotice>}
      <section>
        <h2>Kategori</h2>
        {step1.area} {">"} {step1.subarea} {">"} {step1.subject}
      </section>
      <section>
        <h2>Måttdata</h2>
        {Object.entries(step2)
          .filter(([k, value]) => defined(value) && !isDummyField(k))
          .sort(
            (left, right) =>
              statsEntriesV2.findIndex((e) => e.key === left[0]) -
              statsEntriesV2.findIndex((e) => e.key === right[0])
          )
          .map(([key, value]) => {
            if (key === "breakdownParents") {
              return null;
            }
            const entry = statsEntriesV2.find((entry) => entry.key === key);
            if (!defined(entry)) {
              throw new Error("Unknown key: " + key);
            }

            if (key === "breakdowns") {
              return (
                <div key={key} className="margin-top-md margin-bottom-md">
                  <h3>Breakdowns</h3>
                  {Object.keys(value).length === 0 && <em>Inga breakdowns</em>}
                  {Object.entries(value).map(([key, value]) => {
                    return (
                      <div key={key} className="breakdown-field">
                        <TextField
                          label="CSV-kolumn"
                          value={key}
                          readOnly
                        ></TextField>
                        <TextField
                          label="Etikett"
                          value={value as any}
                          readOnly
                        ></TextField>
                        <Checkbox
                          label="Är underkategori till föregående breakdown"
                          checked={step2.breakdownParents?.[key] !== undefined}
                          disabled
                        ></Checkbox>
                      </div>
                    );
                  })}
                </div>
              );
            }
            return (
              <TextField
                key={key}
                value={value}
                label={metadataEntryLabel(entry)}
                readOnly
              ></TextField>
            );
          })}
      </section>
      <section>
        <h2>Ägarorganisation</h2>
        <strong>{step3.owner_org.name}</strong>
      </section>

      {isCreated && <CreatedNotice></CreatedNotice>}
      <StepFooter
        handlePrevious={
          isCreated ? undefined : () => props.handlePrevious(props.data)
        }
        handleNext={
          isCreated
            ? undefined
            : () =>
                props.handleConfirm(props.data).then((res) => {
                  res.match({ ok: () => setIsCreated(true), err: () => {} });
                })
        }
        nextTitle="Skapa"
      ></StepFooter>
    </div>
  );
}
