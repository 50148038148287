import { useCallback, useEffect, useState } from "react";
import { defined } from "../../core/defined";

import { HttpError, HttpResult } from "../../infra/HttpResult";
import { FileNodeInfo } from "../files/tree";
import { LoadingResult } from "../loading/LoadingResult";

/**
 * node_id -> is_favorite
 */
export type OptimisticFavorites = Record<number, boolean>;

export type FileTreeNavigation = ReturnType<typeof useFileTreeNavigation>;
export function useFileTreeNavigation(
  nodeInfoGetter: (nodeId?: number) => Promise<HttpResult<FileNodeInfo>>
) {
  const [currentFolderNodeId, setCurrentFolderNodeId] = useState<
    number | undefined
  >();

  const [optimisticFavorites, setOptimisticFavorites] =
    useState<OptimisticFavorites>({});

  const [fileItemsRes, setFileItemsRes] = useState<
    LoadingResult<HttpError, FileNodeInfo>
  >(LoadingResult.notStarted());

  const updateResult = useCallback((res: HttpResult<FileNodeInfo>) => {
    res.match({
      err: (err) => {
        setFileItemsRes(LoadingResult.err(err));
      },
      ok: (ok) => {
        setFileItemsRes(LoadingResult.from(ok));
      },
    });
    setOptimisticFavorites({});
  }, []);

  const loadFilesByNode = useCallback(
    async (nodeId?: number) => {
      let instantWaitTimeoutHandle: NodeJS.Timeout | undefined;

      // Only set the current result to loading if:
      // a) we don't already have a resource
      //    otherwise, every time we reload, we'll get a flickering load spinner
      // b) we already have a result, but not for this node ID, and the new network result takes
      //    longer than the "instant wait timeout"
      fileItemsRes.match({
        notReady: () => {
          // a)
          setFileItemsRes(LoadingResult.inProgress());
        },
        ready: () => {
          // b)
          if (nodeId !== currentFolderNodeId) {
            instantWaitTimeoutHandle = setTimeout(() => {
              setFileItemsRes(LoadingResult.inProgress());
            }, 300);
          }
        },
      });
      const currentRes = await nodeInfoGetter(nodeId);
      if (defined(instantWaitTimeoutHandle)) {
        clearTimeout(instantWaitTimeoutHandle);
      }
      updateResult(currentRes);
    },
    [currentFolderNodeId, fileItemsRes, nodeInfoGetter, updateResult]
  );

  const reload = useCallback(async () => {
    const currentRes = await nodeInfoGetter(currentFolderNodeId);
    updateResult(currentRes);
  }, [currentFolderNodeId, nodeInfoGetter, updateResult]);

  /**
   * Loads a folder (nodeId) or root folder (undefined)
   */
  const handleOpenFolder = useCallback(
    (nodeId: number | undefined) => {
      setCurrentFolderNodeId(nodeId);
      return loadFilesByNode(nodeId);
    },
    [loadFilesByNode]
  );

  useEffect(() => {
    reload();
    // Reload only on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    optimisticFavorites,
    setOptimisticFavorites,
    fileItemsRes,
    currentFolderNodeId,
    handleOpenFolder,
    loadFiles: reload,
  };
}
