import { useCallback, useContext } from "react";
import { useRecoilValue } from "recoil";

import { useDocSaveProgress } from "./useDocSaveProgress";
import { Progress } from "../../../core/progress";
import { defined } from "../../../core/defined";
import { Timer } from "../../../core/Timer";
import { Milliseconds } from "../../../core/time";
import {
  emptyWorkspace,
  stateToWorkspaceLatest,
} from "../stats/workspace/shared";
import {
  savePotentiallyCorruptDocument,
  updateDocument,
} from "../../requests/docs/documents";
import { PromiseController } from "../../loading/load_status";
import {
  DocCardState,
  DocCardStateNonError,
} from "../stats/document-core/core";
import {
  ReportMetaPersistent,
  reportMetaStateToPersistent,
} from "../stats/document-meta/definitions";
import { DocumentMetadataReloader } from "../stats/useMetadata";
import { replaceInArrayImmut } from "../generic";
import { AppMessagesContext, SaveDocumentContext } from "../../contexts";
import { docCardsListQuery } from "../stats/document-core/docCardsListState";
import { useGetAllCardsCallback } from "./cardCallbacks";
import { displayHttpError } from "../../../../components/errors/HttpErrorNotice";
import { getText } from "../../strings";
import { assertNever } from "../../../core/assert";
import { reportMetaStateQuery } from "../stats/document-meta/queries";
import { logger } from "../../../infra/logging";
import { saveTracker } from "../../SaveTracker";
import { voidFunc } from "../../../core/voidFunc";

export function useSaveDocument(
  documentId: number,
  reloadDocumentMetadata: DocumentMetadataReloader
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [progress, setProgress] = useDocSaveProgress();
  const appMessages = useContext(AppMessagesContext);

  return useCallback(
    (
      dataCardsState?: DocCardState[],
      reportMeta?: ReportMetaPersistent,
      disableRecordPostponed?: boolean
    ): Promise<void> => {
      if (!defined(dataCardsState)) {
        return Promise.resolve();
      }

      const hasError = dataCardsState.some(
        (card) =>
          card.type === "error" ||
          ((card.type === "dataCard" ||
            card.type === "pythonCard" ||
            card.type === "microCard") &&
            card.initState === Progress.Error)
      );

      if (hasError) {
        saveTracker.recordSaveFailed();
        attemptSaveCorruptDocument(documentId, dataCardsState, reportMeta);
        return Promise.resolve();
      }

      // If there is card data that has not been initialized, abort
      const notYetLoaded = dataCardsState.some((card) => {
        switch (card.type) {
          case "dataCard":
          case "pythonCard":
          case "microCard":
            return card.initState !== Progress.Success;
          case "error":
            return true;
          case "microCardImage":
            return false;
          case "textCardCK":
          case "textCardSimple":
            return false;
          default:
            assertNever(card);
        }
      });

      if (notYetLoaded) {
        if (!disableRecordPostponed) {
          saveTracker.recordSavePostponed();
          attemptSaveCorruptDocument(documentId, dataCardsState, reportMeta);
        }
        return Promise.resolve();
      }

      setProgress(Progress.InProgress);

      const timer = new Timer();
      const minShowTimeMs = Milliseconds.second * 2;

      const workspace =
        (defined(dataCardsState) && defined(reportMeta)
          ? stateToWorkspaceLatest(dataCardsState, reportMeta)
          : undefined) ?? emptyWorkspace();
      return updateDocument(documentId, workspace)
        .then((res) => {
          res.match({
            ok: () => {
              saveTracker.recordSaveSucceded();
              reloadDocumentMetadata(new PromiseController());
              const delay = Math.max(0, minShowTimeMs - timer.elapsedMs());
              setTimeout(() => {
                setProgress(Progress.Success);
              }, delay);
            },
            err: (err) => {
              saveTracker.recordSaveFailed();
              attemptSaveCorruptDocument(
                documentId,
                dataCardsState,
                reportMeta
              );
              if (err.code === "not-found") {
                appMessages?.add(
                  "error",
                  getText("cant-save-doc-may-be-deleted")
                );
              } else {
                appMessages?.add("error", displayHttpError(err));
              }
              setProgress(Progress.Error);
            },
          });
        })
        .catch(() => {
          saveTracker.recordSaveFailed();
          attemptSaveCorruptDocument(documentId, dataCardsState, reportMeta);
          appMessages?.add("error", getText("cant-save-doc-unknown-error"));
          setProgress(Progress.Error);
        });
    },
    [appMessages, documentId, reloadDocumentMetadata, setProgress]
  );
}

/**
 * Convenience function for saving updates to a single card.
 * Relies on SaveDocumentContext being set.
 */
export function useSaveCard() {
  const cardsList = useRecoilValue(docCardsListQuery);
  const getAllCards = useGetAllCardsCallback(cardsList);
  const handleSave = useContext(SaveDocumentContext);
  const metaState = useRecoilValue(reportMetaStateQuery);

  const saveFunc = useCallback(
    (card: DocCardStateNonError, disableRecordSavePostponed?: boolean) => {
      if (!defined(handleSave)) {
        return;
      }
      const prevCards = getAllCards();
      const updatedCards = replaceInArrayImmut(
        prevCards,
        (c) => c.id === card.id,
        () => card
      );
      if (!defined(metaState)) {
        logger.error("Meta state not defined in useSaveCard");
        return;
      }
      return handleSave(
        updatedCards,
        {
          ...reportMetaStateToPersistent(metaState),
          editModeOn: true,
        },
        disableRecordSavePostponed
      );
    },
    [getAllCards, handleSave, metaState]
  );

  if (!handleSave) {
    return;
  }
  return saveFunc;
}

export function useSaveCurrentCards() {
  const cardsList = useRecoilValue(docCardsListQuery);
  const metaState = useRecoilValue(reportMetaStateQuery);
  const getAllCards = useGetAllCardsCallback(cardsList);
  const handleSave = useContext(SaveDocumentContext);
  return useCallback(() => {
    if (!defined(handleSave)) {
      return;
    }
    if (!defined(metaState)) {
      return;
    }
    const cards = getAllCards();
    return handleSave(cards, {
      ...reportMetaStateToPersistent(metaState),
      editModeOn: true,
    });
  }, [getAllCards, handleSave, metaState]);
}

function attemptSaveCorruptDocument(
  documentId: number,
  dataCardsState?: DocCardState[],
  reportMeta?: ReportMetaPersistent
) {
  if (!defined(dataCardsState) || !defined(reportMeta)) {
    logger.warn("Attempted to save corrupt document without data or meta");
    return;
  }

  let saveableContent: any;
  try {
    saveableContent = stateToWorkspaceLatest(dataCardsState, reportMeta);
  } catch (e) {
    logger.warn("Failed to convert corrupt document to workspace", e);
    saveableContent = { rawDataCardsState: dataCardsState, reportMeta };
  }

  return savePotentiallyCorruptDocument(documentId, saveableContent)
    .then((res) => {
      res.match({
        ok: voidFunc,
        err: (err) =>
          logger.error(
            "Failed to save corrupt document",
            displayHttpError(err)
          ),
      });
    })
    .catch((err) =>
      logger.error("Failed to save corrupt document", JSON.stringify(err))
    );
}
