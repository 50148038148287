import { AlertBox } from "../../../../../../components/AlertBox";

export function RetiredMeasureWarning() {
  return (
    <AlertBox intent="warning">
      <span>
        Det här måttet är gallrat. Det kommer inte att uppdateras och kan inte
        längre läggas till i nya kort.
      </span>
    </AlertBox>
  );
}
