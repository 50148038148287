import { isEqual } from "lodash";
import { useEffect } from "react";
import { RecoilState } from "recoil";

import { Milliseconds } from "../../core/time";
import { useTimedRecoilState, useTimedState } from "./useTimedState";

const DEFAULT_UPDATE_DELAY_MS = Milliseconds.second;

/**
 * For use with input components that need state changes reflected immediately, but have a global
 * backing state that needs to be updated.
 *
 * Mirror some state (usually global) by keeping a local copy that is updated on every change.
 * Changes to the original state are throttled.
 * If the global state changes from somewhere else, the local state is updated to match.
 */
export function useLocallyMirroredInputState<T>(
  state: RecoilState<T>,
  options?: {
    updateDelayMs?: number;
    afterSetGlobal?: (previous: T, current: T) => void;
  }
) {
  const [globalValue, setGlobalValue, globalChangeTime] =
    useTimedRecoilState(state);
  const [localValue, setLocalValue, localChangeTime] =
    useTimedState<T>(globalValue);

  useEffect(() => {
    const timeoutHandle = setTimeout(() => {
      if (!isEqual(localValue, globalValue)) {
        if (globalChangeTime > localChangeTime) {
          setLocalValue(globalValue);
        } else {
          setGlobalValue(localValue);
          options?.afterSetGlobal?.(globalValue, localValue);
        }
      }
    }, options?.updateDelayMs ?? DEFAULT_UPDATE_DELAY_MS);

    return () => {
      clearInterval(timeoutHandle);
    };
  }, [
    globalChangeTime,
    globalValue,
    localChangeTime,
    localValue,
    options,
    options?.updateDelayMs,
    setGlobalValue,
    setLocalValue,
  ]);

  return [localValue, setLocalValue] as const;
}
