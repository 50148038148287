import { defined } from "../../core/defined";
import { last } from "../../core/last";
import { dateRangeRawContains } from "../measure";
import { DateRangeRaw } from "../measure/definitions";

export function getCompatibleTimeSelection(
  availableDates: string[] | null | undefined,
  prevTimeSelection: DateRangeRaw | undefined
): DateRangeRaw | undefined {
  if (!defined(availableDates) || availableDates.length === 0) {
    return;
  }
  const defaultRange: DateRangeRaw = [
    availableDates[availableDates.length - 1],
    availableDates[availableDates.length - 1],
  ];

  if (!defined(prevTimeSelection)) {
    return defaultRange;
  }

  const availRange: DateRangeRaw = [
    availableDates[0],
    availableDates[availableDates.length - 1],
  ];
  if (dateRangeRawContains(availRange, prevTimeSelection)) {
    return prevTimeSelection;
  }

  return defaultRange;
}

export function latestPointSelection(
  prevTimeSelection: DateRangeRaw | undefined
): DateRangeRaw | undefined {
  if (!defined(prevTimeSelection)) {
    return;
  }
  const lastTime = last(prevTimeSelection);
  if (!defined(lastTime)) {
    return;
  }
  return [lastTime, lastTime];
}

export function availableDatesToLatestPoint(
  availableDates: string[]
): DateRangeRaw | undefined {
  const lastTime = last(availableDates);
  if (!defined(lastTime)) {
    return;
  }
  return [lastTime, lastTime];
}

export function availableDatesToMaximumSpan(
  availableDates: string[]
): DateRangeRaw | undefined {
  const firstTime = availableDates[0];
  const lastTime = last(availableDates);
  if (!defined(firstTime) || !defined(lastTime)) {
    return;
  }
  return [firstTime, lastTime];
}
