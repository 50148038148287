import { range } from "lodash";
import { defined } from "../../../../core/defined";
import { GeographiesSerializable } from "../../../../domain/geography";
import { measureVariableToLabel } from "../../../../domain/measure/definitions";
import {
  DataSelection,
  DataSelectionGrouping,
} from "../../../../domain/selections/definitions";
import { SelectionsIncompleteSpec } from "./_core-shared";
import { DocCardMicro, DocCardStats } from "./core";
import { currentlyValidGeoSelection } from "./queries/dataCard";

function hasValidGeoSelection(
  card: DocCardStats,
  geographies: GeographiesSerializable
) {
  const validGeoSelection = currentlyValidGeoSelection(card, geographies);
  if (Object.values(validGeoSelection).every((v) => v.length === 0)) {
    return false;
  }
  return true;
}

function validateBreakdowns(card: DocCardStats): string | undefined {
  const groupingSelection = card.data.groupingSelection;
  const selections: (DataSelection | DataSelectionGrouping)[] =
    card.data.dataSelections.concat(
      defined(groupingSelection) ? [groupingSelection] : []
    );
  const errors = selections
    .map((s) => {
      const dims = s.measureSelection?.measure.dimensions.filter((d) => {
        switch (d.type) {
          case "mikro_breakdown":
          case "stats_breakdown":
          case "survey_subquestion":
          case "survey_value":
            return true;
          case "survey_background":
            // Survey background dimensions don't need to be selected
            return false;
        }
      });
      const breakdowns = s.measureSelection?.breakdowns;
      if (!defined(dims)) {
        return undefined;
      }
      for (const dim of dims) {
        const selected = breakdowns?.[dim.data_column];
        if (!defined(selected) || selected.length === 0) {
          return dim.label;
        }
      }

      return undefined;
    })
    .filter(defined);

  return errors[0];
}

function validateBreakdownsMicro(card: DocCardMicro): string | undefined {
  const errors = card.data.dataSelections
    ?.map((s) => {
      const dims = s.measure?.dimensions?.filter((d) => {
        switch (d.type) {
          case "mikro_breakdown":
          case "stats_breakdown":
          case "survey_subquestion":
          case "survey_value":
            return true;
          case "survey_background":
            // Survey background dimensions don't need to be selected
            return false;
        }
      });
      const breakdowns = s.selectedDimensions;
      if (!defined(dims)) {
        return undefined;
      }
      for (const dim of dims) {
        const selected = breakdowns?.[dim.data_column];
        if (!defined(selected) || selected.length === 0) {
          return dim.label;
        }
      }

      return undefined;
    })
    .filter(defined);

  return errors?.[0];
}

export function docCardSelectionValid(
  card: DocCardStats,
  geographies: GeographiesSerializable
): SelectionsIncompleteSpec | undefined {
  const invalidBreakdown = validateBreakdowns(card);
  const invalidBreakdownInfo = defined(invalidBreakdown)
    ? ({
        type: "breakdown",
        dimension: invalidBreakdown,
      } as SelectionsIncompleteSpec)
    : undefined;

  const primarySelection = card.data.dataSelections[0];
  const primaryType = primarySelection.measureSelection?.valueType;

  // Validate geo selections
  // Survey types have no geo selections
  if (primaryType !== "survey" && primaryType !== "survey_string") {
    if (!defined(card.data.groupingSelection)) {
      // If there is a grouping selection, then geo selections aren't used --
      // we always group on municipality
      const validGeoSelection = hasValidGeoSelection(card, geographies);
      if (!validGeoSelection) {
        return { type: "geo" };
      }
    }
  }

  if (defined(card.data.groupingSelection)) {
    const path = card.data.groupingSelection.subjectPath;
    if (!range(0, 3).every((i) => defined(path[i]))) {
      return { type: "incomplete-grouping-subject-path" };
    }
  }

  // survey & survey_string: we check breakdowns only
  return invalidBreakdownInfo;
}

export function validateMicroCardSelection(
  card: DocCardMicro
): SelectionsIncompleteSpec | undefined {
  const invalidBreakdown = validateBreakdownsMicro(card);
  const invalidBreakdownInfo = defined(invalidBreakdown)
    ? ({
        type: "breakdown",
        dimension: invalidBreakdown,
      } as SelectionsIncompleteSpec)
    : undefined;

  if (defined(invalidBreakdownInfo)) {
    return invalidBreakdownInfo;
  }

  for (const d of card.data.dataSelections ?? []) {
    if (d.type !== "primary") {
      return;
    }
    const variables = d.measure?.computed?.variables;
    if (defined(variables)) {
      const variableNames = Object.keys(variables);
      if (!defined(d.computedMeasureVariablesConfig)) {
        return {
          type: "missing-variable-input",
          variable: measureVariableToLabel(variableNames[0]),
        };
      }
      for (const name of variableNames) {
        const selected = d.computedMeasureVariablesConfig[name];
        if (!defined(selected)) {
          return { type: "missing-variable-input", variable: name };
        }
      }
    }
  }
}
