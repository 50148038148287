import { isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useCallback, useState } from "react";
import { useRecoilState } from "recoil";

import { defined } from "../../../../core/defined";
import { Categories, defaultSubject } from "../../../../domain/categories";
import { geoTypesMicro } from "../../../../domain/geography";
import { FilterMeasureMicro } from "../../../../domain/measure/definitions";
import { logger } from "../../../../infra/logging";
import {
  getMicroDimensionsWithCache,
  getMicroMeasuresWithCache,
} from "../../../requests/datasets/micro";
import { replaceAtIndexImmut } from "../../generic";
import {
  measureToMeasureSpecMicro,
  MicroSubjectPath,
} from "../../stats/document-core/core-micro";
import { singleMicroCardQuery } from "../../stats/document-core/queries/microCard";
import { getDefaultSelectedDimensions } from "../../../../domain/measure";

type CallbackType = (
  filterMeasureIndex: number,
  path: MicroSubjectPath
) => Promise<unknown>;

export function useChangeSubjectPathFilterMeasure(
  cardId: string,
  autofill: boolean,
  microCategories: Categories,
  adminShowDraftData: boolean
): [CallbackType, boolean] {
  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: cardId })
  );
  const geoType = card.data.geoSelections?.type;
  const [changePending, setChangePending] = useState(false);

  const callback: CallbackType = useCallback(
    (filterMeasureIndex: number, path: MicroSubjectPath) => {
      const filledPath = autofill
        ? defaultSubject(path, microCategories)
        : path;
      if (!defined(filledPath) || !filledPath.every(defined)) {
        return Promise.resolve();
      }

      const prevFilterMeasure = card.data.filterMeasures[filterMeasureIndex];
      if (!defined(prevFilterMeasure)) {
        logger.warn("Existing filter measure not found");
        return Promise.resolve();
      }

      // If the resulting path is the same as the current path, do nothing.
      if (isEqual(filledPath, prevFilterMeasure.subjectPath)) {
        return Promise.resolve();
      }

      setChangePending(true);
      return getMicroMeasuresWithCache(
        filledPath,
        adminShowDraftData,
        defined(geoType) ? [geoType] : geoTypesMicro,
        ["decimal", "integer"]
      )
        .then(async (res) => {
          const availableMeasures = res.match({
            ok: (data) => {
              return data;
            },
            err: (err) => {
              logger.error(err);
              return undefined;
            },
          });
          if (!defined(availableMeasures)) {
            logger.warn("No available measures found for path:", filledPath);
            return;
          }

          // FIXME: select default micro measure using default flag
          const defaultMeasure = availableMeasures[0];

          const dimensions = (
            await getMicroDimensionsWithCache(
              defaultMeasure.mikro_id,
              adminShowDraftData
            )
          ).match({
            ok: (data) => {
              return data ?? [];
            },
            err: (err) => {
              logger.error(err);
              return undefined;
            },
          });

          if (!defined(dimensions)) {
            logger.error(
              "dimensions object not populated (nor empty array) - this means an error occurred"
            );
            return;
          }

          const selectedDimensions = getDefaultSelectedDimensions(dimensions);

          setCard((prev) => {
            const filterMeasure: FilterMeasureMicro = {
              id: uuidv4(),
              subjectPath: filledPath,
              measureSelection: {
                measure: measureToMeasureSpecMicro(defaultMeasure, dimensions),
                selectedDimensions,
              },
            };

            return {
              ...prev,
              data: {
                ...prev.data,
                filterMeasures: replaceAtIndexImmut(
                  prev.data.filterMeasures,
                  filterMeasureIndex,
                  filterMeasure
                ),
              },
            };
          });
        })
        .finally(() => {
          setChangePending(false);
        });
    },
    [
      adminShowDraftData,
      autofill,
      card.data.filterMeasures,
      geoType,
      microCategories,
      setCard,
    ]
  );

  return [callback, changePending];
}
