import { isEqual } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import { defined } from "../../../../core/defined";
import { Categories, defaultSubject } from "../../../../domain/categories";
import { GeoTypeMicro } from "../../../../domain/geography";
import {
  MeasureSelectionGeoMicroPartial,
  MeasureSelectionMicroPartial,
  PrimaryMeasureSelectionMicroPartial,
  SelectedDimensionsV2,
} from "../../../../domain/measure/definitions";
import { compatibleFilters } from "../../../../domain/micro/filter_measures";
import { MicroMeasure } from "../../../../domain/micro/MicroMeasure";
import { getCompatibleTimeSelection } from "../../../../domain/micro/timeSelection";
import { DataValueTypeMicroAll } from "../../../../infra/api_responses/dataset";
import { logger } from "../../../../infra/logging";
import {
  getMicroAvailableDates,
  getMicroDimensionsWithCache,
  getMicroMeasuresWithCache,
} from "../../../requests/datasets/micro";
import { getText } from "../../../strings";
import { replaceInArrayImmut } from "../../generic";
import {
  DataSelectionMicro,
  isMicroMeasureRegularDto,
  makeMicroDataGeoSelection,
  makePrimaryMicroDataSelection,
  MicroSubjectPath,
} from "../../stats/document-core/core-micro";
import { singleMicroCardQuery } from "../../stats/document-core/queries/microCard";
import { ensureCompatibleSelectedTab } from "./shared";
import { updateGeoSelections } from "./updates";
import { getDefaultSelectedDimensions } from "../../../../domain/measure";
import { CardUpdateCountContext } from "../../../contexts";
import { useApplyChangeMicro } from "../../stats/useApplyChangeMicro";
import { DocCardMicro } from "../../stats/document-core/core";
import { microSettingsCustomBreakpointsReset } from "../../stats/document-core/create";

type CallbackType = (
  selectionId: string | undefined,
  path: MicroSubjectPath
) => Promise<unknown>;

export function useChangeSubjectPath(
  cardId: string,
  autofill: boolean,
  microCategories: Categories,
  adminShowDraftData: boolean,
  acceptGeoTypes: GeoTypeMicro[],
  acceptValueTypes: DataValueTypeMicroAll[]
): [CallbackType, boolean] {
  const card = useRecoilValue(singleMicroCardQuery({ cardStateId: cardId }));
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const currentlySelectedMeasures = useMemo(() => {
    return (
      card.data.dataSelections
        ?.map((s) => {
          const id = s.measure?.id;
          if (!defined(id)) {
            return;
          }
          return [
            id,
            s.type === "primary" ? s.measure?.computed?.type : undefined,
          ] as const;
        })
        .filter(defined) ?? []
    );
  }, [card.data.dataSelections]);

  const handleApply = useApplyChangeMicro(cardId);

  const [changePending, setChangePending] = useState(false);

  const callback: CallbackType = useCallback(
    (selectionId: string | undefined, path: MicroSubjectPath) => {
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;
      const filledPath = autofill
        ? defaultSubject(path, microCategories)
        : path;
      if (!defined(filledPath) || !filledPath.every(defined)) {
        return Promise.resolve();
      }
      const prevSelection = card.data.dataSelections?.find(
        (s) => s.id === selectionId
      );
      const prevPath = prevSelection?.subjectPath;

      // If the resulting path is the same as the current path, do nothing.
      if (isEqual(filledPath, prevPath)) {
        logger.info("Path is the same, not updating");
        return Promise.resolve();
      }

      setChangePending(true);
      return getMicroMeasuresWithCache(
        filledPath,
        adminShowDraftData,
        acceptGeoTypes,
        acceptValueTypes
      )
        .then(async (res) => {
          const availableMeasures = res.ok();
          if (!defined(availableMeasures)) {
            logger.warn("No available measures found for path:", filledPath);
            return;
          }
          if (shouldAbort()) {
            return;
          }

          // TODO: select default micro measure using default flag
          const selectableMeasures = availableMeasures.filter(
            (a) =>
              !currentlySelectedMeasures.some(
                (selected) =>
                  selected[0] === a.mikro_id &&
                  selected[1] ===
                    (isMicroMeasureRegularDto(a)
                      ? a.computed_measurement_type
                      : undefined)
              )
          );
          const defaultMeasure = selectableMeasures[0];
          const prev = card;
          const prevSelection = prev.data.dataSelections?.find(
            (s) => s.id === selectionId
          );
          const dataSelections = prev.data.dataSelections;
          const updatedDataSelections1 = !defined(dataSelections)
            ? undefined
            : replaceInArrayImmut(
                dataSelections,
                (s) => s.id === selectionId,
                (s) => {
                  return {
                    ...s,
                    multiSelectEnabled:
                      s.type === "primary" ? s.multiSelectEnabled : true,
                    subjectPath: filledPath,
                    measure: undefined,
                  };
                }
              );

          const updatedCard1: DocCardMicro = {
            ...prev,
            data: {
              ...prev.data,
              settings:
                prevSelection?.type === "primary"
                  ? microSettingsCustomBreakpointsReset(prev.data.settings)
                  : prev.data.settings,
              dataSelections: updatedDataSelections1,
            },
          };

          if (!defined(defaultMeasure)) {
            return handleApply(updatedCard1);
          }

          const dimensions = (
            await getMicroDimensionsWithCache(
              defaultMeasure.mikro_id,
              adminShowDraftData
            )
          )
            .map((d) => d ?? [])
            .ok();

          if (!defined(dimensions)) {
            logger.error(
              "dimensions object not populated (nor empty array) - this means an error occurred"
            );
            return;
          }

          const selectedDimensions = getDefaultSelectedDimensions(dimensions);
          const availableDates = (
            await getMicroAvailableDates(
              defaultMeasure.mikro_id,
              selectedDimensions,
              adminShowDraftData
            )
          ).ok();

          if (shouldAbort()) {
            return;
          }
          if (!defined(availableDates)) {
            logger.warn(
              "No available dates found for measure:",
              defaultMeasure
            );
            return undefined;
          }
          const microMeasure = new MicroMeasure(defaultMeasure, dimensions);

          const selections = prev.data.dataSelections;
          if (!defined(prevSelection)) {
            const newSelection = makeNewSelection(
              microMeasure,
              selectedDimensions,
              availableDates
            );

            const updatedDataSelections = defined(selections)
              ? selections.concat(newSelection)
              : [newSelection];
            const geoSelections = updateGeoSelections(
              updatedDataSelections,
              prev.data
            );
            const compatibleTab = ensureCompatibleSelectedTab(
              prev.data.selectedTab,
              updatedDataSelections,
              undefined
            );
            return handleApply({
              ...prev,
              data: {
                ...prev.data,
                selectedTab: compatibleTab,
                dataSelections: updatedDataSelections,
                ...geoSelections,
              },
            });
          }

          const dataSelection = updatedDataSelection(
            prevSelection,
            microMeasure,
            selectedDimensions,
            availableDates
          );
          if (!defined(dataSelection)) {
            // No change
            return;
          }

          // When changing the primary selection, we have to remove incompatible filters.
          // If a non-primary selection is changed, no change is needed.
          const remainingFilters =
            prevSelection.type === "primary"
              ? compatibleFilters(
                  prev.data.filterMeasures,
                  prev.data.geoSelections,
                  defaultMeasure
                )
              : prev.data.filterMeasures;
          if (remainingFilters.length < prev.data.filterMeasures.length) {
            if (
              !window.confirm(
                getText(
                  "micro-confirm-change-measure-remove-incompatible-filters"
                )
              )
            ) {
              return prev;
            }
          }

          const updatedDataSelections: DataSelectionMicro[] = !defined(
            prev.data.dataSelections
          )
            ? [dataSelection]
            : replaceInArrayImmut(
                prev.data.dataSelections,
                (s) => s?.id === dataSelection?.id,
                () => dataSelection
              );
          const geoSelections = updateGeoSelections(
            updatedDataSelections,
            prev.data
          );
          handleApply({
            ...prev,
            data: {
              ...prev.data,
              // Switch back to map-select mode if there is no selection to show data for
              selectedTab: ensureCompatibleSelectedTab(
                prev.data.selectedTab,
                updatedDataSelections,
                geoSelections.geoSelections
              ),
              settings:
                prevSelection.type === "primary"
                  ? microSettingsCustomBreakpointsReset(prev.data.settings)
                  : prev.data.settings,
              filterMeasures: remainingFilters,
              dataSelections: updatedDataSelections,
              ...geoSelections,
            },
          });
        })
        .finally(() => {
          setChangePending(false);
        });
    },
    [
      acceptGeoTypes,
      acceptValueTypes,
      adminShowDraftData,
      autofill,
      card,
      currentlySelectedMeasures,
      getCurrentCount,
      incrementUpdateCount,
      microCategories,
      handleApply,
    ]
  );

  return [callback, changePending];
}

function makeNewSelection(
  defaultMeasure: MicroMeasure,
  defaultSelectedDimensions: SelectedDimensionsV2,
  availableDates: string[]
) {
  if (defaultMeasure.isPrimary()) {
    return makePrimaryMicroDataSelection(
      defaultMeasure.subjectPath(),
      defaultMeasure.dto(),
      defaultMeasure.dimensionsDto(),
      availableDates,
      defaultSelectedDimensions,
      undefined
    );
  }
  return makeMicroDataGeoSelection(
    defaultMeasure.subjectPath(),
    defaultMeasure.dto(),
    defaultMeasure.dimensionsDto(),
    availableDates ?? [],
    defaultSelectedDimensions
  );
}

function updatedDataSelection(
  prevSelection: MeasureSelectionMicroPartial,
  defaultMeasure: MicroMeasure,
  defaultSelectedDimensions: SelectedDimensionsV2,
  availableDates: string[]
) {
  if (prevSelection.type === "primary" && defaultMeasure.isPrimary()) {
    return {
      ...prevSelection,
      subjectPath: defaultMeasure.subjectPath(),
      timeSelection: getCompatibleTimeSelection(
        availableDates,
        prevSelection.timeSelection
      ),
      availableDates: availableDates ?? [],
      selectedDimensions: defaultSelectedDimensions,
      measure: defaultMeasure.measureSpec(),
    } as PrimaryMeasureSelectionMicroPartial;
  } else if (defaultMeasure.isPrimary()) {
    // Selected measure is primary but previous was not
    return {
      ...makePrimaryMicroDataSelection(
        defaultMeasure.subjectPath(),
        defaultMeasure.dto(),
        defaultMeasure.dimensionsDto(),
        availableDates,
        defaultSelectedDimensions,
        undefined
      ),
      id: prevSelection.id,
      timeSelection: getCompatibleTimeSelection(
        availableDates,
        prevSelection.timeSelection
      ),
    } as PrimaryMeasureSelectionMicroPartial;
  } else if (
    prevSelection.type === "geo-micro" &&
    !defaultMeasure.isPrimary()
  ) {
    return {
      ...prevSelection,
      measure: defaultMeasure.measureSpec(),
      subjectPath: defaultMeasure.subjectPath(),
      availableDates: availableDates ?? [],
      selectedDimensions: defaultSelectedDimensions,
      timeSelection: getCompatibleTimeSelection(
        availableDates,
        prevSelection.timeSelection
      ),
    } as MeasureSelectionGeoMicroPartial;
  } else if (!defaultMeasure.isPrimary()) {
    // Selected measure is geo but previous was not
    return {
      ...makeMicroDataGeoSelection(
        defaultMeasure.subjectPath(),
        defaultMeasure.dto(),
        defaultMeasure.dimensionsDto(),
        availableDates ?? [],
        defaultSelectedDimensions
      ),
      id: prevSelection.id,
      timeSelection: getCompatibleTimeSelection(
        availableDates,
        prevSelection.timeSelection
      ),
    } as MeasureSelectionGeoMicroPartial;
  }
}
