import _ from "lodash";

import { defined } from "../../../../core/defined";
import { propertiesEqual } from "../../../../core/propertiesEqual";
import { MeasureSelection } from "../../../../domain/measure/definitions";
import { DataSelection } from "../../../../domain/selections/definitions";
import { DocCardStats, GeoSelections } from "./core";
import { DataOutputSettings } from "./DataOutputSettings";

function measureSelectionsEqual(
  left: MeasureSelection | undefined,
  right: MeasureSelection | undefined
): boolean {
  if (!defined(left) || !defined(right)) {
    return defined(left) === defined(right);
  }
  if (left.measure.data_id !== right.measure.data_id) {
    return false;
  }
  const omittedFields: (keyof MeasureSelection)[] = ["measure"];
  return _.isEqual(_.omit(left, omittedFields), _.omit(right, omittedFields));
}

function subjectPathsEqual(a: string[], b: string[]): boolean {
  return a.join("") === b.join("");
}

export function dataSelectionsEqual(
  left: DataSelection | undefined,
  right: DataSelection | undefined
): boolean {
  if (!defined(left) || !defined(right)) {
    return defined(left) === defined(right);
  }
  return (
    left.id === right.id &&
    subjectPathsEqual(left.subjectPath, right.subjectPath) &&
    measureSelectionsEqual(left.measureSelection, right.measureSelection)
  );
}

export function dataSelectionArraysEqual<T extends DataSelection>(
  prev: T[],
  next: T[]
): boolean {
  if (prev.length === next.length) {
    for (let i = 0; i < prev.length; i++) {
      const left = prev[i];
      const right = next[i];
      if (!dataSelectionsEqual(left, right)) {
        return false;
      }
    }
    return true;
  }
  return false;
}

function geoSelectionsEqual(
  left: GeoSelections | undefined,
  right: GeoSelections | undefined
): boolean {
  if (!defined(left) || !defined(right)) {
    return defined(left) === defined(right);
  }

  return _.isEqual(left, right);
}

export function dataCardStateEqual(
  left: DocCardStats,
  right: DocCardStats
): boolean {
  if (!propertiesEqual(left, right, ["isEditing", "label", "type"])) {
    return false;
  }

  const leftData = left.data;
  const rightData = right.data;
  return (
    _.isEqual(leftData.settings, rightData.settings) &&
    geoSelectionsEqual(leftData.geoSelections, rightData.geoSelections) &&
    dataSelectionArraysEqual(
      leftData.dataSelections,
      rightData.dataSelections
    ) &&
    dataSelectionsEqual(leftData.groupingSelection, rightData.groupingSelection)
  );
}

export function dataSettingsEqual(
  left: DataOutputSettings,
  right: DataOutputSettings
): boolean {
  return _.isEqual(left, right);
}
