import { Checkbox, Slider } from "@fluentui/react";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import {
  ButtonsFooter,
  ButtonsFooterLeft,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";
import { Button } from "../../../../../components/Button";
import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  availableTimespanQuery,
  timeResolutionQuery,
} from "../../../../../lib/application/state/stats/document-core/queries/generalDataCard";
import { last } from "../../../../../lib/core/last";
import {
  TimeResolution,
  getDateFormatter,
} from "../../../../../lib/domain/time";
import { defined } from "../../../../../lib/core/defined";
import { SelectableTime } from "../../../../../lib/application/state/stats/document-core/queries/shared";
import { utcTimeStringToDate } from "../../../../../lib/core/time";
import { singleDataCardQuery } from "../../../../../lib/application/state/stats/document-core/queries/dataCard";
import { useSetForecast } from "../../../../../lib/application/state/actions/forecasts";
import { DataOutputSettingsPartial } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";

interface Props {
  cardId: string;
  isOpen: boolean;
  onClose: () => void;
}

const DEFAULT_NUM_FUTURE_STEPS = 5;

export function ForecastDialog(props: Props) {
  const { cardId } = props;
  const queryProps = { cardStateId: cardId };
  const availableTimespan = useRecoilValue(availableTimespanQuery(queryProps));
  const resolutionRaw = useRecoilValue(timeResolutionQuery(queryProps));
  if (!defined(resolutionRaw)) {
    throw new Error("Resolution not defined");
  }
  const lastAvailableTimePoint = getLastAvailableTimePoint(availableTimespan);
  if (!defined(lastAvailableTimePoint)) {
    throw new Error("Last available time point not defined");
  }

  return (
    <ForecastDialogInner
      {...props}
      resolutionRaw={resolutionRaw}
      lastAvailableTimepoint={lastAvailableTimePoint}
    />
  );
}

function ForecastDialogInner(
  props: Props & { resolutionRaw: string; lastAvailableTimepoint: string }
) {
  const card = useRecoilValue(
    singleDataCardQuery({ cardStateId: props.cardId })
  );
  const resolution = TimeResolution.deserialize(props.resolutionRaw);
  const forecast = card.data.settings.forecast;
  const [showUncertainty, setShowUncertainty] = useState<boolean>(
    forecast.show ? forecast.showUncertaintyInterval : true
  );

  const sliderSettings: { currentValue: number; max: number } =
    getSliderSettings(
      resolution,
      card.data.settings,
      props.lastAvailableTimepoint
    );
  const [currentValue, setCurrentValue] = useState<number>(
    sliderSettings.currentValue
  );
  const handleSetForecast = useSetForecast(card, props.lastAvailableTimepoint);
  const format = useMemo(() => getDateFormatter(resolution), [resolution]);

  const handleApply = useCallback(() => {
    const newUntilDate = resolution.incrementDateBySteps(
      utcTimeStringToDate(props.lastAvailableTimepoint),
      currentValue + 1 // currentValue is zero based
    );
    handleSetForecast(newUntilDate, showUncertainty);

    props.onClose();
  }, [currentValue, handleSetForecast, props, resolution, showUncertainty]);

  return (
    <FluentModal title="Trend" isOpen={props.isOpen} onClose={props.onClose}>
      <FluentModalBody>
        <p>
          Beräkna en trendframskrivning från senast tillgänglig data och fram
          till vald tidpunkt.
        </p>
        <div className="section">
          <Slider
            onChange={(value) => setCurrentValue(value)}
            value={currentValue}
            min={0}
            max={sliderSettings.max}
            valueFormat={(val) => {
              const date = resolution.incrementDateBySteps(
                utcTimeStringToDate(props.lastAvailableTimepoint),
                val + 1
              );
              return format(date);
            }}
          />
        </div>
        <Checkbox
          onChange={(e, checked) => {
            if (!defined(checked)) {
              return;
            }
            setShowUncertainty(checked);
          }}
          checked={showUncertainty}
          label="Visa osäkerhetsintervall"
        />
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterLeft>
            <Button title="Avbryt" onClick={props.onClose}></Button>
          </ButtonsFooterLeft>
          <ButtonsFooterRight>
            <Button
              intent="primary"
              title="Spara"
              onClick={handleApply}
            ></Button>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModal>
  );
}

function getSliderSettings(
  resolution: TimeResolution,
  settings: DataOutputSettingsPartial,
  lastAvailableTimepoint: string
) {
  const maxNumPeriods = resolution.forecastMaxPeriods();
  const until =
    settings.forecast?.show === true ? settings.forecast.until : undefined;
  if (!defined(until)) {
    return {
      currentValue: DEFAULT_NUM_FUTURE_STEPS,
      max: maxNumPeriods,
    };
  }

  const distance = resolution.distance(
    utcTimeStringToDate(lastAvailableTimepoint),
    utcTimeStringToDate(until)
  );

  return { currentValue: distance, max: maxNumPeriods };
}

function getLastAvailableTimePoint(
  availableTimespan: SelectableTime | undefined
) {
  if (!defined(availableTimespan)) {
    return undefined;
  }
  if (availableTimespan.type === "range") {
    return last(availableTimespan.range);
  }
  return last(availableTimespan.timePoints);
}
