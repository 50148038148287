import { useRecoilCallback } from "recoil";

import { defined } from "../../../../core/defined";
import { DocListItem } from "../../stats/document-core/atoms";
import {
  cardStyleContainerGeoMicroLinksAtom,
  styleContainersGeoMicroAtomFamily,
} from "../../stats/document-style/atoms";
import {
  CardStyleContainerGeoMicroLink,
  StyleContainerGeoMicro,
} from "../../stats/document-style/definitions";
import { uniqBy } from "lodash";

export function useSetAllGeoMicroStyles() {
  return useRecoilCallback(
    ({ set }) =>
      (styles: StyleContainerGeoMicro[]) => {
        for (const style of styles) {
          set(styleContainersGeoMicroAtomFamily(style.id), style);
        }
      },
    []
  );
}

export function useSetAllGeoMicroLinks() {
  return useRecoilCallback(
    ({ set }) =>
      (links: CardStyleContainerGeoMicroLink[]) => {
        set(cardStyleContainerGeoMicroLinksAtom, links);
      },
    []
  );
}

export function useAddGeoMicroLinks() {
  return useRecoilCallback(
    ({ set }) =>
      (links: CardStyleContainerGeoMicroLink[]) =>
        set(cardStyleContainerGeoMicroLinksAtom, (prevLinks) => {
          if (!Array.isArray(prevLinks)) {
            throw new Error("links is not an array");
          }
          return uniqBy(
            [...prevLinks, ...links],
            (link) => link.cardId + link.styleContainerId
          );
        }),
    []
  );
}

export function useGetAllGeoMicroStyleState(cardsList: DocListItem[]) {
  return useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const links = snapshot.getLoadable(
          cardStyleContainerGeoMicroLinksAtom
        ).contents;
        if (!Array.isArray(links)) {
          throw new Error("links is not an array");
        }
        const validLinks = (links as CardStyleContainerGeoMicroLink[]).filter(
          (link) =>
            cardsList.find((listItem) => listItem.id === link.cardId) !==
            undefined
        );
        const containersList: StyleContainerGeoMicro[] = validLinks.map(
          (link) => {
            const styleContainer = snapshot.getLoadable(
              styleContainersGeoMicroAtomFamily(link.styleContainerId)
            ).contents;
            if (
              styleContainer instanceof Error ||
              styleContainer === undefined
            ) {
              throw new Error("Invalid contents for style container");
            }
            return styleContainer;
          }
        );
        return { styleContainers: containersList, links: validLinks };
      },
    [cardsList]
  );
}

export function useGetStylesGeoMicroByCardId(cardId: string) {
  return useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const links = snapshot.getLoadable(
          cardStyleContainerGeoMicroLinksAtom
        ).contents;
        if (!Array.isArray(links)) {
          throw new Error("cardColorSchemeLinks not an array");
        }
        const link = (links as CardStyleContainerGeoMicroLink[]).find(
          (link) => link.cardId === cardId
        );
        if (!defined(link)) {
          throw new Error("No color scheme link!");
        }

        const container = snapshot.getLoadable(
          styleContainersGeoMicroAtomFamily(link.styleContainerId)
        ).contents;
        return container as StyleContainerGeoMicro;
      },
    [cardId]
  );
}
