import { config } from "../../../config";
import { voidFunc } from "../../core/voidFunc";
import { downloadBlob } from "../browser/downloadBlob";
import { authedFileDownload } from "./shared";

export type HomeType = "multi-family" | "single-family";

export function exportAddresses(
  desoCodes: string[],
  regsoCodes: string[],
  includeAddressTypes: HomeType[]
) {
  return postalExport("address", desoCodes, regsoCodes, includeAddressTypes);
}

export function exportZipcodes(
  desoCodes: string[],
  regsoCodes: string[],
  includeAddressTypes: HomeType[]
) {
  return postalExport(
    "postal_code",
    desoCodes,
    regsoCodes,
    includeAddressTypes
  );
}

function postalExport(
  record_type: "address" | "postal_code",
  desoCodes: string[],
  regsoCodes: string[],
  includeAddressTypes: HomeType[]
) {
  return authedFileDownload(
    config.apis.statsV1,
    "/mikro/addressexport",
    {
      record_type: record_type,
      deso_codes: desoCodes,
      regso_codes: regsoCodes,
      address_type: getAddressFilter(includeAddressTypes),
    },
    "POST"
  ).then((result) => {
    result.match({
      err: voidFunc,
      ok: (response) => {
        response.blob().then((blob) => {
          const filename =
            response.headers
              .get("Content-Disposition")
              ?.split("filename=")[1]
              ?.slice(1, -1) ??
            `${record_type === "address" ? "addresser" : "postnummer"}.xlsx`;
          downloadBlob(blob, filename);
        });
      },
    });
    return result;
  });
}

function getAddressFilter(filterAddressesRaw: HomeType[]) {
  const filterAddresses = filterAddressesRaw.map((addressType) => {
    switch (addressType) {
      case "multi-family":
        return "Flerbostadshus";
      case "single-family":
        return "Småhus";
    }
  });

  if (filterAddresses.length === 1) {
    return filterAddresses[0];
  }
}
