import * as _ from "lodash";

import {
  calculateTextHeight,
  calculateTextWidth,
  measureText,
} from "./measure";
import { TextStyle } from "../TextStyle";
import { ColorKey, SvgTextAnchor } from "../definitions";
import {
  splitByForce,
  splitByWhitespace,
  SplitResult,
  TextSplittingMode,
} from "../text_splitting";

/**
 * Generic container for unpositioned text
 *
 * Sizing works like CSS box-sizing border-box.
 */
export class MultilineText implements ColorKey {
  private _shrinkResult:
    | { type: "split"; result: SplitResult }
    | {
        type: "rotate";
        result: {
          rotation: number;
          text: string;
          width: number;
          height: number;
        };
      };
  private _rotation?: number;
  private _anchor: SvgTextAnchor;
  private _originalWidth: number;
  private _textStyle: TextStyle;

  constructor(
    private _text: string,
    textStyle: TextStyle,
    private _options: {
      colorKey?: string;
      desiredMaxWidth: number;
      boxPaddingTop?: number;
      anchorPadding?: number;
      anchor?: SvgTextAnchor;
    }
  ) {
    this._originalWidth =
      calculateTextWidth(_text, textStyle) + (_options.anchorPadding ?? 0);
    this._textStyle = textStyle;
    this._shrinkResult = {
      type: "split",
      result: splitByWhitespace(
        _text,
        _options.anchorPadding ?? 0,
        textStyle,
        _options.desiredMaxWidth
      ),
    };
    this._anchor = _options.anchor ?? "middle";
  }

  private _anchorPadding(): number {
    return this._options.anchorPadding ?? 0;
  }

  private _height(): number {
    const shrinkResult = this._shrinkResult;
    if (shrinkResult.type === "rotate") {
      return shrinkResult.result.height;
    } else {
      return (
        _.sum(
          shrinkResult.result.lines.map(
            (l) =>
              calculateTextHeight(l, this._textStyle) +
              (this._options.boxPaddingTop ?? 0)
          )
        ) ?? 0
      );
    }
  }

  /**
   * The original text width, without splits
   */
  get originalWidth(): number {
    return this._originalWidth;
  }

  resetAndRotate(rotation: number): void {
    this._rotation = rotation;
    this._anchor = "end";
    const box = measureText(this._text, this._textStyle, rotation);
    const height = box.height + (this._options.boxPaddingTop ?? 0);
    const width = box.width;
    this._shrinkResult = {
      type: "rotate",
      result: {
        text: this._text,
        rotation: rotation,
        width,
        height,
      },
    };
  }

  refineSplitNaive(desiredWidth: number): number {
    const shrinkResult = this._shrinkResult;
    if (shrinkResult.type === "rotate") {
      throw new Error("Attempting to split rotated text -- not supported!");
    }
    const splitLines: string[] = [];
    let maxWidth = 0;
    for (const line of shrinkResult.result.lines) {
      const result = splitByWhitespace(
        line,
        this._anchorPadding(),
        this._textStyle,
        desiredWidth
      );
      splitLines.push(...result.lines);
      maxWidth = Math.max(result.maxWidth, maxWidth);
    }
    this._shrinkResult = {
      type: "split",
      result: {
        desiredMaxWidth: desiredWidth,
        lines: splitLines,
        maxWidth,
        splittingMode: TextSplittingMode.breakOnWhitespace,
      },
    };
    return maxWidth;
  }

  refineSplitForce(desiredWidth: number): number {
    const splitLines: string[] = [];
    let maxWidth = 0;
    const shrinkResult = this._shrinkResult;
    if (shrinkResult.type === "rotate") {
      throw new Error("Attempting to split rotated text -- not supported!");
    }
    const calcWidth = (line: string) =>
      calculateTextWidth(line, this._textStyle) + this._anchorPadding();
    for (const line of shrinkResult.result.lines) {
      const splitResult = splitByForce(line, desiredWidth, calcWidth);
      splitLines.push(...splitResult.lines);
      maxWidth = Math.max(splitResult.maxWidth, maxWidth);
    }
    this._shrinkResult = {
      type: "split",
      result: {
        ...shrinkResult.result,
        desiredMaxWidth: desiredWidth,
        lines: splitLines,
        maxWidth,
        splittingMode: TextSplittingMode.breakAnywhere,
      },
    };
    return maxWidth;
  }

  get desiredMaxWidth(): number {
    return this._options.desiredMaxWidth;
  }

  get rotation(): number | undefined {
    return this._rotation;
  }

  get anchor() {
    return this._anchor;
  }

  get colorKey() {
    return this._options.colorKey ?? this._text;
  }

  get style(): TextStyle {
    return this._textStyle;
  }

  get raw(): string {
    return this._text;
  }

  get paddingTop(): number {
    return this._options.boxPaddingTop ?? 0;
  }

  get anchorPadding(): number {
    return this._options.anchorPadding ?? 0;
  }

  get maxWidth(): number {
    const shrinkResult = this._shrinkResult;
    if (shrinkResult.type === "rotate") {
      return shrinkResult.result.width;
    }
    return shrinkResult.result.maxWidth;
  }

  get height(): number {
    return this._height();
  }

  get numLines(): number {
    const shrinkResult = this._shrinkResult;
    if (shrinkResult.type === "rotate") {
      return 1;
    }
    return shrinkResult.result.lines.length;
  }

  get lineHeight(): number {
    const shrinkResult = this._shrinkResult;
    if (shrinkResult.type === "rotate") {
      return calculateTextHeight(shrinkResult.result.text, this._textStyle);
    }
    return (
      _.max(
        shrinkResult.result.lines.map((l) =>
          calculateTextHeight(l, this._textStyle)
        )
      ) ?? 0
    );
  }

  get lines(): string[] {
    const shrinkResult = this._shrinkResult;
    if (shrinkResult.type === "rotate") {
      return [shrinkResult.result.text];
    }
    return shrinkResult.result.lines;
  }

  /**
   * Text rows that do not respect rotation.
   */
  toTextRows(): TextRow[] {
    const shrinkResult = this._shrinkResult;
    if (shrinkResult.type === "rotate") {
      throw new Error("Attempting to convert rotated text to text rows.");
    }
    const textRows: TextRow[] = [];
    for (const line of shrinkResult.result.lines) {
      const isFirst = shrinkResult.result.lines.indexOf(line) === 0;
      textRows.push(
        new TextRow(
          line,
          this._textStyle,
          isFirst ? this._options.boxPaddingTop ?? 0 : 0
        )
      );
    }
    return textRows;
  }
}

export class LabelRowSet {
  constructor(private _rows: LabelsRow[], private _paddingTop: number) {}

  get paddingTop() {
    return this._paddingTop;
  }

  get totalHeight() {
    return (_.sum(this._rows.map((r) => r.rowHeight)) ?? 0) + this._paddingTop;
  }
}

export class LabelsRow {
  constructor(private _rowHeight: number, private _rowPaddingTop: number) {}

  get rowHeight(): number {
    return this._rowHeight;
  }
}

/**
 * A text row where the text top left will be at _offsetY.
 */
export class TextRowWithOffset {
  constructor(private _row: TextRow, private _offsetY: number) {}

  get rowHeightWithPadding(): number {
    return this._row.innerHeight + (this._row.paddingTop ?? 0);
  }

  get text(): string {
    return this._row.text;
  }

  get paddingTop(): number {
    return this._row.paddingTop ?? 0;
  }

  get offsetY(): number {
    return this._offsetY;
  }

  get textStyle(): TextStyle {
    return this._row.textStyle;
  }
}

export class TextRowSet {
  private _width: number;
  private _totalHeight: number;

  /**
   * @param _paddingBottom Affects totalHeight only. Does not affect text positioning.
   */
  constructor(private _rows: TextRow[], private _paddingBottom?: number) {
    this._width = _.max(_rows.map((r) => r.width)) ?? 0;

    this._totalHeight =
      (this._paddingBottom ?? 0) +
        _.sum(this._rows.map((r) => r.totalHeight)) ?? 0;
  }

  get first(): TextRow | undefined {
    return this._rows[0];
  }

  get count(): number {
    return this._rows.length;
  }

  get width(): number {
    return this._width;
  }

  get totalHeight() {
    return this._totalHeight;
  }

  get paddingBottom(): number {
    return this._paddingBottom ?? 0;
  }

  get heightWithoutTopPadding(): number {
    return this._totalHeight - (this._rows[0].paddingTop ?? 0);
  }

  get heightWithoutBottomPadding(): number {
    return this._totalHeight - (this._paddingBottom ?? 0);
  }

  /** Get the rows in order with the vertical offsets */
  get offsetRows(): TextRowWithOffset[] {
    const rows: TextRowWithOffset[] = [];
    let offsetY = 0;
    for (let i = 0; i < this._rows.length; i++) {
      const row = this._rows[i];
      rows.push(new TextRowWithOffset(row, offsetY));
      offsetY += row.totalHeight ?? 0;
    }
    return rows;
  }

  static fromMixedText(rows: (MultilineText | TextRow)[]): TextRowSet {
    const textRows: TextRow[] = [];
    for (const row of rows) {
      if (row instanceof TextRow) {
        textRows.push(row);
      } else {
        textRows.push.apply(textRows, row.toTextRows());
      }
    }

    return new TextRowSet(textRows);
  }
}

export class TextRow {
  private _height: number;
  private _width: number;

  constructor(
    private _text: string,
    private _textStyle: TextStyle,
    private _paddingTop?: number
  ) {
    this._height = calculateTextHeight(_text, _textStyle);
    this._width = calculateTextWidth(_text, _textStyle) ?? 0;
  }

  get paddingTop(): number | undefined {
    return this._paddingTop;
  }

  get textStyle(): TextStyle {
    return this._textStyle;
  }

  get width(): number {
    return this._width;
  }

  get totalHeight(): number {
    return this._height + (this._paddingTop ?? 0);
  }

  /**
   * Height without top padding
   */
  get innerHeight(): number {
    return this._height;
  }

  get text(): string {
    return this._text;
  }
}
