import { max, min, mean, sum, chain } from "lodash";

import { defined } from "../../../../../core/defined";
import { last } from "../../../../../core/last";
import { getMedian } from "../../../../../core/math/median";
import { nonEmptyString } from "../../../../../core/nonEmptyString";
import {
  StringComparator,
  defaultStringComparatorAsc,
  defaultNumberComparatorAsc,
} from "../../../../../core/order";
import { dateStringComparatorDesc } from "../../../../../core/time";
import { applySortSpecGeneric, SortSpec } from "../../../../../domain/measure";
import { shortenMunicipalityLabel } from "../../../../../domain/names";
import { DataValueTypeAny } from "../../../../../infra/api_responses/dataset";
import { SurveyRowValueRaw } from "../../../../../infra/api_responses/survey_dataset";
import { Dimension, NO_VALUE_MARKER } from "../../../shared/core/definitions";
import { RowBaseInterface } from "../../../shared/row";
import { DataCellValue } from "../../DataCellValue";
import {
  getFormatter,
  getRounderNumeric,
  integerFormatter,
  numSignificantFiguresRounder,
  threeSFRounderNumeric,
} from "../../../format";
import { logger } from "../../../../../infra/logging";
import { assertNever } from "../../../../../core/assert";
import { round } from "../../../../../core/math/round";
import {
  DataOutputSettings,
  DataTableSettings,
} from "../../../../state/stats/document-core/DataOutputSettings";
import {
  ComputedValueOutputSettings,
  SummaryTableRow,
  SuperColumnsRow,
  TableRow,
  TableSuperColumn,
} from "./table_base_definitions";

type ColumnLists = (number[] | undefined)[];

export interface LabelAndDimension {
  label: string;
  dimension: string;
}

export function getComputedValueOutputSettings(
  originalValueType: DataValueTypeAny,
  settings: DataOutputSettings
): ComputedValueOutputSettings {
  // We never round computed values for export
  const roundForExport = (input: string) => parseFloat(input);

  // If number of decimals is specified, override default settings
  if (defined(settings.computationOutputNumDecimals)) {
    const roundValues = getRounderNumeric(
      "decimal",
      settings.computationOutputNumDecimals
    );
    return {
      format: getFormatter("decimal", settings.computationOutputNumDecimals),
      roundValues,
      roundForExport: roundForExport,
    };
  }

  const computedVariablesShouldUseDecimals = settings.computedVariablesV3.some(
    (v) => v.operator === "/"
  );
  const threeSFRounder = numSignificantFiguresRounder(3);

  const defaultDecimalsFormatter = (input: string): string => {
    const value = parseFloat(input);
    if (value >= 1000) {
      return integerFormatter(input);
    }
    return threeSFRounder(value);
  };
  const intRounder = (input: number) => {
    return round(input, 0);
  };
  const defaultRounder = (input: number): number => {
    if (input >= 1000) {
      return intRounder(input);
    }

    return threeSFRounderNumeric(input);
  };

  if (computedVariablesShouldUseDecimals) {
    return {
      format: defaultDecimalsFormatter,
      roundValues: defaultRounder,
      roundForExport: roundForExport,
    };
  }

  switch (originalValueType) {
    case "survey":
      const numDecimals = settings.showSurveyValueFraction ? 1 : 0;
      return {
        format: getFormatter("decimal", numDecimals),
        roundValues: getRounderNumeric("decimal", numDecimals),
        roundForExport: roundForExport,
      };
    case "survey_string":
      // Survey string does not rely on these settings
      logger.error("Unexpected survey_string type");
      return {};
    case "category":
      // No rounding etc for category values
      return {};
    case "decimal":
      if (settings.fixedNumDecimals !== null) {
        return {
          format: getFormatter("decimal", settings.fixedNumDecimals),
          roundValues: getRounderNumeric("decimal", settings.fixedNumDecimals),
          roundForExport: roundForExport,
        };
      }
      return {
        format: defaultDecimalsFormatter,
        roundValues: defaultRounder,
        roundForExport: roundForExport,
      };
    case "integer":
      return {
        format: integerFormatter,
        roundValues: intRounder,
        roundForExport: roundForExport,
      };
    default:
      assertNever(originalValueType);
  }
}

/**
 * Get a sorted list of row labels for the table default view
 */
export function getSortedPrimaryDimensionKeys<
  T extends RowBaseInterface<SurveyRowValueRaw>
>(
  rows: T[],
  getRowValue: (row: T, dim: string) => string | number | undefined,
  primaryDimension: string | undefined,
  primarySortSpec: SortSpec | undefined,
  variantCombinations: LabelAndDimension[][]
): string[] {
  if (!defined(primaryDimension)) {
    return [];
  }
  if (primaryDimension === Dimension.date) {
    return chain(rows.slice())
      .map((r) => (r.dimension(Dimension.date) ?? "") as string)
      .uniq()
      .sort(dateStringComparatorDesc)
      .value();
  }

  return chain(
    applySortSpecGeneric(
      rows,
      primarySortSpec,
      (v) => {
        const value = getRowValue(v, primaryDimension);
        return typeof value === "string" ? value : value?.toString() ?? "";
      },
      (v) => {
        // Which value do we compare by? In a complex table with multiple dimensions, we select
        // the last column as given by variant combinations.
        // The reason for selecting the last column is that it appears visually as the right-most column
        // in the table.

        if (variantCombinations.length === 0) {
          return v.range();
        }
        const combo = last(variantCombinations);
        if (!defined(combo)) {
          throw new Error("Expected combo to be defined");
        }

        const value = getRowValue(v, primaryDimension);
        const found = rows.find((r) => {
          return (
            getRowValue(r, primaryDimension) === value &&
            combo.every(
              (labelAndDim) =>
                getRowValue(r, labelAndDim.dimension) === labelAndDim.label
            )
          );
        });
        return found?.range() ?? -10000;
      }
    ).merged()
  )
    .map((row) => getRowValue(row, primaryDimension) as string)
    .uniq()
    .value();
}

export function getDimensionFormatter(
  dateFormatter: (input: string) => string
) {
  return (dimension: string, label: string) => {
    switch (dimension) {
      case Dimension.region:
        return shortenMunicipalityLabel(label) ?? label;
      case Dimension.date:
        return dateFormatter(label);
      default:
        return label;
    }
  };
}

export function joinDimensions(orderedDimensions: string[]): string {
  return orderedDimensions.filter(nonEmptyString).join("-");
}

export function getSuperColumnRows(
  variantCombinations: LabelAndDimension[][],
  formatLabel: (dimension: string, text: string) => string
): SuperColumnsRow[] {
  const l: SuperColumnsRow[] = [];

  if (variantCombinations.length === 0) {
    return l;
  }

  const labelMatrix = transpose(variantCombinations);

  /**
   * Keep track of column spans for each row. For each subrow, we need to make sure
   * that a column span does not break out of the column span of the row above.
   */
  const colSpansByRow: [spanStart: number, spanEnd: number][][] = [];

  labelMatrix.forEach((labelRow, rowIndex) => {
    const columns: TableSuperColumn[] = [];
    let colWidth = 0;
    for (let i = 0; i < labelRow.length; i += colWidth) {
      colWidth = 0;
      // Corresponding span of the row above the current row
      let upperSpan =
        rowIndex > 0
          ? colSpansByRow[rowIndex - 1].find(
              ([start, end]) => i >= start && i < end
            )
          : undefined;
      const columnSpec = labelRow[i];

      for (let j = i; j < labelRow.length; j++) {
        const label2 = labelRow[j];
        // The span cannot break the bounds of the corresponding span of the row above
        if (defined(upperSpan) && j >= upperSpan[1]) {
          break;
        }
        if (columnSpec.label === label2.label) {
          colWidth += 1;
        } else {
          break;
        }
      }

      const text = formatLabel(columnSpec.dimension, columnSpec.label);
      const colSpan: [number, number] = [i, i + colWidth];
      colSpansByRow[rowIndex] = defined(colSpansByRow[rowIndex])
        ? colSpansByRow[rowIndex].concat([colSpan])
        : [colSpan];

      columns.push({
        key: i + "_" + text,
        text,
        colSpan: colWidth,
      });
    }
    l.push({ columns: columns, dimension: labelRow[0].dimension });
  });

  return l;
}

/**
 * Transpose an i x j matrix to a j x i one
 */
export function transpose<T>(m: T[][]): T[][] {
  if (m.length === 0) {
    return m;
  }
  const result: T[][] = [];
  for (let j = 0; j < m[0].length; j++) {
    const resRow: T[] = [];
    for (let i = 0; i < m.length; i++) {
      resRow.push(m[i][j]);
    }
    result.push(resRow);
  }
  return result;
}

export function getValueComparatorAscending(
  valueType: DataValueTypeAny
): StringComparator {
  switch (valueType) {
    case "category":
      return defaultStringComparatorAsc;
    case "decimal":
    case "survey":
      return getNumberOrNoneComparator(parseFloat);
    case "survey_string":
      return defaultStringComparatorAsc;
    case "integer":
      return getNumberOrNoneComparator(parseInt);
  }
}

export function getNumberOrNoneComparator(numParser: (arg: string) => number) {
  return (left: string, right: string) => {
    if (left === NO_VALUE_MARKER && right !== NO_VALUE_MARKER) {
      return -1;
    } else if (left !== NO_VALUE_MARKER && right === NO_VALUE_MARKER) {
      return 1;
    } else if (left === NO_VALUE_MARKER && right === NO_VALUE_MARKER) {
      return 0;
    }
    return defaultNumberComparatorAsc(numParser(left), numParser(right));
  };
}

export function getSummaryRows(
  settings: DataTableSettings,
  tableRows: TableRow[],
  valueRounder: ((value: string) => number) | undefined,
  valueFormatter: (value: string) => string
): SummaryTableRow[] | undefined {
  const numRows = tableRows.length;
  if (numRows === 0 || tableRows[0].values.length === 0) {
    return;
  }
  const formatters = {
    format: (inputNumber: number) => valueFormatter(inputNumber.toString()),
    roundForExport: !defined(valueRounder)
      ? undefined
      : (inputNumber: number) => valueRounder(inputNumber.toString()),
  };

  const rows: SummaryTableRow[] = [];
  const numColumns = tableRows[0].values.length;
  const columnLists: ColumnLists = [];
  for (let col = 0; col < numColumns; col++) {
    const colValues: number[] = [];
    for (let row = 0; row < numRows; row++) {
      const value = tableRows[row].values[col];
      const numeric = value.fold(
        () => undefined,
        (v) => v.value
      );
      if (defined(numeric)) {
        colValues.push(parseFloat(numeric));
      }
      if (row === numRows - 1) {
        columnLists.push(colValues);
      }
    }
  }

  if (settings.showSumRow) {
    rows.push({
      ...getSumRow(columnLists),
      ...formatters,
    });
  }
  if (settings.showMaxRow) {
    rows.push({
      ...getMaxRow(columnLists),
      ...formatters,
    });
  }
  if (settings.showMinRow) {
    rows.push({
      ...getMinRow(columnLists),
      ...formatters,
    });
  }
  if (settings.showMeanRow) {
    rows.push({
      ...getMeanRow(columnLists),
      ...formatters,
    });
  }
  if (settings.showMedianRow) {
    rows.push({
      ...getMedianRow(columnLists),
      ...formatters,
    });
  }
  return rows;
}

type SummaryTableRowExOutputOptions = Omit<
  SummaryTableRow,
  "format" | "roundForExport"
>;

function getMaxRow(valueColumns: ColumnLists): SummaryTableRowExOutputOptions {
  const numColumns = valueColumns.length;
  const maxValues: DataCellValue<number>[] = [];
  for (let col = 0; col < numColumns; col++) {
    const colValues = valueColumns[col];
    if (!defined(colValues) || colValues.length === 0) {
      maxValues.push(DataCellValue.nan("no_value"));
      continue;
    }
    maxValues.push(DataCellValue.ok(max(colValues)!));
  }

  return {
    label: "Maximivärde",
    values: maxValues,
  };
}

function getMinRow(valueColumns: ColumnLists): SummaryTableRowExOutputOptions {
  const numColumns = valueColumns.length;
  const minValues: DataCellValue<number>[] = [];
  for (let col = 0; col < numColumns; col++) {
    const colValues = valueColumns[col];
    if (!defined(colValues) || colValues.length === 0) {
      minValues.push(DataCellValue.nan("no_value"));
      continue;
    }
    minValues.push(DataCellValue.ok(min(colValues)!));
  }

  return {
    label: "Minimivärde",
    values: minValues,
  };
}

function getMeanRow(valueColumns: ColumnLists): SummaryTableRowExOutputOptions {
  const numColumns = valueColumns.length;
  const meanValues: DataCellValue<number>[] = [];
  for (let col = 0; col < numColumns; col++) {
    const colValues = valueColumns[col];
    if (!defined(colValues) || colValues.length === 0) {
      meanValues.push(DataCellValue.nan("no_value"));
      continue;
    }
    meanValues.push(DataCellValue.ok(mean(colValues)));
  }

  return {
    label: "Medelvärde",
    values: meanValues,
  };
}

function getSumRow(valueColumns: ColumnLists): SummaryTableRowExOutputOptions {
  const numColumns = valueColumns.length;
  const sumValues: DataCellValue<number>[] = [];
  for (let col = 0; col < numColumns; col++) {
    const colValues = valueColumns[col];
    if (!defined(colValues) || colValues.length === 0) {
      sumValues.push(DataCellValue.nan("no_value"));
      continue;
    }
    sumValues.push(DataCellValue.ok(sum(colValues)));
  }

  return {
    label: "Summa",
    values: sumValues,
  };
}

function getMedianRow(
  valueColumns: ColumnLists
): SummaryTableRowExOutputOptions {
  const numColumns = valueColumns.length;
  const medianValues: DataCellValue<number>[] = [];
  for (let col = 0; col < numColumns; col++) {
    const colValues = valueColumns[col];
    if (!defined(colValues) || colValues.length === 0) {
      medianValues.push(DataCellValue.nan("no_value"));
      continue;
    }
    medianValues.push(DataCellValue.ok(getMedian(colValues)));
  }

  return {
    label: "Median",
    values: medianValues,
  };
}

export function tableRowsWithCustomLabels(
  tableRowsOriginal: TableRow[],
  rowDimension: string | undefined,
  settings: DataOutputSettings
): TableRow[] {
  if (
    !defined(rowDimension) ||
    !defined(settings.customLabels?.[rowDimension])
  ) {
    return tableRowsOriginal;
  }
  return tableRowsOriginal.map((r) => {
    const customLabel = settings.customLabels?.[rowDimension]?.[r.label];
    if (defined(customLabel)) {
      return { ...r, label: customLabel };
    }
    return r;
  });
}

export function columnRowsWithCustomLabels(
  columnRows: SuperColumnsRow[],
  settings: DataOutputSettings
): SuperColumnsRow[] {
  return columnRows.map((c) => {
    return {
      ...c,
      columns: c.columns.map((col) => {
        const customLabel = settings.customLabels?.[c.dimension]?.[col.text];
        if (defined(customLabel)) {
          return { ...col, text: customLabel };
        }
        return col;
      }),
    };
  });
}
