import React from "react";
import { Position2D } from "../../core/space/position";

interface BoundingRect {
  getBoundingClientRect(): DOMRect;
}

export function getRelativeEventPointerPosition(
  relativeToElement: BoundingRect,
  event: React.MouseEvent<Element>
): Position2D {
  const pageY = event.pageY;
  const pageX = event.pageX;

  const boundingRect = relativeToElement.getBoundingClientRect();

  const bodyScrollY = window.scrollY;
  const bodyScrollX = window.scrollX;
  const x = pageX - boundingRect.left + bodyScrollX;
  const y = pageY - boundingRect.top + bodyScrollY;

  return { x, y };
}

export function relativeToGlobalPosition(
  position: Position2D,
  relativeElement: Element
): Position2D {
  const boundingRect = relativeElement.getBoundingClientRect();
  const bodyScrollY = window.scrollY;
  const bodyScrollX = window.scrollX;

  const x = position.x - boundingRect.left + bodyScrollX;
  const y = position.y - boundingRect.top + bodyScrollY;
  return { x, y };
}
