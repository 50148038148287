import { useNavigate } from "react-router-dom";

import "./CreateMeasure.scss";
import { classNames } from "../../../../lib/core/classNames";
import { voidFunc } from "../../../../lib/core/voidFunc";
import { FluentIcon } from "../../../../components/Icons";
import { pathInfo } from "../../../../lib/paths";
import { Card } from "../../../../components/Card";

export function SelectMeasureType(props: {}) {
  const navigate = useNavigate();

  return (
    <Card className="select-measure-type">
      <h2>Nytt mått</h2>
      <BigButtons
        buttons={[
          {
            leftEmoji: "🦉",
            label: "Stats",
            onClick: () =>
              navigate(pathInfo.dataAdminNewMeasureStats.pathTemplate),
          },
          {
            leftEmoji: "🦉",
            label: "Mikro",
            onClick: () =>
              navigate(pathInfo.dataAdminNewMeasureMicro.pathTemplate),
          },
          {
            leftEmoji: "🦉",
            label: "Survey",
            onClick: () =>
              navigate(pathInfo.dataAdminNewMeasureSurvey.pathTemplate),
          },
          {
            leftEmoji: "💼",
            label: "Extern kunddata",
            onClick: () =>
              navigate(pathInfo.dataAdminNewMeasureExternal.pathTemplate),
          },
          {
            leftEmoji: "👨‍👩‍👧‍👦",
            label: "Mått för medlemsorganisationer",
            onClick: () =>
              navigate(pathInfo.dataAdminNewMeasureMemberOrg.pathTemplate),
          },
        ]}
      ></BigButtons>
    </Card>
  );
}

function BigButtons(props: {
  buttons: {
    disabled?: boolean;
    leftEmoji?: string;
    label: string;
    onClick: () => void;
  }[];
}) {
  return (
    <div className="big-buttons">
      {props.buttons.map((button) => (
        <div
          key={button.label}
          onClick={button.disabled ? voidFunc : button.onClick}
          className={classNames(
            "button-container",
            button.disabled ? "disabled" : ""
          )}
        >
          <div className="left-block">
            {button.leftEmoji && (
              <div className="left-emoji">{button.leftEmoji}</div>
            )}
            <div className="label">{button.label}</div>
          </div>
          <div className="icon">
            <FluentIcon size="lg" name="chevron-right"></FluentIcon>
          </div>
        </div>
      ))}
    </div>
  );
}
