import * as _ from "lodash";

import { defined } from "../../../../core/defined";
import { RowRegular, RowBaseInterface, RowForecast } from "../row";
import {
  fixedDecimalsFormatter,
  maxNumDecimals,
  RawNumericData,
} from "../../format";
import { ChartType, Dimension, Orientation } from "../core/definitions";
import {
  calculcateTitleRows,
  getChartSourceText,
  HeaderWithSourceInfo,
} from "../texts";
import { shortenMunicipalityLabel } from "../../../../domain/names";
import { assertDefined } from "../../../../core/assert";
import { defaultDimensionComparator, groupingSortSpec } from "../dimensions";
import { TextRowSet } from "../core/text_containers";
import {
  DataDescription,
  getApplicableDimensionHeader,
} from "../DataDescription";
import { DateDistanceGetter } from "../TimeSeriesLine";
import {
  DatasetInput,
  MainHeaderData,
  MicroHeaderData,
} from "../../datasets/headers";
import { RowValueRegularRaw } from "../../../../infra/api_responses/dataset";
import { TickLabelWithRawValue } from "../core/ticks";
import { normalizeTicks } from "../ticks";
import { SortSpec } from "../../../../domain/measure";
import { Dictionary } from "../../../../core/dictionary";
import {
  ChartData,
  DataDimensionDetails,
  RowStringAccessor,
} from "./ChartData";
import { sortedLabelsAndDimensions } from "./sortedLabelsAndDimensions";
import {
  determineChartType,
  getRange,
  groupRows,
  labelSpecsToText,
} from "./chart_data_common";
import { MeasureSelection } from "../../../../domain/measure/definitions";
import { LabelsByDimension } from "../../datasets/DatasetGeneric";
import { chain } from "lodash";
import { extent } from "../../../../core/numeric/extent";
import { getComputedValueOutputSettings } from "../../datasets/table/table_base/table_base";
import { DEFAULT_FONT_SIZE_SOURCE_TEXT } from "../core/TextStyle";
import { DataOutputSettings } from "../../../state/stats/document-core/DataOutputSettings";

/**
 * Data container for general, non-survey data.
 */
export class ChartDataRegular<
  RowProcessed extends RowBaseInterface<RowValueRegularRaw>
> implements ChartData<RowValueRegularRaw, RowProcessed>
{
  private _dataDimensionsSorted: string[];
  public defaultCustomColors: string[] | undefined;

  private constructor(
    private _dataset: DatasetInput<RowProcessed, HeaderWithSourceInfo>,
    private _labelsByDimension: LabelsByDimension,
    private _rangeExtentRaw: [number, number],
    private _rangeFormatter: (val: number) => string,
    private _dateFormatter: (input: string) => string,
    private _dateDistanceGetter: DateDistanceGetter,
    private _dataDescription: DataDescription,
    private _normalizeTicks: (
      ticks: TickLabelWithRawValue[]
    ) => TickLabelWithRawValue[],
    private _sortSpecsByDimension: Dictionary<SortSpec>,
    private _dataOutputSettings: DataOutputSettings
  ) {
    this._dataDimensionsSorted = _dataset.dimensionsSpec.variable
      .slice()
      .sort(defaultDimensionComparator)
      .reverse();
  }

  private _labels(dimension: string): string[] {
    return labelSpecsToText(
      this._labelsByDimension.find(([d]) => d === dimension)
    );
  }

  private _numLabels(dimension: string): number {
    const num = this._labelsByDimension.find(([d]) => d === dimension)?.[1]
      .length;
    assertDefined(num, "Dimension must exist");
    return num;
  }

  private get _dataDimensions(): string[] {
    return this._dataDimensionsSorted;
  }

  private get _dimensionFormatter() {
    return (dim: string, value: any): string => {
      switch (dim) {
        case Dimension.value:
          return this._rangeFormatter(value);
        case Dimension.date:
          return this._dateFormatter(value);
        case Dimension.region:
          return shortenMunicipalityLabel(value) ?? value;
        default:
          return value;
      }
    };
  }

  get outputSettings() {
    return this._dataOutputSettings;
  }

  private _dimensionToLabel(dimension: string) {
    return this._dataDescription.dimensionToLabel(dimension);
  }

  get computedValueOutputSettings() {
    return getComputedValueOutputSettings(
      this._dataset.valueTypeOriginal,
      this._dataOutputSettings
    );
  }

  liftedDimensions(): { [key: string]: string } {
    return this._dataset.header.liftedDimensions ?? {};
  }

  dimensionToLabel(dimension: string) {
    return this._dimensionToLabel(dimension);
  }

  normalizeTicks(ticks: TickLabelWithRawValue[]): TickLabelWithRawValue[] {
    return this._normalizeTicks(ticks);
  }

  makeRangeFormatterRow(values: RawNumericData[]) {
    const formatter = this.makeDynamicRangeFormatter(values);
    return (row: RowRegular) => {
      return formatter(row.range());
    };
  }

  getRangeFormatter() {
    return fixedDecimalsFormatter(this._dataset.numDecimals ?? 0);
  }

  makeDynamicRangeFormatter(values: RawNumericData[]) {
    const numDecimals = maxNumDecimals(values);
    return fixedDecimalsFormatter(numDecimals ?? 0);
  }

  makeTicksRangeFormatter(
    values: RawNumericData[]
  ): (val: RawNumericData) => string {
    return this.makeDynamicRangeFormatter(values);
  }

  hasDrawableRegionDimension(): boolean {
    return (
      defined(
        this._labelsByDimension.find(([dim]) => dim === Dimension.region)
      ) ||
      defined(this._dataset.dimensionsSpec.liftedDimensions?.[Dimension.region])
    );
  }

  dateDistanceGetter() {
    return this._dateDistanceGetter;
  }

  dimensionHeader(dimension: string): string | undefined {
    return getApplicableDimensionHeader(
      dimension,
      this._dataOutputSettings,
      this._dataDescription
    );
  }

  groupedRows(groupAccessor: RowStringAccessor<RowProcessed>) {
    return groupRows(this._dataset.rows, groupAccessor);
  }

  groupedForecastRows(
    groupAccessor: RowStringAccessor<RowForecast>
  ): { key: string; values: RowForecast[] }[] {
    const forecastRows = this._dataset.forecastRows;
    if (!defined(forecastRows)) {
      return [];
    }
    return groupRows(forecastRows, groupAccessor);
  }

  domainExtent<T extends Date>(accessor: (row: RowProcessed) => T) {
    const rows = this._dataset.rows;
    const forecastRows = this._dataset.forecastRows;
    const [domainMin, domainMax] = extent(
      chain(rows.concat((forecastRows as unknown as RowProcessed[]) ?? []))
        .map(accessor)
        .filter(defined)
        .value()
    );
    if (!defined(domainMin) || !defined(domainMax)) {
      throw new Error("Expected both domainMin and domainMax to be defined!");
    }
    const domainExtent: [T, T] = [domainMin, domainMax];
    return domainExtent;
  }

  get uniqueDates() {
    if (this._dataDimensions.includes(Dimension.date)) {
      const datesSet = new Set<string>();
      for (const row of this._dataset.rows) {
        const date = row.dimension(Dimension.date);
        datesSet.add(date);
      }
      for (const row of this._dataset.forecastRows ?? []) {
        const date = row.dimension(Dimension.date) as string;
        datesSet.add(date);
      }
      return Array.from(datesSet);
    }
    const liftedDate =
      this._dataset.dimensionsSpec.liftedDimensions?.[Dimension.date];
    if (defined(liftedDate)) {
      return [liftedDate];
    }
    return undefined;
  }

  /**
   * Positioned and styled chart title text
   */
  titleRows(maxWidth: number, settings: DataOutputSettings) {
    return calculcateTitleRows(this._dataDescription, maxWidth, settings);
  }

  get dataDescription(): DataDescription {
    return this._dataDescription;
  }

  get dataDimensions(): string[] {
    return this._dataDimensions;
  }

  get nonPrimaryDataDimensions(): string[] {
    return this._dataDimensions.slice(1);
  }

  get rows(): RowProcessed[] {
    return this._dataset.rows;
  }

  get footnotes(): TextRowSet | undefined {
    return undefined;
  }

  source(maxWidth: number, baseLabelSize?: number | null): TextRowSet {
    return getChartSourceText(
      { header: this._dataset.header, groupHeader: this._dataset.groupHeader },
      this._dataOutputSettings.customSourceText,
      baseLabelSize ?? DEFAULT_FONT_SIZE_SOURCE_TEXT,
      maxWidth
    );
  }

  chartType(chartInnerWidth: number): ChartType {
    return determineChartType(this, chartInnerWidth, this._dataOutputSettings);
  }

  /**
   * Returns an unsorted list of [dimension, labels]
   *
   * Does not include labels for the primary dimension since they are
   * not labels in the same sense.
   */
  get labelsByDimension(): LabelsByDimension {
    return this._labelsByDimension;
  }

  prepareDimensionsAndLabelsBarChart(
    barOrientation: Orientation,
    fixedNonPrimaryDimensions?: string[]
  ): DataDimensionDetails<string> {
    const {
      sortedDimensionsAndLabels,
      sortedNonPrimaryDimensions,
      legendDimension,
    } = sortedLabelsAndDimensions(
      barOrientation,
      this._dataDimensions,
      this._dataset.rows,
      this._numLabels.bind(this),
      this._labelsByDimension,
      this._sortSpecsByDimension,
      false,
      fixedNonPrimaryDimensions
    );

    const legendDimensionLabel = defined(legendDimension)
      ? this._dataDescription.dimensionToLabel(legendDimension)
      : undefined;
    const domainDimension = sortedDimensionsAndLabels[0]?.dimension;
    const domainDimensionLabel = defined(domainDimension)
      ? this._dataDescription.dimensionToLabel(domainDimension)
      : "";

    return {
      legendDimension,
      nonPrimaryDimensions: sortedNonPrimaryDimensions,
      nonPrimaryLabelsByDimension: sortedDimensionsAndLabels,
      dataDimensions: this._dataDimensions,
      legendDimensionLabel,
      domainDimensionLabel,
    };
  }

  numLabels(dimension: string): number {
    return this._numLabels(dimension);
  }

  /**
   * Get the range extent, extending to zero if applicable and wanted
   */
  rangeExtent(extendToZero: boolean): [number, number] {
    const [rangeMinRaw, rangeMaxRaw] = this._rangeExtentRaw;
    const rangeMin = extendToZero ? Math.min(rangeMinRaw, 0) : rangeMinRaw;
    const rangeMax = extendToZero ? Math.max(rangeMaxRaw, 0) : rangeMaxRaw;
    return [rangeMin, rangeMax];
  }

  get rangeFormatter() {
    return this._rangeFormatter;
  }

  get dimensionFormatter() {
    return this._dimensionFormatter;
  }

  static fromMicroDataset<R extends RowBaseInterface<RowValueRegularRaw>>(
    datasetInput: DatasetInput<
      R,
      MicroHeaderData & { externalSource?: string } // FIXME
    >,
    labelsByDimension: LabelsByDimension,
    dateFormatter: (input: string) => string,
    dateDistanceGetter: DateDistanceGetter,
    dataDescription: DataDescription,
    dataOutputSettings: DataOutputSettings
  ): ChartDataRegular<R> {
    const rows = datasetInput.rows;
    const numDecimals =
      dataOutputSettings.fixedNumDecimals !== null
        ? dataOutputSettings.fixedNumDecimals
        : maxNumDecimals(rows.map((r) => r.range()));
    const rangeFormatter = fixedDecimalsFormatter(numDecimals ?? 0);

    const rangeAccessor = (row: R) => row.range();
    const liftedValue = datasetInput.header.liftedDimensions?.[
      Dimension.value
    ] as string | undefined; // FIXME
    const defaultRange: [number, number] = defined(liftedValue)
      ? [parseFloat(liftedValue), 2 * 2 * parseFloat(liftedValue)]
      : [0, 1];
    const rangeExtentRaw = getRange(rows, rangeAccessor, defaultRange);

    return new ChartDataRegular<R>(
      datasetInput,
      labelsByDimension,
      rangeExtentRaw,
      rangeFormatter,
      dateFormatter,
      dateDistanceGetter,
      dataDescription,
      normalizeTicks,
      { [Dimension.grouping]: groupingSortSpec },
      dataOutputSettings
    );
  }

  static fromStatsDataset<R extends RowBaseInterface<RowValueRegularRaw>>(
    datasetInput: DatasetInput<R, MainHeaderData>,
    labelsByDimension: LabelsByDimension,
    dateFormatter: (input: string) => string,
    dateDistanceGetter: DateDistanceGetter,
    measureSelections: MeasureSelection[],
    dataDescription: DataDescription,
    dataOutputSettings: DataOutputSettings
  ): ChartDataRegular<R> {
    const rows = datasetInput.rows;
    const numDecimals =
      dataOutputSettings.fixedNumDecimals !== null
        ? dataOutputSettings.fixedNumDecimals
        : maxNumDecimals(rows.map((r) => r.range()));
    const rangeFormatter = fixedDecimalsFormatter(numDecimals ?? 0);

    const rangeAccessor = (row: R) => row.range();
    const liftedValue = datasetInput.header.liftedDimensions?.[Dimension.value];
    const defaultRange: [number, number] = defined(liftedValue)
      ? [parseFloat(liftedValue), 2 * 2 * parseFloat(liftedValue)]
      : [0, 1];
    const rangeExtentRaw = getRange(rows, rangeAccessor, defaultRange);
    const forecastRows = datasetInput.forecastRows;
    if (defined(forecastRows) && forecastRows.length > 0) {
      const forecastExt = getForecastRowsRangeExtent(
        forecastRows,
        dataOutputSettings.forecast.show &&
          dataOutputSettings.forecast.showUncertaintyInterval
      );
      const min = forecastExt[0];
      if (defined(min)) {
        rangeExtentRaw[0] = Math.min(rangeExtentRaw[0], min);
      }
      const max = forecastExt[1];
      if (defined(max)) {
        rangeExtentRaw[1] = Math.max(rangeExtentRaw[1], max);
      }
    }

    if (measureSelections.length > 2) {
      throw new Error("More than 2 measure selections not supported");
    }

    const primaryMeasureSelection = measureSelections[0];
    const breakdownSortSpecs: { [key: string]: SortSpec } = {};
    for (const d of primaryMeasureSelection.measure.dimensions) {
      const orderTop: [value: string, order: number][] = [];
      const orderBottom: [value: string, order: number][] = [];
      for (const value of d.values ?? []) {
        if (value.sort_mode === "fixed_top") {
          orderTop.push([value.label, value.sort_order]);
        } else if (value.sort_mode === "fixed_bottom") {
          orderBottom.push([value.label, value.sort_order]);
        }
      }
      orderTop.sort((a, b) => a[1] - b[1]);
      orderBottom.sort((a, b) => a[1] - b[1]);
      breakdownSortSpecs[d.data_column] = {
        orderBottom: orderBottom.map((v) => v[0]),
        orderTop: orderTop.map((v) => v[0]),
      };
    }

    return new ChartDataRegular<R>(
      datasetInput,
      labelsByDimension,
      rangeExtentRaw,
      rangeFormatter,
      dateFormatter,
      dateDistanceGetter,
      dataDescription,
      normalizeTicks,
      { [Dimension.grouping]: groupingSortSpec, ...breakdownSortSpecs },
      dataOutputSettings
    );
  }

  lineChartDataProps() {
    const dimensions = this._dataDimensions;
    const sortedGroupingDimensions = dimensions.filter(
      (d) => ![Dimension.value, Dimension.date].includes(d as Dimension)
    );

    // We have a single legend dimension only if there exactly three data dimensions
    // (i.e. value, date, and breakdown1)
    const singleLegendDimension =
      dimensions.length === 3 ? dimensions[2] : undefined;

    return {
      singleLegendDimension,
      plainDimensionsSorted: sortedGroupingDimensions,
    };
  }
}

function getForecastRowsRangeExtent(
  rows: RowForecast[],
  includeUncertaintyInterval: boolean
) {
  if (includeUncertaintyInterval) {
    const high = _.max(rows.map((r) => r.rangeHigh()));
    const low = _.min(rows.map((r) => r.rangeLow()));
    return [low, high];
  }
  return getRange(rows, (row) => row.range() as any as number, [0, 1]);
}
