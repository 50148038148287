import { useRecoilState } from "recoil";
import { useCallback } from "react";

import {
  ThirdPartyDocDataCardSettings,
  ThirdPartyMicroCardSettings,
} from "../../third_party_docs";
import { singleDataCardQuery } from "../stats/document-core/queries/dataCard";
import { useSaveCard } from "./useSaveDocument";
import { singleMicroCardQuery } from "../stats/document-core/queries/microCard";
import { DocCardMicro } from "../stats/document-core/core";

export function useChangeThirdPartyDataCardSettings(cardId: string) {
  const [card, setCard] = useRecoilState(
    singleDataCardQuery({ cardStateId: cardId })
  );

  const handleSaveCard = useSaveCard();

  return useCallback(
    (settings: ThirdPartyDocDataCardSettings) => {
      const updatedCard = {
        ...card,
        data: {
          ...card.data,
          thirdPartyDataCardSettings: settings,
        },
      };
      setCard(updatedCard);
      handleSaveCard?.(updatedCard);
    },
    [card, handleSaveCard, setCard]
  );
}

export function useChangeThirdPartyMicroCardSettings(cardId: string) {
  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: cardId })
  );

  const handleSaveCard = useSaveCard();

  return useCallback(
    (settings: ThirdPartyMicroCardSettings) => {
      const updatedCard: DocCardMicro = {
        ...card,
        data: {
          ...card.data,
          thirdPartyMicroCardSettings: settings,
        },
      };
      setCard(updatedCard);
      handleSaveCard?.(updatedCard);
    },
    [card, handleSaveCard, setCard]
  );
}
