import { Table, TableColumns, TableData } from "../../../../components/Table";

import "./_TableLatest.scss";

export function TableLatest<
  ID extends string | number,
  Cells extends (string | JSX.Element)[]
>(props: {
  tableData: TableData<ID, Cells>;
  columns: TableColumns<ID, Cells>;
  onRowClick: (id: ID) => void;
}) {
  return (
    <div className="overview-table-latest table-container content-padding">
      <Table
        hideHeader
        onRowClick={(id) => props.onRowClick(id)}
        data={props.tableData}
        columns={props.columns}
      ></Table>
    </div>
  );
}
