import { config } from "../../../config";
import { logger } from "../../infra/logging";

export function setDraftModeStored(modeOn: boolean) {
  try {
    sessionStorage.setItem(
      config.storageKeys.adminDraftMode,
      modeOn.toString()
    );
  } catch (e) {
    logger.error(e);
  }
}

export function getDraftModeStored(): boolean | undefined {
  try {
    const mode = sessionStorage.getItem(config.storageKeys.adminDraftMode);
    if (mode === null) {
      return;
    }
    return mode === "true";
  } catch (e) {
    logger.error(e);
    return;
  }
}

export function setDataAdminModeStored(modeOn: boolean) {
  try {
    sessionStorage.setItem(config.storageKeys.dataAdminMode, modeOn.toString());
  } catch (e) {
    logger.error(e);
  }
}

export function getDataAdminModeStored(): boolean | undefined {
  try {
    const mode = sessionStorage.getItem(config.storageKeys.dataAdminMode);
    if (mode === null) {
      return;
    }
    return mode === "true";
  } catch (e) {
    logger.error(e);
    return;
  }
}
