/**
 * Makes the full browser title, given a dynamic part that
 * changes depending on the view.
 */
export function getBrowserTitle(dynamic: string): string {
  return dynamic + " - Infostat";
}

export function defaultBrowserTitle(): string {
  return "Infostat";
}
