import { ChoiceGroup, Dropdown, IChoiceGroupOption } from "@fluentui/react";

import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";
import {
  FluentModalBody,
  FluentModalFooter,
  FluentModalTall,
} from "../../../../../components/Modal";
import { defined } from "../../../../../lib/core/defined";
import { logger } from "../../../../../lib/infra/logging";
import { OutputPreview } from "../OutputPreview";
import { Button } from "../../../../../components/Button";
import { InfoBoxFoldout } from "../../../../../components/InfoBox";

import "./ComputationSettingsDialog.scss";
import {
  COMPUTATION_INPUT_ROUNDING_SAME_AS_DISPLAY,
  COMPUTATION_INPUT_ROUNDING_NONE,
  DataOutputSettings,
} from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";

const outputNumDecimalsOptions = [
  { numDecimals: null, text: "Auto" },
  { numDecimals: 0, text: "0" },
  { numDecimals: 1, text: "1" },
  { numDecimals: 2, text: "2" },
  { numDecimals: 3, text: "3" },
];

const SAME_AS_DISLAY_LABEL = "Samma som för visning av originaldata";
const NO_ROUNDING_LABEL = "Ingen avrundning";
const inputRoundingOptions: IChoiceGroupOption[] = [
  {
    key: COMPUTATION_INPUT_ROUNDING_SAME_AS_DISPLAY,
    text: SAME_AS_DISLAY_LABEL,
  },
  { key: COMPUTATION_INPUT_ROUNDING_NONE, text: NO_ROUNDING_LABEL },
];

export function ComputationSettingsDialog(props: {
  cardId: string;
  onClose: () => void;
  settings: DataOutputSettings;
  setSettings: (settings: DataOutputSettings) => void;
}) {
  return (
    <FluentModalTall
      title="Beräkningsinställningar"
      isOpen
      onClose={props.onClose}
      width="md"
    >
      <FluentModalBody>
        <div id="computation-settings-dialog">
          <section>
            <h3>Antal decimaler för visning</h3>
            <InfoBoxFoldout title="Detaljer">
              <></>
              <p>
                Välj hur många decimaler som ska användas vid visning av
                beräknade värden i diagram och tabeller.
              </p>
            </InfoBoxFoldout>
            <Dropdown
              dropdownWidth="auto"
              options={outputNumDecimalsOptions.map((o, i) => ({
                key: i,
                text: o.text,
              }))}
              selectedKey={
                outputNumDecimalsOptions.findIndex(
                  (o) =>
                    o.numDecimals ===
                    props.settings.computationOutputNumDecimals
                ) ?? 0
              }
              onChange={(e, option) => {
                if (!defined(option)) {
                  logger.warn("No option selected");
                  return;
                }
                if (typeof option.key !== "number") {
                  logger.warn("Option key is not a number");
                  return;
                }
                const info = outputNumDecimalsOptions[option.key];
                if (!defined(info)) {
                  logger.warn("No info for option: " + option.key);
                  return;
                }
                props.setSettings({
                  ...props.settings,
                  computationOutputNumDecimals: info.numDecimals,
                });
              }}
            />
          </section>
          <section>
            <h3>Avrundning till beräkningar</h3>
            <InfoBoxFoldout title="Detaljer">
              <></>
              <p>
                <em>{SAME_AS_DISLAY_LABEL}</em> innebär att värden avrundas på
                samma sätt som de visas i de ingående måtten och sedan används i
                beräkningen. På så sätt minimeras eventuella avrundningsfel i
                diagram. <em>{NO_ROUNDING_LABEL}</em> innebär att indata till
                beräkningen inte avrundas, vilket ger bättre precision.
              </p>
            </InfoBoxFoldout>

            <ChoiceGroup
              options={inputRoundingOptions}
              selectedKey={props.settings.computationInputRounding}
              onChange={(e, option) => {
                if (!defined(option)) {
                  logger.warn("No option selected");
                  return;
                }
                if (typeof option.key !== "string") {
                  logger.warn("Option key is not a string");
                  return;
                }
                if (option.key === COMPUTATION_INPUT_ROUNDING_SAME_AS_DISPLAY) {
                  props.setSettings({
                    ...props.settings,
                    computationInputRounding: option.key,
                  });
                  return;
                } else if (option.key === COMPUTATION_INPUT_ROUNDING_NONE) {
                  props.setSettings({
                    ...props.settings,
                    computationInputRounding: option.key,
                  });
                  return;
                }
                logger.warn("Invalid option key: " + option.key);
              }}
            />
          </section>

          <div className="preview">
            <h2>Förhandsvisning</h2>
            <OutputPreview cardId={props.cardId}></OutputPreview>
          </div>
        </div>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterRight>
            <Button title="Stäng" onClick={props.onClose}></Button>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModalTall>
  );
}
