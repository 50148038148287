import { config } from "../../../../../../config";
import { decodedAuthedRequest } from "../../../shared";
import {
  MemberOrgMeasureCommitDtoRT,
  MemberOrgMeasureRevertDtoRT,
} from "../shared";

const api = config.apis.importV2;

export function commitPendingChangesMemberOrgsMeasure(id: number) {
  return decodedAuthedRequest(
    api,
    "admin/member_orgs_measures/commit",
    { data_id: id },
    "POST",
    MemberOrgMeasureCommitDtoRT
  );
}

export function revertPendingChangesMemberOrgsMeasure(id: number) {
  return decodedAuthedRequest(
    api,
    "admin/member_orgs_measures/revert",
    { data_id: id },
    "POST",
    MemberOrgMeasureRevertDtoRT
  );
}
