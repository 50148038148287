import { IContextualMenuItem } from "@fluentui/react";
import { useContext, useMemo } from "react";

import {
  ChildNodeInfo,
  FileNodeInfo,
} from "../../../../lib/application/files/tree";
import { UserInfoContext } from "../../../../lib/application/contexts";
import { highestRole } from "../../../../lib/application/files/SharingInfo";
import {
  FileFeatures,
  fileFeaturesBool,
  nodeEnabledFeatures,
} from "./shared/features";
import { IconContextMenu } from "../../../../components/IconContextMenu";

export function FileListMenu(props: {
  parentNode: FileNodeInfo;
  item: ChildNodeInfo;
  menuFeatures: FileFeatures;
  onDelete: (item: ChildNodeInfo) => void;
  handleOpenMoveDialog: () => void;
  handleOpenRenameDialog: () => void;
  handleOpenSharingDialog: () => void;
  handleOpenScheduleNoticesDialog?: () => void;
}) {
  const { parentNode, item, onDelete, menuFeatures } = props;
  const {
    addLibraryRoot,
    addShortcut,
    removeShortcut,
    removeLibraryRoot,
    addHelpRoot,
    removeHelpRoot,
  } = menuFeatures;

  const userInfo = useContext(UserInfoContext);
  const canManageDocLibrary = userInfo?.internalCanManageDocumentLibrary();

  const features = fileFeaturesBool(menuFeatures);
  const parentRole =
    parentNode.type !== "root" ? highestRole(parentNode.roles) : undefined;
  const enable = nodeEnabledFeatures;

  const canMove = enable.canMove(features, parentRole, item);
  const canRename = enable.canRename(features, parentRole, item);
  const canRemove = enable.canRemove(features, parentRole, item);
  const useSharing = enable.canUseSharing(features, item);
  const canSetLibraryRoot = enable.canSetLibraryRoot(features, item);
  const canRemoveLibraryRoot = enable.canRemoveLibraryRoot(
    features,
    parentNode,
    item
  );
  const canSetHelpRoot = enable.canSetHelpRoot(features, item);
  const caneRemoveHelpRoot = enable.canRemoveHelpRoot(
    features,
    parentNode,
    item
  );
  const canScheduleUpdateNotices = enable.canScheduleUpdateNotices(
    features,
    item
  );
  const canAddShortcut = enable.canAddShortcut(features, item);
  const canRemoveShortcut = enable.canRemoveShortcut(features, item);

  const menuItems: IContextualMenuItem[] = useMemo(() => {
    const items: IContextualMenuItem[] = [];
    if (useSharing) {
      items.push({
        key: "sharing",
        text: "Delning",
        iconProps: { iconName: "people" },
        onClick: props.handleOpenSharingDialog,
      });
    }

    if (canMove) {
      items.push({
        key: "move",
        text: "Flytta till",
        iconProps: { iconName: "move" },
        onClick: props.handleOpenMoveDialog,
      });
    }

    if (canRename) {
      items.push({
        key: "rename",
        text: "Ändra namn",
        iconProps: { iconName: "edit" },
        onClick: props.handleOpenRenameDialog,
      });
    }

    if (canRemove) {
      items.push({
        key: "remove",
        name: "Ta bort",
        iconProps: { iconName: "trash" },
        onClick: () => onDelete?.(item),
      });
    }

    if (canManageDocLibrary) {
      const adminItems: IContextualMenuItem[] = [];
      if (canSetLibraryRoot) {
        adminItems.push({
          key: "setSampleLibRoot",
          text: "Lägg till i exempelbibliotek",
          onClick: () => addLibraryRoot?.(item.id, item.type),
        });
      }

      if (canRemoveLibraryRoot) {
        adminItems.push({
          key: "removeSampleLibRoot",
          text: "Ta bort från exempelbibliotek",
          onClick: () => removeLibraryRoot?.(item.id),
        });
      }

      if (canSetHelpRoot) {
        adminItems.push({
          key: "setHelpLibRoot",
          text: "Lägg till i hjälpbibliotek",
          onClick: () => addHelpRoot?.(item.id),
        });
      }

      if (caneRemoveHelpRoot) {
        adminItems.push({
          key: "removeHelpLibRoot",
          text: "Ta bort från hjälpbibliotek",
          onClick: () => removeHelpRoot?.(item.id),
        });
      }

      if (canAddShortcut) {
        adminItems.push({
          key: "addShortcut",
          text: 'Lägg till i "I fokus"',
          onClick: () => addShortcut?.(item.id),
        });
      }

      if (canRemoveShortcut) {
        adminItems.push({
          key: "removeShortcut",
          text: 'Ta bort från "I fokus"',
          onClick: () => removeShortcut?.(item.id),
        });
      }

      if (canScheduleUpdateNotices) {
        adminItems.push({
          key: "scheduleUpdateNotices",
          text: "Schemalägg utskick till användare som bevakar dokumentet",
          onClick: props.handleOpenScheduleNoticesDialog,
        });
      }

      if (adminItems.length > 0) {
        items.push({
          key: "admin",
          text: "Admin",
          iconProps: { iconName: "badge" },
          items: adminItems,
        });
      }
    }
    return items;
  }, [
    useSharing,
    canMove,
    canRename,
    canRemove,
    canManageDocLibrary,
    props.handleOpenSharingDialog,
    props.handleOpenMoveDialog,
    props.handleOpenRenameDialog,
    props.handleOpenScheduleNoticesDialog,
    onDelete,
    item,
    canSetLibraryRoot,
    canRemoveLibraryRoot,
    canSetHelpRoot,
    caneRemoveHelpRoot,
    canAddShortcut,
    canRemoveShortcut,
    canScheduleUpdateNotices,
    addLibraryRoot,
    removeLibraryRoot,
    addHelpRoot,
    removeHelpRoot,
    addShortcut,
    removeShortcut,
  ]);

  if (menuItems.length === 0) {
    return <></>;
  }

  return <IconContextMenu icon="more" items={menuItems} />;
}
