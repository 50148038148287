import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { config } from "../../config";

import { HttpError } from "../../lib/infra/HttpResult";
import { statsOverviewPath } from "../../lib/paths";
import { Button } from "../Button";
import { ExternalLink } from "../ExternalLink";
import { SimpleErrorPage } from "./SimpleErrorPage";
import { setDataAdminModeStored } from "../../lib/application/state/data_admin_mode";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * Shows a big error message suitable for a full page
 */
export function HttpErrorViewEitherFullPage(props: { left: HttpError }) {
  return <HttpErrorPage error={props.left}></HttpErrorPage>;
}

interface Props {
  error: HttpError;
}
/**
 * A simple error view that centers on the page and shows:
 * - title of the error
 * - short explanation
 * - link to go back to Stats
 */
export function HttpErrorPage(props: Props): JSX.Element {
  const error = props.error;
  switch (error.code) {
    case "unauthorized":
      return <Unauthorized></Unauthorized>;
    case "not-found":
      return <NotFound></NotFound>;
    case "bad-client-input":
      return <BadClientInput></BadClientInput>;
    case "4xx":
    case "internal-server-error":
    case "unknown-error":
      return <UnexpectedError></UnexpectedError>;
    case "fetch-error":
      return <FetchError></FetchError>;
    default:
      return <UnexpectedError></UnexpectedError>;
  }
}

function Unauthorized() {
  return (
    <SimpleErrorPage
      title="Behörighet saknas"
      text={
        <span>
          Du verkar inte ha behörighet att visa den här sidan.{" "}
          <ExternalLink
            link={config.infostatSeLinks.contact}
            text="Kontakta oss här"
          ></ExternalLink>{" "}
          för information om licenser och tjänsten.
        </span>
      }
      includeLogoutButton={true}
      includeBackButton={true}
    ></SimpleErrorPage>
  );
}

function UnexpectedError() {
  return (
    <SimpleErrorPage
      title="Oväntat fel"
      text="Ett oväntat fel uppstod. Försök igen senare."
      includeBackButton={true}
      includeLogoutButton={false}
    ></SimpleErrorPage>
  );
}

function BadClientInput() {
  return (
    <SimpleErrorPage
      title="Oväntat fel - gammal sida?"
      text="Ett oväntat fel uppstod i kommunikationen med Stats. Detta kan bero på att du använt en gammal länk eller sida. Öppna Stats på nytt och försök igen."
      includeBackButton={true}
      includeLogoutButton={false}
    ></SimpleErrorPage>
  );
}

function NotFound() {
  return (
    <SimpleErrorPage
      title="Den här sidan finns inte."
      text="Vi kunde tyvärr inte hitta den här sidan. Detta kan hända vid exempelvis
        användning av en gammal länk."
      includeBackButton={true}
      includeLogoutButton={false}
    ></SimpleErrorPage>
  );
}

function FetchError() {
  return (
    <SimpleErrorPage
      title="Ett oväntat fel inträffade"
      text="Kunde inte få kontakt med servern. Försök igen senare."
      includeBackButton={true}
      includeLogoutButton={false}
    ></SimpleErrorPage>
  );
}

export function BackToStatsButton() {
  const navigate = useNavigate();
  return (
    <Button
      intent="primary"
      title="Tillbaka till hemvyn"
      onClick={() => navigate(statsOverviewPath())}
    ></Button>
  );
}
export function LogoutButton() {
  const { logout } = useAuth0();
  const handleLogout = useCallback(() => {
    setDataAdminModeStored(false);
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);
  return (
    <Button
      intent="primary"
      title="Logga in med annan användare"
      onClick={handleLogout}
    ></Button>
  );
}
