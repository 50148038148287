import React, { useState, useCallback, useMemo } from "react";
import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  TextField,
} from "@fluentui/react";

import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import { AnalysisTemplatesDto } from "../../../../../lib/application/requests/python/templates";
import { Button } from "../../../../../components/Button";
import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import { defined } from "../../../../../lib/core/defined";
import { logger } from "../../../../../lib/infra/logging";

interface Props {
  onClose: () => void;
  modulesByCategory: { [category: string]: AnalysisTemplatesDto };
  onSaveNew: (category: string, name: string, description: string) => void;
  onOverwrite: (
    templateId: number,
    name?: string,
    description?: string
  ) => void;
}

export function SaveTemplateDialog({
  onClose,
  modulesByCategory,
  onSaveNew,
  onOverwrite,
}: Props) {
  const [isNewTemplate, setIsNewTemplate] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );
  const [selectedModule, setSelectedModule] = useState<number | undefined>(
    undefined
  );
  const [newCategoryName, setNewCategoryName] = useState<string>("");
  const [newTemplateName, setNewTemplateName] = useState<string>("");
  const [newTemplateDescription, setNewTemplateDescription] =
    useState<string>("");

  const [existingTemplateNewName, setExistingTemplateNewName] =
    useState<string>("");
  const [existingTemplateNewDescription, setExistingTemplateNewDescription] =
    useState<string>("");

  const categoriesOptions: IDropdownOption[] = Object.keys(
    modulesByCategory
  ).map((category) => ({
    key: category,
    text: category,
  }));

  const moduleOptions: IDropdownOption[] = selectedCategory
    ? modulesByCategory[selectedCategory].map((module) => ({
        key: module.template_id,
        text: module.name,
      }))
    : [];

  const handleSave = useCallback(() => {
    if (isNewTemplate) {
      const category = nonEmptyString(newCategoryName)
        ? newCategoryName
        : selectedCategory;
      if (!defined(category)) {
        return;
      }
      onSaveNew(category, newTemplateName, newTemplateDescription);
    } else if (selectedCategory && selectedModule) {
      const template = modulesByCategory[selectedCategory].find(
        (template) => template.template_id === selectedModule
      );
      if (!defined(template)) {
        return logger.error("Template not found");
      }
      if (!window.confirm(`Vill du skriva över mallen "${template.name}"?`)) {
        return;
      }
      onOverwrite(
        template.template_id,
        nonEmptyString(existingTemplateNewName)
          ? existingTemplateNewName
          : undefined,
        nonEmptyString(existingTemplateNewDescription)
          ? existingTemplateNewDescription
          : undefined
      );
    }
  }, [
    isNewTemplate,
    selectedCategory,
    selectedModule,
    newCategoryName,
    onSaveNew,
    newTemplateName,
    newTemplateDescription,
    modulesByCategory,
    onOverwrite,
    existingTemplateNewName,
    existingTemplateNewDescription,
  ]);

  const missingFields = useMemo(() => {
    if (isNewTemplate) {
      if (
        !nonEmptyString(newTemplateName) ||
        !nonEmptyString(newTemplateDescription)
      ) {
        return true;
      }
      if (!nonEmptyString(newCategoryName) && !defined(selectedCategory)) {
        return true;
      }
    } else {
      return !defined(selectedCategory) || !defined(selectedModule);
    }

    return false;
  }, [
    isNewTemplate,
    newCategoryName,
    newTemplateDescription,
    newTemplateName,
    selectedCategory,
    selectedModule,
  ]);

  return (
    <FluentModal title="Spara som mall" isOpen={true} onClose={onClose}>
      <FluentModalBody>
        <div>
          <DefaultButton
            onClick={() => setIsNewTemplate(true)}
            text="Ny mall"
            checked={isNewTemplate}
          />
          <DefaultButton
            className="margin-x-sm"
            onClick={() => setIsNewTemplate(false)}
            text="Existerande mall"
            checked={!isNewTemplate}
          />
        </div>
        <div>
          {isNewTemplate ? (
            <>
              <TextField
                label="Ny kategori (valfritt)"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.currentTarget.value)}
              />
              <Dropdown
                disabled={nonEmptyString(newCategoryName)}
                label="Existerande kategori"
                options={categoriesOptions}
                selectedKey={selectedCategory}
                onChange={(e, option) =>
                  setSelectedCategory(option?.key as string)
                }
              />
              <TextField
                label="Namn"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.currentTarget.value)}
              ></TextField>
              <TextField
                label="Beskrivning"
                value={newTemplateDescription}
                onChange={(e) =>
                  setNewTemplateDescription(e.currentTarget.value)
                }
              />
            </>
          ) : (
            <>
              <Dropdown
                label="Kategori"
                options={categoriesOptions}
                selectedKey={selectedCategory}
                onChange={(e, option) => {
                  setSelectedCategory(option?.key as string);
                  setSelectedModule(undefined);
                }}
              />
              <Dropdown
                label="Mall"
                options={moduleOptions}
                selectedKey={selectedModule}
                onChange={(e, option) =>
                  setSelectedModule(option?.key as number)
                }
                disabled={!defined(selectedCategory)}
              />
              <TextField
                label="Nytt namn (valfritt)"
                value={existingTemplateNewName}
                onChange={(e) =>
                  setExistingTemplateNewName(e.currentTarget.value)
                }
              ></TextField>
              <TextField
                label="Beskrivning"
                value={existingTemplateNewDescription}
                onChange={(e) =>
                  setExistingTemplateNewDescription(e.currentTarget.value)
                }
              />
            </>
          )}
        </div>
      </FluentModalBody>
      <FluentModalFooter>
        <Button onClick={onClose} title="Cancel" />
        <Button
          disabled={missingFields}
          intent="primary"
          onClick={handleSave}
          title="Spara"
        />
      </FluentModalFooter>
    </FluentModal>
  );
}
