import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { defined } from "../../../../core/defined";

import { GeographiesSerializable } from "../../../../domain/geography";
import {
  defaultGeographySelectionMinResolution,
  defaultMeasureSelectionPrimary,
  setMeasureAvailableDatesMut,
  tryExtractSettings,
} from "../../../../domain/measure";
import {
  MeasureFull,
  MeasureSelectionWithoutDates,
  MeasureThin,
} from "../../../../domain/measure/definitions";
import { HttpResult } from "../../../../infra/HttpResult";
import { getSingleMeasure } from "../../../requests/common_requests";
import { statsApiV2 } from "../../../requests/statsApiV2";
import { LatestSettingsFormat } from "../../stats/default-settings/common";
import { DocCardStats } from "../../stats/document-core/core";
import { makeDataCardState } from "../../stats/document-core/create";
import { docCardsListQuery } from "../../stats/document-core/docCardsListState";
import { useAddStatsCardCallback } from "../cardCallbacks";
import { cardWithUpdatedDataState } from "../../stats/cardToDataStateStats";
import { useExtendedAppearanceSettings } from "../useExtendedAppearanceSettings";
import { createColorSchemeContainerWithPalette } from "../../stats/document-style/operations";

/**
 * Load card as single doc measure.
 * Differs from regular documents in default settings.
 * @returns
 */
export function useLoadStatsMeasureAsNewCardSingleDoc(
  adminShowDraftData: boolean
) {
  const makeSelection = useCallback(
    (
      measure: MeasureFull,
      path: string[] | undefined,
      availableMeasures: MeasureThin[],
      settings?: LatestSettingsFormat
    ): MeasureSelectionWithoutDates =>
      defaultMeasureSelectionPrimary(measure, availableMeasures, settings),
    []
  );
  const load = useLoadMeasureAsNewCardInner(
    makeSelection,
    true,
    adminShowDraftData
  );

  const callback = useCallback(
    (measureId: number, geographies: GeographiesSerializable) => {
      return load(measureId, undefined, geographies, []); // No available measures needed for read-only single card docs
    },
    [load]
  );

  return callback;
}

/**
 * Load a new card with a single measure.
 * @returns
 */
export function useLoadStatsMeasureAsNewCard(adminShowDraftData: boolean) {
  const makeSelection = useCallback(
    (
      measure: MeasureFull,
      path: string[] | undefined,
      availableMeasures: MeasureThin[],
      settings?: LatestSettingsFormat
    ): MeasureSelectionWithoutDates => {
      const measureWithPath: MeasureFull = defined(path)
        ? { ...measure, area: path[0], subarea: path[1], subject: path[2] }
        : measure;
      return defaultMeasureSelectionPrimary(
        measureWithPath,
        availableMeasures,
        settings
      );
    },
    []
  );
  const load = useLoadMeasureAsNewCardInner(
    makeSelection,
    false,
    adminShowDraftData
  );

  const callback = useCallback(
    (
      measureId: number,
      path: string[],
      availableMeasures: MeasureThin[],
      geographies: GeographiesSerializable
    ) => {
      return load(measureId, path, geographies, availableMeasures);
    },
    [load]
  );

  return callback;
}

function useLoadMeasureAsNewCardInner(
  makeMeasureSelection: (
    measure: MeasureFull,
    path: string[] | undefined,
    availableMeasures: MeasureThin[],
    settings?: LatestSettingsFormat
  ) => MeasureSelectionWithoutDates,
  insertFirst: boolean, // Insert as first or last card
  adminShowDraftData: boolean = false
) {
  const addCard = useAddStatsCardCallback();
  const docCards = useRecoilValue(docCardsListQuery);
  const appearanceSettings = useExtendedAppearanceSettings();
  const loadMeasure = useCallback(
    async (
      measure: MeasureFull,
      path: string[] | undefined,
      geographies: GeographiesSerializable,
      availableMeasures: MeasureThin[]
    ) => {
      const settings = await tryExtractSettings(measure);
      const measureSelectionDefault = makeMeasureSelection(
        measure,
        path,
        availableMeasures,
        settings
      );

      return setMeasureAvailableDatesMut(
        measureSelectionDefault,
        adminShowDraftData
      ).then(async (fullSelection) => {
        const dataCardState = makeDataCardState(
          path ?? [measure.area, measure.subarea, measure.subject],
          fullSelection,
          geographies,
          appearanceSettings.defaultTheme.customOutputSettings,
          settings
        );
        dataCardState.data.geoSelections =
          defaultGeographySelectionMinResolution(measure, geographies);

        const cardWithData = await cardWithUpdatedDataState(
          () => false,
          dataCardState,
          geographies,
          adminShowDraftData,
          appearanceSettings,
          settings?.colorSchemeContainer
        );
        if (!defined(cardWithData)) {
          return dataCardState;
        }

        addCard(
          cardWithData,
          insertFirst ? 0 : docCards.length,
          settings?.colorSchemeContainer ??
            createColorSchemeContainerWithPalette(
              appearanceSettings.defaultTheme
            )
        );
        return cardWithData;
      });
    },
    [
      makeMeasureSelection,
      adminShowDraftData,
      appearanceSettings,
      addCard,
      insertFirst,
      docCards.length,
    ]
  );

  const callback: (
    measureId: number,
    path: string[] | undefined,
    geographies: GeographiesSerializable,
    availableMeasures: MeasureThin[]
  ) => Promise<HttpResult<DocCardStats>> = useCallback(
    (measureId, path, geographies, availableMeasures) => {
      const selected = availableMeasures.find((m) => m.data_id === measureId);
      if (defined(selected) && selected.value_type !== "survey") {
        return statsApiV2
          .getDimensions(measureId, adminShowDraftData, !adminShowDraftData)
          .then((res) => {
            const dimensions = res.unwrap();
            const fullMeasure = { ...selected, dimensions };
            return loadMeasure(
              fullMeasure,
              path,
              geographies,
              availableMeasures
            ).then(HttpResult.fromOk);
          });
      }
      return getSingleMeasure(
        measureId,
        adminShowDraftData,
        !adminShowDraftData
      ).then((result) => {
        const measure = result.unwrap();
        return loadMeasure(measure, path, geographies, availableMeasures).then(
          HttpResult.fromOk
        );
      });
    },
    [adminShowDraftData, loadMeasure]
  );
  return callback;
}
