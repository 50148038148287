import { chain, sortBy } from "lodash";

import { defined } from "../../../../../core/defined";
import {
  DimensionV2Dto,
  DimensionValueV2Dto,
} from "../../../../../domain/measure/definitions";
import {
  defaultDimensionComparator,
  defaultDimensionLabelComparatorAsc,
  fixedNonPrimaryDimensionsValid,
} from "../../../shared/dimensions";
import { RowBaseInterface } from "../../../shared/row";
import { applyDimensionSortOrder } from "../../../../../domain/measure";
import { TableMicroSettings } from "../TableMicroSettings";
import { Dimension } from "../../../shared/core/definitions";
import { last } from "../../../../../core/last";
import { LabelAndDimension } from "./table_base";
import { getRowLabels } from "./calc_rows";
import { ValuesByDimensionAndLabel } from "../../DatasetGeneric";
import { DataOutputSettings } from "../../../../state/stats/document-core/DataOutputSettings";

export type DimColumnAndValues = [
  dimColumn: string,
  /**
   * For non-breakdown dimensions such as date, we only have a string.
   * Such dimensions have no parent dimension.
   **/
  value: DimensionValueV2Dto | string
][];

interface DimensionsAndVariantsInfo {
  rowKeys: string[];
  rowDimension: string | undefined;
  nonPrimaryDimensions: string[];
  variantCombinations: LabelAndDimension[][];
}

/**
 * Setup the order of dimensions for tables. The primary dimension is the "row dimension"
 * and will decide the row labels. Other dimensions will be the table column headers, in multiple layers
 * if necessary.
 */
export function setupDimensionsAndVariants<T, R extends RowBaseInterface<T>>(
  rows: R[],
  valuesByDimensionAndLabel: ValuesByDimensionAndLabel,
  dimensionFormatter: (dimension: string, label: string) => string,
  dimensionSpecs: DimensionV2Dto[],
  nonValueDimensions: string[],
  settings: DataOutputSettings,
  microSettings: TableMicroSettings | undefined
): DimensionsAndVariantsInfo {
  const fixedNonPrimaryDimensions = settings.fixedDimensionOrder ?? undefined;
  const forceRegionAsPrimary = microSettings?.showReferenceRows() ?? false;

  const validFixedNonPrimaryDimensions = defined(fixedNonPrimaryDimensions)
    ? fixedNonPrimaryDimensionsValid(
        nonValueDimensions,
        fixedNonPrimaryDimensions,
        forceRegionAsPrimary
      )
      ? fixedNonPrimaryDimensions
      : undefined
    : undefined;

  /**
   * Row-wise dimension. This dimension holds the row labels.
   *
   * If displaying mikro reference rows, this dimension is always the region dimension, otherwise tables
   * will be hard to interpret.
   */
  const rowDimension: string | undefined = forceRegionAsPrimary
    ? Dimension.region
    : pickTableRowDimension(
        validFixedNonPrimaryDimensions,
        nonValueDimensions,
        valuesByDimensionAndLabel
      );

  /**
   * These dimensions appear in the table headers, in order.
   */
  const nonRowDimensions = defined(validFixedNonPrimaryDimensions)
    ? validFixedNonPrimaryDimensions.slice(1) // sort order already checked
    : nonValueDimensions
        .filter((dim) => !defined(rowDimension) || rowDimension !== dim)
        .sort(defaultDimensionComparator);

  const nonRowDimensionVariants: DimColumnAndValues[] =
    getNonRowDimensionVariants(
      nonRowDimensions,
      valuesByDimensionAndLabel,
      dimensionSpecs,
      settings
    );

  // Build the combinations of dimension variants
  // Example:
  // Kön: Man, Kvinna
  // Ålder: 0-4, 4-9
  // results in combinations
  // [[Man, 0-4], [Man, 4-9], [Kvinna, 0-4], [Kvinna, 4-9]]

  const validCombos = getAllValidCombinations(nonRowDimensionVariants);
  const variantCombinations = chain(validCombos)
    .map((combo) =>
      combo.map(([dimColumn, value]) => ({
        dimension: dimColumn,
        label: typeof value === "string" ? value : value.label,
      }))
    )
    .filter((combo) => {
      return !settings.hiddenBreakdownCombinations.some((hiddenCombo) =>
        hiddenCombo.every((d) =>
          combo.some((c) => c.dimension === d.dimension && c.label === d.value)
        )
      );
    })
    .value();

  /**
   * Rows are sorted by primary dimension keys -- row labels. Here we make the default sort order.
   * The sort order is given by the sort order specified for the dimension, or if none specified for certain items, by value.
   */
  const rowKeys = getRowLabels(
    rowDimension,
    dimensionSpecs,
    dimensionFormatter,
    rows,
    variantCombinations
  );

  return {
    rowKeys,
    rowDimension,
    nonPrimaryDimensions: nonRowDimensions,
    variantCombinations,
  };
}

/** Get non-row dimension variants when there are no tree dimensions */
function getNonRowDimensionVariants(
  nonRowDimensions: string[],
  valuesByDimensionAndLabel: ValuesByDimensionAndLabel,
  dimensionSpecs: DimensionV2Dto[],
  settings: DataOutputSettings
): DimColumnAndValues[] {
  return nonRowDimensions.map((dim) => {
    const sortedLabels = Object.keys(valuesByDimensionAndLabel[dim]).sort(
      defaultDimensionLabelComparatorAsc(dim)
    );

    // dimSpec will not be defined for date or value dimensions
    const dimSpec = dimensionSpecs.find((d) => d.data_column === dim);
    if (defined(dimSpec?.parent_id)) {
      return sortBy(sortedLabels, (left, right) => {
        const leftValue = valuesByDimensionAndLabel[dim][left];
        const rightValue = valuesByDimensionAndLabel[dim][right];
        return (leftValue?.sort_order ?? 0) - (rightValue?.sort_order ?? 0);
      }).map((label) => [dim, label] as [string, string]);
    }

    const sortedLabelsFinal = defined(dimSpec)
      ? applyDimensionSortOrder(sortedLabels, dimSpec)
          .merged()
          .map((label) => {
            const value = dimSpec?.values?.find((v) => v.label === label);
            if (!defined(value)) {
              const computed = settings.computedVariablesV3.find(
                (v) => v.label === label
              );
              if (defined(computed)) {
                return [dim, computed.label] as [string, string];
              }
              throw new Error(
                `Could not find value for label ${label} in dimension ${dim}`
              );
            }
            return [dim, value] as [string, DimensionValueV2Dto];
          })
      : sortedLabels.map((label) => [dim, label] as [string, string]);
    return sortedLabelsFinal;
  });
}

/** Pick the dimension with the greatest number of labels as the row dimension */
export function pickTableRowDimension(
  validFixedNonPrimaryDimensions: string[] | undefined,
  nonValueDimensions: string[],
  valuesByDimensionAndLabel: ValuesByDimensionAndLabel
): string | undefined {
  if (defined(validFixedNonPrimaryDimensions)) {
    return validFixedNonPrimaryDimensions[0];
  }

  const rowDimension = chain(nonValueDimensions)
    .maxBy((dim) => Object.keys(valuesByDimensionAndLabel[dim]).length ?? 0)
    .value() as string | undefined;
  return rowDimension;
}

/**
 * Gets all valid combinations of dimension values.
 * Depth-first search.
 *
 * Example:
 * Sverige, Norge, Danmark, Finland
 * Stockholm, Göteborg, Malmö, Uppsala, Oslo, Bergen, Köpenhamn, Århus, Helsingfors, Åbo
 * [[Sverige, Stockholm], [Sverige, Göteborg], [Norge, Oslo], ..]
 */
export function getAllValidCombinations(
  valuesByDimension: DimColumnAndValues[]
): DimColumnAndValues[] {
  function generateCombinations(
    index: number,
    current: DimColumnAndValues
  ): DimColumnAndValues[] {
    if (index === valuesByDimension.length) {
      return [current];
    }

    const usedValues = valuesByDimension[index];
    const newCombinations: DimColumnAndValues[] = [];

    for (const [dimColumn, value] of usedValues) {
      const prev = last(current)?.[1];
      // Note that some dimensions, like date, have no dimension/value specifications
      // and cannot have parent values.
      if (
        typeof value === "string" ||
        !defined(value.parent_id) ||
        (defined(prev) &&
          typeof prev !== "string" &&
          prev.id === value.parent_id)
      ) {
        const newCombination: DimColumnAndValues = [
          ...current,
          [dimColumn, value],
        ];
        newCombinations.push(
          ...generateCombinations(index + 1, newCombination)
        );
      }
    }

    return newCombinations;
  }

  return generateCombinations(0, []);
}
