import {
  Array as ArrayRT,
  Number as NumberRT,
  String as StringRT,
  Record,
  Static,
  Null,
} from "runtypes";

export const MyOrganizationInviteDtoRT = Record({
  accepted_at: Null.Or(StringRT),
  accepted_by: Null.Or(StringRT),
  created_at: StringRT,
  created_by: StringRT,
  created_by_email: StringRT,
  invited_email: StringRT,
  license_labels: Null.Or(ArrayRT(StringRT)),
  role_labels: Null.Or(ArrayRT(StringRT)),
  organization_id: StringRT,
  organization_invite_id: NumberRT,
  user_notified_at: Null.Or(StringRT),
});
export type MyOrganizationInviteDto = Static<typeof MyOrganizationInviteDtoRT>;

const MyOrganizationLicenseDtoRT = Record({
  license_id: NumberRT,
  label: StringRT,
  max_count: NumberRT,
  current_usage_count: NumberRT,
});
export type MyOrganizationLicenseDto = Static<
  typeof MyOrganizationLicenseDtoRT
>;

const MyOrganizationUserDtoRT = Record({
  id: StringRT,
  email: StringRT,
  licenses: Null.Or(
    ArrayRT(
      Record({
        license_id: NumberRT,
        label: StringRT,
      })
    )
  ),
  roles: Null.Or(
    ArrayRT(
      Record({
        role_id: NumberRT,
        label: StringRT,
      })
    )
  ),
});
export type MyOrganizationUserDto = Static<typeof MyOrganizationUserDtoRT>;

const OrganizationRoleDtoRT = Record({
  role_id: NumberRT,
  label: StringRT,
  description: StringRT,
});
export type OrganizationRoleDto = Static<typeof OrganizationRoleDtoRT>;
export const MyOrganizationDetailsDtoRT = Record({
  id: StringRT,
  name: StringRT,
  licenses: ArrayRT(MyOrganizationLicenseDtoRT).Or(Null),
  users: Null.Or(ArrayRT(MyOrganizationUserDtoRT)),
  invites: Null.Or(ArrayRT(MyOrganizationInviteDtoRT)),
  available_roles: ArrayRT(OrganizationRoleDtoRT),
  email_domains: ArrayRT(StringRT),
});
export type MyOrganizationDetails = Static<typeof MyOrganizationDetailsDtoRT>;
