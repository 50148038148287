import { useMemo } from "react";
import { findMeasureMicro } from "../../../../../lib/application/state/stats/document-core/core-micro";

import { defined } from "../../../../../lib/core/defined";
import { MeasureSelectionMicroPartial } from "../../../../../lib/domain/measure/definitions";
import { MicroMeasure } from "../../../../../lib/domain/micro/MicroMeasure";
import { MicroMeasureDto } from "../../../../../lib/infra/api_responses/micro_dataset";

export function useMicroMeasure(
  selection: MeasureSelectionMicroPartial | undefined,
  availableMeasures?: MicroMeasureDto[]
) {
  const currentMeasure = selection?.measure;

  const microMeasure = useMemo(() => {
    if (!defined(currentMeasure)) {
      return;
    }
    const selectedMicroMeasure = findMeasureMicro(
      availableMeasures ?? [],
      currentMeasure?.id,
      currentMeasure?.computed?.type
    );
    if (defined(currentMeasure) && defined(selectedMicroMeasure)) {
      return new MicroMeasure(selectedMicroMeasure, currentMeasure.dimensions);
    }
  }, [currentMeasure, availableMeasures]);

  return microMeasure;
}
