import { defined } from "../../../../core/defined";
import {
  MicroOutputTab,
  DataSelectionMicro,
  MicroGeoSelections,
} from "../../stats/document-core/core-micro";

export function ensureCompatibleSelectedTab(
  currentTab: MicroOutputTab,
  dataSelections?: DataSelectionMicro[],
  geoSelections?: MicroGeoSelections
): MicroOutputTab {
  const hasPrimarySelection =
    defined(dataSelections) && dataSelections.some((s) => s.type === "primary");
  if (currentTab !== "map-view" && !hasPrimarySelection) {
    return "map-view";
  }

  const noGeoSelections =
    !defined(geoSelections) || geoSelections.selected.length === 0;
  if (
    currentTab === "map-view" &&
    noGeoSelections &&
    dataSelections?.length === 1 &&
    dataSelections[0].type === "primary"
  ) {
    return "map-select";
  }

  const dataOutput: MicroOutputTab[] = ["table", "chart", "info"];
  if (!defined(geoSelections) && dataOutput.includes(currentTab)) {
    return "map-select";
  }
  return currentTab;
}
