import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpErrorPage } from "../components/errors/HttpErrorView";
import { DefaultLoading } from "../components/Loading";
import { FileNodeInfo } from "../lib/application/files/tree";
import { LoadingResult } from "../lib/application/loading/LoadingResult";
import {
  activateSharingLink,
  readNode,
} from "../lib/application/requests/docs/documents";
import { Progress } from "../lib/core/progress";
import { HttpError } from "../lib/infra/HttpResult";
import { documentPath, statsOverviewOpenFolderPath } from "../lib/paths";

interface Props {
  linkCode: string;
}
export function StatsShareLink(props: Props) {
  const { linkCode } = props;
  const [result, setResult] = useState<LoadingResult<HttpError, FileNodeInfo>>(
    LoadingResult.notStarted()
  );
  const navigate = useNavigate();

  useEffect(() => {
    activateSharingLink(linkCode).then((res) => {
      res.match({
        ok: (nodeId) => {
          readNode(nodeId).then((nodeRes) => {
            nodeRes.match({
              ok: (nodeInfo) => {
                // Redirect to actual page
                switch (nodeInfo.type) {
                  case "document":
                    navigate(documentPath(nodeInfo.id));
                    break;
                  case "folder":
                    navigate(statsOverviewOpenFolderPath(nodeInfo.id));
                    break;
                }
              },
              err: (err) => setResult(LoadingResult.err(err)),
            });
          });
        },
        err: (err) => setResult(LoadingResult.err(err)),
      });
    });
  }, [navigate, linkCode]);

  return result.match({
    notReady: (status) => {
      switch (status.type) {
        case Progress.InProgress:
        case Progress.NotStarted:
          return <DefaultLoading></DefaultLoading>;
        case Progress.Error:
          return <HttpErrorPage error={status.err}></HttpErrorPage>;
      }
    },
    ready: () => {
      return null;
    },
  });
}
