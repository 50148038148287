import { config } from "../../../../config";
import {
  SVG_CHART_MAX_WIDTH,
  SVG_CHART_MIN_WIDTH,
} from "../../stats/shared/charts_common";
import { DocCardStateNonError } from "./document-core/core";

export function getCardOutputWidth(card: DocCardStateNonError): number {
  if (config.appMode === "embedded") {
    return Math.min(window.innerWidth, SVG_CHART_MAX_WIDTH);
  }

  let divWidth =
    document.querySelector(
      `.CardWithTab.card-id-${card.id} .tab-content-with-toolbar`
    )?.clientWidth ?? 0;

  // If we're in the data search preview the above selector won't match and we have no ID set.
  // Use a selector that matches the preview divs content instead
  if (divWidth === 0) {
    divWidth =
      document.querySelector(`#data-search-preview .tab-content-with-toolbar`)
        ?.clientWidth ?? SVG_CHART_MIN_WIDTH;
  }

  return Math.min(divWidth, SVG_CHART_MAX_WIDTH);
}
