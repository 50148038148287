import { CustomBranding } from "../../../components/NavBar";
import { defined } from "../../core/defined";
import { UserInfoDto } from "../../infra/api_responses/account";
import {
  ThemesSpecial,
  CustomThemeSpec,
  CustomThemeSpecApplied,
  ExtendedAppearanceSettings,
  builtInSecondaryThemes,
  getInfostatStandardTheme,
} from "../stats/shared/core/colors/colorSchemes";

export enum Permission {
  InternalAISummary = "internal_ai_summary",
  InternalListUserVisits = "internal_list_user_visits",
  InternalManageAlerts = "internal_manage_alerts",
  InternalManageDocumentLibrary = "internal_manage_document_library",
  InternalManageEmbedCards = "internal_manage_embed_cards",
  InternalManageMeasurements = "internal_manage_measurements",
  InternalReadAllMeasurements = "internal_read_all_measurements",
  InternalSeeDraftData = "internal_see_draft_data",
  InternalManageOrganizations = "internal_manage_organizations",
  InternalMigrateDocuments = "internal_migrate_documents",
  InternalManagePythonAnalysis = "internal_manage_python_analysis",
  InternalManageOrgFiles = "internal_manage_org_files",

  ListStats = "list_stats",
  ListSurvey = "list_survey",
  ListMikro = "list_mikro",
  ReadStats = "read_stats",
  ReadSurvey = "read_survey",
  ReadMikro = "read_mikro",
  WriteStats = "write_stats",
  WriteSurvey = "write_survey",
  WriteMikro = "write_mikro",
  MikroPostalcodeExport = "mikro_postalcode_export",
  MikroAddressExport = "mikro_street_address_export",

  OrgAdminManagePreferences = "org_admin_manage_preferences",
  OrgAdminManageLicenses = "org_admin_manage_licenses",
  OrgAdminManageFiles = "org_admin_manage_files",
  OrgAdminManageSecureDelivery = "org_admin_manage_secure_delivery",

  PythonAnalysis = "python_analysis",
}

export function hasSomePermission(
  userPermissions: string[],
  permissions: Permission[]
): boolean {
  return permissions.some((permission) => userPermissions.includes(permission));
}

export function hasPermission(
  userPermissions: string[],
  permission: Permission
): boolean {
  return userPermissions.includes(permission);
}

function findDefaultOrgTheme(customOrgThemes: CustomThemeSpec[], id: string) {
  const defaultCustomTheme = customOrgThemes.find((p) => p.id === id);
  if (defined(defaultCustomTheme)) {
    return defaultCustomTheme;
  }
  const standard = getInfostatStandardTheme();
  if (standard.id === id) {
    return standard;
  }
  return builtInSecondaryThemes.find((p) => p.id === id);
}

interface UserPreferences {
  /* Default false */
  hideSupportBubble: boolean;
}

export class UserInfo {
  private _orgBranding: CustomBranding | undefined;
  constructor(private _dto: UserInfoDto) {
    const customBranding = this._dto.organization_branding;
    if (defined(customBranding)) {
      this._orgBranding = {
        backgroundColor: customBranding.nav_bg_color,
        foregroundColor: customBranding.nav_fg_color,
        logoBigUrl: customBranding.logo_url_big,
        logoSmallUrl: customBranding.logo_url_small,
        packagedDocLogoLink: customBranding.packaged_doc_logo_link ?? undefined,
      };
    }
  }

  private _userPermissions(): string[] {
    return this._dto.permissions ?? [];
  }

  private _hasSecureDeliveryShareAccess(): boolean {
    return hasPermission(this._userPermissions(), Permission.WriteMikro);
  }

  private _hasMemberOrgsShareAccess(): boolean {
    return hasPermission(
      this._userPermissions(),
      Permission.InternalManageOrganizations
    );
  }

  private _themes(): ThemesSpecial {
    const orgDefaultThemeId =
      this._dto.organization_preferences?.default_theme_id;
    return {
      orgDefault: defined(orgDefaultThemeId)
        ? findDefaultOrgTheme(
            this._dto.organization_preferences?.org_defined_themes ?? [],
            orgDefaultThemeId
          )
        : undefined,
      orgThemes: this._dto.organization_preferences?.org_defined_themes as
        | CustomThemeSpec[]
        | undefined,
      userThemes: this._dto.preferences?.user_defined_themes as
        | CustomThemeSpec[]
        | undefined,
    };
  }

  private _defaultTheme(): CustomThemeSpec {
    const defaultPaletteId = this._dto.preferences?.default_theme_id;
    const userCustomDefault = this._dto.preferences?.user_defined_themes?.find(
      (p) => p.id === defaultPaletteId
    ) as CustomThemeSpec | undefined;
    if (defined(userCustomDefault)) {
      return userCustomDefault;
    }
    const defaultBuiltIn = builtInSecondaryThemes.find(
      (p) => p.id === defaultPaletteId
    );
    if (defined(defaultBuiltIn)) {
      return defaultBuiltIn;
    }

    const palettes = this._themes();
    if (defined(palettes.orgDefault)) {
      return palettes.orgDefault;
    }
    return getInfostatStandardTheme();
  }

  isThirdPartyOrgUserOnly(): boolean {
    const noRegularPermissions =
      !defined(this._dto.permissions) || this._dto.permissions.length === 0;
    return this._dto.is_third_party_org_user === true && noRegularPermissions;
  }

  seenTutorials(): string[] {
    return this._dto.seen_tutorials ?? [];
  }

  organizationId(): string {
    return this._dto.organization_id;
  }

  organizationName(): string | undefined {
    return this._dto.organization_name;
  }

  copyWithUserPrefs(
    preferences: Partial<UserInfoDto["preferences"]>
  ): UserInfo {
    return new UserInfo({
      ...this._dto,
      preferences: { ...this._dto.preferences, ...preferences },
    });
  }

  copyWithOrgPrefs(
    organization_preferences: Partial<UserInfoDto["organization_preferences"]>
  ): UserInfo {
    return new UserInfo({
      ...this._dto,
      organization_preferences: {
        ...this._dto.organization_preferences,
        ...organization_preferences,
      },
    });
  }

  defaultColorPalette(): CustomThemeSpecApplied {
    return this._defaultTheme();
  }

  extendedAppearanceSettings(): ExtendedAppearanceSettings {
    const themes = this._themes();
    return {
      availableThemes: [
        getInfostatStandardTheme(),
        ...(themes.orgThemes ?? []),
        ...(themes.userThemes ?? []),
        ...builtInSecondaryThemes,
      ],
      defaultThemeId: this._dto.preferences?.default_theme_id,
      defaultOrgThemeId: this._dto.organization_preferences?.default_theme_id,
      colorPalettesSpecial: themes,
      defaultTheme: this._defaultTheme(),
    };
  }

  email(): string {
    return this._dto.email;
  }

  hasPermission(p: Permission): boolean {
    return hasPermission(this._userPermissions(), p);
  }

  organizationBranding(): CustomBranding | undefined {
    return this._orgBranding;
  }

  preferences(): UserPreferences {
    return {
      hideSupportBubble: this._dto.preferences?.hide_support_bubble ?? false,
    };
  }

  /** For now, this is an internal feature */
  hasThirdPartyShareAccess(): boolean {
    return (
      this._hasMemberOrgsShareAccess() || this._hasSecureDeliveryShareAccess()
    );
  }

  hasMemberOrgsShareAccess(): boolean {
    return this._hasMemberOrgsShareAccess();
  }

  internalCanReadAllMeasurements(): boolean {
    return hasPermission(
      this._userPermissions(),
      Permission.InternalReadAllMeasurements
    );
  }

  internalCanManageDocumentLibrary(): boolean {
    return hasPermission(
      this._userPermissions(),
      Permission.InternalManageDocumentLibrary
    );
  }

  // TODO: replace with better permission
  internalCanAccessCardAdminFeatures(): boolean {
    return hasPermission(
      this._userPermissions(),
      Permission.InternalManageMeasurements
    );
  }

  internalMeasureAdmin(): boolean {
    return hasPermission(
      this._userPermissions(),
      Permission.InternalManageMeasurements
    );
  }

  internalCanManageITFeatures(): boolean {
    return hasSomePermission(this._userPermissions(), [
      Permission.InternalMigrateDocuments,
    ]);
  }

  internalCanAccessAdminPanel(): boolean {
    return hasSomePermission(this._userPermissions(), [
      Permission.InternalManageEmbedCards,
      Permission.InternalManageMeasurements,
      Permission.InternalManageOrganizations,
      Permission.InternalMigrateDocuments,
    ]);
  }

  internalCanManageEmbedCards(): boolean {
    return hasPermission(
      this._userPermissions(),
      Permission.InternalManageEmbedCards
    );
  }

  hasSurveyWriteAccess(): boolean {
    return hasPermission(this._userPermissions(), Permission.WriteSurvey);
  }

  hasStatsListAccess(): boolean {
    return hasPermission(this._userPermissions(), Permission.ListStats);
  }

  hasSurveyListAccess(): boolean {
    return hasPermission(this._userPermissions(), Permission.ListSurvey);
  }

  hasStatsWriteAccess(): boolean {
    return hasPermission(this._userPermissions(), Permission.WriteStats);
  }

  hasMicroWriteAccess(): boolean {
    return hasPermission(this._userPermissions(), Permission.WriteMikro);
  }

  hasMicroReadAccess(): boolean {
    return hasPermission(this._userPermissions(), Permission.ReadMikro);
  }

  hasAnyWriteAccess(): boolean {
    return (
      this.hasStatsWriteAccess() ||
      this.hasSurveyWriteAccess() ||
      this.hasMicroWriteAccess()
    );
  }

  hasExportMicroZipcodesAccess(): boolean {
    return hasPermission(
      this._userPermissions(),
      Permission.MikroPostalcodeExport
    );
  }

  hasExportMicroAddressesAccess(): boolean {
    return hasPermission(
      this._userPermissions(),
      Permission.MikroAddressExport
    );
  }
}
