import { chunk } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { AppMessagesHandler } from "../../../../lib/application/AppMessagesHandler";
import { useIncrementer } from "../../../../lib/application/hooks/useIncrementer";
import { defined } from "../../../../lib/core/defined";
import { DocumentVersionsDto } from "../../../../lib/infra/api_responses/admin/document_versions";
import {
  displayHttpErrorInternal,
  HttpResult,
} from "../../../../lib/infra/HttpResult";
import { consoleLogger } from "../../../../lib/infra/logging";

const MIGRATION_BATCH_SIZE = 20;

export function useAvailableVersions(
  getVersions: () => Promise<HttpResult<DocumentVersionsDto>>
) {
  const [versions, setVersions] = useState<DocumentVersionsDto>();

  useEffect(() => {
    getVersions().then((res) => {
      res.match({
        ok: (value) => setVersions(value),
        err: (error) => alert(displayHttpErrorInternal(error)),
      });
    });
  }, [getVersions]);

  return versions;
}

export function useMigrationHooks<ID, DocListItem>(
  migrateSingle: (id: ID) => Promise<HttpResult<unknown>>,
  getId: (doc: DocListItem) => ID,
  handleDocMigrated: (id: ID) => void,
  appMessages: AppMessagesHandler | undefined
) {
  const { handleMigrateSingle, singleMigrationInProgress } =
    useHandleMigrateSingle(migrateSingle, handleDocMigrated, appMessages);
  const {
    handleMigrateAll,
    numMigrated,
    numTotalToMigrate,
    failures,
    migrateAllInProgress,
  } = useHandleMigrateAll<ID, DocListItem>(
    migrateSingle,
    getId,
    handleDocMigrated,
    appMessages
  );

  return {
    handleMigrateSingle,
    singleMigrationInProgress,
    handleMigrateAll,
    numMigrated,
    numTotalToMigrate,
    failures,
    migrateAllInProgress,
  };
}

function useHandleMigrateAll<ID, DocListItem>(
  migrateSingle: (id: ID) => Promise<HttpResult<unknown>>,
  getId: (doc: DocListItem) => ID,
  handleDocMigrated: (id: ID) => void,
  appMessages: AppMessagesHandler | undefined
) {
  const [numMigrated, incNumMigrated] = useIncrementer(0);
  const [failures, setFailures] = useState<[DocListItem, string][]>([]);
  const [migrateAllInProgress, setMigrateAllInProgress] = useState(false);
  const [numTotalToMigrate, setNumTotalToMigrate] = useState<number>();

  const handleMigrateAll = useCallback(
    async (docList: DocListItem[]) => {
      if (!defined(docList)) {
        return;
      }
      if (
        !window.confirm(
          "Är du säker på att du vill migrera alla listade dokument?"
        )
      ) {
        return;
      }
      setMigrateAllInProgress(true);
      setNumTotalToMigrate(docList.length);
      const batches = chunk(docList, MIGRATION_BATCH_SIZE);
      for (const batch of batches) {
        await Promise.all(
          batch.map((doc) =>
            migrateSingle(getId(doc))
              .then((res) => {
                res.match({
                  ok: () => {
                    incNumMigrated();
                    handleDocMigrated(getId(doc));
                  },
                  err: (err) => {
                    setFailures((prev) => [
                      ...prev,
                      [doc, displayHttpErrorInternal(err)],
                    ]);
                  },
                });
              })
              .catch((err) => {
                setFailures((prev) => [...prev, [doc, err.toString()]]);
              })
          )
        );
      }

      if (failures.length > 0) {
        appMessages?.add(
          "error",
          `Några dokument kunde inte migreras. Se nedan för detaljer.`
        );
      } else {
        appMessages?.add("success", "Alla dokument har migrerats!");
      }
      setMigrateAllInProgress(false);
    },
    [
      appMessages,
      failures.length,
      getId,
      handleDocMigrated,
      incNumMigrated,
      migrateSingle,
    ]
  );

  return {
    migrateAllInProgress,
    handleMigrateAll,
    numMigrated,
    numTotalToMigrate,
    failures,
  };
}

function useHandleMigrateSingle<ID>(
  migrateSingle: (id: ID) => Promise<HttpResult<unknown>>,
  handleDocMigrated: (id: ID) => void,
  appMessages: AppMessagesHandler | undefined
) {
  const [singleMigrationInProgress, setSingleMigrationInProgress] =
    useState<boolean>(false);

  const handleMigrateSingle = useCallback(
    (docId: ID) => {
      setSingleMigrationInProgress(true);
      return migrateSingle(docId)
        .then((res) => {
          res.match({
            ok: () => {
              handleDocMigrated(docId);
              appMessages?.add("success", `Dokumentet ${docId} har migrerats!`);
            },
            err: (err) => {
              appMessages?.add("error", displayHttpErrorInternal(err));
              consoleLogger.error(err);
            },
          });
        })
        .catch((err) => {
          appMessages?.add("error", displayHttpErrorInternal(err));
        })
        .finally(() => setSingleMigrationInProgress(false));
    },
    [appMessages, handleDocMigrated, migrateSingle]
  );
  return { handleMigrateSingle, singleMigrationInProgress };
}
