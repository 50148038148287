import { Record, String as StringRT, Unknown, Static } from "runtypes";
import { WorkspaceWrapperRT } from "../../application/state/stats/workspace/types";

export const EmbeddedDocumentDtoRT = Record({
  embed_settings: Unknown,
  document: WorkspaceWrapperRT,
});

export type EmbeddedDocumentDto = Static<typeof EmbeddedDocumentDtoRT>;

export const CreateCardEmbedResultDtoRT = Record({
  key: StringRT,
});
export type CreateCardEmbedResultDto = Static<
  typeof CreateCardEmbedResultDtoRT
>;
