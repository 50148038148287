import * as _ from "lodash";
import { chain, flatMap, intersection } from "lodash";

import { defined } from "../../../../core/defined";
import {
  DataSelection,
  MeasureSelection,
} from "../../../../domain/selections/definitions";
import {
  calcCommonTimespan,
  DateRange,
  dateRangeFromRawUtc,
  dateRangeRawContains,
  dateRangeToRaw,
} from "../../../../domain/measure";
import { InnerDataCardState } from "../document-core/core";
import {
  DEFAULT_TIME_SPAN_SELECTION_RAW,
  TimeResolution,
} from "../../../../domain/time";
import { DateRangeRaw } from "../../../../domain/measure/definitions";
import { numSelectedGeos } from "../../../../domain/geography";
import { dataOutputSettingsDefault } from "./create";

/**
 * Updates state for a specific card by:
 * 1) applying an update function to the given field
 * 2) setting a valid time selection
 */
export function updateDataCardInnerImmut<T extends keyof InnerDataCardState>(
  prevCardState: InnerDataCardState,
  key: T,
  updater: (prevValue: InnerDataCardState[T]) => InnerDataCardState[T],
  inheritGeoSelectionsOnMeasureChange?: (
    prevCardState: InnerDataCardState
  ) => boolean
): InnerDataCardState {
  const setGeoSelectionsInherited =
    inheritGeoSelectionsOnMeasureChange?.(prevCardState);
  const updatedCardUnchecked: InnerDataCardState = {
    ...prevCardState,
    geoSelectionsInherited: defined(setGeoSelectionsInherited)
      ? setGeoSelectionsInherited
      : prevCardState.geoSelectionsInherited,
    [key]: updater(prevCardState[key]),
  };

  // If selections are being changed, ensure a valid timespan
  if (key === "dataSelections" || key === "groupingSelection") {
    if (isGroupingMode(updatedCardUnchecked)) {
      const defTimespan = defaultTimespanGrouping(updatedCardUnchecked);
      updatedCardUnchecked.timeSelection = defTimespan;
    } else {
      if (!timespanValid(updatedCardUnchecked)) {
        const defTimespan = defaultTimespan(updatedCardUnchecked);
        updatedCardUnchecked.timeSelection = defTimespan;
      }
    }
  }

  // Ensure fixed dimensions are unset if the selection changes
  // so that it no longer makes sense
  if (!shouldKeepFixedDimensions(prevCardState, updatedCardUnchecked)) {
    updatedCardUnchecked.settings = {
      ...updatedCardUnchecked.settings,
      fixedDimensionOrder: null,
    };
  }

  if (!isMinorChange(prevCardState, updatedCardUnchecked)) {
    updatedCardUnchecked.settings = dataOutputSettingsDefault();
  }

  return updatedCardUnchecked;
}

/**
 * We default to the currently selected time span if it is valid and is a single point.
 * If not a single point, we default to the latest selected time point.
 */
export function defaultTimespanGrouping(
  cardState: InnerDataCardState
): DateRangeRaw | undefined {
  const primaryMeasureSelection = cardState.dataSelections[0].measureSelection;
  if (!defined(primaryMeasureSelection)) {
    return;
  }
  const timeSelection = cardState.timeSelection;
  if (!defined(timeSelection)) {
    return;
  }

  const [start, end] = timeSelection;
  if (start === end) {
    return [start, end];
  }

  return [end, end];
}

export function defaultTimespan(
  cardState: InnerDataCardState
): DateRangeRaw | undefined {
  const dataSelections: DataSelection[] = cardState.dataSelections;
  const groupingSelection: DataSelection | undefined =
    cardState.groupingSelection;

  const allAvailableDatesUnsorted = flatMap(
    dataSelections.map((d) => d.measureSelection?.availableDates)
  ).filter(defined);
  if (
    defined(groupingSelection) &&
    defined(groupingSelection.measureSelection)
  ) {
    allAvailableDatesUnsorted.push(
      ...groupingSelection.measureSelection.availableDates
    );
  }
  const allAvailableDates = chain(allAvailableDatesUnsorted)
    .sortedUniq()
    .value();
  if (allAvailableDates.length > 0) {
    const lastFive = allAvailableDates.slice(-5);
    return [lastFive[0], lastFive[lastFive.length - 1] ?? lastFive[0]];
  }

  // If no valid timespan for all measures can be found,
  // generate a valid timespan for the first measure only
  const primaryMeasure = dataSelections[0].measureSelection?.measure;
  if (!defined(primaryMeasure)) {
    return;
  }
  const timespan = dateRangeFromRawUtc(DEFAULT_TIME_SPAN_SELECTION_RAW);
  const resolution = TimeResolution.deserialize(primaryMeasure.resolution);
  return generateTimeRange(timespan, resolution);
}

function generateTimeRange(
  timespan: DateRange,
  resolution: TimeResolution,
  numPoints = 5
): DateRangeRaw {
  const timeRange = resolution.filledRangeInclusive(timespan[0], timespan[1]);
  const start = timeRange[Math.max(0, timeRange.length - numPoints)];
  const end = _.last(timeRange);
  if (start === undefined || end === undefined) {
    throw new Error("No timespan found!");
  }

  return dateRangeToRaw([start, end]);
}

export function cardMeasuresTimeCompatible(
  cardState: InnerDataCardState
): boolean {
  const dataSelections = cardState.dataSelections;
  const groupingSelection = cardState.groupingSelection;
  const dateRanges: DateRangeRaw[] = [
    ...dataSelections.map((d) => d.measureSelection?.availableDates),
    groupingSelection?.measureSelection?.availableDates,
  ]
    .filter(defined)
    .filter((d) => d.length > 0)
    .map((d) => [d[0], d[d.length - 1]]);
  return defined(calcCommonTimespan(dateRanges));
}

// Grouping selection does not affect the valid timespan
export function timespanValid(cardState: InnerDataCardState): boolean {
  const dataSelections = cardState.dataSelections;
  const timespan = cardState.timeSelection;
  const dateRanges: DateRangeRaw[] = [
    ...dataSelections.map((d) => d.measureSelection?.availableDates),
  ]
    .filter(defined)
    .filter((d) => d.length > 0)
    .map((d) => [d[0], d[d.length - 1]]);
  if (!defined(timespan) || dateRanges.length === 0) {
    return false;
  }

  const availableTimespan =
    dateRanges.length > 0 ? calcCommonTimespan(dateRanges) : undefined;
  if (!defined(availableTimespan)) {
    return false;
  }

  return dateRangeRawContains(availableTimespan, timespan);
}

function isGroupingMode(updatedCardChecked: InnerDataCardState) {
  return defined(updatedCardChecked.groupingSelection?.measureSelection);
}

/**
 * Heuristic to determine whether to keep fixed dimensions.
 * Why heuristic? Because we don't know in this context exactly which dimensions are present
 * in the dataset. Just because they're selected in the UI doesn't mean there is data for them.
 */
function shouldKeepFixedDimensions(
  previous: InnerDataCardState,
  updated: InnerDataCardState
): boolean {
  // 1. Primary selection change
  const primaryPrevious = previous.dataSelections[0];
  const primaryUpdated = updated.dataSelections[0];
  if (!shouldKeepFixedDimensionsSelection(primaryPrevious, primaryUpdated)) {
    return false;
  }

  // 2. Grouping selection change
  const groupingPrevious = previous.groupingSelection;
  const groupingUpdated = updated.groupingSelection;
  if (defined(groupingPrevious) !== defined(groupingUpdated)) {
    return false;
  }
  if (defined(groupingPrevious) && defined(groupingUpdated)) {
    if (
      !shouldKeepFixedDimensionsSelection(groupingPrevious, groupingUpdated)
    ) {
      return false;
    }
  }

  // 3. Time selection change
  const timePrevious = previous.timeSelection;
  const timeUpdated = updated.timeSelection;
  if (defined(timePrevious) !== defined(timeUpdated)) {
    return false;
  }

  // 3. Geo selection change
  const geoPrevious = previous.geoSelections;
  const geoUpdated = updated.geoSelections;
  if (defined(geoPrevious) !== defined(geoUpdated)) {
    return false;
  }
  if (defined(geoPrevious) && defined(geoUpdated)) {
    if (numSelectedGeos(geoPrevious) <= 1 && numSelectedGeos(geoUpdated) > 1) {
      return false;
    }
    if (numSelectedGeos(geoUpdated) <= 1 && numSelectedGeos(geoPrevious) > 1) {
      return false;
    }
  }

  return true;
}

function shouldKeepFixedDimensionsSelection(
  previous: DataSelection,
  updated: DataSelection
): boolean {
  if (
    defined(previous.measureSelection) !== defined(updated.measureSelection)
  ) {
    return false;
  }
  if (defined(previous.measureSelection) && defined(updated.measureSelection)) {
    if (
      previous.measureSelection.measure.data_id !==
      updated.measureSelection.measure.data_id
    ) {
      return false;
    }

    const prevBreakdowns = getVariableBreakdowns(previous.measureSelection);
    const updatedBreakdowns = getVariableBreakdowns(updated.measureSelection);
    if (
      intersection(prevBreakdowns, updatedBreakdowns).length !==
      prevBreakdowns.length
    ) {
      return false;
    }
  }

  return true;
}

function getVariableBreakdowns(measureSelection: MeasureSelection): string[] {
  const breakdowns = measureSelection.breakdowns;
  return Object.entries(breakdowns)
    .map(([key, values]) => {
      if (defined(values) && values.length > 1) {
        return key;
      }
    })
    .filter(defined);
}

function isMinorChange(
  prevCardState: InnerDataCardState,
  updatedCardUnchecked: InnerDataCardState
) {
  const prevPrimary = prevCardState.dataSelections[0];
  const updatedPrimary = updatedCardUnchecked.dataSelections[0];
  if (
    prevPrimary.measureSelection?.measure.data_id !==
    updatedPrimary.measureSelection?.measure.data_id
  ) {
    return false;
  }
  const prevGrouping = prevCardState.groupingSelection;
  const updatedGrouping = updatedCardUnchecked.groupingSelection;
  if (
    prevGrouping?.measureSelection?.measure.data_id !==
    updatedGrouping?.measureSelection?.measure.data_id
  ) {
    return false;
  }
  return true;
}
