/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ExcelJS from "exceljs";

import { defined } from "../../core/defined";
import { mimeTypeUtf8 } from "../../core/mime";
import { logger } from "../../infra/logging";
import { DataCellValue, displayNaNType } from "../stats/datasets/DataCellValue";
import { assertNever } from "@fluentui/react";
import { buildSourceTextParts } from "../stats/shared/texts";
import { borderStyleThin } from "./_shared_excel";
import {
  TableSpec,
  CellValueWithOutputSettings,
} from "../stats/datasets/table/table_base/table_base_definitions";

function sanitizeExcelSheetName(name: string): string {
  return name.replace(/[^a-zåäöA-ZÅÄÖ0-9-\s]/g, "");
}

export async function tableToExcel(
  spec: TableSpec,
  filename: string,
  options?: { formatRowLabels?: (rowLabel: string) => string }
) {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = "Infostat";
  const currentDate = new Date();
  workbook.created = currentDate;

  const description = spec.tableDescription;
  const nameRaw = "Infostat - " + description.header;
  const sheet = workbook.addWorksheet(sanitizeExcelSheetName(nameRaw));

  sheet.addRow([description.header]);
  const excelExportSubheader = description.excelExportSubheader;
  if (defined(excelExportSubheader)) {
    sheet.addRow([excelExportSubheader]);
    sheet.addRow([]);
  }
  sheet.getCell(sheet.lastRow!.number, 1).font = { bold: true, size: 12 };
  description.primaryMeasureSubheaders.forEach((header) => {
    sheet.addRow([header]);
  });
  if (defined(description.groupingHeader)) {
    sheet.addRow([description.groupingHeader]);
  }
  if (defined(description.regionAndTimeHeader)) {
    sheet.addRow([description.regionAndTimeHeader]);
  }
  for (const subquestion of description.single_subquestions) {
    sheet.addRow([]);
    sheet.addRow([subquestion]);
    sheet.getCell(sheet.lastRow!.number, 1).font = { bold: true };
  }
  if (defined(description.surveyResponseSingleChoice)) {
    sheet.addRow([description.surveyResponseSingleChoice]);
  }

  if (defined(description.unitHeader)) {
    sheet.addRow([]);
    sheet.addRow([description.unitHeader]);
    sheet.addRow([]);
  }

  // Super column headers
  for (const superColumnRow of spec.header.superColumnRows) {
    let cellCount = 1; // Cell numbering starts at 1
    const rowColumns: string[] = [];
    const colSpecs: { text: string; start: number; end: number }[] = [];
    for (let i = 0; i < superColumnRow.columns.length; i++) {
      const col = superColumnRow.columns[i];
      const offset = 1; // The left-most header column is empty
      colSpecs.push({
        text: col.text,
        start: cellCount + offset,
        end: cellCount + offset + col.colSpan - 1,
      });
      for (let i = 0; i < col.colSpan; i++) {
        rowColumns.push(col.text);
      }

      cellCount += col.colSpan;
    }

    sheet.addRow([""].concat(rowColumns));
  }

  // Bottom column headers
  sheet.addRow(
    [spec.primaryDimensionInfo?.header ?? ""].concat(
      spec.header.columnRow.columns.map((c) => c.text)
    )
  );

  // Add borders
  const columnsRow = sheet.lastRow?.number;
  for (let i = 1; i < spec.header.columnRow.columns.length + 2; i++) {
    sheet.getCell(columnsRow ?? 1, i).border = {
      bottom: borderStyleThin,
    };
  }

  const processRowLabel = options?.formatRowLabels ?? ((label) => label);

  // Add actual table values
  for (const row of spec.rows) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values: any[] = row.values.map<string | number>(renderRowValue);
    sheet.addRow([processRowLabel(row.label)].concat(values));
  }

  const addBottomBorder = () => {
    const lastValueRow = sheet.lastRow?.number;
    if (!defined(lastValueRow)) {
      throw new Error("lastValueRow undefined");
    }
    for (let i = 1; i < spec.header.columnRow.columns.length + 2; i++) {
      sheet.getCell(lastValueRow, i).border = {
        bottom: borderStyleThin,
      };
    }
  };

  const summaryRows = spec.summaryRows ?? [];
  if (summaryRows.length > 0) {
    addBottomBorder();
  }
  for (const row of summaryRows) {
    const values: any[] = row.values.map<string | number>((v) =>
      v.match({
        nan: displayNaNType,
        ok: (value) => row.roundForExport?.(value) ?? value,
      })
    );
    sheet.addRow([row.label].concat(values));
  }
  const refRows = spec.referenceRows ?? [];
  if (refRows.length > 0) {
    addBottomBorder();
  }
  for (const row of refRows) {
    const values: any[] = row.values.map<string | number>((v) =>
      v.match({
        nan: displayNaNType,
        ok: (value) => row.roundForExport?.(value) ?? value,
      })
    );
    sheet.addRow([row.label].concat(values));
  }

  // Add source text
  sheet.addRow([]);
  const { primary, secondary, multipleSources } = buildSourceTextParts(
    {
      source: spec.sourceInfo.source,
      externalSource: spec.sourceInfo.externalSource,
    },
    defined(spec.groupingSourceInfo) && defined(spec.groupingSourceInfo.source)
      ? {
          source: spec.groupingSourceInfo.source,
          externalSource: spec.groupingSourceInfo.externalSource,
        }
      : undefined
  );
  const sourcesLabel = multipleSources ? "Källor" : "Källa";

  sheet.addRow([
    defined(secondary)
      ? `${sourcesLabel}: ${primary}; ${secondary}`
      : `${sourcesLabel}: ${primary}`,
  ]);

  const sourceRowNumber = sheet.lastRow?.number;
  if (!defined(sourceRowNumber)) {
    throw new Error("No sourceRowNumber");
  }
  sheet.addRow(["Framställt av Infostat"]);

  const buffer = await workbook.xlsx.writeBuffer();

  const blob = new Blob([buffer], {
    type: mimeTypeUtf8("xlsx"),
  });
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Clean up
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);

  function renderRowValue(
    rawValue: DataCellValue<CellValueWithOutputSettings>,
    colIndex: number
  ): string | number {
    const round = spec.header.columnRow.columns[colIndex].round;
    try {
      switch (spec.valueType) {
        case "decimal":
          return rawValue.match({
            ok: (ok) => {
              if (ok.roundForExport) {
                return ok.roundForExport(ok.value);
              }
              const parsed = parseFloat(ok.value);
              return defined(round) ? round(ok.value) : parsed;
            },
            nan: displayNaNType,
          });
        case "integer":
          return rawValue.match({
            ok: (ok) => {
              // Can still be computed value
              if (ok.roundForExport) {
                return ok.roundForExport(ok.value);
              }
              return parseInt(ok.value);
            },
            nan: displayNaNType,
          });
        case "category":
          return rawValue.match({ ok: (v) => v.value, nan: displayNaNType });
        case "survey":
          return rawValue.match({
            ok: (v) => {
              if (defined(v.roundForExport)) {
                return v.roundForExport(v.value);
              }

              if (defined(round)) {
                return round(v.value);
              }

              return v.value;
            },
            nan: displayNaNType,
          });
        case "survey_string":
          return rawValue.match({
            ok: (value) => value.value,
            nan: displayNaNType,
          });
        default:
          assertNever(spec.valueType);
      }
    } catch (e) {
      logger.error("Failed to parse row value for export", e);
    }
    return "";
  }
}
