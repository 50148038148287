import { String as StringRT, Record } from "runtypes";

import { Result } from "../../../../core/Result";
import { ColorSchemeContainer } from "../document-style/definitions";
import { InnerDataCardState } from "../document-core/core";
import {
  DefaultSettingsWrapper,
  LatestSettingsFormat,
  Version,
  VersionParseError,
} from "./common";
import { DefaultSettingsV4RT } from "./v4/types";
import { statsApiV2, StatsApiV2 } from "../../../requests/statsApiV2";
import { encodeSettingsV4, parseSettingsV4 } from "./v4/spec";

const LATEST_VERSION: Version = "v4";
export const DefaultSettingsLatestRT = DefaultSettingsV4RT;

const DefaultSettingsAnyRT = Record({
  version: StringRT,
});

export async function migrate(
  measureId: number,
  settingsAnyVersion: DefaultSettingsWrapper,
  apiV2: StatsApiV2
): Promise<Result<LatestSettingsFormat, VersionParseError>> {
  let res: DefaultSettingsWrapper = settingsAnyVersion;
  while (res.version !== LATEST_VERSION) {
    switch (res.version) {
      case "v1":
      case "v2":
      case "v3":
        throw new Error(
          "Invalid default settings version " +
            res.version +
            ". Versions 1-3 are not supported anymore."
        );
      default:
        throw new Error("Unknown version: " + res.version);
    }
  }
  return Result.fromOk(res) as any;
}

export async function parseAndFillSettings(
  measureId: number,
  json: unknown
): Promise<Result<LatestSettingsFormat, VersionParseError>> {
  const settings = DefaultSettingsAnyRT.check(json);
  const latestFormat = await migrate(measureId, settings, statsApiV2);
  return latestFormat.match({
    err: (err) => Result.fromErr(err),
    ok: (d) => parseSettingsV4(d),
  });
}

export function encodeLatestSettings(
  card: InnerDataCardState,
  colorSchemeContainer: ColorSchemeContainer
): LatestSettingsFormat {
  return encodeSettingsV4({
    colorSchemeContainer,
    innerCardState: card,
  });
}
