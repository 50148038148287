import React from "react";
import { classNames } from "../lib/core/classNames";
import "./Card.scss";
import { defined } from "../lib/core/defined";

interface Props {
  children: JSX.Element[] | JSX.Element;
  id?: string;
  cardRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  useStdPadding?: boolean;
}

export function Card(props: Props) {
  const cardRef = props.cardRef;
  return (
    <div
      ref={cardRef}
      id={props.id}
      className={classNames(
        "Card",
        props.className,
        props.useStdPadding ? "std-padding" : ""
      )}
    >
      {props.children}
    </div>
  );
}

/**
 * Use like this:
 * <TabbedCard>
 *   <TabbedCardTabsContainer>
 *     <TabbedCardTab>
 *   <TabbedCardMain>
 * ...
 *
 * Card top structure
 *
 *                  Tab column
 * ____________________________________________
 * |  invisible   | tab content |   invisible  |
 * |--------------|-------------|--------------|
 * | left-of-tab  | empty-block | right-of-tab |
 *
 */
export function TabbedCard(
  props: Props & { hideTab: boolean; children: JSX.Element[] }
) {
  const cardRef = props.cardRef;
  return (
    <div
      ref={cardRef}
      id={props.id}
      className={classNames(
        "CardWithTab",
        props.hideTab ? "hide-tab" : "",
        props.className,
        props.useStdPadding ? "std-padding" : "",
        props.className
      )}
    >
      {props.children}
    </div>
  );
}

export function TabbedCardTabsContainer(props: {
  currentTab: string;
  children: JSX.Element[] | JSX.Element;
}) {
  return (
    <div className="header-space">
      <div className="left-of-tab"></div>
      {(Array.isArray(props.children) ? props.children : [props.children]).map(
        (r, i) =>
          React.cloneElement(r, {
            selected: props.currentTab === r.props.name,
            key: i, // Using index as key is ok here because tabs appear in the same order, what varies is whether the last tab is there or not
          })
      )}
      <div className="right-of-tab"></div>
    </div>
  );
}

export function TabbedCardsContentContainer(props: {
  currentTab: string;
  children: JSX.Element[];
}) {
  return <>{props.children.filter((c) => c.props.name === props.currentTab)}</>;
}

export function TabbedCardTab(props: {
  onSelect: (name: string) => void;
  bgColor?: string;
  containerKey?: string;
  selected?: boolean;
  name: string;
  children: JSX.Element;
}) {
  const selected = props.selected;
  return (
    <div
      onClick={() => props.onSelect(props.name)}
      key={props.containerKey}
      className={classNames(selected ? "selected" : "", "tab-column")}
      style={defined(props.bgColor) ? { backgroundColor: props.bgColor } : {}}
    >
      <div className="tab">{props.children}</div>
      <div className="empty-block"></div>
    </div>
  );
}

export function TabbedCardMain(props: {
  name: string;
  bgColor?: string;
  customPaddingTop?: number;
  customPaddingRight?: number;
  customPaddingBottom?: number;
  customPaddingLeft?: number;
  children: JSX.Element[] | JSX.Element;
}) {
  const p: React.CSSProperties = {};
  if (defined(props.bgColor)) {
    p.backgroundColor = props.bgColor;
  }
  if (defined(props.customPaddingTop)) {
    p.paddingTop = props.customPaddingTop;
  }
  if (defined(props.customPaddingRight)) {
    p.paddingRight = props.customPaddingRight;
  }
  if (defined(props.customPaddingLeft)) {
    p.paddingLeft = props.customPaddingLeft;
  }
  if (defined(props.customPaddingBottom)) {
    p.paddingBottom = props.customPaddingBottom;
  }

  return (
    <div className="card-main" style={p}>
      {props.children}
    </div>
  );
}
