/* eslint-disable no-console */
import Bugsnag from "@bugsnag/js";
import { IS_PROD } from "../../config";

class Logger {
  constructor(private _remoteLog?: (messages: unknown[]) => void) {}

  debug(...message: any[]) {
    if (!IS_PROD) {
      console.log("[debug] ", ...message);
    }
  }
  info(...message: any[]) {
    console.log("[info]: ", ...message);
  }
  warn(...message: any[]) {
    console.warn("[warn]: ", ...message);
  }
  error(...message: any[]) {
    console.error("[error]: ", ...message);
    try {
      this._remoteLog?.(message);
    } catch (e) {
      console.error("Failed to call remoteLog.", e);
    }
  }
}

export const logger = new Logger((messages: unknown[]) => {
  for (const message of messages) {
    if (message instanceof Error) {
      Bugsnag.notify(message);
    } else if (typeof message === "string") {
      Bugsnag.notify(message);
    } else if (typeof message === "object") {
      Bugsnag.notify({
        name: "Unknown error object",
        message: JSON.stringify(message),
      });
    }
  }
});

export const consoleLogger = new Logger();
