import { defined } from "../../../../core/defined";
import { measureSelectionIsRegular } from "../../../../domain/measure";
import { DocCardState } from "./core";

/**
 * Get all non-template dataset IDs
 */
export function getNonMemberOrgMeasureDatasetIds(cards: DocCardState[]): {
  statsIds: number[];
  microIds: number[];
} {
  const statsIds: number[] = [];
  const microIds: number[] = [];

  for (const card of cards) {
    switch (card.type) {
      case "pythonCard":
      case "error":
      case "microCardImage":
      case "textCardCK":
      case "textCardSimple":
        break;
      case "microCard":
        for (const m of card.data.dataSelections ?? []) {
          const dataId = m.measure?.id;
          if (defined(dataId)) {
            microIds.push(dataId);
          }
        }
        for (const m of card.data.filterMeasures) {
          const id = m.measureSelection?.measure.id;
          if (defined(id)) {
            microIds.push(id);
          }
        }
        break;
      case "dataCard":
        for (const s of card.data.dataSelections) {
          // Filter out data selections for member org measurements.
          if (
            defined(s.measureSelection) &&
            measureSelectionIsRegular(s.measureSelection) &&
            s.measureSelection.measure.is_member_org_measurement_parent
          ) {
            continue;
          }

          const dataId = s.measureSelection?.measure.data_id;
          if (defined(dataId)) {
            statsIds.push(dataId);
          }
        }
        const groupingId =
          card.data.groupingSelection?.measureSelection?.measure.data_id;
        if (defined(groupingId)) {
          statsIds.push(groupingId);
        }
        break;
    }
  }

  return { statsIds: statsIds, microIds: microIds };
}
