import { IControl } from "mapbox-gl";
import { useCallback, useLayoutEffect, useState } from "react";
import { defined } from "../../../../../lib/core/defined";

/**
 * Automatically add a control, and provide handlers for manually adding/removing it.
 *
 * When calling remove with true as the first argument, the control will not be added again
 * until manually added again.
 */
export function useAddRemoveControl<C extends IControl>(
  map: mapboxgl.Map | undefined,
  makeControl: () => C,
  position:
    | "top-right"
    | "top-left"
    | "bottom-right"
    | "bottom-left"
    | undefined
) {
  const [control, setControl] = useState<C>();
  const [enableAutoRecreate, setEnableAutoRecreate] = useState(true);

  const remove = useCallback(
    (autoRecreate: boolean) => {
      if (!defined(map) || !defined(control)) {
        return;
      }
      const oldControl = control;
      if (map.hasControl(control)) {
        map.removeControl(control);
      }
      setControl(undefined);
      setEnableAutoRecreate(autoRecreate);
      return oldControl;
    },
    [control, map, setControl]
  );

  const add = useCallback(
    (providedControl?: C) => {
      if (!defined(map)) {
        return;
      }
      const newControl = providedControl ?? makeControl();
      map.addControl(newControl, position);
      setControl(newControl);
      setEnableAutoRecreate(true);
      return newControl;
    },
    [makeControl, map, position]
  );

  useLayoutEffect(() => {
    if (!defined(map) || !enableAutoRecreate || defined(control)) {
      return;
    }

    add();
    return () => {
      remove(enableAutoRecreate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, enableAutoRecreate]);
  return {
    add,
    remove,
    control: defined(map) && defined(control) ? control : undefined,
  };
}
