import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSetRecoilState } from "recoil";
import { singleMicroCardQuery } from "../../stats/document-core/queries/microCard";

export function useAddMeasureFilter(cardId: string) {
  const setCard = useSetRecoilState(
    singleMicroCardQuery({ cardStateId: cardId })
  );

  const addMeasureFilter = useCallback(() => {
    setCard((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          filterMeasures: [
            ...prev.data.filterMeasures,
            {
              id: uuidv4(),
              subjectPath: [],
            },
          ],
        },
      };
    });
  }, [setCard]);

  return addMeasureFilter;
}
