import { sortBy } from "lodash";

import { GEONAME_SWEDEN } from "../../../domain/geography";
import { measureSelectionIsSurvey } from "../../../domain/measure";
import {
  DateRangeRaw,
  SelectedDimensionsV2,
  SortSpec,
  ValuesSortMode,
} from "../../../domain/measure/definitions";
import {
  MeasureSelection,
  MeasureSelectionRegular,
} from "../../../domain/selections/definitions";
import { Dimension } from "../shared/core/definitions";

/**
 * Create a unique key for this dataset.
 */
export function makeDatasetKey(
  primaryMeasureSelection: MeasureSelection,
  groupingSelection: MeasureSelectionRegular | undefined,
  actualTimespan: DateRangeRaw,
  includedGeoTypes: ("country" | "nuts1" | "nuts2" | "nuts3" | "municipal")[],
  selectedGeocodes: string[]
) {
  if (!measureSelectionIsSurvey(primaryMeasureSelection)) {
    return (
      selectionKeyV2(primaryMeasureSelection.breakdowns) +
      "_" +
      selectionKeyV2(groupingSelection?.breakdowns ?? {}) +
      "_" +
      actualTimespan.join(",") +
      "_" +
      includedGeoTypes.join(", ") +
      "_" +
      selectedGeocodes.join(", ")
    );
  }

  return (
    primaryMeasureSelection.measure.data_id +
    selectionKeyV2(primaryMeasureSelection.breakdowns) +
    selectionKeyV2(groupingSelection?.breakdowns ?? {}) +
    actualTimespan.join(",") +
    includedGeoTypes.join(",") +
    "," +
    selectedGeocodes.join("_")
  );
}

function selectionKeyV2(selectionDict: SelectedDimensionsV2) {
  return Object.entries(selectionDict)
    .map(([key, value]) => `${key}=${value?.join(",") ?? "_"}`)
    .join(";");
}

/**
 * Determines whether to display a single selected geographical region in chart/table headers.
 */
export function displaySingleRegion<LiftedValueType>(
  singleSelectedGeoLabel: string | undefined,
  lifted: { [dimension: string]: LiftedValueType } | undefined
): string | undefined {
  const singleUsedRegionUnchecked =
    singleSelectedGeoLabel ?? lifted?.[Dimension.region];
  const singleUsedRegion =
    typeof singleUsedRegionUnchecked === "string"
      ? singleUsedRegionUnchecked
      : undefined;
  return singleUsedRegion === GEONAME_SWEDEN ? undefined : singleUsedRegion;
}

export function dimensionsToSortSpecs<
  D extends {
    data_column: string;
    values:
      | null
      | { sort_mode?: ValuesSortMode; sort_order: number; label: string }[];
  }
>(dimensions: D[]): Record<string, SortSpec> {
  const sortSpecs: Record<string, SortSpec> = {};
  for (const d of dimensions ?? []) {
    sortSpecs[d.data_column] = {
      orderTop: sortBy(
        d.values?.filter((v) => v.sort_mode === "fixed_top"),
        (item) => item.sort_order
      ).map((item) => item.label),
      orderBottom: sortBy(
        d.values?.filter((v) => v.sort_mode === "fixed_bottom"),
        (item) => item.sort_order
      ).map((item) => item.label),
    };
  }
  return sortSpecs;
}
