import { NewMetadataInput } from "../../../../../lib/domain-admin/metadata";
import {
  AggregationMethod,
  DataValueTypeRegular,
} from "../../../../../lib/infra/api_responses/dataset";
import { MetadataInputField, resolutionOptions } from "../../shared/base";

const dataValueOptions: DataValueTypeRegular[] = [
  "integer",
  "decimal",
  "category",
];
const aggMethodOptions: AggregationMethod[] = [
  "none",
  "sum",
  "avg",
  "avg-weighted",
];

export const statsEntriesV2: MetadataInputField<keyof NewMetadataInput>[] = [
  { key: "label", type: "input" },
  { key: "value_type", type: "select", options: dataValueOptions },
  { key: "measure", type: "input" },
  {
    key: "agg_method_geo",
    label: "Geografisk aggregeringsmetod",
    type: "select",
    options: aggMethodOptions,
  },
  {
    key: "resolution",
    label: "Tidsupplösning",
    type: "select",
    options: resolutionOptions,
  },
  { key: "last_update", type: "date", optional: true },
  { key: "next_update", type: "date", optional: true },
  { key: "descr_long", type: "input" },
  { key: "unit_label", type: "input" },
  { key: "ext_label", type: "input", optional: true },
  { key: "ext_descr", type: "textarea", optional: true },
  { key: "ext_descr_long", type: "textarea", optional: true },
  { key: "public_comment", type: "textarea", optional: true },
  { key: "source", type: "input", placeholder: "SCB" },
  { key: "source_url", type: "input", optional: true },
  { key: "ext_source", type: "textarea", optional: true },
  {
    key: "breakdowns",
    type: "breakdowns",
    optional: true,
    label: { label: "Breakdownetikett", placeholder: "ex. Kön" },
    csvColumn: { label: "CSV-kolumn", placeholder: "ex. breakdown1" },
  },
];
