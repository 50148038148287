import { useRecoilValue } from "recoil";

import { useAddMeasureFilter } from "../../../../../lib/application/state/actions/micro/useAddMeasureFilter";
import { microSelectionPrimary } from "../../../../../lib/application/state/stats/document-core/core-micro";
import { defined } from "../../../../../lib/core/defined";
import { ToolbarMenu } from "../card_general/TabToolbar";
import { cardQuery } from "../../../../../lib/application/state/stats/document-core/queries/card";

export function useFilterMenu(
  cardId: string,
  handleReplaceSelectionsWithResults: () => void,
  handleRemoveAllFilters: () => void
) {
  const card = useRecoilValue(cardQuery(cardId));

  const handleAddMeasureFilter = useAddMeasureFilter(cardId);

  const canUseFilters =
    card.type === "microCard" &&
    defined(card.data.dataSelections) &&
    card.data.dataSelections.some(
      (d) =>
        d.type === "primary" &&
        (!d.multiSelectEnabled || defined(d.measure?.computed))
    );
  if (!canUseFilters) {
    return;
  }

  const menu: ToolbarMenu = {
    type: "menu",
    icon: "filter",
    label: "Filter",
    items: [
      {
        label: "Lägg till måttfilter",
        type: "click",
        onClick: handleAddMeasureFilter,
      },
      {
        label: "Ta bort alla",
        disabled:
          card.data.filterMeasures.length === 0 &&
          !defined(microSelectionPrimary(card)?.filterSet),
        type: "click",
        onClick: handleRemoveAllFilters,
      },
      {
        label: "Gör filtrerade områden till valda områden",
        type: "click",
        onClick: handleReplaceSelectionsWithResults,
      },
    ],
  };
  return menu;
}
