import { useRecoilState } from "recoil";
import { useCallback } from "react";

import { singleMicroCardQuery } from "../../../../../lib/application/state/stats/document-core/queries/microCard";
import {
  GeometryType,
  GroupStyleGeoMicro,
} from "../../../../../lib/application/state/stats/document-style/definitions";
import { MicroMapState } from "../../../../../lib/application/state/stats/document-core/_core-shared";
import { DrawableGroupV2 } from "../../../../../lib/application/stats/datasets/MicroDatasetGeo";
import { useSaveCard } from "../../../../../lib/application/state/actions/useSaveDocument";

export function useApplyGeoStyle(cardId: string) {
  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: cardId })
  );

  const saveCard = useSaveCard();

  return useCallback(
    (
      geometry: GeometryType,
      dataGroupId: string,
      style: GroupStyleGeoMicro
    ) => {
      const microMapState: MicroMapState = {
        ...card.data.loadedData?.microMapState,
      };

      const replace = (group: DrawableGroupV2) => {
        if (group.id === dataGroupId) {
          return { ...group, style };
        }
        return group;
      };

      switch (geometry) {
        case "point":
          microMapState.loadedPoints = microMapState.loadedPoints?.map(replace);
          break;
        case "polygon":
          microMapState.loadedPolygons =
            microMapState.loadedPolygons?.map(replace);
          break;
        case "line":
          microMapState.loadedLines = microMapState.loadedLines?.map(replace);
          break;
      }

      const updatedCard = {
        ...card,
        data: {
          ...card.data,
          loadedData: {
            ...card.data.loadedData,
            microMapState,
          },
        },
      };
      saveCard?.(updatedCard);
      setCard(updatedCard);
    },
    [card, saveCard, setCard]
  );
}
