import { chain } from "lodash";
import {
  Array as ArrayRT,
  String as StringRT,
  Number as NumberRT,
} from "runtypes";

import { DimensionsSpec } from "./headers";
import { Dimension } from "../shared/core/definitions";
import {
  MICRO_DIMENSION_GEOCODES,
  MICRO_DIMENSION_Z_VALUE,
} from "../shared/dimensions";
import { defined } from "../../../core/defined";
import { MicroDatasetDto } from "../../../infra/api_responses/micro_dataset";
import {
  MicroResultsType,
  RegsoResultData,
  DesoResultData,
} from "../../../domain/micro/definitions";
import { DesoRegsoLookup } from "../../../domain/micro/desoLookup";
import { DimensionAndStringValue } from "./MicroDatasetGeo";
import { integerFormatter, numSignificantFiguresRounder } from "../format";
import { PrimaryMeasureSelectionMicroFull } from "../../../domain/measure/definitions";
import { getDateFormatter, TimeResolution } from "../../../domain/time";
import { utcTimeStringToDate } from "../../../core/time";
import { isBreakdownTotal } from "../../../domain/measure";
import { computedMeasureSubDescription } from "../shared/DataDescription";
import { MicroGeoTree } from "../shared/MicroGeoTree";

/**
 * Container for results, for display next to map
 */
export class MicroMapResults {
  private _numSelectedAreas: number | undefined;
  private _forcedDimensionValues: DimensionAndStringValue[];
  private _referenceValues: ReferenceValue[];
  private _comparisonType: MicroResultsType;
  private _desoResultData: DesoResultData[] | undefined;
  private _regsoResultData: RegsoResultData[] | undefined;
  _formatterSingleValue: (n: number) => string;
  private _significantValuesRounder: (n: number) => string;

  constructor(
    private _datasetDto: MicroDatasetDto,
    private _dimensionsSpec: DimensionsSpec,
    comparisonType: MicroResultsType,
    private _measureSelection: PrimaryMeasureSelectionMicroFull,
    desoRegsoLookup: DesoRegsoLookup,
    microGeoTree: MicroGeoTree | undefined
  ) {
    // If using multi-breakdown selection mode, we need to ensure only one
    // value per breakdown is used when preparing data for the map,
    // since only one value per region can be displayed.
    const forcedDimensionValues = _dimensionsSpec.variable
      .map((dimension) => {
        if (dimension === Dimension.value) {
          return;
        }

        const values = chain(_datasetDto.rows)
          .groupBy((r) => r[dimension])
          .keys()
          .value();
        if (values.length < 2) {
          return;
        }
        return { dimension, value: values[0] };
      })
      .filter(defined);

    const rows = _datasetDto.rows.filter((r) => {
      // Filter out user defined rows
      if (r[Dimension.userDefined] === true) {
        return false;
      }
      return forcedDimensionValues.every(
        (dimValue) => r[dimValue.dimension] === dimValue.value
      );
    });

    if (comparisonType === "compare-groups") {
      // TODO: support groups
    } else if (comparisonType === "compare-units-deso") {
      const desoData: DesoResultData[] = rows.map((item) => {
        const desos = ArrayRT(StringRT).check(item[MICRO_DIMENSION_GEOCODES]);
        if (desos.length !== 1) {
          throw new Error("Expected exactly one deso");
        }
        const deso = desos[0];
        const desoProps = desoRegsoLookup.lookupDeso(deso);
        return {
          zValue: NumberRT.check(item[MICRO_DIMENSION_Z_VALUE]),
          deso,
          desoLabel: desoProps.deso_label,
          id: desoProps.id,
          value: NumberRT.check(item[Dimension.value]),
        };
      });
      this._desoResultData = desoData;
    } else if (comparisonType === "compare-units-regso") {
      const regsoData: RegsoResultData[] = rows.map((item) => {
        const regsos = ArrayRT(StringRT).check(item[MICRO_DIMENSION_GEOCODES]);
        if (regsos.length !== 1) {
          throw new Error("Expected exactly one deso");
        }
        const regso = regsos[0];
        const regsoProps = desoRegsoLookup.lookupRegso(regso);
        return {
          zValue: NumberRT.check(item[MICRO_DIMENSION_Z_VALUE]),
          regso,
          regsoLabel: regsoProps.regso_label,
          id: regsoProps.id,
          value: NumberRT.check(item[Dimension.value]),
        };
      });
      this._regsoResultData = regsoData;
    }

    // Handle reference values

    const matchingRefRows =
      forcedDimensionValues.length > 0
        ? _datasetDto.reference_rows?.filter((r) => {
            return forcedDimensionValues.every(
              (dimValue) => r[dimValue.dimension] === dimValue.value
            );
          }) ?? []
        : _datasetDto.reference_rows ?? [];

    const referenceValues: ReferenceValue[] = [];
    const sfRounder = (n: number) =>
      n === 0 ? "0" : numSignificantFiguresRounder(3)(n);
    const formatterSingleValue =
      _datasetDto.header.value_type === "integer"
        ? (n: number) => integerFormatter(n?.toString())
        : sfRounder;
    const refRow = matchingRefRows[0];
    if (defined(refRow)) {
      const singleArea = rows.length === 1 ? rowToGeocode(rows[0]) : undefined;
      const singleAreaLabel = defined(singleArea)
        ? lookupLabel(singleArea, desoRegsoLookup)
        : undefined;

      const specs: Array<[string, string, (n: number) => string]> = [
        [
          "value_selected_areas_average",
          `I ${defined(singleAreaLabel) ? singleAreaLabel : "valda områden"}`,
          defined(singleAreaLabel) &&
          _measureSelection.measure.valueType === "integer"
            ? (n: number) => integerFormatter(n?.toString())
            : sfRounder,
        ],
      ];

      if (_measureSelection.measure.aggMethodGeo !== "sum") {
        // Get arbitrary deso value to use for lookuup
        const arbitraryGeocode = rowToGeocode(rows[0]);

        specs.push(
          ["value_country", "I riket", formatterSingleValue],
          [
            "value_region",
            `I ${
              defined(microGeoTree) && defined(arbitraryGeocode)
                ? microGeoTree.desoRegsoToRegion(arbitraryGeocode)
                : "regionen"
            }`,
            formatterSingleValue,
          ],
          [
            "value_municipality",
            `I ${
              defined(microGeoTree) && defined(arbitraryGeocode)
                ? microGeoTree.desoRegsoToMunicipality(arbitraryGeocode)
                : "kommunen"
            }`,
            formatterSingleValue,
          ]
        );
      }
      for (const [property, label, rounder] of specs) {
        const value = refRow[property];
        if (defined(value) && typeof value === "number") {
          referenceValues.push({
            label,
            value: rounder(value),
          });
        }
      }
    }

    this._numSelectedAreas = rows.length;
    this._forcedDimensionValues = forcedDimensionValues;
    this._referenceValues = referenceValues;
    this._comparisonType = comparisonType;
    this._significantValuesRounder = sfRounder;
    this._formatterSingleValue = formatterSingleValue;
  }

  get forcedDimensionValues(): readonly DimensionAndStringValue[] {
    return this._forcedDimensionValues;
  }

  get resultsType(): MicroResultsType {
    return this._comparisonType;
  }

  get desoResults(): DesoResultData[] | undefined {
    return this._desoResultData;
  }

  get regsoResults(): RegsoResultData[] | undefined {
    return this._regsoResultData;
  }

  title(): string {
    return this._datasetDto.header.descr_long;
  }

  get subtitle(): string | undefined {
    const computedType = this._measureSelection.measure.computed?.type;
    if (defined(computedType)) {
      return computedMeasureSubDescription(
        this._measureSelection.measure,
        this._measureSelection.computedMeasureVariablesConfig
      );
    }

    if (this._forcedDimensionValues.length > 0) {
      return formatValues(this._forcedDimensionValues, this._measureSelection);
    }

    const dimValues = Object.entries(this._datasetDto.header.lifted ?? {}).map(
      ([key, value]) => ({ dimension: key, value } as DimensionAndStringValue)
    );
    return formatValues(dimValues, this._measureSelection);
  }

  unitLabel(): string {
    return this._datasetDto.header.unit_label;
  }

  get referenceValues(): ReferenceValue[] {
    return this._referenceValues;
  }
}

interface ReferenceValue {
  label: string;
  value: string;
}

function formatValues(
  dimValues: DimensionAndStringValue[],
  measureSelection: PrimaryMeasureSelectionMicroFull
): string {
  const time = TimeResolution.deserialize(
    measureSelection.measure.timeResolution
  );
  const dimensions = measureSelection.measure.dimensions;
  const dateFormatter = getDateFormatter(time);

  function getDimLabel(breakdown: string) {
    return dimensions?.find((dim) => dim.data_column === breakdown)?.label;
  }

  let values: string[] = dimValues
    .map((d) => {
      const key = d.dimension;
      const value = d.value;
      if (key === Dimension.date || isBreakdownTotal(value)) {
        return;
      }
      return `${getDimLabel(key) ?? "--"}: ${value}`;
    })
    .filter(defined);
  const dateValue = dimValues.find(
    (d) => d.dimension === Dimension.date
  )?.value;
  if (defined(dateValue)) {
    values.push(dateFormatter(utcTimeStringToDate(dateValue)));
  }

  return values.join(" | ");
}

function rowToGeocode(row: { [key: string]: unknown }): string | undefined {
  const geocodes = row[MICRO_DIMENSION_GEOCODES];
  const unchecked = Array.isArray(geocodes) ? geocodes[0] : undefined;
  const geocodeChecked = typeof unchecked === "string" ? unchecked : undefined;
  return geocodeChecked;
}

function lookupLabel(
  areaCode: string,
  desoRegsoLookup: DesoRegsoLookup
): string | undefined {
  return (
    desoRegsoLookup.lookupDeso(areaCode)?.deso_label ??
    desoRegsoLookup.lookupRegso(areaCode)?.regso_label
  );
}
