import { useCallback, useContext } from "react";
import { useSetRecoilState } from "recoil";

import { DocCardPython } from "../../../stats/document-core/core";
import { cardQuery } from "../../../stats/document-core/queries/card";
import { CardUpdateCountContext } from "../../../../contexts";
import { useSaveCard } from "../../useSaveDocument";

/**
 * Fetch data for a given card and store in card state.
 */
export function useHandleCardChangePython(cardId: string) {
  const setCard = useSetRecoilState(cardQuery(cardId));
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const handleSaveCard = useSaveCard();

  const handleUpdate = useCallback(
    async (card: DocCardPython) => {
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;
      setCard(card);
      handleSaveCard?.(card);
      return card;
    },
    [getCurrentCount, handleSaveCard, incrementUpdateCount, setCard]
  );

  return handleUpdate;
}
