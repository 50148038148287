import { EditorConfig } from "@ckeditor/ckeditor5-core";
import Editor from "ckeditor5-custom-build/build/ckeditor";

export const ckEditorConfig: EditorConfig = {
  fontSize: {
    options: [10, 12, "default", 18, 24, 30, 48, 60],
  },
  fontFamily: {
    options: [
      "default",
      // "Selawik, sans-serif", -- default
      "Circular, Circular-Regular, sans-serif",
      "Roboto, sans-serif",
      "Caladea, serif",
      "Carlito, sans-serif",
      "Arial, Helvetica, sans-serif",
      "Courier New, Courier, monospace",
      "Georgia, serif",
      "Lucida Sans Unicode, Lucida Grande, sans-serif",
      "Tahoma, Geneva, sans-serif",
      "Times New Roman, Times, serif",
      "Trebuchet MS, Helvetica, sans-serif",
      "Verdana, Geneva, sans-serif",
    ],
  },
  heading: {
    options: [
      {
        model: "heading1",
        title: "Titel",
        class: "ck-heading_custom_title",
        view: { name: "h1", classes: "ck-heading_custom_title" },
      },
      {
        model: "heading2",
        title: "Rubrik 1",
        class: "ck-heading_heading1",
        view: "h1",
      },
      {
        model: "heading3",
        title: "Rubrik 2",
        class: "ck-heading_heading2",
        view: "h2",
      },
      {
        model: "heading4",
        title: "Rubrik 3",
        class: "ck-heading_heading3",
        view: "h3",
      },
      {
        model: "paragraph",
        title: "Stycke",
        class: "ck-heading_paragraph",
      },
    ],
  },
  image: {
    upload: {
      types: ["jpeg", "png", "gif", "webp", "svg+xml"],
    },
  },
  simpleUpload: {
    uploadUrl: "",
    withCredentials: true,
    headers: {
      Authorization: "Bearer ...",
    },
  },
  toolbar: Editor.defaultConfig.toolbar,
  htmlEmbed: {
    showPreviews: true,
  },
};

export const readOnlyCkEditorConfig: EditorConfig = {
  ...ckEditorConfig,
  toolbar: undefined,
};

export { Editor };
