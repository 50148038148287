import { RecoilRoot, useRecoilValue, useSetRecoilState } from "recoil";

import {
  SaveDocumentContext,
  SharingInfoContext,
} from "../../../lib/application/contexts";
import { DataCardsContainer } from "./DataCardsContainer";
import {
  allCardsInitializedQuery,
  reportMetaStateQuery,
} from "../../../lib/application/state/stats/document-meta/queries";
import { getSharingInfoNonDocument } from "../../../lib/application/files/SharingInfo";
import { voidFunc } from "../../../lib/core/voidFunc";
import { useCallback, useEffect, useState } from "react";
import { docCardsListQuery } from "../../../lib/application/state/stats/document-core/docCardsListState";
import { useGetAllCardsCallback } from "../../../lib/application/state/actions/cardCallbacks";
import {
  DefaultLoading,
  NotReadyHttpErrNotice,
} from "../../../components/Loading";
import { ReportMetaState } from "../../../lib/application/state/stats/document-meta/definitions";
import { defined } from "../../../lib/core/defined";
import {
  WorkspaceLatest,
  stateToWorkspaceLatest,
} from "../../../lib/application/state/stats/workspace/shared";
import { useLoadThirdPartyDocumentMockLoader } from "../../../lib/application/state/actions/load/useLoadThirdPartyDocument";
import { ThirdPartyDocDto } from "../../../lib/infra/api_responses/document";
import { HttpResult } from "../../../lib/infra/HttpResult";

const categoriesMock = {};
const sharingInfoMock = getSharingInfoNonDocument();
const sharingContext = { info: sharingInfoMock };

export function StatsThirdPartyDocPreview() {
  const cardsList = useRecoilValue(docCardsListQuery);
  const getAllCards = useGetAllCardsCallback(cardsList);
  const metaState = useRecoilValue(reportMetaStateQuery);

  const [workspace, setWorkspace] = useState<WorkspaceLatest | null>(null);

  const ready = useRecoilValue(allCardsInitializedQuery);

  useEffect(() => {
    if (!ready) {
      return;
    }
    if (workspace !== null) {
      return;
    }

    if (!defined(metaState)) {
      return;
    }

    const allCards = getAllCards();
    const currentWorkspace = stateToWorkspaceLatest(allCards, metaState);
    setWorkspace(JSON.parse(JSON.stringify(currentWorkspace)));
  }, [getAllCards, metaState, ready, workspace]);

  if (workspace === null) {
    return <DefaultLoading></DefaultLoading>;
  }
  if (!defined(metaState)) {
    return <DefaultLoading></DefaultLoading>;
  }

  return (
    <RecoilRoot>
      <StatsThirdPartyCardsPreviewInner
        workspace={workspace}
        metaState={metaState}
      />
    </RecoilRoot>
  );
}

function StatsThirdPartyCardsPreviewInner(props: {
  workspace: WorkspaceLatest;
  metaState: ReportMetaState;
}) {
  const setMetaState = useSetRecoilState(reportMetaStateQuery);
  const load = useCallback(() => {
    const doc: ThirdPartyDocDto = {
      content: props.workspace,
      title: props.metaState.title,
      created_at: new Date().toString(),
      third_party_doc_id: "mock",
    };
    return Promise.resolve(HttpResult.fromOk(doc));
  }, [props.metaState.title, props.workspace]);

  useEffect(() => {
    setMetaState(props.metaState);
  }, [props.metaState, setMetaState]);

  const loadRes = useLoadThirdPartyDocumentMockLoader(load);
  return loadRes.match({
    notReady: (status) => (
      <NotReadyHttpErrNotice left={status}></NotReadyHttpErrNotice>
    ),
    ready: (doc) => {
      return (
        <SharingInfoContext.Provider value={sharingContext}>
          <SaveDocumentContext.Provider value={undefined}>
            <DataCardsContainer
              sharingInfo={sharingInfoMock}
              categories={categoriesMock}
              addMaxNumCardsError={voidFunc}
            ></DataCardsContainer>
          </SaveDocumentContext.Provider>
        </SharingInfoContext.Provider>
      );
    },
  });
}
