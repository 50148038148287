import { Driver, driver } from "driver.js";

import { defined } from "../../core/defined";
import { logger } from "../../infra/logging";
import { Tutorial, TutorialKey } from "./types";
import { statsCardIntro } from "./tutorials/stats_card_intro";
import { statsApiV2 } from "../requests/statsApiV2";
import { voidFunc } from "../../core/voidFunc";

export const STATS_CARD_DEFAULT_PATH = [
  "Befolkning",
  "Befolkningens storlek",
  "Befolkningsförändring",
];

export enum TutorialEvents {
  NewCardMenuClicked = "newCardMenuClicked",
  NewStatsCardCreated = "newStatsCard",
  StatsCardAreaDropdownHighlighted = "statsCardAreaDropdownHighlighted",
  AreaSelectClicked = "areaSelectClicked",
}

export class TutorialsHandler {
  private _runnableTutorials: { [key: string]: Tutorial } = {};
  private _lastDriver: Driver | null = null;

  private _externalEventListeners: {
    [key: string]: undefined | ((cardId?: string) => void)[];
  } = {};
  private _activeTutorial: Tutorial | null = null;
  private _eventsHandled: Set<string> | null = null;
  private _tutorials: {
    [key in TutorialKey]: Tutorial;
  } = {
    stats_card_intro: statsCardIntro(this),
  };
  private _seenTutorials = new Set<string>();

  private _emitEvent(event: TutorialEvents) {
    for (const listener of this._externalEventListeners[event] ?? []) {
      listener(this._activeTutorial?.cardId);
    }
  }

  constructor(
    private _setAvailableTutorials: (t: Tutorial[]) => void,
    seenTutorials: string[]
  ) {
    for (const key of seenTutorials) {
      this._seenTutorials.add(key);
    }
  }

  get lastDriver() {
    return this._lastDriver;
  }

  register(key: keyof typeof this._tutorials) {
    const tutorial = this._tutorials[key];
    if (!defined(tutorial)) {
      logger.error("[register] Tutorial not found: " + key);
      return;
    }

    if (this._runnableTutorials[key]) {
      return;
    }

    this._runnableTutorials[key] = tutorial;

    this._setAvailableTutorials(Object.values(this._runnableTutorials));
  }

  unregister(key: keyof typeof this._tutorials) {
    delete this._runnableTutorials[key];
    this._setAvailableTutorials(Object.values(this._runnableTutorials));
  }

  seenTutorials(): string[] {
    return Array.from(this._seenTutorials.keys());
  }

  start(key: keyof typeof this._tutorials) {
    if (this._lastDriver?.isActive()) {
      logger.warn("[start] Tutorial already active");
      return;
    }

    this._eventsHandled = new Set();

    const tutorial = this._runnableTutorials[key];
    if (!defined(tutorial)) {
      logger.error("[start] Tutorial not found: " + key);
      return;
    }

    const d = driver(tutorial.config);
    this._lastDriver = d;
    d.drive();
    tutorial.seen = true;
    if (defined(tutorial.cardId)) {
      delete tutorial.cardId;
    }
    this._activeTutorial = tutorial;

    this._seenTutorials.add(key);
    statsApiV2
      .setUserTutorialSeen(key)
      .then((res) => {
        res.match({
          ok: voidFunc,
          err: (err) => {
            logger.error("[start] Failed to set tutorial seen: " + err);
          },
        });
      })
      .catch((err) => {
        logger.error("[start] Failed to set tutorial seen: " + err);
      });
  }

  onDestroy() {
    this._externalEventListeners = {};
    this._lastDriver = null;
    this._activeTutorial = null;
    this._eventsHandled = null;
  }

  isActive(): boolean {
    return this._lastDriver?.isActive() ?? false;
  }

  activeCardId(): string | undefined {
    return this._activeTutorial?.cardId;
  }

  emitEvent(event: TutorialEvents) {
    this._emitEvent(event);
  }

  addExternalEventHandler(
    event: TutorialEvents,
    handler: (cardId?: string) => void
  ) {
    this._externalEventListeners[event] ??= [];
    if (this._externalEventListeners[event].includes(handler)) {
      return;
    }
    this._externalEventListeners[event].push(handler);
  }

  removeExternalEventHandler(
    event: TutorialEvents,
    handler: (cardId?: string) => void
  ) {
    if (!defined(this._externalEventListeners[event])) {
      return;
    }
    this._externalEventListeners[event] = this._externalEventListeners[
      event
    ].filter((h) => h !== handler);
  }

  handleEvent(event: string, cardId?: string) {
    if (!this._eventsHandled) {
      return;
    }

    if (this._eventsHandled.has(event)) {
      return;
    }
    switch (event) {
      case TutorialEvents.NewCardMenuClicked:
        this._eventsHandled.add(event);
        this._lastDriver?.moveNext();
        break;
      case TutorialEvents.NewStatsCardCreated:
        if (this._lastDriver?.getActiveIndex() === 2) {
          const tutorial = this._activeTutorial;
          if (!defined(tutorial)) {
            logger.error("[handleEvent] Tutorial not found");
            return;
          }
          tutorial.cardId = cardId;
          this._eventsHandled.add(event);
          // Delay a bit to wait for animations
          setTimeout(() => {
            this._lastDriver?.moveNext();
          }, 700);
        }
        break;
      default:
        logger.warn(`[emitEvent] Event not handled: ${event}`);
        break;
    }
  }
}
