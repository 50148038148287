import { useCallback, useContext } from "react";

import { InfostatSearchBox } from "../../../components/InfostatSearchBox";
import { statsSearchV2 } from "../../../lib/application/requests/common_requests";
import { defined } from "../../../lib/core/defined";
import { TimeResolution } from "../../../lib/domain/time";
import { ShowDraftDataContext } from "../../../lib/application/contexts";
import {
  handleSelectSearchHit,
  renderSearchHit,
  SelectMeasureResultHandler,
} from "./SearchMain";
import { SearchResultItemV2 } from "../../../lib/infra/api_responses/search";

interface Props {
  onSelectPath: (tree: string[]) => void;
  onSelectMeasure: SelectMeasureResultHandler;
  maxTimeResolution: TimeResolution;
  className?: string;
}
export function SearchGroupingMeasure(props: Props) {
  const maxTimeResolution = props.maxTimeResolution;
  const adminShowDraftData = useContext(ShowDraftDataContext);

  const handleSearch = useCallback(
    (searchString: string) => {
      return statsSearchV2(
        searchString,
        maxTimeResolution,
        adminShowDraftData,
        true
      ).then((resultsRaw) => {
        const results = resultsRaw.unwrap();
        if (!defined(results)) {
          return [];
        }
        return results.map<{ item: SearchResultItemV2; key: string }>((r) => ({
          item: r,
          key: Object.entries(r)
            .map(([key, value]) => key + "-" + value)
            .join("_"),
        }));
      });
    },
    [adminShowDraftData, maxTimeResolution]
  );

  return (
    <InfostatSearchBox<SearchResultItemV2>
      autofocus={false}
      minimumSearchStringLength={3}
      className={props.className}
      search={handleSearch}
      render={renderSearchHit}
      onSelect={(item) =>
        handleSelectSearchHit(item, props.onSelectPath, props.onSelectMeasure)
      }
    ></InfostatSearchBox>
  );
}
