import { config } from "../../../../../config";
import { HttpResult } from "../../../../infra/HttpResult";
import { authedRequest } from "../../shared";

export function deleteMeasureStats(
  measureId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/measures/${measureId}`,
    undefined,
    "DELETE"
  );
}

export function deleteMeasureSurvey(
  measureId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/measures/${measureId}`,
    undefined,
    "DELETE"
  );
}

export function deleteMeasureMicro(
  measureId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/mikro/measures/${measureId}`,
    undefined,
    "DELETE"
  );
}

export function deleteMemberOrgsMeasure(
  measureId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    `admin/member_orgs_measures/${measureId}`,
    undefined,
    "DELETE"
  );
}
