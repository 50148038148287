import { useCallback } from "react";

import { defined } from "../../../../../core/defined";
import { setMeasureAvailableDatesMut } from "../../../../../domain/measure";
import {
  DataSelection,
  DataSelectionGrouping,
} from "../../../../../domain/selections/definitions";
import { statsApi } from "../../../../requests/statsApi";

export function useUpdateSelectionOnToggleDraftMode<
  T extends DataSelectionGrouping | DataSelection
>(
  handleChange: (selection: T) => void,
  isGroupingSelection: boolean,
  groupableOnly: boolean
) {
  return useCallback(
    async (dataSelection: T, nextDraftMode: boolean) => {
      const measureSelection = dataSelection.measureSelection;
      if (!defined(measureSelection)) {
        return;
      }
      if (nextDraftMode) {
        const measures = await statsApi.getAvailableMeasures(
          dataSelection.subjectPath,
          groupableOnly,
          isGroupingSelection,
          nextDraftMode
        );
        const measureSelectionCopy = {
          ...measureSelection,
        };
        await setMeasureAvailableDatesMut(measureSelectionCopy, nextDraftMode);
        handleChange({
          ...dataSelection,
          measureSelection: {
            ...measureSelectionCopy,
            available: measures,
          },
        });
      } else {
        // switch to non-draft mode
        const measures = await statsApi.getAvailableMeasures(
          dataSelection.subjectPath,
          groupableOnly,
          isGroupingSelection,
          nextDraftMode
        );
        const measureSelectionCopy = {
          ...measureSelection,
        };
        if (
          !measures.some(
            (m) => m.data_id === measureSelectionCopy.measure.data_id
          )
        ) {
          return handleChange({
            ...dataSelection,
            measureSelection: undefined,
          });
        }
        await setMeasureAvailableDatesMut(measureSelectionCopy, nextDraftMode);
        handleChange({
          ...dataSelection,
          measureSelection: {
            ...measureSelectionCopy,
            available: measures,
          },
        });
      }
    },
    [groupableOnly, handleChange, isGroupingSelection]
  );
}
