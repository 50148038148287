import * as _ from "lodash";
import { partition, fromPairs } from "lodash";
import { isEqual, last, memoize, range } from "lodash";

import { defined } from "../../../core/defined";
import { invertComparator } from "../../../core/order";
import { calcPermutations } from "../../../core/permutations";

import {
  dateStringComparatorAsc,
  dateStringComparatorDesc,
} from "../../../core/time";
import { isBreakdownTotal, SortSpec } from "../../../domain/measure";
import { Dimension } from "./core/definitions";
import { DimensionV2Dto } from "../../../domain/measure/definitions";

export const groupingSortSpec: SortSpec = {
  orderTop: ["Låg", "Under medel", "Medel", "Över medel", "Hög"],
  orderBottom: [],
};

/**
 * Standard dimensions for which a header can be displayed
 */
export function filterHeaderEnabledDimensions(dimensions: string[]): string[] {
  return dimensions.filter((dim) => {
    return !(
      [
        Dimension.value,
        Dimension.date,
        Dimension.region,
        Dimension.percentage,
      ] as string[]
    ).includes(dim);
  });
}

export function getLegendDimensionLineChart(
  allDimensions: string[]
): string | undefined {
  const tertiaryDimensions = allDimensions.slice(2);
  return _.last(tertiaryDimensions.sort(defaultDimensionComparator));
}

/**
 * Pick the last sorted dimension as legend dimension
 */
export function getLegendDimensionBarChart(
  allDimensions: string[]
): string | undefined {
  const nonPrimaryDimensions = allDimensions.slice(1);
  if (nonPrimaryDimensions.length <= 1) {
    return;
  }

  return _.last(nonPrimaryDimensions.sort(defaultDimensionComparator));
}

function dimensionLabelSortValues(label: string) {
  if (isBreakdownTotal(label)) {
    return 10;
  }
  return 5;
}
const surveyInfoDimensions = [Dimension.status, Dimension.version];

/**
 * Dimensions excluding metadata dimensions, date, subquestion, value and percentage
 */
export function surveyBreakdownDimensions(rawDimensions: string[]): string[] {
  return rawDimensions.filter(
    (d) =>
      !surveyInfoDimensions.includes(d as Dimension) &&
      ![
        Dimension.percentage,
        Dimension.value,
        Dimension.subquestion,
        ...range(1, 4).map((i) => `${Dimension.subquestion}${i}`),
        Dimension.date,
      ].includes(d as Dimension)
  );
}

export function surveyDataDimensions(rawDimensions: string[]) {
  return rawDimensions.filter(
    (d) => !surveyInfoDimensions.includes(d as Dimension)
  );
}

/**
 * Micro dataset dimensions that aren't for data but for metadata
 */

export const MICRO_DIMENSION_GROUP_ID = "group_id";
export const MICRO_DIMENSION_GEOCODES = "geocodes";
export const MICRO_DIMENSION_Z_VALUE = "index";
export const MICRO_DIMENSIONS_LABEL = "label";
const MICRO_INFO_DIMENSIONS = [
  MICRO_DIMENSIONS_LABEL,
  MICRO_DIMENSION_GROUP_ID,
  MICRO_DIMENSION_GEOCODES,
  MICRO_DIMENSION_Z_VALUE,
];
export function filterMicroDataDimensions(rawDimensions: string[]) {
  return rawDimensions.filter((d) => !MICRO_INFO_DIMENSIONS.includes(d));
}

export function surveyDataDimensionsSorted(rawDimensions: string[]): string[] {
  return surveyDataDimensions(rawDimensions)
    .sort(defaultDimensionComparator)
    .reverse();
}

export function surveyDataDimensionsWithoutRange(
  rawDimensions: string[]
): string[] {
  return surveyDataDimensionsSorted(rawDimensions).filter(
    (d) => d !== Dimension.percentage
  );
}

export function isStatsDataDimension(dim: string): boolean {
  return dim !== Dimension.userDefined;
}

export const surveyDataStandardDimensions = surveyInfoDimensions.concat([
  Dimension.date,
  Dimension.value,
  Dimension.percentage,
  Dimension.region,
  Dimension.subquestion,
  Dimension.subquestion1,
  Dimension.subquestion2,
  Dimension.subquestion3,
]);

export function isEligibleLegendDimension(dim: string): boolean {
  switch (dim) {
    case Dimension.value:
    case Dimension.date:
    case Dimension.region:
    case Dimension.status:
    case Dimension.version:
    case Dimension.subquestion:
    case Dimension.subquestion1:
    case Dimension.subquestion2:
    case Dimension.subquestion3:
      return false;
    default:
      return true;
  }
}

export function commonDimensionLabel(dim: Dimension) {
  switch (dim) {
    case Dimension.date:
      return "Datum";
    case Dimension.region:
      return "Geografisk region";
  }
}

function dimensionSortValues(dim: string) {
  switch (dim) {
    case Dimension.percentage:
      return 1;
    case Dimension.subquestion:
    case Dimension.subquestion1:
      return 2;
    case Dimension.subquestion2:
      return 3;
    case Dimension.subquestion3:
      return 4;
    case Dimension.value:
      return 5;
    case Dimension.date:
      return 6;
    case Dimension.region:
      return 7;
    default:
      return 12;
  }
}

export type DimensionAndLabels<T = string> = {
  dimension: string;
  labelsSorted: T[];
};

export function stringCompareDefault(left: string, right: string): -1 | 1 | 0 {
  if (left < right) {
    return 1;
  } else if (left > right) {
    return -1;
  }
  return 0;
}

export function defaultDimensionComparator(left: string, right: string) {
  const leftScore = dimensionSortValues(left);
  const rightScore = dimensionSortValues(right);
  if (leftScore === rightScore) {
    return stringCompareDefault(left, right);
  }
  return leftScore < rightScore ? 1 : -1;
}

function groupingComparatorDesc(left: string, right: string): -1 | 1 | 0 {
  const values = ["Låg", "Under medel", "Medel", "Över medel", "Hög"];
  const leftScore = values.indexOf(left);
  const rightScore = values.indexOf(right);
  if (leftScore === rightScore) {
    return 0;
  }
  return leftScore > rightScore ? -1 : 1;
}
const groupingComparatorAsc = invertComparator(groupingComparatorDesc);

/**
 * Sorts date and grouping dimensions, but other dimensions are kept as-is except for special labels like "Samtliga"
 */
export function defaultDimensionLabelComparatorDesc(dimension: string) {
  switch (dimension) {
    case Dimension.date:
      return dateStringComparatorDesc;
    case Dimension.grouping:
      return groupingComparatorDesc;
    default:
      return (left: string, right: string) => {
        const leftScore = dimensionLabelSortValues(left);
        const rightScore = dimensionLabelSortValues(right);
        if (leftScore === rightScore) {
          return 0;
        }
        return leftScore > rightScore ? -1 : 1;
      };
  }
}

/**
 * Get special comparators for date or grouping, else by simple string comparisons
 */
export function anyDimensionLabelComparatorAsc(dimension: string) {
  if (dimension === Dimension.date) {
    return dateStringComparatorAsc;
  }
  if (dimension === Dimension.grouping) {
    return groupingComparatorAsc;
  }

  return (left: string, right: string) => {
    return left < right ? -1 : left > right ? 1 : 0;
  };
}

export function isDateDimension(dim?: string): boolean {
  return dim === Dimension.date;
}

/**
 * Sorts date and grouping dimensions, but other dimensions are kept as-is except for special labels like "Samtliga"
 */
export function defaultDimensionLabelComparatorAsc(dimension: string) {
  return (left: string, right: string) => {
    const result = defaultDimensionLabelComparatorDesc(dimension)(left, right);
    if (result === 0) {
      return 0;
    }
    return result > 0 ? -1 : 1;
  };
}

export function sortDimensionSpecsByParentChild(
  dimensions: DimensionV2Dto[]
): DimensionV2Dto[] {
  const dataColumns = dimensions.map((d) => d.data_column);
  const sortOrder = sortDimensionsByParentChildBase(
    dataColumns,
    dimensions
  ).all;
  return _.sortBy(dimensions, (d) => sortOrder.indexOf(d.data_column));
}

/**
 * Get a list of ordered dimensions representing the hierarchy of dimensions.
 * Even if a breakdown dimension is lifted, it will be included in its original position in the hierarchy.
 * Root dimensions that do not have children are excluded.
 */
export function getHierarchicalDimensionGroups(
  dataColumns: string[],
  dimensions: DimensionV2Dto[]
): string[][] {
  const extendedDataColumns = dataColumns.slice();
  for (const dim of dimensions) {
    if (!extendedDataColumns.includes(dim.data_column)) {
      extendedDataColumns.push(dim.data_column);
    }
  }

  return sortDimensionsByParentChildBase(
    extendedDataColumns,
    dimensions
  ).groups.filter((g) => g.length > 1);
}

export function sortDimensionsByParentChildBase(
  dataColumns: string[],
  dimensions: DimensionV2Dto[]
): { all: string[]; groups: string[][] } {
  const dimsMap = fromPairs(
    dataColumns.map((d) => [d, dimensions.find((d2) => d2.data_column === d)])
  );
  // Build up a sorted array of dims in resultDims.
  // Child dims will be inserted after their parent dim in resultDims.
  const [rootDims, childCols] = partition(
    dataColumns,
    (column) => !defined(dimsMap[column]?.parent_id)
  );

  function getChildren(column: string): string[] {
    const currentDim = dimsMap[column];
    const child = childCols.find(
      (c) => dimsMap[c]?.parent_id === currentDim?.dimension_id
    );
    if (!defined(child)) {
      return [];
    }

    return [child, ...getChildren(child)];
  }

  const resultDims: string[] = [];
  const groups: string[][] = [];
  for (const currentParent of rootDims) {
    const children: string[] = getChildren(currentParent);
    resultDims.push(currentParent, ...children);
    groups.push([currentParent, ...children]);
  }

  return { all: resultDims, groups };
}

/**
 * Make sure the the fixed dimensions selected are actually valid. When setting fixed dimensions,
 * we don't know for sure that data returned has data points for all dimensions.
 *
 * Also, we check to make sure that hieararchical dimensions are always in the right order. If not, it's
 * not a valid combination.
 * @param nonPrimaryDimensions Excludes primary dimension
 * @param fixedNonPrimaryDimensions Excludes primary dimension
 */
export function fixedNonPrimaryDimensionsValid(
  nonPrimaryDimensions: string[],
  fixedNonPrimaryDimensions: string[],
  forceRegionDimensionAsPrimary: boolean
): boolean {
  const candidateRowDimension = fixedNonPrimaryDimensions[0];
  if (
    forceRegionDimensionAsPrimary &&
    candidateRowDimension !== Dimension.region
  ) {
    return false;
  }

  return (
    nonPrimaryDimensions.length === fixedNonPrimaryDimensions.length &&
    fixedNonPrimaryDimensions.every((dim) => nonPrimaryDimensions.includes(dim))
  );
}

const calcPermutationsMemoized = memoize(calcPermutations, (dimensions) =>
  dimensions.join("-")
);

function calcPermutationsWithFixedRegion(dimensions: string[]) {
  const dimsWithoutRegion = dimensions.filter((d) => d !== Dimension.region);
  if (dimsWithoutRegion.length < dimensions.length) {
    return calcPermutationsMemoized(dimsWithoutRegion).map((p) => [
      Dimension.region,
      ...p,
    ]);
  }
  return calcPermutationsMemoized(dimensions);
}

/**
 *
 * @param dimensions
 * @param currentFixedDimensionsOrder
 * @param forceRegionDimensionAsPrimary In micro cards in table mode, we need to ensure the region is always the primary dimension if using reference values.
 */
export function getNextDimensionOrder(
  dimensions: string[],
  currentFixedDimensionsOrder: string[] | null,
  forceRegionDimensionAsPrimary: boolean
): string[] | undefined {
  if (dimensions.length < 2) {
    return;
  }

  const nonPrimaryDimensions =
    currentFixedDimensionsOrder ?? dimensions.slice();
  if (nonPrimaryDimensions.length === 1) {
    return nonPrimaryDimensions;
  }
  const permutations = forceRegionDimensionAsPrimary
    ? calcPermutationsWithFixedRegion(dimensions.slice())
    : calcPermutationsMemoized(dimensions.slice());
  if (defined(currentFixedDimensionsOrder)) {
    const index = permutations.findIndex((p) => {
      return isEqual(p, currentFixedDimensionsOrder);
    });
    return permutations[(index + 1) % permutations.length];
  }

  return last(permutations);
}
