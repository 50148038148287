import { debounce } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RecoilRoot } from "recoil";

import { AlertBox } from "../../../../components/AlertBox";
import { Button } from "../../../../components/Button";
import { MainContent } from "../../../../components/MainContent";
import { Table } from "../../../../components/Table";
import {
  AppMessagesContext,
  SharingInfoContext,
} from "../../../../lib/application/contexts";
import {
  getSharingInfoNonDocument,
  getSharingInfoStatsPreview,
} from "../../../../lib/application/files/SharingInfo";
import { useLoadableHttpResource } from "../../../../lib/application/hooks/useLoadableResource";
import { adminApi } from "../../../../lib/application/requests/admin/admin_api";
import {
  getCategoriesWithCache,
  getGeographicRegionsWithCache,
  getSingleMeasure,
} from "../../../../lib/application/requests/common_requests";
import { statsApiV2 } from "../../../../lib/application/requests/statsApiV2";
import { useLoadSingleStaticMeasure } from "../../../../lib/application/state/actions/load/useLoadSingleStaticMeasure";
import { DefaultSettingsWrapperRT } from "../../../../lib/application/state/stats/default-settings/common";
import {
  DefaultSettingsLatestRT,
  migrate,
} from "../../../../lib/application/state/stats/default-settings/parse_encode";
import { CURRENT_WORKSPACE_VERSION } from "../../../../lib/application/state/stats/workspace/types";
import { defined } from "../../../../lib/core/defined";
import { Milliseconds } from "../../../../lib/core/time";
import { voidFunc } from "../../../../lib/core/voidFunc";
import { Categories } from "../../../../lib/domain/categories";
import { GeographiesSerializable } from "../../../../lib/domain/geography";
import { TimeResolution } from "../../../../lib/domain/time";
import { AdminMeasureListDto } from "../../../../lib/infra/api_responses/admin/document_versions";
import {
  displayHttpErrorInternal,
  HttpResult,
} from "../../../../lib/infra/HttpResult";
import { consoleLogger } from "../../../../lib/infra/logging";
import { DataCardsContainer } from "../../../stats/docs/DataCardsContainer";
import { useMigrationHooks } from "./shared";

interface Props {}
export function ManageMigrationsSettings(props: Props) {
  const [loadedVersionNumber, setLoadedVersionNumber] = useState<
    number | undefined
  >();
  const [measures, setMeasures] = useState<AdminMeasureListDto>([]);
  const [availableVersions, setAvailableVersions] = useState<number[] | null>(
    null
  );
  useEffect(() => {
    adminApi.listSettingsVersions().then((res) => {
      res.match({
        ok: (versions) => {
          setAvailableVersions(versions);
        },
        err: (err) => {
          alert(displayHttpErrorInternal(err));
          consoleLogger.error(err);
        },
      });
    });
  }, []);

  const appMessages = useContext(AppMessagesContext);

  const handleShowMeasures = useCallback((versionNumber: number) => {
    return adminApi.listMeasuresBySettingsVersion(versionNumber).then((res) => {
      res.match({
        ok: (measures) => {
          setLoadedVersionNumber(versionNumber);
          setMeasures(measures);
        },
        err: (err) => {
          alert(displayHttpErrorInternal(err));
          consoleLogger.error(err);
        },
      });
    });
  }, []);

  const handleMigrateInner = useCallback((measureId: number) => {
    return getSingleMeasure(measureId, false, false).then((res) => {
      const measure = res.unwrap();
      if (!defined(measure.default_settings)) {
        return HttpResult.fromOk(undefined);
      }

      const defaultSettings = DefaultSettingsWrapperRT.check(
        measure.default_settings
      );

      return migrate(measureId, defaultSettings, statsApiV2).then((res) => {
        return res.match({
          err: (err) => {
            throw new Error(err);
          },
          ok: (migrated) => {
            const migratedChecked = DefaultSettingsLatestRT.check(migrated);
            return adminApi.saveMeasureDefaultSettings(
              measureId,
              migratedChecked
            );
          },
        });
      });
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDocMigrated = useCallback(
    debounce((docId: number) => {
      if (!defined(loadedVersionNumber)) {
        return;
      }
      handleShowMeasures(loadedVersionNumber);
    }, Milliseconds.second * 2),
    [handleShowMeasures, loadedVersionNumber]
  );

  const {
    handleMigrateAll,
    handleMigrateSingle,
    singleMigrationInProgress,
    numMigrated,
    numTotalToMigrate,
    failures,
    migrateAllInProgress,
  } = useMigrationHooks<number, { id: number }>(
    handleMigrateInner,
    (measure) => measure.id,
    handleDocMigrated,
    appMessages
  );

  const [selectedMeasure, setSelectedMeasure] = useState<number | undefined>();
  const [selectedMeasureInput, setSelectedMeasureInput] = useState<
    number | undefined
  >();

  const anyMigrationInProgress = useMemo(() => {
    return singleMigrationInProgress || migrateAllInProgress;
  }, [migrateAllInProgress, singleMigrationInProgress]);

  const categoriesGetter = useCallback(
    () => getCategoriesWithCache(undefined, TimeResolution.maximal(), false),
    []
  );
  const [categoriesResult] = useLoadableHttpResource(categoriesGetter);
  const categories = categoriesResult.getReadyData();
  const [geographiesResult] = useLoadableHttpResource(
    getGeographicRegionsWithCache
  );
  const geographies = geographiesResult.getReadyData();

  return (
    <>
      <div>
        {failures.length > 0 && (
          <AlertBox>
            <div>
              {failures.map((f) => (
                <p>
                  id: {f[0].id}, fel: {f[1]}
                </p>
              ))}
            </div>
          </AlertBox>
        )}
        {migrateAllInProgress && defined(numTotalToMigrate) && (
          <div>
            <p>Migrerar alla ...</p>
            <progress
              value={numMigrated / numTotalToMigrate}
              max={1}
            ></progress>
          </div>
        )}
        <label>Visa mått med ID</label>
        <input
          type="number"
          value={selectedMeasureInput}
          disabled={anyMigrationInProgress}
          onChange={(e) =>
            setSelectedMeasureInput(parseInt(e.currentTarget.value))
          }
        ></input>
        <Button
          disabled={anyMigrationInProgress}
          title="Välj"
          onClick={() => setSelectedMeasure(selectedMeasureInput)}
        />
      </div>
      <div>
        <label>Lista alla mått med given version</label>
        {defined(availableVersions) &&
          availableVersions.length > 0 &&
          availableVersions.map((v) => (
            <Button
              key={v}
              title={v.toString()}
              onClick={() => handleShowMeasures(v)}
            />
          ))}
      </div>
      <>
        {measures === null && (
          <AlertBox intent="warning">
            <p>Inga mått hittades</p>
          </AlertBox>
        )}
        {defined(measures) &&
          measures.length > 0 &&
          defined(loadedVersionNumber) && (
            <div>
              <h3>
                Mått med settingsversion {loadedVersionNumber} (totalt{" "}
                {measures.length})
              </h3>
              {loadedVersionNumber < CURRENT_WORKSPACE_VERSION && (
                <Button
                  title="Migrera alla"
                  disabled={anyMigrationInProgress}
                  onClick={() =>
                    handleMigrateAll(measures.map((m) => ({ id: m.data_id })))
                  }
                />
              )}
              <Table
                columns={[
                  { name: "ID" },
                  { name: "Namn" },
                  { name: "Version" },
                ]}
                containerClassName="doc-list"
                onRowClick={(id) => {
                  if (anyMigrationInProgress) {
                    return;
                  }
                  setSelectedMeasure(id);
                }}
                data={measures.map((m) => {
                  return {
                    id: m.data_id,
                    cells: [
                      m.data_id.toString() + ": " + m.label,
                      m.descr_long,
                      loadedVersionNumber.toString(),
                    ],
                  };
                })}
              ></Table>
            </div>
          )}
      </>

      <>
        {defined(selectedMeasure) && (
          <div>
            <h3>Valt dokument: {selectedMeasure}</h3>
            <Button
              title="Avbryt"
              onClick={() => setSelectedMeasure(undefined)}
            ></Button>
            {defined(loadedVersionNumber) &&
              loadedVersionNumber < CURRENT_WORKSPACE_VERSION && (
                <Button
                  title="Migrera"
                  disabled={anyMigrationInProgress}
                  onClick={() => handleMigrateSingle(selectedMeasure)}
                ></Button>
              )}
            <div id="doc-migration-comparison">
              {defined(categories) && defined(geographies) && (
                <div className="doc">
                  <RecoilRoot key={selectedMeasure}>
                    <CurrentMeasure
                      key={selectedMeasure}
                      geographies={geographies}
                      categories={categories}
                      measureId={selectedMeasure}
                    />
                  </RecoilRoot>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </>
  );
}

function CurrentMeasure(props: {
  geographies: GeographiesSerializable;
  measureId: number;
  categories: Categories;
}) {
  const [status] = useLoadSingleStaticMeasure(
    props.measureId,
    props.geographies
  );

  const sharingInfoValue = useMemo(() => {
    return {
      info: {
        ...getSharingInfoNonDocument(),
        nodeId: () => -1,
      },
    };
  }, []);

  if (!status.isReady()) {
    return <div>Laddar...</div>;
  }

  const sharingValue = getSharingInfoStatsPreview();
  return (
    <SharingInfoContext.Provider value={sharingInfoValue}>
      <MainContent>
        <div>
          <DataCardsContainer
            sharingInfo={sharingValue}
            categories={props.categories}
            addMaxNumCardsError={voidFunc}
          ></DataCardsContainer>
        </div>
      </MainContent>
    </SharingInfoContext.Provider>
  );
}
