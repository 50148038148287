import { Checkbox } from "@fluentui/react";
import { AlertBox } from "../../../../../components/AlertBox";
import {
  DataOutputView,
  DocCardStats,
} from "../../../../../lib/application/state/stats/document-core/core";
import { useCallback } from "react";
import { defined } from "../../../../../lib/core/defined";
import { useChangeThirdPartyDataCardSettings } from "../../../../../lib/application/state/actions/useChangeThirdPartyCardSettings";

import "./ThirdPartyDocCardSettings.scss";
import { classNames } from "../../../../../lib/core/classNames";

export function ThirdPartDocCardSettings(props: { card: DocCardStats }) {
  const { card } = props;
  const settings = card.data.thirdPartyDataCardSettings;

  const handleUpdateSettings = useChangeThirdPartyDataCardSettings(card.id);

  const handleToggleSetting = useCallback(
    (
      setting: keyof NonNullable<
        DocCardStats["data"]["thirdPartyDataCardSettings"]
      >
    ) => {
      handleUpdateSettings({
        ...settings,
        [setting]: !defined(settings) ? true : !settings[setting],
      });
    },
    [handleUpdateSettings, settings]
  );

  const toggleTab = useCallback(
    (tab: DataOutputView) => {
      handleUpdateSettings({
        ...settings,
        hideTabs: settings?.hideTabs?.includes(tab)
          ? settings.hideTabs.filter((t) => t !== tab)
          : [...(settings?.hideTabs ?? []), tab],
      });
    },
    [handleUpdateSettings, settings]
  );

  const commonElementProps = {
    disabled: false,
    className: "element",
  };

  return (
    <AlertBox className="third-party-doc-card-settings" intent="default">
      <p>
        <strong>
          Välj vilka inställningsmöjligheter som ska finnas när dokumentet
          visas.
        </strong>
      </p>
      <div className="flex-row">
        <section>
          <Checkbox
            {...commonElementProps}
            className={classNames(
              commonElementProps.className,
              "show-geo-selector"
            )}
            checked={settings?.showGeoSelector ?? false}
            label="Visa geoväljare"
            onChange={() => handleToggleSetting("showGeoSelector")}
          ></Checkbox>

          <Checkbox
            {...commonElementProps}
            className={classNames(
              commonElementProps.className,
              "show-timeline"
            )}
            checked={settings?.showTimeline ?? false}
            label="Visa tidslinje"
            onChange={() => handleToggleSetting("showTimeline")}
          ></Checkbox>
          <Checkbox
            {...commonElementProps}
            className={classNames(
              commonElementProps.className,
              "show-breakdowns"
            )}
            checked={settings?.showBreakdowns ?? false}
            label="Visa nedbrytningar"
            onChange={() => handleToggleSetting("showBreakdowns")}
          ></Checkbox>
        </section>

        <section>
          <Checkbox
            {...commonElementProps}
            className={classNames(commonElementProps.className, "show-toolbar")}
            checked={settings?.showToolbar ?? false}
            label="Visa verktygsfält"
            onChange={() => handleToggleSetting("showToolbar")}
          ></Checkbox>
          <div className="indent">
            <Checkbox
              {...commonElementProps}
              className={classNames(
                commonElementProps.className,
                "show-toolbar-settings"
              )}
              disabled={!(settings?.showToolbar ?? false)}
              checked={
                (settings?.showToolbar && settings.showToolbarSettings) ?? false
              }
              label="Visa verktygsfältets inställningar"
              onChange={() => handleToggleSetting("showToolbarSettings")}
            ></Checkbox>
            <Checkbox
              {...commonElementProps}
              className={classNames(
                commonElementProps.className,
                "show-toolbar-download"
              )}
              disabled={!(settings?.showToolbar ?? false)}
              checked={settings?.showToolbarDownload ?? false}
              label="Visa ladda ner"
              onChange={() => handleToggleSetting("showToolbarDownload")}
            ></Checkbox>
          </div>
        </section>

        <section>
          <Checkbox
            {...commonElementProps}
            className={classNames(
              commonElementProps.className,
              "third-party-doc-hide-chart"
            )}
            checked={settings?.hideTabs?.includes("diagram") ?? false}
            label="Dölj diagram"
            onChange={() => toggleTab("diagram")}
          ></Checkbox>
          <Checkbox
            {...commonElementProps}
            className={classNames(
              commonElementProps.className,
              "third-party-doc-hide-table"
            )}
            checked={settings?.hideTabs?.includes("table") ?? false}
            label="Dölj tabell"
            onChange={() => toggleTab("table")}
          ></Checkbox>
          <Checkbox
            {...commonElementProps}
            className={classNames(
              commonElementProps.className,
              "third-party-doc-hide-map"
            )}
            checked={settings?.hideTabs?.includes("map") ?? false}
            label="Dölj karta"
            onChange={() => toggleTab("map")}
          ></Checkbox>
          <Checkbox
            {...commonElementProps}
            className={classNames(
              commonElementProps.className,
              "third-party-doc-hide-info"
            )}
            checked={settings?.hideTabs?.includes("info") ?? false}
            label="Dölj info"
            onChange={() => toggleTab("info")}
          ></Checkbox>
        </section>
      </div>
    </AlertBox>
  );
}
