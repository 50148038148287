import { ColorPicker, IColor } from "@fluentui/react";
import { useState, useCallback } from "react";

import { FileDrop } from "../../../../components/FileDrop";
import { IframeContentPreview } from "../../../../components/IframeContent";
import { DefaultLoading } from "../../../../components/Loading";
import { CustomBranding, NavBar } from "../../../../components/NavBar";
import { OrganizationBrandingContext } from "../../../../lib/application/contexts";
import { useAppMessages } from "../../../../lib/application/hooks/useAppMessages";
import { uploadPublicImageBlob } from "../../../../lib/application/requests/common_requests";
import { defined } from "../../../../lib/core/defined";
import { Progress } from "../../../../lib/core/progress";
import { voidFunc } from "../../../../lib/core/voidFunc";
import { displayHttpErrorInternal } from "../../../../lib/infra/HttpResult";

import { Button } from "../../../../components/Button";

interface OrgBrandingProps {
  changePending: boolean;
  originalDocID: number;
  setChangePending: (pending: boolean) => void;
  handleSetBranding: (branding: CustomBranding) => void;
  currentBranding: CustomBranding | undefined;
  removeCurrentBranding: () => void;
}

export function OrgBranding({
  changePending,
  currentBranding,
  originalDocID,
  removeCurrentBranding,
  setChangePending,
  handleSetBranding,
}: OrgBrandingProps) {
  const [logoBigUrl, setLogoBigUrl] = useState<string | undefined>(
    currentBranding?.logoBigUrl
  );
  const [logoBigState, setLogoBigState] = useState(Progress.NotStarted);
  const [logoSmallUrl, setLogoSmallUrl] = useState<string | undefined>(
    currentBranding?.logoSmallUrl
  );
  const [logoSmallState, setLogoSmallState] = useState(Progress.NotStarted);
  const [packagedDocLogoLink, setPackagedDocLogoLink] = useState<
    string | undefined
  >();
  const [foregroundColor, setForegroundColor] = useState<string>("#000000");
  const [backgroundColor, setBackgroundColor] = useState<string>("#FFFFFF");

  const appMessages = useAppMessages();

  const handleLogoUpload = useCallback(
    (
      file: File,
      setter: (url: string) => void,
      progressSetter: (progress: Progress) => void
    ) => {
      // Here you would typically upload the file to your server
      // and get back a URL. For this example, we'll just use a fake URL
      progressSetter(Progress.InProgress);
      return uploadPublicImageBlob(file)
        .then((res) => {
          res.match({
            ok: (url) => {
              setter(url);
              progressSetter(Progress.Success);
            },
            err: (err) => {
              appMessages?.add(
                "error",
                "Failed to upload image: " + displayHttpErrorInternal(err)
              );
              progressSetter(Progress.Error);
            },
          });
        })
        .catch((err) => {
          progressSetter(Progress.Error);
        });
    },
    [appMessages]
  );

  const handleLogoBigUpload = useCallback(
    (file: File) => handleLogoUpload(file, setLogoBigUrl, setLogoBigState),
    [handleLogoUpload]
  );

  const handleLogoSmallUpload = useCallback(
    (file: File) => handleLogoUpload(file, setLogoSmallUrl, setLogoSmallState),
    [handleLogoUpload]
  );

  const handleSave = useCallback(() => {
    if (!defined(logoBigUrl) || !defined(logoSmallUrl)) {
      appMessages?.add("error", "Ladda upp båda loggorna först");
      return;
    }

    handleSetBranding({
      backgroundColor,
      foregroundColor,
      logoBigUrl,
      logoSmallUrl,
      packagedDocLogoLink,
    });
  }, [
    logoBigUrl,
    foregroundColor,
    backgroundColor,
    logoSmallUrl,
    handleSetBranding,
    packagedDocLogoLink,
    appMessages,
  ]);

  const handleRemoveBranding = useCallback(() => {
    setChangePending(true);
    removeCurrentBranding();
    setLogoBigUrl(undefined);
    setLogoSmallUrl(undefined);
    setPackagedDocLogoLink(undefined);
    setForegroundColor("#000000");
    setBackgroundColor("#FFFFFF");
  }, [setChangePending, removeCurrentBranding]);

  return (
    <div className="org-branding">
      <Button
        title="Ta bort branding"
        onClick={handleRemoveBranding}
        disabled={!defined(currentBranding)}
      />
      <div>
        <p>
          Börja med att ladda upp en logga (png/svg) för att aktivera branding.
          Loggornas bakgrunder bör vara transparenta.
        </p>
        <LogoUploadSection
          label="Stor logga (ca 100px x 24px)"
          handleSubmit={handleLogoBigUpload}
          progress={logoBigState}
          setProgress={setLogoBigState}
        />
        <LogoUploadSection
          label="Liten logga (ca 60px x 24px)"
          handleSubmit={handleLogoSmallUpload}
          progress={logoSmallState}
          setProgress={setLogoSmallState}
        />
      </div>

      {defined(logoBigUrl) && defined(logoSmallUrl) && (
        <div>
          <div className="settings">
            <section>
              <div>
                <label>Färg för text/ikoner</label>
                <ColorPicker
                  alphaType="none"
                  color={foregroundColor}
                  onChange={(_: any, color: IColor) => {
                    setForegroundColor(color.str);
                    setChangePending(true);
                  }}
                />
              </div>

              <div>
                <label>Bakgrundsfärg navbar</label>
                <ColorPicker
                  alphaType="none"
                  color={backgroundColor}
                  onChange={(_: any, color: IColor) => {
                    setBackgroundColor(color.str);
                    setChangePending(true);
                  }}
                />
              </div>
            </section>

            <section>
              <div className="margin-right-lg">
                <div>
                  <label>Stor logga</label>
                </div>
                <div>{logoBigUrl}</div>
                <div>
                  <img
                    src={logoBigUrl}
                    alt="Logo"
                    style={{
                      maxWidth: "200px",
                      maxHeight: "24px",
                      marginTop: "10px",
                    }}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label>Liten logga</label>
                </div>
                <div>{logoSmallUrl}</div>
                <div>
                  <img
                    src={logoSmallUrl}
                    alt="Logo"
                    style={{
                      maxWidth: "200px",
                      maxHeight: "24px",
                      marginTop: "10px",
                    }}
                  />
                </div>
              </div>
            </section>
          </div>

          <section>
            <h3>Förhandsgranskning</h3>
            <div onClickCapture={(e) => e.stopPropagation()}>
              <OrganizationBrandingContext.Provider
                value={{
                  backgroundColor: backgroundColor,
                  foregroundColor: foregroundColor,
                  logoSmallUrl: logoSmallUrl,
                  logoBigUrl: logoBigUrl,
                  packagedDocLogoLink: packagedDocLogoLink,
                }}
              >
                <NavBar
                  logout={voidFunc}
                  toggleDataAdminMode={voidFunc}
                  toggleShowDraftData={voidFunc}
                />
              </OrganizationBrandingContext.Provider>
            </div>
            <div className="margin-top-md" style={{ width: 500, height: 50 }}>
              <IframeContentPreview key={originalDocID} title="preview">
                <OrganizationBrandingContext.Provider
                  value={{
                    backgroundColor: backgroundColor,
                    foregroundColor: foregroundColor,
                    logoSmallUrl: logoSmallUrl,
                    logoBigUrl: logoBigUrl,
                    packagedDocLogoLink: packagedDocLogoLink,
                  }}
                >
                  <NavBar
                    logout={voidFunc}
                    toggleDataAdminMode={voidFunc}
                    toggleShowDraftData={voidFunc}
                  />
                </OrganizationBrandingContext.Provider>
              </IframeContentPreview>
            </div>
          </section>

          <div>
            <Button
              intent="primary"
              title="Spara"
              onClick={handleSave}
              disabled={!changePending}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function LogoUploadSection(props: {
  label: string;
  handleSubmit: (file: File) => void;
  progress: Progress;
  setProgress: (progress: Progress) => void;
}) {
  return (
    <section>
      <label>{props.label}</label>
      {props.progress !== Progress.Success && (
        <FileDrop
          accept=".svg,.png"
          onSubmit={(file) => {
            props.handleSubmit(file);
          }}
          multi={false}
        />
      )}
      {props.progress === Progress.InProgress && <DefaultLoading />}
      {props.progress === Progress.Success && <p>Bild uppladdad.</p>}
      {props.progress === Progress.Success && (
        <div>
          <Button
            small
            title="Ändra"
            onClick={() => props.setProgress(Progress.NotStarted)}
          />
        </div>
      )}
    </section>
  );
}
