import { classNames } from "../lib/core/classNames";
import "./MainContent.scss";

export function MainContent(props: {
  children: JSX.Element[] | JSX.Element;
  className?: string;
}) {
  return (
    <div
      id="main-content-container"
      data-is-scrollable="true"
      className={props.className}
    >
      <div id="main-content">{props.children}</div>
    </div>
  );
}

/**
 * Uses same layout/positioning as MainContent but can be used multiple times
 */
export function PageContentContainer(props: {
  children: JSX.Element[] | JSX.Element;
  className?: string;
  classNameInner?: string;
}) {
  return (
    <div className={classNames("page-content-container", props.className)}>
      <div className={classNames("page-content", props.classNameInner)}>
        {props.children}
      </div>
    </div>
  );
}
