import { config } from "../../../../config";
import {
  AdminDocListDto,
  AdminDocListDtoRT,
  AdminEmbeddedDocListDto,
  AdminEmbeddedDocListDtoRT,
  DocumentVersionsDtoRT,
} from "../../../infra/api_responses/admin/document_versions";
import {
  DocumentDto,
  DocumentDtoRT,
} from "../../../infra/api_responses/document";
import {
  EmbeddedDocumentDto,
  EmbeddedDocumentDtoRT,
} from "../../../infra/api_responses/document_embed";
import { HttpResult } from "../../../infra/HttpResult";
import { WorkspaceLatest } from "../../state/stats/workspace/shared";
import { authedRequest, decodedAuthedRequest } from "../shared";

export function updateDocument(
  id: number,
  workspace: WorkspaceLatest
): Promise<HttpResult<void>> {
  return authedRequest(
    config.apis.statsV1,
    `/admin/documents/${id}`,
    { data: workspace },
    "PUT"
  );
}

export function getDocument(id: number): Promise<HttpResult<DocumentDto>> {
  return decodedAuthedRequest<DocumentDto>(
    config.apis.statsV1,
    `/admin/documents/${id}`,
    undefined,
    "GET",
    DocumentDtoRT
  );
}

export function listDocumentsByVersion(
  version: number
): Promise<HttpResult<AdminDocListDto>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `/admin/documents/versions/${version}`,
    undefined,
    "GET",
    AdminDocListDtoRT
  );
}

export function listDocumentVersions() {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `/admin/documents/versions`,
    undefined,
    "GET",
    DocumentVersionsDtoRT
  );
}

export function listEmbeddedDocumentVersions() {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `/admin/documents/embedded/versions`,
    undefined,
    "GET",
    DocumentVersionsDtoRT
  );
}

export function getEmbeddedDocument(
  embedKey: string
): Promise<HttpResult<EmbeddedDocumentDto>> {
  return decodedAuthedRequest<EmbeddedDocumentDto>(
    config.apis.statsV1,
    `/admin/documents/embedded/${embedKey}`,
    undefined,
    "GET",
    EmbeddedDocumentDtoRT
  );
}

export function updateEmbeddedDocument(
  key: string,
  workspace: WorkspaceLatest
): Promise<HttpResult<void>> {
  return authedRequest(
    config.apis.statsV1,
    `/admin/documents/embedded/${key}`,
    { data: workspace },
    "PUT"
  );
}

export function listEmbeddedDocumentsByVersion(
  version: number
): Promise<HttpResult<AdminEmbeddedDocListDto>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `/admin/documents/embedded/versions/${version}`,
    undefined,
    "GET",
    AdminEmbeddedDocListDtoRT
  );
}
