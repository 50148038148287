import { PageBreakType } from "../../lib/application/state/stats/document-core/core";
import "./page_breaks.scss";

const pageBreakIndicator = "-------- Sidbrytning --------";
export function PageBreakBefore(props: { breakSetting?: PageBreakType }) {
  const breakSetting = props.breakSetting;
  if (breakSetting === "before" || breakSetting === "both") {
    return (
      <div className="page-break-before page-break-indicator">
        <span>{pageBreakIndicator}</span>
      </div>
    );
  }
  return null;
}

export function PageBreakAfter(props: { breakSetting?: PageBreakType }) {
  const breakSetting = props.breakSetting;
  if (breakSetting === "after" || breakSetting === "both") {
    return (
      <div className="page-break-after page-break-indicator">
        <span>{pageBreakIndicator}</span>
      </div>
    );
  }
  return null;
}
