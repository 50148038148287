import "./InfoBox.scss";

import { defined } from "../lib/core/defined";
import { classNames } from "../lib/core/classNames";
import React from "react";

interface Props {
  mainHeader?: string;
  className?: string;
  sections: {
    header?: string;
    text: string;
  }[];
}

export function InfoBox(props: Props) {
  const { mainHeader, sections } = props;
  return (
    <div className={classNames(props.className, "infobox")}>
      {defined(mainHeader) && <h3>{mainHeader}</h3>}
      {sections.map((section, index) => (
        <div key={section.header + "" + index}>
          {defined(section.header) && <h4>{section.header}</h4>}
          <p>{section.text}</p>
        </div>
      ))}
    </div>
  );
}
export function InfoBoxFoldout(props: {
  className?: string;
  title: string;
  children: JSX.Element[];
}) {
  return (
    <details className={classNames(props.className, "infobox")}>
      <summary>{props.title}</summary>
      <div className="content">{props.children}</div>
    </details>
  );
}
