import mapbox, { LinePaint } from "mapbox-gl";
import { useEffect, useState } from "react";

import { config } from "../../../../../config";
import { InfostatBoundsToMapboxBounds } from "../../../../../lib/application/cartography/mapbox";
import { InfostatBoundingBox } from "../../../../../lib/domain/cartography/types";

import {
  desoSource,
  desoBorderLayerId,
  desoSourceLayer,
  SELECTION_DEFAULT_COLOR,
  desoLayerId,
  regsoSource,
  regsoBorderLayerId,
  regsoSourceLayer,
  regsoLayerId,
  desoRegsoLabelsLayerId,
  desoRegsoLabelsSourceId,
} from "./shared";

export const MAPBOX_LABELS_LAYER = "settlement-subdivision-label";
export const MAPBOX_LABEL_LAYERS_ALL = [
  MAPBOX_LABELS_LAYER,
  "country-label",
  "state-label",
  "settlement-major-label",
  "settlement-minor-label",
  "path-pedestrian-label",
  "water-point-label",
  "water-line-label",
  "natural-point-label",
  "natural-line-label",
  "waterway-label",
  "road-label-simple",
  "airport-label",
];

export function useMapSetup(
  containerId: string,
  startingBounds: InfostatBoundingBox,
  fullscreen: boolean
): mapbox.Map | undefined {
  const [map, setMap] = useState<mapbox.Map>();
  const [mapboxLoaded, setMapboxLoaded] = useState(false);

  useEffect(() => {
    mapbox.accessToken = config.mapbox.apiToken;
    const mapboxMap = new mapbox.Map({
      // Disable all interactivity handlers when first loading map
      scrollZoom: false,
      dragRotate: false,
      dragPan: false,
      touchZoomRotate: false,
      doubleClickZoom: false,

      container: containerId, // container ID
      style: config.mapbox.styleUrl,
      bounds: InfostatBoundsToMapboxBounds(startingBounds),
    });
    mapboxMap.on("load", () => {
      // DeSO layers
      mapboxMap.addSource(desoSource, {
        type: "vector",
        url: "mapbox://erik-infostat.70hecid0",
      });
      mapboxMap.addLayer(
        {
          id: desoBorderLayerId,
          source: desoSource,
          "source-layer": desoSourceLayer,
          type: "line",
          paint: {
            "line-color": SELECTION_DEFAULT_COLOR,
            "line-opacity": 0.4,
            "line-width": 1,
          },
          layout: { visibility: "none" },
          minzoom: 6,
        },
        MAPBOX_LABELS_LAYER
      );
      mapboxMap.addLayer(
        {
          id: desoLayerId,
          source: desoSource,
          "source-layer": desoSourceLayer,
          type: "fill",
          layout: { visibility: "none" },
          paint: {
            "fill-color": [
              "to-color",
              ["feature-state", "color"],
              "rgba(0,0,0,0)",
            ],
            "fill-outline-color": [
              "to-color",
              ["feature-state", "borderColor"],
              "rgba(0,0,0,0)",
            ],
          },
        },
        MAPBOX_LABELS_LAYER
      );

      // RegSO layers
      mapboxMap.addSource(regsoSource, {
        type: "vector",
        url: "mapbox://erik-infostat.4dwubvei",
      });
      mapboxMap.addLayer(
        {
          id: regsoBorderLayerId,
          source: regsoSource,
          "source-layer": regsoSourceLayer,
          type: "line",
          layout: { visibility: "none" },
          paint: {
            "line-color": SELECTION_DEFAULT_COLOR,
            "line-opacity": 0.4,
            "line-width": 1,
          },
          minzoom: 5,
        },
        MAPBOX_LABELS_LAYER
      );
      mapboxMap.addLayer(
        {
          id: regsoLayerId,
          source: regsoSource,
          "source-layer": regsoSourceLayer,
          type: "fill",
          layout: { visibility: "none" },
          paint: {
            "fill-color": [
              "to-color",
              ["feature-state", "color"],
              "rgba(0,0,0,0)",
            ],
          },
        },
        MAPBOX_LABELS_LAYER
      );

      const outlinePaint: LinePaint = {
        "line-color": [
          "to-color",
          ["feature-state", "borderColor"],
          "rgba(0,0,0,0)",
        ],
        "line-width": 2,
      };

      mapboxMap.addLayer({
        id: "regso-outlines",
        source: regsoSource,
        type: "line",
        "source-layer": regsoSourceLayer,
        paint: outlinePaint,
      });

      mapboxMap.addLayer({
        id: "deso-outlines",
        source: desoSource,
        type: "line",
        "source-layer": desoSourceLayer,
        paint: outlinePaint,
      });

      // Label Source and Layer
      mapboxMap.addSource(desoRegsoLabelsSourceId, {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });

      mapboxMap.addLayer(
        {
          id: desoRegsoLabelsLayerId,
          type: "symbol",
          source: desoRegsoLabelsSourceId,
          layout: {
            "text-field": ["get", "label"],
            "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
            "text-size": 12,
            "text-anchor": "center",
            "text-allow-overlap": true,
          },
          paint: {
            "text-color": "#000000",
          },
        },
        MAPBOX_LABELS_LAYER
      );

      setMapboxLoaded(true);
    });

    setMap(mapboxMap);

    return () => {
      mapboxMap.remove();
      setMapboxLoaded(false);
      setMap(undefined);
    };
    // We don't want to run this effect every time the stored map location is updated, only on first load,
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerId, setMap, setMapboxLoaded, fullscreen]);

  if (mapboxLoaded) {
    return map;
  }
}
