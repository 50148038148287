import { RoleDto } from "../requests/docs/dto";

export type NodeTypeRegular = "document" | "folder";

export type NodePath = {
  nodeId: number;
  title: string;
}[];

export type ChildNodeInfo = {
  id: number;
  type: NodeTypeRegular;
  title: string;
  path?: NodePath;
  lastModified: Date;
  children?: ChildNodeInfo[];

  isShared?: boolean;
  hasShortcut?: boolean;
  isFavorite?: boolean;
  belongsToHelpLibrary: boolean;
  belongsToSampleLibrary: boolean;
  roles: RoleDto[];
  ownership: { type: "owner" } | { type: "shared"; owner_email: string };
};

export type FileNodeInfo =
  | {
      type: "root";
      children?: ChildNodeInfo[];
    }
  | ChildNodeInfo;

export function matchNodeTypeRegular(
  type: NodeTypeRegular,
  matcher: {
    [key in NodeTypeRegular]: () => string;
  }
): string {
  return matcher[type]();
}

export function displayNodeTypeDefinite(type: NodeTypeRegular): string {
  return matchNodeTypeRegular(type, {
    document: () => "dokumentet",
    folder: () => "mappen",
  });
}
