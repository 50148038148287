import { useRecoilState } from "recoil";
import _ from "lodash";

import { geoExpansionsQuery } from "../../../../../lib/application/state/stats/document-core/queries/dataCard";
import { useGeoSelection } from "../../../../../lib/application/state/actions/selections/useChangeGeoSelection";
import {
  allGeoTypes,
  GeographiesSerializable,
  GeoType,
} from "../../../../../lib/domain/geography";
import { Geo } from "../data_card/GeoSelect";
import { DataSelection } from "../../../../../lib/domain/selections/definitions";
import { FluentModal, FluentModalBody } from "../../../../../components/Modal";
import { useResetGeoSelection } from "../../../../../lib/application/state/actions/selections/useResetGeoSelection";
import { useContext } from "react";
import { ShowDraftDataContext } from "../../../../../lib/application/contexts";

import "./DataCardGeoModal.scss";

const isGrouping = false;

/**
 * Geo selection modal for data cards.
 * Used for previews and embedded cards.
 */
export function DataCardGeoModal(props: {
  cardStateId: string;
  supportedGeographies: GeoType[];
  geographies: GeographiesSerializable;
  handleClose: () => void;
  primarySelection: DataSelection | undefined;
}) {
  const { geographies, primarySelection, supportedGeographies } = props;
  const queryProps = { cardStateId: props.cardStateId };
  const [expansions, setExpansions] = useRecoilState(
    geoExpansionsQuery(queryProps)
  );

  const adminShowDraftData = useContext(ShowDraftDataContext);

  const [selections, setSelections] = useGeoSelection(
    props.cardStateId,
    geographies,
    adminShowDraftData
  );
  const resetGeoSelections = useResetGeoSelection(
    supportedGeographies,
    setSelections,
    setExpansions
  );

  const onlyCountrySupported =
    supportedGeographies.length === 1 && supportedGeographies[0] === "country";

  return (
    <FluentModal
      isOpen={true}
      onClose={props.handleClose}
      title="Geografiska områden"
      containerClassName="data-card-geo-modal"
    >
      <FluentModalBody>
        <div className="data-geo">
          <Geo
            isGroupingMode={isGrouping}
            supportedGeographies={supportedGeographies}
            disabled={
              primarySelection?.measureSelection?.valueType === "survey" ||
              onlyCountrySupported ||
              isGrouping
            }
            geographies={geographies}
            selection={_.chain(selections)
              .values()
              .flatten()
              .map((item) => item.geocode)
              .value()}
            expansions={expansions}
            onReset={() => {
              resetGeoSelections();
            }}
            onExpand={(geocode, expanded) => {
              if (expanded) {
                setExpansions(expansions.concat(geocode));
              } else {
                setExpansions(expansions.filter((g) => g !== geocode));
              }
            }}
            onSelect={(geoItems, selected) => {
              const selectionsCopy = { ...selections };
              const newlySelected = _.groupBy(geoItems, (item) => item.type);
              for (const key of allGeoTypes) {
                if (selected) {
                  selectionsCopy[key] = _.unionBy(
                    selectionsCopy[key],
                    newlySelected[key],
                    (item) => item.geocode
                  );
                } else {
                  selectionsCopy[key] = _.differenceBy(
                    selectionsCopy[key],
                    newlySelected[key],
                    (item) => item.geocode
                  );
                }
              }
              setSelections(selectionsCopy);
            }}
          ></Geo>
        </div>
      </FluentModalBody>
    </FluentModal>
  );
}
