import { chain } from "lodash";
import { defined } from "../../../../../core/defined";
import { calcTimespanUnion } from "../../../../../domain/measure";
import {
  DateRangeRaw,
  MeasureSelection,
  MeasureSelectionGrouping,
} from "../../../../../domain/measure/definitions";
import { DocCardMicro, DocCardStats } from "../core";
import { ColorSchemeContainer } from "../../document-style/definitions";

export type SelectableTime =
  | {
      type: "range";
      range: DateRangeRaw;
    }
  | {
      type: "time-points";
      timePoints: string[];
    };

export function getStandardMeasureSelections(
  cardState: DocCardStats
): MeasureSelection[] {
  return cardState.data.dataSelections
    .map((selection) => selection.measureSelection)
    .filter(defined);
}

export function getGroupingMeasureSelection(
  cardState: DocCardStats
): MeasureSelectionGrouping | undefined {
  return cardState.data.groupingSelection?.measureSelection;
}

export function availableTimespanDataCard(
  cardState: DocCardStats
): SelectableTime | undefined {
  const measureSelections = getStandardMeasureSelections(cardState);
  if (measureSelections.length === 0) {
    return;
  }

  const timeRanges: DateRangeRaw[] = chain([
    ...measureSelections.map((m) => m.availableDates),
    getGroupingMeasureSelection(cardState)?.availableDates,
  ])
    .filter(defined)
    .filter((range) => range.length > 0)
    .map<DateRangeRaw>((dates) => [dates[0], dates[dates.length - 1]])
    .value();

  const selectableRange =
    timeRanges.length > 0 ? calcTimespanUnion(timeRanges) : undefined;
  if (defined(selectableRange)) {
    return {
      type: "range",
      range: selectableRange,
    };
  }
}

export function cardColors(
  card: DocCardStats | DocCardMicro
): ColorSchemeContainer | undefined {
  return card.data.loadedData?.chartDataState?.loadedChartData
    ?.colorSchemeContainer;
}
