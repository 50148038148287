import { IconButton, Modal } from "@fluentui/react";
import { classNames } from "../lib/core/classNames";

import "./Modal.scss";

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element | JSX.Element[];
  containerClassName?: string;
  width?: "md" | "lg";
  grow?: boolean;
  coverViewport?: boolean;
}

/**
 * To be used with FluentModalBody & FluentModalFooter
 */
export function FluentModal(props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onDismiss={props.onClose}
      allowTouchBodyScroll={true}
      containerClassName={classNames(
        "fluent-modal-container",
        props.containerClassName,
        props.width ? `width-${props.width}` : "",
        props.grow ? "grow" : "",
        props.coverViewport ? "cover-viewport" : ""
      )}
      scrollableContentClassName={classNames("fluent-modal-scrollable")}
    >
      <div className="fluent-modal-header">
        <h2>{props.title}</h2>
        <IconButton
          iconProps={{ iconName: "cross" }}
          ariaLabel="Close popup modal"
          onClick={props.onClose}
        />
      </div>
      {props.children}
    </Modal>
  );
}

/**
 * Modal element that covers the full height of the viewport (including padding).
 * This makes it possible to adjust which parts of the modal should be scrollable.
 *
 * To be used with FluentModalBody & FluentModalFooter
 */
export function FluentModalTall(props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      onDismiss={props.onClose}
      allowTouchBodyScroll={true}
      containerClassName={classNames(
        "fluent-modal-container-tall",
        props.containerClassName,
        props.width ? `width-${props.width}` : "",
        props.grow ? "grow" : "",
        props.coverViewport ? "cover-viewport" : ""
      )}
      scrollableContentClassName={classNames("fluent-modal-scrollable")}
    >
      <div className="fluent-modal-header">
        <h2>{props.title}</h2>
        <IconButton
          iconProps={{ iconName: "cross" }}
          ariaLabel="Close popup modal"
          onClick={props.onClose}
        />
      </div>
      {props.children}
    </Modal>
  );
}

export function FluentModalBody(props: {
  children: JSX.Element | JSX.Element[];
}) {
  return <div className="fluent-modal-body">{props.children}</div>;
}

export function FluentModalFooter(props: {
  children: JSX.Element | JSX.Element[];
}) {
  return <div className="fluent-modal-footer">{props.children}</div>;
}
