import {
  Optional,
  Null as NullRT,
  Boolean as BooleanRT,
  Record,
  Array as ArrayRT,
  String as StringRT,
  Number as NumberRT,
  Literal,
  Union,
  Static,
  Partial,
} from "runtypes";

const RoleDtoRT = Union(Literal("owner"), Literal("editor"), Literal("viewer"));
const RoleObjectDtoRT = Record({
  id: NumberRT,
  role: RoleDtoRT,
});

export const DocumentMetadataRT = Record({
  id: NumberRT,
  title: StringRT,
  version: NumberRT,
  locked: Optional(Record({ by_you: BooleanRT, until: NumberRT })),
});
export type DocumentMetadataDto = Static<typeof DocumentMetadataRT>;

const OwnershipRT = Union(
  Record({ type: Literal("owner") }),
  Record({ type: Literal("shared"), owner_email: StringRT })
);
export const NodesChildDtoRT = Record({
  id: NumberRT,
  type: Literal("folder").Or(Literal("document")),
  title: StringRT,
  path: Optional(ArrayRT(Record({ node_id: NumberRT, title: StringRT }))),

  has_shortcut: Optional(BooleanRT),
  is_shared: Optional(BooleanRT),
  is_favorite: Optional(BooleanRT),
  belongs_to_sample_library: Optional(BooleanRT),
  belongs_to_help_library: Optional(BooleanRT),
  belongs_to_help_lib: Optional(BooleanRT),

  ownership: OwnershipRT,
  roles: ArrayRT(RoleDtoRT),
  last_update: StringRT,
});

export type NodesChildDto = Static<typeof NodesChildDtoRT>;

export const SharingInfoPartDtoRT = Record({
  global_roles: ArrayRT(RoleObjectDtoRT).Or(Literal(null)),
  org_roles: ArrayRT(RoleObjectDtoRT).Or(Literal(null)),
  user_roles: ArrayRT(RoleObjectDtoRT).Or(Literal(null)),
});

const SingleShareBaseRT = Record({
  role: RoleDtoRT,
  explicit: BooleanRT,
});
const SingleShareBaseWithIdRT = SingleShareBaseRT.And(
  Record({
    sharing_id: NumberRT,
  })
);

export const SharingInfoDtoRT = Record({
  node_id: NumberRT,
  user_role: Optional(
    Record({
      explicit: BooleanRT,
      role: RoleDtoRT,
    })
  ),
  shares: Optional(
    Partial({
      organization: SingleShareBaseWithIdRT.Or(NullRT),
      links: ArrayRT(SingleShareBaseRT.And(Record({ link: StringRT }))).Or(
        NullRT
      ),
      users: ArrayRT(
        SingleShareBaseWithIdRT.And(Record({ user_email: StringRT }))
      ).Or(NullRT),
      sample_library: SingleShareBaseWithIdRT.Or(NullRT),
      help_library: SingleShareBaseWithIdRT.Or(NullRT),
    })
  ),
  ownership: OwnershipRT,
  /** @deprecated */
  implicit_shares: SharingInfoPartDtoRT,
  /** @deprecated */
  explicit_shares: SharingInfoPartDtoRT,
  belongs_to_sample_library: BooleanRT,
});

const NodesDtoRootRT = Record({
  type: Literal("root"),
});
const NodesChildFullDtoRT = NodesChildDtoRT.And(
  Partial({
    children: Optional(ArrayRT(NodesChildDtoRT).Or(NullRT)),
  })
);
const NodesDtoRootFullRT = NodesDtoRootRT.And(
  Partial({
    children: Optional(ArrayRT(NodesChildFullDtoRT).Or(NullRT)),
  })
);
export const NodesDtoRT = Union(NodesDtoRootFullRT, NodesChildFullDtoRT);

export const SampleLibraryRootDtoRT = Record({
  id: StringRT,
  title: StringRT,
});

export const SampleLibraryRootsDtoRT = ArrayRT(SampleLibraryRootDtoRT).Or(
  NullRT
);

export const CreateShareLinkRT = StringRT;
export type CreateShareLink = Static<typeof CreateShareLinkRT>;

export type SampleLibraryRootDto = Static<typeof SampleLibraryRootDtoRT>;
export type RoleObjectDto = Static<typeof RoleObjectDtoRT>;
export type RoleDto = Static<typeof RoleDtoRT>;
export type SharingInfoDto = Static<typeof SharingInfoDtoRT>;
export type NodesDto = Static<typeof NodesDtoRT>;
export type NodeChildFullDto = Static<typeof NodesChildFullDtoRT>;
