import { DimensionValueV2Dto } from "../../../domain/measure/definitions";
import {
  AggregationMethod,
  GroupingMunicipalitiesDto,
  RowValueRegularRaw,
} from "../../../infra/api_responses/dataset";
import { SurveyRowValue } from "../../../infra/api_responses/survey_dataset";
import { DataOutputSettings } from "../../state/stats/document-core/DataOutputSettings";
import { ChartData } from "../shared/chart_data/ChartData";
import { RowBaseInterface } from "../shared/row";

export interface DetailedDimensionInfo {
  dataColumn: string;
  dimensionId: number;
  parentId?: number;
  values: DimensionValueV2Dto[];
}

/** Dict from dimension -> Dict<label, value> */
export interface ValuesByDimensionAndLabel {
  [dim: string]: {
    [label: string]: DimensionValueV2Dto | undefined;
  };
}

/**
 * Labels always have a text representation. Sometimes they also have an id, which if it exists
 * must be used for internal logic and storage.
 */
export type LabelsByDimension = [dimension: string, labels: string[]][];
export type LabelsWithIdsByDimension = [
  dimension: string,
  labels: { text: string; id?: number }[]
][];

export type ClassBoundaries = Array<{
  label: string;
  minExclusive?: string;
  maxInclusive?: string;
}>;

export type ClassBoundariesNumeric = Array<{
  label: string;
  minExclusive?: number;
  maxInclusive?: number;
}>;

/**
 * Common interface for original source data.
 * Does not represent user-defined custom overrides!
 */
export interface DataSourceInfo {
  aggregationMethod?: AggregationMethod;
  aggregationNote?: string;
  // Longer description of the data source
  sourceLong?: string;
  // Short description of the data source
  source: string;
  descriptionLong?: string[];
  measureTitle?: string;
  methodDescription?: string;
  /** This is YYYY-MM-DD string set in the database, so we want to display it without the conversion to higher-precision date */
  lastUpdate?: string;
  /** datetime string with timezone.
   * If available, this overrides the lastUpdate property. */
  lastFetchedFromSource?: string;
  sourceUrl?: string;
  unitLabel?: string;
  externalSource?: string;
  externalDescription?: string;
  externalDescriptionLong?: string[];
  classBoundaries?: ClassBoundaries;
  classBoundariesNumeric?: ClassBoundariesNumeric;
  groupingMunicipalities?: GroupingMunicipalitiesDto;
  surveyTargetAudience?: string;
}

export function displayAggregationMethod(
  method: AggregationMethod,
  useLongVersion: boolean
): string | undefined {
  switch (method) {
    case "avg":
    case "avg-weighted":
      if (useLongVersion) {
        return "medelvärden vägda på befolkningsantal";
      }
      return "medelvärden";
    case "sum":
      return "summor";
    case "none":
      return undefined;
  }
}

/**
 * The type of value output. Useful for determining whether additional computation can be
 * based on the output data.
 */
export type DatasetOutputType = "numeric" | "text";

export interface DatasetGeneric<
  RowValue extends RowValueRegularRaw | SurveyRowValue,
  RowProcessed extends RowBaseInterface<RowValue>
> {
  chartData?: () => ChartData<RowValue, RowProcessed>;
  labelsByDimension(): LabelsByDimension;
  /** Structure for lookup of labels/ids. Includes values that are to be filtered in chart/table/etc output. */
  labelsWithIdsByDimension(): LabelsWithIdsByDimension;
  /**
   * Data dimensions (i.e. no metadata dimensions), excluding the primary value dimension.
   */
  nonPrimaryDataDimensions(): string[];
  eligibleDimensionsComputedVariable(): string[];

  /** Indicates whether order of dimensions can be changed in order to change appearance of charts/tables */
  canChangeDimensionOrder(): boolean;

  isChartable(): boolean;
  outputType: DatasetOutputType;
  /**
   * Supports manually setting the number of decimals to display.
   */
  supportsDecimalModes: boolean;

  /**
   * This dataset can be used to make a computed value. Not possible if it's not numeric,
   * or does not have any varying dimensions.
   */
  canMakeComputedValue: boolean;

  /**
   * If settings match existing settings, return the existing instance,
   * otherwise return a new instance created with given settings but all
   * other params the same.
   */
  copyWithSettings(
    settings: DataOutputSettings
  ): DatasetGeneric<RowValue, RowProcessed>;

  internalLabel(): string | undefined;
  infostatPublicComment(): string[];

  /** Original source info */
  sourceInfo: DataSourceInfo | undefined;
  /** Original source info */
  groupingSourceInfo: DataSourceInfo | undefined;
}
