import { FontIcon, TextField } from "@fluentui/react";
import * as React from "react";

import { config } from "../../../../../config";
import { useKeyUpEnter } from "../../../../../lib/application/hooks/useKeyUp";
import { useLocallyMirroredInputState } from "../../../../../lib/application/hooks/useLocallyMirroredInputState";
import { cardLabelQuery } from "../../../../../lib/application/state/stats/document-core/queries/card";
import { defined } from "../../../../../lib/core/defined";
import { useSaveCurrentCards } from "../../../../../lib/application/state/actions/useSaveDocument";

export function TabContainer(props: {
  containerKey?: string | number;
  children: JSX.Element[];
}) {
  return (
    <div key={props.containerKey} className="card-tab-content">
      {props.children}
    </div>
  );
}

export function TitleContainer(props: { isEditing: boolean; cardId: string }) {
  const handleSave = useSaveCurrentCards();

  // Create a ref to store the current afterSetGlobal function
  const afterSetGlobalRef = React.useRef(() => {});

  // Update the ref whenever handleSave changes
  React.useEffect(() => {
    afterSetGlobalRef.current = handleSave;
  }, [handleSave, props.cardId]);

  const [label, setLabel] = useLocallyMirroredInputState<string>(
    cardLabelQuery({ cardStateId: props.cardId }),
    {
      afterSetGlobal: () => afterSetGlobalRef.current(), // Use the current value of the ref
    }
  );
  const fieldRef = React.useRef<HTMLInputElement>(null);
  useKeyUpEnter(() => {
    const current = fieldRef.current?.getElementsByTagName("input")?.[0];
    if (defined(current)) {
      current.blur();
    }
  }, fieldRef);

  return (
    <span>
      {props.isEditing ? (
        <TextField
          elementRef={fieldRef}
          maxLength={config.cardTitleMaxLength}
          key={props.cardId}
          onChange={(event) => {
            setLabel(event.currentTarget.value);
          }}
          className="card-title-input"
          value={label}
        ></TextField>
      ) : (
        label
      )}
    </span>
  );
}

export function TitleActionsContainer(props: {
  setEditing: (on: boolean) => void;
  contextMenuContainerRef: React.RefObject<HTMLDivElement>;
  setShowContextMenu: (on: boolean) => void;
  showContextMenu: boolean;
  isEditing: boolean;
  hasEditDocumentAccess: boolean;
  hideMenu?: boolean;
}) {
  const {
    isEditing,
    contextMenuContainerRef,
    showContextMenu,
    hasEditDocumentAccess,
  } = props;
  return (
    <div className="actions-container">
      {hasEditDocumentAccess && (
        <>
          {isEditing ? (
            <div
              className="menu-button subtle"
              onClick={() => props.setEditing(false)}
            >
              <FontIcon iconName="tick"></FontIcon>
            </div>
          ) : (
            <div
              className="menu-button subtle"
              onClick={() => props.setEditing(true)}
            >
              <FontIcon iconName="edit"></FontIcon>
            </div>
          )}
        </>
      )}
      {!props.hideMenu && (
        <div
          className="menu-button"
          ref={contextMenuContainerRef}
          onClick={() => props.setShowContextMenu(!showContextMenu)}
        >
          <FontIcon iconName="menu"></FontIcon>
        </div>
      )}
    </div>
  );
}

export function TitleActionsContainerPythonCard(props: {
  contextMenuContainerRef: React.RefObject<HTMLDivElement>;
  setShowContextMenu: (on: boolean) => void;
  showContextMenu: boolean;
}) {
  return (
    <div className="actions-container">
      <div
        className="menu-button"
        ref={props.contextMenuContainerRef}
        onClick={() => props.setShowContextMenu(!props.showContextMenu)}
      >
        <FontIcon iconName="menu"></FontIcon>
      </div>
    </div>
  );
}
