import { useCallback, useContext, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { defined } from "../../../core/defined";
import { ISharingInfo, SharingInfo } from "../../files/SharingInfo";
import { cardEditModeQuery, cardQuery } from "./document-core/queries/card";
import { DocumentMetadata } from "./document-meta/DocumentMetadata";
import { reportEditModeOnQuery } from "./document-meta/queries";
import { useDocumentMetadataValue } from "./useMetadata";
import { UserInfoContext } from "../../contexts";
import { hasWriteAccessForCardType } from "../../auth/permissions";
import { useSaveCard } from "../actions/useSaveDocument";
import { DocCardStateNonError } from "./document-core/core";

export function useCardEditMode(
  cardId: string,
  sharingInfo: ISharingInfo
): {
  isEditingCard: boolean;
  isEditingDocument: boolean;
  hasEditDocumentAccess: boolean;
  setCardEditing: (isEditing: boolean) => void;
} {
  const [card, setCard] = useRecoilState(cardQuery(cardId));
  const metadata = useDocumentMetadataValue();
  const documentEditModeOn =
    useRecoilValue(reportEditModeOnQuery) && sharingInfo.canEdit();
  const editorIsEditing = useRecoilValue(
    cardEditModeQuery({ cardStateId: cardId })
  );

  const handleSave = useSaveCard();
  const setCardEditing = useCallback(
    (editing: boolean) => {
      if (card.type === "error") {
        return;
      }
      if (!documentEditModeOn) {
        return;
      }
      const updatedCard: DocCardStateNonError = {
        ...card,
        isEditing: editing,
      };
      setCard(updatedCard);
      handleSave?.(updatedCard);
    },
    [card, documentEditModeOn, handleSave, setCard]
  );
  const user = useContext(UserInfoContext);
  const hasWriteAccess = defined(user)
    ? hasWriteAccessForCardType(card, user)
    : false;

  const currentUserEditModeOn =
    documentEditModeOn && metadata?.lockedByOther() !== true;

  const canEdit = hasWriteAccess && sharingInfo.canEdit();
  if (!canEdit) {
    return {
      isEditingCard: false,
      isEditingDocument: false,
      hasEditDocumentAccess: false,
      setCardEditing: () => {},
    };
  }

  return {
    isEditingCard: currentUserEditModeOn && editorIsEditing && canEdit,
    isEditingDocument: currentUserEditModeOn,
    hasEditDocumentAccess: canEdit,
    setCardEditing,
  };
}

type DocumentEditModeStatus = {
  isEditingDocument: boolean;
  hasEditDocumentAccess: boolean;
};

function calculateDocumentEditMode(
  hasEditAccess: boolean,
  docEditModeOn: boolean,
  metadata?: DocumentMetadata
): DocumentEditModeStatus {
  if (!defined(metadata)) {
    return { isEditingDocument: false, hasEditDocumentAccess: false };
  }

  if (!hasEditAccess || metadata.lockedByOther()) {
    return {
      isEditingDocument: false,
      hasEditDocumentAccess: false,
    };
  }

  return {
    isEditingDocument: docEditModeOn,
    hasEditDocumentAccess: hasEditAccess,
  };
}

export function useDocumentEditModeOptionalMetadata(
  sharingInfo: SharingInfo,
  metadata?: DocumentMetadata
): DocumentEditModeStatus {
  const editModeOn = useRecoilValue(reportEditModeOnQuery);
  const hasEditAccess = sharingInfo.canEdit();
  return useMemo(() => {
    if (!defined(metadata)) {
      return { isEditingDocument: false, hasEditDocumentAccess: false };
    }
    return calculateDocumentEditMode(hasEditAccess, editModeOn, metadata);
  }, [editModeOn, hasEditAccess, metadata]);
}

export function useDocumentEditMode(
  sharingInfo: ISharingInfo,
  metadata?: DocumentMetadata
): DocumentEditModeStatus {
  const editModeOn = useRecoilValue(reportEditModeOnQuery);
  const hasEditAccess = sharingInfo.canEdit();
  return useMemo(() => {
    return calculateDocumentEditMode(hasEditAccess, editModeOn, metadata);
  }, [editModeOn, hasEditAccess, metadata]);
}
