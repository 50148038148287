import {
  ContextualMenu,
  ContextualMenuItemType,
  IContextualMenuItem,
  IContextualMenuProps,
} from "@fluentui/react";
import { useRecoilState, useRecoilValue } from "recoil";
import * as React from "react";
import { useContext } from "react";

import {
  useGetAllCardsCallback,
  useMoveCardCallback,
  useSetCardSpacesCallback,
} from "../../../../../lib/application/state/actions/cardCallbacks";
import { docCardsListQuery } from "../../../../../lib/application/state/stats/document-core/docCardsListState";
import {
  cardPageBreakQuery,
  cardQuery,
  cardTypeQuery,
} from "../../../../../lib/application/state/stats/document-core/queries/card";
import { defined } from "../../../../../lib/core/defined";
import { useLoadableHttpResource } from "../../../../../lib/application/hooks/useLoadableResource";
import {
  createMeasureAlert,
  deleteAlert,
  getAlertStatus,
  saveMeasureDefaultSettings,
} from "../../../../../lib/application/requests/admin/common_requests_admin";
import {
  isGroupingQuery,
  primarySelectionQuery,
  singleDataCardQuery,
} from "../../../../../lib/application/state/stats/document-core/queries/dataCard";
import {
  AppMessagesContext,
  DocumentIdContext,
  ShowDraftDataContext,
  UserInfoContext,
} from "../../../../../lib/application/contexts";
import { MeasureAlertStatus } from "../../../../../lib/domain/alerts";
import { logger } from "../../../../../lib/infra/logging";
import { config, IS_PROD } from "../../../../../config";
import { encodeLatestSettings } from "../../../../../lib/application/state/stats/default-settings/parse_encode";
import { DataSelection } from "../../../../../lib/domain/selections/definitions";
import { cardColors } from "../../../../../lib/application/state/stats/document-core/queries/shared";
import { createColorSchemeContainerWithPalette } from "../../../../../lib/application/state/stats/document-style/operations";
import {
  useSaveCurrentCards,
  useSaveDocument,
} from "../../../../../lib/application/state/actions/useSaveDocument";
import {
  DocCardState,
  PageBreakType,
} from "../../../../../lib/application/state/stats/document-core/core";
import { reportMetaStateQuery } from "../../../../../lib/application/state/stats/document-meta/queries";
import { voidFunc } from "../../../../../lib/core/voidFunc";
import { ReportMetaState } from "../../../../../lib/application/state/stats/document-meta/definitions";
import { DocListItem } from "../../../../../lib/application/state/stats/document-core/atoms";
import { useExtendedAppearanceSettings } from "../../../../../lib/application/state/actions/useExtendedAppearanceSettings";

interface Props {
  cardId: string;
  hasEditDocumentAccess: boolean;
  setMeasureAsDefault?: () => void;
  handleEditBgColor: () => void;
  handleDuplicateCard: () => void;
  handleRemoveCard: () => void;
  handleTriggerStatsSettingsSaved?: (primarySelection: DataSelection) => void;
  handleCreateEmbed?: () => void;
  handleAddAIAnalysis?: () => void;
  handleTextCardMargins?: () => void;
  targetRef: React.MutableRefObject<null>;
  hidden: boolean;
  onDismiss: () => void;
}

interface PropsPythonCard {
  cardId: string;
  handleDuplicateCard: () => void;
  handleRemoveCard: () => void;
  handleExportViaLink: () => void;
  targetRef: React.MutableRefObject<null>;
  onDismiss: () => void;
  hidden: boolean;
}

const menuDisabledInDraftMode = [
  {
    key: "disabled-menu",
    text: "Menyn avstängd i utkastläge",
    disabled: true,
  },
];

export function TitleActionsContextMenu(props: Props) {
  const { hasEditDocumentAccess, onDismiss, targetRef, hidden, cardId } = props;
  const cardType = useRecoilValue(cardTypeQuery({ cardStateId: cardId }));
  const adminShowDraftData = useContext(ShowDraftDataContext);

  const menu = useBaseMenu(
    cardId,
    hasEditDocumentAccess,
    props.handleEditBgColor,
    props.handleDuplicateCard,
    props.handleRemoveCard,
    props.handleTextCardMargins
  );

  if (cardType === "dataCard") {
    return (
      <TitleActionsContextMenuDataCard
        {...props}
      ></TitleActionsContextMenuDataCard>
    );
  }

  return (
    <ContextualMenu
      items={adminShowDraftData ? menuDisabledInDraftMode : menu}
      target={targetRef}
      hidden={hidden}
      onDismiss={onDismiss}
    ></ContextualMenu>
  );
}

export function TitleActionsContextMenuPythonCard(props: PropsPythonCard) {
  const {
    onDismiss,
    targetRef,
    handleDuplicateCard,
    handleRemoveCard,
    hidden,
    cardId,
  } = props;

  const moveCard = useMoveCardCallback();
  const cardsList = useRecoilValue(docCardsListQuery);
  const currentIndex = cardsList.findIndex((card) => card.id === cardId);

  const handleSaveAllCards = useSaveCurrentCards();

  const handleMoveCardAndSave = React.useCallback(
    (cardId: string, newIndex: number) => {
      moveCard(cardId, newIndex);
      setTimeout(() => {
        handleSaveAllCards();
      }, 300);
    },
    [handleSaveAllCards, moveCard]
  );

  const menu = ([] as IContextualMenuItem[]).concat([
    getDuplicateCardEntry(handleDuplicateCard),
    getMoveCardEntry(currentIndex, handleMoveCardAndSave, cardId, cardsList),
    getRemoveCard(handleRemoveCard),
    {
      key: "export-link",
      text: "Exportera via länk",
      onClick: () => props.handleExportViaLink(),
      iconProps: { iconName: "link" },
    },
  ]);

  return (
    <ContextualMenu
      items={menu}
      target={targetRef}
      hidden={hidden}
      onDismiss={onDismiss}
    ></ContextualMenu>
  );
}

function TitleActionsContextMenuDataCard(props: Props) {
  const { hasEditDocumentAccess, onDismiss, targetRef, hidden, cardId } = props;

  const isGrouping = useRecoilValue(
    isGroupingQuery({ cardStateId: props.cardId })
  );
  const primarySelection = useRecoilValue(
    primarySelectionQuery({ cardStateId: props.cardId })
  );
  const measureId = primarySelection?.measureSelection?.measure.data_id;
  const userInfo = React.useContext(UserInfoContext);
  const adminShowDraftData = useContext(ShowDraftDataContext);

  const menu = useBaseMenu(
    cardId,
    hasEditDocumentAccess,
    props.handleEditBgColor,
    props.handleDuplicateCard,
    props.handleRemoveCard
  );

  if (adminShowDraftData) {
    return (
      <ContextualMenu
        items={menuDisabledInDraftMode}
        target={targetRef}
        hidden={hidden}
        onDismiss={onDismiss}
      ></ContextualMenu>
    );
  }

  if (userInfo?.internalMeasureAdmin() && !isGrouping && defined(measureId)) {
    return (
      <ContextMenuAdminDataCard
        {...props}
        baseMenu={menu}
        measureId={measureId}
      />
    );
  }

  return (
    <ContextualMenu
      items={menu}
      target={targetRef}
      hidden={hidden}
      onDismiss={onDismiss}
    ></ContextualMenu>
  );
}

function ContextMenuAdminDataCard(
  props: Props & { baseMenu: IContextualMenuItem[]; measureId: number }
) {
  const {
    baseMenu,
    measureId,
    setMeasureAsDefault,
    targetRef,
    hidden,
    onDismiss,
  } = props;

  const card = useRecoilValue(
    singleDataCardQuery({ cardStateId: props.cardId })
  );
  const appearanceSettings = useExtendedAppearanceSettings();

  const messagesHandler = React.useContext(AppMessagesContext);
  const alertStatusGetter = React.useCallback(() => {
    return getAlertStatus(measureId);
  }, [measureId]);
  const [alertLoadStatus, reloadAlertStatus] =
    useLoadableHttpResource<MeasureAlertStatus>(alertStatusGetter);

  const adminSubMenu: IContextualMenuProps = {
    items: [],
  };
  if (defined(setMeasureAsDefault)) {
    adminSubMenu.items.push({
      key: "set-default-measure",
      text:
        (!IS_PROD ? `[${config.appEnv}] ` : "") +
        "Välj mått som default för ämne",
      onClick: () => {
        return setMeasureAsDefault();
      },
    });
  }

  adminSubMenu.items.push({
    key: "set-default-settings",
    text: "Spara nuvarande inställningar som förvalda inställningar för mått",
    onClick: () => {
      const colorSchemeContainer = cardColors(card);
      const primaryValueType =
        card.data.dataSelections[0].measureSelection?.valueType;
      const isNonColorable =
        primaryValueType === "survey_string" || primaryValueType === "category";
      if (!defined(colorSchemeContainer) && !isNonColorable) {
        throw new Error("Color scheme container is not defined");
      }
      const settings = encodeLatestSettings(
        card.data,
        colorSchemeContainer ??
          createColorSchemeContainerWithPalette(appearanceSettings.defaultTheme)
      );
      saveMeasureDefaultSettings(measureId, settings)
        .then(() => {
          messagesHandler?.add("success", "Inställningarna sparades");
          const primarySelection = card.data.dataSelections[0];
          if (!defined(primarySelection)) {
            return;
          }
          props.handleTriggerStatsSettingsSaved?.(primarySelection);
        })
        .catch((e) => {
          logger.error(e);
          messagesHandler?.add("error", "Inställningarna kunde inte sparas");
        });
    },
  });

  adminSubMenu.items.push({
    key: "register-alert",
    text:
      (!IS_PROD ? `[${config.appEnv}] ` : "") +
      "Schemalägg utskick till alla som bevakar" +
      (alertLoadStatus?.match({
        ready: (arg) => arg.isScheduled === true,
        notReady: () => false,
      })
        ? " (redan schemalagt)"
        : ""),
    disabled: alertLoadStatus.match({
      ready: (arg) => arg.isScheduled === true,
      notReady: () => true,
    }),
    onClick: () => {
      if (
        window.confirm(
          "Schemalägg mejlutskick om det här måttet till alla som bevakar?"
        )
      ) {
        createMeasureAlert(measureId)
          .then(() => {
            reloadAlertStatus();
            messagesHandler?.add("success", "Utskick schemalagt!");
          })
          .catch((err) => {
            logger.error(err);
            messagesHandler?.add(
              "error",
              "Schemaläggning av utskick misslyckades"
            );
          });
      }
    },
  });
  adminSubMenu.items.push({
    key: "unregister-alert",
    text:
      (!IS_PROD ? `[${config.appEnv}] ` : "") + "Ta bort schemalagt utskick",
    disabled: alertLoadStatus.match({
      ready: (arg) => !defined(arg) || arg.isScheduled === false,
      notReady: () => true,
    }),
    onClick: () => {
      deleteAlert(measureId)
        .then(() => {
          reloadAlertStatus();
          messagesHandler?.add("success", "Schemalagt utskick borttaget");
        })
        .catch((err) => {
          logger.error(err);
          messagesHandler?.add(
            "error",
            "Misslyckades med att ta bort schemalagt utskick"
          );
        });
    },
  });

  if (defined(props.handleAddAIAnalysis)) {
    adminSubMenu.items.push({
      key: "add-ai-analysis",
      text: "Lägg till AI-analys",
      onClick: props.handleAddAIAnalysis,
    });
  }

  const handleCreateEmbed = props.handleCreateEmbed;
  if (defined(handleCreateEmbed)) {
    const selectionType =
      card.data.dataSelections?.[0].measureSelection?.valueType;
    adminSubMenu.items.push({
      key: "create-embed",
      disabled:
        card.data.dataSelections.length !== 1 || selectionType === "survey",
      text:
        (!IS_PROD ? `[${config.appEnv}] ` : "") +
        "Skapa inbäddningskod för kort",
      onClick: () => {
        handleCreateEmbed();
      },
    });
  }

  return (
    <ContextualMenu
      items={baseMenu.concat([
        {
          subMenuProps: adminSubMenu,
          key: "admin",
          text: "Admin",
          iconProps: { iconName: "shield" },
        },
      ] as IContextualMenuItem[])}
      target={targetRef}
      hidden={hidden}
      onDismiss={onDismiss}
    ></ContextualMenu>
  );
}

function useBaseMenu(
  cardId: string,
  hasEditDocumentAccess: boolean,
  handleEditBgColor: () => void,
  handleDuplicateCard: () => void,
  handleRemoveCard: () => void,
  handleTextCardMargins?: () => void
) {
  const [pageBreak, setPageBreak] = useRecoilState(
    cardPageBreakQuery({ cardStateId: cardId })
  );
  const [card, setCard] = useRecoilState(cardQuery(cardId));
  const moveCard = useMoveCardCallback();
  const cardsList = useRecoilValue(docCardsListQuery);
  const currentIndex = cardsList.findIndex((card) => card.id === cardId);

  const setCardSpaces = useSetCardSpacesCallback();
  const getAllCards = useGetAllCardsCallback(cardsList);
  const allCards = getAllCards();

  const handleSaveAllCards = useSaveCurrentCards();
  const docId = useContext(DocumentIdContext);
  if (!defined(docId)) {
    throw new Error("Document id is not defined");
  }

  const handleSaveDocument = useSaveDocument(docId, voidFunc);

  const [metaState, setMetaState] = useRecoilState(reportMetaStateQuery);

  const handleMoveCardAndSave = React.useCallback(
    (cardId: string, newIndex: number) => {
      moveCard(cardId, newIndex);
      setTimeout(() => {
        handleSaveAllCards();
      }, 300);
    },
    [handleSaveAllCards, moveCard]
  );

  const handleSetCardAndSave = React.useCallback(
    (card: DocCardState) => {
      setCard(card);
      setTimeout(() => {
        handleSaveAllCards();
      }, 300);
    },
    [handleSaveAllCards, setCard]
  );

  const handleSetPageBreak = React.useCallback(
    (pageBreak: PageBreakType) => {
      setPageBreak(pageBreak);
      setTimeout(() => {
        handleSaveAllCards();
      }, 300);
    },
    [handleSaveAllCards, setPageBreak]
  );

  const handleSetCardSpacesAndSave = React.useCallback(
    (cardIds: string[], hideSpace: boolean) => {
      setCardSpaces(cardIds, hideSpace);
      setTimeout(() => {
        handleSaveAllCards();
      }, 300);
    },
    [handleSaveAllCards, setCardSpaces]
  );

  const toggleNoFramesMode = React.useCallback(() => {
    if (!defined(metaState)) {
      return;
    }

    handleSaveDocument(getAllCards());
    const updatedMetaState: ReportMetaState = {
      ...metaState,
      noFramesMode: !metaState.noFramesMode,
    };
    setMetaState(updatedMetaState);
    handleSaveDocument(getAllCards(), updatedMetaState);
  }, [getAllCards, handleSaveDocument, metaState, setMetaState]);

  const allSpacesHidden = allCards.every((c, index) => {
    if (c.type === "error") {
      return false;
    }
    if (index === 0) {
      return c.hideSpaceAfter ?? false;
    } else if (index === allCards.length - 1) {
      return c.hideSpaceBefore ?? false;
    }
    return (c.hideSpaceAfter ?? false) && (c.hideSpaceBefore ?? false);
  });

  const currentCardIndex = cardsList.findIndex((c) => c.id === cardId);
  const spaceBefore = card.type !== "error" ? card.hideSpaceBefore : false;
  const spaceBeforeDisabeled = currentCardIndex === 0;
  const spaceAfter = card.type !== "error" ? card.hideSpaceAfter : false;
  const spaceAfterDisabled = currentCardIndex === cardsList.length - 1;

  const noFramesMode = metaState?.noFramesMode ?? false;
  return ([] as IContextualMenuItem[]).concat(
    hasEditDocumentAccess
      ? [
          getDuplicateCardEntry(handleDuplicateCard),
          getMoveCardEntry(
            currentIndex,
            handleMoveCardAndSave,
            cardId,
            cardsList
          ),
          {
            key: "bg-color",
            text: "Bakgrundsfärg",
            iconProps: { iconName: "color-fill" },
            onClick: () => {
              handleEditBgColor();
            },
          },
          defined(handleTextCardMargins)
            ? {
                key: "tex-card-margin",
                text: "Marginaler",
                iconProps: { iconName: "vertical-distribution" },
                onClick: handleTextCardMargins,
              }
            : undefined,
          {
            key: "card-spacing",
            text: "Mellanrum mellan kort",
            iconProps: { iconName: "vertical-inbetween" },
            subMenuProps: {
              items: [
                {
                  key: "hide-neither",
                  text: "Dölj ej mellanrum",
                  canCheck: true,
                  checked: !spaceBefore && !spaceAfter,
                  disabled: noFramesMode,
                  onClick: () => {
                    if (card.type === "error") {
                      return;
                    }
                    handleSetCardAndSave({
                      ...card,
                      hideSpaceBefore: false,
                      hideSpaceAfter: false,
                    });
                  },
                },
                {
                  key: "before",
                  text: "Dölj mellanrum till föregående kort",
                  disabled: spaceBeforeDisabeled || noFramesMode,
                  canCheck: true,
                  checked: spaceBefore || noFramesMode,
                  onClick: () => {
                    if (card.type === "error") {
                      return;
                    }
                    handleSetCardAndSave({
                      ...card,
                      hideSpaceBefore: !spaceBefore,
                    });
                  },
                },
                {
                  key: "after",
                  text: "Dölj mellanrum till efterföljande kort",
                  disabled: spaceAfterDisabled || noFramesMode,
                  canCheck: true,
                  checked: spaceAfter || noFramesMode,
                  onClick: () => {
                    if (card.type === "error") {
                      return;
                    }
                    handleSetCardAndSave({
                      ...card,
                      hideSpaceAfter: !spaceAfter,
                    });
                  },
                },
                { key: "divider-1", itemType: ContextualMenuItemType.Divider },
                {
                  key: "hide-all",
                  text: "Dölj alla mellanrum mellan kort",
                  disabled: noFramesMode,
                  canCheck: true,
                  checked: allSpacesHidden || noFramesMode,
                  onClick: () => {
                    if (card.type === "error") {
                      return;
                    }
                    handleSetCardSpacesAndSave(
                      cardsList.map((c) => c.id),
                      !allSpacesHidden
                    );
                  },
                },
                {
                  key: "hide-frames",
                  text: "Dölj alla ramar, mellanrum och dokumentbakgrund",
                  canCheck: true,
                  checked: noFramesMode,
                  onClick: toggleNoFramesMode,
                },
              ],
            },
          },
          {
            key: "page-break",
            text: "Sidbrytning för utskrift/PDF",
            iconProps: { iconName: "vertical-distribution" },
            subMenuProps: {
              items: [
                {
                  key: "before",
                  text: "Före",
                  canCheck: true,
                  checked: pageBreak === "before",
                  onClick: () => handleSetPageBreak("before"),
                },
                {
                  key: "none",
                  text: "Ingen",
                  canCheck: true,
                  checked: pageBreak === "none" || !defined(pageBreak),
                  onClick: () => handleSetPageBreak("none"),
                },
                {
                  key: "after",
                  text: "Efter",
                  canCheck: true,
                  checked: pageBreak === "after",
                  onClick: () => handleSetPageBreak("after"),
                },
                {
                  key: "both",
                  text: "Både före och efter",
                  canCheck: true,
                  onClick: () => handleSetPageBreak("both"),
                  checked: pageBreak === "both",
                },
              ],
            },
          },
          getRemoveCard(handleRemoveCard),
        ].filter(defined)
      : []
  );
}

function getRemoveCard(handleRemoveCard: () => void): IContextualMenuItem {
  return {
    key: "delete",
    text: "Ta bort kort",
    iconProps: { iconName: "trash" },
    onClick: () => handleRemoveCard(),
  };
}

function getMoveCardEntry(
  currentIndex: number,
  handleMoveCardAndSave: (cardId: string, newIndex: number) => void,
  cardId: string,
  cardsList: DocListItem[]
): IContextualMenuItem {
  return {
    key: "move",
    text: "Flytta",
    iconProps: { iconName: "move" },
    subMenuProps: {
      items: [
        {
          key: "to-first",
          text: "Längst upp",
          disabled: currentIndex === 0,
          onClick: () => handleMoveCardAndSave(cardId, 0),
          iconProps: { iconName: "double-chevron-up" },
        },
        {
          key: "1-up",
          text: "1 steg upp",
          onClick: () => handleMoveCardAndSave(cardId, currentIndex - 1),
          disabled: currentIndex === 0,
          iconProps: { iconName: "arrow-up" },
        },
        {
          key: "1-down",
          text: "1 steg ner",
          onClick: () => handleMoveCardAndSave(cardId, currentIndex + 1),
          disabled: currentIndex === cardsList.length - 1,
          iconProps: { iconName: "arrow-down" },
        },
        {
          key: "to-last",
          text: "Längst ner",
          onClick: () => handleMoveCardAndSave(cardId, cardsList.length),
          disabled: currentIndex === cardsList.length - 1,
          iconProps: { iconName: "double-chevron-down" },
        },
      ],
    },
  };
}

function getDuplicateCardEntry(
  handleDuplicateCard: () => void
): IContextualMenuItem {
  return {
    key: "duplicate",
    text: "Skapa kopia",
    iconProps: { iconName: "duplicate" },
    onClick: () => handleDuplicateCard(),
  };
}
