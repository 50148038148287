import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from "react";
import { Card } from "../../../../components/Card";
import { TableColumns, TableData, Table } from "../../../../components/Table";
import { formatDateSE, formatDateTimeSE } from "../../../../lib/core/time";
import { maxBy } from "lodash";
import {
  FluentModalTall,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../components/Modal";
import { secureDeliveryLinkFromCode } from "../../../../lib/paths";
import {
  listSecureDeliveries,
  patchSecureDelivery,
  deleteSecureDelivery,
  secureDeliveryAddRecipients,
} from "../../../../lib/application/requests/secure_delivery";
import {
  SecureDeliveryDto,
  SecureDeliveryListing,
} from "../../../../lib/infra/api_responses/secure_delivery";
import { InfostatDatePickerControlled } from "../../../../components/input/DatePicker";
import { Button } from "../../../../components/Button";
import { useAppMessages } from "../../../../lib/application/hooks/useAppMessages";
import { HttpResult } from "../../../../lib/infra/HttpResult";
import { UserInfoContext } from "../../../../lib/application/contexts";
import { Permission } from "../../../../lib/application/auth/UserInfo";
import { FluentIcon } from "../../../../components/Icons";
import { displayHttpError } from "../../../../components/errors/HttpErrorNotice";
import { logger } from "../../../../lib/infra/logging";
import { DefaultLoading } from "../../../../components/Loading";

import "./Deliveries.scss";
import { Checkbox, TextField } from "@fluentui/react";
import { useEmailsInputState } from "../../../../lib/application/hooks/useEmailsInputState";
import { defined } from "../../../../lib/core/defined";
import { FoldoutPanel } from "../../../../components/FoldoutPanel";
import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../components/ButtonContainers";
import { config } from "../../../../config";

export function Deliveries() {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selectedDelivery, setSelectedDelivery] =
    useState<SecureDeliveryListing | null>(null);
  const [deliveries, setDeliveries] = useState<
    (SecureDeliveryListing & { last_shared_at?: string })[]
  >([]);
  const [isPending, setIsPending] = useState(false);
  const userInfo = useContext(UserInfoContext);

  const canViewAllShares = useMemo(
    () =>
      userInfo?.hasPermission(Permission.OrgAdminManageSecureDelivery) ?? false,
    [userInfo]
  );

  const [sendEmailsToNewRecipients, setSendEmailsToNewRecipients] =
    useState(false);
  const [newRecipientsExpiry, setNewRecipientsExpiry] = useState<string | null>(
    null
  );
  const [emailSubject, setEmailSubject] = useState(
    config.emails.externalDeliveryEmailSubjectDefault
  );
  const [emailBody, setEmailBody] = useState(
    config.emails.externalDeliveryEmailBodyDefault
  );

  const appMessages = useAppMessages();

  const {
    emailsRaw: recipientEmailsRaw,
    setEmailsRaw: setRecipientEmailsRaw,
    emailValidationMessage,
    currentEmails,
  } = useEmailsInputState();

  const loadData = useCallback(() => {
    setIsPending(true);
    listSecureDeliveries()
      .then((res) => {
        res.map((data) => {
          if (data === null) {
            setDeliveries([]);
          } else {
            const rows = data.map((s) => {
              return {
                ...s,
                last_shared_at: maxBy(
                  s.shares,
                  (share) => new Date(share.created_at)
                )?.created_at,
              };
            });
            setDeliveries(rows);
            if (selectedDelivery) {
              const listing = rows.find(
                (r) =>
                  r.third_party_doc_id === selectedDelivery.third_party_doc_id
              );
              setSelectedDelivery(listing ?? null);
            }
          }
        });
      })
      .finally(() => {
        setIsPending(false);
      });
  }, [selectedDelivery]);

  const handleRemoveDeliveryDoc = useCallback(
    async (delivery: SecureDeliveryListing) => {
      if (
        !window.confirm(
          "Är du säker på att du vill ta bort leveransen för alla mottagare?"
        )
      ) {
        return;
      }

      const errors: string[] = [];
      setIsPending(true);
      for (const share of delivery.shares ?? []) {
        try {
          await deleteSecureDelivery(share.id).then((res) => {
            res.match({
              ok: (data) => {},
              err: (err) => {
                errors.push(displayHttpError(err));
              },
            });
            return res;
          });
        } catch (e) {
          logger.error("Failed to delete secure delivery", e);
        }
      }
      if (errors.length > 0) {
        appMessages?.add(
          "error",
          "Kunde inte ta bort leveransen för alla mottagare: " +
            errors.join(", ")
        );
      }

      loadData();
      setIsPending(false);
    },
    [loadData, appMessages]
  );

  const handleAddRecipients = useCallback(() => {
    if (!selectedDelivery) {
      return;
    }

    const emails = currentEmails;
    if (emails.length === 0) {
      return;
    }

    if (sendEmailsToNewRecipients && emailSubject.trim().length === 0) {
      appMessages?.add("error", "E-postämne saknas");
      return;
    }

    setIsPending(true);
    secureDeliveryAddRecipients(
      selectedDelivery.third_party_doc_id,
      emails,
      sendEmailsToNewRecipients,
      sendEmailsToNewRecipients ? emailSubject : "",
      sendEmailsToNewRecipients ? emailBody : undefined,
      newRecipientsExpiry ?? undefined
    ).then((res) => {
      res.match({
        ok: (data) => {
          setRecipientEmailsRaw("");
          loadData();
        },
        err: (err) => {
          appMessages?.add(
            "error",
            "Kunde inte lägga till mottagare: " + displayHttpError(err)
          );
        },
      });
      setIsPending(false);
    });
  }, [
    selectedDelivery,
    currentEmails,
    sendEmailsToNewRecipients,
    emailSubject,
    emailBody,
    newRecipientsExpiry,
    setRecipientEmailsRaw,
    loadData,
    appMessages,
  ]);

  const handleExpiresAtChange = useCallback(
    (shareId: number, newExpiresAt: string | null) => {
      return patchSecureDelivery(
        shareId,
        undefined,
        newExpiresAt ?? undefined,
        newExpiresAt === null ? true : false
      ).then((res) => {
        res.match({
          ok: (data) => {
            loadData();
            if (selectedDelivery) {
              setSelectedDelivery({
                ...selectedDelivery,
                shares: selectedDelivery.shares.map((share) =>
                  share.id === shareId
                    ? { ...share, expires_at: newExpiresAt }
                    : share
                ),
              });
            }
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Något gick fel när utgångsdatumet skulle uppdateras"
            );
          },
        });
        return res;
      });
    },
    [loadData, selectedDelivery, appMessages]
  );

  const handleRemoveShare = useCallback(
    async (share: SecureDeliveryDto) => {
      if (
        !window.confirm(
          "Är du säker på att du vill ta bort delningen för denna mottagare?"
        )
      ) {
        return;
      }

      setIsPending(true);
      await deleteSecureDelivery(share.id).then((res) => {
        res.match({
          ok: (data) => {
            loadData();
            if (selectedDelivery) {
              setSelectedDelivery({
                ...selectedDelivery,
                shares: selectedDelivery.shares.filter(
                  (s) => s.id !== share.id
                ),
              });
            }
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Kunde inte ta bort delningen för denna mottagare: " +
                displayHttpError(err)
            );
          },
        });
      });
      setIsPending(false);
    },
    [loadData, selectedDelivery, appMessages]
  );

  useEffect(() => {
    if (deliveries.length === 0) {
      loadData();
    }
  }, [deliveries.length, loadData]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedDelivery(null);
  };

  const columns: TableColumns<string, any> = useMemo(
    () => [
      {
        name: "",
        key: "actions",
      },
      {
        name: "Namn",
        key: "share_name",
      },
      {
        name: "Senast delad",
        key: "last_shared_at",
      },
      { name: "Antal mottagare", key: "shares_count" },
      { name: "", key: "remove" },
    ],
    []
  );

  const data: TableData<string, any[]> = useMemo(
    () =>
      deliveries.map((delivery) => {
        const cells: any[] = [
          <span className="row-actions" key="actions">
            <div
              className="clickable"
              onClick={() => {
                setSelectedDelivery(delivery);
                setDialogOpen(true);
              }}
            >
              📄
            </div>
          </span>,
          <span
            className="delivery-link"
            onClick={() => {
              setSelectedDelivery(delivery);
              setDialogOpen(true);
            }}
          >
            {delivery.share_name}
          </span>,
          delivery.last_shared_at
            ? formatDateTimeSE(new Date(delivery.last_shared_at))
            : "-",
          delivery.shares?.length ?? 0,
          <FluentIcon
            name="trash"
            size="sm"
            onClick={() => handleRemoveDeliveryDoc(delivery)}
          />,
        ];
        return {
          id: delivery.third_party_doc_id.toString(),
          cells,
        };
      }),
    [deliveries, handleRemoveDeliveryDoc]
  );

  const canAddRecipients = useMemo(() => {
    return (
      currentEmails.length > 0 &&
      (!defined(emailValidationMessage) || emailValidationMessage === "")
    );
  }, [currentEmails.length, emailValidationMessage]);

  return (
    <Card id="secure-delivery">
      <>
        <h2>Leveranser</h2>
        {isPending ? (
          <DefaultLoading />
        ) : data.length === 0 ? (
          <h3>Inga leveranser</h3>
        ) : (
          <Table
            data={data}
            columns={columns}
            hideHeader={false}
            containerClassName="deliveries-table-container"
          />
        )}
        {isDialogOpen && selectedDelivery && (
          <FluentModalTall
            width="lg"
            isOpen={true}
            onClose={handleDialogClose}
            containerClassName="secure-delivery-modal"
            title={"Leveransdetaljer för " + selectedDelivery.share_name}
          >
            <FluentModalBody>
              <p>
                <a
                  rel="noreferrer"
                  href={secureDeliveryLinkFromCode(
                    selectedDelivery.third_party_doc_id
                  )}
                  target="_blank"
                >
                  Länk till utskick
                </a>{" "}
                (OBS: du måste vara mottagare för att kunna öppna länken)
              </p>

              <section>
                <FoldoutPanel title="Lägg till mottagare">
                  <section>
                    <TextField
                      className=""
                      multiline
                      errorMessage={
                        recipientEmailsRaw === ""
                          ? undefined
                          : emailValidationMessage
                      }
                      value={recipientEmailsRaw}
                      placeholder="E-postadresser"
                      onChange={(e) =>
                        setRecipientEmailsRaw(e.currentTarget.value)
                      }
                    />
                  </section>
                  <div className="options">
                    <InfostatDatePickerControlled
                      value={
                        newRecipientsExpiry
                          ? new Date(newRecipientsExpiry)
                          : undefined
                      }
                      onChange={(date) => {
                        setNewRecipientsExpiry(date?.toISOString() ?? null);
                      }}
                      disabled={false}
                      label="Utgångsdatum"
                    />
                    <Checkbox
                      className="margin-bottom-sm"
                      label="Skicka e-post till nya mottagare"
                      checked={sendEmailsToNewRecipients}
                      onChange={(e, checked) =>
                        setSendEmailsToNewRecipients(!!checked)
                      }
                    />
                  </div>
                  <section>
                    {sendEmailsToNewRecipients && (
                      <>
                        <TextField
                          className=""
                          value={emailSubject}
                          required
                          label="Ämne"
                          onChange={(e) =>
                            setEmailSubject(e.currentTarget.value)
                          }
                        />
                        <TextField
                          className=""
                          multiline
                          value={emailBody}
                          label="Meddelande"
                          onChange={(e) => setEmailBody(e.currentTarget.value)}
                        />
                      </>
                    )}
                  </section>
                  <section>
                    <div>
                      <Button
                        title="Rensa"
                        onClick={() => setRecipientEmailsRaw("")}
                      />
                      <Button
                        disabled={!canAddRecipients}
                        onClick={handleAddRecipients}
                        title="Lägg till mottagare"
                      />
                    </div>
                  </section>
                </FoldoutPanel>
              </section>

              <div>
                <h3>Mottagare</h3>
                <SharesTable
                  shares={selectedDelivery.shares}
                  canViewAllShares={canViewAllShares}
                  handleRemoveShare={handleRemoveShare}
                  handleExpiresAtChange={handleExpiresAtChange}
                />
              </div>
            </FluentModalBody>
            <FluentModalFooter>
              <ButtonsFooter>
                <ButtonsFooterRight>
                  <Button title="Stäng" onClick={handleDialogClose} />
                </ButtonsFooterRight>
              </ButtonsFooter>
            </FluentModalFooter>
          </FluentModalTall>
        )}
      </>
    </Card>
  );
}

function SharesTable({
  shares,
  canViewAllShares,
  handleExpiresAtChange,
  handleRemoveShare,
}: {
  shares: SecureDeliveryDto[];
  canViewAllShares: boolean;
  handleRemoveShare: (share: SecureDeliveryDto) => void;
  handleExpiresAtChange: (
    shareId: number,
    newExpiresAt: string | null
  ) => Promise<HttpResult<unknown>>;
}) {
  const columns: TableColumns<number, any> = [
    {
      name: "Mottagare",
      key: "recipient_email",
    },
    {
      name: "Skapad",
      key: "created_at",
    },
    {
      name: "Uppdaterad",
      key: "updated_at",
    },
    {
      name: "Utgångsdatum",
      key: "expires_at",
    },
  ];
  if (canViewAllShares) {
    columns.push({
      name: "Skapad av",
      key: "created_by_email",
    });
  }

  const data: TableData<number, any[]> = shares.map((share) => {
    const cells: any[] = [
      share.recipient_email,
      formatDateTimeSE(new Date(share.created_at)),
      formatDateTimeSE(new Date(share.updated_at)),
      <ExpiresAtCell
        key={share.id}
        share={share}
        handleExpiresAtChange={handleExpiresAtChange}
      />,
    ];

    if (canViewAllShares) {
      cells.push(share.created_by_email);
    }

    cells.push(
      <span>
        <FluentIcon
          name="trash"
          size="sm"
          onClick={() => handleRemoveShare(share)}
        />
      </span>
    );

    return {
      id: share.id,
      cells,
    };
  });

  return (
    <Table
      data={data}
      columns={columns}
      hideHeader={false}
      containerClassName="shares-table-container"
    />
  );
}

function ExpiresAtCell({
  share,
  handleExpiresAtChange,
}: {
  share: SecureDeliveryDto;
  handleExpiresAtChange: (
    shareId: number,
    newExpiresAt: string | null
  ) => Promise<HttpResult<unknown>>;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [expiresAt, setExpiresAt] = useState<string | null>(share.expires_at);

  const handleSave = useCallback(() => {
    handleExpiresAtChange(share.id, expiresAt).then((res) => {
      res.match({
        ok: () => {
          setIsEditing(false);
        },
        err: (err) => {
          // Error handled in ancestor component
        },
      });
    });
  }, [expiresAt, handleExpiresAtChange, share.id]);

  const handleReset = async () => {
    handleExpiresAtChange(share.id, null).then((res) => {
      res.match({
        ok: () => {
          setExpiresAt(null);
          setIsEditing(false);
        },
        err: () => {
          // Error handled in ancestor component
        },
      });
    });
  };

  return (
    <div className="expires-at-cell">
      {isEditing ? (
        <div>
          <InfostatDatePickerControlled
            value={expiresAt ? new Date(expiresAt) : undefined}
            onChange={(date) => setExpiresAt(date?.toISOString() ?? null)}
            disabled={false}
            label="Datum"
          />
          <div>
            <Button onClick={handleSave} title="Spara" />
            <Button
              onClick={() => {
                setExpiresAt(share.expires_at);
                setIsEditing(false);
              }}
              title="Avbryt"
            />
          </div>
        </div>
      ) : (
        <div>
          {expiresAt
            ? formatDateSE(new Date(expiresAt))
            : "[Inget utgångsdatum satt]"}
          <Button
            className="margin-left-sm"
            onClick={() => setIsEditing(true)}
            title="Redigera"
          />
          {expiresAt && <Button onClick={handleReset} title="Återställ" />}
        </div>
      )}
    </div>
  );
}
