import React, { useCallback, useContext } from "react";
import { NotReadyHttpErrNotification } from "../../../../../../components/Loading";
import { eitherDisplayer } from "../../../../../../components/wrappers/either";
import { ShowDraftDataContext } from "../../../../../../lib/application/contexts";
import { useLoadableHttpResource } from "../../../../../../lib/application/hooks/useLoadableResource";
import { getMicroDimensionsWithCache } from "../../../../../../lib/application/requests/datasets/micro";
import { defined } from "../../../../../../lib/core/defined";
import {
  DimensionV2Dto,
  SelectedDimensionsV2,
} from "../../../../../../lib/domain/measure/definitions";
import {
  BreakdownSelectMultiV2,
  BreakdownSelectSingleV2,
} from "../../data_card/selections/shared";

export function DimensionSelectionOuter(props: {
  changePending: boolean;
  measureId: number;
  multiSelect: boolean;
  selectedBreakdowns: SelectedDimensionsV2;
  handleClearBreakdowns: (labelId: string) => void;
  handleSetSingleBreakdownKey: (breakdownId: string, key?: number) => void;
  handleSetBreakdowns: (
    labelId: string,
    keys: number[],
    selected: boolean
  ) => void;
}) {
  const selectedMeasureId = props.measureId;
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const loader = useCallback(() => {
    if (!defined(selectedMeasureId)) {
      throw new Error("MeasureId is not defined");
    }
    return getMicroDimensionsWithCache(selectedMeasureId, adminShowDraftData);
  }, [adminShowDraftData, selectedMeasureId]);

  const [dims] = useLoadableHttpResource(loader, [loader]);

  return (
    <DimensionSelection
      item={dims}
      changePending={props.changePending}
      multiSelect={props.multiSelect}
      handleClearBreakdowns={props.handleClearBreakdowns}
      selectedDimensions={props.selectedBreakdowns}
      handleSetSingleBreakdownKey={props.handleSetSingleBreakdownKey}
      handleSetBreakdowns={props.handleSetBreakdowns}
    ></DimensionSelection>
  );
}

export const DimensionSelection = eitherDisplayer(
  NotReadyHttpErrNotification,
  DimensionSelectionInner
);

function DimensionSelectionInner(props: {
  item: DimensionV2Dto[] | null;
  changePending: boolean;
  multiSelect: boolean;
  selectedDimensions: SelectedDimensionsV2;
  handleSetSingleBreakdownKey: (breakdownId: string, key?: number) => void;
  handleClearBreakdowns: (labelId: string) => void;
  handleSetBreakdowns: (
    labelId: string,
    keys: number[],
    selected: boolean
  ) => void;
}) {
  const selectedDimensions = props.selectedDimensions;

  return (
    <React.Fragment>
      {props.item?.map((dimensionSpec) => {
        const currentDimension = selectedDimensions[dimensionSpec.data_column];
        if (!defined(currentDimension)) {
          // Happens when dimensions for current measure have not yet been loaded, old ones are loaded
          return null;
        }
        if (!props.multiSelect) {
          const selectedValueId = dimensionSpec.values?.find((dimensionValue) =>
            currentDimension.find((id) => dimensionValue.id === id)
          )?.id;
          return (
            <div
              className="dimension-selection-item"
              key={dimensionSpec.data_column}
            >
              {
                <BreakdownSelectSingleV2
                  disabled={props.changePending}
                  noneSelectedError="Välj ett värde"
                  key={dimensionSpec.data_column}
                  dimension={dimensionSpec}
                  setSelectedKey={props.handleSetSingleBreakdownKey}
                  selectedValueId={selectedValueId}
                ></BreakdownSelectSingleV2>
              }
            </div>
          );
        }
        return (
          <div
            className="dimension-selection-item"
            key={dimensionSpec.data_column}
          >
            <BreakdownSelectMultiV2
              disabled={props.changePending}
              noneSelectedError="Välj minst ett värde"
              key={dimensionSpec.dimension_id}
              dimension={dimensionSpec}
              setBreakdowns={props.handleSetBreakdowns}
              clearBreakdownValues={props.handleClearBreakdowns}
              selectedBreakdowns={props.selectedDimensions}
            ></BreakdownSelectMultiV2>
          </div>
        );
      })}
    </React.Fragment>
  );
}
