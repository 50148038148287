import { useCallback } from "react";

import { defined } from "../../../../../core/defined";
import { DataSelection } from "../../../../../domain/selections/definitions";
import { clearCachedMeasureLists } from "../../../../requests/common_requests";
import { statsApi } from "../../../../requests/statsApi";

export function useUpdateSelectionOnSettingsChange(
  handleChange: (selection: DataSelection) => void
) {
  return useCallback(
    async (dataSelection: DataSelection, adminDraftMode: boolean) => {
      const measureSelection = dataSelection.measureSelection;
      if (!defined(measureSelection)) {
        return;
      }
      clearCachedMeasureLists();
      const measures = await statsApi.getAvailableMeasures(
        dataSelection.subjectPath,
        false,
        false,
        adminDraftMode
      );
      const measureSelectionCopy = {
        ...measureSelection,
      };
      handleChange({
        ...dataSelection,
        measureSelection: {
          ...measureSelectionCopy,
          available: measures,
        },
      });
    },
    [handleChange]
  );
}
