import { useMemo, useState } from "react";
import { ToolbarMenuItem } from "../TabToolbar";
import { useDeactivateForecast } from "../../../../../../lib/application/state/actions/forecasts";
import { useRecoilValue } from "recoil";
import { defined } from "../../../../../../lib/core/defined";
import { ChartType } from "../../../../../../lib/application/stats/shared/core/definitions";
import { cardQuery } from "../../../../../../lib/application/state/stats/document-core/queries/card";
import { DataOutputSettings } from "../../../../../../lib/application/state/stats/document-core/DataOutputSettings";

export function useForecastDialogMenu(
  cardId: string,
  settings: DataOutputSettings
) {
  const [showForecastDialog, setShowForecastDialog] = useState(false);
  const handleDeactivateForecast = useDeactivateForecast(cardId);
  const card = useRecoilValue(cardQuery(cardId));
  const loadedData = useMemo(() => {
    if (card.type !== "dataCard") {
      return undefined;
    }
    return card.data.loadedData;
  }, [card]);
  const selectedView = useMemo(() => {
    if (card.type !== "dataCard") {
      return undefined;
    }
    return card.data.selectedView;
  }, [card]);

  const trendSupported = useMemo(() => {
    if (card.type !== "dataCard") {
      return false;
    }
    if (!defined(selectedView)) {
      return false;
    }
    if (
      selectedView === "diagram" &&
      loadedData?.chartDataState?.loadedChartData?.chartType !== ChartType.line
    ) {
      return false;
    }
    return true;
  }, [
    card.type,
    selectedView,
    loadedData?.chartDataState?.loadedChartData?.chartType,
  ]);

  const menuItem: ToolbarMenuItem = useMemo(() => {
    return {
      type: "submenu",
      label: "Trend",
      items: [
        {
          type: "click",
          label: "Redigera",
          disabled: !trendSupported,
          onClick: () => setShowForecastDialog(true),
        },
        {
          type: "click",
          label: "Ta bort",
          disabled: !settings.forecast.show || !trendSupported,
          onClick: () => handleDeactivateForecast(),
        },
      ],
    };
  }, [handleDeactivateForecast, settings.forecast.show, trendSupported]);

  return { menuItem, showForecastDialog, setShowForecastDialog };
}
