import { config } from "../../../config";
import { logger } from "../../infra/logging";

let tidioInitialized = false;

export function openTidioWindow() {
  try {
    (window as any).tidioChatApi?.open();
  } catch (e) {
    logger.error("Failed to open Tidio window on manual click", e);
  }
}

export function updateTidioState(enabled: boolean, userEmail: string) {
  if (enabled) {
    loadTidio(userEmail);
  } else {
    unloadTidio();
  }
}

let removeEventListeners: (() => void) | undefined;
let scriptElement: HTMLScriptElement | undefined;

export function unloadTidio() {
  if (!tidioInitialized) {
    return;
  }

  try {
    removeEventListeners?.();
    document.getElementById("tidio-chat-code")?.remove();
    document.getElementById("tidio-chat")?.remove();
    scriptElement?.remove();
    tidioInitialized = false;
  } catch (e) {
    logger.error("Failed to hide Tidio window", e);
  }
}

function loadTidio(userEmail: string) {
  if (tidioInitialized) {
    return;
  }

  try {
    (document as any).tidioIdentify = {
      distinct_id: userEmail,
      email: userEmail,
      name: userEmail,
    };

    const s1 = document.createElement("script");
    scriptElement = s1;
    const s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = config.tidioEmbedUrl;
    s0?.parentNode?.insertBefore(s1, s0);

    tidioInitialized = true;

    function onTidioChatApiReady() {
      const tidioChatIframe = document.getElementById("tidio-chat-iframe");
      if (tidioChatIframe) {
        tidioChatIframe.style.zIndex = "180";
      }
    }

    document.addEventListener("tidioChat-ready", onTidioChatApiReady);
    removeEventListeners = () =>
      document.removeEventListener("tidioChat-ready", onTidioChatApiReady);
  } catch (e) {
    logger.error("Failed to load Tidio", e);
  }
}
