import { useCallback, useState } from "react";

import { defined } from "../../../../../lib/core/defined";
import { InfostatDataWarning } from "./InfostatDataWarning";
import { StepFooter } from "./StepFooter";
import { CompleteData0, CompleteData1 } from "./types";
import { SimpleSubjectSelectMicro } from "../../shared/SimpleSubjectSelectMicro";

export function Step1SelectCategory(props: {
  data: CompleteData0;
  handlePrevious?: () => void;
  handleNext: (data: CompleteData1) => void;
}) {
  const { step1: step2 } = props.data;
  const [area, setArea] = useState<string | undefined>(step2?.area);
  const [subarea, setSubarea] = useState<string | undefined>(step2?.subarea);
  const [subject, setSubject] = useState<string | undefined>(step2?.subject);

  const handleNext = useCallback(
    (finalArea: string, finalSubarea: string, finalSubject: string) => {
      props.handleNext({
        ...props.data,
        step1: {
          area: finalArea,
          subarea: finalSubarea,
          subject: finalSubject,
        },
        step2: props.data.step2,
      });
    },
    [props]
  );

  return (
    <div className="select-category">
      <InfostatDataWarning></InfostatDataWarning>
      <h2>Kategorisering</h2>

      <section>
        <SimpleSubjectSelectMicro
          adminShowDraftCategories={true}
          area={area}
          subarea={subarea}
          subject={subject}
          onSelect={(path) => {
            setArea(path[0]);
            setSubarea(path[1]);
            setSubject(path[2]);
            return Promise.resolve();
          }}
        ></SimpleSubjectSelectMicro>
      </section>

      <StepFooter
        handlePrevious={props.handlePrevious}
        handleNext={
          defined(area) && defined(subarea) && defined(subject)
            ? () => handleNext(area, subarea, subject)
            : undefined
        }
      ></StepFooter>
    </div>
  );
}
