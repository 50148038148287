import {
  Array as ArrayRT,
  Record,
  String as StringRT,
  Number as NumberRT,
  Boolean as BooleanRT,
  Static,
  Tuple,
  Literal,
  Union,
  Dictionary,
  Null,
  Unknown,
  Optional,
  Partial,
} from "runtypes";
import {
  FilterSet,
  MicroSubjectPath,
} from "../../application/state/stats/document-core/core-micro";
import { defined } from "../../core/defined";
import {
  AggregationMethod,
  DataValueTypeMicroAll,
  DataValueTypeRegular,
  DataValueTypeRegularRT,
  DataValueTypeSurvey,
  DataValueTypeSurveyRT,
  DataValueTypeSurveyString,
  DataValueTypeSurveyStringRT,
} from "../../infra/api_responses/dataset";
import {
  ComputedMeasurementType,
  ComputedMeasurementVariables,
} from "../../infra/api_responses/micro_dataset";
import { GeoTypeMicro, GeoTypeRT } from "../geography";

export const BREAKDOWN_ALL_LABEL = "Samtliga";
export const SURVEY_MEASURE_FILTER_NONE_SELECTED = "Samtliga";
export const SURVEY_SUBQUESTION_BREAKDOWN_KEY = "subquestion";
export const SURVEY_LOW_BASE_LABEL = "Få svar";
export const SURVEY_INVALID_CHOICE_LABEL = "[ogiltigt val]";

export const DateRangeRawRT = Tuple(StringRT, StringRT);
/**
 * Date range where both start and end are included
 */
export type DateRangeRaw = [start: string, end: string];
export function dateRangesEqual(a: DateRangeRaw, b: DateRangeRaw): boolean {
  return a[0] === b[0] && a[1] === b[1];
}

const measureReference = Record({ descr_long: StringRT, data_id: NumberRT });
const MeasureBaseRT = Record({
  data_id: NumberRT,
  subject: StringRT,
  area: StringRT,
  subarea: StringRT,
  public_comment: Optional(StringRT),
  default_measure: Optional(BooleanRT),
  retired: BooleanRT,
  measure: StringRT,
  resolution: StringRT,
  geo_types: ArrayRT(GeoTypeRT),
  see_also_measures: Optional(ArrayRT(measureReference)),
  default_settings: Optional(Unknown),
  /**
   * Internal label
   */
  label: Optional(StringRT),
});

const SortModeRT = Optional(
  Union(Literal("fixed_bottom"), Literal("fixed_top"))
);

/**
 * Differs from regular DTO in that it contains numerical IDs
 */
const DimensionValueV2DtoRT = Record({
  label: StringRT,
  id: NumberRT,
  // Used for hierarchical dimensions
  parent_id: Optional(Null.Or(NumberRT)),
  draft: Optional(BooleanRT),
  sort_order: NumberRT,
  sort_mode: SortModeRT,
});

export type DimensionValueV2Dto = Static<typeof DimensionValueV2DtoRT>;
export type ValuesSortMode = DimensionValueV2Dto["sort_mode"];

const DimensionTypeRT = Union(
  Literal("stats_breakdown"),
  Literal("mikro_breakdown"),
  Literal("survey_background"),
  Literal("survey_value"),
  Literal("survey_subquestion")
);

const DimensionV2DtoRT = Record({
  type: DimensionTypeRT,
  // Used for hierarchical dimensions
  parent_id: Optional(Null.Or(NumberRT)),
  dimension_id: NumberRT,
  data_column: StringRT,
  label: StringRT,
  values: Null.Or(ArrayRT(DimensionValueV2DtoRT)),
  sort_order: NumberRT,
});

/** V2 type's difference compared to V1 is that values have numerical IDs. */
export type DimensionV2Dto = Static<typeof DimensionV2DtoRT>;
export type DimensionType = Static<typeof DimensionTypeRT>;

export const DimensionsResponseV2RT = ArrayRT(DimensionV2DtoRT);

const SurveyQuestionTypeRT = Union(
  Literal("multichoice"),
  Literal("singlechoice")
);
export const MeasureSurveyDtoRT = MeasureBaseRT.And(
  Record({
    value_type: DataValueTypeSurveyRT,
    survey_question_type: SurveyQuestionTypeRT,
  })
);

export const MeasureSurveyStringDtoRT = MeasureBaseRT.And(
  Record({
    value_type: DataValueTypeSurveyStringRT,
    survey_question_type: SurveyQuestionTypeRT,
  })
);

const MeasureRegularDtoRT = MeasureBaseRT.And(
  Record({
    value_type: DataValueTypeRegularRT,
  }).And(
    Partial({
      is_member_org_measurement_parent: BooleanRT,
    })
  )
);

export const SingleMeasureUpdateRT = Record({
  data_id: NumberRT,
  area: StringRT,
  subarea: StringRT,
  subject: StringRT,
  measure: StringRT,
  last_update: StringRT,
  other_measurements_count: NumberRT,
});
export type SingleMeasureUpdate = Static<typeof SingleMeasureUpdateRT>;
export const MeasureUpdatesResponseRT = ArrayRT(SingleMeasureUpdateRT).Or(Null);
export type MeasureUpdatesResponse = Static<typeof MeasureUpdatesResponseRT>;

export const MeasureDtoRT = MeasureSurveyDtoRT.Or(MeasureRegularDtoRT).Or(
  MeasureSurveyStringDtoRT
);

export const MeasureDatesRT = ArrayRT(StringRT).Or(Null);
export type MeasureDates = Static<typeof MeasureDatesRT>;

export type MeasureReference = Static<typeof measureReference>;
export type MeasureDto = Static<typeof MeasureDtoRT>;

export type MeasureRegularDto = Static<typeof MeasureRegularDtoRT>;
export type MeasureSurveyDto = Static<typeof MeasureSurveyDtoRT>;
export type MeasureSurveyStringDto = Static<typeof MeasureSurveyStringDtoRT>;

type WithDims<T> = T & {
  dimensions: DimensionV2Dto[];
};
export type MeasureSurvey = WithDims<MeasureSurveyDto>;
export type MeasureSurveyString = WithDims<MeasureSurveyStringDto>;
export type MeasureRegular = WithDims<MeasureRegularDto>;

export interface SortSpec {
  orderTop: string[];
  orderBottom: string[];
}

export type MeasureThinNonCustomer =
  | MeasureRegularDto
  | MeasureSurveyDto
  | MeasureSurveyStringDto;
export type MeasureThin = MeasureThinNonCustomer;
export type MeasureFull = MeasureRegular | MeasureSurvey | MeasureSurveyString;

// Measure selections
interface StatsSelection {
  availableDates: string[];
  breakdowns: SelectedDimensionsV2;
  available: MeasureThin[];
}
export interface MeasureSelectionRegular extends StatsSelection {
  measure: MeasureRegular;
  valueType: DataValueTypeRegular;
}

export interface MeasureSelectionSurvey extends StatsSelection {
  measure: MeasureSurvey;
  valueType: DataValueTypeSurvey;
}

export interface MeasureSelectionSurveyString extends StatsSelection {
  measure: MeasureSurveyString;
  valueType: DataValueTypeSurveyString;
}

/**
 * Map of breakdown_dimension -> array of value ids.
 */
export const SelectedDimensionsV2RT = Dictionary(
  Optional(ArrayRT(NumberRT)),
  StringRT
);
export type SelectedDimensionsV2 = Static<typeof SelectedDimensionsV2RT>;

export type MeasureSelection =
  | MeasureSelectionRegular
  | MeasureSelectionSurvey
  | MeasureSelectionSurveyString;
export type MeasureSelectionGrouping =
  | MeasureSelectionRegular
  | MeasureSelectionSurvey;

export type MeasureSelectionWithoutDatesGeneric<T> = Omit<T, "availableDates">;
export type MeasureSelectionWithoutDates =
  | Omit<MeasureSelectionSurveyString, "availableDates">
  | Omit<MeasureSelectionSurvey, "availableDates">
  | Omit<MeasureSelectionRegular, "availableDates">;

export interface MeasureSpecMicro {
  id: number;
  timeResolution: string;
  label: string;
  valueType: DataValueTypeMicroAll;
  descrLong: string;
  measure: string;
  unitLabel: string;
  geoTypes: GeoTypeMicro[];
  aggMethodGeo: AggregationMethod;
  source: string;
  extSource?: string;
  extDescription?: string;
  extDescriptionLong?: string;
  publicComment?: string[];
  lastUpdate?: string;
  sourceUrl?: string;
  dimensions: DimensionV2Dto[] | null;
  computed?: {
    type: ComputedMeasurementType;
    variables?: ComputedMeasurementVariables;
  };
}

interface MeasureSelectionPartialMicroCommon {
  subjectPath: MicroSubjectPath;
  availableDates: string[];
  selectedDimensions: SelectedDimensionsV2;
  timeSelection: DateRangeRaw | undefined;
  measure?: MeasureSpecMicro;
}

export function isSingleDate(DateRangeRaw: DateRangeRaw): boolean {
  return DateRangeRaw[0] === DateRangeRaw[1];
}

export type ComputedMeasureVariablesConfig = {
  [key: string]: number;
};
export function measureVariableToLabel(variable: string): string {
  switch (variable) {
    case "distance":
      return "Avstånd";
  }
  return variable;
}

export interface PrimaryMeasureSelectionMicroPartial
  extends MeasureSelectionPartialMicroCommon {
  id: string;
  type: "primary";
  multiSelectEnabled: boolean;
  computedMeasureVariablesConfig?: ComputedMeasureVariablesConfig;
  filterSet?: FilterSet;
}

export interface PrimaryMeasureSelectionMicroFull
  extends PrimaryMeasureSelectionMicroPartial {
  measure: MeasureSpecMicro;
}

/**
 * Measure selection for point/polygon/line measures
 */
export interface MeasureSelectionGeoMicroPartial
  extends MeasureSelectionPartialMicroCommon {
  id: string;
  type: "geo-micro";
}
export interface MeasureSelectionGeoMicroFull
  extends MeasureSelectionGeoMicroPartial {
  measure: MeasureSpecMicro;
}

export type MeasureSelectionMicroFull =
  | PrimaryMeasureSelectionMicroFull
  | MeasureSelectionGeoMicroFull;

export type MeasureSelectionMicroPartial =
  | PrimaryMeasureSelectionMicroPartial
  | MeasureSelectionGeoMicroPartial;

export interface FilterMeasureMicro {
  id: string;
  subjectPath: MicroSubjectPath;
  filterSet?: FilterSet;
  measureSelection?: {
    selectedDimensions: SelectedDimensionsV2;
    computedMeasureVariablesConfig?: ComputedMeasureVariablesConfig;
    measure: MeasureSpecMicro;
  };
}

export type ActiveFilterMeasureMicro = {
  [key in keyof FilterMeasureMicro]-?: FilterMeasureMicro[key];
};

export function isActiveFilterMeasureMicro(
  f: FilterMeasureMicro
): f is ActiveFilterMeasureMicro {
  return defined(f.filterSet) && defined(f.measureSelection);
}
