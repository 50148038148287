import { useState, useContext } from "react";

import { AlertBox } from "../../../../../components/AlertBox";
import { Card } from "../../../../../components/Card";
import { IS_PROD } from "../../../../../config";
import { AppMessagesContext } from "../../../../../lib/application/contexts";
import { useNavigationPrompt } from "../../../../../lib/application/hooks/useNavigationPrompt";
import { createMemberOrgsMeasure } from "../../../../../lib/application/requests/admin/measure_management/create";
import { logger } from "../../../../../lib/infra/logging";
import { Step1SelectCategory } from "./Step1SelectCategory";
import { Step2Meta } from "./Step2Meta";
import { Step3LockMode } from "./Step3LockMode";
import { Step4ConfirmMemberOrgsMeasure } from "./Step4ConfirmMemberOrgsMeasure";
import {
  CompleteData0,
  CompleteData1,
  CompleteData2,
  CompleteData3,
} from "./types";
import { displayHttpErrorInternal } from "../../../../../lib/infra/HttpResult";

type State =
  | {
      step: 1;
      data: CompleteData0;
    }
  | {
      step: 2;
      data: CompleteData1;
    }
  | {
      step: 3;
      data: CompleteData2;
    }
  | {
      step: 4;
      data: CompleteData3;
    };

export function CreateMembergOrgsMeasure() {
  const [state, setState] = useState<State>({ step: 1, data: {} });
  const [measureCreated, setMeasureCreated] = useState(false);

  const appMessages = useContext(AppMessagesContext);

  const hasData = Object.values(state.data).some((v) => v !== undefined);
  useNavigationPrompt(hasData && !measureCreated);

  return (
    <Card id="create-measure">
      <div>
        {!IS_PROD && (
          <AlertBox intent="danger">
            <span>
              Du är ej i produktionsmiljön. Om du fortsätter att skapa ett mått
              kommer det endast vara synligt i utvecklingsmiljön. Kunder kommer
              inte kunna se måttet.
            </span>
          </AlertBox>
        )}
        {state.step === 1 && (
          <Step1SelectCategory
            data={state.data}
            handleNext={(data) => setState({ step: 2, data })}
          ></Step1SelectCategory>
        )}
        {state.step === 2 && (
          <Step2Meta
            data={state.data}
            handlePrevious={(data) => setState({ data, step: 1 })}
            handleNext={(data) => setState({ step: 3, data })}
          ></Step2Meta>
        )}
        {state.step === 3 && (
          <Step3LockMode
            data={state.data}
            handlePrevious={(data) => {
              setState({ data, step: 2 });
            }}
            handleNext={(data) => {
              setState({ data, step: 4 });
            }}
          ></Step3LockMode>
        )}
        {state.step === 4 && (
          <Step4ConfirmMemberOrgsMeasure
            data={state.data}
            handlePrevious={(data) => {
              setState({ data, step: 3 });
            }}
            handleConfirm={(data) => {
              const fields = data.step2;
              const breakdowns = fields.breakdowns ?? {};
              const subjectPath = {
                area: data.step1.area,
                subarea: data.step1.subarea,
                subject: data.step1.subject,
              };
              const dimensions = Object.entries(breakdowns).map(
                ([key, value]) => {
                  if (typeof value !== "string") {
                    throw new Error("Invalid breakdown label, expected string");
                  }
                  const parent_data_column = fields.breakdownParents?.[key];
                  return {
                    data_column: key,
                    parent_data_column,
                    label: value,
                  };
                }
              );

              return createMemberOrgsMeasure(
                fields,
                subjectPath,
                data.step3.owner_org.id,
                dimensions
              ).then((res) => {
                res.match({
                  ok: () => {
                    appMessages?.add("success", "Mått skapat!");
                    setMeasureCreated(true);
                  },
                  err: (e) => {
                    logger.error(e);
                    appMessages?.add(
                      "error",
                      "Kunde inte skapa mått. Felinfo: " +
                        displayHttpErrorInternal(e)
                    );
                  },
                });
                return res;
              });
            }}
          ></Step4ConfirmMemberOrgsMeasure>
        )}
      </div>
    </Card>
  );
}
