export const extensionToMimeType = {
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  svg: "image/svg+xml",
};

export function mimeTypeUtf8(
  extension: keyof typeof extensionToMimeType
): string {
  return extensionToMimeType[extension] + ";charset=utf-8";
}
