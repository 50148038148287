import { defined } from "../../core/defined";

let scrollToCard: string | null = null;
let lastCardScrolledIntoView: string | null = null;
let triggerFunc: ((cardId: string) => void) | null = null;

export function setScrollToCard(cardId: string) {
  scrollToCard = cardId;
}

export function shouldCardScrollIntoView(cardId: string): boolean {
  return scrollToCard === cardId;
}

export function handleCardScrolledTo() {
  lastCardScrolledIntoView = scrollToCard;
  scrollToCard = null;
  if (defined(lastCardScrolledIntoView) && defined(triggerFunc)) {
    triggerFunc(lastCardScrolledIntoView);
  }
}

export function lastCardScrolledTo(): string | null {
  return lastCardScrolledIntoView;
}

export function triggerOnCardScrolledTo(
  func: ((cardId: string) => void) | null
) {
  if (func === null) {
    triggerFunc = null;
    return;
  }
  triggerFunc = func;
}
