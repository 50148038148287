import { Spinner, SpinnerLabelPosition, SpinnerSize } from "@fluentui/react";
import React from "react";

import { NotReady } from "../lib/application/loading/load_status";
import { assertNever } from "../lib/core/assert";
import { Progress } from "../lib/core/progress";
import { HttpError } from "../lib/infra/HttpResult";
import { DelayedSpinner } from "./DelayedSpinner";
import {
  HttpErrorViewEitherNotification,
  HttpErrorViewEitherSmallNotice,
} from "./errors/HttpErrorNotice";
import { HttpErrorViewEitherFullPage } from "./errors/HttpErrorView";

import "./Loading.scss";

export function DefaultLoading(props: {
  delayMs?: number;
  spinnerSize?: SpinnerSize;
  label?: string;
  labelPosition?: SpinnerLabelPosition;
}) {
  return (
    <DelayedSpinner
      delayShowMs={props.delayMs ?? 500}
      size={props.spinnerSize ?? SpinnerSize.medium}
      labelPosition={props.labelPosition ?? "top"}
      label={props.label ?? "Laddar..."}
    ></DelayedSpinner>
  );
}

export function DefaultLoadingStretchedToFit(props: {
  delayMs?: number;
  spinnerSize?: SpinnerSize;
  label?: string;
}) {
  return (
    <div className="default-loading-stretched-to-fit">
      <DelayedSpinner
        delayShowMs={props.delayMs ?? 500}
        size={props.spinnerSize ?? SpinnerSize.medium}
        labelPosition="top"
        label={props.label ?? "Laddar..."}
      ></DelayedSpinner>
    </div>
  );
}

export function PaddedSmallSpinner() {
  return <Spinner className="padding-lg"></Spinner>;
}

export function DefaultNotReady<E>(props: {
  left: NotReady<E>;
  ErrComp: React.FunctionComponent<{ left: E }>;
}) {
  const { left, ErrComp } = props;
  switch (left.type) {
    case Progress.InProgress:
      return <DefaultLoading></DefaultLoading>;
    case Progress.NotStarted:
      return <DefaultLoading></DefaultLoading>;
    case Progress.Error:
      return <ErrComp left={left.err}></ErrComp>;
    default:
      assertNever(left);
  }
  return null;
}

export function NotReadyHttpErrFullPage(props: { left: NotReady<HttpError> }) {
  return (
    <DefaultNotReady
      left={props.left}
      ErrComp={HttpErrorViewEitherFullPage}
    ></DefaultNotReady>
  );
}

export function NotReadyHttpErrNotice(props: { left: NotReady<HttpError> }) {
  return (
    <DefaultNotReady
      left={props.left}
      ErrComp={HttpErrorViewEitherSmallNotice}
    ></DefaultNotReady>
  );
}

export function NotReadyHttpErrNotification(props: {
  left: NotReady<HttpError>;
}) {
  return (
    <DefaultNotReady
      left={props.left}
      ErrComp={HttpErrorViewEitherNotification}
    ></DefaultNotReady>
  );
}
