import * as _ from "lodash";
import { chain } from "lodash";

import { defined } from "../../../../core/defined";
import { isEligibleLegendDimension } from "../dimensions";
import { LabelsByDimension } from "../../datasets/DatasetGeneric";

export function getOrderedDimensions(
  nonPrimaryDimensionsInitialSort: string[],
  getNextDimension: (dimensions: string[]) => string,
  labelsByDimension: LabelsByDimension,
  fixedDimensions?: string[]
): OrderedDimensions {
  const nonPrimaryDimensionsFinalSort: string[] = [];
  let legendDimension: string | undefined;
  let hasLegend = false;

  const freeDimensions = nonPrimaryDimensionsInitialSort.slice();

  // A dimension that varies has more than 1 label
  const dimensionsCopy = (fixedDimensions ?? freeDimensions).slice();
  const legendApplicable = freeDimensions.length > 1;

  for (let i = freeDimensions.length - 1; i >= 0; i--) {
    // We need at least 2 dimensions that vary to create a useful legend.
    if (!hasLegend && legendApplicable) {
      const legendCandidates = defined(fixedDimensions)
        ? dimensionsCopy
        : chain(dimensionsCopy).filter(isEligibleLegendDimension).value();

      if (legendCandidates.length > 0) {
        const nextDimension = getNextDimension(legendCandidates);
        const validLegendIndex = dimensionsCopy.indexOf(nextDimension);
        if (validLegendIndex >= 0) {
          const [legend] = dimensionsCopy.splice(validLegendIndex, 1);
          nonPrimaryDimensionsFinalSort.unshift(legend);
          legendDimension = legend;
          hasLegend = true;
          continue;
        }
      }
    }

    const nextDimension = getNextDimension(dimensionsCopy);
    dimensionsCopy.splice(dimensionsCopy.indexOf(nextDimension), 1);
    if (defined(nextDimension)) {
      nonPrimaryDimensionsFinalSort.unshift(nextDimension);
    }
  }

  return new OrderedDimensions(nonPrimaryDimensionsFinalSort, legendDimension);
}

/**
 * Class for ordered dimensions. Non-primary dimensions only.
 */
export class OrderedDimensions {
  constructor(
    /**
     * Dimensions sorted from outer to inner where the innermost is the one
     * closest to the chart
     */
    private _dimensionsSorted: readonly string[],
    /**
     * If the dataset has a single legend dimension, it is given here.
     * If multiple dimensions are combined to create the legend, this is undefined.
     */
    private _legendDimension: string | undefined
  ) {}

  get dimensions(): readonly string[] {
    return this._dimensionsSorted;
  }

  get inner(): string {
    return _.last(this._dimensionsSorted)!;
  }

  get dimensionsWithoutInner(): readonly string[] {
    return this._dimensionsSorted.slice(0, this._dimensionsSorted.length - 1);
  }

  get legendDimension(): string | undefined {
    return this._legendDimension;
  }
}
