import { BackToStatsButton, LogoutButton } from "./HttpErrorView";

import "./SimpleErrorPage.scss";

export function SimpleErrorPage(props: {
  title: string;
  text: string | JSX.Element;
  includeBackButton: boolean;
  includeLogoutButton: boolean;
}) {
  return (
    <div className="simple-error-view">
      <h1>{props.title}</h1>
      <p>{props.text}</p>
      <div>
        {props.includeBackButton && <BackToStatsButton></BackToStatsButton>}
        {props.includeLogoutButton && <LogoutButton></LogoutButton>}
      </div>
    </div>
  );
}
