import { useCallback, useContext, useState } from "react";
import {
  UserInfoContext,
  AppMessagesContext,
  UserInfoUpdateContext,
} from "../../../../lib/application/contexts";
import { Checkbox } from "@fluentui/react";
import { Card } from "../../../../components/Card";
import { voidFunc } from "../../../../lib/core/voidFunc";
import { logger } from "../../../../lib/infra/logging";

export function General() {
  const userInfo = useContext(UserInfoContext);
  const [isUpdating, setIsUpdating] = useState(false);

  const appMessages = useContext(AppMessagesContext);
  const handleUpdateUser = useContext(UserInfoUpdateContext);

  const [hideSupportBubble, setHideSupportBubble] = useState(
    () => userInfo?.preferences().hideSupportBubble ?? false
  );

  const toggleSupport = useCallback(() => {
    const newState = !hideSupportBubble;
    setHideSupportBubble(newState);
    setIsUpdating(true);
    handleUpdateUser?.({ hideSupportBubble: newState })
      .then((res) => {
        res.match({
          ok: voidFunc,
          err: (err) => {
            logger.error(err);
            appMessages?.add("error", "Kunde inte spara ändringarna");
            setHideSupportBubble(hideSupportBubble);
          },
        });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }, [appMessages, handleUpdateUser, hideSupportBubble]);

  return (
    <Card id="general-settings">
      <h2>Allmänt</h2>
      <Checkbox
        disabled={isUpdating}
        checked={!hideSupportBubble}
        label="Visa supportbubbla"
        onChange={toggleSupport}
      />
    </Card>
  );
}
