import { AlertBox } from "../../../../../components/AlertBox";
import { Card } from "../../../../../components/Card";
import { DocCardError } from "../../../../../lib/application/state/stats/document-core/core";

import "./ErrorCard.scss";

export function ErrorCard(props: { card: DocCardError }) {
  return (
    <Card className="error-card document-card-container">
      <AlertBox intent="warning">
        <div>
          <h3>Fel vid laddning av kort</h3>
          <span>{props.card.message}</span>
        </div>
      </AlertBox>
    </Card>
  );
}
