import { assertNever } from "../../../core/assert";
import {
  OperandV3,
  ComputedVariableV3,
} from "../../state/stats/document-core/DataOutputSettings";

export interface OperandDto {
  dimension_value_id?: number;
  user_defined_series_label?: string;
  region?: string;
  grouping?: string;
  const?: number;
}

export interface UserDefinedSeriesDto {
  label: string;
  dimension: string;
  operator: "+" | "-" | "*" | "/" | "%" | "absdiff";
  left_side: OperandDto[];
  right_side: OperandDto[];
}

function mapOperand(operand: OperandV3): OperandDto {
  switch (operand.type) {
    case "constant":
      return {
        const: operand.value,
      };
    case "dimension_value_with_id":
      return {
        dimension_value_id: operand.id,
      };
    case "grouping":
      return {
        grouping: operand.label,
      };
    case "region":
      return { region: operand.value };
    case "user_defined_value":
      return {
        user_defined_series_label: operand.label,
      };
    default:
      assertNever(operand);
  }
}

export function userDefinedSeriesToDto(
  computedVariables: ComputedVariableV3[]
): UserDefinedSeriesDto[] {
  return computedVariables.map((v) => {
    return {
      label: v.label,
      dimension: v.dimension,
      operator: v.operator,
      left_side: v.leftOperand.map((o) => mapOperand(o)),
      right_side: v.rightOperand.map((o) => mapOperand(o)),
    };
  });
}
