import _, { max } from "lodash";

import { nonEmptyString } from "../../../../core/nonEmptyString";
import { TextStyle } from "./TextStyle";
import { calculateTextWidth } from "./text_containers/measure";

export enum TextSplittingMode {
  breakOnWhitespace = "breakOnWhitespace",
  breakAnywhere = "breakAnywhere",
}

export interface SplitResult {
  lines: string[];
  maxWidth: number;
  desiredMaxWidth: number;
  splittingMode: TextSplittingMode;
}

/**
 * Splits on whitespace, but only if it isn't preceded of succeeded by
 * numbers. This means we won't split text like:
 * 50 000 - 99 000 kr
 */
const splitRegex = new RegExp(/[/\s]+(?![0-9]|kr\b|år\b)(?=.+)/g);

export function splitByWhitespace(
  text: string,
  padding: number,
  textStyle: TextStyle,
  desiredMaxWidth: number
): SplitResult {
  const lines: string[] = [];
  const splitCharMatches = [...text.matchAll(splitRegex)];

  const parts = text.split(splitRegex);
  let current = "";
  for (let i = 0; i < parts.length; i++) {
    if (i === 0) {
      current = parts[i];
      continue;
    }

    const matchedPartSplitter = splitCharMatches[i - 1]?.[0] ?? "";

    if (
      calculateTextWidth(current + matchedPartSplitter + parts[i], textStyle) +
        padding >
      desiredMaxWidth
    ) {
      lines.push(
        current +
          (nonEmptyString(matchedPartSplitter.trim())
            ? matchedPartSplitter.trimEnd()
            : "")
      );
      current = parts[i];
    } else {
      current =
        (current === "" ? "" : current + matchedPartSplitter) + parts[i];
    }
  }
  lines.push(current);

  return {
    lines,
    maxWidth: _.chain(lines)
      .map((l) => calculateTextWidth(l, textStyle) + padding)
      .max()
      .value(),
    desiredMaxWidth,
    splittingMode: TextSplittingMode.breakOnWhitespace,
  };
}

export function splitByForce(
  text: string,
  maxWidth: number,
  calcTextWidth: (text: string) => number
): SplitResult {
  let splitInNumParts = 2;
  let parts: string[] = [text];
  while (
    (max(parts.map(calcTextWidth)) ?? 0) > maxWidth &&
    canSplit(text, splitInNumParts) &&
    splitInNumParts < 10
  ) {
    splitInNumParts += 1;
    parts = split(text, splitInNumParts);
  }

  parts = parts.map((part) => part.trim()).filter((part) => part.length > 0);

  return {
    lines: parts,
    desiredMaxWidth: maxWidth,
    maxWidth: max(parts.map(calcTextWidth)) ?? 0,
    splittingMode: TextSplittingMode.breakAnywhere,
  };
}

function canSplit(text: string, splitInNumParts: number): boolean {
  return text.length > splitInNumParts;
}

/**
 * Splits text in two parts. If input string has odd length, the first part
 * will contain one character more than the second part.
 */
export function split(text: string, numParts: number): string[] {
  if (numParts > text.length) {
    return [text];
  }

  const charsPerPart = Math.ceil(text.length / numParts);
  const parts: string[] = [];
  for (let i = 0; i < numParts; i++) {
    parts.push(text.slice(i * charsPerPart, i * charsPerPart + charsPerPart));
  }
  return parts;
}
