import { useCallback, useState } from "react";
import {
  TabbedCardMain,
  TabbedCardTab,
  TabbedCardTabsContainer,
  TabbedCardsContentContainer,
} from "../../../../../components/Card";
import { ISharingInfo } from "../../../../../lib/application/files/SharingInfo";
import { CardContainer } from "../CardContainer";
import { PythonCardTab } from "../card_tabs/PythonCardTab";
import { CreateDataframe } from "./CreateDataframe";

import "./PythonCard.scss";
import { PythonAnalysis } from "./PythonAnalysis";
import { TabContainer } from "../card_tabs/CardTab";
import { useRecoilValue } from "recoil";
import { pythonCardQuery } from "../../../../../lib/application/state/stats/document-core/queries/card";
import { createDataFrameDataLink } from "../../../../../lib/application/requests/data_links";
import {
  DataLinkParams,
  getFullDataLinkDataFrame,
} from "../../../../../lib/paths";
import { ExportViaLinkDialog } from "../card_general/ExportViaLinkDialog";
import { getDataLinkFeatureSupport } from "../../../../../lib/domain/data_links";

interface Props {
  cardId: string;
  removeCard: (cardId: string) => void;
  sharingInfo: ISharingInfo;
  onDuplicateCard: (cardId: string) => void;
}

const cardTabs = ["create", "execute"];
export function PythonCard(props: Props) {
  const { cardId, removeCard, onDuplicateCard } = props;
  const [currentTabUnchecked, setCurrentTab] = useState(cardTabs[0]);

  const onSelectTab = useCallback((name: string) => setCurrentTab(name), []);
  const handleRemoveCard = useCallback(
    () => removeCard(cardId),
    [cardId, removeCard]
  );

  const handleDuplicateCard = useCallback(() => {
    onDuplicateCard(cardId);
  }, [cardId, onDuplicateCard]);

  const currentTab = cardTabs.includes(currentTabUnchecked)
    ? currentTabUnchecked
    : cardTabs[0];

  const [showExportDialog, setShowExportDialog] = useState(false);

  return (
    <>
      {showExportDialog && (
        <LinkExport
          cardId={props.cardId}
          closeLinkExportModal={() => setShowExportDialog(false)}
        />
      )}
      <CardContainer
        className="python-card"
        cardId={props.cardId}
        isRemovingCard={false}
        removeBrokenCard={props.removeCard}
        sharingInfo={props.sharingInfo}
      >
        <TabbedCardTabsContainer currentTab={currentTab}>
          <TabbedCardTab
            key="primary"
            name={cardTabs[0]}
            onSelect={onSelectTab}
          >
            <PythonCardTab
              sharingInfo={props.sharingInfo}
              cardId={props.cardId}
              handleDuplicateCard={handleDuplicateCard}
              handleRemoveCard={handleRemoveCard}
              handleExportViaLink={() => setShowExportDialog(true)}
            />
          </TabbedCardTab>
          <TabbedCardTab
            key="execute"
            name={cardTabs[1]}
            onSelect={onSelectTab}
          >
            <TabContainer>
              <></>
              <p>Analys</p>
            </TabContainer>
          </TabbedCardTab>
        </TabbedCardTabsContainer>
        <TabbedCardsContentContainer currentTab={currentTab}>
          <TabbedCardMain name={cardTabs[0]}>
            <CreateDataframe cardId={cardId}></CreateDataframe>
          </TabbedCardMain>
          <TabbedCardMain name={cardTabs[1]}>
            <PythonAnalysis cardId={props.cardId} />
          </TabbedCardMain>
        </TabbedCardsContentContainer>
      </CardContainer>
    </>
  );
}

const featureSupport = getDataLinkFeatureSupport("dataframe");

function LinkExport(props: {
  cardId: string;
  closeLinkExportModal: () => void;
}) {
  const card = useRecoilValue(pythonCardQuery(props.cardId));

  return (
    <ExportViaLinkDialog
      featureSupport={featureSupport}
      exportFunc={(params: DataLinkParams, description: string) =>
        createDataFrameDataLink(card, description).then((res) =>
          res.map((linkRes) => ({
            link: getFullDataLinkDataFrame(linkRes.link, params),
          }))
        )
      }
      handleClose={() => props.closeLinkExportModal()}
    />
  );
}
