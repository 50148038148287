import { useCallback } from "react";

import { DateRangeRaw } from "../../../../domain/measure/definitions";
import { DocCardMicro } from "../../stats/document-core/core";
import { defined } from "../../../../core/defined";
import {
  availableTimespanQuery,
  microCardUpdateTimeSelection,
} from "../../stats/document-core/queries/generalDataCard";
import { useRecoilValue } from "recoil";
import { MicroGeoTree } from "../../../stats/shared/MicroGeoTree";
import { logger } from "../../../../infra/logging";
import { useApplyChangeMicro } from "../../stats/useApplyChangeMicro";

export function useChangeTimeMicro(
  card: DocCardMicro,
  geoTree: MicroGeoTree | undefined
) {
  const availableTimespan = useRecoilValue(
    availableTimespanQuery({ cardStateId: card.id })
  );
  const handleApply = useApplyChangeMicro(card.id);

  const handleUpdateTime = useCallback(
    async (range: DateRangeRaw) => {
      if (!defined(geoTree)) {
        logger.warn("Attemptyng to update time, but no geoTree available");
        return;
      }
      const cardDataUpdated: DocCardMicro = microCardUpdateTimeSelection(
        card,
        range
      );
      if (defined(availableTimespan)) {
        const latestAvailablePoint =
          availableTimespan.type === "range"
            ? availableTimespan.range[1]
            : availableTimespan.timePoints[
                availableTimespan.timePoints.length - 1
              ];
        cardDataUpdated.data.lockToLatestTime =
          range[1] === latestAvailablePoint;
      }
      const updatedCard: DocCardMicro = { ...card, data: cardDataUpdated.data };
      handleApply(updatedCard);
    },
    [availableTimespan, card, geoTree, handleApply]
  );

  return handleUpdateTime;
}

export function useUnsetLockToLatestTimeMicro(
  card: DocCardMicro,
  geoTree: MicroGeoTree | undefined
) {
  const handleApply = useApplyChangeMicro(card.id);

  return useCallback(async () => {
    if (!defined(geoTree)) {
      logger.warn(
        "Attemptyng to unset lock to latest time, but no geoTree available"
      );
      return;
    }
    const updatedCard: DocCardMicro = {
      ...card,
      data: { ...card.data, lockToLatestTime: false },
    };
    handleApply(updatedCard);
  }, [card, geoTree, handleApply]);
}

export function useActivateLockToLatestTimeMicro(
  card: DocCardMicro,
  geoTree: MicroGeoTree | undefined
) {
  const handleApply = useApplyChangeMicro(card.id);

  return useCallback(async () => {
    if (!defined(geoTree)) {
      logger.warn(
        "Attemptyng to unset lock to latest time, but no geoTree available"
      );
      return;
    }
    const updatedCard: DocCardMicro = {
      ...card,
      data: { ...card.data, lockToLatestTime: true },
    };
    handleApply(updatedCard);
  }, [card, geoTree, handleApply]);
}
