import {
  Array as ArrayRT,
  Record,
  String as StringRT,
  Union,
  Literal,
  Static,
  Unknown,
  Null,
  Optional,
} from "runtypes";

export const FolderNodeDtoRT = Record({
  id: StringRT,
  name: StringRT,
  type: Union(Literal("file"), Literal("dir")),
  created_at: StringRT,
  owner: Union(Literal("customer"), Literal("infostat")),
  parent_id: StringRT.Or(Null),
  status: Optional(StringRT),
  updated_at: StringRT,
  uploaded_by: StringRT,
});

export type FolderNodeDto = Static<typeof FolderNodeDtoRT>;
export const FileResultDtoRT = Record({
  contentType: StringRT,
  name: StringRT,
  data: Unknown, // TODO
});

export type FileResultDto = Static<typeof FileResultDtoRT>;

export const FolderNodesArrayDtoRT = ArrayRT(FolderNodeDtoRT).Or(Null);
