import React, { useMemo, useCallback } from "react";
import { Dropdown, IDropdownOption } from "@fluentui/react";

import { SELECT_ALL_LABEL } from "../../lib/application/menus";

interface MultiSelectDropdownProps {
  options: IDropdownOption[];
  label: string;
  selectedKeys: any[];
  onChange: (selectedKeys: any[]) => void;
  onRenderTitle?: (options: IDropdownOption[] | undefined) => JSX.Element;
  disabled: boolean;
}

const SELECT_ALL_KEY = "__selectAll__";

export function MultiDropdownWithSelectAll(props: MultiSelectDropdownProps) {
  const { options, label, selectedKeys, onChange, disabled } = props;

  // Define the "Select All" option
  const selectAllOption: IDropdownOption = useMemo(
    () => ({
      key: SELECT_ALL_KEY,
      text: SELECT_ALL_LABEL,
    }),
    []
  );

  // State to manage the selected keys internally
  const allSelected = useMemo(
    () => options.every((opt) => selectedKeys.includes(opt.key as any)),
    [options, selectedKeys]
  );

  const combinedSelectedKeys = useMemo(
    () => (allSelected ? [SELECT_ALL_KEY, ...selectedKeys] : selectedKeys),
    [allSelected, selectedKeys]
  );

  // Handle the selection logic
  const handleDropdownChange = useCallback(
    (e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (!option) {
        return;
      }

      let updatedSelectedKeys = [...selectedKeys];

      if (option.key === selectAllOption.key) {
        if (option.selected) {
          // Select all options
          updatedSelectedKeys = options.map((opt) => opt.key as any);
        } else {
          // Deselect all options
          updatedSelectedKeys = [];
        }
      } else {
        if (option.selected) {
          updatedSelectedKeys.push(option.key as any);
        } else {
          updatedSelectedKeys = updatedSelectedKeys.filter(
            (key) => key !== option.key
          );
        }
      }

      onChange(updatedSelectedKeys);
    },
    [options, selectAllOption, selectedKeys, onChange]
  );

  // Combine the options with the "Select All" option
  const combinedOptions = useMemo(
    () => [selectAllOption, ...options],
    [options, selectAllOption]
  );

  return (
    <Dropdown
      disabled={disabled}
      multiSelect
      options={combinedOptions}
      label={label}
      selectedKeys={combinedSelectedKeys}
      onRenderTitle={(options) => {
        const renderableOptions = options?.filter(
          (opt) => opt.key !== SELECT_ALL_KEY
        );
        return (
          <span>
            {props.onRenderTitle?.(renderableOptions) ??
              (renderableOptions?.map((opt) => opt.text) ?? []).join(", ")}
          </span>
        );
      }}
      onChange={handleDropdownChange}
    />
  );
}
