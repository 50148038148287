import * as _ from "lodash";
import { defined } from "../../../../core/defined";
import { RowBaseInterface } from "../row";
import { Dimension, Orientation } from "../core/definitions";
import {
  defaultDimensionLabelComparatorAsc,
  defaultDimensionLabelComparatorDesc,
  DimensionAndLabels,
  surveyDataStandardDimensions,
} from "../dimensions";
import { findReordering, simpleComparatorDesc } from "../../../../core/order";
import { RowValueRegularRaw } from "../../../../infra/api_responses/dataset";
import {
  applySortSpec,
  applySortSpecGeneric,
  SortSpec,
} from "../../../../domain/measure";
import { Dictionary } from "../../../../core/dictionary";
import { getOrderedDimensions } from "./OrderedDimensions";
import { BREAKDOWN_ALL_LABEL } from "../../../../domain/measure/definitions";
import { last } from "../../../../core/last";
import { LabelsByDimension } from "../../datasets/DatasetGeneric";
import { flatMap } from "lodash";
import { SurveyRowValue } from "../../../../infra/api_responses/survey_dataset";
import { logger } from "../../../../infra/logging";

interface LabelsAndDimensions {
  sortedDimensionsAndLabels: DimensionAndLabels[];
  legendDimension?: string;
  sortedNonPrimaryDimensions: readonly string[];
}

/**
 * Sort labels and dimensions in descending order.
 *
 * In the case of vertical bars, the time dimension is always sorted
 * ascending from left to right (natural order).
 */

export function sortedLabelsAndDimensions(
  barOrientation: Orientation,
  sortedDataDimensions: string[],
  rows: RowBaseInterface<RowValueRegularRaw | SurveyRowValue>[],
  numLabels: (dimension: string) => number,
  labelsByDimension: LabelsByDimension,
  fixedSortSpecs: Dictionary<SortSpec>,
  isSurvey: boolean,
  fixedNonPrimaryDimensions?: string[]
): LabelsAndDimensions {
  const nonPrimaryDimensionsInitialSort = sortedDataDimensions.slice(1);

  const getRowDimensionWithDefault = (
    r: RowBaseInterface<string | boolean | number | null>,
    dimension: string
  ): string => {
    const value = isSurvey
      ? r.dimension(dimension) ?? BREAKDOWN_ALL_LABEL
      : r.dimension(dimension);
    if (typeof value === "boolean") {
      logger.error("Boolean value found in dimension", { dimension, value });
      return "";
    }
    return value as string;
  };

  const firstDimension = nonPrimaryDimensionsInitialSort[0];
  if (!defined(firstDimension)) {
    return { sortedDimensionsAndLabels: [], sortedNonPrimaryDimensions: [] };
  }

  // Simple dimensions
  // If a single non-primary dimension but not date, we sort
  // the labels by their corresponding values
  // so that the bars appear in order of height
  if (
    nonPrimaryDimensionsInitialSort.length === 1 &&
    firstDimension !== Dimension.date &&
    firstDimension !== Dimension.grouping
  ) {
    const sortedDimensionsAndLabels = nonPrimaryDimensionsInitialSort.map(
      (dimension) => {
        const rowsSorted = rows.slice().sort((left, right) => {
          const leftValue = left.range();
          const rightValue = right.range();
          if (leftValue === rightValue) {
            return 0;
          }
          return leftValue > rightValue ? -1 : 1;
        });
        const sortSpec = fixedSortSpecs[dimension];
        return {
          dimension: dimension,
          labelsSorted: (defined(sortSpec)
            ? applySortSpecGeneric(rowsSorted, sortSpec, (r) =>
                getRowDimensionWithDefault(r, dimension)
              ).merged()
            : rowsSorted
          ).map((item) => getRowDimensionWithDefault(item, dimension)),
        };
      }
    );

    return {
      sortedDimensionsAndLabels,
      sortedNonPrimaryDimensions: sortedDimensionsAndLabels.map(
        (d) => d.dimension
      ),
    };
  }

  const getNextDimension = (dimensions: string[]) => {
    return dimensions.reduceRight((prev, curr) => {
      if (isSurvey) {
        const prevIsStandard = surveyDataStandardDimensions.includes(
          prev as Dimension
        );
        const currIsStandard = surveyDataStandardDimensions.includes(
          curr as Dimension
        );
        if (prevIsStandard && !currIsStandard) {
          return prev;
        } else if (currIsStandard && !prevIsStandard) {
          return curr;
        }
      }

      if (prev === Dimension.date && curr !== Dimension.date) {
        return prev;
      } else if (prev !== Dimension.date && curr === Dimension.date) {
        return curr;
      }
      const numPrevLabels = numLabels(prev);
      const numCurrLabels = numLabels(curr);
      if (numPrevLabels < numCurrLabels) {
        return prev;
      }
      return curr;
    });
  };

  const orderedDimensions = getOrderedDimensions(
    nonPrimaryDimensionsInitialSort,
    defined(fixedNonPrimaryDimensions)
      ? (dims: string[]) => last(dims)!
      : getNextDimension,
    labelsByDimension,
    fixedNonPrimaryDimensions
  );

  // For each sortable dimension:
  // 1) group by label
  // 2) sort labels by highest values in each group
  // 3) If this dimension has fixed order for top/bottom labels, apply it
  // 4) proceed to next dimension, but this time with the previous dimensions fixated to the highest label
  const fixatedMaxLabels: string[] = [];
  const labelsSorted: string[][] = [];
  const orderedDimensionsMixed = flatMap(
    orderedDimensions.dimensions,
    (d) => d
  );
  for (let i = 0; i < orderedDimensionsMixed.length; i++) {
    const d = orderedDimensionsMixed[i];
    const fixatedDims = _.range(0, fixatedMaxLabels.length).map(
      (i) => orderedDimensionsMixed[i]
    );

    // Filter in rows that match fixated labels
    const filteredRows = rows.filter((r) =>
      fixatedDims.every(
        (f, fi) => getRowDimensionWithDefault(r, f) === fixatedMaxLabels[fi]
      )
    );

    // Group current rows by current dimension labels
    const groupedRows = _.groupBy(filteredRows, (r) =>
      getRowDimensionWithDefault(r, d)
    );
    const groupEntries = Object.entries(groupedRows);
    const reordering = findReordering(groupEntries, (left, right) => {
      if (Dimension.date === d) {
        // For vertical bars, we sort dates in ascending order so that
        // later dates appear to the right
        if (barOrientation === Orientation.vertical) {
          return defaultDimensionLabelComparatorAsc(d)(left[0], right[0]);
        }
        // For horizontal bars, we sort descending
        return defaultDimensionLabelComparatorDesc(d)(left[0], right[0]);
      }
      const leftMax = _.maxBy(left[1], (r) => r.range())?.range() ?? 0;
      const rightMax = _.maxBy(right[1], (r) => r.range())?.range() ?? 0;
      return simpleComparatorDesc(leftMax, rightMax);
    });

    const allLabelsCurrentDimension =
      labelsByDimension.find((l) => l[0] === d)?.[1] ?? [];

    // Here we first get the keys for the current dimension that belong to rows
    // matching the currently fixated dimensions. If there are none, we will miss out on keys,
    // so proceed below to fill in potentially missing keys.
    const groupKeysIncomplete = groupEntries.map((g) => g[0]);
    const dimLabelsSortedIncomplete = reordering.apply(groupKeysIncomplete);
    const dimLabelsSorted =
      dimLabelsSortedIncomplete.length < allLabelsCurrentDimension.length
        ? dimLabelsSortedIncomplete.concat(
            allLabelsCurrentDimension.filter(
              (key) => !dimLabelsSortedIncomplete.includes(key)
            ) ?? []
          )
        : dimLabelsSortedIncomplete;

    // 3. Note that we have to apply this order again to the top dimension labels further down.
    const sortSpec: SortSpec | undefined = fixedSortSpecs[d];
    labelsSorted.push(
      defined(sortSpec)
        ? applySortSpec(dimLabelsSorted, sortSpec).merged()
        : dimLabelsSorted
    );
    fixatedMaxLabels.push(dimLabelsSorted[0]);
  }

  const topDimension = orderedDimensions.dimensions[0];

  // Now the orders for all dimensions except the top dimension is decided.
  // Use previously fixated label orders in order to sort the top dimension.
  if (topDimension !== Dimension.date) {
    const column = topDimension;
    const max = (topLabel: string) => {
      return (
        _.maxBy(
          rows.filter((r) => {
            const matchesTop =
              getRowDimensionWithDefault(r, column) === topLabel;
            const isMatch = fixatedMaxLabels.every((f, fi) => {
              const fiDim = orderedDimensions.dimensions[fi];
              return fi === 0 || getRowDimensionWithDefault(r, fiDim) === f;
            });
            return matchesTop && isMatch;
          }),
          (r) => r.range()
        )?.range() ?? 0
      );
    };

    const firstDimensionLabels = labelsSorted[0];
    if (firstDimensionLabels.length === 0) {
      throw new Error("No labels for top dimension");
    }
    const topReordering = findReordering(
      firstDimensionLabels,
      (left, right) => {
        const maxLeft = max(left);
        const maxRight = max(right);
        return simpleComparatorDesc(maxLeft, maxRight);
      }
    );
    labelsSorted[0] = topReordering.apply(firstDimensionLabels);
    const sortSpec: SortSpec | undefined = fixedSortSpecs[topDimension];
    if (defined(sortSpec)) {
      labelsSorted[0] = applySortSpec(firstDimensionLabels, sortSpec).merged();
    }
  }

  const sorted: DimensionAndLabels[] = flatMap(
    orderedDimensions.dimensions,
    (dimension) => dimension
  ).map((dimension, i) => ({
    dimension,
    labelsSorted: labelsSorted[i],
  }));
  return {
    sortedDimensionsAndLabels: sorted,
    legendDimension: orderedDimensions.legendDimension,
    sortedNonPrimaryDimensions: orderedDimensions.dimensions,
  };
}
