import { Spinner, SpinnerLabelPosition, SpinnerSize } from "@fluentui/react";
import React from "react";
import { useEffect, useState } from "react";
import { classNames } from "../lib/core/classNames";

import "./DelayedSpinner.scss";

interface Props {
  delayShowMs: number;
  labelPosition?: SpinnerLabelPosition;
  label?: string;
  size?: SpinnerSize;
  className?: string;
}
export function DelayedSpinner(props: Props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setShow(true);
    }, props.delayShowMs);
    return () => clearTimeout(id);
  });

  return !show ? (
    <div></div>
  ) : (
    <Spinner
      className={classNames("delayed-spinner", props.className)}
      size={props.size ?? SpinnerSize.medium}
      labelPosition={props.labelPosition}
      label={props.label}
    ></Spinner>
  );
}
