import { useCallback, useContext, useEffect, useState } from "react";

import { AlertBox } from "../../../../../components/AlertBox";
import {
  getMeasureMetadata,
  getOrganizationsWithCache,
} from "../../../../../lib/application/requests/admin/common_requests_admin";
import { defined } from "../../../../../lib/core/defined";
import { AdminMetadata } from "../../../../../lib/domain-admin/metadata";
import { InfostatDataWarning } from "./InfostatDataWarning";
import { StepFooter } from "./StepFooter";
import { SimpleMeasureSelect } from "../../shared/SimpleMeasureSelect";
import { MeasureWithPath } from "../../shared/MeasureWithPath";
import { CompleteData0, CompleteData1, DataStep2, DataStep3 } from "./types";
import { SimpleSubjectSelect } from "../../shared/SimpleSubjectSelect";
import { sortBy } from "lodash";
import { OrganizationResponse } from "../../../../../lib/infra/api_responses/admin/organizations";
import { AppMessagesContext } from "../../../../../lib/application/contexts";
import { getMeasureDimensionsV2 } from "../../../../../lib/application/requests/datasets/dimensions";
import { displayHttpErrorInternal } from "../../../../../lib/infra/HttpResult";

const copyableFields: (keyof AdminMetadata)[] = [
  "label",
  "value_type",
  "resolution",
  "agg_method_geo",
  "public_comment",
  "last_update",
  "next_update",
  "source",
  "source_url",
  "measure",
  "descr_long",
  "ext_label",
  "ext_descr",
  "ext_descr_long",
  "ext_source",
  "public_comment",
  "unit_label",
];

export function Step1SelectCategory(props: {
  data: CompleteData0;
  handlePrevious?: () => void;
  handleNext: (data: CompleteData1) => void;
}) {
  const { step1: step2 } = props.data;
  const [step2Prefilled, setStep2Prefilled] = useState<DataStep2 | undefined>(
    undefined
  );
  const [step3Prefilled, setStep3Prefilled] = useState<DataStep3 | undefined>();
  const [area, setArea] = useState<string | undefined>(step2?.area);
  const [subarea, setSubarea] = useState<string | undefined>(step2?.subarea);
  const [subject, setSubject] = useState<string | undefined>(step2?.subject);
  const [isCopying, setIsCopying] = useState(false);

  const [organizations, setOrganizations] = useState<OrganizationResponse>();

  const appMessages = useContext(AppMessagesContext);

  useEffect(() => {
    getOrganizationsWithCache().then((res) =>
      setOrganizations(sortBy(res, "name"))
    );
  }, []);

  const handleNext = useCallback(
    (finalArea: string, finalSubarea: string, finalSubject: string) => {
      props.handleNext({
        ...props.data,
        step1: {
          area: finalArea,
          subarea: finalSubarea,
          subject: finalSubject,
        },
        step2:
          (defined(step2Prefilled) ? step2Prefilled : undefined) ??
          props.data.step2,
        step3:
          (defined(step3Prefilled) ? step3Prefilled : undefined) ??
          props.data.step3,
      });
    },
    [props, step2Prefilled, step3Prefilled]
  );

  const handleCopyMeasure = useCallback(
    async (recentMeasure: MeasureWithPath) => {
      const { path, measureId } = recentMeasure;

      const step2Data: DataStep2 = {};

      setIsCopying(true);

      setArea(path[0]);
      setSubarea(path[1]);
      setSubject(path[2]);

      try {
        const m = await getMeasureMetadata(measureId);
        if (m.value_type === "survey") {
          return appMessages?.add("error", "Kan inte skapa surveymått här!");
        }
        for (const field of copyableFields) {
          const copyValue = m[field] as any;
          if (defined(copyValue)) {
            step2Data[field] = copyValue;
          }
        }
        const dims = await getMeasureDimensionsV2(measureId, false, false).then(
          (res) => {
            return res.match({
              ok: (data) => data,
              err: (e) => {
                throw new Error(displayHttpErrorInternal(e));
              },
            });
          }
        );
        step2Data.breakdowns = {};
        step2Data.breakdownParents = {};

        for (const dim of dims) {
          step2Data.breakdowns[dim.data_column] = dim.label;
          if (defined(dim.parent_id)) {
            const parent = dims.find((d) => d.dimension_id === dim.parent_id);
            if (defined(parent)) {
              step2Data.breakdownParents[dim.data_column] = parent.data_column;
            }
          }
        }

        setStep2Prefilled(step2Data);
        const orgs = m.organization_access_restrictions;
        if (defined(orgs) && orgs.length > 0) {
          const orgSpec = orgs.map((orgId) => {
            const name = organizations?.find((org) => org.id === orgId)?.name;
            if (!defined(name)) {
              throw new Error("Organization not found!");
            }
            return { id: orgId, name };
          });
          setStep3Prefilled({ mode: { locked: true, orgs: orgSpec } });
        }
      } catch (e) {
        appMessages?.add("error", "Kunde inte kopiera mått");
      } finally {
        setIsCopying(false);
      }
    },
    [appMessages, organizations]
  );

  return (
    <div className="select-category">
      <InfostatDataWarning></InfostatDataWarning>
      <h2>Kategorisering</h2>
      <AlertBox>
        <div>
          <h3>Fyll från annat mått?</h3>
          <SimpleMeasureSelect
            actionText="Fyll i"
            onSelect={handleCopyMeasure}
          ></SimpleMeasureSelect>
        </div>
      </AlertBox>
      <section>
        <SimpleSubjectSelect
          area={area}
          subarea={subarea}
          subject={subject}
          onSelect={(path) => {
            setArea(path[0]);
            setSubarea(path[1]);
            setSubject(path[2]);
            return Promise.resolve();
          }}
        ></SimpleSubjectSelect>
      </section>

      <StepFooter
        handlePrevious={props.handlePrevious}
        handleNext={
          !isCopying && defined(area) && defined(subarea) && defined(subject)
            ? () => handleNext(area, subarea, subject)
            : undefined
        }
      ></StepFooter>
    </div>
  );
}
