import { selector } from "recoil";
import { DocumentMetadataDto } from "../../../requests/docs/dto";
import {
  documentMetadata,
  ReportMetaPersistent,
  ReportMetaState,
  reportMetaState,
} from "./definitions";
import { docCardsListQuery } from "../document-core/docCardsListState";
import { docCardAtomFamily } from "../document-core/atoms";
import { cardLoaded } from "../document-core/core";
import { defined } from "../../../../core/defined";

/**
 * @deprecated
 */
export const reportEditModeOnQuery = selector<boolean>({
  key: "reportEditModeOnQuery",
  get: ({ get }) => {
    return get(reportMetaState)?.editModeOn ?? false;
  },
  set: ({ set }, newValue) => {
    return set(reportMetaState, (prevValue) => {
      return {
        ...(prevValue as ReportMetaState),
        editModeOn: newValue as boolean,
      };
    });
  },
});

/**
 * @deprecated
 */
export const reportTitleQuery = selector<string | undefined>({
  key: "reportTitleQuery",
  get: ({ get }) => {
    return get(reportMetaState)?.title;
  },
  set: ({ set }, newValue) => {
    return set(reportMetaState, (prevValue) => {
      return {
        ...(prevValue as ReportMetaState),
        title: newValue as string,
      };
    });
  },
});

/**
 * @deprecated
 */
export const reportMetaStateQuery = selector<ReportMetaState | undefined>({
  key: "reportMetaStateQuery",
  get: ({ get }) => {
    return get(reportMetaState);
  },
  set: ({ set }, newValue) => {
    return set(reportMetaState, newValue);
  },
});

/**
 * @deprecated
 */
export const reportMetaPersistentQuery = selector<
  ReportMetaPersistent | undefined
>({
  key: "reportMetaPersistentQuery",
  get: ({ get }) => {
    return get(reportMetaState);
  },
  set: ({ set }, newValue) => {
    return set(reportMetaState, (prevValue) => {
      return { ...(prevValue as ReportMetaState), ...newValue };
    });
  },
});

export const documentMetadataQuery = selector<DocumentMetadataDto | undefined>({
  key: "documentMetadataQuery",
  get: ({ get }) => {
    return get(documentMetadata);
  },
  set: ({ set }, newValue) => {
    return set(documentMetadata, newValue);
  },
});

export const allCardsInitializedQuery = selector<boolean>({
  key: "allCardsInitializedQuery",
  get: ({ get }) => {
    const docCardsList = get(docCardsListQuery);
    const cards = docCardsList.map((c) => get(docCardAtomFamily(c.id)));
    return cards.every((c) => defined(c) && cardLoaded(c));
  },
});
