export function displayShortenedUrl(url: string): string {
  const withoutPrefix = url.replace(/^(https?:\/\/)?(www\.)?/, "");
  if (withoutPrefix.length > 70) {
    return (
      withoutPrefix.substring(0, 30) +
      "..." +
      withoutPrefix.substring(withoutPrefix.length - 30)
    );
  }
  return withoutPrefix;
}
