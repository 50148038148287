import { Checkbox } from "@fluentui/react";
import { flatMap } from "lodash";
import { useCallback, useState } from "react";
import { AlertBox } from "../../../../../../components/AlertBox";
import { Button } from "../../../../../../components/Button";
import {
  ButtonsFooter,
  ButtonsFooterLeft,
  ButtonsFooterRight,
} from "../../../../../../components/ButtonContainers";
import { HttpErrorNotice } from "../../../../../../components/errors/HttpErrorNotice";
import { ExternalLink } from "../../../../../../components/ExternalLink";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../../components/Modal";
import { config } from "../../../../../../config";
import { HomeType } from "../../../../../../lib/application/requests/address_export";
import { MicroGeoSelections } from "../../../../../../lib/application/state/stats/document-core/core-micro";
import { MicroDataset } from "../../../../../../lib/application/stats/datasets/MicroDataset";
import { getText } from "../../../../../../lib/application/strings";
import { defined } from "../../../../../../lib/core/defined";
import { HttpError, HttpResult } from "../../../../../../lib/infra/HttpResult";
import { logger } from "../../../../../../lib/infra/logging";

export function PostalExportModal(props: {
  originalGeoSelections: MicroGeoSelections;
  microDataset: MicroDataset;
  exportTypeLabel: string;
  handleClose: () => void;
  exportFunc: (
    deso: string[],
    regso: string[],
    homeTypes: HomeType[]
  ) => Promise<HttpResult<unknown>>;
  hasAccess: boolean;
}) {
  const [singleFamily, setSingleFamily] = useState(true);
  const [multiFamily, setMultiFamily] = useState(true);
  const noBoxesChecked = !singleFamily && !multiFamily;
  const [errorMessage, setErrorMessage] = useState<
    { type: "string"; error: string } | { type: "httpError"; error: HttpError }
  >();

  const [isExporting, setIsExporting] = useState(false);

  const filteredGeoSelections = props.microDataset.filterGeoSelectionsByResults(
    props.originalGeoSelections
  );

  const noRegionSelected = filteredGeoSelections.selected.length === 0;

  const handleExport = useCallback(() => {
    setErrorMessage(undefined);

    const homeTypes: HomeType[] = [
      singleFamily ? "single-family" : undefined,
      multiFamily ? "multi-family" : undefined,
    ].filter(defined) as HomeType[];
    const selections = filteredGeoSelections;
    const selectedDeso =
      selections.type === "deso"
        ? flatMap(selections.selected, (item) => item.props).map((p) => p.deso)
        : [];

    const selectedRegso =
      selections.type === "regso"
        ? flatMap(selections.selected, (item) => item.props).map((p) => p.regso)
        : [];
    const max = config.micro.maxNumAreasForAddressExport;
    if (selectedDeso.length >= max || selectedRegso.length >= max) {
      return setErrorMessage({
        type: "string",
        error: getText("micro-address-export-max-regions-exceeded"),
      });
    }
    setIsExporting(true);
    return props
      .exportFunc(selectedDeso, selectedRegso, homeTypes)
      .then((result) => {
        setIsExporting(false);
        result.match({
          ok: props.handleClose,
          err: (err) => {
            logger.error(err);
            setErrorMessage({ type: "httpError", error: err });
          },
        });
      });
  }, [filteredGeoSelections, multiFamily, props, singleFamily]);

  return (
    <FluentModal
      onClose={props.handleClose}
      isOpen={true}
      title={`Exportera ${props.exportTypeLabel}`}
    >
      <FluentModalBody>
        <p>
          Exportera en lista över alla {props.exportTypeLabel} i de valda
          områdena som Excel-fil.
        </p>

        <section>
          <Checkbox
            label="Småhus"
            className="margin-bottom-sm"
            disabled={!props.hasAccess}
            checked={singleFamily}
            onChange={(e, checked) => {
              if (!defined(checked)) {
                return;
              }
              setSingleFamily(checked);
            }}
          ></Checkbox>
          <Checkbox
            disabled={!props.hasAccess}
            label="Flerbostadshus"
            checked={multiFamily}
            onChange={(e, checked) => {
              if (!defined(checked)) {
                return;
              }
              setMultiFamily(checked);
            }}
          ></Checkbox>
        </section>

        <>
          {!props.hasAccess && (
            <AlertBox intent="warning">
              <p>
                Detta kräver en högre licens.{" "}
                <ExternalLink
                  link={config.infostatSeLinks.contact}
                  text="Kontakta Infostat"
                ></ExternalLink>{" "}
                för att uppgradera din licens.
              </p>
            </AlertBox>
          )}
          {props.hasAccess && noRegionSelected && (
            <AlertBox intent="warning">
              <p>
                Du måste välja minst ett område för att kunna exportera{" "}
                {props.exportTypeLabel}.
              </p>
            </AlertBox>
          )}
          {defined(errorMessage) && errorMessage.type === "string" && (
            <AlertBox intent="warning">
              <p>{errorMessage.error}</p>
            </AlertBox>
          )}
          {defined(errorMessage) && errorMessage.type === "httpError" && (
            <HttpErrorNotice error={errorMessage.error}></HttpErrorNotice>
          )}
        </>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterLeft>
            <Button onClick={props.handleClose} title="Avbryt"></Button>
          </ButtonsFooterLeft>

          <ButtonsFooterRight>
            <Button
              disabled={
                !props.hasAccess ||
                noRegionSelected ||
                noBoxesChecked ||
                isExporting
              }
              intent="primary"
              onClick={handleExport}
              title="Exportera"
            />
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModal>
  );
}
