import { sortBy } from "lodash";
import { useEffect, useState, useCallback, useMemo } from "react";
import { Checkbox, TextField } from "@fluentui/react";

import { Button } from "../../../components/Button";
import { ThirdPartyOrgDto } from "../../../lib/infra/api_responses/third_party_orgs/org_admin";
import { UserInfo } from "../../../lib/application/auth/UserInfo";
import { MainContent } from "../../../components/MainContent";
import { Card } from "../../../components/Card";
import { defined } from "../../../lib/core/defined";
import { useEmailsInputState } from "../../../lib/application/hooks/useEmailsInputState";
import { nonEmptyString } from "../../../lib/core/nonEmptyString";
import { Table } from "../../../components/Table";
import { useAppMessages } from "../../../lib/application/hooks/useAppMessages";
import { emptyOrUndefined } from "../../../lib/core/emptyOrUndefined";
import {
  addThirdPartyOrgMembers,
  handleThirdPartyOrgMemberToggleAdmin,
  listThirdPartyOrgs,
  removeThirdPartyOrgMember,
} from "../../../lib/application/requests/third_party_org_admin";
import { logger } from "../../../lib/infra/logging";
import { DefaultLoadingStretchedToFit } from "../../../components/Loading";

export function ThirdPartyOrgAdmin(props: { userInfo?: UserInfo }) {
  const [orgs, setOrgs] = useState<ThirdPartyOrgDto[] | null>(null);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const appMessages = useAppMessages();

  const fetchOrgs = useCallback(() => {
    try {
      listThirdPartyOrgs().then((res) => {
        res.match({
          ok: (data) => {
            setOrgs(data ?? []);
            if (defined(data) && data.length === 0) {
              const firstOrg = data[0];
              setSelectedOrgId(firstOrg.third_party_org_id);
            }
          },
          err: (err) => {
            logger.error("Kunde inte hämta organisationer", err);
            appMessages?.add("error", "Kunde inte hämta organisationer");
            setOrgs([]);
          },
        });
      });
    } catch (err) {
      appMessages?.add("error", "Kunde inte hämta organisationer");
    }
  }, [appMessages]);

  useEffect(() => {
    fetchOrgs();
  }, [fetchOrgs]);

  const selectedOrg = useMemo(
    () => orgs?.find((org) => org.third_party_org_id === selectedOrgId),
    [orgs, selectedOrgId]
  );

  if (orgs === null) {
    return (
      <MainContent>
        <DefaultLoadingStretchedToFit />
      </MainContent>
    );
  }

  return (
    <MainContent>
      <Card className="content-padding padding-y-sm">
        <>
          {defined(selectedOrg) ? (
            <EditOrg
              refetchOrgs={fetchOrgs}
              org={selectedOrg}
              handleCancel={() => setSelectedOrgId("")}
            />
          ) : (
            <>
              <h2>Hantera organisationsmedlemmar</h2>
              {orgs.length === 0 ? (
                <p>Inga organisationer att visa</p>
              ) : (
                <Table
                  noGrow
                  slim
                  columns={[
                    {
                      key: "name",
                      name: "Organisation",
                      type: "text",
                    },
                    { key: "action", name: "", type: "icon" },
                  ]}
                  data={
                    orgs.map((org) => {
                      return {
                        id: org.third_party_org_id,
                        cells: [
                          org.name,
                          <Button
                            small
                            title="Hantera"
                            onClick={() =>
                              setSelectedOrgId(org.third_party_org_id)
                            }
                          />,
                        ],
                      };
                    }) ?? []
                  }
                />
              )}
            </>
          )}
        </>
      </Card>
    </MainContent>
  );
}

function EditOrg(props: {
  org: ThirdPartyOrgDto;
  handleCancel: () => void;
  refetchOrgs: () => void;
}) {
  const { org, handleCancel, refetchOrgs } = props;
  const {
    emailsRaw: newMemberEmailsRaw,
    setEmailsRaw: setNewMemberEmailsRaw,
    emailValidationMessage,
    currentEmails,
  } = useEmailsInputState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  const appMessages = useAppMessages();

  const admins = useMemo(() => {
    return org.addresses?.filter((a) => a.admin) ?? [];
  }, [org.addresses]);

  const handleAddMembers = useCallback(() => {
    if (nonEmptyString(emailValidationMessage)) {
      setErrorMessage(emailValidationMessage);
      return;
    }
    const invalidDomainEmail = currentEmails.find(
      (email) => !org.email_domains?.some((domain) => email.endsWith(domain))
    );
    if (defined(invalidDomainEmail)) {
      setErrorMessage(
        `E-postadressen ${invalidDomainEmail} har inte en tillåten domän. Måste vara ${
          org.email_domains.length > 1 ? "en av" : ""
        }: ${org.email_domains.join(", ")}.`
      );
      return;
    }
    setErrorMessage("");
    setIsUpdating(true);
    addThirdPartyOrgMembers(org.third_party_org_id, currentEmails)
      .then((res) => {
        res.match({
          ok: () => {
            appMessages?.add("success", "Medlemmar tillagda");
            setNewMemberEmailsRaw("");
            refetchOrgs();
          },
          err: (err) => {
            logger.error("Kunde inte lägga till medlemmar", err);
            appMessages?.add("error", "Kunde inte lägga till medlemmar");
          },
        });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  }, [
    appMessages,
    currentEmails,
    emailValidationMessage,
    org.email_domains,
    org.third_party_org_id,
    refetchOrgs,
    setNewMemberEmailsRaw,
  ]);

  const handleToggleAdmin = useCallback(
    (email: string, checked: boolean) => {
      if (admins.length === 1 && admins[0].email === email) {
        appMessages?.add(
          "error",
          "Kan inte ta bort administratörsbehörigheter från den enda administratören"
        );
        return;
      }
      setIsUpdating(true);
      handleThirdPartyOrgMemberToggleAdmin(
        org.third_party_org_id,
        email,
        checked
      )
        .then((res) => {
          res.match({
            ok: () => {
              appMessages?.add("success", "Adminstatus uppdaterad");
              refetchOrgs();
            },
            err: (err) => {
              logger.error("Kunde inte uppdatera adminstatus", err);
              appMessages?.add("error", "Kunde inte uppdatera adminstatus");
            },
          });
        })
        .finally(() => setIsUpdating(false));
    },
    [admins, appMessages, org.third_party_org_id, refetchOrgs]
  );

  const handleRemoveMember = useCallback(
    (email: string) => {
      if (admins.length === 1 && admins[0].email === email) {
        appMessages?.add(
          "error",
          "Kan inte ta bort den enda återstående administratören"
        );
        return;
      }
      setIsUpdating(true);
      removeThirdPartyOrgMember(org.third_party_org_id, email)
        .then((res) => {
          res.match({
            ok: () => {
              appMessages?.add("success", "Medlem borttagen");
              refetchOrgs();
            },
            err: (err) => {
              logger.error("Kunde inte ta bort medlem", err);
              appMessages?.add("error", "Kunde inte ta bort medlem");
            },
          });
        })
        .finally(() => setIsUpdating(false));
    },
    [admins, appMessages, org.third_party_org_id, refetchOrgs]
  );

  return (
    <>
      <h2>Hantera organisationsmedlemmar ({org.name})</h2>

      <h3>Lägg till medlemmar</h3>
      <div className="flex-row margin-top-sm">
        <TextField
          className=""
          multiline
          value={newMemberEmailsRaw}
          placeholder="E-postadresser"
          onChange={(e) => setNewMemberEmailsRaw(e.currentTarget.value)}
        />
        <Button
          intent="primary"
          disabled={isUpdating}
          title={"Lägg till medlemmar"}
          onClick={handleAddMembers}
        />
      </div>

      {defined(errorMessage) && <p style={{ color: "red" }}>{errorMessage}</p>}

      {!emptyOrUndefined(org.addresses) && (
        <section>
          <Table
            noGrow
            slim
            columns={[
              {
                key: "email",
                name: "E-postadress",
                type: "text",
              },
              {
                key: "admin",
                name: "Admin",
                type: "text",
                classNames: "",
              },
              { key: "action", name: "", type: "icon" },
            ]}
            data={
              sortBy(org.addresses, (e) => e.email).map((entry) => {
                return {
                  id: entry.email,
                  cells: [
                    entry.email,
                    <Checkbox
                      disabled={isUpdating}
                      checked={entry.admin}
                      onChange={(e, checked) => {
                        if (!defined(checked)) {
                          return;
                        }
                        handleToggleAdmin(entry.email, checked);
                      }}
                    />,
                    <Button
                      small
                      disabled={isUpdating}
                      intent="danger"
                      title="Ta bort"
                      onClick={() => {
                        handleRemoveMember(entry.email);
                      }}
                    />,
                  ],
                };
              }) ?? []
            }
          />
        </section>
      )}

      <div>
        <Button
          title="Tillbaka"
          disabled={isUpdating}
          onClick={handleCancel}
        ></Button>
      </div>
    </>
  );
}
