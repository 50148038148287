import { getLinkedCard } from "../requests/common_requests";
import { WorkspaceCardLatest, WorkspaceLatest } from "./stats/workspace/shared";
import { Result } from "../../core/Result";
import { HttpError } from "../../infra/HttpResult";

export interface LinkedWorkspaceCard {
  version: number;
  data: WorkspaceCardLatest;
}

export function linkToWorkspaceCard(
  link: string
): Promise<SharingResult<LinkedWorkspaceCard>> {
  return getLinkedCard(link).then((workspaceCard) => {
    const res = workspaceCard.switch();
    if (res.type === "err") {
      return SharingResult.fromErr({
        code: "httpError",
        error: res.error,
      });
    }
    return SharingResult.fromOk(res.data as any);
  });
}

export function linkToDoc(
  link: string
): Promise<SharingResult<WorkspaceLatest>> {
  return getLinkedCard(link).then((doc) => {
    const res = doc.switch();
    if (res.type === "err") {
      return SharingResult.fromErr({
        code: "httpError",
        error: res.error,
      });
    }
    return SharingResult.fromOk(res.data as any);
  });
}

export type SharingError =
  | { code: "decodeError" }
  | { code: "httpError"; error: HttpError };
export class SharingResult<T> extends Result<T, SharingError> {}
