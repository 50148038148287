import { Auth0ContextInterface } from "@auth0/auth0-react/src/auth0-context";

class AuthState {
  private context?: Auth0ContextInterface;
  private _embedKey?: string;

  public setEmbedKey(key: string) {
    this._embedKey = key;
  }

  public get embedKey() {
    return this._embedKey;
  }

  public silentTokenGetterOrThrow() {
    const getter = this.context?.getAccessTokenSilently;
    if (getter === undefined) {
      throw new Error("Expected token getter to be available, was undefined");
    }
    return getter;
  }

  public updateAuthContext(context: Auth0ContextInterface) {
    this.context = context;
  }
}

export const authState = new AuthState();
