import { useParams } from "react-router-dom";

import { getText } from "../../../../../lib/application/strings";
import { defined } from "../../../../../lib/core/defined";
import { DocNotFound } from "../../../../stats/docs/DocNotFound";
import { MemberOrgsMeasureConfirmation } from "./MeasureForMemberOrgsConfirmation";
import { DraftDataTodo } from "../../../../../lib/application_admin/todos/draft_data_todos";

import "../_shared/DataAdministration.scss";

export function MemberOrgsMeasureConfirmationWrapper(props: {
  idParam: string;
  todos?: DraftDataTodo[];
  reloadTodos: () => void;
}) {
  const params = useParams();
  const idRaw = params[props.idParam];
  if (!defined(idRaw)) {
    return <DocNotFound title={getText("page-not-found")}></DocNotFound>;
  }
  const parsedId = parseInt(idRaw, 10);
  return (
    <MemberOrgsMeasureConfirmation
      todos={props.todos}
      reloadTodos={props.reloadTodos}
      id={parsedId}
    />
  );
}
