import { useCallback, useState } from "react";

import { statsApiV2 } from "../../../../lib/application/requests/statsApiV2";
import { useLoadableHttpResource } from "../../../../lib/application/hooks/useLoadableResource";
import { defined } from "../../../../lib/core/defined";
import { Card } from "../../../../components/Card";
import { config } from "../../../../config";

import { PreviewSingleMeasure } from "../DataSearchAndPreview";
import { useRemoveCardCallback } from "../../../../lib/application/state/actions/cardCallbacks";
import { TableLatest } from "./_TableLatest";

export function LatestUpdatedMeasures(props: {}) {
  const [res] = useLoadableHttpResource(statsApiV2.getMeasureUpdates);
  const data = res.getReadyData();
  const [selectedDataId, setSelectedDataId] = useState<number | undefined>();

  const handleRemoveCard = useRemoveCardCallback();

  const handleCloseModal = useCallback(
    (cardId: string) => {
      setSelectedDataId(undefined);
      handleRemoveCard(cardId);
    },
    [handleRemoveCard]
  );

  if (!defined(data) || data.length === 0) {
    return <></>;
  }

  return (
    <>
      {defined(selectedDataId) && (
        <PreviewSingleMeasure
          dataId={selectedDataId}
          handleClose={handleCloseModal}
        ></PreviewSingleMeasure>
      )}
      <Card id="latest-updated-measures">
        <div className="content-padding">
          <h2 className="padding-top-sm margin-bottom-md">Senaste statistik</h2>
        </div>

        <TableLatest
          onRowClick={(id) => setSelectedDataId(id)}
          columns={[
            {
              name: "Mått",
              key: "measure",
              type: "text",
            },
            {
              name: "Senaste ändring",
              key: "last_update",
              type: "text",
              classNames: "date",
            },
          ]}
          tableData={data.slice(0, 40).map((item) => {
            const othersString =
              item.other_measurements_count > 0
                ? ` (+${item.other_measurements_count} ${
                    item.other_measurements_count === 1 ? "annat" : "andra"
                  })`
                : "";
            const measure = ` ${item.subject} | ${item.measure}
                    ${othersString}`;
            return {
              id: item.data_id,
              cells: [
                <>
                  <p className="line-header">{measure}</p>
                  <p className="subtext">
                    {item.area} {">"} {item.subarea}
                  </p>
                </>,

                new Date(item.last_update).toLocaleDateString(config.locale),
              ],
            };
          })}
        ></TableLatest>
      </Card>
    </>
  );
}
