import { atom } from "recoil";
import {
  Record,
  Boolean as BooleanRT,
  Static,
  String as StringRT,
  Optional,
} from "runtypes";
import { DocumentMetadataDto } from "../../../requests/docs/dto";

/**
 * @deprecated
 * Old metadata structure that is part of the document JSON in storage.
 * To be migrated to the proper DocumentMetadata structure.
 */
export interface ReportMetaState {
  id: number;
  title: string;
  draft?: boolean;
  editModeOn: boolean;
  noFramesMode: boolean;
  userHasApplicableWriteLicenses: boolean;
  thirdPartySharingDoc?: boolean;
  // Only used for third party docs with custom branding
  customBranding?: ThirdPartyDocCustomBrandingDto;
}

export const ThirdPartyDocCustomBrandingDtoRT = Record({
  backgroundColor: StringRT,
  foregroundColor: StringRT,
  logoSmallUrl: StringRT,
  logoBigUrl: StringRT,
});
export type ThirdPartyDocCustomBrandingDto = Static<
  typeof ThirdPartyDocCustomBrandingDtoRT
>;

export const ReportMetaPersisentRT = Record({
  editModeOn: BooleanRT,
  noFramesMode: Optional(BooleanRT),
  thirdPartySharingDoc: Optional(BooleanRT),
  customBranding: Optional(ThirdPartyDocCustomBrandingDtoRT),
});

export function reportMetaStateToPersistent(
  state: ReportMetaState
): ReportMetaPersistent {
  return {
    customBranding: state.customBranding,
    editModeOn: state.editModeOn,
    noFramesMode: state.noFramesMode,
    thirdPartySharingDoc: state.thirdPartySharingDoc,
  };
}

export const ReportMetaPersistentSharingRT = ReportMetaPersisentRT.And(
  Record({
    // Must be optional to be compatible with non-sharing format
    defaultTitle: Optional(StringRT),
  })
);

export type ReportMetaPersistent = Static<typeof ReportMetaPersisentRT>;
/**
 * When sharing a document link, we must currently embed the original title in the document data
 * so that shared document copies can be restored with the same title.
 * We currently don't store a name for link codes.
 */
export type ReportMetaPersistentSharing = Static<
  typeof ReportMetaPersistentSharingRT
>;

export function reportMetaPersistentDefault(): ReportMetaPersistent {
  return {
    editModeOn: true,
  };
}

/**
 * @deprecated
 */
export const reportMetaState = atom<ReportMetaState | undefined>({
  key: "reportMetaState",
  default: undefined,
});

export const documentMetadata = atom<DocumentMetadataDto | undefined>({
  key: "documentMetadata",
  default: undefined,
});
