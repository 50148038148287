import { Checkbox, IconButton, IDropdownOption } from "@fluentui/react";
import { isEqual, uniq } from "lodash";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { AlertBox } from "../../../../../../components/AlertBox";
import { HelpIcon } from "../../../../../../components/HelpIcon";
import { FluentIcon } from "../../../../../../components/Icons";
import { LineClear } from "../../../../../../components/LineClear";
import { config } from "../../../../../../config";
import {
  CardUpdateCountContext,
  ShowDraftDataContext,
} from "../../../../../../lib/application/contexts";
import { useToggle } from "../../../../../../lib/application/hooks/useToggle";
import {
  useReadMicroCardState,
  useSetMultiMultiSelect,
} from "../../../../../../lib/application/state/actions/micro/updates";
import { replaceInArrayImmut } from "../../../../../../lib/application/state/generic";
import {
  FilterSet,
  MicroDataFilter,
  MicroOutputTab,
  MicroSubjectPath,
} from "../../../../../../lib/application/state/stats/document-core/core-micro";
import {
  microCardMultiSelectQuery,
  singleMicroCardQuery,
} from "../../../../../../lib/application/state/stats/document-core/queries/microCard";
import { defined } from "../../../../../../lib/core/defined";
import { Categories } from "../../../../../../lib/domain/categories";
import { GeoTypeMicro } from "../../../../../../lib/domain/geography";
import {
  dateRangeRawIsSingular,
  setMicroCardAvailableDatesImmut,
} from "../../../../../../lib/domain/measure";
import {
  DateRangeRaw,
  MeasureSelectionMicroPartial,
  SelectedDimensionsV2,
} from "../../../../../../lib/domain/measure/definitions";
import { getMicroMapSelectedDimensions } from "../../../../../../lib/domain/micro/operations";
import { DataValueTypeMicroAll } from "../../../../../../lib/infra/api_responses/dataset";
import {
  ComputedMeasurementType,
  MicroMeasureDto,
} from "../../../../../../lib/infra/api_responses/micro_dataset";
import {
  StyledDropdown,
  StyledDropdownCondensed,
} from "../../card_general/StyledDropdown";
import { TimeSelect } from "../../card_general/TimeSelect";
import { useMicroAvailableMeasures } from "../useMicroAvailableMeasures";
import { useMicroMeasure } from "../useMicroMeasure";
import { VariablesConfig } from "./VariablesConfig";
import { DimensionSelectionOuter } from "./DimensionSelectionOuter";
import { FilterMeasures } from "./FilterMeasures";
import { FilterSelector } from "./Filters";
import {
  useVariablesStatePrimarySelection,
  useSelectionInputState,
} from "./_shared";

import "./MeasureSelectionMicro.scss";
import { PromiseController } from "../../../../../../lib/application/loading/load_status";
import { loadAndStoreDataMicro } from "../../../../../../lib/application/state/stats/cardToDataStateMicro";
import { useGeoTree } from "../useGeoTree";
import {
  useActivateLockToLatestTimeMicro,
  useChangeTimeMicro,
  useUnsetLockToLatestTimeMicro,
} from "../../../../../../lib/application/state/actions/micro/useChangeTimeMicro";
import { useSaveCard } from "../../../../../../lib/application/state/actions/useSaveDocument";
import { DocCardMicro } from "../../../../../../lib/application/state/stats/document-core/core";
import { logger } from "../../../../../../lib/infra/logging";
import { cardColors } from "../../../../../../lib/application/state/stats/document-core/queries/shared";
import { Progress } from "../../../../../../lib/core/progress";
import {
  DataLoadErrorMessage,
  DataloadNotStartedMessage,
} from "../../card_general/data_load_views";
import { useExtendedAppearanceSettings } from "../../../../../../lib/application/state/actions/useExtendedAppearanceSettings";
import { FilterMeasuresThirdPartyDoc } from "./FilterMeasuresThirdPartyDoc";
import { classNames } from "../../../../../../lib/core/classNames";

const geotypes: { geotype: GeoTypeMicro; label: string }[] = [
  { geotype: "deso", label: "Lokalområden" },
  { geotype: "regso", label: "Kommundelar" },
];

interface Props {
  cardId: string;
  /** Hide measure (and are, subarea, subject) */
  selection?: MeasureSelectionMicroPartial;
  categories: Categories;
  handleChangePath: (
    selectionId: string | undefined,
    path: MicroSubjectPath
  ) => void;
  handleCancelCreateNewSelection?: () => void; // Only supplied when this selection is for creating a new selection
  handleRemoveMeasure: (selectionId: string) => void;
  handleChangeMeasure: (
    selectionId: string | undefined,
    id: number,
    computedMeasureType?: ComputedMeasurementType
  ) => void;
  handleChangeGeotype: (geotype: GeoTypeMicro) => void;
  changePending: boolean;
  autofill: boolean;
  acceptMeasureValueTypes: DataValueTypeMicroAll[];
  // In order to prevent selecting the same measure twice, we need to know which measures are already selected
  otherSelectedMeasures: [
    dataId: number,
    computedMeasureType: ComputedMeasurementType
  ][];
}

export function MeasureSelectionMicro(props: Props) {
  const seeDraftData = useContext(ShowDraftDataContext);
  const subjectPath = props.selection?.subjectPath;

  const availableMeasures = useMicroAvailableMeasures(
    subjectPath ?? [undefined, undefined, undefined],
    seeDraftData,
    [],
    props.acceptMeasureValueTypes
  );

  return (
    <MicroMeasureSelectionInner
      {...props}
      isThirdPartyView={false}
      availableMeasures={availableMeasures}
    />
  );
}

export function MeasureSelectionMicroThirdPartyDoc(props: Props) {
  return (
    <MicroMeasureSelectionInner
      {...props}
      isThirdPartyView={true}
      availableMeasures={[]}
    />
  );
}

export function MicroMeasureSelectionInner(
  props: Props & {
    availableMeasures: MicroMeasureDto[];
    isThirdPartyView: boolean;
  }
) {
  const {
    availableMeasures,
    categories,
    handleChangePath,
    changePending,
    handleChangeMeasure,
    handleChangeGeotype,
    selection,
    isThirdPartyView,
  } = props;

  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: props.cardId })
  );
  const geoTree = useGeoTree();
  const multiSelect = useRecoilValue(
    microCardMultiSelectQuery({ cardStateId: props.cardId })
  );
  const handleSaveCard = useSaveCard();
  const setMultiSelect = useSetMultiMultiSelect(props.cardId, selection?.id);
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const appearanceSettings = useExtendedAppearanceSettings();
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const handleActivateLockToLatest = useActivateLockToLatestTimeMicro(
    card,
    geoTree
  );
  const readCard = useReadMicroCardState(props.cardId);
  const microMeasure = useMicroMeasure(selection, availableMeasures);
  const geotypeOptions: IDropdownOption[] | undefined = useMemo(() => {
    if (!defined(microMeasure)) {
      return;
    }
    const supportedOptions = geotypes
      .filter(
        ({ geotype }) =>
          defined(microMeasure) && microMeasure.geotypeSupported(geotype)
      )
      .map(({ geotype, label }) => ({
        key: geotype,
        text: label,
      }));
    return supportedOptions;
  }, [microMeasure]);

  const selectionId = selection?.id;

  const {
    areasInputProps,
    subareasInputProps,
    subjectsInputProps,
    measureInputProps,
    selectedMeasureKey,
  } = useSelectionInputState(
    changePending,
    selection,
    handleChangePath,
    handleChangeMeasure,
    availableMeasures,
    props.otherSelectedMeasures,
    categories
  );

  const selectedMeasure = selection?.measure;

  // We want the below to make effect re-run on every render, since we want outstanding promises to be cancelled
  // whenever the component is re-rendered.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const promiseController = new PromiseController();

  useEffect(() => {
    return () => {
      promiseController.stop();
    };
  }, [promiseController]);

  const handleChangeTime = useChangeTimeMicro(card, geoTree);
  const handleUnsetLockToLatestTime = useUnsetLockToLatestTimeMicro(
    card,
    geoTree
  );

  const handleClearBreakdowns = useCallback(
    async (dataColumn: string) => {
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      if (!defined(selection)) {
        throw new Error("Expected dataSelection");
      }
      if (!defined(geoTree)) {
        return;
      }
      const measureId = selection.measure?.id;
      if (!defined(measureId)) {
        return;
      }
      const selectedDimensionsUpdated = {
        ...selection.selectedDimensions,
        [dataColumn]: [],
      };

      if (shouldAbort()) {
        return;
      }

      const updatedCard2 = await setMicroCardAvailableDatesImmut(
        () => {
          const card = readCard();
          if (!defined(card.data.dataSelections)) {
            return card;
          }
          return {
            ...card,
            data: {
              ...card.data,
              dataSelections: replaceInArrayImmut(
                card.data.dataSelections,
                (s) => s.id === selection.id,
                () => ({
                  ...selection,
                  selectedDimensions: selectedDimensionsUpdated,
                })
              ),
            },
          };
        },
        selection,
        adminShowDraftData
      );

      setCard(updatedCard2);
    },
    [
      adminShowDraftData,
      geoTree,
      getCurrentCount,
      incrementUpdateCount,
      readCard,
      selection,
      setCard,
    ]
  );

  const handleSetSingleBreakdownValue = useCallback(
    async (dataColumn: string, key?: number) => {
      const id = selectedMeasure?.id;
      if (!defined(id)) {
        throw new Error("selectedMeasureId is not defined");
      }
      if (!defined(selection)) {
        return;
      }

      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const selectedDimensionsUpdated = {
        ...selection.selectedDimensions,
        [dataColumn]: defined(key) ? [key] : undefined,
      };

      const updatedCard: DocCardMicro = {
        ...card,
        data: {
          ...card.data,
          dataSelections: replaceInArrayImmut(
            card.data.dataSelections ?? [],
            (s) => s.id === selection.id,
            () => ({
              ...selection,
              selectedDimensions: selectedDimensionsUpdated,
            })
          ),
        },
      };
      setCard(updatedCard);

      const updatedCard2 = await setMicroCardAvailableDatesImmut(
        () => {
          return {
            ...card,
            data: {
              ...card.data,
              dataSelections: replaceInArrayImmut(
                card.data.dataSelections ?? [],
                (s) => s.id === selection.id,
                () => ({
                  ...selection,
                  selectedDimensions: selectedDimensionsUpdated,
                })
              ),
            },
          };
        },
        selection,
        adminShowDraftData
      );
      if (!defined(geoTree) || shouldAbort()) {
        return;
      }

      loadAndStoreDataMicro(
        updatedCard2,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedCard2),
        handleSaveCard
      );
    },
    [
      selectedMeasure?.id,
      selection,
      incrementUpdateCount,
      card,
      setCard,
      adminShowDraftData,
      geoTree,
      appearanceSettings,
      handleSaveCard,
      getCurrentCount,
    ]
  );

  const handleSetFilter = useCallback(
    (filter: MicroDataFilter) => {
      if (!defined(geoTree)) {
        logger.error("Geo tree not defined");
        return;
      }
      const prev = readCard();
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const filterSet: FilterSet = {
        type: "simple-and-filter",
        filters: [filter],
      };
      const dataSelection = selection;
      if (!defined(dataSelection)) {
        throw new Error("Expected dataSelection");
      }
      const updatedCard = {
        ...prev,
        data: {
          ...prev.data,
          dataSelections: !defined(prev.data.dataSelections)
            ? undefined
            : replaceInArrayImmut(
                prev.data.dataSelections,
                (s) => s.id === dataSelection.id,
                (prevSel) => {
                  return {
                    ...prevSel,
                    filterSet,
                  };
                }
              ),
        },
      };

      setCard(updatedCard);
      loadAndStoreDataMicro(
        updatedCard,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedCard),
        handleSaveCard
      );
    },
    [
      adminShowDraftData,
      appearanceSettings,
      geoTree,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      readCard,
      selection,
      setCard,
    ]
  );

  const [createNewFilter, toggleIsEditingFilter] = useToggle(false);
  const {
    isEditingVariables,
    toggleIsEditingVariables,
    handleSetVariablesConfig,
  } = useVariablesStatePrimarySelection(props.cardId, selection, () => {
    // Default to true only when a variable input is required but currently missing
    return (
      selection?.type === "primary" &&
      selection.measure?.computed?.type === "count_within_distance" &&
      !defined(selection.computedMeasureVariablesConfig)
    );
  });

  const handleUpdateBreakdowns = useCallback(
    async (labelId: string, keys: number[], selected: boolean) => {
      if (!defined(selectedMeasureKey) || !defined(selection)) {
        throw new Error("selectedMeasureId is not defined");
      }
      if (!defined(card.data.dataSelections)) {
        return;
      }
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const updatedSelections = selected
        ? replaceInArrayImmut(
            card.data.dataSelections,
            (s) => s.id === selection.id,
            (prevSel) => {
              return {
                ...prevSel,
                selectedDimensions: {
                  ...prevSel.selectedDimensions,
                  [labelId]: uniq([
                    ...(prevSel.selectedDimensions?.[labelId] ?? []),
                    ...keys,
                  ]),
                },
              };
            }
          )
        : replaceInArrayImmut(
            card.data.dataSelections,
            (s) => selection.id === s.id,
            (prevSel) => {
              return {
                ...prevSel,
                selectedDimensions: {
                  ...prevSel.selectedDimensions,
                  [labelId]: (
                    prevSel.selectedDimensions?.[labelId] ?? []
                  ).filter((k) => !keys.includes(k)),
                },
              };
            }
          );

      const updatedCard = {
        ...card,
        data: {
          ...card.data,
          dataSelections: updatedSelections,
        },
      };
      setCard(updatedCard);

      const updatedCard2 = await setMicroCardAvailableDatesImmut(
        () => {
          const card = readCard();
          return {
            ...card,
            data: {
              ...card.data,
              dataSelections: updatedSelections,
            },
          };
        },
        selection,
        adminShowDraftData
      );
      if (!defined(geoTree) || shouldAbort()) {
        return;
      }

      loadAndStoreDataMicro(
        updatedCard2,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedCard2),
        handleSaveCard
      );
    },
    [
      selectedMeasureKey,
      selection,
      card,
      incrementUpdateCount,
      setCard,
      adminShowDraftData,
      geoTree,
      appearanceSettings,
      handleSaveCard,
      getCurrentCount,
      readCard,
    ]
  );

  const handleRemoveFilter = useCallback(() => {
    if (!defined(selection)) {
      return;
    }
    if (!defined(geoTree)) {
      logger.error("Geo tree not defined");
      return;
    }

    const prev = readCard();
    const currentUpdate = incrementUpdateCount();
    const shouldAbort = () => getCurrentCount() > currentUpdate;

    const dataSelections = prev.data.dataSelections;
    if (!defined(dataSelections)) {
      return prev;
    }
    const updatedCard = {
      ...prev,
      data: {
        ...prev.data,
        dataSelections: replaceInArrayImmut(
          dataSelections,
          (s) => s.id === selection.id,
          (prevSel) => {
            return {
              ...prevSel,
              filterSet: undefined,
            };
          }
        ),
      },
    };
    setCard(updatedCard);
    loadAndStoreDataMicro(
      updatedCard,
      setCard,
      shouldAbort,
      geoTree,
      adminShowDraftData,
      appearanceSettings,
      cardColors(updatedCard),
      handleSaveCard
    );
  }, [
    adminShowDraftData,
    appearanceSettings,
    geoTree,
    getCurrentCount,
    handleSaveCard,
    incrementUpdateCount,
    readCard,
    selection,
    setCard,
  ]);

  const mainFilterSet =
    selection?.type === "primary" ? selection.filterSet : undefined;

  const thirdPartySettings = useMemo(
    () => card.data.thirdPartyMicroCardSettings,
    [card.data.thirdPartyMicroCardSettings]
  );

  const canUseFilters =
    selection?.type === "primary" &&
    (defined(selection.measure?.computed) || !selection.multiSelectEnabled) &&
    (isThirdPartyView ? thirdPartySettings?.allowEditFilterParams : true);

  const loadedData = card.data.loadedData;

  const thirdPartyDocNoControlsAboveToolbar = useMemo(() => {
    return (
      isThirdPartyView &&
      !thirdPartySettings?.showTimeline &&
      !thirdPartySettings?.showBreakdowns &&
      card.data.filterMeasures.length === 0
    );
  }, [
    card.data.filterMeasures.length,
    isThirdPartyView,
    thirdPartySettings?.showBreakdowns,
    thirdPartySettings?.showTimeline,
  ]);

  return (
    <div className="micro-measure-selection-container">
      <div
        className={classNames(
          "micro-measure-selection",
          thirdPartyDocNoControlsAboveToolbar
            ? "third-party-doc-no-controls-above-toolbar"
            : ""
        )}
      >
        {isThirdPartyView !== true && (
          <>
            <StyledDropdown
              className="item"
              dropdownWidth="auto"
              {...areasInputProps}
            />
            <StyledDropdown
              className="item"
              dropdownWidth="auto"
              {...subareasInputProps}
            />
            <StyledDropdown
              className="item"
              dropdownWidth="auto"
              {...subjectsInputProps}
            />
            <StyledDropdown
              className="measure-dropdown item"
              dropdownWidth="auto"
              {...measureInputProps}
            ></StyledDropdown>

            {defined(selectionId) &&
              defined(card.data.dataSelections) &&
              card.data.dataSelections.length > 1 && (
                <div className="remove-button-container">
                  <StyledDropdown
                    className="measure-dropdown mock-container"
                    onChange={(e, option) => {}}
                    selectedKey={undefined}
                    options={[]}
                  ></StyledDropdown>
                  <IconButton
                    title="Ta bort mått"
                    iconProps={{ iconName: "cross" }}
                    onClick={() => props.handleRemoveMeasure(selectionId)}
                  ></IconButton>
                </div>
              )}
          </>
        )}

        {defined(props.handleCancelCreateNewSelection) && (
          <div className="remove-button-container">
            <StyledDropdown
              className="measure-dropdown mock-container"
              onChange={(e, option) => {}}
              selectedKey={undefined}
              options={[]}
            ></StyledDropdown>
            <IconButton
              title="Ta bort"
              iconProps={{ iconName: "cross" }}
              onClick={() => props.handleCancelCreateNewSelection?.()}
            ></IconButton>
          </div>
        )}

        <LineClear></LineClear>

        {((isThirdPartyView && thirdPartySettings?.showBreakdowns) ||
          !isThirdPartyView) &&
          defined(selection) &&
          defined(selection.measure) && (
            <div className="dimension-selection-and-filter">
              <DimensionSelectionOuter
                changePending={changePending}
                multiSelect={selection.type === "primary" ? multiSelect : true} // Always enable multi-select for secondary (point measures etc) selections
                measureId={selection.measure.id}
                handleClearBreakdowns={handleClearBreakdowns}
                handleSetSingleBreakdownKey={handleSetSingleBreakdownValue}
                selectedBreakdowns={selection?.selectedDimensions ?? {}}
                handleSetBreakdowns={handleUpdateBreakdowns}
              ></DimensionSelectionOuter>
              {selection.type === "primary" &&
                defined(selection.measure.computed) &&
                defined(selection.measure.computed.variables) && (
                  <VariablesConfig
                    isEditingVariables={isEditingVariables}
                    toggleIsEditingVariables={toggleIsEditingVariables}
                    handleSetConfig={handleSetVariablesConfig}
                    config={selection.computedMeasureVariablesConfig}
                    variablesSpec={selection.measure.computed.variables}
                  ></VariablesConfig>
                )}
              {canUseFilters && (
                <>
                  {defined(mainFilterSet) || createNewFilter ? (
                    <div className="flex-row flex-align-items-center">
                      <FilterSelector
                        handleRemoveFilter={handleRemoveFilter}
                        filterSet={mainFilterSet}
                        handleSetFilter={handleSetFilter}
                        toggleIsEditingFilter={toggleIsEditingFilter}
                        isEditingFilter={createNewFilter}
                      ></FilterSelector>
                      <div>
                        {createNewFilter && (
                          <div style={{ paddingTop: 24 }}>
                            <IconButton
                              title="Ta bort"
                              iconProps={{ iconName: "cross" }}
                              onClick={toggleIsEditingFilter}
                            ></IconButton>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="add-filter-button">
                      <StyledDropdownCondensed
                        options={[]}
                        className="invisible"
                      ></StyledDropdownCondensed>
                      <FluentIcon
                        name="filter"
                        size="md"
                        onClick={toggleIsEditingFilter}
                      ></FluentIcon>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
      </div>

      {microMeasure?.isPrimary() &&
        card.data.filterMeasures.length > 0 &&
        (isThirdPartyView ? (
          <FilterMeasuresThirdPartyDoc
            cardId={props.cardId}
            thirdPartySettings={thirdPartySettings}
            mainMeasureGeoType={card.data.geoSelections?.type}
            changePending={changePending}
            filterMeasures={card.data.filterMeasures}
          />
        ) : (
          <FilterMeasures
            cardId={card.id}
            mainMeasureGeoType={card.data.geoSelections?.type}
            changePending={changePending}
            filterMeasures={card.data.filterMeasures}
          ></FilterMeasures>
        ))}
      {microMeasure?.isPrimary() &&
        defined(loadedData) &&
        loadedData.primaryProgress?.type === Progress.Error && (
          <div className="margin-y-sm content-padding">
            <DataLoadErrorMessage
              error={loadedData.primaryProgress.error}
            ></DataLoadErrorMessage>
          </div>
        )}
      {microMeasure?.isPrimary() &&
        defined(loadedData) &&
        loadedData.primaryProgress?.type === Progress.NotStarted && (
          <div className="margin-y-sm content-padding">
            <DataloadNotStartedMessage info={loadedData.primaryProgress.info} />
          </div>
        )}
      {((isThirdPartyView && thirdPartySettings?.showTimeline) ||
        !isThirdPartyView) &&
        defined(selectedMeasureKey) &&
        microMeasure?.isPrimary() &&
        !microMeasure?.isComputed() && (
          <TimeSelect
            setLockToLatestTime={handleActivateLockToLatest}
            updateCardStateWithTimeRange={handleChangeTime}
            unsetLockToLatestTime={handleUnsetLockToLatestTime}
            appMode={isThirdPartyView ? "embedded" : config.appMode}
            disabled={changePending}
            cardStateId={card.id}
          ></TimeSelect>
        )}
      {!isThirdPartyView &&
        defined(microMeasure) &&
        microMeasure.isPrimary() && (
          <DataOptions
            isComputedMeasure={defined(selection?.measure?.computed)}
            selectedGeotype={card.data.geoSelections?.type}
            geotypeOptions={geotypeOptions}
            handleChangeGeotype={handleChangeGeotype}
            changePending={changePending}
            multiSelect={multiSelect}
            setMultiSelect={setMultiSelect}
          ></DataOptions>
        )}
      {!isThirdPartyView &&
        defined(microMeasure) &&
        microMeasure.isPrimary() &&
        !microMeasure.isComputed() && (
          <DimensionalityWarningMessages
            timeSelection={selection?.timeSelection}
            selectedDimensions={selection?.selectedDimensions}
            microOutputTab={card.data.selectedTab}
          />
        )}
    </div>
  );
}

function DataOptions(props: {
  changePending: boolean;
  isComputedMeasure: boolean;
  multiSelect: boolean;
  setMultiSelect: (multiSelect: boolean) => void;
  handleChangeGeotype: (geotype: GeoTypeMicro) => void;
  geotypeOptions: IDropdownOption[] | undefined;
  selectedGeotype: string | undefined;
}) {
  const {
    changePending,
    handleChangeGeotype,
    selectedGeotype,
    geotypeOptions,
  } = props;
  const iconWithTooltip = (
    <HelpIcon tooltipText="I kartläge kan endast ett värde per filter användas, men i diagram och tabeller kan flera användas."></HelpIcon>
  );

  return (
    <div className="data-options">
      {defined(geotypeOptions) && (
        <StyledDropdown
          disabled={changePending || geotypeOptions.length < 2}
          className="item"
          selectedKey={selectedGeotype}
          dropdownWidth="auto"
          options={geotypeOptions}
          onChange={(_, item) => {
            if (!defined(item) || item.key === selectedGeotype) {
              return;
            }
            const geotype = item.key as GeoTypeMicro;
            handleChangeGeotype(geotype);
          }}
        />
      )}
      {!props.isComputedMeasure && (
        <div className="item multi-choice-toggle flex-row flex-align-items-center">
          <Checkbox
            disabled={props.changePending}
            label="Flerval"
            checked={props.multiSelect}
            onChange={(e, checked) => {
              if (!defined(checked)) {
                return;
              }
              props.setMultiSelect(checked);
            }}
          ></Checkbox>
          {iconWithTooltip}
        </div>
      )}
    </div>
  );
}

/**
 * Warnings applicable to non-computed primary measures only.
 */
function DimensionalityWarningMessages(props: {
  selectedDimensions?: SelectedDimensionsV2;
  timeSelection?: DateRangeRaw;
  microOutputTab: MicroOutputTab;
}) {
  const { selectedDimensions, microOutputTab } = props;
  const warnings: string[] = [];

  if (defined(selectedDimensions) && microOutputTab === "map-view") {
    const mapSelectedDimensions =
      getMicroMapSelectedDimensions(selectedDimensions);

    if (
      !isEqual(selectedDimensions, mapSelectedDimensions) ||
      (defined(props.timeSelection) &&
        !dateRangeRawIsSingular(props.timeSelection))
    ) {
      warnings.push(
        "Kartvyn kan bara visa en kombination av filter och ett datum."
      );
    }
  }

  return warnings.length > 0 ? (
    <section className="content-padding">
      <AlertBox intent="warning" className="warnings">
        {warnings.map((warning) => (
          <span key={warning}>{warning}</span>
        ))}
      </AlertBox>
    </section>
  ) : null;
}
