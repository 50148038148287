import { useState, useContext, useMemo, useCallback } from "react";

import {
  AppMessagesContext,
  UserInfoContext,
  UserInfoUpdateContext,
} from "../../../../lib/application/contexts";
import { Card } from "../../../../components/Card";
import { defined } from "../../../../lib/core/defined";

import { CustomThemeSpec } from "../../../../lib/application/stats/shared/core/colors/colorSchemes";
import { CustomThemesSection, ManageThemes } from "../_shared/ManageThemes";
import { voidFunc } from "../../../../lib/core/voidFunc";
import { logger } from "../../../../lib/infra/logging";

export function AppearanceSettings() {
  const userInfo = useContext(UserInfoContext);
  const [isUpdating, setIsUpdating] = useState(false);

  const appMessages = useContext(AppMessagesContext);
  const handleUpdateUser = useContext(UserInfoUpdateContext);

  const colorPalettesSpecial = useMemo(() => {
    return userInfo?.extendedAppearanceSettings().colorPalettesSpecial;
  }, [userInfo]);

  const appearanceSettings = userInfo?.extendedAppearanceSettings();

  const handleUpdateUserPalettes = useCallback(
    (themes: CustomThemeSpec[]) => {
      if (!defined(handleUpdateUser)) {
        return;
      }

      setIsUpdating(true);
      return handleUpdateUser({ userThemes: themes })
        .then((res) => {
          res.match({
            ok: voidFunc,
            err: (err) => {
              logger.error(err);
              appMessages?.add("error", "Kunde inte spara ändringarna");
            },
          });
          return res;
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [appMessages, handleUpdateUser]
  );

  const handleSetDefault = useCallback(
    (id?: string) => {
      if (!defined(handleUpdateUser)) {
        return;
      }
      setIsUpdating(true);
      return handleUpdateUser({ defaultThemeId: id })
        .then((res) => {
          res.match({
            ok: voidFunc,
            err: (err) => {
              logger.error(err);
              appMessages?.add("error", "Kunde inte spara ändringarna");
            },
          });
          return res;
        })
        .finally(() => {
          setIsUpdating(false);
        });
    },
    [appMessages, handleUpdateUser]
  );

  const customThemesSection: CustomThemesSection | undefined = useMemo(() => {
    const userThemes = colorPalettesSpecial?.userThemes;
    if (!defined(userThemes)) {
      return undefined;
    }
    return {
      name: "Dina teman",
      themes: userThemes,
    };
  }, [colorPalettesSpecial?.userThemes]);

  const customOrgThemesSection: CustomThemesSection | undefined =
    useMemo(() => {
      const orgThemes = colorPalettesSpecial?.orgThemes;
      if (!defined(orgThemes)) {
        return undefined;
      }
      return {
        name: "Organisationens teman",
        themes: orgThemes,
      };
    }, [colorPalettesSpecial?.orgThemes]);

  return (
    <Card id="appearance-settings">
      <h2>Teman</h2>
      <ManageThemes
        isUpdating={isUpdating}
        themeNameModifier={(theme) => {
          if (theme.id === colorPalettesSpecial?.orgDefault?.id) {
            return theme.name + " (förvalt av din organisation)";
          }
          return theme.name;
        }}
        handleUpdateCustomThemes={handleUpdateUserPalettes}
        handleSetDefaultTheme={handleSetDefault}
        customThemesSectionLocked={customOrgThemesSection}
        customThemesSectionEditable={customThemesSection}
        defaultThemeId={appearanceSettings?.defaultThemeId}
      />
    </Card>
  );
}
