import { IconProps } from "@blueprintjs/core";
import { FluentIcon, IconSize } from "./Icons";

import "./Headings.scss";

export function H3Icon(props: {
  icon: IconProps["icon"];
  iconSize?: IconSize;
  text: string;
}) {
  return (
    <div className="heading-with-icon">
      <FluentIcon name={props.icon} size={props.iconSize ?? "sm"} />
      <h3>{props.text}</h3>
    </div>
  );
}
