import { useCallback } from "react";

import { editStatsDimensionV2 } from "../../../../../lib/application/requests/admin/measure_management/edit";
import { getMeasureDimensionsV2 } from "../../../../../lib/application/requests/datasets/dimensions";
import { DimensionV2Dto } from "../../../../../lib/domain/measure/definitions";
import { MeasureConfirmation } from "../_shared/MeasureConfirmation";
import { DraftDataTodo } from "../../../../../lib/application_admin/todos/draft_data_todos";
import {
  commitPendingChangesMemberOrgsMeasure,
  revertPendingChangesMemberOrgsMeasure,
} from "../../../../../lib/application/requests/admin/measure_management/member_orgs_measures/import";
import {
  DraftConflictDto,
  MemberOrgMeasureCommitDto,
  MemberOrgMeasureRevertDto,
} from "../../../../../lib/application/requests/admin/measure_management/shared";
import { Table } from "../../../../../components/Table";
import { HttpResult } from "../../../../../lib/infra/HttpResult";

interface Props {
  id: number;
  todos?: DraftDataTodo[];
  reloadTodos: () => void;
}

export function MemberOrgsMeasureConfirmation(props: Props) {
  const getDims = useCallback(async (id: number) => {
    const dims = await getMeasureDimensionsV2(id, true, false);
    return dims;
  }, []);
  const editDimension = useCallback(
    (dimensionId: number, dimension: DimensionV2Dto) => {
      if (dimension.type !== "stats_breakdown") {
        throw new Error("Invalid dimension type");
      }
      return editStatsDimensionV2(dimensionId, dimension);
    },
    []
  );

  const renderCommitDto = useCallback((dto: MemberOrgMeasureCommitDto) => {
    return (
      <>
        <h3>Info om gjorda ändringar</h3>
        <Table
          columns={[
            { name: "ID", type: "text", key: "id" },
            { name: "label", type: "text", key: "label" },
            { name: "Rader borttagna", type: "text", key: "rows_deleted" },
            { name: "Rader publicerade", type: "text", key: "rows_published" },
            {
              name: "Dimensionsvärden publicerade",
              type: "text",
              key: "dimension_values_published",
            },
            {
              name: "Ej publicerade dimensionsvärden borttagna",
              type: "text",
              key: "unused_dimension_values_deleted",
            },
          ]}
          data={dto.map((c) => ({
            id: c.data_id,
            rowClassName: "",
            cells: [
              c.data_id.toString(),
              c.data_label,
              c.rows_deleted.toString(),
              c.rows_published.toString(),
              c.dimension_values_published.toString(),
              c.unused_dimension_values_deleted.toString(),
            ],
          }))}
        ></Table>
      </>
    );
  }, []);

  const renderRevertDto = useCallback((dto: MemberOrgMeasureRevertDto) => {
    return (
      <>
        <h3>Info om slängda ändringar</h3>
        <Table
          columns={[
            { name: "ID", type: "text", key: "id" },
            { name: "label", type: "text", key: "label" },
            {
              name: "Nya, ej publicerade rader som slängts",
              type: "text",
              key: "rows_deleted",
            },
            {
              name: "Rader ej längre markerade för borttagning",
              type: "text",
              key: "rows_reverted",
            },
            {
              name: "Ej använda dimensionsvärden borttagna",
              type: "text",
              key: "unused_dimension_values_deleted",
            },
          ]}
          data={dto.map((c) => ({
            id: c.data_id,
            rowClassName: "",
            cells: [
              c.data_id.toString(),
              c.data_label,
              c.rows_deleted.toString(),
              c.rows_reverted.toString(),
              c.unused_dimension_values_deleted.toString(),
            ],
          }))}
        ></Table>
      </>
    );
  }, []);

  // We never check for conflicts with data links here, because data links should not
  // point to member org measures.
  const getConflicts = useCallback(async (id: number) => {
    return Promise.resolve(HttpResult.fromOk([] as DraftConflictDto[]));
  }, []);

  return (
    <MeasureConfirmation
      {...props}
      type="memberOrgsMeasure"
      getConflicts={getConflicts}
      getDimensions={getDims}
      editDimension={editDimension}
      revertPendingChanges={revertPendingChangesMemberOrgsMeasure}
      commitPendingChanges={commitPendingChangesMemberOrgsMeasure}
      renderCommitDto={renderCommitDto}
      renderRevertDto={renderRevertDto}
    ></MeasureConfirmation>
  );
}
