import { useCallback, useContext } from "react";
import { useRecoilState } from "recoil";

import { defined } from "../../../../core/defined";
import { MeasureThin } from "../../../../domain/measure/definitions";
import { DataSelection } from "../../../../domain/selections/definitions";
import { replaceAtIndexImmut } from "../../generic";
import { singleDataCardQuery } from "../../stats/document-core/queries/dataCard";
import { cardWithUpdatedDataState } from "../../stats/cardToDataStateStats";
import {
  CardUpdateCountContext,
  GeographiesContext,
  ShowDraftDataContext,
} from "../../../contexts";
import { useSaveCard } from "../useSaveDocument";
import { useExtendedAppearanceSettings } from "../useExtendedAppearanceSettings";

export function useDeactivateGroupingMode(cardId: string) {
  const [card, setCard] = useRecoilState(
    singleDataCardQuery({ cardStateId: cardId })
  );
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const geo = useContext(GeographiesContext);
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const handleSave = useSaveCard();
  const appearanceSettings = useExtendedAppearanceSettings();

  return useCallback(
    async (primarySelection: DataSelection, measures: MeasureThin[]) => {
      const measureSelection = primarySelection.measureSelection;
      if (!defined(primarySelection) || !defined(measureSelection)) {
        return;
      }
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const prevCard = card;
      const updatedCard = {
        ...prevCard,
        data: {
          ...prevCard.data,
          groupingSelection: undefined,
          dataSelections: replaceAtIndexImmut(prevCard.data.dataSelections, 0, {
            ...primarySelection,
            measureSelection: {
              ...measureSelection,
              available: measures,
            },
          }),
        },
      };
      setCard(updatedCard);
      handleSave?.(updatedCard);

      if (!defined(geo)) {
        return;
      }
      const cardWithData = await cardWithUpdatedDataState(
        shouldAbort,
        updatedCard,
        geo,
        adminShowDraftData,
        appearanceSettings
      );
      if (!defined(cardWithData) || shouldAbort()) {
        return;
      }
      setCard(cardWithData);
      handleSave?.(updatedCard);
    },
    [
      adminShowDraftData,
      card,
      appearanceSettings,
      geo,
      getCurrentCount,
      handleSave,
      incrementUpdateCount,
      setCard,
    ]
  );
}
