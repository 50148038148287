import { chain, last } from "lodash";

import { defined } from "../../../../../core/defined";
import { Dimension } from "../../../shared/core/definitions";
import { RowBaseInterface } from "../../../shared/row";
import { DataCellValue } from "../../DataCellValue";
import { joinDimensions } from "./table_base";
import { dateStringComparatorDesc } from "../../../../../core/time";
import { applyDimensionSortOrderGeneric } from "../../../../../domain/measure";
import { DimensionV2Dto } from "../../../../../domain/measure/definitions";
import { groupingSortSpec } from "../../../shared/dimensions";
import { classNames } from "../../../../../core/classNames";
import {
  ComputedValueOutputSettings,
  TableRow,
  CellValueWithOutputSettings,
} from "./table_base_definitions";

export function calcRows<T>(
  rowKeys: string[],
  rowDimension: string | undefined,
  isSingleValue: boolean,
  rows: RowBaseInterface<T>[],
  groupedRows: _.Dictionary<RowBaseInterface<T>[]>,
  liftedDimensions: { [key: string]: string } | undefined,
  variantCombinations: { dimension: string; label: string }[][],
  dimensionFormatter: (dimension: string, label: string) => string | undefined,
  formatDate: (date: string) => string,
  isForecastLabel: (label: string) => boolean,
  computedValueOutputSettings: ComputedValueOutputSettings
): TableRow[] {
  const matchRow = (dim: string, label: string) => {
    return (r: RowBaseInterface<T>) => r.dimension(dim) === label;
  };

  return !defined(rowDimension)
    ? isSingleValue || rowKeys.length === 0
      ? [
          getSingleTableRow(
            liftedDimensions,
            rows,
            formatDate,
            computedValueOutputSettings
          ),
        ]
      : []
    : rowKeys.map((point) => {
        const rowMatcher = matchRow(rowDimension, point);
        const label = dimensionFormatter(rowDimension, point) ?? "--";
        return {
          label,
          labelClassName: classNames(
            rowDimension === Dimension.date ? "date" : undefined
          ),
          // Mark row as forecast so we can style these rows. Depending on config forecast values may be in
          // columns, in which case we don't want to mark the row as forecast.
          rowClassName: isForecastLabel(label) ? "forecast" : undefined,
          values:
            variantCombinations.length === 0
              ? [
                  renderRowValue(
                    rows.find(rowMatcher),
                    computedValueOutputSettings
                  ),
                ]
              : variantCombinations.map((combo) => {
                  const key = joinDimensions(combo.map((c) => c.label));
                  const found = groupedRows[key]?.find(rowMatcher);
                  if (defined(found)) {
                    return renderRowValue(found, computedValueOutputSettings);
                  }
                  return DataCellValue.nan("no_value");
                }),
        };
      });
}

export function getRowLabels<T>(
  rowDimension: string | undefined,
  dimensions: DimensionV2Dto[],
  dimensionFormatter: (d: string, label: string) => string | undefined,
  rows: RowBaseInterface<T>[],
  variantCombinations: { dimension: string; label: string }[][]
): string[] {
  if (!defined(rowDimension)) {
    return [];
  }

  switch (rowDimension) {
    case Dimension.date:
      return chain(rows.slice())
        .map((r) => (r.dimension(Dimension.date) ?? "") as string)
        .uniq()
        .sort(dateStringComparatorDesc)
        .value();
    case Dimension.grouping:
      return chain(rows.slice())
        .map((r) => (r.dimension(Dimension.grouping) ?? "") as string)
        .uniq()
        .sortBy((v) => groupingSortSpec.orderTop.indexOf(v))
        .value();
  }

  const getPrimary = (row: RowBaseInterface<T>): string => {
    const value = row.dimension(rowDimension);
    if (typeof value === "string") {
      return value;
    }
    return value?.toString() ?? "";
  };

  const primaryDimRaw = rowDimension;
  const dimSpec = dimensions.find((d) => d.data_column === primaryDimRaw);

  return chain(
    applyDimensionSortOrderGeneric(
      rows,
      dimSpec,
      (v) => {
        const label = getPrimary(v);
        // FIXME: somehow replace way of finding id of value
        return dimSpec?.values?.find((v) => v.label === label)?.id;
      },
      (v) => {
        // Which value do we compare by? In a complex table with multiple dimensions, we select
        // the last column as given by variant combinations.
        // The reason for selecting the last column is that it appears visually as the right-most column
        // in the table.

        if (variantCombinations.length === 0) {
          return v.range();
        }
        const combo = last(variantCombinations);
        if (!defined(combo)) {
          throw new Error("Expected combo to be defined");
        }

        const value = getPrimary(v);
        const found = rows.find(
          (r) =>
            getPrimary(r) === value &&
            combo.every(
              (labelAndDim) =>
                r.dimension(labelAndDim.dimension) === labelAndDim.label
            )
        );
        return found?.range();
      }
    ).merged()
  )
    .map(getPrimary)
    .uniqBy((v) => dimensionFormatter(rowDimension, v))
    .value();
}

function renderRowValue<T>(
  found: RowBaseInterface<T> | undefined,
  computedValueOutputSettings: ComputedValueOutputSettings
): DataCellValue<CellValueWithOutputSettings> {
  if (!defined(found)) {
    return DataCellValue.nan("no_value");
  }
  const statusType = found.type();
  if (statusType === "low_base") {
    return DataCellValue.nan("low_base");
  } else if (statusType === "invalid_choice") {
    return DataCellValue.nan("no_value");
  }

  const value = found.range();
  if (found.isUserDefined) {
    return DataCellValue.ok({
      value: (value ?? "").toString(),
      ...computedValueOutputSettings,
    });
  }

  return DataCellValue.ok({ value: (value ?? "").toString() });
}

function getSingleTableRow<V>(
  liftedDimensions: { [key: string]: string } | undefined,
  rows: RowBaseInterface<V>[],
  formatDate: (label: string) => string,
  computedValueOutputSettings: ComputedValueOutputSettings
): TableRow {
  const liftedDate = liftedDimensions?.[Dimension.date];
  return {
    label: defined(liftedDate) ? formatDate(liftedDate) : "",
    labelClassName: "date",
    values: rows.map((r) => renderRowValue(r, computedValueOutputSettings)),
  };
}
