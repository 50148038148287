import {
  Array as ArrayRT,
  Number as NumberRT,
  Dictionary,
  Optional,
  Record,
  Static,
  String as StringRT,
  Unknown,
  Union,
  Literal,
} from "runtypes";
import { RuntypeBase } from "runtypes/lib/runtype";

import { GeoTypeMicroRT } from "../../domain/geography";
import {
  AggregationMethodRT,
  DataValueTypeMicroLineRT,
  DataValueTypeMicroPointRT,
  DataValueTypeMicroPolygonRT,
  DataValueTypeMicroRegularRT,
} from "./dataset";

export const MicroDatasetDtoRT = Record({
  header: Record({
    descr_long: StringRT,
    dimensions: ArrayRT(StringRT),
    lifted: Dictionary(StringRT, StringRT),
    measure: StringRT,
    source: StringRT,
    unit_label: StringRT,
    public_comment: Optional(StringRT),
    value_type: DataValueTypeMicroRegularRT,
    externalSource: Optional(StringRT), // FIXME
  }),
  reference_rows: Optional(ArrayRT(Dictionary(Unknown, StringRT))),
  rows: ArrayRT(Dictionary(Unknown, StringRT)),
});

export type MicroDatasetDto = Static<typeof MicroDatasetDtoRT>;

function microDtoRT<T extends RuntypeBase>(valueType: T) {
  return Record({
    header: Record({
      descr_long: StringRT,
      dimensions: ArrayRT(StringRT),
      lifted: Dictionary(StringRT, StringRT),
      measure: StringRT,
      source: StringRT,
      unit_label: StringRT,
      value_type: valueType,
    }),
    rows: ArrayRT(Dictionary(Unknown, StringRT)),
  });
}

export const MicroPointDatasetDtoRT = microDtoRT(DataValueTypeMicroPointRT);
export const MicroPolygonDatasetDtoRT = microDtoRT(DataValueTypeMicroPolygonRT);
export const MicroLineDatasetDtoRT = microDtoRT(DataValueTypeMicroLineRT);
export type MicroPointDatasetDto = Static<typeof MicroPointDatasetDtoRT>;
export type MicroPolygonDatasetDto = Static<typeof MicroPolygonDatasetDtoRT>;
export type MicroLineDatasetDto = Static<typeof MicroLineDatasetDtoRT>;

const CommonFieldsRT = Record({
  mikro_id: NumberRT,
  resolution: StringRT,
  label: StringRT,
  area: StringRT,
  subarea: StringRT,
  subject: StringRT,
  measure: StringRT,
  public_comment: Optional(StringRT),
  descr_long: StringRT,
  source: StringRT,
  unit_label: StringRT,

  ext_descr: Optional(StringRT),
  ext_descr_long: Optional(StringRT),
  ext_source: Optional(StringRT),
  source_url: Optional(StringRT),
  last_update: Optional(StringRT),
});

export const ComputedMeasurementTypeRT = Union(
  Literal("count_within_distance"),
  Literal("count_in_area"),
  Literal("distance_to_nearest")
);
export const MICRO_COUNT_WITHIN_DISTANCE_KEY = "distance";
const ComputedVariableTypeRT = Union(Literal("float"));
const ComputedVariableRT = Record({
  type: ComputedVariableTypeRT,
  unit: StringRT,
});

export type ComputedVariableType = Static<typeof ComputedVariableTypeRT>;
const ComputedMeasurementVariablesRT = Dictionary(ComputedVariableRT, StringRT);
export type ComputedMeasurementVariables = Static<
  typeof ComputedMeasurementVariablesRT
>;

export type ComputedMeasurementType = Static<typeof ComputedMeasurementTypeRT>;
export const COMPUTED_TYPE_LABELS: {
  [key in ComputedMeasurementType]: string;
} = {
  count_in_area: "Antal i område",
  count_within_distance: "Antal inom valt avstånd",
  distance_to_nearest: "Avstånd till närmaste",
};

export const ALL_COMPUTED_MEASURE_TYPES: ComputedMeasurementType[] = [
  "count_in_area",
  "count_within_distance",
  "distance_to_nearest",
];

export const MicroVariableTypeRT = Union(Literal("float"), Literal("int"));
const MicroSingleMeasureRegularRT = CommonFieldsRT.And(
  Record({
    agg_method_geo: AggregationMethodRT,
    geo_types: ArrayRT(GeoTypeMicroRT),
    value_type: DataValueTypeMicroRegularRT,
    computed_measurement_type: Optional(ComputedMeasurementTypeRT),
    computed_measurement_variables: Optional(ComputedMeasurementVariablesRT),
  })
);

const MicroSingleMeasureGeometricRT = CommonFieldsRT.And(
  Record({
    value_type: Union(
      DataValueTypeMicroLineRT,
      DataValueTypeMicroPointRT,
      DataValueTypeMicroPolygonRT
    ),
  })
);

export const MicroMeasureDtoRT = MicroSingleMeasureRegularRT.Or(
  MicroSingleMeasureGeometricRT
);
export type MicroMeasureRegularDto = Static<typeof MicroSingleMeasureRegularRT>;
export type MicroMeasureDto = Static<typeof MicroMeasureDtoRT>;

export const MicroMeasureListRT = ArrayRT(MicroMeasureDtoRT);
