import { partition } from "lodash";
import React, { useEffect, useRef } from "react";

import { ColorScheme } from "../../../lib/application/state/stats/document-core/core";
import { DataPoint } from "../../../lib/application/stats/datasets/DataPoint";
import { LEGEND_COLOR_INDICATOR_CHAR } from "../../../lib/application/stats/shared/core/definitions";

export function ResultsTable<T>(props: {
  headerLines: string[];
  chartWidth: number;
  domainPositionXOffset: number;
  marginLeft: number;
  marginTop: number;
  sortedResults: T[];
  infoboxOffset: number;
  /**
   * If a value has low priority, it will only be displayed if there are no other items
   * to fill the number of spots in the list.
   */
  isLowPriorityValue: (value: T) => boolean;
  renderDataPoint: (row: T) => JSX.Element;
}) {
  const {
    headerLines,
    marginLeft,
    chartWidth,
    domainPositionXOffset,
    sortedResults,
    infoboxOffset,
  } = props;
  const [overlayWidth, setOverlayWidth] = React.useState<number>();

  const overlayRef: React.LegacyRef<HTMLDivElement> = useRef(null);

  useEffect(() => {
    const width = overlayRef.current?.getBoundingClientRect().width;
    const adjustedWidth = Math.min(width ?? 0, chartWidth);
    if (adjustedWidth !== overlayWidth) {
      setOverlayWidth(adjustedWidth);
    }
  }, [chartWidth, overlayWidth]);

  const swapSide = domainPositionXOffset > chartWidth / 2;
  const top5 = sortedResults.slice(0, 5);
  const remainingItems = sortedResults.slice(5);
  const [remainingLow, remainingHigh] = partition(
    remainingItems,
    props.isLowPriorityValue
  );
  const bottom5 = remainingHigh
    .slice(-5)
    .concat(remainingLow.slice(0, Math.max(0, 5 - remainingHigh.length)));

  return (
    <div
      ref={overlayRef}
      id="overlay-container"
      style={{
        position: "absolute",
        left: !swapSide
          ? domainPositionXOffset + marginLeft + infoboxOffset
          : domainPositionXOffset +
            marginLeft -
            (overlayWidth ?? 0) -
            infoboxOffset,
        top: props.marginTop + 10,
      }}
    >
      <div className="header">
        {headerLines.map((header) => {
          return (
            <p key={header} className="hover-header">
              {header}
            </p>
          );
        })}
      </div>
      <table>
        <thead></thead>
        <tbody>
          {top5.map(props.renderDataPoint)}
          {bottom5.length > 0 && (
            <>
              {sortedResults.length > bottom5.length + top5.length && (
                <tr>
                  <td></td>
                  <td>⋮</td>
                  <td></td>
                </tr>
              )}
              {bottom5.map(props.renderDataPoint)}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}

export function HoverTableRow(props: {
  hasColorDimension: boolean;
  dataPoint: DataPoint;
  colorScheme: ColorScheme;
  labelMaxWidth: number;
}) {
  const { dataPoint, hasColorDimension } = props;
  const label = dataPoint.label();
  const colorKey = dataPoint.colorKey?.();
  return (
    <tr>
      {hasColorDimension && (
        <>
          <td
            className="color-indicator"
            style={{ color: props.colorScheme[colorKey ?? label] }}
          >
            {LEGEND_COLOR_INDICATOR_CHAR}
          </td>

          <td className="label" style={{ maxWidth: props.labelMaxWidth }}>
            {label}
          </td>
        </>
      )}
      <td className="value tabular-data">{dataPoint.display()}</td>
    </tr>
  );
}
