export interface Position2D {
  readonly x: number;
  readonly y: number;
}

export const Position2DOrigin: Position2D = {
  x: 0,
  y: 0,
};

export function subtractPosition2D(
  left: Position2D,
  right: Position2D
): Position2D {
  return { x: left.x - right.x, y: left.y - right.y };
}
