import {
  IconBuildingCommunity,
  IconChartBar,
  IconCode,
  IconExclamationCircle,
  IconTable,
  IconTypography,
} from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { useRecoilValue } from "recoil";

import { DefaultLoading } from "../../../components/Loading";
import { FluentModal, FluentModalBody } from "../../../components/Modal";
import { useMoveCardCallback } from "../../../lib/application/state/actions/cardCallbacks";
import { docCardsListQuery } from "../../../lib/application/state/stats/document-core/docCardsListState";
import { cardLabelQuery } from "../../../lib/application/state/stats/document-core/queries/card";
import {
  allThumbnailsQuery,
  SingleCardThumbnail,
} from "../../../lib/application/state/stats/document-thumbnails/definitions";
import { defined } from "../../../lib/core/defined";

import "./RearrangeDialog.scss";
import { StrictModeDroppable } from "../../../components/compat/Droppable";

interface Props {
  onClose: () => void;
}
export function RearrangeDialog(props: Props) {
  const cardsList = useRecoilValue(docCardsListQuery);
  const cardIds = useMemo(() => {
    return cardsList.map((c) => c.id);
  }, [cardsList]);
  const allThumbnails = useRecoilValue(allThumbnailsQuery(cardIds));

  return (
    <FluentModal
      containerClassName="rearrange-dialog"
      title="Ordna kort"
      isOpen={true}
      onClose={props.onClose}
    >
      <FluentModalBody>
        {allThumbnails.length === 0 ? (
          <DefaultLoading></DefaultLoading>
        ) : (
          <RearrangeDialogInner
            initialThumbnails={allThumbnails}
          ></RearrangeDialogInner>
        )}
      </FluentModalBody>
    </FluentModal>
  );
}

function RearrangeDialogInner(props: {
  initialThumbnails: SingleCardThumbnail[];
}) {
  const [localThumbnailsState, setLocalThumbnailsState] = useState(
    props.initialThumbnails
  );

  const moveCardCallback = useMoveCardCallback();
  const handleMoveCard = (cardId: string, insertIndex: number) => {
    const copy = localThumbnailsState.slice() ?? [];
    const currentIndex = copy.findIndex((c) => c.cardId === cardId);
    const elements = copy.splice(currentIndex, 1);
    copy.splice(insertIndex, 0, elements[0]);
    setLocalThumbnailsState(copy);
    moveCardCallback(cardId, insertIndex);
  };

  return (
    <>
      <p>Klicka och dra för att ändra ordning på korten.</p>
      <DragDropContext
        onDragEnd={(res) => {
          const destination = res.destination;
          if (!defined(destination)) {
            return;
          }
          handleMoveCard(res.draggableId, destination.index);
        }}
      >
        {localThumbnailsState.length === 0 ? (
          <DefaultLoading delayMs={0}></DefaultLoading>
        ) : (
          <StrictModeDroppable droppableId="droppable">
            {(droppableProvided) => (
              <div
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {localThumbnailsState.map((thumbnail, index) => {
                  return (
                    <Draggable
                      key={thumbnail.cardId}
                      draggableId={thumbnail.cardId}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          <ThumbnailContainer
                            key={thumbnail.cardId}
                            thumbnail={thumbnail}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {droppableProvided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        )}
      </DragDropContext>
    </>
  );
}

function ThumbnailContainer(props: { thumbnail: SingleCardThumbnail }) {
  const { thumbnail } = props;
  const label = useRecoilValue(
    cardLabelQuery({ cardStateId: thumbnail.cardId })
  );
  return (
    <div className="thumbnail-container" key={thumbnail.cardId}>
      <label>{label}</label>
      {thumbnail.type === "chart" && (
        <div className="thumbnail-img-wrapper">
          <img alt="thumbnail" src={thumbnail.imageDataUri}></img>
        </div>
      )}
      {thumbnail.type === "text-ck" && (
        <div className="text-ck-thumbnail">
          <IconTypography size={36} />
        </div>
      )}
      {thumbnail.type === "chart-placeholder" && (
        <div className="chart-placeholder-thumbnail">
          <IconChartBar size={36} />
        </div>
      )}
      {thumbnail.type === "python" && (
        <div className="python-thumbnail">
          <IconCode size={36} />
        </div>
      )}
      {thumbnail.type === "table" && (
        <div className="table-thumbnail">
          <IconTable size={36}></IconTable>
        </div>
      )}
      {thumbnail.type === "micro" && (
        <div className="micro-thumbnail">
          <IconBuildingCommunity size={36}></IconBuildingCommunity>
        </div>
      )}
      {thumbnail.type === "error" && (
        <div className="error-thumbnail">
          <IconExclamationCircle size={36}></IconExclamationCircle>
        </div>
      )}
    </div>
  );
}
