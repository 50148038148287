import { BasicDoc } from "../state/actions/load/_shared";
import { DocCardState } from "../state/stats/document-core/core";
import { Permission, UserInfo } from "./UserInfo";
import { assertNever } from "../../core/assert";

export function hasWriteLicensesForUninitializedDoc(
  doc: BasicDoc,
  user: UserInfo
): boolean {
  return doc.state.cards.every((c) => {
    switch (c.type) {
      case "dataCard":
        return c.data.dataSelections.every((s) => {
          switch (s.measureType) {
            case "survey":
            case "survey_string":
              return user.hasSurveyWriteAccess();
            case "category":
            case "decimal":
            case "integer":
              return user.hasStatsWriteAccess();
            default:
              assertNever(s.measureType);
          }
        });
      case "microCard":
        return user.hasMicroWriteAccess();
      case "pythonCard":
        return user.hasPermission(Permission.PythonAnalysis);
      case "textCardSimple":
      case "textCardCK":
        return true;
    }
    assertNever(c);
  });
}

export function hasWriteAccessForCardType(
  card: DocCardState,
  user: UserInfo
): boolean {
  switch (card.type) {
    case "error":
    case "textCardSimple":
    case "textCardCK":
      return true;
    // No one has write access to micro card images - they only exist
    // in packaged docs, which are read-only
    case "pythonCard":
      return user.hasPermission(Permission.PythonAnalysis);
    case "microCardImage":
      return false;
    case "microCard":
      return user.hasMicroWriteAccess();
    case "dataCard":
      const isSurvey = card.data.dataSelections.some(
        (s) =>
          s.measureSelection?.measure.value_type === "survey" ||
          s.measureSelection?.measure.value_type === "survey_string"
      );
      if (isSurvey) {
        return user.hasSurveyWriteAccess();
      }
      return user.hasStatsWriteAccess();
  }
}
