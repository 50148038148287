import { Null, Array as ArrayRT, String as StringRT } from "runtypes";

import { config } from "../../../../../config";
import {
  AdminMeasureListDto,
  AdminMeasureListDtoRT,
} from "../../../../infra/api_responses/admin/document_versions";
import { HttpResult } from "../../../../infra/HttpResult";
import { decodedAuthedRequest } from "../../shared";

export function listSettingsVersions(): Promise<HttpResult<number[] | null>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    "admin/defaultsettings/versions",
    undefined,
    "GET",
    ArrayRT(StringRT).Or(Null)
  ).then((res) => {
    return res.match({
      ok: (versions) => {
        if (versions === null) {
          return HttpResult.fromOk(null);
        }
        const nums = versions.map((versionString) => {
          const numString = versionString.replace("v", "");
          return parseInt(numString, 10);
        });
        return HttpResult.fromOk(nums);
      },
      err: (err) => {
        return HttpResult.fromErr(err);
      },
    });
  });
}

export function listMeasuresBySettingsVersion(
  version: number
): Promise<HttpResult<AdminMeasureListDto>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `admin/defaultsettings/versions/v${version}`,
    undefined,
    "GET",
    AdminMeasureListDtoRT
  );
}
