import { Dropdown, TextField } from "@fluentui/react";
import { useRecoilValue } from "recoil";
import { first, uniqBy } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import "./AddVariableDialog.scss";

import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import { Button } from "../../../../../components/Button";
import { defined } from "../../../../../lib/core/defined";
import {
  computedVariableOperators,
  displayOperator,
} from "../../../../../lib/application/stats/datasets/computed_variables";
import {
  DocCardMicro,
  DocCardStats,
} from "../../../../../lib/application/state/stats/document-core/core";
import { validateNonEmptyString } from "../../../../../lib/application/validation/string";
import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import { config } from "../../../../../config";
import { StatsDataset } from "../../../../../lib/application/stats/datasets/StatsDataset";
import { MicroDataset } from "../../../../../lib/application/stats/datasets/MicroDataset";
import { SurveyDataset } from "../../../../../lib/application/stats/datasets/SurveyDataset";
import { useChangeDataOutputSettings } from "../../../../../lib/application/state/actions/selections/useChangeDataOutputSettings";
import { logger } from "../../../../../lib/infra/logging";
import { AlertBox } from "../../../../../components/AlertBox";
import { Dimension } from "../../../../../lib/application/stats/shared/core/definitions";
import { cardQuery } from "../../../../../lib/application/state/stats/document-core/queries/card";
import { useApplyChangeMicro } from "../../../../../lib/application/state/stats/useApplyChangeMicro";
import { useApplyChangeStats } from "../../../../../lib/application/state/stats/useApplyChangeStats";
import { translateGeoLabelReverse } from "../../../../../lib/domain/names";
import {
  ComputedVariableOperator,
  ComputedVariableV2,
  ComputedVariableV3,
  DataOutputSettings,
  OperandV3,
} from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";
import { ComputedVariableV1 } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";
import { Table } from "../../../../../components/Table";
import { replaceInArrayImmut } from "../../../../../lib/application/state/generic";

interface Props {
  isOpen: boolean;
  cardId: string;
  dataset: StatsDataset | MicroDataset | SurveyDataset;
  dimensionToLabel: (dimension: string) => string | undefined;
  onClose: () => void;
}
type UserDefinedConst = [dimension: string, value: number];

export function AddVariableDialog(props: Props) {
  const { cardId, dataset, dimensionToLabel } = props;

  const [labelInput, setLabelInput] = useState("");
  // Represents the label of the variable being edited
  const [isEditingExistingVariable, setIsEditingExistingVariable] = useState<
    null | string
  >(null);
  const [validationError, setValidationError] = useState<string | undefined>();
  const trimmedLabelInput = labelInput.trim();

  const card = useRecoilValue(cardQuery(cardId));

  const selectableDimensions: string[] = useMemo(() => {
    return dataset.eligibleDimensionsComputedVariable();
  }, [dataset]);

  const [selectedDimension, setSelectedDimension] = useState<
    string | undefined
  >(first(selectableDimensions));
  const [selectedOperator, setSelectedOperator] =
    useState<ComputedVariableOperator>(computedVariableOperators[0]);

  const [showVariableAddedMessage, setShowVariableAddedMessage] =
    useState(false);

  useEffect(() => {
    if (!showVariableAddedMessage) {
      return;
    }

    const timer = setTimeout(() => {
      setShowVariableAddedMessage(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [showVariableAddedMessage]);

  const stateSimple = useStateSimple();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showAddUserDefinedValue, setShowAddUserDefinedValue] = useState(false);
  const [userDefinedValue, setUserDefinedValue] = useState<string>("");

  const [userDefinedValues, setUserDefinedValues] = useState<
    UserDefinedConst[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [settings, setSettings] = useChangeDataOutputSettings(cardId);

  const applyChangeMicro = useApplyChangeMicro(cardId);
  const applyChangeStats = useApplyChangeStats(cardId);

  const handleAddUserDefinedValue = useCallback(() => {
    if (!defined(selectedDimension)) {
      return;
    }
    const value = parseFloat(userDefinedValue);
    if (
      userDefinedValues.some(
        (v) => v[0] === selectedDimension && v[1] === value
      )
    ) {
      setUserDefinedValue("");
      setShowAddUserDefinedValue(false);
      return;
    }

    setUserDefinedValues([...userDefinedValues, [selectedDimension, value]]);
    setUserDefinedValue("");
    setShowAddUserDefinedValue(false);
  }, [selectedDimension, userDefinedValue, userDefinedValues]);

  const handleCancelEdit = useCallback(() => {
    setIsEditingExistingVariable(null);
    setLabelInput("");
    stateSimple.setLeftSelectedKeys([]);
    stateSimple.setRightSelectedKeys([]);
  }, [stateSimple]);

  const handleRemoveVariable = useCallback(
    (variable: ComputedVariableV3) => {
      return Promise.resolve().then(() => {
        const updatedSettings: DataOutputSettings = {
          ...settings,
          computedVariablesV3: settings.computedVariablesV3.filter(
            (v) => v.label !== variable.label
          ),
        };
        if (card.type === "microCard") {
          const updatedCard: DocCardMicro = {
            ...card,
            data: {
              ...card.data,
              settings: {
                ...card.data.settings,
                dataOutputSettings: {
                  ...card.data.settings.dataOutputSettings,
                  computedVariablesV3: updatedSettings.computedVariablesV3,
                },
              },
            },
          };

          return applyChangeMicro(updatedCard);
        } else if (card.type === "dataCard") {
          const updatedCard: DocCardStats = {
            ...card,
            data: {
              ...card.data,
              settings: {
                ...card.data.settings,
                computedVariablesV3: updatedSettings.computedVariablesV3,
              },
            },
          };
          return applyChangeStats(updatedCard);
        }
      });
    },
    [applyChangeMicro, applyChangeStats, card, settings]
  );

  const handleEditVariable = useCallback(
    (variable: ComputedVariableV3) => {
      setSelectedDimension(variable.dimension);
      const dim = dataset
        .labelsWithIdsByDimension()
        .find((d) => d[0] === variable.dimension);

      function instantiateOperand(op: OperandV3): Operand {
        if (!defined(dim)) {
          throw new Error("Could not find dimension for key: " + dim);
        }

        switch (op.type) {
          case "constant":
            return {
              type: "constant",
              label: op.value.toString(),
              value: op.value,
            };
          case "dimension_value_with_id":
            const fullValue = dim[1].find((l) => l.id === op.id);
            if (!defined(fullValue)) {
              throw new Error("Could not find value for id: " + op.id);
            }
            return {
              type: "dimension_value_with_id",
              label: fullValue.text,
              id: op.id,
            };
          case "grouping":
            return {
              type: "grouping",
              label: op.label,
            };
          case "region":
            if (dataset instanceof MicroDataset) {
              return {
                type: "region",
                label: op.label,
                value: dataset.lookupDesoRegsoByLabel(op.label) ?? "",
              };
            }
            return {
              type: "region",
              label: translateGeoLabelReverse(op.label),
              value: translateGeoLabelReverse(op.label),
            };
          case "user_defined_value":
            return {
              type: "user_defined_value",
              label: op.label,
            };
        }
      }

      const constants = variable.leftOperand
        .concat(variable.rightOperand)
        .map((op) => (op.type === "constant" ? op.value : undefined))
        .filter(defined);

      setUserDefinedValues(
        uniqBy(
          [
            ...userDefinedValues,
            ...constants.map(
              (c) => [variable.dimension, c] as UserDefinedConst
            ),
          ],
          ([dim, value]) => dim + value
        )
      );

      setIsEditingExistingVariable(variable.label);
      const leftOps: Operand[] = variable.leftOperand
        .map(instantiateOperand)
        .filter(defined);
      const rightOps = variable.rightOperand
        .map(instantiateOperand)
        .filter(defined);
      stateSimple.setLeftSelectedKeys(leftOps);
      stateSimple.setRightSelectedKeys(rightOps);
      setLabelInput(variable.label);
    },
    [dataset, stateSimple, userDefinedValues]
  );

  const handleAddVariable = useCallback(
    (variable: ComputedVariableV3) => {
      return Promise.resolve()
        .then(() => {
          const updatedSettings: DataOutputSettings = {
            ...settings,
            computedVariablesV3:
              isEditingExistingVariable !== null
                ? replaceInArrayImmut(
                    settings.computedVariablesV3,
                    (v) => v.label === isEditingExistingVariable,
                    () => variable
                  )
                : [...settings.computedVariablesV3, variable],
          };
          if (card.type === "microCard") {
            const updatedCard: DocCardMicro = {
              ...card,
              data: {
                ...card.data,
                settings: {
                  ...card.data.settings,
                  dataOutputSettings: {
                    ...card.data.settings.dataOutputSettings,
                    computedVariablesV3: updatedSettings.computedVariablesV3,
                  },
                },
              },
            };

            return applyChangeMicro(updatedCard);
          } else if (card.type === "dataCard") {
            const updatedCard: DocCardStats = {
              ...card,
              data: {
                ...card.data,
                settings: {
                  ...card.data.settings,
                  computedVariablesV3: updatedSettings.computedVariablesV3,
                },
              },
            };
            return applyChangeStats(updatedCard);
          }
        })
        .then(() => {
          if (isEditingExistingVariable !== null) {
            setIsEditingExistingVariable(null);
          }
          setShowVariableAddedMessage(true);
          setLabelInput("");
          stateSimple.setLeftSelectedKeys([]);
          stateSimple.setRightSelectedKeys([]);
        });
    },
    [
      applyChangeMicro,
      applyChangeStats,
      card,
      isEditingExistingVariable,
      settings,
      stateSimple,
    ]
  );

  return (
    <div>
      <FluentModal
        width="md"
        containerClassName="add-variable-dialog-container"
        title="Beräknade värden"
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <FluentModalBody>
          <div className="add-variable-dialog">
            <p>
              Fyll i fälten här för att skapa ett värde som beräknas automatiskt
              baserat på dina val.
            </p>
            <section>
              <TextField
                required
                autoFocus
                label="Etikett"
                maxLength={config.valueLabelMaxLength}
                onChange={(event) => {
                  const newInput = event.currentTarget.value;
                  setLabelInput(newInput);
                  setValidationError(validateNonEmptyString(newInput.trim()));
                  if (
                    settings.computedVariables.find(
                      (c) => c.label === newInput.trim()
                    ) ||
                    settings.computedVariablesV3.find(
                      (c) => c.label === newInput.trim()
                    )
                  ) {
                    setValidationError("Etiketten finns redan");
                  }
                }}
                errorMessage={validationError}
                value={labelInput}
              ></TextField>

              <Dropdown
                label="Kategori"
                selectedKey={selectedDimension}
                onChange={(_, item) => {
                  if (!defined(item)) {
                    return;
                  }
                  const dim = selectableDimensions.find((d) => d === item.key);
                  if (!defined(dim)) {
                    logger.error("Could not find dimension for key", item.key);
                    return;
                  }
                  setSelectedDimension(dim);
                  stateSimple.setLeftSelectedKeys([]);
                  stateSimple.setRightSelectedKeys([]);
                }}
                options={selectableDimensions.map((d) => {
                  return {
                    text: dimensionToLabel(d) ?? "[saknas]",
                    key: d,
                  };
                })}
              ></Dropdown>
            </section>

            <h3>Beräkning</h3>
            <section>
              {defined(selectedDimension) && (
                <div>
                  <AddVariableDialogSimple
                    {...stateSimple}
                    settings={settings}
                    isEditingExisting={isEditingExistingVariable !== null}
                    handleCancelEdit={handleCancelEdit}
                    userDefinedConsts={userDefinedValues}
                    selectedOperator={selectedOperator}
                    setSelectedOperator={setSelectedOperator}
                    trimmedLabelInput={trimmedLabelInput}
                    validationError={validationError}
                    handleAdd={handleAddVariable}
                    dataset={dataset}
                    selectedDimension={selectedDimension}
                  />
                </div>
              )}
            </section>

            <div>
              {showAddUserDefinedValue ? (
                <div>
                  <div className="add-user-defined-value">
                    <TextField
                      className="grow"
                      value={userDefinedValue}
                      onChange={(e) =>
                        setUserDefinedValue(e.currentTarget.value)
                      }
                      type="number"
                      label="Egen siffra"
                    ></TextField>
                    <Button
                      title="Avbryt"
                      onClick={() => setShowAddUserDefinedValue(false)}
                    ></Button>
                    <Button
                      disabled={
                        !nonEmptyString(userDefinedValue) &&
                        !isFinite(parseFloat(userDefinedValue))
                      }
                      title="Infoga"
                      onClick={handleAddUserDefinedValue}
                    ></Button>
                  </div>
                  <p>
                    När du infogar en egen siffra kan den användas som en del i
                    beräkningen.
                  </p>
                </div>
              ) : (
                <Button
                  title="Infoga egen siffra"
                  onClick={() => setShowAddUserDefinedValue(true)}
                ></Button>
              )}
              {showVariableAddedMessage && (
                <>
                  <section></section>
                  <AlertBox intent="success">
                    <span>Värde tillagt</span>
                  </AlertBox>
                </>
              )}
            </div>
            <DisplayCurrentVariables
              settings={settings}
              dataset={dataset}
              dimensionToLabel={dimensionToLabel}
              onRemove={handleRemoveVariable}
              onEdit={handleEditVariable}
            />
          </div>
        </FluentModalBody>
        <FluentModalFooter>
          <Button
            title="Stäng"
            onClick={() => {
              props.onClose();
            }}
          ></Button>
        </FluentModalFooter>
      </FluentModal>
    </div>
  );
}

function DisplayCurrentVariables(props: {
  settings: DataOutputSettings;
  dataset: StatsDataset | MicroDataset | SurveyDataset;
  dimensionToLabel: (dimension: string) => string | undefined;
  onEdit: (variable: ComputedVariableV3) => void;
  onRemove: (variable: ComputedVariableV3) => void;
}) {
  const { settings, dataset, dimensionToLabel } = props;
  const labelsByDimension = dataset.labelsWithIdsByDimension();
  const allVariables = useMemo(() => {
    const vars: (
      | ComputedVariableV1
      | ComputedVariableV2
      | ComputedVariableV3
    )[] = [];
    for (const v of settings.computedVariables) {
      vars.push(v);
    }
    for (const v of settings.computedVariablesV3) {
      vars.push(v);
    }

    return vars;
  }, [settings.computedVariables, settings.computedVariablesV3]);

  const getLabel = useCallback(
    (dimension: string, valueId: number) => {
      const labels = labelsByDimension.find((d) => d[0] === dimension)?.[1];
      if (!defined(labels)) {
        return "[saknas]";
      }
      const label = labels.find((l) => l.id === valueId);
      if (!defined(label)) {
        return "[saknas]";
      }
      return label.text;
    },
    [labelsByDimension]
  );

  if (allVariables.length === 0) {
    return null;
  }

  return (
    <div className="margin-top-lg">
      <h3>Existerande variabler</h3>
      <div>
        <Table
          columns={[
            { type: "text", name: "Etikett" },
            { type: "text", name: "Dimension" },
            { type: "text", name: "Beräkning" },
            { type: "text", name: "", classNames: "align-right" },
          ]}
          data={allVariables.map((v) => {
            dataset.labelsWithIdsByDimension();
            switch (v.version) {
              case "1": {
                return {
                  id: v.label,
                  cells: [
                    v.label,
                    dimensionToLabel(v.dimension) ?? v.dimension,
                    `${v.leftOperand.join(" + ")} ${
                      v.operator
                    } ${v.rightOperand.join(" + ")}`,
                    <></>,
                  ],
                };
              }
              case "2":
                return {
                  id: v.label,
                  cells: [
                    v.label,
                    v.dimensions.map((d) => dimensionToLabel(d)).join(" > "),
                    `${v.leftOperand.join(" + ")} ${
                      v.operator
                    } ${v.rightOperand.join(" + ")}`,
                    <></>,
                  ],
                };
              case "3":
                return {
                  id: v.label,
                  cells: [
                    v.label,
                    dimensionToLabel(v.dimension) ?? v.dimension,
                    displayFormula(
                      renderSideOperands(v.leftOperand, (id: number) =>
                        getLabel(v.dimension, id)
                      ),
                      v.operator,
                      renderSideOperands(v.rightOperand, (id: number) =>
                        getLabel(v.dimension, id)
                      )
                    ),
                    <>
                      <Button
                        small
                        title="Redigera"
                        onClick={() => props.onEdit(v)}
                      />
                      <Button
                        small
                        intent="danger"
                        title="Ta bort"
                        onClick={() => props.onRemove(v)}
                      />
                    </>,
                  ],
                };
            }
          })}
        />
      </div>
    </div>
  );
}

function displayFormula(
  left: string | null,
  operator: ComputedVariableOperator,
  right: string | null
): string {
  if (left === null && right === null) {
    return "";
  }
  if (left === null && right !== null) {
    return right;
  }
  if (left !== null && right === null) {
    return left;
  }
  return `(${left ?? ""}) ${displayOperator(operator)} (${right ?? ""})`;
}

function renderOperand(operand: OperandV3, getLabel: (id: number) => string) {
  switch (operand.type) {
    case "constant":
      return operand.value.toString();
    case "dimension_value_with_id":
      return getLabel(operand.id);
    case "grouping":
      return operand.label;
    case "region":
      return operand.label;
    case "user_defined_value":
      return operand.label;
  }
}

function renderSideOperands(
  operands: OperandV3[],
  getLabel: (id: number) => string
) {
  if (operands.length === 0) {
    return null;
  }
  if (operands.length === 1) {
    return renderOperand(operands[0], getLabel);
  }
  return operands.map((o) => renderOperand(o, getLabel)).join(" + ");
}

type Operand = OperandV3 & {
  label: string;
};

interface StateSimple {
  leftSelectedKeys: Operand[];
  setLeftSelectedKeys: React.Dispatch<React.SetStateAction<Operand[]>>;
  rightSelectedKeys: Operand[];
  setRightSelectedKeys: React.Dispatch<React.SetStateAction<Operand[]>>;
}

function useStateSimple(): StateSimple {
  const [leftSelectedKeys, setLeftSelectedKeys] = useState<Operand[]>([]);
  const [rightSelectedKeys, setRightSelectedKeys] = useState<Operand[]>([]);

  return {
    leftSelectedKeys,
    setLeftSelectedKeys,
    rightSelectedKeys,
    setRightSelectedKeys,
  };
}

type OptionType = {
  key: string;
  text: string;
  value: Operand;
};

function AddVariableDialogSimple(
  props: {
    isEditingExisting: boolean;
    dataset: StatsDataset | MicroDataset | SurveyDataset;
    settings: DataOutputSettings;
    userDefinedConsts: UserDefinedConst[];
    handleCancelEdit: () => void;
    handleAdd: (variable: ComputedVariableV3) => void;
    selectedDimension: string;
    setSelectedOperator: React.Dispatch<
      React.SetStateAction<ComputedVariableOperator>
    >;
    selectedOperator: ComputedVariableOperator;
    trimmedLabelInput: string;
    validationError: string | undefined;
  } & StateSimple
) {
  const {
    isEditingExisting,
    validationError,
    handleCancelEdit,
    trimmedLabelInput,
    dataset,
    selectedDimension,
    setSelectedOperator,
    selectedOperator,
    leftSelectedKeys,
    setLeftSelectedKeys,
    rightSelectedKeys,
    setRightSelectedKeys,
  } = props;

  const dimensionLabelsWithIds = dataset
    .labelsWithIdsByDimension()
    .find((dimAndLabels) => dimAndLabels[0] === selectedDimension)?.[1];
  const options: OptionType[] = (dimensionLabelsWithIds ?? [])
    .map((l) =>
      dimensionLabelWithIdToOptionType(
        selectedDimension,
        dataset,
        props.settings,
        l
      )
    )
    .filter(defined)
    .concat(
      props.userDefinedConsts
        .filter((v) => v[0] === selectedDimension)
        .map((v) => {
          const value: Operand = {
            type: "constant",
            label: v[1].toString(),
            value: v[1],
          };
          return {
            key: itemToKey(value),
            text: v[1].toString(),
            value: value,
          } as OptionType;
        })
    );

  const handleAddComputedVariableSimpleDimensions = useCallback(() => {
    if (!defined(selectedDimension)) {
      return;
    }
    props.handleAdd({
      version: "3",
      label: trimmedLabelInput,
      dimension: selectedDimension,
      operator: selectedOperator,
      leftOperand: leftSelectedKeys,
      rightOperand: rightSelectedKeys,
    });
  }, [
    leftSelectedKeys,
    props,
    rightSelectedKeys,
    selectedDimension,
    selectedOperator,
    trimmedLabelInput,
  ]);

  const allFieldsValid =
    !defined(validationError) &&
    nonEmptyString(trimmedLabelInput) &&
    rightSelectedKeys.length + leftSelectedKeys.length > 0;

  const renderTitleLeft = useCallback(() => {
    return renderTitleFromOperands(leftSelectedKeys);
  }, [leftSelectedKeys]);

  const renderTitleRight = useCallback(() => {
    return renderTitleFromOperands(rightSelectedKeys);
  }, [rightSelectedKeys]);

  return (
    <>
      <div className="formula">
        <ValueSelectorLeft
          options={options}
          renderTitle={renderTitleLeft}
          renderDropdownHoverTitle={renderTextTitleFromOperands}
          selectedItems={leftSelectedKeys}
          itemToKey={itemToKey}
          setSelectedItems={setLeftSelectedKeys}
        />
        <Dropdown
          className="operator-dropdown"
          selectedKey={selectedOperator}
          onRenderTitle={(selectedItems) => (
            <strong>{selectedItems?.[0]?.text}</strong>
          )}
          dropdownWidth="auto"
          options={(config.appEnv === "prod"
            ? computedVariableOperators
            : computedVariableOperators
          ).map((op) => ({
            key: op,
            text: displayOperator(op),
          }))}
          onChange={(_, item) => {
            if (!defined(item)) {
              return;
            }
            setSelectedOperator(item.key as ComputedVariableOperator);
          }}
        ></Dropdown>
        <ValueSelectorRight
          options={options}
          renderDropdownHoverTitle={renderTextTitleFromOperands}
          renderTitle={renderTitleRight}
          itemToKey={itemToKey}
          selectedItems={rightSelectedKeys}
          setSelectedItems={setRightSelectedKeys}
        />

        {isEditingExisting && (
          <Button title="Avbryt" onClick={() => handleCancelEdit()} />
        )}
        <Button
          intent="primary"
          title={isEditingExisting ? "Spara" : "Lägg till"}
          disabled={!allFieldsValid}
          onClick={handleAddComputedVariableSimpleDimensions}
        ></Button>
      </div>
    </>
  );
}

function dimensionLabelWithIdToOptionType(
  selectedDimension: string,
  dataset: MicroDataset | StatsDataset | SurveyDataset,
  settings: DataOutputSettings,
  l: {
    text: string;
    id?: number;
  }
): OptionType | undefined {
  if (selectedDimension === Dimension.region) {
    if (dataset instanceof MicroDataset) {
      const value: Operand = {
        type: "region",
        value: dataset.lookupDesoRegsoByLabel(l.text) ?? "",
        label: l.text,
      };
      return {
        key: itemToKey(value),
        text: l.text,
        value,
      };
    }

    const value: Operand = {
      type: "region",
      value: translateGeoLabelReverse(l.text),
      label: translateGeoLabelReverse(l.text),
    };

    return {
      key: itemToKey(value),
      text: l.text,
      value: value,
    } as OptionType;
  } else if (selectedDimension === Dimension.grouping) {
    const value: Operand = {
      type: "grouping",
      label: l.text,
    };
    return {
      key: itemToKey(value),
      text: l.text,
      value: value,
    } as OptionType;
  }

  if (!defined(l.id)) {
    const found = settings.computedVariablesV3.find((c) => c.label === l.text);
    if (defined(found)) {
      const value: Operand = {
        type: "user_defined_value",
        label: l.text,
      };
      return {
        key: itemToKey(value),
        text: l.text,
        value: value,
      } as OptionType;
    }
    // If not a user-defined value, it must be a constant

    logger.error("should not happen -- no id, no computed variable found");
    return;
  }

  const value: Operand = {
    label: l.text,
    id: l.id,
    type: "dimension_value_with_id",
  };
  return {
    key: itemToKey(value),
    text: l.text,
    value: value,
  } as OptionType;
}

interface ValueSelectorProps<T> {
  id: string;
  title?: string;
  renderDropdownHoverTitle: (items: T[]) => string;
  renderTitle: () => ReactElement<any>;
  selectedItems: T[];
  itemToKey: (item: T) => string;
  setSelectedItems: (items: T[]) => void;
  options: {
    key: string;
    text: string;
    value: T;
  }[];
}

function ValueSelectorLeft(props: Omit<ValueSelectorProps<Operand>, "id">) {
  // ID required by end-to-end-tests
  return <ValueSelector id="left-operands" {...props} />;
}
function ValueSelectorRight(props: Omit<ValueSelectorProps<Operand>, "id">) {
  // ID required by end-to-end-tests
  return <ValueSelector id="right-operands" {...props} />;
}

function ValueSelector<T>(props: ValueSelectorProps<T>) {
  const { setSelectedItems, selectedItems, title, itemToKey, renderTitle } =
    props;

  const handleUpdatedSelectedKeys = useCallback(
    (toggle: T) => {
      const keys = selectedItems.some(
        (item) => itemToKey(item) === itemToKey(toggle)
      )
        ? selectedItems.filter((k) => itemToKey(k) !== itemToKey(toggle))
        : [...selectedItems, toggle];
      setSelectedItems(keys);
    },
    [itemToKey, selectedItems, setSelectedItems]
  );

  const selectedKeys = selectedItems.map(itemToKey);

  return (
    <Dropdown
      id={props.id}
      title={props.renderDropdownHoverTitle(selectedItems)}
      label={title}
      onRenderTitle={renderTitle}
      onChange={(_, item) => {
        if (!defined(item)) {
          return;
        }
        const option = props.options.find((o) => o.key === item.key);
        if (!defined(option)) {
          return;
        }
        handleUpdatedSelectedKeys(option.value);
      }}
      dropdownWidth="auto"
      placeholder="Välj"
      selectedKeys={selectedKeys}
      multiSelect
      options={props.options}
      className="value-selector"
    ></Dropdown>
  );
}

function renderTitleFromOperands(
  labelsRaw: Operand[] | undefined
): JSX.Element {
  return <span>{renderTextTitleFromOperands(labelsRaw)}</span>;
}

function renderTextTitleFromOperands(labelsRaw: Operand[] | undefined): string {
  const labels = labelsRaw?.map((l) => {
    return l.label;
  });
  return !defined(labels) || labels.length === 0
    ? "Välj"
    : labels.length === 1
    ? labels[0]
    : `(${labels.join(" + ")})`;
}

const itemToKey = (item: Operand) => {
  switch (item.type) {
    case "dimension_value_with_id":
      return item.id.toString();
    case "region":
    case "constant":
    case "grouping":
    case "user_defined_value":
      return JSON.stringify(
        Object.keys(item)
          .sort()
          .map((key) => [key, item[key as keyof typeof item]])
      );
  }
  return JSON.stringify(item);
};
