import { useSetRecoilState } from "recoil";
import { reportMetaStateQuery } from "../../stats/document-meta/queries";
import {
  PackagedDocCardDataDto,
  PackagedDocMicroCardNonImageDto,
  PackagedDocumentDto,
} from "../../stats/packaged-doc/types";
import { useSetAllCardsCallback } from "../cardCallbacks";
import { SurveyDataset } from "../../../stats/datasets/SurveyDataset";
import { defined } from "../../../../core/defined";
import {
  DataOutputView,
  DocCardMicro,
  DocCardState,
  DocCardStats,
  GeoSelections,
  QuillDocState,
} from "../../stats/document-core/core";
import {
  WorkspaceLatest,
  initializeColorSchemeContainerLatest,
  parsePartialDataOutputSettingsLatest,
} from "../../stats/workspace/shared";
import { decodeLatestWorkspace } from "../../stats/workspace/rebuild_state";
import { useEffect, useState } from "react";
import { logger } from "../../../../infra/logging";
import { pathJoin } from "../../../../paths";
import { sanitizeTextCardHtml } from "../../../sanitize_html";
import {
  chartDataToDataState,
  handleCreateMapDataset,
} from "../../stats/cardToDataStateStats";
import { StatsDataset } from "../../../stats/datasets/StatsDataset";
import { Progress } from "../../../../core/progress";
import {
  Geographies,
  GeographiesSerializable,
} from "../../../../domain/geography";
import { SVG_CHART_MAX_WIDTH } from "../../../stats/shared/charts_common";
import { DatasetGeneric } from "../../../stats/datasets/DatasetGeneric";
import { RowBaseInterface } from "../../../stats/shared/row";
import { SurveyStringDataset } from "../../../stats/datasets/SurveyStringDataset";
import { defaultThemeSpec } from "../../../stats/shared/core/colors/colorSchemes";
import { MicroDataset } from "../../../stats/datasets/MicroDataset";
import { makeDesoRegsoToPropertiesLookup } from "../../../../domain/micro/desoLookup";
import { MicroGeoTree } from "../../../stats/shared/MicroGeoTree";

export function useLoadPackagedDocument(packageId: string) {
  const setReportMetaState = useSetRecoilState(reportMetaStateQuery);
  const setAllCards = useSetAllCardsCallback();
  // TODO: mikro
  // const setAllGeoMicroStyles = useSetAllGeoMicroStyles();
  // const setAllGeoMicroLinks = useSetAllGeoMicroLinks();

  const [workspace, setWorkspace] = useState<WorkspaceLatest>();
  const [docData, setDocData] = useState<PackagedDocumentDto>();

  const [loadProgress, setLoadProgress] = useState<Progress>(
    Progress.NotStarted
  );

  // Load document and data files
  useEffect(() => {
    setLoadProgress(Progress.InProgress);
    const baseUrl = pathJoin(window.origin, "docs", packageId);

    fetch(pathJoin(baseUrl, "document.json"))
      .then((res) => res.json())
      .then((workspaceUnverified) => {
        const doc: { id: number; title: string; workspace: unknown } =
          workspaceUnverified; // FIXME: type check
        try {
          const decodedDoc = decodeLatestWorkspace(doc);
          setReportMetaState({
            userHasApplicableWriteLicenses: false, // Always false for packaged docs
            noFramesMode: decodedDoc.state.reportMeta?.noFramesMode ?? false,
            editModeOn: false,
            id: doc.id,
            title: doc.title,
          });
          setWorkspace(decodedDoc);
        } catch (e) {
          logger.error("Error decoding document.json", e);
          setLoadProgress(Progress.Error);
        }
      })
      .catch((err) => {
        logger.error("Error loading document.json", err);
        setLoadProgress(Progress.Error);
      });

    fetch(pathJoin(baseUrl, "data.json"))
      .then((res) => res.json())
      .then((doc: PackagedDocumentDto) => {
        try {
          setDocData(doc);
        } catch (e) {
          logger.error("Error decoding data.json", e);
          setLoadProgress(Progress.Error);
        }
      })
      .catch((err) => {
        logger.error("Error loading document", err);
        setLoadProgress(Progress.Error);
      });
  }, [packageId, setReportMetaState]);

  // Load card data using loaded file data
  useEffect(() => {
    if (!defined(workspace) || !defined(docData)) {
      return;
    }
    const geographies = Geographies.serializable(docData.geographies);

    const cardsPromise: Promise<DocCardState[]> = Promise.all(
      workspace.state.cards.map<Promise<DocCardState | null>>(
        async (card) => {
          switch (card.type) {
            case "textCardSimple":
              return {
                type: "textCardSimple",
                id: card.id,
                label: card.label,
                data: card.data as QuillDocState,
                isEditing: card.isEditing,
                pageBreak: card.pageBreak,
                // Does not have space before/after, customMargin..., bgColor; card type cannot be created anymore
              } as DocCardState;
            case "textCardCK":
              return {
                type: card.type,
                id: card.id,
                customMarginBottom: card.customMarginBottom,
                customMarginLeft: card.customMarginLeft,
                customMarginRight: card.customMarginRight,
                customMarginTop: card.customMarginTop,
                label: card.label,
                bgColor: card.bgColor,
                data: sanitizeTextCardHtml(card.data),
                isEditing: card.isEditing,
                pageBreak: card.pageBreak,
                hideSpaceAfter: card.hideSpaceAfter,
                hideSpaceBefore: card.hideSpaceBefore,
              } as DocCardState;
            case "dataCard": {
              const cardData = docData.cardData.find(
                (c) => c.type === "dataCard" && c.id === card.id
              );

              if (!defined(cardData)) {
                throw new Error(
                  `Card data not found for card ${card.id} in document ${packageId}`
                );
              }
              if (cardData.type !== "dataCard") {
                throw new Error("Card data type mismatch");
              }

              const dataOutputView =
                card.type === "dataCard" ? card.data.selectedView : undefined;
              const geoSelections =
                card.type === "dataCard" ? card.data.geoSelections : undefined;
              if (!defined(dataOutputView)) {
                throw new Error("Data output view not defined");
              }

              return {
                type: "dataCard",
                id: card.id,
                isEditing: false, // Packaged docs are always in view mode
                bgColor: card.bgColor,
                label: card.label,
                pageBreak: card.pageBreak,
                hideSpaceAfter: card.hideSpaceAfter,
                hideSpaceBefore: card.hideSpaceBefore,
                initState: Progress.Success,
                data: {
                  loadedData: await getDataCardLoadedData(
                    dataOutputView,
                    geoSelections,
                    cardData,
                    geographies
                  ),
                  selectedView: card.data.selectedView ?? "diagram",
                  settings: parsePartialDataOutputSettingsLatest(
                    card.data.settings
                  ),
                  geoSelections: card.data.geoSelections,
                  timeSelection: card.data.timeSelection,
                  lockToLatestTime: card.data.lockToLatestTime,
                  dataSelections: cardData.dataSelections,
                  geoExpansions: [],
                  geoSelectionsInherited: false,
                  groupingSelection: cardData.groupingSelection,
                },
              } as DocCardState;
            }
            case "microCard": {
              const cardData = docData.cardData.find((c) => c.id === card.id);
              if (!defined(cardData)) {
                throw new Error(
                  `Card data not found for card ${card.id} in package doc ${packageId}`
                );
              }

              switch (cardData.type) {
                case "dataCard":
                  throw new Error("Data card found for micro card");
                case "microCardImage":
                  return {
                    ...card,
                    bgColor: card.bgColor,
                    type: "microCardImage",
                    imgUrl: cardData.imgUrl,
                  };
                case "microCardNonImage":
                  return {
                    ...card,
                    type: "microCard",
                    id: card.id,
                    bgColor: card.bgColor,
                    isEditing: false, // Packaged docs are always in view mode
                    label: card.label,
                    pageBreak: card.pageBreak,
                    hideSpaceAfter: card.hideSpaceAfter,
                    hideSpaceBefore: card.hideSpaceBefore,
                    initState: Progress.Success,
                    data: {
                      filterMeasures: card.data.filterMeasures,
                      mapLocationBounds: card.data.mapLocationBounds,
                      loadedData: await getMicroCardLoadedData(cardData),
                      selectedTab: card.data.selectedTab,
                      settings: card.data.settings,
                      geoSelections: undefined,
                      timeSelection: undefined,
                      lockToLatestTime: false,
                      dataSelections: [],
                      geoExpansions: [],
                      geoSelectionsInherited: false,
                      groupingSelection: undefined,
                    },
                  } as DocCardMicro;
              }
              break;
            }
            case "pythonCard":
              throw new Error(
                "card of type pythonCard must not exist in packaged document"
              );
          }
        },
        [docData, workspace]
      )
    ).then((c) => c.filter(defined));

    cardsPromise
      .then((cards) => {
        setAllCards(cards);
        setLoadProgress(Progress.Success);
      })
      .catch((err) => {
        logger.error("Error loading cards", err);
        setLoadProgress(Progress.Error);
      });
  }, [docData, packageId, setAllCards, setReportMetaState, workspace]);

  return [loadProgress, docData] as const;
}

async function getMicroCardLoadedData(
  cardData: PackagedDocMicroCardNonImageDto
): Promise<DocCardMicro["data"]["loadedData"]> {
  const datasetDto = cardData.dataset;
  if (!defined(datasetDto)) {
    return {};
  }

  const microDataset = new MicroDataset(
    datasetDto.measureSelection,
    datasetDto.geoSelection,
    datasetDto.microSettings,
    datasetDto.data,
    datasetDto.microResultsType,
    makeDesoRegsoToPropertiesLookup(datasetDto.geoSelection),
    rebuildMicroGeoTree(datasetDto.microGeoTree),
    false
  );

  const loadedData: NonNullable<DocCardMicro["data"]["loadedData"]> = {
    primaryDataset: microDataset,
    primaryProgress: { type: Progress.Success },
  };

  const initData = await initializeMicroCardData(microDataset, cardData);
  loadedData.chartDataState = initData?.chartDataState;
  loadedData.tableDataState = initData?.tableDataState;
  return loadedData;
}

async function initializeMicroCardData(
  dataset: MicroDataset,
  cardData: PackagedDocMicroCardNonImageDto
): Promise<DocCardMicro["data"]["loadedData"]> {
  if (!(dataset instanceof MicroDataset)) {
    throw new Error("Unsupported dataset type");
  }
  const microOutputTab = cardData.selectedTab;
  const loadedData: DocCardMicro["data"]["loadedData"] = {};

  if (microOutputTab === "chart" && defined(cardData.chartDataState)) {
    const defaultCustomPalette =
      cardData.chartDataState?.colorSchemeContainer.embeddedPalette ??
      defaultThemeSpec();
    const chartData = dataset.chartData?.();
    if (defined(chartData)) {
      loadedData.chartDataState = await chartDataToDataState(
        chartData,
        undefined,
        SVG_CHART_MAX_WIDTH,
        defaultCustomPalette,
        initializeColorSchemeContainerLatest(
          cardData.chartDataState.colorSchemeContainer
        )
      );
    }
  }
  if (microOutputTab === "table") {
    loadedData.tableDataState = {
      loadProgress: { type: Progress.Success },
      loadedTableData: dataset.table(),
    };
  }

  return loadedData;
}

async function getDataCardLoadedData(
  dataOutputView: DataOutputView,
  geoSelections: GeoSelections | undefined,
  cardData: PackagedDocCardDataDto,
  geographies: GeographiesSerializable
): Promise<DocCardStats["data"]["loadedData"]> {
  const datasetDto = cardData.dataset;
  const loadedData: DocCardStats["data"]["loadedData"] = {};
  if (!defined(datasetDto)) {
    return loadedData;
  }

  switch (datasetDto.type) {
    case "survey": {
      const surveyDataset = new SurveyDataset(
        datasetDto.data,
        datasetDto.primaryMeasureSelection,
        datasetDto.groupingMeasureSelection,
        datasetDto.settings
      );
      loadedData.dataset = surveyDataset;
      loadedData.progress = { type: Progress.Success };
      break;
    }
    case "survey_string": {
      const surveyStringDataset = new SurveyStringDataset(
        datasetDto.data,
        datasetDto.primaryMeasureSelection,
        datasetDto.settings
      );
      loadedData.dataset = surveyStringDataset;
      loadedData.progress = { type: Progress.Success };
      break;
    }
    case "stats": {
      const dataset = new StatsDataset(
        datasetDto.data,
        datasetDto.measureSelections,
        datasetDto.includedGeoTypes,
        datasetDto.selectedGeocodes,
        datasetDto.singleSelectedGeoLabel,
        datasetDto.settings
      );
      loadedData.dataset = dataset;
      loadedData.progress = { type: Progress.Success };
      break;
    }
  }

  const dataset = loadedData.dataset;
  if (!defined(dataset)) {
    return loadedData;
  }

  const initData = await initializeStatsCardData(
    dataOutputView,
    geoSelections,
    dataset,
    cardData,
    geographies
  );
  loadedData.chartDataState = initData?.chartDataState;
  loadedData.tableDataState = initData?.tableDataState;
  loadedData.tableSurveyStringDataState = initData?.tableSurveyStringDataState;
  loadedData.statsMapsState = initData?.statsMapsState;
  return loadedData;
}

async function initializeStatsCardData<
  T extends string | number | null,
  U extends RowBaseInterface<T>
>(
  dataOutputView: DataOutputView,
  geoSelections: GeoSelections | undefined,
  dataset: DatasetGeneric<T, U>,
  cardData: PackagedDocCardDataDto,
  geographies: GeographiesSerializable
): Promise<DocCardStats["data"]["loadedData"]> {
  const loadedData: DocCardStats["data"]["loadedData"] = {};

  if (dataOutputView === "diagram" && defined(cardData.chartDataState)) {
    const defaultCustomPalette =
      cardData.chartDataState?.colorSchemeContainer.embeddedPalette ??
      defaultThemeSpec();
    const chartData = dataset.chartData?.();
    if (defined(chartData)) {
      loadedData.chartDataState = await chartDataToDataState(
        chartData,
        undefined,
        SVG_CHART_MAX_WIDTH,
        defaultCustomPalette,
        initializeColorSchemeContainerLatest(
          cardData.chartDataState.colorSchemeContainer
        )
      );
    }
  }
  if (dataOutputView === "table") {
    if (dataset instanceof SurveyStringDataset) {
      loadedData.tableSurveyStringDataState = {
        loadProgress: { type: Progress.Success },
        loadedData: dataset.tableSurveyString(),
      };
    } else if (
      dataset instanceof SurveyDataset ||
      dataset instanceof StatsDataset
    ) {
      loadedData.tableDataState = {
        loadProgress: { type: Progress.Success },
        loadedTableData: dataset.table(),
      };
    } else {
      throw new Error("Unsupported dataset type");
    }
  }
  if (dataOutputView === "map" && dataset instanceof StatsDataset) {
    if (!defined(geoSelections)) {
      throw new Error("Geo selections not defined");
    }

    loadedData.statsMapsState = {
      loadProgress: { type: Progress.Success },
      loadedMapsData: await handleCreateMapDataset(
        dataset,
        geoSelections,
        geographies,
        cardData.dataset?.settings
      ),
    };
  }

  return loadedData;
}

function rebuildMicroGeoTree(dto: MicroGeoTree) {
  return new MicroGeoTree((dto as any)._tree);
}
