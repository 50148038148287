import { classNames } from "../lib/core/classNames";
import "./AlertBox.scss";

export type AlertIntent =
  | "default"
  | "primary"
  | "success"
  | "warning"
  | "danger";
interface Props {
  intent?: "default" | "primary" | "success" | "warning" | "danger";
  className?: string;
  children: JSX.Element[] | JSX.Element;
}
export function AlertBox(props: Props) {
  const intent = props.intent || "default";
  return (
    <div className={classNames(props.className, "alert-box", intent)}>
      {props.children}
    </div>
  );
}
