import { Breadcrumb } from "@fluentui/react";

import { NodePath } from "../../../../../lib/application/files/tree";
import { defined } from "../../../../../lib/core/defined";

export function FilePathNavigation(props: {
  path?: NodePath;
  rootName: string;
  handleBackToRoot: () => void;
  handleNavigate: (nodeId: number) => void;
}) {
  const pathItems = [
    {
      text: props.rootName,
      key: "my-docs",
      onClick: props.handleBackToRoot,
    },
  ]
    .concat(
      props.path?.map((segment) => ({
        text: segment.title,
        key: segment.nodeId.toString(),
        onClick: () => props.handleNavigate(segment.nodeId),
      })) ?? []
    )
    .filter(defined);
  return <Breadcrumb items={pathItems}></Breadcrumb>;
}
