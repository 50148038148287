export function getMedian(valuesUnsorted: number[]): number {
  if (valuesUnsorted.length === 0) {
    throw new Error("No values -- cannot calculate median");
  }
  const values = valuesUnsorted.slice().sort((a, b) => a - b);

  const numValues = values.length;
  if (numValues % 2 === 0) {
    const middleIndex = numValues / 2;
    return (values[middleIndex - 1] + values[middleIndex]) / 2;
  }

  const middleIndex = Math.ceil(numValues / 2) - 1;
  return values[middleIndex];
}
