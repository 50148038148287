import * as _ from "lodash";
import { chain } from "lodash";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { round } from "../../lib/core/math/round";

import { SvgWrapper } from "./shared/SvgWrapper";
import Bounds from "./shared/Bounds";
import { AxisLineBottom } from "./shared/axes/AxisLineBottom";
import { ChartSourceVertical } from "./shared/ChartSource";
import { LinesHorizontal } from "./shared/LinesHorizontal";
import { TicksLeft } from "./shared/ticks/TicksLeft";
import { TicksBottom } from "./shared/ticks/TicksBottom";
import {
  ChartLegendBottom,
  ChartLegendRight,
  LegendLabelEvents,
} from "./shared/ChartLegendCommon";
import { ChartTitle } from "./shared/ChartTitle";
import { AxisLineLeft } from "./shared/axes/AxisLineLeft";
import { defined } from "../../lib/core/defined";

import { FocusLineVertical, LinesVertical } from "./shared/LinesVertical";
import {
  LEGEND_COLOR_INDICATOR_CHAR,
  MainChartDimensions,
  Orientation,
} from "../../lib/application/stats/shared/core/definitions";
import { getDefaultTextStyleChartValueLabel } from "../../lib/application/stats/shared/core/TextStyle";
import { useUpdateSvgThumbnail } from "../../lib/application/hooks/thumbnails";
import { DefaultLoading } from "../Loading";
import { ColorScheme } from "../../lib/application/state/stats/document-core/core";
import {
  ChartPointPositions,
  ExtendedPositionInfo,
  OverlayMode,
} from "./line_chart_overlay/types";
import { HoverTableRow, ResultsTable } from "./line_chart_overlay/ResultsTable";
import { getExtendedPositionInfo } from "./line_chart_overlay/shared";
import {
  dateComparatorAscending,
  dateStringComparatorAsc,
} from "../../lib/core/time";
import { BackgroundBandVertical } from "./shared/background";
import { displayNaNType } from "../../lib/application/stats/datasets/DataCellValue";
import {
  DataPoint,
  DrawableDataPoint,
  ForecastDataPoint,
  isDrawableDataPoint,
} from "../../lib/application/stats/datasets/DataPoint";
import {
  ChartDataContainerV2Line,
  LineChartDataV2,
} from "../../lib/application/state/stats/document-core/_core-shared";
import { last } from "../../lib/core/last";
import { DataOutputSettings } from "../../lib/application/state/stats/document-core/DataOutputSettings";

import "./LineChart.scss";
import { DEFAULT_CHART_TEXT_COLOR } from "../../lib/application/stats/shared/core/colors/colors";

type AnchorAlignment = "start" | "middle" | "end";

interface Props {
  cardId: string;
  chartData: ChartDataContainerV2Line;
  preserveAspectRatio?: string;
}

const BASE_LINE_WIDTH = 2;
const FAT_LINE_WIDTH = 3;
const BASE_DOT_RADIUS = 3;
const FAT_DOT_RADIUS = 4;
const EXTRA_FAT_DOT_RADIUS = 5;

type FocusState = { pinned: boolean; label: string }[];

/**
 * Line chart component with hooks
 */
export function LineChartContainer(props: Props) {
  const svgContainerRef = useRef<null | SVGSVGElement>(null);
  const handleUpdate = useUpdateSvgThumbnail(svgContainerRef, props.cardId);

  useEffect(() => {
    handleUpdate();
  }, [handleUpdate, props.chartData]);

  return (
    <LineChartInner
      {...props}
      svgContainerRef={svgContainerRef}
    ></LineChartInner>
  );
}

/**
 * Hooks-free line chart component
 */
export function LineChartInner(
  props: Props & {
    /** Only used for previews, not in actual cards */
    previewBackgroundColor?: string;
    svgContainerRef: React.MutableRefObject<SVGSVGElement | null>;
  }
) {
  const svgContainerRef = props.svgContainerRef;
  const dataContainer = props.chartData;
  const lineChartData = dataContainer.data;

  const colorSchemeContainer = dataContainer.colorSchemeContainer;
  const fullColorScheme = colorSchemeContainer.colorScheme;

  const chartDimensions = lineChartData.chartDimensions;
  const {
    main: dims,
    title: titleDims,
    source,
    legend: legendPosition,
  } = chartDimensions;
  const lineGen = lineChartData.d3lineGen;
  const legend = lineChartData.legend;
  const settings = lineChartData.settings;
  const labelAreas = lineChartData.labelAreas;
  const [hoverLabelsPosition, setHoverLabelsPosition] = useState<
    ChartPointPositions | undefined
  >();
  const [overlayMode, setOverlayMode] = useState<OverlayMode>({
    type: "hover",
  });
  const chartDivContainer = useRef<null | HTMLDivElement>(null);

  const [focusState, setFocusState] = useState<FocusState>([]);
  const isFocusMode = focusState.length > 0;

  const updateFocusState = (
    focusLabel: string,
    on: boolean,
    pinned: boolean
  ) => {
    const remainingState = focusState.filter((l) => l.pinned);
    if (on) {
      setFocusState(remainingState.concat([{ pinned, label: focusLabel }]));
    } else {
      setFocusState(remainingState.filter((l) => l.label !== focusLabel));
    }
  };

  const legendLabelEvents: LegendLabelEvents = {
    onMouseEnter: (label) => updateFocusState(label, true, false),
    onMouseLeave: (label) => updateFocusState(label, false, false),
  };

  const handleChartClick = useCallback(() => {
    if (!defined(hoverLabelsPosition)) {
      return;
    }
    switch (overlayMode.type) {
      case "hover":
        return setOverlayMode({
          type: "1-fixed",
          positions: hoverLabelsPosition,
        });
      case "1-fixed":
        return setOverlayMode({
          type: "2-fixed",
          positions: [overlayMode.positions, hoverLabelsPosition],
        });
      case "2-fixed":
        return setOverlayMode({ type: "hover" });
    }
  }, [hoverLabelsPosition, overlayMode]);

  useEffect(() => {
    function onMouseMoveRaw(event: globalThis.MouseEvent) {
      const svgArea = svgContainerRef.current;
      if (!defined(svgArea)) {
        return;
      }

      const pageY = event.pageY;
      const pageX = event.pageX;

      const svgBoundingRect = (
        svgArea as any as SVGElement
      ).getBoundingClientRect();

      const bodyScrollY = window.scrollY;
      const bodyScrollX = window.scrollX;
      const rectX = svgBoundingRect.left + bodyScrollX + dims.marginLeft;
      const rectY = svgBoundingRect.top + bodyScrollY + dims.marginTop;
      const rectHeight =
        svgBoundingRect.height - dims.marginTop - dims.marginBottom;
      const rectWidth =
        svgBoundingRect.width - dims.marginLeft - dims.marginRight;

      if (
        pageY > rectY &&
        pageY < rectY + rectHeight &&
        pageX > rectX &&
        pageX < rectX + rectWidth
      ) {
        setHoverLabelsPosition({
          chartAreaPosition: { x: pageX - rectX, y: pageY - rectY },
          pagePosition: { x: pageX, y: pageY },
        });
        return;
      }

      if (defined(hoverLabelsPosition)) {
        setHoverLabelsPosition(undefined);
      }
    }

    const onMouseMove = _.throttle(onMouseMoveRaw, 20);
    const options = { capture: false };

    document.addEventListener("mousemove", onMouseMove, options);
    return () => {
      document.removeEventListener("mousemove", onMouseMove, options);
    };
  });

  const hoveredDomainValueRaw = defined(hoverLabelsPosition)
    ? lineChartData.closestDomainValue(hoverLabelsPosition.chartAreaPosition.x)
    : undefined;

  // Sort lines so that focused lines appear last, meaning they get
  // the highest z-index
  const lines =
    focusState.length === 0
      ? lineChartData.lines
      : lineChartData.lines.slice().sort((left, right) => {
          const leftFocused = defined(
            focusState.find((s) => s.label === left.colorKey)
          );
          const rightFocused = defined(
            focusState.find((s) => s.label === right.colorKey)
          );
          if (leftFocused && !rightFocused) {
            return 1;
          } else if (!leftFocused && rightFocused) {
            return -1;
          }
          return 0;
        });

  if (!defined(fullColorScheme)) {
    return <DefaultLoading></DefaultLoading>;
  }

  const showAllLabels =
    settings.showLabels &&
    !settings.lineChartShowLastLabel &&
    !settings.lineChartShowLastLabelAlignLeft;

  const drawSpecs: Array<{
    lineLabel: string;
    lineColor: string;
    lineHasSinglePoint: boolean;
    linePathSpec: React.SVGProps<SVGPathElement>;
    lineForecastSpec?: React.SVGProps<SVGPathElement>;
    uncertaintyIntervalSpec?: React.SVGProps<SVGPathElement>;
    drawablePoints?: {
      point: DrawableDataPoint;
      showLabel: boolean;
      accentuate: boolean;
    }[];
    forecastDrawablePoints?: {
      point: ForecastDataPoint;
      showLabel: boolean;
      accentuate: boolean;
    }[];
  }> = [];
  for (const line of lines) {
    const timeSeriesLine = line.line;
    const points = timeSeriesLine.singleLine().map((p) => p.point);
    const drawablePoints = points.filter(isDrawableDataPoint);
    const lineLabel = line.colorKey;
    const lineFocused =
      isFocusMode &&
      focusState.find((s) => s.label === lineLabel) !== undefined;
    const lineColor =
      isFocusMode && !lineFocused ? "#eee" : fullColorScheme[lineLabel];
    const lineHasSinglePoint = drawablePoints.length === 1;
    const forecastLine = line.forecastLine.points();
    const forecastUncertaintyLine = line.forecastLine.uncertaintyOutline();
    const forecastDrawablePoints = forecastLine.filter(isDrawableDataPoint);
    const lineStrokeWidth = settings.showFatLines
      ? FAT_LINE_WIDTH
      : BASE_LINE_WIDTH;
    drawSpecs.push({
      lineLabel,
      lineColor,
      lineHasSinglePoint,
      linePathSpec: {
        d: lineGen(drawablePoints) ?? "",
        stroke: lineColor,
        strokeWidth: lineStrokeWidth,
        fill: "none",
      },
      lineForecastSpec:
        settings.forecast.show && forecastLine.length > 0
          ? {
              d:
                lineGen(
                  [last(drawablePoints)]
                    .filter(defined)
                    .concat(forecastDrawablePoints as DrawableDataPoint[])
                ) ?? undefined,
              stroke: lineColor,
              strokeDasharray: "5,5",
              strokeWidth: lineStrokeWidth,
              fill: "none",
            }
          : undefined,
      uncertaintyIntervalSpec:
        settings.forecast.show &&
        settings.forecast.showUncertaintyInterval &&
        forecastUncertaintyLine.length > 0
          ? {
              d:
                lineGen(
                  [last(drawablePoints)]
                    .filter(defined)
                    .concat(forecastUncertaintyLine as DrawableDataPoint[])
                ) ?? undefined,
              strokeWidth: lineStrokeWidth,
              opacity: 0.2,
              fill: lineColor,
            }
          : undefined,
      forecastDrawablePoints: forecastDrawablePoints.map((point, i) => ({
        point,
        accentuate:
          settings.lineChartAccentuateLastDatum &&
          i === forecastDrawablePoints.length - 1,
        showLabel:
          showAllLabels ||
          (settings.lineChartShowLastLabel ||
          settings.lineChartShowLastLabelAlignLeft
            ? i === forecastDrawablePoints.length - 1
            : false),
      })),
      drawablePoints: drawablePoints.map((point, i) => ({
        point,
        accentuate:
          settings.lineChartAccentuateLastDatum &&
          i === drawablePoints.length - 1 &&
          forecastDrawablePoints.length === 0,
        showLabel:
          showAllLabels ||
          ((settings.lineChartShowLastLabel ||
            settings.lineChartShowLastLabelAlignLeft) &&
          forecastDrawablePoints.length === 0
            ? i === drawablePoints.length - 1
            : false),
      })),
    });
  }

  let labelAnchor: AnchorAlignment = "middle";
  if (settings.lineChartShowLastLabel) {
    labelAnchor = "end";
  } else if (settings.lineChartShowLastLabelAlignLeft) {
    labelAnchor = "start";
  }

  return (
    <div
      className={"svg-chart-container"}
      ref={chartDivContainer}
      onClick={handleChartClick}
    >
      <OverlayInfoBox
        dimensions={dimensionsWithSvgOffset(
          dims,
          chartDivContainer,
          svgContainerRef
        )}
        overlayMode={overlayMode}
        colorScheme={fullColorScheme}
        currentHoverPosition={hoverLabelsPosition}
        chartData={lineChartData}
      />

      <SvgWrapper
        cardId={props.cardId}
        containerRef={svgContainerRef}
        width={dims.fullWidth}
        height={dims.fullHeight}
        preserveAspectRatio={props.preserveAspectRatio}
        previewBackgroundColor={props.previewBackgroundColor}
      >
        <ChartTitle
          position={titleDims.position}
          titleRowSet={lineChartData.title}
          fontColor={colorSchemeContainer?.customHeadersColor}
        />
        <Bounds dims={dims}>
          <LinesHorizontal
            dims={dims}
            ticksContainer={lineChartData.rangeTicks}
            strokeColor={
              colorSchemeContainer.customGridLinesXColor ??
              colorSchemeContainer?.customGridLinesColor
            }
            strokeDashArray={settings.gridLinesXStyle}
          />
          {/* Use vertical lines only when explicitly defined */}
          {defined(colorSchemeContainer.customGridLinesYColor) && (
            <LinesVertical
              dims={dims}
              ticksContainer={lineChartData.domainTicks}
              strokeColor={colorSchemeContainer.customGridLinesYColor}
              strokeDashArray={settings.gridLinesYStyle}
            />
          )}
          <OverlaySvgGraphics
            chartData={lineChartData}
            overlayMode={overlayMode}
            dimensions={dims}
            currentHoverPosition={hoverLabelsPosition}
          ></OverlaySvgGraphics>
        </Bounds>

        <TicksLeft
          hideTickLabels={settings.showYAxisLabels === false}
          fontColor={colorSchemeContainer.customLabelsColor}
          drawMarks={settings.showTicksYAxis}
          customTickStrokeColor={colorSchemeContainer?.customAxesColor}
          dims={dims}
          ticksContainer={lineChartData.rangeTicks}
        />
        <TicksBottom
          fontColor={colorSchemeContainer.customLabelsColor}
          customTickStrokeColor={
            settings.showTicksXAxis === false
              ? null
              : colorSchemeContainer?.customAxesColor
          }
          dims={dims}
          ticksContainer={lineChartData.domainTicks}
        />

        {settings.showYAxis && (
          <AxisLineLeft
            customStrokeColor={colorSchemeContainer?.customAxesColor}
            dims={dims}
          ></AxisLineLeft>
        )}
        {settings.showXAxis && (
          <AxisLineBottom
            customStrokeColor={colorSchemeContainer?.customAxesColor}
            dims={dims}
          ></AxisLineBottom>
        )}

        <Bounds dims={dims}>
          {drawSpecs.map((s) => {
            return (
              <g key={s.lineLabel}>
                <path {...s.linePathSpec}></path>
                {defined(s.lineForecastSpec) && (
                  <path {...s.lineForecastSpec}></path>
                )}
                {defined(s.uncertaintyIntervalSpec) && (
                  <path {...s.uncertaintyIntervalSpec}></path>
                )}
                {s.drawablePoints?.map((p, i) => (
                  <DatumCircle
                    anchor={labelAnchor}
                    key={p.point.domainRaw() + "data" + s.lineLabel}
                    overlayMode={overlayMode}
                    hoveredDomainValueRaw={hoveredDomainValueRaw}
                    point={p.point}
                    settings={settings}
                    lineHasSinglePoint={s.lineHasSinglePoint}
                    lineChartData={lineChartData}
                    lineColor={s.lineColor}
                    accentuateDatum={p.accentuate}
                  />
                ))}
                {s.forecastDrawablePoints?.map((p, i) => (
                  <DatumCircle
                    anchor={labelAnchor}
                    key={p.point.domainRaw() + "fcast" + s.lineLabel}
                    overlayMode={overlayMode}
                    hoveredDomainValueRaw={hoveredDomainValueRaw}
                    point={p.point}
                    settings={settings}
                    lineHasSinglePoint={false}
                    lineChartData={lineChartData}
                    lineColor={s.lineColor}
                    accentuateDatum={p.accentuate}
                  />
                ))}
              </g>
            );
          })}
          {drawSpecs.map((s) => {
            const verticallyCenterLastLabel =
              !settings.showLineCircles &&
              !settings.lineChartAccentuateLastDatum &&
              settings.lineChartShowLastLabelAlignLeft;
            return (
              <g key={s.lineLabel + "_labels"}>
                {chain(s.drawablePoints ?? [])
                  .filter((p) => p.showLabel)
                  .map((p) => (
                    <DatumLabel
                      key={p.point.domainRaw() + "label" + s.lineLabel}
                      point={p.point}
                      settings={settings}
                      lineChartData={lineChartData}
                      anchor={labelAnchor}
                      verticallyCentered={verticallyCenterLastLabel}
                      accentuateDatum={p.accentuate}
                    />
                  ))
                  .value()}
                {chain(s.forecastDrawablePoints ?? [])
                  .filter((p) => p.showLabel)
                  .map((p) => (
                    <DatumLabel
                      key={p.point.domainRaw() + "label" + s.lineLabel}
                      point={p.point}
                      settings={settings}
                      lineChartData={lineChartData}
                      anchor={labelAnchor}
                      verticallyCentered={verticallyCenterLastLabel}
                      accentuateDatum={p.accentuate}
                    />
                  ))
                  .value()}
              </g>
            );
          })}
        </Bounds>

        {legend?.orientation === Orientation.vertical && (
          <ChartLegendRight
            fontColor={
              colorSchemeContainer?.customLabelsColor ??
              DEFAULT_CHART_TEXT_COLOR
            }
            colorScheme={fullColorScheme}
            labelEvents={legendLabelEvents}
            position={legendPosition.position}
            legend={legend}
          ></ChartLegendRight>
        )}
        {legend?.orientation === Orientation.horizontal && (
          <ChartLegendBottom
            fontColor={
              colorSchemeContainer?.customLabelsColor ??
              DEFAULT_CHART_TEXT_COLOR
            }
            colorScheme={fullColorScheme}
            labelEvents={legendLabelEvents}
            legendPosition={{
              x: dims.marginLeft,
              y:
                dims.boundedHeight +
                dims.marginTop +
                (labelAreas.labelsBottom?.heightWithoutLegend ?? 0),
            }}
            legend={legend}
          ></ChartLegendBottom>
        )}
        <ChartSourceVertical
          fontColor={colorSchemeContainer?.customLabelsColor}
          position={source.position}
          source={lineChartData.source}
        ></ChartSourceVertical>
      </SvgWrapper>
    </div>
  );
}

function DatumLabel(props: {
  point: DataPoint;
  settings: DataOutputSettings;
  lineChartData: LineChartDataV2;
  accentuateDatum: boolean;
  verticallyCentered: boolean;
  anchor: AnchorAlignment;
}) {
  const {
    lineChartData,
    verticallyCentered,
    settings,
    point,
    anchor,
    accentuateDatum,
  } = props;

  return (
    <text
      style={getDefaultTextStyleChartValueLabel({
        desiredSize: settings.chart.labelSize,
        forcedSize: settings.chart.valueLabelSize,
      }).svgFontAttrs()}
      textAnchor={anchor}
      x={lineChartData.scaledDomain(point) + (verticallyCentered ? 2 : 0)}
      // If accentuating this datum, move the label further from the point
      // because the point will be bigger
      dy={verticallyCentered ? "0.4em" : accentuateDatum ? "-0.4em" : "-0.2em"}
      y={lineChartData.scaledRange(point) - (verticallyCentered ? 0 : 5)}
    >
      {point.isUserDefined
        ? point.display()
        : lineChartData.formattedRange(point)}
    </text>
  );
}

function DatumCircle(props: {
  overlayMode: OverlayMode;
  hoveredDomainValueRaw: string | undefined;
  point: DataPoint;
  settings: DataOutputSettings;
  lineHasSinglePoint: boolean;
  lineChartData: LineChartDataV2;
  lineColor: string;
  accentuateDatum: boolean;
  anchor: AnchorAlignment;
}) {
  const {
    overlayMode,
    hoveredDomainValueRaw,
    point,
    settings,
    lineHasSinglePoint,
    lineChartData,
    lineColor,
  } = props;
  const isHoveredValue =
    overlayMode.type !== "2-fixed" && // No hover effect when already having fixed an overlay
    defined(hoveredDomainValueRaw) &&
    hoveredDomainValueRaw === point.domainRaw();

  return (
    <g key={point.domainRaw()}>
      {!props.accentuateDatum &&
        (settings.showLineCircles || isHoveredValue || lineHasSinglePoint) && (
          <circle
            cx={lineChartData.scaledDomain(point)}
            cy={lineChartData.scaledRange(point)}
            r={
              isHoveredValue
                ? EXTRA_FAT_DOT_RADIUS
                : settings.showFatLines
                ? FAT_DOT_RADIUS
                : BASE_DOT_RADIUS
            }
            fill={lineColor}
          />
        )}
      {props.accentuateDatum && (
        <circle
          // stroke="rgba(0,0,0,0.5)"
          cx={lineChartData.scaledDomain(point)}
          cy={lineChartData.scaledRange(point)}
          r={getAccentuatedCirceSize(settings)}
          fill={lineColor}
        />
      )}
    </g>
  );
}

function getAccentuatedCirceSize(settings: DataOutputSettings) {
  if (settings.showLineCircles) {
    return settings.showFatLines
      ? EXTRA_FAT_DOT_RADIUS + 1
      : FAT_DOT_RADIUS + 1;
  }
  return settings.showFatLines ? FAT_DOT_RADIUS + 1 : BASE_DOT_RADIUS + 1;
}

function OverlaySvgGraphics(props: {
  overlayMode: OverlayMode;
  currentHoverPosition?: ChartPointPositions;
  chartData: LineChartDataV2;
  dimensions: MainChartDimensions;
}): JSX.Element {
  const { overlayMode, currentHoverPosition, chartData, dimensions } = props;
  if (overlayMode.type === "hover" && defined(currentHoverPosition)) {
    const domainValue = getExtendedPositionInfo(
      currentHoverPosition,
      chartData
    ).domainValue;
    if (!defined(domainValue)) {
      return <></>;
    }
    return (
      <FocusLineVertical
        dims={dimensions}
        xOffset={chartData.scaleDomainValue(domainValue)}
      ></FocusLineVertical>
    );
  } else if (overlayMode.type === "1-fixed") {
    return (
      <OverlaySvgGraphicsRange
        position1={overlayMode.positions}
        position2={currentHoverPosition}
        chartData={chartData}
        dimensions={dimensions}
      />
    );
  } else if (overlayMode.type === "2-fixed") {
    return (
      <OverlaySvgGraphicsRange
        position1={overlayMode.positions[0]}
        position2={overlayMode.positions[1]}
        chartData={chartData}
        dimensions={dimensions}
      />
    );
  }
  return <></>;
}

function OverlaySvgGraphicsRange(props: {
  position1: ChartPointPositions;
  position2?: ChartPointPositions;
  chartData: LineChartDataV2;
  dimensions: MainChartDimensions;
}): JSX.Element {
  const { chartData, dimensions } = props;
  const positions = [props.position1, props.position2]
    .filter(defined)
    .sort((a, b) => (a.pagePosition.x > b.pagePosition.x ? 1 : -1));
  const position1 = positions[0];
  const position2 = positions[1] as ChartPointPositions | undefined;
  const domainValue1 = getExtendedPositionInfo(
    position1,
    chartData
  ).domainValue;
  const domainValue2 = defined(position2)
    ? getExtendedPositionInfo(position2, chartData).domainValue
    : undefined;
  const xOffset1 = defined(domainValue1)
    ? chartData.scaleDomainValue(domainValue1)
    : undefined;
  const xOffset2 = defined(domainValue2)
    ? chartData.scaleDomainValue(domainValue2)
    : undefined;
  return (
    <>
      {defined(xOffset1) && (
        <FocusLineVertical
          dims={dimensions}
          xOffset={xOffset1}
        ></FocusLineVertical>
      )}
      {defined(xOffset1) && defined(xOffset2) && (
        <BackgroundBandVertical
          x1={xOffset1}
          x2={xOffset2}
          dims={dimensions}
        ></BackgroundBandVertical>
      )}
      {defined(xOffset2) && (
        <FocusLineVertical
          dims={dimensions}
          xOffset={xOffset2}
        ></FocusLineVertical>
      )}
    </>
  );
}

function OverlayInfoBox(props: {
  overlayMode: OverlayMode;
  currentHoverPosition?: ChartPointPositions;
  colorScheme: ColorScheme;
  chartData: LineChartDataV2;
  dimensions: MainChartDimensions;
}): JSX.Element {
  const infoboxOffset = 20;
  const {
    chartData,
    overlayMode,
    currentHoverPosition,
    dimensions,
    colorScheme,
  } = props;
  const commonProps = { dimensions, chartData, colorScheme, infoboxOffset };
  switch (overlayMode.type) {
    case "hover":
      if (!defined(currentHoverPosition)) {
        return <></>;
      }
      return (
        <HoverLabels
          {...commonProps}
          extendedPositionInfo={getExtendedPositionInfo(
            currentHoverPosition,
            chartData
          )}
        ></HoverLabels>
      );
    case "1-fixed":
      const extendedPosition1 = getExtendedPositionInfo(
        overlayMode.positions,
        chartData
      );
      const hoverPosition = defined(currentHoverPosition)
        ? getExtendedPositionInfo(currentHoverPosition, chartData)
        : undefined;
      if (
        !defined(hoverPosition) ||
        hoverPosition.domainValueRaw === extendedPosition1.domainValueRaw
      ) {
        return (
          <HoverLabels
            {...commonProps}
            chartData={chartData}
            colorScheme={props.colorScheme}
            extendedPositionInfo={extendedPosition1}
          ></HoverLabels>
        );
      }
      return (
        <PercentageChangeOverlay
          {...commonProps}
          extendedPositionInfo1={extendedPosition1}
          extendedPositionInfo2={hoverPosition}
        ></PercentageChangeOverlay>
      );

    case "2-fixed":
      return (
        <PercentageChangeOverlay
          {...commonProps}
          extendedPositionInfo1={getExtendedPositionInfo(
            overlayMode.positions[0],
            chartData
          )}
          extendedPositionInfo2={getExtendedPositionInfo(
            overlayMode.positions[1],
            chartData
          )}
        ></PercentageChangeOverlay>
      );
  }
}

function PercentageChangeOverlay(props: {
  extendedPositionInfo1: ExtendedPositionInfo;
  extendedPositionInfo2: ExtendedPositionInfo;
  colorScheme: ColorScheme;
  chartData: LineChartDataV2;
  infoboxOffset: number;
  dimensions: MainChartDimensions;
}): JSX.Element {
  const {
    chartData,
    extendedPositionInfo1,
    extendedPositionInfo2,
    infoboxOffset,
  } = props;

  const raw = [extendedPositionInfo1, extendedPositionInfo2]
    .map((pos) => pos.domainValueRaw)
    .filter(defined);
  if (raw.length < 2) {
    return <></>;
  }
  raw.sort(dateStringComparatorAsc);
  const relativeChanges = chartData.relativeChanges(raw[0], raw[1]);

  const domainValue = extendedPositionInfo2.domainValue;
  if (!defined(domainValue)) {
    return <></>;
  }

  const sortedPeriods = [
    extendedPositionInfo1.domainValue,
    extendedPositionInfo2.domainValue,
  ]
    .filter(defined)
    .sort(dateComparatorAscending);
  const [periodStart, periodEnd] = sortedPeriods;
  const xOffset = chartData.scaleDomainValue(domainValue);
  const chartWidth = chartData.chartDimensions.main.boundedWidth;
  return (
    <ResultsTable
      marginLeft={props.dimensions.marginLeft}
      marginTop={props.dimensions.marginTop}
      headerLines={[
        `Förändring`,
        `${
          defined(periodStart) ? chartData.formatDomain(periodStart) : "--"
        } till ${
          defined(periodEnd) ? chartData.formatDomain(periodEnd) : "--"
        }`,
      ]}
      isLowPriorityValue={(item) =>
        item.change.match({ ok: () => false, nan: () => true })
      }
      domainPositionXOffset={xOffset}
      chartWidth={chartWidth}
      sortedResults={relativeChanges}
      infoboxOffset={infoboxOffset}
      renderDataPoint={(row) => {
        const hasColorDimension = chartData.sortedGroupingDimensions.length > 0;
        return (
          <tr key={row.label}>
            {hasColorDimension && (
              <>
                <td
                  className="color-indicator"
                  style={{ color: props.colorScheme[row.label] }}
                >
                  {LEGEND_COLOR_INDICATOR_CHAR}
                </td>
                <td
                  className="label"
                  style={{ maxWidth: chartWidth / 2 - infoboxOffset }}
                >
                  {row.label}
                </td>
              </>
            )}

            <td className="value tabular-data">
              {row.change.match({
                nan: displayNaNType,
                ok: (value) => round(value, 0) + "%",
              })}
            </td>
          </tr>
        );
      }}
    ></ResultsTable>
  );
}

function HoverLabels(props: {
  extendedPositionInfo: ExtendedPositionInfo;
  colorScheme: ColorScheme;
  chartData: LineChartDataV2;
  infoboxOffset: number;
  dimensions: MainChartDimensions;
}): JSX.Element {
  const { chartData, extendedPositionInfo } = props;
  const hasColorDimension = props.chartData.hasColorDimension;

  const renderDataPoint = useCallback(
    (dataPoint: DataPoint): JSX.Element => {
      return (
        <HoverTableRow
          hasColorDimension={hasColorDimension}
          colorScheme={props.colorScheme}
          key={dataPoint.label()}
          dataPoint={dataPoint}
          labelMaxWidth={
            props.dimensions.boundedWidth / 2 - props.infoboxOffset
          }
        ></HoverTableRow>
      );
    },
    [
      hasColorDimension,
      props.colorScheme,
      props.dimensions.boundedWidth,
      props.infoboxOffset,
    ]
  );

  const domainValueRaw = extendedPositionInfo.domainValueRaw;
  if (!defined(domainValueRaw)) {
    return <></>;
  }
  const dataPoints: DataPoint[] = chartData.dataPointsByDomainValue(
    domainValueRaw,
    { adaptiveFormatting: true }
  );

  const domainValue = extendedPositionInfo.domainValue;
  if (!defined(domainValue)) {
    return <></>;
  }
  const xOffset = chartData.scaleDomainValue(domainValue);
  const chartWidth = chartData.chartDimensions.main.boundedWidth;

  return (
    <ResultsTable
      isLowPriorityValue={(item) => false}
      infoboxOffset={props.infoboxOffset}
      marginLeft={props.dimensions.marginLeft}
      marginTop={props.dimensions.marginTop}
      domainPositionXOffset={xOffset}
      chartWidth={chartWidth}
      renderDataPoint={renderDataPoint}
      headerLines={[chartData.formatDomain(domainValue)]}
      sortedResults={dataPoints}
    ></ResultsTable>
  );
}

/**
 * Offset the dimensions to account for the SVG element being offset from the
 * div container
 */
function dimensionsWithSvgOffset(
  dims: MainChartDimensions,
  chartDivContainerRef: MutableRefObject<HTMLDivElement | null>,
  svgContainerRef: MutableRefObject<SVGSVGElement | null>
): MainChartDimensions {
  const divRect = chartDivContainerRef.current?.getBoundingClientRect();
  const svgRect = svgContainerRef.current?.getBoundingClientRect();
  if (!defined(divRect) || !defined(svgRect)) {
    return dims;
  }

  const svgLeftOffset = svgRect.left - divRect.left;
  const modifiedDimensions = {
    ...dims,
    marginLeft: dims.marginLeft + svgLeftOffset,
  };
  return modifiedDimensions;
}
