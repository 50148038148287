import React from "react";
import "./ProgressBar.scss";

export const PROGRESS_BAR_GREEN = "#4CAF50";
export const PROGRESS_BAR_RED = "#F44336";
export const PROGRESS_BAR_RED_LIGHT = "#ff8566";
export const PROGRESS_BAR_GREEN_LIGHT = "#a5e65c";
export const PROGRESS_BAR_BACKGROUND_GREEN = "#E8F5E9";

interface Section {
  value: number;
  color: string;
  hoverText?: string;
}

interface ProgressBarProps {
  sections: Section[];
}

export const ProgressBar: React.FC<ProgressBarProps> = React.memo(
  ({ sections }) => {
    // Calculate the total value to determine how much each section should take up
    const totalValue = sections.reduce(
      (total, section) => total + section.value,
      0
    );

    return (
      <div className="progress-bar">
        {sections.map((section, index) => {
          if (section.value <= 0) {
            return null;
          }
          return (
            <div
              title={section.hoverText}
              key={index}
              style={{
                backgroundColor: section.color,
                width: `${(section.value / totalValue) * 100}%`,
              }}
            />
          );
        })}
      </div>
    );
  }
);

export function ProgressBarPercent(props: { percent: number }) {
  return (
    <div className="progress-bar-percent">
      <ProgressBar
        sections={[
          { value: props.percent, color: PROGRESS_BAR_GREEN },
          { value: 100 - props.percent, color: PROGRESS_BAR_BACKGROUND_GREEN },
        ]}
      />
      <p>{Math.round(props.percent)}%</p>
    </div>
  );
}
