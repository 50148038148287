import { last } from "lodash";
import { Link } from "@fluentui/react/lib/Link";
import {
  DetailsList,
  IColumn,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import { useCallback, useState } from "react";

import { defined } from "../../../../lib/core/defined";
import {
  ChildNodeInfo,
  FileNodeInfo,
} from "../../../../lib/application/files/tree";
import { NotReadyHttpErrNotice } from "../../../../components/Loading";
import {
  readNode,
  listNodes,
} from "../../../../lib/application/requests/docs/documents";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../components/Modal";
import { InfoBox } from "../../../../components/InfoBox";
import { Button } from "../../../../components/Button";
import { FileFeatures, sharingColumnSpec } from "./shared/features";
import { FolderOrLibrary } from "./shared/FileListCommon";
import { FilePathNavigation } from "./shared/FilePathWithNavigation";
import { minimumAccessMatched } from "../../../../lib/application/files/SharingInfo";
import { AlertBox } from "../../../../components/AlertBox";
import { useLoadableHttpResource } from "../../../../lib/application/hooks/useLoadableResource";
import { useStateTransition } from "../../../../lib/application/hooks/useStateTransition";

/**
 * Indicates which nodes to show
 */
export type MoveDialogNodesMode = "owned-only" | "shared-only";

export function FileListMoveDialog(props: {
  nodesMode: MoveDialogNodesMode;
  fileFeatures: FileFeatures;
  fileRootName: string;
  item: ChildNodeInfo;
  onClose: () => void;
  onMove: (item: ChildNodeInfo, target: FileNodeInfo) => void;
}) {
  const nodesMode = props.nodesMode;
  const [currentFolderId, setCurrentFolderId] = useState<number | undefined>(
    last(props.item.path)?.nodeId
  );
  const loader = useCallback(() => {
    return defined(currentFolderId) ? readNode(currentFolderId) : listNodes();
  }, [currentFolderId]);
  const [nodeInfoResult, reload] = useLoadableHttpResource(loader);

  useStateTransition(currentFolderId, (prev, current) => {
    if (prev !== current) {
      reload();
    }
  });

  const foldersList = nodeInfoResult.map((ok) => {
    return (
      ok.children?.filter((child) => {
        const isFolder = child.type === "folder";
        if (nodesMode === "owned-only") {
          return isFolder && child.ownership.type === "owner";
        } else if (nodesMode === "shared-only") {
          return isFolder && child.isShared;
        }
      }) ?? []
    );
  });

  const columns: IColumn[] = [
    {
      key: "type-col",
      name: "Filtyp",
      fieldName: "type",
      isIconOnly: true,
      minWidth: 14,
      maxWidth: 14,
      onRender: (item) => (
        <FolderOrLibrary
          fileFeatures={props.fileFeatures}
          item={item}
        ></FolderOrLibrary>
      ),
    },
    {
      key: "name-col",
      name: "Namn",
      fieldName: "name",
      minWidth: 210,
      maxWidth: 550,
      isRowHeader: true,
      isResizable: true,
      className: "name-column",
      onRender: (item: ChildNodeInfo) => {
        return <Link data-selection-invoke={true}>{item.title}</Link>;
      },
      data: "string",
      isPadded: true,
    },
  ];

  if (nodesMode === "shared-only") {
    columns.push(sharingColumnSpec);
  }

  return nodeInfoResult.match({
    notReady: (status) => <NotReadyHttpErrNotice left={status} />,
    ready: (currentNodeInfo) => {
      const isSelf =
        currentNodeInfo.type !== "root" && currentNodeInfo.id === props.item.id;
      const hasMinimumMoveAccess =
        currentNodeInfo.type === "root"
          ? true
          : minimumAccessMatched("editor", currentNodeInfo.roles);

      return (
        <FluentModal
          containerClassName="file-move-dialog"
          title={`Flytta ${props.item.title}`}
          onClose={props.onClose}
          isOpen={true}
        >
          <FluentModalBody>
            <>
              <FilePathNavigation
                rootName={props.fileRootName}
                path={
                  currentNodeInfo.type === "root"
                    ? undefined
                    : currentNodeInfo.path
                }
                handleBackToRoot={() => setCurrentFolderId(undefined)}
                handleNavigate={(nodeId) => setCurrentFolderId(nodeId)}
              ></FilePathNavigation>
            </>
            <>
              {!hasMinimumMoveAccess && (
                <AlertBox intent="warning">
                  <span>Du har inte behörighet att flytta hit.</span>
                </AlertBox>
              )}
            </>
            {foldersList.fold(
              () => null,
              (folders) => {
                return folders.length === 0 ? (
                  <InfoBox
                    sections={[
                      {
                        header: "Inga mappar",
                        text: "Den här mappen innehåller inga mappar.",
                      },
                    ]}
                  ></InfoBox>
                ) : (
                  <DetailsList
                    selectionMode={SelectionMode.none}
                    columns={columns}
                    items={folders}
                    onItemInvoked={(item: ChildNodeInfo) => {
                      setCurrentFolderId(item.id);
                    }}
                  ></DetailsList>
                );
              }
            )}
          </FluentModalBody>
          <FluentModalFooter>
            <Button title="Avbryt" onClick={props.onClose}></Button>
            <Button
              disabled={isSelf || !hasMinimumMoveAccess}
              intent="primary"
              title="Flytta hit"
              onClick={() => {
                if (currentNodeInfo.type === "root") {
                  return props.onMove(props.item, currentNodeInfo);
                }
                if (currentNodeInfo.belongsToSampleLibrary) {
                  if (
                    !window.confirm(
                      "Om du flyttar hit kommer mappen/dokumenten automatiskt ingå i ett exempelbibliotek. Vill du fortsätta?"
                    )
                  ) {
                    return;
                  }
                } else if (!props.item.isShared && currentNodeInfo.isShared) {
                  if (
                    !window.confirm(
                      "Om du flyttar hit kommer mappen/dokumentet automatiskt delas. Vill du fortsätta?"
                    )
                  ) {
                    return;
                  }
                }
                props.onMove(props.item, currentNodeInfo);
              }}
            ></Button>
          </FluentModalFooter>
        </FluentModal>
      );
    },
  });
}
