export function round(value: number, decimals: number): number {
  if (decimals >= 50) {
    return value;
  }
  if (decimals < 0) {
    decimals = 0;
  }
  if (value < 0) {
    return -round(-value, decimals);
  }
  const pow = Math.pow(10, decimals);
  return Math.round(value * pow) / pow;
}
