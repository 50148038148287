import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SpinnerSize, ThemeProvider } from "@fluentui/react";
import {
  Auth0ContextInterface,
  Auth0Provider,
  useAuth0,
  User,
} from "@auth0/auth0-react";
import { RecoilRoot, useSetRecoilState } from "recoil";
import { once } from "lodash";

import "./App.scss";

import logoFull from "../logo_with_text_color.svg";
import { NavBar, NavBarThirdPartyDoc } from "../components/NavBar";
import { pathInfo, pathJoin, statsOverviewPath } from "../lib/paths";
import { authState } from "../lib/application/state/AuthState";
import { DelayedSpinner } from "../components/DelayedSpinner";
import {
  AppMessage,
  globalAppMessagesHandler,
} from "../lib/application/AppMessagesHandler";
import { AppMessagesBar } from "../components/AppMessagesBar";
import { defined } from "../lib/core/defined";
import {
  AppMessagesContext,
  InfostatDataAdminModeContext,
  CategoriesContext,
  ShowDraftDataContext,
  UserInfoContext,
  GeographiesContext,
  CategoriesContextMicroPrimaryContext,
  CategoriesContextMicroSecondaryContext,
  UserInfoUpdateContext,
  UserInfoUpdateOpts,
  OrgPreferencesUpdateContext,
  OrgPreferencesUpdateOpts,
  TutorialsContext,
  OrganizationBrandingContext,
} from "../lib/application/contexts";
import { Milliseconds } from "../lib/core/time";
import { IS_LOCAL, IS_PROD, auth0AuthorizationParams, config } from "../config";
import { DocumentsOverview } from "./stats/home/Overview";
import { MainContent } from "../components/MainContent";
import { Categories } from "../lib/domain/categories";
import {
  getCategoriesWithCache,
  getGeographicRegionsWithCache,
} from "../lib/application/requests/common_requests";
import { getText } from "../lib/application/strings";
import { getQueryParam } from "../lib/application/browser/query_params";
import {
  extractPathToRestoreAfterAuthRedirect,
  setAuthRedirectRestorePathIfNotExists,
} from "../lib/application/browser/redirectState";
import { HttpError, HttpResult } from "../lib/infra/HttpResult";
import { HttpErrorPage } from "../components/errors/HttpErrorView";
import { DocNotFound } from "./stats/docs/DocNotFound";
import { StatsDocument } from "./stats/docs/StatsDocument";
import { StatsNewCopy } from "./stats/docs/StatsNewCopy";
import { StatsNewDoc } from "./stats/docs/StatsNewDoc";
import { StatsNewFromSharedDoc } from "./stats/docs/StatsNewFromSharedDoc";
import { StatsShareLink } from "./StatsShareLink";
import { DefaultLoading, NotReadyHttpErrFullPage } from "../components/Loading";
import { UserSettings } from "./stats/settings/UserSettings";
import {
  getDataAdminModeStored,
  getDraftModeStored,
  setDataAdminModeStored,
  setDraftModeStored,
} from "../lib/application/state/data_admin_mode";
import { DataAdminHome } from "./admin/views_data_admin/DataAdminHome";
import { StatsSingleMeasureNewDoc } from "./stats/docs/StatsSingleMeasureNewDoc";
import { TimeResolution } from "../lib/domain/time";
import { useLoadableHttpResource } from "../lib/application/hooks/useLoadableResource";
import { eitherDisplayer } from "../components/wrappers/either";
import { isMissingLicenseResult } from "../lib/infra/errors";
import { AlertBox } from "../components/AlertBox";
import { fluentUITheme } from "../lib/application/theme";
import { useToggle } from "../lib/application/hooks/useToggle";
import {
  getAccountInfo,
  updateOrgPreferences,
  updateUserPreferences,
} from "../lib/application/requests/account";
import { UserInfo } from "../lib/application/auth/UserInfo";
import { logger } from "../lib/infra/logging";
import { EmbedWrapper } from "./stats/docs/cards/embedded_card/DataCardEmbed";
import { shouldReloadClientApp } from "../lib/application/app_version";
import { updateTidioState } from "../lib/application/customer_support/load";
import { StripedBar } from "../components/StripedBar";
import { StatsDocumentPackaged } from "./stats/docs/StatsDocumentPackaged";
import { PackagedDocErrorBoundary } from "../components/errors/PackagedDocErrorBoundary";
import { GeographiesSerializable } from "../lib/domain/geography";
import { DataLoadErrorView } from "./stats/docs/cards/card_general/data_load_views";
import { StatsSingleMeasure } from "./stats/docs/StatsSingleMeasure";
import { HttpErrorNotice } from "../components/errors/HttpErrorNotice";
import { getMicroCategoriesWithCache } from "../lib/application/requests/datasets/micro";
import { nonEmptyString } from "../lib/core/nonEmptyString";
import { useExtendedAppearanceSettings } from "../lib/application/state/actions/useExtendedAppearanceSettings";
import { addToHistory } from "../lib/application/browser/locationHistory";
import { UserInfoDto } from "../lib/infra/api_responses/account";
import { MemberOrgDoc, SecureDeliveryDoc } from "./stats/docs/ThirdPartyDoc";
import { ThirdPartyOrgAdmin } from "./stats/third_party_org_admin/ThirdPartyOrgAdmin";
import { TutorialsHandler } from "../lib/application/tutorial_system/tutorials_handler";
import { Tutorial } from "../lib/application/tutorial_system/types";
import { patchNode } from "../lib/application/requests/docs/documents";
import { voidFunc } from "../lib/core/voidFunc";
import { reportMetaStateQuery } from "../lib/application/state/stats/document-meta/queries";

const setEmbeddedFrameMode = once(function (embedKey: string) {
  authState.setEmbedKey(embedKey);
  config.appMode = "embedded";
  document
    .getElementsByTagName("html")?.[0]
    ?.classList.add("embedded-frame", "Stats");
});

/** Fetches and sets release id, needed for creating packaged docs */
const initReleaseId = once(function () {
  return fetch(pathJoin(window.origin, "release_id.txt"), {
    cache: "no-cache",
  })
    .then((res) => res.text())
    .then((releaseId) => {
      config.releaseId = releaseId.trim();
    })
    .catch((err) => {
      logger.error("Kunde inte hämta release-id", err);
    });
});

async function packagedDocModeActive(): Promise<boolean> {
  const host = new URL(window.location.origin).host;
  if (host === config.packagedDocDomain) {
    return true;
  }
  if (IS_LOCAL) {
    const base = pathJoin(window.location.origin, window.location.pathname);
    return Promise.all([
      fetch(pathJoin(base, "document.json"), { method: "HEAD" })
        .then((res) => res.ok)
        .catch(() => false),
      fetch(pathJoin(base, "data.json"), { method: "HEAD" })
        .then((res) => res.ok)
        .catch(() => false),
    ]).then(([docOk, dataOk]) => docOk && dataOk);
  }

  return false;
}

export function App() {
  const [packagedDocMode, setPackagedDocMode] = useState<undefined | boolean>(
    undefined
  );

  useEffect(() => {
    packagedDocModeActive().then(setPackagedDocMode);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Routes WITHOUT auth! */}
        <Route
          path={pathInfo.embeddedCard.pathTemplate}
          element={<EmbeddedCard></EmbeddedCard>}
        ></Route>

        {defined(packagedDocMode) && packagedDocMode && (
          <Route
            path={pathInfo.packagedDoc.pathTemplate}
            element={<PackagedDocument></PackagedDocument>}
          ></Route>
        )}

        {!defined(packagedDocMode) && (
          <Route path="*" element={<DefaultLoading />}></Route>
        )}

        {/* Routes WITH auth! */}
        {defined(packagedDocMode) && !packagedDocMode && (
          <Route
            path="*"
            element={
              <Auth0Provider
                domain={config.auth0.domain}
                clientId={config.auth0.clientId}
                authorizationParams={auth0AuthorizationParams}
                cacheLocation="localstorage"
                // Undocumented option that is required because we use passwordless magic links,
                // that open in a new tab
                useCookiesForTransactions={true}
              >
                <Routes>
                  <Route
                    path={pathInfo.loggedOutMissingLicense.pathTemplate}
                    element={
                      <div className="margin-md">
                        <AlertBox>
                          <h2>Kontot du loggade in med saknar licens.</h2>
                          <p>
                            Kontrollera att du loggade in med rätt epostadress
                            och har en aktiv licens.
                          </p>
                          <a href={window.location.origin}>
                            Tillbaka till inloggning
                          </a>
                        </AlertBox>
                        <div className="align-center margin-lg">
                          <img
                            src={logoFull}
                            height={50}
                            className="logo"
                            alt="logotyp"
                          />
                        </div>
                      </div>
                    }
                  ></Route>
                  <Route
                    path="*"
                    element={<AppAuthedOrLoading></AppAuthedOrLoading>}
                  ></Route>
                </Routes>
              </Auth0Provider>
            }
          ></Route>
        )}
      </Routes>
    </BrowserRouter>
  );
}

function AppAuthedOrLoading() {
  const auth = useAuth0();
  const { isLoading, isAuthenticated, loginWithRedirect } = auth;

  if (isLoading) {
    return (
      <DelayedSpinner
        className="padding-lg"
        delayShowMs={200}
        label="Laddar..."
        labelPosition="bottom"
        size={SpinnerSize.large}
      ></DelayedSpinner>
    );
  }

  if (!isAuthenticated) {
    setAuthRedirectRestorePathIfNotExists();
    loginWithRedirect();
    return <div></div>;
  }

  return <AppAuthenticated auth={auth}></AppAuthenticated>;
}

interface NavbarProps {
  toggleShowDraftData: () => void;
  toggleDataAdminMode: () => void;
  logout: () => void;
  handleSaveDraft?: (
    docId: number,
    name: string
  ) => Promise<HttpResult<unknown>>;
}

export function AppAuthenticated(props: { auth: Auth0ContextInterface<User> }) {
  const [authStateInitialized, setAuthStateInitialized] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [geographies, setGeographies] = useState<
    GeographiesSerializable | undefined
  >(undefined);
  const [appMessages, setAppMessages] = useState<AppMessage[]>([]);
  const [userInfoError, setUserInfoError] = useState<HttpError | undefined>(
    undefined
  );

  const location = useLocation();
  useEffect(() => {
    addToHistory(location.pathname);
  }, [location]);

  const auth = props.auth;

  useEffect(() => {
    if (!authStateInitialized) {
      return;
    }

    getGeographicRegionsWithCache().then((res) => {
      res.match({
        ok: (geos) => {
          setGeographies(geos);
        },
        err: (err) => {
          logger.error("Kunde inte hämta geografiska områden", err);
        },
      });
    });
  }, [authStateInitialized]);

  const appMessagesHandler = useMemo(() => globalAppMessagesHandler, []);

  useEffect(() => {
    appMessagesHandler.init((message) => {
      setAppMessages((previous) => {
        const remainingMessages = previous.filter(
          (m) => !defined(m.expiryTime) || m.expiryTime > Date.now()
        );
        return [...remainingMessages, message];
      });
    });
  }, [appMessagesHandler]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const upToDate = appMessages.filter(
        (m) => !defined(m.expiryTime) || m.expiryTime > currentTime
      );
      if (upToDate.length !== appMessages.length) {
        setAppMessages(upToDate);
      }
    }, Milliseconds.second);

    return () => {
      clearInterval(interval);
    };
  });

  // Make auth0 context available outside of React component tree so we
  // can use it in API requests that aren't handled in React components
  useEffect(() => {
    authState.updateAuthContext(auth);
    setAuthStateInitialized(true);
    getAccountInfo().then((res) => {
      res.match({
        ok: (userInfoDto) => {
          setUserInfo(new UserInfo(userInfoDto));
        },
        err: (err) => {
          logger.error("Kunde inte hämta användarinfo", err);
          setUserInfoError(err);
        },
      });
    });
  }, [auth]);

  if (authStateInitialized && defined(userInfoError)) {
    return (
      <ThemeProvider theme={fluentUITheme}>
        <NavBarThirdPartyDoc logout={auth.logout} />
        <HttpErrorPage error={userInfoError}></HttpErrorPage>;
      </ThemeProvider>
    );
  }

  if (!authStateInitialized || !defined(userInfo)) {
    return (
      <DelayedSpinner
        className="padding-lg"
        delayShowMs={200}
        label="Laddar..."
        labelPosition="bottom"
        size={SpinnerSize.large}
      ></DelayedSpinner>
    );
  }

  return (
    <RecoilRoot>
      <ThemeProvider theme={fluentUITheme}>
        <GeographiesContext.Provider value={geographies}>
          <UserInfoContext.Provider value={userInfo}>
            <AppMessagesContext.Provider value={appMessagesHandler}>
              <OrganizationBrandingContext.Provider
                value={userInfo.organizationBranding()}
              >
                <div className="App">
                  <AppMessagesBar
                    messages={appMessages}
                    onDismiss={(id) =>
                      setAppMessages(appMessages.filter((m) => m.id !== id))
                    }
                  ></AppMessagesBar>
                  <div id="main" className="Stats">
                    {!IS_PROD && <StripedBar text="[DEV ENV]"></StripedBar>}
                    <div
                      className="dummy-font-loading"
                      style={{
                        height: 0,
                        fontSize: 2,
                        zIndex: -1,
                        opacity: 0,
                      }}
                    >
                      <p style={{ fontFamily: "Circular-Mono" }}>a</p>
                      <p style={{ fontFamily: "Circular-Medium" }}>a</p>
                      <p
                        style={{
                          fontFamily: "Circular-Regular",
                          fontWeight: "bold",
                        }}
                      >
                        a
                      </p>
                      <p style={{ fontFamily: "Circular-Regular" }}>a</p>
                    </div>
                    <Routes>
                      <Route
                        path={pathInfo.thirdPartyOrgAdmin.pathTemplate}
                        element={
                          <>
                            <NavBarThirdPartyDoc logout={auth.logout} />
                            <ThirdPartyOrgAdmin userInfo={userInfo} />
                          </>
                        }
                      />
                      <Route
                        path={pathInfo.thirdPartyDoc.pathTemplate}
                        element={<ThirdPartyDocInit logout={auth.logout} />}
                      ></Route>
                      <Route
                        path={pathInfo.secureDeliveryDoc.pathTemplate}
                        element={<SecureDeliveryDocInit logout={auth.logout} />}
                      ></Route>

                      <Route
                        path={pathInfo.thirdPartyUserDefault.pathTemplate}
                        element={
                          <>
                            <NavBarThirdPartyDoc logout={auth.logout} />
                            <ThirdPartyUserDefault />
                          </>
                        }
                      />

                      {userInfo?.isThirdPartyOrgUserOnly() && (
                        <Route path="*" element={<RedirectThirdPartyUser />} />
                      )}

                      <Route
                        path="*"
                        element={
                          <StatsRegularUser
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            auth={auth}
                          />
                        }
                      ></Route>
                    </Routes>
                  </div>
                </div>
              </OrganizationBrandingContext.Provider>
            </AppMessagesContext.Provider>
          </UserInfoContext.Provider>
        </GeographiesContext.Provider>
      </ThemeProvider>
    </RecoilRoot>
  );
}

function ThirdPartyUserDefault() {
  return (
    <MainContent className="Stats padding-lg">
      <AlertBox intent="warning">
        <>
          <h2>Oops, här var det tomt!</h2>
          <p>
            För att visa innehåll här behöver du använda en länk som skickats
            till dig.
          </p>
        </>
      </AlertBox>
    </MainContent>
  );
}

function RedirectThirdPartyUser() {
  const navigate = useNavigate();
  const navigatedOnce = useRef(false);

  useEffect(() => {
    if (navigatedOnce.current) {
      return;
    }

    const pathToRestore = extractPathToRestoreAfterAuthRedirect();
    if (defined(pathToRestore) && pathToRestore.trim() !== "/") {
      navigatedOnce.current = true;
      return navigate(pathToRestore);
    }

    navigatedOnce.current = true;
    navigate(pathInfo.thirdPartyUserDefault.pathTemplate);
  }, [navigate]);
  return <div></div>;
}

function StatsRegularUser(props: {
  userInfo?: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
  auth: Auth0ContextInterface<User>;
}) {
  const { userInfo, auth, setUserInfo } = props;
  const logout = auth.logout;

  const [
    adminShowDraftData,
    toggleAdminShowDraftDataRaw,
    setAdminShowDraftData,
  ] = useToggle(false);
  const [infostatDataAdminMode, setInfostatDataAdminMode] = useState(false);
  const toggleAdminShowDraftData = useCallback(() => {
    const nextMode = !adminShowDraftData;
    setDraftModeStored(nextMode);
    toggleAdminShowDraftDataRaw();
  }, [adminShowDraftData, toggleAdminShowDraftDataRaw]);
  const toggleDataAdminMode = useCallback(() => {
    const nextMode = !infostatDataAdminMode;
    if (!nextMode) {
      setAdminShowDraftData(false);
    }
    setInfostatDataAdminMode(nextMode);
    setDataAdminModeStored(nextMode);
  }, [infostatDataAdminMode, setAdminShowDraftData]);

  const setMetaState = useSetRecoilState(reportMetaStateQuery);

  initReleaseId();

  const handleLogout = useCallback(() => {
    setDataAdminModeStored(false);
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  useEffect(() => {
    if (!defined(userInfo)) {
      return;
    }
    setInfostatDataAdminMode(
      (getDataAdminModeStored() ?? false) && userInfo.internalMeasureAdmin()
    );
    setAdminShowDraftData(
      (getDraftModeStored() ?? false) && userInfo.internalMeasureAdmin()
    );
  }, [setAdminShowDraftData, userInfo]);

  const hideSupportBubble = useMemo(
    () => userInfo?.preferences().hideSupportBubble,
    [userInfo]
  );
  const userEmail = useMemo(() => userInfo?.email(), [userInfo]);

  useEffect(() => {
    if (!defined(userEmail)) {
      return;
    }

    updateTidioState(!hideSupportBubble, userEmail);
  }, [hideSupportBubble, userEmail, userInfo]);

  const handleUpdateUserInfo = useCallback(
    (opts: Partial<UserInfoUpdateOpts>) => {
      if (!defined(userInfo)) {
        return Promise.resolve(
          HttpResult.fromErr({
            code: "unknown-error",
            info: { type: "errorMessage", message: "userInfo missing" },
          })
        );
      }
      const args: Partial<UserInfoDto["preferences"]> = {};
      if (defined(opts.userThemes)) {
        args.user_defined_themes = opts.userThemes;
      }
      const themeIdProp = "defaultThemeId";
      if (opts.hasOwnProperty(themeIdProp)) {
        args.default_theme_id = opts[themeIdProp];
      }
      const hideSupportBubbleProp = "hideSupportBubble";
      if (opts.hasOwnProperty(hideSupportBubbleProp)) {
        args.hide_support_bubble = opts[hideSupportBubbleProp];
      }
      if (Object.keys(args).length === 0) {
        return Promise.resolve(HttpResult.fromOk(undefined));
      }
      return updateUserPreferences(args).then((res) => {
        res.match({
          ok: () => {
            const updatedUserInfo = userInfo.copyWithUserPrefs(args);
            setUserInfo(updatedUserInfo);
          },
          err: (err) => {
            logger.error("Kunde inte uppdatera användarpreferenser", err);
          },
        });
        return res;
      });
    },
    [setUserInfo, userInfo]
  );

  const handleUpdateOrgPreferences = useCallback(
    (opts: Partial<OrgPreferencesUpdateOpts>) => {
      if (!defined(userInfo)) {
        return Promise.resolve(
          HttpResult.fromErr({
            code: "unknown-error",
            info: { type: "errorMessage", message: "userInfo missing" },
          })
        );
      }
      const args: Partial<UserInfoDto["organization_preferences"]> = {};
      if (defined(opts.orgDefinedThemes)) {
        args.org_defined_themes = opts.orgDefinedThemes;
      }
      if (defined(opts.defaultThemeId)) {
        args.default_theme_id = opts.defaultThemeId;
      }

      if (Object.keys(args).length === 0) {
        return Promise.resolve(HttpResult.fromOk(undefined));
      }

      return updateOrgPreferences(args).then((res) => {
        res.match({
          ok: () => {
            const updatedUserInfo = userInfo.copyWithOrgPrefs(args);
            setUserInfo(updatedUserInfo);
          },
          err: (err) => {
            logger.error(
              "Kunde inte uppdatera organisationens preferenser",
              err
            );
          },
        });
        return res;
      });
    },
    [setUserInfo, userInfo]
  );

  const handleSaveDraft = useCallback(
    (docId: number, name: string) => {
      return patchNode(docId, { title: name, draft: false }).then((res) => {
        res.match({
          ok: () => {
            setMetaState((prev) => {
              if (!defined(prev)) {
                return;
              }
              return {
                ...prev,
                title: name,
                draft: false,
              };
            });
          },
          err: voidFunc,
        });

        return res;
      });
    },
    [setMetaState]
  );

  const navbarProps = useMemo(() => {
    return {
      toggleShowDraftData: toggleAdminShowDraftData,
      toggleDataAdminMode,
      handleSaveDraft: handleSaveDraft,
      logout: handleLogout,
    };
  }, [
    handleLogout,
    handleSaveDraft,
    toggleAdminShowDraftData,
    toggleDataAdminMode,
  ]);

  const [tutorialsHandler, setTutorialsHandler] =
    useState<TutorialsHandler | null>(null);
  const [availableTutorials, setAvailableTutorials] = useState<Tutorial[]>([]);
  const createTutorialsHandler = useCallback(() => {
    if (defined(tutorialsHandler) || !defined(userInfo)) {
      return;
    }
    setTutorialsHandler(
      new TutorialsHandler(setAvailableTutorials, userInfo.seenTutorials())
    );
  }, [tutorialsHandler, userInfo]);

  return (
    <>
      <UserInfoUpdateContext.Provider value={handleUpdateUserInfo}>
        <OrgPreferencesUpdateContext.Provider
          value={handleUpdateOrgPreferences}
        >
          <ShowDraftDataContext.Provider value={adminShowDraftData}>
            <InfostatDataAdminModeContext.Provider
              value={infostatDataAdminMode}
            >
              <TutorialsContext.Provider
                value={{
                  availableTutorials,
                  tutorialsHandler,
                  createTutorialsHandler,
                }}
              >
                <Routes>
                  {/* We need to explicitly render a normal NavBar for NewDoc, or else the newDoc path will
                        be matched by statsDocsSingle path and pick up a bad parameter. */}
                  <Route
                    path={pathInfo.newDoc.pathTemplate}
                    element={<NavBar {...navbarProps}></NavBar>}
                  ></Route>
                  <Route
                    path={pathInfo.statsDocsSingle.pathTemplate}
                    element={
                      <StatsSingleNavBar
                        navbarProps={navbarProps}
                      ></StatsSingleNavBar>
                    }
                  ></Route>
                  <Route
                    path="*"
                    element={<NavBar {...navbarProps}></NavBar>}
                  ></Route>
                </Routes>
                <StatsRouterOuter1 logout={auth.logout}></StatsRouterOuter1>
              </TutorialsContext.Provider>
            </InfostatDataAdminModeContext.Provider>
          </ShowDraftDataContext.Provider>
        </OrgPreferencesUpdateContext.Provider>
      </UserInfoUpdateContext.Provider>
    </>
  );
}

function StatsSingleNavBar(props: { navbarProps: NavbarProps }) {
  const params = useParams();
  const raw = params[pathInfo.statsDocsSingle.params.singleDocParam];
  const parsedId = defined(raw) ? parseInt(raw) : undefined;
  if (!defined(parsedId)) {
    return <DocNotFound title={getText("doc-not-found")}></DocNotFound>;
  }
  return <NavBar documentId={parsedId} {...props.navbarProps}></NavBar>;
}

function StatsRouterOuter1(props: {
  logout: Auth0ContextInterface<User>["logout"];
}) {
  const logout = props.logout;
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const categoriesLoader = useCallback(
    () =>
      getCategoriesWithCache(
        undefined,
        TimeResolution.maximal(),
        adminShowDraftData
      ),
    [adminShowDraftData]
  );
  const [categories] = useLoadableHttpResource(categoriesLoader);

  useEffect(() => {
    if (isMissingLicenseResult(categories)) {
      logout({
        logoutParams: {
          returnTo: pathJoin(
            window.origin,
            pathInfo.loggedOutMissingLicense.pathTemplate
          ),
        },
      });
    }
  }, [categories, logout]);

  return <Outer2 item={categories}></Outer2>;
}

const Outer2 = eitherDisplayer(NotReadyHttpErrFullPage, StatsRouterOuter2);
function StatsRouterOuter2(props: { item: Categories }) {
  return <StatsRouterInner categories={props.item} />;
}

function StatsRouterInner(props: { categories: Categories }) {
  const [categories, setCategories] = useState<
    HttpResult<Categories> | undefined
  >(undefined);
  const navigate = useNavigate();
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const userInfo = useContext(UserInfoContext);
  const [microPrimaryCategories, setMicroPrimaryCategories] =
    useState<Categories>({});
  const [microSecondaryCategories, setMicroSecondaryCategories] =
    useState<Categories>({});

  useEffect(() => {
    const handle = setInterval(() => {
      shouldReloadClientApp().then((shouldReload) => {
        if (shouldReload) {
          logger.warn("Client app is outdated");
        }
      });
    }, Milliseconds.minute * 2);
    return () => {
      clearInterval(handle);
    };
  }, []);

  useEffect(() => {
    const path = extractPathToRestoreAfterAuthRedirect();
    if (defined(path)) {
      navigate(path);
    }
  });

  useEffect(() => {
    if (!defined(userInfo) || !userInfo.hasMicroReadAccess()) {
      return;
    }

    getMicroCategoriesWithCache(
      adminShowDraftData,
      [],
      ["line", "point", "polygon"]
    ).then((res) =>
      res.match({
        ok: (cats) => setMicroSecondaryCategories(cats),
        err: (err) => logger.error("Kunde inte hämta mikrokategorier", err),
      })
    );
    getMicroCategoriesWithCache(adminShowDraftData, [], []).then((res) =>
      res.match({
        ok: (cats) => setMicroPrimaryCategories(cats),
        err: (err) => logger.error("Kunde inte hämta mikrokategorier", err),
      })
    );
  }, [adminShowDraftData, userInfo]);

  useEffect(() => {
    getCategoriesWithCache(
      undefined,
      TimeResolution.maximal(),
      adminShowDraftData
    ).then((cats) => {
      setCategories(cats);
    });
  }, [adminShowDraftData]);

  if (!defined(categories)) {
    return (
      <MainContent className="Stats padding-lg">
        <DelayedSpinner
          delayShowMs={500}
          size={SpinnerSize.large}
        ></DelayedSpinner>
      </MainContent>
    );
  }

  const categoriesResult = categories.switch();
  if (categoriesResult.type === "err") {
    return <HttpErrorPage error={categoriesResult.error}></HttpErrorPage>;
  }

  return (
    <CategoriesContext.Provider value={categoriesResult.data}>
      <CategoriesContextMicroPrimaryContext.Provider
        value={microPrimaryCategories}
      >
        <CategoriesContextMicroSecondaryContext.Provider
          value={microSecondaryCategories}
        >
          <Routes>
            <Route
              path="/lab/*"
              element={<ViewlabLoader></ViewlabLoader>}
            ></Route>
            <Route
              path={pathInfo.userSettings.pathTemplate + "/*"}
              element={<UserSettings></UserSettings>}
            ></Route>
            <Route
              path={pathInfo.newFromSingleMeasure.pathTemplate}
              element={<NewFromSingleMeasure></NewFromSingleMeasure>}
            ></Route>
            <Route
              path={pathInfo.newCopy.pathTemplate}
              element={<NewCopyDraft></NewCopyDraft>}
            ></Route>
            <Route
              path={pathInfo.newCopySaved.pathTemplate}
              element={<NewCopySaved></NewCopySaved>}
            ></Route>
            <Route
              path={pathInfo.singleMeasure.pathTemplate}
              element={<SingleMeasure></SingleMeasure>}
            ></Route>
            <Route
              path={pathInfo.newDoc.pathTemplate}
              element={<NewDoc></NewDoc>}
            ></Route>
            <Route
              path={pathInfo.newFromSharedDoc.pathTemplate}
              element={<NewFromSharedDoc></NewFromSharedDoc>}
            ></Route>
            <Route
              path={pathInfo.statsDocsSingle.pathTemplate}
              element={<StatsDocSingle></StatsDocSingle>}
            ></Route>
            <Route
              path={pathInfo.shareLink.pathTemplate}
              element={<ShareLink></ShareLink>}
            ></Route>
            <Route
              path={pathInfo.statsDocsOverview.pathTemplate}
              element={<StatsDocsOverview></StatsDocsOverview>}
            ></Route>
            <Route
              path={pathInfo.dataAdminHome.pathTemplate + "/*"}
              element={<DataAdminHome></DataAdminHome>}
            ></Route>
            <Route
              path="admin-portal/*"
              element={<AdminPortalLoader />}
            ></Route>
            <Route
              path="/"
              element={<Navigate to={statsOverviewPath()}></Navigate>}
            ></Route>
          </Routes>
        </CategoriesContextMicroSecondaryContext.Provider>
      </CategoriesContextMicroPrimaryContext.Provider>
    </CategoriesContext.Provider>
  );
}

function PackagedDocument() {
  const params = useParams();
  const docId = params[pathInfo.packagedDoc.params.singleDocParam];
  if (!defined(docId)) {
    return <DocNotFound title={getText("doc-not-found")}></DocNotFound>;
  }
  return (
    <PackagedDocErrorBoundary>
      <RecoilRoot>
        <StatsDocumentPackaged documentId={docId} />
      </RecoilRoot>
    </PackagedDocErrorBoundary>
  );
}

function ThirdPartyDocInit(props: {
  logout: Auth0ContextInterface<User>["logout"];
}) {
  const params = useParams();
  const docId = params[pathInfo.thirdPartyDoc.params.singleDocParam];
  if (!defined(docId)) {
    return <DocNotFound title={getText("doc-not-found")}></DocNotFound>;
  }

  return <MemberOrgDoc docId={docId} logout={props.logout} />;
}

function SecureDeliveryDocInit(props: {
  logout: Auth0ContextInterface<User>["logout"];
}) {
  const params = useParams();
  const docId = params[pathInfo.secureDeliveryDoc.params.singleDocParam];
  if (!defined(docId)) {
    return <DocNotFound title={getText("doc-not-found")}></DocNotFound>;
  }

  return <SecureDeliveryDoc docId={docId} logout={props.logout} />;
}

function EmbeddedCard() {
  const params = useParams();
  const embedKey = params[pathInfo.embeddedCard.params.embedKey];
  const [geographies, setGeographies] = useState<
    GeographiesSerializable | undefined
  >();

  const [geoLoadError, setGeoLoadError] = useState<HttpError>();

  useEffect(() => {
    if (!defined(embedKey)) {
      return;
    }
    getGeographicRegionsWithCache().then((res) => {
      res.match({
        ok: (geos) => {
          setGeographies(geos);
        },
        err: (err) => {
          logger.error("Kunde inte hämta geografiska områden", err);
          setGeoLoadError(err);
        },
      });
    });
  }, [embedKey]);

  if (window.location.protocol === "file:") {
    return (
      <AlertBox intent="warning">
        <p>Kan endas visas på originalwebbplatsen</p>
      </AlertBox>
    );
  }

  if (!defined(embedKey)) {
    return <DocNotFound title={getText("doc-not-found")}></DocNotFound>;
  }

  if (defined(geoLoadError)) {
    return <HttpErrorNotice error={geoLoadError}></HttpErrorNotice>;
  }

  setEmbeddedFrameMode(embedKey);
  return (
    <GeographiesContext.Provider value={geographies}>
      <EmbedWrapper embedKey={embedKey}></EmbedWrapper>
    </GeographiesContext.Provider>
  );
}

function StatsDocsOverview() {
  const raw = getQueryParam(
    pathInfo.statsDocsOverview.queryParams.openSharedFolder
  );
  const openSharedFolder = defined(raw) ? parseInt(raw) : undefined;

  return (
    <DocumentsOverview openSharedFolder={openSharedFolder}></DocumentsOverview>
  );
}

function ShareLink() {
  const params = useParams();
  const linkCode = params[pathInfo.shareLink.params.linkParam];
  if (!defined(linkCode)) {
    return <DocNotFound title={getText("doc-not-found")}></DocNotFound>;
  }
  return <StatsShareLink linkCode={linkCode}></StatsShareLink>;
}

/** The primary view for documents */
function StatsDocSingle(props: {}) {
  const params = useParams();
  const raw = params[pathInfo.statsDocsSingle.params.singleDocParam];
  const parsedId = defined(raw) ? parseInt(raw) : undefined;
  if (!defined(parsedId)) {
    return <DocNotFound title={getText("doc-not-found")}></DocNotFound>;
  }
  return <StatsDocument documentId={parsedId}></StatsDocument>;
}

function NewFromSharedDoc() {
  const params = useParams();
  const sharedDocLink = params[pathInfo.newFromSharedDoc.params.sharedDocParam];
  if (!defined(sharedDocLink)) {
    return <DocNotFound title={getText("doc-not-found")}></DocNotFound>;
  }
  return (
    <StatsNewFromSharedDoc shareCode={sharedDocLink}></StatsNewFromSharedDoc>
  );
}

function NewFromSingleMeasure() {
  const params = useParams();
  const measureIdRaw =
    params[pathInfo.newFromSingleMeasure.params.singleMeasureParam];
  const measureId = defined(measureIdRaw) ? parseInt(measureIdRaw) : undefined;
  const name =
    getQueryParam(pathInfo.newFromSingleMeasure.queryParams.name) ??
    getText("default-document-name");
  const geographies = useContext(GeographiesContext);

  if (!defined(measureId)) {
    return <DocNotFound title={getText("doc-not-found")}></DocNotFound>;
  }
  if (!defined(geographies)) {
    return <DataLoadErrorView error="unknown-error"></DataLoadErrorView>;
  }

  return (
    <StatsSingleMeasureNewDoc
      name={name}
      measureId={measureId}
      geographies={geographies}
    ></StatsSingleMeasureNewDoc>
  );
}

function SingleMeasure() {
  const params = useParams();
  const measureIdRaw = params[pathInfo.singleMeasure.params.singleMeasureParam];
  const measureId = defined(measureIdRaw) ? parseInt(measureIdRaw) : undefined;
  const geographies = useContext(GeographiesContext);
  const appearanceSettings = useExtendedAppearanceSettings();
  if (!defined(measureId)) {
    return <DocNotFound title={getText("doc-not-found")}></DocNotFound>;
  }
  if (!defined(geographies)) {
    return <DataLoadErrorView error="unknown-error"></DataLoadErrorView>;
  }

  return (
    <StatsSingleMeasure
      measureId={measureId}
      geographies={geographies}
      defaultPalette={appearanceSettings.defaultTheme}
    ></StatsSingleMeasure>
  );
}

function NewCopySaved() {
  return NewCopyInner({ draft: false });
}
function NewCopyDraft() {
  return NewCopyInner({ draft: true });
}
function NewCopyInner(props: { draft: boolean }) {
  const params = useParams();
  const title = getQueryParam(pathInfo.newCopy.queryParams.title);
  const originalDocIdRaw = params[pathInfo.newCopy.params.singleDocParam];
  const originalDocId = defined(originalDocIdRaw)
    ? parseInt(originalDocIdRaw)
    : undefined;
  if (!defined(originalDocId)) {
    return <DocNotFound title={getText("doc-not-found")}></DocNotFound>;
  }
  return (
    <StatsNewCopy
      draft={props.draft}
      fromCopyId={originalDocId}
      newTitle={title}
    ></StatsNewCopy>
  );
}

function NewDoc() {
  const title = getQueryParam(pathInfo.newDoc.queryParams.title);
  const parentFolderId = getQueryParam(
    pathInfo.newDoc.queryParams.parentFolderId
  );
  return (
    <StatsNewDoc
      newTitle={title}
      parentFolderId={
        nonEmptyString(parentFolderId) ? parseInt(parentFolderId) : undefined
      }
    ></StatsNewDoc>
  );
}

function ViewlabLoader() {
  let [Comp, setComp] = useState<
    typeof import("./view_lab/view_lab")["ViewLabMain"] | undefined
  >();

  useEffect(() => {
    import("./view_lab/view_lab").then((module) => {
      setComp((prev) => module.ViewLabMain);
    });
  }, []);

  if (!defined(Comp)) {
    return <DefaultLoading></DefaultLoading>;
  }

  return <Comp></Comp>;
}

function AdminPortalLoader() {
  let [Comp, setComp] = useState<
    | typeof import("./admin/views_admin_portal/AdminPortalHome")["AdminPortalHome"]
    | undefined
  >();

  useEffect(() => {
    import("./admin/views_admin_portal/AdminPortalHome").then((module) => {
      setComp((prev) => module.AdminPortalHome);
    });
  }, []);

  if (!defined(Comp)) {
    return <DefaultLoading></DefaultLoading>;
  }

  return <Comp></Comp>;
}
