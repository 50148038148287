/**
 * Time selection slider that works with Micro cards and Stats (data) cards.
 */

import { useCallback, useContext, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import {
  TimespanSliderRange,
  TimespanSliderSingle,
} from "../../../../../components/TimespanSlider";
import { useLocallyMirroredInputState } from "../../../../../lib/application/hooks/useLocallyMirroredInputState";
import {
  availableDatesQuery,
  availableTimespanQuery,
  lockToLatestTimeQuery,
  timeResolutionQuery,
  timeSelectionModeQuery,
  timeSelectionQuery,
} from "../../../../../lib/application/state/stats/document-core/queries/generalDataCard";
import { SelectableTime } from "../../../../../lib/application/state/stats/document-core/queries/shared";
import { defined } from "../../../../../lib/core/defined";
import { last } from "../../../../../lib/core/last";
import { utcTimeStringToDate } from "../../../../../lib/core/time";
import { dateRangeToRaw } from "../../../../../lib/domain/measure";
import { DateRangeRaw } from "../../../../../lib/domain/measure/definitions";
import {
  TimeResolution,
  getDateFormatter,
  DEFAULT_TIME_SPAN_SELECTION,
} from "../../../../../lib/domain/time";
import { Checkbox } from "@fluentui/react";
import { AppMode } from "../../../../../config";
import {
  AppMessagesContext,
  DataPreviewContext,
} from "../../../../../lib/application/contexts";
import { isEqual, throttle } from "lodash";
import { useChangeDataOutputSettings } from "../../../../../lib/application/state/actions/selections/useChangeDataOutputSettings";

type SliderRange = [number, number];

interface TimeSettings {
  numberRange: [number, number];
  dates: Date[];
  resolution: TimeResolution;
  format: (d: Date) => string;
}

export function getTimeSettings(
  timespan: SelectableTime,
  resolutionRaw: string,
  specificAvailableDates?: string[]
): TimeSettings | undefined {
  const resolution = TimeResolution.deserialize(resolutionRaw);
  const format = getDateFormatter(resolution);
  if (defined(specificAvailableDates) && specificAvailableDates.length > 0) {
    return {
      numberRange: [0, specificAvailableDates.length - 1],
      dates: specificAvailableDates.map((d) => utcTimeStringToDate(d)),
      resolution,
      format,
    };
  }

  if (timespan.type === "range") {
    const start = utcTimeStringToDate(timespan.range[0]);
    const end = utcTimeStringToDate(timespan.range[1]);
    const dates: Date[] = resolution.filledRangeInclusive(start, end)!;
    return {
      numberRange: [0, dates!.length - 1],
      dates,
      resolution,
      format,
    };
  }
  if (timespan.timePoints.length === 0) {
    return undefined;
  }
  const start = utcTimeStringToDate(timespan.timePoints[0]);
  const lastTimePoint = last(timespan.timePoints);
  if (!defined(lastTimePoint)) {
    throw new Error("Failed to get available time span");
  }
  const end = utcTimeStringToDate(lastTimePoint);
  const dates: Date[] = resolution.filledRangeInclusive(start, end)!;
  return {
    numberRange: [0, dates!.length - 1],
    dates,
    resolution,
    format,
  };
}

export function timespanToSliderRange(
  selectedTimespan: DateRangeRaw,
  timeSettings: TimeSettings
): SliderRange {
  const start = utcTimeStringToDate(selectedTimespan[0]);
  const end = utcTimeStringToDate(selectedTimespan[1]);
  if (start === null || end === null) {
    throw new Error("Failed to parse");
  }
  const dates = timeSettings.dates;
  return selectedTimespan.map((selectedDateRaw) => {
    const selectedDate = utcTimeStringToDate(selectedDateRaw);
    return dates.findIndex((d) => d.getTime() === selectedDate.getTime());
  }) as [number, number];
}

interface Props {
  cardStateId: string;
  /** If set to embedded, the option to automatically update time to latest available will be hidden */
  appMode: AppMode;
  updateCardStateWithTimeRange: (range: DateRangeRaw) => void;
  unsetLockToLatestTime: () => void;
  setLockToLatestTime: () => void;
  disabled?: boolean;
}
export function TimeSelect(props: Props) {
  const queryProps = { cardStateId: props.cardStateId };
  const {
    updateCardStateWithTimeRange,
    unsetLockToLatestTime,
    setLockToLatestTime,
  } = props;

  const timeSelectionMode = useRecoilValue(timeSelectionModeQuery(queryProps));
  const isDataPreview = useContext(DataPreviewContext);

  const availableTimespan = useRecoilValue(availableTimespanQuery(queryProps));
  const resolutionRaw = useRecoilValue(timeResolutionQuery(queryProps));
  const availableDates = useRecoilValue(availableDatesQuery(queryProps));
  const timeSettings =
    defined(resolutionRaw) && defined(availableTimespan)
      ? getTimeSettings(availableTimespan, resolutionRaw, availableDates)
      : undefined;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [settings, _setSettings] = useChangeDataOutputSettings(
    props.cardStateId
  );

  const [lockToLatest, setLockToLatest] = useRecoilState(
    lockToLatestTimeQuery(queryProps)
  );

  const fetchData = useCallback(
    (prev: DateRangeRaw | undefined, current: DateRangeRaw | undefined) => {
      if (!defined(current) || !defined(timeSettings)) {
        return;
      }

      if (isEqual(prev, current)) {
        return;
      }

      return updateCardStateWithTimeRange(current);
    },
    [timeSettings, updateCardStateWithTimeRange]
  );

  /**
   * The local timespan is only used temporarily while adjusting the timeline. After a certain timeout
   * the change is propagated (1) to global state, and the local timespan is cleared (2).
   */
  const [localTimespan, setLocalTimespan] = useLocallyMirroredInputState(
    timeSelectionQuery(queryProps),
    { updateDelayMs: 500, afterSetGlobal: fetchData }
  );

  const handleToggleLockToLatestAndUpdateTime = useCallback(
    (locked: boolean) => {
      if (!defined(availableTimespan)) {
        return;
      }
      setLockToLatest(locked);
      switch (locked) {
        case false:
          return unsetLockToLatestTime();
        case true: {
          const start = localTimespan?.[0];
          const useSinglePoint = defined(start) && start === localTimespan?.[1];
          if (availableTimespan.type === "range") {
            if (!defined(start)) {
              return;
            }
            setLocalTimespan(
              useSinglePoint
                ? [availableTimespan.range[1], availableTimespan.range[1]]
                : [start, availableTimespan.range[1]]
            );
          } else if (availableTimespan.type === "time-points") {
            const lastTimePoint = last(availableTimespan.timePoints);
            if (!defined(lastTimePoint)) {
              return;
            }
            setLocalTimespan(
              useSinglePoint
                ? [lastTimePoint, lastTimePoint]
                : [lastTimePoint, lastTimePoint]
            );
          }
        }
      }
    },
    [
      availableTimespan,
      localTimespan,
      setLocalTimespan,
      setLockToLatest,
      unsetLockToLatestTime,
    ]
  );

  const sliderRange = defined(timeSettings)
    ? timeSettings.numberRange
    : DEFAULT_TIME_SPAN_SELECTION;

  const selectedSliderRange = useMemo(() => {
    if (!defined(localTimespan) || !defined(timeSettings)) {
      return undefined;
    }
    return timespanToSliderRange(localTimespan, timeSettings);
  }, [localTimespan, timeSettings]);
  const appMessagesHandler = useContext(AppMessagesContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const addForecastTimeWarning = useCallback(
    throttle(
      () => {
        appMessagesHandler?.add(
          "warning",
          "Vid trendframskrivning måste tidsintervallet inkludera senaste tillgängliga tidpunkt."
        );
      },
      3000,
      { leading: true, trailing: false }
    ),
    []
  );

  return (
    <div className="time-select content-padding">
      {timeSelectionMode === "range" ? (
        <TimespanSliderRange
          disabled={!defined(timeSettings) || props.disabled === true}
          format={(val) => {
            if (!defined(timeSettings)) {
              return val + "";
            }
            return timeSettings.format(timeSettings.dates[val]);
          }}
          min={sliderRange[0]}
          max={sliderRange[1]}
          selectedRange={selectedSliderRange}
          setTimeRange={(range) => {
            if (!defined(timeSettings)) {
              return;
            }
            if (
              settings.forecast.show &&
              range[1] !== timeSettings.dates.length - 1
            ) {
              addForecastTimeWarning();
              return;
            }

            const newValue: [Date, Date] = [
              timeSettings.dates[range[0]],
              timeSettings.dates[range[1]],
            ];
            setLocalTimespan(dateRangeToRaw(newValue));
          }}
        ></TimespanSliderRange>
      ) : (
        <TimespanSliderSingle
          disabled={!defined(timeSettings) || props.disabled === true}
          format={(val) => {
            if (!defined(timeSettings)) {
              return val + "";
            }
            return timeSettings.format(timeSettings.dates[val]);
          }}
          min={sliderRange[0]}
          max={sliderRange[1]}
          selectedValue={
            defined(localTimespan) && defined(timeSettings)
              ? timespanToSliderRange(localTimespan, timeSettings)[0]
              : undefined
          }
          setTimeValue={(value) => {
            if (!defined(timeSettings)) {
              return;
            }
            const newValue: [Date, Date] = [
              timeSettings.dates[value],
              timeSettings.dates[value],
            ];
            setLocalTimespan(dateRangeToRaw(newValue));
          }}
        ></TimespanSliderSingle>
      )}
      {props.appMode !== "embedded" && !isDataPreview && (
        <Checkbox
          label="Lås till senaste"
          checked={lockToLatest}
          onChange={(ev, checked) => {
            if (!defined(checked)) {
              return;
            }
            const lastAvailableDate = last(availableDates ?? []);
            if (checked && lastAvailableDate === localTimespan?.[1]) {
              return setLockToLatestTime();
            }
            handleToggleLockToLatestAndUpdateTime(checked);
          }}
        />
      )}
    </div>
  );
}
