import { chain, flatMap, uniqBy } from "lodash";
import { v4 as uuidv4 } from "uuid";
import mapbox from "mapbox-gl";
import { useCallback, useEffect, useState } from "react";

import {
  CustomRegion,
  DesoProperties,
  MicroGeoSelections,
  MicroMapSettings,
  MicroMapView,
  RegsoProperties,
  SelectedAreaDesoMode,
  SelectedAreaRegsoMode,
  SelectedCustomRegion,
  SelectedDesoArea,
  SelectedRegsoArea,
} from "../../../../../lib/application/state/stats/document-core/core-micro";
import { MicroDataset } from "../../../../../lib/application/stats/datasets/MicroDataset";
import { assertNever } from "../../../../../lib/core/assert";
import { defined } from "../../../../../lib/core/defined";
import { InfostatBoundingBox } from "../../../../../lib/domain/cartography/types";
import { HttpResult } from "../../../../../lib/infra/HttpResult";
import {
  HighlightedResult,
  getHightlightedResult,
  makeHoverPopup,
} from "./components/HoverResult";
import {
  ColorBag,
  mapRemoveFeatureState,
  mapSetDesoFeatureColor,
  mapSetFeatureSelected,
} from "./shared";
import { GeoState } from "./useGeoState";
import { config } from "../../../../../config";
import { getText } from "../../../../../lib/application/strings";
import { GeoTypeMicro } from "../../../../../lib/domain/geography";
import { useUserEvents } from "./useUserEvents";
import { MicroGeoTree } from "../../../../../lib/application/stats/shared/MicroGeoTree";

// Selection logic
// When using box selection, old selections are cleared
// When using pointer selection, selections are added to old selections
// When using groups:
//  - if deselecting a region within the group, the group is deselected
//  - regions within an active group cannot at the same time be added to a different group
// When adding/removing regions/groups using geopanel, old selections are kept

const pixelTolerance = 20;

export interface PointerResult {
  position: mapbox.Point;
  lngLat: mapbox.LngLat;

  properties:
    | { type: "deso"; data: DesoProperties }
    | { type: "regso"; data: RegsoProperties }
    | {
        type: "geo";
        data: {
          label?: string;
          infoLines?: string[];
          value?: string;
          unit?: string;
        };
      };
}

// Set up map drawing/box selection
export function useGeoPanelActions(
  view: MicroMapView,
  map: mapbox.Map | undefined,
  mapSettings: MicroMapSettings,
  geoState: GeoState,
  microDataset: MicroDataset | undefined,
  colorBag: ColorBag,
  saveMapLocation: (box: InfostatBoundingBox) => void,
  geoTree: MicroGeoTree | undefined,
  geoLayerIds: string[]
) {
  const {
    userDefinedRegions,
    selectedAreas,
    setSelectedAreas,
    addUserDefinedRegion,
    removeUserDefinedRegion,
    userDefinedRegionsToggle,
    handleEditUserDefinedRegionName,
  } = geoState;
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showUserDefinedRegions,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toggleShowUserDefinedRegions,
    setShowUserDefinedRegions,
  ] = userDefinedRegionsToggle;
  const [highlightedResult, setHighlightedResult] =
    useState<HighlightedResult>();
  const geotype = selectedAreas?.type;

  const handleClearGeos = useCallback(
    (selection: MicroGeoSelections) => {
      const geoType = selection.type;
      const selected = selection.selected;
      for (const area of selected) {
        if (!defined(map)) {
          break;
        }
        if (area.type === "deso" || area.type === "regso") {
          const id = area.props.id;
          mapRemoveFeatureState(area.type, map, id);
        } else if (area.type === "user-defined") {
          for (const { id } of area.props) {
            mapRemoveFeatureState(selection.type, map, id);
          }
        } else {
          assertNever(area);
        }
      }
      setSelectedAreas(() => {
        return { type: geoType, selected: [] };
      });
    },
    [map, setSelectedAreas]
  );

  const handleMapUnselectCustomRegions = useCallback(
    (regionId: string) => {
      if (!defined(selectedAreas) || !defined(map)) {
        return;
      }
      for (const area of selectedAreas.selected ?? []) {
        if (area.type === "user-defined" && regionId === area.groupId) {
          for (const { id } of area.props) {
            mapRemoveFeatureState(selectedAreas.type, map, id);
          }
        }
      }
    },
    [map, selectedAreas]
  );

  const handleSaveRegion: (id: string) => Promise<HttpResult<unknown>> =
    useCallback((regionId: string) => {
      return Promise.resolve(HttpResult.fromErr({ code: "not-found" }));
    }, []);

  const handleDeleteLocalRegion = useCallback(
    (regionId: string) => {
      handleMapUnselectCustomRegions(regionId);
      removeUserDefinedRegion(regionId);
    },
    [handleMapUnselectCustomRegions, removeUserDefinedRegion]
  );
  const handleDeleteSavedRegion: (id: string) => Promise<HttpResult<unknown>> =
    useCallback((regionId: string) => {
      return Promise.resolve(HttpResult.fromErr({ code: "not-found" }));
    }, []);

  const handleHoverResult = useCallback(
    (hoverResult?: PointerResult) => {
      if (highlightedResult?.type === "fixed") {
        return;
      }
      if (!defined(hoverResult)) {
        setHighlightedResult(undefined);
        return;
      }

      if (
        highlightedResult?.type === "hover" &&
        positionsClose(highlightedResult.position, hoverResult.position)
      ) {
        return;
      }

      if (hoverResult.properties.type === "geo") {
        return setHighlightedResult({
          type: "hover",
          lngLat: hoverResult.lngLat,
          position: hoverResult.position,
          data: hoverResult.properties.data,
        });
      }

      const datasetMatch = getHightlightedResult(
        "hover",
        hoverResult,
        microDataset
      );
      if (!defined(datasetMatch)) {
        setHighlightedResult(undefined);
        return;
      }
      setHighlightedResult(datasetMatch);
    },
    [highlightedResult, microDataset]
  );

  const handleClickResult = useCallback(
    (hoverResult?: PointerResult) => {
      if (!defined(hoverResult) || highlightedResult?.type === "fixed") {
        setHighlightedResult(undefined);
        return;
      }
      if (hoverResult.properties.type === "geo") {
        return setHighlightedResult({
          type: "fixed",
          data: hoverResult.properties.data,
          position: hoverResult.position,
          lngLat: hoverResult.lngLat,
        });
      }

      const datasetMatch = getHightlightedResult(
        "fixed",
        hoverResult,
        microDataset
      );
      if (!defined(datasetMatch)) {
        setHighlightedResult(undefined);
        return;
      }
      setHighlightedResult(datasetMatch);
    },
    [highlightedResult?.type, microDataset]
  );

  const handleBoxSelect = useCallback(
    (propertiesArray: DesoProperties[] | RegsoProperties[]) => {
      setSelectedAreas((prev) => {
        if (!defined(prev)) {
          throw new Error("MicroGeoSelections is undefined");
        }
        const uniqNew = uniqBy(propertiesArray, (a) => a.id);
        if (prev.type === "deso") {
          const allSelected = collectAllSelected(
            prev.selected,
            uniqNew as DesoProperties[],
            (newItem) => {
              const alreadyExists = prev.selected.some((oldSelected) => {
                if (
                  oldSelected.type === "deso" &&
                  oldSelected.props.id === newItem.id
                ) {
                  return true;
                }
                if (
                  oldSelected.type === "user-defined" &&
                  oldSelected.props.some((old) => old.id === newItem.id)
                ) {
                  return true;
                }

                return false;
              });
              return alreadyExists;
            },
            (newItem) => {
              return { type: "deso", props: newItem } as const;
            }
          );
          return { type: prev.type, selected: allSelected };
        } else if (prev.type === "regso") {
          const allSelected = collectAllSelected(
            prev.selected,
            uniqNew as RegsoProperties[],
            (newItem) => {
              const alreadyExists = prev.selected.some((oldSelected) => {
                if (
                  oldSelected.type === "regso" &&
                  oldSelected.props.id === newItem.id
                ) {
                  return true;
                }
                if (
                  oldSelected.type === "user-defined" &&
                  oldSelected.props.some((old) => old.id === newItem.id)
                ) {
                  return true;
                }

                return false;
              });
              return alreadyExists;
            },
            (newItem) => {
              return { type: "regso", props: newItem } as const;
            }
          );
          return { type: prev.type, selected: allSelected };
        }
        return prev;
      });
    },
    [setSelectedAreas]
  );

  const numSelectedAreas = selectedAreas?.selected.length ?? 0;
  const handleSelectAllInMunicipality = useCallback(
    (currentGeotype: GeoTypeMicro, municipality: string) => {
      if (!defined(geoTree)) {
        return;
      }

      const units =
        currentGeotype === "deso"
          ? geoTree.allDesoInMunicipality(municipality)
          : geoTree.allRegsoInMunicipality(municipality);
      if (
        numSelectedAreas + units.length >
        config.micro.maxNumFeaturesSelected
      ) {
        return window.alert(getText("micro-too-many-features-selected"));
      }
      handleBoxSelect(units);
    },
    [geoTree, handleBoxSelect, numSelectedAreas]
  );

  const handleSelectAllInRegion = useCallback(
    (currentGeotype: GeoTypeMicro, region: string) => {
      if (!defined(geoTree)) {
        return;
      }

      const units =
        currentGeotype === "deso"
          ? geoTree.allDesoInRegion(region)
          : geoTree.allRegsoInRegion(region);
      if (
        numSelectedAreas + units.length >
        config.micro.maxNumFeaturesSelected
      ) {
        return window.alert(getText("micro-too-many-features-selected"));
      }
      handleBoxSelect(units);
    },
    [geoTree, handleBoxSelect, numSelectedAreas]
  );

  const handlePointerSelect = useCallback(
    (properties: DesoProperties | RegsoProperties) => {
      if (!defined(map) || !defined(geotype)) {
        return;
      }
      if (numSelectedAreas + 1 > config.micro.maxNumFeaturesSelected) {
        return window.alert(getText("micro-too-many-features-selected"));
      }
      mapSetFeatureSelected(map, geotype, properties.id);
      setSelectedAreas((prevSelected?: MicroGeoSelections) => {
        if (!defined(prevSelected)) {
          throw new Error("MicroGeoSelections not defined");
        }
        if (prevSelected.type === "deso") {
          const area: SelectedDesoArea = {
            type: "deso",
            props: properties as DesoProperties,
          };
          const all: SelectedAreaDesoMode[] = [...prevSelected?.selected, area];
          return {
            type: "deso",
            selected: uniqBy(all, (a) =>
              a.type === "deso" ? a.props.id : a.groupId
            ),
          };
        } else if (prevSelected.type === "regso") {
          const area: SelectedRegsoArea = {
            type: "regso",
            props: properties as RegsoProperties,
          };
          const all: SelectedAreaRegsoMode[] = [
            ...prevSelected?.selected,
            area,
          ];
          return {
            type: "regso",
            selected: uniqBy(all, (a) =>
              a.type === "regso" ? a.props.id : a.groupId
            ),
          };
        }
        return prevSelected;
      });
    },
    [geotype, map, numSelectedAreas, setSelectedAreas]
  );

  const handleSelectUserDefinedRegion = useCallback(
    (groupId: string) => {
      const region = userDefinedRegions.find((r) => r.id === groupId);
      if (!defined(region) || !defined(map)) {
        return;
      }
      const color = colorBag.pick();
      for (const feature of region.propertiesArray) {
        mapSetDesoFeatureColor(map, feature.id, color);
      }

      setSelectedAreas((prev) => {
        if (!defined(prev)) {
          throw new Error("MicroGeoSelections is undefined");
        }
        const selected = [
          ...(prev?.selected ?? []),
          {
            type: "user-defined",
            color,
            groupId: region.id,
            groupName: region.name,
            props: region.propertiesArray,
          },
        ];
        return { type: prev.type, selected } as MicroGeoSelections;
      });
    },
    [colorBag, map, setSelectedAreas, userDefinedRegions]
  );

  const handleDeselectSingleUnit = useCallback(
    (id: number, selection: MicroGeoSelections) => {
      const prevSelected = selection.selected;
      const containedInGroup = prevSelected.some(
        (a) => a.type === "user-defined" && a.props.some((p) => p.id === id)
      );
      if (defined(map) && !containedInGroup) {
        mapRemoveFeatureState(selection.type, map, id);
      }

      setSelectedAreas((prev) => {
        if (!defined(prev)) {
          throw new Error("MicroGeoSelections not defined");
        }
        if (prev.type === "deso") {
          return {
            type: selection.type,
            selected: prev?.selected.filter((a) => {
              if (a.type === "deso") {
                return a.props.id !== id;
              }
              return true;
            }),
          } as MicroGeoSelections;
        } else if (prev.type === "regso") {
          return {
            type: selection.type,
            selected: prev?.selected.filter((a) => {
              if (a.type === "regso") {
                return a.props.id !== id;
              }
              return true;
            }),
          } as MicroGeoSelections;
        }
        assertNever(prev);
      });
    },
    [map, setSelectedAreas]
  );

  const handleDeselectGroup = useCallback(
    (groupId: string) => {
      setSelectedAreas((prev) => {
        if (!defined(prev)) {
          throw new Error("MicroGeoSelections not defined");
        }

        if (prev.type === "deso") {
          const group = prev?.selected.find(
            (area) => area.type === "user-defined" && area.groupId === groupId
          );
          if (!defined(group) || group.type !== "user-defined") {
            return prev;
          }
          if (defined(map)) {
            handleDeselectGroupDesoMode(map, group, prev.selected);
          }
          return {
            type: prev.type,
            selected: prev.selected.filter((a) => {
              if (a.type === "user-defined") {
                return a.groupId !== groupId;
              }
              return true;
            }),
          };
        } else if (prev.type === "regso") {
          const group = prev?.selected.find(
            (area) => area.type === "user-defined" && area.groupId === groupId
          );
          if (!defined(group) || group.type !== "user-defined") {
            return prev;
          }
          if (defined(map)) {
            handleDeselectGroupRegsoMode(map, group, prev.selected);
          }
          return {
            type: prev.type,
            selected: prev.selected.filter((a) => {
              if (a.type === "user-defined") {
                return a.groupId !== groupId;
              }
              return true;
            }),
          };
        }

        return prev;
      });
    },
    [map, setSelectedAreas]
  );

  /**
   * Create and select a user-defined region
   */
  const handleCreateRegion = useCallback(
    (areas: MicroGeoSelections) => {
      if (areas.type !== "deso") {
        throw new Error("Only deso mode is supported"); // FIXME
      }
      const selected = flatMap(areas.selected, (a) =>
        a.type === "deso" ? a.props : a.props
      );
      if (selected.length === 0) {
        return;
      }

      const newGroup: CustomRegion = {
        name: makeUnusedName(userDefinedRegions.map((g) => g.name)),
        id: uuidv4(),
        propertiesArray: selected,
      };
      setShowUserDefinedRegions(true);
      addUserDefinedRegion(newGroup);

      // Empty selected areas. When creating regions, the user will probably want to create at least one more region.
      handleClearGeos(areas);
    },
    [
      addUserDefinedRegion,
      handleClearGeos,
      setShowUserDefinedRegions,
      userDefinedRegions,
    ]
  );

  // Add hover popup
  useEffect(() => {
    if (!defined(map) || !defined(highlightedResult) || view !== "map-view") {
      return;
    }
    const popup = makeHoverPopup(highlightedResult)?.addTo(map);
    return () => {
      popup?.remove();
    };
  }, [highlightedResult, map, view]);

  useUserEvents(
    view,
    geoLayerIds,
    map,
    mapSettings,
    selectedAreas,
    handleHoverResult,
    handleClickResult,
    handleBoxSelect,
    handlePointerSelect,
    handleDeselectSingleUnit,
    saveMapLocation
  );

  return {
    handleSelectAllInMunicipality,
    handleSelectAllInRegion,
    handleSaveRegion,
    handleDeleteLocalRegion,
    handleDeleteSavedRegion,
    handleEditUserDefinedRegionName,
    handleCreateRegion,
    handlePointerSelect,
    handleSelectUserDefinedRegion,
    handleBoxSelect,
    handleDeselectSingleDeso: handleDeselectSingleUnit,
    handleDeselectGroup,
    handleClearGeos,
  };
}

function positionsClose(left: mapboxgl.Point, right: mapboxgl.Point): boolean {
  return (
    Math.abs(left.x - right.x) < pixelTolerance &&
    Math.abs(left.y - right.y) < pixelTolerance
  );
}

function makeUnusedName(existingNames: string[]): string {
  const baseName = "Eget område ";
  for (let i = 0; i < 1000; i++) {
    const name = baseName + (i + 1);
    if (!existingNames.includes(name)) {
      return name;
    }
  }
  return baseName + "_";
}

const handleDeselectGroupDesoMode = (
  map: mapboxgl.Map,
  group: SelectedCustomRegion<DesoProperties>,
  prevSelectedAreas: SelectedAreaDesoMode[]
) => {
  for (const deso of group.props) {
    const groupId = group.groupId;
    // Is the unit selected outside this group too?
    // If so, we cannot deselect in on the map.
    const unitSelectedOutsideGroup = prevSelectedAreas?.some((area) => {
      if (area.type === "deso") {
        return area.props.deso === deso.deso;
      } else if (area.type === "user-defined") {
        if (area.groupId === groupId) {
          return false;
        }
        return area.props.some((d) => d.deso === deso.deso);
      }
      assertNever(area);
    });

    if (!unitSelectedOutsideGroup) {
      mapRemoveFeatureState("deso", map, deso.id);
    }
  }
};

const handleDeselectGroupRegsoMode = (
  map: mapboxgl.Map,
  group: SelectedCustomRegion<RegsoProperties>,
  prevSelectedAreas: SelectedAreaRegsoMode[]
) => {
  for (const regso of group.props) {
    const groupId = group.groupId;
    // Is the unit selected outside this group too?
    // If so, we cannot deselect in on the map.
    const unitSelectedOutsideGroup = prevSelectedAreas?.some((area) => {
      if (area.type === "regso") {
        return area.props.regso === regso.regso;
      } else if (area.type === "user-defined") {
        if (area.groupId === groupId) {
          return false;
        }
        return area.props.some((d) => d.regso === regso.regso);
      }
      assertNever(area);
    });

    if (!unitSelectedOutsideGroup) {
      mapRemoveFeatureState("regso", map, regso.id);
    }
  }
};

function collectAllSelected<T, U>(
  selected: T[] | undefined,
  newItems: U[],
  newItemAlreadyExists: (u: U) => boolean,
  convert: (newItem: U) => T
) {
  return (selected ?? []).concat(
    chain(newItems)
      .filter((newSelected) => !newItemAlreadyExists(newSelected))
      .map<T>(convert)
      .value()
  );
}
