import { useCallback, useContext } from "react";
import { useSetRecoilState } from "recoil";

import { DocCardStats } from "./document-core/core";
import {
  CardUpdateCountContext,
  GeographiesContext,
  ShowDraftDataContext,
} from "../../contexts";
import { useSaveCard } from "../actions/useSaveDocument";
import { cardQuery } from "./document-core/queries/card";
import { defined } from "../../../core/defined";
import { logger } from "../../../infra/logging";
import { cardColors } from "./document-core/queries/shared";
import { loadAndStoreDataStats } from "./cardToDataStateStats";
import { useExtendedAppearanceSettings } from "../actions/useExtendedAppearanceSettings";

/** Apply a change to a measure */
export function useApplyChangeStats(cardId: string) {
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const handleSaveCard = useSaveCard();
  const setCard = useSetRecoilState(cardQuery(cardId));
  const geographies = useContext(GeographiesContext);
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const appearanceSettings = useExtendedAppearanceSettings();

  const handleApply = useCallback(
    (updatedCard: DocCardStats) => {
      if (!defined(geographies)) {
        logger.error("Attempted to update card, but no geoTree available");
        return Promise.reject();
      }

      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      return loadAndStoreDataStats(
        setCard,
        shouldAbort,
        updatedCard,
        geographies,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedCard),
        handleSaveCard
      );
    },
    [
      adminShowDraftData,
      appearanceSettings,
      geographies,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      setCard,
    ]
  );

  return handleApply;
}
