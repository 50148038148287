import { IconButton, TextField } from "@fluentui/react";
import { sortBy, isEqual, maxBy } from "lodash";
import { useContext, useState, useCallback, useEffect, useMemo } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { AlertBox } from "../../../../components/AlertBox";
import {
  Button,
  CancelButton,
  SaveButton,
} from "../../../../components/Button";

import { FluentIcon } from "../../../../components/Icons";
import { AppMessagesContext } from "../../../../lib/application/contexts";
import { replaceInArrayImmut } from "../../../../lib/application/state/generic";
import { classNames } from "../../../../lib/core/classNames";
import { defined } from "../../../../lib/core/defined";
import { nonEmptyString } from "../../../../lib/core/nonEmptyString";
import { voidFunc } from "../../../../lib/core/voidFunc";
import { DimensionValueMinimal } from "../../../../lib/domain-admin/survey_dimensions";
import {
  DimensionV2Dto,
  DimensionValueV2Dto,
} from "../../../../lib/domain/measure/definitions";
import {
  displayHttpErrorInternal,
  HttpResult,
} from "../../../../lib/infra/HttpResult";
import { dimensionValueSortOrder } from "../../../stats/docs/cards/data_card/selections/shared";

import "./DimensionsEditor.scss";
import { StrictModeDroppable } from "../../../../components/compat/Droppable";
import React from "react";

interface BaseDimProperties {
  label: string;
  values: DimensionValueMinimal<string | number>[] | null;
}

export function DimensionsEditorV2(props: {
  dimensions?: DimensionV2Dto[];
  handleEditDimension: (
    dimensionId: number,
    dimension: DimensionV2Dto
  ) => Promise<HttpResult<void>>;
  handleSaveDimensionsOrder: (
    dims: DimensionV2Dto[]
  ) => Promise<HttpResult<void>>;
}) {
  const appMessages = useContext(AppMessagesContext);
  const { dimensions, handleEditDimension, handleSaveDimensionsOrder } = props;
  const handleSave = useCallback(
    (dim: DimensionV2Dto) => {
      return handleEditDimension(dim.dimension_id, dim).then((res) => {
        res.match({
          ok: () => {
            appMessages?.add("success", "Dimensionen har sparats");
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Misslyckades med att uppdatera dimension: " +
                displayHttpErrorInternal(err)
            );
          },
        });
        return res;
      });
    },
    [appMessages, handleEditDimension]
  );

  const handleSaveDimOrder = useCallback(
    (dims: DimensionV2Dto[]) => {
      return handleSaveDimensionsOrder(dims);
    },
    [handleSaveDimensionsOrder]
  );

  return (
    <div className="dimensions-editor-container">
      {defined(dimensions) && (
        <EditDimensionsOrder
          handleSave={handleSaveDimOrder}
          clone={copyDimensionDropValues}
          dimensions={dimensions}
        ></EditDimensionsOrder>
      )}
      {dimensions?.map((dimension) => (
        <EditableDimension
          handleSave={handleSave}
          key={dimension.dimension_id}
          clone={copyDimension}
          originalDimension={dimension}
        />
      ))}
    </div>
  );
}

function copyDimension<T extends BaseDimProperties>(dim: T): T {
  return {
    ...dim,
    values: dim.values?.map((v) => ({ ...v })),
  };
}

function copyDimensionDropValues<T extends BaseDimProperties>(dim: T): T {
  return {
    ...dim,
    values: [],
  };
}

export const EditableDimension = React.memo(EditableDimensionInner);

/**
 * Component for editing the values of a dimension
 */
function EditableDimensionInner(props: {
  disableEditLabel?: boolean;
  originalDimension: DimensionV2Dto;
  clone: (d: DimensionV2Dto) => DimensionV2Dto;
  handleSave: (d: DimensionV2Dto) => Promise<HttpResult<unknown>>;
  enableDelete?: boolean;
}) {
  const { clone, enableDelete, originalDimension } = props;
  const [dim, setDim] = useState(() => clone(props.originalDimension));

  useEffect(() => {
    setDim(clone(originalDimension));
  }, [clone, originalDimension]);

  const [isEditingDimLabel, setIsEditingDimLabel] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [dimLabelEdits, setDimLabelEdits] = useState(dim.label);
  const handleDimLabelChange = useCallback((value: string) => {
    setDimLabelEdits(value);
  }, []);
  const dimLabelValid = nonEmptyString(dimLabelEdits);

  const handleSaveDimensionLabel = useCallback(() => {
    const dimClone = clone(dim);
    setIsSaving(true);
    return props
      .handleSave({ ...dimClone, label: dimLabelEdits })
      .then((res) => {
        res.match({
          ok: () => {
            setIsEditingDimLabel(false);
          },
          err: voidFunc,
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  }, [clone, dim, dimLabelEdits, props]);

  const appMessages = useContext(AppMessagesContext);

  const handleSaveDimensionValueLabel = useCallback(
    (valueId: number, label: string) => {
      const dimClone = clone(dim);
      if (!defined(dimClone.values)) {
        appMessages?.add(
          "error",
          "Kunde inte hitta värden för dimensionen: " + dimClone.label
        );
        return Promise.reject();
      }
      const updatedValues = replaceInArrayImmut(
        dimClone.values,
        (v) => v.id === valueId,
        (v) => ({
          ...v,
          label,
        })
      );

      setIsSaving(true);
      return props
        .handleSave({ ...dimClone, values: updatedValues })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [appMessages, clone, dim, props]
  );

  const [isEditing, setIsEditing] = useState(false);
  const handleMoveItem = useCallback(
    (
      id: string,
      destinationIndexLocal: number,
      makeBottomFixed: boolean,
      makeTopFixed: boolean
    ) => {
      if (!defined(dim.values)) {
        return;
      }

      let destinationIndex = destinationIndexLocal;

      let copy = dim.values.slice();
      const currentIndex = copy.findIndex((v) => v.id.toString() === id);
      const elements = copy.splice(currentIndex, 1);
      const movedItem = {
        ...elements[0],
      };
      if (makeBottomFixed) {
        destinationIndex += copy.filter(
          (item) => item.sort_mode !== "fixed_bottom"
        ).length;
        movedItem.sort_mode = "fixed_bottom";
      } else if (makeTopFixed) {
        movedItem.sort_mode = "fixed_top";
      } else {
        destinationIndex += copy.filter(
          (item) => item.sort_mode === "fixed_top"
        ).length;
        delete movedItem.sort_mode;
      }
      copy = sortBy(copy, [dimensionValueSortOrder]);
      copy.splice(destinationIndex, 0, movedItem);
      for (let i = 0; i < copy.length; i++) {
        copy[i].sort_order = i;
      }
      setDim({ ...dim, values: copy });
    },
    [dim]
  );

  const handleSort = useCallback(
    (items: DimensionValueV2Dto[], descending: boolean) => {
      if (!defined(dim.values)) {
        return;
      }
      let copy = dim.values.slice();
      const sortedItems = sortBy(items, (item) => item.label);
      const idToIndex: { [key: string]: number } = {};
      for (let i = 0; i < sortedItems.length; i++) {
        const item = sortedItems[i];
        idToIndex[item.id] = i;
      }

      setDim({
        ...dim,
        values: copy.map((item) => {
          const index = idToIndex[item.id];
          if (!defined(index)) {
            return item;
          }
          return {
            ...item,
            sort_order: descending ? sortedItems.length - index : index,
          };
        }),
      });
    },
    [dim]
  );

  const handleMakeTopFixed = useCallback(
    (items: DimensionValueV2Dto[]) => {
      if (!defined(dim.values)) {
        return;
      }
      const topItems = items.filter((item) => item.sort_mode === "fixed_top");
      const topSortOrder =
        maxBy(topItems, (item) => item.sort_order)?.sort_order ?? 0;

      const idToIndex: { [key: number]: number } = {};
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        idToIndex[item.id] = i + topSortOrder + 1;
      }

      setDim({
        ...dim,
        values: dim.values.slice().map((item) => {
          const index = idToIndex[item.id];
          if (!defined(index)) {
            return item;
          }
          return {
            ...item,
            sort_order: index,
            sort_mode: "fixed_top",
          };
        }),
      });
    },
    [dim]
  );

  const handleMakeBottomFixed = useCallback(
    (items: DimensionValueV2Dto[]) => {
      if (!defined(dim.values)) {
        return;
      }
      const bottomItems = items.filter(
        (item) => item.sort_mode === "fixed_bottom"
      );
      const bottomSortOrder =
        maxBy(bottomItems, (item) => item.sort_order)?.sort_order ?? 0;

      const idToIndex: { [key: number]: number } = {};
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        idToIndex[item.id] = i + bottomSortOrder + 1;
      }

      setDim({
        ...dim,
        values: dim.values.slice().map((item) => {
          const index = idToIndex[item.id];
          if (!defined(index)) {
            return item;
          }
          return {
            ...item,
            sort_order: index,
            sort_mode: "fixed_bottom",
          };
        }),
      });
    },
    [dim]
  );

  const handleMakeUnsorted = useCallback(
    (items: DimensionValueV2Dto[]) => {
      if (!defined(dim.values)) {
        return;
      }
      const unsortedItems = items.filter((item) => !defined(item.sort_mode));
      const unsortedSortOrder =
        maxBy(unsortedItems, (item) => item.sort_order)?.sort_order ?? 0;

      const idToIndex: { [key: number]: number } = {};
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        idToIndex[item.id] = i + unsortedSortOrder + 1;
      }

      setDim({
        ...dim,
        values: dim.values.slice().map((item) => {
          const index = idToIndex[item.id];
          if (!defined(index)) {
            return item;
          }
          return {
            ...item,
            sort_order: index,
            sort_mode: undefined,
          };
        }),
      });
    },
    [dim]
  );

  const handleDelete = useCallback(
    (valueId: string) => {
      if (!enableDelete || !defined(dim.values)) {
        return;
      }

      const dimClone = clone(dim);
      dimClone.values =
        dimClone.values?.filter((v) => v.id.toString() !== valueId) ?? [];
      dimClone.values = sortBy(dimClone.values, (v) =>
        v.sort_mode === "fixed_bottom" ? 1 : 0
      ).map((v, i) => ({ ...v, sort_order: i }));

      setDim(dimClone);
    },
    [clone, dim, enableDelete]
  );

  const handleSave = useCallback(() => {
    props.handleSave(dim).then(() => {
      setIsEditing(false);
    });
  }, [dim, props]);

  const handleCancel = useCallback(() => {
    setDim(props.clone(props.originalDimension));
    setIsEditing(false);
  }, [props]);

  const topSorted = useMemo(
    () =>
      sortBy(
        dim.values?.filter((v) => v.sort_mode === "fixed_top") ?? [],
        (item) => item.sort_order
      ),
    [dim.values]
  );
  const bottom = useMemo(
    () =>
      sortBy(
        dim.values?.filter((v) => v.sort_mode === "fixed_bottom") ?? [],
        (item) => item.sort_order
      ),
    [dim.values]
  );
  const unsorted = useMemo(
    () =>
      sortBy(
        dim.values?.filter((v) => !defined(v.sort_mode)) ?? [],
        (item) => item.sort_order
      ),
    [dim.values]
  );

  if (isEditing) {
    const disabled = isEqual(dim, props.originalDimension);
    return (
      <div className="admin-editor-editable-dimension editing">
        <h3>{dim.label}</h3>
        <div className="overflow-container">
          <DragDropContext
            onDragEnd={(res) => {
              const destination = res.destination;
              if (!defined(destination)) {
                return;
              }
              handleMoveItem(
                res.draggableId,
                destination.index,
                destination.droppableId === "droppable-fixed-bottom",
                destination.droppableId === "droppable-fixed-top"
              );
            }}
          >
            <strong>Fixerad ordning</strong>
            {defined(topSorted) && topSorted.length > 0 && (
              <div>
                <Button
                  small
                  title="Sortera stigande"
                  onClick={() => handleSort(topSorted, false)}
                ></Button>
                <Button
                  small
                  title="Sortera fallande"
                  onClick={() => handleSort(topSorted, true)}
                ></Button>
                <Button
                  small
                  title="Flytta alla ↓"
                  onClick={() => handleMakeUnsorted(topSorted)}
                ></Button>
              </div>
            )}
            <StrictModeDroppable droppableId="droppable-fixed-top">
              {(droppableProvided) => {
                return (
                  <div
                    {...droppableProvided.droppableProps}
                    className="droppable-fixed-top"
                    ref={droppableProvided.innerRef}
                  >
                    {topSorted?.map((value, index) => (
                      <DraggableItem
                        key={value.id}
                        value={value}
                        index={index}
                        handleDelete={
                          props.enableDelete ? handleDelete : undefined
                        }
                      />
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                );
              }}
            </StrictModeDroppable>
            <strong>Fri ordning (storleksordning)</strong>
            {defined(unsorted) && unsorted.length > 0 && (
              <div>
                <Button
                  small
                  title="Sortera stigande"
                  onClick={() => handleSort(unsorted, false)}
                ></Button>
                <Button
                  small
                  title="Sortera fallande"
                  onClick={() => handleSort(unsorted, true)}
                ></Button>
                <Button
                  small
                  title="Flytta alla ↑"
                  onClick={() => handleMakeTopFixed(unsorted)}
                ></Button>
                <Button
                  small
                  title="Flytta alla ↓"
                  onClick={() => handleMakeBottomFixed(unsorted)}
                ></Button>
              </div>
            )}
            <StrictModeDroppable droppableId="droppable">
              {(droppableProvided) => {
                return (
                  <div
                    {...droppableProvided.droppableProps}
                    className="droppable droppable-unsorted"
                    ref={droppableProvided.innerRef}
                  >
                    {unsorted?.map((value, index) => (
                      <DraggableItem
                        key={value.id}
                        value={value}
                        index={index}
                        handleDelete={
                          props.enableDelete ? handleDelete : undefined
                        }
                      />
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                );
              }}
            </StrictModeDroppable>
            <strong>Fixera sist</strong>
            {defined(bottom) && bottom.length > 0 && (
              <div>
                <Button
                  small
                  title="Sortera stigande"
                  onClick={() => handleSort(bottom, false)}
                ></Button>
                <Button
                  small
                  title="Sortera fallande"
                  onClick={() => handleSort(bottom, true)}
                ></Button>
                <Button
                  small
                  title="Flytta alla ↑"
                  onClick={() => handleMakeUnsorted(bottom)}
                ></Button>
              </div>
            )}
            <StrictModeDroppable droppableId="droppable-fixed-bottom">
              {(droppableProvided) => {
                return (
                  <div
                    {...droppableProvided.droppableProps}
                    className="droppable-fixed-bottom"
                    ref={droppableProvided.innerRef}
                  >
                    {bottom?.map((value, index) => (
                      <DraggableItem
                        key={value.id}
                        value={value}
                        index={index}
                        handleDelete={
                          props.enableDelete ? handleDelete : undefined
                        }
                      />
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                );
              }}
            </StrictModeDroppable>
          </DragDropContext>
        </div>
        <div className="buttons">
          <Button title="Avbryt" onClick={handleCancel}></Button>
          <Button
            disabled={disabled}
            intent="primary"
            title="Spara"
            onClick={handleSave}
          ></Button>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-editor-editable-dimension">
      {isEditingDimLabel ? (
        <>
          <TextField
            autoFocus
            value={dimLabelEdits}
            onChange={(e) => handleDimLabelChange(e.currentTarget.value)}
          />
          <div className="margin-top-sm">
            <CancelButton
              onClick={() => {
                setIsEditingDimLabel(false);
              }}
            ></CancelButton>
            <SaveButton
              disabled={!dimLabelValid}
              onClick={handleSaveDimensionLabel}
            ></SaveButton>
          </div>
        </>
      ) : (
        <h3>
          {dim.label}{" "}
          {!props.disableEditLabel && (
            <FluentIcon
              onClick={() => setIsEditingDimLabel(true)}
              size={"sm"}
              name="edit"
            ></FluentIcon>
          )}
        </h3>
      )}

      <div className="overflow-container">
        <table>
          <thead></thead>
          <tbody>
            {defined(topSorted) && topSorted.length > 0 && (
              <tr>
                <td colSpan={3}>
                  <strong>Fixerad ordning</strong>
                </td>
              </tr>
            )}
            {defined(topSorted) &&
              topSorted.map((value, i) => (
                <tr key={value.id}>
                  <td
                    className={classNames(
                      "sort-order",
                      i === topSorted.length - 1 ? "top" : ""
                    )}
                  >
                    {value.sort_order}
                  </td>
                  <DraftCell value={value} />
                  <EditableDimensionValueCell
                    value={value}
                    disableEditing={isEditingDimLabel || isSaving}
                    handleSaveLabel={handleSaveDimensionValueLabel}
                  />
                </tr>
              ))}
            {defined(unsorted) && unsorted.length > 0 && (
              <tr>
                <td colSpan={3}>
                  <strong>Fri ordning (storleksordning)</strong>
                </td>
              </tr>
            )}
            {defined(unsorted) &&
              unsorted.map((value) => (
                <tr key={value.id}>
                  <td className="sort-order">{value.sort_order}</td>
                  <DraftCell value={value} />
                  <EditableDimensionValueCell
                    value={value}
                    disableEditing={isEditingDimLabel || isSaving}
                    handleSaveLabel={handleSaveDimensionValueLabel}
                  />
                </tr>
              ))}
            {defined(bottom) && bottom.length > 0 && (
              <tr>
                <td colSpan={3}>
                  <strong>Fixera sist</strong>
                </td>
              </tr>
            )}
            {defined(bottom) &&
              bottom.map((value, i) => (
                <tr key={value.id} className={i === 0 ? "bottom" : ""}>
                  <td className="sort-order">{value.sort_order}*</td>
                  <DraftCell value={value} />
                  <EditableDimensionValueCell
                    value={value}
                    disableEditing={isEditingDimLabel || isSaving}
                    handleSaveLabel={handleSaveDimensionValueLabel}
                  />
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="buttons">
        <Button title="Redigera" onClick={() => setIsEditing(true)}></Button>
      </div>
    </div>
  );
}

function EditableDimensionValueCell(props: {
  value: DimensionValueV2Dto;
  disableEditing: boolean;
  handleSaveLabel: (id: number, label: string) => Promise<HttpResult<unknown>>;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [labelEdits, setLabelEdits] = useState(props.value.label);
  const dimLabelValid = nonEmptyString(labelEdits);
  if (isEditing) {
    return (
      <td>
        <AlertBox intent="warning">
          <p>
            Vid ändring av värdeetiketter här måste också CSV-filen med
            grunddata ändras så att etiketten där matchar. Annars kommer
            etiketterna inte matcha vid nästa datauppladdning.
          </p>
        </AlertBox>
        <TextField
          autoFocus
          value={labelEdits}
          onChange={(e) => setLabelEdits(e.currentTarget.value)}
          disabled={props.disableEditing}
        />
        <div className="margin-top-sm">
          <CancelButton
            disabled={props.disableEditing}
            onClick={() => {
              setIsEditing(false);
            }}
          ></CancelButton>
          <SaveButton
            disabled={props.disableEditing || !dimLabelValid}
            onClick={() =>
              props.handleSaveLabel(props.value.id, labelEdits).then((res) => {
                res.match({
                  ok: () => {
                    setIsEditing(false);
                  },
                  err: voidFunc,
                });
              })
            }
          ></SaveButton>
        </div>
      </td>
    );
  }
  return (
    <td>
      {props.value.label}
      {!props.disableEditing && (
        <>
          {" "}
          <FluentIcon
            onClick={() => setIsEditing(true)}
            size={"xs"}
            name="edit"
          ></FluentIcon>
        </>
      )}
    </td>
  );
}

function DraftCell(props: { value: DimensionValueMinimal<string | number> }) {
  return (
    <td className="draft">{props.value.draft ? <strong>Ny!</strong> : "  "}</td>
  );
}

function DraggableItem(props: {
  value: DimensionValueMinimal<string | number>;
  index: number;
  handleDelete?: (id: string) => void;
}) {
  const { value, index, handleDelete } = props;
  return (
    <Draggable key={value.id} draggableId={value.id.toString()} index={index}>
      {(draggableProvided) => (
        <>
          <div
            className="draggable"
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <div className="main">
              <div className="grow">
                <div className="sort-order">{value.sort_order}</div>{" "}
                <span>{value.draft ? <strong>Ny! </strong> : "  "}</span>
                <span>{value.label}</span>
              </div>
              <FluentIcon
                className="drag-handle"
                size="sm"
                name="drag-handle-vertical"
              ></FluentIcon>
            </div>
            {defined(handleDelete) && (
              <IconButton
                iconProps={{ iconName: "cross" }}
                onClick={() => handleDelete(value.id.toString())}
              ></IconButton>
            )}
          </div>
        </>
      )}
    </Draggable>
  );
}

function DraggableDimensionItem(props: {
  value: DimensionV2Dto;
  index: number;
}) {
  const { value, index } = props;
  return (
    <Draggable
      key={value.dimension_id}
      draggableId={value.dimension_id.toString()}
      index={index}
    >
      {(draggableProvided) => (
        <>
          <div
            className="draggable"
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <div className="main">
              <div className="grow">
                <div className="sort-order">{value.sort_order}</div>{" "}
                <span>{value.label}</span>
              </div>
              <FluentIcon
                className="drag-handle"
                size="sm"
                name="drag-handle-vertical"
              ></FluentIcon>
            </div>
          </div>
        </>
      )}
    </Draggable>
  );
}

function EditDimensionsOrder(props: {
  dimensions: DimensionV2Dto[];
  clone: (d: DimensionV2Dto) => DimensionV2Dto;
  handleSave: (d: DimensionV2Dto[]) => void;
}) {
  const [dims, setDims] = useState(() => props.dimensions.map(props.clone));
  const [isEditing, setIsEditing] = useState(false);

  const handleMoveItem = useCallback(
    (id: string, destinationIndexLocal: number) => {
      let destinationIndex = destinationIndexLocal;

      let copy = dims.slice();
      const currentIndex = copy.findIndex(
        (v) => v.dimension_id.toString() === id
      );
      const elements = copy.splice(currentIndex, 1);
      const movedItem = {
        ...elements[0],
      };

      copy.splice(destinationIndex, 0, movedItem);
      for (let i = 0; i < copy.length; i++) {
        copy[i].sort_order = i;
      }
      setDims(copy);
    },
    [dims]
  );

  return (
    <div className="admin-editor-editable-dimension">
      <h3>Ändra ordning på dimensioner</h3>
      {isEditing ? (
        <>
          <DragDropContext
            onDragEnd={(res) => {
              const destination = res.destination;
              if (!defined(destination)) {
                return;
              }
              handleMoveItem(res.draggableId, destination.index);
            }}
          >
            <StrictModeDroppable droppableId="droppable">
              {(droppableProvided) => {
                return (
                  <div
                    {...droppableProvided.droppableProps}
                    className="droppable droppable-unsorted"
                    ref={droppableProvided.innerRef}
                  >
                    {dims?.map((value, index) => (
                      <DraggableDimensionItem
                        key={value.dimension_id}
                        value={value}
                        index={index}
                      />
                    ))}
                    {droppableProvided.placeholder}
                  </div>
                );
              }}
            </StrictModeDroppable>
          </DragDropContext>

          <div className="buttons">
            <Button
              title="Avbryt"
              onClick={() => {
                setDims(props.dimensions);
                setIsEditing(false);
              }}
            ></Button>
            <Button
              title="Spara"
              onClick={() => props.handleSave(dims)}
            ></Button>
          </div>
        </>
      ) : (
        <>
          <table>
            <thead></thead>
            <tbody>
              {dims.map((d) => (
                <tr key={d.dimension_id}>
                  <td>{d.sort_order}</td>
                  <td>{d.label}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="buttons">
            <Button
              title="Redigera"
              onClick={() => setIsEditing(true)}
            ></Button>
          </div>
        </>
      )}
    </div>
  );
}
