import { flatMap, uniq } from "lodash";
import { defined } from "../../core/defined";
import { RoleDto, RoleObjectDto, SharingInfoDto } from "../requests/docs/dto";

function matchRole(roleObjects: RoleObjectDto[], role: RoleDto): boolean {
  return roleObjects.some((r) => r.role === role);
}

export function highestRole(roles: RoleDto[]): RoleDto | undefined {
  const uniqueRoles = uniq(roles);
  if (uniqueRoles.includes("owner")) {
    return "owner";
  } else if (uniqueRoles.includes("editor")) {
    return "editor";
  } else if (uniqueRoles.includes("viewer")) {
    return "viewer";
  }
}

export function minimumAccessMatched(
  minimumRole: RoleDto,
  actualRoles: RoleDto[]
): boolean {
  return actualRoles.some((role) => {
    switch (minimumRole) {
      case "viewer":
        return role === "viewer" || role === "editor" || role === "owner";
      case "editor":
        return role === "editor" || role === "owner";
      case "owner":
        return role === "owner";
    }
  });
}

function parseShares(shares: SharingInfoDto["shares"]): Shares {
  if (!defined(shares)) {
    return { users: [], links: [] };
  }

  return {
    helpLibrary: defined(shares.help_library)
      ? {
          explicit: shares.help_library.explicit,
          role: shares.help_library.role,
          sharingId: shares.help_library.sharing_id,
        }
      : undefined,
    sampleLibrary: defined(shares.sample_library)
      ? {
          explicit: shares.sample_library.explicit,
          role: shares.sample_library.role,
          sharingId: shares.sample_library.sharing_id,
        }
      : undefined,
    organization: defined(shares.organization)
      ? {
          sharingId: shares.organization.sharing_id,
          role: shares.organization.role,
          explicit: shares.organization.explicit,
        }
      : undefined,
    users:
      shares.users?.map((dto) => ({
        sharingId: dto.sharing_id,
        userEmail: dto.user_email,
        role: dto.role,
        explicit: dto.explicit,
      })) ?? [],
    links:
      shares.links?.map((dto) => ({
        role: dto.role,
        explicit: dto.explicit,
        code: dto.link,
      })) ?? [],
  };
}
export interface ISharingInfo {
  hasMinimumAccess(role: RoleDto): boolean;
  nodeId(): number | undefined;
  owner: string | undefined;
  isOwner(): boolean;
  canEdit(): boolean;
  canViewOnly(): boolean;
  canView(): boolean;
  isShared(): boolean;
  isSharedWithUserOrganization(): boolean;
  shares(): Shares;
  isSampleLibDoc(): boolean;
  isHelpLibDoc(): boolean;
}

/**
 * Returns sharing info for a single measure view -- i.e. not a an actual document.
 */
export function getSharingInfoNonDocument(): ISharingInfo {
  return {
    hasMinimumAccess: () => false,
    nodeId: () => undefined,
    owner: undefined,
    isOwner: () => false,
    canEdit: () => false,
    canViewOnly: () => true,
    canView: () => true,
    isShared: () => false,
    isSharedWithUserOrganization: () => false,
    shares: () => ({ users: [], links: [] }),
    isSampleLibDoc: () => false,
    isHelpLibDoc: () => false,
  };
}

export function getSharingInfoStatsPreview(): ISharingInfo {
  return {
    hasMinimumAccess: () => false,
    nodeId: () => undefined,
    owner: undefined,
    isOwner: () => true,
    canEdit: () => true,
    canViewOnly: () => false,
    canView: () => true,
    isShared: () => false,
    isSharedWithUserOrganization: () => false,
    shares: () => ({ users: [], links: [] }),
    isSampleLibDoc: () => false,
    isHelpLibDoc: () => false,
  };
}

export function getSharingInfoMicroPreview(): ISharingInfo {
  return {
    hasMinimumAccess: () => false,
    nodeId: () => undefined,
    owner: undefined,
    isOwner: () => true,
    canEdit: () => true,
    canViewOnly: () => true,
    canView: () => true,
    isShared: () => false,
    isSharedWithUserOrganization: () => false,
    shares: () => ({ users: [], links: [] }),
    isSampleLibDoc: () => false,
    isHelpLibDoc: () => false,
  };
}

export class SharingInfo implements ISharingInfo {
  private _shares: Shares;
  constructor(private _dto: SharingInfoDto) {
    this._shares = parseShares(this._dto.shares);
  }

  private _allImplicitRoles(): RoleObjectDto[] {
    const implicitShares = this._dto.implicit_shares;
    return flatMap(
      [
        implicitShares.user_roles,
        implicitShares.org_roles,
        implicitShares.global_roles,
      ].filter(defined)
    );
  }

  private _allExplicitRoles(): RoleObjectDto[] {
    const explicitShares = this._dto.explicit_shares;
    return flatMap(
      [
        explicitShares.user_roles,
        explicitShares.org_roles,
        explicitShares.global_roles,
      ].filter(defined)
    );
  }

  private _hasExplicitRole(role: RoleDto): boolean {
    return matchRole(this._allExplicitRoles(), role);
  }

  private _hasImplicitRole(role: RoleDto): boolean {
    return matchRole(this._allImplicitRoles(), role);
  }

  private _hasRole(role: RoleDto): boolean {
    return this._hasExplicitRole(role) || this._hasImplicitRole(role);
  }

  hasMinimumAccess(role: RoleDto): boolean {
    const allRoles = this._allExplicitRoles().concat(this._allImplicitRoles());
    return minimumAccessMatched(
      role,
      allRoles.map((r) => r.role)
    );
  }

  nodeId(): number | undefined {
    return this._dto.node_id;
  }

  get owner(): string | undefined {
    const ownership = this._dto.ownership;
    return ownership.type === "shared" ? ownership.owner_email : undefined;
  }

  isOwner(): boolean {
    return this._hasRole("owner");
  }

  canEdit(): boolean {
    return this.isOwner() || this._hasRole("editor");
  }

  canViewOnly(): boolean {
    return (
      !this.isOwner() && !this._hasRole("editor") && this._hasRole("viewer")
    );
  }

  canView(): boolean {
    return this.isOwner() || this._hasRole("editor") || this._hasRole("viewer");
  }

  isShared(): boolean {
    return (
      defined(this._shares.organization) ||
      this._shares.links.length > 0 ||
      this._shares.users.length > 0 ||
      defined(this._shares.helpLibrary) ||
      defined(this._shares.sampleLibrary)
    );
  }

  isSharedWithUserOrganization(): boolean {
    return defined(this._shares.organization);
  }

  shares(): Shares {
    return this._shares;
  }

  isHelpLibDoc(): boolean {
    return defined(this._shares.helpLibrary);
  }

  isSampleLibDoc(): boolean {
    return defined(this._shares.sampleLibrary);
  }
}

export interface SingleShareBase {
  sharingId: number;
  role: RoleDto;
  explicit: boolean;
}

export type UserShare = SingleShareBase & {
  userEmail: string;
};

export type LinkShare = Omit<SingleShareBase, "sharingId"> & {
  code: string;
};
export type OrgShare = SingleShareBase;

export interface Shares {
  organization?: OrgShare;
  helpLibrary?: SingleShareBase;
  sampleLibrary?: SingleShareBase;
  users: Array<UserShare>;
  links: Array<LinkShare>;
}
