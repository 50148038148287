import { useEffect } from "react";

export function useSynchronizeState<T>(
  externalState: T,
  internalState: T,
  setInternalState: (newValue: T) => void,
  delayMs: number
) {
  useEffect(() => {
    if (externalState === internalState) {
      return;
    }

    const handle = setTimeout(() => {
      setInternalState(externalState);
    }, delayMs);

    return () => {
      clearTimeout(handle);
    };
  }, [delayMs, externalState, internalState, setInternalState]);
}
