import { assertNever, IDropdownOption, IDropdownProps } from "@fluentui/react";
import {
  IconPolygon,
  IconMapPin,
  IconTimeline,
  IconCalculator,
  IconChartBar,
} from "@tabler/icons-react";
import { useCallback, useMemo } from "react";
import { useSetRecoilState } from "recoil";

import { useToggle } from "../../../../../../lib/application/hooks/useToggle";
import { replaceInArrayImmut } from "../../../../../../lib/application/state/generic";
import {
  isMicroMeasureRegularDto,
  MicroSubjectPath,
} from "../../../../../../lib/application/state/stats/document-core/core-micro";
import { singleMicroCardQuery } from "../../../../../../lib/application/state/stats/document-core/queries/microCard";
import { classNames } from "../../../../../../lib/core/classNames";
import { defined } from "../../../../../../lib/core/defined";
import { voidFunc } from "../../../../../../lib/core/voidFunc";
import {
  Categories,
  prepareCategoryParts,
} from "../../../../../../lib/domain/categories";
import {
  ComputedMeasureVariablesConfig,
  MeasureSelectionMicroPartial,
  MeasureSpecMicro,
} from "../../../../../../lib/domain/measure/definitions";
import {
  ComputedMeasurementType,
  MicroMeasureDto,
  MicroMeasureRegularDto,
} from "../../../../../../lib/infra/api_responses/micro_dataset";
import { logger } from "../../../../../../lib/infra/logging";
import { useReadMicroCardState } from "../../../../../../lib/application/state/actions/micro/updates";
import { useApplyChangeMicro } from "../../../../../../lib/application/state/stats/useApplyChangeMicro";

export type SelectedMicroMeasures = [
  dataId: number,
  computedMeasureType: ComputedMeasurementType | undefined
][];

export function useSelectionInputState(
  changePending: boolean,
  selection:
    | Pick<MeasureSelectionMicroPartial, "subjectPath" | "id" | "measure">
    | undefined,
  handleChangePath: (id: string | undefined, path: MicroSubjectPath) => void,
  handleChangeMeasure: (
    selectionId: string | undefined,
    measureId: number,
    computedMeasureType?: ComputedMeasurementType
  ) => void,
  availableMeasures: MicroMeasureDto[],
  selectedMeasures: SelectedMicroMeasures,
  categories: Categories
) {
  const subjectPath = useMemo(
    () => selection?.subjectPath ?? [undefined, undefined, undefined],
    [selection]
  );
  const [area, subarea, subject] = subjectPath;
  const { areas, subareas, subjects } = useMemo(
    () => prepareCategoryParts(subjectPath, categories),
    [subjectPath, categories]
  );
  const selectionId = selection?.id;

  const selectedMeasure = selection?.measure;
  const selectedMeasureKey = defined(selectedMeasure)
    ? getMeasureKeyMicroSpec(selectedMeasure)
    : undefined;
  const otherSelectedMeasures = selectedMeasures.filter(
    (selected) =>
      !(
        selected[0] === selectedMeasure?.id &&
        selected[1] === selectedMeasure?.computed?.type
      )
  );

  const microOptions: IDropdownOption[] = useMemo(
    () =>
      availableMeasures.map<IDropdownOption>((item) => {
        const alreadySelected = otherSelectedMeasures.some(
          (other) => `${other[0]}-${other[1] ?? ""}` === getMeasureKey(item)
        );
        return {
          disabled: alreadySelected,
          key: getMeasureKey(item),
          text: item.measure,
        };
      }),
    [availableMeasures, otherSelectedMeasures]
  );

  const areasInputProps: IDropdownProps = useMemo(() => {
    return {
      disabled: changePending,
      selectedKey: area,
      onChange: (_: any, item?: IDropdownOption) => {
        if (!item) {
          return;
        }
        handleChangePath(selectionId, [
          item.key as string,
          undefined,
          undefined,
        ]);
      },
      label: "Område",
      options: areas.map((area) => ({ key: area, text: area })),
    };
  }, [changePending, areas, area, selectionId, handleChangePath]);

  const subareasInputProps: IDropdownProps = useMemo(() => {
    return {
      disabled:
        changePending ||
        !defined(area) ||
        (defined(subareas) && subareas.length < 2),
      label: "Delområde",
      selectedKey: subarea ?? "",
      ...(defined(area) && defined(subareas)
        ? ({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange: (
              _unused: any,
              item: IDropdownOption<any> | undefined
            ) => {
              if (!defined(item)) {
                return;
              }
              handleChangePath(selectionId, [
                area,
                item.key as string,
                undefined,
              ]);
            },
            options: subareas.map((label) => ({ key: label, text: label })),
          } as IDropdownProps)
        : ({ onChange: voidFunc, options: [] } as IDropdownProps)),
    };
  }, [changePending, area, subarea, subareas, selectionId, handleChangePath]);

  const subjectsInputProps = useMemo(() => {
    return {
      disabled: changePending || !defined(subjects) || subjects.length < 2,
      label: "Ämne",
      selectedKey: subject ?? "",
      ...(defined(area) && defined(subarea) && defined(subjects)
        ? {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange: (
              _unused: any,
              item: IDropdownOption<any> | undefined
            ) => {
              if (!defined(item)) {
                return;
              }
              handleChangePath(selectionId, [
                area,
                subarea,
                item.key as string,
              ]);
            },
            options: subjects.map((s) => ({ key: s, text: s })),
          }
        : { onChange: voidFunc, options: [] }),
    };
  }, [
    changePending,
    area,
    subarea,
    subject,
    subjects,
    selectionId,
    handleChangePath,
  ]);

  const onRenderTitle = useCallback(
    (options?: IDropdownOption[]) => {
      const singleOption = options?.filter((o) => !o.disabled)?.[0];
      if (!defined(singleOption)) {
        return null;
      }
      const option = availableMeasures.find(
        (m) => getMeasureKey(m) === singleOption.key
      );
      if (!defined(option)) {
        return <span>--</span>;
      }
      const alreadySelected = otherSelectedMeasures.some(
        (other) => `${other[0]}-${other[1] ?? ""}` === getMeasureKey(option)
      );
      return renderMeasureLine(option, alreadySelected, "fixed-width");
    },
    [availableMeasures, otherSelectedMeasures]
  );

  const onRenderOption = useCallback(
    (item?: IDropdownOption) => {
      if (!defined(item)) {
        return null;
      }
      const option = availableMeasures.find(
        (m) => getMeasureKey(m) === item.key
      );
      if (!defined(option)) {
        return <span>--</span>;
      }
      const alreadySelected = otherSelectedMeasures.some(
        (other) => `${other[0]}-${other[1] ?? ""}` === getMeasureKey(option)
      );
      return renderMeasureLine(option, alreadySelected);
    },
    [availableMeasures, otherSelectedMeasures]
  );

  const onChange = useCallback(
    (e: unknown, option?: IDropdownOption) => {
      if (!defined(option)) {
        return;
      }
      const measureKey = option.key;
      if (typeof measureKey !== "string") {
        logger.error("Invalid measureId");
        return;
      }
      const nextMeasure = availableMeasures.find(
        (m) => getMeasureKey(m) === measureKey
      );
      if (!defined(nextMeasure)) {
        logger.error("Selected measure not found! Measure key: " + measureKey);
        return;
      }
      handleChangeMeasure(
        selectionId,
        nextMeasure.mikro_id,
        isMicroMeasureRegularDto(nextMeasure)
          ? nextMeasure.computed_measurement_type
          : undefined
      );
    },
    [availableMeasures, handleChangeMeasure, selectionId]
  );

  const measureInputProps: IDropdownProps = useMemo(() => {
    return {
      label: "Mått",
      disabled: changePending || microOptions.length < 2,
      selectedKey: selectedMeasureKey,
      options: microOptions,
      onRenderTitle: onRenderTitle,
      onRenderOption: onRenderOption,
      onChange: onChange,
    };
  }, [
    changePending,
    microOptions,
    onChange,
    onRenderOption,
    onRenderTitle,
    selectedMeasureKey,
  ]);

  return {
    areasInputProps,
    subareasInputProps,
    subjectsInputProps,
    measureInputProps,
    selectedMeasureKey,
  };
}

/**
 * State related to computed measure variables
 */
function useVariablesStateInner(
  cardId: string,
  isEditingVariablesDefault: () => boolean
) {
  const setCard = useSetRecoilState(
    singleMicroCardQuery({ cardStateId: cardId })
  );
  const [isEditingVariables, toggleIsEditingVariables] = useToggle(
    isEditingVariablesDefault()
  );

  return {
    setCard,
    isEditingVariables,
    toggleIsEditingVariables,
  };
}

/**
 * State related to computed measure variables
 */
export function useVariablesStatePrimarySelection(
  cardId: string,
  selection: MeasureSelectionMicroPartial | undefined,
  isEditingVariablesDefault: () => boolean
) {
  const { isEditingVariables, toggleIsEditingVariables, setCard } =
    useVariablesStateInner(cardId, isEditingVariablesDefault);
  const readCard = useReadMicroCardState(cardId);

  const handleApply = useApplyChangeMicro(cardId);

  const handleSetVariablesConfig = useCallback(
    (config: ComputedMeasureVariablesConfig) => {
      const prev = readCard();

      const dataSelection = selection;
      if (!defined(dataSelection)) {
        throw new Error("Expected dataSelection");
      }

      const updatedCard = {
        ...prev,
        data: {
          ...prev.data,
          dataSelections: !defined(prev.data.dataSelections)
            ? undefined
            : replaceInArrayImmut(
                prev.data.dataSelections,
                (s) => s.id === dataSelection.id,
                (prevSel) => {
                  return {
                    ...prevSel,
                    computedMeasureVariablesConfig: config,
                  };
                }
              ),
        },
      };

      setCard(updatedCard);
      handleApply(updatedCard);
      toggleIsEditingVariables();
    },
    [handleApply, readCard, selection, setCard, toggleIsEditingVariables]
  );

  return {
    handleSetVariablesConfig,
    isEditingVariables,
    toggleIsEditingVariables,
  };
}

/**
 * State related to computed measure variables
 */
export function useVariablesStateFilterSelection(
  cardId: string,
  filterIndex: number,
  isEditingVariablesDefault: () => boolean
) {
  const { isEditingVariables, toggleIsEditingVariables, setCard } =
    useVariablesStateInner(cardId, isEditingVariablesDefault);

  const handleSetVariablesConfig = useCallback(
    (config: ComputedMeasureVariablesConfig) => {
      setCard((prev) => {
        return {
          ...prev,
          data: {
            ...prev.data,
            filterMeasures: replaceInArrayImmut(
              prev.data.filterMeasures,
              (s, i) => i === filterIndex,
              (prevFilter) => {
                const measureSelection = prevFilter.measureSelection;
                if (!defined(measureSelection)) {
                  logger.error(
                    "Attempting to update measure selection, but no selection defined"
                  );
                  return prevFilter;
                }

                return {
                  ...prevFilter,
                  measureSelection: {
                    ...measureSelection,
                    computedMeasureVariablesConfig: config,
                  },
                };
              }
            ),
          },
        };
      });
      toggleIsEditingVariables();
    },
    [filterIndex, setCard, toggleIsEditingVariables]
  );

  return {
    handleSetVariablesConfig,
    isEditingVariables,
    toggleIsEditingVariables,
  };
}

function getMeasureKey(m: MicroMeasureDto): string {
  return `${m.mikro_id}-${
    isMicroMeasureRegularDto(m) ? m.computed_measurement_type ?? "" : ""
  }`;
}

function getMeasureKeyMicroSpec(m: MeasureSpecMicro): string {
  return `${m.id}-${defined(m.computed) ? m.computed.type : ""}`;
}

function renderMeasureLine(
  option: MicroMeasureDto,
  alreadySelected: boolean,
  className?: string
) {
  return (
    <div
      className={classNames(
        "micro-measure-selection-entry-with-icon",
        className
      )}
    >
      <div className="icon">{renderMicroMeasureTypeIcon(option)}</div>
      <div className="text" title={option.measure}>
        {option.measure}
        {alreadySelected && " (redan vald)"}
      </div>
    </div>
  );
}

export function renderMicroMeasureTypeIcon(option: {
  value_type: MicroMeasureDto["value_type"];
  computed_measurement_type?: MicroMeasureRegularDto["computed_measurement_type"];
}): JSX.Element {
  const iconSize = 17;
  switch (option.value_type) {
    case "polygon":
      return <IconPolygon size={iconSize}></IconPolygon>;
    case "point":
      return <IconMapPin size={iconSize}></IconMapPin>;
    case "line":
      return <IconTimeline size={iconSize}></IconTimeline>;
    case "decimal":
    case "integer":
      if (defined(option.computed_measurement_type)) {
        return <IconCalculator size={iconSize}></IconCalculator>;
      }
      return <IconChartBar size={iconSize}></IconChartBar>;
  }
  assertNever(option.value_type);
}
