import { useEffect, useState } from "react";

import { getMicroMeasuresWithCache } from "../../../../../lib/application/requests/datasets/micro";
import { MicroSubjectPath } from "../../../../../lib/application/state/stats/document-core/core-micro";
import { defined } from "../../../../../lib/core/defined";
import { GeoTypeMicro } from "../../../../../lib/domain/geography";
import { DataValueTypeMicroAll } from "../../../../../lib/infra/api_responses/dataset";
import { MicroMeasureDto } from "../../../../../lib/infra/api_responses/micro_dataset";
import { logger } from "../../../../../lib/infra/logging";

export function useMicroAvailableMeasures(
  subjectPath: MicroSubjectPath,
  adminSeeDraftData: boolean,
  acceptGeoTypes: GeoTypeMicro[],
  acceptValueTypes: DataValueTypeMicroAll[]
) {
  const [lastFetchedSubjectPath, setLastFetchedSubjectPath] =
    useState<MicroSubjectPath>();
  const [availableMeasures, setAvailableMeasures] = useState<MicroMeasureDto[]>(
    []
  );

  useEffect(() => {
    if (!isValidSubjectPath(subjectPath)) {
      return;
    }
    if (subjectPath === lastFetchedSubjectPath) {
      return;
    }
    getMicroMeasuresWithCache(
      subjectPath,
      adminSeeDraftData,
      acceptGeoTypes,
      acceptValueTypes
    ).then((res) => {
      res.match({
        ok: (data) => {
          setLastFetchedSubjectPath(subjectPath);
          setAvailableMeasures(data);
        },
        err: (err) => {
          logger.error(err);
        },
      });
    });
  }, [
    acceptGeoTypes,
    acceptValueTypes,
    adminSeeDraftData,
    lastFetchedSubjectPath,
    subjectPath,
  ]);

  return availableMeasures;
}

function isValidSubjectPath(
  subjectPath: (string | undefined)[]
): subjectPath is [string, string, string] {
  return subjectPath.length === 3 && subjectPath.every(defined);
}
