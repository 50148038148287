import mapboxgl from "mapbox-gl";
import { InfostatBoundingBox } from "../../domain/cartography/types";

export function InfostatBoundsToMapboxBounds(
  bounds: InfostatBoundingBox
): mapboxgl.LngLatBounds {
  return new mapboxgl.LngLatBounds(bounds.sw, bounds.ne);
}

export function mapboxBoundsToInfostatBounds(
  bounds: mapboxgl.LngLatBounds
): InfostatBoundingBox {
  const sw = bounds.getSouthWest();
  const ne = bounds.getNorthEast();
  return {
    sw: {
      lng: sw.lng,
      lat: sw.lat,
    },
    ne: {
      lng: ne.lng,
      lat: ne.lat,
    },
  };
}
