import { classNames } from "../../lib/core/classNames";
import "./TextButton.scss";

export function TextButton(props: {
  className?: string;
  title: string;
  onClick: () => void;
}) {
  return (
    <button
      onClick={props.onClick}
      className={classNames("text-button", props.className)}
    >
      {props.title}
    </button>
  );
}
