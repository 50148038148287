import { config } from "../../../../../../config";
import { HttpResult } from "../../../../../infra/HttpResult";
import { decodedAuthedRequest } from "../../../shared";
import {
  CommitDto,
  CommitDtoRT,
  FORCE_REMOVE_CONFLICTING_VALUES_FIELD,
  RevertDto,
  RevertDtoRT,
} from "../shared";

const api = config.apis.importV1;

export function commitPendingChangesMicro(
  id: number,
  forceRemoveConflictingValues?: boolean
): Promise<HttpResult<CommitDto>> {
  const opts: { [key: string]: any } = { mikro_id: id };
  if (forceRemoveConflictingValues) {
    opts[FORCE_REMOVE_CONFLICTING_VALUES_FIELD] = forceRemoveConflictingValues;
  }
  return decodedAuthedRequest(
    api,
    "admin/mikro/commit",
    opts,
    "POST",
    CommitDtoRT
  );
}

export function revertPendingChangesMicro(
  id: number
): Promise<HttpResult<RevertDto>> {
  return decodedAuthedRequest(
    api,
    "admin/mikro/revert",
    { mikro_id: id },
    "POST",
    RevertDtoRT
  );
}
