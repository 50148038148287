import React from "react";

import { Categories } from "../domain/categories";
import { AppMessagesHandler } from "./AppMessagesHandler";
import { ISharingInfo } from "./files/SharingInfo";
import { HttpResult } from "../infra/HttpResult";
import { UserInfo } from "./auth/UserInfo";
import { DocCardState } from "./state/stats/document-core/core";
import { ReportMetaPersistent } from "./state/stats/document-meta/definitions";
import { GeographiesSerializable } from "../domain/geography";
import { voidFunc } from "../core/voidFunc";
import { MicroGeoTree } from "./stats/shared/MicroGeoTree";
import { CustomThemeSpec } from "./stats/shared/core/colors/colorSchemes";
import { TutorialsHandler } from "./tutorial_system/tutorials_handler";
import { Tutorial } from "./tutorial_system/types";
import { CustomBranding } from "../../components/NavBar";

export const UserInfoContext = React.createContext<UserInfo | undefined>(
  undefined
);
export interface UserInfoUpdateOpts {
  userThemes: CustomThemeSpec[];
  defaultThemeId: string | undefined;
  hideSupportBubble: boolean;
}
export interface OrgPreferencesUpdateOpts {
  orgDefinedThemes: CustomThemeSpec[];
  defaultThemeId: string | undefined;
}
export const UserInfoUpdateContext = React.createContext<
  | undefined
  | ((opts: Partial<UserInfoUpdateOpts>) => Promise<HttpResult<unknown>>)
>(undefined);
export const OrgPreferencesUpdateContext = React.createContext<
  | undefined
  | ((opts: Partial<OrgPreferencesUpdateOpts>) => Promise<HttpResult<unknown>>)
>(undefined);

export const GeographiesContext = React.createContext<
  GeographiesSerializable | undefined
>(undefined);

export const CategoriesContext = React.createContext<Categories>({});
/** Categories for micro primary measure -- measure of any type */
export const CategoriesContextMicroPrimaryContext =
  React.createContext<Categories>({});
/** Categories for micro secondary measure -- measure of geometric type like polygon */
export const CategoriesContextMicroSecondaryContext =
  React.createContext<Categories>({});

export const AppMessagesContext = React.createContext<
  AppMessagesHandler | undefined
>(undefined);

export const DocumentIdContext = React.createContext<number | null>(null);

export const TutorialsContext = React.createContext<{
  availableTutorials: Tutorial[];
  tutorialsHandler: TutorialsHandler | null;
  createTutorialsHandler: (() => void) | null;
}>({
  availableTutorials: [],
  tutorialsHandler: null,
  createTutorialsHandler: null,
});

/**
 * Admin-only
 * Context for deciding whether to show draft data.
 */
export const ShowDraftDataContext = React.createContext<boolean>(false);

export const InfostatDataAdminModeContext = React.createContext<boolean>(false);

export const HideSpaceBeforeCardContext = React.createContext<boolean>(false);
export const HideSpaceAfterCardContext = React.createContext<boolean>(false);
/**
 * Sharing status for the current document
 */
export type SharingInfoReloader = () => Promise<HttpResult<unknown>>;
export const SharingInfoContext = React.createContext<
  { info: ISharingInfo; reload?: SharingInfoReloader } | undefined
>(undefined);

export type MicroMapDownloadFunc = () => void;
export const MicroMapDownloadContext = React.createContext<{
  emitEvent: MicroMapDownloadFunc;
  listenEvent: (callback: MicroMapDownloadFunc) => void;
}>({ emitEvent: voidFunc, listenEvent: voidFunc });

/**
 * Context that shows whether the current output is in preview mode or not.
 * Preview mode is used to display quick search results.
 */
export const DataPreviewContext = React.createContext<boolean>(false);

export const MicroGeoTreeContext = React.createContext<
  MicroGeoTree | undefined
>(undefined);

export const SaveDocumentContext = React.createContext<
  | undefined
  | ((
      dataCardsState?: DocCardState[],
      reportMeta?: ReportMetaPersistent,
      disableRecordSavePostponed?: boolean
    ) => Promise<void>)
>(undefined);

export const CardUpdateCountContext = React.createContext<{
  getCurrentValue: () => number;
  increment: () => number;
}>({ getCurrentValue: () => 0, increment: () => 0 });

export const OrganizationBrandingContext = React.createContext<
  CustomBranding | undefined
>(undefined);

export const CardBgColorContext = React.createContext<string | undefined>(
  undefined
);
