import { defined } from "../../lib/core/defined";
import { RequestErrorInfo } from "../../lib/infra/HttpResult";

export function DisplayRequestErrorInfo(props: {
  info: RequestErrorInfo | undefined;
}) {
  const info = props.info;
  if (!defined(info)) {
    return null;
  }

  switch (info.type) {
    case "data":
      try {
        const stringifiedData = JSON.stringify(info.data);
        return <div>{stringifiedData}</div>;
      } catch (e) {
        return <div>Unknown request error</div>;
      }
    case "errorCode":
      return (
        <div>
          <p>
            <b>Error</b>: {info.error}
          </p>
          {defined(info.message) && (
            <p>
              <b>Message</b>: {info.message}
            </p>
          )}
        </div>
      );
    case "errorMessage":
      return (
        <div>
          <p>
            <b>Message</b>: {info.message}
          </p>
        </div>
      );
  }
}
