import {
  DimensionV2Dto,
  MeasureRegular,
  MeasureSelectionRegular,
} from "../../../../lib/domain/measure/definitions";
import { StatsDatasetResponseDto } from "../../../../lib/infra/api_responses/dataset";

export const sampleDataBarChart: StatsDatasetResponseDto = {
  header: {
    subject: "Befolkningsförändring",
    measure: "Per kommun och typ av förändring, antal/invånare",
    unit_label: "antal/1 000 invånare",
    value_type: "decimal",
    source: "Infostat",
    source_url:
      "https://www.statistikdatabasen.scb.se/pxweb/sv/ssd/START__BE__BE0101__BE0101G/ManadBefStatRegion/#",
    ext_source: "SCB",
    descr_long: "Befolkningsförändring",
    ext_descr:
      "Befolkningsförändringar antal personer efter region, förändringar, kön och år",
    agg_method_geo: "avg-weighted",
    last_update: "2024-03-13",
    lifted: {
      region: "Sverige",
      breakdown2: "Samtliga",
    },
    dimensions: ["value", "date", "breakdown1"],
  },
  rows: [
    {
      date: "2023-01-01",
      breakdown1: "Utflyttningar",
      value: "6.95944267595755",
      user_defined: false,
    },
    {
      date: "2023-01-01",
      breakdown1: "Inflyttningar",
      value: "8.957223698497314",
      user_defined: false,
    },
    {
      date: "2023-01-01",
      breakdown1: "Födda",
      value: "9.48197291679915",
      user_defined: false,
    },
    {
      date: "2023-01-01",
      breakdown1: "Döda",
      value: "8.94499818844477",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Utflyttningar",
      value: "4.80841426876405",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Inflyttningar",
      value: "9.735822344147577",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Födda",
      value: "9.954231104220705",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Döda",
      value: "9.004086467819018",
      user_defined: false,
    },
  ],
} as any;

export const sampleDataLineChart: StatsDatasetResponseDto = {
  header: {
    subject: "Befolkningsförändring",
    measure: "Per kommun och typ av förändring, antal/invånare",
    unit_label: "antal/1 000 invånare",
    value_type: "decimal",
    source: "Infostat",
    source_url:
      "https://www.statistikdatabasen.scb.se/pxweb/sv/ssd/START__BE__BE0101__BE0101G/ManadBefStatRegion/#",
    ext_source: "SCB",
    descr_long: "Befolkningsförändring",
    ext_descr:
      "Befolkningsförändringar antal personer efter region, förändringar, kön och år",
    agg_method_geo: "avg-weighted",
    last_update: "2024-03-13",
    lifted: {
      region: "Sverige",
    },
    dimensions: ["value", "date", "breakdown1", "breakdown2"],
    breakdown1_label: "Förändringstyp",
    breakdown2_label: "Kön",
  },
  rows: [
    {
      date: "2023-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Kvinnor",
      value: "6.47409484065088",
      user_defined: false,
    },
    {
      date: "2023-01-01",
      breakdown1: "Döda",
      breakdown2: "Kvinnor",
      value: "8.95272320825288",
      user_defined: false,
    },
    {
      date: "2023-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Män",
      value: "7.438091044944969",
      user_defined: false,
    },
    {
      date: "2023-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Män",
      value: "9.058414661669916",
      user_defined: false,
    },
    {
      date: "2023-01-01",
      breakdown1: "Döda",
      breakdown2: "Män",
      value: "8.93737980042989",
      user_defined: false,
    },
    {
      date: "2023-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Kvinnor",
      value: "8.854616402389073",
      user_defined: false,
    },
    {
      date: "2023-01-01",
      breakdown1: "Födda",
      breakdown2: "Män",
      value: "9.646836086609762",
      user_defined: false,
    },
    {
      date: "2023-01-01",
      breakdown1: "Födda",
      breakdown2: "Kvinnor",
      value: "9.314802217442855",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Kvinnor",
      value: "4.672585862546408",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Födda",
      breakdown2: "Män",
      value: "10.16849856671657",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Döda",
      breakdown2: "Kvinnor",
      value: "9.0432513815201",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Kvinnor",
      value: "9.356467413279747",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Födda",
      breakdown2: "Kvinnor",
      value: "9.736883217134526",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Män",
      value: "10.109800759636443",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Döda",
      breakdown2: "Män",
      value: "8.965476629968268",
      user_defined: false,
    },
    {
      date: "2022-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Män",
      value: "4.942317608360682",
      user_defined: false,
    },
    {
      date: "2021-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Kvinnor",
      value: "4.480297957149784",
      user_defined: false,
    },
    {
      date: "2021-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Kvinnor",
      value: "8.506594956216933",
      user_defined: false,
    },
    {
      date: "2021-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Män",
      value: "8.833033278606848",
      user_defined: false,
    },
    {
      date: "2021-01-01",
      breakdown1: "Döda",
      breakdown2: "Män",
      value: "8.836074694903177",
      user_defined: false,
    },
    {
      date: "2021-01-01",
      breakdown1: "Döda",
      breakdown2: "Kvinnor",
      value: "8.75911733892645",
      user_defined: false,
    },
    {
      date: "2021-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Män",
      value: "4.756775087454975",
      user_defined: false,
    },
    {
      date: "2021-01-01",
      breakdown1: "Födda",
      breakdown2: "Kvinnor",
      value: "10.743854662678444",
      user_defined: false,
    },
    {
      date: "2021-01-01",
      breakdown1: "Födda",
      breakdown2: "Män",
      value: "11.117327005666729",
      user_defined: false,
    },
    {
      date: "2020-01-01",
      breakdown1: "Födda",
      breakdown2: "Män",
      value: "11.15387833493878",
      user_defined: false,
    },
    {
      date: "2020-01-01",
      breakdown1: "Döda",
      breakdown2: "Kvinnor",
      value: "9.45282489031209",
      user_defined: false,
    },
    {
      date: "2020-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Kvinnor",
      value: "7.708407027473175",
      user_defined: false,
    },
    {
      date: "2020-01-01",
      breakdown1: "Födda",
      breakdown2: "Kvinnor",
      value: "10.631737195837136",
      user_defined: false,
    },
    {
      date: "2020-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Kvinnor",
      value: "4.384219524758128",
      user_defined: false,
    },
    {
      date: "2020-01-01",
      breakdown1: "Döda",
      breakdown2: "Män",
      value: "9.454805013434244",
      user_defined: false,
    },
    {
      date: "2020-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Män",
      value: "8.189020279552514",
      user_defined: false,
    },
    {
      date: "2020-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Män",
      value: "5.0413117596590515",
      user_defined: false,
    },
    {
      date: "2019-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Kvinnor",
      value: "10.538069186587487",
      user_defined: false,
    },
    {
      date: "2019-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Män",
      value: "4.869496868055708",
      user_defined: false,
    },
    {
      date: "2019-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Män",
      value: "11.879947973503285",
      user_defined: false,
    },
    {
      date: "2019-01-01",
      breakdown1: "Födda",
      breakdown2: "Män",
      value: "11.355679783764392",
      user_defined: false,
    },
    {
      date: "2019-01-01",
      breakdown1: "Döda",
      breakdown2: "Kvinnor",
      value: "8.718231021430206",
      user_defined: false,
    },
    {
      date: "2019-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Kvinnor",
      value: "4.368274135167657",
      user_defined: false,
    },
    {
      date: "2019-01-01",
      breakdown1: "Födda",
      breakdown2: "Kvinnor",
      value: "10.819063579365814",
      user_defined: false,
    },
    {
      date: "2019-01-01",
      breakdown1: "Döda",
      breakdown2: "Män",
      value: "8.4733595159488",
      user_defined: false,
    },
    {
      date: "2018-01-01",
      breakdown1: "Döda",
      breakdown2: "Kvinnor",
      value: "9.19247753475163",
      user_defined: false,
    },
    {
      date: "2018-01-01",
      breakdown1: "Döda",
      breakdown2: "Män",
      value: "8.831608664995086",
      user_defined: false,
    },
    {
      date: "2018-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Män",
      value: "4.891259748780636",
      user_defined: false,
    },
    {
      date: "2018-01-01",
      breakdown1: "Födda",
      breakdown2: "Män",
      value: "11.576999080980656",
      user_defined: false,
    },
    {
      date: "2018-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Kvinnor",
      value: "12.216409345826355",
      user_defined: false,
    },
    {
      date: "2018-01-01",
      breakdown1: "Födda",
      breakdown2: "Kvinnor",
      value: "11.065408716274609",
      user_defined: false,
    },
    {
      date: "2018-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Kvinnor",
      value: "4.290307674497179",
      user_defined: false,
    },
    {
      date: "2018-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Män",
      value: "13.699338718327766",
      user_defined: false,
    },
    {
      date: "2017-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Män",
      value: "15.31972812671785",
      user_defined: false,
    },
    {
      date: "2017-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Män",
      value: "4.797092547173115",
      user_defined: false,
    },
    {
      date: "2017-01-01",
      breakdown1: "Döda",
      breakdown2: "Män",
      value: "8.825296665408796",
      user_defined: false,
    },
    {
      date: "2017-01-01",
      breakdown1: "Födda",
      breakdown2: "Kvinnor",
      value: "11.148210053239849",
      user_defined: false,
    },
    {
      date: "2017-01-01",
      breakdown1: "Döda",
      breakdown2: "Kvinnor",
      value: "9.352903576717392",
      user_defined: false,
    },
    {
      date: "2017-01-01",
      breakdown1: "Födda",
      breakdown2: "Män",
      value: "11.658457713694123",
      user_defined: false,
    },
    {
      date: "2017-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Kvinnor",
      value: "13.225397909313598",
      user_defined: false,
    },
    {
      date: "2017-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Kvinnor",
      value: "4.215913196415739",
      user_defined: false,
    },
    {
      date: "2016-01-01",
      breakdown1: "Födda",
      breakdown2: "Kvinnor",
      value: "11.486998891566634",
      user_defined: false,
    },
    {
      date: "2016-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Män",
      value: "18.18066852344352",
      user_defined: false,
    },
    {
      date: "2016-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Män",
      value: "4.9463960902766155",
      user_defined: false,
    },
    {
      date: "2016-01-01",
      breakdown1: "Döda",
      breakdown2: "Män",
      value: "8.860547654092166",
      user_defined: false,
    },
    {
      date: "2016-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Kvinnor",
      value: "14.424287095884504",
      user_defined: false,
    },
    {
      date: "2016-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Kvinnor",
      value: "4.231397208161057",
      user_defined: false,
    },
    {
      date: "2016-01-01",
      breakdown1: "Födda",
      breakdown2: "Män",
      value: "12.00774652143568",
      user_defined: false,
    },
    {
      date: "2016-01-01",
      breakdown1: "Döda",
      breakdown2: "Kvinnor",
      value: "9.346208985255547",
      user_defined: false,
    },
    {
      date: "2015-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Kvinnor",
      value: "5.153808364994591",
      user_defined: false,
    },
    {
      date: "2015-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Män",
      value: "14.925878620943646",
      user_defined: false,
    },
    {
      date: "2015-01-01",
      breakdown1: "Födda",
      breakdown2: "Män",
      value: "12.067006748779042",
      user_defined: false,
    },
    {
      date: "2015-01-01",
      breakdown1: "Döda",
      breakdown2: "Kvinnor",
      value: "9.43526804905071",
      user_defined: false,
    },
    {
      date: "2015-01-01",
      breakdown1: "Döda",
      breakdown2: "Män",
      value: "9.021558858852403",
      user_defined: false,
    },
    {
      date: "2015-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Kvinnor",
      value: "12.3252787420293",
      user_defined: false,
    },
    {
      date: "2015-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Män",
      value: "6.1799249883288585",
      user_defined: false,
    },
    {
      date: "2015-01-01",
      breakdown1: "Födda",
      breakdown2: "Kvinnor",
      value: "11.253541884017057",
      user_defined: false,
    },
    {
      date: "2014-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Kvinnor",
      value: "11.867002111745055",
      user_defined: false,
    },
    {
      date: "2014-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Män",
      value: "14.18505656535803",
      user_defined: false,
    },
    {
      date: "2014-01-01",
      breakdown1: "Döda",
      breakdown2: "Män",
      value: "8.903912779337636",
      user_defined: false,
    },
    {
      date: "2014-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Män",
      value: "5.609329589675386",
      user_defined: false,
    },
    {
      date: "2014-01-01",
      breakdown1: "Födda",
      breakdown2: "Män",
      value: "12.129533848907279",
      user_defined: false,
    },
    {
      date: "2014-01-01",
      breakdown1: "Födda",
      breakdown2: "Kvinnor",
      value: "11.44772995098577",
      user_defined: false,
    },
    {
      date: "2014-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Kvinnor",
      value: "4.90388431862633",
      user_defined: false,
    },
    {
      date: "2014-01-01",
      breakdown1: "Döda",
      breakdown2: "Kvinnor",
      value: "9.35239476402095",
      user_defined: false,
    },
    {
      date: "2013-01-01",
      breakdown1: "Döda",
      breakdown2: "Kvinnor",
      value: "9.676416988941327",
      user_defined: false,
    },
    {
      date: "2013-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Kvinnor",
      value: "11.472708765353202",
      user_defined: false,
    },
    {
      date: "2013-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Kvinnor",
      value: "4.880026051095672",
      user_defined: false,
    },
    {
      date: "2013-01-01",
      breakdown1: "Födda",
      breakdown2: "Kvinnor",
      value: "11.411017518450961",
      user_defined: false,
    },
    {
      date: "2013-01-01",
      breakdown1: "Födda",
      breakdown2: "Män",
      value: "12.145339450314964",
      user_defined: false,
    },
    {
      date: "2013-01-01",
      breakdown1: "Utflyttningar",
      breakdown2: "Män",
      value: "5.637720675886729",
      user_defined: false,
    },
    {
      date: "2013-01-01",
      breakdown1: "Döda",
      breakdown2: "Män",
      value: "9.068708448501013",
      user_defined: false,
    },
    {
      date: "2013-01-01",
      breakdown1: "Inflyttningar",
      breakdown2: "Män",
      value: "12.551208811477835",
      user_defined: false,
    },
  ],
} as any;

const popDimensions: DimensionV2Dto[] = [
  {
    dimension_id: 5842,
    parent_id: null,
    data_column: "breakdown2",
    type: "stats_breakdown",
    label: "Kön",
    values: [
      {
        label: "Samtliga",
        id: 78008,
        parent_id: null,
        sort_order: 0,
        sort_mode: "fixed_top",
      },
      {
        label: "Kvinnor",
        id: 78006,
        parent_id: null,
        sort_order: 1,
      },
      {
        label: "Män",
        id: 78007,
        parent_id: null,
        sort_order: 2,
      },
    ],
    sort_order: 0,
  },
  {
    dimension_id: 5841,
    parent_id: null,
    data_column: "breakdown1",
    type: "stats_breakdown",
    label: "Förändringstyp",
    values: [
      {
        label: "Folkökning",
        id: 78010,
        parent_id: null,
        sort_order: 0,
        sort_mode: "fixed_top",
      },
      {
        label: "Födda",
        id: 78009,
        parent_id: null,
        sort_order: 1,
      },
      {
        label: "Döda",
        id: 78005,
        parent_id: null,
        sort_order: 2,
      },
      {
        label: "Inflyttningar",
        id: 78011,
        parent_id: null,
        sort_order: 3,
      },
      {
        label: "Utflyttningar",
        id: 78012,
        parent_id: null,
        sort_order: 4,
      },
      {
        label: "Justeringspost",
        id: 1372211,
        parent_id: null,
        sort_order: 5,
      },
    ],
    sort_order: 0,
  },
];

export const sampleMeasure: MeasureRegular = {
  data_id: 11692,
  area: "Befolkning",
  subarea: "Befolkningens storlek",
  subject: "Befolkningsförändring",
  measure: "Per kommun och typ av förändring, antal/invånare",
  value_type: "decimal",
  geo_types: ["country", "nuts3", "municipal"],
  resolution: "years-1",
  label: "befolk_forandring_andel",
  retired: false,
  default_measure: true,
  dimensions: popDimensions,
};

export const sampleMeasureSelectionBarChart: MeasureSelectionRegular = {
  available: [],
  availableDates: ["2022-01-01", "2023-01-01"],
  breakdowns: {
    breakdown1: [78009, 78005, 78011, 78012],
  },
  measure: sampleMeasure,
  valueType: "decimal",
};

export const sampleMeasureSelectionLineChart: MeasureSelectionRegular = {
  available: [],
  availableDates: ["2022-01-01", "2023-01-01"],
  breakdowns: {
    breakdown1: [78009, 78005, 78011, 78012],
    breakdown2: [78006, 78007],
  },
  measure: sampleMeasure,
  valueType: "decimal",
};
