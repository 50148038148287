/**
 * Make a text string as long as @maxLength characters.
 * If the original is longer than @maxLength, ellipsis is added.
 */
export function truncate(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substr(0, maxLength) + "...";
}
