import React from "react";

/**
 *
 * @param Component
 * @param eq Function that returns true if props are considered unchanged
 * @returns
 */
export function wrapWithCheckedRenders<P extends Record<string, unknown>>(
  Component: React.FunctionComponent<P>,
  eq: (prevProps: P, nextProps: P) => boolean
) {
  return class CheckedRender extends React.Component<P> {
    shouldComponentUpdate(next: P) {
      const prev = this.props;
      return !eq(prev, next);
    }

    render() {
      return <Component {...this.props}></Component>;
    }
  };
}
