import { useContext, useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { defined } from "../../../../../core/defined";
import { GeographiesSerializable } from "../../../../../domain/geography";
import {
  defaultMeasureSelectionGrouping,
  measureSelectionIsSurvey,
  setMeasureAvailableDatesMut,
  tryExtractSettings,
} from "../../../../../domain/measure";
import {
  MeasureThin,
  MeasureSelection,
  MeasureFull,
  MeasureSelectionGrouping,
} from "../../../../../domain/measure/definitions";
import {
  DataSelection,
  DataSelectionGrouping,
  MeasureSelectionRegular,
} from "../../../../../domain/selections/definitions";
import {
  CardUpdateCountContext,
  ShowDraftDataContext,
} from "../../../../contexts";
import { handleGetDefaultMeasure } from "../../../../requests/common_requests";
import { LatestSettingsFormat } from "../../../stats/default-settings/common";
import { DocCardStats } from "../../../stats/document-core/core";
import { dataSelectionsEqual } from "../../../stats/document-core/eq";
import {
  primarySelectionQuery,
  singleDataCardQuery,
} from "../../../stats/document-core/queries/dataCard";
import { updateDataCardInnerImmut } from "../../../stats/document-core/updates";
import { TimeResolution } from "../../../../../domain/time";
import { cardColors } from "../../../stats/document-core/queries/shared";
import { wait } from "../../../../../core/wait";
import { loadAndStoreDataStats } from "../../../stats/cardToDataStateStats";
import { useSaveCard } from "../../useSaveDocument";
import { useExtendedAppearanceSettings } from "../../useExtendedAppearanceSettings";

export function useGroupingSelectionChanger(
  cardId: string,
  oldSelection: DataSelection | undefined,
  geographies: GeographiesSerializable
) {
  const [card, setCard] = useRecoilState(
    singleDataCardQuery({ cardStateId: cardId })
  );

  const primarySelection = useRecoilValue(
    primarySelectionQuery({ cardStateId: cardId })
  );
  const primaryResolutionRaw =
    primarySelection?.measureSelection?.measure.resolution;
  const appearanceSettings = useExtendedAppearanceSettings();

  const maxResolutionGrouping = useMemo(() => {
    return defined(primaryResolutionRaw)
      ? TimeResolution.deserialize(primaryResolutionRaw)
      : TimeResolution.maximal();
  }, [primaryResolutionRaw]);

  const adminShowDraftData = useContext(ShowDraftDataContext);
  const handleSaveCard = useSaveCard();
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const changeHandler = useCallback(
    async (newDataSelection: DataSelectionGrouping) => {
      if (dataSelectionsEqual(newDataSelection, oldSelection)) {
        return Promise.resolve();
      }

      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      return Promise.resolve().then(async () => {
        const newMeasureSelection: MeasureSelection | undefined =
          newDataSelection.measureSelection;
        // 1) If setting a measure as opposed to just a subject path, make sure the measure has a default breakdown selection
        if (defined(newMeasureSelection)) {
          const updatedCard = await (newMeasureSelection.measure.data_id !==
          oldSelection?.measureSelection?.measure.data_id
            ? // 1.a) change measure
              cardWithNewMeasure(
                newMeasureSelection.measure,
                newDataSelection,
                newMeasureSelection.available,
                card,
                geographies,
                adminShowDraftData
              ).then(async (result) => {
                setCard(result.card);
                return result.card;
              })
            : Promise.resolve().then(async () => {
                setCard(updateCardGroupingSelection(card, newDataSelection));
                await wait(1);

                // 1.b) change breakdowns/filters: we only need to set available dates here,
                // but if the selected measure is a survey measure we don't need to update the available dates
                // since available dates are the same regardless of selected dimensions/breakdowns
                return (
                  measureSelectionIsSurvey(newMeasureSelection)
                    ? Promise.resolve(newMeasureSelection)
                    : setMeasureAvailableDatesMut<MeasureSelectionRegular>(
                        newMeasureSelection,
                        adminShowDraftData
                      )
                ).then((selection) =>
                  updateCardGroupingSelection(card, {
                    ...newDataSelection,
                    measureSelection: selection,
                  })
                );
              }));

          return loadAndStoreDataStats(
            setCard,
            shouldAbort,
            updatedCard,
            geographies,
            adminShowDraftData,
            appearanceSettings,
            cardColors(card),
            handleSaveCard
          );
        }

        // 2) If updating something else than the measure, make sure to fetch and set default measure
        // TODO: Not setting the new selection makes the UI seem to lag a bit. We may want to
        // to disable the selection interface while waiting for the new measure details to load.
        // updateSelection(newSelection);
        const updatedCard = await (!defined(newDataSelection.subjectPath[2])
          ? Promise.resolve(
              updateCardGroupingSelection(card, {
                ...newDataSelection,
              })
            )
          : handleGetDefaultMeasure(
              newDataSelection.subjectPath,
              false,
              true,
              maxResolutionGrouping,
              adminShowDraftData
            )
              .then((res) => {
                if (!defined(res)) {
                  return Promise.resolve(undefined);
                }
                const { availableMeasures, defaultMeasure } = res;
                return cardWithNewMeasure(
                  defaultMeasure,
                  newDataSelection,
                  availableMeasures,
                  card,
                  geographies,
                  adminShowDraftData
                );
              })
              .then(async (result) => {
                if (!defined(result)) {
                  throw new Error("Card not defined");
                }
                setCard(result.card);
                return result.card;
              }));

        return loadAndStoreDataStats(
          setCard,
          shouldAbort,
          updatedCard,
          geographies,
          adminShowDraftData,
          appearanceSettings,
          cardColors(card),
          handleSaveCard
        );
      });
    },
    [
      adminShowDraftData,
      card,
      appearanceSettings,
      geographies,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      maxResolutionGrouping,
      oldSelection,
      setCard,
    ]
  );

  return changeHandler;
}

function updateCardGroupingSelection(
  card: DocCardStats,
  selection: DataSelectionGrouping
): DocCardStats {
  return {
    ...card,
    data: updateDataCardInnerImmut(
      card.data,
      "groupingSelection",
      () => selection
    ),
  };
}

// FIXME: set the correct dates when setting a grouping selection
async function cardWithNewMeasure(
  newMeasure: MeasureFull,
  newSelection: DataSelection,
  availableMeasures: MeasureThin[],
  card: DocCardStats,
  geographies: GeographiesSerializable,
  adminShowDraftData: boolean
): Promise<{ card: DocCardStats; settings?: LatestSettingsFormat }> {
  return Promise.resolve(newMeasure).then(
    async (measureExtended: MeasureFull) => {
      const settings = await tryExtractSettings(measureExtended);
      const defaultMeasureSelection = defaultMeasureSelectionGrouping(
        measureExtended,
        availableMeasures,
        settings
      );
      return setMeasureAvailableDatesMut<MeasureSelectionGrouping>(
        { ...newSelection.measureSelection, ...defaultMeasureSelection },
        adminShowDraftData
      ).then((selection) => {
        const cardWithUpdatedSelection = updateCardGroupingSelection(card, {
          ...newSelection,
          measureSelection: selection,
        });
        return { card: cardWithUpdatedSelection, settings };
      });
    }
  );
}
