import { useCallback } from "react";
import { LoadingResult } from "../../../loading/LoadingResult";
import { HttpError } from "../../../../infra/HttpResult";
import { useLoadableHttpResource } from "../../../hooks/useLoadableResource";
import { DocCardStats } from "../../stats/document-core/core";
import { GeographiesSerializable } from "../../../../domain/geography";
import { useLoadStatsMeasureAsNewCardSingleDoc } from "./measure";

export function useLoadSingleStaticMeasure(
  measureId: number,
  geographies: GeographiesSerializable
): [LoadingResult<HttpError, DocCardStats>] {
  const loadMeasure = useLoadStatsMeasureAsNewCardSingleDoc(false);
  const getCard = useCallback(() => {
    return loadMeasure(measureId, geographies);
  }, [geographies, loadMeasure, measureId]);

  const [cardResource] = useLoadableHttpResource(getCard);
  return [cardResource];
}
