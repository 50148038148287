import { DefaultValue, selector } from "recoil";
import { DocListItem, docCardsListState } from "./atoms";

export const docCardsListQuery = selector<DocListItem[]>({
  key: "dataCardListQuery",
  get: ({ get }) => {
    const docList = get(docCardsListState);
    return docList;
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      throw new Error("Cannot set the default value for dataCardListQuery");
    }
    set(docCardsListState, newValue);
  },
});
