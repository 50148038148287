import { Checkbox, SearchBox } from "@fluentui/react";
import { useEffect, useState } from "react";
import { classNames } from "../lib/core/classNames";
import { defined } from "../lib/core/defined";

import "./InfostatMultiSearchBox.scss";

interface SearchResultItem<T> {
  key: string;
  label: string;
  multi?: boolean;
  item: T;
}

interface Props<T> {
  search: (input: string) => SearchResultItem<T>[];
  onSelect: (item: T, selected: boolean) => void;
  selectedKeys: string[];
  className?: string;
  disabled?: boolean;
  size?: "narrow" | "normal";
}
export function InfostatMultiSearchBox<T>(props: Props<T>) {
  const disabled = props.disabled;
  const [searchString, setSearchString] = useState("");
  const [showResults, setShowResults] = useState(false);

  const clickListener = () => {
    setShowResults(false);
  };

  useEffect(() => {
    if (!disabled) {
      document.body.addEventListener("click", clickListener);
      return function cleanup() {
        document.body.removeEventListener("click", clickListener);
      };
    }
  }, [disabled]);

  const results = props.search(searchString);
  return (
    <div
      className={classNames(
        "infostat-multi-search-box",
        props.size,
        props.className
      )}
      onClick={(event) => {
        if (!disabled) {
          event.stopPropagation();
          setShowResults(true);
        }
      }}
    >
      <SearchBox
        disabled={disabled}
        value={searchString}
        onChange={(event, value) => {
          setSearchString(value ?? "");
          setShowResults(true);
        }}
      ></SearchBox>
      {showResults && (
        <div className="search-results">
          {results.map((result) => (
            <div key={result.key} className="result-item">
              <Checkbox
                checked={props.selectedKeys.includes(result.key)}
                onChange={(_, checked) => {
                  if (defined(checked)) {
                    props.onSelect(result.item, checked);
                  }
                }}
              ></Checkbox>
              <span>{result.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
