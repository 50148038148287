import * as _ from "lodash";

import { defined } from "../../../../core/defined";
import { round } from "../../../../core/math/round";
import { RowBaseInterface, RowForecast, SurveyRow } from "../row";
import { fixedDecimalsFormatter, RawNumericData } from "../../format";
import {
  ChartType,
  Dimension,
  NO_VALUE_MARKER,
  Orientation,
} from "../core/definitions";
import { DEFAULT_FONT_SIZE_SOURCE_TEXT, TextStyle } from "../core/TextStyle";
import { calculcateTitleRows, getChartSourceText } from "../texts";
import { shortenMunicipalityLabel } from "../../../../domain/names";
import { assertDefined } from "../../../../core/assert";
import {
  defaultDimensionComparator,
  surveyDataDimensionsSorted,
} from "../dimensions";
import { TextRow, TextRowSet } from "../core/text_containers";
import {
  DataDescription,
  getApplicableDimensionHeader,
} from "../DataDescription";
import { DateDistanceGetter } from "../TimeSeriesLine";
import {
  DatasetHeaders,
  DatasetHeaderSurvey,
  DatasetInput,
} from "../../datasets/headers";
import { LOW_BASE_EXPLANATION } from "../../../../domain/survey_dataset";
import { TickLabelWithRawValue } from "../core/ticks";
import { normalizeTicksIntegersOnly } from "../ticks";
import { SortSpec } from "../../../../domain/measure";
import { Dictionary } from "../../../../core/dictionary";
import {
  ChartData,
  DataDimensionDetails,
  RowStringAccessor,
} from "./ChartData";
import { sortedLabelsAndDimensions } from "./sortedLabelsAndDimensions";
import {
  determineChartType,
  getRange,
  groupRows,
  labelSpecsToText,
} from "./chart_data_common";
import { invertComparator } from "../../../../core/order";
import {
  SURVEY_LOW_BASE_LABEL,
  MeasureSelection,
} from "../../../../domain/measure/definitions";
import { LabelsByDimension } from "../../datasets/DatasetGeneric";
import { chain } from "lodash";
import { extent } from "../../../../core/numeric/extent";
import { OrderedDimensions } from "./OrderedDimensions";
import { SurveyRowValue } from "../../../../infra/api_responses/survey_dataset";
import { getComputedValueOutputSettings } from "../../datasets/table/table_base/table_base";
import { DataOutputSettings } from "../../../state/stats/document-core/DataOutputSettings";

/**
 * Container for survey data
 */
export class ChartDataSurvey<RowProcessed extends SurveyRow>
  implements ChartData<SurveyRowValue, RowProcessed>
{
  private _hasLowBaseRows: boolean;
  public defaultCustomColors: string[] | undefined;
  constructor(
    private _dataset: DatasetInput<RowProcessed, DatasetHeaderSurvey>,
    private _labelsByDimension: LabelsByDimension,
    private _rangeExtentRaw: [number, number],
    private _rangeFormatter: (val: number) => string,
    private _dateFormatter: (input: string) => string,
    private _dateDistanceGetter: DateDistanceGetter,
    private _dataDescription: DataDescription,
    private _normalizeTicks: (
      ticks: TickLabelWithRawValue[]
    ) => TickLabelWithRawValue[],
    private _sortSpecsByDimension: Dictionary<SortSpec>,
    private _dataOutputSettings: DataOutputSettings
  ) {
    this._hasLowBaseRows = defined(
      _dataset.rows.find((r) => r.type() === "low_base")
    );
  }

  private _labels(dimension: string): string[] {
    return labelSpecsToText(
      this._labelsByDimension.find(([d]) => d === dimension)
    );
  }

  private _numLabels(dimension: string): number {
    const num = this._labelsByDimension.find(([d]) => d === dimension)?.[1]
      .length;
    assertDefined(num, "Dimension must exist");
    return num;
  }

  private get _dataDimensions(): string[] {
    return surveyDataDimensionsSorted(
      this._dataset.dimensionsSpec.variable
    ).sort(invertComparator(defaultDimensionComparator));
  }

  private get _dimensionFormatter() {
    return (dim: string, value: any): string => {
      switch (dim) {
        case Dimension.percentage:
          return this._rangeFormatter(value);
        case Dimension.value:
          return value;
        case Dimension.date:
          return this._dateFormatter(value);
        case Dimension.region:
          return shortenMunicipalityLabel(value) ?? value;
        default:
          return value;
      }
    };
  }

  private _dimensionToLabel(dimension: string): string | undefined {
    if (dimension === Dimension.grouping) {
      return this._dataDescription.groupingDimensionLabel;
    }
    return this._dataDescription.dimensionToLabel(dimension);
  }

  get outputSettings() {
    return this._dataOutputSettings;
  }

  liftedDimensions(): { [key: string]: string } {
    return this._dataset.header.liftedDimensions ?? {};
  }

  normalizeTicks(ticks: TickLabelWithRawValue[]): TickLabelWithRawValue[] {
    return this._normalizeTicks(ticks);
  }

  makeRangeFormatterRow(values: RawNumericData[]) {
    const formatter = this.makeDynamicRangeFormatter(values);
    return (r: RowProcessed) => {
      if (r.type() === "low_base") {
        return SURVEY_LOW_BASE_LABEL;
      } else if (r.type() === "invalid_choice") {
        return NO_VALUE_MARKER;
      }
      const range = r.range();
      if (range === null) {
        return NO_VALUE_MARKER;
      }

      return formatter(range);
    };
  }

  get computedValueOutputSettings() {
    return getComputedValueOutputSettings(
      this._dataset.valueTypeOriginal,
      this._dataOutputSettings
    );
  }

  getRangeFormatter() {
    return (val: RawNumericData) => {
      if (typeof val === "number") {
        return this._rangeFormatter(val);
      }
      return val;
    };
  }

  makeDynamicRangeFormatter(values: RawNumericData[]) {
    return this.getRangeFormatter();
  }

  makeTicksRangeFormatter(
    values: RawNumericData[]
  ): (val: RawNumericData) => string {
    return (val: RawNumericData) => {
      if (typeof val === "number") {
        return round(val, 0) + "";
      }
      return val;
    };
  }

  hasDrawableRegionDimension(): boolean {
    return (
      defined(
        this._labelsByDimension.find(([dim]) => dim === Dimension.region)
      ) || defined(this._dataset.header.liftedDimensions?.[Dimension.region])
    );
  }

  dateDistanceGetter() {
    return this._dateDistanceGetter;
  }

  groupedRows(groupAccessor: RowStringAccessor<RowProcessed>) {
    return groupRows(this._dataset.rows, groupAccessor);
  }

  /** Forecasting not implemented for survey */
  groupedForecastRows(groupAccessor: RowStringAccessor<RowForecast>) {
    return [];
  }

  domainExtent<T extends Date>(accessor: (row: RowProcessed) => T) {
    const rows = this._dataset.rows;
    const [domainMin, domainMax] = extent(
      chain(rows).map(accessor).filter(defined).value()
    );
    if (!defined(domainMin) || !defined(domainMax)) {
      throw new Error("Expected both domainMin and domainMax to be defined!");
    }
    const domainExtent: [T, T] = [domainMin, domainMax];
    return domainExtent;
  }

  get uniqueDates() {
    return this._dataDimensions.includes(Dimension.date)
      ? Object.keys(
          _.groupBy(this._dataset.rows, (r) => r.dimension(Dimension.date))
        )
      : undefined;
  }

  /**
   * Positioned and styled chart title text
   */
  titleRows(maxWidth: number, settings: DataOutputSettings) {
    return calculcateTitleRows(this._dataDescription, maxWidth, settings);
  }

  get dataDescription(): DataDescription {
    return this._dataDescription;
  }

  get dataDimensions(): string[] {
    return this._dataDimensions;
  }

  get nonPrimaryDataDimensions(): string[] {
    return this._dataDimensions.slice(1);
  }

  get rows(): RowProcessed[] {
    return this._dataset.rows;
  }

  get footnotes(): TextRowSet | undefined {
    if (this._hasLowBaseRows) {
      const textStyle = new TextStyle(10);
      return new TextRowSet([new TextRow(LOW_BASE_EXPLANATION, textStyle, 5)]);
    }
    return undefined;
  }

  source(maxWidth: number, baseLabelSize?: number | null): TextRowSet {
    const datasetHeaders = new DatasetHeaders(
      this._dataset.header.main,
      this._dataset.groupHeader
    );
    return getChartSourceText(
      datasetHeaders,
      this._dataOutputSettings.customSourceText,
      baseLabelSize ?? DEFAULT_FONT_SIZE_SOURCE_TEXT,
      maxWidth
    );
  }

  /**
   * Returns an unsorted list of [dimension, labels]
   *
   * Does not include labels for the primary dimension since they are
   * not labels in the same sense.
   */
  get labelsByDimension(): LabelsByDimension {
    return this._labelsByDimension;
  }

  prepareDimensionsAndLabelsBarChart(
    barOrientation: Orientation,
    fixedNonPrimaryDimensions?: string[]
  ): DataDimensionDetails<string> {
    const { sortedDimensionsAndLabels, legendDimension } =
      sortedLabelsAndDimensions(
        barOrientation,
        this._dataDimensions,
        this.rows,
        this._numLabels.bind(this),
        this._labelsByDimension,
        this._sortSpecsByDimension,
        true,
        fixedNonPrimaryDimensions
      );

    const legendDimensionLabel = defined(legendDimension)
      ? this._dataDescription.dimensionToLabel(legendDimension)
      : undefined;

    const domainDimension = sortedDimensionsAndLabels[0]?.dimension;
    const domainDimensionLabel = defined(domainDimension)
      ? this._dataDescription.dimensionToLabel(domainDimension)
      : "";

    return {
      legendDimension,
      nonPrimaryDimensions: sortedDimensionsAndLabels.map((l) => l.dimension),
      nonPrimaryLabelsByDimension: sortedDimensionsAndLabels,
      dataDimensions: this._dataDimensions,
      legendDimensionLabel,
      domainDimensionLabel,
    };
  }

  numLabels(dimension: string): number {
    return this._numLabels(dimension);
  }

  dimensionToLabel(dimension: string): string | undefined {
    return this._dimensionToLabel(dimension);
  }

  dimensionHeader(dimension: string): string | undefined {
    return getApplicableDimensionHeader(
      dimension,
      this._dataOutputSettings,
      this._dataDescription
    );
  }

  /**
   * Get the range extent, extending to zero if applicable and wanted
   */
  rangeExtent(extendToZero: boolean): [number, number] {
    const [rangeMinRaw, rangeMaxRaw] = this._rangeExtentRaw;
    const rangeMin = extendToZero ? Math.min(rangeMinRaw, 0) : rangeMinRaw;
    const rangeMax = extendToZero ? Math.max(rangeMaxRaw, 0) : rangeMaxRaw;
    return [rangeMin, rangeMax];
  }

  get rangeFormatter() {
    return this._rangeFormatter;
  }

  get dimensionFormatter() {
    return this._dimensionFormatter;
  }

  get hierarchicalDimensionGroups(): string[][] {
    return [];
  }

  chartType(chartInnerWidth: number): ChartType {
    return determineChartType(this, chartInnerWidth, this._dataOutputSettings);
  }

  static fromSurveyDataset(
    dataInput: DatasetInput<SurveyRow, DatasetHeaderSurvey>,
    labelsByDimension: LabelsByDimension,
    dateFormatter: (input: string) => string,
    dateDistanceGetter: DateDistanceGetter,
    measureSelections: MeasureSelection[],
    dataDescription: DataDescription,
    sortSpecs: Dictionary<SortSpec>,
    dataOutputSettings: DataOutputSettings
  ) {
    const rows = dataInput.rows;
    const rangeFormatter = dataOutputSettings.showSurveyValueFraction
      ? fixedDecimalsFormatter(1)
      : fixedDecimalsFormatter(0);
    const rangeAccessor = (row: RowBaseInterface<SurveyRowValue>) =>
      row.range();
    const rangeExtentRaw = getRange(rows, rangeAccessor, [0, 100]);

    // If all values in the chart are 0, set a range from 0 - 100 just to draw
    // a reasonable-looking (but empty) chart
    const rangeExtent: [number, number] = rangeExtentRaw.every(
      (value) => value === 0
    )
      ? [0, 100]
      : [rangeExtentRaw[0], Math.max(rangeExtentRaw[1], 1)]; // We don't allow a smaller scale than 0 - 1 for survey datasets

    if (measureSelections.length > 2) {
      throw new Error("More than 2 measure selections not supported");
    }

    return new ChartDataSurvey(
      dataInput,
      labelsByDimension,
      rangeExtent,
      rangeFormatter,
      dateFormatter,
      dateDistanceGetter,
      dataDescription,
      normalizeTicksIntegersOnly,
      sortSpecs,
      dataOutputSettings
    );
  }

  lineChartDataProps() {
    const dimensions = this._dataDimensions;
    const sortedDataDimensions = surveyDataDimensionsSorted(dimensions);
    const sortedGroupingDimensions = dimensions.filter(
      (d) => d !== Dimension.percentage && d !== Dimension.date
    );

    // We have a single legend dimension only if there exactly three data dimensions
    // (i.e. value, date, and breakdown1)
    const singleLegendDimension =
      sortedGroupingDimensions.length === 1
        ? sortedGroupingDimensions[0]
        : undefined;
    const orderedDimensions = new OrderedDimensions(
      sortedDataDimensions.map((d) => d),
      singleLegendDimension
    );

    return {
      singleLegendDimension: orderedDimensions.legendDimension,
      plainDimensionsSorted: sortedGroupingDimensions,
    };
  }
}
