import { useState, useCallback, useMemo, useEffect } from "react";
import {
  Checkbox,
  ChoiceGroup,
  IChoiceGroupOption,
  TextField,
} from "@fluentui/react";

import { AlertBox } from "../../../../../components/AlertBox";
import {
  ButtonsFooter,
  ButtonsFooterLeft,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import {
  HttpErrorNotice,
  displayHttpError,
} from "../../../../../components/errors/HttpErrorNotice";
import { defined } from "../../../../../lib/core/defined";
import { HttpResult, HttpError } from "../../../../../lib/infra/HttpResult";
import { logger } from "../../../../../lib/infra/logging";
import { Button } from "../../../../../components/Button";
import { useAppMessages } from "../../../../../lib/application/hooks/useAppMessages";
import { copyToClipboard } from "../../../../../lib/application/browser/copyToClipboard";
import { DefaultLoadingStretchedToFit } from "../../../../../components/Loading";
import {
  DataLinkParams,
  getFullDataLinkDataFrame,
  getFullDataLinkMikro,
  getFullDataLinkMikroMetadataPath,
  getFullDataLinkStats,
  getFullDataLinkStatsMetadataPath,
  getFullDataLinkSurvey,
  getFullDataLinkSurveyMetadataPath,
} from "../../../../../lib/paths";
import { DataLinkDto } from "../../../../../lib/infra/api_responses/data_links";
import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import {
  anyLinkExportFeatureAvailable,
  DataLinkFeatureSupport,
} from "../../../../../lib/domain/data_links";

type Delimiter = "" | "semicolon";
type NumberFormat = "" | "se";

export function ExportViaLinkDialog(props: {
  handleClose: () => void;
  featureSupport: DataLinkFeatureSupport;
  exportFunc: (
    params: DataLinkParams,
    description: string
  ) => Promise<HttpResult<{ link: string }>>;
}) {
  const [errorMessage, setErrorMessage] = useState<
    { type: "string"; error: string } | { type: "httpError"; error: HttpError }
  >();
  const [description, setDescription] = useState<string>("");
  const [format, setFormat] = useState<string | null>(null);
  const [delimiter, setDelimiter] = useState<Delimiter>("");
  const [numberFormat, setNumberFormat] = useState<NumberFormat>("");
  const [wideFormat, setWideFormat] = useState(false);
  const [omitNullMarkers, setOmitNullMarkers] = useState(false);
  const [swedishGeocodes, setSwedishGeocodes] = useState(true);
  const [longGeoNames, setLongGeoNames] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [exportLink, setExportLink] = useState<string | null>(null);

  const appMessages = useAppMessages();

  const handleExport = useCallback(() => {
    setErrorMessage(undefined);
    if (!defined(format)) {
      appMessages?.add("error", "Välj ett exportformat.");
      return;
    }

    if (!nonEmptyString(description)) {
      appMessages?.add("error", "Ange en beskrivning.");
      return;
    }

    setIsExporting(true);
    try {
      return props
        .exportFunc(
          {
            format,
            delimiter: format === "csv" ? delimiter : undefined,
            numberFormat: format === "csv" ? numberFormat : undefined,
            omitNullMarkers: omitNullMarkers,
            longGeoNames: longGeoNames,
            swedishGeocodes: swedishGeocodes,
            wideFormat: wideFormat,
          },
          description
        )
        .then((result) => {
          setIsExporting(false);
          result.match({
            ok: (response) => setExportLink(response.link),
            err: (err) => {
              logger.error(err);
              appMessages?.add("error", displayHttpError(err));
            },
          });
        });
    } catch (err) {
      logger.error(err);
      appMessages?.add("error", "Kunde inte exportera vald data.");
      setIsExporting(false);
    }
  }, [
    appMessages,
    delimiter,
    description,
    format,
    longGeoNames,
    numberFormat,
    omitNullMarkers,
    props,
    swedishGeocodes,
    wideFormat,
  ]);

  return (
    <FluentModal
      onClose={props.handleClose}
      isOpen={true}
      title="Exportera data"
    >
      <FluentModalBody>
        <p>Välj ett format för att exportera data och få en länk till filen.</p>
        <FormatOptions
          disabled={defined(exportLink)}
          format={format}
          setFormat={setFormat}
          numberFormat={numberFormat}
          setNumberFormat={setNumberFormat}
          delimiter={delimiter}
          setDelimiter={setDelimiter}
          wideFormat={wideFormat}
          setWideFormat={setWideFormat}
          omitNullMarkers={omitNullMarkers}
          setOmitNullMarkers={setOmitNullMarkers}
          swedishGeocodes={swedishGeocodes}
          setSwedishGeocodes={setSwedishGeocodes}
          longGeoNames={longGeoNames}
          setLongGeoNames={setLongGeoNames}
          featureSupport={props.featureSupport}
        />
        <section>
          <TextField
            disabled={defined(exportLink)}
            label="Beskrivning"
            onChange={(e, newValue) => setDescription(newValue ?? "")}
            value={description}
            required
          />
        </section>

        <>
          {isExporting && <DefaultLoadingStretchedToFit />}
          {defined(errorMessage) && errorMessage.type === "string" && (
            <AlertBox intent="warning">
              <p>{errorMessage.error}</p>
            </AlertBox>
          )}
          {defined(errorMessage) && errorMessage.type === "httpError" && (
            <HttpErrorNotice error={errorMessage.error}></HttpErrorNotice>
          )}
          {defined(exportLink) && (
            <AlertBox intent="success">
              <p>
                Länk skapad!
                <div className="flex-row">
                  <TextField
                    className="flex-grow"
                    readOnly
                    value={exportLink}
                  />
                  <Button
                    title="Kopiera"
                    onClick={() => copyToClipboard(exportLink)}
                  />
                </div>
              </p>
            </AlertBox>
          )}
        </>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterLeft>
            <Button onClick={props.handleClose} title="Avbryt"></Button>
          </ButtonsFooterLeft>
          <ButtonsFooterRight>
            <Button
              disabled={!defined(format) || isExporting || defined(exportLink)}
              intent="primary"
              onClick={handleExport}
              title="Skapa länk"
            />
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModal>
  );
}

export function ShowDataLinkDialog(props: {
  handleClose: VoidFunction;
  featureSupport: DataLinkFeatureSupport;
  link: Pick<DataLinkDto, "link" | "type">;
}) {
  const [format, setFormat] = useState<string | null>(null);
  const [delimiter, setDelimiter] = useState<Delimiter>("");
  const [numberFormat, setNumberFormat] = useState<NumberFormat>("");
  const [producedLink, setProducedLink] = useState<string | null>(null);
  const [wideFormat, setWideFormat] = useState(false);
  const [swedishGeocodes, setSwedishGeocodes] = useState(true);
  const [longGeoNames, setLongGeoNames] = useState(false);
  const [omitNullMarkers, setOmitNullMarkers] = useState(false);

  useEffect(() => {
    if (format === null) {
      return;
    }

    let fullLink: string;
    const opts: DataLinkParams = {
      format,
      delimiter,
      numberFormat,
      omitNullMarkers,
      longGeoNames,
      swedishGeocodes,
      wideFormat,
    };
    switch (props.link.type) {
      case "dataframe":
        fullLink = getFullDataLinkDataFrame(props.link.link, opts);
        break;
      case "mikro":
        fullLink = getFullDataLinkMikro(props.link.link, opts);
        break;
      case "stats":
        fullLink = getFullDataLinkStats(props.link.link, opts);
        break;
      case "survey":
        fullLink = getFullDataLinkSurvey(props.link.link, opts);
        break;
      default:
        logger.error("Unknown link type", props.link);
        return;
    }
    setProducedLink("...");
    const handle = setTimeout(() => {
      setProducedLink(fullLink);
    }, 400);
    return () => clearTimeout(handle);
  }, [
    delimiter,
    format,
    longGeoNames,
    numberFormat,
    omitNullMarkers,
    props.link,
    swedishGeocodes,
    wideFormat,
  ]);

  const metadataLink = useMemo(() => {
    switch (props.link.type) {
      case "mikro":
        return getFullDataLinkMikroMetadataPath(props.link.link);
      case "stats":
        return getFullDataLinkStatsMetadataPath(props.link.link);
      case "survey":
        return getFullDataLinkSurveyMetadataPath(props.link.link);
      case "dataframe":
        return undefined;
      default:
        return undefined;
    }
  }, [props.link.link, props.link.type]);

  return (
    <FluentModal
      onClose={props.handleClose}
      isOpen={true}
      width="md"
      title="Datalänk"
    >
      <FluentModalBody>
        <>
          {!nonEmptyString(props.link.type) && (
            <AlertBox intent="warning">
              <p>Okänd länktyp.</p>
            </AlertBox>
          )}
        </>
        <p>Välj ett format för att få en komplett länk till filen.</p>
        <FormatOptions
          disabled={false}
          format={format}
          setFormat={setFormat}
          numberFormat={numberFormat}
          setNumberFormat={setNumberFormat}
          delimiter={delimiter}
          setDelimiter={setDelimiter}
          wideFormat={wideFormat}
          setWideFormat={setWideFormat}
          omitNullMarkers={omitNullMarkers}
          setOmitNullMarkers={setOmitNullMarkers}
          swedishGeocodes={swedishGeocodes}
          setSwedishGeocodes={setSwedishGeocodes}
          longGeoNames={longGeoNames}
          setLongGeoNames={setLongGeoNames}
          featureSupport={props.featureSupport}
        />

        <>
          {defined(producedLink) && (
            <AlertBox intent="success">
              <label>
                <strong>Länk till data</strong>
              </label>
              <div>
                <div className="flex-row">
                  <TextField
                    className="flex-grow"
                    autoFocus
                    readOnly
                    value={producedLink}
                  />
                  <Button
                    title="Kopiera"
                    onClick={() => copyToClipboard(producedLink)}
                  />
                </div>
              </div>
            </AlertBox>
          )}
          {defined(metadataLink) && (
            <AlertBox>
              <>
                <label>
                  <strong>Länk till metadata</strong>
                </label>
                <div>
                  <div className="flex-row">
                    <TextField
                      className="flex-grow"
                      readOnly
                      value={metadataLink}
                    />
                    <Button
                      title="Kopiera"
                      onClick={() => copyToClipboard(metadataLink)}
                    />
                  </div>
                </div>
              </>
            </AlertBox>
          )}
        </>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooter>
          <ButtonsFooterRight>
            <Button onClick={props.handleClose} title="Klar"></Button>
          </ButtonsFooterRight>
        </ButtonsFooter>
      </FluentModalFooter>
    </FluentModal>
  );
}

function FormatOptions(props: {
  featureSupport: DataLinkFeatureSupport;
  format: string | null;
  setFormat: (format: string | null) => void;
  delimiter: Delimiter;
  setDelimiter: (delimiter: Delimiter) => void;
  numberFormat: NumberFormat;
  setNumberFormat: (numberFormat: NumberFormat) => void;
  omitNullMarkers: boolean;
  setOmitNullMarkers: (omitNullMarkers: boolean) => void;
  wideFormat: boolean;
  setWideFormat: (wideFormat: boolean) => void;
  swedishGeocodes: boolean;
  setSwedishGeocodes: (swedishGeocodes: boolean) => void;
  longGeoNames: boolean;
  setLongGeoNames: (longGeoNames: boolean) => void;
  disabled: boolean;
}) {
  const {
    disabled,
    format,
    setFormat,
    delimiter,
    setDelimiter,
    numberFormat,
    setNumberFormat,
    wideFormat,
    omitNullMarkers,
    setOmitNullMarkers,
    setWideFormat,
    featureSupport,
    swedishGeocodes,
    setSwedishGeocodes,
    longGeoNames,
    setLongGeoNames,
  } = props;

  const options: IChoiceGroupOption[] = useMemo(
    () => [
      { key: "csv", text: "CSV" },
      { key: "json-dw", text: "JSON", disabled: !featureSupport.jsonExport },
    ],
    [featureSupport.jsonExport]
  );
  const formatOptions: IChoiceGroupOption[] = useMemo(
    () => [
      { key: "", text: "Komma (,)" },
      { key: "semicolon", text: "Semikolon (;)" },
    ],
    []
  );
  const numberFormatOptions: IChoiceGroupOption[] = useMemo(
    () => [
      { key: "", text: "Standard" },
      { key: "se", text: "Svensk (komma som decimaltecken)" },
    ],
    []
  );

  return (
    <>
      <section>
        <ChoiceGroup
          disabled={disabled}
          options={options}
          onChange={(e, option) => setFormat(option?.key || null)}
          label="Format"
          selectedKey={format}
        />
      </section>
      <>
        {format === "csv" && (
          <section className="">
            <ChoiceGroup
              className="flex-row"
              disabled={disabled}
              options={formatOptions}
              label="Avgränsare"
              onChange={(e, option) =>
                setDelimiter((option?.key ?? "") as "" | "semicolon")
              }
              selectedKey={delimiter}
            />
            <ChoiceGroup
              disabled={disabled}
              options={numberFormatOptions}
              label="Decimaltecken"
              onChange={(e, option) =>
                setNumberFormat((option?.key ?? "") as "" | "se")
              }
              selectedKey={numberFormat}
            />
          </section>
        )}
      </>
      {anyLinkExportFeatureAvailable(featureSupport) && (
        <section>
          <h3>Avancerade inställningar</h3>
          {featureSupport.nullMarkersControl && (
            <Checkbox
              className="margin-bottom-sm"
              disabled={disabled}
              label="Dölj nullmarkörer"
              checked={omitNullMarkers}
              onChange={(e, checked) => {
                if (!defined(checked)) {
                  return;
                }
                setOmitNullMarkers(checked);
              }}
            />
          )}
          {featureSupport.wideFormat && (
            <Checkbox
              className="margin-bottom-sm"
              disabled={disabled}
              label="Brett format"
              onChange={(e, checked) => {
                if (!defined(checked)) {
                  return;
                }
                setWideFormat(checked);
              }}
              checked={wideFormat}
            />
          )}
          {featureSupport.longGeoNames && (
            <Checkbox
              className="margin-bottom-sm"
              disabled={disabled}
              label="Kompletta kommun- och regionnamn"
              onChange={(e, checked) => {
                if (!defined(checked)) {
                  return;
                }
                setLongGeoNames(checked);
              }}
              checked={longGeoNames}
            />
          )}
          {featureSupport.geocodeFormat && (
            <Checkbox
              disabled={disabled}
              label="Svenska geokoder"
              onChange={(e, checked) => {
                if (!defined(checked)) {
                  return;
                }
                setSwedishGeocodes(checked);
              }}
              checked={swedishGeocodes}
            />
          )}
        </section>
      )}
    </>
  );
}
