import React, { useMemo } from "react";

import { gridLineStyleOptions } from "../../lib/application/stats/shared/core/colors/colorSchemes";
import { Dropdown, IRenderFunction, ISelectableOption } from "@fluentui/react";
import { defined } from "../../lib/core/defined";

interface GridLineDropdownProps {
  selectedOption: string;
  handleOutputSettingChange: (value: string) => void;
  label: string;
}

const onRender: IRenderFunction<ISelectableOption<any>> = (item) => {
  if (!item) {
    return null;
  }
  return (
    <svg viewBox="0 0 40 1">
      <line
        x1="0"
        y1="0"
        x2="40"
        y2="0"
        strokeDasharray={item.key}
        stroke="black"
      />
    </svg>
  );
};

export const GridLineDropdown: React.FC<GridLineDropdownProps> = ({
  label,
  selectedOption,
  handleOutputSettingChange,
}) => {
  const options = useMemo(() => gridLineStyleOptions, []);

  return (
    <Dropdown
      options={options.map((option) => ({
        key: option,
        text: "",
      }))}
      label={label}
      selectedKey={selectedOption}
      onChange={(e, option) => {
        if (!option) {
          return;
        }
        handleOutputSettingChange(option.key as string);
      }}
      onRenderTitle={(options) => {
        const option = options?.[0];
        if (!defined(option)) {
          return null;
        }
        return onRender(option);
      }}
      onRenderOption={onRender}
    ></Dropdown>
  );
};
