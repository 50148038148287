import { useCallback } from "react";
import { useRecoilCallback } from "recoil";

import { thumbnailQuery } from "./document-thumbnails/definitions";

type GenerationFunc = () => Promise<string>;

export function useSetThumbnail(cardId: string) {
  const setThumbnail = useRecoilCallback(
    ({ set }) =>
      (thumbnail: string) => {
        set(thumbnailQuery(cardId), {
          cardId,
          type: "chart",
          imageDataUri: thumbnail,
        });
      },
    [cardId]
  );

  const generateThumbnail = useCallback(
    (generateThumbnail: GenerationFunc) => {
      generateThumbnail().then((thumbnail) => {
        setThumbnail(thumbnail);
      });
    },
    [setThumbnail]
  );

  return generateThumbnail;
}
