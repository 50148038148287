import { logger } from "../../infra/logging";
import { AppMessagesHandler } from "../AppMessagesHandler";

export function copyToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  // Don't scroll when focusing
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    logger.error("Failed to copy text to clipboard", err);
  }

  document.body.removeChild(textArea);
}

export function copyImageToClipboard(blob: Blob): Promise<unknown> {
  const item = new ClipboardItem({ "image/png": blob });
  return navigator.clipboard.write([item]);
}

export async function pasteFromClipboard(appMessages?: AppMessagesHandler) {
  try {
    const text = await navigator.clipboard.readText();
    return text;
  } catch (err) {
    appMessages?.add(
      "error",
      "Kunde inte läsa från urklipp: " + err?.toString?.()
    );
  }
}
