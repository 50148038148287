import { useCallback } from "react";

import { useHandleUpdateCardDataState } from "./shared/useHandleUpdateCardDataState";
import { updateDataCardInnerImmut } from "../../stats/document-core/updates";
import { DateRangeRaw } from "../../../../domain/measure/definitions";
import { DocCardStats } from "../../stats/document-core/core";
import { GeographiesSerializable } from "../../../../domain/geography";
import { defined } from "../../../../core/defined";
import { useSaveCard } from "../useSaveDocument";
import { availableTimespanQuery } from "../../stats/document-core/queries/generalDataCard";
import { useRecoilValue } from "recoil";
import { cardColors } from "../../stats/document-core/queries/shared";
import { useApplyChangeStats } from "../../stats/useApplyChangeStats";

export function useChangeTime(card: DocCardStats) {
  const availableTimespan = useRecoilValue(
    availableTimespanQuery({ cardStateId: card.id })
  );

  const handleApply = useApplyChangeStats(card.id);

  const handleUpdateTime = useCallback(
    async (range: DateRangeRaw) => {
      const cardDataUpdated = updateDataCardInnerImmut(
        card.data,
        "timeSelection",
        () => range
      );
      if (defined(availableTimespan)) {
        const latestAvailablePoint =
          availableTimespan.type === "range"
            ? availableTimespan.range[1]
            : availableTimespan.timePoints[
                availableTimespan.timePoints.length - 1
              ];
        cardDataUpdated.lockToLatestTime = range[1] === latestAvailablePoint;
      }
      const updatedCard = { ...card, data: cardDataUpdated };
      handleApply(updatedCard);
    },
    [availableTimespan, card, handleApply]
  );

  return handleUpdateTime;
}

export function useUnsetLockToLatestTime(
  card: DocCardStats,
  geographies: GeographiesSerializable,
  adminShowDraftData: boolean
) {
  const handleUpdateCardDataState = useHandleUpdateCardDataState(
    card.id,
    geographies,
    adminShowDraftData
  );
  const handleSaveCard = useSaveCard();

  return useCallback(async () => {
    const updatedCard = {
      ...card,
      data: { ...card.data, lockToLatestTime: false },
    };
    await handleUpdateCardDataState(updatedCard, cardColors(updatedCard));
    handleSaveCard?.(updatedCard);
  }, [card, handleSaveCard, handleUpdateCardDataState]);
}

/** Set the lock flag, but do not refetch data */
export function useActivateLockToLatestTime(
  card: DocCardStats,
  geographies: GeographiesSerializable,
  adminShowDraftData: boolean
) {
  const handleUpdateCardDataState = useHandleUpdateCardDataState(
    card.id,
    geographies,
    adminShowDraftData
  );
  const handleSaveCard = useSaveCard();

  return useCallback(async () => {
    const updatedCard = {
      ...card,
      data: { ...card.data, lockToLatestTime: true },
    };
    await handleUpdateCardDataState(updatedCard, cardColors(updatedCard));
    handleSaveCard?.(updatedCard);
  }, [card, handleSaveCard, handleUpdateCardDataState]);
}
