import {
  Array as ArrayRT,
  Boolean as BooleanRT,
  Optional,
  Record,
  Static,
} from "runtypes";
import { logger } from "../infra/logging";
import { DataOutputViewRT } from "./state/stats/document-core/core";

const EmbedSettingsRT = Record({
  showBreakdowns: Optional(BooleanRT),
  showGeoSelector: Optional(BooleanRT),
  showTimeline: Optional(BooleanRT),

  hideTabs: Optional(ArrayRT(DataOutputViewRT)),

  showToolbar: Optional(BooleanRT),
  // toolbar sub settings, valid only if showToolbar is true
  showToolbarSettings: Optional(BooleanRT),
  showToolbarDownload: Optional(BooleanRT),
});

export type EmbedSettings = Static<typeof EmbedSettingsRT>;

export function parseEmbedSettings(settings: unknown): EmbedSettings {
  try {
    return EmbedSettingsRT.check(settings);
  } catch (e) {
    logger.error("Failed to parse embed settings", e);
    return {};
  }
}

export interface EmbeddedModeToolbarSettings
  extends Pick<
    EmbedSettings,
    "showToolbar" | "showToolbarSettings" | "showToolbarDownload"
  > {}
