import { config } from "../../config";
import { logger } from "../infra/logging";

export function getLatestClientVersion(): Promise<number> {
  return fetch("/latest_version.txt", { cache: "no-cache" })
    .then((response) => response.text())
    .then((latestVersionRaw) => {
      const latestVersion = parseInt(latestVersionRaw);
      return latestVersion;
    });
}

export function shouldReloadClientApp(): Promise<boolean> {
  return getLatestClientVersion()
    .then((latestVersion) => {
      const currentVersion = parseInt(config.appVersion);
      return currentVersion !== latestVersion;
    })
    .catch(() => {
      logger.warn("Failed to get latest version from server");
      return false;
    });
}
