import { useCallback, useContext } from "react";
import { useGeoTree } from "../../../../../views/stats/docs/cards/micro/useGeoTree";
import { FilterMeasureMicro } from "../../../../domain/measure/definitions";
import {
  CardUpdateCountContext,
  ShowDraftDataContext,
} from "../../../contexts";
import { useReadMicroCardState } from "./updates";
import { defined } from "../../../../core/defined";
import { logger } from "../../../../infra/logging";
import { replaceAtIndexImmut } from "../../generic";
import { singleMicroCardQuery } from "../../stats/document-core/queries/microCard";
import { useRecoilState } from "recoil";
import { useExtendedAppearanceSettings } from "../useExtendedAppearanceSettings";
import { cardColors } from "../../stats/document-core/queries/shared";
import { loadAndStoreDataMicro } from "../../stats/cardToDataStateMicro";
import { useSaveCard } from "../useSaveDocument";
import { MicroDataFilter } from "../../stats/document-core/core-micro";

export function useFilterCallbacks(
  cardId: string,
  filterMeasures: FilterMeasureMicro[]
) {
  const geoTree = useGeoTree();
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const readCard = useReadMicroCardState(cardId);
  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: cardId })
  );
  const handleSaveCard = useSaveCard();
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const appearanceSettings = useExtendedAppearanceSettings();

  const handleSetSingleBreakdownValue = useCallback(
    async (filterMeasureIndex: number, dataColumn: string, key?: number) => {
      const currentFilter = filterMeasures[filterMeasureIndex];
      if (!defined(currentFilter)) {
        return;
      }
      if (!defined(geoTree)) {
        logger.error("Geo tree not defined");
        return;
      }

      const prev = readCard();
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const selectedMeasure = currentFilter?.measureSelection?.measure;
      const selectedMeasureId = selectedMeasure?.id;
      if (!defined(selectedMeasureId)) {
        throw new Error("selectedMeasureId is not defined");
      }

      const selectedDimensionsUpdated = {
        ...currentFilter.measureSelection?.selectedDimensions,
        [dataColumn]: defined(key) ? [key] : undefined,
      };

      const currentMeasureSelection = currentFilter.measureSelection;
      if (!defined(currentMeasureSelection)) {
        throw new Error("currentMeasureSelection is not defined");
      }

      const filterMeasure: FilterMeasureMicro = {
        ...currentFilter,
        measureSelection: {
          ...currentMeasureSelection,
          selectedDimensions: selectedDimensionsUpdated,
        },
      };
      const updatedCard = {
        ...prev,
        data: {
          ...prev.data,
          filterMeasures: replaceAtIndexImmut(
            prev.data.filterMeasures,
            filterMeasureIndex,
            filterMeasure
          ),
        },
      };

      setCard(updatedCard);
      loadAndStoreDataMicro(
        updatedCard,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedCard),
        handleSaveCard
      );
    },
    [
      adminShowDraftData,
      appearanceSettings,
      filterMeasures,
      geoTree,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      readCard,
      setCard,
    ]
  );

  const handleSetFilter = useCallback(
    (filterMeasureIndex: number, filter: MicroDataFilter) => {
      if (!defined(geoTree)) {
        logger.error("Geo tree not defined");
        return;
      }

      const prev = readCard();
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;
      const prevFilter = prev.data.filterMeasures[filterMeasureIndex];
      const updatedCard = {
        ...prev,
        data: {
          ...prev.data,
          filterMeasures: replaceAtIndexImmut(
            prev.data.filterMeasures,
            filterMeasureIndex,
            {
              ...prevFilter,
              filterSet: { filters: [filter], type: "simple-and-filter" },
            }
          ),
        },
      };
      setCard(updatedCard);
      loadAndStoreDataMicro(
        updatedCard,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedCard),
        handleSaveCard
      );
    },
    [
      adminShowDraftData,
      appearanceSettings,
      geoTree,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      readCard,
      setCard,
    ]
  );

  const handleRemoveFilter = useCallback(
    (filterMeasureIndex: number) => {
      if (!defined(geoTree)) {
        logger.error("Geo tree not defined");
        return;
      }

      const prev = readCard();
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;
      const updatedCard = {
        ...prev,
        data: {
          ...prev.data,
          filterMeasures: prev.data.filterMeasures.filter(
            (_, i) => i !== filterMeasureIndex
          ),
        },
      };
      setCard(updatedCard);
      loadAndStoreDataMicro(
        updatedCard,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedCard),
        handleSaveCard
      );
    },
    [
      adminShowDraftData,
      appearanceSettings,
      geoTree,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      readCard,
      setCard,
    ]
  );

  const handleClearBreakdowns = useCallback(
    async (filterMeasureIndex: number, dataColumn: string) => {
      const currentFilter = filterMeasures[filterMeasureIndex];
      if (!defined(currentFilter)) {
        return;
      }
      const selection = currentFilter.measureSelection;
      if (!defined(selection)) {
        return;
      }

      const selectedDimensionsUpdated = { ...selection.selectedDimensions };
      delete selectedDimensionsUpdated[dataColumn];

      setCard((prev) => {
        const dataSelections = prev.data.dataSelections;
        if (!defined(dataSelections)) {
          throw new Error("Expected dataSelections");
        }
        const filterMeasure: FilterMeasureMicro = {
          ...currentFilter,
          measureSelection: {
            ...selection,
            selectedDimensions: selectedDimensionsUpdated,
          },
        };
        return {
          ...prev,
          data: {
            ...prev.data,
            filterMeasures: replaceAtIndexImmut(
              prev.data.filterMeasures,
              filterMeasureIndex,
              filterMeasure
            ),
          },
        };
      });
    },
    [filterMeasures, setCard]
  );

  return {
    handleSetSingleBreakdownValue,
    handleSetFilter,
    handleRemoveFilter,
    handleClearBreakdowns,
  };
}
