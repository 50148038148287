import { debounce } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { RecoilRoot } from "recoil";

import { AlertBox } from "../../../../components/AlertBox";
import { Button } from "../../../../components/Button";
import { Table } from "../../../../components/Table";
import { AppMessagesContext } from "../../../../lib/application/contexts";
import { adminApi } from "../../../../lib/application/requests/admin/admin_api";
import { statsApiV2 } from "../../../../lib/application/requests/statsApiV2";
import { migrateDocument } from "../../../../lib/application/state/stats/workspace/rebuild_state";
import { WorkspaceLatestRT } from "../../../../lib/application/state/stats/workspace/shared";
import {
  CURRENT_WORKSPACE_VERSION,
  WorkspaceWrapperRT,
} from "../../../../lib/application/state/stats/workspace/types";
import { defined } from "../../../../lib/core/defined";
import { Milliseconds } from "../../../../lib/core/time";
import { AdminDocListDto } from "../../../../lib/infra/api_responses/admin/document_versions";
import { displayHttpErrorInternal } from "../../../../lib/infra/HttpResult";
import { consoleLogger } from "../../../../lib/infra/logging";
import { StatsDocumentReadOnlyAdmin } from "../../../stats/docs/StatsDocumentReadOnlyAdmin";
import { useAvailableVersions, useMigrationHooks } from "./shared";
import { statsApi } from "../../../../lib/application/requests/statsApi";

interface Props {}
export function ManageMigrationsDocuments(props: Props) {
  const [loadedVersionNumber, setLoadedVersionNumber] = useState<
    number | undefined
  >();
  const [docs, setDocs] = useState<AdminDocListDto>([]);
  const availableVersions = useAvailableVersions(adminApi.listDocumentVersions);

  const appMessages = useContext(AppMessagesContext);

  const handleShowDocs = useCallback((versionNumber: number) => {
    return adminApi.listDocumentsByVersion(versionNumber).then((res) => {
      res.match({
        ok: (docs) => {
          setLoadedVersionNumber(versionNumber);
          setDocs(docs);
        },
        err: (err) => {
          alert(displayHttpErrorInternal(err));
          consoleLogger.error(err);
        },
      });
    });
  }, []);

  const handleMigrateInner = useCallback((docId: number) => {
    return adminApi.getDocument(docId).then((res) => {
      const doc = res.unwrap();
      const workspace = WorkspaceWrapperRT.check(doc.workspace);
      return migrateDocument(workspace, statsApi, statsApiV2).then(
        (migrated) => {
          const migratedChecked = WorkspaceLatestRT.check(migrated);
          return adminApi.updateDocument(docId, migratedChecked);
        }
      );
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDocMigrated = useCallback(
    debounce((docId: number) => {
      if (!defined(loadedVersionNumber)) {
        return;
      }
      handleShowDocs(loadedVersionNumber);
    }, Milliseconds.second * 2),
    [handleShowDocs, loadedVersionNumber]
  );

  const {
    handleMigrateAll,
    handleMigrateSingle,
    singleMigrationInProgress,
    numMigrated,
    numTotalToMigrate,
    failures,
    migrateAllInProgress,
  } = useMigrationHooks<number, { id: number }>(
    handleMigrateInner,
    (doc) => doc.id,
    handleDocMigrated,
    appMessages
  );

  const [selectedDoc, setSelectedDoc] = useState<number | undefined>();
  const [selectedDocInput, setSelectedDocInput] = useState<
    number | undefined
  >();

  const anyMigrationInProgress = useMemo(() => {
    return singleMigrationInProgress || migrateAllInProgress;
  }, [migrateAllInProgress, singleMigrationInProgress]);

  return (
    <>
      <div>
        {failures.length > 0 && (
          <AlertBox>
            <div>
              {failures.map((f) => (
                <p>
                  id: {f[0].id}, fel: {f[1]}
                </p>
              ))}
            </div>
          </AlertBox>
        )}
        {migrateAllInProgress && defined(numTotalToMigrate) && (
          <div>
            <p>Migrerar alla ...</p>
            <progress
              value={numMigrated / numTotalToMigrate}
              max={1}
            ></progress>
          </div>
        )}
        <label>Visa dokument med ID</label>
        <input
          type="number"
          value={selectedDocInput}
          disabled={anyMigrationInProgress}
          onChange={(e) => setSelectedDocInput(parseInt(e.currentTarget.value))}
        ></input>
        <Button
          disabled={anyMigrationInProgress}
          title="Välj"
          onClick={() => setSelectedDoc(selectedDocInput)}
        />
      </div>
      <div>
        <label>Lista alla dokument med given version</label>
        {defined(availableVersions) &&
          availableVersions.length > 0 &&
          availableVersions.map((v) => (
            <Button
              key={v}
              title={v.toString()}
              onClick={() => handleShowDocs(v)}
            />
          ))}
      </div>
      <>
        {docs === null && (
          <AlertBox intent="warning">
            <p>Inga dokument hittades</p>
          </AlertBox>
        )}
        {defined(docs) && docs.length > 0 && defined(loadedVersionNumber) && (
          <div>
            <h3>
              Dokument med version {loadedVersionNumber} (totalt {docs.length})
            </h3>
            {loadedVersionNumber < CURRENT_WORKSPACE_VERSION && (
              <Button
                title="Migrera alla"
                disabled={anyMigrationInProgress}
                onClick={() => handleMigrateAll(docs)}
              />
            )}
            <Table
              columns={[{ name: "ID" }, { name: "Namn" }, { name: "Version" }]}
              containerClassName="doc-list"
              onRowClick={(id) => {
                if (anyMigrationInProgress) {
                  return;
                }
                setSelectedDoc(id);
              }}
              data={docs.map((d) => {
                return {
                  id: d.id,
                  cells: [
                    d.id.toString(),
                    d.title,
                    loadedVersionNumber.toString(),
                  ],
                };
              })}
            ></Table>
          </div>
        )}
      </>

      <>
        {defined(selectedDoc) && (
          <div>
            <h3>Valt dokument: {selectedDoc}</h3>
            <Button
              title="Avbryt"
              onClick={() => setSelectedDoc(undefined)}
            ></Button>
            {defined(loadedVersionNumber) &&
              loadedVersionNumber < CURRENT_WORKSPACE_VERSION && (
                <Button
                  title="Migrera"
                  disabled={anyMigrationInProgress}
                  onClick={() => handleMigrateSingle(selectedDoc)}
                ></Button>
              )}
            <div id="doc-migration-comparison">
              <div className="doc">
                <RecoilRoot>
                  <CurrentDoc docId={selectedDoc} />
                </RecoilRoot>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
}

function CurrentDoc(props: { docId: number }) {
  const { docId } = props;

  return (
    <StatsDocumentReadOnlyAdmin
      key={docId}
      documentId={docId}
      mockDocumentId={docId}
    />
  );
}
