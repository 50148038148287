import { isEqual } from "lodash";
import { Checkbox } from "@fluentui/react";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  TimespanSliderRange,
  TimespanSliderSingle,
} from "../../../../../components/TimespanSlider";
import { defined } from "../../../../../lib/core/defined";
import { DateRangeRaw } from "../../../../../lib/domain/measure/definitions";
import {
  getTimeSettings,
  timespanToSliderRange,
} from "../card_general/TimeSelect";
import { last } from "../../../../../lib/core/last";
import { DEFAULT_TIME_SPAN_SELECTION } from "../../../../../lib/domain/time";
import { dateRangeToRaw } from "../../../../../lib/domain/measure";

export function TimeSelect(props: {
  timeSelection: DateRangeRaw;
  availableDates: string[];
  timeResolution: string;
  handleUpdate: (timespan: DateRangeRaw) => void;
  handleSetLockToLatestTime: (lock: boolean) => void;
  lockToLatest: boolean | null;
  enableRange: boolean;
}) {
  const {
    timeSelection,
    availableDates,
    timeResolution: resolutionRaw,
    handleUpdate,
    handleSetLockToLatestTime,
    lockToLatest,
  } = props;

  const [timespan, setTimespan] = useState<DateRangeRaw>(timeSelection);

  useEffect(() => {
    // Sync the component's state with the prop when it changes
    if (!isEqual(timespan, timeSelection)) {
      setTimespan(timeSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeSelection]);

  const availableTimespan: DateRangeRaw = useMemo(() => {
    return [availableDates[0], last(availableDates) ?? availableDates[0]];
  }, [availableDates]);

  const timeSettings =
    defined(resolutionRaw) && defined(availableTimespan)
      ? getTimeSettings(
          { type: "range", range: availableTimespan },
          resolutionRaw,
          availableDates
        )
      : undefined;

  const sliderRange = defined(timeSettings)
    ? timeSettings.numberRange
    : DEFAULT_TIME_SPAN_SELECTION;

  const selectedSliderRange = useMemo(() => {
    if (!defined(timespan) || !defined(timeSettings)) {
      return undefined;
    }
    return timespanToSliderRange(timespan, timeSettings);
  }, [timespan, timeSettings]);

  const disabled = !defined(timeSettings);

  const handleToggleLockToLatestAndUpdateTime = useCallback(
    (locked: boolean) => {
      if (locked) {
        const start = timespan[0];
        const lastPoint = last(availableDates) ?? start;
        const newTimespan: [string, string] =
          defined(start) && start === timespan[1]
            ? [lastPoint, lastPoint]
            : [start, lastPoint];
        setTimespan(newTimespan);
        handleUpdate(newTimespan);
      } else {
        handleSetLockToLatestTime(false);
      }
    },
    [availableDates, handleSetLockToLatestTime, handleUpdate, timespan]
  );

  const handleSliderChange = (range: [number, number]) => {
    if (!defined(timeSettings)) {
      return;
    }

    const newValue: [Date, Date] = [
      timeSettings.dates[range[0]],
      timeSettings.dates[range[1]],
    ];
    const newTimespan = dateRangeToRaw(newValue);
    setTimespan(newTimespan);
    handleUpdate(newTimespan);
  };

  const handleSingleSliderChange = (value: number) => {
    if (!defined(timeSettings)) {
      return;
    }
    const date = timeSettings.dates[value];
    const newTimespan = dateRangeToRaw([date, date]);
    setTimespan(newTimespan);
    handleUpdate(newTimespan);
  };

  return (
    <div className="time-select content-padding">
      {props.enableRange ? (
        <TimespanSliderRange
          disabled={disabled}
          format={(val) => {
            if (!defined(timeSettings)) {
              return val + "";
            }
            return timeSettings.format(timeSettings.dates[val]);
          }}
          min={sliderRange[0]}
          max={sliderRange[1]}
          selectedRange={selectedSliderRange}
          setTimeRange={handleSliderChange}
        />
      ) : (
        <TimespanSliderSingle
          disabled={disabled}
          format={(val) => {
            if (!defined(timeSettings)) {
              return val + "";
            }
            return timeSettings.format(timeSettings.dates[val]);
          }}
          min={sliderRange[0] ?? 0}
          max={sliderRange[1] ?? 0}
          selectedValue={
            defined(timespan) && defined(timeSettings)
              ? timespanToSliderRange(timespan, timeSettings)[0]
              : 0
          }
          setTimeValue={handleSingleSliderChange}
        />
      )}
      {defined(lockToLatest) && (
        <Checkbox
          label="Lås till senaste"
          checked={lockToLatest}
          onChange={(ev, checked) => {
            if (!defined(checked)) {
              return;
            }
            handleToggleLockToLatestAndUpdateTime(checked);
          }}
        />
      )}
    </div>
  );
}
