import { useCallback, useMemo, useState } from "react";

export type ToggleStateAndActions = [
  boolean,
  () => void,
  React.Dispatch<React.SetStateAction<boolean>>
];

export function useToggle(
  initialState: boolean | (() => boolean)
): ToggleStateAndActions {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(!state), [state]);
  return useMemo(() => [state, toggle, setState], [state, toggle, setState]);
}

/**
 * Toggle that can only be in state true if the condition is true.
 * Useful for a toggle requiring the user to be in admin mode to use a certain feature.
 */
export function useConditionalToggle(
  initialState: boolean,
  condition: boolean
): [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(!state), [state]);
  return [state && condition, toggle, setState];
}
