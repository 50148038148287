import * as React from "react";
import { useContext, useState, useCallback } from "react";

import {
  GeographiesContext,
  UserInfoContext,
} from "../../../../../lib/application/contexts";
import { ISharingInfo } from "../../../../../lib/application/files/SharingInfo";
import { useCardEditMode } from "../../../../../lib/application/state/stats/useEditMode";

import { TabContainer, TitleActionsContainer, TitleContainer } from "./CardTab";
import { TitleActionsContextMenu } from "./TitleActionsContextMenu";
import { useToggle } from "../../../../../lib/application/hooks/useToggle";
import { CreateCardEmbedModal } from "../card_general/CreateCardEmbedModal";
import { DataSelection } from "../../../../../lib/domain/selections/definitions";
import { useAddTextCardCallback } from "../../../../../lib/application/state/actions/cardCallbacks";
import { makeTextCardCK } from "../../../../../lib/application/state/stats/document-core/create";
import { adminApi } from "../../../../../lib/application/requests/admin/admin_api";
import { docCardsListQuery } from "../../../../../lib/application/state/stats/document-core/docCardsListState";
import { useRecoilValue } from "recoil";

import { AppMessagesContext } from "../../../../../lib/application/contexts";
import { DatasetError } from "../../../../../lib/application/state/stats/document-core/core";
import { singleDataCardQuery } from "../../../../../lib/application/state/stats/document-core/queries/dataCard";
import { fetchDatasetForCard } from "../../../../../lib/application/state/stats/useDataset";
import { defined } from "../../../../../lib/core/defined";
import { getDataLoadErrorMessage } from "../card_general/data_load_views";
import { SurveyStringDataset } from "../../../../../lib/application/stats/datasets/SurveyStringDataset";
import { CardBackgroundColorDialog } from "../card_general/CardBackgroundColorDialog";
import { useCardBgColor } from "../../../../../lib/application/state/stats/useCardBgColor";

interface Props {
  containerKey: string;
  primaryMeasureId?: number;
  sharingInfo: ISharingInfo;
  cardId: string;
  handleRemoveCard: () => void;
  handleDuplicateCard: () => void;
  handleSetCardBgColor: (color?: string) => void;
  handleTriggerSettingsSaved: (selection: DataSelection) => void;
  singleSelectedMeasure: boolean;
  setMeasureAsDefault: () => void;
}

export function ThirdPartyDocDataCardTabView(props: {
  sharingInfo: ISharingInfo;
  containerKey: string;
  cardId: string;
}) {
  const {
    hasEditDocumentAccess,
    isEditingCard,
    setCardEditing,
    isEditingDocument,
  } = useCardEditMode(props.cardId, props.sharingInfo);
  const [showContextMenu, setShowContextMenu] = useState(false);

  const contextMenuContainerRef = React.useRef(null);

  if (!isEditingDocument) {
    return <></>;
  }

  return (
    <TabContainer containerKey={props.containerKey ?? props.cardId}>
      <div className="card-label">
        <TitleContainer
          key={props.cardId}
          isEditing={isEditingCard}
          cardId={props.cardId}
        ></TitleContainer>
      </div>

      <TitleActionsContainer
        setEditing={setCardEditing}
        isEditing={isEditingCard}
        hasEditDocumentAccess={hasEditDocumentAccess}
        setShowContextMenu={setShowContextMenu}
        showContextMenu={showContextMenu}
        contextMenuContainerRef={contextMenuContainerRef}
      ></TitleActionsContainer>
    </TabContainer>
  );
}

export function DataCardTab(props: Props) {
  const { singleSelectedMeasure, primaryMeasureId } = props;

  const {
    hasEditDocumentAccess,
    isEditingCard,
    setCardEditing,
    isEditingDocument,
  } = useCardEditMode(props.cardId, props.sharingInfo);
  const [showContextMenu, setShowContextMenu] = useState(false);

  const currentBgColor = useCardBgColor(props.cardId);

  const contextMenuContainerRef = React.useRef(null);

  const userInfo = useContext(UserInfoContext);
  const [setBgColorOpen, toggleSetBgColorOpen] = useToggle(false);
  const [createEmbedOpen, toggleCreateEmbedOpen] = useToggle(false);

  const addAIAnalysis = useAddAIAnalysis(props.cardId);

  if (!isEditingDocument) {
    return <></>;
  }

  return (
    <TabContainer
      containerKey={primaryMeasureId ?? props.containerKey ?? props.cardId}
    >
      <div className="card-label">
        <TitleContainer
          key={props.cardId}
          isEditing={isEditingCard}
          cardId={props.cardId}
        ></TitleContainer>
        {createEmbedOpen && (
          <CreateCardEmbedModal
            cardId={props.cardId}
            onClose={toggleCreateEmbedOpen}
          ></CreateCardEmbedModal>
        )}
        {setBgColorOpen && (
          <CardBackgroundColorDialog
            isOpen
            onClose={toggleSetBgColorOpen}
            onColorChange={props.handleSetCardBgColor}
            initialColor={currentBgColor ?? "#ffffff"}
          />
        )}
      </div>

      <TitleActionsContainer
        setEditing={setCardEditing}
        isEditing={isEditingCard}
        hasEditDocumentAccess={hasEditDocumentAccess}
        setShowContextMenu={setShowContextMenu}
        showContextMenu={showContextMenu}
        contextMenuContainerRef={contextMenuContainerRef}
      ></TitleActionsContainer>
      <TitleActionsContextMenu
        cardId={props.cardId}
        handleAddAIAnalysis={addAIAnalysis}
        handleEditBgColor={toggleSetBgColorOpen}
        hasEditDocumentAccess={hasEditDocumentAccess}
        handleCreateEmbed={toggleCreateEmbedOpen}
        setMeasureAsDefault={
          userInfo?.internalMeasureAdmin() && singleSelectedMeasure
            ? () => {
                props.setMeasureAsDefault();
              }
            : undefined
        }
        handleDuplicateCard={() => props.handleDuplicateCard()}
        handleRemoveCard={() => props.handleRemoveCard()}
        handleTriggerStatsSettingsSaved={props.handleTriggerSettingsSaved}
        targetRef={contextMenuContainerRef}
        hidden={!showContextMenu}
        onDismiss={() => setShowContextMenu(false)}
      ></TitleActionsContextMenu>
    </TabContainer>
  );
}

function useAddAIAnalysis(cardId: string) {
  const appMessagesHandler = useContext(AppMessagesContext);

  const cardsList = useRecoilValue(docCardsListQuery);
  const card = useRecoilValue(singleDataCardQuery({ cardStateId: cardId }));
  const geographies = useContext(GeographiesContext);
  const addTextCard = useAddTextCardCallback();

  return useCallback(() => {
    if (!defined(geographies)) {
      return null;
    }

    return fetchDatasetForCard(card, geographies, false, false).then((res) => {
      if (res instanceof DatasetError) {
        return appMessagesHandler?.add("error", res.displayMessage());
      }
      if (res?.type === "err") {
        return appMessagesHandler?.add(
          "error",
          getDataLoadErrorMessage(res.error)
        );
      }
      if (!defined(res)) {
        return appMessagesHandler?.add("error", "Kunde inte hämta data");
      }
      let stringifiedData: string;
      if (res.data instanceof SurveyStringDataset) {
        const tableData = res.data.tableSurveyString();
        stringifiedData = JSON.stringify({
          cols: tableData.cols,
          rows: tableData.rows,
        });
      } else {
        const tableData = res.data.table();
        stringifiedData = JSON.stringify({
          header: tableData?.tableDescription.header,
          primarySubHeaders:
            tableData?.tableDescription.primaryMeasureSubheaders,
          groupingHeader: tableData?.tableDescription.groupingHeader,
          unit: tableData?.tableDescription.unitHeader,
          regionAndTimeHeader: tableData?.tableDescription.regionAndTimeHeader,

          headers: tableData?.header,
          rows: tableData?.rows,
        });
      }

      return adminApi.getAIAnalysis(stringifiedData).then((res) => {
        const currentCardIndex = cardsList.findIndex((c) => c.id === cardId);
        addTextCard(
          makeTextCardCK(res.unwrap().result.trim()),
          currentCardIndex + 1
        );
      });
    });
  }, [geographies, card, appMessagesHandler, cardsList, addTextCard, cardId]);
}
