import { useEffect } from "react";
import { defined } from "../../core/defined";

function useKeyUp(
  key: string,
  action: () => void,
  elementRef: React.RefObject<HTMLElement>
) {
  useEffect(() => {
    function onKeyUp(event: KeyboardEvent) {
      if (event.key === key) {
        action();
      }
    }

    const element = elementRef.current;
    if (!defined(element)) {
      return;
    }

    element.addEventListener("keyup", onKeyUp);
    return () => {
      element.removeEventListener("keyup", onKeyUp);
    };
  });
}

function useKeyUpDocument(key: string, action: () => void) {
  useEffect(() => {
    function onKeyUp(event: KeyboardEvent) {
      if (event.key === key) {
        action();
      }
    }

    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keyup", onKeyUp);
    };
  });
}

export function useKeyUpEnter(
  action: () => void,
  elementRef: React.RefObject<HTMLElement>
) {
  useKeyUp("Enter", action, elementRef);
}

export function useKeyUpEscape(action: () => void) {
  useKeyUpDocument("Escape", action);
}

export function useKeyUpArrowUp(
  action: () => void,
  elementRef: React.RefObject<HTMLElement>
) {
  useKeyUp("ArrowUp", action, elementRef);
}

export function useKeyUpArrowDown(
  action: () => void,
  elementRef: React.RefObject<HTMLElement>
) {
  useKeyUp("ArrowDown", action, elementRef);
}
