import * as Papa from "papaparse";
import { String as StringRT, Array as ArrayRT } from "runtypes";
import { defined } from "../../../core/defined";

import { nonEmptyString } from "../../../core/nonEmptyString";
import { Result } from "../../../core/Result";
import { SURVEY_METADATA_FIELDS } from "./metadata_definition";
import { CsvCellValue, DataLineFromCsv, MetadataField } from "./types";

export interface MetadataTable {
  header: readonly MetadataField[];
  dataLines: DataLineFromCsv[];
}

const HeaderRT = ArrayRT(StringRT);

export function metadataFromCsv(
  contents: string
): Result<MetadataTable, string[]> {
  const parseResult = Papa.parse(contents, { delimiter: ";" });
  const data = parseResult.data;
  if (data.length === 0) {
    throw new Error("Ingen data hittades");
  }
  const header = HeaderRT.check((data[0] as string[])?.map((s) => s.trim()));
  const csvFields = SURVEY_METADATA_FIELDS.filter((f) => f.fromCsv);
  const nonMatchedHeaderLabels = csvFields.filter(
    (h) =>
      h.fromCsv &&
      h.csvColumnValueRequired &&
      !header.some((label) => label === h.label)
  );
  if (nonMatchedHeaderLabels.length !== 0) {
    return Result.fromErr([
      "Felaktigt format. Förväntade rubriker i CSV-filen saknades: ",
      ...nonMatchedHeaderLabels.map((r) => {
        return `Saknad rubrik: ${r.label}`;
      }),
    ]);
  }

  const errors: string[] = [];
  const fieldIndexToCsvColIndex: { [key: number]: number } = {};
  for (let i = 0; i < SURVEY_METADATA_FIELDS.length; i++) {
    const f = SURVEY_METADATA_FIELDS[i];
    const labelIndex = header.indexOf(f.label);
    if (labelIndex === -1) {
      continue;
    }
    fieldIndexToCsvColIndex[i] = labelIndex;
  }

  if (errors.length > 0) {
    return Result.fromErr(errors);
  }

  const numHeaderFields = header.length;
  const dataLines: DataLineFromCsv[] = [];
  const dataLinesRaw = data.slice(1);
  for (const line of dataLinesRaw) {
    if (!Array.isArray(line)) {
      errors.push(`Felaktigt format. Kunde inte tolka rad ${line} i CSV-fil`);
      continue;
    }

    if (line.length !== numHeaderFields) {
      errors.push(
        `Felaktigt format. Rad ${dataLinesRaw.indexOf(line) + 1} saknar värden`
      );
      continue;
    }

    const cells: CsvCellValue[] = [];
    SURVEY_METADATA_FIELDS.forEach((f, i) => {
      if (!f.fromCsv || !nonEmptyString(f.label.trim())) {
        cells.push(null);
        return;
      }

      const lineColIndex = fieldIndexToCsvColIndex[i];
      if (!defined(lineColIndex)) {
        cells.push(null);
        return;
      }

      const rawCell = line[lineColIndex];
      if (f.type === "multi-string") {
        // parse array of strings separated by newline
        if (typeof rawCell !== "string") {
          errors.push(
            `Värde på rad ${
              dataLinesRaw.indexOf(line) + 1
            } kunde inte tolkas: ej textvärde`
          );
          return;
        }
        const trimmed = rawCell.trim();
        const checkedCell = nonEmptyString(trimmed)
          ? trimmed.split("\n")
          : null;
        cells.push(checkedCell);
        return;
      }

      // Only two types of f.type, now only one left:
      // f.type === "string"
      if (typeof rawCell !== "string") {
        errors.push(
          `Värde på rad ${
            dataLinesRaw.indexOf(line) + 1
          } kunde inte tolkas: ej textvärde`
        );
        return;
      }
      const trimmed = rawCell.trim();
      const checkedCell = nonEmptyString(trimmed) ? trimmed : null;
      cells.push(checkedCell);
    });

    // Ignore empty lines
    if (!cells.every((field) => field === null)) {
      dataLines.push(cells);
    }
  }

  return Result.fromOk({
    header: SURVEY_METADATA_FIELDS,
    dataLines,
  });
}
