import { Shimmer } from "@fluentui/react";

import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import {
  DocCardMicro,
  DocCardStats,
} from "../../../../../lib/application/state/stats/document-core/core";
import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";
import { Button, SaveButton } from "../../../../../components/Button";
import { useCallback, useContext, useEffect, useState } from "react";
import { AppMessagesContext } from "../../../../../lib/application/contexts";
import { svgChartId } from "../../../../../components/charts/shared/SvgWrapper";
import { defined } from "../../../../../lib/core/defined";
import { getSvgAsPngDataUri } from "../../../../../lib/application/exports/svg";
import { dataURLtoBlob } from "../../../../../lib/application/exports/dataUrlToBlob";
import { uploadShareableImage } from "../../../../../lib/application/requests/common_requests";
import { displayHttpError } from "../../../../../components/errors/HttpErrorNotice";
import { AlertBox } from "../../../../../components/AlertBox";
import {
  shareImageUrlToTwitter,
  shareToFacebook,
  shareToLinkedIn,
} from "../../../../../lib/application/external_sharing/social_media";
import { logger } from "../../../../../lib/infra/logging";
import { copyToClipboard } from "../../../../../lib/application/browser/copyToClipboard";
import { ReactComponent as LogoFacebook } from "../../../../../assets/logos/facebook_logo.svg";
import { ReactComponent as LogoX } from "../../../../../assets/logos/x_logo.svg";
import { ReactComponent as LogoLinkedIn } from "../../../../../assets/logos/linkedin_logo.svg";

import "./CardShareDialog.scss";

const LOGO_WIDTH = 20;

export function CardShareDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  card: DocCardStats | DocCardMicro;
}) {
  const appMessagesHandler = useContext(AppMessagesContext);
  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [imageUploadErr, setImageUploadErr] = useState<string | undefined>();
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  const handleShareToTwitter = useCallback(() => {
    if (!defined(imageUrl)) {
      return;
    }
    shareImageUrlToTwitter(imageUrl, "");
  }, [imageUrl]);

  const handleShareLinkedIn = useCallback(() => {
    if (!defined(imageUrl)) {
      return;
    }
    shareToLinkedIn(imageUrl);
  }, [imageUrl]);

  const handleShareToFacebook = useCallback(() => {
    if (!defined(imageUrl)) {
      return;
    }

    shareToFacebook(imageUrl);
  }, [imageUrl]);

  const handleCopyLink = useCallback(() => {
    if (!defined(imageUrl)) {
      return;
    }
    copyToClipboard(imageUrl);
    setLinkCopied(true);
  }, [imageUrl]);

  useEffect(() => {
    // Run image upload only once
    const card = props.card;

    const element = document.getElementById(svgChartId(card.id));
    if (!defined(element)) {
      return appMessagesHandler?.add("warning", "Hittade inte diagrammet");
    }

    if (card.type === "dataCard" && card.data.selectedView !== "diagram") {
      return;
    }
    if (card.type === "microCard" && card.data.selectedTab !== "chart") {
      return;
    }
    const cardHeader = getImageTitle(card);
    getSvgAsPngDataUri(element, getBackgroundColor(card) ?? "white")
      .then((uri) => {
        if (!defined(uri)) {
          throw new Error("Kunde inte skapa bild");
        }
        return dataURLtoBlob(uri);
      })
      .then((blob) => uploadShareableImage(blob, cardHeader, cardHeader))
      .then((urlRes) => {
        urlRes.match({
          ok: (url) => {
            setImageUrl(url);
          },
          err: (error) => {
            setImageUploadErr(displayHttpError(error));
          },
        });
      })
      .catch((err) => {
        appMessagesHandler?.add(
          "error",
          "Kunde inte skapa bild för delning. Försök igen senare."
        );
        logger.error("Error uploading image", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <FluentModal title="Dela" isOpen={props.isOpen} onClose={props.onClose}>
        <FluentModalBody>
          <div className="card-social-share">
            <></>
            <p>Dela en bild av diagrammet.</p>
            <>
              {defined(imageUploadErr) && (
                <AlertBox intent="warning">
                  <span>
                    {"Kan inte dela just nu."}
                    <br />
                    {imageUploadErr}
                  </span>
                </AlertBox>
              )}
            </>
            {defined(imageUrl) ? (
              <>
                <div className="share-buttons section">
                  <div
                    onClick={() => handleShareLinkedIn()}
                    title="LinkedIn"
                    role="button"
                  >
                    <LogoLinkedIn width={LOGO_WIDTH} />
                  </div>
                  <div
                    onClick={() => handleShareToTwitter()}
                    title="X"
                    role="button"
                  >
                    <LogoX width={LOGO_WIDTH} />
                  </div>
                  <div
                    onClick={() => handleShareToFacebook()}
                    title="Facebook"
                    role="button"
                  >
                    <LogoFacebook width={LOGO_WIDTH} />
                  </div>
                </div>
                <div>
                  <Button
                    icon={linkCopied ? "tick" : "link"}
                    title={linkCopied ? "Länk kopierad" : "Kopiera länk"}
                    onClick={() => handleCopyLink()}
                  ></Button>
                </div>
              </>
            ) : (
              <div>
                <Shimmer style={{ marginBottom: 10 }} width="100%" />
                <Shimmer style={{ marginBottom: 10 }} width="90%" />
                <Shimmer width="100%" />
              </div>
            )}

            <section></section>
          </div>
        </FluentModalBody>
        <FluentModalFooter>
          <ButtonsFooter>
            <ButtonsFooterRight>
              <SaveButton title="Stäng" onClick={props.onClose}></SaveButton>
            </ButtonsFooterRight>
          </ButtonsFooter>
        </FluentModalFooter>
      </FluentModal>
    </div>
  );
}

function getBackgroundColor(
  card: DocCardStats | DocCardMicro
): string | undefined | null {
  return card.data.loadedData?.chartDataState?.loadedChartData
    ?.colorSchemeContainer.customBgColor;
}

function getImageTitle(card: DocCardStats | DocCardMicro): string {
  const defaultTitle = "Diagram - Powered by Infostat";

  if (card.type === "microCard") {
    return (
      card.data.settings.dataOutputSettings.customTitle ??
      card.data.dataSelections?.find((s) => s.type === "primary")?.measure
        ?.descrLong ??
      defaultTitle
    );
  }

  const description = card.data.loadedData?.dataset?.sourceInfo.measureTitle;
  return card.data.settings.customTitle ?? description ?? defaultTitle;
}
