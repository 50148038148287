import React from "react";
import { TextRowSet } from "../../../lib/application/stats/shared/core/text_containers";
import { translatePosition } from "../../../lib/application/stats/svg";
import { RenderOffsetTextRow } from "./Text";

export interface TitleProps {
  debug?: boolean;
  position: { x: number; y: number };
  titleRowSet: TextRowSet;
  fontColor?: string | null;
  shiftOneLineDown?: boolean;
}

export function ChartTitle(props: TitleProps) {
  const position = {
    x: props.position.x,
    y:
      props.position.y +
      (props.shiftOneLineDown ? props.titleRowSet.first?.totalHeight ?? 0 : 0),
  };
  return (
    <g transform={translatePosition(position)}>
      <>
        {props.debug &&
          props.titleRowSet.offsetRows.map((row, i) => {
            return (
              <React.Fragment key={i}>
                <rect
                  key={i + "padding"}
                  width={props.titleRowSet.width}
                  y={row.offsetY}
                  x={0}
                  height={row.paddingTop}
                  fill="rgba(0,255,0,0.9)"
                ></rect>
                <rect
                  key={i + "text"}
                  width={props.titleRowSet.width}
                  y={row.offsetY}
                  x={0}
                  height={row.rowHeightWithPadding}
                  stroke="black"
                  fill="rgba(255,255,0,0.5)"
                ></rect>
              </React.Fragment>
            );
          })}
        {props.titleRowSet.offsetRows.map((row, i) => (
          <RenderOffsetTextRow
            key={row.text + "-" + i}
            row={row}
            fontColor={props.fontColor}
          ></RenderOffsetTextRow>
        ))}
      </>
    </g>
  );
}
