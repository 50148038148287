import { useContext, useCallback } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { defined } from "../../../../../core/defined";
import { GeographiesSerializable } from "../../../../../domain/geography";
import {
  defaultMeasureSelectionPrimary,
  measureSelectionIsSurvey,
  setMeasureAvailableDatesMut,
  tryExtractSettings,
} from "../../../../../domain/measure";
import {
  MeasureThin,
  MeasureSelection,
  MeasureFull,
} from "../../../../../domain/measure/definitions";
import { DataSelection } from "../../../../../domain/selections/definitions";
import { logger } from "../../../../../infra/logging";
import {
  AppMessagesContext,
  CardUpdateCountContext,
  ShowDraftDataContext,
} from "../../../../contexts";
import { handleGetDefaultMeasure } from "../../../../requests/common_requests";
import { applyDefaultSettings } from "../../../stats/default-settings/apply";
import { LatestSettingsFormat } from "../../../stats/default-settings/common";
import { DocCardStats } from "../../../stats/document-core/core";
import { dataSelectionsEqual } from "../../../stats/document-core/eq";
import {
  isGroupingQuery,
  singleDataCardQuery,
} from "../../../stats/document-core/queries/dataCard";
import {
  defaultTimespan,
  updateDataCardInnerImmut,
} from "../../../stats/document-core/updates";
import { TimeResolution } from "../../../../../domain/time";
import { loadAndStoreDataStats } from "../../../stats/cardToDataStateStats";
import { wait } from "../../../../../core/wait";
import { cardColors } from "../../../stats/document-core/queries/shared";
import { useSaveCard } from "../../useSaveDocument";
import { useExtendedAppearanceSettings } from "../../useExtendedAppearanceSettings";
import { isInfostatStandardPalette } from "../../../../stats/shared/core/colors/colorSchemes";
import { CustomDataOutputSettings } from "../../../../../infra/api_responses/account";

export function usePrimarySelectionChanger(
  cardId: string,
  oldSelection: DataSelection | undefined,
  geographies: GeographiesSerializable
) {
  const [card, setCard] = useRecoilState(
    singleDataCardQuery({ cardStateId: cardId })
  );
  const isGroupingMode = useRecoilValue(
    isGroupingQuery({ cardStateId: cardId })
  );

  const adminShowDraftData = useContext(ShowDraftDataContext);
  const appMessagesHandler = useContext(AppMessagesContext);
  const handleSaveCard = useSaveCard();
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const appearanceSettings = useExtendedAppearanceSettings();
  const customDataOutputSettings =
    appearanceSettings.defaultTheme.customOutputSettings;

  const changeHandler = useCallback(
    async (newDataSelection: DataSelection) => {
      if (dataSelectionsEqual(newDataSelection, oldSelection)) {
        return Promise.resolve();
      }

      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      return Promise.resolve().then(async () => {
        const newMeasureSelection: MeasureSelection | undefined =
          newDataSelection.measureSelection;
        // 1) If setting a measure as opposed to just a subject path, make sure the measure has a default breakdown selection
        if (defined(newMeasureSelection)) {
          // 1.a) change measure
          const { card: updatedCard, colorSchemeContainer } =
            await (newMeasureSelection.measure.data_id !==
            oldSelection?.measureSelection?.measure.data_id
              ? // If the selected measure is changed, ensure default settings for the new measure are applied
                cardWithNewMeasure(
                  newMeasureSelection.measure,
                  newDataSelection,
                  newMeasureSelection.available,
                  card,
                  customDataOutputSettings,
                  geographies,
                  adminShowDraftData
                ).then(async (result) => {
                  setCard(result.card);
                  await wait(1); // Avoid blocking main thread for too long

                  return {
                    card: result.card,
                    // When switching measure, reset palette if current is different from default
                    colorSchemeContainer: isInfostatStandardPalette(
                      appearanceSettings.defaultTheme
                    )
                      ? result.settings?.colorSchemeContainer
                      : undefined,
                  };
                })
              : Promise.resolve().then(async () => {
                  setCard(updateCardPrimarySelection(card, newDataSelection));
                  await wait(1); // Avoid blocking main thread for too long

                  // 1.b) change breakdowns/filters: we only need to set available dates here,
                  // but if the selected measure is a survey measure we don't need to update the available dates
                  // since available dates are the same regardless of selected dimensions/breakdowns
                  return (
                    measureSelectionIsSurvey(newMeasureSelection)
                      ? Promise.resolve(newMeasureSelection)
                      : setMeasureAvailableDatesMut(
                          newMeasureSelection,
                          adminShowDraftData
                        )
                  ).then(async (selection) => {
                    const updatedCard = updateCardPrimarySelection(card, {
                      ...newDataSelection,
                      measureSelection: selection,
                    });
                    return {
                      card: updatedCard,
                      colorSchemeContainer: cardColors(updatedCard),
                    };
                  });
                }));

          return loadAndStoreDataStats(
            setCard,
            shouldAbort,
            updatedCard,
            geographies,
            adminShowDraftData,
            appearanceSettings,
            colorSchemeContainer,
            handleSaveCard
          );
        }

        // 2) If updating something else than the measure, make sure to fetch and set default measure
        // TODO: Not setting the new selection makes the UI seem to lag a bit. We may want to
        // to disable the selection interface while waiting for the new measure details to load.
        // updateSelection(newSelection);
        if (!defined(newDataSelection.subjectPath[2])) {
          const updatedCard = updateCardPrimarySelection(card, {
            ...newDataSelection,
          });
          setCard(updatedCard);
          handleSaveCard?.(updatedCard);
          return Promise.resolve();
        } else {
          return handleGetDefaultMeasure(
            newDataSelection.subjectPath,
            isGroupingMode,
            false,
            TimeResolution.maximal(), // FIXME - is this correct?
            adminShowDraftData
          )
            .then((res) => {
              if (!defined(res)) {
                return Promise.resolve(undefined);
              }
              const { availableMeasures, defaultMeasure } = res;
              return cardWithNewMeasure(
                defaultMeasure,
                newDataSelection,
                availableMeasures,
                card,
                customDataOutputSettings,
                geographies,
                adminShowDraftData
              );
            })
            .then(async (result) => {
              const updatedCard = result?.card;
              if (!defined(updatedCard)) {
                throw new Error("Card not defined");
              }
              loadAndStoreDataStats(
                setCard,
                shouldAbort,
                updatedCard,
                geographies,
                adminShowDraftData,
                appearanceSettings,
                isInfostatStandardPalette(appearanceSettings.defaultTheme)
                  ? result?.settings?.colorSchemeContainer
                  : undefined,
                handleSaveCard
              );
            })
            .catch((e) => {
              const message = `Kunde inte hämta måttinformation för ämne: ${newDataSelection.subjectPath.join(
                ", "
              )}`;
              logger.error(e, message);
              logger.error(e.code, e.details);
              appMessagesHandler?.add("error", message);
            });
        }
      });
    },
    [
      oldSelection,
      incrementUpdateCount,
      getCurrentCount,
      card,
      customDataOutputSettings,
      geographies,
      adminShowDraftData,
      setCard,
      appearanceSettings,
      handleSaveCard,
      isGroupingMode,
      appMessagesHandler,
    ]
  );

  return changeHandler;
}

function updateCardPrimarySelection(
  card: DocCardStats,
  newSelection: DataSelection
): DocCardStats {
  return {
    ...card,
    data: updateDataCardInnerImmut(card.data, "dataSelections", () => [
      newSelection,
    ]),
  };
}

async function cardWithNewMeasure(
  newMeasure: MeasureFull,
  newSelection: DataSelection,
  availableMeasures: MeasureThin[],
  card: DocCardStats,
  customDataOutputSettings: CustomDataOutputSettings | undefined,
  geographies: GeographiesSerializable,
  adminShowDraftData: boolean
): Promise<{ card: DocCardStats; settings?: LatestSettingsFormat }> {
  return Promise.resolve(newMeasure).then(
    async (measureExtended: MeasureFull) => {
      const settings = await tryExtractSettings(measureExtended);
      const defaultMeasureSelection = defaultMeasureSelectionPrimary(
        measureExtended,
        availableMeasures,
        settings
      );
      return setMeasureAvailableDatesMut(
        { ...newSelection.measureSelection, ...defaultMeasureSelection },
        adminShowDraftData
      ).then((selection) => {
        const cardWithUpdatedSelection = updateCardPrimarySelection(card, {
          ...newSelection,
          measureSelection: selection,
        });
        if (defined(settings)) {
          return {
            card: applyDefaultSettings(
              settings,
              customDataOutputSettings,
              cardWithUpdatedSelection,
              geographies
            ),
            settings,
          };
        }
        return {
          card: {
            ...cardWithUpdatedSelection,
            data: {
              ...cardWithUpdatedSelection.data,
              timeSelection: defaultTimespan(cardWithUpdatedSelection.data),
            },
          },
          settings,
        };
      });
    }
  );
}
