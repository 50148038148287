import {
  DesoProperties,
  MicroGeoSelections,
  RegsoProperties,
} from "../../application/state/stats/document-core/core-micro";
import { assertNever } from "../../core/assert";

export interface DesoRegsoLookup {
  lookupDeso: (deso: string) => DesoProperties;
  lookupRegso: (regso: string) => RegsoProperties;
  lookupDesoRegsoByLabel: (label: string) => DesoProperties | RegsoProperties;
}
export function makeDesoRegsoToPropertiesLookup(
  selectedAreas: MicroGeoSelections
): DesoRegsoLookup {
  const desoToProperties: { [deso: string]: DesoProperties } = {};
  const regsoToProperties: { [regso: string]: RegsoProperties } = {};
  const labelToDesoRegso: {
    [desoOrRegso: string]: DesoProperties | RegsoProperties;
  } = {};

  if (selectedAreas.type === "deso") {
    for (const area of selectedAreas.selected) {
      switch (area.type) {
        case "deso":
          desoToProperties[area.props.deso] = area.props;
          labelToDesoRegso[area.props.deso_label] = area.props;
          break;
        case "user-defined":
          for (const p of area.props) {
            desoToProperties[p.deso] = p;
          }
          break;
        default:
          assertNever(area);
      }
    }
  } else if (selectedAreas.type === "regso") {
    for (const area of selectedAreas.selected) {
      switch (area.type) {
        case "regso":
          regsoToProperties[area.props.regso] = area.props;
          regsoToProperties[area.props.regso_label] = area.props;
          break;
        case "user-defined":
          for (const p of area.props) {
            regsoToProperties[p.regso] = p;
          }
          break;
        default:
          assertNever(area);
      }
    }
  }

  return {
    lookupDeso: (deso: string) => {
      return desoToProperties[deso];
    },
    lookupRegso: (regso: string) => {
      return regsoToProperties[regso];
    },
    lookupDesoRegsoByLabel: (label: string) => {
      return labelToDesoRegso[label];
    },
  };
}
