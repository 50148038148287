import { useCallback } from "react";
import {
  editSurveyDimensionV2,
  getUpdateConflictsSurvey,
} from "../../../../../lib/application/requests/admin/measure_management/edit";
import {
  commitPendingChangesSurvey,
  revertPendingChangesSurvey,
} from "../../../../../lib/application/requests/admin/measure_management/survey/import";
import { getMeasureDimensionsV2 } from "../../../../../lib/application/requests/datasets/dimensions";
import { DimensionV2Dto } from "../../../../../lib/domain/measure/definitions";
import { MeasureConfirmation } from "../_shared/MeasureConfirmation";
import { DraftDataTodo } from "../../../../../lib/application_admin/todos/draft_data_todos";
import {
  renderCommitResult,
  renderRevertResult,
} from "../_shared/renderCommitRevert";

interface Props {
  id: number;
  todos?: DraftDataTodo[];
  reloadTodos: () => void;
}

export function SurveyMeasureConfirmation(props: Props) {
  const getDims = useCallback(async (id: number) => {
    const dims = await getMeasureDimensionsV2(id, true, false);
    return dims;
  }, []);

  const getConflicts = useCallback(async (id: number) => {
    const dims = await getUpdateConflictsSurvey(id);
    return dims;
  }, []);

  const editDimension = useCallback(
    (dimensionId: number, dimension: DimensionV2Dto) => {
      if (!dimension.type.startsWith("survey")) {
        throw new Error("Invalid dimension type");
      }
      return editSurveyDimensionV2(dimensionId, dimension);
    },
    []
  );

  return (
    <MeasureConfirmation
      {...props}
      type="survey"
      getConflicts={getConflicts}
      getDimensions={getDims}
      editDimension={editDimension}
      commitPendingChanges={commitPendingChangesSurvey}
      revertPendingChanges={revertPendingChangesSurvey}
      renderCommitDto={renderCommitResult}
      renderRevertDto={renderRevertResult}
      updateLastUpdatedDisabled
      updateNextUpdateDisabled
    ></MeasureConfirmation>
  );
}
