import { findLastIndex, range } from "lodash";

import { defined } from "../../../../lib/core/defined";
import { logger } from "../../../../lib/infra/logging";
import { config } from "../../../../config";

export interface CardVisibility {
  id: string;
  render: boolean;
  isInViewport?: boolean;
  isMicro: boolean;
  dimensions?: {
    marginTop?: string;
    marginBottom?: string;
    height: number;
  };
}

export type RenderLease =
  | {
      render: true;
      isInViewport?: boolean;
    }
  | {
      render: false;
      isInViewport?: boolean;
      height?: number;
      marginTop?: string;
      marginBottom?: string;
    };

/**
 * Check the first and last visible micro card, if less than
 * n in total, increase backwards and forwards until we have n visible micro cards
 */
export function increaseNumVisibleMicroCards(
  cardVisibility: CardVisibility[]
): CardVisibility[] {
  const cardVisibilityCopy = cardVisibility.slice();
  const microCards = cardVisibilityCopy.filter((c) => c.isMicro);
  const numOutOfViewMicroCards = microCards.filter((c) => !c.render).length;
  const numMicroCardsToRender = microCards.filter((c) => c.render).length;
  if (
    numMicroCardsToRender >= config.micro.numSimultaneousCardsRendered ||
    numOutOfViewMicroCards === 0
  ) {
    return cardVisibilityCopy;
  }

  const numRemaining =
    config.micro.numSimultaneousCardsRendered - numMicroCardsToRender;
  const firstVisibleMicroCardIndex = microCards.findIndex(
    (c) => c.isMicro && c.render
  );
  const lastVisibleMicroCardIndex = findLastIndex(
    microCards,
    (c) => c.isMicro && c.render
  );
  const upwardsRange = range(lastVisibleMicroCardIndex + 1, microCards.length);
  const downwardsRange = range(firstVisibleMicroCardIndex - 1, -1, -1);

  const indicesToPick = [];
  for (const i in range(
    0,
    Math.max(upwardsRange.length, downwardsRange.length)
  )) {
    if (defined(upwardsRange[i])) {
      indicesToPick.push(upwardsRange[i]);
    }
    if (defined(downwardsRange[i])) {
      indicesToPick.push(downwardsRange[i]);
    }
  }

  for (const index of indicesToPick.slice(0, numRemaining)) {
    microCards[index] = { ...microCards[index], render: true };
  }

  return cardVisibilityCopy.map((c) => {
    const current = microCards.find((m) => m.id === c.id);
    if (!defined(current)) {
      return c;
    }
    return current;
  });
}

export function getCardsVisibility(cards: string[]): CardVisibility[] {
  const cardVisibility: CardVisibility[] = [];
  for (const cardId of cards) {
    const cardElement = document.querySelector(
      `.card-id-${cardId}:not(.invisible-micro-card)`
    );

    if (cardElement === null) {
      continue;
    }

    const isMicroCard =
      document.querySelector(
        `.card-id-${cardId}:not(.invisible-micro-card) .micro-output-viewer`
      ) !== null;

    const cardBoundingBox = cardElement?.getBoundingClientRect();
    if (!defined(cardBoundingBox)) {
      logger.warn("Could not find card bounding box", { cardId });
      continue;
    }

    const mainContainer = getPageScrollElement();
    if (!defined(mainContainer)) {
      if (window.location.pathname.includes("data-admin/migrations")) {
        continue;
      }
      logger.error("Could not find main container");
      continue;
    }

    if (!isMicroCard) {
      cardVisibility.push({
        id: cardId,
        render: true,
        isMicro: false,
      });
      continue;
    }

    if (
      cardBoundingBox.bottom <= 0 ||
      cardBoundingBox.top >=
        mainContainer.scrollTop + mainContainer.clientHeight
    ) {
      // get margin from cardElement html element
      const computedStyle = window.getComputedStyle(cardElement);

      cardVisibility.push({
        id: cardId,
        render: false,
        isInViewport: false,
        isMicro: true,
        dimensions: {
          marginTop: computedStyle.marginTop,
          marginBottom: computedStyle.marginBottom,
          height: cardBoundingBox.height,
        },
      });
    } else {
      cardVisibility.push({
        id: cardId,
        isInViewport: true,
        render: true,
        isMicro: true,
      });
    }
  }

  return increaseNumVisibleMicroCards(cardVisibility);
}

export function getPageScrollElement(): HTMLElement | null {
  return document.querySelector(".page-scrollable");
}
