import { assertNever } from "@fluentui/react";
import * as d3scaleChromatic from "d3-scale-chromatic";
import { range } from "lodash";

import { SimpleCache } from "../../../core/SimpleCache";
import { defined } from "../../../core/defined";

export const availableColorSchemes = [
  "d3-div-RdYlGn",
  "d3-div-GnYlRd",
  "d3-div-BrBG",
  "d3-div-BGBr",
  "d3-white-red",
  "d3-red-white",
  "d3-white-blue",
  "d3-blue-white",
  "d3-white-purple",
  "d3-purple-white",
] as const;

export type StatsMapColorScheme = (typeof availableColorSchemes)[number];
export const DEFAULT_STATS_MAP_COLOR_SCHEME: StatsMapColorScheme =
  "d3-white-blue";

export type ColorRamp = readonly string[];

const cache = new SimpleCache<ColorRamp>(10);

export function makeColorGetter(scheme: StatsMapColorScheme) {
  const interpolator = getInterpolator(scheme);
  return (numColors: number) => {
    const colors = range(0, numColors).map((i) => interpolator(i / numColors));
    return colors;
  };
}

export function getColorRamp(
  scheme: StatsMapColorScheme,
  steps: number
): ColorRamp {
  const cacheKey = `${scheme}-${steps}`;
  const cachedRamp = cache.get(cacheKey);
  if (defined(cachedRamp)) {
    return cachedRamp;
  }

  const interpolator = getInterpolator(scheme);
  const ramp = range(0, steps).map((i) => interpolator(i / steps));
  cache.set(cacheKey, ramp);
  return ramp;
}

function invertInterpolator<T>(interpolator: (t: number) => T) {
  return (num: number) => interpolator(1 - num);
}

function getInterpolator(scheme: StatsMapColorScheme): (t: number) => string {
  switch (scheme) {
    case "d3-div-RdYlGn":
      return d3scaleChromatic.interpolateRdYlGn;
    case "d3-div-GnYlRd":
      return invertInterpolator(d3scaleChromatic.interpolateRdYlGn);
    case "d3-div-BrBG":
      return d3scaleChromatic.interpolateBrBG;
    case "d3-div-BGBr":
      return invertInterpolator(d3scaleChromatic.interpolateBrBG);
    case "d3-white-blue":
      return d3scaleChromatic.interpolateBlues;
    case "d3-blue-white":
      return invertInterpolator(d3scaleChromatic.interpolateBlues);
    case "d3-white-purple":
      return d3scaleChromatic.interpolatePurples;
    case "d3-purple-white":
      return invertInterpolator(d3scaleChromatic.interpolatePurples);
    case "d3-white-red":
      return d3scaleChromatic.interpolateReds;
    case "d3-red-white":
      return invertInterpolator(d3scaleChromatic.interpolateReds);
    default:
      assertNever(scheme);
  }
}
