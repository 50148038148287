import { Pivot, PivotItem } from "@fluentui/react";

import { Card } from "../../../../components/Card";

import "./ManageMigrations.scss";
import { ManageMigrationsDocuments } from "./ManageMigrationsDocuments";
import { ManageMigrationsEmbeddedDocs } from "./ManageMigrationsEmbeddedDocs";
import { ManageMigrationsSettings } from "./ManageMigrationsSettings";

export function ManageMigrations() {
  return (
    <Card id="manage-migrations" className="no-min-width">
      <Pivot>
        <PivotItem itemKey="docs" headerText="Dokument">
          <ManageMigrationsDocuments></ManageMigrationsDocuments>
        </PivotItem>
        <PivotItem itemKey="embedded" headerText="Embedded docs">
          <ManageMigrationsEmbeddedDocs></ManageMigrationsEmbeddedDocs>
        </PivotItem>
        <PivotItem itemKey="settings" headerText="Measure settings">
          <ManageMigrationsSettings></ManageMigrationsSettings>
        </PivotItem>
      </Pivot>
    </Card>
  );
}
