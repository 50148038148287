/**
 * Takes an optional value, and if it's defined, maps using mapFunc.
 * If null / undefined, it returns the same without calling mapFunc.
 */
export function mapOptional<T, U>(
  mapFunc: (arg: Exclude<T, null | undefined>) => U,
  arg: T
): T extends null ? U | null : T extends undefined ? U | undefined : U {
  if (arg === null) {
    return arg as any;
  }
  if (arg === undefined) {
    return arg as any;
  }
  return mapFunc(arg as Exclude<T, null | undefined>) as any;
}
