import { InfostatBoundingBox, LatLngRaw } from "./types";

export class BoundingBox {
  constructor(
    private _boundaries: {
      north: number;
      west: number;
      east: number;
      south: number;
    }
  ) {}

  clone(): BoundingBox {
    return new BoundingBox(this._boundaries);
  }

  get south() {
    return this._boundaries.south;
  }
  get north() {
    return this._boundaries.north;
  }
  get west() {
    return this._boundaries.west;
  }
  get east() {
    return this._boundaries.east;
  }

  /**
   * Bounding box according to RFC7946
   * https://datatracker.ietf.org/doc/html/rfc7946#section-5
   */
  geoJsonBbox(): [number, number, number, number] {
    return [this.south, this.west, this.north, this.east];
  }

  padBoundingBoxMut(paddingFactor: number): void {
    const width = Math.abs(this.east - this.west);
    const additionalWidth = paddingFactor * width;
    this._boundaries.west += -additionalWidth / 2;
    this._boundaries.east += additionalWidth / 2;

    const height = Math.abs(this.north - this.south);
    const additionalHeight = paddingFactor * height;
    this._boundaries.north += -additionalHeight / 2;
    this._boundaries.south += additionalHeight / 2;
  }

  extendMutBox(other: BoundingBox): void {
    const { west, east, north, south } = other._boundaries;
    this.extendMut([south, west]);
    this.extendMut([north, east]);
  }

  /**
   * Extend this bounding box to include @latLong
   */
  extendMut(latLong: LatLngRaw): void {
    const lat = latLong[0];
    const long = latLong[1];

    if (lat > this._boundaries.north) {
      this._boundaries.north = lat;
    } else if (lat < this._boundaries.south) {
      this._boundaries.south = lat;
    }

    if (long < this._boundaries.west) {
      this._boundaries.west = long;
    } else if (long > this._boundaries.east) {
      this._boundaries.east = long;
    }
  }

  get center(): LatLngRaw {
    const { west, east, north, south } = this._boundaries;
    return [west + (east - west) / 2, south + (north - south) / 2];
  }

  static fromCorners(northWest: LatLngRaw, southEast: LatLngRaw): BoundingBox {
    const [north, west] = northWest;
    const [south, east] = southEast;
    return new BoundingBox({ north, south, west, east });
  }

  static fromInfostatBoundingBox(bbox: InfostatBoundingBox): BoundingBox {
    return new BoundingBox({
      north: bbox.ne.lat,
      south: bbox.sw.lat,
      west: bbox.sw.lng,
      east: bbox.ne.lng,
    });
  }
}
