/**
 * @returns number of decimal places in given number
 */
export function countDecimals(num: number): number {
  const split = num.toString().split(".");
  if (split.length < 2) {
    return 0;
  }
  return split[1].length;
}

/**
 * Counts the number of decimal places when only considering
 * @numSignificantFigures.
 */
export function countDecimalsSF(
  num: number,
  numSignificantFigures: number
): number {
  const split = num.toString().split(".");
  if (split.length < 2) {
    return 0;
  }
  const numSignificantPre = num < 1 ? 0 : split[0].length;
  return Math.min(
    Math.max(0, numSignificantFigures - numSignificantPre),
    split[1].length
  );
}
