import { useEffect, useMemo, useRef, useState } from "react";

import { SvgWrapper as SwgWrapper } from "./shared/SvgWrapper";
import Bounds from "./shared/Bounds";
import { AxisLineBottom } from "./shared/axes/AxisLineBottom";
import { LinesHorizontal } from "./shared/LinesHorizontal";
import { TicksLeft } from "./shared/ticks/TicksLeft";
import {
  ChartLegendBottom,
  ChartLegendRight,
} from "./shared/ChartLegendCommon";
import { ChartSourceVertical } from "./shared/ChartSource";
import { Position2D } from "../../lib/core/space/position";
import { ChartTitle } from "./shared/ChartTitle";
import { AxisLineLeft } from "./shared/axes/AxisLineLeft";
import { TextContainerPositioned } from "../../lib/application/stats/shared/core/text_containers";
import { translatePosition } from "../../lib/application/stats/svg";
import { BarAndLabelProps, BarLabel, BarWithoutLabel } from "./shared/Bar";
import { defined } from "../../lib/core/defined";
import { getDefaultTextStyleChartValueLabel } from "../../lib/application/stats/shared/core/TextStyle";
import { useUpdateSvgThumbnail } from "../../lib/application/hooks/thumbnails";
import {
  BarSpecLowBase,
  BarSpecLowInvalidChoice,
  BarSpecRegular,
} from "../../lib/application/stats/shared/bar_chart/bar_chart_common";
import { MainChartDimensions } from "../../lib/application/stats/shared/core/definitions";
import { DebugBoxes } from "./shared/DebugBoxes";
import { ChartDataContainerV2BarVertical } from "../../lib/application/state/stats/document-core/_core-shared";
import { DEFAULT_CHART_TEXT_COLOR } from "../../lib/application/stats/shared/core/colors/colors";

interface Props {
  debug?: boolean;
  cardId: string;
  dataContainer: ChartDataContainerV2BarVertical;
}

export function BarChartVerticalContainerV2(props: Props) {
  const svgContainerRef = useRef<null | SVGSVGElement>(null);
  const handleUpdate = useUpdateSvgThumbnail(svgContainerRef, props.cardId);
  useEffect(() => {
    handleUpdate();
  }, [props.dataContainer, handleUpdate]);

  return <BarChartVerticalInner {...props} svgContainerRef={svgContainerRef} />;
}

/**
 * Bar chart container where the bars are vertical.
 */
export function BarChartVerticalInner(
  props: Props & {
    svgContainerRef: React.MutableRefObject<SVGSVGElement | null>;
    /** Only used for previews, not in actual cards */
    previewBackgroundColor?: string;
  }
) {
  const svgContainerRef = props.svgContainerRef;
  const container = props.dataContainer;
  const chartData = container.data;
  const [focusedBar, setFocusedBar] = useState<string>();

  const dimensions = chartData.chartDimensions;
  const {
    main: dims,
    title: titleDims,
    legend: legendDims,
    source,
  } = dimensions;

  const labelAreas = chartData.labelAreas;

  const bottomLabelContainers = chartData.labels;
  const axisOffset = chartData.axisOffset;
  const sourcePosition: Position2D = source.position;
  const legendBottom = chartData.legendStandard;
  const legendSide = chartData.legendSide;

  const renderableBarsAndLabels: BarAndLabelProps[] = useMemo(
    () =>
      getRenderableBarsAndLabels(
        axisOffset,
        dims,
        focusedBar,
        setFocusedBar,
        container
      ),
    [axisOffset, container, dims, focusedBar]
  );

  const colorSchemeContainer = container.colorSchemeContainer;
  const fullColorScheme = colorSchemeContainer.colorScheme;

  const settings = container.data.settings;
  return (
    <div className="svg-chart-container">
      <SwgWrapper
        cardId={props.cardId}
        width={dims.fullWidth}
        height={dims.fullHeight}
        previewBackgroundColor={props.previewBackgroundColor}
        containerRef={svgContainerRef}
      >
        {props.debug && <DebugBoxes dimensions={dimensions}></DebugBoxes>}
        <ChartTitle
          debug={props.debug}
          position={titleDims.position}
          titleRowSet={chartData.title}
          fontColor={colorSchemeContainer.customHeadersColor}
        />
        <Bounds dims={dims}>
          <LinesHorizontal
            excludeY={axisOffset}
            dims={dims}
            ticksContainer={chartData.rangeTicksTopDown}
            strokeColor={
              colorSchemeContainer.customGridLinesXColor ??
              colorSchemeContainer?.customGridLinesColor
            }
            strokeDashArray={settings.gridLinesXStyle}
          />
        </Bounds>

        <TicksLeft
          hideTickLabels={settings.showYAxisLabels === false}
          fontColor={colorSchemeContainer.customLabelsColor}
          drawMarks={settings.showTicksYAxis}
          customTickStrokeColor={colorSchemeContainer?.customAxesColor}
          dims={dims}
          ticksContainer={chartData.rangeTicksTopDown}
        />

        {settings.showYAxis && (
          <AxisLineLeft
            dims={dims}
            customStrokeColor={colorSchemeContainer?.customAxesColor}
          ></AxisLineLeft>
        )}
        {settings.showXAxis && (
          <AxisLineBottom
            dims={dims}
            customStrokeColor={colorSchemeContainer?.customAxesColor}
            horizontalAxisOffset={axisOffset}
          ></AxisLineBottom>
        )}

        <Bounds dims={dims}>
          {renderableBarsAndLabels.map((barAndLabel) => (
            <BarWithoutLabel {...barAndLabel}></BarWithoutLabel>
          ))}
          {renderableBarsAndLabels.map((barAndLabel) => (
            <BarLabel
              {...barAndLabel}
              fontColor={colorSchemeContainer.customLabelsColor}
            ></BarLabel>
          ))}
        </Bounds>
        <ChartLabelsBottom
          fontColor={
            colorSchemeContainer.customLabelsColor ?? DEFAULT_CHART_TEXT_COLOR
          }
          position={{
            x: dims.marginLeft,
            y: dims.boundedHeight + dims.marginTop,
          }}
          textContainers={bottomLabelContainers}
        ></ChartLabelsBottom>
        {legendBottom && (
          <ChartLegendBottom
            fontColor={
              colorSchemeContainer.customLabelsColor ?? DEFAULT_CHART_TEXT_COLOR
            }
            colorScheme={fullColorScheme}
            legendPosition={{
              x: dims.marginLeft,
              y:
                dims.boundedHeight +
                dims.marginTop +
                (labelAreas.labelsBottom?.heightWithoutLegend ?? 0),
            }}
            legend={legendBottom}
          ></ChartLegendBottom>
        )}
        {legendSide && (
          <ChartLegendRight
            fontColor={
              colorSchemeContainer.customLabelsColor ?? DEFAULT_CHART_TEXT_COLOR
            }
            colorScheme={fullColorScheme}
            legend={legendSide}
            position={legendDims.position}
          ></ChartLegendRight>
        )}
        <ChartSourceVertical
          fontColor={colorSchemeContainer.customLabelsColor}
          position={sourcePosition}
          source={chartData.source}
        ></ChartSourceVertical>
      </SwgWrapper>
    </div>
  );
}

function getRenderableBarsAndLabels(
  axisOffset: number,
  dims: MainChartDimensions,
  focusedBar: string | undefined,
  setFocusedBar: (bar: string | undefined) => void,
  container: ChartDataContainerV2BarVertical
): BarAndLabelProps[] {
  const chartData = container.data;
  const settings = chartData.settings;
  const baseLabelSize = settings.chart.labelSize;

  const rangeMax = chartData.barsSpec.outputRange[1];
  return chartData.barsSpec.bars.map((bar) => {
    const labelPositioningNoValueLabel = calculateValueLabelYPosition(
      { height: 0, y: Math.max(rangeMax - 2 - axisOffset, 0) },
      false,
      false,
      dims.boundedHeight
    );
    const key = Object.values(bar).join("-");
    const barFocused = key === focusedBar;
    return {
      focused: barFocused,
      onMouseEnter: () => setFocusedBar(key),
      onMouseLeave: () => setFocusedBar(undefined),
      key: key,
      bar: bar,
      renderSettingsLowBase: (b: BarSpecLowBase) => {
        return {
          bar: { key },
          label: {
            elementProps: {
              textAnchor: "middle",
              x: b.domainAxisOffset + b.width / 2,
              y: labelPositioningNoValueLabel.position,
            },
          },
        };
      },
      renderSettingsInvalidChoice: (b: BarSpecLowInvalidChoice) => {
        return {
          bar: { key },
          label: {
            elementProps: {
              textAnchor: "middle",
              x: b.domainAxisOffset + b.width / 2,
              y: labelPositioningNoValueLabel.position,
            },
          },
        };
      },
      renderSettingsRegular: (b: BarSpecRegular) => {
        const commonProps = {
          key,
          className: `dataDomain=${b.domain}, dataRange=${b.range}`,
          x: b.domainAxisOffset,
          width: b.width,
          fill: container.colorSchemeContainer.colorScheme[b.colorKey],
          shapeRendering: "crispEdges",
        } as const;
        const height = b.heightFromAxis;
        const pixelRoundForRender = 0.5;
        const flip = b.flip && !settings.forceLabelsOutsideBars;
        const sizeAttributes = flip
          ? {
              y: Math.max(rangeMax - axisOffset + pixelRoundForRender, 0),
              height: Math.abs(b.heightFromAxis),
            }
          : {
              y: Math.max(
                rangeMax - axisOffset - height - pixelRoundForRender,
                0
              ),
              height,
            };

        const labelPositioning = calculateValueLabelYPosition(
          sizeAttributes,
          flip,
          settings.forceLabelsOutsideBars,
          dims.boundedHeight
        );
        const labelProps = {
          textAnchor: "middle",
          x: b.domainAxisOffset + b.width / 2,
          y: labelPositioning.position,
          dy: defined(settings.chart.valueLabelSize) ? "-0.1em" : undefined,
          style: getDefaultTextStyleChartValueLabel({
            desiredSize: baseLabelSize,
            forcedSize: settings.chart.valueLabelSize,
          }).svgFontAttrs(),
        };
        const label = defined(b.format)
          ? b.format(b.range.toString())
          : chartData.formatRange(b.range);
        return {
          bar: { ...commonProps, ...sizeAttributes },
          label:
            chartData.settings.showLabels || barFocused
              ? {
                  elementProps: labelProps,
                  shadow: labelPositioning.flip,
                  text: label,
                }
              : undefined,
        };
      },
    };
  });
}

function calculateValueLabelYPosition(
  sizeAttributes: { y: number; height: number },
  flip: boolean,
  forbidFlip: boolean,
  boundedHeight: number
): { position: number; flip: boolean } {
  if (flip) {
    const labelTooCloseToEdge =
      sizeAttributes.y + sizeAttributes.height > boundedHeight * 0.95;
    return {
      position:
        sizeAttributes.y +
        sizeAttributes.height +
        (labelTooCloseToEdge ? -2 : 3) * 5,
      flip: labelTooCloseToEdge,
    };
  }

  const labelTooCloseToEdge =
    !forbidFlip && sizeAttributes.height > boundedHeight * 0.95;
  return {
    position: sizeAttributes.y + (labelTooCloseToEdge ? 4 : -1) * 5,
    flip: labelTooCloseToEdge,
  };
}

function ChartLabelsBottom(props: {
  position: Position2D;
  textContainers: TextContainerPositioned[];
  fontColor: string;
}) {
  const commonOptions = {
    className: "mixed-alpha-numeric",
    lengthAdjust: "spacingAndGlyphs",
    dominantBaseline: "",
  };
  if (props.textContainers.length === 1) {
    return <></>;
  }
  return (
    <g transform={translatePosition(props.position)}>
      {props.textContainers.map((item) => {
        return item.lines.map((line, i) => {
          const { x, y } = line.position;
          return (
            <text
              // className={`domainOffset=${label.domainAxisOffset}`}
              {...commonOptions}
              transform={
                defined(line.rotation)
                  ? `rotate(${line.rotation},${x},${y})`
                  : undefined
              }
              style={item.textStyle.svgFontAttrs()}
              textAnchor={line.anchor}
              key={line.text + i}
              fill={props.fontColor}
              x={x}
              y={y}
            >
              {line.text}
            </text>
          );
        });
      })}
    </g>
  );
}
