import { Either } from "../../../core/func/monad";
import { SURVEY_LOW_BASE_LABEL } from "../../../domain/measure/definitions";
import { NO_VALUE_MARKER } from "../shared/core/definitions";

export type NaNType = "low_base" | "no_value";
export class DataCellValue<T> implements Either<NaNType, T> {
  private constructor(
    private _raw: { type: "ok"; value: T } | { type: "nan"; value: NaNType }
  ) {}

  fold<LeftOut, RightOut>(
    onLeft: (arg: NaNType) => RightOut,
    onRight: (arg: T) => LeftOut
  ) {
    if (this._raw.type === "ok") {
      return onRight(this._raw.value);
    }
    return onLeft(this._raw.value);
  }

  map<U>(f: (input: T) => U): DataCellValue<U> {
    if (this._raw.type === "ok") {
      return new DataCellValue<U>({
        type: "ok",
        value: f(this._raw.value),
      });
    }
    return new DataCellValue<U>({ type: "nan", value: this._raw.value });
  }

  match<LeftOut, RightOut>(matcher: {
    ok: (arg: T) => LeftOut;
    nan: (arg: NaNType) => RightOut;
  }): LeftOut | RightOut {
    if (this._raw.type === "ok") {
      return matcher.ok(this._raw.value);
    }
    return matcher.nan(this._raw.value);
  }

  /**
   * Unsafe method to get the inner value from a DataCellValue, useful for test code.
   * Throws if the value is not a T.
   */
  unwrap(): T {
    if (this._raw.type === "nan") {
      throw new Error("DataCellValue is NaN");
    }
    return this._raw.value;
  }

  static nan<T>(e: NaNType): DataCellValue<T> {
    return new DataCellValue<T>({ type: "nan", value: e });
  }

  static ok<T>(t: T): DataCellValue<T> {
    return new DataCellValue<T>({ type: "ok", value: t });
  }
}

export function displayNaNType(type: NaNType): string {
  switch (type) {
    case "low_base":
      return SURVEY_LOW_BASE_LABEL;
    case "no_value":
      return NO_VALUE_MARKER;
  }
}
