export type Listener<T> = (data: T) => void;
export class StatsEvent<T> {
  private _listeners: Listener<T>[];
  constructor() {
    this._listeners = [];
  }

  addListener(f: Listener<T>) {
    this._listeners.push(f);
  }

  removeListener(f: Listener<T>) {
    const index = this._listeners.findIndex((l) => l === f);
    if (index >= 0) {
      this._listeners.splice(index, 1);
    }
  }

  fire(data: T) {
    for (const l of this._listeners) {
      l(data);
    }
  }

  get numListeners(): number {
    return this._listeners.length;
  }

  destroy() {
    this._listeners = [];
  }
}
