import { Icon } from "@blueprintjs/core";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { classNames } from "../lib/core/classNames";
import { defined } from "../lib/core/defined";

import "./CustomDropdown.scss";

interface Props {
  onClick: (show: boolean) => void;
  isOpen: boolean;
  onDismiss: () => void;
  label: JSX.Element;
  content: JSX.Element;
  classNames?: string;
}

export function CustomDropdown(props: Props) {
  const ref = useRef<null | HTMLDivElement>(null);
  const containerRef = useRef<null | HTMLDivElement>(null);
  const dropdownContainerRef = useRef<null | HTMLDivElement>(null);
  const [dropdownHeight, setDropdownHeight] = useState<number | undefined>();
  /**
   * After a position is updated, this is set to false.
   * When the dropdownHeight has been calculated and the position recalculated, this is set to true.
   */
  const [positionValidated, setPositionValidated] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState<
    { top?: number; bottom?: number; left: number } | undefined
  >(undefined);

  useEffect(() => {
    const listener = function (event: MouseEvent) {
      if (!props.isOpen && !defined(event.target)) {
        return;
      }

      if (
        containerRef.current !== null &&
        !containerRef.current.contains(event.target as any)
      ) {
        props.onDismiss();
      }
    };
    document.addEventListener("mousedown", listener, { capture: true });
    return () => {
      document.removeEventListener("mousedown", listener, { capture: true });
    };
  });

  useEffect(() => {
    if (!props.isOpen) {
      setDropdownPosition(undefined);
      setDropdownHeight(undefined);
    }
  }, [props.isOpen]);

  const updatePosition = useCallback(() => {
    const current = ref.current;
    if (defined(current) && props.isOpen) {
      const boundingRect = current.getBoundingClientRect();
      const top = boundingRect.top;
      setPositionValidated(defined(dropdownHeight));
      if (top > window.innerHeight / 2) {
        setDropdownPosition({
          top: top - (dropdownHeight ?? 0),
          left: boundingRect.left,
        });
      } else {
        setDropdownPosition({
          top: top + boundingRect.height,
          left: boundingRect.left,
        });
      }
    }
  }, [dropdownHeight, props.isOpen]);

  useEffect(() => {
    updatePosition();
  }, [ref, updatePosition]);

  useEffect(() => {
    const listener = function (event: Event) {
      updatePosition();
    };
    document.addEventListener("scroll", listener, { capture: true });
    return () => {
      document.removeEventListener("scroll", listener, { capture: true });
    };
  }, [updatePosition]);

  return (
    <div
      className={classNames("custom-dropdown", props.classNames)}
      ref={containerRef}
    >
      <div
        className={classNames("button", props.isOpen ? "open" : "")}
        ref={ref}
        onClick={() => props.onClick(!props.isOpen)}
      >
        <div>{props.label}</div>
        <div className="icon">
          <Icon icon="chevron-down"></Icon>
        </div>
      </div>
      {props.isOpen && defined(dropdownPosition) && (
        <CustomDropdownContainer
          transparent={!positionValidated}
          setHeight={(height) => setDropdownHeight(height)}
          dropdownContainerRef={dropdownContainerRef}
          dropdownPosition={dropdownPosition}
        >
          {props.content}
        </CustomDropdownContainer>
      )}
    </div>
  );
}

function CustomDropdownContainer(props: {
  transparent: boolean;
  dropdownContainerRef: React.RefObject<HTMLDivElement>;
  dropdownPosition: { top?: number; bottom?: number; left: number };
  children: JSX.Element;
  setHeight: (height: number) => void;
}) {
  const { dropdownContainerRef, dropdownPosition } = props;

  useEffect(() => {
    const current = dropdownContainerRef.current;
    if (!defined(current)) {
      return;
    }
    const boundingRect = current.getBoundingClientRect();
    props.setHeight(boundingRect.height);
  });

  return (
    <div
      ref={dropdownContainerRef}
      className={classNames(
        props.transparent ? "transparent" : "",
        "custom-dropdown-container"
      )}
      style={{
        position: "fixed",
        top: dropdownPosition.top,
        bottom: dropdownPosition.bottom,
        left: dropdownPosition.left,
      }}
    >
      {props.children}
    </div>
  );
}
