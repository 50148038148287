let homeSearchLoaded = false;
let homeInFocusLoaded = false;

let listeners: Function[] = [];
export function listenHomeLoaded(f: Function) {
  if (listeners.some((l) => l === f)) {
    // Avoid adding the same listener twice
    return;
  }
  listeners.push(f);
}

let notified = false;
function notifyHomeLoaded() {
  if (notified) {
    return;
  }

  if (homeSearchLoaded && homeInFocusLoaded) {
    listeners.forEach((f) => f());
    notified = true;
  }
}

export function setHomeInFocusLoaded() {
  homeInFocusLoaded = true;
  notifyHomeLoaded();
}

export function setHomeSearchLoaded() {
  homeSearchLoaded = true;
  notifyHomeLoaded();
}
