import * as d3scl from "d3-scale";

export const THEME_USE_DEFAULT_SPECIAL_COLORS = true;
export const DEFAULT_GRID_LINE_COLOR = "#eeeeee";
export const DEFAULT_AXIS_COLOR = "gray";
export const DEFAULT_CHART_TEXT_COLOR = "black";
export const DEFAULT_CHART_SOURCE_COLOR = "#555";
export const DEFAULT_FONT_COLOR_TICKS = "inherit";

const INFOSTAT_OLD_BRAND_GREEN = "#59a68b";
export const INFOSTAT_INTERFACE_BLUE = "#5A81A7";
export const INFOSTAT_BRAND_BLUE = "#2980B9";
export const INFOSTAT_STD_RED = "#e05263";
export const INFOSTAT_STD_YELLOW = "#ffcf99";
export const INFOSTAT_STD_LIGHT_BLUE = "#00a6fb";
export const COLOR_GOOD_GREEN = "#2ECC71";
export const COLOR_NEUTRAL_GREY_LIGHT = "#ECF0F1";
export const COLOR_NEUTRAL_GREY_DARK = "#95A5A6";

// Base brand colors. Not used directly in interface.
// Infostat Blå
// Hex:  #235789
// Infostat Cyan
// Hex: #76C3CB
// Infostat Lila
// Hex:  #6F138F

/**
 * Colors for use in filling map areas.
 */
export const COLORS_LOW_HIGH_MICRO = [
  "#8ABBFF",
  "#4C90F0",
  "#2D72D2",
  "#215DB0",
  "#184A90",
];

export const COLORS_HIGH_LOW_RANGE_CLASSIFICATION = [
  "#d6e6f5",
  "#85b3e0",
  "#3481cb",
  "#1f4d7a",
  "#0a1a29",
];

// Brand color ranges

export const COLORS_HIGH_LOW_RANGE_BLUE = [
  "#ddeaf6",
  "#99c0e5",
  "#5696d4",
  "#2b6ba9",
  "#1a4066",
  "#091522",
];
export const COLORS_HIGH_LOW_RANGE_CYAN = [
  "#e0f1f3",
  "#a3d7dc",
  "#65bcc5",
  "#3a919a",
  "#23575c",
  "#0c1d1f",
];

export const COLORS_HIGH_LOW_RANGE_PURPLE = [
  "#f2d9fa",
  "#d78ef0",
  "#bc43e6",
  "#9219bc",
  "#570f71",
  "#1d0525",
];

export const COLORS_HIGH_LOW_RANGE_GREEN = [
  "#cffabf",
  "#a8d697",
  "#81b471",
  "#5c924c",
  "#367128",
  "#075200",
];

const COLORS_HIGH_LOW_RANGE_RED = [
  "#ffd7cb",
  "#f1b19f",
  "#df8c75",
  "#cc674d",
  "#b63f27",
  "#9e0000",
];

// End brand color ranges

/**
 * Colors from brand profile
 */
export const standardColors = [
  INFOSTAT_INTERFACE_BLUE,
  INFOSTAT_STD_RED,
  INFOSTAT_OLD_BRAND_GREEN,
  INFOSTAT_STD_YELLOW,
  "#cb769e",
  "#c6d4ff",
  "#235789",
  "#e0acd5",
  "#541388",
  "#1c5253",
  "#a66a5a",
  "#543d1d",
  "#a399ff",
  "#ade0d5",
  "#76c3cc",
  "#53a1e0",
  "#858a24",
  "#138720",
];

/**
 * Colors that are part of the standard palette but not automatically used
 */
export const extendedStandardColors = [
  "#E74C3C",
  "#9B59B6",
  "#8E44AD",
  INFOSTAT_STD_LIGHT_BLUE,
  "#3498DB",
  "#1ABC9C",
  "#16A085",
  "#27AE60",
  COLOR_GOOD_GREEN,
  "#F1C40F",
  "#F39C12",
  "#E67E22",
  "#EB984E",
  "#D35400",
  COLOR_NEUTRAL_GREY_LIGHT,
  COLOR_NEUTRAL_GREY_DARK,
  "#7F8C8D",
  "#2C3E50",
  "#1c5253",
];

export const extendedSwatchColors = [
  ...COLORS_HIGH_LOW_RANGE_BLUE,
  ...COLORS_HIGH_LOW_RANGE_CYAN,
  ...COLORS_HIGH_LOW_RANGE_PURPLE,
  ...COLORS_HIGH_LOW_RANGE_GREEN,
  ...COLORS_HIGH_LOW_RANGE_RED,
];

export const differenceSet1 = standardColors;

/**
 * Returns an ordinal scale in which colors are assigned
 * from start.
 */
export function scaleColorsCategory(x: string[]) {
  return d3scl.scaleOrdinal(x, differenceSet1);
}
