import { config } from "../../../../config";
import { HttpResult } from "../../../infra/HttpResult";
import {
  MyOrganizationDetails,
  MyOrganizationDetailsDtoRT,
} from "../../../infra/api_responses/org_admin/organization";
import { pathJoin } from "../../../paths";
import { authedRequest, decodedAuthedRequest } from "../shared";

const orgBasePath = "users/me/organization";

export function deleteUserFromOrganization(
  userId: string
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    pathJoin(orgBasePath, "users", userId),
    undefined,
    "DELETE"
  );
}

export function deleteOrganizationInvite(
  inviteId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    pathJoin(orgBasePath, "invites", inviteId.toString()),
    undefined,
    "DELETE"
  );
}

export function addUserLicense(
  userId: string,
  licenseId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    pathJoin(orgBasePath, "users", userId, "licenses"),
    { userId, license_id: licenseId },
    "POST"
  );
}

export function addUserRole(
  userId: string,
  roleId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    pathJoin(orgBasePath, "users", userId, "roles"),
    { role_id: roleId },
    "POST"
  );
}

export function removeUserRole(
  userId: string,
  roleId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    pathJoin(orgBasePath, "users", userId, "roles", roleId.toString()),
    undefined,
    "DELETE"
  );
}

export function removeUserLicense(
  userId: string,
  licenseId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    pathJoin(orgBasePath, "users", userId, "licenses", licenseId.toString()),
    undefined,
    "DELETE"
  );
}

export function addOrganizationInvites(
  email: string,
  licenseIds: number[],
  roleIds: number[]
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    pathJoin(orgBasePath, "invites"),
    {
      email,
      roles: roleIds,
      licenses: licenseIds,
    },
    "POST"
  );
}

export function sendOrganizationInviteEmailToUser(
  inviteId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV2,
    pathJoin(orgBasePath, "invites", inviteId.toString(), "notifybyemail"),
    undefined,
    "POST"
  );
}

export function getMyOrganization(): Promise<
  HttpResult<MyOrganizationDetails>
> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    orgBasePath,
    undefined,
    "GET",
    MyOrganizationDetailsDtoRT
  );
}
