import { Slider } from "@fluentui/react";

import { defined } from "../lib/core/defined";
import { DEFAULT_TIME_SPAN_SELECTION } from "../lib/domain/time";

import "./TimespanSlider.scss";

type TimeRange = [start: number, end: number];

interface Props {
  disabled: boolean;
  min: number;
  max: number;
  selectedRange?: TimeRange;
  format: (arg: number) => string;
  setTimeRange: (arg: TimeRange) => void;
}

export function TimespanSliderRange(props: Props) {
  const start = props.selectedRange?.[0];
  const end = props.selectedRange?.[1];
  return (
    <div className="infostat-slider">
      {defined(start) && defined(end) ? (
        <Slider
          disabled={props.disabled}
          valueFormat={props.format}
          ranged
          min={props.min}
          max={props.max}
          value={end}
          lowerValue={start}
          onChange={(value, range) => {
            if (range === undefined) {
              return;
            }
            props.setTimeRange(range);
          }}
        />
      ) : (
        <Slider
          key="slider-placeholder"
          disabled={true}
          ranged
          min={DEFAULT_TIME_SPAN_SELECTION[0]}
          max={DEFAULT_TIME_SPAN_SELECTION[1]}
        />
      )}
    </div>
  );
}

export function TimespanSliderSingle(props: {
  disabled: boolean;
  min: number;
  max: number;
  selectedValue?: number;
  format: (arg: number) => string;
  setTimeValue: (arg: number) => void;
}) {
  return (
    <div className="infostat-slider">
      <Slider
        disabled={props.disabled}
        valueFormat={props.format}
        min={props.min}
        max={props.max}
        value={props.selectedValue}
        onChange={(value) => {
          if (value === undefined) {
            return;
          }
          props.setTimeValue(value);
        }}
      />
    </div>
  );
}
