import { useCallback, useContext, useEffect, useState } from "react";
import { sortBy } from "lodash";

import { AlertBox } from "../../../../../components/AlertBox";
import {
  getMeasureMetadata,
  getOrganizationsWithCache,
} from "../../../../../lib/application/requests/admin/common_requests_admin";
import { defined } from "../../../../../lib/core/defined";
import { AdminMetadata } from "../../../../../lib/domain-admin/metadata";
import { StepFooter } from "./StepFooter";
import { SimpleMeasureSelect } from "../../shared/SimpleMeasureSelect";
import { MeasureWithPath } from "../../shared/MeasureWithPath";
import { CompleteData0, CompleteData1, DataStep2, DataStep3 } from "./types";
import { SimpleSubjectSelect } from "../../shared/SimpleSubjectSelect";
import { OrganizationResponse } from "../../../../../lib/infra/api_responses/admin/organizations";
import { AppMessagesContext } from "../../../../../lib/application/contexts";
import { getMeasureDimensionsV2 } from "../../../../../lib/application/requests/datasets/dimensions";
import { displayHttpErrorInternal } from "../../../../../lib/infra/HttpResult";

const copyableFields: (keyof AdminMetadata)[] = [
  "label",
  "value_type",
  "resolution",
  "agg_method_geo",
  "public_comment",
  "last_update",
  "next_update",
  "source",
  "source_url",
  "measure",
  "descr_long",
  "ext_label",
  "ext_descr",
  "ext_descr_long",
  "ext_source",
  "public_comment",
  "unit_label",
];

export function Step1SelectCategory(props: {
  data: CompleteData0;
  handlePrevious?: () => void;
  handleNext: (data: CompleteData1) => void;
}) {
  const { step1: step2 } = props.data;
  const [step2Prefilled, setStep2Prefilled] = useState<DataStep2 | undefined>(
    undefined
  );
  const [step3Prefilled, setStep3Prefilled] = useState<DataStep3 | undefined>();
  const [area, setArea] = useState<string | undefined>(step2?.area);
  const [subarea, setSubarea] = useState<string | undefined>(step2?.subarea);
  const [subject, setSubject] = useState<string | undefined>(step2?.subject);

  const [isCopying, setIsCopying] = useState(false);
  const [organizations, setOrganizations] = useState<OrganizationResponse>();

  const appMessages = useContext(AppMessagesContext);

  useEffect(() => {
    getOrganizationsWithCache().then((res) =>
      setOrganizations(sortBy(res, "name"))
    );
  }, []);

  const handleNext = useCallback(
    (finalArea: string, finalSubarea: string, finalSubject: string) => {
      props.handleNext({
        ...props.data,
        step1: {
          area: finalArea,
          subarea: finalSubarea,
          subject: finalSubject,
        },
        step2:
          (defined(step2Prefilled) ? step2Prefilled : undefined) ??
          props.data.step2,
        step3:
          (defined(step3Prefilled) ? step3Prefilled : undefined) ??
          props.data.step3,
      });
    },
    [props, step2Prefilled, step3Prefilled]
  );

  const handleCopyMeasure = useCallback(
    async (recentMeasure: MeasureWithPath) => {
      const { path, measureId } = recentMeasure;

      setIsCopying(true);
      try {
        const step2Data: DataStep2 = {};
        setArea(path[0]);
        setSubarea(path[1]);
        setSubject(path[2]);
        const m = await getMeasureMetadata(measureId);
        const dims = await getMeasureDimensionsV2(measureId, false, false).then(
          (res) => {
            return res.match({
              ok: (data) => data,
              err: (e) => {
                throw new Error(displayHttpErrorInternal(e));
              },
            });
          }
        );
        if (m.value_type === "survey") {
          return appMessages?.add("error", "Kan inte skapa surveymått här!");
        }
        for (const field of copyableFields) {
          const copyValue = m[field] as any;
          if (defined(copyValue)) {
            step2Data[field] = copyValue;
          }
        }
        step2Data.breakdowns = {};
        for (const b of sortBy(dims, (d) => d.sort_order)) {
          if (b.data_column === "breakdown1") {
            if (b.label !== "Organisation") {
              alert(
                `Kopierar breakdowns, men inte breakdown1 (var "${b.label}" för måttet som kopieras från) eftersom den specialhanteras för medlemsmått.`
              );
            }
            // Never copy breakdown1 for member orgs measures, it's always the org breakdown
            step2Data.breakdowns["breakdown1"] = "Organisation";
            continue;
          }
          step2Data.breakdowns[b.data_column] = b.label;
        }

        setStep2Prefilled(step2Data);
        const orgs = m.organization_access_restrictions;
        const ownerOrg = m.member_org_measurement_owner_org;
        if (defined(orgs) && orgs.length > 0) {
          const res = organizations?.find((org) => org.id === ownerOrg);
          if (!defined(res)) {
            throw new Error("Organization not found!");
          }
          setStep3Prefilled({ owner_org: { id: res.id, name: res.name } });
        }
      } catch (e) {
        appMessages?.add("error", "Kunde inte kopiera mått: " + e);
      } finally {
        setIsCopying(false);
      }
    },
    [appMessages, organizations]
  );

  return (
    <div className="select-category">
      <h2>Kategorisering</h2>
      <AlertBox>
        <div>
          <h3>Fyll från annat mått?</h3>
          <SimpleMeasureSelect
            actionText="Fyll i"
            onSelect={handleCopyMeasure}
          ></SimpleMeasureSelect>
        </div>
      </AlertBox>
      <section>
        <SimpleSubjectSelect
          area={area}
          subarea={subarea}
          subject={subject}
          onSelect={(path) => {
            setArea(path[0]);
            setSubarea(path[1]);
            setSubject(path[2]);
            return Promise.resolve();
          }}
        ></SimpleSubjectSelect>
      </section>

      <StepFooter
        handlePrevious={!isCopying ? props.handlePrevious : undefined}
        handleNext={
          defined(area) && defined(subarea) && defined(subject) && !isCopying
            ? () => handleNext(area, subarea, subject)
            : undefined
        }
      ></StepFooter>
    </div>
  );
}
