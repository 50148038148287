import { ChoiceGroup, Dropdown, TextField } from "@fluentui/react";
import { uniq } from "lodash";
import { useState, useContext, useCallback, useMemo } from "react";

import { AlertBox } from "../../../../components/AlertBox";
import { SimpleInputModal } from "../../../../components/SimpleInputModal";
import { AppMessagesContext } from "../../../../lib/application/contexts";
import {
  CustomThemeSpec,
  gridLineStyleOptions,
} from "../../../../lib/application/stats/shared/core/colors/colorSchemes";
import {
  standardColors,
  extendedStandardColors,
  DEFAULT_AXIS_COLOR,
  DEFAULT_GRID_LINE_COLOR,
  DEFAULT_CHART_TEXT_COLOR,
} from "../../../../lib/application/stats/shared/core/colors/colors";
import { defined } from "../../../../lib/core/defined";
import { nonEmptyString } from "../../../../lib/core/nonEmptyString";
import { HttpResult } from "../../../../lib/infra/HttpResult";
import { ColorDropdown } from "../../docs/cards/card_general/ColorOptionsDialog";
import { Button } from "../../../../components/Button";
import { CustomDataOutputSettings } from "../../../../lib/infra/api_responses/account";
import { SampleDataPreview } from "./SampleDataPreview";
import { GridLineDropdown } from "../../../../components/charts/GridLinesDropdown";

export const defaultColorsNewPalette = [
  "#005FCB",
  "#75E4D5",
  "#B190FF",
  "#FDA600",
  "#75BFFF",
  "#DE2C62",
  "#A4F9AC",
  "#812AE7",
  "#FF5B45",
  "#007A72",
  "#F76593",
  "#FFC58F",
];
interface EditColorSchemeProps {
  theme: CustomThemeSpec;
  previewMode: PreviewModeSetting;
  handleStopEditing: VoidFunction;
  handleSave: (
    newPalette: CustomThemeSpec
  ) => Promise<HttpResult<unknown>> | undefined;
  handleRemovePalette?: () => Promise<HttpResult<unknown>> | undefined;
}

function validateSchemeNameInput(input: string) {
  return !(input.length < 0 || input.length > 70);
}

export function EditTheme(props: EditColorSchemeProps) {
  const { theme, handleStopEditing, handleSave, handleRemovePalette } = props;

  const [currentColors, setCurrentColors] = useState<string[]>(theme.colors);
  const [currentBgColor, setCurrentBgColor] = useState<
    string | undefined | null
  >(theme.customBgColor);
  const [currentAxesColor, setCurrentAxesColor] = useState<
    string | undefined | null
  >(theme.customAxesColor);
  const [currentUseDefaultSpecialColors, setCurrentUseDefaultSpecialColors] =
    useState<boolean>(theme.useDefaultSpecialColors);

  const [currentGridLinesColor, setCurrentGridLinesColor] = useState<
    string | undefined | null
  >(theme.customGridLinesColor);
  const [currentGridLinesXColor, setCurrentGridLinesXColor] = useState<
    string | undefined | null
  >(theme.customGridLinesXColor);
  const [currentGridLinesYColor, setCurrentGridLinesYColor] = useState<
    string | undefined | null
  >(theme.customGridLinesYColor);
  const [currentCustomLabelsColor, setCurrentCustomLabelsColor] = useState<
    string | undefined | null
  >(theme.customLabelsColor);
  const [currentCustomHeadersColor, setCurrentCustomHeadersColor] = useState<
    string | undefined | null
  >(theme.customHeadersColor);

  const [editAsList, setEditAsList] = useState(false);
  const [editedPaletteName, setEditedPaletteName] = useState(theme.name);
  const [validationError, setValidationError] = useState<string | undefined>();
  const [isUpdating, setIsUpdating] = useState(false);

  // Chart output settings
  const [currentCustomOutputSettings, setCurrentCustomOutputSettings] =
    useState(theme.customOutputSettings);

  const currentTheme = useMemo(() => {
    const paletteNameTrimmed = editedPaletteName.trim();
    return {
      ...theme,
      customOutputSettings: currentCustomOutputSettings,
      colors: currentColors,
      name: paletteNameTrimmed,
      customAxesColor: currentAxesColor,
      customBgColor: currentBgColor,
      customGridLinesColor: currentGridLinesColor,
      customGridLinesXColor: currentGridLinesXColor,
      customGridLinesYColor: currentGridLinesYColor,
      customHeadersColor: currentCustomHeadersColor,
      customLabelsColor: currentCustomLabelsColor,
    };
  }, [
    currentAxesColor,
    currentBgColor,
    currentColors,
    currentCustomHeadersColor,
    currentCustomLabelsColor,
    currentCustomOutputSettings,
    currentGridLinesColor,
    currentGridLinesXColor,
    currentGridLinesYColor,
    editedPaletteName,
    theme,
  ]);

  const appMessages = useContext(AppMessagesContext);

  const handleOutputSettingChange = <K extends keyof CustomDataOutputSettings>(
    setting: K,
    value: CustomDataOutputSettings[K]
  ) => {
    setCurrentCustomOutputSettings((prevSettings) => ({
      ...prevSettings,
      [setting]: value,
    }));
  };

  const handleColorChange = (color: string, index: number) => {
    const newColors = [...currentColors];
    newColors[index] = color;
    setCurrentColors(newColors);
  };

  const handleAddColor = () => {
    setCurrentColors([...currentColors, "#FFFFFF"]); // Add a new default color
  };

  const handleRemoveColor = (index: number) => {
    const newColors = [...currentColors];
    newColors.splice(index, 1);
    setCurrentColors(newColors);
  };

  const handleValidateAndSave = useCallback(() => {
    const paletteNameTrimmed = editedPaletteName.trim();
    if (!validateSchemeNameInput(paletteNameTrimmed)) {
      setValidationError("Namnet måste vara mellan 1 och 70 tecken långt");
      return;
    }
    setValidationError(undefined);

    const updatedTheme = {
      ...theme,
      customOutputSettings: currentCustomOutputSettings,
      colors: currentColors,
      name: paletteNameTrimmed,
      useDefaultSpecialColors: currentUseDefaultSpecialColors,
      customAxesColor: currentAxesColor,
      customBgColor: currentBgColor,
      customGridLinesColor: currentGridLinesColor,
      customGridLinesXColor: currentGridLinesXColor,
      customGridLinesYColor: currentGridLinesYColor,
      customHeadersColor: currentCustomHeadersColor,
      customLabelsColor: currentCustomLabelsColor,
    } satisfies CustomThemeSpec;

    const promise = handleSave(updatedTheme);
    if (!defined(promise)) {
      return;
    }
    setIsUpdating(true);
    promise
      .then((res) => {
        res.match({
          ok: () => {
            handleStopEditing();
          },
          err: (e) => {
            appMessages?.add("error", "Kunde inte spara temat");
          },
        });
      })
      .finally(() => setIsUpdating(false));
  }, [
    editedPaletteName,
    theme,
    currentCustomOutputSettings,
    currentColors,
    currentUseDefaultSpecialColors,
    currentAxesColor,
    currentBgColor,
    currentGridLinesColor,
    currentGridLinesXColor,
    currentGridLinesYColor,
    currentCustomHeadersColor,
    currentCustomLabelsColor,
    handleSave,
    handleStopEditing,
    appMessages,
  ]);

  const handleRemoveScheme = useCallback(() => {
    if (!window.confirm("Är du säker på att du vill ta bort temat?")) {
      return;
    }
    const promise = handleRemovePalette?.();
    if (!defined(promise)) {
      return;
    }
    setIsUpdating(true);
    promise
      .then((res) => {
        res.match({
          ok: () => {
            handleStopEditing();
          },
          err: () => {
            appMessages?.add("error", "Kunde inte ta bort temat");
          },
        });
      })
      .finally(() => setIsUpdating(false));
  }, [appMessages, handleRemovePalette, handleStopEditing]);

  return (
    <div className="edit-theme">
      {editAsList && (
        <SimpleInputModal
          title="Redigera som färgkodslista"
          input={{
            label: "Färgkod",
            maxLength: 300,
            // Validate by splitting on whitespace, filtering out empty strings, trimming initial # characters and then
            // checking that length is 6. If not, return an error message containing faulty strings
            validate: () => {
              return undefined;
            },
            initialValue: currentColors.join("\n"),
          }}
          autoSelectText={false}
          onDismiss={() => setEditAsList(false)}
          multiline
          onSaveInput={(input) => {
            const colorStrings = input.split(/[\s,]+/);
            const newColors = colorStrings
              .filter((color) => nonEmptyString(color.trim()))
              .map((color) => color.trim());
            setCurrentColors(newColors);
            setEditAsList(false);
          }}
          actions={{
            saveLabel: "Klar",
            cancelLabel: "Avbryt",
          }}
        ></SimpleInputModal>
      )}
      <div className="theme-and-preview">
        <div>
          <TextField
            disabled={isUpdating}
            className="input-default-width margin-bottom-sm"
            label="Namn"
            value={editedPaletteName}
            onChange={(e) => {
              setEditedPaletteName(e.currentTarget.value);
            }}
          ></TextField>
          <div className="color-grid">
            {currentColors.map((color, index) => (
              <ColorDropdown
                swatchColors={uniq(
                  currentColors
                    .concat(standardColors)
                    .concat(extendedStandardColors)
                )}
                key={index + "" + currentColors.length}
                colorCode={color}
                label={index.toString()}
                applyColor={(label, color) => {
                  if (isUpdating) {
                    return;
                  }
                  handleColorChange(color, parseInt(label));
                }}
                removeColor={(label) => {
                  if (isUpdating) {
                    return;
                  }
                  handleRemoveColor(parseInt(label));
                }}
                noMargin
                mode="both"
              />
            ))}
          </div>
          <div>
            <div className="margin-bottom-sm">
              <Button
                disabled={isUpdating}
                title="Redigera som färgkodslista"
                onClick={() => setEditAsList(true)}
              ></Button>
              <Button
                disabled={isUpdating}
                title="Lägg till färg"
                onClick={handleAddColor}
              ></Button>
            </div>
          </div>

          <section className="special-colors">
            <div>
              <label>Bakgrund</label>
              <ColorDropdown
                swatchColors={[]}
                applyNoColor={() => setCurrentBgColor(null)}
                label=""
                colorCode={currentBgColor === null ? undefined : currentBgColor}
                applyColor={(label, color) => setCurrentBgColor(color)}
                applyResetColor={() => setCurrentBgColor(null)}
                mode="picker"
                noMargin
              />
            </div>
            <div>
              <label>Axlar</label>
              <ColorDropdown
                swatchColors={[]}
                applyNoColor={() => setCurrentAxesColor(null)}
                colorCode={
                  currentAxesColor === null
                    ? undefined
                    : currentAxesColor ?? DEFAULT_AXIS_COLOR
                }
                label=""
                applyResetColor={() => setCurrentAxesColor(DEFAULT_AXIS_COLOR)}
                applyColor={(label, color) => setCurrentAxesColor(color)}
                mode="picker"
                noMargin
              />
            </div>
            <div>
              <label>Stödlinjer</label>
              <ColorDropdown
                swatchColors={[]}
                applyNoColor={() => setCurrentGridLinesColor(null)}
                colorCode={
                  currentGridLinesColor === null
                    ? undefined
                    : currentGridLinesColor ?? DEFAULT_GRID_LINE_COLOR
                }
                label=""
                applyResetColor={() =>
                  setCurrentGridLinesColor(DEFAULT_GRID_LINE_COLOR)
                }
                applyColor={(label, color) => setCurrentGridLinesColor(color)}
                mode="picker"
                noMargin
              />
            </div>
            <div>
              <label>Rubriker</label>
              <ColorDropdown
                swatchColors={[]}
                applyNoColor={() => setCurrentCustomHeadersColor(null)}
                colorCode={
                  currentCustomHeadersColor ?? DEFAULT_CHART_TEXT_COLOR
                }
                label=""
                applyResetColor={() => setCurrentCustomHeadersColor(null)}
                applyColor={(label, color) =>
                  setCurrentCustomHeadersColor(color)
                }
                mode="picker"
                noMargin
              />
            </div>
            <div>
              <label>Etiketter</label>
              <ColorDropdown
                swatchColors={[]}
                applyNoColor={() => setCurrentCustomLabelsColor(null)}
                colorCode={currentCustomLabelsColor ?? DEFAULT_CHART_TEXT_COLOR}
                label=""
                applyResetColor={() => setCurrentCustomLabelsColor(null)}
                applyColor={(label, color) =>
                  setCurrentCustomLabelsColor(color)
                }
                mode="picker"
                noMargin
              />
            </div>
          </section>
          <section>
            <p className="special-colors-option">
              <strong>
                Använd färgautomatik för ordningsföljder, kön m.m.
              </strong>
            </p>
            <div>
              <BoolDropdownNoUndefined
                disabled={isUpdating}
                label=""
                value={currentUseDefaultSpecialColors}
                onChange={setCurrentUseDefaultSpecialColors}
              />
            </div>
          </section>

          <section className="">
            <details>
              <summary>Detaljerade inställningar för stödlinjer</summary>
              <div className="details-body-block">
                <div className="advanced-gridlines-settings">
                  <div>
                    <label>Färg för stödlinjer i x-led</label>
                    <ColorDropdown
                      swatchColors={[]}
                      applyNoColor={() => setCurrentGridLinesXColor(null)}
                      label=""
                      colorCode={
                        currentGridLinesXColor === null
                          ? undefined
                          : currentGridLinesXColor
                      }
                      applyColor={(label, color) =>
                        setCurrentGridLinesXColor(color)
                      }
                      applyResetColor={() => setCurrentGridLinesXColor(null)}
                      mode="picker"
                      noMargin
                    />
                  </div>

                  <div>
                    <label>Färg för stödlinjer i y-led</label>
                    <ColorDropdown
                      swatchColors={[]}
                      applyNoColor={() => setCurrentGridLinesYColor(null)}
                      label=""
                      colorCode={
                        currentGridLinesYColor === null
                          ? undefined
                          : currentGridLinesYColor
                      }
                      applyColor={(label, color) =>
                        setCurrentGridLinesYColor(color)
                      }
                      applyResetColor={() => setCurrentGridLinesYColor(null)}
                      mode="picker"
                      noMargin
                    />
                  </div>
                </div>
              </div>
              <div className="details-body-block">
                <div className="advanced-gridlines-settings">
                  <GridLineDropdown
                    label="Stil för stödlinjer i x-led"
                    selectedOption={
                      currentCustomOutputSettings?.gridLinesXStyle ??
                      gridLineStyleOptions[0]
                    }
                    handleOutputSettingChange={(value) =>
                      handleOutputSettingChange("gridLinesXStyle", value)
                    }
                  />
                  <GridLineDropdown
                    label="Stil för stödlinjer i y-led"
                    selectedOption={
                      currentCustomOutputSettings?.gridLinesYStyle ??
                      gridLineStyleOptions[0]
                    }
                    handleOutputSettingChange={(value) =>
                      handleOutputSettingChange("gridLinesYStyle", value)
                    }
                  />
                </div>
              </div>
            </details>

            <details>
              <summary>Diagramelement</summary>
              <div>
                <section className="details-body-block">
                  <BoolDropdown
                    disabled={isUpdating}
                    label="Visa etiketter"
                    value={currentCustomOutputSettings?.showLabels}
                    onChange={(val) =>
                      handleOutputSettingChange("showLabels", val)
                    }
                  />
                  <BoolDropdown
                    disabled={isUpdating}
                    label="Visa punkter i linjediagram"
                    value={currentCustomOutputSettings?.showLineCircles}
                    onChange={(val) =>
                      handleOutputSettingChange("showLineCircles", val)
                    }
                  />
                  <BoolDropdown
                    disabled={isUpdating}
                    label="Visa feta linjer i linjediagram"
                    value={currentCustomOutputSettings?.showFatLines}
                    onChange={(val) =>
                      handleOutputSettingChange("showFatLines", val)
                    }
                  />
                  <BoolDropdown
                    disabled={isUpdating}
                    label="Visa diagramtitel"
                    value={reverseBool(
                      currentCustomOutputSettings?.hideChartTitleSection
                    )}
                    onChange={(val) =>
                      handleOutputSettingChange(
                        "hideChartTitleSection",
                        reverseBool(val)
                      )
                    }
                  />
                  <BoolDropdown
                    disabled={isUpdating}
                    label="Visa kategorirubriker"
                    value={reverseBool(
                      currentCustomOutputSettings?.hideLegendDimensionLabels
                    )}
                    onChange={(val) =>
                      handleOutputSettingChange(
                        "hideLegendDimensionLabels",
                        reverseBool(val)
                      )
                    }
                  />
                </section>

                <h4>Axlar och skalstreck</h4>
                <span>
                  Notera att skalstreck bara stöds för vissa diagramtyper.
                </span>
                <div className="details-body-block">
                  <BoolDropdown
                    disabled={isUpdating}
                    label="Visa y-axel"
                    value={currentCustomOutputSettings?.showYAxis}
                    onChange={(val) =>
                      handleOutputSettingChange("showYAxis", val)
                    }
                  />
                  <BoolDropdown
                    disabled={isUpdating}
                    label="Visa x-axel"
                    value={currentCustomOutputSettings?.showXAxis}
                    onChange={(val) =>
                      handleOutputSettingChange("showXAxis", val)
                    }
                  />
                  <BoolDropdown
                    disabled={isUpdating}
                    label="Visa skalstreck längs y-axel"
                    value={currentCustomOutputSettings?.showTicksYAxis}
                    onChange={(val) =>
                      handleOutputSettingChange("showTicksYAxis", val)
                    }
                  />
                  <BoolDropdown
                    disabled={isUpdating}
                    label="Visa skalstreck längs x-axel"
                    value={currentCustomOutputSettings?.showTicksXAxis}
                    onChange={(val) =>
                      handleOutputSettingChange("showTicksXAxis", val)
                    }
                  />
                  <BoolDropdown
                    disabled={isUpdating}
                    label="Visa y-axelvärden"
                    value={currentCustomOutputSettings?.showYAxisLabels}
                    onChange={(val) =>
                      handleOutputSettingChange("showYAxisLabels", val)
                    }
                  />
                </div>
              </div>
            </details>
            <details>
              <summary>Textstorlekar</summary>
              <div className="details-body">
                <AdjustFontSizesSection
                  disabled={isUpdating}
                  settings={theme.customOutputSettings ?? {}}
                  setCustomMainHeaderSize={(size) =>
                    handleOutputSettingChange("customMainHeaderSize", size)
                  }
                  setCustomSubHeaderLargeSize={(size) =>
                    handleOutputSettingChange("customSubHeaderLargeSize", size)
                  }
                  setCustomSubHeaderSmallSize={(size) =>
                    handleOutputSettingChange("customSubHeaderSmallSize", size)
                  }
                  setCustomUnitSize={(size) =>
                    handleOutputSettingChange("customUnitSize", size)
                  }
                  setCustomLabelSize={(size) =>
                    handleOutputSettingChange("chartLabelsSize", size)
                  }
                  setCustomValueLabelSize={(size) =>
                    handleOutputSettingChange("chartValueLabelsSize", size)
                  }
                  setCustomSourceTextSize={(size) =>
                    handleOutputSettingChange("customSourceTextSize", size)
                  }
                />
              </div>
            </details>
            <details>
              <summary>Hantering av decimaler</summary>
              <div className="details-body">
                <BoolDropdown
                  label="Använd decimaler för undersökningsdata"
                  value={currentCustomOutputSettings?.showSurveyValueFraction}
                  onChange={(value) =>
                    handleOutputSettingChange("showSurveyValueFraction", value)
                  }
                />
                <NumberDropdown
                  label="Antal decimaler"
                  value={currentCustomOutputSettings?.fixedNumDecimals}
                  onChange={(value) =>
                    handleOutputSettingChange("fixedNumDecimals", value)
                  }
                  values={[0, 1, 2, 3, 4, 5]}
                />
              </div>
            </details>
          </section>

          <div>
            {nonEmptyString(validationError) && (
              <AlertBox intent="warning">
                <span>{validationError}</span>
              </AlertBox>
            )}
            <div className="buttons-container">
              <Button
                title="Avbryt"
                disabled={isUpdating}
                onClick={() => {
                  setCurrentColors(theme.colors);
                  handleStopEditing();
                }}
              ></Button>
              {defined(props.handleRemovePalette) && (
                <Button
                  disabled={isUpdating}
                  title="Ta bort schema"
                  intent="danger"
                  onClick={handleRemoveScheme}
                ></Button>
              )}
              <Button
                disabled={isUpdating}
                intent="primary"
                title="Spara"
                onClick={handleValidateAndSave}
              ></Button>
            </div>
          </div>
        </div>
        <SampleDataPreview
          theme={currentTheme}
          previewMode={props.previewMode}
        ></SampleDataPreview>
      </div>
    </div>
  );
}

export type PreviewModeSetting = "bars" | "lines";
export function PreviewMode(props: {
  current: PreviewModeSetting;
  onChange: (mode: PreviewModeSetting) => void;
}) {
  return (
    <ChoiceGroup
      className="preview-mode"
      selectedKey={props.current}
      label="Förhandsvisa"
      options={[
        { key: "bars", text: "Stapeldiagram" },
        { key: "lines", text: "Linjediagram" },
      ]}
      onChange={(e, item) => {
        if (!defined(item)) {
          return;
        }
        props.onChange(item.key as PreviewModeSetting);
      }}
      styles={{
        flexContainer: {
          display: "flex",
          flexDirection: "row",
        },
      }}
    />
  );
}

export function DisplayColorGrid(props: { colors: string[] }) {
  return (
    <div className="color-grid">
      {props.colors.map((color, index) => (
        <div
          key={`${color}-${index}-${props.colors.length}`}
          style={{
            backgroundColor: color,
            height: 22,
            width: 22,
          }}
        ></div>
      ))}
    </div>
  );
}

function BoolDropdownNoUndefined(props: {
  label: string;
  disabled?: boolean;
  trueLabel?: string;
  falseLabel?: string;
  unselectedLabel?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  return (
    <Dropdown
      disabled={props.disabled}
      label={props.label}
      selectedKey={props.value.toString()}
      options={[
        { key: "true", text: props.trueLabel ?? "Ja" },
        { key: "false", text: props.falseLabel ?? "Nej" },
      ]}
      onChange={(e, item) => {
        if (!defined(item)) {
          return;
        }
        switch (item.key) {
          case "true":
            props.onChange(true);
            break;
          case "false":
            props.onChange(false);
            break;
        }
      }}
    ></Dropdown>
  );
}

function BoolDropdown(props: {
  label: string;
  disabled?: boolean;
  trueLabel?: string;
  falseLabel?: string;
  unselectedLabel?: string;
  value: boolean | undefined;
  onChange: (value?: boolean) => void;
}) {
  return (
    <Dropdown
      disabled={props.disabled}
      label={props.label}
      selectedKey={props.value === undefined ? "undef" : props.value.toString()}
      options={[
        { key: "undef", text: props.unselectedLabel ?? "Auto" },
        { key: "true", text: props.trueLabel ?? "Ja" },
        { key: "false", text: props.falseLabel ?? "Nej" },
      ]}
      onChange={(e, item) => {
        if (!defined(item)) {
          return;
        }
        switch (item.key) {
          case "true":
            props.onChange(true);
            break;
          case "false":
            props.onChange(false);
            break;
          case "undef":
            props.onChange(undefined);
            break;
        }
      }}
    ></Dropdown>
  );
}

export function AdjustFontSizesSection(props: {
  settings: CustomDataOutputSettings;
  disabled?: boolean;
  setCustomMainHeaderSize: (size?: number) => void;
  setCustomSubHeaderLargeSize: (size?: number) => void;
  setCustomSubHeaderSmallSize: (size?: number) => void;
  setCustomUnitSize: (size?: number) => void;
  setCustomLabelSize: (size?: number) => void;
  setCustomSourceTextSize?: (size?: number) => void;
  setCustomValueLabelSize?: (size?: number) => void;
}) {
  const [mainHeaderSize, setMainHeaderSize] = useState(
    props.settings.customMainHeaderSize
  );
  const [unitSize, setUnitSize] = useState(props.settings.customUnitSize);
  const [subHeaderLargeSize, setSubHeaderLargeSize] = useState(
    props.settings.customSubHeaderLargeSize
  );
  const [subHeaderSmallSize, setSubHeaderSmallSize] = useState(
    props.settings.customSubHeaderSmallSize
  );
  const [sourceTextSize, setSourceTextSize] = useState(
    props.settings.customSourceTextSize
  );
  const [labelsSize, setLabelsSize] = useState(props.settings.chartLabelsSize);
  const [valueLabelSize, setValueLabelSize] = useState(
    props.settings.chartValueLabelsSize
  );
  return (
    <>
      <NumberDropdown
        disabled={props.disabled}
        label="Huvudrubrik"
        value={mainHeaderSize}
        onChange={(size) => {
          setMainHeaderSize(size);
          props.setCustomMainHeaderSize(size);
        }}
        values={fontSizes}
      />
      <NumberDropdown
        disabled={props.disabled}
        label="Underrubriker (stora)"
        value={subHeaderLargeSize}
        onChange={(size) => {
          setSubHeaderLargeSize(size);
          props.setCustomSubHeaderLargeSize(size);
        }}
        values={fontSizes}
      />
      <NumberDropdown
        disabled={props.disabled}
        label="Underrubriker (små)"
        value={subHeaderSmallSize}
        onChange={(size) => {
          setSubHeaderSmallSize(size);
          props.setCustomSubHeaderSmallSize(size);
        }}
        values={fontSizes}
      />
      <NumberDropdown
        disabled={props.disabled}
        label="Enhet"
        value={unitSize}
        onChange={(size) => {
          setUnitSize(size);
          props.setCustomUnitSize(size);
        }}
        values={fontSizes}
      />
      <NumberDropdown
        disabled={props.disabled}
        label="Etiketter"
        value={labelsSize}
        onChange={(size) => {
          setLabelsSize(size);
          props.setCustomLabelSize(size);
        }}
        values={fontSizes}
      />
      <NumberDropdown
        disabled={props.disabled}
        label="Dataetiketter i diagram"
        value={valueLabelSize}
        onChange={(size) => {
          setValueLabelSize(size);
          props.setCustomValueLabelSize?.(size);
        }}
        values={fontSizes}
      />
      <NumberDropdown
        disabled={props.disabled}
        label="Källtext"
        value={sourceTextSize}
        onChange={(size) => {
          setSourceTextSize(size);
          props.setCustomSourceTextSize?.(size);
        }}
        values={fontSizes}
      />
    </>
  );
}

const fontSizes = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 36];

function NumberDropdown(props: {
  disabled?: boolean;
  label: string;
  value: number | null | undefined;
  onChange: (value?: number) => void;
  values: number[];
}) {
  return (
    <Dropdown
      disabled={props.disabled}
      label={props.label}
      selectedKey={props.value?.toString() ?? "undef"}
      options={[
        { key: "undef", text: "Auto" },
        ...props.values.map((v) => ({
          key: v.toString(),
          text: v.toString(),
        })),
      ]}
      onChange={(e, item) => {
        if (!defined(item)) {
          return;
        }
        if (item.key === "undef") {
          props.onChange(undefined);
        } else {
          props.onChange(parseInt(item.key as string));
        }
      }}
    ></Dropdown>
  );
}

function reverseBool(value: boolean | undefined) {
  if (value === undefined) {
    return undefined;
  }
  return !value;
}
