import { config } from "../../../config";
import { omitUndefined } from "../../core/object";
import { SecureDeliveriesListDtoRT } from "../../infra/api_responses/secure_delivery";
import { authedRequest, decodedAuthedRequest } from "./shared";

export function listSecureDeliveries() {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "users/me/organization/securedelivery",
    undefined,
    "GET",
    SecureDeliveriesListDtoRT
  );
}

export function deleteSecureDelivery(deliveryId: number) {
  return authedRequest(
    config.apis.statsV2,
    `users/me/organization/securedelivery/${deliveryId}`,
    undefined,
    "DELETE"
  );
}

export function secureDeliveryAddRecipients(
  docId: string,
  recipients: string[],
  sendEmailToRecipients: boolean,
  emailSubject: string,
  emailBody?: string,
  expiresAt?: string
) {
  return authedRequest(
    config.apis.statsV2,
    "users/me/organization/securedelivery",
    omitUndefined({
      email_subject: emailSubject,
      email_body: emailBody,
      third_party_doc_id: docId,
      recipient_emails: recipients,
      send_email_to_recipients: sendEmailToRecipients,
      expires_at: expiresAt,
    }),
    "POST"
  );
}

export function patchSecureDelivery(
  id: number,
  recipientEmail?: string,
  expiresAt?: string,
  clearExpiresAt?: boolean
) {
  return authedRequest(
    config.apis.statsV2,
    `users/me/organization/securedelivery/${id}`,
    omitUndefined({
      recipient_email: recipientEmail,
      expires_at: expiresAt,
      clear_expires_at: clearExpiresAt,
    }),
    "PATCH"
  );
}
