import * as d3scl from "d3-scale";
import { uniq } from "lodash";
import * as _ from "lodash";

import { defined } from "../../../core/defined";
import {
  DEFAULT_MARGIN_LEFT,
  RANGE_TICKS_PADDING_PIXELS,
  RANGE_TICKS_SIZE_PIXELS,
  calcChartDimensionsStep1,
  calcChartDimensionsStep2,
  getStandardChartInnerWidth,
} from "../shared/charts_common";
import { calculateLabelAreasBarChartVertical } from "../shared/labels";

import {
  ChartType,
  Dimension,
  LegendPositioningBottom,
  LegendPositioningSide,
  Orientation,
} from "../shared/core/definitions";
import { getDefaultTextStyle } from "../shared/core/TextStyle";
import {
  calculateLegend,
  getRangeAxisOffset,
  makeBandScalesAndLabelLayersVerticalBars,
  calcBarChartColorConfig,
  AllBarsSpec,
  labelSpecToTextContainerVerticalBars,
} from "../shared/bar_chart/bar_chart_common";
import { ChartDataUnknown } from "../shared/chart_data/ChartData";
import {
  defaultTicksStyle,
  TicksStyle,
  YTicksContainer,
} from "../shared/core/ticks";
import { calculateGroupedLabels } from "../shared/bar_chart/calculateGroupedLabels";
import { calculateGroupedBarsSpec } from "../shared/bar_chart/calculateGroupedBarsSpec";
import { last } from "../../../core/last";
import { fixedNonPrimaryDimensionsValid } from "../shared/dimensions";
import {
  BarChartVerticalDataV2,
  ChartDataContainerV2BarVertical,
} from "../../state/stats/document-core/_core-shared";
import { ColorSchemeContainer } from "../../state/stats/document-style/definitions";
import {
  colorSchemesEqual,
  copyColorSchemeContainer,
  createColorSchemeContainerWithPalette,
  getSingleColor,
  makeFullColorScheme,
} from "../../state/stats/document-style/operations";
import {
  CustomThemeSpecApplied,
  defaultThemeSpec,
} from "../shared/core/colors/colorSchemes";

export function createBarChartVerticalDataAndColors<T extends ChartDataUnknown>(
  chartData: T,
  vizAreaWidth: number,
  windowHeight: number,
  defaultCustomTheme: CustomThemeSpecApplied = defaultThemeSpec()
): ChartDataContainerV2BarVertical {
  const data = createBarChartVerticalData(
    chartData,
    vizAreaWidth,
    windowHeight
  );
  return {
    renderWidth: vizAreaWidth,
    chartType: ChartType.barVertical,
    data,
    colorSchemeContainer: createColorSchemeContainer(data, defaultCustomTheme),
  };
}

export function createColorSchemeContainer(
  data: BarChartVerticalDataV2,
  defaultCustomTheme: CustomThemeSpecApplied
): ColorSchemeContainer {
  const container = createColorSchemeContainerWithPalette(defaultCustomTheme);
  const scheme = makeFullColorScheme(
    container,
    data.barsSpec.uniqueColorKeys,
    data.colorConfig.defaultToSingleColor,
    data.colorConfig.colorableDimensionLabel
  );
  container.colorScheme = scheme;

  return container;
}

export function createBarChartVerticalDataWithExistingColors<
  T extends ChartDataUnknown
>(
  chartData: T,
  vizAreaWidth: number,
  windowHeight: number,
  existingColors: ColorSchemeContainer
): ChartDataContainerV2BarVertical {
  const data = createBarChartVerticalData(
    chartData,
    vizAreaWidth,
    windowHeight
  );
  const existingColorsCopy = copyColorSchemeContainer(existingColors);
  const colorKeys = data.barsSpec.uniqueColorKeys;
  for (const key of Object.keys(existingColorsCopy.colorScheme)) {
    if (!colorKeys.includes(key)) {
      delete existingColorsCopy.colorScheme[key];
    }
  }
  const scheme = makeFullColorScheme(
    existingColorsCopy,
    colorKeys,
    data.colorConfig.defaultToSingleColor,
    data.colorConfig.colorableDimensionLabel,
    getSingleColor(existingColors)
  );
  return {
    renderWidth: vizAreaWidth,
    chartType: ChartType.barVertical,
    data,
    colorSchemeContainer: colorSchemesEqual(existingColors.colorScheme, scheme)
      ? existingColors
      : { ...existingColors, colorScheme: scheme },
  };
}

function createBarChartVerticalData<T extends ChartDataUnknown>(
  chartData: T,
  vizAreaWidth: number,
  windowHeight: number
): BarChartVerticalDataV2 {
  const settings = chartData.outputSettings;
  const barOrientation = Orientation.vertical;
  const defaultTextStyle = getDefaultTextStyle(settings.chart.labelSize);

  const {
    dataDimensions,
    nonPrimaryLabelsByDimension,
    nonPrimaryDimensions,
    legendDimension,
    legendDimensionLabel,
  } = chartData.prepareDimensionsAndLabelsBarChart(
    barOrientation,
    fixedNonPrimaryDimensionsValid(
      chartData.nonPrimaryDataDimensions,
      settings.fixedDimensionOrder ?? [],
      false
    )
      ? settings.fixedDimensionOrder ?? undefined
      : undefined
  );

  /** Custom labels, when available */
  const nonPrimaryLabelsByDimensionCustom = nonPrimaryLabelsByDimension.map(
    (d) => {
      const customLabels = settings.customLabels?.[d.dimension];
      if (!defined(customLabels)) {
        return d;
      }
      return {
        ...d,
        labelsSorted: d.labelsSorted.map((l) => customLabels[l] ?? l),
      };
    }
  );

  const innerChartWidth = getStandardChartInnerWidth(
    vizAreaWidth,
    windowHeight
  );
  const {
    layeredLabels: labelLayers,
    bandScales,
    numberOfBars,
  } = makeBandScalesAndLabelLayersVerticalBars(
    nonPrimaryLabelsByDimensionCustom,
    legendDimension,
    chartData.dimensionFormatter,
    settings.chart.labelSize,
    innerChartWidth
  );

  const groupingLabelsWithoutLegend = nonPrimaryLabelsByDimensionCustom
    .filter((d) => !defined(legendDimension) || legendDimension !== d.dimension)
    .map(({ labelsSorted }) => labelsSorted);

  const colorLabelTexts = defined(legendDimension)
    ? nonPrimaryLabelsByDimension.find((l) => l.dimension === legendDimension)
        ?.labelsSorted
    : undefined;

  const { dimensions: chartDimensionsStep1, titleRows } =
    calcChartDimensionsStep1(innerChartWidth, chartData, settings);

  const { innerChartHeight } = chartDimensionsStep1;
  const legendHeader = defined(legendDimension)
    ? chartData.dimensionHeader(legendDimension)
    : undefined;
  const legend = calculateLegend(
    barOrientation,
    innerChartHeight,
    legendHeader,
    colorLabelTexts?.map((l) => {
      if (defined(legendDimension)) {
        const formattedValue = chartData.dimensionFormatter(legendDimension, l);
        return {
          colorKey: formattedValue,
          customLabel: settings.customLabels?.[legendDimension]?.[l],
          fullOriginalLabel: formattedValue,
        };
      }
      return {
        colorKey: l,
        fullOriginalLabel: l,
      };
    }) ?? [],
    dataDimensions.length,
    innerChartWidth,
    innerChartHeight,
    settings.chart.labelSize,
    defaultTextStyle
  );

  const yTicksStyle: TicksStyle = defaultTicksStyle(settings.chart.labelSize);
  const { axisOffset, rangeTickValues, rangeScale, yTicksContainer } =
    getAxesInfo(chartData, innerChartHeight, yTicksStyle);

  const desiredMaxLabelWidthVerticalBars =
    (chartDimensionsStep1.innerChartWidth * (0.8 - 0.2 * (1 / numberOfBars))) / // The side padding is largest when num bars is small, so this number approaches 0.8 with greater number of bars
    (_.maxBy(groupingLabelsWithoutLegend, (level) => level.length)?.length ??
      numberOfBars);

  const chartSource = chartData.source(
    innerChartHeight,
    settings.customSourceTextSize
  );
  const rawLabels = calculateGroupedLabels(labelLayers, bandScales);

  const preparedLayeredLabels = labelSpecToTextContainerVerticalBars(
    innerChartWidth,
    settings.chart.labelSize,
    _.groupBy(rawLabels, (label) => label.level),
    {
      domainAxisPosition: innerChartWidth / 2,
      getDimensionHeader: (d) => chartData.dimensionHeader(d),
    }
  );

  const labelAreas = calculateLabelAreasBarChartVertical(
    [rangeTickValues],
    preparedLayeredLabels.totalHeight,
    legend?.orientation,
    colorLabelTexts,
    yTicksStyle,
    undefined,
    defaultTextStyle,
    desiredMaxLabelWidthVerticalBars
  );

  const marginLeft = Math.ceil(
    Math.max(
      (labelAreas.labelsLeft?.totalWidth ?? 0) +
        RANGE_TICKS_SIZE_PIXELS +
        RANGE_TICKS_PADDING_PIXELS,
      DEFAULT_MARGIN_LEFT
    )
  );

  const chartDimensions = calcChartDimensionsStep2(
    chartDimensionsStep1,
    0,
    labelAreas,
    chartSource,
    legend,
    marginLeft
  );

  const innerMostDimension = last(labelLayers.layers())?.dimension as
    | Dimension
    | undefined;
  const innerMostDimensionLabel = defined(innerMostDimension)
    ? chartData.dimensionToLabel(innerMostDimension)
    : undefined;

  // Labels & bars
  const bars = calculateGroupedBarsSpec(
    chartData.rows,
    nonPrimaryDimensions.map((d) => d),
    innerMostDimension,
    innerMostDimensionLabel,
    legendDimension,
    legendDimensionLabel,
    bandScales,
    (row, round) => rangeScale(round?.(row.range()) ?? row.range()),
    axisOffset,
    chartData.dimensionFormatter,
    chartData.computedValueOutputSettings,
    settings.customLabels ?? undefined
  );

  const colorConfig = calcBarChartColorConfig(
    legendDimension,
    legendDimensionLabel,
    nonPrimaryLabelsByDimension.find((l) => l.dimension === legendDimension)
      ?.labelsSorted,
    innerMostDimension,
    innerMostDimensionLabel,
    nonPrimaryLabelsByDimension.find((l) => l.dimension === innerMostDimension)
      ?.labelsSorted
  );

  let legendSide: LegendPositioningSide | undefined;
  let legendStandard: LegendPositioningBottom | undefined;

  if (defined(legend)) {
    if (legend.orientation === Orientation.vertical) {
      legendSide = legend;
    } else {
      legendStandard = legend;
    }
  }

  const barsSpec: AllBarsSpec = {
    uniqueColorKeys: uniq(bars.map((b) => b.colorKey)),
    bars,
    outputRange: rangeScale.domain().map(rangeScale) as [number, number],
  };

  return {
    title: titleRows,
    liftedDimensions: chartData.liftedDimensions(),
    legendDimension,
    colorConfig,
    axisOffset: axisOffset,
    barsSpec: barsSpec,
    labelAreas: labelAreas,
    labels: preparedLayeredLabels.preparedLabels,
    chartDimensions: chartDimensions,
    legendStandard: legendStandard,
    legendSide: legendSide,
    settings: settings,
    formatRange: (value: number) => chartData.rangeFormatter(value),
    rangeTicksTopDown: yTicksContainer,
    source: chartSource,
  };
}

function getAxesInfo<T extends ChartDataUnknown>(
  chartData: T,
  innerChartHeight: number,
  yTicksStyle: TicksStyle
) {
  const settings = chartData.outputSettings;
  const rangeScaleReversed = d3scl
    .scaleLinear()
    .domain(chartData.rangeExtent(settings.startFromZero))
    .rangeRound([innerChartHeight, 0])
    .nice();
  const rangeScaleRegular = rangeScaleReversed
    .copy()
    .rangeRound([0, innerChartHeight]);

  const ticksValues = rangeScaleReversed.ticks(5);
  const ticksRangeFormatter = chartData.makeTicksRangeFormatter(ticksValues);
  const rangeTicksTopDown = new YTicksContainer(
    chartData.normalizeTicks(
      ticksValues.map((t) => ({
        value: t,
        text: ticksRangeFormatter(t),
        offset: rangeScaleReversed(t),
      }))
    ),
    yTicksStyle
  );
  const rangeTickValues = rangeTicksTopDown.ticks.map((t) => t.text);

  const niceRange = rangeScaleRegular.domain();
  const axisOffset = getRangeAxisOffset(
    niceRange[0],
    niceRange[1],
    rangeScaleRegular
  );

  return {
    axisOffset,
    yTicksContainer: rangeTicksTopDown,
    rangeTickValues,
    rangeScale: rangeScaleRegular,
  };
}
