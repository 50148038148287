import { Icon, IconProps } from "@blueprintjs/core";
import {
  ContextualMenu,
  ContextualMenuItemType,
  IContextualMenuItem,
} from "@fluentui/react";
import { range } from "lodash";
import { useState, useRef } from "react";

import { svgChartId } from "../../../../../components/charts/shared/SvgWrapper";
import { AppMessagesHandler } from "../../../../../lib/application/AppMessagesHandler";
import {
  getSvgAsPngDataUri,
  saveAsPng,
} from "../../../../../lib/application/exports/svg";
import { toggleStatePropertyImmut } from "../../../../../lib/application/state/generic";
import { DocCardStats } from "../../../../../lib/application/state/stats/document-core/core";
import { defined } from "../../../../../lib/core/defined";
import { logger } from "../../../../../lib/infra/logging";

import "./TabToolbar.scss";
import { ChartType } from "../../../../../lib/application/stats/shared/core/definitions";
import { copyImageToClipboard } from "../../../../../lib/application/browser/copyToClipboard";
import { dataURLtoBlob } from "../../../../../lib/application/exports/dataUrlToBlob";
import { DataOutputSettings } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";
import { classNames } from "../../../../../lib/core/classNames";

type MenuItemCommon = {
  icon?: IconProps["icon"];
  disabled?: boolean;
  label: string;
};
export type TogglableMenuItem = {
  type: "toggle";
  toggled: boolean;
  onClick: () => void;
} & MenuItemCommon;

type ClickableMenuItem = {
  type: "click";
  onClick: () => void;
} & MenuItemCommon;

type DividerMenuItem = {
  type: "divider";
};

type SubmenuMenuItem = {
  type: "submenu";
  items: (TogglableMenuItem | ClickableMenuItem)[];
} & MenuItemCommon;

export type ToolbarMenuItem =
  | TogglableMenuItem
  | ClickableMenuItem
  | SubmenuMenuItem
  | DividerMenuItem;

export interface ToolbarMenu {
  type: "menu";
  icon?: IconProps["icon"];
  label: string;
  className?: string;
  items: ToolbarMenuItem[];
}

export interface ToolbarButton {
  type: "button";
  className?: string;
  icon?: IconProps["icon"];
  label: string;
  onClick: () => void;
}

export type ToolbarTopLevelItem = ToolbarMenu | ToolbarButton;

interface Props {
  menus: ToolbarTopLevelItem[];
  /** Right-aligned menus */
  menusRight?: ToolbarTopLevelItem[];
  doNotLayer?: boolean;
}

function TopLevelButton(props: { menu: ToolbarButton }) {
  const menu = props.menu;
  return (
    <div
      onClick={menu.onClick}
      className={classNames("menu top-level-button", menu.className)}
    >
      {menu.icon && (
        <Icon
          className="icon top-level-button-icon"
          icon={menu.icon}
          size={14}
          // size="sm"
        ></Icon>
      )}
      <span className="label">{menu.label}</span>
    </div>
  );
}

export function TabToolbar(props: Props) {
  return (
    <div className="tools-surface tab-toolbar">
      <div className="menus-left">
        {props.menus.map((menu) => {
          if (menu.type === "button") {
            return (
              <TopLevelButton key={menu.label} menu={menu}></TopLevelButton>
            );
          }
          return (
            <Menu
              doNotLayer={props.doNotLayer}
              key={menu.label}
              menu={menu}
            ></Menu>
          );
        })}
      </div>
      <div className="menus-right">
        {props.menusRight?.map((menu) => {
          if (menu.type === "button") {
            return (
              <TopLevelButton key={menu.label} menu={menu}></TopLevelButton>
            );
          }
          return (
            <Menu
              doNotLayer={props.doNotLayer}
              key={menu.label}
              menu={menu}
            ></Menu>
          );
        })}
      </div>
    </div>
  );
}

function Menu(props: { menu: ToolbarMenu; doNotLayer?: boolean }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const containerRef = useRef(null);
  const menu = props.menu;
  return (
    <div className={menu.className}>
      <ContextualMenu
        {...(props.doNotLayer ? { doNotLayer: true } : {})}
        className="tab-toolbar-dropdown"
        hidden={!menuOpen}
        onDismiss={() => setMenuOpen(false)}
        // eslint-disable-next-line array-callback-return
        items={menu.items.map<IContextualMenuItem>((item, i) => {
          const iconPropsObject =
            (item.type === "click" || item.type === "submenu") &&
            defined(item.icon)
              ? { iconProps: { iconName: item.icon as string } }
              : undefined;
          switch (item.type) {
            case "toggle":
              return {
                key: item.label,
                text: item.label,
                disabled: item.disabled,
                canCheck: true,
                checked: item.toggled,
                onClick: item.onClick,
              };
            case "click":
              return {
                ...iconPropsObject,
                key: item.label,
                disabled: item.disabled,
                text: item.label,
                canCheck: false,
                onClick: item.onClick,
              };
            case "submenu":
              return {
                ...iconPropsObject,
                key: item.label,
                text: item.label,
                disabled: item.disabled,
                canCheck: false,
                subMenuProps: {
                  items: item.items.map((submenuItem) => {
                    const properties: IContextualMenuItem = {
                      disabled: submenuItem.disabled,
                      key: submenuItem.label,
                      text: submenuItem.label,
                      canCheck: submenuItem.type === "toggle",
                      checked:
                        submenuItem.type === "toggle"
                          ? submenuItem.toggled
                          : undefined,
                      onClick: submenuItem.onClick,
                    };
                    if (defined(submenuItem.icon)) {
                      properties["iconProps"] = {
                        iconName: submenuItem.icon as string,
                      };
                    }

                    return properties;
                  }),
                },
              };

            case "divider":
              return {
                key: "divider-" + i,
                itemType: ContextualMenuItemType.Divider,
              };
          }
        })}
        target={containerRef}
      ></ContextualMenu>
      <div
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
        ref={containerRef}
        key={menu.label}
        className="menu"
      >
        {defined(menu.icon) && (
          <Icon className="icon" icon={menu.icon} size={14}></Icon>
        )}
        <span className="label">{menu.label}</span>
        <Icon icon="caret-down" size={10}></Icon>
      </div>
    </div>
  );
}

export function tablePaddingSidesSubMenu(
  settings: DataOutputSettings,
  setSettings: (s: DataOutputSettings) => void
): SubmenuMenuItem {
  const handleClicktablePaddingSides = (setting: string) => {
    if (settings.tablePaddingSides === setting) {
      return setSettings({ ...settings, tablePaddingSides: null });
    }
    setSettings({ ...settings, tablePaddingSides: setting });
  };

  return {
    type: "submenu",
    label: "Marginaler",
    items: [
      {
        type: "toggle",
        toggled: !defined(settings.tablePaddingSides),
        label: "Auto",
        onClick: () => {
          setSettings({
            ...settings,
            tablePaddingSides: null,
          });
        },
      },
      {
        type: "toggle",
        toggled: settings.tablePaddingSides === "small",
        label: "Små",
        onClick: () => {
          handleClicktablePaddingSides("small");
        },
      },
      {
        type: "toggle",
        toggled: settings.tablePaddingSides === "medium",
        label: "Mellan",
        onClick: () => {
          handleClicktablePaddingSides("medium");
        },
      },
      {
        type: "toggle",
        toggled: settings.tablePaddingSides === "large",
        label: "Stora",
        onClick: () => {
          handleClicktablePaddingSides("large");
        },
      },
    ],
  };
}

export function toggleShowSurveyValueFraction(
  card: DocCardStats,
  applyChange: (updatedCard: DocCardStats) => Promise<void>
): TogglableMenuItem {
  return {
    type: "toggle",
    label: "Använd decimaler för resultat",
    toggled: card.data.settings.showSurveyValueFraction,
    onClick: () => {
      const updatedCard = {
        ...card,
        data: {
          ...card.data,
          settings: toggleStatePropertyImmut(
            card.data.settings,
            "showSurveyValueFraction"
          ),
        },
      };
      applyChange(updatedCard).catch((e) => {
        logger.error(e);
      });
    },
  };
}

export function toggleReferenceValuesMenuItem(
  settings: DataOutputSettings,
  setSettings: (settings: DataOutputSettings) => void
): TogglableMenuItem {
  return {
    type: "toggle",
    label: "Visa värden för gruppen Samtliga (inga filter)",
    toggled: settings.showReferenceLines,
    onClick: () => {
      setSettings(toggleStatePropertyImmut(settings, "showReferenceLines"));
    },
  };
}

export function toggleHideMenuSectionMenuItem(
  settings: DataOutputSettings,
  setSettings: (settings: DataOutputSettings) => void
): TogglableMenuItem {
  return {
    type: "toggle",
    label: "Dölj rubriker",
    toggled: settings.hideChartTitleSection,
    onClick: () => {
      setSettings(toggleStatePropertyImmut(settings, "hideChartTitleSection"));
    },
  };
}

export function axisSettingsSubmenu(
  settings: DataOutputSettings,
  setSettings: (settings: DataOutputSettings) => void,
  openManualYAxisDialog: () => void,
  chartType?: ChartType
): SubmenuMenuItem {
  let items: (TogglableMenuItem | ClickableMenuItem)[] = [];
  if (chartType === ChartType.line) {
    items.push({
      type: "toggle",
      label: "Lås y-axel till 0",
      toggled: settings.startFromZero,
      onClick: () => {
        const settingsCopy = {
          ...settings,
          startFromZero: !settings.startFromZero,
        };
        if (
          settingsCopy.startFromZero &&
          defined(settingsCopy.customYAxisRange)
        ) {
          settingsCopy.customYAxisRange = [0, settingsCopy.customYAxisRange[1]];
        }
        setSettings(settingsCopy);
      },
    });

    items.push({
      type: "click",
      label: "Ange y-skala",
      onClick: openManualYAxisDialog,
    });
  }
  return {
    type: "submenu",
    label: "Axlar",
    disabled: items.length === 0,
    items,
  };
}

export function computedValueMenuItems(
  handleAdd: () => void,
  disableAdd: boolean
): (ClickableMenuItem | DividerMenuItem)[] {
  return [
    {
      disabled: disableAdd,
      type: "click",
      label: "Beräknade värden ...",
      onClick: () => handleAdd(),
    },
  ];
}

const numDecimalsChoices = range(0, 4);
export function decimalsModeItem(
  settings: DataOutputSettings,
  setSettings: (settings: DataOutputSettings) => void
): SubmenuMenuItem {
  return {
    type: "submenu",
    label: "Antal decimaler",
    items: [
      {
        type: "toggle",
        label: "Auto",
        toggled: settings.fixedNumDecimals === null,
        onClick: () => {
          setSettings({ ...settings, fixedNumDecimals: null });
        },
      },
      ...numDecimalsChoices.map<SubmenuMenuItem["items"][0]>((numDecimals) => ({
        type: "toggle",
        label: numDecimals.toString(),
        toggled: settings.fixedNumDecimals === numDecimals,
        onClick: () => {
          setSettings({ ...settings, fixedNumDecimals: numDecimals });
        },
      })),
    ],
  };
}

export function svgExportOptions(
  cardId: string,
  cardLabel: string,
  currentCustomBackground: string | null | undefined,
  appMessagesHandler?: AppMessagesHandler
): ClickableMenuItem[] {
  const downloadFunc = (backgroundColor?: string) => {
    saveAsPng(
      document.getElementById(svgChartId(cardId)),
      `${cardLabel}.png`,
      backgroundColor
    ).catch((e) => {
      logger.error(e);
      appMessagesHandler?.add(
        "error",
        "Misslyckades med att ladda ner som bild"
      );
    });
  };

  const items: ClickableMenuItem[] = [
    {
      icon: "media",
      label: "Kopiera bild",
      type: "click",
      onClick: () => {
        const element = document.getElementById(svgChartId(cardId));
        if (!defined(element)) {
          return appMessagesHandler?.add("warning", "Hittade inte diagrammet");
        }
        getSvgAsPngDataUri(element, currentCustomBackground ?? "white")
          .then((uri) => {
            if (!defined(uri)) {
              throw new Error("No data uri");
            }
            return dataURLtoBlob(uri);
          })
          .then((blob) => {
            if (!defined(blob)) {
              throw new Error("No blob");
            }
            return copyImageToClipboard(blob).then(() =>
              appMessagesHandler?.add("success", "Bilden kopierad")
            );
          })
          .catch((e) => {
            appMessagesHandler?.add("warning", "Kunde inte kopiera bild");
            logger.error(e);
          });
      },
    },
    {
      icon: "media",
      label: "Spara bild",
      type: "click",
      onClick: () => {
        logger.debug("Downloading chart as png...");
        downloadFunc(currentCustomBackground ?? "white");
      },
    },
    {
      icon: "media",
      label: "Spara bild med transparent bakgrund",
      type: "click",
      onClick: () => {
        logger.debug("Downloading chart as png...");
        downloadFunc();
      },
    },
  ];

  return items;
}

export function fineTuneBreakdowns(onClick: () => void): ClickableMenuItem {
  return {
    type: "click",
    label: "Visa/dölj kategorier ...",
    onClick,
  };
}

export function categoryOrderMenuItem(
  settings: DataOutputSettings,
  resetOrder: () => void,
  setNextOrder?: () => void
): SubmenuMenuItem {
  return {
    type: "submenu",
    label: "Ordning",
    items: [
      {
        type: "click",
        label: "Växla",
        disabled: !defined(setNextOrder),
        onClick: () => {
          setNextOrder?.();
        },
      },
      {
        type: "click",
        label: "Återställ",
        disabled: settings.fixedDimensionOrder === null,
        onClick: () => {
          resetOrder();
        },
      },
    ],
  };
}
