import { MessageBar, MessageBarType } from "@fluentui/react";
import { AppMessage } from "../lib/application/AppMessagesHandler";

import "./AppMessagesBar.scss";

import { defined } from "../lib/core/defined";
import { classNames } from "../lib/core/classNames";
import { Button } from "./Button";

interface Props {
  messages: AppMessage[];
  onDismiss: (id: number) => void;
}

export function AppMessagesBar(props: Props) {
  const activeMessages = props.messages.filter(
    (m) => !defined(m.expiryTime) || m.expiryTime > Date.now()
  );
  return (
    <div
      id="app-messages-bar"
      className={classNames(activeMessages.length > 0 ? "active" : "")}
    >
      {activeMessages.map((message) => {
        const messageBarType = getMessageBarType(message.type);
        return (
          <MessageBar
            className="app-message"
            onDismiss={() => props.onDismiss(message.id)}
            key={message.id}
            messageBarType={messageBarType}
            isMultiline={false}
            actions={
              defined(message.action) ? (
                <Button
                  onClick={message.action.onClick}
                  title={message.action.text}
                ></Button>
              ) : undefined
            }
          >
            <span className="text">{message.text}</span>
          </MessageBar>
        );
      })}
    </div>
  );
}

function getMessageBarType(type: AppMessage["type"]): MessageBarType {
  switch (type) {
    case "success":
      return MessageBarType.success;
    case "info":
      return MessageBarType.info;
    case "warning":
      return MessageBarType.warning;
    case "error":
      return MessageBarType.error;
  }
}
