import {
  DimensionV2Dto,
  MeasureFull,
  MeasureThin,
  SelectedDimensionsV2,
} from "../../domain/measure/definitions";
import { HttpResult } from "../../infra/HttpResult";
import { getMeasuresWithCache, getSingleMeasure } from "./common_requests";
import {
  getMicroAvailableDates,
  getMicroDimensionsWithCache,
  getSingleMicroMeasure,
} from "./datasets/micro";
import {
  ComputedMeasurementType,
  MicroMeasureDto,
} from "../../infra/api_responses/micro_dataset";
import { TimeResolution } from "../../domain/time";

type HR<T> = HttpResult<T>;
export interface StatsApi {
  getAvailableMeasures(
    subjectPath: string[],
    groupableMeasuresOnly: boolean,
    groupingMeasuresOnly: boolean,
    adminShowDraftData: boolean,
    maxResolution?: TimeResolution
  ): Promise<MeasureThin[]>;
  getDimensionsMicro(
    measureId: number,
    adminShowDraftData: boolean
  ): Promise<HR<DimensionV2Dto[] | null>>;
  getDatesMicro(
    measureId: number,
    selectedDimensions: SelectedDimensionsV2,
    adminShowDraftData: boolean
  ): Promise<HR<string[] | null>>;
  getSingleMeasure(
    measureId: number,
    adminShowDraftData: boolean,
    useCache: boolean
  ): Promise<HR<MeasureFull>>;
  getSingleMeasureMicro(
    measureId: number,
    computedMeasurementType: ComputedMeasurementType | undefined
  ): Promise<HR<MicroMeasureDto>>;
}

export const statsApi: StatsApi = {
  getAvailableMeasures: getMeasuresWithCache,
  getDatesMicro: getMicroAvailableDates,
  getDimensionsMicro: getMicroDimensionsWithCache,
  getSingleMeasure: getSingleMeasure,
  getSingleMeasureMicro: getSingleMicroMeasure,
};
