import { Icon } from "@blueprintjs/core";
import { TextField } from "@fluentui/react";
import { uniq } from "lodash";
import { useCallback, useContext, useState } from "react";

import { Button } from "../../../../../components/Button";
import { AppMessagesContext } from "../../../../../lib/application/contexts";
import { classNames } from "../../../../../lib/core/classNames";
import { defined } from "../../../../../lib/core/defined";
import { nonEmptyString } from "../../../../../lib/core/nonEmptyString";
import { Progress } from "../../../../../lib/core/progress";
import { HttpResult } from "../../../../../lib/infra/HttpResult";

interface Props {
  measureId: number;
  saveTags: (id: number, tags: string[]) => Promise<HttpResult<unknown>>;
  tags: string[] | undefined;
}

export function TagsEditor(props: Props) {
  const { measureId, tags, saveTags } = props;
  const [draftTags, setDraftTags] = useState<string[] | undefined>();
  const [value, setValue] = useState("");
  const [submitProgress, setSubmitProgress] = useState(Progress.NotStarted);
  const appMessages = useContext(AppMessagesContext);

  const handleAddDraftTag = useCallback(
    (tag: string) => {
      const checkedTag = tag.toLowerCase().trim();
      if (!nonEmptyString(checkedTag)) {
        return;
      }
      if (tags?.includes(checkedTag)) {
        appMessages?.add("error", "Taggen finns redan");
        return;
      }
      setDraftTags((draftTags) => {
        if (draftTags === undefined) {
          return [checkedTag];
        }
        return uniq([...draftTags, checkedTag]);
      });
      setValue("");
    },
    [appMessages, tags]
  );

  const handleSubmit = useCallback(() => {
    setSubmitProgress(Progress.InProgress);

    saveTags(measureId, uniq([...(tags ?? []), ...(draftTags ?? [])]))
      .then(() => {
        setSubmitProgress(Progress.NotStarted);
        setDraftTags(undefined);
      })
      .catch(() => {
        alert("Kunde inte lägga till tagg!");
        setSubmitProgress(Progress.NotStarted);
      });
  }, [measureId, tags, draftTags, saveTags]);

  const handleDeleteAll = useCallback(() => {
    if (!window.confirm("Vill du ta bort alla taggar?")) {
      return;
    }
    setDraftTags(undefined);
    saveTags(measureId, [])
      .then(() => {
        setSubmitProgress(Progress.NotStarted);
        setDraftTags(undefined);
      })
      .catch(() => {
        alert("Kunde inte ta bort alla taggar!");
        setSubmitProgress(Progress.NotStarted);
      });
  }, [measureId, saveTags]);

  const canSubmit =
    submitProgress !== Progress.InProgress &&
    nonEmptyString(value) &&
    !tags?.includes(value.toLowerCase());

  const handleDeleteTag = useCallback(
    (tag: string) => {
      setSubmitProgress(Progress.InProgress);
      if (draftTags?.includes(tag)) {
        setDraftTags((draftTags) => draftTags?.filter((t) => t !== tag));
        return;
      }

      saveTags(
        measureId,
        (tags ?? []).filter((t) => t !== tag)
      )
        .then(() => {
          setSubmitProgress(Progress.NotStarted);
          setDraftTags(undefined);
        })
        .catch(() => {
          alert("Kunde inte ta bort tagg!");
          setSubmitProgress(Progress.NotStarted);
        });
    },
    [measureId, tags, saveTags, draftTags]
  );

  return (
    <div className="tags-editor">
      <div className="label">
        <label>tags</label>
      </div>
      <div className="existing-tags">
        {tags?.map((tag) => (
          <Tag key={tag} handleDelete={handleDeleteTag} name={tag}></Tag>
        ))}
        {draftTags?.map((tag) => (
          <Tag
            unsaved
            key={tag}
            handleDelete={handleDeleteTag}
            name={tag}
          ></Tag>
        ))}
      </div>
      <div className="editable-field">
        <TextField
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleAddDraftTag(value);
            }
          }}
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
        ></TextField>
        <Button
          disabled={!canSubmit}
          intent="primary"
          onClick={() => handleAddDraftTag(value)}
          title="Lägg till (enter)"
        ></Button>
        <Button
          disabled={!defined(tags) || tags.length === 0}
          intent="danger"
          onClick={handleDeleteAll}
          title="Ta bort alla"
        ></Button>
        <Button
          disabled={!defined(draftTags) || draftTags.length === 0}
          onClick={handleSubmit}
          title="Spara"
        ></Button>
      </div>
    </div>
  );
}

function Tag(props: {
  unsaved?: boolean;
  name: string;
  handleDelete: (tag: string) => void;
}) {
  return (
    <div className={classNames("tag", props.unsaved ? "unsaved" : "")}>
      <div className="name">{props.name}</div>
      <div className="button" onClick={() => props.handleDelete(props.name)}>
        <Icon size={10} icon="cross"></Icon>
      </div>
    </div>
  );
}
