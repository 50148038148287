import { Checkbox } from "@fluentui/react";
import { useCallback } from "react";

import { AlertBox } from "../../../../../components/AlertBox";
import { DocCardMicro } from "../../../../../lib/application/state/stats/document-core/core";
import { defined } from "../../../../../lib/core/defined";
import { useChangeThirdPartyMicroCardSettings } from "../../../../../lib/application/state/actions/useChangeThirdPartyCardSettings";
import { MicroOutputTab } from "../../../../../lib/application/state/stats/document-core/core-micro";

import "./ThirdPartyMicroCardSettings.scss";

export function ThirdPartMicroCardSettings(props: { card: DocCardMicro }) {
  const { card } = props;
  const settings = card.data.thirdPartyMicroCardSettings;

  const handleUpdateSettings = useChangeThirdPartyMicroCardSettings(card.id);

  const handleToggleSetting = useCallback(
    (
      setting: keyof NonNullable<
        DocCardMicro["data"]["thirdPartyMicroCardSettings"]
      >
    ) => {
      const updatedSettings = {
        ...settings,
        [setting]: !defined(settings) ? true : !settings[setting],
      };
      if (setting === "showToolbar" && !updatedSettings.showToolbar) {
        updatedSettings.showToolbarSettings = false;
        updatedSettings.showToolbarDownload = false;
      }
      handleUpdateSettings(updatedSettings);
    },
    [handleUpdateSettings, settings]
  );

  const toggleTab = useCallback(
    (tab: MicroOutputTab) => {
      handleUpdateSettings({
        ...settings,
        hideTabs: settings?.hideTabs?.includes(tab)
          ? settings.hideTabs.filter((t) => t !== tab)
          : [...(settings?.hideTabs ?? []), tab],
      });
    },
    [handleUpdateSettings, settings]
  );

  const commonElementProps = {
    disabled: false,
    className: "element",
  };

  return (
    <AlertBox className="third-party-micro-card-settings" intent="default">
      <p>
        <strong>
          Välj vilka inställningsmöjligheter som ska finnas när dokumentet
          visas.
        </strong>
      </p>
      <div className="flex-row">
        <section>
          <Checkbox
            id="show-timeline"
            {...commonElementProps}
            checked={settings?.showTimeline ?? false}
            label="Visa tidslinje"
            onChange={() => handleToggleSetting("showTimeline")}
          ></Checkbox>
          <Checkbox
            id="show-breakdowns"
            {...commonElementProps}
            checked={settings?.showBreakdowns ?? false}
            label="Visa nedbrytningar"
            onChange={() => handleToggleSetting("showBreakdowns")}
          ></Checkbox>
          {/* TODO: Allow editing filter params later */}
          {/* <Checkbox
            id="allow-editing-filter-params"
            {...commonElementProps}
            checked={settings?.allowEditFilterParams ?? false}
            label="Tillåt redigering av filterparametrar"
            onChange={() => handleToggleSetting("allowEditFilterParams")}
          ></Checkbox> */}
        </section>

        <section>
          <Checkbox
            id="show-toolbar"
            {...commonElementProps}
            checked={settings?.showToolbar ?? false}
            label="Visa verktygsfält"
            onChange={() => handleToggleSetting("showToolbar")}
          ></Checkbox>
          <div className="indent">
            <Checkbox
              id="show-toolbar-settings"
              {...commonElementProps}
              disabled={settings?.showToolbar !== true}
              checked={settings?.showToolbarSettings ?? false}
              label="Visa verktygsfältets inställningar"
              onChange={() => handleToggleSetting("showToolbarSettings")}
            ></Checkbox>
            <Checkbox
              id="show-toolbar-download"
              {...commonElementProps}
              disabled={!(settings?.showToolbar ?? false)}
              checked={settings?.showToolbarDownload ?? false}
              label="Visa ladda ner"
              onChange={() => handleToggleSetting("showToolbarDownload")}
            ></Checkbox>
          </div>
        </section>

        <section>
          {/* TODO: implement later when necessary */}
          {/* <Checkbox
            id="third-party-doc-hide-map-select"
            {...commonElementProps}
            checked={settings?.hideTabs?.includes("map-select") ?? false}
            label="Dölj områdesväljare (karta)"
            onChange={() => toggleTab("map-select")}
          ></Checkbox> */}
          <Checkbox
            id="third-party-doc-hide-map-view"
            {...commonElementProps}
            checked={settings?.hideTabs?.includes("map-view") ?? false}
            label="Dölj resultatkarta"
            onChange={() => toggleTab("map-view")}
          ></Checkbox>
          <Checkbox
            id="third-party-doc-hide-chart"
            {...commonElementProps}
            checked={settings?.hideTabs?.includes("chart") ?? false}
            label="Dölj diagram"
            onChange={() => toggleTab("chart")}
          ></Checkbox>
          <Checkbox
            id="third-party-doc-hide-table"
            {...commonElementProps}
            checked={settings?.hideTabs?.includes("table") ?? false}
            label="Dölj tabell"
            onChange={() => toggleTab("table")}
          ></Checkbox>
          <Checkbox
            id="third-party-doc-hide-info"
            {...commonElementProps}
            checked={settings?.hideTabs?.includes("info") ?? false}
            label="Dölj info"
            onChange={() => toggleTab("info")}
          ></Checkbox>
        </section>
      </div>
    </AlertBox>
  );
}
