import { useCallback, useEffect, useMemo, useState } from "react";
import { Progress } from "../../core/progress";
import { Milliseconds } from "../../core/time";

import { HttpError, HttpResult } from "../../infra/HttpResult";
import { logger } from "../../infra/logging";
import { LoadingResult } from "../loading/LoadingResult";
import { PromiseController } from "../loading/load_status";
import { ReloadingResultWithData } from "../loading/ReloadingResultWithData.ts";
import { useIncrementer } from "./useIncrementer";

export function usePolledResource<T>(
  loader: () => Promise<HttpResult<T>>,
  waitTime: number
) {
  const resourceInfo = useReloadingHttpResource(loader);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resource, reload] = resourceInfo;

  useEffect(() => {
    const timeout = setInterval(() => {
      reload();
    }, waitTime);

    return () => {
      clearInterval(timeout);
    };
  }, [reload, waitTime]);

  return resourceInfo;
}

/**
 * Loads a network resource once by default, or every time the optional reloadDeps change.
 */
export function useLoadableHttpResource<T>(
  loader: () => Promise<HttpResult<T>>,
  reloadDeps?: any[]
) {
  const [resource, setResource] = useState<LoadingResult<HttpError, T>>(
    LoadingResult.notStarted()
  );

  const load: () => Promise<HttpResult<T>> = useCallback(() => {
    if (!resource.isReady()) {
      setResource(LoadingResult.inProgress());
    }
    return loader()
      .then((httpRes) => {
        const loadingResult = httpRes.match({
          ok: LoadingResult.from,
          err: LoadingResult.err,
        });
        setResource(loadingResult);
        return httpRes;
      })
      .catch((e) => {
        logger.error(e);
        setResource(LoadingResult.err(e));
        return HttpResult.fromErr<T>({ code: "fetch-error" });
      });
  }, [loader, resource]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, reloadDeps ?? []); // Run only on mount or when explicit dependencies change.

  const result = useMemo(() => {
    return [resource, load] as const;
  }, [load, resource]);

  return result;
}

/**
 * Load an HTTP resource and retry with exponential back-off if it fails.
 */
export function useLoadableHttpResourceWithRetries<T>(
  loader: () => Promise<HttpResult<T>>
) {
  const [resource, setResource] = useState<LoadingResult<HttpError, T>>(
    LoadingResult.notStarted()
  );
  const [numAttempts, incrementNumAttempts] = useIncrementer(0);

  const load: (promiseController: PromiseController) => Promise<unknown> =
    useCallback(
      (promiseController: PromiseController) => {
        const isAccessError = resource.fold(
          (notReady) =>
            notReady.type === Progress.Error &&
            notReady.err.code === "unauthorized",
          () => false
        );
        if (isAccessError) {
          return Promise.resolve();
        }
        if (!resource.isReady()) {
          setResource(LoadingResult.inProgress());
        }

        return loader()
          .then((httpRes) => {
            if (promiseController.stopped) {
              return;
            }
            const loadingResult = httpRes.match({
              ok: LoadingResult.from,
              err: LoadingResult.err,
            });
            setResource(loadingResult);
            return httpRes;
          })
          .catch((e) => {
            if (promiseController.stopped) {
              return;
            }
            logger.error(e);
            setResource(LoadingResult.err(e));
          })
          .finally(() => {
            incrementNumAttempts();
          });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [incrementNumAttempts, loader]
    );

  useEffect(() => {
    if (resource.isInProgress() || resource.isReady()) {
      return;
    }
    const promiseController = new PromiseController();
    const timeout = setTimeout(
      () => {
        load(promiseController);
      },
      // If no attempts done yet, invoke immediately
      // otherwise, use exponential back-off
      numAttempts === 0 ? 0 : Math.pow(2, numAttempts) * Milliseconds.second
    );

    return () => {
      clearTimeout(timeout);
      promiseController.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, numAttempts]);

  const result = useMemo(() => {
    return [resource, load] as const;
  }, [load, resource]);

  return result;
}

/**
 * Differs from @useLoadableHttpResource in that it will always set state to loading when fetching a resource,
 * even if it's been loaded before.
 */
export function useReloadingHttpResource<T>(
  loader: () => Promise<HttpResult<T>>,
  reloadDeps?: any[]
) {
  const [resource, setResource] = useState<
    ReloadingResultWithData<HttpError, T>
  >(ReloadingResultWithData.notStarted());

  const load: () => Promise<HttpResult<T>> = useCallback(() => {
    setResource((r) => r.toReloading());
    return loader()
      .then((httpRes) => {
        const loadingResult = httpRes.match({
          ok: ReloadingResultWithData.from,
          err: ReloadingResultWithData.err,
        });
        setResource(loadingResult);
        return httpRes;
      })
      .catch((e) => {
        logger.error(e);
        setResource(ReloadingResultWithData.err(e));
        return HttpResult.fromErr<T>({ code: "fetch-error" });
      });
  }, [loader]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, reloadDeps ?? []); // Run only on mount or when explicit dependencies change.

  const result = useMemo(() => {
    return [resource, load] as const;
  }, [load, resource]);

  return result;
}
