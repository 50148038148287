import _ from "lodash";

import {
  allGeoTypes,
  emptyGeoSelections,
  GeographiesSerializable,
} from "../../../../../lib/domain/geography";
import { Geo } from "../data_card/GeoSelect";
import { FluentModal, FluentModalBody } from "../../../../../components/Modal";
import {
  GeoExpansions,
  GeoSelections,
} from "../../../../../lib/application/state/stats/document-core/core";
import { defined } from "../../../../../lib/core/defined";
import { useCallback } from "react";
import { defaultGeographySelection } from "../../../../../lib/domain/measure";
import { DataSelectionWithoutId } from "../../../../../lib/domain/selections/definitions";

export function GeoModalDataframe(props: {
  dataSelection: DataSelectionWithoutId;
  geographies: GeographiesSerializable;
  geoSelections?: GeoSelections;
  geoExpansions: GeoExpansions;
  setGeoSelections: (sel: GeoSelections) => void;
  setGeoExpansions: (exp: GeoExpansions) => void;
  handleClose: () => void;
}) {
  const {
    geographies,
    dataSelection,
    geoSelections,
    geoExpansions,
    setGeoExpansions,
    setGeoSelections,
  } = props;
  const measureSelection = dataSelection.measureSelection;
  if (!defined(measureSelection)) {
    throw new Error("Measure selection must be defined");
  }
  const supportedGeographies = measureSelection.measure.geo_types;

  const handleSetExpansions = useCallback(
    (exp: GeoExpansions) => {
      setGeoExpansions(exp);
    },
    [setGeoExpansions]
  );

  const handleSetGeoSelections = useCallback(
    (sel: GeoSelections) => {
      setGeoSelections(sel);
    },
    [setGeoSelections]
  );

  const handleResetGeoSelections = useCallback(() => {
    setGeoSelections(
      defaultGeographySelection([measureSelection.measure], geographies)
    );
  }, [geographies, measureSelection.measure, setGeoSelections]);

  return (
    <FluentModal
      isOpen={true}
      onClose={props.handleClose}
      title="Geografiska områden"
      containerClassName="embedded-card-geo-modal"
    >
      <FluentModalBody>
        <div className="data-geo">
          <Geo
            isGroupingMode={false}
            supportedGeographies={supportedGeographies}
            geographies={geographies}
            selection={_.chain(geoSelections ?? emptyGeoSelections())
              .values()
              .flatten()
              .map((item) => item.geocode)
              .value()}
            expansions={geoExpansions}
            onReset={() => {
              handleResetGeoSelections();
            }}
            onExpand={(geocode, expanded) => {
              if (expanded) {
                handleSetExpansions(geoExpansions.concat(geocode));
              } else {
                handleSetExpansions(geoExpansions.filter((g) => g !== geocode));
              }
            }}
            onSelect={(geoItems, selected) => {
              const selectionsCopy: GeoSelections = defined(geoSelections)
                ? { ...geoSelections }
                : {
                    country: [],
                    nuts1: [],
                    nuts2: [],
                    nuts3: [],
                    municipal: [],
                  };
              const newlySelected = _.groupBy(geoItems, (item) => item.type);
              for (const key of allGeoTypes) {
                if (selected) {
                  selectionsCopy[key] = _.unionBy(
                    selectionsCopy[key],
                    newlySelected[key],
                    (item) => item.geocode
                  );
                } else {
                  selectionsCopy[key] = _.differenceBy(
                    selectionsCopy[key],
                    newlySelected[key],
                    (item) => item.geocode
                  );
                }
              }
              handleSetGeoSelections(selectionsCopy);
            }}
          ></Geo>
        </div>
      </FluentModalBody>
    </FluentModal>
  );
}
