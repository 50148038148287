import {
  Record,
  Boolean as BooleanRT,
  Array as ArrayRT,
  String as StringRT,
  Unknown,
  Static,
  Union,
  Literal,
  Optional,
} from "runtypes";

import { config } from "../../../../config";
import { decodedAuthedRequest } from "../shared";
import {
  DocCardPython,
  isDataframeMicroColumn,
  isDataframeStatsColumn,
  isUserDefinedColumn,
} from "../../state/stats/document-core/core";
import { defined } from "../../../core/defined";
import { geoSelectionsToCodes } from "../../../domain/geography";
import { HttpResult } from "../../../infra/HttpResult";
import { withoutUndefinedProperties } from "../../../core/object";

export interface DataColumnUserDefinedDto {
  column_name: string;
  expression: string;
}

export interface DataColumnStatsDto {
  column_name: string;
  mikro_id?: number;
  data_id?: number;
  breakdown_1: number[];
  breakdown_2: number[];
  breakdown_3: number[];
  breakdown_4: number[];
  breakdown_5: number[];
  geocodes?: string[];
  dates: string[];
}

const DataframeDtoRT = Record({
  data_frame: ArrayRT(ArrayRT(Unknown)),
});

const FileChunkDtoRT = Union(
  Record({
    type: Literal("download-csv"),
    content: StringRT,
    file_name: Optional(StringRT),
    mime_type: Optional(StringRT),
  }),
  Record({
    type: Literal("download-json"),
    content: StringRT,
    file_name: Optional(StringRT),
    mime_type: Optional(StringRT),
  }),
  Record({
    type: Literal("download-figure"),
    content: StringRT,
    file_name: Optional(StringRT),
    mime_type: Optional(StringRT),
  })
);

export type FileChunkDto = Static<typeof FileChunkDtoRT>;

const DataOutputDtoRT = Record({
  cards: ArrayRT(
    Record({
      name: Optional(StringRT),
      placement: StringRT,
      chunks: ArrayRT(
        Union(
          Record({
            type: Literal("text"),
            content: StringRT,
          }),
          Record({
            type: Literal("png"),
            content: StringRT,
          }),
          FileChunkDtoRT
        )
      ),
    })
  ),
});
export type DataOutputDto = Static<typeof DataOutputDtoRT>;
export type DataframeDto = Static<typeof DataframeDtoRT>;

export function getDataframe(dataframeSpecDto: DataframeSpecDto) {
  const dataframeInput = getDataframeArgObject(dataframeSpecDto);
  return decodedAuthedRequest(
    config.apis.statsV2,
    "/pythonanalysis/dataframe",
    dataframeInput,
    "POST",
    DataframeDtoRT
  );
}
interface StatsDataframeSpecDto {
  type: "stats";
  data_columns: DataColumnStatsDto[];
  user_defined_columns: DataColumnUserDefinedDto[];
}
interface SurveyDataframeSpecDto {
  type: "survey";
  survey_data_id: number;
  stats_data_columns: DataColumnStatsDto[];
  user_defined_columns: DataColumnUserDefinedDto[];
}
export type DataframeSpecDto = StatsDataframeSpecDto | SurveyDataframeSpecDto;

function getDataframeArgObject(dataframeSpec?: DataframeSpecDto) {
  if (!defined(dataframeSpec)) {
    return {};
  }
  if (dataframeSpec.type === "survey") {
    return {
      survey_data_frame: {
        survey_data_id: dataframeSpec.survey_data_id,
        stats_data_columns: dataframeSpec.stats_data_columns,
        user_defined_columns: dataframeSpec.user_defined_columns,
      },
    };
  }
  return {
    data_frame: {
      data_columns: dataframeSpec.data_columns,
      user_defined_columns: dataframeSpec.user_defined_columns,
    },
  };
}

export function executeCode(
  dataframeSpec: DataframeSpecDto | undefined,
  code: string
): Promise<HttpResult<DataOutputDto>> {
  const dataframeInput = getDataframeArgObject(dataframeSpec);
  return decodedAuthedRequest(
    config.apis.statsV2,
    "/pythonanalysis/output",
    withoutUndefinedProperties({
      ...dataframeInput,
      analysis_code: code,
    }),
    "POST",
    DataOutputDtoRT
  );
}

export function cardToDataframe(card: DocCardPython): DataframeSpecDto {
  const cols = card.data.columns;
  const userDefinedCols = cols.filter(isUserDefinedColumn);

  const dataColsDto = cols.map((c) => {
    if (isDataframeStatsColumn(c)) {
      const sel = c.selection.measureSelection;
      if (!defined(sel)) {
        throw new Error("Selection must be defined");
      }
      const dataId = sel.measure.data_id;
      return {
        column_name: c.columnName,
        data_id: dataId,
        breakdown_1: sel.breakdowns["breakdown1"] ?? [],
        breakdown_2: sel.breakdowns["breakdown2"] ?? [],
        breakdown_3: sel.breakdowns["breakdown3"] ?? [],
        breakdown_4: sel.breakdowns["breakdown4"] ?? [],
        breakdown_5: sel.breakdowns["breakdown5"] ?? [],
        geocodes: geoSelectionsToCodes(c.geoSelections),
        dates: c.timeSelection,
        lock_to_latest: c.lockToLatestTime,
      };
    }
    if (isDataframeMicroColumn(c)) {
      const sel = c.selection;
      if (!defined(sel)) {
        throw new Error("Selection must be defined");
      }
      const measure = sel.measure;
      if (!defined(measure)) {
        throw new Error("Measure must be defined");
      }
      const dataId = measure.id;

      return {
        column_name: c.columnName,
        mikro_id: dataId,
        breakdown_1: sel.selectedDimensions["breakdown1"] ?? [],
        breakdown_2: sel.selectedDimensions["breakdown2"] ?? [],
        breakdown_3: sel.selectedDimensions["breakdown3"] ?? [],
        breakdown_4: sel.selectedDimensions["breakdown4"] ?? [],
        breakdown_5: sel.selectedDimensions["breakdown5"] ?? [],
        geocodes: [],
        dates: c.timeSelection,
        lock_to_latest: c.lockToLatestTime,
      };
    }

    return undefined;
  });

  const userDefinedColsDto = userDefinedCols.map((c) => ({
    column_name: c.columnName,
    expression: c.expression,
  }));

  if (defined(card.data.surveySelection)) {
    const dataId =
      card.data.surveySelection.selection.measureSelection?.measure.data_id;
    if (!defined(dataId)) {
      throw new Error("Data id must be defined");
    }
    return {
      type: "survey",
      survey_data_id: dataId,
      stats_data_columns: dataColsDto.filter(defined),
      user_defined_columns: userDefinedColsDto,
    };
  }

  return {
    type: "stats",
    data_columns: dataColsDto.filter(defined),
    user_defined_columns: userDefinedColsDto,
  };
}
