import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from "mapbox-gl";
import { useCallback } from "react";
import {
  desoRegsoSearch,
  propertySearch,
} from "../../../../../lib/application/requests/search";
import { logger } from "../../../../../lib/infra/logging";

import { useAddRemoveControl } from "./useAddRemoveControl";

export function useGeocoderControl(map: mapboxgl.Map | undefined) {
  const makeControl = useCallback(() => {
    return new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl as any,
      placeholder: "Sök",
      countries: "SE",
      limit: 10,
      marker: { offset: new mapboxgl.Point(0, -25) } as any,
      externalGeocoder: (
        searchInput: string,
        features: GeoJSON.FeatureCollection<
          GeoJSON.Geometry,
          GeoJSON.GeoJsonProperties
        >
      ) => {
        if (searchInput.startsWith("#")) {
          return propertySearch(searchInput.slice(1)).then((res) => {
            return res.match({
              ok: (data) => data,
              err: (err) => {
                logger.error(err);
                return [];
              },
            });
          });
        } else if (searchInput.startsWith("!")) {
          return desoRegsoSearch(searchInput.slice(1)).then((res) => {
            return res.match({
              ok: (data) => data,
              err: (err) => {
                logger.error(err);
                return [];
              },
            });
          });
        }
        return Promise.resolve([]) as any;
      },
    }) as mapboxgl.IControl;
  }, []);

  const { add, remove, control } = useAddRemoveControl(
    map,
    makeControl,
    "top-left"
  );

  return [control, add, remove] as const;
}
