import { atom, atomFamily } from "recoil";

import {
  CardColorSchemeLink,
  CardStyleContainerGeoMicroLink,
  ColorSchemeContainer,
  StyleContainerGeoMicro,
} from "./definitions";

/** @deprecated */
export const colorSchemeContainersAtomFamily = atomFamily<
  ColorSchemeContainer | undefined,
  string
>({
  key: "colorSchemesAtomFamily",
  default: undefined,
});

/** @deprecated */
export const cardColorSchemeLinksAtom = atom<CardColorSchemeLink[]>({
  key: "cardColorSchemesList",
  default: [],
});

export const styleContainersGeoMicroAtomFamily = atomFamily<
  StyleContainerGeoMicro | undefined,
  string
>({
  key: "styleContainersGeoMicroAtomFamily",
  default: undefined,
});

export const cardStyleContainerGeoMicroLinksAtom = atom<
  CardStyleContainerGeoMicroLink[]
>({
  key: "cardStyleContainerGeoMicroLinksAtom",
  default: [],
});
