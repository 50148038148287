import { config } from "../../../../../config";
import { HttpResult } from "../../../../infra/HttpResult";
import { decodedAuthedRequest } from "../../shared";
import {
  CommitDto,
  CommitDtoRT,
  FORCE_REMOVE_CONFLICTING_VALUES_FIELD,
  RevertDto,
  RevertDtoRT,
} from "./shared";

const api = config.apis.importV2;

export function commitPendingChangesStats(
  id: number,
  forceRemoveConflictingValues?: boolean
): Promise<HttpResult<CommitDto>> {
  const opts: { [key: string]: any } = {
    data_id: id,
  };
  if (forceRemoveConflictingValues) {
    opts[FORCE_REMOVE_CONFLICTING_VALUES_FIELD] = forceRemoveConflictingValues;
  }
  return decodedAuthedRequest(
    api,
    "admin/stats/commit",
    opts,
    "POST",
    CommitDtoRT
  );
}

export function revertPendingChangesStats(
  id: number
): Promise<HttpResult<RevertDto>> {
  return decodedAuthedRequest(
    api,
    "admin/stats/revert",
    { data_id: id },
    "POST",
    RevertDtoRT
  );
}
