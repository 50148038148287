import { DEFAULT_CHART_SOURCE_COLOR } from "../../../lib/application/stats/shared/core/colors/colors";
import { TextRowSet } from "../../../lib/application/stats/shared/core/text_containers";
import {
  translatePosition,
  translate,
} from "../../../lib/application/stats/svg";
import { Position2D } from "../../../lib/core/space/position";

interface SourceProps {
  position: Position2D;
  source: TextRowSet;
  fontColor?: string | null;
}
export function ChartSource(props: SourceProps) {
  return (
    <g transform={translatePosition(props.position)}>
      {props.source.offsetRows.map((r) => {
        return (
          <text
            key={r.offsetY + "-" + r.text}
            transform={translate(0, r.offsetY + r.paddingTop)}
            {...r.textStyle.svgFontAttrs()}
            fill={props.fontColor ?? DEFAULT_CHART_SOURCE_COLOR}
            textAnchor={"left"}
          >
            {r.text}
          </text>
        );
      })}
    </g>
  );
}

export function ChartSourceVertical(props: SourceProps) {
  return (
    <g transform={translatePosition(props.position)}>
      {props.source.offsetRows.map((r) => {
        return (
          <text
            key={r.offsetY + "-" + r.text}
            transform={`translate(${r.offsetY},-5) rotate(-90)`}
            {...r.textStyle.svgFontAttrs()}
            fill={props.fontColor ?? DEFAULT_CHART_SOURCE_COLOR}
            textAnchor={"start"}
          >
            {r.text}
          </text>
        );
      })}
    </g>
  );
}
