import { Button } from "../../../../../components/Button";
import { defined } from "../../../../../lib/core/defined";

export function StepFooter(props: {
  handleNext?: () => void;
  nextTitle?: string;
  handlePrevious?: () => void;
}) {
  const { handleNext, handlePrevious, nextTitle } = props;
  return (
    <div className="step-footer">
      <Button
        disabled={!defined(handlePrevious)}
        onClick={() => handlePrevious?.()}
        title="Föregående"
      ></Button>
      <Button
        intent="primary"
        disabled={!defined(handleNext)}
        onClick={() => handleNext?.()}
        title={nextTitle ?? "Nästa"}
      ></Button>
    </div>
  );
}
