import * as _ from "lodash";

import { capitalize } from "../core/capitalize";
import {
  GeoType,
  displayGeography,
  GeographiesSerializable,
  RegionResponseItem,
  GEOCODE_MUNICIPALITY_STHLM,
  GEOCODE_NUTS3_STHLM,
  geoResolutionMin,
} from "./geography";
import {
  DocCardMicro,
  GeoSelections,
} from "../application/state/stats/document-core/core";
import { Categories } from "./categories";
import { TimeResolution } from "./time";
import { utcTimeStringToDate } from "../core/time";
import { fromPairs, identity, partition, sortBy } from "lodash";
import {
  DateRangeRaw,
  MeasureDto,
  BREAKDOWN_ALL_LABEL,
  MeasureSelection,
  SortSpec,
  MeasureThin,
  MeasureFull,
  MeasureSelectionWithoutDates,
  SelectedDimensionsV2,
  DimensionV2Dto,
  DimensionValueV2Dto,
  MeasureSurvey,
  PrimaryMeasureSelectionMicroPartial,
  MeasureSelectionGeoMicroPartial,
  MeasureSelectionWithoutDatesGeneric,
  MeasureSelectionGrouping,
  MeasureSurveyString,
} from "./measure/definitions";
import { defined } from "../core/defined";
import { parseAndFillSettings } from "../application/state/stats/default-settings/parse_encode";
import { LatestSettingsFormat } from "../application/state/stats/default-settings/common";
import { logger } from "../infra/logging";
import {
  MeasureSelectionRegular,
  MeasureSelectionSurvey,
} from "./selections/definitions";
import { statsApiV2 } from "../application/requests/statsApiV2";
import { MicroMeasureDto } from "../infra/api_responses/micro_dataset";
import { MicroSubjectPath } from "../application/state/stats/document-core/core-micro";
import { sortDimensionSpecsByParentChild } from "../application/stats/shared/dimensions";
import { getMicroAvailableDates } from "../application/requests/datasets/micro";
import { replaceInArrayImmut } from "../application/state/generic";
import { assertNever } from "../core/assert";

export function cloneDateRangeRaw(range: DateRangeRaw): DateRangeRaw {
  return [range[0], range[1]];
}

/**
 * Date range where both start and end are included
 */
export type DateRange = [start: Date, end: Date];

export function dateRangeFromRawUtc(raw: DateRangeRaw): DateRange {
  return [utcTimeStringToDate(raw[0]), utcTimeStringToDate(raw[1])];
}

/** Returns a date as a simple string in format YYYY-MM-DD */
export function dateToSimpleString(date: Date): string {
  return date.toISOString().slice(0, 10);
}
export function dateRangeToRaw(range: DateRange): DateRangeRaw {
  return [dateToSimpleString(range[0]), dateToSimpleString(range[1])];
}

export function calcTimespanUnion(timespans: DateRangeRaw[]): DateRangeRaw {
  if (timespans.length === 0) {
    throw new Error("Can't get available timespan for 0 measures!");
  } else if (timespans.length === 1) {
    return timespans[0];
  }
  const dateRanges = timespans.map(dateRangeFromRawUtc);
  const dateRange = dateRanges.reduce((prev, curr) =>
    timespanCover(prev, curr)
  );
  return dateRangeToRaw(dateRange);
}

export function calcCommonTimespan(
  timespans: DateRangeRaw[]
): DateRangeRaw | undefined {
  if (timespans.length === 0) {
    throw new Error("Can't get common timespan for 0 timespans!");
  }
  const dateRanges = timespans.map((m) => dateRangeFromRawUtc(m));
  const dateRange = dateRanges.reduce<DateRange | undefined>((prev, curr) => {
    if (!defined(prev)) {
      return undefined;
    }
    return timespanIntersection(prev, curr);
  }, dateRanges[0]);
  if (!defined(dateRange)) {
    return;
  }
  return dateRangeToRaw(dateRange);
}

export function measuresLowestResolution(
  measures: MeasureDto[]
): TimeResolution {
  const resolutions = measures.map((m) =>
    TimeResolution.deserialize(m.resolution)
  );
  return resolutions.reduce((prev, curr) =>
    prev.greaterThan(curr) ? prev : curr
  );
}

export function dateRangeRawContains(
  containingRaw: DateRangeRaw,
  containedRaw: DateRangeRaw
): boolean {
  const containing = dateRangeFromRawUtc(containingRaw);
  const contained = dateRangeFromRawUtc(containedRaw);
  return (
    containing[0].getTime() <= contained[0].getTime() &&
    containing[1].getTime() >= contained[1].getTime()
  );
}

export function dateRangeRawIsSingular(range: DateRangeRaw): boolean {
  const [start, end] = range;
  return start === end;
}

export function getSubjectPath(
  measure: Pick<MeasureDto, "area" | "subarea" | "subject">
): [string, string, string] {
  return [measure.area, measure.subarea, measure.subject];
}

export function getSubjectPathMicro(
  measure: MicroMeasureDto
): MicroSubjectPath {
  return [measure.area, measure.subarea, measure.subject];
}

export function isBreakdownTotal(breakdownValue: string) {
  return [BREAKDOWN_ALL_LABEL, "Total", "Totalt", "T"].includes(breakdownValue);
}

export function getDefaultDimensionValue(
  spec: DimensionV2Dto,
  avoidBreakdownTotal: boolean
): DimensionValueV2Dto | undefined {
  const [totals, nonTotals] = partition(spec.values, (b) =>
    isBreakdownTotal(b.label)
  );
  if (avoidBreakdownTotal) {
    return nonTotals[0] ?? totals[0];
  }

  return totals[0] ?? nonTotals[0];
}

function getDefaultDimensionValueHierarchical(
  spec: DimensionV2Dto,
  selectedValueIds: number[] | undefined
): DimensionValueV2Dto | undefined {
  if (!defined(selectedValueIds) || selectedValueIds.length === 0) {
    return undefined;
  }

  return spec.values?.find((v) => selectedValueIds.includes(v.parent_id ?? -1));
}

export function getDefaultSelectedDimensions(
  dimsDto: DimensionV2Dto[] | null
): SelectedDimensionsV2 {
  if (!defined(dimsDto) || dimsDto.length === 0) {
    return {};
  }

  const selected: SelectedDimensionsV2 = {};
  const useHierarchy = dimsDto.some((d) => defined(d.parent_id));
  if (useHierarchy) {
    const sortedDims = sortDimensionSpecsByParentChild(dimsDto);
    function findParentDim(dim: DimensionV2Dto): DimensionV2Dto | undefined {
      return sortedDims.find((d) => d.dimension_id === dim.parent_id);
    }

    for (let i = 0; i < sortedDims.length; i++) {
      const dim = sortedDims[i];
      const parentDim = findParentDim(dim);
      if (!defined(parentDim)) {
        const value = getDefaultDimensionValue(dim, false);
        if (!value) {
          logger.warn(
            "Expected to find default value for dimension",
            dim.data_column,
            dim.dimension_id
          );
          continue;
        }
        selected[dim.data_column] = [value?.id];
      } else {
        const value = getDefaultDimensionValueHierarchical(
          dim,
          selected[parentDim.data_column]
        );
        if (!value) {
          logger.warn(
            "Expected to find default value for dimension",
            dim.data_column,
            dim.dimension_id
          );
          continue;
        }
        selected[dim.data_column] = [value?.id];
      }
    }

    return selected;
  }

  let breakdownTotalUsed = false;
  for (const dim of dimsDto) {
    const value = getDefaultDimensionValue(dim, breakdownTotalUsed);

    if (!value) {
      logger.warn(
        "Expected to find default value for dimension",
        dim.data_column,
        dim.dimension_id
      );
      continue;
    }
    if (isBreakdownTotal(value.label)) {
      breakdownTotalUsed = true;
    }
    const id = value.id;
    selected[dim.data_column] = [id];
  }

  return selected;
}

export function getDefaultMeasure(measures: MeasureThin[]): MeasureThin {
  const activeMeasures = measures.filter((m) => !m.retired);
  return (
    activeMeasures.find((m) => m.default_measure === true) ??
    activeMeasures[0] ??
    measures[0]
  );
}

function* traverseCategories(categories: Categories) {
  const areas = Object.keys(categories);
  for (const area of areas) {
    const subareas = Object.keys(categories[area]);
    for (const subarea of subareas) {
      for (const subject of categories[area][subarea]) {
        yield [area, subarea, subject];
      }
    }
  }
}

export function searchSubjectNaive(
  searchString: string,
  categories: Categories
): string[][] {
  if (searchString.length < 3) {
    return [];
  }
  const results: string[][] = [];
  for (const path of traverseCategories(categories)) {
    const subject = path[2];
    if (subject.toLowerCase().startsWith(searchString)) {
      results.push(path);
    }
    if (results.length > 5) {
      break;
    }
  }
  return results;
}

function defaultMunicipality(
  regions: RegionResponseItem[]
): RegionResponseItem | undefined {
  return regions.find((r) => r.geocode === GEOCODE_MUNICIPALITY_STHLM);
}

function defaultNuts3(
  regions: RegionResponseItem[]
): RegionResponseItem | undefined {
  return regions.find((r) => r.geocode === GEOCODE_NUTS3_STHLM);
}

/**
 * Returns a selection of geographies corresponding to the lowest level of resolution.
 */
export function defaultGeographySelectionMinResolution(
  measure: MeasureDto,
  geographies: GeographiesSerializable
): GeoSelections {
  const measureGeoTypes = measure.geo_types;
  const highestResolutionGeoType: GeoType =
    measureGeoTypes.length > 1
      ? measureGeoTypes.reduce(geoResolutionMin)
      : measureGeoTypes[0];

  // Municipal or Nuts3: default to STHLM
  const items = geographies.itemsList.filter(
    highestResolutionGeoType === "municipal"
      ? (item) =>
          item.type === highestResolutionGeoType &&
          item.geocode === GEOCODE_MUNICIPALITY_STHLM
      : highestResolutionGeoType === "nuts3"
      ? (item) =>
          item.type === highestResolutionGeoType &&
          item.geocode === GEOCODE_NUTS3_STHLM
      : (item) => item.type === highestResolutionGeoType
  );

  return geographyItemsListToDict(items);
}

/**
 * Determine a default geographic selection
 *
 * If the measures have a supported geography in common, select the lowest-resolution one.
 * Else, select the lowest-resolution one among all measures.
 *
 * If the lowest supported resolution is municipal, select a default municipality in order
 * to avoid making an extremely large selection (all municipalities).
 */
export function defaultGeographySelection(
  measures: MeasureDto[],
  geographies: GeographiesSerializable
): GeoSelections {
  const measureGeoTypes = measures.map((m) => m.geo_types);
  const commonGeographies = _.intersection(...measureGeoTypes);
  const lowestResolutionGeoType: GeoType =
    commonGeographies.length > 0
      ? commonGeographies.reduce(geoResolutionMin)
      : _.flatten(measureGeoTypes).reduce(geoResolutionMin, "country");

  const selected =
    lowestResolutionGeoType === "municipal"
      ? [defaultMunicipality(geographies.itemsList)].filter(defined)
      : lowestResolutionGeoType === "nuts3"
      ? [defaultNuts3(geographies.itemsList)].filter(defined)
      : geographies.itemsList.filter(
          (item) => item.type === lowestResolutionGeoType
        );

  return geographyItemsListToDict(selected);
}

function geographyItemsListToDict(items: RegionResponseItem[]): GeoSelections {
  return _.groupBy(items, (item) => item.type) as GeoSelections;
}

export function measureSupportsMunicipal(m: MeasureDto): boolean {
  return m.geo_types.includes("municipal");
}

export function displayGeographies(m: MeasureDto): string {
  return capitalize(m.geo_types.map(displayGeography).join(", "));
}

function maxDate(a: Date, b: Date) {
  return a.getTime() > b.getTime() ? a : b;
}

function minDate(a: Date, b: Date) {
  return a.getTime() > b.getTime() ? a : b;
}

export function measureSelectionWithDefaultBreakdowns(
  m: MeasureFull,
  otherMeasures: MeasureThin[],
  isGroupingSelection: boolean
): MeasureSelectionWithoutDates {
  if (m.value_type === "survey") {
    const subquestions = m.dimensions.filter(
      (d) => d.type === "survey_subquestion"
    );
    const valueDimension = m.dimensions.find((d) => d.type === "survey_value");
    const validValues = valueDimension?.values?.map((v) => v.id);

    const breakdowns: SelectedDimensionsV2 = {};
    for (const subq of subquestions) {
      breakdowns[subq.data_column] = subq.values?.map((v) => v.id);
    }
    if (defined(valueDimension) && defined(validValues)) {
      breakdowns[valueDimension.data_column] = validValues;
    }

    return {
      valueType: m.value_type,
      measure: m,
      breakdowns,
      available: otherMeasures,
    };
  } else if (m.value_type === "survey_string") {
    const subquestions = m.dimensions.filter(
      (d) => d.type === "survey_subquestion"
    );
    const valueDimension = m.dimensions.find((d) => d.type === "survey_value");
    const validValues = valueDimension?.values?.map((v) => v.id);

    const breakdowns: SelectedDimensionsV2 = {};
    for (const subq of subquestions) {
      breakdowns[subq.data_column] = subq.values?.map((v) => v.id);
    }
    if (defined(valueDimension) && defined(validValues)) {
      breakdowns[valueDimension.data_column] = validValues;
    }

    return {
      valueType: m.value_type,
      measure: m,
      breakdowns,
      available: otherMeasures,
    };
  }

  // isGroupingSelection is only applicable when the measure is a stats measure
  else {
    const breakdowns: SelectedDimensionsV2 = getDefaultSelectedDimensions(
      m.dimensions ?? []
    );
    return {
      valueType: m.value_type,
      measure: m,
      breakdowns: isGroupingSelection
        ? trimSelectedDimensionsV2(breakdowns)
        : breakdowns,
      available: otherMeasures,
    };
  }
}

export async function tryExtractSettings(
  measure: MeasureDto
): Promise<LatestSettingsFormat | undefined> {
  try {
    const settingsRes = defined(measure.default_settings)
      ? await parseAndFillSettings(measure.data_id, measure.default_settings)
      : undefined;
    const settings = settingsRes?.unwrap();
    if (!defined(settings)) {
      return undefined;
    }
    return validSettings(measure, settings);
  } catch (e) {
    logger.error("Failed to extract settings", e);
    return undefined;
  }
}

function validSettings(
  measure: MeasureDto,
  settings: LatestSettingsFormat
): LatestSettingsFormat {
  return {
    ...settings,
    outputSettings: {
      ...settings.outputSettings,
      fixedNumDecimals: ["survey_string", "integer", "category"].includes(
        measure.value_type
      )
        ? undefined
        : settings.outputSettings.fixedNumDecimals,
    },
  };
}

/**
 * Default measure selection for a single measure document (non-editable, created on the fly, via link)
 */
export function defaultMeasureSelectionPrimary(
  measure: MeasureFull,
  availableMeasures: MeasureThin[],
  settings?: LatestSettingsFormat
): MeasureSelectionWithoutDates {
  if (defined(settings)) {
    return defaultMeasureSelectionFromStoredSettings(
      availableMeasures,
      measure,
      settings,
      false
    );
  }

  return measureSelectionWithDefaultBreakdowns(
    measure,
    availableMeasures,
    false
  );
}

/**
 * Default measure selection for a single measure document (non-editable, created on the fly, via link)
 */
export function defaultMeasureSelectionGrouping(
  measure: MeasureFull,
  availableMeasures: MeasureThin[],
  settings?: LatestSettingsFormat
): MeasureSelectionWithoutDatesGeneric<MeasureSelectionGrouping> {
  if (defined(settings)) {
    return defaultMeasureSelectionFromStoredSettings(
      availableMeasures,
      measure,
      settings,
      true
    ) as MeasureSelectionWithoutDatesGeneric<MeasureSelectionGrouping>;
  }

  return measureSelectionWithDefaultBreakdowns(
    measure,
    availableMeasures,
    true
  ) as MeasureSelectionWithoutDatesGeneric<MeasureSelectionGrouping>;
}

/**
 * Make a default measure selection.
 * Grouping selections can have only one selected value per breakdown.
 */
export function defaultMeasureSelectionFromStoredSettings(
  availableMeasures: MeasureThin[],
  measure: MeasureFull,
  settings: LatestSettingsFormat,
  isGroupingSelection: boolean
): MeasureSelectionWithoutDates {
  /** filter breakdowns v2 */
  const fbV2: (b: SelectedDimensionsV2) => SelectedDimensionsV2 =
    isGroupingSelection ? trimSelectedDimensionsV2 : (s) => s;
  const measureSettings = settings.measureSettings;
  if (measure.value_type === "survey") {
    if (measureSettings.type !== "survey") {
      throw new Error("Expected survey settings");
    }
    return {
      valueType: measure.value_type,
      available: availableMeasures,
      measure,
      breakdowns: fbV2(measureSettings.breakdownSelection),
    };
  } else if (measure.value_type === "survey_string") {
    if (measureSettings.type !== "survey_string") {
      throw new Error("Expected survey string settings");
    }
    return {
      valueType: measure.value_type,
      available: availableMeasures,
      measure,
      breakdowns: fbV2(measureSettings.breakdownSelection),
    };
  }

  if (measureSettings.type !== "stats") {
    throw new Error("Expected stats settings");
  }
  return {
    valueType: measure.value_type,
    available: availableMeasures,
    measure,
    breakdowns: fbV2(measureSettings.breakdownSelection),
  };
}

function trimSelectedDimensionsV2(
  selection: SelectedDimensionsV2
): SelectedDimensionsV2 {
  return fromPairs(
    Object.entries(selection).map(([key, values]) => [key, values?.slice(0, 1)])
  );
}

/**
 * Mutates the input, setting available dates for selection, if applicable.
 * Only applicable when the measure has week resolution.
 */
export function setMeasureAvailableDatesMut<T extends MeasureSelection>(
  measureSelection: MeasureSelectionWithoutDatesGeneric<T>,
  adminShowDraftData: boolean
): Promise<T> {
  switch (measureSelection.valueType) {
    case "survey":
    case "survey_string":
      return statsApiV2
        .getSurveyDates(
          measureSelection.measure.data_id,
          adminShowDraftData,
          true
        )
        .then((res) => {
          return {
            ...measureSelection,
            availableDates: res.unwrap() ?? [],
          } as T;
        });
    case "category":
    case "decimal":
    case "integer":
      return statsApiV2
        .getStatsDates(
          measureSelection.measure.data_id,
          measureSelection.breakdowns,
          adminShowDraftData,
          true
        )
        .then((res) => {
          return {
            ...measureSelection,
            availableDates: res.unwrap() ?? [],
          } as T;
        });
  }
  assertNever(measureSelection.valueType);
}

export async function setMicroCardAvailableDatesImmut(
  readCard: () => DocCardMicro,
  selection:
    | PrimaryMeasureSelectionMicroPartial
    | MeasureSelectionGeoMicroPartial,
  adminShowDraftData: boolean
) {
  const measureId = selection.measure?.id;
  if (!defined(measureId)) {
    logger.warn("Could not find measure ID", selection);
    return readCard();
  }

  const availableDates =
    (
      await getMicroAvailableDates(
        measureId,
        selection.selectedDimensions,
        adminShowDraftData
      )
    ).unwrap() ?? [];

  const card = readCard();
  const dataSelections = card.data.dataSelections;
  if (!defined(dataSelections)) {
    logger.warn("Could not find data selections", card);
    return card;
  }

  return {
    ...card,
    data: {
      ...card.data,
      dataSelections: replaceInArrayImmut(
        dataSelections,
        (s) => s.id === selection.id,
        (s) => ({
          ...s,
          availableDates,
        })
      ),
    },
  };
}

/**
 * Returns the longest common timespan of a and b,
 * or undefined if no intersection
 */
export function timespanIntersection(
  a: DateRange,
  b: DateRange
): DateRange | undefined {
  const aStart = a[0].getTime();
  const aEnd = a[1].getTime();
  const bStart = b[0].getTime();
  const bEnd = b[1].getTime();
  if (aStart <= bStart && aEnd >= bEnd) {
    return [b[0], b[1]];
  } else if (bStart <= aStart && bEnd >= aEnd) {
    return [a[0], a[1]];
  } else if (aEnd >= bStart && aEnd <= bEnd) {
    return [maxDate(a[0], b[0]), a[1]];
  } else if (aStart >= bStart && aStart <= bEnd) {
    return [a[0], minDate(a[1], b[1])];
  }
  return;
}

/**
 * Returns the longest timespan covered by any of a or b
 */
export function timespanCover(a: DateRange, b: DateRange): DateRange {
  const early = minDate(a[0], b[0]);
  const late = maxDate(a[1], b[1]);
  return [early, late];
}

export class SortedLayers<T> {
  constructor(
    private _parts: {
      top: T[];
      middle: T[];
      bottom: T[];
    }
  ) {}

  get middle() {
    return this._parts.middle;
  }
  get bottom() {
    return this._parts.bottom;
  }
  get top() {
    return this._parts.top;
  }

  /**
   * @returns a singe array with values in order top, middle, bottom
   */
  merged() {
    const { bottom, middle, top } = this._parts;
    return top.concat(middle, bottom);
  }
}

export type { SortSpec };

const integerSpanBreakdowns = [
  "Arbetslöshetstid",
  "Boendeyta",
  "Färdigställandeår",
  "Gruppstorlek",
  "Graviditetsvecka",
  "Inkomst",
  "Moderns ålder",
  "Storlek i m2",
  "Storlek i m²",
  "Tid i arbetslöshet",
  "Transportavstånd",
  "Vanligen arbetad tid",
  "Vistelsetid i Sverige",
  "Ålder",
  "Åldersklass",
];

/**
 * Make sort spec for integer spans with optional suffix. Works for age groups, sizes, examples:
 * [0-9 år, 10-19 år, 20-29 år]
 * [0-5 kvm, 6-10 kvm, 11-15 kvm]
 * [-5 kvm, 6-10 kvm, 11+ kvm]
 * [0-10, 11-20, 21-30, 31-40, 41-50, 51-60, 61-70, 71-80, 81-90, 91-100]
 */
export function makeIntegerSpanSortSpec(values: string[]): SortSpec {
  const isFirstSpan = (value: string) => value[0] === "-";
  const isNumber = (value: string) => {
    const firstInt = parseInt(value[0]);
    return typeof firstInt === "number" && firstInt >= 0 && firstInt <= 9;
  };
  const [topSorted, other] = partition(
    values,
    (v) => isFirstSpan(v) || isBreakdownTotal(v)
  );
  const [ageSpans, bottomSorted] = partition(other, (v) => {
    try {
      return isNumber(v);
    } catch (e) {
      return false;
    }
  });

  return {
    orderBottom: bottomSorted.sort(),
    orderTop: topSorted
      .sort((left, right) => {
        return isBreakdownTotal(left) && !isBreakdownTotal(right) ? -1 : 1;
      })
      .concat(
        ageSpans.sort((left, right) => {
          const leftNum = parseInt(left.split("-")[0]);
          const rightNum = parseInt(right.split("-")[0]);
          return leftNum < rightNum ? -1 : 1;
        })
      ),
  };
}

/**
 * Construct three layers of ordered values, in descending order: top, middle and bottom.
 *
 * Values in validTop and validBottom are removed from all and put in separate bags,
 * in the given order.
 */
export function applySortSpecGeneric<T>(
  values: T[],
  spec: SortSpec | undefined,
  getLabel: (value: T) => string,
  getValueSecondary?: (value: T) => number
): SortedLayers<T> {
  const { orderBottom, orderTop } = defined(spec)
    ? spec
    : { orderBottom: [], orderTop: [] };
  const allMutable = values.slice();
  const actualValuesTop = _.remove(allMutable, (item) =>
    orderTop.includes(getLabel(item))
  );
  const sortedValuesTop = orderTop
    .map((t) => {
      return actualValuesTop.find((v) => getLabel(v) === t);
    })
    .filter(defined);

  const actualValuesBottom = _.remove(allMutable, (item) =>
    orderBottom.includes(getLabel(item))
  );
  const sortedValuesBottom = orderBottom
    .map((t) => {
      return actualValuesBottom.find((v) => getLabel(v) === t);
    })
    .filter(defined);

  return new SortedLayers<T>({
    top: sortedValuesTop,
    bottom: sortedValuesBottom,
    middle: defined(getValueSecondary)
      ? sortBy(allMutable, (value) => getValueSecondary(value)).reverse()
      : allMutable,
  });
}

export function applySortSpec(
  values: string[],
  spec: SortSpec
): SortedLayers<string> {
  return applySortSpecGeneric(values, spec, identity);
}

/**
 * Construct three layers of ordered values, in descending order: top, middle and bottom.
 */
export function applyDimensionSortOrderGeneric<T>(
  values: T[],
  spec: DimensionV2Dto | undefined,
  /** Get a value ID that matches value IDs in DimensionV2Dto */
  getValueId: (value: T) => number | undefined,
  /** If the value does not have fixed_top or fixed_bottom sorting, we sort by this given value */
  getSortByValueSecondary?: (value: T) => number
): SortedLayers<T> {
  const orderTop: { [key: number]: DimensionValueV2Dto } = {};
  const orderBottom: { [key: number]: DimensionValueV2Dto } = {};
  for (const value of spec?.values ?? []) {
    if (value.sort_mode === "fixed_top") {
      orderTop[value.id] = value;
    } else if (value.sort_mode === "fixed_bottom") {
      orderBottom[value.id] = value;
    }
  }

  const allMutable = values.slice();

  const actualValuesTop = _.remove(allMutable, (item) => {
    const id = getValueId(item);
    return defined(id) && defined(orderTop[id]);
  });
  const sortedValuesTop = sortBy(actualValuesTop, (v) => {
    const id = getValueId(v);
    if (!defined(id)) {
      throw new Error("Value ID expected but not defined");
    }
    return orderTop[id].sort_order;
  });

  const actualValuesBottom = _.remove(allMutable, (item) => {
    const id = getValueId(item);
    return defined(id) && defined(orderBottom[id]);
  });
  const sortedValuesBottom = sortBy(actualValuesBottom, (v) => {
    const valueId = getValueId(v);
    if (!defined(valueId)) {
      throw new Error("Value ID expected but not defined");
    }
    return orderBottom[valueId].sort_order;
  });

  return new SortedLayers<T>({
    top: sortedValuesTop,
    bottom: sortedValuesBottom,
    middle: defined(getSortByValueSecondary)
      ? sortBy(allMutable, (value) => getSortByValueSecondary(value)).reverse()
      : allMutable,
  });
}

export function applyDimensionSortOrder(
  values: string[],
  dimension?: DimensionV2Dto
): SortedLayers<string> {
  const lookup: { [key: string]: DimensionValueV2Dto } = {};
  for (const v of dimension?.values ?? []) {
    lookup[v.label] = v;
  }

  const top: [string, number][] = [];
  const middle: [string, number][] = [];
  const bottom: [string, number][] = [];

  for (const v of values) {
    const dimValue = lookup[v];
    if (!defined(dimValue)) {
      middle.push([v, 10000]);
      continue;
    }
    switch (dimValue.sort_mode) {
      case "fixed_top":
        top.push([v, dimValue.sort_order]);
        break;
      case "fixed_bottom":
        bottom.push([v, dimValue.sort_order]);
        break;
      case undefined:
        middle.push([v, dimValue.sort_order]);
    }
  }

  return new SortedLayers({
    top: sortBy(top, (v) => v[1]).map((v) => v[0]),
    bottom: sortBy(bottom, (v) => v[1]).map((v) => v[0]),
    middle: sortBy(middle, (v) => v[1]).map((v) => v[0]),
  });
}

export function measureSelectionIsSurvey(
  measureSelection: MeasureSelection
): measureSelection is MeasureSelectionSurvey {
  return measureSelection.valueType === "survey";
}

export function measureSelectionIsSurveyString(
  measureSelection: MeasureSelection
): measureSelection is MeasureSelectionSurvey {
  return measureSelection.valueType === "survey_string";
}

export function measureSelectionIsRegular(
  measureSelection: MeasureSelection
): measureSelection is MeasureSelectionRegular {
  switch (measureSelection.valueType) {
    case "category":
    case "integer":
    case "decimal":
      return true;
    case "survey":
    case "survey_string":
      return false;
  }
}

/**
 * Returns the dimension that represents the survey response.
 * If multichoice, we default to the last appearing subquestion dimension.
 */
export function getMeasureSelectionResponseDimensionsSurvey(
  selection: MeasureSelectionSurvey
) {
  if (selection.measure.survey_question_type === "multichoice") {
    return selection.measure.dimensions
      .slice()
      .reverse()
      .filter((d) => d.type === "survey_subquestion");
  }
  return selection.measure.dimensions.filter((d) => d.type === "survey_value");
}

export function measureIsNotRetired(measure: MeasureThin): boolean {
  return !measure.retired;
}

export function measureToDimensionsLabelDict(
  measure: MeasureSurvey | MeasureSurveyString
): Record<string, string> {
  const dimensions = measure.dimensions;
  const dict: Record<string, string> = {};
  for (const dimension of dimensions) {
    dict[dimension.data_column] = dimension.label;
  }
  return dict;
}
