import { LoadingResult } from "../application/loading/LoadingResult";
import { Progress } from "../core/progress";
import { HttpError } from "./HttpResult";

enum KnownErrors {
  UserMissingLicense = "user-missing-license",
  LicenseQuotaFilled = "license quota full",
}

export function isMissingLicenseResult<T>(
  result: LoadingResult<HttpError, T>
): boolean {
  return result.match({
    ready: () => false,
    notReady: (status) => {
      if (status.type !== Progress.Error) {
        return false;
      }
      const info = status.err.info;
      if (
        info?.type === "errorCode" &&
        info.error === KnownErrors.UserMissingLicense
      ) {
        return true;
      }
      return false;
    },
  });
}

export function displayCommonError(err: HttpError): string | undefined {
  if (err.info?.type === "errorCode") {
    switch (err.info.error) {
      case KnownErrors.LicenseQuotaFilled:
        return "Inga licenser kvar. Kontakta support för att lägga till fler licenser.";
      case KnownErrors.UserMissingLicense:
        return "Licens saknas.";
    }
  }
  return undefined;
}
