import { useMemo } from "react";
import { fromPairs } from "lodash";
import { useRecoilValue } from "recoil";

import { defined } from "../../../core/defined";
import {
  GeographiesSerializable,
  geoSelectionsToCodes,
  GeoType,
} from "../../../domain/geography";
import { DateRangeRaw } from "../../../domain/measure/definitions";
import { logger } from "../../../infra/logging";
import { StatsDataset } from "../../stats/datasets/StatsDataset";
import { SurveyDataset } from "../../stats/datasets/SurveyDataset";
import {
  DatasetError,
  DatasetErrorCode,
  DocCardStats,
  getCardTimeSelectionMode,
} from "./document-core/core";
import {
  cardToValidGeoSelections,
  currentlyValidGeoSelection,
  getGeoSelections,
  groupingTimeMismatch,
} from "./document-core/queries/dataCard";
import {
  getGroupingMeasureSelection,
  getStandardMeasureSelections,
} from "./document-core/queries/shared";
import { defaultTimespan, timespanValid } from "./document-core/updates";
import {
  getStatsDatasetOrCached,
  getSurveyDatasetOrCached,
  getSurveyStringDatasetOrCached,
  StatsQueryResult,
  SurveyQueryResult,
  SurveyStringQueryResult,
} from "../../requests/common_requests";
import { LoadingResult } from "../../loading/LoadingResult";
import { TimeResolution } from "../../../domain/time";
import { utcTimeStringToDate } from "../../../core/time";

export type DatasetResult = LoadingResult<
  DatasetError,
  StatsDataset | SurveyDataset | undefined
>;
export type DatasetLoadInfo = [result: DatasetResult, isReloading: boolean];

export function useDatasetError(cardId: string): DatasetError | undefined {
  const cardIdParams = { cardStateId: cardId };
  const mismatch = useRecoilValue(groupingTimeMismatch(cardIdParams));
  const mismatchErr: DatasetError | undefined = useMemo(() => {
    if (mismatch) {
      return new DatasetError(DatasetErrorCode.GroupingTimeMismatch);
    }
  }, [mismatch]);

  return mismatchErr;
}

export async function fetchDatasetForCard(
  cardState: DocCardStats,
  geographies: GeographiesSerializable,
  adminShowDraftData: boolean,
  disableCache: boolean
): Promise<
  | StatsQueryResult
  | SurveyQueryResult
  | SurveyStringQueryResult
  | DatasetError
  | undefined
> {
  const groupingMeasureSelection = getGroupingMeasureSelection(cardState);
  const timeSelection = getCardTimeSelection(cardState);
  if (!defined(timeSelection)) {
    return undefined;
  }

  const primaryMeasureSelection = getStandardMeasureSelections(cardState)[0];
  if (!defined(primaryMeasureSelection)) {
    return new DatasetError(DatasetErrorCode.NoSelection);
  }
  const includedGeoTypes = getIncludedGeoTypes(cardState, geographies);

  const validGeoSelection = currentlyValidGeoSelection(cardState, geographies);
  const selectedGeocodes = geoSelectionsToCodes(validGeoSelection);
  const singleSelectedGeoLabel =
    selectedGeocodes.length === 1
      ? geographies.itemsList.find(
          (item) => item.geocode === selectedGeocodes[0]
        )?.label
      : undefined;

  if (groupingMeasureSelection?.valueType === "survey") {
    throw new Error(
      "customer survey / survey not supported for grouping measure"
    );
  }

  const breakdownKeys = [
    "breakdown1",
    "breakdown2",
    "breakdown3",
    "breakdown4",
    "breakdown5",
  ];
  const groupingDataSpec: { [key: string]: number | null } | undefined =
    defined(groupingMeasureSelection)
      ? {
          ...fromPairs<number | null>(
            breakdownKeys.map((key) => [
              key,
              groupingMeasureSelection.breakdowns[key]?.[0] ?? null,
            ])
          ),
        }
      : undefined;

  if (
    primaryMeasureSelection.valueType === "survey" ||
    primaryMeasureSelection.valueType === "survey_string"
  ) {
    const measure = primaryMeasureSelection.measure;
    const unusedDimensions = measure.dimensions.filter((d) => {
      if (d.type === "survey_background") {
        return false;
      }
      if (measure.value_type === "survey_string" && d.type === "survey_value") {
        // survey_value is not a selectable dimension for survey_string
        return false;
      }

      if (
        (d.type === "survey_value" &&
          measure.survey_question_type === "singlechoice") || // survey_value is only used for singlechoice questions
        d.type === "survey_subquestion"
      ) {
        const selected = primaryMeasureSelection.breakdowns[d.data_column];
        if (!defined(selected) || selected.length === 0) {
          return true;
        }
      }
    });
    if (unusedDimensions.length > 0) {
      return new DatasetError(
        DatasetErrorCode.Custom,
        "Välj minst ett värde för: " +
          unusedDimensions.map((d) => d.label.toLowerCase()).join(", ")
      );
    }
    try {
      const dateStart = timeSelection?.[0];
      const dateEnd = timeSelection?.[1];

      if (primaryMeasureSelection.valueType === "survey") {
        const dataset = await getSurveyDatasetOrCached(
          primaryMeasureSelection.measure.data_id,
          dateStart,
          dateEnd,
          primaryMeasureSelection,
          groupingMeasureSelection,
          groupingDataSpec,
          primaryMeasureSelection.breakdowns,
          cardState.data.settings,
          disableCache,
          adminShowDraftData
        );
        return dataset;
      } else if (primaryMeasureSelection.valueType === "survey_string") {
        const dataset = await getSurveyStringDatasetOrCached(
          primaryMeasureSelection.measure.data_id,
          dateStart,
          dateEnd,
          primaryMeasureSelection,
          primaryMeasureSelection.breakdowns,
          cardState.data.settings,
          disableCache,
          adminShowDraftData
        );
        return dataset;
      }
    } catch (e) {
      logger.error(
        `Failed to get survey dataset, data ID ${
          primaryMeasureSelection.measure.data_id
        }, grouping data ID ${
          groupingMeasureSelection?.measure.data_id ?? "[none]"
        }`,
        e
      );
      return;
    }
  }

  const dateStart = timeSelection?.[0];
  const dateEnd = timeSelection?.[1];

  if (
    !defined(primaryMeasureSelection) ||
    (!defined(groupingMeasureSelection) && selectedGeocodes.length === 0) || // If we're doing a grouping selection, we don't care about geocodes
    !defined(dateStart) ||
    !defined(dateEnd)
  ) {
    return;
  }

  try {
    const measure = primaryMeasureSelection.measure;
    const unusedBreakdowns = measure.dimensions.filter((d) => {
      const selectedKeys = primaryMeasureSelection.breakdowns[d.data_column];
      return !defined(selectedKeys) || selectedKeys.length === 0;
    });
    if (defined(unusedBreakdowns) && unusedBreakdowns.length > 0) {
      return new DatasetError(
        DatasetErrorCode.Custom,
        "Välj minst ett värde för: " +
          unusedBreakdowns?.map((d) => d.label.toLowerCase()).join(", ")
      );
    }

    const forecast = cardState.data.settings.forecast;
    const resolution = TimeResolution.deserialize(measure.resolution);
    const forecastPeriods = forecast.show
      ? resolution.distance(
          utcTimeStringToDate(dateEnd),
          utcTimeStringToDate(forecast.until)
        )
      : undefined;
    const dataset = await getStatsDatasetOrCached(
      {
        id: measure.data_id,
        ...primaryMeasureSelection.breakdowns,
      },
      groupingDataSpec,
      selectedGeocodes,
      forecastPeriods,
      dateStart,
      dateEnd,
      primaryMeasureSelection,
      groupingMeasureSelection,
      includedGeoTypes,
      singleSelectedGeoLabel,
      cardState.data.settings,
      adminShowDraftData,
      disableCache
    );
    return dataset;
  } catch (e) {
    logger.error("Failed to get dataset", e);
  }
}

function getCardTimeSelection(
  cardState: DocCardStats
): DateRangeRaw | undefined {
  const selectionMode = getCardTimeSelectionMode(cardState);
  const selection = cardState.data.timeSelection;
  if (selectionMode === "point") {
    return [selection?.[0], selection?.[0]] as DateRangeRaw;
  }

  if (defined(selection) && timespanValid(cardState.data)) {
    return selection;
  }

  return defaultTimespan(cardState.data);
}

function getIncludedGeoTypes(
  cardState: DocCardStats,
  geographies: GeographiesSerializable
): GeoType[] {
  const uncheckedGeoSelections = getGeoSelections(cardState, geographies);
  if (!defined(uncheckedGeoSelections)) {
    return [];
  }
  const geoSelections = cardToValidGeoSelections(
    cardState,
    uncheckedGeoSelections
  );
  return Object.entries(geoSelections)
    .filter(([, values]) => {
      return values.length > 0;
    })
    .map(([key]) => key as GeoType);
}
