import { DEFAULT_AXIS_COLOR } from "../../../../lib/application/stats/shared/core/colors/colors";
import { MainChartDimensions } from "../../../../lib/application/stats/shared/core/definitions";

interface Props {
  dims: MainChartDimensions;
  customStrokeColor?: string | null;
  children?: JSX.Element;
}

export function AxisLineTop({ dims, children, customStrokeColor }: Props) {
  if (customStrokeColor === null) {
    return null;
  }
  return (
    <g transform={`translate(${dims.marginLeft}, ${dims.marginTop})`}>
      <line
        x1="0"
        y1="0"
        x2={dims.boundedWidth}
        y2="0"
        stroke={customStrokeColor ?? DEFAULT_AXIS_COLOR}
        strokeWidth="1"
        fill="none"
        shapeRendering="crispEdges"
      />
      {children}
    </g>
  );
}
