import { defined } from "../../../core/defined";
import { round } from "../../../core/math/round";
import {
  DimensionV2Dto,
  MeasureSelectionSurveyString,
} from "../../../domain/measure/definitions";
import { SurveyStringDatasetDto } from "../../../infra/api_responses/survey_dataset";
import { DataOutputSettings } from "../../state/stats/document-core/DataOutputSettings";
import {
  SurveyStringCol,
  SurveyStringTableData,
} from "../../state/stats/document-core/_core-shared";
import { dataSettingsEqual } from "../../state/stats/document-core/eq";
import { PackagedDatasetDto } from "../../state/stats/packaged-doc/types";
import { DataDescriptionSurveyString } from "../shared/DataDescription";
import {
  getSourceInfoSurveyString,
  processLongDescription,
} from "../shared/charts_common";
import { DataSourceInfo, DatasetGeneric } from "./DatasetGeneric";

export class SurveyStringDataset implements DatasetGeneric<string, any> {
  private _copyWithSettings: (s: DataOutputSettings) => SurveyStringDataset;

  public baseCountsOverview = {};
  public baseCountsDetailed = {};
  public baseCountsTotals = {};

  public canMakeComputedValue = false;
  public labelsByDimension = () => [];
  public labelsWithIdsByDimension = () => [];
  public supportsDecimalModes = false;
  public outputType = "text" as const;

  public groupingSourceInfo = undefined;
  public validResponses = undefined;
  public dubiousBaseDimensions = undefined;

  constructor(
    private _dataDto: SurveyStringDatasetDto,
    private _primaryMeasureSelection: MeasureSelectionSurveyString,
    private _settings: DataOutputSettings
  ) {
    this._copyWithSettings = (s: DataOutputSettings): SurveyStringDataset => {
      if (dataSettingsEqual(s, this._settings)) {
        return this;
      }
      return new SurveyStringDataset(
        _dataDto,
        this._primaryMeasureSelection,
        s
      );
    };
  }

  nonPrimaryDataDimensions(): string[] {
    return [];
  }

  copyWithSettings(settings: DataOutputSettings): SurveyStringDataset {
    return this._copyWithSettings(settings);
  }

  eligibleDimensionsComputedVariable(): string[] {
    return [];
  }

  canChangeDimensionOrder(): boolean {
    return false;
  }

  get surveyInfo() {
    return this._dataDto.header.survey_info;
  }

  internalLabel() {
    return this._primaryMeasureSelection.measure.label;
  }

  infostatPublicComment(): string[] {
    return processLongDescription(
      this._primaryMeasureSelection.measure.public_comment
    );
  }

  isChartable(): boolean {
    return false;
  }

  get sourceInfo(): DataSourceInfo {
    return getSourceInfoSurveyString(
      this._primaryMeasureSelection.measure,
      this._dataDto.header
    );
  }

  get surveySubquestions(): { label: string; values: string[] }[] | undefined {
    const primaryMeasureSelection = this._primaryMeasureSelection;
    return primaryMeasureSelection.measure.dimensions
      .filter((d) => d.type === "survey_subquestion")
      .map((d) => ({
        label: d.label,
        values: d.values?.map((v) => v.label) ?? [],
      }));
  }

  packDatasetForPublication(): PackagedDatasetDto {
    return {
      type: "survey_string",
      data: this._dataDto,
      settings: this._settings,
      primaryMeasureSelection: this._primaryMeasureSelection,
    };
  }

  tableSurveyString(): SurveyStringTableData {
    const selection = this._primaryMeasureSelection;
    const dimensions = selection.measure.dimensions;
    const alwaysUsedDimensions = dimensions.filter(
      (d) => d.type === "survey_subquestion"
    );
    const usedFilterDimensions = dimensions.filter((d) =>
      backgroundFilterUsed(d, selection, false)
    );
    const varyingFilterDimensions = dimensions.filter((d) =>
      backgroundFilterUsed(d, selection, true)
    );

    const includeSummary = usedFilterDimensions.length > 0;

    const varyingDimensions = alwaysUsedDimensions.concat(
      varyingFilterDimensions
    );

    const cols = [{ key: "value", label: "Svar" }];
    if (includeSummary) {
      cols.push({ key: "summary", label: "Sammanfattning" });
    }
    cols.push(
      ...varyingDimensions
        .map<SurveyStringCol>((d) => ({ key: d.data_column, label: d.label }))
        .concat([
          {
            key: "weight",
            label: "Vikt",
            format: (num: any) => round(num, 2).toString(),
          },
        ])
    );

    return {
      sourceInfo: getSourceInfoSurveyString(
        this._primaryMeasureSelection.measure,
        this._dataDto.header
      ),
      tableDescription: new DataDescriptionSurveyString(
        this._dataDto,
        this._primaryMeasureSelection,
        this._settings
      ),
      cols,
      rows:
        this._dataDto.rows?.map((r) => {
          const processedRow: { [key: string]: string | number } = {};
          processedRow["value"] =
            (r.value as string | number | undefined) ?? "";
          for (const d of varyingDimensions) {
            processedRow[d.data_column] = r[d.data_column]?.toString() ?? "";
          }
          processedRow["weight"] =
            (r.weight as string | number | undefined) ??
            this._dataDto.header.lifted?.weight ??
            "";

          if (includeSummary) {
            processedRow["summary"] = usedFilterDimensions
              .map(
                (d) =>
                  (
                    r[d.data_column] ??
                    this._dataDto.header.lifted?.[d.data_column]
                  )?.toString() ?? ""
              )
              .join(", ");
          }

          return processedRow;
        }) ?? [],
    };
  }
}

function backgroundFilterUsed(
  d: DimensionV2Dto,
  selection: MeasureSelectionSurveyString,
  hasMultipleValues: boolean
): boolean {
  if (d.type !== "survey_background") {
    return false;
  }

  const breakdown = selection.breakdowns[d.data_column];
  return defined(breakdown) && breakdown.length > (hasMultipleValues ? 1 : 0);
}
