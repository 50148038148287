import { useMemo, useRef, useCallback } from "react";

import { DataTable } from "../../../../../components/DataTable";
import { defined } from "../../../../../lib/core/defined";
import { PivotPlaceholder } from "../data_card/PivotPlaceholder";
import { DataHeader } from "./DataHeader";
import { TableDataState } from "../../../../../lib/application/state/stats/document-core/_core-shared";
import { SortOrder, sortOrderEnum } from "../../../../../lib/core/order";
import { DataOutputSettings } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";
import { classNames } from "../../../../../lib/core/classNames";
import { DataTableSortSetting } from "../../../../../lib/application/stats/datasets/table/table_base/sort";
import { TableSpec } from "../../../../../lib/application/stats/datasets/table/table_base/table_base_definitions";

export interface TableTabProps {
  setSettings: (settings: DataOutputSettings) => void;
  settings: DataOutputSettings;
  tableDataState?: TableDataState;
}

export function TableTab(props: TableTabProps) {
  const { setSettings, settings, tableDataState } = props;
  const htmlContainerRef = useRef<null | HTMLDivElement>(null);

  const sortSetting: DataTableSortSetting | undefined = useMemo(() => {
    const columnSort = settings.table.columnSort;
    if (columnSort?.type === "secondary") {
      return {
        columnIndex: columnSort.columnIndex,
        order: sortOrderEnum(columnSort.order),
      };
    }
  }, [settings.table.columnSort]);

  const primarySortOrder: SortOrder | undefined = useMemo(() => {
    const columnSort = settings.table.columnSort;
    if (columnSort?.type === "primary") {
      return sortOrderEnum(columnSort.order);
    }
  }, [settings.table.columnSort]);

  const table: TableSpec | undefined = tableDataState?.loadedTableData;

  const handleSetNonPrimarySort = useCallback(
    (columnIndex: number) => {
      const state =
        defined(sortSetting) && sortSetting.columnIndex === columnIndex
          ? sortSetting.order
          : undefined;
      const nextOrder = !defined(state)
        ? SortOrder.descending
        : state === SortOrder.descending
        ? SortOrder.ascending
        : undefined;
      setSettings({
        ...settings,
        table: {
          ...settings.table,
          columnSort: defined(nextOrder)
            ? {
                type: "secondary",
                columnIndex: columnIndex,
                order: nextOrder,
              }
            : undefined,
        },
      });
    },
    [setSettings, settings, sortSetting]
  );

  const handleTogglePrimarySort = useCallback(() => {
    const state = primarySortOrder;
    const nextOrder = !defined(state)
      ? SortOrder.ascending
      : state === SortOrder.ascending
      ? SortOrder.descending
      : undefined;
    setSettings({
      ...settings,
      table: {
        ...settings.table,
        columnSort: defined(nextOrder)
          ? {
              type: "primary",
              order: nextOrder,
            }
          : undefined,
      },
    });
  }, [primarySortOrder, setSettings, settings]);

  if (!defined(table)) {
    return <PivotPlaceholder icon={"th"}></PivotPlaceholder>;
  }

  return (
    <div className="dataset-tables tab-content">
      <div
        ref={htmlContainerRef}
        className={classNames(
          "dataset-table-container",
          `table-padding-${settings.tablePaddingSides ?? "auto"}`
        )}
      >
        <DataHeader
          settings={settings}
          dataDescription={table.tableDescription}
        ></DataHeader>
        <DataTable
          handleSetNonPrimarySort={handleSetNonPrimarySort}
          handleTogglePrimarySort={handleTogglePrimarySort}
          sortOrderPrimary={primarySortOrder}
          sortSetting={sortSetting}
          key={table.name + table.dimensionsString}
          table={table}
        ></DataTable>
      </div>
    </div>
  );
}
