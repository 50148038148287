import { DataTableMicroSettings } from "../../../state/stats/document-core/DataOutputSettings";

export class TableMicroSettings {
  constructor(private _settings: DataTableMicroSettings) {}

  showReferenceValuesOnly(): boolean {
    return this._settings.showReferenceValuesOnly ?? false;
  }

  showReferenceRows(): boolean {
    const s = this._settings;
    return (
      (s.showCountryReference ||
        s.showMunicipalityReference ||
        s.showNuts3Reference ||
        s.showSelectedAreasAverage) ??
      false
    );
  }
}
