import {
  Boolean as BooleanRT,
  Array as ArrayRT,
  Record,
  String as StringRT,
  Number as NumberRT,
  Null,
  Static,
} from "runtypes";

export const SecureDeliveryDtoRT = Record({
  id: NumberRT,
  recipient_email: StringRT,
  created_at: StringRT,
  updated_at: StringRT,
  expires_at: Null.Or(StringRT),
  created_by_email: StringRT,
  created_by_me: BooleanRT,
});

export type SecureDeliveryDto = Static<typeof SecureDeliveryDtoRT>;

const SecureDeliveryListingRT = Record({
  third_party_doc_id: StringRT,
  share_name: StringRT,
  shares: ArrayRT(SecureDeliveryDtoRT),
});
export type SecureDeliveryListing = Static<typeof SecureDeliveryListingRT>;
export const SecureDeliveriesListDtoRT = Null.Or(
  ArrayRT(SecureDeliveryListingRT)
);
