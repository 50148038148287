import { Snapshot, useRecoilCallback, useSetRecoilState } from "recoil";

import { defined } from "../../../../core/defined";
import { keepSingleValuePerKey } from "../../../../core/dictionary";
import { latestPointSelection } from "../../../../domain/micro/timeSelection";
import { getText } from "../../../strings";
import { replaceInArrayImmut } from "../../generic";
import { docCardAtomFamily } from "../../stats/document-core/atoms";
import { DocCardMicro } from "../../stats/document-core/core";

import {
  DataSelectionMicro,
  InnerMicroCardData,
  isMicroSelectionPrimary,
  microCardHasFilters,
  MicroGeoSelections,
  MicroMapSettings,
  microSelectionPrimary,
} from "../../stats/document-core/core-micro";
import { cardQuery } from "../../stats/document-core/queries/card";
import { useCallback, useContext } from "react";
import { loadAndStoreDataMicro } from "../../stats/cardToDataStateMicro";
import {
  CardUpdateCountContext,
  ShowDraftDataContext,
} from "../../../contexts";
import { cardColors } from "../../stats/document-core/queries/shared";
import { useGeoTree } from "../../../../../views/stats/docs/cards/micro/useGeoTree";
import { logger } from "../../../../infra/logging";
import { useSaveCard } from "../useSaveDocument";
import { useExtendedAppearanceSettings } from "../useExtendedAppearanceSettings";

function getSnapshotMicroCard(
  snapshot: Snapshot,
  cardId: string
): DocCardMicro {
  const microCard = snapshot
    .getLoadable(docCardAtomFamily(cardId))
    .valueOrThrow();
  if (microCard?.type !== "microCard") {
    throw new Error("Expected micro card");
  }
  return microCard;
}

export function useReadMicroCardState(cardId: string) {
  return useRecoilCallback(
    ({ snapshot }) =>
      () => {
        return getSnapshotMicroCard(snapshot, cardId);
      },
    [cardId]
  );
}

export function useUpdateSelectedAreas(cardId: string) {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      (updateFunc: (prev?: MicroGeoSelections) => MicroGeoSelections) => {
        const microCard = getSnapshotMicroCard(snapshot, cardId);
        const updatedSelections = updateFunc(microCard.data.geoSelections);
        const updatedCard = {
          ...microCard,
          data: { ...microCard.data, geoSelections: updatedSelections },
        };
        set(docCardAtomFamily(cardId), updatedCard);
      },
    [cardId]
  );
}

export function replaceMicroMapSetting<T extends keyof MicroMapSettings>(
  settings: MicroMapSettings,
  setting: T,
  settingValue: MicroMapSettings[T]
): MicroMapSettings {
  return {
    ...settings,
    [setting]: settingValue,
  };
}

export function useSetMultiMultiSelect(cardId: string, selectionId?: string) {
  const setCard = useSetRecoilState(cardQuery(cardId));
  const readCard = useReadMicroCardState(cardId);
  const appearanceSettings = useExtendedAppearanceSettings();
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const geoTree = useGeoTree();
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const handleSaveCard = useSaveCard();

  const callback = useCallback(
    (enable: boolean) => {
      const microCard = readCard();
      const needToRemoveFilters = enable && microCardHasFilters(microCard);
      if (needToRemoveFilters) {
        if (!window.confirm(getText("micro-confirm-remove-filters"))) {
          return;
        }
      }

      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const dataSelections = needToRemoveFilters
        ? microCard.data.dataSelections?.map((d) => {
            return {
              ...d,
              filterSet: undefined,
            };
          })
        : microCard.data.dataSelections;
      const updatedCard: DocCardMicro = {
        ...microCard,
        data: {
          ...microCard.data,
          filterMeasures: needToRemoveFilters
            ? []
            : microCard.data.filterMeasures,
          dataSelections: defined(dataSelections)
            ? replaceInArrayImmut(
                dataSelections,
                (s) => s.id === selectionId,
                (prev) => {
                  return {
                    ...prev,
                    multiSelectEnabled: enable,
                    selectedDimensions: enable
                      ? prev.selectedDimensions
                      : keepSingleValuePerKey(prev.selectedDimensions),
                    timeSelection: enable
                      ? prev.timeSelection
                      : latestPointSelection(prev.timeSelection),
                  };
                }
              )
            : undefined,
        },
      };

      if (!defined(geoTree)) {
        logger.warn("Geo tree not loaded");
        return;
      }

      loadAndStoreDataMicro(
        updatedCard,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        cardColors(updatedCard),
        handleSaveCard
      );
    },
    [
      adminShowDraftData,
      appearanceSettings,
      geoTree,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      readCard,
      selectionId,
      setCard,
    ]
  );

  return callback;
}

type GeoSelectionFields = Pick<
  InnerMicroCardData,
  "geoSelections" | "geoSelectionsOldDeso" | "geoSelectionsOldRegso"
>;

/**
 * Set geo selections to empty selection only when swapping to a measure that does not support the current geo type.
 * If swapping a primary measure to a geo-micro measure, keep the current geo selections.
 * When only showing geo-micro selections, geo selections are hidden by the micro card query.
 */
export function updateGeoSelections(
  updatedDataSelections: DataSelectionMicro[],
  prevSelections: GeoSelectionFields
): GeoSelectionFields {
  const prevGeo = prevSelections.geoSelections;
  let prevDeso =
    (prevGeo?.type === "deso" ? prevGeo : undefined) ??
    prevSelections.geoSelectionsOldDeso;
  let prevRegso =
    (prevGeo?.type === "regso" ? prevGeo : undefined) ??
    prevSelections.geoSelectionsOldRegso;

  const primary = updatedDataSelections.find(isMicroSelectionPrimary);
  if (!defined(primary)) {
    return {
      geoSelections: undefined,
      geoSelectionsOldDeso: prevDeso,
      geoSelectionsOldRegso: prevRegso,
    };
  }

  if (defined(prevGeo) && primary.measure.geoTypes.includes(prevGeo.type)) {
    return {
      geoSelections: prevGeo,
      geoSelectionsOldDeso: prevDeso,
      geoSelectionsOldRegso: prevRegso,
    };
  }
  // NB: we already checked if the primary measure type matches current geo selection
  // and it does not. We only have two geo types, so we check both deso and regso now
  const geoType = primary.measure.geoTypes[0];
  switch (geoType) {
    case "deso":
      return {
        geoSelections: prevDeso ?? {
          type: "deso",
          selected: [],
        },
        geoSelectionsOldDeso: prevDeso,
        geoSelectionsOldRegso: prevRegso,
      };
    case "regso":
      return {
        geoSelections: prevRegso ?? {
          type: "regso",
          selected: [],
        },
        geoSelectionsOldDeso: prevDeso,
        geoSelectionsOldRegso: prevRegso,
      };
  }

  return {
    geoSelections: undefined,
    geoSelectionsOldDeso: prevDeso,
    geoSelectionsOldRegso: prevRegso,
  };
}

/**
 * Replace current selection with a filtered selection,
 * and remove any filterSet / filterMeasures
 */
export function replaceSelectionWithFilteredSelection(
  prevCard: DocCardMicro,
  filteredSelection: MicroGeoSelections
): DocCardMicro {
  const dataSelection = microSelectionPrimary(prevCard);
  return {
    ...prevCard,
    data: {
      ...prevCard.data,
      geoSelections: filteredSelection,
      filterMeasures: [],
      dataSelections: defined(dataSelection)
        ? prevCard.data.dataSelections?.map((d) =>
            d.id === dataSelection.id
              ? {
                  ...dataSelection,
                  filterSet: undefined,
                }
              : d
          )
        : prevCard.data.dataSelections,
    },
  };
}
