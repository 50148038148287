import {
  Checkbox,
  Dropdown,
  ProgressIndicator,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { useState, useContext, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { AlertBox } from "../../../../../components/AlertBox";
import { Button } from "../../../../../components/Button";
import { Card } from "../../../../../components/Card";
import { FileDrop } from "../../../../../components/FileDrop";
import { FluentIcon } from "../../../../../components/Icons";
import { Table } from "../../../../../components/Table";
import { Api } from "../../../../../config";
import { AppMessagesContext } from "../../../../../lib/application/contexts";
import { useStateTransition } from "../../../../../lib/application/hooks/useStateTransition";
import { authedFileFormUpload } from "../../../../../lib/application/requests/shared";
import { DraftDataTodo } from "../../../../../lib/application_admin/todos/draft_data_todos";
import { assertNever } from "../../../../../lib/core/assert";
import { defined } from "../../../../../lib/core/defined";
import { Progress } from "../../../../../lib/core/progress";
import {
  MeasureAdminLogEntry,
  TodosMicroDto,
} from "../../../../../lib/infra/api_responses/admin/todos";
import {
  HttpError,
  HttpResult,
  displayHttpErrorInternal,
} from "../../../../../lib/infra/HttpResult";
import { logger } from "../../../../../lib/infra/logging";
import { Milliseconds } from "../../../../../lib/core/time";
import { aggregationMethods } from "../../../../../lib/infra/api_responses/dataset";
import { flatten, groupBy } from "lodash";

const aggMethodOptions = [
  { key: "unchanged", text: "Behåll nuvarande" },
].concat(aggregationMethods.map((m) => ({ key: m, text: m })));

interface Props {
  todos?: DraftDataTodo[];
  reloadTodos: () => void;
  header: string;
  uploadApi: Api;
  csvUploadPath: string;
  isMultiUpload: boolean;
  isLoadingTodos: boolean;
  isStats?: boolean;
  isMemberOrgs?: boolean;
  handleRemoveMeasure: (dataId: number) => Promise<HttpResult<unknown>>;
  handleQuickConfirm?: (dataId: number) => Promise<HttpResult<unknown>>;
  measureAdminPath: (id: number) => string;
}

export function TodoOverview(props: Props) {
  const [showMemberOrgMeasures, setShowMemberOrgMeasures] = useState(false);

  const todos = useMemo(() => {
    if (!props.isMemberOrgs) {
      return props.todos;
    }

    if (!showMemberOrgMeasures) {
      return props.todos?.filter(
        (t) => !defined(t.memberOrgMeasurementParentId)
      );
    }
    const groups = groupBy(props.todos, (t) => {
      if (defined(t.memberOrgMeasurementParentId)) {
        return t.memberOrgMeasurementParentId;
      }
      return t.dataId;
    });

    return flatten(
      Object.values(groups).map((group) => {
        const main = group.findIndex(
          (t) => !defined(t.memberOrgMeasurementParentId)
        );

        return [group.splice(main, 1)[0], ...group];
      })
    );
  }, [props.isMemberOrgs, props.todos, showMemberOrgMeasures]);

  const reloadTodos = props.reloadTodos;
  const quickConfirm = props.handleQuickConfirm;

  const [selectedNewAggMethod, setSelectedNewAggMethod] = useState<string>(
    aggMethodOptions[0].key as string
  );

  const [uploadProgressPercent, setUploadProgressPercent] = useState<number>();
  const [requestProgress, setRequestProgress] = useState<Progress>(
    Progress.NotStarted
  );
  const [deleteCurrentData, setDeleteCurrentData] = useState(false);
  const [requestError, setRequestError] = useState<HttpError>();
  const navigate = useNavigate();

  const appMessages = useContext(AppMessagesContext);

  useEffect(() => {
    reloadTodos();

    const timeout = setInterval(() => {
      reloadTodos();
    }, Milliseconds.second * 2);

    return () => {
      clearInterval(timeout);
    };
  }, [reloadTodos]);

  const handleQuickConfirm = useCallback(
    (dataId: number) => {
      if (!defined(quickConfirm)) {
        alert("Quick confirm not implemented");
        return;
      }

      if (
        !window.confirm(
          'Är du säker på att du vill godkänna OCH skicka mejlutskick om uppdateringen?\n\nDetta kan inte ångras. Om du är osäker, använd i stället "Hantera"-knappen för att förhandsgranska resultatet.'
        )
      ) {
        return;
      }
      return quickConfirm(dataId);
    },
    [quickConfirm]
  );

  const handleUploadProgress = useCallback((progress: number) => {
    setUploadProgressPercent(progress);
  }, []);

  const handleUploadDone = useCallback(() => {
    logger.info("Upload done -- still awaiting response");
  }, []);

  const handleUploadError = useCallback(() => {
    appMessages?.add("error", "Filuppladdning kunde inte slutföras");
    setRequestProgress(Progress.Error);
  }, [appMessages]);

  const handleUploadFiles = useCallback(
    (files: File[], deleteCurrentData: boolean) => {
      setRequestProgress(Progress.InProgress);
      const form = new FormData();
      if (deleteCurrentData) {
        form.append("delete-current-data", "true");

        if (
          props.isStats &&
          aggregationMethods.includes(selectedNewAggMethod as any)
        ) {
          form.append("new-agg-method-geo", selectedNewAggMethod);
        }
      }
      for (const file of files) {
        form.append("file", file);
      }

      authedFileFormUpload(props.uploadApi, props.csvUploadPath, form, {
        onUploadProgress: handleUploadProgress,
        onUploadDone: handleUploadDone,
        onUploadError: handleUploadError,
      }).then((result) => {
        result.match({
          ok: () => {
            appMessages?.add("success", "Filuppladdning klar!");
            setRequestProgress(Progress.Success);
            reloadTodos();
          },
          err: (err) => {
            appMessages?.add(
              "error",
              "Filuppladdning / response från server misslyckades"
            );
            setRequestProgress(Progress.Error);
            setRequestError(err);
          },
        });
      });
    },
    [
      appMessages,
      handleUploadDone,
      handleUploadError,
      handleUploadProgress,
      props.csvUploadPath,
      props.isStats,
      props.uploadApi,
      reloadTodos,
      selectedNewAggMethod,
    ]
  );

  const [lastLoadedTime, setLastLoadedTime] = useState<number>();

  useStateTransition(props.isLoadingTodos, (prev, next) => {
    if (defined(prev) && defined(next) && prev && !next) {
      setLastLoadedTime(Date.now());
    }
  });

  return (
    <Card id="data-administration">
      <section>
        <div className="data-upload">
          <h2>
            <FluentIcon name="upload" size="md" /> {props.header}
          </h2>
          <div className="flex-row flex-align-items-end margin-bottom-md">
            <Checkbox
              disabled={requestProgress === Progress.InProgress}
              className="margin-y-md"
              label="Ta bort befintlig data"
              checked={deleteCurrentData}
              onChange={(e, checked) => {
                if (!defined(checked)) {
                  return;
                }
                setDeleteCurrentData(checked);
              }}
            ></Checkbox>
            {props.isStats && (
              <Dropdown
                label="Ändra agg_method_geo"
                className="margin-x-md"
                selectedKey={selectedNewAggMethod}
                onChange={(e, o) => {
                  if (defined(o)) {
                    setSelectedNewAggMethod(o.key as string);
                  }
                }}
                disabled={
                  !deleteCurrentData || requestProgress === Progress.InProgress
                }
                options={aggMethodOptions}
              />
            )}
          </div>
          {props.isMultiUpload ? (
            <FileDrop
              disabled={requestProgress !== Progress.NotStarted}
              multi={true}
              accept=".csv,.zip"
              onSubmitMulti={(f) => handleUploadFiles(f, deleteCurrentData)}
            ></FileDrop>
          ) : (
            <FileDrop
              disabled={requestProgress !== Progress.NotStarted}
              multi={false}
              accept=".csv,.zip"
              onSubmit={(f) => handleUploadFiles([f], deleteCurrentData)}
            ></FileDrop>
          )}
          {requestProgress !== Progress.NotStarted && (
            <div className="upload-info margin-top-md">
              {requestProgress === Progress.InProgress && (
                <div>
                  <span>Laddar upp... ({uploadProgressPercent}%)</span>
                  <ProgressIndicator
                    percentComplete={(uploadProgressPercent ?? 0) / 100}
                  />
                </div>
              )}
              {requestProgress === Progress.Success && (
                <AlertBox intent="success">
                  <span>
                    Uppladdning klar! Filen bearbetas nu. När det är klart
                    kommer du se resultatet här nedan. Du kan nu stänga sidan
                    och återkomma senare.
                  </span>
                </AlertBox>
              )}
              {requestProgress === Progress.Error && (
                <AlertBox intent="danger">
                  <>
                    <span>Uppladdning misslyckades!</span>
                    {defined(requestError) && (
                      <div>{displayHttpErrorInternal(requestError)}</div>
                    )}
                  </>
                </AlertBox>
              )}
            </div>
          )}
        </div>
      </section>

      <>
        <section>
          <div className="flex-row flex-align-items-center">
            <h2>
              Att göra{" "}
              {defined(lastLoadedTime)
                ? `(senast uppdaterad: ${new Date(
                    lastLoadedTime
                  ).toLocaleTimeString()})`
                : ""}
            </h2>
          </div>
          <div>
            {props.isMemberOrgs && (
              <Checkbox
                label="Visa delmått för varje medlemsorg"
                checked={showMemberOrgMeasures}
                onChange={(e, checked) => {
                  if (!defined(checked)) {
                    return;
                  }
                  setShowMemberOrgMeasures(checked);
                }}
              ></Checkbox>
            )}
          </div>
          {defined(todos) && todos.length > 0 ? (
            <>
              <Table
                columns={[
                  { name: "", type: "icon", key: "first-col-icon" },
                  { name: "label", type: "text" },
                  { name: "id", type: "text" },
                  { name: "kunddata?", type: "icon" },
                  {
                    name: "Väntar på datapublicering",
                    type: "icon",
                    classNames: "align-center",
                  },
                  {
                    name: "Ny data finns",
                    type: "icon",
                    classNames: "align-center",
                  },
                  {
                    name: "Nya dimensionsvärden finns",
                    type: "icon",
                    classNames: "align-center",
                  },
                  { name: "", type: "icon", classNames: "align-center" },
                  { name: "", type: "icon", classNames: "align-center" },
                  { name: "", type: "icon", classNames: "align-center" },
                  {
                    name: "Logg",
                    type: "icon",
                    classNames: "align-center log-cell",
                  },
                ]}
                data={todos.map((t) => {
                  const showManageButton =
                    t.hasDraftData || t.hasDraftDimensions;
                  const showRemoveButton = t.isDraftMeasure;
                  /** Quick confirm should be enabled only when it is relatively safe to confirm:
                   * - There is no draft dimension/value
                   * - The measure is not a draft measure, i.e. it has previously been published so there should be no surprises
                   */
                  const enableQuickConfirm =
                    defined(quickConfirm) &&
                    !defined(t.memberOrgMeasurementOwnerOrg) && // disable quick confirm for member org measurements -- these should require extra care
                    t.hasDraftData &&
                    !t.isDraftMeasure &&
                    !t.hasDraftDimensions;
                  return {
                    id: t.dataId,
                    rowClassName:
                      t.log[0]?.event === "data_processing_error"
                        ? "error"
                        : "",
                    cells: [
                      t.log[0]?.event === "data_processing_started" ? (
                        <Spinner size={SpinnerSize.medium}></Spinner>
                      ) : (
                        ""
                      ),
                      (defined(t.memberOrgMeasurementParentId) ? "--> " : "") +
                        t.label,
                      t.dataId.toString(),
                      t.isCustomerData ? (
                        <FluentIcon name="small-tick" size="sm"></FluentIcon>
                      ) : (
                        ""
                      ),
                      t.isDraftMeasure ? (
                        <FluentIcon name="small-tick" size="sm"></FluentIcon>
                      ) : (
                        ""
                      ),
                      t.hasDraftData ? (
                        <FluentIcon name="small-tick" size="sm"></FluentIcon>
                      ) : (
                        ""
                      ),
                      t.hasDraftDimensions ? (
                        <FluentIcon name="small-tick" size="sm"></FluentIcon>
                      ) : (
                        ""
                      ),
                      <>
                        {showManageButton && (
                          <Button
                            title="Hantera"
                            small
                            onClick={() => {
                              navigate(props.measureAdminPath(t.dataId));
                            }}
                          ></Button>
                        )}
                      </>,
                      <>
                        {enableQuickConfirm && (
                          <Button
                            title="Snabbgodkänn + mejlutskick"
                            small
                            onClick={() => handleQuickConfirm(t.dataId)}
                          ></Button>
                        )}
                      </>,
                      <>
                        {showRemoveButton && (
                          <Button
                            intent="danger"
                            title="Ta bort mått"
                            small
                            onClick={() => props.handleRemoveMeasure(t.dataId)}
                          ></Button>
                        )}
                      </>,
                      <Log log={t.log}></Log>,
                    ],
                  };
                })}
              ></Table>
            </>
          ) : (
            <p>Inget att göra just nu.</p>
          )}
        </section>
      </>
    </Card>
  );
}

function Log(props: { log: TodosMicroDto["drafts"][0]["log"] }) {
  const min = 3;
  const max = 10;
  const [limit, setLimit] = useState(min);
  const hasMore = props.log.length > limit;

  return (
    <div>
      <>
        <table className="log-table">
          <thead></thead>
          <tbody>
            {props.log.slice(0, limit).map((entry, i) => (
              <tr key={entry.time}>
                <td>
                  {entry.performed_by.slice(
                    0,
                    entry.performed_by.lastIndexOf("@")
                  )}
                </td>
                <td>
                  <strong>{displayTodoEvent(entry)}</strong>
                </td>
                <td>
                  {entry.time.slice(2, 10)} {entry.time.slice(11, 16)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {limit === min && hasMore && (
          <Button
            title="Visa fler"
            small
            onClick={() => setLimit(max)}
          ></Button>
        )}
        {limit === max && (
          <Button
            title="Visa färre"
            small
            onClick={() => setLimit(min)}
          ></Button>
        )}
      </>
    </div>
  );
}

function displayTodoEvent(entry: MeasureAdminLogEntry): string {
  switch (entry.event) {
    case "data_commit":
      return "Ny data publicerad";
    case "data_revert":
      return "Senaste datauppladdning raderad";
    case "data_processed":
      return "Bearbetning av data klar";
    case "measure_created":
      return "Mått skapat";
    case "data_processing_started":
      return "Bearbetning av data startad";
    case "data_processing_error":
      return "Fel vid bearbetning: " + entry.message;
    default:
      assertNever(entry);
  }
}
