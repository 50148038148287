import { ITextField, TextField } from "@fluentui/react";
import { useCallback, useRef, useState } from "react";

import { Button } from "../Button";
import { defined } from "../../lib/core/defined";
import { useStateTransition } from "../../lib/application/hooks/useStateTransition";
import { parseSwedishNumber } from "../../lib/core/numeric/parseNum";

import "./EditableInput.scss";
import { AlertBox } from "../AlertBox";
import { nonEmptyString } from "../../lib/core/nonEmptyString";

interface Props {
  label: string;
  handleSave: (value: string) => Promise<void>;
  handleClear?: () => void;
  initialValue: string;
}

export function EditableInputText(props: Props) {
  const {
    componentRef,
    valueState: [value],
    isEditingState: [isEditing, setIsEditing],
    isSavingState: [isSaving],
    handleCancel,
    onSave,
    handleChange,
    handleClear,
    handleKeyDown,
  } = useHandlersAndState(props, true);

  return (
    <div className="infostat-editable-input-text">
      <TextField
        componentRef={componentRef}
        className="field-input"
        label={props.label}
        disabled={isSaving || !isEditing}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <Buttons
        isEditing={isEditing}
        onClear={
          defined(value) && defined(props.handleClear) ? handleClear : undefined
        }
        onSave={onSave}
        onCancel={handleCancel}
        setIsEditing={setIsEditing}
      />
    </div>
  );
}

/**
 * Comma-separated editable input list
 */
export function EditableInputList(props: {
  label: string;
  handleSave: (value: string[]) => Promise<void>;
  handleClear?: () => void;
  initialValue: string[];
}) {
  const {
    componentRef,
    valueState: [value, setValue],
    isEditingState: [isEditing, setIsEditing],
    isSavingState: [isSaving],
    handleCancel,
    onSave,
    handleChange,
    handleClear,
    handleKeyDown,
    errorMessage,
  } = useHandlersAndState(
    {
      label: props.label,
      handleSave: (value) => {
        const splitValues = value
          .split(/\s*,\s*|\s+/)
          .map((s) => s.trim())
          .filter(nonEmptyString);
        return props.handleSave(splitValues).then(() => {
          setValue(splitValues.join(", "));
        });
      },
      handleClear: props.handleClear,
      initialValue: props.initialValue.join(", "),
    },
    true
  );

  return (
    <div className="infostat-editable-input-text">
      <TextField
        componentRef={componentRef}
        className="field-input"
        label={props.label}
        disabled={isSaving || !isEditing}
        value={value}
        errorMessage={errorMessage ?? undefined}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <Buttons
        isEditing={isEditing}
        onClear={
          defined(value) && defined(props.handleClear) ? handleClear : undefined
        }
        onSave={onSave}
        onCancel={handleCancel}
        setIsEditing={setIsEditing}
      />
    </div>
  );
}

export function EditableInputTextArea(props: Props) {
  const {
    componentRef,
    valueState: [value],
    isEditingState: [isEditing, setIsEditing],
    isSavingState: [isSaving],
    handleCancel,
    onSave,
    handleChange,
    handleClear,
  } = useHandlersAndState(props);

  return (
    <div className="infostat-editable-input-text">
      <TextField
        componentRef={componentRef}
        className="field-input"
        label={props.label}
        multiline
        disabled={isSaving || !isEditing}
        value={value}
        onChange={handleChange}
      />
      <Buttons
        isEditing={isEditing}
        onClear={
          defined(value) && defined(props.handleClear) ? handleClear : undefined
        }
        onSave={onSave}
        onCancel={handleCancel}
        setIsEditing={setIsEditing}
      />
    </div>
  );
}

export function EditableInputRange(props: {
  initialValue: [min: number, max: number] | null;
  handleSave: (value: [min: number, max: number]) => Promise<void>;
  handleClear?: () => void;
  validateRange: (min: number, max: number) => string | undefined;
}) {
  const { initialValue, handleSave, validateRange } = props;

  const [minValue, setMinValue] = useState(initialValue?.[0].toString() ?? "");
  const [maxValue, setMaxValue] = useState(initialValue?.[1].toString() ?? "");
  const [minValueError, setMinValueError] = useState<null | string>(null);
  const [maxValueError, setMaxValueError] = useState<null | string>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClear = useCallback(() => {
    setMinValue("");
    setMaxValue("");
    setMinValueError(null);
    setMaxValueError(null);
    setErrorMessage("");
    props.handleClear?.();
  }, [props]);

  const handleCancel = useCallback(() => {
    setMinValue(initialValue?.[0].toString() ?? "");
    setMaxValue(initialValue?.[1].toString() ?? "");
    setIsEditing(false);
    setMinValueError(null);
    setMaxValueError(null);
    setErrorMessage("");
  }, [initialValue]);

  const onSave = useCallback(async () => {
    if (!nonEmptyString(minValue) || !nonEmptyString(maxValue)) {
      setErrorMessage("Fyll i båda fälten.");
      return;
    }
    const minRes = parseSwedishNumber(minValue);
    const maxRes = parseSwedishNumber(maxValue);
    const range: [number, number] | undefined = minRes.match({
      ok: (minValueNum) => {
        setMinValueError(null);
        return maxRes.match({
          ok: (maxValueNum) => {
            setMaxValueError(null);
            return [minValueNum, maxValueNum];
          },
          err: (err) => {
            setMaxValueError(err);
            return undefined;
          },
        });
      },
      err: (err) => {
        setMinValueError(err);
        return undefined;
      },
    });
    if (!defined(range)) {
      return;
    }

    const errorMessage = validateRange(range[0], range[1]);
    if (defined(errorMessage)) {
      setErrorMessage(errorMessage);
    } else {
      await handleSave(range);
      setIsEditing(false);
    }
  }, [minValue, maxValue, validateRange, handleSave]);

  return (
    <div className="infostat-editable-input-range">
      <section>
        <div className="fields">
          <TextField
            label="Minimum"
            className="field-input"
            value={minValue}
            onChange={(e, newValue) => setMinValue(newValue ?? "")}
            disabled={!isEditing}
            errorMessage={minValueError ?? ""}
          />
          <TextField
            label="Maximum"
            className="field-input"
            value={maxValue}
            onChange={(e, newValue) => setMaxValue(newValue ?? "")}
            disabled={!isEditing}
            errorMessage={maxValueError ?? ""}
          />
          <Buttons
            isEditing={isEditing}
            onClear={
              defined(initialValue) && defined(props.handleClear)
                ? handleClear
                : undefined
            }
            onSave={onSave}
            onCancel={handleCancel}
            setIsEditing={setIsEditing}
          />
        </div>
      </section>
      {nonEmptyString(errorMessage) && (
        <AlertBox intent="warning">
          <span>{errorMessage}</span>
        </AlertBox>
      )}
    </div>
  );
}

function Buttons(props: {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  onCancel: () => void;
  onSave: () => void;
  onClear?: () => void;
}) {
  return (
    <div className="buttons">
      {props.isEditing ? (
        <>
          <Button title="Avbryt" onClick={props.onCancel}></Button>
          <Button
            intent="primary"
            title="Spara"
            onClick={props.onSave}
          ></Button>
        </>
      ) : (
        <>
          {defined(props.onClear) && (
            <Button
              intent="danger"
              title="Rensa"
              onClick={props.onClear}
            ></Button>
          )}
          <Button
            intent="primary"
            title="Redigera"
            onClick={() => props.setIsEditing(true)}
          ></Button>
        </>
      )}
    </div>
  );
}

function useHandlersAndState(props: Props, enterToSave?: boolean) {
  // We store initial value here because we want to be able to reset it on successful save
  const [initialValue, setInitialValue] = useState(props.initialValue);
  const [value, setValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const componentRef = useRef<ITextField>(null);

  useStateTransition(isEditing, (prev, curr) => {
    if (prev === false && curr === true) {
      // just started editing
      componentRef.current?.focus();
    }
  });

  const handleCancel = useCallback(() => {
    setErrorMessage(null);
    setValue(initialValue);
    setIsEditing(false);
  }, [initialValue]);

  const onSave = useCallback(() => {
    props
      .handleSave(value)
      .then(() => {
        setInitialValue(value);
        setErrorMessage(null);
        setIsEditing(false);
      })
      .catch((e) => {
        if (typeof e === "string") {
          setErrorMessage(e);
        }
      });
  }, [props, value]);

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(e.currentTarget.value);
    },
    []
  );

  const handleClear = useCallback(() => {
    setValue("");
    props.handleClear?.();
  }, [props]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!isEditing || isSaving) {
        return;
      }
      if (enterToSave && event.key === "Enter") {
        onSave();
      } else if (event.key === "Escape") {
        event.stopPropagation();
        handleCancel();
      }
    },
    [enterToSave, handleCancel, isEditing, isSaving, onSave]
  );

  return {
    componentRef,
    valueState: [value, setValue] as const,
    isEditingState: [isEditing, setIsEditing] as const,
    isSavingState: [isSaving, setIsSaving] as const,
    errorMessage,
    handleCancel,
    onSave,
    handleChange,
    handleClear,
    handleKeyDown,
  } as const;
}
