import { useCallback } from "react";
import { defined } from "../../../../core/defined";
import {
  tryExtractSettings,
  defaultMeasureSelectionPrimary,
  setMeasureAvailableDatesMut,
} from "../../../../domain/measure";
import {
  DimensionV2Dto,
  MeasureFull,
} from "../../../../domain/measure/definitions";
import {
  MeasureSelection,
  MeasureSelectionRegular,
} from "../../../../domain/selections/definitions";
import { statsApi } from "../../../requests/statsApi";
import { statsApiV2 } from "../../../requests/statsApiV2";
import { logger } from "../../../../infra/logging";
import { assertNever } from "../../../../core/assert";
import { LatestSettingsFormat } from "../../stats/default-settings/common";

interface ReturnValue<T extends MeasureSelection> {
  subjectPath: string[];
  measureSelection: T;
  defaultSettings?: LatestSettingsFormat;
}

/**
 * Hook that creates a measure selection from a search hit
 */
export function useCreateSelectionFromSearchHit<T extends MeasureSelection>(
  adminShowDraftData: boolean
): (
  measureId: number,
  subjectPath: string[],
  dimensionDataColumn?: string,
  dimensionValueId?: number
) => Promise<ReturnValue<T>> {
  const handleSelectMeasureResult = useCallback(
    async (
      measureId: number,
      subjectPath: string[],
      dimensionDataColumn?: string,
      dimensionValueId?: number
    ): Promise<ReturnValue<T>> => {
      const measure = (
        await statsApi.getSingleMeasure(measureId, adminShowDraftData, true)
      ).unwrap();

      switch (measure.value_type) {
        case "survey":
        case "survey_string": {
          const dimensions = (
            await statsApiV2.getDimensions(
              measure.data_id,
              adminShowDraftData,
              true
            )
          ).unwrap();
          const measureFull: MeasureFull = {
            ...measure,
            dimensions,
          };
          const available = await statsApi.getAvailableMeasures(
            subjectPath,
            false,
            false,
            adminShowDraftData
          );

          const settings = await tryExtractSettings(measure);
          const measureSelection = defaultMeasureSelectionPrimary(
            measureFull,
            available,
            settings
          );
          const updatedSelection = await setMeasureAvailableDatesMut(
            measureSelection,
            adminShowDraftData
          );

          return {
            subjectPath,
            measureSelection: updatedSelection as T,
            defaultSettings: settings,
          };
        }
        case "decimal":
        case "integer":
        case "category": {
          const dimensions = (
            await statsApiV2.getDimensions(
              measure.data_id,
              adminShowDraftData,
              true
            )
          ).unwrap();
          const measureFull: MeasureFull = {
            ...measure,
            dimensions,
          };
          const available = await statsApi.getAvailableMeasures(
            subjectPath,
            false,
            false,
            adminShowDraftData
          );
          const settings = await tryExtractSettings(measure);
          const measureSelection = defaultMeasureSelectionPrimary(
            measureFull,
            available,
            settings
          ) as MeasureSelectionRegular;
          const updatedSelection = (await setMeasureAvailableDatesMut(
            measureSelection,
            adminShowDraftData
          )) as MeasureSelectionRegular;

          // If the hit represents a dimension & value, add it to the breakdowns if it isn't already
          // part of the default selection
          let hitValueId = dimensionValueId;
          let hitDimension = defined(dimensionDataColumn)
            ? dimensions.find((d) => d.data_column === dimensionDataColumn)
            : undefined;

          // If the search hit is on a dimension value, add the dimension value and all its parents,
          // overriding all previous values for the dimensions relevant to the hit
          if (defined(hitDimension) && defined(hitValueId)) {
            const hitBreakdowns = makeHitBreakdowns(
              dimensions,
              hitValueId,
              hitDimension
            );

            updatedSelection.breakdowns = {
              ...updatedSelection.breakdowns,
              ...hitBreakdowns, // override default values
            };
          }

          return {
            subjectPath,
            measureSelection: updatedSelection as T,
            defaultSettings: settings,
          };
        }
        default:
          assertNever(measure);
      }
    },
    [adminShowDraftData]
  );

  return handleSelectMeasureResult;
}

function makeHitBreakdowns(
  dimensions: DimensionV2Dto[],
  hitValueId: number,
  hitDimension: DimensionV2Dto | undefined
) {
  const hitBreakdowns: { [key: string]: number[] } = {};

  function ensureBreakdownValueAdded(column: string, valueId: number) {
    if (!defined(hitBreakdowns[column])) {
      hitBreakdowns[column] = [];
    }
    if (!hitBreakdowns[column]?.includes(valueId)) {
      hitBreakdowns[column]?.push(valueId);
    }
  }

  function findValue(dim: DimensionV2Dto, valueId: number) {
    return dim.values?.find((v) => v.id === valueId);
  }

  function findDimension(dimId: number) {
    return dimensions.find((d) => d.dimension_id === dimId);
  }

  function findChildDimension(parentId: number) {
    return dimensions.find((d) => d.parent_id === parentId);
  }

  while (defined(hitDimension) && defined(hitValueId)) {
    ensureBreakdownValueAdded(hitDimension.data_column, hitValueId);

    const hitValue = findValue(hitDimension, hitValueId);
    if (!defined(hitValue)) {
      logger.error(
        "Could not find value for id:",
        hitValueId,
        hitDimension.dimension_id
      );
      break;
    }
    if (defined(hitValue.parent_id) && defined(hitDimension.parent_id)) {
      hitValueId = hitValue.parent_id;
      hitDimension = findDimension(hitDimension.parent_id);
      continue;
    }
    break;
  }

  // Fill in empty selections for dimensions that are part of the same tree as the hit dimension
  let childDim = findChildDimension(hitDimension?.dimension_id ?? -1);
  while (defined(childDim)) {
    if (!defined(hitBreakdowns[childDim.data_column])) {
      hitBreakdowns[childDim.data_column] = [];
    }
    childDim = findChildDimension(childDim.dimension_id);
  }

  return hitBreakdowns;
}
