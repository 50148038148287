import { Position2D } from "../../../../../core/space/position";
import { defined } from "../../../../../core/defined";
import { MultilineText } from "./relative_text";
import { TextStyle } from "../TextStyle";
import { calculateTextWidth } from "./measure";
import { ColorKey, SvgTextAnchor } from "../definitions";

interface ColorIndicator extends ColorKey {
  paddingRight: number;
  content: string;
}

export interface PositionedTextLine {
  position: Position2D;
  rotation?: number;
  anchorPadding: number;
  text: string;
  lineHeightWithPadding: number;
  anchor: SvgTextAnchor;
  colorIndicator?: ColorIndicator;
}
/**
 * Generic container for positioned text with
 * support for multiple lines of text with different alignment.
 */
export class TextContainerPositioned {
  private _lines: PositionedTextLine[];
  private _indicatorWidth: number;

  constructor(
    _position: Position2D,
    _anchor: SvgTextAnchor,
    _verticalAlign: "top" | "center",
    private _label: MultilineText,
    private _textStyle: TextStyle,
    private _options: {
      rotation?: number;
      paddingTop?: number;
      colorIndicator?: ColorIndicator;
    }
  ) {
    const totalHeight = _label.height;
    const indicatorWidth = defined(_options.colorIndicator)
      ? calculateTextWidth(_options.colorIndicator?.content, _textStyle) +
        _options.colorIndicator.paddingRight
      : 0;
    const x = _position.x + indicatorWidth;
    const indicator = _options.colorIndicator;

    const lineHeight = _label.lineHeight;
    const offset = totalHeight / 2 - lineHeight / 2;

    this._lines = _label.lines.map((text, i) => {
      const y =
        _verticalAlign === "center"
          ? _position.y + i * lineHeight - offset
          : _position.y + i * lineHeight;
      const position = {
        x,
        y: defined(_options.paddingTop) ? _options.paddingTop + y : y,
      };
      return {
        lineHeightWithPadding: lineHeight + (i === 0 ? this.paddingTop : 0),
        rotation: _options.rotation,
        anchorPadding: _label.anchorPadding,
        colorIndicator: i === 0 ? indicator : undefined,
        anchor: _anchor,
        text,
        position,
      };
    });

    this._indicatorWidth = indicatorWidth;
  }

  get anchor() {
    return this._label.anchor;
  }

  get paddingTop(): number {
    return this._options.paddingTop ?? 0;
  }

  get textStyle(): TextStyle {
    return this._textStyle;
  }

  /**
   * Indicator width + padding
   */
  get indicatorWidth(): number {
    return this._indicatorWidth;
  }

  /**
   * Unrotated width
   */
  get width(): number {
    return this._label.maxWidth + this.indicatorWidth;
  }

  /**
   * Unrotated height
   */
  get height(): number {
    return this._label.height + this.paddingTop;
  }

  get bbox() {
    return { width: this.width, height: this.height };
  }

  get originalText(): string {
    return this._label.raw;
  }

  get lines(): PositionedTextLine[] {
    return this._lines;
  }
}
