import { useEffect, useState } from "react";
import { RecoilState, useRecoilState } from "recoil";

/**
 * State wrapper that records the time of the last change.
 */
export function useTimedRecoilState<T>(recoilState: RecoilState<T>) {
  const currentState = useRecoilState(recoilState);
  const previousState = useState<T>(currentState[0]);
  return useTimedStateInner(currentState, previousState);
}

/**
 * State wrapper that records the time of the last change.
 */
export function useTimedState<T>(initialValue: T) {
  const currentState = useState<T>(initialValue);
  const previousState = useState<T>(initialValue);
  return useTimedStateInner(currentState, previousState);
}

function useTimedStateInner<T>(
  state: [T, (value: T) => void],
  lastState: [T, (value: T) => void]
) {
  const [value, setValue] = state;
  const [lastValue, setLastValue] = lastState;
  const [changeTime, setChangeTime] = useState<number>(Date.now());

  useEffect(() => {
    if (lastValue !== value) {
      setChangeTime(Date.now());
      setLastValue(value);
    }
  }, [value, lastValue, setChangeTime, setLastValue]);

  return [value, setValue, changeTime] as const;
}
