import { SpinnerSize } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DelayedSpinner } from "../../../components/DelayedSpinner";
import { HttpErrorPage } from "../../../components/errors/HttpErrorView";
import { SharingErrorView } from "../../../components/errors/SharingErrorView";
import { MainContent } from "../../../components/MainContent";
import { createDocument } from "../../../lib/application/requests/docs/documents";
import {
  linkToDoc,
  SharingError,
} from "../../../lib/application/state/sharing";
import { getText } from "../../../lib/application/strings";
import { defined } from "../../../lib/core/defined";
import { HttpError } from "../../../lib/infra/HttpResult";
import { logger } from "../../../lib/infra/logging";
import { documentPath } from "../../../lib/paths";

interface Props {
  shareCode: string;
}
export function StatsNewFromSharedDoc(props: Props) {
  const { shareCode } = props;
  const [loadError, setLoadError] = useState<SharingError>();
  const [httpError, setHttpError] = useState<HttpError | undefined>(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    linkToDoc(shareCode).then((linkedWorkspaceRes) => {
      const res = linkedWorkspaceRes.switch();
      if (res.type === "err") {
        return setLoadError(res.error);
      }
      const { reportMeta, cards, colorSchemes, cardColorSchemes } =
        res.data.state;
      createDocument(
        reportMeta?.defaultTitle ?? getText("default-document-name"),
        {
          state: {
            reportMeta: { editModeOn: reportMeta?.editModeOn ?? true },
            cards,
            colorSchemes,
            cardColorSchemes,
          },
          workspaceVersion: res.data.workspaceVersion,
        }
      )
        .then((doc) => {
          const res = doc.switch();
          if (res.type === "ok") {
            navigate(documentPath(res.data));
          } else {
            setHttpError(res.error);
          }
        })
        .catch((e) => {
          logger.error("error creating report from linked workspace");
          logger.error(e);
        });
    });
  }, [navigate, shareCode]);

  if (defined(loadError)) {
    return <SharingErrorView error={loadError}></SharingErrorView>;
  }

  return (
    <MainContent className="Stats padding-lg">
      {defined(httpError) ? (
        <HttpErrorPage error={httpError}></HttpErrorPage>
      ) : (
        <DelayedSpinner
          delayShowMs={500}
          size={SpinnerSize.large}
        ></DelayedSpinner>
      )}
    </MainContent>
  );
}
