import { useCallback, useState } from "react";

import { HttpError, HttpResult } from "../../infra/HttpResult";
import { LoadingResult } from "../loading/LoadingResult";

export function useHttpRequestWithStatus<TArgs extends any[], TReturn>(
  request: (...args: TArgs) => Promise<HttpResult<TReturn>>
): [
  status: LoadingResult<HttpError, TReturn>,
  func: (...args: TArgs) => Promise<HttpResult<TReturn>>
] {
  const [status, setStatus] = useState<LoadingResult<HttpError, TReturn>>(
    LoadingResult.notStarted()
  );
  const func = useCallback(
    (...args: TArgs) => {
      setStatus(LoadingResult.inProgress());
      return request(...args).then((res) => {
        res.match({
          ok: (data) => setStatus(LoadingResult.from(data)),
          err: (err) => setStatus(LoadingResult.err(err)),
        });
        return res;
      });
    },
    [request]
  );

  return [status, func];
}
