import { useCallback, useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { defined } from "../../../core/defined";
import { voidFunc } from "../../../core/voidFunc";
import { HttpError } from "../../../infra/HttpResult";
import { useStateTransition } from "../../hooks/useStateTransition";
import { LoadingResult } from "../../loading/LoadingResult";
import { PromiseController } from "../../loading/load_status";
import { getDocumentMetadata } from "../../requests/docs/documents";
import { DocumentMetadata } from "./document-meta/DocumentMetadata";

import { documentMetadataQuery } from "./document-meta/queries";

export function useDocumentMetadataValue() {
  const metadataDto = useRecoilValue(documentMetadataQuery);
  const metadata = useMemo(
    () =>
      defined(metadataDto) ? DocumentMetadata.fromDto(metadataDto) : undefined,
    [metadataDto]
  );

  return metadata;
}

export function useDocumentMetadata() {
  const [metadataDto, setMetadataDto] = useRecoilState(documentMetadataQuery);

  const setMetadata = useCallback(
    (m: DocumentMetadata) => {
      setMetadataDto(m.toDto());
    },
    [setMetadataDto]
  );

  const metadata = useMemo(
    () =>
      defined(metadataDto) ? DocumentMetadata.fromDto(metadataDto) : undefined,
    [metadataDto]
  );

  return [metadata, setMetadata] as const;
}
export type DocumentMetadataReloader = (
  promiseController: PromiseController
) => void;

export function useDocumentMetadataLoader(documentId: number): {
  documentMetadata: LoadingResult<HttpError, DocumentMetadata>;
  reloadDocumentMetadata: DocumentMetadataReloader;
} {
  const [metadata, setMetadata] = useDocumentMetadata();

  const [documentMetadata, setDocumentMetadata] = useState<
    LoadingResult<HttpError, DocumentMetadata>
  >(LoadingResult.notStarted());

  const reloadDocumentMetadata = useCallback(
    (promiseControl: PromiseController) => {
      setDocumentMetadata(LoadingResult.inProgress());
      getDocumentMetadata(documentId).then((metaRes) => {
        if (promiseControl.stopped) {
          return;
        }

        metaRes.match({
          ok: (data) => {
            setDocumentMetadata(LoadingResult.from(data));
          },
          err: (err) => {
            setDocumentMetadata(LoadingResult.err(err));
          },
        });
      });
    },
    [documentId]
  );

  useStateTransition(documentMetadata, (prev, current) => {
    current?.match({
      ready: (data) => {
        // Avoid updating state if no change was detected
        if (!defined(metadata) || !metadata.eq(data)) {
          setMetadata(data);
        }
      },
      notReady: voidFunc,
    });
  });

  /** Run once on start */
  useEffect(() => {
    const promiseController = new PromiseController();
    reloadDocumentMetadata(promiseController);

    return () => {
      promiseController.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Only return ready status if metadata has been set
  return useMemo(() => {
    if (defined(metadata)) {
      return {
        documentMetadata: LoadingResult.from(metadata),
        reloadDocumentMetadata,
      };
    }

    // documentMetadata is ready, but it has not yet been saved to global recoil storage,
    // so the document cannot yet be rendered
    if (documentMetadata.isReady()) {
      return {
        documentMetadata: LoadingResult.inProgress(),
        reloadDocumentMetadata,
      };
    }
    return { documentMetadata, reloadDocumentMetadata };
  }, [documentMetadata, metadata, reloadDocumentMetadata]);
}
