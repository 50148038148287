import { flatMap, range } from "lodash";
import { DimensionType } from "../../../domain/measure/definitions";
import { RequiredWhen, MetadataField, DataCellValue } from "./types";

const TRUNCATED_LENGTH_DEFAULT = 30;

export const FIELD_ORG_ACCESS = "organization_access_restrictions";
export const FIELD_LABEL = "label";
export const FIELD_AREA = "area";
export const FIELD_SUBAREA = "subarea";
export const FIELD_SUBJECT = "subject";
export const FIELD_QUESTION_TYPE = "question_type";
export const FIELD_RESOLUTION = "resolution";

export const subjectPathFields = [FIELD_AREA, FIELD_SUBAREA, FIELD_SUBJECT];

export class DataLine {
  constructor(private _cells: DataCellValue[]) {}

  fieldValue(f: MetadataField): DataCellValue {
    const index = SURVEY_METADATA_FIELDS.findIndex(
      (ext) => ext.field === f.field
    );
    return this._cells[index];
  }

  replaceFieldImmut(f: MetadataField, value: DataCellValue): DataLine {
    const index = SURVEY_METADATA_FIELDS.findIndex(
      (ext) => ext.field === f.field
    );
    return new DataLine(
      this._cells.map((cell, i) => (i === index ? value : cell))
    );
  }

  cells(): readonly DataCellValue[] {
    return this._cells;
  }

  clone(): DataLine {
    return new DataLine(this._cells.slice());
  }
}

const makeComplexField = (
  label: string,
  displayLabel: string | undefined,
  field: string,
  labelField: string | undefined,
  dataColumn: string,
  dimensionType: DimensionType,
  fromCsv: boolean,
  csvColumnValueRequired: boolean,
  requiredWhen: undefined | RequiredWhen,
  truncatedLength: number | undefined = TRUNCATED_LENGTH_DEFAULT
): MetadataField => ({
  dataColumn,
  label,
  labelField,
  displayLabel,
  field,
  fromCsv,
  requiredWhen,
  truncatedLength,
  csvColumnValueRequired,
  type: "multi-string",
  dimensionType,
});

export const questionTypes = ["multichoice", "singlechoice"];

const makeSimpleField = (
  label: string,
  displayLabel: string | undefined,
  field: string,
  fromCsv: boolean,
  csvColumnValueRequired: boolean,
  dataColumn: string,
  requiredWhen: undefined | RequiredWhen,
  truncatedLength: number | undefined = TRUNCATED_LENGTH_DEFAULT
): MetadataField => ({
  dataColumn,
  label,
  displayLabel,
  field,
  fromCsv,
  requiredWhen,
  truncatedLength,
  csvColumnValueRequired,
  type: "string",
});

function makeCsvField(
  label: string,
  field: string,
  csvColumnValueRequired: boolean,
  truncatedLength?: number,
  displayLabel?: string
): MetadataField {
  return makeSimpleField(
    label,
    displayLabel,
    field,
    true,
    csvColumnValueRequired,
    field,
    undefined,
    truncatedLength
  );
}

export const answerLabelField = makeCsvField(
  "answer_label",
  "answer_label",
  false,
  50
);
export const SURVEY_METADATA_FIELDS: readonly MetadataField[] = [
  makeCsvField(FIELD_LABEL, FIELD_LABEL, true, 50),
  makeCsvField("question", "question", true, 50),
  answerLabelField,
  makeComplexField(
    "values",
    undefined,
    "answer_values",
    undefined,
    "value",
    "survey_value",
    true,
    false,
    undefined
  ),

  ...range(1, 4).map((i) => {
    const subqField = `subquestion${i}`;
    return makeCsvField(`subquestion${i}`, subqField, false);
  }),
  makeCsvField(FIELD_AREA, FIELD_AREA, true),
  makeCsvField(FIELD_SUBAREA, FIELD_SUBAREA, true),
  makeCsvField(FIELD_SUBJECT, FIELD_SUBJECT, true),
  makeCsvField("measure", "measure", true),
  makeCsvField("descr_long", "descr_long", true),
  makeCsvField("weight_info", "weight_info", false),
  makeCsvField("method", "method", true),
  makeCsvField("method_info_public", "method_info_public", false),
  makeCsvField("target_group_info_public", "target_group_info_public", false),
  makeCsvField("filter_info", "filter_info", true),
  makeCsvField("public_comment", "public_comment", false),
  makeSimpleField(
    "question_type",
    undefined,
    FIELD_QUESTION_TYPE,
    true,
    true,
    FIELD_QUESTION_TYPE,
    {
      type: "always",
    }
  ),
  makeSimpleField("source", undefined, "source", true, false, "source", {
    type: "always",
  }),
  makeSimpleField(
    FIELD_RESOLUTION,
    undefined,
    FIELD_RESOLUTION,
    true,
    false,
    FIELD_RESOLUTION,
    { type: "always" }
  ),
  ...flatMap(range(1, 31), (i) => {
    const backgroundField = `background${i}`;
    return [
      makeCsvField(
        backgroundField,
        backgroundField,
        false,
        TRUNCATED_LENGTH_DEFAULT,
        backgroundField
      ),
    ];
  }),
];
