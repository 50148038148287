import {
  Array as ArrayRT,
  Record,
  Boolean as BooleanRT,
  Number as NumberRT,
  String as StringRT,
  Static,
  Null,
  Literal,
} from "runtypes";

export const AlertStatusRT = Record({
  data_id: NumberRT,
  scheduled: BooleanRT,
});

export type MeasureAlertStatusDto = Static<typeof AlertStatusRT>;

const MeasureAlertRT = Record({
  type: Literal("measure"),
  alert_registration_id: NumberRT,
  data_id: NumberRT,
  area: StringRT,
  subarea: StringRT,
  subject: StringRT,
  measure: StringRT,
  descr_long: StringRT,
});
const DocumentAlertRT = Record({
  type: Literal("document"),
  alert_registration_id: NumberRT,
  node_id: NumberRT,
  title: StringRT,
  last_updated: StringRT,
});
export const SingleAlertRegistrationRT = MeasureAlertRT.Or(DocumentAlertRT);

export type SingleAlertRegistrationDto = Static<
  typeof SingleAlertRegistrationRT
>;

export const AlertRegistrationsRT = ArrayRT(SingleAlertRegistrationRT).Or(Null);
export type AlertRegistrationsDto = Static<typeof AlertRegistrationsRT>;
