import { useState, useContext } from "react";

import { AlertBox } from "../../../../../components/AlertBox";
import { Card } from "../../../../../components/Card";
import { IS_PROD } from "../../../../../config";
import { AppMessagesContext } from "../../../../../lib/application/contexts";
import { useNavigationPrompt } from "../../../../../lib/application/hooks/useNavigationPrompt";
import { createMicroMeasure } from "../../../../../lib/application/requests/admin/measure_management/create";
import { displayHttpErrorInternal } from "../../../../../lib/infra/HttpResult";
import { logger } from "../../../../../lib/infra/logging";
import { Step1SelectCategory } from "./Step1SelectCategory";
import { Step2MetaMicro } from "./Step2MetaMicro";
import { Step3LockMode } from "./Step3LockMode";
import { Step4ConfirmMicro } from "./Step4ConfirmMicro";
import {
  CompleteData0,
  CompleteData1,
  CompleteData2,
  CompleteData3,
} from "./types";

type State =
  | {
      step: 1;
      data: CompleteData0;
    }
  | {
      step: 2;
      data: CompleteData1;
    }
  | {
      step: 3;
      data: CompleteData2;
    }
  | {
      step: 4;
      data: CompleteData3;
    };

export function CreateMicroMeasure() {
  const [state, setState] = useState<State>({ step: 1, data: {} });
  const [created, setCreated] = useState(false);

  const appMessages = useContext(AppMessagesContext);
  const hasData = Object.values(state.data).some((v) => v !== undefined);

  useNavigationPrompt(hasData && !created);

  return (
    <Card id="create-measure">
      <div>
        {!IS_PROD && (
          <AlertBox intent="danger">
            <span>
              Du är ej i produktionsmiljön. Om du fortsätter att skapa ett mått
              kommer det endast vara synligt i utvecklingsmiljön. Kunder kommer
              inte kunna se måttet.
            </span>
          </AlertBox>
        )}
        {state.step === 1 && (
          <Step1SelectCategory
            data={state.data}
            handleNext={(data) => setState({ step: 2, data })}
          ></Step1SelectCategory>
        )}
        {state.step === 2 && (
          <Step2MetaMicro
            data={state.data}
            handlePrevious={(data) => setState({ data, step: 1 })}
            handleNext={(data) => setState({ step: 3, data })}
          ></Step2MetaMicro>
        )}
        {state.step === 3 && (
          <Step3LockMode
            data={state.data}
            handlePrevious={(data) => setState({ data, step: 2 })}
            handleNext={(data) => setState({ step: 4, data })}
          ></Step3LockMode>
        )}
        {state.step === 4 && (
          <Step4ConfirmMicro
            data={state.data}
            handlePrevious={(data) => {
              setState({ data, step: 3 });
            }}
            handleConfirm={(data) => {
              const fields = data.step2;
              const restrictionMode = data.step3.mode;
              const breakdowns = fields.breakdowns ?? {};
              const subjectPath = {
                area: data.step1.area,
                subarea: data.step1.subarea,
                subject: data.step1.subject,
              };
              const dimensions = Object.entries(breakdowns).map(
                ([key, value]) => {
                  if (typeof value !== "string") {
                    throw new Error("Invalid breakdown label, expected string");
                  }
                  return {
                    data_column: key,
                    label: value,
                  };
                }
              );
              return createMicroMeasure(
                fields ?? {},
                subjectPath,
                restrictionMode.locked
                  ? {
                      restricted: true,
                      organizations: restrictionMode.orgs.map((org) => org.id),
                    }
                  : { restricted: false },
                dimensions
              ).then((res) => {
                res.match({
                  ok: (createdResult) => {
                    appMessages?.add("success", "Mått skapat!");
                    setCreated(true);
                  },
                  err: (e) => {
                    logger.error(e);
                    appMessages?.add(
                      "error",
                      "Kunde inte skapa mått. Fel: " +
                        displayHttpErrorInternal(e)
                    );
                  },
                });
                return res;
              });
            }}
          ></Step4ConfirmMicro>
        )}
      </div>
    </Card>
  );
}
