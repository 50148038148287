import { defined } from "../../core/defined";
import { sign } from "../../core/sign";

export function calculatePercentageChange(
  from?: number,
  to?: number
): number | undefined {
  if (!defined(from) || !defined(to)) {
    return;
  }
  if (from === 0) {
    return undefined;
  }
  if (sign(from) !== sign(to)) {
    return undefined;
  }
  const percentage = ((to - from) / Math.abs(from)) * 100;

  if (to < from) {
    return -Math.abs(percentage);
  }
  return Math.abs(percentage);
}
