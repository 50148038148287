import { Dropdown, IDropdownProps, TextField } from "@fluentui/react";
import { useCallback, useContext, useEffect, useState } from "react";

import { Button } from "../../../../components/Button";
import {
  ButtonsFooter,
  ButtonsFooterLeft,
  ButtonsFooterRight,
} from "../../../../components/ButtonContainers";
import { DefaultLoading } from "../../../../components/Loading";
import { ShowDraftDataContext } from "../../../../lib/application/contexts";
import { useLoadableHttpResource } from "../../../../lib/application/hooks/useLoadableResource";
import { getMeasuresWithCache } from "../../../../lib/application/requests/common_requests";
import { getMicroCategories } from "../../../../lib/application/requests/datasets/micro";
import { classNames } from "../../../../lib/core/classNames";
import { defined } from "../../../../lib/core/defined";
import { prepareCategoryParts } from "../../../../lib/domain/categories";
import { measureIsNotRetired } from "../../../../lib/domain/measure";
import { MeasureThin } from "../../../../lib/domain/measure/definitions";

import "./SimpleSubjectSelect.scss";

function useMicroCategories(showDraftData: boolean) {
  const loadCategories = useCallback(() => {
    return getMicroCategories(showDraftData, [], []);
  }, [showDraftData]);
  const [status] = useLoadableHttpResource(loadCategories);

  return status.match({
    notReady: () => undefined,
    ready: (data) => data,
  });
}

/**
 * Subject selection component allowing for both controlled and uncontrolled behavior.
 * @param props
 * @returns
 */
export function SimpleSubjectSelectMicro(props: {
  area?: string;
  subarea?: string;
  subject?: string;
  defaultPath?: string[];
  disableCreate?: boolean;
  className?: string;
  /**
   * Used when state is controlled.
   */
  onSelect?: (path: string[]) => Promise<void>;
  /**
   * Used when state is uncontrolled
   */
  onSubmit?: (path: string[]) => Promise<void>;
  submitText?: string;
  onCancel?: () => void;
  adminShowDraftCategories: boolean;
}) {
  const [subjectPathLocal, setSubjectPathLocal] = useState<
    (string | undefined)[]
  >(props.defaultPath ?? []);
  const area = props.area ?? subjectPathLocal[0];
  const subarea = props.subarea ?? subjectPathLocal[1];
  const subject = props.subject ?? subjectPathLocal[2];
  const subjectPath = [area, subarea, subject];

  const [isSaving, setIsSaving] = useState(false);
  const categories = useMicroCategories(props.adminShowDraftCategories);

  const { onCancel, onSubmit, onSelect, submitText } = props;
  if (!defined(onSubmit) && !defined(onSelect)) {
    throw new Error("either onSubmit or onSelect must be defined");
  }

  const handleUpdateSelection = useCallback(
    (path: string[]) => {
      setSubjectPathLocal(path);
      if (defined(onSelect)) {
        onSelect(path);
      }
    },
    [onSelect]
  );

  const handleSubmit = useCallback(() => {
    setIsSaving(true);
    if (!defined(area) || !defined(subarea) || !defined(subject)) {
      throw new Error("area, subarea and subject must be defined");
    }
    onSubmit?.([area, subarea, subject]).then(() => {
      setIsSaving(false);
    });
  }, [area, onSubmit, subarea, subject]);

  if (!defined(categories)) {
    return <DefaultLoading></DefaultLoading>;
  }

  const { areas, subareas, subjects } = prepareCategoryParts(
    subjectPath,
    categories
  );

  return (
    <div className={classNames("simple-subject-select", props.className)}>
      <section>
        <SelectOrCreate
          disableCreate={props.disableCreate}
          onSelect={(key: string) => {
            const subjectPath = [key];
            handleUpdateSelection(subjectPath);
          }}
          dropdownProps={{
            dropdownWidth: "auto",
            label: "Område",
            options: areas.map((area) => ({ key: area, text: area })),
            selectedKey: area ?? null,
          }}
        ></SelectOrCreate>

        <SelectOrCreate
          disableCreate={props.disableCreate}
          onSelect={(key: string) => {
            const path = [area, key as string] as string[];
            handleUpdateSelection(path);
          }}
          dropdownProps={{
            disabled: !defined(area),
            label: "Delområde",
            options: subareas?.map((area) => ({ key: area, text: area })) ?? [],
            selectedKey: subarea ?? null,
          }}
        ></SelectOrCreate>

        <SelectOrCreate
          disableCreate={props.disableCreate}
          onSelect={(key: string) => {
            const path = [area, subarea, key] as string[];
            handleUpdateSelection(path);
          }}
          dropdownProps={{
            disabled: !defined(area) || !defined(subarea),
            label: "Ämne",
            options: subjects?.map((area) => ({ key: area, text: area })) ?? [],
            selectedKey: subject ?? null,
          }}
        ></SelectOrCreate>
      </section>

      {defined(area) && defined(subarea) && defined(subject) && (
        <ExistingMeasures path={[area, subarea, subject]}></ExistingMeasures>
      )}

      <ButtonsFooter>
        <ButtonsFooterLeft>
          <>
            {defined(onCancel) && (
              <Button title="Avbryt" onClick={props.onCancel}></Button>
            )}
          </>
        </ButtonsFooterLeft>
        <ButtonsFooterRight>
          <>
            {defined(onSubmit) && (
              <Button
                intent="primary"
                disabled={
                  !defined(area) ||
                  !defined(subarea) ||
                  !defined(subject) ||
                  isSaving
                }
                title={submitText ?? "Spara"}
                onClick={handleSubmit}
              ></Button>
            )}
          </>
        </ButtonsFooterRight>
      </ButtonsFooter>
    </div>
  );
}

function SelectOrCreate(props: {
  onSelect: (key: string) => void;
  disableCreate?: boolean;
  dropdownProps: IDropdownProps;
}) {
  const { disabled, label, options, selectedKey } = props.dropdownProps;
  const [create, setCreate] = useState(false);
  return (
    <div className="select-or-create">
      {!props.disableCreate && (create || options.length === 0) ? (
        <>
          <TextField
            disabled={disabled}
            label={label}
            onChange={(e) => {
              if (defined(e.currentTarget.value)) {
                props.onSelect(e.currentTarget.value);
              }
            }}
            suffix={"Ny kategori"}
          ></TextField>
          {options.length > 0 && (
            <>
              <span className="or-indicator">eller</span>
              <Button
                title="Välj existerande"
                onClick={() => setCreate(!create)}
              ></Button>
            </>
          )}
        </>
      ) : (
        <>
          <Dropdown
            dropdownWidth="auto"
            disabled={disabled}
            label={label}
            options={options}
            selectedKey={selectedKey}
            onChange={(e, item) => {
              if (defined(item)) {
                props.onSelect(item.key as string);
              }
            }}
          ></Dropdown>
          {!props.disableCreate && (
            <>
              <span className="or-indicator">eller</span>
              <Button
                title="Skapa nytt"
                onClick={() => setCreate(!create)}
              ></Button>
            </>
          )}
        </>
      )}
    </div>
  );
}

function ExistingMeasures(props: { path: string[] }) {
  const [measures, setMeasures] = useState<MeasureThin[]>([]);
  const showDraftData = useContext(ShowDraftDataContext);

  useEffect(() => {
    getMeasuresWithCache(props.path, false, false, showDraftData).then(
      (res) => {
        setMeasures(res);
      }
    );
  }, [props.path, showDraftData]);

  if (measures.length === 0) {
    return null;
  }

  return (
    <section className="existing-measures">
      <h3>Nuvarande mått under valt ämne</h3>
      <ul>
        {measures.filter(measureIsNotRetired).map((measure) => {
          return (
            <li key={measure.data_id}>
              {measure.measure}{" "}
              <span className="text-subdued">
                ({measure.data_id}, {measure.label})
              </span>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
