import { config } from "../../../../../../config";
import {
  TodosMeasuresForMemberOrgsDto,
  TodosMeasuresForMemberOrgsDtoRT,
  TodosMicroDto,
  TodosMicroRT,
  TodosStatsDto,
  TodosStatsDtoRT,
} from "../../../../../infra/api_responses/admin/todos";
import { HttpResult } from "../../../../../infra/HttpResult";
import { decodedAuthedRequest } from "../../../shared";

export function fetchTodosMicro(): Promise<HttpResult<TodosMicroDto>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    "admin/mikro/todos",
    undefined,
    "GET",
    TodosMicroRT
  );
}

export function fetchTodosStats(): Promise<HttpResult<TodosStatsDto>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/stats/todos",
    undefined,
    "GET",
    TodosStatsDtoRT
  );
}

export function fetchTodosMeasuresForMemberOrgs(): Promise<
  HttpResult<TodosMeasuresForMemberOrgsDto>
> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/member_orgs_measures/todos",
    undefined,
    "GET",
    TodosMeasuresForMemberOrgsDtoRT
  );
}

export function fetchTodosSurvey(): Promise<HttpResult<TodosStatsDto>> {
  return decodedAuthedRequest(
    config.apis.statsV2,
    "admin/survey/todos",
    undefined,
    "GET",
    TodosStatsDtoRT
  );
}
