import { useState } from "react";
import { DefaultButton } from "@fluentui/react";

import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import {
  extendedStandardColors,
  standardColors,
} from "../../../../../lib/application/stats/shared/core/colors/colors";
import { ColorDropdown } from "./ColorOptionsDialog";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  initialColor?: string;
  onColorChange: (color?: string) => void;
}

export function CardBackgroundColorDialog(props: Props) {
  const { isOpen, onClose, initialColor, onColorChange } = props;
  const [selectedColor, setSelectedColor] = useState(initialColor);

  const handleColorChange = (color?: string) => {
    setSelectedColor(color);
    onColorChange(color);
  };

  return (
    <FluentModal
      title="Välj bakgrundsfärg för kort"
      isOpen={isOpen}
      onClose={onClose}
    >
      <FluentModalBody>
        <div className="card-background-color-dialog">
          <label>
            <strong>Bakgrundsfärg</strong>
          </label>
          <ColorDropdown
            mode="both"
            colorCode={selectedColor}
            label="mock_label"
            applyColor={(_, color) => handleColorChange(color)}
            applyNoColor={() => handleColorChange()}
            swatchColors={standardColors.concat(extendedStandardColors)}
          />
          <p>Den valda färgen sätts endast för det valda kortet.</p>
        </div>
      </FluentModalBody>
      <FluentModalFooter>
        <DefaultButton onClick={onClose} text="Stäng" />
      </FluentModalFooter>
    </FluentModal>
  );
}
