import { uniq } from "lodash";

import { defined } from "../../../core/defined";
import {
  DEFAULT_FONT_SIZE_LARGE_SUBTEXT,
  DEFAULT_FONT_SIZE_MAIN_HEADER,
  DEFAULT_FONT_SIZE_SMALL_SUBTEXT,
  DEFAULT_FONT_SIZE_UNIT_LABEL,
  TextStyle,
} from "./core/TextStyle";
import { isBreakdownTotal } from "../../../domain/measure";
import { MultilineText, TextRow, TextRowSet } from "./core/text_containers";
import { Dimension, H2_FONTS, HEADER_FONTS_SMALL } from "./core/definitions";
import { DataDescription } from "./DataDescription";
import { CommonHeaderData } from "../datasets/headers";
import { Dictionary } from "../../../core/dictionary";
import { surveyDataStandardDimensions } from "./dimensions";
import { DataOutputSettings } from "../../state/stats/document-core/DataOutputSettings";

export type HeaderWithSourceInfo = Pick<CommonHeaderData, "source"> & {
  externalSource?: string;
  liftedDimensions?: Dictionary<string> | undefined;
};

export interface DatasetHeadersWithSourceInfo {
  header: HeaderWithSourceInfo;
  groupHeader?: HeaderWithSourceInfo;
}

export function getChartSourceText(
  datasetHeaders: DatasetHeadersWithSourceInfo,
  customSourceText: string | null | undefined,
  fontSize: number,
  maxWidth: number
) {
  const textStyle = new TextStyle(fontSize);
  if (defined(customSourceText)) {
    return calculateCustomSourceText(customSourceText, textStyle, maxWidth);
  }

  // Add a bit of bottom padding here to ensure text bits below the baseline won't be cut off
  const bottomPadding = 10;
  return calculateSourceText(
    datasetHeaders,
    textStyle,
    maxWidth,
    bottomPadding
  );
}

/**
 * Process primary/secondary source texts so that no repetition occurs between primary/secondary parts.
 */
export function buildSourceTextParts(
  primary: HeaderWithSourceInfo,
  secondary?: HeaderWithSourceInfo
): { primary: string; secondary?: string; multipleSources: boolean } {
  const multipleSources =
    uniq(
      [
        primary.source,
        primary.externalSource,
        secondary?.source,
        secondary?.externalSource,
      ].filter(defined)
    ).length > 1;
  const primarySources = uniq(
    [...primary.source.split(" / "), primary.externalSource].filter(defined)
  );

  if (!defined(secondary)) {
    return {
      primary: primarySources.join(" / "),
      multipleSources,
    };
  }

  const secondarySources = uniq(
    [secondary.source, secondary.externalSource]
      .filter(defined)
      .filter((s) => !primarySources.includes(s))
  );
  return {
    primary: primarySources.join(" / "),
    secondary:
      secondarySources.length > 0 ? secondarySources.join(" / ") : undefined,
    multipleSources,
  };
}

export function calculateCustomSourceText(
  text: string,
  textStyle: TextStyle,
  maxWidth: number,
  paddingBottom?: number
) {
  return new TextRowSet(
    makeSourceLinesFromText("", text, textStyle, maxWidth),
    paddingBottom
  );
}

/**
 * Builds source text in the format `$main_source / $external_source`.
 * If $main_source == $external_source, $external_source is omitted.
 */
export function calculateSourceText(
  headers: DatasetHeadersWithSourceInfo,
  textStyle: TextStyle,
  maxWidth: number,
  paddingBottom?: number
): TextRowSet {
  const header = headers.header;
  const rows: TextRow[] = [];
  const headerTopPadding = 5;

  const groupHeader = headers.groupHeader;
  const { primary, secondary, multipleSources } = buildSourceTextParts(
    header,
    groupHeader
  );

  const sourcesLabel = multipleSources ? "Källor:" : "Källa:";
  if (defined(secondary)) {
    const mainSource = makeSourceLinesFromText(
      "",
      primary,
      textStyle,
      maxWidth
    );
    const groupSource = makeSourceLinesFromText(
      "",
      secondary,
      textStyle,
      maxWidth
    );
    rows.push(
      new TextRow(sourcesLabel, textStyle, headerTopPadding),
      ...mainSource,
      ...groupSource
    );
  } else {
    const mainSource = makeSourceLinesFromText(
      sourcesLabel + " ",
      primary,
      textStyle,
      maxWidth,
      headerTopPadding
    );
    rows.push(...mainSource);
  }

  return new TextRowSet(rows, paddingBottom);
}

function makeSourceLinesFromText(
  leading: string,
  text: string,
  textStyle: TextStyle,
  maxWidth: number,
  paddingTop?: number
) {
  const fullLineText = `${leading}${text}`;
  return new MultilineText(fullLineText, textStyle, {
    boxPaddingTop: paddingTop,
    desiredMaxWidth: maxWidth,
  }).toTextRows();
}

/**
 * Note that these settings are defined in both CSS and JS (in order to construct well-sized SVG charts) so changes to these must be made in multiple files:
 * src/lib/application/charts/shared/core/definitions.ts
 * src/lib/application/charts/shared/texts.ts
 * src/views/stats/data_card/DataCard.scss
 */
export function calculcateTitleRows(
  dataDescription: DataDescription,
  maxWidth: number,
  settings: DataOutputSettings
): TextRowSet {
  const paddingMinimal = 0;
  const paddingSmall = 4;
  const paddingLarge = 8;
  const fontOptsH2 = { fontFamily: H2_FONTS };
  const fontOptsSmallHeaders = { fontFamily: HEADER_FONTS_SMALL };

  const headerStyle = new TextStyle(
    settings.customMainHeaderSize ?? DEFAULT_FONT_SIZE_MAIN_HEADER,
    { ...fontOptsH2 }
  );
  const subTextStyle = new TextStyle(
    settings.customSubHeaderSmallSize ?? DEFAULT_FONT_SIZE_SMALL_SUBTEXT,
    fontOptsSmallHeaders
  );

  const unitTextStyle = new TextStyle(
    settings.customUnitSize ?? DEFAULT_FONT_SIZE_UNIT_LABEL,
    { ...fontOptsSmallHeaders }
  );
  const largeSubTextStyle = new TextStyle(
    settings.customSubHeaderLargeSize ?? DEFAULT_FONT_SIZE_LARGE_SUBTEXT,
    {
      ...fontOptsSmallHeaders,
    }
  );

  const hideHeaders = settings.hideChartTitleSection;
  if (hideHeaders) {
    const unitRow = defined(dataDescription.unitHeader)
      ? new TextRow(dataDescription.unitHeader, unitTextStyle, paddingSmall)
      : undefined;
    return new TextRowSet(defined(unitRow) ? [unitRow] : []);
  }

  function breakTextIntoTextRows(
    input: string,
    style: TextStyle,
    boxPaddingTop: number
  ): TextRow[] {
    return new MultilineText(input, style, {
      desiredMaxWidth: maxWidth,
      boxPaddingTop,
    }).toTextRows();
  }

  /**
   * Specify the box top padding that the following text line should apply
   */
  let nextTopPadding = paddingSmall;

  const groupingHeader = dataDescription.groupingHeader;

  const standardMeasureRows: TextRow[] = new MultilineText(
    dataDescription.header,
    headerStyle,
    { desiredMaxWidth: maxWidth }
  ).toTextRows();

  const subheaders = dataDescription.primaryMeasureSubheaders;
  for (const subheader of subheaders) {
    const rows = breakTextIntoTextRows(subheader, subTextStyle, nextTopPadding);
    standardMeasureRows.push(...rows);
    nextTopPadding = paddingMinimal;
  }
  nextTopPadding = paddingSmall;

  if (defined(groupingHeader)) {
    for (const line of groupingHeader) {
      const rows = breakTextIntoTextRows(
        line,
        subTextStyle,
        groupingHeader[0] === line && subheaders.length > 0
          ? paddingLarge
          : nextTopPadding
      );
      standardMeasureRows.push(...rows);
    }
    nextTopPadding = paddingLarge;
  }

  const regionAndTime = dataDescription.regionAndTimeHeader;
  if (defined(regionAndTime)) {
    const rows = breakTextIntoTextRows(
      regionAndTime,
      subTextStyle,
      nextTopPadding
    );
    standardMeasureRows.push(...rows);
    nextTopPadding = paddingLarge;
  }
  for (const subquestion of dataDescription.single_subquestions) {
    const rows = breakTextIntoTextRows(
      subquestion,
      largeSubTextStyle,
      nextTopPadding
    );
    standardMeasureRows.push(...rows);
    nextTopPadding = paddingLarge;
  }
  if (defined(dataDescription.surveyResponseSingleChoice)) {
    const rows = breakTextIntoTextRows(
      dataDescription.surveyResponseSingleChoice,
      subTextStyle,
      nextTopPadding
    );
    standardMeasureRows.push(...rows);
    nextTopPadding = paddingSmall;
  }
  if (defined(dataDescription.unitHeader)) {
    const unitRow = new TextRow(
      dataDescription.unitHeader,
      unitTextStyle,
      paddingLarge
    );
    standardMeasureRows.push(unitRow);
  }

  return new TextRowSet(standardMeasureRows);
}

export function getSingleBreakdownSelectionsText(
  liftedDimensions: Dictionary<string> | undefined,
  breakdownDimensionToHeader: Dictionary<string>
): string[] {
  if (!defined(liftedDimensions)) {
    return [];
  }
  return Object.entries(liftedDimensions)
    .filter(([key, value]) => {
      return (
        ![Dimension.date, Dimension.region].includes(key as Dimension) &&
        !isBreakdownTotal(value)
      );
    })
    .map(
      ([key, value]) => `${breakdownDimensionToHeader[key] ?? key}: ${value}`
    );
}

export function getSingleBreakdownSelectionsTextV2(
  liftedDimensions: Dictionary<number> | undefined,
  displayBreakdown: (dataColumn: string) => string | undefined,
  displayBreakdownValue: (
    dataColumn: string,
    valueId: number
  ) => string | undefined
): string[] {
  if (!defined(liftedDimensions)) {
    return [];
  }
  return Object.entries(liftedDimensions)
    .filter(([key, value]) => {
      const valueLabel = displayBreakdownValue(key, value);
      if (!defined(valueLabel)) {
        return false;
      }
      return (
        ![Dimension.date, Dimension.region].includes(key as Dimension) &&
        !isBreakdownTotal(valueLabel)
      );
    })
    .map(
      ([key, value]) =>
        `${displayBreakdown(key) ?? key}: ${displayBreakdownValue(key, value)}`
    );
}

export function getSingleBreakdownSelectionsTextsSurvey(
  breakdowns: Record<string, string | number>,
  breakdownDisplayLabels: Dictionary<string>
): string[] {
  return Object.entries(breakdowns)
    .filter(
      ([key, values]) =>
        !surveyDataStandardDimensions.includes(key as Dimension) &&
        typeof values === "string" &&
        !isBreakdownTotal(values)
    )
    .map(([key, values]) => `${breakdownDisplayLabels[key] ?? key}: ${values}`);
}
