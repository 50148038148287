import { assertNever } from "@fluentui/react";
import { MicroReferenceType } from "../../state/stats/document-core/core";
import { MicroGeoTree } from "./MicroGeoTree";
import { defined } from "../../../core/defined";
import { AggregationMethod } from "../../../infra/api_responses/dataset";

export function chartMicroSettingsLabels(
  setting: MicroReferenceType,
  regionCode?: string,
  nameGeoTree?: MicroGeoTree,
  aggMethodGeoMicro?: AggregationMethod
) {
  switch (setting) {
    case "showMunicipalityReference":
      if (defined(regionCode) && defined(nameGeoTree)) {
        return nameGeoTree.desoRegsoToMunicipality(regionCode) ?? regionCode;
      }
      return "Kommunen";
    case "showNuts3Reference":
      if (defined(regionCode) && defined(nameGeoTree)) {
        return nameGeoTree.desoRegsoToRegion(regionCode) ?? regionCode;
      }
      return "Regionen";
    case "showCountryReference":
      return "Riket";
    case "showSelectedAreasAverage":
      if (defined(aggMethodGeoMicro)) {
        switch (aggMethodGeoMicro) {
          case "avg":
            return "Medelvärde för valda områden";
          case "avg-weighted":
            return "Vägt medelvärde för valda områden";
          case "sum":
            return "Summa för valda områden";
        }
      }
      return "Snitt för valda områden";
    default:
      assertNever(setting);
  }
}
