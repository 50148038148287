import * as _ from "lodash";
import { DEFAULT_GRID_LINE_COLOR } from "../../../lib/application/stats/shared/core/colors/colors";

import { MainChartDimensions } from "../../../lib/application/stats/shared/core/definitions";
import { YTicksContainer } from "../../../lib/application/stats/shared/core/ticks";
import { defined } from "../../../lib/core/defined";

interface Props {
  dims: MainChartDimensions;
  ticksContainer: YTicksContainer;
  excludeY?: number;
  strokeColor?: string | null;
  strokeDashArray?: string;
}

export function LinesHorizontal(props: Props) {
  const { dims, ticksContainer, excludeY, strokeColor } = props;
  if (strokeColor === null) {
    return null;
  }
  return (
    <g>
      {_.chain(ticksContainer.ticks)
        .filter((t) => (defined(excludeY) ? t.yOffset !== excludeY : true))
        .map((t) => (
          <line
            className="chart-guide-line"
            key={t.yOffset}
            x1="0"
            x2={dims.boundedWidth}
            y1={t.yOffset}
            y2={t.yOffset}
            stroke={strokeColor ?? DEFAULT_GRID_LINE_COLOR}
            strokeDasharray={props.strokeDashArray}
            strokeWidth="1"
            shapeRendering="crispEdges"
          />
        ))
        .value()}
    </g>
  );
}
