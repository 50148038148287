import {
  Array as ArrayRT,
  Dictionary,
  Record,
  Static,
  String as StringRT,
  Boolean as BooleanRT,
  Optional,
  Null,
} from "runtypes";
import { ColorPaletteNameRT } from "../../../stats/shared/core/colors/colorSchemes";
import { ColorScheme } from "../document-core/core";

export const CardColorSchemeLinkRT = Record({
  cardId: StringRT,
  schemeId: StringRT,
});

export type CardColorSchemeLink = Static<typeof CardColorSchemeLinkRT>;

export const CustomPaletteSpecAppliedRT = Record({
  id: StringRT,
  colors: ArrayRT(StringRT),
  useDefaultSpecialColors: BooleanRT,
  customBgColor: Optional(StringRT.Or(Null)),
  customAxesColor: Optional(StringRT.Or(Null)),
  customHeadersColor: Optional(StringRT.Or(Null)),
  customLabelsColor: Optional(StringRT.Or(Null)),

  customGridLinesColor: Optional(StringRT.Or(Null)),
  customGridLinesYColor: Optional(StringRT.Or(Null)),
  customGridLinesXColor: Optional(StringRT.Or(Null)),
});

export const ColorSchemeContainerRT = Record({
  id: StringRT,

  // Color key -> Color name/code
  colorScheme: Dictionary(StringRT, StringRT),
  // If user has manually edited the color scheme, this is set to true
  isUserDefined: Optional(BooleanRT),
  paletteName: ColorPaletteNameRT,

  useDefaultSpecialColors: Optional(BooleanRT),
  customBgColor: Optional(StringRT.Or(Null)),
  customAxesColor: Optional(StringRT.Or(Null)),
  customHeadersColor: Optional(StringRT.Or(Null)),
  customLabelsColor: Optional(StringRT.Or(Null)),

  customGridLinesColor: Optional(StringRT.Or(Null)),
  customGridLinesYColor: Optional(StringRT.Or(Null)), // Overrides customGridLinesColor
  customGridLinesXColor: Optional(StringRT.Or(Null)), // Overrides customGridLinesColor

  embeddedPalette: Optional(CustomPaletteSpecAppliedRT),
});

export type ColorSchemeContainer = Static<typeof ColorSchemeContainerRT> & {
  colorScheme: ColorScheme;
};

/** @deprecated */
export interface CardStyleContainerGeoMicroLink {
  cardId: string;
  styleContainerId: string;
}

export interface StyleContainerGeoMicro {
  styles: Array<{
    /** ID identifying the measure selection _AND_ the particular group of data (a data series) */
    dataGroupId: string;
    geometry: "point" | "polygon" | "line";
    label: string;
    fill?: string;
    lineDashArray?: number[];
    fillOpacity?: number;
    border?: string;
  }>;
  id: string;
}

export type GeometryType = "point" | "polygon" | "line";

export interface GroupStyleGeoMicro {
  label: string;
  fill?: string;
  fillOpacity?: number;
  lineDashArray?: number[];
  border?: string;
}
