import { IColumn } from "@fluentui/react";
import { fromPairs } from "lodash";
import { minimumAccessMatched } from "../../../../../lib/application/files/SharingInfo";
import {
  ChildNodeInfo,
  FileNodeInfo,
  NodeTypeRegular,
} from "../../../../../lib/application/files/tree";
import { OptimisticFavorites } from "../../../../../lib/application/hooks/useFileTreeNavigation";
import { RoleDto } from "../../../../../lib/application/requests/docs/dto";
import { defined } from "../../../../../lib/core/defined";
import { MoveDialogNodesMode } from "../FileListMoveDialog";

export interface FileFeatures {
  showSharedStatus?: boolean;
  hideAll?: boolean;
  listSortAlphabetical?: boolean;
  /**
   * Use help icon to indicate if a node is shared with help library.
   */
  useHelpIcon?: boolean;
  /**
   * Use sample lib icon to indicate if a node is shared with sample library.
   */
  useSampleLibIcon?: boolean;
  enableMove?: { mode: MoveDialogNodesMode };
  enableRename?: boolean;
  enableRemove?: boolean;
  addShortcut?: (nodeId: number) => void;
  removeShortcut?: (nodeId: number) => void;
  addLibraryRoot?: (nodeId: number, nodeType: NodeTypeRegular) => void;
  removeLibraryRoot?: (nodeId: number) => void;
  addHelpRoot?: (nodeId: number) => void;
  removeHelpRoot?: (nodeId: number) => void;
  useSharingFeatures?: boolean;
  favorites?: {
    optimistic: OptimisticFavorites;
    remove: (nodeId: number) => void;
    add: (nodeId: number) => void;
  };
}

type FileFeaturesBool = { [key in keyof FileFeatures]: boolean };
/**
 * Converts a FileFeatures object to a an object with only boolean values,
 * representing whether a feature is enabled or not.
 */
export function fileFeaturesBool(f: FileFeatures): FileFeaturesBool {
  return fromPairs(
    Object.entries(f).map(([key, value]) => {
      return [key, defined(value)];
    })
  );
}

export type NewNodeHandler = (node: FileNodeInfo) =>
  | {
      file: () => void;
      folder: (title: string) => void;
    }
  | undefined;

export const sharingColumnSpec: IColumn = {
  key: "sharing-col",
  name: "Delas av",
  minWidth: 120,
  maxWidth: 150,
  isResizable: false,
  data: "string",
  onRender: (item: ChildNodeInfo) => {
    return item.ownership.type === "owner" ? "Dig" : item.ownership.owner_email;
  },
};

function isNodeOwner(node: Pick<ChildNodeInfo, "roles">): boolean {
  return node.roles.includes("owner");
}

function isNodeEditor(node: Pick<ChildNodeInfo, "roles">): boolean {
  return minimumAccessMatched("editor", node.roles);
}

function isParentEditor(parentFolderRole: RoleDto | undefined): boolean {
  return (
    defined(parentFolderRole) &&
    minimumAccessMatched("editor", [parentFolderRole])
  );
}

/**
 * Structure for determining access to node features such as move, rename, remove, etc.
 */
export const nodeEnabledFeatures = {
  canMove(
    args: Pick<FileFeaturesBool, "enableMove">,
    parentFolderRole: RoleDto | undefined,
    node: Pick<ChildNodeInfo, "roles">
  ): boolean {
    if (!args.enableMove) {
      return false;
    }

    return isNodeOwner(node) || isParentEditor(parentFolderRole);
  },

  canRename(
    args: Pick<FileFeaturesBool, "enableRename">,
    parentFolderRole: RoleDto | undefined,
    node: Pick<ChildNodeInfo, "roles">
  ): boolean {
    if (!args.enableRename) {
      return false;
    }

    return isNodeOwner(node) || isParentEditor(parentFolderRole);
  },

  canRemove(
    args: Pick<FileFeaturesBool, "enableRemove">,
    parentFolderRole: RoleDto | undefined,
    node: Pick<
      ChildNodeInfo,
      "roles" | "belongsToSampleLibrary" | "belongsToHelpLibrary"
    >
  ): boolean {
    if (!args.enableRemove) {
      return false;
    }

    if (node.belongsToSampleLibrary || node.belongsToHelpLibrary) {
      return false;
    }

    return isNodeOwner(node) || isParentEditor(parentFolderRole);
  },

  canUseSharing(
    args: Pick<FileFeaturesBool, "useSharingFeatures">,
    node: Pick<
      ChildNodeInfo,
      "roles" | "belongsToSampleLibrary" | "belongsToHelpLibrary"
    >
  ): boolean {
    return args.useSharingFeatures === true && isNodeEditor(node);
  },

  canSetLibraryRoot(
    args: Pick<FileFeaturesBool, "addLibraryRoot">,
    node: Pick<
      ChildNodeInfo,
      "type" | "roles" | "belongsToSampleLibrary" | "belongsToHelpLibrary"
    >
  ): boolean {
    if (!args.addLibraryRoot) {
      return false;
    }

    return (
      (node.type === "folder" || node.type === "document") &&
      !node.belongsToHelpLibrary &&
      !node.belongsToSampleLibrary
    );
  },

  canSetHelpRoot(
    args: Pick<FileFeaturesBool, "addHelpRoot">,
    node: Pick<
      ChildNodeInfo,
      "type" | "roles" | "belongsToSampleLibrary" | "belongsToHelpLibrary"
    >
  ): boolean {
    if (!args.addHelpRoot) {
      return false;
    }
    return (
      (node.type === "folder" || node.type === "document") &&
      !node.belongsToHelpLibrary &&
      !node.belongsToSampleLibrary
    );
  },

  canRemoveLibraryRoot(
    args: Pick<FileFeaturesBool, "removeLibraryRoot">,
    parentNode: Pick<FileNodeInfo, "type">,
    node: Pick<ChildNodeInfo, "belongsToSampleLibrary">
  ): boolean {
    if (!args.removeLibraryRoot) {
      return false;
    }
    // Only the library root can be removed, all subfolders/docs have their library status inherited
    // from the root
    return parentNode.type === "root" && node.belongsToSampleLibrary;
  },

  canRemoveHelpRoot(
    args: Pick<FileFeaturesBool, "removeHelpRoot">,
    parentNode: Pick<FileNodeInfo, "type">,
    node: Pick<ChildNodeInfo, "belongsToHelpLibrary">
  ): boolean {
    if (!args.removeHelpRoot) {
      return false;
    }
    // Only the library root can be removed, all subfolders/docs have their library status inherited
    // from the root
    return parentNode.type === "root" && node.belongsToHelpLibrary;
  },

  canAddShortcut(
    args: Pick<FileFeaturesBool, "addShortcut">,
    node: Pick<ChildNodeInfo, "belongsToSampleLibrary" | "hasShortcut">
  ): boolean {
    if (!args.addShortcut) {
      return false;
    }
    return !node.hasShortcut && node.belongsToSampleLibrary;
  },

  canRemoveShortcut(
    args: Pick<FileFeaturesBool, "removeShortcut">,
    node: Pick<ChildNodeInfo, "hasShortcut">
  ): boolean {
    if (!args.removeShortcut) {
      return false;
    }
    return node.hasShortcut === true;
  },

  canScheduleUpdateNotices(
    args: Pick<FileFeaturesBool, "removeHelpRoot" | "removeLibraryRoot">,
    node: Pick<
      ChildNodeInfo,
      "belongsToHelpLibrary" | "belongsToSampleLibrary" | "type"
    >
  ): boolean {
    return (
      node.type === "document" &&
      ((args.removeHelpRoot === true && node.belongsToHelpLibrary) ||
        (args.removeLibraryRoot === true && node.belongsToSampleLibrary))
    );
  },
};
