import {
  Array as ArrayRT,
  Record,
  Number as NumberRT,
  String as StringRT,
  Static,
  Null,
  Unknown,
} from "runtypes";

export const AdminDocListDtoRT = ArrayRT(
  Record({
    id: NumberRT,
    title: StringRT,
  })
).Or(Null);

export const AdminMeasureListDtoRT = ArrayRT(
  Record({
    data_id: NumberRT,
    label: StringRT,
    descr_long: StringRT,
    default_settings: Unknown,
  })
).Or(Null);
export type AdminMeasureListDto = Static<typeof AdminMeasureListDtoRT>;

export const DocumentVersionsDtoRT = ArrayRT(NumberRT);
export type DocumentVersionsDto = Static<typeof DocumentVersionsDtoRT>;

export type AdminDocListDto = Static<typeof AdminDocListDtoRT>;

export const AdminEmbeddedDocListDtoRT = ArrayRT(
  Record({
    key: StringRT,
    title: StringRT,
  })
).Or(Null);

export type AdminEmbeddedDocListDto = Static<typeof AdminEmbeddedDocListDtoRT>;
