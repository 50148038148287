import {
  DefaultValue,
  GetRecoilValue,
  selector,
  selectorFamily,
  SetRecoilState,
} from "recoil";

import { defined } from "../../../../../core/defined";
import {
  CardIdParams,
  CardType,
  DocCardPython,
  DocCardState,
  DocCardStateNonError,
  PageBreakType,
} from "../core";
import { docCardAtomFamily, docCardsListState } from "../atoms";

export function getCardOrThrow(get: GetRecoilValue, id: string) {
  const card = get(docCardAtomFamily(id));
  if (!defined(card)) {
    throw new Error(`Could not find card with id ${id}`);
  }
  return card;
}

export function getNonErrorCardOrThrow(get: GetRecoilValue, id: string) {
  const card = get(docCardAtomFamily(id));
  if (!defined(card)) {
    throw new Error(`Could not find card with id ${id}`);
  }
  if (card.type === "error") {
    throw new Error("Did not expect error card!");
  }
  return card;
}

export function updateCardOrThrow(
  set: SetRecoilState,
  id: string,
  updater: (card: DocCardState) => DocCardState
) {
  set(docCardAtomFamily(id), (prev) => {
    if (!defined(prev)) {
      throw new Error("Could not find card to update");
    }
    return updater(prev);
  });
}

export const cardLabelQuery = selectorFamily<string, CardIdParams>({
  key: "cardLabelQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getNonErrorCardOrThrow(get, params.cardStateId);
      return card.label ?? "";
    },
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("DefaultValue not implemented for cardLabelQuery");
      }
      updateCardOrThrow(set, params.cardStateId, (card) => {
        return { ...card, label: newValue };
      });
    },
});

export const cardPageBreakQuery = selectorFamily<
  PageBreakType | undefined,
  CardIdParams
>({
  key: "cardPageBreakQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getNonErrorCardOrThrow(get, params.cardStateId);
      return card.pageBreak;
    },
  set:
    (params: CardIdParams) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("DefaultValue not implemented for cardPageBreakQuery");
      }
      updateCardOrThrow(set, params.cardStateId, (card) => {
        return { ...card, pageBreak: newValue };
      });
    },
});

export const cardEditModeQuery = selectorFamily<boolean, CardIdParams>({
  key: "cardEditModeQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getCardOrThrow(get, params.cardStateId);
      if (card.type === "error") {
        return false;
      }
      return card.isEditing;
    },
});

export const cardTypeQuery = selectorFamily<CardType, CardIdParams>({
  key: "cardTypeQuery",
  get:
    (params: CardIdParams) =>
    ({ get }) => {
      const card = getCardOrThrow(get, params.cardStateId);
      return card.type;
    },
});

export const cardQuery = selectorFamily<DocCardState, string>({
  key: "cardQuery",
  get:
    (id: string) =>
    ({ get }) => {
      return getCardOrThrow(get, id);
    },
  set:
    (id: string) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("DefaultValue not implemented for cardQuery");
      }
      updateCardOrThrow(set, id, (card) => {
        return newValue;
      });
    },
});

export const pythonCardQuery = selectorFamily<DocCardPython, string>({
  key: "pythonCardQuery",
  get:
    (id: string) =>
    ({ get }) => {
      const card = getCardOrThrow(get, id);
      if (card.type !== "pythonCard") {
        throw new Error("Expected python card");
      }
      return card;
    },
  set:
    (id: string) =>
    ({ set }, newValue) => {
      if (newValue instanceof DefaultValue) {
        throw new Error("DefaultValue not implemented for pythonCardQuery");
      }
      updateCardOrThrow(set, id, (card) => {
        return newValue;
      });
    },
});

/**
 * // TODO: replace
 * @deprecated
 */
export const fullCardsQuery = selector<DocCardStateNonError[]>({
  key: "dataCardsQuery",
  get: ({ get }) => {
    return get(docCardsListState)
      .map(({ id }) => get(docCardAtomFamily(id)))
      .filter(defined)
      .filter(isNonError);
  },
});

function isNonError(c: DocCardState): c is DocCardStateNonError {
  return c.type !== "error";
}
