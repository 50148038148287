import {
  Array as ArrayRT,
  Record,
  Number as NumberRT,
  Static,
  String as StringRT,
  Optional,
  Null,
} from "runtypes";

export const FORCE_REMOVE_CONFLICTING_VALUES_FIELD =
  "force_remove_conflicting_values";

export const DraftConflictDtoRT = Record({
  referencing_link: Optional(StringRT),
  referencing_link_type: Optional(StringRT),
  referencing_owner_email: Optional(StringRT),

  dimension_id: NumberRT,
  dimension_value_id: NumberRT,
  dimension_value_label: StringRT,
});

export const DraftConflictsDtoRT = ArrayRT(DraftConflictDtoRT).Or(Null);
export type DraftConflictDto = Static<typeof DraftConflictDtoRT>;
export type DraftConflictsDto = Static<typeof DraftConflictsDtoRT>;

export const CommitDtoRT = Record({
  rows_deleted: NumberRT,
  rows_published: NumberRT,
  dimension_values_published: NumberRT,
  unused_dimension_values_deleted: NumberRT,
});

export const RevertDtoRT = Record({
  rows_deleted: NumberRT,
  rows_reverted: NumberRT,
  unused_dimension_values_deleted: NumberRT,
});

export const MemberOrgMeasureCommitDtoRT = ArrayRT(
  Record({
    data_id: NumberRT,
    data_label: StringRT,
    rows_deleted: NumberRT,
    rows_published: NumberRT,
    dimension_values_published: NumberRT,
    unused_dimension_values_deleted: NumberRT,
  })
);

export const MemberOrgMeasureRevertDtoRT = ArrayRT(
  Record({
    data_id: NumberRT,
    data_label: StringRT,
    rows_deleted: NumberRT,
    rows_reverted: NumberRT,
    unused_dimension_values_deleted: NumberRT,
  })
);

export type MemberOrgMeasureCommitDto = Static<
  typeof MemberOrgMeasureCommitDtoRT
>;
export type MemberOrgMeasureRevertDto = Static<
  typeof MemberOrgMeasureRevertDtoRT
>;

export type CommitDto = Static<typeof CommitDtoRT>;
export type RevertDto = Static<typeof RevertDtoRT>;
