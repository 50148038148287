import { Position2D } from "../../../../core/space/position";
import { StatsDatasetResponseDto } from "../../../../infra/api_responses/dataset";

import { TextContainerPositioned } from "./text_containers/";

export const SVGNamespace = "http://www.w3.org/2000/svg";

export type SvgTextAnchor = "left" | "middle" | "end";

export const GOLDEN_RATIO = 1.62;
export const LEGEND_COLOR_INDICATOR_CHAR = "⬤";

export const MAX_LABEL_NUM_CHARS_ROTATION_MODE = 25;
export const MAX_LABEL_ROTATION_ANGLE_DEG = 65;

/**
 * Note that these settings are defined in both CSS and JS (in order to construct well-sized SVG charts) so changes to these must be made in multiple files:
 * src/lib/application/charts/shared/core/definitions.ts
 * src/lib/application/charts/shared/texts.ts
 * src/views/stats/data_card/DataCard.scss
 */
export const H2_FONTS = `Circular-Medium, sans-serif`;
export const HEADER_FONTS_SMALL = `Circular-Regular, sans-serif`;
export const LABEL_FONTS = `Circular-Regular, Verdana, "DejaVu Sans", sans-serif`;
export const NUMBER_LABEL_FONTS = `Circular-Mono, "DejaVu Sans", sans-serif`;

export enum ChartType {
  barVertical = "barVertical",
  barHorizontal = "barHorizontal",
  line = "line",
}

export enum Orientation {
  vertical = "v",
  horizontal = "h",
}

export enum Dimension {
  /** Forecast rows only */
  forecastValue = "forecast_value",
  /** Forecast rows only */
  forecastValueLow = "forecast_value_lower",
  /** Forecast rows only */
  forecastValueHigh = "forecast_value_higher",

  subquestion = "subquestion", // Legacy
  subquestion1 = "subquestion1",
  subquestion2 = "subquestion2",
  subquestion3 = "subquestion3",
  grouping = "grouping",
  weight = "weight", // Survey string dimension
  value = "value",
  date = "date",
  region = "region",
  percentage = "percentage",
  index = "index", // Micro z value
  version = "version",
  status = "status",
  /** User-defined (computed value) value */
  userDefined = "user_defined",
  /** @deprecated */
  breakdown = "breakdown", // Special survey breakdown

  background1 = "background1",
  background2 = "background2",
  background3 = "background3",
  background4 = "background4",
  background5 = "background5",
  background6 = "background6",
  background7 = "background7",
  background8 = "background8",
  background9 = "background9",
  background10 = "background10",
  background11 = "background11",
  background12 = "background12",
  background13 = "background13",
  background14 = "background14",
  background15 = "background15",
  background16 = "background16",
  background17 = "background17",
  background18 = "background18",
  background19 = "background19",
  background20 = "background20",
  background21 = "background21",
  /** The first custom breakdown, for normal Infostat survey */
  background22 = "background22",
  background23 = "background23",
  background24 = "background24",
  background25 = "background25",
  background26 = "background26",
  background27 = "background27",
  background28 = "background28",
  background29 = "background29",
  background30 = "background30",
}

/**
 * Interface that forces implementors to provide a key for determining color
 */
export interface ColorKey {
  /**
   * Gets a consistent key, used to determine a color
   */
  colorKey: string;
}

export type LegendPositioningBottom = {
  orientation: Orientation.horizontal;
  height: number;
  headerLabel?: TextContainerPositioned;
  labels: TextContainerPositioned[];
};

export type LegendPositioningSide = {
  orientation: Orientation.vertical;
  width: number;
  headerLabel?: TextContainerPositioned;
  labels: TextContainerPositioned[];
};

export type LegendPositioning = LegendPositioningBottom | LegendPositioningSide;

/**
 * Describes size of label areas around chart. Label areas include
 * domain/range labels _and_ ticks.
 */
export interface LabelAreas {
  labelsLeft?: { totalWidth: number };
  labelsBottom?: { heightWithoutLegend: number; heightWithLegend?: number };
  labelsRight?: { totalWidth: number };
}

export interface SidePadding {
  paddingRight?: number;
  paddingLeft?: number;
}

/**
 * The special value used in db to indicate a value is missing
 * tags: MISSING_VALUE, NO_VALUE, missing value
 */
export const NO_VALUE_MARKER = "--";

interface PositionedDimensions {
  position: Position2D;
  width: number;
  height: number;
}

export interface OuterDimensions {
  fullWidth: number;
  fullHeight: number;
}

export interface MainChartDimensions extends OuterDimensions {
  boundedWidth: number;
  boundedHeight: number;
  marginTop: number;
  marginBottom: number;
  marginRight: number;
  marginLeft: number;
}

export interface ChartDimensions {
  main: MainChartDimensions;
  legend: PositionedDimensions;
  title: PositionedDimensions;
  footnotes?: PositionedDimensions;
  source: PositionedDimensions;
}

export type DatasetInputDto = StatsDatasetResponseDto;
