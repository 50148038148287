import {
  ChartDimensions,
  MainChartDimensions,
} from "../../../lib/application/stats/shared/core/definitions";

interface Props {
  dimensions: ChartDimensions;
}

export function DebugBoxes(props: Props) {
  const { main } = props.dimensions;
  return <DebugBoxesMainDimensions main={main}></DebugBoxesMainDimensions>;
}

export function DebugBoxesMainDimensions(props: { main: MainChartDimensions }) {
  const { main } = props;
  return (
    <>
      <rect
        x={main.marginLeft}
        width={main.boundedWidth}
        y={0}
        height={main.marginTop}
        fill="rgba(255,0,0,0.3)"
      ></rect>
      <rect
        x={0}
        width={main.marginLeft}
        y={main.marginTop}
        height={main.boundedHeight}
        fill="rgba(255,0,0,0.3)"
      ></rect>
      <rect
        x={main.marginLeft}
        width={main.boundedWidth}
        y={main.marginTop}
        height={main.boundedHeight}
        fill="rgba(0,255,0,0.3)"
      ></rect>
      <rect
        x={main.marginLeft + main.boundedWidth}
        width={main.marginRight}
        y={main.marginTop}
        height={main.boundedHeight}
        fill="rgba(0,0,255,0.3)"
      ></rect>
      <rect
        x={main.marginLeft}
        width={main.boundedWidth}
        y={main.marginTop + main.boundedHeight}
        height={main.marginBottom}
        fill="rgba(255,0,0,0.3)"
      ></rect>
    </>
  );
}
