import * as _ from "lodash";

import { defined } from "../../../../core/defined";
import { BandScalesByDimension } from "./bandScales";
import { LabelSpecWithTextContainer } from "./bar_chart_common";
import { LabelLayerWithRenderInfo, LayeredLabels } from "./labels/definitions";

export function calculateGroupedLabels(
  layeredLabels: LayeredLabels,
  bandScales: BandScalesByDimension
): LabelSpecWithTextContainer[] {
  const layers = layeredLabels.extendedLayers();
  if (layers.length === 0) {
    return [];
  }
  return getLabels(0, layers, layers[0], bandScales);
}

function getLabels(
  currentDomainAxisOffset: number,
  layers: readonly LabelLayerWithRenderInfo[],
  currentLayer: LabelLayerWithRenderInfo,
  bandScales: BandScalesByDimension
): LabelSpecWithTextContainer[] {
  const level = layers.indexOf(currentLayer);
  const remainingLayers = layers.slice(level + 1);

  const currentDimension = currentLayer.layer.dimension;
  const bandscale = bandScales.find((b) => b.dimension === currentDimension);
  if (!defined(bandscale)) {
    throw new Error("Bandscale not found for dimension: " + currentDimension);
  }

  const bandwidth = bandscale.scale.bandwidth();
  const renderInfo = currentLayer.renderInfo;
  const originalLabels = currentLayer.layer.labels;
  return _.flatMap(renderInfo.textContainers, (textContainer, i) => {
    const originalLabel = originalLabels[i];
    const currentOffset =
      currentDomainAxisOffset + (bandscale.scale(originalLabel) ?? 0);
    const positionedLabels: LabelSpecWithTextContainer[] = [
      {
        textContainer: textContainer.container,
        dimension: currentDimension,
        level,
        domainAxisOffset: currentOffset,
        bandwidth: bandwidth,
      },
    ];
    if (remainingLayers.length === 0) {
      return positionedLabels;
    }

    return positionedLabels.concat(
      getLabels(currentOffset, layers, remainingLayers[0], bandScales)
    );
  });
}
