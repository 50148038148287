import * as _ from "lodash";

import { TickLabelWithRawValue } from "./core/ticks";

/**
 * Return a subset of ticks that have unique texts
 * and include the start and end points, if possible.
 * @param ticks
 */
export function normalizeTicks(
  ticks: TickLabelWithRawValue[]
): TickLabelWithRawValue[] {
  if (ticks.length < 2) {
    return ticks;
  }

  let finalTicks: TickLabelWithRawValue[] = _.uniqBy(
    [ticks[0], ticks[ticks.length - 1]],
    (tick) => tick.text
  );
  for (const tick of ticks) {
    if (!finalTicks.find((t) => t.text === tick.text)) {
      finalTicks = [
        ...finalTicks.slice(0, finalTicks.length - 1),
        tick,
        finalTicks[finalTicks.length - 1],
      ];
    }
  }

  return finalTicks;
}

export function normalizeTicksIntegersOnly(
  ticksRaw: TickLabelWithRawValue[]
): TickLabelWithRawValue[] {
  return normalizeTicks(ticksRaw.filter((t) => _.isInteger(t.value)));
}
