import { useEffect, useState } from "react";

/**
 * Hook for executing a function when a state value changes.
 * NB: Does not execute the given function when the state value is unchanged.
 */
export function useStateTransition<T>(
  state: T | undefined,
  transitionFunc: (
    prev: T | undefined,
    current: T | undefined
  ) => void | (() => void)
) {
  const [currentValue, setCurrentValue] = useState<T>();

  useEffect(() => {
    let cleanup: (() => void) | void;
    if (state !== currentValue) {
      cleanup = transitionFunc(currentValue, state);
      setCurrentValue(state);
    }
    return () => {
      cleanup?.();
    };
  }, [state, transitionFunc, currentValue]);
}
