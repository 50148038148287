import React, { useState, useEffect } from "react";
import { defined } from "../lib/core/defined";
import { ReactCodeMirrorRef } from "@uiw/react-codemirror";
import { DefaultLoading } from "./Loading";

// Lazy load the CodeMirror component
const CodeMirrorLazyComp = React.lazy(() =>
  import("@uiw/react-codemirror").then((module) => ({
    default: module.default,
  }))
);

let pythonExtensions: any = undefined;

export function CodeMirrorLazy(props: {
  codeMirrorRef: React.MutableRefObject<ReactCodeMirrorRef | null>;
  code: string;
  onCodeChange: (code: string) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  readonly?: boolean;
  height?: string;
}) {
  const codeMirrorRef = props.codeMirrorRef;
  const [extensions, setExtensions] = useState<any[] | undefined>(
    pythonExtensions
  );

  useEffect(() => {
    if (defined(extensions)) {
      return;
    }

    // Dynamically load the Python language extension
    import("@codemirror/lang-python").then((python) => {
      setExtensions([python.python()]);
      pythonExtensions = [python.python()];
    });
  }, [extensions]);

  if (!defined(extensions)) {
    return <DefaultLoading />;
  }

  return (
    <CodeMirrorLazyComp
      ref={codeMirrorRef}
      readOnly={props.readonly}
      value={props.code}
      extensions={extensions}
      height={props.height ?? "400px"}
      onKeyDown={(e) => {
        props.onKeyDown?.(e);
      }}
      onChange={(v) => {
        props.onCodeChange(v);
      }}
    />
  );
}
