import { useCallback } from "react";

import { statsApi } from "../../../requests/statsApi";
import { statsApiV2 } from "../../../requests/statsApiV2";
import { handleWorkspaceCardToCardStateLatest } from "../../stats/workspace/rebuild_state";
import { GeneratedCard } from "./useLoadDocumentGeneric";
import { WorkspaceLatest } from "../../stats/workspace/shared";
import { assertNever } from "../../../../core/assert";
import { CustomBranding } from "../../../../../components/NavBar";

export type BasicDoc = WorkspaceLatest & {
  id: number;
  title: string;
  draft: boolean;
};

export type BasicThirdPartyDoc = WorkspaceLatest & {
  thirdPartyDocId: string;
  title: string;
  customBranding?: CustomBranding;
};

export function useGetCardGenerator(showAdminDraftData: boolean) {
  const getCardGenerator = useCallback(
    function* (doc: WorkspaceLatest) {
      for (const c of doc.state.cards) {
        yield handleWorkspaceCardToCardStateLatest(
          c,
          showAdminDraftData,
          statsApi,
          statsApiV2
        ).then((res) => {
          switch (res.type) {
            case "error":
              return { card: res };
            case "textCardCK":
            case "textCardSimple":
              return { card: res };
            case "dataCard": {
              const link = doc.state.cardColorSchemes.find(
                (c) => c.cardId === res.id
              );
              const schemeContainer = doc.state.colorSchemes.find(
                (c) => c.id === link?.schemeId
              );
              return {
                card: res,
                colorScheme: schemeContainer,
              };
            }
            case "microCard": {
              const link = doc.state.cardColorSchemes.find(
                (c) => c.cardId === res.id
              );
              const geoLinks = doc.state.styleContainerLinksGeoMicro?.filter(
                (l) => l.cardId === res.id
              );
              return {
                card: res,
                colorScheme: doc.state.colorSchemes.find(
                  (c) => c.id === link?.schemeId
                ),
                styleContainersGeoMicro:
                  doc.state.styleContainersGeoMicro?.filter((s) =>
                    geoLinks?.some((geo) => geo.styleContainerId === s.id)
                  ),
              };
            }
            case "microCardImage":
              return { card: res };
            case "pythonCard":
              return { card: res };
            default:
              assertNever(res);
          }
          return { card: res } as GeneratedCard;
        });
      }
    },
    [showAdminDraftData]
  );

  return getCardGenerator;
}
