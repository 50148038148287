import { Card } from "../../../components/Card";
import { MainContent } from "../../../components/MainContent";

interface Props {
  title: string;
}
export function DocNotFound(props: Props) {
  return (
    <MainContent>
      <Card useStdPadding>
        <h2>{props.title}</h2>
        <p>Försök igen senare eller kontakta en administratör för hjälp.</p>
      </Card>
    </MainContent>
  );
}
