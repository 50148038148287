import { cloneDeep } from "lodash";
import { defined } from "../../../../core/defined";
import { last } from "../../../../core/last";
import {
  geocodesToSelections,
  GeographiesSerializable,
} from "../../../../domain/geography";
import { CustomDataOutputSettings } from "../../../../infra/api_responses/account";
import { DocCardStats } from "../document-core/core";
import { LatestSettingsFormat } from "./common";
import { DataOutputSettings } from "../document-core/DataOutputSettings";

/**
 * Apply default settings to a card.
 * NOTE: Apply after measure selections have been fully loaded: available dates must be set.
 */
export function applyDefaultSettings(
  settings: LatestSettingsFormat,
  customDataOutputSettings: CustomDataOutputSettings | undefined,
  card: DocCardStats,
  geographies: GeographiesSerializable
): DocCardStats {
  const timeRange = settings.timeRange;
  const geocodes =
    settings.measureSettings.type === "stats"
      ? settings.measureSettings.geocodes
      : undefined;

  const settingsWithDefaultsApplied: DataOutputSettings = {
    ...card.data.settings,
    ...settings.outputSettings,
  };

  return {
    ...card,
    data: {
      ...card.data,
      settings: defined(customDataOutputSettings)
        ? applyCustomOutputSettingsOnCompleteSettings(
            settingsWithDefaultsApplied,
            customDataOutputSettings
          )
        : settingsWithDefaultsApplied,
      geoSelections: defined(geocodes)
        ? geocodesToSelections(geocodes, geographies)
        : undefined,
      lockToLatestTime: settings.lockToLatest,
      timeSelection: determineTimeRange(timeRange, settings.lockToLatest, card),
      selectedView: settings.selectedView,
    },
  };
}

export function applyCustomOutputSettingsOnCompleteSettings(
  dataOutputSettings: DataOutputSettings,
  customOutputSettings: CustomDataOutputSettings
): DataOutputSettings {
  const settingsCopy = cloneDeep(dataOutputSettings);
  const {
    chartLabelsSize,
    chartValueLabelsSize,
    customMainHeaderSize,
    customSubHeaderLargeSize,
    customSubHeaderSmallSize,
    customUnitSize,
    customSourceTextSize,
    forceLabelsOutsideBars,
    fixedNumDecimals,
    showTicksXAxis,
    showTicksYAxis,
    showXAxis,
    showYAxis,
    showYAxisLabels,
    hideChartTitleSection,
    showFatLines,
    showLabels,
    showLineCircles,
    showSurveyValueFraction,
    hideLegendDimensionLabels,
    gridLinesXStyle,
    gridLinesYStyle,
  } = customOutputSettings;

  if (defined(gridLinesXStyle)) {
    settingsCopy.gridLinesXStyle = gridLinesXStyle;
  }
  if (defined(gridLinesYStyle)) {
    settingsCopy.gridLinesYStyle = gridLinesYStyle;
  }

  if (defined(chartLabelsSize)) {
    settingsCopy.chart.labelSize = chartLabelsSize;
  }
  if (defined(chartValueLabelsSize)) {
    settingsCopy.chart.valueLabelSize = chartValueLabelsSize;
  }
  if (defined(forceLabelsOutsideBars)) {
    settingsCopy.forceLabelsOutsideBars = forceLabelsOutsideBars;
  }
  if (defined(customMainHeaderSize)) {
    settingsCopy.customMainHeaderSize = customMainHeaderSize;
  }
  if (defined(customSubHeaderLargeSize)) {
    settingsCopy.customSubHeaderLargeSize = customSubHeaderLargeSize;
  }
  if (defined(customSubHeaderSmallSize)) {
    settingsCopy.customSubHeaderSmallSize = customSubHeaderSmallSize;
  }
  if (defined(customUnitSize)) {
    settingsCopy.customUnitSize = customUnitSize;
  }
  if (defined(fixedNumDecimals)) {
    settingsCopy.fixedNumDecimals = fixedNumDecimals;
  }
  if (defined(hideChartTitleSection)) {
    settingsCopy.hideChartTitleSection = hideChartTitleSection;
  }
  if (defined(showFatLines)) {
    settingsCopy.showFatLines = showFatLines;
  }
  if (defined(showLabels)) {
    settingsCopy.showLabels = showLabels;
  }
  if (defined(showLineCircles)) {
    settingsCopy.showLineCircles = showLineCircles;
  }
  if (defined(showSurveyValueFraction)) {
    settingsCopy.showSurveyValueFraction = showSurveyValueFraction;
  }
  if (defined(customSourceTextSize)) {
    settingsCopy.customSourceTextSize = customSourceTextSize;
  }
  if (defined(hideLegendDimensionLabels)) {
    settingsCopy.hideLegendDimensionLabels = hideLegendDimensionLabels;
  }
  if (defined(showTicksXAxis)) {
    settingsCopy.showTicksXAxis = showTicksXAxis;
  }
  if (defined(showTicksYAxis)) {
    settingsCopy.showTicksYAxis = showTicksYAxis;
  }
  if (defined(showXAxis)) {
    settingsCopy.showXAxis = showXAxis;
  }
  if (defined(showYAxis)) {
    settingsCopy.showYAxis = showYAxis;
  }
  if (defined(showYAxisLabels)) {
    settingsCopy.showYAxisLabels = showYAxisLabels;
  }

  return settingsCopy;
}

function determineTimeRange(
  savedTimeRange: LatestSettingsFormat["timeRange"],
  lockToLatest: boolean | undefined,
  card: DocCardStats
): [string, string] | undefined {
  if (
    lockToLatest ||
    !defined(savedTimeRange) || // No saved time range: lock to latest
    (defined(savedTimeRange) && savedTimeRange.last === undefined) // For backwards compatibility -- when first is defined but not last, we snap to the latest date
  ) {
    const maxDate = getMaxDate(card);
    if (!defined(maxDate)) {
      return;
    }

    if (!defined(savedTimeRange)) {
      return [maxDate, maxDate];
    }

    if (savedTimeRange.first === savedTimeRange.last) {
      return [maxDate, maxDate];
    }

    return [savedTimeRange.first, maxDate];
  }

  return [
    savedTimeRange.first,
    // If timeRange.last is not defined, we use the current max date available
    savedTimeRange.last ?? getMaxDate(card) ?? savedTimeRange.first,
  ];
}

function getMaxDate(card: DocCardStats): string | undefined {
  const primarySelection = card.data.dataSelections[0];
  if (!defined(primarySelection)) {
    return;
  }
  return last(primarySelection.measureSelection?.availableDates ?? []);
}
