export class Timestamp {
  private constructor(private _unixTimeMs: number) {}

  isPast(): boolean {
    return this._unixTimeMs < Date.now();
  }

  eq(other: Timestamp): boolean {
    return this._unixTimeMs === other._unixTimeMs;
  }

  static fromUnixTimeSeconds(seconds: number): Timestamp {
    return new Timestamp(seconds * 1000);
  }

  static fromUnixTimeMs(ms: number): Timestamp {
    return new Timestamp(ms);
  }
}
