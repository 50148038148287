import { useCallback, useContext, useEffect, useMemo } from "react";
import { DefaultLoading } from "../../../components/Loading";
import { MainContent } from "../../../components/MainContent";
import {
  CategoriesContext,
  SharingInfoContext,
  SharingInfoReloader,
} from "../../../lib/application/contexts";
import {
  SharingInfo,
  getSharingInfoNonDocument,
} from "../../../lib/application/files/SharingInfo";
import { adminApi } from "../../../lib/application/requests/admin/admin_api";
import {
  DocMetadataState,
  useLoadDocumentGeneric,
} from "../../../lib/application/state/actions/load/useLoadDocumentGeneric";
import { useDocumentMetadata } from "../../../lib/application/state/stats/useMetadata";
import { DocumentMetadata } from "../../../lib/application/state/stats/document-meta/DocumentMetadata";
import { getVerifiedWorkspace } from "../../../lib/application/state/stats/workspace/rebuild_state";
import { defined } from "../../../lib/core/defined";
import { voidFunc } from "../../../lib/core/voidFunc";
import { DocumentDto } from "../../../lib/infra/api_responses/document";
import { HttpError, HttpResult } from "../../../lib/infra/HttpResult";
import { DataCardsContainer } from "./DataCardsContainer";
import { statsApiV2 } from "../../../lib/application/requests/statsApiV2";
import { LoadingResult } from "../../../lib/application/loading/LoadingResult";
import { WorkspaceLatest } from "../../../lib/application/state/stats/workspace/shared";
import { Progress } from "../../../lib/core/progress";
import { DocCardState } from "../../../lib/application/state/stats/document-core/core";
import { assertNever } from "../../../lib/core/assert";
import { useGetCardGenerator } from "../../../lib/application/state/actions/load/_shared";
import { statsApi } from "../../../lib/application/requests/statsApi";

export type Result = [
  LoadingResult<HttpError, SharingInfo>,
  SharingInfoReloader | undefined
];

type BasicDoc = WorkspaceLatest & {
  id: number;
  title: string;
};
type GetDocResult = HttpResult<BasicDoc>;

/**
 * ADMIN-ONLY view for rendering a document.
 *
 * Assumes categories context is available.
 */
export function StatsDocumentReadOnlyAdmin(props: {
  documentId: number;
  mockDocumentId: number; // For setting map container id, via sharingInfoValue
}) {
  const documentId = props.documentId;
  const sharingInfoValue = useMemo(() => {
    return {
      info: {
        ...getSharingInfoNonDocument(),
        nodeId: () => props.mockDocumentId,
      },
    };
  }, [props.mockDocumentId]);

  const categories = useContext(CategoriesContext);

  const [metadata, setMetadata] = useDocumentMetadata();

  const loadDoc = useCallback(
    (): Promise<GetDocResult> =>
      adminApi.getDocument(documentId).then((doc: HttpResult<DocumentDto>) => {
        return doc.match({
          ok: (doc) => {
            return getVerifiedWorkspace(doc, statsApi, statsApiV2).then(
              (res) => {
                return HttpResult.fromOk({
                  ...res,
                  id: -1,
                  title: "Mock doc",
                });
              }
            );
          },
          err: (err) => {
            return Promise.resolve(HttpResult.fromErr(err));
          },
        });
      }),
    [documentId]
  );

  const getDocumentMetadata = useCallback((doc: BasicDoc): DocMetadataState => {
    return {
      ...doc,
      draft: false, // Always false for read only docs
      userHasApplicableWriteLicenses: false, // Always false for read only docs
      editModeOn: false,
      editModeAvailable: false,
    };
  }, []);

  const getUninitializedCards = useCallback((doc: BasicDoc) => {
    return doc.state.cards.map((c) => {
      switch (c.type) {
        case "dataCard":
        case "microCard":
        case "pythonCard":
          return {
            ...c,
            initState: Progress.NotStarted,
          } as DocCardState;
        case "textCardCK":
        case "textCardSimple":
          return c as DocCardState;
      }
      assertNever(c);
    });
  }, []);

  const getCardGenerator = useGetCardGenerator(true);

  const notReadyStatusWorkspace = useLoadDocumentGeneric(
    loadDoc,
    getDocumentMetadata,
    getUninitializedCards,
    getCardGenerator,
    undefined
  );

  useEffect(() => {
    setMetadata(
      DocumentMetadata.fromDto({
        id: props.mockDocumentId,
        title: "Mock document",
        version: 0,
      })
    );
  }, [props.mockDocumentId, setMetadata]);

  if (!defined(metadata)) {
    return <DefaultLoading></DefaultLoading>;
  }

  const workspace = notReadyStatusWorkspace.getReadyData();
  if (!defined(workspace)) {
    return <DefaultLoading></DefaultLoading>;
  }

  return (
    <div className="page-scrollable">
      <p>
        doc-version: <strong>{workspace.workspaceVersion}</strong>
      </p>
      <SharingInfoContext.Provider value={sharingInfoValue}>
        <MainContent>
          <DataCardsContainer
            sharingInfo={getSharingInfoNonDocument()}
            categories={categories}
            addMaxNumCardsError={voidFunc}
          ></DataCardsContainer>
        </MainContent>
      </SharingInfoContext.Provider>
    </div>
  );
}
