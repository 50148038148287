import { String as StringRT } from "runtypes";

import { config } from "../../../config";
import { defined } from "../../core/defined";
import { ResultType } from "../../core/Result";
import { SimpleCache } from "../../core/SimpleCache";
import { voidFunc } from "../../core/voidFunc";
import { AlertRegistrations } from "../../domain/alerts";
import { Categories } from "../../domain/categories";
import {
  Geographies,
  GeographiesSerializable,
  GeoType,
  RegionsResponse,
} from "../../domain/geography";
import { getDefaultMeasure } from "../../domain/measure";
import {
  MeasureDto,
  MeasureDates,
  MeasureDatesRT,
  MeasureDtoRT,
  MeasureSelection,
  MeasureSelectionSurvey,
  MeasureThin,
  MeasureFull,
  MeasureSelectionRegular,
  SelectedDimensionsV2,
  MeasureUpdatesResponseRT,
  MeasureSelectionSurveyString,
} from "../../domain/measure/definitions";

import { TimeResolution } from "../../domain/time";
import { AlertRegistrationsRT } from "../../infra/api_responses/alerts";
import {
  StatsDatasetResponseDto,
  DataValueTypeAny,
} from "../../infra/api_responses/dataset";
import {
  SearchResultsV2,
  SearchResultsV2RT,
} from "../../infra/api_responses/search";
import {
  SurveyDatasetDto,
  SurveyDatasetDtoRT,
  SurveyStringDatasetDto,
  SurveyStringDatasetDtoRT,
} from "../../infra/api_responses/survey_dataset";
import { HttpResult } from "../../infra/HttpResult";
import { logger } from "../../infra/logging";
import {
  DataLoadError,
  httpErrToDataLoadErr,
} from "../state/stats/document-core/_core-shared";
import { StatsDataset } from "../stats/datasets/StatsDataset";
import { SurveyDataset } from "../stats/datasets/SurveyDataset";
import { SurveyStringDataset } from "../stats/datasets/SurveyStringDataset";
import {
  authedFileFormUpload,
  authedRequest,
  decodedAuthedRequest,
} from "./shared";
import { statsApiV2 } from "./statsApiV2";
import {
  UserDefinedSeriesDto,
  userDefinedSeriesToDto,
} from "./datasets/user_defined_series";
import { Dimension } from "../stats/shared/core/definitions";
import { DataOutputSettings } from "../state/stats/document-core/DataOutputSettings";

export function getMeasureUpdates() {
  return decodedAuthedRequest(
    config.apis.statsV2,
    `measures/updates`,
    undefined,
    "GET",
    MeasureUpdatesResponseRT
  );
}

const singleMeasureCache = new SimpleCache<MeasureFull>(30);
export function getSingleMeasure(
  measureId: number,
  adminShowDraftData: boolean,
  useCache: boolean
): Promise<HttpResult<MeasureFull>> {
  const cacheKey = measureId + "_" + adminShowDraftData;
  if (useCache) {
    const cached = singleMeasureCache.get(cacheKey);
    if (defined(cached)) {
      return Promise.resolve(HttpResult.fromOk(cached));
    }
  }

  return authedRequest(
    config.apis.statsV1,
    `measures/${measureId}`,
    undefined,
    "GET"
  ).then<HttpResult<MeasureFull>>((singleRes) => {
    return handleSingleMeasure(singleRes, adminShowDraftData, useCache).then(
      (resFull) => {
        resFull.match({
          ok: (full) => {
            singleMeasureCache.set(cacheKey, full);
          },
          err: (e) => {
            logger.error(e);
          },
        });
        return resFull;
      }
    );
  });
}

function handleSingleMeasure(
  unverified: HttpResult<unknown>,
  adminShowDraftData: boolean,
  useCache: boolean
): Promise<HttpResult<MeasureFull>> {
  return unverified.match({
    ok: (data) => {
      const verified = MeasureDtoRT.check(data);
      return statsApiV2
        .getDimensions(verified.data_id, adminShowDraftData, useCache)
        .then((res) => {
          const dimensions = res.unwrap();
          return HttpResult.fromOk({
            ...verified,
            dimensions,
          }) as HttpResult<MeasureFull>;
        });
    },
    err: (err) => {
      return Promise.resolve(HttpResult.fromErr(err));
    },
  });
}

/** @deprecated */
export function getMeasureDatesCustomerSurvey(
  measureId: number,
  valueType: DataValueTypeAny,
  breakdowns: SelectedDimensionsV2
): Promise<HttpResult<MeasureDates>> {
  return authedRequest(
    config.apis.statsV1,
    `measures/${measureId}/dates`,
    { breakdowns: breakdowns, valueType: valueType },
    "POST"
  ).then((res) => {
    return res.map(MeasureDatesRT.check).map((dates) => {
      return dates?.map((d) => d.slice(0, 10)) ?? null;
    });
  });
}

const surveyDatesCache = new SimpleCache<MeasureDates>(30);
export function getMeasureDatesSurveyV2(
  measureId: number,
  adminSeeDraftData: boolean,
  useCache: boolean
): Promise<HttpResult<MeasureDates>> {
  const cacheKey = measureId + "_" + adminSeeDraftData;
  if (useCache) {
    const cached = surveyDatesCache.get(cacheKey);
    if (defined(cached)) {
      return Promise.resolve(HttpResult.fromOk(cached));
    }
  }

  return authedRequest(
    config.apis.statsV2,
    `survey/measures/${measureId}/dates`,
    { see_draft_data: adminSeeDraftData },
    "POST"
  ).then((res) => {
    return res.map(MeasureDatesRT.check).map((dates) => {
      const finalDates = dates?.map((d) => d.slice(0, 10)) ?? null;
      surveyDatesCache.set(cacheKey, finalDates);
      return finalDates;
    });
  });
}

const statsDatesCache = new SimpleCache<MeasureDates>(30);
export function getMeasureDatesStatsV2(
  measureId: number,
  breakdowns: SelectedDimensionsV2,
  adminSeeDraftData: boolean,
  useCache: boolean
): Promise<HttpResult<MeasureDates>> {
  const cacheKey =
    measureId + "_" + adminSeeDraftData + JSON.stringify(breakdowns);
  if (useCache) {
    const cached = statsDatesCache.get(cacheKey);
    if (defined(cached)) {
      return Promise.resolve(HttpResult.fromOk(cached));
    }
  }

  return authedRequest(
    config.apis.statsV2,
    `stats/measures/${measureId}/dates`,
    { ...breakdowns, see_draft_data: adminSeeDraftData },
    "POST"
  ).then((res) => {
    return res.map(MeasureDatesRT.check).map((dates) => {
      const finalDates = dates?.map((d) => d.slice(0, 10)) ?? null;
      statsDatesCache.set(cacheKey, finalDates);
      return finalDates;
    });
  });
}

export function createDocumentAlertRegistration(
  userId: string,
  documentId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `users/${userId}/alert_registrations`,
    { node_id: documentId },
    "POST"
  );
}
export function createMeasureAlertRegistration(
  userId: string,
  measureId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `users/${userId}/alert_registrations`,
    { data_id: measureId },
    "POST"
  );
}

export function uploadShareableImage(
  blob: Blob,
  title: string,
  description: string
): Promise<HttpResult<string>> {
  const form = new FormData();
  form.append("file", blob);
  return authedFileFormUpload<string>(
    config.apis.statsV2,
    `share/imageashtml?title=${encodeURIComponent(
      title
    )}&desc=${encodeURIComponent(description)}`,
    form,
    {
      onUploadDone: voidFunc,
      onUploadError: voidFunc,
      onUploadProgress: voidFunc,
    }
  ).then((res) => {
    return res.map((s) => StringRT.check(s));
  });
}

export function uploadPublicImageBlob(blob: Blob): Promise<HttpResult<string>> {
  const form = new FormData();
  form.append("file", blob);
  return authedFileFormUpload<string>(
    config.apis.statsV2,
    `share/image`,
    form,
    {
      onUploadDone: voidFunc,
      onUploadError: voidFunc,
      onUploadProgress: voidFunc,
    }
  ).then((res) => {
    return res.map((s) => StringRT.check(s));
  });
}

export function getAlertRegistrations(
  userId: string
): Promise<HttpResult<AlertRegistrations>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `users/${userId}/alert_registrations`,
    undefined,
    "GET",
    AlertRegistrationsRT
  ).then((res) => res.map((dto) => new AlertRegistrations(dto)));
}

export function deleteAlertRegistration(
  userId: string,
  registrationId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `users/${userId}/alert_registrations/${registrationId}`,
    undefined,
    "DELETE"
  );
}

export function createLink(json: object): Promise<string> {
  return authedRequest<string>(
    config.apis.statsV1,
    "shortlinks",
    json,
    "POST"
  ).then((res) => res.unwrap());
}

export function getLinkedCard(link: string): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `shortlinks/${link}`,
    undefined,
    "GET"
  );
}

function getMeasures(
  subjectPath: string[],
  groupingOnly: boolean,
  seeDraftData: boolean,
  groupableOnly?: boolean,
  maxResolution?: TimeResolution
): Promise<MeasureDto[] | null> {
  const timeParam = defined(maxResolution)
    ? `&maxResolution=${maxResolution.serialize()}`
    : "";
  const area = encodeURIComponent(subjectPath[0]);
  const subarea = encodeURIComponent(subjectPath[1]);
  const subject = encodeURIComponent(subjectPath[2]);
  const draftParam = seeDraftData ? "&seeDraftData=true" : "";
  return authedRequest<MeasureDto[] | null>(
    config.apis.statsV1,
    `measures?area=${area}&subarea=${subarea}&subject=${subject}&groupingOnly=${groupingOnly}&groupableOnly=${groupableOnly}${timeParam}${draftParam}`,
    null,
    "GET"
  ).then((res) => {
    const unwrapped: MeasureDto[] | null = res.unwrap(); // TODO: safety
    if (unwrapped === null) {
      return unwrapped;
    }

    return unwrapped;
  });
}

const cachedMeasures = new SimpleCache<MeasureThin[]>(10);
export function clearCachedMeasureLists() {
  cachedMeasures.clear();
}

export function getMeasuresWithCache(
  subjectPath: string[],
  groupableMeasuresOnly: boolean,
  groupingMeasuresOnly: boolean,
  seeDraftData: boolean,
  maxResolution?: TimeResolution
): Promise<MeasureThin[]> {
  const key =
    subjectPath.join("-") +
    groupableMeasuresOnly +
    "_" +
    seeDraftData +
    "_" +
    groupingMeasuresOnly +
    (maxResolution?.serialize() ?? "");
  const cached = cachedMeasures.get(key);
  if (defined(cached)) {
    return Promise.resolve(cached);
  }
  return getMeasures(
    subjectPath,
    groupingMeasuresOnly,
    seeDraftData,
    groupableMeasuresOnly,
    maxResolution
  ).then((measureDtos) => {
    if (!defined(measureDtos)) {
      return [];
    }
    const measures: MeasureThin[] = [];
    for (const m of measureDtos) {
      try {
        MeasureDtoRT.check(m);
        measures.push(m);
      } catch (e) {
        logger.error("Failed to decode measure using MeasureDto", e, m);
      }
    }
    cachedMeasures.set(key, measures);
    return measures;
  });
}

interface DatasetSpec {
  [key: string]: number | number[] | null;
  id: number; // data_id
}

interface GroupingSpec {
  [key: string]: number | null;
}

export function getStatsDataset(
  dataset: DatasetSpec,
  groupingDataId: number | undefined,
  groupingDimensions: GroupingSpec | undefined,
  geocodes: string[],
  dateStart: string,
  dateEnd: string,
  forecastPeriods: number,
  adminSeeDraftData: boolean,
  userDefinedSeries: UserDefinedSeriesDto[] | undefined,
  numDecimalsForComputation: number | undefined
) {
  return authedRequest<StatsDatasetResponseDto | null>(
    config.apis.statsV2,
    `stats/query`,
    {
      see_draft_data: adminSeeDraftData,
      forecast_periods: forecastPeriods,
      dataset,
      grouping_data_id: groupingDataId,
      grouping_dimensions: groupingDimensions,
      geocodes,
      date_start: dateStart,
      date_end: dateEnd,
      user_defined_series: userDefinedSeries,
      user_defined_series_decimal_places_for_calculation:
        numDecimalsForComputation,
    },
    "POST"
  );
}

export function getSurveyStringDataset(
  dataId: number,
  dateStart: string,
  dateEnd: string,
  dimensions: SelectedDimensionsV2,
  seeDraftData: boolean
): Promise<HttpResult<SurveyStringDatasetDto | null>> {
  let body: { [key: string]: any } = {
    see_draft_data: seeDraftData,
    data_id: dataId,
    date_start: dateStart,
    date_end: dateEnd,
    dimensions,
  };

  return decodedAuthedRequest<SurveyStringDatasetDto | null>(
    config.apis.statsV2,
    `survey/query`,
    body,
    "POST",
    SurveyStringDatasetDtoRT
  );
}

function getSurveyDatasetV2(
  dataId: number,
  dateStart: string,
  dateEnd: string,
  dimensions: SelectedDimensionsV2,
  groupingDataId: number | undefined,
  groupingDimensions: { [key: string]: number } | undefined,
  seeDraftData: boolean,
  userDefinedSeries: UserDefinedSeriesDto[] | undefined,
  numDecimalsForComputation: number | undefined
): Promise<HttpResult<SurveyDatasetDto | null>> {
  let body: { [key: string]: any } = {
    see_draft_data: seeDraftData,
    data_id: dataId,
    date_start: dateStart,
    date_end: dateEnd,
    dimensions,
    user_defined_series: userDefinedSeries,
    user_defined_series_decimal_places_for_calculation:
      numDecimalsForComputation,
  };
  if (defined(groupingDimensions) && defined(groupingDataId)) {
    body.grouping_dimensions = groupingDimensions;
    body.grouping_data_id = groupingDataId;
  }

  return authedRequest<SurveyDatasetDto | null>(
    config.apis.statsV2,
    `survey/query`,
    body,
    "POST"
  )
    .then((res) => {
      return res.match({
        ok: (data) => {
          if (data === null) {
            return HttpResult.fromOk(null);
          }
          // FIXME: remove workaround
          // #WORKAROUND -- make rows hold string values only to adhere to same format as regular dataset
          const checkedData: SurveyDatasetDto = SurveyDatasetDtoRT.check({
            ...data,
            rows:
              data?.rows?.map((r) => {
                const row: { [key: string]: string } = {};
                for (const [key, value] of Object.entries(r)) {
                  if (key === Dimension.userDefined) {
                    row[key] = value as any;
                    continue;
                  }

                  if (defined(value)) {
                    row[key] = value + "";
                  }
                }
                return row;
              }) ?? [],
          });
          return HttpResult.fromOk(checkedData);
        },
        err: (e) => {
          return res as HttpResult<SurveyDatasetDto>;
        },
      });
    })
    .catch((e) => {
      logger.error("Details", e.details);
      throw e;
    });
}

export function numDecimalsForStatsComputation(
  settings: DataOutputSettings
): number | undefined {
  if (settings.computedVariablesV3.length === 0) {
    return;
  }

  if (settings.computationInputRounding === "same_as_display_precision") {
    return settings.fixedNumDecimals ?? undefined;
  }

  return undefined;
}

export type StatsQueryResult = ResultType<StatsDataset, DataLoadError>;
const cachedDatasets = new SimpleCache<StatsDataset>(10);
export async function getStatsDatasetOrCached(
  dataset: DatasetSpec,
  groupingDimensions: GroupingSpec | undefined,
  geocodes: string[],
  forecastPeriods: number | undefined,
  dateStart: string,
  dateEnd: string,
  primaryMeasureSelection: MeasureSelection,
  groupingSelection: MeasureSelection | undefined,
  /**
   * If only a single geo type is selected, include here
   */
  includedGeoTypes: GeoType[],
  /**
   * If only a single geography is selected, include the label here
   */
  singleSelectedGeoLabel: string | undefined,
  settings: DataOutputSettings,
  adminShowDraftData: boolean,
  disableCache: boolean
): Promise<StatsQueryResult | undefined> {
  const numDecimalsForComputation = numDecimalsForStatsComputation(settings);
  const args = [
    dataset,
    groupingSelection?.measure.data_id,
    groupingDimensions,
    geocodes,
    dateStart,
    dateEnd,
    forecastPeriods ?? 0,
    adminShowDraftData,
    userDefinedSeriesToDto(settings.computedVariablesV3),
    numDecimalsForComputation,
  ] as const;
  const key = JSON.stringify(args);

  if (!disableCache) {
    const availableDataset = cachedDatasets.get(key);
    if (defined(availableDataset)) {
      return { type: "ok", data: availableDataset.copyWithSettings(settings) };
    }
  }

  return getStatsDataset(...args).then((res) => {
    return res.match({
      ok: (response) => {
        if (!defined(response)) {
          return { type: "err", error: "no-data" };
        }
        const dataset = new StatsDataset(
          response,
          [primaryMeasureSelection, groupingSelection].filter(defined),
          includedGeoTypes,
          geocodes,
          singleSelectedGeoLabel,
          settings
        );
        cachedDatasets.set(key, dataset);
        return { type: "ok", data: dataset };
      },
      err: (err) => {
        logger.error(err);
        return { type: "err", error: httpErrToDataLoadErr(err) };
      },
    });
  });
}

export type SurveyStringQueryResult = ResultType<
  SurveyStringDataset,
  DataLoadError
>;
const cachedSurveyStringDatasets = new SimpleCache<SurveyStringDataset>(10);
export async function getSurveyStringDatasetOrCached(
  dataId: number,
  dateStart: string,
  dateEnd: string,
  primaryMeasureSelection: MeasureSelectionSurveyString,
  breakdowns: SelectedDimensionsV2,
  settings: DataOutputSettings,
  disableCache: boolean,
  seeDraftData: boolean
): Promise<SurveyStringQueryResult> {
  const args = [dataId, dateStart, dateEnd, breakdowns, seeDraftData] as const;
  // Note that settings is not part of the key, beacuse we don't want to re-fetch the
  // entire dataset because the settings differ since settings don't affect the raw data.
  // Instead, if settings differ but we have the raw data cached, we create a new SurveyDataset
  // with all params the same except settings
  const key = JSON.stringify(args);

  if (!disableCache) {
    const availableDataset = cachedSurveyStringDatasets.get(key);
    if (defined(availableDataset)) {
      return { type: "ok", data: availableDataset.copyWithSettings(settings) };
    }
  }

  return getSurveyStringDataset(...args).then((res) => {
    return res.match({
      ok: (response) => {
        if (!defined(response)) {
          return { type: "err", error: "no-data" };
        }
        const dataset = new SurveyStringDataset(
          response,
          primaryMeasureSelection,
          settings
        );
        cachedSurveyStringDatasets.set(key, dataset);
        return { type: "ok", data: dataset };
      },
      err: (err) => {
        logger.error(err);
        return { type: "err", error: httpErrToDataLoadErr(err) };
      },
    });
  });
}

export function getComputatationInputPrecisionSurvey(
  settings: DataOutputSettings
): number | undefined {
  if (settings.computationInputRounding === "same_as_display_precision") {
    return settings.showSurveyValueFraction ? 1 : 0;
  }

  return;
}

export type SurveyQueryResult = ResultType<SurveyDataset, DataLoadError>;
const cachedSurveyDatasets = new SimpleCache<SurveyDataset>(10);
export async function getSurveyDatasetOrCached(
  dataId: number,
  dateStart: string,
  dateEnd: string,
  primaryMeasureSelection: MeasureSelectionSurvey,
  groupingMeasureSelection: MeasureSelectionRegular | undefined,
  groupingSet: { [key: string]: any } | undefined,
  breakdowns: SelectedDimensionsV2,
  settings: DataOutputSettings,
  disableCache: boolean,
  seeDraftData: boolean
): Promise<SurveyQueryResult> {
  const useDecimalsForUserDefinedCalculations =
    getComputatationInputPrecisionSurvey(settings);
  const args = [
    dataId,
    dateStart,
    dateEnd,
    breakdowns,
    groupingMeasureSelection?.measure.data_id,
    groupingSet,
    seeDraftData,
    userDefinedSeriesToDto(settings.computedVariablesV3),
    useDecimalsForUserDefinedCalculations,
  ] as const;
  // Note that settings is not part of the key, beacuse we don't want to re-fetch the
  // entire dataset because the settings differ since most settings don't affect the raw data.
  // Instead, if settings differ but we have the raw data cached, we create a new SurveyDataset
  // with all params the same except settings
  const key = JSON.stringify(args);

  if (!disableCache) {
    const availableDataset = cachedSurveyDatasets.get(key);
    if (defined(availableDataset)) {
      return { type: "ok", data: availableDataset.copyWithSettings(settings) };
    }
  }

  return getSurveyDatasetV2(...args).then((res) => {
    return res.match({
      ok: (response) => {
        if (!defined(response)) {
          return { type: "err", error: "no-data" };
        }
        const dataset = new SurveyDataset(
          response,
          primaryMeasureSelection,
          groupingMeasureSelection,
          settings
        );
        cachedSurveyDatasets.set(key, dataset);
        return { type: "ok", data: dataset };
      },
      err: (err) => {
        logger.error(err);
        return { type: "err", error: httpErrToDataLoadErr(err) };
      },
    });
  });
}

function getGeographicRegions(): Promise<HttpResult<GeographiesSerializable>> {
  return authedRequest<RegionsResponse>(
    config.apis.statsV1,
    `regions`,
    null,
    "GET"
  ).then((res) => res.map(Geographies.serializable));
}

let cachedGeos: HttpResult<GeographiesSerializable> | undefined;
export function getGeographicRegionsWithCache(): Promise<
  HttpResult<GeographiesSerializable>
> {
  if (defined(cachedGeos)) {
    return Promise.resolve(cachedGeos);
  }
  return getGeographicRegions().then((res) => {
    res.match({
      ok: (geos) => {
        cachedGeos = HttpResult.fromOk(geos);
      },
      err: (e) => {
        logger.error(e);
      },
    });

    return res;
  });
}

const categoriesCache = new SimpleCache<Categories>(10);
export function getCategoriesWithCache(
  groupingOption: "groupingOnly" | "groupableOnly" | undefined,
  maxTimeResolution: TimeResolution,
  seeDraftData: boolean
): Promise<HttpResult<Categories>> {
  const groupingParam =
    groupingOption === "groupableOnly"
      ? "groupableOnly=true"
      : groupingOption === "groupingOnly"
      ? "groupingOnly=true"
      : undefined;
  const timeParam = `maxResolution=${maxTimeResolution.serialize()}`;
  const draftParam = seeDraftData ? "seeDraftData=true" : undefined;
  const paramsString = [groupingParam, timeParam, draftParam]
    .filter(defined)
    .join("&");

  const key = paramsString;
  const cachedItem = categoriesCache.get(key);
  if (defined(cachedItem)) {
    return Promise.resolve(HttpResult.fromOk(cachedItem));
  }
  return authedRequest<Categories>(
    config.apis.statsV1,
    `categories?${paramsString}`,
    undefined,
    "GET"
  ).then((res) => {
    res.match({
      ok: (data) => {
        categoriesCache.set(key, data);
      },
      err: (e) => {
        logger.error(e);
      },
    });
    return res;
  });
}

let lau2json: GeoJSON.GeoJSON | undefined;
export async function getGeoSeLau2(): Promise<GeoJSON.GeoJSON> {
  return getGeoFile(
    config.geoFiles.lau2,
    () => lau2json,
    (f) => {
      lau2json = f;
    }
  );
}

let nuts3json: GeoJSON.GeoJSON | undefined;
export async function getGeoSeNuts3(): Promise<GeoJSON.GeoJSON> {
  return getGeoFile(
    config.geoFiles.nuts3,
    () => nuts3json,
    (f) => {
      nuts3json = f;
    }
  );
}

let nuts2json: GeoJSON.GeoJSON | undefined;
export async function getGeoSeNuts2(): Promise<GeoJSON.GeoJSON> {
  return getGeoFile(
    config.geoFiles.nuts2,
    () => nuts2json,
    (f) => {
      nuts2json = f;
    }
  );
}

let nuts1json: GeoJSON.GeoJSON | undefined;
export async function getGeoSeNuts1(): Promise<GeoJSON.GeoJSON> {
  return getGeoFile(
    config.geoFiles.nuts1,
    () => nuts1json,
    (f) => {
      nuts1json = f;
    }
  );
}

let countryJson: GeoJSON.GeoJSON | undefined;
export async function getGeoSeCountry(): Promise<GeoJSON.GeoJSON> {
  return getGeoFile(
    config.geoFiles.country,
    () => countryJson,
    (f) => {
      countryJson = f;
    }
  );
}
type GeographicSpec = {
  [geoType in GeoType]: GeoJSON.GeoJSON;
};
export function getAllGeoJson(): Promise<GeographicSpec> {
  return Promise.all([
    getGeoSeLau2(),
    getGeoSeNuts3(),
    getGeoSeNuts2(),
    getGeoSeNuts1(),
    getGeoSeCountry(),
  ]).then(([municipal, nuts3, nuts2, nuts1, country]) => ({
    municipal,
    nuts3,
    nuts2,
    nuts1,
    country,
  }));
}

function getGeoFile(
  path: string,
  get: () => GeoJSON.GeoJSON | undefined,
  set: (file: GeoJSON.GeoJSON) => void
) {
  const file = get();
  if (defined(file)) {
    return file;
  }
  return fetch(path).then((r) => {
    return r.json().then((json) => {
      set(json);
      return json;
    });
  });
}

export function statsSearchV2(
  searchString: string,
  maxTimeResolution: TimeResolution,
  seeDraftData: boolean,
  canGroupOthersOnly?: boolean,
  canBeGroupedOnly?: boolean
): Promise<HttpResult<SearchResultsV2>> {
  const params: { [key: string]: any } = {
    term: searchString,
    see_draft_data: seeDraftData,
    max_resolution: maxTimeResolution.serialize(),
  };
  if (canBeGroupedOnly) {
    params["can_be_grouped_only"] = canBeGroupedOnly;
  }
  if (canGroupOthersOnly) {
    params["can_group_others_only"] = canGroupOthersOnly;
  }

  return decodedAuthedRequest(
    config.apis.statsV2,
    `search`,
    params,
    "POST",
    SearchResultsV2RT
  );
}

export type DefaultMeasureResponse =
  | {
      availableMeasures: MeasureThin[];
      defaultMeasure: MeasureFull;
    }
  | undefined;

export async function handleGetDefaultMeasure(
  subjectPath: string[],
  groupableMeasuresOnly: boolean,
  groupingMeasuresOnly: boolean,
  maxTimeResolution: TimeResolution,
  adminShowDraftData: boolean
): Promise<DefaultMeasureResponse> {
  return getMeasuresWithCache(
    subjectPath,
    groupableMeasuresOnly,
    groupingMeasuresOnly,
    adminShowDraftData,
    groupingMeasuresOnly ? maxTimeResolution : undefined
  )
    .then<DefaultMeasureResponse>((m) => {
      if (m.length === 0) {
        logger.warn(
          `No measures found, ${subjectPath.join(
            ", "
          )}, groupingMeasuresOnly: ${groupingMeasuresOnly}, maxTimeResolution: ${maxTimeResolution.serialize()}`
        );
        return undefined;
      }
      const defaultMeasure = getDefaultMeasure(m);
      return statsApiV2
        .getDimensions(
          defaultMeasure.data_id,
          adminShowDraftData,
          !adminShowDraftData
        ) // Use cache unless in admin draft mode. This function is called on user interaction changing measure selections so we want to avoid unnecessary network requests
        .then((res) => {
          const dimensions = res.unwrap();

          return {
            availableMeasures: m,
            defaultMeasure: { ...defaultMeasure, dimensions },
          };
        });
    })
    .catch((e) => {
      logger.error(e);
      return undefined;
    });
}

export type MeasureSelectionSetter = (m: MeasureSelection) => void;
export type MeasureSelectionSetterRegular = (
  m: MeasureSelectionRegular
) => void;
