import _, { flatten, identity, max } from "lodash";
import * as d3scl from "d3-scale";
import * as d3shape from "d3-shape";
import * as d3timefmt from "d3-time-format";

import { defined } from "../../../core/defined";
import { RowValueRegularRaw } from "../../../infra/api_responses/dataset";
import {
  DataPoint,
  ForecastDataPoint,
  isDrawableDataPoint,
} from "../datasets/DataPoint";
import { RawNumericData } from "../format";
import {
  TimeSeriesLine,
  DataPointWithLowBaseInfo,
  ColorableTimeSeriesLine,
  ForecastLine,
} from "../shared/TimeSeriesLine";
import { ChartDataUnknown } from "../shared/chart_data/ChartData";
import {
  getStandardChartInnerWidth,
  calcChartDimensionsStep1,
  calcChartDimensionsStep2,
  getSpecialColorSchemeKeyByDimensionAndLabels,
  RANGE_TICKS_SIZE_PIXELS,
  RANGE_TICKS_PADDING_PIXELS,
  DEFAULT_MARGIN_LEFT,
} from "../shared/charts_common";
import { getDefaultTextStyle } from "../shared/core/TextStyle";
import {
  LegendPositioningSide,
  LegendPositioningBottom,
  Dimension,
  NO_VALUE_MARKER,
  ChartType,
} from "../shared/core/definitions";
import {
  TickLabel,
  TicksStyle,
  XTicksContainer,
  YTicksContainer,
  defaultTicksStyle,
} from "../shared/core/ticks";
import {
  calculateBottomLegend,
  calculateLabelAreasLineChart,
  calculateSideLegend,
} from "../shared/labels";
import { RowBaseInterface, RowForecast } from "../shared/row";
import {
  BREAKDOWN_ALL_LABEL,
  SURVEY_LOW_BASE_LABEL,
} from "../../../domain/measure/definitions";
import { LegendLabel } from "../shared/bar_chart/bar_chart_common";
import { DataCellValue } from "../datasets/DataCellValue";
import { calculatePercentageChange } from "../../../domain/data/calc";
import {
  ChartDataContainerV2,
  ChartDataContainerV2Line,
  LineChartDataV2,
} from "../../state/stats/document-core/_core-shared";
import {
  createColorSchemeContainerWithPalette,
  makeFullColorScheme,
} from "../../state/stats/document-style/operations";
import { ColorSchemeContainer } from "../../state/stats/document-style/definitions";
import { getUpdatedColorScheme } from "../shared/chart_data/chart_data_common";
import { SurveyRowValue } from "../../../infra/api_responses/survey_dataset";
import { logger } from "../../../infra/logging";
import {
  CustomThemeSpecApplied,
  defaultThemeSpec,
} from "../shared/core/colors/colorSchemes";
import {
  CUSTOM_LINE_CHART_LABELS_KEY,
  DataOutputSettings,
} from "../../state/stats/document-core/DataOutputSettings";

export function createLineChartDataAndColors(
  chartData: ChartDataUnknown,
  vizAreaWidth: number,
  windowHeight: number,
  defaultCustomColors: CustomThemeSpecApplied = defaultThemeSpec()
): ChartDataContainerV2Line {
  const data = createLineChartData(chartData, vizAreaWidth, windowHeight);
  return {
    chartType: ChartType.line,
    renderWidth: vizAreaWidth,
    data,
    colorSchemeContainer: createColorSchemeContainer(data, defaultCustomColors),
  };
}

// Same as above but for line chart data
export function createLineChartDataWithExistingColors(
  chartData: ChartDataUnknown,
  prevLoadedChartData: ChartDataContainerV2 | undefined,
  vizAreaWidth: number,
  windowHeight: number,
  existingColors: ColorSchemeContainer
): ChartDataContainerV2Line {
  const data = createLineChartData(chartData, vizAreaWidth, windowHeight);
  const lineLabels = data.lines.map((line) => line.colorKey);

  return {
    chartType: ChartType.line,
    renderWidth: vizAreaWidth,
    data,
    colorSchemeContainer: getUpdatedColorScheme(
      existingColors,
      lineLabels,
      data.colorConfig,
      chartData.liftedDimensions()
    ),
  };
}

export function createColorSchemeContainer(
  data: LineChartDataV2,
  customPalette: CustomThemeSpecApplied
): ColorSchemeContainer {
  const container = createColorSchemeContainerWithPalette(customPalette);
  const lineLabels = data.lines.map((line) => line.colorKey);
  const scheme = makeFullColorScheme(
    container,
    lineLabels,
    data.colorConfig.defaultToSingleColor,
    data.colorConfig.colorableDimensionLabel
  );
  container.colorScheme = scheme;

  return container;
}

export function createLineChartData<
  T extends RowValueRegularRaw | SurveyRowValue,
  U extends RowBaseInterface<T>
>(
  chartData: ChartDataUnknown,
  vizAreaWidth: number,
  windowHeight: number
): LineChartDataV2 {
  const {
    singleLegendDimension: legendDimension,
    plainDimensionsSorted: sortedGroupingDimensions,
  } = chartData.lineChartDataProps();

  const settings = chartData.outputSettings;
  const defaultTextStyle = getDefaultTextStyle(settings.chart.labelSize);
  const innerChartWidth = getStandardChartInnerWidth(
    vizAreaWidth,
    windowHeight
  );

  const { dimensions: chartDimensionsStep1, titleRows } =
    calcChartDimensionsStep1(innerChartWidth, chartData, settings);
  const { innerChartHeight } = chartDimensionsStep1;
  const maxSideLegendHeight = innerChartHeight;

  const groupAccessor = (row: RowBaseInterface<unknown>) => {
    return row.makeLabel(sortedGroupingDimensions);
  };
  const groupedRows = chartData.groupedRows(groupAccessor);
  const groupedForecastRows = chartData.groupedForecastRows(groupAccessor);
  const getRowKey = (row: U | RowForecast) => {
    return row.makeLabel(sortedGroupingDimensions, BREAKDOWN_ALL_LABEL);
  };
  const formatComputedValue = chartData.computedValueOutputSettings?.format;
  const roundComputedValue = chartData.computedValueOutputSettings?.roundValues;

  function _rowToDataPoint<R extends U | RowForecast>(
    row: R,
    rangeFormatter?: (row: R) => any
  ): DataPoint {
    return {
      isUserDefined: row.dimension(Dimension.userDefined) === true,
      _dimension: row.dimension,
      domainRaw: () => row.domain() as string,
      display: () => {
        if (row.type() !== "") {
          return NO_VALUE_MARKER;
        }

        if (row.isUserDefined && defined(formatComputedValue)) {
          return formatComputedValue(row.range().toString());
        }
        if (defined(rangeFormatter)) {
          return rangeFormatter(row);
        }
        return row.range()?.toString();
      },
      value: () => {
        if (row.type() !== "") {
          return undefined;
        }
        if (row.isUserDefined && defined(roundComputedValue)) {
          return roundComputedValue(row.range());
        }
        return row.range();
      },
      colorKey: () => getRowKey(row),
      label: () => {
        const rowKey = getRowKey(row);
        const customLabel =
          settings.customLabels?.[CUSTOM_LINE_CHART_LABELS_KEY]?.[rowKey];
        return customLabel ?? rowKey;
      },
    };
  }

  function _rowToForecastDataPoint(
    row: RowForecast,
    rangeFormatter?: (row: RowForecast) => any
  ): ForecastDataPoint {
    try {
      return {
        ..._rowToDataPoint(row, rangeFormatter),
        high: parseFloat(row.rangeHigh() as any),
        low: parseFloat(row.rangeLow() as any),
      };
    } catch (e) {
      logger.error("Failed to parse forecast data point", e);
      throw e;
    }
  }

  function _rowToDataPointWithLowBaseInfo(
    row: U,
    rangeFormatter?: (row: U) => any
  ): DataPointWithLowBaseInfo {
    return {
      point: _rowToDataPoint(row, rangeFormatter),
      lowBase: row.type() === "low_base",
    };
  }

  const lines: ColorableTimeSeriesLine[] = groupedRows.map((group) => {
    return {
      colorKey: group.key,
      line: TimeSeriesLine.fromRows(
        group.values.map((row) => _rowToDataPointWithLowBaseInfo(row)),
        chartData.dateDistanceGetter()
      ),
      forecastLine: new ForecastLine(
        groupedForecastRows
          .find((g) => g.key === group.key)
          ?.values.map((v) => {
            return _rowToForecastDataPoint(v);
          }) ?? []
      ),
    };
  });

  const legendLabels =
    sortedGroupingDimensions.length < 1
      ? []
      : getSortedLegendLabels(lines, settings);
  const labelsTextStyle = defaultTextStyle;

  const rangeScale = d3scl
    .scaleLinear()
    .domain(
      settings.customYAxisRange ?? chartData.rangeExtent(settings.startFromZero)
    )
    .rangeRound([innerChartHeight, 0])
    .nice();
  const ticksStyle = defaultTicksStyle(settings.chart.labelSize);
  const rangeTickValues = rangeScale.ticks(5);
  const ticksFormatter = chartData.makeTicksRangeFormatter(rangeTickValues);
  const rangeTicks = new YTicksContainer(
    chartData.normalizeTicks(
      rangeTickValues.map((t) => ({
        value: t,
        text: ticksFormatter(t),
        offset: rangeScale(t),
      }))
    ),
    ticksStyle
  );
  const areas = calculateLabelAreasLineChart(
    rangeTicks,
    [],
    ticksStyle,
    ticksStyle,
    labelsTextStyle
  );
  const chartSource = chartData.source(
    innerChartHeight,
    settings.customSourceTextSize
  );

  const legendDimensionLabel = defined(legendDimension)
    ? chartData.dataDescription.dimensionToLabel(legendDimension)
    : undefined;

  const legendHeader = defined(legendDimension)
    ? chartData.dimensionHeader(legendDimension)
    : undefined;
  const legendPaddingleft = 10;

  const marginLeft = Math.ceil(
    Math.max(
      (areas.labelsLeft?.totalWidth ?? 0) +
        RANGE_TICKS_SIZE_PIXELS +
        RANGE_TICKS_PADDING_PIXELS,
      DEFAULT_MARGIN_LEFT
    )
  );

  const sideLegendMaxWidth =
    vizAreaWidth -
    chartSource.totalHeight -
    innerChartWidth -
    legendPaddingleft -
    marginLeft;

  let legend: LegendPositioningSide | LegendPositioningBottom | undefined =
    calculateSideLegend(
      sideLegendMaxWidth,
      maxSideLegendHeight,
      legendHeader,
      legendLabels,
      settings.chart.labelSize,
      defaultTextStyle
    );

  // If side legend takes up more height than available,
  // swap to bottom legend below chart
  if (
    !defined(legend) ||
    _.sumBy(legend.labels, (l) => l.height) > maxSideLegendHeight ||
    vizAreaWidth <
      legend.width + (areas.labelsLeft?.totalWidth ?? 0) + innerChartWidth ||
    legend.width > sideLegendMaxWidth
  ) {
    legend = calculateBottomLegend(
      innerChartWidth,
      legendHeader,
      legendLabels,
      settings.chart.labelSize,
      labelsTextStyle
    );
  }

  const rowDomainAccessor = (row: U) =>
    dateParser(row.domain() as string) ?? new Date(0);
  const domainAccessor = (dataPoint: DataPoint) =>
    dateParser(dataPoint.domainRaw()) ?? new Date(0);
  const domainScale = d3scl
    .scaleUtc()
    .domain(chartData.domainExtent(rowDomainAccessor))
    .rangeRound([0, innerChartWidth])
    .nice();

  const chartDimensions = calcChartDimensionsStep2(
    chartDimensionsStep1,
    legendPaddingleft,
    areas,
    chartSource,
    legend,
    marginLeft
  );

  const domainTicks = getDomainTicks(
    innerChartWidth,
    domainScale,
    chartData,
    Dimension.date,
    ticksStyle
  );

  const title = titleRows;
  const scaledDomain = (dataPoint: DataPoint) => {
    return domainScale(domainAccessor(dataPoint));
  };

  const scaledRange = (dataPoint: DataPoint) => {
    const range = rangeAccessor(dataPoint);
    if (!defined(range)) {
      throw new Error("range is undefined");
    }
    return rangeScale(range);
  };

  const scaleDomainValue = (value: Date): number => {
    return domainScale(value);
  };

  const rangeAccessor = (point: DataPoint) => point.value();

  // private methods as closures here

  function _makeRangeFormatterRow(values: RawNumericData[]) {
    const fixedNumDecimals = chartData.outputSettings.fixedNumDecimals;
    if (fixedNumDecimals !== null) {
      return (row: U | RowForecast) => chartData.rangeFormatter(row.range());
    }
    return chartData.makeRangeFormatterRow(values);
  }

  const colorConfig = {
    defaultToSingleColor: false,
    colorableDimensionLabel:
      (defined(legendDimension)
        ? getSpecialColorSchemeKeyByDimensionAndLabels(
            legendDimension,
            legendLabels.map((l) => l.colorKey)
          )
        : undefined) ?? legendDimensionLabel,
  };

  const hasColorDimension = sortedGroupingDimensions.length > 0;

  /**
   * Returns the domain values that is nearest to the
   * given domain position on screen
   */
  const closestDomainValue = (domainScaled: number): string | undefined => {
    const allDomainValues = chartData.uniqueDates ?? [];
    let best: { item: string; distance: number } | undefined;
    for (const domainValue of allDomainValues) {
      const parsedDate = new Date(domainValue);
      const pixelsX = domainScale(parsedDate);
      const distance = Math.abs(domainScaled - pixelsX);
      if (!defined(best) || best.distance > distance) {
        best = {
          item: domainValue,
          distance,
        };
        continue;
      }
    }

    return best?.item;
  };

  /**
   * Returns all data points for the given domain value (time)
   *
   * adaptiveFormatting sets all values to be formatted according to the magnitude of all values
   */
  const dataPointsByDomainValue = (
    domainValue: string,
    options?: { adaptiveFormatting?: boolean }
  ): DataPoint[] => {
    const rows = _.sortBy(
      chartData.rows.filter((row) => row.domain() === domainValue),
      (row) => -row.range()
    );
    const forecastRows = flatten(
      groupedForecastRows.map((g) => g.values)
    ).filter((row) => row.domain() === domainValue);

    const allValues = rows.concat(forecastRows).map((r) => r.range());

    const rangeFormatter = options?.adaptiveFormatting
      ? _makeRangeFormatterRow(allValues)
      : (r: U) => r.range().toString();
    if (rows.length > 0) {
      return rows.map((row) => _rowToDataPoint(row, rangeFormatter));
    }
    // Regular data points not found, try forecast data points

    return _.sortBy(
      forecastRows.map((row) =>
        _rowToForecastDataPoint(row, rangeFormatter as any)
      ),
      (row) => -(row.value() ?? Infinity)
    );
  };

  /**
   * Calculates relative changes between two domain axis points
   */
  const relativeChanges = (
    from: string,
    to: string
  ): { label: string; change: DataCellValue<number> }[] => {
    const results1 = dataPointsByDomainValue(from);
    const results2 = dataPointsByDomainValue(to);
    const dict2: { [key: string]: DataPoint } = {};
    for (const point of results2) {
      const label = point.label();
      dict2[label] = point;
    }
    type ChangeItem = {
      label: string;
      change: DataCellValue<number>;
    };

    const relativeChange: ChangeItem[] = _.chain(results1)
      .map<ChangeItem | undefined>((point) => {
        const label = point.label();
        if (defined(dict2[label])) {
          const fromValue = point.value();
          const toValue = dict2[label].value();
          if (fromValue === 0) {
            return { label, change: DataCellValue.nan("no_value") };
          }
          const change = calculatePercentageChange(fromValue, toValue);
          if (!defined(change)) {
            return { label, change: DataCellValue.nan("no_value") };
          }
          return {
            label: label,
            change: DataCellValue.ok(change),
          };
        }
      })
      .filter(defined)
      .sortBy((x) => x.change.match({ ok: identity, nan: () => -Infinity }))
      .reverse()
      .value();

    return relativeChange;
  };

  const formatDomain = (domain: Date) =>
    chartData.dimensionFormatter(Dimension.date, domain);

  const formattedRange = (row: DataPoint) => {
    const range = rangeAccessor(row);
    if (!defined(range)) {
      throw new Error("range is undefined");
    }
    return chartData.rangeFormatter(range);
  };

  const d3lineGen: d3shape.Line<DataPoint> = d3shape
    .line<DataPoint>()
    .x((point) => scaledDomain(point))
    .y((point) => scaledRange(point));

  return {
    colorConfig,
    liftedDimensions: chartData.liftedDimensions(),
    legendDimension,
    d3lineGen,
    chartDimensions,
    legend,
    legendLabels,
    title,
    sortedGroupingDimensions,
    rangeTicks,
    domainTicks,
    formatDomain,
    formattedRange,
    source: chartSource,
    settings,
    labelAreas: areas,
    lines,
    hasColorDimension,
    scaledDomain,
    scaledRange,
    scaleDomainValue,
    closestDomainValue,
    dataPointsByDomainValue,
    relativeChanges,
  };
}

const dateParser = d3timefmt.utcParse("%Y-%m-%d");

function getDomainTicks(
  innerChartWidth: number,
  domainScale: d3scl.ScaleTime<number, number, never>,
  chartData: ChartDataUnknown,
  domainDimension: Dimension,
  ticksStyle: TicksStyle
): XTicksContainer {
  const maxNumTicks = Math.round(innerChartWidth / 80);
  let domainTicks: TickLabel[] = _.uniqBy(
    domainScale
      .ticks(Math.min(maxNumTicks, chartData.uniqueDates?.length ?? 0))
      .map<TickLabel>((t) => ({
        text: chartData.dimensionFormatter(domainDimension, t),
        offset: domainScale(t),
      })),
    (item) => item.text
  );
  // Ensure num ticks does not exceed a number that is feasible to display
  if (domainTicks.length > maxNumTicks) {
    domainTicks = domainTicks.filter((_t, i) => i % 2 !== 0);
  }
  return new XTicksContainer(domainTicks, ticksStyle);
}

/**
 * Return legend labels, sorted by the last value along the domain
 * axis for each group
 *
 * Assumes the domain dimension is date!
 */
function getSortedLegendLabels(
  lines: ColorableTimeSeriesLine[],
  settings: DataOutputSettings
): LegendLabel[] {
  const defaultSortValue = -1;
  const domainAccessor = (point: DataPoint) => {
    const domain = point.domainRaw() as string | undefined;
    return defined(domain) ? dateParser(domain) : new Date(0);
  };
  const rangeAcc = (point: DataPoint) => point.value();

  const domainMaxUnixMs: number = _.chain(lines)
    .map<number | undefined>((g) =>
      max(
        g.line
          .points()
          .map(domainAccessor)
          .map((d) => d?.getTime())
      )
    )
    .max()
    .value() as number;

  return _.chain(lines)
    .map((g) => {
      const lastValue = g.line.points().find(
        (point) =>
          isDrawableDataPoint(point) && // Exclude low base / invalid choice rows
          domainAccessor(point)?.getTime() === domainMaxUnixMs
      );
      const fullLabel =
        g.colorKey + (g.line.hasLowBase ? ` (${SURVEY_LOW_BASE_LABEL})` : "");
      const customLabel =
        settings.customLabels?.[CUSTOM_LINE_CHART_LABELS_KEY]?.[g.colorKey];
      return {
        fullOriginalLabel: fullLabel,
        customLabel,
        colorKey: g.colorKey,
        key: g.colorKey,
        lastValue,
      };
    })
    .sortBy((val) => {
      return defined(val.lastValue)
        ? rangeAcc(val.lastValue)
        : defaultSortValue;
    })
    .reverse() // sortBy sorts in asceding order so we reverse to get highest priority label first
    .value();
}
