import { Checkbox } from "@fluentui/react";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import { MICRO_REFERENCE_TYPES } from "../../../../../lib/application/state/stats/document-core/core";
import { defined } from "../../../../../lib/core/defined";
import {
  ButtonsFooter,
  ButtonsFooterRight,
} from "../../../../../components/ButtonContainers";
import { SaveButton } from "../../../../../components/Button";
import { chartMicroSettingsLabels } from "../../../../../lib/application/stats/shared/micro_labels";
import { AggregationMethod } from "../../../../../lib/infra/api_responses/dataset";
import { DataOutputSettings } from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";

export function ReferenceSettingsDialog(props: {
  isOpen: boolean;
  onClose: () => void;
  aggMethodGeoMicro?: AggregationMethod | undefined;
  settings: DataOutputSettings;
  setSettings: (settings: DataOutputSettings) => void;
}) {
  return <ReferenceSettingsDialogGeneric {...props} />;
}

export function ReferenceSettingsDialogGeneric(props: {
  isOpen: boolean;
  onClose: () => void;
  aggMethodGeoMicro?: AggregationMethod | undefined;
  settings: DataOutputSettings;
  setSettings: (settings: DataOutputSettings) => void;
}) {
  const { settings, setSettings } = props;

  const buttonState = MICRO_REFERENCE_TYPES.map((part) => {
    return {
      label: chartMicroSettingsLabels(
        part,
        undefined,
        undefined,
        props.aggMethodGeoMicro
      ),
      toggled: settings.chartMicro[part] === true,
      onClick: () => {
        setSettings({
          ...settings,
          chartMicro: {
            ...settings.chartMicro,
            [part]: defined(settings.chartMicro[part])
              ? !settings.chartMicro[part]
              : true,
          },
          tableMicro: {
            ...settings.tableMicro,
            [part]: defined(settings.tableMicro[part])
              ? !settings.tableMicro[part]
              : true,
          },
        });
      },
    };
  });

  return (
    <div>
      <FluentModal
        title="Referensvärden"
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <FluentModalBody>
          <section>
            <p>Visa följande referensvärden:</p>
            <>
              {buttonState.map((button) => (
                <Checkbox
                  className="margin-bottom-xs"
                  checked={button.toggled}
                  label={button.label}
                  onChange={button.onClick}
                />
              ))}
            </>
          </section>
          <p>Dölj alla valda områden och visa endast referensvärden</p>
          <Checkbox
            label="Visa endast referensvärden"
            checked={settings.chartMicro.showReferenceValuesOnly}
            onChange={() => {
              setSettings({
                ...settings,
                chartMicro: {
                  ...settings.chartMicro,
                  showReferenceValuesOnly: defined(
                    settings.chartMicro.showReferenceValuesOnly
                  )
                    ? !settings.chartMicro.showReferenceValuesOnly
                    : true,
                },
                tableMicro: {
                  ...settings.tableMicro,
                  showReferenceValuesOnly: defined(
                    settings.tableMicro.showReferenceValuesOnly
                  )
                    ? !settings.tableMicro.showReferenceValuesOnly
                    : true,
                },
              });
            }}
          />
        </FluentModalBody>
        <FluentModalFooter>
          <ButtonsFooter>
            <ButtonsFooterRight>
              <SaveButton title="Stäng" onClick={props.onClose}></SaveButton>
            </ButtonsFooterRight>
          </ButtonsFooter>
        </FluentModalFooter>
      </FluentModal>
    </div>
  );
}
