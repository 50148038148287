import { String as StringRT, Record, Static } from "runtypes";

export const CreatePackagedDocResultRT = Record({
  packaged_document_id: StringRT,
  release_id: StringRT,
  title: StringRT,
  created_at: StringRT,
  created_by: StringRT,
});

export type CreatePackagedDocResult = Static<typeof CreatePackagedDocResultRT>;
