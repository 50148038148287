import { useCallback, useContext } from "react";
import { useRecoilState } from "recoil";

import { defined } from "../../../../core/defined";
import { GeoTypeMicro } from "../../../../domain/geography";
import { microSelectionPrimary } from "../../stats/document-core/core-micro";
import { singleMicroCardQuery } from "../../stats/document-core/queries/microCard";
import { updateGeoSelections } from "./updates";
import { loadAndStoreDataMicro } from "../../stats/cardToDataStateMicro";
import {
  CardUpdateCountContext,
  ShowDraftDataContext,
} from "../../../contexts";
import { useGeoTree } from "../../../../../views/stats/docs/cards/micro/useGeoTree";
import { useSaveCard } from "../useSaveDocument";
import { DocCardMicro } from "../../stats/document-core/core";
import { useExtendedAppearanceSettings } from "../useExtendedAppearanceSettings";

export function useChangeGeotype(cardId: string) {
  const [card, setCard] = useRecoilState(
    singleMicroCardQuery({ cardStateId: cardId })
  );
  const adminShowDraftData = useContext(ShowDraftDataContext);
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);
  const handleSaveCard = useSaveCard();
  const geoTree = useGeoTree();
  const appearanceSettings = useExtendedAppearanceSettings();

  const handleChangeGeotype = useCallback(
    (geotype: GeoTypeMicro) => {
      const primary = microSelectionPrimary(card);
      if (!defined(primary) || !defined(geoTree)) {
        return;
      }

      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const oldGeoSelections = updateGeoSelections(
        card.data.dataSelections ?? [],
        card.data
      );
      const newGeoSelections = (geotype === "deso"
        ? oldGeoSelections.geoSelectionsOldDeso
        : oldGeoSelections.geoSelectionsOldRegso) ?? {
        type: geotype,
        selected: [],
      };
      const updatedCard: DocCardMicro = {
        ...card,
        data: {
          ...card.data,
          ...oldGeoSelections,
          selectedTab:
            newGeoSelections.selected.length === 0
              ? "map-select"
              : card.data.selectedTab,
          geoSelections: newGeoSelections,
        },
      };

      loadAndStoreDataMicro(
        updatedCard,
        setCard,
        shouldAbort,
        geoTree,
        adminShowDraftData,
        appearanceSettings,
        undefined,
        handleSaveCard
      );
    },
    [
      adminShowDraftData,
      card,
      appearanceSettings,
      geoTree,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      setCard,
    ]
  );

  return handleChangeGeotype;
}
