import { Position2D } from "../../../lib/core/space/position";
import { defined } from "../../../lib/core/defined";
import {
  LegendPositioningBottom,
  LegendPositioningSide,
} from "../../../lib/application/stats/shared/core/definitions";
import { TextContainerPositioned } from "../../../lib/application/stats/shared/core/text_containers";
import {
  translate,
  translatePosition,
} from "../../../lib/application/stats/svg";
import { ColorScheme } from "../../../lib/application/state/stats/document-core/core";
import { RenderPositionedTextLine } from "./Text";

export interface LegendLabelEvents {
  onMouseEnter?: (label: string) => void;
  onMouseLeave?: (label: string) => void;
  onClick?: (label: string) => void;
}

export function ChartLegendBottom(props: {
  legendPosition: Position2D;
  legend: LegendPositioningBottom;
  fontColor: string;
  colorScheme: ColorScheme;
  labelEvents?: LegendLabelEvents;
}) {
  const labels = props.legend.labels;
  const headerLabel = props.legend.headerLabel;
  return (
    <g transform={translate(props.legendPosition.x, props.legendPosition.y)}>
      {defined(headerLabel) &&
        headerLabel.lines.map((line, i) => {
          return (
            <RenderPositionedTextLine
              key={line.text + i}
              anchor={line.anchor}
              line={line}
              fontColor={props.fontColor}
              textStyle={headerLabel.textStyle}
            ></RenderPositionedTextLine>
          );
        })}
      {labels.map((item, i) => (
        <ColorTextLabel
          {...props.labelEvents}
          fontColor={props.fontColor}
          colorScheme={props.colorScheme}
          key={item.lines.map((l) => l.text).join(",")}
          item={item}
        ></ColorTextLabel>
      ))}
    </g>
  );
}

interface LegendRightProps {
  position: Position2D;
  legend: LegendPositioningSide;
  colorScheme: ColorScheme;
  fontColor?: string | null;
  labelEvents?: LegendLabelEvents;
}

export function ChartLegendRight(props: LegendRightProps) {
  const headerLabel = props.legend.headerLabel;
  return (
    <g transform={translate(props.position.x, props.position.y)}>
      {defined(headerLabel) &&
        headerLabel.lines.map((line, i) => {
          return (
            <RenderPositionedTextLine
              fontColor={props.fontColor}
              key={line.text + i}
              anchor={line.anchor}
              line={line}
              textStyle={headerLabel.textStyle}
            ></RenderPositionedTextLine>
          );
        })}
      {props.legend.labels.map((item, i) => (
        <ColorTextLabel
          {...props.labelEvents}
          fontColor={props.fontColor}
          colorScheme={props.colorScheme}
          key={item.lines.map((l) => l.text).join(",")}
          item={item}
        ></ColorTextLabel>
      ))}
    </g>
  );
}

function ColorTextLabel(props: {
  item: TextContainerPositioned;
  colorScheme: ColorScheme;
  onMouseEnter?: (label: string) => void;
  onMouseLeave?: (label: string) => void;
  fontColor?: string | null;
  onClick?: (label: string) => void;
}) {
  const { item, onMouseEnter, onMouseLeave, onClick } = props;
  const lines = item.lines;
  const label = item.originalText;
  const indicatorWidth = item.indicatorWidth;
  return (
    <g
      className="legend-label-container"
      onMouseEnter={
        defined(onMouseEnter) ? (e) => onMouseEnter(label) : undefined
      }
      onMouseLeave={
        defined(onMouseLeave) ? (e) => onMouseLeave(label) : undefined
      }
      onClick={defined(onClick) ? (e) => onClick(label) : undefined}
      transform={translate(0, item.paddingTop ?? 0)}
    >
      {lines.map((line, i) => {
        const commonOptions = {
          className: "mixed-alpha-numeric",
          textAnchor: line.anchor,
          lengthAdjust: "spacingAndGlyphs",
          dominantBaseline: "mathematical",
        };
        return (
          <g key={line.text + i}>
            {line.colorIndicator ? (
              <text
                key={label + "_" + line.text + i}
                {...commonOptions}
                style={{ ...item.textStyle.svgFontAttrs() }}
                transform={translate(
                  line.position.x - indicatorWidth,
                  line.position.y
                )}
              >
                <tspan fill={props.colorScheme[line.colorIndicator.colorKey]}>
                  {line.colorIndicator.content}
                </tspan>
                <tspan
                  dx={line.colorIndicator.paddingRight}
                  fill={props.fontColor ?? undefined}
                >
                  {line.text}
                </tspan>
              </text>
            ) : (
              <text
                {...commonOptions}
                style={item.textStyle.svgFontAttrs()}
                transform={translatePosition(line.position)}
                fill={props.fontColor ?? undefined}
              >
                <tspan>{line.text}</tspan>
              </text>
            )}
          </g>
        );
      })}
    </g>
  );
}
