import { config } from "../../../../../../config";

import {
  AdminMetadataMicroDto,
  AdminMetadataMicroRT,
} from "../../../../../domain-admin/metadata";

import { HttpResult } from "../../../../../infra/HttpResult";
import { authedRequest, decodedAuthedRequest } from "../../../shared";
import { RestrictionMode } from "../create";

export function getMicroMeasureMetadata(
  id: number
): Promise<HttpResult<AdminMetadataMicroDto>> {
  return decodedAuthedRequest(
    config.apis.statsV1,
    `admin/mikro/measures/${id}`,
    undefined,
    "GET",
    AdminMetadataMicroRT
  );
}

export function saveMicroMeasureMetadata(
  measureId: number,
  field: keyof AdminMetadataMicroDto,
  value: AdminMetadataMicroDto[keyof AdminMetadataMicroDto]
): Promise<HttpResult<void>> {
  return authedRequest<void>(
    config.apis.statsV1,
    `admin/mikro/measures/${measureId}`,
    { [field]: value },
    "PUT"
  );
}

export function saveMicroOrgAccessRestriction(
  measureId: number,
  mode: RestrictionMode
): Promise<HttpResult<void>> {
  return authedRequest<void>(
    config.apis.statsV1,
    `admin/mikro/measures/${measureId}`,
    { restriction_mode: mode },
    "PUT"
  );
}

export function saveEnabledComputedMeasures(
  measureId: number,
  enabledComputedMeasures: string[]
): Promise<HttpResult<void>> {
  return authedRequest<void>(
    config.apis.statsV1,
    `admin/mikro/measures/${measureId}`,
    { enabled_computed_measures: enabledComputedMeasures },
    "PUT"
  );
}

export function saveMicroMeasureMetadataMultiField(
  measureId: number,
  fields: {
    [key in keyof Partial<AdminMetadataMicroDto>]: AdminMetadataMicroDto[keyof AdminMetadataMicroDto];
  }
): Promise<HttpResult<void>> {
  return authedRequest<void>(
    config.apis.statsV1,
    `admin/mikro/measures/${measureId}`,
    fields,
    "PUT"
  );
}

export function retireMicroMeasure(
  measureId: number
): Promise<HttpResult<unknown>> {
  return authedRequest(
    config.apis.statsV1,
    `admin/mikro/measures/${measureId}/retire`,
    undefined,
    "POST"
  );
}
