import { useCallback, useContext, useMemo, useState } from "react";
import { TextField } from "@fluentui/react";

import { Card } from "../../../../components/Card";
import { defined } from "../../../../lib/core/defined";
import { SimpleMeasureSelect } from "../shared/SimpleMeasureSelect";
import {
  AppMessagesContext,
  CategoriesContext,
} from "../../../../lib/application/contexts";
import { getMeasureMetadata } from "../../../../lib/application/requests/admin/common_requests_admin";
import { AdminMetadata } from "../../../../lib/domain-admin/metadata";
import { displayMeasure } from "../shared/MeasureWithPath";
import { isEqual } from "lodash";
import { Button } from "../../../../components/Button";
import { prepareCategoryParts } from "../../../../lib/domain/categories";
import { useStateTransition } from "../../../../lib/application/hooks/useStateTransition";
import "./ManageAliases.scss";
import {
  ButtonsFooter,
  ButtonsFooterLeft,
  ButtonsFooterRight,
} from "../../../../components/ButtonContainers";
import { nonEmptyString } from "../../../../lib/core/nonEmptyString";
import {
  createAlias,
  deleteAlias,
  updateAlias,
} from "../../../../lib/application/requests/admin/measure_management/aliases";
import { AlertBox } from "../../../../components/AlertBox";
import { SimpleSubjectSelect } from "../shared/SimpleSubjectSelect";
import { StyledDropdown } from "../../../stats/docs/cards/card_general/StyledDropdown";

type SelectedMeasure = {
  path: string[];
  measure: {
    data_id: number;
    measure: string;
  };
};

type MainMetadata = Pick<
  AdminMetadata,
  "area" | "subarea" | "subject" | "measure"
>;

export function ManageAliases() {
  const [metadata, setMetadata] = useState<AdminMetadata>();

  const [selectedMeasure, setSelectedMeasure] = useState<SelectedMeasure>();
  const appMessages = useContext(AppMessagesContext);

  const handleGoToMeasure = useCallback(
    (metadata: MainMetadata, canonicalMeasureId: number) => {
      return getMeasureMetadata(canonicalMeasureId).then((result) => {
        setMetadata(result);
        const path = [metadata.area, metadata.subarea, metadata.subject];
        setSelectedMeasure({
          path,
          measure: { measure: metadata.measure, data_id: canonicalMeasureId },
        });
      });
    },
    []
  );

  const reloadMetadata = useCallback(() => {
    if (!defined(selectedMeasure)) {
      return;
    }
    getMeasureMetadata(selectedMeasure.measure.data_id).then((result) => {
      setMetadata(result);
    });
  }, [selectedMeasure]);

  return (
    <Card id="manage-aliases">
      <>
        <h2>Hantera alias</h2>
        <section>
          <SimpleMeasureSelect
            actionText="Välj"
            onSelect={(measure) => {
              return getMeasureMetadata(measure.measureId)
                .then((result) => {
                  setSelectedMeasure({
                    path: measure.path,
                    measure: {
                      data_id: measure.measureId,
                      measure: measure.measure,
                    },
                  });
                  setMetadata(result);
                })
                .catch((err) => {
                  appMessages?.add("error", "Kunde inte hämta mått: " + err);
                });
            }}
          ></SimpleMeasureSelect>
        </section>

        {defined(metadata) && defined(selectedMeasure) && (
          <AliasEditor
            reloadAliases={reloadMetadata}
            handleGoTo={handleGoToMeasure}
            metadata={metadata}
            selectedMeasure={selectedMeasure}
          />
        )}
      </>
    </Card>
  );
}

function AliasEditor(props: {
  metadata: AdminMetadata;
  selectedMeasure: SelectedMeasure;
  handleGoTo: (metadata: MainMetadata, canonicalMeasureId: number) => void;
  reloadAliases: () => void;
}) {
  const { metadata, selectedMeasure } = props;
  const [editedAlias, setEditedAlias] = useState<SelectedMeasure>();
  const [newAlias, setNewAlias] = useState<SelectedMeasure>();
  const aliases = metadata.aliases ?? [];
  const currentAlias = aliases.find(
    (a) =>
      isEqual(metadataToPath(a), selectedMeasure.path) &&
      a.measure === selectedMeasure.measure.measure
  );

  const appMessages = useContext(AppMessagesContext);
  const currentIsAlias = defined(currentAlias);
  const handleEditAlias = useCallback((selected: SelectedMeasure) => {
    setEditedAlias(selected);
  }, []);
  const handleGoTo = useCallback(
    (m: MainMetadata, canonicalMeasureId: number) => {
      setEditedAlias(undefined);
      props.handleGoTo(m, canonicalMeasureId);
    },
    [props]
  );

  useStateTransition(selectedMeasure, (prev, next) => {
    if (!isEqual(prev, next)) {
      setEditedAlias(undefined);
      setNewAlias(undefined);
    }
  });

  const handleDeleteAlias = useCallback(
    (a: MainMetadata & { alias_data_id: number }) => {
      if (window.confirm("Är du säker på att du vill ta bort aliaset?")) {
        deleteAlias(selectedMeasure.measure.data_id, a.alias_data_id).then(
          (res) => {
            res.match({
              ok: () => {
                appMessages?.add("success", "Alias borttaget");
                props.reloadAliases();
              },
              err: (err) => {
                appMessages?.add(
                  "error",
                  "Alias kunde inte tas bort: " + err.code
                );
              },
            });
          }
        );
      }
    },
    [appMessages, props, selectedMeasure.measure.data_id]
  );

  const handleSaveEditedAlias = useCallback(
    (data: MainMetadata) => {
      if (!defined(currentAlias)) {
        throw new Error("No alias!");
      }
      if (!defined(editedAlias)) {
        throw new Error("No edited alias!");
      }
      return updateAlias(
        editedAlias.measure.data_id,
        currentAlias.alias_data_id,
        data
      ).then((res) => {
        res.match({
          ok: () => {
            props.reloadAliases();
            setEditedAlias(undefined);
            appMessages?.add("success", "Sparat!");
          },
          err: (err) => {
            appMessages?.add("error", "Kunde inte spara: " + err.code);
          },
        });
      });
    },
    [appMessages, currentAlias, editedAlias, props]
  );

  const handleSaveNewAlias = useCallback(
    (data: MainMetadata) => {
      if (!defined(newAlias)) {
        throw new Error("No new alias!");
      }
      return createAlias(newAlias.measure.data_id, data).then((res) => {
        res.match({
          ok: () => {
            props.reloadAliases();
            setNewAlias(undefined);
            appMessages?.add("success", "Sparat!");
          },
          err: (err) => {
            appMessages?.add("error", "Kunde inte spara: " + err.code);
          },
        });
      });
    },
    [appMessages, newAlias, props]
  );

  return (
    <>
      <h3>{displayMeasure(selectedMeasure)}</h3>
      {currentIsAlias ? (
        <>
          <AlertBox intent="warning">
            <strong>Det här måttet är ett alias</strong>
          </AlertBox>
          <div className="canonical-measure">
            <p>Grundmått: {displayMeasurePath(metadata)}</p>
            <Button
              onClick={() =>
                handleGoTo(metadata, selectedMeasure.measure.data_id)
              }
              small
              title="Gå till"
            ></Button>
          </div>
          {!defined(editedAlias) && !defined(newAlias) && (
            <div>
              <Button
                title="Redigera"
                onClick={() => handleEditAlias(selectedMeasure)}
              />
            </div>
          )}
        </>
      ) : (
        <>
          {aliases.length === 0 ? (
            <strong>Inga alias</strong>
          ) : (
            <section>
              <h4>Alias</h4>
              <ul>
                {aliases.map((a) => {
                  const text = displayMeasurePath(a);
                  return (
                    <li className="alias-line" key={text}>
                      {text}{" "}
                      <Button
                        small
                        intent="danger"
                        title="Ta bort"
                        onClick={() => handleDeleteAlias(a)}
                      ></Button>
                      <Button
                        small
                        title="Gå till"
                        onClick={() => {
                          handleGoTo(a, selectedMeasure.measure.data_id);
                        }}
                      />
                    </li>
                  );
                })}
              </ul>
            </section>
          )}

          <ButtonsFooter>
            <ButtonsFooterRight>
              <Button
                onClick={() =>
                  setNewAlias({
                    path: [],
                    measure: selectedMeasure.measure,
                  })
                }
                intent="primary"
                title="Nytt alias"
              />
            </ButtonsFooterRight>
          </ButtonsFooter>
        </>
      )}
      {defined(editedAlias) && (
        <EditAlias
          handleCancel={() => setEditedAlias(undefined)}
          handleSave={handleSaveEditedAlias}
          area={editedAlias.path[0]}
          subarea={editedAlias.path[1]}
          subject={editedAlias.path[2]}
          measure={editedAlias.measure.measure}
        ></EditAlias>
      )}
      {defined(newAlias) && (
        <CreateNewAlias
          handleCancel={() => setNewAlias(undefined)}
          handleSave={handleSaveNewAlias}
          area={newAlias.path[0]}
          subarea={newAlias.path[1]}
          subject={newAlias.path[2]}
          measure={newAlias.measure.measure}
        ></CreateNewAlias>
      )}
    </>
  );
}

function CreateNewAlias(props: {
  area: string;
  subarea: string;
  subject: string;
  measure: string;
  handleSave: (data: MainMetadata) => Promise<void>;
  handleCancel: () => void;
}) {
  const [selectedPath, setSelectedPath] = useState<string[]>();
  const [measure, setMeasure] = useState<string>(props.measure);
  const area = selectedPath?.[0];
  const subarea = selectedPath?.[1];
  const subject = selectedPath?.[2];
  return (
    <div>
      <section>
        <SimpleSubjectSelect
          area={props.area}
          subarea={props.subarea}
          subject={props.subject}
          onSelect={(path) => {
            setSelectedPath(path);
            return Promise.resolve();
          }}
        ></SimpleSubjectSelect>

        <TextField
          value={measure}
          label="Mått"
          onChange={(e) => {
            setMeasure(e.currentTarget.value ?? "");
          }}
        />
      </section>

      <ButtonsFooter>
        <ButtonsFooterLeft>
          <Button title="Avbryt" onClick={props.handleCancel}></Button>
        </ButtonsFooterLeft>
        <ButtonsFooterRight>
          <Button
            title="Spara"
            disabled={
              !defined(selectedPath) ||
              selectedPath.length !== 3 ||
              !selectedPath.every(nonEmptyString) ||
              !nonEmptyString(measure)
            }
            onClick={() => {
              if (!defined(area) || !defined(subarea) || !defined(subject)) {
                alert("Du måste välja area, subarea, subject och mått!");
                return;
              }
              props.handleSave({
                area,
                subarea,
                subject,
                measure,
              });
            }}
          ></Button>
        </ButtonsFooterRight>
      </ButtonsFooter>
    </div>
  );
}

function EditAlias(
  props: {
    handleSave: (data: MainMetadata) => Promise<unknown>;
    handleCancel: () => void;
  } & Partial<MainMetadata>
) {
  const [area, setArea] = useState<string | undefined>(props.area);
  const [subarea, setSubarea] = useState<string | undefined>(props.subarea);
  const [subject, setSubject] = useState<string | undefined>(props.subject);
  const [measure, setMeasure] = useState<string | undefined>(props.measure);

  const noChanges = isEqual(
    [props.area, props.subarea, props.subject, props.measure],
    [area, subarea, subject, measure]
  );

  const path = useMemo(() => {
    return [area, subarea, subject];
  }, [area, subarea, subject]);
  const categories = useContext(CategoriesContext);
  const { areas, subareas, subjects } = prepareCategoryParts(
    path as any,
    categories
  );
  return (
    <div className="edit-alias">
      <section>
        <StyledDropdown
          className="item"
          selectedKey={area ?? ""}
          onChange={(_, item) => {
            if (defined(item)) {
              setArea(item.key as string);
              setSubarea(undefined);
              setSubject(undefined);
            }
          }}
          dropdownWidth="auto"
          label="Område"
          options={areas.map((area) => ({ key: area, text: area }))}
        />
        <StyledDropdown
          disabled={!defined(area)}
          className="item"
          selectedKey={subarea ?? ""}
          dropdownWidth="auto"
          label="Delområde"
          onChange={(_, item) => {
            if (defined(item)) {
              setSubarea(item.key as string);
              setSubject(undefined);
            }
          }}
          options={
            subareas?.map((subarea) => ({ key: subarea, text: subarea })) ?? []
          }
        />
        <StyledDropdown
          disabled={!defined(subarea)}
          className="item"
          selectedKey={subject ?? ""}
          dropdownWidth="auto"
          label="Ämne"
          onChange={(_, item) => {
            if (defined(item)) {
              setSubject(item.key as string);
            }
          }}
          options={
            subjects?.map((subject) => ({ key: subject, text: subject })) ?? []
          }
        />
        <TextField
          label="Mått"
          value={measure}
          onChange={(e) => {
            setMeasure(e.currentTarget.value);
          }}
        ></TextField>
      </section>
      <ButtonsFooter>
        <ButtonsFooterLeft>
          <Button title="Avbryt" onClick={props.handleCancel}></Button>
        </ButtonsFooterLeft>
        <ButtonsFooterRight>
          <Button
            disabled={
              !nonEmptyString(area) ||
              !nonEmptyString(subarea) ||
              !nonEmptyString(subject) ||
              !nonEmptyString(measure) ||
              noChanges
            }
            intent="primary"
            title="Spara"
            onClick={() => {
              if (
                nonEmptyString(area) &&
                nonEmptyString(subarea) &&
                nonEmptyString(subject) &&
                nonEmptyString(measure)
              ) {
                props.handleSave({ area, subarea, subject, measure });
              }
            }}
          ></Button>
        </ButtonsFooterRight>
      </ButtonsFooter>
    </div>
  );
}

function metadataToPath<
  T extends { area: string; subarea: string; subject: string }
>(metadata: T): string[] {
  return [metadata.area, metadata.subarea, metadata.subject];
}

function displayMeasurePath<
  T extends { area: string; subarea: string; subject: string; measure: string }
>(measure: T): string {
  return `${metadataToPath(measure).join(" > ")} > ${measure.measure}`;
}
