import { TextField } from "@fluentui/react";
import { useCallback, useState } from "react";
import { Button } from "../../../../../../components/Button";
import { FluentIcon } from "../../../../../../components/Icons";
import { defined } from "../../../../../../lib/core/defined";
import { parseSwedishNumber } from "../../../../../../lib/core/numeric/parseNum";
import {
  ComputedMeasureVariablesConfig,
  measureVariableToLabel,
} from "../../../../../../lib/domain/measure/definitions";
import { ComputedMeasurementVariables } from "../../../../../../lib/infra/api_responses/micro_dataset";
import { classNames } from "../../../../../../lib/core/classNames";

interface Props {
  readOnly?: boolean;
  isEditingVariables: boolean;
  toggleIsEditingVariables: () => void;
  config?: ComputedMeasureVariablesConfig;
  variablesSpec: ComputedMeasurementVariables;
  handleSetConfig: (config: ComputedMeasureVariablesConfig) => void;
}
export function VariablesConfig(props: Props) {
  const variableEntries = Object.entries(props.variablesSpec);
  if (variableEntries.length !== 1) {
    throw new Error("Invalid number of variables: " + variableEntries.length);
  }
  const variable = variableEntries[0];

  const [errorMessage, setErrorMessage] = useState<string>();
  const [distanceInput, setDistanceInput] = useState<string>(
    (props.config?.[variable[0]] ?? "").toString()
  );

  const handleSubmit = useCallback(() => {
    const valueResult = parseSwedishNumber(distanceInput.trim());
    valueResult.match({
      ok: (v) => {
        setErrorMessage(undefined);
        props.handleSetConfig({ [variable[0]]: v });
      },
      err: (e) => {
        setErrorMessage("Fyll i ett tal");
      },
    });
  }, [distanceInput, props, variable]);

  return (
    <>
      <TextField
        readOnly={!props.isEditingVariables}
        className={classNames(
          "variable-input-numeric",
          props.readOnly ? "margin-right-xs" : "" // When in read-only mode, add margin to compensate for missing edit icon
        )}
        invalid={defined(errorMessage) || distanceInput.length === 0}
        inputMode="numeric"
        label={`${measureVariableToLabel(variable[0])} (${
          props.variablesSpec[variable[0]].unit
        })`}
        onChange={(e) => {
          setDistanceInput(e.currentTarget.value);
        }}
        value={distanceInput}
      ></TextField>
      {!props.readOnly && (
        <div className="variable-edit-container">
          {props.isEditingVariables ? (
            <Button title="OK" onClick={handleSubmit}></Button>
          ) : (
            <>
              {/* TextField is only used here to ensure the height of the container stays consistent with other fields */}
              <TextField
                className="invisible variable-input-numeric"
                readOnly
                value="D"
              ></TextField>
              <FluentIcon
                size="sm"
                name="edit"
                onClick={props.toggleIsEditingVariables}
              ></FluentIcon>
            </>
          )}
        </div>
      )}
    </>
  );
}
