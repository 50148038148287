import { ChoiceGroup, Dropdown, IChoiceGroupOption } from "@fluentui/react";
import { sortBy } from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";
import { DefaultLoading } from "../../../../../components/Loading";
import { AppMessagesContext } from "../../../../../lib/application/contexts";
import { getOrganizationsWithCache } from "../../../../../lib/application/requests/admin/common_requests_admin";

import { defined } from "../../../../../lib/core/defined";
import {
  OrganizationDto,
  OrganizationResponse,
} from "../../../../../lib/infra/api_responses/admin/organizations";
import { InfostatDataWarning } from "./InfostatDataWarning";
import { StepFooter } from "./StepFooter";
import { DataStep3, CompleteData2, CompleteData3 } from "./types";

const optionKeyLock = "lock";
const optionKeyPublic = "public";
const optionKeys = [optionKeyLock, optionKeyPublic];
type OptionKeys = (typeof optionKeys)[number];

function getExistingMode(data?: DataStep3) {
  if (!defined(data)) {
    return;
  }
  if (data.mode.locked) {
    return optionKeyLock;
  }
  return optionKeyPublic;
}

function getExistingOrgLock(data?: DataStep3): string[] | undefined {
  if (!defined(data)) {
    return;
  }
  return data.mode.locked ? data.mode.orgs.map((o) => o.id) : undefined;
}

export function Step3LockMode(props: {
  data: CompleteData2;
  handlePrevious: (data: CompleteData2) => void;
  handleNext: (data: CompleteData3) => void;
}) {
  const step3 = props.data.step3;
  const [selectedKey, setSelectedKey] = useState<OptionKeys>(
    getExistingMode(step3) ?? optionKeyLock
  );
  const [selectedOrgs, setSelectedOrgs] = useState<string[] | undefined>(
    getExistingOrgLock(step3)
  );
  const [selectedOrgsErrorMessage, setSelectedOrgsErrorMessage] = useState<
    string | undefined
  >();
  const [organizations, setOrganizations] = useState<OrganizationResponse>();
  const appMessages = useContext(AppMessagesContext);

  useEffect(() => {
    getOrganizationsWithCache().then((res) =>
      setOrganizations(sortBy(res, "name"))
    );
  }, []);

  const validate: () => boolean = useCallback(() => {
    let noErrors = true;
    if (selectedKey === optionKeyLock) {
      if (!defined(selectedOrgs) || selectedOrgs.length === 0) {
        setSelectedOrgsErrorMessage("Du måste välja minst en organisation");
        noErrors = false;
      } else {
        setSelectedOrgsErrorMessage(undefined);
      }
    }
    return noErrors;
  }, [selectedKey, selectedOrgs]);

  useEffect(() => {
    validate();
  }, [validate]);

  const options: IChoiceGroupOption[] = [
    { key: optionKeyLock, text: "Lås till en eller flera organisationer" },
    { key: optionKeyPublic, text: "Gör publik för alla Infostat-användare" },
  ];
  const allValid =
    selectedKey === optionKeyPublic ||
    (selectedKey === optionKeyLock && defined(selectedOrgs));

  const handleNext = useCallback(() => {
    if (!validate()) {
      return;
    }
    if (!allValid) {
      return;
    }

    if (selectedKey === optionKeyPublic) {
      props.handleNext({
        ...props.data,
        step3: { mode: { locked: false } },
      });
    } else if (selectedKey === optionKeyLock && defined(selectedOrgs)) {
      const finalSelectedOrgs: OrganizationDto[] = [];
      for (const org of selectedOrgs) {
        const orgInfo = organizations?.find((o) => o.id === org);
        if (!defined(orgInfo)) {
          appMessages?.add(
            "error",
            "Organisationsinfo hittades inte (id: " + org + ")"
          );
          return;
        }
        finalSelectedOrgs.push(orgInfo);
      }
      props.handleNext({
        ...props.data,
        step3: { mode: { locked: true, orgs: finalSelectedOrgs } },
      });
    }
  }, [
    allValid,
    appMessages,
    organizations,
    props,
    selectedKey,
    selectedOrgs,
    validate,
  ]);

  if (!defined(organizations)) {
    return <DefaultLoading></DefaultLoading>;
  }

  return (
    <div>
      <InfostatDataWarning></InfostatDataWarning>
      <h2>Lås mått?</h2>
      <section>
        <ChoiceGroup
          selectedKey={selectedKey}
          options={options}
          onChange={(e, option) => {
            const key = option?.key;
            if (defined(key)) {
              setSelectedKey(key);
            }
          }}
          label="Lås mått till organisation?"
          required={true}
        />
      </section>

      {selectedKey === optionKeyLock && (
        <section>
          <Dropdown
            required
            errorMessage={selectedOrgsErrorMessage}
            multiSelect
            onChange={(_, option) => {
              if (!defined(option)) {
                return;
              }
              const key = option.key as string;
              if (selectedOrgs?.includes(key)) {
                setSelectedOrgs(selectedOrgs.filter((o) => o !== key));
              } else {
                setSelectedOrgs(
                  defined(selectedOrgs) ? [...selectedOrgs, key] : [key]
                );
              }
              validate();
            }}
            selectedKeys={selectedOrgs}
            options={organizations.map((org) => {
              return { key: org.id, text: org.name };
            })}
          ></Dropdown>
        </section>
      )}

      <StepFooter
        handlePrevious={() => props.handlePrevious(props.data)}
        handleNext={handleNext}
      ></StepFooter>
    </div>
  );
}
