/** @deprecated - remove after v5 */
export const SURVEY_MEASURE_BREAKDOWN_KEY = "breakdown";
/** @deprecated - remove after v5 */
export const SURVEY_SUBQUESTION_KEY = "subquestion";

export const SURVEY_VALUE_LOW_BASE = "low_base";
export const SURVEY_VALUE_INVALID_CHOICE = "invalid_choice";
export type SurveyRowStatus =
  | ""
  | typeof SURVEY_VALUE_LOW_BASE
  | typeof SURVEY_VALUE_INVALID_CHOICE;

export const LOW_BASE_EXPLANATION = "FS = Få svar";
