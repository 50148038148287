import { defined } from "../../../../../core/defined";
import { Result } from "../../../../../core/Result";
import { geoSelectionsToCodes } from "../../../../../domain/geography";
import { logger } from "../../../../../infra/logging";
import { defaultThemeSpec } from "../../../../stats/shared/core/colors/colorSchemes";
import { createColorSchemeContainerWithPalette } from "../../document-style/operations";
import {
  initializeColorSchemeContainerLatest,
  parsePartialDataOutputSettingsLatest,
} from "../../workspace/shared";
import { CardState, LatestSettingsFormat, VersionParseError } from "../common";
import { DefaultSettingsV4, DefaultSettingsV4RT } from "./types";

export function encodeSettingsV4(state: CardState): DefaultSettingsV4 {
  const timeSelection = state.innerCardState.timeSelection;
  const colorSchemeContainer = { ...state.colorSchemeContainer };
  return {
    version: "v4" as const,
    measureSettings: getMeasureSettings(state),
    outputSettings: state.innerCardState.settings,
    colorSchemeContainer: colorSchemeContainer,
    lockToLatest: state.innerCardState.lockToLatestTime,
    timeRange: defined(timeSelection)
      ? {
          first: timeSelection[0],
          last: timeSelection[1],
        }
      : undefined,
    selectedView: state.innerCardState.selectedView,
  };
}

export function parseSettingsV4(
  json: unknown
): Result<LatestSettingsFormat, VersionParseError> {
  try {
    const settings = DefaultSettingsV4RT.check(json);
    const defaultColors = createColorSchemeContainerWithPalette(
      settings.colorSchemeContainer.embeddedPalette
    );
    const colorSchemeContainer = initializeColorSchemeContainerLatest({
      ...(settings.colorSchemeContainer ?? defaultColors),
      id: defaultColors.id,
    });
    if (!defined(colorSchemeContainer.embeddedPalette)) {
      const defaultPalette = defaultThemeSpec();
      if (
        Object.values(colorSchemeContainer.colorScheme).every((color) =>
          defaultPalette.colors.some(
            (c) => c.toLowerCase() === color.toLowerCase()
          )
        )
      ) {
        colorSchemeContainer.embeddedPalette = defaultPalette;
      } else {
        logger.error(
          "No embedded palette. Expected color scheme to match default palette, using custom palette"
        );
      }
    }
    return Result.fromOk({
      ...settings,
      outputSettings: parsePartialDataOutputSettingsLatest(
        settings.outputSettings
      ),
      colorSchemeContainer: colorSchemeContainer,
    });
  } catch (e) {
    logger.error("Failed to decode settings using DefaultSettingsV4RT", e);
    return Result.fromErr("decode-failure" as VersionParseError);
  }
}

function getMeasureSettings(
  cardState: CardState
): DefaultSettingsV4["measureSettings"] {
  const measureSelection =
    cardState.innerCardState.dataSelections[0].measureSelection;
  if (!defined(measureSelection)) {
    throw new Error("measureSelection is undefined");
  }
  const valueType = measureSelection.valueType;
  if (
    valueType === "category" ||
    valueType === "decimal" ||
    valueType === "integer"
  ) {
    const geoSelections = cardState.innerCardState.geoSelections;
    return {
      type: "stats",
      geocodes: defined(geoSelections)
        ? geoSelectionsToCodes(geoSelections)
        : [],
      breakdownSelection: measureSelection.breakdowns,
    };
  } else if (valueType === "survey") {
    return {
      type: "survey",
      breakdownSelection: measureSelection.breakdowns,
    };
  } else if (valueType === "survey_string") {
    return {
      type: "survey_string",
      breakdownSelection: measureSelection.breakdowns,
    };
  }
  throw new Error("Invalid valueType: " + valueType);
}
