import { useRecoilState } from "recoil";
import { cardQuery } from "../stats/document-core/queries/card";
import { useCallback, useContext } from "react";
import { cardWithUpdatedDataStateInner } from "../stats/cardToDataStateStats";
import { cardColors } from "../stats/document-core/queries/shared";
import { defined } from "../../../core/defined";
import { cardWithUpdatedDataStateMicroInner } from "../stats/cardToDataStateMicro";
import { GeographiesContext } from "../../contexts";
import { useExtendedAppearanceSettings } from "./useExtendedAppearanceSettings";

export function useRecalculateChart(cardId: string) {
  const [card, setCard] = useRecoilState(cardQuery(cardId));
  const geographies = useContext(GeographiesContext);

  const appearanceSettings = useExtendedAppearanceSettings();

  return useCallback(
    async (outputWidth: number) => {
      switch (card.type) {
        case "microCard": {
          const dataset = card.data.loadedData?.primaryDataset;
          if (!defined(dataset)) {
            return;
          }
          const updatedCard = await cardWithUpdatedDataStateMicroInner(
            card,
            dataset,
            outputWidth,
            appearanceSettings,
            cardColors(card)
          );
          if (!defined(updatedCard)) {
            return;
          }
          setCard(updatedCard);
          break;
        }
        case "dataCard": {
          const dataset = card.data.loadedData?.dataset;
          if (!defined(dataset)) {
            return;
          }
          if (!defined(geographies)) {
            return;
          }
          const updatedCard = await cardWithUpdatedDataStateInner(
            card,
            dataset,
            geographies,
            outputWidth,
            appearanceSettings,
            cardColors(card)
          );
          if (!defined(updatedCard)) {
            return;
          }
          setCard(updatedCard);
          break;
        }
      }
    },
    [card, appearanceSettings, geographies, setCard]
  );
}
