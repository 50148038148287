import * as d3fmt from "d3-format";

import { DataValueTypeAny } from "../../infra/api_responses/dataset";
import { NO_VALUE_MARKER } from "./shared/core/definitions";
import { defined } from "../../core/defined";
import { countDecimalsSF } from "../../core/math/countDecimals";
import { logger } from "../../infra/logging";
import { round } from "../../core/math/round";
import { SURVEY_LOW_BASE_LABEL } from "../../domain/measure/definitions";

export type RawNumericData = number | typeof NO_VALUE_MARKER;
export const DEFAULT_NUM_SIGNIFICANT_FIGURES = 3;

/**
 * Determine the max number of decimals needed to display the number
 * @param values
 * @returns
 */
export function maxNumDecimals(
  values: RawNumericData[],
  numSignificantFigures = DEFAULT_NUM_SIGNIFICANT_FIGURES
) {
  let maxNumDecimals: undefined | number;

  for (const value of values) {
    if (typeof value !== "number") {
      continue;
    }
    const numDecimals = countDecimalsSF(value, numSignificantFigures);
    if (!defined(maxNumDecimals) || numDecimals > maxNumDecimals) {
      maxNumDecimals = numDecimals;
    }
  }

  return maxNumDecimals;
}
export function getRounderNumeric(
  type: Exclude<DataValueTypeAny, "category">,
  numDecimals: number = 0
): (input: number) => number {
  switch (type) {
    case "decimal":
      return (s: number) => round(s, numDecimals);
    case "integer":
      return (s: number) => s;
    case "survey":
      return (s: number) => round(s, numDecimals);
    default:
      throw new Error(`Unrecognized value type: ${type}`);
  }
}
export function getRounder(
  type: Exclude<DataValueTypeAny, "category">,
  numDecimals: number = 0
): (input: string) => number {
  switch (type) {
    case "decimal":
      return (s: string) => round(parseFloat(s), numDecimals);
    case "integer":
      return (s: string) => parseInt(s);
    case "survey":
      return (s: string) => round(parseFloat(s), numDecimals);
    default:
      throw new Error(`Unrecognized value type: ${type}`);
  }
}

export function formatPostalCode(s: string): string {
  return s.slice(0, 3) + " " + s.slice(3);
}

export type Formatter = (input: string) => string;
export function getFormatter(
  type: DataValueTypeAny,
  numDecimals?: number
): Formatter {
  const fixedFormatter = fixedDecimalsFormatter(numDecimals ?? 0);
  switch (type) {
    case "decimal": {
      return (input: string) => {
        try {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return fixedFormatter(input as any as number);
        } catch (e) {
          logger.error("Failed to format input using fixedFormatter", e);
          return input;
        }
      };
    }
    case "survey":
      if (defined(numDecimals)) {
        return (input: string) => fixedFormatter(input as any);
      }
      return (input: string) => formatSurveyValue(input, numDecimals);
    case "survey_string":
      return (input: string) => input;
    case "integer":
      return integerFormatter;
    case "category":
      return textFormatter;
  }
}

export function formatSurveyValue(input: string, numDecimals?: number) {
  if (input === SURVEY_LOW_BASE_LABEL) {
    return input;
  } else if (!defined(input)) {
    return NO_VALUE_MARKER;
  }
  if (defined(numDecimals)) {
    return round(parseFloat(input), numDecimals).toString();
  }
  return integerFormatter(input);
}

export function decimalFormatter(input: string) {
  try {
    return smartValueFormatter(parseFloat(input));
  } catch (e) {
    logger.error("Failed to format input using smartValueFormatter", e);
    return "[ogiltigt värde]";
  }
}

export function textFormatter(input: string) {
  return input;
}

const swedishLocale = d3fmt.formatLocale({
  decimal: ",",
  thousands: "\u00a0",
  grouping: [3],
  currency: ["", " kr"],
});

export const swedishLocaleSimple = d3fmt.formatLocale({
  decimal: ",",
  thousands: "",
  grouping: [1],
  currency: ["", " kr"],
});

const zeroDecFormat = swedishLocale.format(",.0~f");
const oneDecFormat = swedishLocale.format(",.1~f");

export function numSignificantFiguresRounder(numSignificantFigures: number) {
  return swedishLocale.format(`,.${numSignificantFigures}r`);
}

export function significantFiguresRounderLocale(
  numSignificantFigures: number,
  locale: d3fmt.FormatLocaleObject
) {
  return locale.format(`,.${numSignificantFigures}r`);
}

export function threeSFRounderNumeric(num: number): number {
  if (num === 0) {
    return num;
  }
  const d = Math.ceil(Math.log10(num < 0 ? -num : num));
  const power = 3 - d;

  const magnitude = Math.pow(10, power);
  const shifted = round(num * magnitude, 0);

  return shifted / magnitude;
}
const twoSignificantFiguresFormat = numSignificantFiguresRounder(2);

export function integerFormatter(input: string) {
  try {
    if (input === NO_VALUE_MARKER || !defined(input)) {
      return NO_VALUE_MARKER;
    }
    const num = parseFloat(input);
    return zeroDecFormat(num);
  } catch (e) {
    logger.error("Failed to format input using integerFormatter", e);
    return "[ogiltigt värde]";
  }
}

export function fixedDecimalsFormatter(numDecimals: number) {
  const decimalFormat = swedishLocale.format(`0>,.${numDecimals}f`);
  return (rawVal: RawNumericData) => {
    if (rawVal === NO_VALUE_MARKER) {
      return "Data saknas";
    }
    if (numDecimals === 0) {
      return integerFormatter(rawVal + "");
    }
    return decimalFormat(rawVal);
  };
}

export function smartValueRounder(val: number): number {
  const absVal = Math.abs(val);
  if (absVal === 0) return 0;
  if (absVal < 1)
    return parseFloat(numSignificantFiguresRounder(2)(val).replace(",", "."));
  if (absVal < 100) return round(val, 1);
  if (absVal < 10_000) return round(val, 0);
  return round(val, 0);
}
export function smartValueFormatter(rawVal: RawNumericData) {
  if (rawVal === NO_VALUE_MARKER) {
    return "Data saknas";
  }
  const absVal = Math.abs(rawVal);
  if (absVal === 0) return "0";
  if (absVal < 1) return twoSignificantFiguresFormat(rawVal);
  if (absVal < 100) return oneDecFormat(rawVal);
  if (absVal < 10_000) return zeroDecFormat(rawVal);
  return integerFormatter(rawVal + "");
}
