const dataLinkFeatureSupportMicro: DataLinkFeatureSupport = {
  jsonExport: true,
  nullMarkersControl: true,
  geocodeFormat: false,
  longGeoNames: false,
  wideFormat: true,
};

const dataLinkFeatureSupportStats: DataLinkFeatureSupport = {
  jsonExport: true,
  nullMarkersControl: true,
  geocodeFormat: true,
  longGeoNames: true,
  wideFormat: true,
};

const dataLinkFeatureSupportSurvey: DataLinkFeatureSupport = {
  jsonExport: true,
  nullMarkersControl: true,
  geocodeFormat: false,
  longGeoNames: false,
  wideFormat: true,
};

const dataLinkFeatureSupportDataframe: DataLinkFeatureSupport = {
  jsonExport: false,
  wideFormat: false,
  geocodeFormat: false,
  nullMarkersControl: false,
  longGeoNames: false,
};

export function getDataLinkFeatureSupport(
  linkType: "stats" | "survey" | "mikro" | "dataframe"
) {
  switch (linkType) {
    case "stats":
      return dataLinkFeatureSupportStats;
    case "survey":
      return dataLinkFeatureSupportSurvey;
    case "mikro":
      return dataLinkFeatureSupportMicro;
    case "dataframe":
      return dataLinkFeatureSupportDataframe;
  }
}

export function dataLinkSupportZeroFeatures(): DataLinkFeatureSupport {
  return {
    jsonExport: false,
    wideFormat: false,
    geocodeFormat: false,
    nullMarkersControl: false,
    longGeoNames: false,
  };
}

export interface DataLinkFeatureSupport {
  jsonExport: boolean;
  wideFormat: boolean;
  nullMarkersControl: boolean;
  longGeoNames: boolean;
  geocodeFormat: boolean;
}

export function anyLinkExportFeatureAvailable(
  features: DataLinkFeatureSupport
): boolean {
  return Object.values(features).some((v) => v);
}
