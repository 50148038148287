import { useCallback, useContext, useMemo } from "react";
import { useRecoilState } from "recoil";

import {
  getGeoSelections,
  singleDataCardQuery,
} from "../../stats/document-core/queries/dataCard";
import { DocCardStats, GeoSelections } from "../../stats/document-core/core";
import { updateDataCardInnerImmut } from "../../stats/document-core/updates";
import { loadAndStoreDataStats } from "../../stats/cardToDataStateStats";
import { GeographiesSerializable } from "../../../../domain/geography";
import { cardColors } from "../../stats/document-core/queries/shared";
import { useSaveCard } from "../useSaveDocument";
import { CardUpdateCountContext } from "../../../contexts";
import { useExtendedAppearanceSettings } from "../useExtendedAppearanceSettings";

export function useGeoSelection(
  cardId: string,
  geographies: GeographiesSerializable,
  adminShowDraftData: boolean
) {
  const [card, setCard] = useRecoilState(
    singleDataCardQuery({ cardStateId: cardId })
  );

  const selections = useMemo(
    () => getGeoSelections(card, geographies),
    [card, geographies]
  );
  const appearanceSettings = useExtendedAppearanceSettings();
  const handleSaveCard = useSaveCard();
  const { getCurrentValue: getCurrentCount, increment: incrementUpdateCount } =
    useContext(CardUpdateCountContext);

  const handleChange = useCallback(
    async (nextSelection: GeoSelections) => {
      const currentUpdate = incrementUpdateCount();
      const shouldAbort = () => getCurrentCount() > currentUpdate;

      const cardData = updateDataCardInnerImmut(
        card.data,
        "geoSelections",
        () => nextSelection
      );
      const currentColorSchemeContainer = cardColors(card);
      const updatedCard: DocCardStats = { ...card, data: { ...cardData } };
      setCard(updatedCard);

      loadAndStoreDataStats(
        setCard,
        shouldAbort,
        updatedCard,
        geographies,
        adminShowDraftData,
        appearanceSettings,
        currentColorSchemeContainer,
        handleSaveCard
      );
    },
    [
      adminShowDraftData,
      card,
      appearanceSettings,
      geographies,
      getCurrentCount,
      handleSaveCard,
      incrementUpdateCount,
      setCard,
    ]
  );

  return useMemo(
    () => [selections, handleChange] as const,
    [handleChange, selections]
  );
}
