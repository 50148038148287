import { atom, atomFamily } from "recoil";
import { DocCardState } from "./core";

import { Progress } from "../../../../core/progress";

export const docCardAtomFamily = atomFamily<DocCardState | undefined, string>({
  key: "docCardAtomFamily",
  default: undefined,
});

export type DocListItem = { id: string };

export const docCardsListState = atom<DocListItem[]>({
  key: "dataCardListState",
  default: [] as DocListItem[],
});

export const docSaveState = atom<Progress>({
  key: "docSaveState",
  default: Progress.NotStarted,
});
