import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
  createRef,
  forwardRef,
  useRef,
} from "react";
import { ChoiceGroup, Dropdown, IChoiceGroupOption } from "@fluentui/react";
import { v4 } from "uuid";

import {
  AppMessagesContext,
  UserInfoContext,
} from "../../../../lib/application/contexts";
import { defined } from "../../../../lib/core/defined";
import { Button } from "../../../../components/Button";

import {
  CustomThemeSpec,
  builtInSecondaryThemes,
  getInfostatStandardTheme,
} from "../../../../lib/application/stats/shared/core/colors/colorSchemes";
import {
  HttpResult,
  displayHttpErrorInternal,
} from "../../../../lib/infra/HttpResult";
import { logger } from "../../../../lib/infra/logging";
import { classNames } from "../../../../lib/core/classNames";
import {
  DisplayColorGrid,
  EditTheme,
  PreviewMode,
  PreviewModeSetting,
  defaultColorsNewPalette,
} from "./themes";
import { SampleDataPreview } from "../_shared/SampleDataPreview";
import { THEME_USE_DEFAULT_SPECIAL_COLORS } from "../../../../lib/application/stats/shared/core/colors/colors";
import { useEffect } from "react";
import { Permission } from "../../../../lib/application/auth/UserInfo";
import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../components/Modal";
import { adminApi } from "../../../../lib/application/requests/admin/admin_api";
import { OrganizationDto } from "../../../../lib/infra/api_responses/admin/organizations";
import { OrganizationDetailsV2Dto } from "../../../../lib/infra/api_responses/admin/organizations";
import { ButtonsFooterLeft } from "../../../../components/ButtonContainers";
import { emptyOrUndefined } from "../../../../lib/core/emptyOrUndefined";
import { useAppMessages } from "../../../../lib/application/hooks/useAppMessages";

type SelectableColorScheme =
  | {
      type: "built-in";
      default: boolean;
      theme: CustomThemeSpec;
    }
  | { type: "custom"; default: boolean; theme: CustomThemeSpec };

export interface CustomThemesSection {
  name?: string;
  themes: CustomThemeSpec[];
}

export function ManageThemes(props: {
  themeNameModifier?: (theme: CustomThemeSpec) => string;
  handleUpdateCustomThemes: (
    themes: CustomThemeSpec[]
  ) => Promise<HttpResult<unknown>> | undefined;
  handleSetDefaultTheme: (
    id?: string
  ) => Promise<HttpResult<unknown>> | undefined;
  /** The first themes section, if available. Non-editable themes. */
  customThemesSectionLocked?: CustomThemesSection;
  /** First or second themes section. Editable themes */
  customThemesSectionEditable?: CustomThemesSection;
  orgsDefaultThemeId?: string;
  isUpdating: boolean;
  defaultThemeId?: string;
}) {
  const {
    handleUpdateCustomThemes,
    handleSetDefaultTheme,
    customThemesSectionEditable,
    customThemesSectionLocked,
    defaultThemeId,
    isUpdating,
  } = props;

  const [scrollToThemeId, setScrollToThemeId] = useState<string | undefined>();

  const [createNewColorScheme, setCreateNewColorScheme] = useState(false);
  const appMessages = useContext(AppMessagesContext);
  const [currentPreviewMode, setPreviewMode] =
    useState<PreviewModeSetting>("bars");

  // Used for scrolling into view only. Needed when a theme is copied because
  // the copy pops up under the custom themes section, which may not be in view.
  const themeRefs = useRef<{
    [key: string]: React.RefObject<HTMLDivElement>;
  }>({});

  const customThemes = useMemo(
    () => props.customThemesSectionEditable?.themes ?? [],
    [props.customThemesSectionEditable?.themes]
  );

  const handleStartCreateNew = useCallback(() => {
    setCreateNewColorScheme(true);
  }, []);

  const handleSaveNewTheme = useCallback(
    (newTheme: CustomThemeSpec) => {
      if (customThemes.some((p) => p.name === newTheme.name)) {
        appMessages?.add(
          "warning",
          `Ett tema med namnet "${newTheme.name}" finns redan`
        );
        return;
      }
      return handleUpdateCustomThemes(customThemes.concat(newTheme));
    },
    [appMessages, handleUpdateCustomThemes, customThemes]
  );

  const handleUpdateTheme = useCallback(
    (oldName: string, newPalette: CustomThemeSpec) => {
      // Check if one of the palettes not currently being edited already has the name of the edited palette
      if (
        customThemes
          .filter((p) => p.name !== oldName)
          .some((p) => p.name === newPalette.name)
      ) {
        appMessages?.add(
          "warning",
          `Ett tema med namnet "${newPalette.name}" finns redan`
        );
        return;
      }
      const newPalettes = customThemes.map((p) =>
        p.name === oldName ? newPalette : p
      );
      return handleUpdateCustomThemes(newPalettes);
    },
    [appMessages, handleUpdateCustomThemes, customThemes]
  );

  const handleRemoveTheme = useCallback(
    (id: string) => {
      const newPalettes = customThemes.filter((p) => p.id !== id);
      return handleUpdateCustomThemes(newPalettes);
    },
    [handleUpdateCustomThemes, customThemes]
  );

  const handleCopyTheme = useCallback(
    (theme: CustomThemeSpec) => {
      const themeCopy = {
        ...theme,
        customOutputSettings: { ...theme.customOutputSettings },
        id: v4(),
        name: theme.name + " (Kopia)",
      };
      const newPalettes = customThemes.concat(themeCopy);
      return handleUpdateCustomThemes(newPalettes)?.then((res) => {
        setScrollToThemeId(themeCopy.id);
        return res;
      });
    },
    [customThemes, handleUpdateCustomThemes]
  );

  useEffect(() => {
    if (!defined(scrollToThemeId)) {
      return;
    }
    const scrollToRef = themeRefs.current[scrollToThemeId];
    if (!defined(scrollToRef)) {
      return;
    }
    setScrollToThemeId(undefined);
    scrollToRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [scrollToThemeId]);

  const standardInfostat = useMemo(() => {
    const standard = getInfostatStandardTheme();
    return {
      type: "built-in",
      default: standard.id === defaultThemeId,
      theme: standard,
    } as SelectableColorScheme;
  }, [defaultThemeId]);

  const secondaryThemes = useMemo(() => {
    return builtInSecondaryThemes.map(
      (p) =>
        ({
          type: "built-in",
          default: p.id === defaultThemeId,
          theme: {
            ...p,
          },
        } as SelectableColorScheme)
    );
  }, [defaultThemeId]);

  const renderCustomPaletteView = (p: CustomThemeSpec) => {
    // Assign a ref for each PaletteView if it doesn't exist
    if (!themeRefs.current[p.id]) {
      themeRefs.current[p.id] = createRef();
    }

    return (
      <ThemeView
        ref={themeRefs.current[p.id]}
        previewMode={currentPreviewMode}
        key={p.id}
        nameModifier={props.themeNameModifier}
        scheme={{
          type: "custom",
          default: p.id === defaultThemeId,
          theme: p,
        }}
        handleUpdatePalette={(updated) => handleUpdateTheme(p.name, updated)}
        handleCopy={handleCopyTheme}
        handleRemovePalette={handleRemoveTheme}
        handleSetDefault={handleSetDefaultTheme}
      />
    );
  };

  return (
    <div id="manage-themes">
      <PreviewMode
        current={currentPreviewMode}
        onChange={(newMode) => setPreviewMode(newMode)}
      />
      <section>
        <ThemeView
          previewMode={currentPreviewMode}
          nameModifier={props.themeNameModifier}
          scheme={standardInfostat}
          handleUpdatePalette={(updated) => {
            return undefined;
          }}
          handleCopy={handleCopyTheme}
          handleRemovePalette={handleRemoveTheme}
          handleSetDefault={handleSetDefaultTheme}
        />
      </section>
      {defined(customThemesSectionLocked) && (
        <section className="palette-list">
          {defined(customThemesSectionLocked.name) && (
            <h2>{customThemesSectionLocked.name}</h2>
          )}
          {customThemesSectionLocked.themes.map((p) => {
            return (
              <ThemeView
                key={p.name}
                previewMode={currentPreviewMode}
                nameModifier={props.themeNameModifier}
                scheme={{
                  type: "built-in",
                  default: p.id === defaultThemeId,
                  theme: p,
                }}
                handleUpdatePalette={(updated) => {
                  return undefined;
                }}
                handleCopy={handleCopyTheme}
                handleRemovePalette={handleRemoveTheme}
                handleSetDefault={handleSetDefaultTheme}
              />
            );
          })}
        </section>
      )}
      {defined(customThemesSectionEditable) && (
        <section className="palette-list">
          {defined(customThemesSectionEditable.name) && (
            <h2>{customThemesSectionEditable.name}</h2>
          )}
          {customThemesSectionEditable.themes.map(renderCustomPaletteView)}
        </section>
      )}
      <>
        {createNewColorScheme && (
          <section>
            <EditTheme
              previewMode={currentPreviewMode}
              theme={{
                name: "Nytt tema",
                useDefaultSpecialColors: THEME_USE_DEFAULT_SPECIAL_COLORS,
                id: v4(),
                colors: defaultColorsNewPalette,
              }}
              handleStopEditing={() => setCreateNewColorScheme(false)}
              handleSave={handleSaveNewTheme}
            />
          </section>
        )}
      </>
      <section>
        <Button
          disabled={isUpdating}
          intent="primary"
          title="Skapa nytt"
          onClick={handleStartCreateNew}
        ></Button>
      </section>

      <section className="palette-list">
        <h2>Inbyggda teman</h2>
        {secondaryThemes.map((p) => {
          return (
            <ThemeView
              key={p.theme.name}
              previewMode={currentPreviewMode}
              nameModifier={props.themeNameModifier}
              scheme={p}
              handleUpdatePalette={(updated) =>
                handleUpdateTheme(p.theme.name, updated)
              }
              handleCopy={handleCopyTheme}
              handleRemovePalette={handleRemoveTheme}
              handleSetDefault={handleSetDefaultTheme}
            />
          );
        })}
      </section>
    </div>
  );
}

interface ThemeViewProps {
  scheme: SelectableColorScheme;
  nameModifier?: (theme: CustomThemeSpec) => string;
  previewMode: PreviewModeSetting;
  handleUpdatePalette: (
    newPalette: CustomThemeSpec
  ) => Promise<HttpResult<unknown>> | undefined;
  handleRemovePalette: (id: string) => Promise<HttpResult<unknown>> | undefined;
  handleSetDefault: (id?: string) => Promise<HttpResult<unknown>> | undefined;
  handleCopy: (
    palette: CustomThemeSpec
  ) => Promise<HttpResult<unknown>> | undefined;
}

const ThemeView = forwardRef((props: ThemeViewProps, ref) => {
  const [editUserDefinedScheme, setEditUserDefinedScheme] = useState(false);
  const [showSendTheme, setShowSendTheme] = useState(false);

  const userInfo = useContext(UserInfoContext);

  const scheme = props.scheme;

  if (editUserDefinedScheme) {
    if (scheme.type === "built-in") {
      logger.error("Can't edit built-in scheme");
      return null;
    }

    return (
      <EditTheme
        previewMode={props.previewMode}
        theme={scheme.theme}
        handleStopEditing={() => setEditUserDefinedScheme(false)}
        handleSave={props.handleUpdatePalette}
        handleRemovePalette={() => props.handleRemovePalette(scheme.theme.id)}
      ></EditTheme>
    );
  }
  const theme = scheme.theme;
  const currentColors = theme.colors;

  const options: IChoiceGroupOption[] = [
    {
      key: "this",
      text: "",
      onRenderField: (props, render) => {
        return render ? render(props) : null;
      },
    },
  ];

  return (
    <div
      ref={ref as any}
      className={classNames("theme-view", scheme.default ? "default" : "")}
    >
      {showSendTheme && (
        <SendThemeView
          selectedTheme={theme}
          onClose={() => setShowSendTheme(false)}
        />
      )}
      <div className="flex-grow">
        <div className="theme-and-preview">
          <div className="left">
            <ChoiceGroup
              className="default-button"
              selectedKey={scheme.default ? "this" : "that"}
              options={options}
              onChange={(ev, option) => {
                if (!defined(option)) {
                  return;
                }
                if (scheme.default) {
                  // Already is default
                  return;
                }

                props.handleSetDefault(theme.id);
              }}
            />
            <div>
              <h3>
                {props.nameModifier?.(theme) ?? theme.name}
                {scheme.default ? " (förvalt)" : ""}
              </h3>
              <DisplayColorGrid colors={currentColors} />
              <div className="buttons-container">
                <Button
                  small
                  title={"Kopiera"}
                  onClick={() => props.handleCopy(theme)}
                ></Button>
                {scheme.type === "custom" && (
                  <>
                    <Button
                      small
                      title="Redigera"
                      onClick={() => setEditUserDefinedScheme(true)}
                    ></Button>
                    {userInfo?.hasPermission(
                      Permission.InternalManageOrganizations
                    ) && (
                      <Button
                        small
                        title="Skicka ..."
                        onClick={() => setShowSendTheme(true)}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <SampleDataPreview
            theme={theme}
            previewMode={props.previewMode}
          ></SampleDataPreview>
        </div>
      </div>
    </div>
  );
});

function SendThemeView(props: {
  selectedTheme: CustomThemeSpec;
  onClose: () => void;
}) {
  const [orgs, setOrgs] = useState<OrganizationDto[]>([]);
  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>();
  const [selectedOrg, setSelectedOrg] = useState<
    OrganizationDetailsV2Dto | undefined
  >();
  const [selectedUser, setSelectedUser] = useState<string>("");

  const appMessages = useAppMessages();

  useEffect(() => {
    adminApi.listOrganizationsV2().then((res) => {
      res.match({
        ok: setOrgs,
        err: (err) => {
          alert(displayHttpErrorInternal(err));
        },
      });
    });
  }, []);

  useEffect(() => {
    if (!defined(selectedOrgId)) {
      return;
    }

    adminApi.getOrganizationV2(selectedOrgId).then((res) => {
      res.match({
        ok: setSelectedOrg,
        err: (err) => {
          alert(displayHttpErrorInternal(err));
        },
      });
    });
  }, [selectedOrgId]);

  const handleReset = useCallback(() => {
    setSelectedOrgId(undefined);
    setSelectedOrg(undefined);
    setSelectedUser("");
  }, []);

  const handleSendToOrg = useCallback(() => {
    if (!defined(selectedOrgId)) {
      return;
    }

    adminApi
      .sendThemeToOrg({ ...props.selectedTheme, id: v4() }, selectedOrgId)
      .then((res) => {
        res.match({
          ok: () => {
            handleReset();
            appMessages?.add("success", "Tema skickat till organisation");
          },
          err: (err) => {
            appMessages?.add("error", displayHttpErrorInternal(err));
          },
        });
      });
  }, [appMessages, handleReset, props.selectedTheme, selectedOrgId]);

  const handleSendToUser = useCallback(() => {
    if (!defined(selectedUser) || !defined(selectedOrgId)) {
      return;
    }

    adminApi
      .sendThemeToUser(
        { ...props.selectedTheme, id: v4() },
        selectedOrgId,
        selectedUser
      )
      .then((res) => {
        res.match({
          ok: () => {
            handleReset();
            appMessages?.add("success", "Tema skickat till användare");
          },
          err: (err) => {
            appMessages?.add("error", displayHttpErrorInternal(err));
          },
        });
      });
  }, [
    appMessages,
    handleReset,
    props.selectedTheme,
    selectedOrgId,
    selectedUser,
  ]);

  return (
    <FluentModal
      isOpen={true}
      title="Skicka tema (admin)"
      onClose={props.onClose}
    >
      <FluentModalBody>
        <>
          <p>
            Temanamn: <strong>{props.selectedTheme.name}</strong>
          </p>

          <Dropdown
            label="Välj organisation"
            options={orgs.map((o) => ({ key: o.id, text: o.name }))}
            selectedKey={selectedOrgId ?? ""}
            onChange={(ev, option) => {
              if (!defined(option)) {
                return;
              }
              setSelectedOrgId(option.key as string);
              setSelectedUser("");
            }}
          />

          {defined(selectedOrgId) && (
            <div>
              <section>
                <Button
                  intent="primary"
                  title="Skicka till organisation"
                  onClick={handleSendToOrg}
                  className="margin-top-sm"
                ></Button>
              </section>

              {defined(selectedOrg) && (
                <div>
                  {emptyOrUndefined(selectedOrg.users) ? (
                    <strong>Organisationen har inga användare</strong>
                  ) : (
                    <Dropdown
                      label="Välj användare"
                      selectedKey={selectedUser}
                      onChange={(ev, option) => {
                        if (!defined(option)) {
                          return;
                        }
                        setSelectedUser(option.key as string);
                      }}
                      options={
                        selectedOrg.users?.map((u) => ({
                          key: u.id,
                          text: u.email,
                        })) ?? []
                      }
                    />
                  )}
                </div>
              )}

              {defined(selectedUser) && (
                <section>
                  <Button
                    intent="primary"
                    title="Skicka till användare"
                    onClick={handleSendToUser}
                    className="margin-top-sm"
                  ></Button>
                </section>
              )}
            </div>
          )}

          {/*  If org defined, select user */}
        </>
      </FluentModalBody>
      <FluentModalFooter>
        <ButtonsFooterLeft>
          <Button title={"Stäng"} onClick={props.onClose}></Button>
        </ButtonsFooterLeft>
      </FluentModalFooter>
    </FluentModal>
  );
}
