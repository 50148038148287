import { SpinnerSize } from "@fluentui/react";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { DelayedSpinner } from "../../../components/DelayedSpinner";
import { HttpErrorPage } from "../../../components/errors/HttpErrorView";
import { MainContent } from "../../../components/MainContent";
import { createDocument } from "../../../lib/application/requests/docs/documents";
import { emptyWorkspace } from "../../../lib/application/state/stats/workspace/shared";
import { getText } from "../../../lib/application/strings";
import { defined } from "../../../lib/core/defined";
import { HttpError } from "../../../lib/infra/HttpResult";
import { logger } from "../../../lib/infra/logging";
import { documentPath } from "../../../lib/paths";

interface Props {
  newTitle?: string;
  parentFolderId?: number;
}

let statsNewDocCreated = false;

export const StatsNewDoc = memo(StatsNewDocComponent);
export function StatsNewDocComponent(props: Props) {
  const { newTitle, parentFolderId } = props;
  const [httpError, setHttpError] = useState<HttpError | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    // Only create the document once
    // Effects are called repeatedly in dev mode and this
    // effect we never want to run more than once
    if (statsNewDocCreated) {
      return;
    }
    statsNewDocCreated = true;
    createDocument(
      newTitle ?? getText("default-document-name"),
      emptyWorkspace(),
      parentFolderId
    )
      .then((doc) => {
        const res = doc.switch();
        if (res.type === "ok") {
          navigate(documentPath(res.data));
        } else {
          setHttpError(res.error);
        }
      })
      .catch((err) => {
        logger.error(err);
      });
  }, [navigate, newTitle, parentFolderId]);

  return (
    <MainContent className="Stats padding-lg">
      {defined(httpError) ? (
        <HttpErrorPage error={httpError}></HttpErrorPage>
      ) : (
        <DelayedSpinner
          delayShowMs={500}
          size={SpinnerSize.large}
        ></DelayedSpinner>
      )}
    </MainContent>
  );
}
