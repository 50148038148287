import * as _ from "lodash";

import { capitalize } from "../../../core/capitalize";
import { defined } from "../../../core/defined";
import { Dictionary } from "../../../core/dictionary";
import {
  applyDimensionSortOrder,
  measureSelectionIsSurvey,
  measureToDimensionsLabelDict,
} from "../../../domain/measure";
import {
  ComputedMeasureVariablesConfig,
  DimensionV2Dto,
  MeasureSelectionGrouping,
  MeasureSelectionSurveyString,
  MeasureSpecMicro,
  PrimaryMeasureSelectionMicroFull,
  SelectedDimensionsV2,
} from "../../../domain/measure/definitions";
import {
  MeasureSelection,
  MeasureSelectionRegular,
  MeasureSelectionSurvey,
} from "../../../domain/selections/definitions";
import { AggregationMethod } from "../../../infra/api_responses/dataset";
import { MicroDatasetDto } from "../../../infra/api_responses/micro_dataset";
import { logger } from "../../../infra/logging";
import { displayAggregationMethod } from "../datasets/DatasetGeneric";
import { GroupHeaderData, MainHeaderData } from "../datasets/headers";
import { Dimension } from "./core/definitions";
import {
  commonDimensionLabel,
  filterHeaderEnabledDimensions,
  surveyDataDimensionsSorted,
  surveyDataStandardDimensions,
} from "./dimensions";
import {
  getSingleBreakdownSelectionsText,
  getSingleBreakdownSelectionsTextsSurvey,
  getSingleBreakdownSelectionsTextV2,
} from "./texts";
import {
  SurveyDatasetDto,
  SurveyStringDatasetDto,
} from "../../../infra/api_responses/survey_dataset";
import { DataOutputSettings } from "../../state/stats/document-core/DataOutputSettings";

/**
 * A description of the data that should be used for
 * output views and exports.
 * Notably, this may hold custom texts set by the user.
 */
export interface DataDescription {
  header: string;
  excelExportSubheader?: string;

  primaryMeasureSubheaders: string[];
  groupingHeader: string[] | undefined;
  groupingDimensionLabel: string | undefined;

  regionAndTimeHeader: string | undefined;

  listDimensions(): string[];
  dimensionToLabel(dimension: string): string | undefined;

  single_subquestions: string[]; // survey data only
  surveyResponseSingleChoice?: string; // survey data only
  unitHeader: string | undefined;
}

export class DataDescriptionMicro implements DataDescription {
  private _useCustomSubtitles: boolean;
  constructor(
    private _microHeader: MicroDatasetDto["header"],
    private _measureSelection: PrimaryMeasureSelectionMicroFull,
    private _regionAndTimeHeader: string | undefined,
    private _settings: DataOutputSettings
  ) {
    const customSubtitles = this._settings.customSubtitles;
    this._useCustomSubtitles =
      defined(customSubtitles) && customSubtitles.length > 0;
  }

  get header(): string {
    return this._settings.customTitle ?? this._microHeader.descr_long;
  }

  get primaryMeasureSubheaders(): string[] {
    const customSubtitles = this._settings.customSubtitles;
    if (defined(customSubtitles) && customSubtitles.length > 0) {
      return customSubtitles;
    }

    const subheaders: string[] = [];
    const breakdownDisplayLabels: Dictionary<string> = _.chain(
      this._measureSelection.measure.dimensions
    )
      .map((dim) => {
        return [dim.data_column, dim.label];
      })
      .fromPairs()
      .value();

    // For computed measures, we apply OR logic to the breakdowns,
    // so the subtitles must reflect that.
    if (defined(this._measureSelection.measure.computed)) {
      const description = computedMeasureSubDescription(
        this._measureSelection.measure,
        this._measureSelection.computedMeasureVariablesConfig
      );
      if (defined(description)) {
        subheaders.push(description);
      }
      subheaders.push(
        ...displaySelectedDimensions(
          this._measureSelection.selectedDimensions,
          this._measureSelection.measure.dimensions
        )
      );
      return subheaders;
    }

    const liftedDimensionsWithKeys = this._microHeader.lifted;
    subheaders.push(
      ...getSingleBreakdownSelectionsText(
        liftedDimensionsWithKeys,
        breakdownDisplayLabels
      )
    );
    const liftedDimensions = Object.keys(liftedDimensionsWithKeys);

    // Filter out breakdowns that are lifted (headers for those have already been generated above)
    const usedBreakdowns = Object.entries(
      this._measureSelection.selectedDimensions
    )
      .filter(([key, values]) => {
        return (
          defined(values) &&
          values.length > 1 &&
          !liftedDimensions.includes(key)
        );
      })
      .map(([key]) => breakdownDisplayLabels[key]?.toLocaleLowerCase());
    if (usedBreakdowns.length > 0) {
      subheaders.push(makeBreakdownCategoriesLine(usedBreakdowns));
    }

    return subheaders;
  }

  groupingHeader = undefined;
  groupingDimensionLabel = undefined;

  get regionAndTimeHeader() {
    if (this._useCustomSubtitles) {
      return undefined;
    }
    return this._regionAndTimeHeader;
  }

  single_subquestions = [];
  get unitHeader() {
    return this._settings.customUnitText ?? this._microHeader.unit_label;
  }

  listDimensions(): string[] {
    return this._microHeader.dimensions;
  }
  dimensionToLabel(dimension: string): string | undefined {
    const defaultResponse = "__";
    const breakdownDimension = this._measureSelection.measure.dimensions?.find(
      (d) => {
        return d.data_column === dimension;
      }
    );
    if (defined(breakdownDimension)) {
      return breakdownDimension.label;
    }
    if (dimension === Dimension.region) {
      return "Område";
    }
    return commonDimensionLabel(dimension as Dimension) ?? defaultResponse;
  }
}

/**
 * Description of a data set. Use for displaying headers/metadata
 * for charts/tables.
 */
export class DataDescriptionRegular implements DataDescription {
  private _unitHeader: string | undefined;
  public single_subquestions = [];
  private _useCustomSubtitles: boolean;

  constructor(
    private _datasetHeaders: {
      header: MainHeaderData;
      groupHeader?: GroupHeaderData;
    },
    private _regionAndTimeHeader: string | undefined,
    private _measureSelections: MeasureSelection[],
    private _settings: DataOutputSettings,
    private _options?: { excelExportSubheader?: string }
  ) {
    const customSubtitles = this._settings.customSubtitles;
    this._useCustomSubtitles =
      defined(customSubtitles) && customSubtitles.length > 0;
    this._unitHeader = _datasetHeaders.header.unitLabel;
  }

  get excelExportSubheader(): string | undefined {
    return this._options?.excelExportSubheader;
  }

  listDimensions(): string[] {
    return this._datasetHeaders.header.dimensions;
  }

  dimensionToLabel(dimension: string): string {
    const defaultResponse = "__";
    if (dimension === Dimension.grouping) {
      return this._groupingDimensionLabel() ?? "";
    }
    const commonLabel = commonDimensionLabel(dimension as Dimension);
    if (defined(commonLabel)) {
      return commonLabel;
    }

    const primarySelection = this._measureSelections[0];
    if (!defined(primarySelection)) {
      logger.error("No primary selection");
      return defaultResponse;
    }

    if (primarySelection.valueType === "survey") {
      logger.error(
        "Invalid data type: survey data not supported for DataDescriptionRegular"
      );
      return defaultResponse;
    }

    const dim = primarySelection.measure.dimensions.find(
      (d) => d.data_column === dimension
    );
    return dim?.label ?? defaultResponse;
  }

  get aggregationNote(): string | undefined {
    return this._datasetHeaders.header.aggregationNote;
  }

  get header(): string {
    return (
      this._settings.customTitle ?? this._datasetHeaders.header.descriptionLong
    );
  }

  private _groupingDimensionLabel(): string | undefined {
    const groupHeader = this._datasetHeaders.groupHeader;
    if (!defined(groupHeader)) {
      return;
    }
    return groupingDimensionLabel(groupHeader);
  }

  get primaryMeasureSubheaders(): string[] {
    const customSubtitles = this._settings.customSubtitles;
    if (defined(customSubtitles) && customSubtitles.length > 0) {
      return customSubtitles;
    }

    const subheaders: string[] = [];
    const primaryMeasureSelection = this._measureSelections[0];
    // Regular data description is not used for survey measures
    if (measureSelectionIsSurvey(primaryMeasureSelection)) {
      return [];
    }
    const breakdowns = primaryMeasureSelection.breakdowns;
    const breakdownDisplayLabels: Dictionary<string> = _.chain(
      primaryMeasureSelection.breakdowns
    )
      .keys()
      .map((key) => {
        const breakdownDimension =
          primaryMeasureSelection.measure.dimensions?.find(
            (d) => d.data_column === key
          );
        if (!defined(breakdownDimension)) {
          logger.error(
            "Could not find breakdown dimension for dimension label: " + key
          );
          return undefined;
        }
        return [key, breakdownDimension.label];
      })
      .filter(defined)
      .fromPairs()
      .value();

    const primarySingleBreakdowns = getSingleBreakdownSelectionsText(
      this._datasetHeaders.header.liftedDimensions,
      breakdownDisplayLabels
    );
    subheaders.push(...primarySingleBreakdowns);

    const liftedDimensions = Object.keys(
      this._datasetHeaders.header.liftedDimensions ?? {}
    );

    // Filter out breakdowns that are lifted (headers for those have already been generated above)
    const usedBreakdowns = Object.entries(breakdowns)
      .filter(([key, values]) => {
        return (
          defined(values) &&
          values.length > 1 &&
          !liftedDimensions.includes(key)
        );
      })
      .map(([key]) => (breakdownDisplayLabels[key] ?? key).toLocaleLowerCase());
    if (usedBreakdowns.length > 0) {
      subheaders.push(makeBreakdownCategoriesLine(usedBreakdowns));
    }

    return subheaders;
  }

  get groupingHeader(): string[] | undefined {
    if (this._useCustomSubtitles) {
      return undefined;
    }

    const groupHeader = this._datasetHeaders.groupHeader;
    const groupSelection = this._measureSelections[1] as
      | MeasureSelectionGrouping
      | undefined;
    if (defined(groupSelection) && measureSelectionIsSurvey(groupSelection)) {
      return undefined;
    }

    return buildGroupingHeaderForChart(
      this._datasetHeaders.header.aggregationMethod,
      groupHeader,
      groupSelection
    );
  }

  get groupingDimensionLabel(): string | undefined {
    return this._groupingDimensionLabel();
  }

  /**
   * Populated when a single region and/or single time is selected
   */
  get regionAndTimeHeader(): string | undefined {
    if (this._useCustomSubtitles) {
      return undefined;
    }
    return this._regionAndTimeHeader;
  }

  get unitHeader(): string | undefined {
    return this._settings.customUnitText ?? this._unitHeader;
  }
}

export class DataDescriptionSurveyString implements DataDescription {
  private _breakdownDisplayLabels: Dictionary<string>;
  private _useCustomSubtitles: boolean;

  constructor(
    private _rawData: SurveyStringDatasetDto,
    private _primaryMeasureSelection: MeasureSelectionSurveyString,
    private _settings: DataOutputSettings
  ) {
    const customSubtitles = this._settings.customSubtitles;
    this._useCustomSubtitles =
      defined(customSubtitles) && customSubtitles.length > 0;
    this._breakdownDisplayLabels = measureToDimensionsLabelDict(
      _primaryMeasureSelection.measure
    );
  }

  listDimensions(): string[] {
    return this._rawData.header.dimensions;
  }

  dimensionToLabel(dimension: string): string {
    const defaultResponse = "__";
    if (dimension === Dimension.grouping) {
      return "";
    }
    const breakdownLabel = this._breakdownDisplayLabels[dimension];
    if (defined(breakdownLabel)) {
      return breakdownLabel;
    }
    return commonDimensionLabel(dimension as Dimension) ?? defaultResponse;
  }

  get header(): string {
    return this._settings.customTitle ?? this._rawData.header.descr_long;
  }

  get groupingDimensionLabel(): string | undefined {
    return undefined;
  }

  get regionAndTimeHeader() {
    return undefined;
  }

  get groupingHeader() {
    return undefined;
  }
  get unitHeader(): string | undefined {
    // This data type cannot have a unit header
    return undefined;
  }

  get primaryMeasureSubheaders(): string[] {
    const customSubtitles = this._settings.customSubtitles;
    if (defined(customSubtitles) && customSubtitles.length > 0) {
      return customSubtitles;
    }

    const subheaders: string[] = [];
    const primarySingleBreakdowns = getSingleBreakdownSelectionsTextsSurvey(
      this._rawData.header.lifted ?? {},
      this._breakdownDisplayLabels
    );
    subheaders.push(...primarySingleBreakdowns);

    const dataDimensions = surveyDataDimensionsSorted(
      this._rawData.header.dimensions
    ).filter(
      (f) =>
        !surveyDataStandardDimensions.includes(f as Dimension) &&
        f !== Dimension.weight &&
        f !== Dimension.grouping // Exclude grouping dimension because we make a special groupingHeader
    );
    const multiLabels = dataDimensions.map((key) => {
      const displayLabel = this._breakdownDisplayLabels[key] ?? key;
      return displayLabel.toLocaleLowerCase();
    });
    if (multiLabels.length > 0) {
      subheaders.push(makeBreakdownCategoriesLine(multiLabels));
    }

    return subheaders;
  }

  /**
   * For each subquestion set, returns the label of the selected subquestion
   * if only a single one is selected
   */
  get single_subquestions(): string[] {
    if (this._useCustomSubtitles) {
      return [];
    }

    const measureSelection = this._primaryMeasureSelection;
    const subquestionBreakdowns = measureSelection.measure.dimensions.filter(
      (d) => d.type === "survey_subquestion"
    );
    return subquestionBreakdowns
      .map((d) => {
        const values = measureSelection.breakdowns[d.data_column];
        if (defined(values) && values.length === 1) {
          return d.values?.find((v) => v.id === values[0])?.label;
        }
        return undefined;
      })
      .filter(defined);
  }
}

/**
 * Description of a data set. Use for displaying headers/metadata
 * for charts/tables.
 */
export class DataDescriptionSurvey implements DataDescription {
  private _breakdownDisplayLabels: Dictionary<string>;
  private _useCustomSubtitles: boolean;

  constructor(
    private _rawData: SurveyDatasetDto,
    private _regionAndTimeHeader: string | undefined,
    private _primaryMeasureSelection: MeasureSelectionSurvey,
    private _groupingMeasureSelection: MeasureSelectionRegular | undefined,
    private _settings: DataOutputSettings
  ) {
    const customSubtitles = this._settings.customSubtitles;
    this._useCustomSubtitles =
      defined(customSubtitles) && customSubtitles.length > 0;
    this._breakdownDisplayLabels = measureToDimensionsLabelDict(
      _primaryMeasureSelection.measure
    );
  }

  private _groupingDimensionLabel(): string | undefined {
    const groupHeader = this._rawData.groupHeader;
    if (!defined(groupHeader)) {
      return;
    }
    return groupingDimensionLabel(new GroupHeaderData(groupHeader));
  }

  listDimensions(): string[] {
    return this._rawData.header.dimensions;
  }

  dimensionToLabel(dimension: string): string {
    const defaultResponse = "__";
    if (dimension === Dimension.grouping) {
      return this._groupingDimensionLabel() ?? "";
    }
    const breakdownLabel = this._breakdownDisplayLabels[dimension];
    if (defined(breakdownLabel)) {
      return breakdownLabel;
    }
    return commonDimensionLabel(dimension as Dimension) ?? defaultResponse;
  }

  get header(): string {
    return this._settings.customTitle ?? this._rawData.header.descr_long;
  }

  get primaryMeasureSubheaders(): string[] {
    const customSubtitles = this._settings.customSubtitles;
    if (defined(customSubtitles) && customSubtitles.length > 0) {
      return customSubtitles;
    }

    const subheaders: string[] = [];
    const primarySingleBreakdowns = getSingleBreakdownSelectionsTextsSurvey(
      this._rawData.header.lifted ?? {},
      this._breakdownDisplayLabels
    );
    subheaders.push(...primarySingleBreakdowns);

    const dataDimensions = surveyDataDimensionsSorted(
      this._rawData.header.dimensions
    ).filter(
      (f) =>
        !surveyDataStandardDimensions.includes(f as Dimension) &&
        !(f === (Dimension.grouping as string)) // Exclude grouping dimension because we make a special groupingHeader
    );
    const multiLabels = dataDimensions.map((key) => {
      const displayLabel = this._breakdownDisplayLabels[key] ?? key;
      return displayLabel.toLocaleLowerCase();
    });
    if (multiLabels.length > 0) {
      subheaders.push(makeBreakdownCategoriesLine(multiLabels));
    }

    return subheaders;
  }

  get groupingHeader(): string[] | undefined {
    if (this._useCustomSubtitles) {
      return undefined;
    }

    const groupHeader = this._rawData.groupHeader;

    return buildGroupingHeaderForChart(
      "survey",
      defined(groupHeader) ? new GroupHeaderData(groupHeader) : undefined,
      this._groupingMeasureSelection
    );
  }

  get groupingDimensionLabel(): string | undefined {
    return this._groupingDimensionLabel();
  }
  /**
   * Populated when a single region and/or single time is selected
   */
  get regionAndTimeHeader(): string | undefined {
    if (this._useCustomSubtitles) {
      return undefined;
    }
    return this._regionAndTimeHeader;
  }

  /**
   * For each subquestion set, returns the label of the selected subquestion
   * if only a single one is selected
   */
  get single_subquestions(): string[] {
    if (this._useCustomSubtitles) {
      return [];
    }

    const measureSelection = this._primaryMeasureSelection;
    const subquestionBreakdowns = measureSelection.measure.dimensions.filter(
      (d) => d.type === "survey_subquestion"
    );
    return subquestionBreakdowns
      .map((d) => {
        const values = measureSelection.breakdowns[d.data_column];
        if (defined(values) && values.length === 1) {
          return d.values?.find((v) => v.id === values[0])?.label;
        }
        return undefined;
      })
      .filter(defined);
  }

  /** Return the survey response for singlechoice type survey measures */
  get surveyResponseSingleChoice(): string | undefined {
    if (this._useCustomSubtitles) {
      return undefined;
    }

    const selection = this._primaryMeasureSelection;
    if (selection.measure.survey_question_type !== "singlechoice") {
      return undefined;
    }

    const valueDimension = selection.measure.dimensions.find(
      (d) => d.type === "survey_value"
    );
    if (!defined(valueDimension)) {
      return undefined;
    }
    const liftedValue =
      this._rawData.header.lifted?.[valueDimension.data_column];
    if (defined(liftedValue)) {
      return `Svar: ${liftedValue}`;
    }

    return undefined;
  }

  get unitHeader(): string | undefined {
    return this._settings.customUnitText ?? this._rawData.header.unit_label;
  }
}

function makeBreakdownCategoriesLine(usedBreakdowns: string[]) {
  const ending = _.last(usedBreakdowns);
  const beginning = usedBreakdowns.slice(0, -1).join(", ");
  const text =
    usedBreakdowns.length === 1
      ? `Per ${usedBreakdowns[0]}`
      : `Per ${beginning}${usedBreakdowns.length > 1 ? " och " + ending : ""}`;

  return text;
}

export function getApplicableDimensionHeader(
  dimension: string,
  settings: DataOutputSettings,
  dataDescription: DataDescription
): string | undefined {
  if (settings.hideLegendDimensionLabels) {
    return;
  }
  const enabledDimensions = filterHeaderEnabledDimensions(
    dataDescription.listDimensions()
  );
  if (enabledDimensions.includes(dimension)) {
    return dataDescription.dimensionToLabel(dimension);
  }
}

function groupingDimensionLabel(
  groupHeader: GroupHeaderData
): string | undefined {
  const valueType = groupHeader.valueType;
  if (valueType === "category") {
    return groupHeader.descriptionLong;
  }
  return `Nivå för ${groupHeader.descriptionLong.toLocaleLowerCase()} i kommunen`;
}

function buildGroupingHeaderForChart(
  primaryMeasureAggregationMethod: AggregationMethod | "survey" | undefined,
  groupingHeader: GroupHeaderData | undefined,
  groupingMeasureSelection: MeasureSelectionRegular | undefined
) {
  const groupSelection = groupingMeasureSelection;
  if (!defined(groupSelection) || !defined(groupingHeader)) {
    return undefined;
  }

  const breakdowns = groupSelection.breakdowns;
  const liftedDimensions = _.chain(breakdowns)
    .entries()
    .map(([key, value]) => [key, value?.[0]])
    .filter(([key, value]) => defined(value))
    .fromPairs()
    .value();
  const singleBreakdowns = getSingleBreakdownSelectionsTextV2(
    liftedDimensions,
    (d) =>
      groupingMeasureSelection?.measure.dimensions.find(
        (dim) => dim.data_column === d
      )?.label,
    (d, valueId) =>
      groupingMeasureSelection?.measure.dimensions
        .find((dim) => dim.data_column === d)
        ?.values?.find((v) => v.id === valueId)?.label
  );

  const firstLine =
    primaryMeasureAggregationMethod === "survey"
      ? "Andel svarande indelat efter: " + groupingHeader.descriptionLong
      : `${capitalize(
          defined(primaryMeasureAggregationMethod)
            ? displayAggregationMethod(
                primaryMeasureAggregationMethod,
                false
              ) ?? ""
            : ""
        )} för kommuner indelade efter: ${groupingHeader.descriptionLong}`;
  if (singleBreakdowns.length === 0) {
    return [firstLine];
  }
  return [firstLine].concat(singleBreakdowns);
}

function displaySelectedDimensions(
  selectedDimensions: SelectedDimensionsV2,
  dimensions: DimensionV2Dto[] | null
): string[] {
  const results: string[] = [];
  for (const [key, values] of Object.entries(selectedDimensions)) {
    const dim = dimensions?.find((d) => d.data_column === key);
    if (!defined(dim)) {
      logger.error("Could not find dimension with data_column " + key);
      continue;
    }
    const usedValues = values
      ?.map((v) => dim.values?.find((dimValue) => dimValue.id === v)?.label)
      .filter(defined);
    if (!defined(usedValues) || usedValues.length === 0) {
      continue;
    }

    const sortedValues = applyDimensionSortOrder(usedValues, dim).merged();
    results.push(dim.label + ": " + sortedValues.join(", "));
  }

  return results;
}

export function computedMeasureSubDescription(
  measure: MeasureSpecMicro,
  variablesConfig?: ComputedMeasureVariablesConfig
) {
  const computedType = measure.computed?.type;
  if (defined(computedType)) {
    switch (computedType) {
      case "count_in_area":
        return undefined;
      case "count_within_distance":
        return `Antal inom ${variablesConfig?.["distance"]} km`;
      case "distance_to_nearest":
        return "Avstånd till närmaste";
    }
  }
}
