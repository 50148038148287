import React from "react";

import {
  TabbedCardTabsContainer,
  TabbedCardsContentContainer,
  TabbedCardMain,
} from "../../../../../components/Card";
import {
  PageBreakAfter,
  PageBreakBefore,
} from "../../../../../components/print/page_breaks";
import { DocCardMicroImage } from "../../../../../lib/application/state/stats/document-core/core";
import { classNames } from "../../../../../lib/core/classNames";
import { voidFunc } from "../../../../../lib/core/voidFunc";
import { CardContainer } from "../CardContainer";
import { getSharingInfoNonDocument } from "../../../../../lib/application/files/SharingInfo";

import "./MicroCard.scss";

const tabName = "only-tab";
const mockSharingInfo = getSharingInfoNonDocument();
export function MicroCardImage(props: { card: DocCardMicroImage }) {
  const card = props.card;

  return (
    <CardContainer
      isRemovingCard={false}
      cardId={props.card.id}
      removeBrokenCard={voidFunc}
      disableScrollIntoView={true}
      className="infostat-micro"
      sharingInfo={mockSharingInfo}
    >
      <PageBreakBefore breakSetting={card.pageBreak}></PageBreakBefore>
      <TabbedCardTabsContainer currentTab={tabName}>
        <React.Fragment key="no-doc-access"></React.Fragment>
      </TabbedCardTabsContainer>
      <TabbedCardsContentContainer currentTab={tabName}>
        <React.Fragment></React.Fragment>
        <TabbedCardMain name={tabName}>
          <div
            className={classNames(
              "read-only",
              "micro-card-img-packaged-doc",
              "micro-card-content"
            )}
          >
            <img alt="micro map content" src={card.imgUrl} />
          </div>
        </TabbedCardMain>
      </TabbedCardsContentContainer>
      <PageBreakAfter breakSetting={card.pageBreak}></PageBreakAfter>
    </CardContainer>
  );
}
