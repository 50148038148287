import { Dropdown } from "@fluentui/react";
import { Icon } from "@blueprintjs/core";
import { useCallback, useMemo, useState } from "react";

import "./FineTuneBreakdowns.scss";

import {
  FluentModal,
  FluentModalBody,
  FluentModalFooter,
} from "../../../../../components/Modal";
import { Button } from "../../../../../components/Button";
import { defined } from "../../../../../lib/core/defined";
import { displayOperator } from "../../../../../lib/application/stats/datasets/computed_variables";
import { combinations } from "../../../../../lib/core/combinations";
import { SELECT_ALL_LABEL } from "../../../../../lib/application/menus";
import { StatsDataset } from "../../../../../lib/application/stats/datasets/StatsDataset";
import { MicroDataset } from "../../../../../lib/application/stats/datasets/MicroDataset";
import { SurveyDataset } from "../../../../../lib/application/stats/datasets/SurveyDataset";
import { useChangeDataOutputSettings } from "../../../../../lib/application/state/actions/selections/useChangeDataOutputSettings";
import {
  DataOutputSettings,
  ComputedValueConfig,
} from "../../../../../lib/application/state/stats/document-core/DataOutputSettings";

const ALL_VALUES_HIDDEN = "[Alla kategorier]";

type BreakdownCombination = {
  value: string;
  dimension: string;
}[];

type DimensionAndLabel = {
  dimension: string;
  label: string;
};
interface Props {
  isOpen: boolean;
  cardId: string;
  dataset: StatsDataset | MicroDataset | SurveyDataset;
  dimensionToLabel: (dimension: string) => string | undefined;
  onClose: () => void;
}

const SELECT_ALL_KEY = "select-all";

export function FineTuneBreakdownsDialog(props: Props) {
  const { cardId, dataset, dimensionToLabel } = props;

  const [selectedDimensionValues, setSelectedDimensionValues] = useState<
    Record<string, string[]>
  >({});

  const selectableDimensions = useMemo(() => {
    return dataset.labelsByDimension().map((l) => l[0]);
  }, [dataset]);
  const labelsByDimension = useMemo(() => {
    return dataset.labelsByDimension();
  }, [dataset]);

  const [settings, setSettings] = useChangeDataOutputSettings(cardId);

  const dimensionValues = Object.entries(selectedDimensionValues)
    .map(([key, value]) => {
      if (!defined(value)) {
        return;
      }
      return { dimension: key, value };
    })
    .filter(defined);

  /**
   * We filter out dimensions where all values are selected here because we
   * don't need to specify any values for those dimensions.
   */
  const dimensionValuesWithoutAll = dimensionValues.filter((s) => {
    const numSelected = s.value.length;
    const numAvailable = labelsByDimension.find(
      (l) => l[0] === s.dimension
    )?.[1].length;
    return numSelected !== numAvailable;
  });
  const flattenedCombinations: DimensionAndLabel[][] = flattenCombinations(
    dimensionValuesWithoutAll
  );

  const handleHideCombination = useCallback(() => {
    const s = settings;
    const newCombinations = flattenedCombinations.filter(
      (c) =>
        !s.hiddenBreakdownCombinations.some((h) =>
          combinationsEqual(
            h,
            c.map((d) => ({
              dimension: d.dimension,
              value: d.label,
            }))
          )
        )
    );

    const updatedCombinations = s.hiddenBreakdownCombinations.slice();
    if (newCombinations.length > 0) {
      updatedCombinations.push(
        ...newCombinations.map((c) =>
          c.map((label) => ({
            dimension: label.dimension,
            value: label.label,
          }))
        )
      );
    } else {
      // If newCombinations is an empty array, it means all combinations should be hidden,
      // so we add an empty array to the list of hidden combinations.
      // It will filter out all combinations.
      updatedCombinations.push([]);
    }

    const dataOutputSettings: DataOutputSettings = {
      ...s,
      hiddenBreakdownCombinations: updatedCombinations,
    };

    setSettings(dataOutputSettings);
    setSelectedDimensionValues({});
  }, [settings, flattenedCombinations, setSettings]);

  const handleResetBreakdownSelection = useCallback(() => {
    setSelectedDimensionValues({});
  }, []);

  const handleRemoveHiddenCombination = useCallback(
    (combination: BreakdownCombination) => {
      const s = settings;
      const updatedSettings = {
        ...s,
        hiddenBreakdownCombinations: s.hiddenBreakdownCombinations.filter(
          (c) => !combinationsEqual(c, combination)
        ),
      };
      setSettings(updatedSettings);
    },
    [setSettings, settings]
  );

  const handleRemoveComputedVariable = useCallback(
    (label: string) => {
      const updatedSettings = {
        ...settings,
        computedVariables: settings.computedVariables.filter(
          (c) => c.label !== label
        ),
      };
      setSettings(updatedSettings);
    },
    [setSettings, settings]
  );

  const numLockedDimensions = Object.entries(selectedDimensionValues).filter(
    ([dimension, value]) => defined(value) && value.length > 0
  ).length;

  const hiddenCombinations = settings.hiddenBreakdownCombinations;
  const computedValues = settings.computedVariables;
  return (
    <div>
      <FluentModal
        containerClassName="fine-tune-breakdowns-container"
        title="Visa/dölj kategorier"
        isOpen={props.isOpen}
        onClose={props.onClose}
      >
        <FluentModalBody>
          <div className="fine-tune-breakdowns-dialog">
            {computedValues.length > 0 && (
              <section>
                <h3>Beräknade värden</h3>
                <div className="computed-values">
                  <table>
                    <thead></thead>
                    <tbody>
                      {computedValues.map((c) => (
                        <ComputedValueRow
                          key={c.label}
                          handleDelete={handleRemoveComputedVariable}
                          computedValue={c}
                        ></ComputedValueRow>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            )}
            {hiddenCombinations.length > 0 && (
              <section>
                <h3>Dolda kategorier</h3>
                <div className="hidden-breakdowns">
                  {hiddenCombinations.map((combination) => (
                    <Tag
                      key={combination
                        .map((c) => c.dimension + c.value)
                        .join("_")}
                      handleDelete={() =>
                        handleRemoveHiddenCombination(combination)
                      }
                      name={
                        combination.length === 0
                          ? ALL_VALUES_HIDDEN
                          : combination.map((c) => c.value).join(", ")
                      }
                    ></Tag>
                  ))}
                </div>
              </section>
            )}

            <section>
              <h3>Dölj kategori</h3>
              <p>
                Lägg till kategorier eller kombinationer av kategorier som du
                vill dölja från resultatet.
              </p>
              {selectableDimensions.map((dimension) => {
                const currentLabels = labelsByDimension
                  .find((l) => l[0] === dimension)?.[1]
                  .slice()
                  .sort();

                const currentSelectedDimensionValues =
                  selectedDimensionValues[dimension];
                const allValuesSelected =
                  defined(currentSelectedDimensionValues) &&
                  defined(currentLabels) &&
                  currentSelectedDimensionValues.length ===
                    currentLabels.length &&
                  currentLabels.length > 0;
                return (
                  <Dropdown
                    placeholder="Alla"
                    multiSelect
                    key={dimension}
                    label={dimensionToLabel(dimension)}
                    selectedKeys={
                      allValuesSelected
                        ? [SELECT_ALL_KEY].concat(
                            selectedDimensionValues[dimension] ?? []
                          )
                        : selectedDimensionValues[dimension] ?? null
                    }
                    dropdownWidth="auto"
                    onRenderTitle={(items) => {
                      if (allValuesSelected) {
                        return <span>Alla</span>;
                      }
                      return (
                        <span>
                          {items
                            ?.filter((i) => i.key !== SELECT_ALL_KEY)
                            .map((item) => item.key)
                            .join(", ") ?? null}
                        </span>
                      );
                    }}
                    options={[
                      {
                        key: SELECT_ALL_KEY,
                        text: SELECT_ALL_LABEL,
                      },
                    ].concat(
                      currentLabels?.map((label) => ({
                        key: label,
                        text: label,
                      })) ?? []
                    )}
                    onChange={(_, item) => {
                      if (!defined(item)) {
                        return;
                      }
                      if (item.key === SELECT_ALL_KEY) {
                        if (allValuesSelected) {
                          return setSelectedDimensionValues((s) => {
                            const copy = { ...s };
                            delete copy[dimension];
                            return copy;
                          });
                        } else {
                          return setSelectedDimensionValues((s) => {
                            return { ...s, [dimension]: currentLabels ?? [] };
                          });
                        }
                      }
                      setSelectedDimensionValues((s) => {
                        const currentSelected = s[dimension] as
                          | string[]
                          | undefined;
                        if (currentSelected?.includes(item.key as string)) {
                          return {
                            ...s,
                            [dimension]: currentSelected?.filter(
                              (label) => label !== item.key
                            ),
                          };
                        }
                        return {
                          ...s,
                          [dimension]: [
                            ...(currentSelected ?? []),
                            item.key,
                          ] as string[],
                        };
                      });
                    }}
                  ></Dropdown>
                );
              })}
            </section>

            <div>
              <Button
                disabled={numLockedDimensions === 0}
                onClick={handleResetBreakdownSelection}
                title="Återställ"
              ></Button>
              <Button
                intent="primary"
                onClick={handleHideCombination}
                title="Dölj"
              ></Button>
            </div>
          </div>
        </FluentModalBody>
        <FluentModalFooter>
          <Button
            title="Stäng"
            onClick={() => {
              props.onClose();
            }}
          ></Button>
        </FluentModalFooter>
      </FluentModal>
    </div>
  );
}

function Tag(props: { name: string; handleDelete: (tag: string) => void }) {
  return (
    <div className="tag">
      <div className="name">{props.name}</div>
      <div className="button" onClick={() => props.handleDelete(props.name)}>
        <Icon size={12} icon="cross"></Icon>
      </div>
    </div>
  );
}

function combinationsEqual(
  left: BreakdownCombination,
  right: BreakdownCombination
) {
  return (
    left.length === right.length &&
    left.every((l, i) =>
      right.some((r) => r.dimension === l.dimension && r.value === l.value)
    )
  );
}

function ComputedValueRow(props: {
  computedValue: ComputedValueConfig;
  handleDelete: (label: string) => void;
}) {
  const { computedValue: c, handleDelete } = props;
  const leftOps =
    c.version === "1"
      ? displayOperand(c.leftOperand)
      : displayOperandV2(c.leftOperand);
  const rightOps =
    c.version === "1"
      ? displayOperand(c.rightOperand)
      : displayOperandV2(c.rightOperand);
  return (
    <tr>
      <td>
        <strong>{c.label}</strong>
      </td>
      <td>
        {leftOps}{" "}
        {c.leftOperand.length > 0 && c.rightOperand.length > 0 && (
          <>
            <strong>{displayOperator(c.operator)}</strong>{" "}
          </>
        )}
        {rightOps}
      </td>
      <td>
        <Button
          small
          onClick={() => handleDelete(c.label)}
          title="Ta bort"
        ></Button>
      </td>
    </tr>
  );
}

function displayOperandV2(operand: (string[] | number)[]): string | undefined {
  if (operand.length === 0) {
    return;
  }
  const formattedOperands = operand.map((o) => {
    if (typeof o === "number") {
      return o.toString();
    }
    return o.join(" > ");
  });

  return formattedOperands.length > 1
    ? formattedOperands.join(" + ")
    : formattedOperands[0].toString();
}

function displayOperand(operand: (string | number)[]): string | undefined {
  if (operand.length === 0) {
    return;
  }
  return operand.length > 1 ? operand.join(" + ") : operand[0].toString();
}

function flattenCombinations(
  dimensionSelection: { dimension: string; value: string[] }[]
): DimensionAndLabel[][] {
  const flattened: DimensionAndLabel[][] = dimensionSelection.map((s) =>
    s.value.map((v) => ({ dimension: s.dimension, label: v }))
  );
  return combinations<DimensionAndLabel>(flattened);
}
