import { EmbeddedDocumentDto } from "../../../../infra/api_responses/document_embed";
import { StatsApi } from "../../../requests/statsApi";
import { StatsApiV2 } from "../../../requests/statsApiV2";
import {
  WorkspaceCardLatest,
  WorkspaceLatest,
  initializeColorSchemeContainerLatest,
} from "./shared";
import {
  CURRENT_WORKSPACE_VERSION,
  WorkspaceWrapper,
  WorkspaceWrapperRT,
} from "./types";
import {
  workspaceCardToCardStateV8,
  workspaceEmbeddedCardToCardStateV8,
} from "./v8/workspaceToStateV8";
import { WorkspaceV8RT } from "./v8/types";

export async function migrateDocument(
  workspaceWithVersion: WorkspaceWrapper,
  statsApi: StatsApi,
  statsApiV2: StatsApiV2
): Promise<WorkspaceWrapper> {
  let res: WorkspaceWrapper = workspaceWithVersion;
  while (res.workspaceVersion < CURRENT_WORKSPACE_VERSION) {
    switch (res.workspaceVersion) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        throw new Error(
          "Invalid version " +
            res.workspaceVersion +
            ". Versions 1-7 are not supported anymore."
        );
      default:
        throw new Error(
          "Cannot migrate workspace with version " + res.workspaceVersion
        );
    }
  }
  return res;
}

export async function getVerifiedEmbeddedDoc(
  embeddedDocument: EmbeddedDocumentDto,
  statsApi: StatsApi,
  statsApiV2: StatsApiV2
): Promise<WorkspaceLatest> {
  const documentUnverifed = embeddedDocument.document;
  const workspaceWithVersionUnmigrated =
    WorkspaceWrapperRT.check(documentUnverifed);
  const workspaceWithVersion = await migrateDocument(
    workspaceWithVersionUnmigrated,
    statsApi,
    statsApiV2
  ).then((r) => WorkspaceV8RT.check(r));
  return {
    ...workspaceWithVersion,
    state: {
      ...workspaceWithVersion.state,
      colorSchemes: workspaceWithVersion.state.colorSchemes.map(
        initializeColorSchemeContainerLatest
      ),
    },
  };
}

export async function getVerifiedWorkspace(
  workspaceUnverified: {
    id: number;
    title: string;
    workspace: unknown;
  },
  statsApi: StatsApi,
  statsApiV2: StatsApiV2
): Promise<WorkspaceLatest> {
  const workspaceWithVersionUnmigrated = WorkspaceWrapperRT.check(
    workspaceUnverified.workspace
  );
  const workspaceWithVersion = await migrateDocument(
    workspaceWithVersionUnmigrated,
    statsApi,
    statsApiV2
  );
  const decodedWorkspace = WorkspaceV8RT.check(workspaceWithVersion);
  return {
    ...decodedWorkspace,
    state: {
      ...decodedWorkspace.state,
      colorSchemes: decodedWorkspace.state.colorSchemes.map(
        initializeColorSchemeContainerLatest
      ),
    },
  };
}

export async function getVerifiedThirdPartyDoc(
  docUnverified: {
    title: string;
    content: unknown;
  },
  statsApi: StatsApi,
  statsApiV2: StatsApiV2
): Promise<WorkspaceLatest> {
  const workspaceWithVersionUnmigrated = WorkspaceWrapperRT.check(
    docUnverified.content
  );
  const workspaceWithVersion = await migrateDocument(
    workspaceWithVersionUnmigrated,
    statsApi,
    statsApiV2
  );

  const decodedWorkspace = WorkspaceV8RT.check(workspaceWithVersion);

  return {
    ...decodedWorkspace,
    state: {
      ...decodedWorkspace.state,
      colorSchemes: decodedWorkspace.state.colorSchemes.map(
        initializeColorSchemeContainerLatest
      ),
    },
  };
}

export async function handleWorkspaceCardToCardStateLatest(
  workspaceCard: WorkspaceCardLatest,
  useDraftMode: boolean,
  api: StatsApi,
  apiV2: StatsApiV2
) {
  return workspaceCardToCardStateV8(workspaceCard, useDraftMode, api, apiV2);
}

export async function handleEmbeddedCardToCardStateLatest(
  workspaceCard: WorkspaceCardLatest,
  api: StatsApi,
  apiV2: StatsApiV2
) {
  return workspaceEmbeddedCardToCardStateV8(workspaceCard, api, apiV2);
}

export function decodeLatestWorkspace(workspaceUnverified: {
  id: number;
  title: string;
  workspace: unknown;
}): WorkspaceLatest {
  return WorkspaceV8RT.check(workspaceUnverified.workspace);
}
