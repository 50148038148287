import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { defined } from "../../../../core/defined";
import { GeographiesSerializable } from "../../../../domain/geography";
import { makeDataSelection } from "../../stats/document-core/core";
import { primarySelectionQuery } from "../../stats/document-core/queries/dataCard";
import { usePrimarySelectionChanger } from "./shared/usePrimarySelectionChanger";

export function useChangePrimarySelection(
  cardId: string,
  geographies: GeographiesSerializable
) {
  const primarySelection = useRecoilValue(
    primarySelectionQuery({ cardStateId: cardId })
  );

  const handleChangePrimarySelection = usePrimarySelectionChanger(
    cardId,
    primarySelection,
    geographies
  );

  /**
   * Change the subject path, keeping some settings from previous data selection
   */
  const handleChangePrimaryPath = useCallback(
    (path: string[]) => {
      const oldSelection = defined(primarySelection)
        ? primarySelection
        : makeDataSelection(path);

      handleChangePrimarySelection({
        ...oldSelection,
        subjectPath: path,
        measureSelection: undefined,
      });
    },
    [handleChangePrimarySelection, primarySelection]
  );

  return { handleChangePrimarySelection, handleChangePrimaryPath };
}
