import { Record, Static, String as StringRT } from "runtypes";

import { ColorSchemeContainer } from "../document-style/definitions";
import { InnerDataCardState } from "../document-core/core";
import { DefaultSettingsV3 } from "./v3/types";
import { DefaultSettingsV4 } from "./v4/types";

type All = DefaultSettingsV3 | DefaultSettingsV4;

export const DefaultSettingsWrapperRT = Record({ version: StringRT });
export type DefaultSettingsWrapper = Static<typeof DefaultSettingsWrapperRT>;

export type Version = All["version"];
export type LatestSettingsFormat = DefaultSettingsV4;

export type VersionParseError = "decode-failure" | "unknown-failure";

export interface CardState {
  colorSchemeContainer: ColorSchemeContainer;
  innerCardState: InnerDataCardState;
}
